import { arrangeArray } from '../common';

export const updateDescriptionGoTime = (draft, action) => {
    const { descHtml } = action;

    draft.experience.best_time_to_go.description_html = descHtml;

    return draft;
};

export const arrangeTipsGoTime = (draft, action) => {
    return arrangeArray(
        draft,
        action,
        draft.experience.best_time_to_go.tips_html
    );
};

export const deleteTipGoTime = (draft, action) => {
    const { tipIndex } = action;

    draft.experience.best_time_to_go.tips_html.splice(tipIndex, 1);

    return draft;
};

export const addTipGoTime = (draft, action) => {
    const { dataObj } = action;

    draft.experience.best_time_to_go.tips_html.push(dataObj);

    return draft;
};

export const updateTipGoTime = (draft, action) => {
    const { tipIndex, dataObj } = action;

    draft.experience.best_time_to_go.tips_html[tipIndex] = {
        ...draft.experience.best_time_to_go.tips_html[tipIndex],
        ...dataObj,
    };

    return draft;
};

export const updateGoTime = (draft, action) => {
    const { dataObj } = action;

    draft.experience.best_time_to_go.time_range = dataObj;

    return draft;
};
