/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import Icons from 'components/blocks/Icons';
import Image from 'components/blocks/Image';
import { handleRowReverse } from 'helpers/FEutils';
import { getCompletion, getAllActivitiesCompletion, getOverviewCompletion, getMarketingCompletion, buildMenuData, getHeadTitle } from 'helpers/menuHelpers';
import { createItineraryDay } from 'store/actions/experienceDetails/overview';
import { ButtonsGroupNextPrev } from 'components/blocks/Buttons';
import ListBoxGeneric from 'components/blocks/ListBoxGeneric';
// import { CardXlDragHandleMenuItem } from 'components/blocks/CardXlDrag';
import LayoutNavList from 'components/layouts/LayoutNavList';
// import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import 'styles/progressCircle.css';
import ProgressMenu from 'components/blocks/ProgressMenu';
import UploadGalleryImages from 'components/specialty/UploadGalleryImages';
import { updateFeatureImage } from 'store/actions/experienceDetails/sidebar';
import { ButtonsClose } from 'components/blocks/Buttons';
import { ReactComponent as KLogoIcon } from 'components/svg/kn_logoicon_black.svg';
import { ReactComponent as KLogoText } from 'components/svg/kn_logotext.svg';
import CollapseButton from 'components/blocks/CollapseButton';
import ConfirmModal from 'components/modals/ConfirmModal';
import KreatorBadge from 'components/blocks/KreatorBadge';
import { useWindowSize } from 'helpers/useWindowSize';

import { MenuRefsHolder } from 'helpers/MenuRefsHolder';
import GenericTitle from 'components/blocks/GenericTitle';
import { _INTRO_OVERVIEW } from 'store/actions/types';
import { useOnlineStatus } from 'helpers/useOnlineStatus';
import classNames from '../../../node_modules/classnames/index';
// import FeedbackBox from 'components/blocks/FeedbackBox';

const baseUrl = {
    kreatorPage: '/kreator',
    experiencePage: '/purchased',
    avatar: '/assets/media/kreators',
    experienceImageFeatured: '/assets/media/results'
};

const LayoutExperiencePurchasedSidebar = (props) => {
    const {
        publicView,
        dayIndex,
        experienceDetails: { experience, type, user: author },
        globalState: { lang, edit, inPublished, isFeedbackMode },
        menuIsOpen,
        handleMenuIsOpen,
        experienceId,
        children,
        auth: { user },
        updateFeatureImage,
        createItineraryDay
        // auth: {
        //     user: { role: userRole, _id: userId }
        // }
    } = props;

    let history = useNavigate();
    let featuredImage = null;
    let userRole = null;
    let isAdminStaff = false;
    const isOnline = useOnlineStatus();
    const titleTxt = experience.overview_intro.title;

    const introObj = {
        titleEmpty: uiStruct.overview.intro.uiPreview.titleEmpty,
        titleHint: uiStruct.overview.intro.uiEdit.modals.title.hint,
        titleModalPlaceholder: uiStruct.overview.intro.uiEdit.modals.title.placeholders,
        descriptionEmpty: uiStruct.overview.intro.uiPreview.descriptionEmpty,
        descriptionHint: uiStruct.overview.intro.uiEdit.modals.description.hint
    };
    if (!publicView) {
        userRole = props.auth.user.role;
        isAdminStaff = userRole === process.env.REACT_APP_STRAPI_ADMIN_ROLE_ID || userRole === process.env.REACT_APP_STRAPI_ADMIN_ROLE_ID;
    }
    // const isPublishedView = window.location.pathname.includes(
    //     process.env.REACT_APP_BASENAME_EXPERIENCE_PUBLISHED
    // );
    const rtl = !!translations[lang].rtl;

    featuredImage = experience.featured_image;
    const [move] = useState(false);
    const [delConfirmModalState, setDelConfirmModalState] = useState(false);
    const daysList = buildMenuData(experience, experienceId, false, false, true);
    const [selectedDay, setSelectedDay] = useState(daysList[dayIndex + 1]);
    const currentNumDays = useRef(daysList.length);
    const [newDayModalStatus, setNewDayModalStatus] = useState(false);

    // const [daysList, setDaysList] = useState(daysListTmp);

    // useEffect(() => {
    //     setDaysList(buildMenuData(experience, experienceId));
    // }, [experience]);

    // useEffect(() => {
    //     history(selectedDay.id, { replace: true });
    //     // getNextPrevStatus();
    // }, [selectedDay]);

    useEffect(() => {
        getNextPrevStatus();
        setSelectedDay(daysList[dayIndex + 1]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dayIndex]);

    useEffect(() => {
        if (currentNumDays.current < daysList.length) {
            currentNumDays.current = daysList.length;
            history(daysList[daysList.length - 2].id, { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [daysList]);

    const handleSelectDropdown = (val) => {
        history(val.id, { replace: true });
    };

    const handleImageUpdate = (imgageData) => {
        const { url } = imgageData[0];

        updateFeatureImage(url);
    };
    const handleDeleteImage = () => {
        setDelConfirmModalState(true);
    };
    const confirmDelete = () => {
        updateFeatureImage('');
    };
    const [daysNav, setDaysNav] = useState({ next: false, prev: true });

    const getNextPrevStatus = () => {
        const publicIndex = Number(publicView ? -1 : 0);
        const next = !(dayIndex <= experience.itinerary.trip_days.length - 1 + publicIndex);
        const prev = !(dayIndex > -1 && dayIndex <= experience.itinerary.trip_days.length);
        setDaysNav({ next, prev });
    };

    const setNavDaysNext = () => {
        history(daysList[dayIndex + 2].id, { replace: true });
    };

    const setNavDaysPrev = () => {
        history(daysList[dayIndex].id, { replace: true });
    };

    const handleCreateDay = () => {
        setNewDayModalStatus(true);
    };

    const confirmNewDay = () => {
        createItineraryDay(type);
    };

    return (
        <div className={`fixed top-0 z-100 `}>
            <div
                onClick={() => handleMenuIsOpen(false)}
                className={`fixed xl:hidden inset-0 bg-black duration-300 transition-opacity ${menuIsOpen ? 'opacity-50' : 'opacity-0 pointer-events-none'}`}
            ></div>

            <div className={`w-full md:w-96 ${handleRowReverse(rtl).rtl}`}>
                <div
                    style={{ zIndex: 51 }}
                    className={`${
                        handleRowReverse(rtl).rtl
                    } fixed bottom-0 inset-x-0 h-16 md:h-20 bg-white transform-gpu duration-300 translate-y-0 shadow-cards-top md:hidden
                    `}
                >
                    <div className='flex flex-1 flex-row justify-start md:justify-between items-center h-full gap-4'>
                        <div className='flex justify-start items-center h-full'>
                            <button
                                onClick={() => handleMenuIsOpen(!menuIsOpen)}
                                className={classNames(
                                    'bg-green-400 hover:bg-gray-900 hover:text-white focus:outline-none w-20 flex flex-shrink-0 h-12 items-center justify-center text-2xl rounded-r-lg'
                                )}
                            >
                                <i
                                    className={`${
                                        menuIsOpen ? (rtl ? 'ri-menu-unfold-line' : 'ri-menu-fold-line') : rtl ? 'ri-menu-fold-line' : 'ri-menu-unfold-line'
                                    }`}
                                ></i>
                            </button>
                        </div>
                        <div className='ml-0 mr-6 w-full flex flex-row items-center gap-4 justify-between'>
                            <div className='flex items-center flex-1'>
                                <div className='w-full'>
                                    <ListBoxGeneric
                                        disabled={!isOnline}
                                        listData={daysList}
                                        val={selectedDay}
                                        handleChange={handleSelectDropdown}
                                        textClass={'text-sm'}
                                        useRouting
                                        height='h-9'
                                        direction='top'
                                    />
                                </div>
                            </div>
                            <ButtonsGroupNextPrev
                                disabled={!isOnline}
                                nextAction={setNavDaysNext}
                                isNext={daysNav.next}
                                prevAction={setNavDaysPrev}
                                isPrev={daysNav.prev}
                                className={`z-50`}
                                rtl={rtl}
                                alwaysOn
                            />
                        </div>
                    </div>
                </div>

                <div
                    style={{ zIndex: 51 }}
                    className={`${
                        handleRowReverse(rtl).rtl
                    } fixed bottom-0 left-0 right-0 h-16 md:h-20 bg-white transform-gpu duration-300 translate-y-0 shadow-cards-top hidden md:block
                    
                    `}
                >
                    <div className='flex flex-row justify-start items-center h-full gap-4'>
                        <div className='flex justify-start items-center h-full'>
                            <button
                                onClick={() => handleMenuIsOpen(!menuIsOpen)}
                                className={`focus:outline-none w-20 
                            flex flex-shrink-0 h-12 items-center justify-center text-2xl bg-green-400 hover:bg-gray-900 hover:text-white rounded-r-lg`}
                            >
                                <i
                                    className={`${
                                        menuIsOpen ? (rtl ? 'ri-menu-unfold-line' : 'ri-menu-fold-line') : rtl ? 'ri-menu-fold-line' : 'ri-menu-unfold-line'
                                    }`}
                                ></i>
                            </button>
                        </div>
                        <div className='ml-0 mr-6 w-full flex flex-row items-center gap-4'>
                            <div className='flex items-center '>
                                <div className='w-40'>
                                    <ListBoxGeneric
                                        disabled={!isOnline}
                                        listData={daysList}
                                        val={selectedDay}
                                        handleChange={handleSelectDropdown}
                                        textClass={'text-sm'}
                                        useRouting
                                        height='h-9'
                                        direction='top'
                                    />
                                </div>
                            </div>
                            <ButtonsGroupNextPrev
                                disabled={!isOnline}
                                nextAction={setNavDaysNext}
                                isNext={daysNav.next}
                                prevAction={setNavDaysPrev}
                                isPrev={daysNav.prev}
                                className={`z-50`}
                                rtl={rtl}
                                alwaysOn
                            />
                        </div>
                    </div>
                </div>

                {false && (
                    <div
                        className={`z-50 w-96 ${handleRowReverse(rtl).left}-0 fixed   transition duration-500 ease-in-out transform ${
                            menuIsOpen ? 'translate-x-0' : handleRowReverse(rtl).menuTranslate
                        }`}
                    >
                        <button
                            onClick={() => handleMenuIsOpen(!menuIsOpen)}
                            className={`hidden xl:flex absolute top-4 tour-drawer outline-none focus:outline-none w-12 h-12
                             items-center justify-center text-xl bg-green-400 hover:bg-gray-900 hover:text-white ${
                                 rtl ? 'rounded-l-lg -left-12' : 'rounded-r-lg -right-12'
                             }`}
                        >
                            <i
                                className={`${
                                    menuIsOpen ? (rtl ? 'ri-menu-unfold-line' : 'ri-menu-fold-line') : rtl ? 'ri-menu-fold-line' : 'ri-menu-unfold-line'
                                }`}
                            ></i>
                        </button>
                    </div>
                )}

                <div
                    className={`z-50 ${handleRowReverse(rtl).left}-0 fixed overflow-y-auto no-scrollbar ${
                        publicView ? '' : ''
                    } md:pt-0 lg:overflow-visible2 top-0 h-full w-full md:w-96 border-glass-75 tour-exp-step1 bg-gray-50 shadow-images transition duration-500 ease-in-out transform ${
                        menuIsOpen ? 'translate-x-0' : handleRowReverse(rtl).menuTranslate
                    }`}
                >
                    <div className='flex flex-col tour-left-nav '>
                        {publicView ? (
                            <div
                                className={`rounded-b-3xl-t w-full 
                               flex
                             items-center justify-center h-20 gap-3 shadow-cards bg-green-400 bg-white-t ${handleRowReverse(rtl).flex}`}
                            >
                                <KLogoIcon className='opacity-90a mix-blend-multiplya' />
                                <KLogoText className='opacity-80a mix-blend-multiply' />
                                <div className='rounded-full bg-white px-1 text-xxs d-hdpi-2:text-vw-xxs d-hdpi-2:px-vw-1 d-hdpi-2:h-vw-4 h-4 flex items-center mt-1.5 d-hdpi-2:mt-vw-1.5 -ml-1'>
                                    
                                </div>
                            </div>
                        ) : (
                            <div
                                className={`rounded-b-3xl-t w-full 
                           flex
                         items-center justify-center h-20 gap-3 shadow-cards bg-green-400 bg-white-t ${handleRowReverse(rtl).flex}`}
                            >
                                <KLogoIcon className='opacity-90a mix-blend-multiplya' />
                                <KLogoText className='opacity-80a mix-blend-multiply' />
                                <div className='rounded-full bg-white px-1 text-xxs d-hdpi-2:text-vw-xxs d-hdpi-2:px-vw-1 d-hdpi-2:h-vw-4 h-4 flex items-center mt-1.5 d-hdpi-2:mt-vw-1.5 -ml-1'>
                                    
                                </div>
                            </div>
                        )}

                        <div className='relative'>
                            <div className='px-8 pt-4'>
                                {!featuredImage && (
                                    <div className='text-xs whitespace-nowrap tracking-widest uppercase absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 -mt-4'>
                                        <Icons iName='ADDIMAGE' />
                                    </div>
                                )}
                                <div
                                    className={`${
                                        featuredImage ? 'shadow-images' : 'border-4 border-green-400 border-dotted'
                                    }  tour-featured overflow-hidden rounded-xl h-48 relative`}
                                >
                                    {featuredImage && (
                                        <>
                                            <Image
                                                size='360'
                                                src={featuredImage}
                                                className='h-full w-full object-cover object-center'
                                                fixEdge={true}
                                                groupScope={true}
                                            >
                                                {isAdminStaff && <KreatorBadge author={author} baseUrl={baseUrl} nolink fixEdge={true} />}
                                                {edit && !isFeedbackMode && <ButtonsClose rtl={rtl} type='image' handleClose={handleDeleteImage} />}
                                            </Image>
                                        </>
                                    )}
                                    {edit && !featuredImage && isOnline && (
                                        <span className='text-sm w-full mt-6 h-full flex tracking-widest uppercase justify-center items-center '>
                                            <UploadGalleryImages multiple={false} handleUpdate={handleImageUpdate} />
                                        </span>
                                    )}
                                </div>
                                <div className='text-xxs whitespace-nowrap tracking-widest uppercase absolute bottom-0 bg-gray-900 text-green-400 rounded-full px-4 py-2 left-1/2 transform -translate-x-1/2 translate-y-1/2'>
                                    {type.toUpperCase()}
                                </div>
                            </div>
                            {/* <FeedbackBox /> */}
                        </div>
                        <div className='px-8 py-3 mt-8 mb-4 flex items-center justify-center'>
                            {/* {false && edit ? (
                                <button className='hover:bg-green-100 px-4 py-1 rounded-lg focus:outline-none outline-none'>
                                    {experience.overview_intro.title ||
                                        'Add Exciting Title'}
                                </button>
                            ) : (
                                <span>
                                    {experience.overview_intro.title ||
                                        'Add Exciting Title'}
                                </span>
                            )} */}
                            <GenericTitle
                                nohover
                                actionType={_INTRO_OVERVIEW}
                                dayIndex={null}
                                index={null}
                                titleTxt={titleTxt}
                                defaultTxt={'Add Exciting Title'}
                                // hintObj={selIntroObj.titleHint}
                                placeholder={introObj.titleModalPlaceholder}
                                // icon={uiStruct.itinerary.intro.uiEdit.modals.title.icon}
                                tour=''
                                // objId={objId?.title}
                                className=''
                                noStyling={true}
                            />
                        </div>

                        <nav
                            className={`relative flex-1 h-full mt-4 bg-gradient-to-t from-gray-50 to-white rounded-t-3xl shadow-cards-top pt-12 transform transition duration-500 ${
                                move ? '-translate-y-72' : 'translate-y-0'
                            }`}
                        >
                            {/* <button
                                className='-mt-6 pb-4 focus:outline-none flex items-center justify-center w-full'
                                onClick={() => setMove(!move)}
                            >
                                <div className='w-12 h-1 bg-gray-200 rounded-full '></div>
                            </button> */}

                            {edit && !isFeedbackMode && (
                                <div className='absolute tour-add-nav top-0 -translate-y-1/2 left-1/2 transform -translate-x-1/2'>
                                    <CollapseButton
                                        disabled={!isOnline}
                                        alwaysOn
                                        labelHover='Add a day'
                                        handleClick={handleCreateDay}
                                        icon='ri-add-line text-2xl'
                                        size='10'
                                        textSize='text-xs'
                                        sizeHover='w-32'
                                        offsetCenter='1'
                                        padding='px-4'
                                        btnColor='bg-green-400 hover:bg-gray-800 text-gray-900 text-opacity-80 hover:text-white'
                                    />
                                </div>
                            )}
                            <div className='hidden xl:flex px-10 mb-4 tour-arrow-nav  flex-row items-center gap-4 justify-between'>
                                <div className='flex items-center flex-1 gap-4'>
                                    <div className='flex-1'>
                                        <ListBoxGeneric
                                            disabled={!isOnline}
                                            listData={daysList}
                                            val={selectedDay}
                                            handleChange={handleSelectDropdown}
                                            textClass={'text-sm'}
                                            useRouting
                                            height='h-9'
                                        />
                                    </div>
                                </div>
                                <ButtonsGroupNextPrev
                                    disabled={!isOnline}
                                    nextAction={setNavDaysNext}
                                    isNext={daysNav.next}
                                    prevAction={setNavDaysPrev}
                                    isPrev={daysNav.prev}
                                    className={`z-50`}
                                    rtl={rtl}
                                    alwaysOn
                                />
                            </div>
                            <div
                                // style={{ direction: rtl ? 'ltr' : 'rtl' }}
                                className='h-max lg:h-screen-2/5-5 xl:h-screen-1/2-5 overflow-y-scroll pb-20 md:pb-36'
                            >
                                {children}
                            </div>
                        </nav>
                    </div>
                </div>
                {newDayModalStatus && (
                    <ConfirmModal
                        confirmText={translations[lang][uiStruct.ui.modals.OK.description]}
                        icon={{ name: 'CALENDAR' }}
                        actionBtnObj={uiStruct.ui.modals.OK.buttons.action}
                        cancelBtnObj={uiStruct.ui.modals.OK.buttons.cancel}
                        setModalIsOpen={setNewDayModalStatus}
                        handleActionBtn={confirmNewDay}
                    />
                )}
                {delConfirmModalState && <ConfirmModal setModalIsOpen={setDelConfirmModalState} handleActionBtn={confirmDelete} />}
            </div>
        </div>
    );
};

const NS_LayoutExperiencePurchasedSidebarOverview = ({
    publicView,
    experienceDetails,
    experienceDetails: { experience, type },
    globalState: { lang, edit, menuRefsLoading },
    handleScroll,
    sections,
    visibleSection,
    menuIsOpen,
    handleMenuIsOpen
}) => {
    const rtl = !!translations[lang].rtl;
    const {
        budget: { isVisible: budgetVisible = true },
        best_time_to_go: { isVisible: bestTimeToGoVisible = true },
        getting_there: { isVisible: gettingThereVisible = true },
        moving_around_visible: { isVisible: movingAroundVisible = false },
        local_currency_visible: { isVisible: localCurrencyVisible = true },
        general_tips_visible: { isVisible: generalTipsVisible = true },
        accommodation: { isVisible: accommodationVisible = true }
    } = experience;

    const [isHiddenMovingAround, setIsHiddenMovingAround] = useState(true);
    const [isHiddenItinerary, setIsHiddenItinerary] = useState(false);

    return (
        <>
            {!menuRefsLoading && (
                <>
                    <NavigationSection
                        publicView={publicView}
                        rtl={rtl}
                        title={translations[lang][uiStruct.overview.intro.uiPreview.menuTitle]}
                        handleScroll={handleScroll}
                        visibleSection={visibleSection}
                        hasData={true}
                        completion={getOverviewCompletion(experience, experienceDetails).intro}
                        menuIsOpen={menuIsOpen}
                        handleMenuIsOpen={handleMenuIsOpen}
                        scrollRef='ovIntro'
                    />

                    {type.toLowerCase() === 'digital' ? (
                        <div className={`${!edit && !budgetVisible ? 'hidden' : ''}`}>
                            <NavigationSection
                                publicView={publicView}
                                rtl={rtl}
                                title={'Budget'}
                                scrollRef='ovBudget'
                                isMenuItemVisible={budgetVisible}
                                handleScroll={handleScroll}
                                visibleSection={visibleSection}
                                hasData={true}
                                completion={getOverviewCompletion(experience, experienceDetails).budget}
                                menuIsOpen={menuIsOpen}
                                handleMenuIsOpen={handleMenuIsOpen}
                            />
                        </div>
                    ) : null}

                    <NavigationSection
                        publicView={publicView}
                        rtl={rtl}
                        title={translations[lang][uiStruct.overview.destination.uiPreview.menuTitle]}
                        handleScroll={handleScroll}
                        visibleSection={visibleSection}
                        hasData={true}
                        completion={getOverviewCompletion(experience, experienceDetails).destination}
                        menuIsOpen={menuIsOpen}
                        handleMenuIsOpen={handleMenuIsOpen}
                        scrollRef='ovDestination'
                    />
                    <div className={`${!edit && !bestTimeToGoVisible ? 'hidden' : ''}`}>
                        <NavigationSection
                            publicView={publicView}
                            rtl={rtl}
                            title={translations[lang][uiStruct.overview.when_to_go.uiPreview.menuTitle]}
                            handleScroll={handleScroll}
                            visibleSection={visibleSection}
                            isMenuItemVisible={bestTimeToGoVisible}
                            hasData={true}
                            completion={getOverviewCompletion(experience, experienceDetails).whenToGo}
                            menuIsOpen={menuIsOpen}
                            handleMenuIsOpen={handleMenuIsOpen}
                            scrollRef='ovWhenToGo'
                        />
                    </div>
                    <div className={`${!edit && !gettingThereVisible ? 'hidden' : ''}`}>
                        <NavigationSection
                            publicView={publicView}
                            rtl={rtl}
                            title={translations[lang][uiStruct.overview.getting_there.uiPreview.menuTitle]}
                            handleScroll={handleScroll}
                            visibleSection={visibleSection}
                            isMenuItemVisible={gettingThereVisible}
                            hasData={true}
                            completion={getOverviewCompletion(experience, experienceDetails).gettingThere}
                            menuIsOpen={menuIsOpen}
                            handleMenuIsOpen={handleMenuIsOpen}
                            scrollRef='ovGettingThere'
                        />
                    </div>
                    <div className={`${!edit && !accommodationVisible ? 'hidden' : ''}`}>
                        <NavigationSection
                            publicView={publicView}
                            rtl={rtl}
                            title={translations[lang][uiStruct.overview.accommodation.uiPreview.menuTitle]}
                            handleScroll={handleScroll}
                            visibleSection={visibleSection}
                            isMenuItemVisible={accommodationVisible}
                            hasData={true}
                            completion={getOverviewCompletion(experience, experienceDetails).accommodation}
                            menuIsOpen={menuIsOpen}
                            handleMenuIsOpen={handleMenuIsOpen}
                            scrollRef='ovAccommodation'
                        />
                    </div>
                    <div className={'hidden'}>
                        <NavigationSection
                            publicView={publicView}
                            rtl={rtl}
                            title={translations[lang][uiStruct.overview.moving_around.uiPreview.menuTitle]}
                            handleScroll={handleScroll}
                            visibleSection={visibleSection}
                            hasData={true}
                            nominimize={experience.moving_around.length > 0 && movingAroundVisible ? false : true}
                            hidden={isHiddenMovingAround}
                            isMenuItemVisible={movingAroundVisible}
                            handleMinimize={setIsHiddenMovingAround}
                            completion={getOverviewCompletion(experience, experienceDetails).movingAround}
                            menuIsOpen={menuIsOpen}
                            handleMenuIsOpen={handleMenuIsOpen}
                            scrollRef='ovMovingAround'
                        />
                        {false && (
                            <LayoutNavList
                                visibleSection={visibleSection}
                                handleMenuIsOpen={handleMenuIsOpen}
                                handleScroll={handleScroll}
                                publicView={publicView}
                                lang={lang}
                                rtl={rtl}
                                edit={edit}
                                dayIndex={0}
                                navType={'movingAroundOverview'}
                            />
                        )}
                    </div>
                    <div className={`${!edit && !localCurrencyVisible ? 'hidden' : ''}`}>
                        <NavigationSection
                            publicView={publicView}
                            rtl={rtl}
                            title={translations[lang][uiStruct.overview.currency_paying.uiPreview.menuTitle]}
                            handleScroll={handleScroll}
                            visibleSection={visibleSection}
                            isMenuItemVisible={localCurrencyVisible}
                            hasData={true}
                            completion={getOverviewCompletion(experience, experienceDetails).currency}
                            menuIsOpen={menuIsOpen}
                            handleMenuIsOpen={handleMenuIsOpen}
                            scrollRef='ovCurrency'
                        />
                    </div>
                    <div className={`${!edit && !generalTipsVisible ? 'hidden' : ''}`}>
                        <NavigationSection
                            publicView={publicView}
                            rtl={rtl}
                            title={translations[lang][uiStruct.overview.general_tips.uiPreview.menuTitle]}
                            handleScroll={handleScroll}
                            visibleSection={visibleSection}
                            isMenuItemVisible={generalTipsVisible}
                            hasData={true}
                            completion={getOverviewCompletion(experience, experienceDetails).generalTips}
                            menuIsOpen={menuIsOpen}
                            handleMenuIsOpen={handleMenuIsOpen}
                            scrollRef='ovTips'
                        />
                    </div>
                    <NavigationSection
                        publicView={publicView}
                        rtl={rtl}
                        title={translations[lang][uiStruct.overview.itinerary.uiPreview.menuTitle]}
                        handleScroll={handleScroll}
                        visibleSection={visibleSection}
                        hasData={experience.itinerary.trip_days.length > 0 ? true : false}
                        nominimize={experience.itinerary.trip_days.length > 0 ? false : true}
                        hidden={isHiddenItinerary}
                        handleMinimize={setIsHiddenItinerary}
                        completion={getOverviewCompletion(experience, experienceDetails).itinerary}
                        menuIsOpen={menuIsOpen}
                        handleMenuIsOpen={handleMenuIsOpen}
                        scrollRef='ovItinerary'
                    />
                    {!isHiddenItinerary && (
                        <LayoutNavList
                            visibleSection={visibleSection}
                            handleMenuIsOpen={handleMenuIsOpen}
                            handleScroll={handleScroll}
                            publicView={publicView}
                            lang={lang}
                            rtl={rtl}
                            edit={edit}
                            dayIndex={0}
                            navType={'daysOverview'}
                        />
                    )}
                    {type.toLowerCase() === 'digital' ? (
                        <NavigationSection
                            publicView={publicView}
                            rtl={rtl}
                            title={'Thank you note'}
                            handleScroll={handleScroll}
                            visibleSection={visibleSection}
                            hasData={true}
                            completion={getOverviewCompletion(experience, experienceDetails).thankYou}
                            menuIsOpen={menuIsOpen}
                            handleMenuIsOpen={handleMenuIsOpen}
                            scrollRef='mkThankyou'
                        />
                    ) : null}
                </>
            )}
        </>
    );
};

const NS_LayoutExperiencePurchasedSidebarMarketing = ({
    publicView,
    experienceDetails,
    experienceDetails: { experience, type, menuRefsLoading },
    globalState: { lang },
    handleScroll,
    // sections,
    visibleSection,
    menuIsOpen,
    handleMenuIsOpen
}) => {
    const rtl = !!translations[lang].rtl;

    // const [isHiddenMovingAround, setIsHiddenMovingAround] = useState(false);
    // const [isHiddenItinerary, setIsHiddenItinerary] = useState(false);

    return (
        <>
            {!menuRefsLoading && (
                <>
                    <NavigationSection
                        publicView={publicView}
                        rtl={rtl}
                        title={'The Gallery'}
                        handleScroll={handleScroll}
                        visibleSection={visibleSection}
                        hasData={true}
                        completion={getMarketingCompletion(experienceDetails).marketingGallery}
                        menuIsOpen={menuIsOpen}
                        handleMenuIsOpen={handleMenuIsOpen}
                        scrollRef='mkGallery'
                    />

                    <NavigationSection
                        publicView={publicView}
                        rtl={rtl}
                        title={'Overview'}
                        handleScroll={handleScroll}
                        visibleSection={visibleSection}
                        hasData={true}
                        completion={getMarketingCompletion(experienceDetails).marketingIntro}
                        menuIsOpen={menuIsOpen}
                        handleMenuIsOpen={handleMenuIsOpen}
                        scrollRef='mkOverview'
                    />

                    <NavigationSection
                        publicView={publicView}
                        rtl={rtl}
                        title={'The Destination'}
                        handleScroll={handleScroll}
                        visibleSection={visibleSection}
                        hasData={true}
                        completion={getMarketingCompletion(experienceDetails).marketingWhatToDo}
                        menuIsOpen={menuIsOpen}
                        handleMenuIsOpen={handleMenuIsOpen}
                        scrollRef='mkWhatYouDo'
                    />

                    {type.toLowerCase() === 'digital' && false && (
                        <div className={`${!experience.accommodation.isVisible ? 'hidden' : ''}`}>
                            <NavigationSection
                                publicView={publicView}
                                rtl={rtl}
                                title={"Where you'll stay"}
                                handleScroll={handleScroll}
                                visibleSection={visibleSection}
                                hasData={true}
                                completion={getMarketingCompletion(experienceDetails).marketingWhereToStay}
                                menuIsOpen={menuIsOpen}
                                handleMenuIsOpen={handleMenuIsOpen}
                                scrollRef='mkWhereStay'
                            />
                        </div>
                    )}

                    <NavigationSection
                        publicView={publicView}
                        rtl={rtl}
                        title={'Itinerary'}
                        handleScroll={handleScroll}
                        visibleSection={visibleSection}
                        hasData={experience.itinerary.trip_days.length > 0 ? true : false}
                        completion={getOverviewCompletion(experience, experienceDetails).itinerary}
                        menuIsOpen={menuIsOpen}
                        handleMenuIsOpen={handleMenuIsOpen}
                        scrollRef='mkItinerary'
                    />

                    {type.toLowerCase() === 'guided' ? (
                        <NavigationSection
                            publicView={publicView}
                            rtl={rtl}
                            title={"What's included?"}
                            handleScroll={handleScroll}
                            visibleSection={visibleSection}
                            hasData={true}
                            completion={getMarketingCompletion(experienceDetails).marketingIncludes}
                            menuIsOpen={menuIsOpen}
                            handleMenuIsOpen={handleMenuIsOpen}
                            scrollRef='mkGuided'
                        />
                    ) : null}
                    {type.toLowerCase() === 'guided' ? (
                        <NavigationSection
                            publicView={publicView}
                            rtl={rtl}
                            title={'Policies'}
                            handleScroll={handleScroll}
                            visibleSection={visibleSection}
                            hasData={true}
                            completion={getMarketingCompletion(experienceDetails).marketingPolicies}
                            menuIsOpen={menuIsOpen}
                            handleMenuIsOpen={handleMenuIsOpen}
                            scrollRef='mkPolicies'
                        />
                    ) : null}
                    {type.toLowerCase() === 'digital' && false ? (
                        <NavigationSection
                            publicView={publicView}
                            rtl={rtl}
                            title={'Thank you note'}
                            handleScroll={handleScroll}
                            visibleSection={visibleSection}
                            hasData={true}
                            completion={getMarketingCompletion(experienceDetails).marketingThankYou}
                            menuIsOpen={menuIsOpen}
                            handleMenuIsOpen={handleMenuIsOpen}
                            scrollRef='mkThankyou'
                        />
                    ) : null}
                    <NavigationSection
                        publicView={publicView}
                        rtl={rtl}
                        title={'Categories & Tags'}
                        handleScroll={handleScroll}
                        visibleSection={visibleSection}
                        hasData={true}
                        completion={getMarketingCompletion(experienceDetails).marketingCats}
                        menuIsOpen={menuIsOpen}
                        handleMenuIsOpen={handleMenuIsOpen}
                        scrollRef='mkTags'
                    />
                </>
            )}
        </>
    );
};

const NS_LayoutExperiencePurchasedSidebarDay = ({
    experienceDetails: { experience, type },
    publicView,
    globalState: { lang, edit, menuRefsLoading },
    handleScroll,
    sections,
    visibleSection,
    dayIndex,
    menuIsOpen,
    handleMenuIsOpen
}) => {
    const whatYouNeedVisible = experience?.itinerary?.trip_days[dayIndex]?.what_you_need?.isVisible;

    const rtl = !!translations[lang].rtl;
    const [isHiddenMovingAround, setIsHiddenMovingAround] = useState(false);
    const [isHiddenActivities, setIsHiddenActivities] = useState(false);

    return (
        <>
            {!menuRefsLoading && (
                <>
                    <NavigationSection
                        publicView={publicView}
                        rtl={rtl}
                        title={translations[lang][uiStruct.itinerary.intro.uiPreview.menuTitle]}
                        handleScroll={handleScroll}
                        visibleSection={visibleSection}
                        hasData={true}
                        completion={getCompletion(experience.itinerary.trip_days[dayIndex]?.day_intro)}
                        menuIsOpen={menuIsOpen}
                        handleMenuIsOpen={handleMenuIsOpen}
                        scrollRef='dayIntro'
                    />

                    <div className={!edit && !whatYouNeedVisible ? 'hidden' : ''}>
                        <NavigationSection
                            publicView={publicView}
                            rtl={rtl}
                            title={translations[lang][uiStruct.itinerary.what_you_need.uiPreview.menuTitle]}
                            handleScroll={handleScroll}
                            visibleSection={visibleSection}
                            isMenuItemVisible={whatYouNeedVisible}
                            hasData={Object.keys(experience.itinerary.trip_days[dayIndex]?.what_you_need).length > 0 ? true : false}
                            completion={getCompletion({
                                description_html: experience.itinerary.trip_days[dayIndex]?.what_you_need.description_html,
                                checklist: experience.itinerary.trip_days[dayIndex]?.what_you_need.checklist
                            })}
                            menuIsOpen={menuIsOpen}
                            handleMenuIsOpen={handleMenuIsOpen}
                            scrollRef='dayWhatYouNeed'
                        />
                    </div>

                    <NavigationSection
                        publicView={publicView}
                        rtl={rtl}
                        title={translations[lang][uiStruct.itinerary.activities.uiPreview.menuTitle]}
                        handleScroll={handleScroll}
                        visibleSection={visibleSection}
                        hasData={true}
                        nominimize={
                            (type.toLowerCase() === 'guided' && experience?.itinerary?.trip_days?.[dayIndex]?.activities?.length < 2) ||
                            (type.toLowerCase() !== 'guided' && experience?.itinerary?.trip_days?.[dayIndex]?.activities?.length === 0)
                        }
                        // nominimize={
                        //     experience.itinerary.trip_days[dayIndex]?.activities
                        //         ?.length > 0 && type.toLowerCase() !== 'guided'
                        //         ? false
                        //         : true
                        // }
                        hidden={isHiddenActivities}
                        handleMinimize={setIsHiddenActivities}
                        completion={getAllActivitiesCompletion(experience.itinerary.trip_days[dayIndex]?.activities)}
                        menuIsOpen={menuIsOpen}
                        handleMenuIsOpen={handleMenuIsOpen}
                        scrollRef='dayActivities'
                    />

                    {!isHiddenActivities &&
                        (type.toLowerCase() !== 'guided' ||
                            (type.toLowerCase() === 'guided' && experience?.itinerary?.trip_days?.[dayIndex]?.activities?.length > 1)) && (
                            <LayoutNavList
                                visibleSection={visibleSection}
                                handleMenuIsOpen={handleMenuIsOpen}
                                handleScroll={handleScroll}
                                publicView={publicView}
                                lang={lang}
                                rtl={rtl}
                                edit={edit}
                                dayIndex={dayIndex}
                                navType={'activity'}
                            />
                        )}
                </>
            )}
        </>
    );
};

const NS_NavigationSection = (props) => {
    const {
        publicView,
        rtl,
        title,
        handleScroll,
        // refs,
        // id,
        visibleSection,
        handleMinimize,
        hidden = false,
        nominimize = true,
        hasData = false,
        completion = 0,
        menuIsOpen,
        handleMenuIsOpen,
        scrollRef,
        isMenuItemVisible = true,
        globalState: { edit }

        // auth: {
        //     user: { role: userRole, _id: userId }
        // }
    } = props;

    let userRole = null;
    let isAdminStaff = false;

    if (!publicView) {
        userRole = props.auth.user.role;
        isAdminStaff = userRole === process.env.REACT_APP_STRAPI_ADMIN_ROLE_ID || userRole === process.env.REACT_APP_STRAPI_ADMIN_ROLE_ID;
    }

    const [width, height] = useWindowSize();

    const handleBtnClick = (theRef) => {
        handleScroll(theRef, width);
        if (width < 1280) handleMenuIsOpen(false);
    };

    const { ref } = MenuRefsHolder.sectionsMainNav[scrollRef] || { ref: null };

    return (
        <div
            className={`tour-nav-section relative flex px-6 md:px-10 py-1 `}
            // style={{ direction: rtl ? 'rtl' : 'ltr' }}
        >
            <button
                onClick={() => handleBtnClick(ref)}
                className={`border border-transparent hover:border-green-400 ring-4 ring-transparent hover:ring-green-200 hover:bg-green-50 transition-all duration-200 ease-in-out rounded-xl flex-1 justify-between flex items-center outline-none focus:outline-none pl-4 pr-4 md:pl-8 md:pr-4
                    py-3 ${visibleSection === scrollRef ? 'bg-green-100 shadow-2xl-green-400a' : ''}`}
            >
                <span
                    className={`${
                        visibleSection === scrollRef
                            ? 'text-green-600 mix-blend-multiply'
                            : 'bg-clip-text text-transparent bg-gradient-to-tr from-gray-900 via-blue-500 to-green-400 '
                    } py-1 leading-none font-bold ${hasData ? '' : 'opacity-30'}`}
                >
                    {title}
                </span>
                {edit && (
                    <>
                        {isMenuItemVisible ? (
                            <ProgressMenu completion={completion} isAdminStaff={isAdminStaff} />
                        ) : (
                            <span className='w-3.5 h-3.5 flex items-center justify-center'>
                                <i className='ri-eye-close-line text-green-800 text-xl'></i>
                            </span>
                        )}
                    </>
                )}
            </button>

            {!nominimize && (
                <div className={`z-50 absolute transform top-1/2 -translate-y-1/2 ${handleRowReverse(rtl).right}-2 `}>
                    <button
                        onClick={() => handleMinimize(!hidden)}
                        className={`focus:outline-none bg-gray-100 rounded-lg py-2 flex items-start justify-center sm:bg-transparent`}
                    >
                        <Icons
                            iName={`ARROW_${hidden ? handleRowReverse(rtl).RIGHT : 'DOWN'}_CHEVRON`}
                            size='xl'
                            iClasses='text-gray-900 opacity-50 sm:text-gray-900 sm:opacity-30 hover:opacity-100'
                        />
                    </button>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateFeatureImage, createItineraryDay }, dispatch);
}

const LayoutExperiencePurchasedSidebarDay = connect(mapStateToProps, mapDispatchToProps)(NS_LayoutExperiencePurchasedSidebarDay);
const LayoutExperiencePurchasedSidebarOverview = connect(mapStateToProps)(NS_LayoutExperiencePurchasedSidebarOverview);
const LayoutExperiencePurchasedSidebarMarketing = connect(mapStateToProps)(NS_LayoutExperiencePurchasedSidebarMarketing);

const NavigationSection = connect(mapStateToProps, mapDispatchToProps)(NS_NavigationSection);

export { LayoutExperiencePurchasedSidebarOverview, LayoutExperiencePurchasedSidebarDay, LayoutExperiencePurchasedSidebarMarketing, NavigationSection };

export default connect(mapStateToProps, mapDispatchToProps)(LayoutExperiencePurchasedSidebar);
