/* eslint-disable arrow-body-style */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { useState } from 'react';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic';
import { Section__Title } from 'components/blocks/Sections';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import OnboardingSection from 'components/specialty/OnboardingSection';
import GenericDescription from 'components/blocks/GenericDescription';
import CardXl from 'components/blocks/CardXl';
import { PillContainer, PillIcon, PillTitle } from 'components/blocks/Pills';
import DestinationList from 'components/blocks/DestinationList';
import DestinationMap from 'components/map/DestinationMap';
import TipList from 'components/blocks/TipList';

function SectionDestinations(props) {
    const {
        actionType,
        experienceDetails: { experience },

        globalState: { lang, edit, isPrintMode, isFeedbackMode }
    } = props;

    const rtl = !!translations[lang].rtl;
    const destinationObj = experience.destination;
    const hintObj = uiStruct.overview.destination.uiPreview.hint;
    const hintObjEmpty = uiStruct.overview.destination.uiPreview.hintEmpty;
    // const hintObjModal = uiStruct.overview.destination.uiEdit.modals.type.hint;
    const [isMinimized, setIsMinimized] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleEditlocations = () => {
        setShowModal(() => !showModal);
    };

    return (
        <div className='w-full touch:-mt-24 touch:py-10'>
            <>
                <Section__Title
                    title={translations[lang][uiStruct.overview.destination.uiPreview.headTitle]}
                    hasData
                    hintObj={hintObj}
                    isVisible={true}
                    className={'-mb-4 lg:-mb-8'}
                />
                <OnboardingSection visible data={hintObjEmpty} />
            </>
            <CardXl
                isFeedbackMode={isFeedbackMode}
                objId={experience?.destination?.objId?.section}
                isPrintMode={isPrintMode}
                rtl={rtl}
                hidden={isMinimized}
                handleMinimize={() => setIsMinimized(!isMinimized)}
            >
                {/* <PillContainer rtl={rtl}>
                    <PillIcon
                        icon={
                            uiStruct.overview.destination.uiPreview.icon.name
                            // uiStruct.overview.getting_there
                        }
                    />
                    <PillTitle
                        title={
                            translations[lang][
                                uiStruct.overview.destination.uiPreview.subTitle
                            ]
                        }
                    />
                </PillContainer> */}
                <div className={`${isMinimized ? 'hidden' : ''}`}>
                    <GenericDescription
                        actionType={actionType}
                        descriptionHtml={destinationObj.description_html}
                        defaultHtml={translations[lang][uiStruct.overview.destination.uiPreview.descriptionEmpty]}
                        objId={experience?.destination?.objId?.description}
                        title='Destinations'
                        subtitle='Description'
                    />

                    <DestinationMap handleEditlocations={handleEditlocations} destinations={destinationObj.locations} />

                    <DestinationList showModal={showModal} actionType={actionType} destinations={destinationObj.locations} />

                    <TipList
                        // max
                        objId={experience?.destination?.objId?.tiplist}
                        actionType={actionType}
                        tips={destinationObj.tips_html}
                        dayIndex={null}
                        index={null}
                        offset='12'
                    />
                </div>
            </CardXl>
        </div>
    );
}

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            experienceDetailsAction
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionDestinations);
