import React, { useRef } from 'react';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

const setRangeData = {
    marks: {
        0: '12AM',
        120: '2AM',
        240: '4AM',
        360: '6AM',
        480: '8AM',
        600: '10AM',
        720: '12PM',
        840: '2PM',
        960: '4PM',
        1080: '6PM',
        1200: '8PM',
        1320: '10PM',
        1440: '12AM'
    },
    rangeMin: 0,
    rangeMax: 1440
};

const TimeToGoRange = ({ defaultValue, min, max, onChange, isReverse }) => {
    // const setRangeData = () => {
    //     const marks = {
    //         0: '12AM',
    //         120: '2AM',
    //         240: '4AM',
    //         360: '6AM',
    //         480: '8AM',
    //         600: '10AM',
    //         720: '12PM',
    //         840: '2PM',
    //         960: '4PM',
    //         1080: '6PM',
    //         1200: '8PM',
    //         1320: '10PM',
    //         1440: '12AM'
    //     };

    //     return {
    //         marks,
    //         rangeMin: 0,
    //         rangeMax: 1440
    //     };
    // };
    const rangeData = useRef(setRangeData);

    return (
        <div className='pb-8'>
            <Range
                trackStyle={[{ background: isReverse ? '#A7F3D0' : '#34D399' }]}
                railStyle={{
                    background: !isReverse ? '#A7F3D0' : '#34D399'
                }}
                activeDotStyle={{
                    borderColor: isReverse ? '#A7F3D0' : '#34D399'
                }}
                dotStyle={{
                    borderColor: !isReverse ? '#A7F3D0' : '#34D399'
                }}
                handleStyle={[{}]}
                step={30}
                min={0}
                max={1440}
                defaultValue={defaultValue}
                allowCross={true}
                onAfterChange={onChange}
                marks={rangeData.current.marks}
            />
        </div>
    );
};

export default TimeToGoRange;
