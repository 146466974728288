import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import translations from 'constants/translations';

import BuyingCard from 'components/blocks/BuyingCard';
import BookingCard from 'components/blocks/BookingCard';

function SectionPricingBooking({
    experienceDetails: { experience_price, type },
    globalState: { lang, edit }
}) {
    const rtl = !!translations[lang].rtl;

    return (
        <div className=''>
            {type === 'GUIDED' ? (
                <BookingCard
                    price={experience_price?.price?.price}
                    desc='Book Your experience Today!'
                />
            ) : (
                <BuyingCard
                    price={experience_price?.price?.price}
                    desc='For a limited time only, you can try our experiences for free!'
                />
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SectionPricingBooking);
