import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ListBoxGeneric from 'components/blocks/ListBoxGeneric';
import CardXlEditDelete from 'components/blocks/CardXlEditDelete';
import { handleRowReverse, urlArrLength } from 'helpers/FEutils';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { BlockHTML, BlockInputSingle } from 'components/blocks/Blocks';
import { ModalButton } from 'components/blocks/ModalTre';
import { _GETTING_THERE, _ACTIVITIES } from 'store/actions/types';

function CheckListLinkTitle({
    noDelete = false,
    single = false,
    data,
    updateCheckListUrls,
    deleteCheckList,
    addCheckList,
    validationObj,
    saveAttempted,
    globalState: { lang }
}) {
    const rtl = !!translations[lang].rtl;

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        if (name === 'title') {
            updateCheckListUrls('title', index, name, value);
        } else {
            updateCheckListUrls('url', index, name, value);
        }
    };

    const buildUrls = () => {
        return data.map((obj, index) => {
            const key = `vendor_${index}`;
            return (
                <li
                    key={key}
                    className={`relative w-full items-center justify-between ${
                        handleRowReverse(rtl).pr
                    }-4  flex flex-col md:flex-row md:${
                        handleRowReverse(rtl).flex
                    } flex-wrap md:flex-nowrap  mb-4 
                    `}
                >
                    {!noDelete && (
                        <CardXlEditDelete
                            handleDelete={() => {
                                deleteCheckList(index);
                            }}
                            rtl={rtl}
                        />
                    )}
                    <div
                        className={`flex w-full flex-col gap-4 md:flex-row md:${
                            handleRowReverse(rtl).flex
                        } ${
                            handleRowReverse(rtl).pr
                        }-10 flex-wrap md:flex-nowrap  md:items-center
                             `}
                    >
                        <BlockInputSingle
                            error={
                                saveAttempted &&
                                validationObj[index].title.error
                            }
                            className='text-xs'
                            height='h-10'
                            width={`w-full md:w-full`}
                            rounded='rounded-lg'
                            handleChange={(e) => handleChange(e, index)}
                            id={`title${index}`}
                            name='title'
                            value={obj.title}
                            placeholder={
                                translations[lang][
                                    uiStruct.itinerary.what_you_need.uiEdit
                                        .modals.checklist.placeholders.label
                                ]
                            }
                        />

                        <BlockInputSingle
                            error={
                                saveAttempted && validationObj[index].url.error
                            }
                            className='text-xs'
                            height='h-10'
                            width={`w-full md:w-full`}
                            rounded='rounded-lg'
                            handleChange={(e) => handleChange(e, index)}
                            id={`url${index}`}
                            name='url'
                            value={obj.url}
                            placeholder={
                                translations[lang][
                                    uiStruct.itinerary.what_you_need.uiEdit
                                        .modals.checklist.placeholders.url
                                ]
                            }
                        />
                    </div>
                </li>
            );
        });
    };

    return (
        <>
            <div className='w-full px-2'>
                <ul>{buildUrls()}</ul>
            </div>

            {!single && (
                <div className='flex justify-center pt-4'>
                    <ModalButton
                        handleClick={addCheckList}
                        icon={{ name: 'PLUS' }}
                        color='plusdark'
                    />
                </div>
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(CheckListLinkTitle);
