import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import VendorsModal from 'components/modals/VendorsModal';
import ConfirmModal from 'components/modals/ConfirmModal';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic.js';
import { CardXlIconTitleList } from 'components/blocks/CardXl';
import * as types from 'store/actions/types';
import { ButtonsAdd } from 'components/blocks/Buttons';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import useDraggableInPortal from 'helpers/useDraggableInPortal';
import { _GETTING_THERE, _ACTIVITIES } from 'store/actions/types';
import { nanoid } from 'nanoid';
import FeedbackBox from './FeedbackBox';

function VendorList({
    index,
    objId,
    dayIndex,
    globalState: { lang, edit, isFeedbackMode },
    experienceDetailsAction,
    vendors,
    modalTitle,
    modalSubTitle,
    actionType,
    btnLabel = translations[lang][uiStruct.ui.buttons.add_link],
    labelTitle = null,
    labelVendors = null,
    labelVendorsPlaceholderName = null,
    labelVendorsPlaceholderUrl = null,
    labelVendorsPlaceholderTitle = null,
    btnOffset = '16',
    icon,
    isVisible = true
}) {
    const renderDraggable = useDraggableInPortal();
    const rtl = !!translations[lang].rtl;
    const [delConfirmModalState, setDelConfirmModalState] = useState(false);
    const [currObjIndex, setCurrObjIndex] = useState(0);
    const [updateObj, setUpdateObj] = useState({});
    const [vendorModalState, setVendorModalState] = useState(false);
    const [updateVendor, setUpdateVendor] = useState(false);
    const isGettingThereType =
        actionType === _GETTING_THERE || actionType === _ACTIVITIES;
    const delVendor = (currObjIndex) => {
        setCurrObjIndex(currObjIndex);
        setDelConfirmModalState(true);
    };

    const editVendor = (currObjIndex) => {
        setVendorModalState(true);
        setCurrObjIndex(currObjIndex);
        setUpdateVendor(true);
        setUpdateObj({
            ...vendors[currObjIndex]
        });
    };

    const addVendor = () => {
        setUpdateVendor(false);
        setVendorModalState(true);
    };

    const updateCreateVendor = (dataObj) => {
        if (!dataObj.objId) {
            dataObj.objId = `vendor_${nanoid()}`;
        }
        if (updateVendor) {
            experienceDetailsAction({
                type: `${types.UPDATE_VENDOR}${actionType}`,
                dayIndex,
                index,
                dataObj,
                vendorIndex: currObjIndex
            });
        } else {
            experienceDetailsAction({
                type: `${types.ADD_VENDOR}${actionType}`,
                dayIndex,
                index,
                dataObj
            });
        }
    };

    const confirmDelete = () => {
        experienceDetailsAction({
            type: `${types.DELETE_VENDOR}${actionType}`,
            dayIndex,
            index,
            vendorIndex: currObjIndex
        });
    };

    const buildVendorSingle = (provided) => {
        return vendors.map((obj, index, arr) => {
            const key = `vr${index}`;
            const text = isGettingThereType
                ? translations[lang][obj.name]
                : obj.name;

            return edit ? (
                <Draggable key={key} draggableId={key} index={index}>
                    {renderDraggable((provided) => (
                        <CardXlIconTitleList
                            objId={obj.objId}
                            index={index}
                            deleteIcon={delVendor}
                            editIcon={editVendor}
                            vendorIndex={index}
                            edit={edit}
                            isFeedbackMode={isFeedbackMode}
                            key={key}
                            name={text}
                            url={obj.url}
                            rtl={rtl}
                            icon='CHECK'
                            bullet='TRAIN'
                            provided={provided}
                        />
                    ))}
                </Draggable>
            ) : (
                <CardXlIconTitleList
                    objId={obj.objId}
                    key={key}
                    name={text}
                    url={obj.url}
                    rtl={rtl}
                    icon='CHECK'
                    bullet='TRAIN'
                    edit={edit}
                    isFeedbackMode={isFeedbackMode}
                />
            );
        });
    };

    const buildVendors = () => {
        return (
            <DragDropContext onDragEnd={vendorDragEnd}>
                <Droppable droppableId='movingAround'>
                    {(provided) => (
                        <ul
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {buildVendorSingle(provided)}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
        );
    };

    const vendorDragEnd = (result) => {
        const { source, destination } = result;

        if (source && destination && source.index !== destination.index) {
            experienceDetailsAction({
                type: `${types.ARRANGE_VENDORS}${actionType}`,
                dayIndex,
                index,
                source: source.index,
                destination: destination.index
            });
        }
    };

    return (
        <div className=''>
            <div className='relative'>
                <FeedbackBox
                    inset='-inset-y-4 -inset-x-8'
                    dot='right-2 -top-3 md:top-1/2 md:-translate-y-1/2 md:-right-16 lg:-right-20'
                    posY='bottom-full'
                    root
                    objId={objId}
                    label='Link List'
                />
                {vendors.length ? buildVendors() : null}
            </div>
            {edit && isVisible && !isFeedbackMode && (
                <div className='flex items-center justify-center w-full'>
                    <ButtonsAdd
                        handleClick={addVendor}
                        label={btnLabel}
                        className={`${rtl ? '' : '-'}translate-x-${btnOffset}`}
                        rtl={rtl}
                        iconOnly={icon}
                    />
                </div>
            )}

            {vendorModalState && (
                <VendorsModal
                    dayIndex={dayIndex}
                    modalTitle={modalTitle}
                    modalSubTitle={modalSubTitle}
                    actionType={actionType}
                    setModalIsOpen={setVendorModalState}
                    updateObj={updateObj}
                    update={updateVendor}
                    handleActionBtn={updateCreateVendor}
                    labels={{
                        labelTitle,
                        labelVendors,
                        labelVendorsPlaceholderTitle,
                        labelVendorsPlaceholderName,
                        labelVendorsPlaceholderUrl
                    }}
                />
            )}

            {delConfirmModalState && (
                <ConfirmModal
                    setModalIsOpen={setDelConfirmModalState}
                    handleActionBtn={confirmDelete}
                />
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            experienceDetailsAction
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorList);
