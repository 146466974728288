import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ConfirmModal from 'components/modals/ConfirmModal';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic.js';
// import { CardXlIconTitleList } from 'components/blocks/CardXl';
// import { ButtonsAdd } from 'componets/blocks/Buttons';
// import uiStruct from 'constants/uiStruct';
import { ButtonsAdd } from 'components/blocks/Buttons';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';

import useDraggableInPortal from 'helpers/useDraggableInPortal';
import Destination from './Destination';
import DestinationModal from 'components/modals/DestinationModal';
import {
    updateDestinations,
    arrangeDesinations,
    updatePlacePlacesList
} from 'store/actions/experienceDetails/destinations';

function DestinationList({
    globalState: { lang, edit, isFeedbackMode },
    experienceDetailsAction,
    btnLabel = translations[lang][
        uiStruct.overview.destination.uiEdit.buttons.addlink.label
    ],
    destinations,
    actionType,
    updateDestinations,
    arrangeDesinations,
    updatePlacePlacesList,
    showModal
}) {
    const didMountRef = useRef(false);
    const renderDraggable = useDraggableInPortal();
    const rtl = !!translations[lang].rtl;
    const [delConfirmModalState, setDelConfirmModalState] = useState(false);
    const [currObjIndex, setCurrObjIndex] = useState(0);
    const [destinationModalState, setDestinationModalState] = useState(false);
    const delDest = (currObjIndex) => {
        setCurrObjIndex(currObjIndex);
        setDelConfirmModalState(true);
    };

    const updateDestinationsList = (dataObj) => {
        updatePlacePlacesList(dataObj);
        updateDestinations(dataObj);
    };

    const confirmDelete = () => {
        updatePlacePlacesList(
            destinations.filter((dest, i) => i !== currObjIndex)
        );
        experienceDetailsAction({
            type: `DELETE${actionType}`,
            index: currObjIndex,
            vendorIndex: currObjIndex
        });
    };

    const updateSingleDestinations = (countryIndex, destIndex) => {
        const tmpDestinations = JSON.parse(JSON.stringify(destinations));
        tmpDestinations[countryIndex].splice(destIndex, 1);
        updateDestinations(tmpDestinations);
    };

    // const parsedDestinations = parseDestinations(destinations, lang);

    // const buildDestinationArray = (destinations) => {
    //     return [...new Set(destinations.map((item) => item.countryCode))].map(
    //         (country) => {
    //             return destinations.filter(
    //                 (dest) => dest.countryCode === country
    //             );
    //         }
    //     );
    // };

    const buildlocationsingle = (provided) => {
        // const filteredDest = buildDestinationArray(destinations);

        return destinations.map((obj, index, arr) => {
            const key = `dest${index}`;

            return (
                <Draggable key={key} draggableId={key} index={index}>
                    {renderDraggable((provided) => (
                        <Destination
                            index={index}
                            handleDeleteBtnAction={delDest}
                            handleClickBtnAction={() =>
                                setDestinationModalState(true)
                            }
                            handleActionBtn={updateSingleDestinations}
                            key={key}
                            destInf={obj}
                            label={'text'}
                            rtl={rtl}
                            icon='CHECK'
                            bullet='TRAIN'
                            provided={provided}
                        />
                    ))}
                </Draggable>
            );
        });
    };

    const buildlocations = () => {
        return (
            <DragDropContext onDragEnd={vendorDragEnd}>
                <Droppable droppableId='movingAround'>
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {buildlocationsingle(provided)}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    };

    const vendorDragEnd = (result) => {
        const { source, destination } = result;

        if (source && destination && source.index !== destination.index) {
            arrangeDesinations(source.index, destination.index);
        }
    };
    useEffect(() => {
        if (didMountRef.current) {
            setDestinationModalState(true);
        } else {
            didMountRef.current = true;
        }
    }, [showModal]);

    return (
        <div className='my-6'>
            <div className=''>
                {destinations.length ? buildlocations() : null}{' '}
            </div>

            {edit && !isFeedbackMode && (
                <div className='flex items-center justify-center w-full'>
                    <ButtonsAdd
                        handleClick={() => setDestinationModalState(true)}
                        label={btnLabel}
                        className={`${rtl ? '' : '-'}translate-x-16`}
                        rtl={rtl}
                        iconOnly='ri-map-pin-add-line'
                    />
                </div>
            )}

            {destinationModalState && (
                <DestinationModal
                    actionType={actionType}
                    setModalIsOpen={setDestinationModalState}
                    handleActionBtn={updateDestinationsList}
                    destinations={destinations}
                />
            )}

            {delConfirmModalState && (
                <ConfirmModal
                    setModalIsOpen={setDelConfirmModalState}
                    handleActionBtn={confirmDelete}
                />
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            experienceDetailsAction,
            updateDestinations,
            arrangeDesinations,
            updatePlacePlacesList
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(DestinationList);
