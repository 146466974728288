import React from 'react';
import { ButtonsClose } from 'components/blocks/Buttons';
import Icons from 'components/blocks/Icons';
const imgWithClick = { cursor: 'pointer' };

const Photo = (props) => {
    const { handleDelete, photo, margin, direction, top, left } = props;
    const imgStyle = { margin: margin };

    if (direction === 'column') {
        imgStyle.position = 'absolute';
        imgStyle.left = left;
        imgStyle.top = top;
    }
    imgStyle.width = 'auto';
    imgStyle.height = 150;

    const handleClick = () => {
        handleDelete(photo);
    };

    return (
        <div className='relative w-40 h-32 p-1' style={{ zIndex: 1200 }}>
            <button
                onClick={handleClick}
                className={`absolute top-3 right-3 rounded-full flex  border-2  border-transparent  w-4 h-4 text-white bg-kn-red hover:bg-gray-900 focus:outline-none  items-center justify-center `}
            >
                -
            </button>

            {/* <button className='absolute top-0' onClick={handleClick}>
                {' '}
                x{' '}
            </button> */}
            {/* <ButtonsClose
                type='thumb'
                handleClose={handleClick}
               
            /> */}

            <img
                className='object-cover w-full h-full overflow-hidden rounded-xl'
                data-blink-src={photo.src}
                alt='img'
            />
            {photo.type === 'youtube' && (
                <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                    <i className='ri-play-fill text-2xl text-white'></i>
                </div>
            )}
        </div>
    );
};

export default Photo;
