const langData = {
    en: {
        rtl: '',
        lang: 'English',
        lang_iso: 'en',
        // Status
        rejected: 'rejected',
        submitted: 'submitted',
        approved: 'approved',
        pending: 'pending',
        initial: 'Not Submitted',
        EXP_LIST_SUBMIT: 'You are about to submit your experience. Are you sure?',
        EXP_LIST_SUBMIT_PS:
            'After submission, you can not edit your experience unless you receive a feedback notification from the admin team. If your submission is approved and you wish to edit it after the approval, you will need to go through a new submission/feedback/approval cycle.',

        EXP_LIST_SUBMIT_PENDING: 'You are about to re-submit your experience for approval based on the feedback. Are you sure?',
        EXP_LIST_SUBMIT_PENDING_PS:
            'Your experience is currently in pending feedback cycle and you wish to re-submit changes based on the provided feedback. After resubmission, you can not edit your experience unless you receive another feedback notification from the admin team. If your resubmission is approved and you wish to edit it after the approval, you will need to go through a new submission/feedback/approval cycle just like you are doing now.',
        EXP_LIST_SUBMIT_APPROVED: 'You are about to re-submit your experience for approval. Are you sure?',
        EXP_LIST_SUBMIT_APPROVED_PS:
            'Your experience is currently approved and you wish to re-submit changes. After resubmission, you can not edit your experience unless you receive a feedback notification from the admin team. If your resubmission is approved and you wish to edit it after the approval, you will need to go through a new submission/feedback/approval cycle just like you are doing now.',
        ACCOMMODATION: 'Accommodation',
        FOOD_BEVERAGE: 'Food and Beverage',
        AIRPORT_TRANSFER_FULL: '2 way airport transfer',
        AIRPORT_TRANSFER_HALF: 'One way airport transfer',
        ACTIVITIES_ALL: 'All activities',
        ACTIVITIES_PARTIAL: 'Part of the activity fees',
        FULL_BOARD: 'Full Board',
        BREAKFAST: 'Breakfast',
        LUNCH: 'Lunch',
        DINNER: 'Dinner',
        SNACKS: 'Snakcs',
        ACTIVITY_GEAR: 'Gear Rental',
        TRANSPORTATION: 'Transportation',
        VENUE_TICKETS: 'Venue Tickets',
        PLANE_TICKET: 'Plane Tickets',
        TRAIN_TICKET: 'Train tickets',
        LOCAL_GUIDE: 'Local Guide',

        'Auth.form.error.invalid': 'Invalid email or password',
        'Auth.form.error.email.taken': 'The Email or username are already taken',
        DEFAULTERROR: "We're having a problem connecting to the server. Try to refresh the page to solve the problem",
        NETWORKERROR: "We're having connectivity problems. Check your Internet connection and try to refresh the page",
        LOGIN_SUCCESS: 'Hello Kreator!',
        REGISTER_SUCCESS: 'Welcome Kreator!',
        PROFILE_SUCCESS: 'Profile Saved',
        FORBIDDEN: "Hmm...it seems you're not allowed to make that request",

        NAVMAIN_KREATIONS: 'My Kreations',
        NAVMAIN_PAYOUT: 'Payout',
        NAVMAIN_PROFILE: 'Profile',
        NAVMAIN_HELP: 'Konnect Academy',
        NAVMAIN_SIGNOUT: 'Sign out',

        // Full Months
        UI_DATE_MONTH_FULL_1: 'January',
        UI_DATE_MONTH_FULL_2: 'February',
        UI_DATE_MONTH_FULL_3: 'March',
        UI_DATE_MONTH_FULL_4: 'April',
        UI_DATE_MONTH_FULL_5: 'May',
        UI_DATE_MONTH_FULL_6: 'June',
        UI_DATE_MONTH_FULL_7: 'July',
        UI_DATE_MONTH_FULL_8: 'August',
        UI_DATE_MONTH_FULL_9: 'September',
        UI_DATE_MONTH_FULL_10: 'October',
        UI_DATE_MONTH_FULL_11: 'November',
        UI_DATE_MONTH_FULL_12: 'December',

        // 3 Letter or short months
        UI_DATE_MONTH_SHORT_1: 'Jan',
        UI_DATE_MONTH_SHORT_2: 'Feb',
        UI_DATE_MONTH_SHORT_3: 'Mar',
        UI_DATE_MONTH_SHORT_4: 'Apr',
        UI_DATE_MONTH_SHORT_5: 'May',
        UI_DATE_MONTH_SHORT_6: 'Jun',
        UI_DATE_MONTH_SHORT_7: 'Jul',
        UI_DATE_MONTH_SHORT_8: 'Aug',
        UI_DATE_MONTH_SHORT_9: 'Sep',
        UI_DATE_MONTH_SHORT_10: 'Oct',
        UI_DATE_MONTH_SHORT_11: 'Nov',
        UI_DATE_MONTH_SHORT_12: 'Dec',

        // Time and Date related utils
        UI_DATE_RANGE_LABEL: 'Best time to go',
        UI_TIME_RANGE_LABEL: 'Best time to go',

        UI_TIME_DURATION_LABEL: 'Duration',

        UI_TIME_HOUR_SINGLE: 'hour',
        UI_TIME_HOUR_MULTIPLE: 'hours',
        UI_TIME_MINS_SINGLE: 'min',
        UI_TIME_MINS_MULTIPLE: 'mins',

        // Pricing
        UI_PRICING_RANGE_LABEL: 'Pricing',
        UI_PRICING_PERSON: 'Per person',
        UI_PRICING_COUPLE: 'Per couple',
        UI_PRICING_FREE_LABEL: 'Free',

        // Reservations

        UI_RESERVATION_LABEL: 'Reservation',
        UI_RESERVATION_SELECTION_NO: 'No need',
        UI_RESERVATION_SELECTION_YES: 'Yes, required',
        UI_RESERVATION_SELECTION_RECOMMENDED: 'Recommended (walk-ins accepted)',

        // getting there

        UI_GETTING_THERE_LABEL: 'Getting there',

        // UI labels for windows, buttons and such

        // Buttons
        UI_BTN_SAVE: 'Save',
        UI_BTN_SUBMIT: 'Submit',
        UI_BTN_SAVE_CHANGES: 'Save changes',
        UI_BTN_DISCARD: 'Discard',
        UI_BTN_DISCARD_CHANGES: 'Discard changes',
        UI_BTN_EDIT: 'Edit',
        UI_BTN_CANCEL: 'Cancel',
        UI_BTN_DELETE: 'Delete',
        UI_BTN_OK: 'OK',
        UI_BTN_APPLY: 'Apply',
        UI_BTN_UPDATE: 'Update',
        UI_BTN_CLOSE: 'Close',
        UI_BTN_CONFIRM: 'Confirm',
        UI_BTN_ADD: 'Add',
        UI_BTN_REMOVE: 'Remove',
        UI_BTN_ADD_TIP: 'Tip',
        UI_BTN_ADD_LINK: 'Link',
        UI_BTN_ADD_EXTRA: 'Misc',

        UI_LBL_MYKREATIONS: 'My Kreations',
        UI_BTN_ADD_EXPERIENCE: 'Create new experience',

        // Labels
        UI_LBL_TIP: 'Tip',
        UI_LBL_NOTE: 'Note',
        UI_LBL_FUNFACT: 'Fun fact',
        UI_LBL_WATCHOUT: 'Watchout',
        UI_LBL_DONTFORGET: "Don't forget",
        UI_LBL_COVID19: 'Other',

        // Messages
        UI_MSG_HELLO: 'Hello',
        UI_MSG_WELCOME: 'Welcome',
        UI_MSG_WELCOME_BACK: 'Welcome back',

        // gallery
        UI_LBL_GALLERY_ADD: 'Add Images and Videos',
        UI_LBL_GALLERY_MANAGE: 'Manage the gallery',

        // Modals
        UI_MDL_DELETE_TITLE: 'Delete Item',
        UI_MDL_DELETE_TEXT: 'Are you sure you want to delete this item? This action cannot be undone.',
        UI_MDL_OK_TEXT: 'You are about to add a new day to your experience. Clicking Ok will take you directly to it.',
        UI_MDL_ADD_TITLE: 'Add Item',
        UI_MDL_EDIT_TITLE: 'Edit Item',

        UI_MDL_ERR: 'Something went wrong',

        // Quill Editor
        UI_EDITOR_PLACEHOLDER: 'Start writing...',
        UI_EDITOR_HELP:
            "<p class='text-sm mb-2'>Write something inspiring. Keep your sentences short and energetic.</p<p class='font-bold mt-2'>Reference</p><p>en.UI_EDITOR_HELP</p>",

        // Help Msgs General
        UI_HELP_NEEDHELP: 'Need help?',
        UI_HELP_NEEDMOREHELP: 'Need more help?',
        UI_HELP_KONNECTACADEMY: 'Checkout Konnect Academy where we teach you how to create the best experience.',

        // Validattion msgs
        ERR_FIELD_EMPTY: '',
        ERR_TYPE_EMAIL: '',
        ERR_FILE_SIZE: '',
        ERR_TYPE_URL: '',
        ERR_TYPE_NUMBER: '',
        ERR_MSG_NOT_FOUND: '',
        ERR_MSG_SERVER: '',

        // Experience type list
        DIGITAL: 'Digital',
        GUIDED: 'Guided',

        // activity list
        SIGHTSEEING: 'Sightseeing',
        CULTURE: 'Culture',
        HISTORY: 'History',
        NATURE: 'Nature',
        ENTERTAINMENT: 'Entertainment',
        PHYSICAL: 'Physical activity',
        FOOD_RELATED: 'Food and Drinks',
        RELAXED: 'Relax and Chill',

        HIKING: 'Hiking',

        SPORTS: 'Involves Sports',
        WILD: 'Wild and nature',

        WATER_BASED: 'Water based',

        // transport lists
        PLANE_RIDES: 'Plane Rides',
        BOAT_RIDES: 'Boat Rides',
        FERRY: 'Ferry',
        TRAIN: 'Train',
        METRO: 'Metro / Subway',
        TRAMWAY: 'Tramway',
        BUS: 'Bus',
        TAXI: 'Taxi',
        CAR: 'Car',
        CAR_HIRE: 'Car Hire',
        CAR_RENTAL: 'Car Rental',
        RIDE_SHARING: 'Ride sharing',
        RIDE_POOLING: 'Ride Pooling',
        SCOOTER: 'Scooter',
        BIKE: 'Bike',
        E_BIKE: 'E-Bike',
        WALKING: 'Walking',
        HORSE: 'Horse',
        CAMEL: 'Camel',
        DONKEY: 'Donkey',
        ELEPHANT: 'Elephant',
        INTERNATIONAL_TRAVEL: 'International or long distance travellers',
        LOCAL_TRAVEL: 'Local travellers',
        AIR_TRAVEL: 'Airport',
        SEA: 'Seaport',
        RAILWAY: 'Railway station',
        VEHICLE: 'Bus Station',

        // Currency list

        CASH: 'CASH',
        CREDIT: 'CREDIT',
        CHEQUE: 'CHEQUE',
        AMEX: 'AMEX',

        // Activity List

        DIRECTION: 'Directions',
        BOOKING: 'Booking',
        WIKILINK: 'Wiki Links',

        // Accommodation refs

        ACC_HOTEL: 'Hotel',
        ACC_MOTEL: 'Motel',
        ACC_APARTMENT: 'Apartment',
        ACC_CHALET: 'Chalet',
        ACC_COTTAGE: 'Cottage',
        ACC_LOG_CABIN: 'Log Cabin',
        ACC_CAMPING_TENT: 'Camping Site',
        ACC_CAMPING_SITE: 'Camping Site',
        ACC_TEPEE: 'Tepee',
        ACC_RESORT: 'Resort',
        ACC_BED_N_BREAKFAST: 'Bed and Breakfast',
        ACC_APART_HOTEL: 'Apart Hotel',
        ACC_GUEST_HOUSE: 'Guest House',
        ACC_YOUTH_HOSTEL: 'Youth Hostel',
        ACC_BOAT: 'Boat',
        ACC_CAMPER: 'Camper',
        ACC_YURT: 'Yurt',
        ACC_TREEHOUSE: 'Treehouse',

        ACC_STAR1: '1 Star',
        ACC_STAR2: '2 Stars',
        ACC_STAR3: '3 Stars',
        ACC_STAR4: '4 Stars',
        ACC_STAR5: '5 Stars',
        ACC_NA: 'Rating Not available',
        ACC_BOUTIQUE: 'Boutique Hotel',

        ACC_LUXURIOUS: 'Lurxurious',
        ACC_UPSCALE: 'Upscale',
        ACC_HOMEY: 'Homey',
        ACC_COSEY: 'Cosey',
        ACC_HIP: 'Hip / Trendy',
        ACC_FAMILY: 'Family Friendly',
        ACC_CORPORATE: 'Corporate',

        //=====================================================================//
        //========== Overview Page ==========//
        //=====================================================================//

        //---------- Overview Intro ----------//
        OV_INTRO_V_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.OV_INTRO_V_HINT</p>",
        OV_INTRO_V_HEADTITLE: 'Overview',
        OV_INTRO_V_MENUTITLE: 'Summary',
        OV_INTRO_V_TITLE_EMPTY: "Let's give our experience an exciting title",
        OV_INTRO_V_DESCRIPTION_EMPTY:
            'Provide a general description of your experience in a few lines. Where will we go? What makes it special? What will we see? Why do you love it? This will be posted in your marketing section and will be public to anyone visiting the website before they purchase an experience.',
        OV_INTRO_E_M_TITLE_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.OV_INTRO_E_M_TITLE_HINT</p>",
        OV_INTRO_E_M_TITLE_PLACEHOLDER: 'Give your experience a name. This is what peopel will see in search results.',
        OV_INTRO_E_M_DESCRIPTION_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.OV_INTRO_E_M_DESCRIPTION_HINT</p>",
        OV_INTRO_E_M_DESCRIPTION_PLACEHOLDER: 'INTRO_E_M_DESCRIPTION_PLACEHOLDER',

        //---------- Overview bUDGET ----------//
        OV_BUDGET_V_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.OV_WHENTOGO_V_HINT</p>",
        OV_BUDGET_V_HEADTITLE: 'Best time to go',
        OV_BUDGET_V_MENUTITLE: 'Budget',
        OV_BUDGET_V_DESCRIPTION_EMPTY:
            "Give advice on how much we should budget for the trip. If it's subjective or you do not wish to include this information, you can hide the section using the eye icon above.",

        //---------- Overview When to go ----------//
        OV_WHENTOGO_V_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.OV_WHENTOGO_V_HINT</p>",
        OV_WHENTOGO_V_HEADTITLE: 'Best time to go',
        OV_WHENTOGO_V_MENUTITLE: 'When to go',
        OV_WHENTOGO_V_DESCRIPTION_EMPTY:
            'When is the best time to go on the experience? Use the sliders to select months. Also feel free to add some tips on visiting and fun facts. You can also hide the section using the eye icon above.',

        //---------- Overview Destination ----------//
        OV_DESTINATION_V_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.OV_DESTINATION_V_HINT</p>",
        OV_DESTINATION_V_HEADTITLE: 'The Destination',
        OV_DESTINATION_V_SUBTITLE: 'The Destination',
        OV_DESTINATION_V_MENUTITLE: 'The Destination',
        OV_DESTINATION_V_DESCRIPTION_EMPTY:
            "So where are we going? Click on this box to add a short paragraph about the place (not the experience). When you're done, click on the map to find and add your destination to the experience (you can add as many as you like).",

        OV_DESTINATION_E_M_PLACE_DESCRIPTION:
            'Let us help you find your destination(s). Search for a country, region or place and select it from the list. Add as many as you want. Remove by clicking on the x.',
        OV_DESTINATION_E_M_PLACE_PLACEHOLDER_AUTOCOMPLETE: 'ex: Bali, Indonesia',

        OV_DESTINATION_E_I_ADDLINK: 'Destination',
        //---------- Overview Getting there ----------//
        OV_GETTINGTHERE_V_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.OV_GETTINGTHERE_V_HINT</p>",
        OV_GETTINGTHERE_V_HEADTITLE: 'Transportation',
        OV_GETTINGTHERE_V_SUBTITLE: 'Airports & Visas',
        OV_GETTINGTHERE_V_MENUTITLE: 'Transportation',
        OV_GETTINGTHERE_V_DESCRIPTION_EMPTY:
            "<p class='mb-6'>Use this section to give general advice and tips on getting to the destination and moving around.</p><p class='mb-6'>You can also add links to relevant websites right in the text. The section can also be hidden if there is nothing special to add..</p>",
        OV_GETTINGTHERE_V_ENTRY: 'Entry & Visa requirements',
        OV_GETTINGTHERE_V_ENTRY_EMPTY: 'Click to add a weblink',

        OV_GETTINGTHERE_E_I_ADDMAIN: 'Add Getting There section',
        OV_GETTINGTHERE_E_I_ADDLINK: 'Airport',
        OV_GETTINGTHERE_E_I_ADDTIP: 'Tips',
        OV_GETTINGTHERE_E_M_ADD_DESCRIPTION: 'OV_GETTINGTHERE_E_M_ADD_DESCRIPTION',
        OV_GETTINGTHERE_E_M_ADD_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.OV_GETTINGTHERE_E_M_ADD_HINT</p>",

        OV_GETTINGTHERE_E_M_ENTRY_DESCRIPTION: '',
        OV_GETTINGTHERE_E_M_ENTRY_PLACEHOLDER_NAME: 'Indonesia Border Control',
        OV_GETTINGTHERE_E_M_ENTRY_PLACEHOLDER_URL: 'https://indonesia-border-control.com',

        OV_GETTINGTHERE_E_M_DESCRIPTION_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.OV_GETTINGTHERE_E_M_DESCRIPTION_HINT</p>",

        OV_GETTINGTHERE_E_M_VENDOR_DESCRIPTION: 'OV_GETTINGTHERE_E_M_VENDOR_DESCRIPTION',
        OV_GETTINGTHERE_E_M_VENDOR_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.OV_GETTINGTHERE_E_M_VENDOR_HINT</p>",

        OV_GETTINGTHERE_E_M_TIP_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.OV_GETTINGTHERE_E_M_TIP_HINT</p>",

        //---------- Overview Moving around ----------//
        OV_MOVING_AROUND_V_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.OV_MOVINGAROUND_V_HINT</p>",
        OV_MOVING_AROUND_V_HEADTITLE: 'Moving Around',
        OV_MOVING_AROUND_V_MENUTITLE: 'Moving Around',
        OV_MOVING_AROUND_V_DESCRIPTION_EMPTY:
            "Tell us where we'll be using this mode of transport and how to get to it. If it's a rental car you mention, explain if there is anything particular to look out for when booking a car. If it's the bus, tell us how the bus lines work if there are any and how we can get the tickets. Is there an app we can download? can we use a CC to purchase these tickets? Be as thorough as possible while keeping a light conversational tone.",
        OV_MOVING_AROUND_V_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.OV_MOVING_AROUND_V_HINT</p>",
        OV_MOVING_AROUND_V_HINT_EMPTY:
            "<p class='mb-6'>Ok, so we've arrived at our destination. How are we moving from place to place generally? Your trip might include several visits to several cities where you may need to get train or plane tickets.</p><p class='mb-6'>Or it may be a regional trip where a car is certainly needed, hence you could recommend renting a car.</p><p class='mb-6'>Or it might be a matter of using rented bikes, scooters and ride sharing apps.</p><p class='mb-6'>Be thorough. This helps people have a visibility on what tickets they  need to buy of they, and which services to make reservations for.</p>",

        //---------- Overview Currency ----------//
        OV_CURRENCY_V_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.OV_CURRENCY_V_HINT</p>",
        OV_CURRENCY_V_HINT_EMPTY: "<p class='mb-6'>Currency panels will be automatically added when you add destinations.</p>",
        OV_CURRENCY_V_HEADTITLE: 'Currency & Payments',
        OV_CURRENCY_V_MENUTITLE: 'Currency & Payments',
        OV_CURRENCY_V_DESCRIPTION_EMPTY:
            'How do you pay for things? Are other currencies accepted? What is the best strategy to exchange local currency? Should I take cash? Can I pay with Credit/Debit cards?',
        OV_CURRENCY_V_ENTRY: 'Entry requirements',
        OV_CURRENCY_V_ENTRY_EMPTY: 'Click to add a link',

        OV_CURRENCY_E_I_ADDMAIN: 'Add Currency Panel',
        OV_CURRENCY_E_I_ADDMAIN_MORE: 'Add Panel for more countries',
        OV_CURRENCY_E_I_ADDLINK: 'Add travel mode',
        OV_CURRENCY_E_I_ADDMTIP: 'Bubble',
        OV_CURRENCY_E_M_ADD_DESCRIPTION: 'OV_CURRENCY_E_M_ADD_DESCRIPTION',
        OV_CURRENCY_E_M_ADD_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.OV_CURRENCY_E_M_ADD_HINT</p>",

        OV_CURRENCY_E_M_DESCRIPTION_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.OV_CURRENCY_E_M_DESCRIPTION_HINT</p>",

        OV_CURRENCY_E_M_VENDOR_DESCRIPTION: 'OV_CURRENCY_E_M_VENDOR_DESCRIPTION',
        OV_CURRENCY_E_M_VENDOR_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.OV_CURRENCY_E_M_VENDOR_HINT</p>",

        OV_CURRENCY_E_M_TIP_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.OV_CURRENCY_E_M_TIP_HINT</p>",

        //---------- Overview Accommodation ----------//
        OV_ACCOMMODATION_V_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.OV_ACCOMMODATION_V_HINT</p>",

        OV_ACCOMMODATION_V_HEADTITLE: 'Where you can stay',
        OV_ACCOMMODATION_V_SUBTITLE: 'Accommodation',
        OV_ACCOMMODATION_V_MENUTITLE: 'Where to stay',
        OV_ACCOMMODATION_V_DESCRIPTION_EMPTY:
            'Use to section to give general advice and tips on your favorite areas or places to stay and why, or generally discuss the housing options at a destination. You can add accommodation locations as well as areas using the map tool. The section can also be hidden if there is nothing special to add.',

        OV_ACCOMMODATION_E_I_ADDLINK: 'Accommodation',

        //---------- Overview Tips ----------//
        OV_TIPS_V_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.OV_TIPS_V_HINT</p>",
        OV_TIPS_V_HEADTITLE: 'General Tips',
        OV_TIPS_V_SUBTITLE: 'Misc info',
        OV_TIPS_V_MENUTITLE: 'General Tips',
        OV_TIPS_E_I_ADDMAIN: 'Add More Tips',
        OV_TIPS_V_HINT_EMPTY:
            'use this section to add more information that doesn’t fit anywhere else. Categories include Tips, Fun Facts, Don’t forget, Notes, and Watch-out and you can add as many as you would like.). remove Covid 19 from the list.',
        OV_TIPS_V_DESCRIPTION_EMPTY: 'What else should we know about this experience? little things that could help us make the most of it',

        //---------- Overview Itinerary ----------//
        OV_ITINERARY_V_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.OV_ITINERARY_V_HINT</p>",
        OV_ITINERARY_V_HEADTITLE: 'Your Itinerary',
        OV_ITINERARY_V_MENUTITLE: 'Itinerary',
        OV_ITINERARY_V_DESCRIPTION_EMPTY: 'OV_ITINERARY_V_DESCRIPTION_EMPTY',

        OV_ITINERARY_V_HINT_EMPTY:
            "<p class='mb-6'>Ok, so we're done with the basics. Now we can start building our fantastic experience day by day.</p><p class='mb-6'>Every exeperience is <strong>made of one or several days</strong>. Kreators have different ways of approaching this step:</p><p class='mb-6'> They might prefer to <strong>add all the days first</strong> - for example clicking 3 times on the button to add 3 days, then go into each day and fill it out. <strong>Others prefer to add a day, fill it out</strong> then come back to add a new day. You could also mix both strategies.</p><p class='mb-6'>The beauty of this process is that it is not linear. You can go to any section of your experience at any time to fill it out or edit it. </p>",

        OV_ITINERARY_V_HINT:
            "<p class='mb-6'>Ok, so we're done with the basics. Now we can start building our fantastic experience day by day.</p><p class='mb-6'>Every exeperience is <strong>made of one or several days</strong>. Kreators have different ways of approaching this step:</p><p class='mb-6'> They might prefer to <strong>add all the days first</strong> - for example clicking 3 times on the button to add 3 days, then go into each day and fill it out. <strong>Others prefer to add a day, fill it out</strong> then come back to add a new day. You could also mix both strategies.</p><p class='mb-6'>The beauty of this process is that it is not linear. You can go to any section of your experience at any time to fill it out or edit it.</p><p class='font-bold mt-2'>Reference</p><p>en.OV_ITINERARY_V_HINT</p>",

        OV_ITINERARY_E_I_ADDMAIN_EMPTY: 'Create your first day',
        OV_ITINERARY_E_I_ADDMAIN_ONE: 'Add a day',

        //=====================================================================//
        //========== Day Page ==========//
        //=====================================================================//

        //---------- Day Intro ----------//
        INTRO_V_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.INTRO_V_HINT</p>",
        INTRO_V_HEADTITLE: 'Day',
        INTRO_V_MENUTITLE: 'Summary',
        INTRO_V_TITLE_EMPTY: "Let's give this day an exciting title",
        INTRO_V_DESCRIPTION_EMPTY:
            'Summarize the day in a few lines. Tell us in general what you will be doing and the exciting things you will see. You will be able to add more detail in the next section so keep this section simple. This will also be posted in your marketing section and will be public to anyone visiting the website before they purchase an experience.',
        INTRO_E_M_TITLE_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.INTRO_E_M_TITLE_HINT</p>",
        INTRO_E_M_TITLE_PLACEHOLDER: 'Give this day a title. ex: Exploring ancient ruins',
        INTRO_E_M_DESCRIPTION_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.INTRO_E_M_DESCRIPTION_HINT</p>",
        INTRO_E_M_DESCRIPTION_PLACEHOLDER: 'INTRO_E_M_DESCRIPTION_PLACEHOLDER',

        //---------- Day What you need ----------//

        // Preview //
        WHAT_YOU_NEED_V_HEADTITLE: 'Before you go',
        WHAT_YOU_NEED_V_SUBTITLE: 'Checklist',
        WHAT_YOU_NEED_V_MENUTITLE: 'Before you go',
        WHAT_YOU_NEED_V_TIP: "Don't forget",
        WHAT_YOU_NEED_V_DESCRIPTION_EMPTY: 'Let travelers know if there is something special they need to do or bring along on the day.',
        WHAT_YOU_NEED_V_CHEKLIST_EMPTY:
            'So what are those items one must take on this day. List them out. Feel free to add links to those items as well (affiliate links are accepted)',
        WHAT_YOU_NEED_V_HINT:
            "<p class='mb-6'>You just woke up, had your morning routine and are excited to start your day.<br>You leave your room, attack the streets and realize it's quite hot outside. <strong>Wait! did I bring a hat? or sunscreen?</strong></p><p class=''>Travellers may find themselves in similar situations often. You can't predict them all but since you've been on this journey before, it's good to remind people in a <strong>checklist</strong> what they need to bring with them before they start their day.</p><p class='font-bold mt-12 text-sm'>Reference</p><p class='text-xs'>en.WHAT_YOU_NEED_V_HINT</p>",

        WHAT_YOU_NEED_V_HINT_EMPTY:
            "<p class='mb-6'>You just woke up, had your morning routine and are excited to start your day.<br>You leave your room, attack the streets and realize it's quite hot outside. <strong>Wait! did I bring a hat? or sunscreen?</strong></p><p class=''>Travellers may find themselves in similar situations often. You can't predict them all but since you've been on this journey before, it's good to remind people in a <strong>checklist</strong> what they need to bring with them before they start their day.</p>",

        //  Edit Inline //
        WHAT_YOU_NEED_E_I_ADDMAIN: 'Add Checklist',
        WHAT_YOU_NEED_E_I_ADDLINK: 'WHAT_YOU_NEED_E_I_ADDLINK',
        WHAT_YOU_NEED_E_I_ADDTIP: 'WHAT_YOU_NEED_E_I_ADDTIP',

        // Edit Modals //
        WHAT_YOU_NEED_E_M_DESCRIPTION_HINT:
            "<p class='text-sm mb-2'>How many times have you left your hotel room without a hat or sunscreen or a towel only to realize this too late and ruin part of your day?</p><p class='text-sm mb-6'> This happens to the best of us, so just write in your own style about that. You will have another section to add a list of those things. </p><h3 class='font-bold mb-2'>Examples of writing</h3><p>This day is quite intense as we'll be walking a lot. I have 3 words for you. Hydrate 💧, hydrate 💧 and hydrate 💧. Although we might run by villages, it's hard to find clean drinking water. So pack a punch.</p><p class='font-bold mt-2'>Reference</p><p>en.WHAT_YOU_NEED_E_M_DESCRIPTION_HINT</p>",
        WHAT_YOU_NEED_E_M_DESCRIPTION_PLACEHOLDER: 'Start writing a description...',
        WHAT_YOU_NEED_E_M_DELETE_DESCRIPTION: 'Are you sure you want to delete this item? This action cannot be undone.',

        //Checklist
        WHAT_YOU_NEED_E_M_CHECKLIST_PLACEHOLDER_LABEL: 'Water Bottle',
        WHAT_YOU_NEED_E_M_CHECKLIST_PLACEHOLDER_URL: 'Affiliate Link (optional) https://',
        WHAT_YOU_NEED_E_M_CHECKLIST_HINT:
            "<h3 class='font-bold mb-2'>Examples of Titles</h3><p class='mb-4'>Uber - Road Assistance - Buy tickets</p><h3 class='font-bold mb-2'>Examples of Link usage</h3>If your link is very long, we recommend adding a display name to it. Your <a class='text-green-500' target='_blank' href='https://www.viakonnect.com'>https://www.viakonnect.com</a> link will show as <a class='text-green-500' target='_blank' href='https://www.viakonnect.com'>My Link</a><p class='font-bold mt-2'>Reference</p><p>en.WHAT_YOU_NEED_E_M_CHECKLIST_HINT</p>",
        MOVING_AROUND_E_M_CHECKLIST_DESCRIPTION: 'Some description of vendors before adding them',

        //// Section > Moving Around ////////////////////////////////////////////////////////////////

        // View mode
        MOVING_AROUND_V_HEADTITLE: 'Ways to move around',
        MOVING_AROUND_V_MENUTITLE: 'Moving around',
        MOVING_AROUND_V_TIP: 'The Tip',
        MOVING_AROUND_V_DESCRIPTION_EMPTY:
            "<p class='mb-6'>You're doing great. So tell us how this transport mode is going to be used and how to get it by clicking on this box.</p><p class='mb-6'> If it' a rental car, suggest some rental services by also clicking on + Link and adding as many as you want. If it's a train ticket, maybe link to a site where one could purchase such tickets.</p><p class='mb-6'>Are there tips, watchouts or fun facts about using this mode of transport? Click on the + Tip button.</p><p class='mb-6'>Want to change the mode of transport? Just click on the header icon.</p>",
        MOVING_AROUND_V_HINT:
            "<p class='mb-6'>Ok, so we've arrived at our destination. How are we moving from place to place generally?</p><p class='mb-6'>Your trip might include several visits to several cities where you may need to get train or plane tickets.Or it may regional where a car is certainly needed, hence you could recommend renting a car. Or it might be a matter of using rented bikes, scooters and ride sharing apps.</p><p>Be thorough. This helps people have a visibility on what tickets they  need to buy if any, and which services to make reservations for.</p><p class='font-bold mt-12 text-sm'>Reference</p><p class='text-xs'>en.MOVING_AROUND_V_HINT</p> ",

        MOVING_AROUND_V_HINT_EMPTY:
            "<p class='mb-6'>Ok, so we've arrived at our destination. How are we moving from place to place generally?</p><p class='mb-6'>Your trip might include several visits to several cities where you may need to get train or plane tickets.Or it may regional where a car is certainly needed, hence you could recommend renting a car. Or it might be a matter of using rented bikes, scooters and ride sharing apps.</p><p>Be thorough. This helps people have a visibility on what tickets they  need to buy if any, and which services to make reservations for.</p> ",

        //  Edit mode inline buttons
        MOVING_AROUND_E_I_ADDMAIN: 'Add Transport Mode',
        MOVING_AROUND_E_I_ADDLINK: 'Link 1',
        MOVING_AROUND_E_I_ADDTIP: 'Tip 1',

        // Modal windows

        // Delete something
        MOVING_AROUND_E_M_DELETE_DESCRIPTION: 'Are you sure you want to delete this item? This action cannot be undone.',

        //Transport type
        MOVING_AROUND_E_M_TYPE_HINT: "<p class='font-bold mb-1'>Reference</p><p>en.MOVING_AROUND_E_M_TYPE_HINT</p>",

        //Description box
        MOVING_AROUND_E_M_DESCRIPTION_HINT: "<p class='font-bold mb-1'>Reference</p><p>en.MOVING_AROUND_E_M_DESCRIPTION_HINT</p>",
        MOVING_AROUND_E_M_DESCRIPTION_PLACEHOLDER: 'Start writing a description...',

        //Vendor List
        MOVING_AROUND_E_M_VENDOR_PLACEHOLDER_NAME: '',
        MOVING_AROUND_E_M_VENDOR_PLACEHOLDER_LABEL: 'Display Name',
        MOVING_AROUND_E_M_VENDOR_PLACEHOLDER_URL: 'The Link',
        MOVING_AROUND_E_M_VENDOR_HINT:
            "<h3 class='font-bold mb-2'>Examples of Titles</h3><p class='mb-4'>Uber - Road Assistance - Buy tickets</p><h3 class='font-bold mb-2'>Examples of Link usage</h3>If your link is very long, we recommend adding a display name to it. Your <a class='text-green-500' target='_blank' href='https://www.viakonnect.com'>https://www.viakonnect.com</a> link will show as <a class='text-green-500' target='_blank' href='https://www.viakonnect.com'>My Link</a><p class='font-bold mt-2'>Reference</p><p>en.MOVING_AROUND_E_M_VENDOR_HINT</p>",
        MOVING_AROUND_E_M_VENDOR_DESCRIPTION: 'Some description of vendors before adding them',

        //Tip List
        MOVING_AROUND_E_M_TIP_HINT: "<p class='font-bold mt-2'>Reference</p><p>en.MOVING_AROUND_E_M_TIP_HINT</p>",
        MOVING_AROUND_E_M_TIP_PLACEHOLDER: 'Start writing a tip...',

        //// Section > Activities ////////////////////////////////////////////////////////////////
        // </p><p class='mb-6'>
        // View mode
        ACTIVITIES_V_HEADTITLE: 'Detailed Itinerary',
        ACTIVITIES_V_MENUTITLE: 'Detailed Itinerary',
        ACTIVITIES_V_TIP: 'Note',
        ACTIVITIES_V_ADDRESS_EMPTY: 'Where is this activity taking place? Click to locate the address using Google Maps',
        ACTIVITIES_V_DESCRIPTION_EMPTY:
            "<p class='mb-6'>Start by introducing the activity then describe it in more detail as if you were there showing them around. The more personal detail you can add the better.</p><p class='mb-6'> Add a Location by clicking on the blue box.</p><p class='mb-6'> Add or edit extra details relating to the activity (best time to go, duration, pricing, reservation) by clicking on the Edit details button (or hide some of them by clicking on the x inside that window).</p><p class='mb-6'> Don't forget to add photos by clicking on the Manage Gallery button below.</p><p class='mb-6'> Finally, if there are any tips or fun facts about the place, click on the Misc button to add one.</p>",
        ACTIVITIES_V_HINT:
            "<p class='mb-6'>So this is what our trip is all about, what we will be doing; the activities and all the stories we'll experience to tell.</p><p class='mb-6'> Anything you do during the day can be considered an activity.<br>Are you starting with a <strong>breakfast at the hotel</strong>? That's an activity related to food. Are we <strong>renting bikes and cruising the coast</strong>? That's an activity right there.</p><p class='mb-6'>Be a visual storysteller. Don't be academic and just fill out the 'required' fields. Think back about what emotions got triggered when you were there doing that specific thing. Sometimes those are emotions were intense and sometimes it was more about just fun.</p><p class='mb-6'> Don't be poetic, be genuine.</p><p class='font-bold mt-12 text-sm'>Reference</p><p class='text-xs'>en.ACTIVITIES_V_HINT</p>",

        ACTIVITIES_V_HINT_EMPTY:
            "<p class='mb-6'>So this is what our trip is all about, what we will be doing; the activities and all the stories we'll experience to tell.</p><p class='mb-6'> Anything you do during the day can be considered an activity.<br>Are you starting with a <strong>breakfast at the hotel</strong>? That's an activity related to food. Are we <strong>renting bikes and cruising the coast</strong>? That's an activity right there.</p><p class='mb-6'>Be a visual storysteller. Don't be academic and just fill out the 'required' fields. Think back about what emotions got triggered when you were there doing that specific thing. Sometimes those are emotions were intense and sometimes it was more about just fun.</p><p class='mb-6'> Don't be poetic, be genuine.</p>",

        //  Edit mode inline buttons
        ACTIVITIES_E_I_ADDMAIN: 'Add new activity',
        ACTIVITIES_E_I_ADDLINK: 'Extra info',
        ACTIVITIES_E_I_ADDTIP: 'Note',

        // Modal windows

        // Delete something
        ACTIVITIES_E_M_DELETE_DESCRIPTION: 'Are you sure you want to delete this item? This action cannot be undone.',

        //Transport type
        ACTIVITIES_E_M_TYPE_HINT: "<p class='font-bold mb-1'>Reference</p><p>en.ACTIVITIES_E_M_TYPE_HINT</p>",
        ACTIVITIES_E_M_TYPE_DESCRIPTION: 'Give this activity a name and choose its type',
        ACTIVITIES_E_M_TYPE_PLACEHOLDER: 'ex: Breakfast at Agra Café',

        // Map
        ACTIVITIES_E_M_MAP_BTN_ADDRESS: 'Guess Address',

        ACTIVITIES_E_M_MAP_HINT: "<p class='font-bold mb-1'>Reference</p><p>en.ACTIVITIES_E_M_MAP_HINT</p>",
        ACTIVITIES_E_M_MAP_PLACEHOLDER_ADDRESS: 'Full address including city and country',
        ACTIVITIES_E_M_MAP_PLACEHOLDER_AUTOCOMPLETE: 'ex: Café les trois, paris ',

        ACTIVITIES_E_M_MAP_DESCRIPTION:
            "Let us help you pin this activity on a map. Search for the place or a nearby place. Zoom in and click on where the activity takes place. Press the Guess Address button to get an address. If it doesn't look right, edit the text box to your liking.",

        //Description box
        ACTIVITIES_E_M_DESCRIPTION_HINT: "<p class='font-bold mb-1'>Reference</p><p>en.ACTIVITIES_E_M_TYPE_HINT</p>",
        ACTIVITIES_E_M_DESCRIPTION_PLACEHOLDER: 'Start writing a description...',

        //Vendor List
        ACTIVITIES_E_M_VENDOR_PLACEHOLDER_NAME: '',
        ACTIVITIES_E_M_VENDOR_PLACEHOLDER_LABEL: 'Display Name',
        ACTIVITIES_E_M_VENDOR_PLACEHOLDER_URL: 'The Link',
        ACTIVITIES_E_M_VENDOR_HINT:
            "<h3 class='font-bold mb-2'>Examples of Titles</h3><p class='mb-4'>Uber - Road Assistance - Buy tickets</p><h3 class='font-bold mb-2'>Examples of Link usage</h3>If your link is very long, we recommend adding a display name to it. Your <a class='text-green-500' target='_blank' href='https://www.viakonnect.com'>https://www.viakonnect.com</a> link will show as <a class='text-green-500' target='_blank' href='https://www.viakonnect.com'>My Link</a><p class='font-bold mt-2'>Reference</p><p>en.ACTIVITIES_E_M_TIP_HINT</p>",
        ACTIVITIES_E_M_VENDOR_DESCRIPTION: 'Some description of vendors before adding them',

        //Tip List
        ACTIVITIES_E_M_TIP_HINT: "<p class='font-bold mb-1'>Reference</p><p>en.ACTIVITIES_E_M_TIP_HINT</p>",
        ACTIVITIES_E_M_TIP_PLACEHOLDER: 'Start writing a tip...',

        //Google maps
        GOOGLE_MAP_NOT_INITIALISED: 'Google map loading',
        GOOGLE_MAP_INITIALISE_FAIL: 'There seems to be a problem with google maps'
    },

    ar: {
        rtl: 'true',

        lang: 'العربية',
        lang_iso: 'ar',
        rejected: 'rejected',
        submitted: 'submitted',
        approved: 'approved',
        pending: 'pending',
        initial: 'Not Submitted',
        EXP_LIST_SUBMIT: 'Are you sure you want to submit this experience',
        ACCOMMODATION: 'ACCOMMODATION',
        AIRPORT_TRANSFER: 'AIRPORT_TRANSFER',
        ACTIVITIES: 'ACTIVITIES',
        FOOD_BEVERAGE: 'FOOD_BEVERAGE',

        NAVMAIN_KREATIONS: 'الإنشاءات الخاصة بي',
        NAVMAIN_PAYOUT: 'المبالغ المستحقة',
        NAVMAIN_PROFILE: 'الملف الشخصي',
        NAVMAIN_HELP: 'أكاديمية كونيكت',
        NAVMAIN_SIGNOUT: 'تسجيل الخروج',

        // Full Months
        UI_DATE_MONTH_FULL_1: 'January',
        UI_DATE_MONTH_FULL_2: 'February',
        UI_DATE_MONTH_FULL_3: 'March',
        UI_DATE_MONTH_FULL_4: 'April',
        UI_DATE_MONTH_FULL_5: 'May',
        UI_DATE_MONTH_FULL_6: 'June',
        UI_DATE_MONTH_FULL_7: 'July',
        UI_DATE_MONTH_FULL_8: 'August',
        UI_DATE_MONTH_FULL_9: 'September',
        UI_DATE_MONTH_FULL_10: 'October',
        UI_DATE_MONTH_FULL_11: 'November',
        UI_DATE_MONTH_FULL_12: 'December',

        // 3 Letter or short months
        UI_DATE_MONTH_SHORT_1: 'Jan',
        UI_DATE_MONTH_SHORT_2: 'Feb',
        UI_DATE_MONTH_SHORT_3: 'Mar',
        UI_DATE_MONTH_SHORT_4: 'Apr',
        UI_DATE_MONTH_SHORT_5: 'May',
        UI_DATE_MONTH_SHORT_6: 'Jun',
        UI_DATE_MONTH_SHORT_7: 'Jul',
        UI_DATE_MONTH_SHORT_8: 'Aug',
        UI_DATE_MONTH_SHORT_9: 'Sep',
        UI_DATE_MONTH_SHORT_10: 'Oct',
        UI_DATE_MONTH_SHORT_11: 'Nov',
        UI_DATE_MONTH_SHORT_12: 'Dec',

        // Time and Date related utils
        UI_DATE_RANGE_LABEL: 'أفضل وقت للذهاب',
        UI_TIME_RANGE_LABEL: 'أفضل وقت للذهاب',

        UI_TIME_DURATION_LABEL: 'مدة النشاط',

        UI_TIME_HOUR_SINGLE: 'ساعة',
        UI_TIME_HOUR_MULTIPLE: 'ساعات',
        UI_TIME_MINS_SINGLE: 'دقيقة',
        UI_TIME_MINS_MULTIPLE: 'دقائق',

        // Pricing
        UI_PRICING_RANGE_LABEL: 'تكلفة النشاط',
        UI_PRICING_PERSON: 'للشخص الواحد',
        UI_PRICING_COUPLE: 'لشخصين',
        UI_PRICING_FREE_LABEL: 'مجاني',

        // Reservations

        UI_RESERVATION_LABEL: 'حجز',
        UI_RESERVATION_SELECTION_NO: 'لا ضرورة للحجز',
        UI_RESERVATION_SELECTION_YES: 'الحجز ضروري',
        UI_RESERVATION_SELECTION_RECOMMENDED: 'يوصى بالحجز',

        // getting there

        UI_GETTING_THERE_LABEL: 'كيفية الوصول الى هناك',

        // UI labels for windows, buttons and such

        // Buttons
        UI_BTN_SAVE: 'إحفظ',
        UI_BTN_SAVE_CHANGES: 'إحفظ التعديلات',
        UI_BTN_DISCARD: 'إلغاء',
        UI_BTN_DISCARD_CHANGES: 'تجاهل التغييرات',
        UI_BTN_EDIT: 'تعديل',
        UI_BTN_CANCEL: 'إلغاء',
        UI_BTN_DELETE: 'حذف',
        UI_BTN_OK: 'OK',
        UI_BTN_APPLY: 'Apply',
        UI_BTN_UPDATE: 'Update',
        UI_BTN_CLOSE: 'Close',
        UI_BTN_CONFIRM: 'Confirm',
        UI_BTN_ADD: 'أضف',
        UI_BTN_REMOVE: 'Remove',
        UI_BTN_ADD_TIP: 'نصيحة',
        UI_BTN_ADD_LINK: 'رابط',
        UI_BTN_ADD_EXTRA: 'Extra',

        UI_LBL_MYKREATIONS: 'My Kreations',
        UI_BTN_ADD_EXPERIENCE: 'Create new experience',

        // Labels
        UI_LBL_TIP: 'نصيحة',
        UI_LBL_NOTE: 'ملاحظة',
        UI_LBL_FUNFACT: 'ملاحظة ظريفة',
        UI_LBL_WATCHOUT: 'احذر',
        UI_LBL_DONTFORGET: 'لا تنسى',

        // Messages
        UI_MSG_HELLO: 'مرحبا',
        UI_MSG_WELCOME: 'أهلا بك',
        UI_MSG_WELCOME_BACK: 'أهلا بكم من جديد',

        // gallery
        UI_LBL_GALLERY_ADD: 'أضف صور وفيديو',
        UI_LBL_GALLERY_MANAGE: 'إدارة محتوى المعرض',

        // Modals
        UI_MDL_DELETE_TITLE: 'تأكيد الحذف',
        UI_MDL_DELETE_TEXT: 'هل أنت متأكد أنك تريد حذف هذا البند؟ لا يمكن التراجع عن هذا الإجراء.',
        UI_MDL_OK_TEXT: 'Are you sure you want to create a new day.',

        UI_MDL_ADD_TITLE: 'إضافة بند',
        UI_MDL_EDIT_TITLE: 'تعديل البند',

        UI_MDL_ERR: 'هناك خطأ ما',

        // Quill Editor
        UI_EDITOR_PLACEHOLDER: 'ابدأ الكتابة ...',
        UI_EDITOR_HELP: "<p class='text-sm mb-2'>'اكتب شيئًا ملهمًا. اجعل جملك قصيرة وحيوية.</p><p class='font-bold mt-2'>مرجع</p><p>ar.UI_EDITOR_HELP</p>",

        // Help Msgs General
        UI_HELP_NEEDHELP: 'هل تحتاج مساعدة؟',
        UI_HELP_NEEDMOREHELP: 'هل تحتاج إلى مزيد من المساعدة؟',
        UI_HELP_KONNECTACADEMY: 'تحقق من أكاديمية كونيكت حيث نعلمك كيفية إنشاء أفضل التجارب.',

        // Validattion msgs
        ERR_FIELD_EMPTY: '',
        ERR_TYPE_EMAIL: '',
        ERR_FILE_SIZE: '',
        ERR_TYPE_URL: '',
        ERR_TYPE_NUMBER: '',
        ERR_MSG_NOT_FOUND: '',
        ERR_MSG_SERVER: '',

        // Experience type list
        DIGITAL: 'Digital',
        GUIDED: 'Guided',

        // activity list

        SIGHTSEEING: 'Sightseeing',
        CULTURE: 'Culture',
        HISTORY: 'History',
        NATURE: 'Nature',
        ENTERTAINMENT: 'Entertainment',
        PHYSICAL: 'Physical activity',
        FOOD_RELATED: 'Food and Drinks',
        RELAXED: 'Relax and Chill',

        HIKING: 'Hiking',

        SPORTS: 'Involves Sports',
        WILD: 'Wild and nature',

        WATER_BASED: 'Water based',

        // transport lists
        PLANE_RIDES: 'بالطائرة',
        BOAT_RIDES: 'بالقارب',
        FERRY: 'العبارة',
        TRAIN: 'قطار',
        METRO: 'المترو',
        TRAMWAY: 'الترام',
        BUS: 'أوتوبيس',
        TAXI: 'سيارة اجره',
        CAR: 'سيارة',
        CAR_HIRE: 'سيارة مستأجرة مع سائق',
        CAR_RENTAL: 'سيارة مستأجرة',
        RIDE_SHARING: 'Ride sharing',
        RIDE_POOLING: 'Ride Pooling',
        SCOOTER: 'سكوتر',
        BIKE: 'دراجة هوائية',
        E_BIKE: 'دراجة كهربائية',
        WALKING: 'المشي',
        HORSE: 'حصان',
        CAMEL: 'جمل',
        DONKEY: 'حمار',
        ELEPHANT: 'الفيل',
        INTERNATIONAL_TRAVEL: 'المسافرون الدوليون',
        LOCAL_TRAVEL: 'مسافرون محليون',
        AIR_TRAVEL: 'السفر جوا',
        SEA: 'السفر عن طريق البحر',
        RAILWAY: 'السفر بالقطار',
        VEHICLE: 'السفر بالسيارة',

        // Currency list

        CASH: 'CASH',
        CREDIT: 'CREDIT',
        CHEQUE: 'CHEQUE',
        AMEX: 'AMEX',

        // Activity List

        DIRECTION: 'Directions',

        // Accommodation refs

        ACC_HOTEL: 'Hotel',
        ACC_MOTEL: 'Motel',
        ACC_APARTMENT: 'Apartment',
        ACC_CHALET: 'Chalet',
        ACC_COTTAGE: 'Cottage',
        ACC_LOG_CABIN: 'Log Cabin',
        ACC_CAMPING_TENT: 'Camping Site',
        ACC_CAMPING_SITE: 'Camping Site',
        ACC_TEPEE: 'Tepee',
        ACC_RESORT: 'Resort',
        ACC_BED_N_BREAKFAST: 'Bed and Breakfast',
        ACC_APART_HOTEL: 'Apart Hotel',
        ACC_GUEST_HOUSE: 'Guest House',
        ACC_YOUTH_HOSTEL: 'Youth Hostel',
        ACC_BOAT: 'Boat',
        ACC_CAMPER: 'Camper',
        ACC_YURT: 'Yurt',
        ACC_TREEHOUSE: 'Treehouse',

        ACC_STAR1: '1 Star',
        ACC_STAR2: '2 Stars',
        ACC_STAR3: '3 Stars',
        ACC_STAR4: '4 Stars',
        ACC_STAR5: '5 Stars',
        ACC_NA: 'Rating Not available',
        ACC_BOUTIQUE: 'Boutique Hotel',

        ACC_LUXURIOUS: 'Lurxurious',
        ACC_UPSCALE: 'Upscale',
        ACC_HOMEY: 'Homey',
        ACC_COSEY: 'Cosey',
        ACC_HIP: 'Hip / Trendy',
        ACC_FAMILY: 'Family Friendly',
        ACC_CORPORATE: 'Corporate',

        //=====================================================================//
        //========== Overview Page ==========//
        //=====================================================================//

        //---------- Overview Intro ----------//
        OV_INTRO_V_HINT: "<p class='font-bold mt-2'>Reference</p><p>ar.OV_INTRO_V_HINT</p>",
        OV_INTRO_V_HEADTITLE: 'لمحة عامة',
        OV_INTRO_V_MENUTITLE: 'ملخص اليوم',
        OV_INTRO_V_TITLE_EMPTY: 'فلنبدأ بإسم مميز لهذه الرحلة ',
        OV_INTRO_V_DESCRIPTION_EMPTY: 'أخبرنا عن هذه التجربة في بضعة أسطر. لمن هي؟ الى اين سنذهب؟ ماذا سنفعل؟ أي نوع من الأشخاص الملهمين سوف نلتقي؟',
        OV_INTRO_E_M_TITLE_HINT: "<p class='font-bold mt-2'>Reference</p><p>ar.OV_INTRO_E_M_TITLE_HINT</p>",
        OV_INTRO_E_M_TITLE_PLACEHOLDER: 'أعطِ تجربتك اسمًا. هذا ما سيراه الناس في نتائج البحث.',
        OV_INTRO_E_M_DESCRIPTION_HINT: "<p class='font-bold mt-2'>Reference</p><p>ar.OV_INTRO_E_M_DESCRIPTION_HINT</p>",
        OV_INTRO_E_M_DESCRIPTION_PLACEHOLDER: 'INTRO_E_M_DESCRIPTION_PLACEHOLDER',

        //---------- Overview Destination ----------//
        OV_DESTINATION_V_HINT: "<p class='font-bold mt-2'>Reference</p><p>ar.OV_DESTINATION_V_HINT</p>",
        OV_DESTINATION_V_HEADTITLE: 'إلى أين نحن ذاهبون؟',
        OV_DESTINATION_V_SUBTITLE: 'الوجهات',
        OV_DESTINATION_V_MENUTITLE: 'إلى أين؟',
        OV_DESTINATION_V_DESCRIPTION_EMPTY: 'ما الذي يميز هذه الوجهة؟ لماذا اخترت ذلك؟ أخبرنا في سطرين.',

        //---------- Overview Getting there ----------//
        OV_GETTINGTHERE_V_HINT: "<p class='font-bold mt-2'>Reference</p><p>ar.OV_GETTINGTHERE_V_HINT</p>",
        OV_GETTINGTHERE_V_HEADTITLE: 'كيفية الوصول الى هناك',
        OV_GETTINGTHERE_V_SUBTITLE: 'كيفية الوصول الى هناك',
        OV_GETTINGTHERE_V_MENUTITLE: 'كيفية الوصول',
        OV_GETTINGTHERE_V_DESCRIPTION_EMPTY: 'كيف نصل الى وجهتنا؟ أضف منافذ الدخول المختلفة حسب نوع السفر',
        OV_GETTINGTHERE_V_ENTRY: 'شروط الدخول',
        OV_GETTINGTHERE_V_ENTRY_EMPTY: 'Click to add a link',

        OV_GETTINGTHERE_E_I_ADDMAIN: 'Add Getting There section',
        OV_GETTINGTHERE_E_I_ADDLINK: 'Add travel mode',
        OV_GETTINGTHERE_E_I_ADDTIP: 'Bubble',
        OV_GETTINGTHERE_E_M_ADD_DESCRIPTION: 'OV_GETTINGTHERE_E_M_ADD_DESCRIPTION',
        OV_GETTINGTHERE_E_M_ADD_HINT: "<p class='font-bold mt-2'>Reference</p><p>ar.OV_GETTINGTHERE_E_M_ADD_HINT</p>",

        OV_GETTINGTHERE_E_M_DESCRIPTION_HINT: "<p class='font-bold mt-2'>Reference</p><p>ar.OV_GETTINGTHERE_E_M_DESCRIPTION_HINT</p>",

        OV_GETTINGTHERE_E_M_VENDOR_DESCRIPTION: 'OV_GETTINGTHERE_E_M_VENDOR_DESCRIPTION',
        OV_GETTINGTHERE_E_M_VENDOR_HINT: "<p class='font-bold mt-2'>Reference</p><p>ar.OV_GETTINGTHERE_E_M_VENDOR_HINT</p>",

        OV_GETTINGTHERE_E_M_TIP_HINT: "<p class='font-bold mt-2'>Reference</p><p>ar.OV_GETTINGTHERE_E_M_TIP_HINT</p>",

        //---------- Overview Moving around ----------//
        OV_MOVING_AROUND_V_HINT: "<p class='font-bold mt-2'>Reference</p><p>ar.OV_MOVINGAROUND_V_HINT</p>",
        OV_MOVING_AROUND_V_HEADTITLE: 'كيفية التنقل',
        OV_MOVING_AROUND_V_MENUTITLE: 'كيفية التنقل',
        OV_MOVING_AROUND_V_DESCRIPTION_EMPTY:
            "Tell us where we'll be using this mode of transport and how to get to it. If it's a rental car you mention, explain if there is anything particular to look out for when booking a car. If it's the bus, tell us how the bus lines work if there are any and how we can get the tickets. Is there an app we can download? can we use a CC to purchase these tickets? Be as thorough as possible while keeping a light conversational tone.",
        OV_MOVING_AROUND_V_HINT: "<p class='font-bold mt-2'>Reference</p><p>ar.OV_MOVING_AROUND_V_HINT</p>",
        OV_MOVING_AROUND_V_HINT_EMPTY:
            "Ok, so we've arrived at our destination. How are we moving from place to place generally? Your trip might include several visits to several cities where you may need to get train or plane tickets. Or it may regional where a car is certainly needed, hence you could recommend renting a car. Or it might be a matter of using rented bikes, scooters and ride sharing apps. Be thorough. This helps people have a visibility on what tickets they  need to buy of they, and which services to make reservations for. ",

        //---------- Overview Currency ----------//
        OV_CURRENCY_V_HINT: "<p class='font-bold mt-2'>Reference</p><p>ar.OV_CURRENCY_V_HINT</p>",
        OV_CURRENCY_V_HEADTITLE: 'الدفع والعملة',
        OV_CURRENCY_V_MENUTITLE: 'الدفع والعملة',
        OV_CURRENCY_V_DESCRIPTION_EMPTY: 'How can we get to our destination? Add the various entry ports depending on the travel mode.',
        OV_CURRENCY_V_ENTRY: 'Entry requirements',
        OV_CURRENCY_V_ENTRY_EMPTY: 'Click to add a link',

        OV_CURRENCY_E_I_ADDMAIN: 'Add Getting There section',
        OV_CURRENCY_E_I_ADDLINK: 'Add travel mode',
        OV_CURRENCY_E_I_ADDMTIP: 'Bubble',
        OV_CURRENCY_E_M_ADD_DESCRIPTION: 'OV_CURRENCY_E_M_ADD_DESCRIPTION',
        OV_CURRENCY_E_M_ADD_HINT: "<p class='font-bold mt-2'>Reference</p><p>ar.OV_CURRENCY_E_M_ADD_HINT</p>",

        OV_CURRENCY_E_M_DESCRIPTION_HINT: "<p class='font-bold mt-2'>Reference</p><p>ar.OV_CURRENCY_E_M_DESCRIPTION_HINT</p>",

        OV_CURRENCY_E_M_VENDOR_DESCRIPTION: 'OV_CURRENCY_E_M_VENDOR_DESCRIPTION',
        OV_CURRENCY_E_M_VENDOR_HINT: "<p class='font-bold mt-2'>Reference</p><p>ar.OV_CURRENCY_E_M_VENDOR_HINT</p>",

        OV_CURRENCY_E_M_TIP_HINT: "<p class='font-bold mt-2'>Reference</p><p>ar.OV_CURRENCY_E_M_TIP_HINT</p>",

        //---------- Overview Accommodation ----------//
        OV_ACCOMMODATION_V_HINT: "<p class='font-bold mt-2'>Reference</p><p>ar.OV_ACCOMMODATION_V_HINT</p>",
        OV_ACCOMMODATION_V_HEADTITLE: 'أين سنقيم ؟',
        OV_ACCOMMODATION_V_MENUTITLE: 'أين سنقيم ؟',
        OV_ACCOMMODATION_V_DESCRIPTION_EMPTY: 'Where are we going yo stay? Tell us about the areas you recommended on the map',

        //---------- Overview Tips ----------//
        OV_TIPS_V_HINT: "<p class='font-bold mt-2'>Reference</p><p>ar.OV_TIPS_V_HINT</p>",
        OV_TIPS_V_HEADTITLE: 'المزيد من النصائح',
        OV_TIPS_V_SUBTITLE: 'المزيد من النصائح',
        OV_TIPS_V_MENUTITLE: 'المزيد من النصائح',
        OV_TIPS_V_DESCRIPTION_EMPTY: 'What else should we know about this experience? little things that could help us make the most of it',

        //---------- Overview Itinerary ----------//
        OV_ITINERARY_V_HINT: "<p class='font-bold mt-2'>Reference</p><p>ar.OV_ITINERARY_V_HINT</p>",
        OV_ITINERARY_V_HEADTITLE: 'مسار الرحلة',
        OV_ITINERARY_V_MENUTITLE: 'مسار الرحلة',
        OV_ITINERARY_V_DESCRIPTION_EMPTY: 'OV_ITINERARY_V_DESCRIPTION_EMPTY',

        OV_ITINERARY_E_I_ADDMAIN_EMPTY: 'إبدأ بإضافة يوم على رحلتك',
        OV_ITINERARY_E_I_ADDMAIN_ONE: 'أضف يوما آخر',

        //=====================================================================//
        //========== Day Page ==========//
        //=====================================================================//

        //---------- Day Intro ----------//
        INTRO_V_HINT: "<p class='font-bold mt-2'>Reference</p><p>ar.INTRO_V_HINT</p>",
        INTRO_V_HEADTITLE: 'يوم',
        INTRO_V_MENUTITLE: 'ملخص اليوم',
        INTRO_V_TITLE_EMPTY: 'فلنبدأ بعنوان ملهم لهذا اليوم',
        INTRO_V_DESCRIPTION_EMPTY: 'أخبرنا عن هذا اليوم في بضعة أسطر. ماذا سنفعل؟ أين سنذهب؟ أي أكلة شهية سوف نأكل؟',
        INTRO_E_M_TITLE_HINT: "<p class='font-bold mt-2'>مرجع</p><p>ar.INTRO_E_M_TITLE_HINT</p>",
        INTRO_E_M_TITLE_PLACEHOLDER: 'أعط هذا اليوم عنوانا ملهما ',
        INTRO_E_M_DESCRIPTION_HINT: "<p class='font-bold mt-2'>مرجع</p><p>ar.INTRO_E_M_DESCRIPTION_HINT</p>",
        INTRO_E_M_DESCRIPTION_PLACEHOLDER: 'INTRO_E_M_DESCRIPTION_PLACEHOLDER',

        //// Section > What you need  ////////////////////////////////////////////////////////////////

        // View mode
        WHAT_YOU_NEED_V_HEADTITLE: 'قبل أن تبدأ يومك',
        WHAT_YOU_NEED_V_SUBTITLE: 'قائمة تذكير',
        WHAT_YOU_NEED_V_MENUTITLE: 'قائمة تذكير',
        WHAT_YOU_NEED_V_TIP: 'احضر معك',

        WHAT_YOU_NEED_V_HINT: "<p class='font-bold mt-2'>مرجع</p><p>ar.WHAT_YOU_NEED_V_HINT</p>",
        WHAT_YOU_NEED_V_HINT_EMPTY:
            "<p class='mb-6'>لقد استيقظت للتو ، وانتهيت من روتينك الصباحي وأنت متحمس لبدء يومك.<br>تغادر غرفتك ، وتذهب إلى الشوارع وتدرك أن الجو حار في الخارج. <strong>لحظة هل أحضرت قبعة؟ أو واقي من الشمس؟</strong></p><p class=''>قد يجد المسافرون أنفسهم في مواقف مماثلة في كثير من الأحيان. لا يمكنك التنبؤ بها جميعًا ولكن بما أنك كنت في هذه الرحلة من قبل ، فمن الجيد تذكير الأشخاص في <strong>قائمة تذكير</strong> بما يحتاجون إلى إحضاره معهم قبل أن يبدأوا يومهم.</p>",

        WHAT_YOU_NEED_V_DESCRIPTION_EMPTY:
            'استخدم هذه المنطقة لإخبار المسافرين بما قلناه لك للتو ، ولكن بكلماتك الخاصة. ربما بضعة أسطر من قصة مضحكة. أو ربما يكون أمامهم يومًا عصيبًا ويحتاجون إلى الحصول على العناصر التالية.',
        WHAT_YOU_NEED_V_CHEKLIST_EMPTY:
            'إذن ما هي تلك العناصر التي يجب على المرء أن يأخذها في هذا اليوم. قائمة بها. لا تتردد في إضافة روابط إلى هذه العناصر أيضًا (يتم قبول الروابط التابعة)',

        //  Edit mode inline buttons
        WHAT_YOU_NEED_E_I_ADDMAIN: 'أضف قائمة تذكير',
        WHAT_YOU_NEED_E_I_ADDLINK: 'Link in arabic',
        WHAT_YOU_NEED_E_I_ADDTIP: 'Tip 2 in arabic',

        // Modal windows

        // Delete something
        WHAT_YOU_NEED_E_M_DELETE_DESCRIPTION: 'هل أنت متأكد أنك تريد حذف هذا البند؟ لا يمكن التراجع عن هذا الإجراء.',

        //Description box
        WHAT_YOU_NEED_E_M_DESCRIPTION_HINT:
            "<p class='text-sm mb-2'>How many times have you left your hotel room without a hat or sunscreen or a towel only to realize this too late and ruin part of your day?</p><p class='text-sm mb-6'> This happens to the best of us, so just write in your own style about that. You will have another section to add a list of those things. </p><h3 class='font-bold mb-2'>Examples of writing</h3><p>This day is quite intense as we'll be walking a lot. I have 3 words for you. Hydrate 💧, hydrate 💧 and hydrate 💧. Although we might run by villages, it's hard to find clean drinking water. So pack a punch.</p><p class='font-bold mt-2'>مرجع</p><p>ar.WHAT_YOU_NEED_E_M_DESCRIPTION_HINT</p>",
        WHAT_YOU_NEED_E_M_DESCRIPTION_PLACEHOLDER: 'Start writing a description...',

        //Checklist
        WHAT_YOU_NEED_E_M_CHECKLIST_PLACEHOLDER_LABEL: 'Display Name',
        WHAT_YOU_NEED_E_M_CHECKLIST_PLACEHOLDER_URL: 'The Link',
        WHAT_YOU_NEED_E_M_CHECKLIST_HINT:
            "<h3 class='font-bold mb-2'>Examples of Titles</h3><p class='mb-4'>Uber - Road Assistance - Buy tickets</p><h3 class='font-bold mb-2'>Examples of Link usage</h3>If your link is very long, we recommend adding a display name to it. Your <a class='text-green-500' target='_blank' href='https://www.viakonnect.com'>https://www.viakonnect.com</a> link will show as <a class='text-green-500' target='_blank' href='https://www.viakonnect.com'>My Link</a><p class='font-bold mt-2'>مرجع</p><p>ar.WHAT_YOU_NEED_E_M_CHECKLIST_HINT</p>",
        MOVING_AROUND_E_M_CHECKLIST_DESCRIPTION: 'Some description of vendors before adding them',

        //// Section > Moving Around ////////////////////////////////////////////////////////////////

        // View mode
        MOVING_AROUND_V_HEADTITLE: 'كيفية التنقل',
        MOVING_AROUND_V_MENUTITLE: 'كيفية التنقل',
        MOVING_AROUND_V_TIP: 'نصيحة 2',
        MOVING_AROUND_V_DESCRIPTION_EMPTY: 'انقر لإضافة بعض المحتوى',
        MOVING_AROUND_V_HINT: "<p class='font-bold mt-2'>مرجع</p><p>ar.MOVING_AROUND_V_HINT</p>",

        MOVING_AROUND_V_HINT_EMPTY:
            "<p class='mb-6'>حسنًا ، لقد وصلنا إلى وجهتنا. كيف ننتقل من مكان إلى آخر بشكل عام؟</p><p class='mb-6'>قد تتضمن رحلتك عدة زيارات إلى العديد من المدن حيث قد تحتاج إلى الحصول على تذاكر القطار أو الطائرة ، أو قد تكون إقليمية حيث تكون هناك حاجة بالتأكيد لسيارة ، وبالتالي يمكنك أن توصي باستئجار سيارة. أو قد يتعلق الأمر باستخدام دراجات مستأجرة وسكوترات كهربائية وتطبيقات مشاركة الركوب.</p><p>كن دقيقًا. يساعد هذا الأشخاص في الحصول على رؤية بشأن التذاكر التي يحتاجون إلى شرائها ، إن وجدت ، والخدمات التي يتعين عليهم حجزها. ",

        //  Edit mode inline buttons
        MOVING_AROUND_E_I_ADDMAIN: 'أضف وسيلة نقل',
        MOVING_AROUND_E_I_ADDLINK: 'رابط 3',
        MOVING_AROUND_E_I_ADDTIP: 'نصيحة 3',

        // Modal windows

        // Delete something
        MOVING_AROUND_E_M_DELETE_DESCRIPTION: 'هل أنت متأكد أنك تريد حذف هذا البند؟ لا يمكن التراجع عن هذا الإجراء.',

        //Transport type
        MOVING_AROUND_E_M_TYPE_HINT: "<p class='font-bold mb-1'>مرجع</p><p>ar.MOVING_AROUND_E_M_TYPE_HINT</p>",

        //Description box
        MOVING_AROUND_E_M_DESCRIPTION_HINT: "<p class='font-bold mb-1'>مرجع</p><p>ar.MOVING_AROUND_E_M_DESCRIPTION_HINT</p>",
        MOVING_AROUND_E_M_DESCRIPTION_PLACEHOLDER: 'Start writing a description...',

        //Vendor List
        MOVING_AROUND_E_M_VENDOR_PLACEHOLDER_NAME: '',
        MOVING_AROUND_E_M_VENDOR_PLACEHOLDER_LABEL: 'اسم العرض',
        MOVING_AROUND_E_M_VENDOR_PLACEHOLDER_URL: 'الرابط',
        MOVING_AROUND_E_M_VENDOR_HINT:
            "<h3 class='font-bold mb-2'>أمثلة على العناوين</h3><p class='mb-4'>Uber - Road Assistance - Buy tickets</p><h3 class='font-bold mb-2'>أمثلة على استخدام الروابط</h3> إذا كانت الروابط الخاصة بك طويلة جدًا ، نوصي بإضافة اسم عرض إليها. الرابط <a class='text-green-500' target='_blank' href='https://www.viakonnect.com'>https://www.viakonnect.com</a> سيظهر كـ <a class='text-green-500' target='_blank' href='https://www.viakonnect.com'>My Link</a><p class='font-bold mt-2'>مرجع</p><p>ar.MOVING_AROUND_E_M_VENDOR_HINT</p>",
        MOVING_AROUND_E_M_VENDOR_DESCRIPTION: 'عندما يريد العالم أن ‪يتكلّم هو يتحدّث بلغة يونيكود تسجّل الآن لحضور المؤتمر الدولي العاشر ليونيكود',

        //Tip List
        MOVING_AROUND_E_M_TIP_HINT: "<p class='font-bold mt-2'>مرجع</p><p>ar.MOVING_AROUND_E_M_TIP_HINT</p>",
        MOVING_AROUND_E_M_TIP_PLACEHOLDER: 'Start writing a tip...',

        // View mode
        ACTIVITIES_V_HEADTITLE: 'أنشطة اليوم',
        ACTIVITIES_V_MENUTITLE: 'أنشطة اليوم',
        ACTIVITIES_V_TIP: 'ملاحظة',
        ACTIVITIES_V_DESCRIPTION_EMPTY: 'انقر لإضافة بعض المحتوى للأنشطة',
        ACTIVITIES_V_ADDRESS_EMPTY: 'انقر لتحديد الموقع',
        ACTIVITIES_V_HINT: "<p class='font-bold mt-2'>مرجع</p><p>ar.ACTIVITIES_V_HINT</p>",

        ACTIVITIES_V_HINT_EMPTY:
            "<p class='mb-6'>هذا ما تدور حوله رحلتنا ، ما سنفعله ؛ الأنشطة وجميع القصص التي سنختبرها لنرويها.</p><p class='mb-6'> يمكن اعتبار أي شيء تفعله خلال اليوم نشاطًا.<br>هل تبدأ بوجبة الإفطار في الفندق؟ هذا نشاط متعلق بالطعام. هل نستأجر دراجات ونبحر في الساحل؟ هذا نشاط هناك.</p><p class='mb-6'>كن راوي قصص بصري. لا تكن أكاديميًا بمجرد ملء الحقول 'المطلوبة'. فكر في المشاعر التي نشأت عندما كنت تفعل هذا الشيء المحدد. في بعض الأحيان كانت هذه المشاعر شديدة وأحيانًا كانت تتعلق فقط بالمتعة.</p><p class='mb-6'> لا تكن شاعريًا ، كن حقيقيًا.</p>",

        //  Edit mode inline buttons
        ACTIVITIES_E_I_ADDMAIN: 'أضف نشاطًا جديدًا',
        ACTIVITIES_E_I_ADDLINK: 'Activity Link Ar',
        ACTIVITIES_E_I_ADDTIP: 'ملاحظة',

        // Modal windows

        // Delete something
        ACTIVITIES_E_M_DELETE_DESCRIPTION: 'هل أنت متأكد أنك تريد حذف هذا البند؟ لا يمكن التراجع عن هذا الإجراء.',

        //Activity type
        ACTIVITIES_E_M_TYPE_HINT: "<p class='font-bold mb-1'>مرجع</p><p>ar.ACTIVITIES_E_M_TYPE_HINT</p>",

        ACTIVITIES_E_M_TYPE_DESCRIPTION: 'Give this activity a name and choose its type',
        ACTIVITIES_E_M_TYPE_PLACEHOLDER: 'ex: Breakfast at Agra Café',

        // Map
        ACTIVITIES_E_M_MAP_BTN_ADDRESS: 'ابحث عن العنوان',

        ACTIVITIES_E_M_MAP_HINT: "<p class='font-bold mb-1'>Reference</p><p>ar.ACTIVITIES_E_M_MAP_HINT</p>",
        ACTIVITIES_E_M_MAP_PLACEHOLDER_ADDRESS: 'العنوان الكامل بما في ذلك المدينة والبلد',
        ACTIVITIES_E_M_MAP_PLACEHOLDER_AUTOCOMPLETE: 'ex: Café les trois, paris ',

        ACTIVITIES_E_M_MAP_DESCRIPTION:
            'دعنا نساعدك في تثبيت هذا النشاط على الخريطة. ابحث عن مكان أو مكان قريب. قم بالتكبير والنقر على مكان حدوث النشاط. اضغط على زر تخمين العنوان للحصول على عنوان. إذا لم يكن يبدو صحيحًا ، فقم بتحرير حقل العنوان حسب رغبتك.',

        //Description box
        ACTIVITIES_E_M_DESCRIPTION_HINT: "<p class='font-bold mb-1'>مرجع</p><p>ar.ACTIVITIES_E_M_TYPE_HINT</p>",
        ACTIVITIES_E_M_DESCRIPTION_PLACEHOLDER: 'Start writing a description...',

        //Vendor List
        ACTIVITIES_E_M_VENDOR_PLACEHOLDER_NAME: '',
        ACTIVITIES_E_M_VENDOR_PLACEHOLDER_LABEL: 'Display Name',
        ACTIVITIES_E_M_VENDOR_PLACEHOLDER_URL: 'The Link',
        ACTIVITIES_E_M_VENDOR_HINT:
            "<h3 class='font-bold mb-2'>Examples of Titles</h3><p class='mb-4'>Uber - Road Assistance - Buy tickets</p><h3 class='font-bold mb-2'>Examples of Link usage</h3>If your link is very long, we recommend adding a display name to it. Your <a class='text-green-500' target='_blank' href='https://www.viakonnect.com'>https://www.viakonnect.com</a> link will show as <a class='text-green-500' target='_blank' href='https://www.viakonnect.com'>My Link</a><p class='font-bold mt-2'>مرجع</p><p>ar.ACTIVITIES_E_M_TIP_HINT</p>",
        ACTIVITIES_E_M_VENDOR_DESCRIPTION: 'Some description of activity vendors before adding them in ar',

        //Tip List
        ACTIVITIES_E_M_TIP_HINT: "<p class='font-bold mb-1'>مرجع</p><p>ar.ACTIVITIES_E_M_TIP_HINT</p>",
        ACTIVITIES_E_M_TIP_PLACEHOLDER: 'Start writing ana activity tip in ar...',

        //Google maps
        GOOGLE_MAP_NOT_INITIALISED: 'Google map loading',
        GOOGLE_MAP_INITIALISE_FAIL: 'There seems to be a problem with google maps'
    }
};

export default langData;
