import React, { useState, useEffect } from 'react';
import {  isEqual } from 'lodash';
import { BlockInputSingle } from 'components/blocks/Blocks';
import { Calendar } from 'react-nice-dates';
import { enGB } from 'date-fns/locale';
import 'react-nice-dates/build/style.css';
import './calendar.css';
import GenericBtn from 'components/blocks/GenericBtn';

/* eslint-disable react/jsx-pascal-case */
function CalenderSelect({
    closeCal,
    handleUpdateDefaults,
    selected,
    dataObj,
    handleDayClick,
    modifiers,
    originalData
}) {
    const modifiersClassNames = {
        highlight: 'has-booking',
        disabledColor: 'date_used_already'
    };

    const [disableUpdate, setDisableUpdate] = useState(false);

    useEffect(() => {
        setDisableUpdate(false);
        if(!originalData && dataObj[selected].days.length === 0) {
            setDisableUpdate(true);
        } else if(isEqual(originalData, dataObj[selected])) {
            setDisableUpdate(true);
        }
    }, [dataObj])

    return (
        <div className='flex px-6 gap-12'>
            <div className='flex gap-4 w-1/2'>
                <div className='w-full mt-10'>
                    <div className=' pb-8 mb-8 text-sm leading-7 border-b border-gray-200'>
                        How do we add dates? Select dates from the calendar that
                        have something in common, for example, 5 different dates
                        that share the same price, or same capacity. Once you've
                        selected them, type in the Max capacity and price and
                        click on Add Date Group. The selected dates will be
                        added in the main window but still giving you the
                        ability to edit each one individually.
                    </div>
                    <div className='flex flex-1 flex-col gap-6 mb-4 w-2/3'>
                        {/* <ListBoxGeneric
                                            color='bg-white'
                                            listData={statusList.current}
                                            val={selectedStatus}
                                            handleChange={setSelectedStatus}
                                            label='Experience Status'
                                            height='h-10'
                                            fontSize='text-sm'
                                            textClass='text-sm'
                                            labelWidth='w-48'
                                            labelPos='top'
                                            direction='top2'
                                        /> */}

                        <BlockInputSingle
                            label='Group name'
                            labelPos='top'
                            className='text-xs'
                            height='h-10'
                            width='w-full'
                            rounded='rounded-lg'
                            handleChange={handleUpdateDefaults}
                            id={`name${selected}`}
                            name='name'
                            value={dataObj[selected].name}
                            placeholder={'ex: High Season August'}
                        />
                        <BlockInputSingle
                            label='Max Capacity per Date'
                            labelPos='top'
                            className='text-xs'
                            height='h-10'
                            width='w-full'
                            rounded='rounded-lg'
                            handleChange={handleUpdateDefaults}
                            id={`capacity${selected}`}
                            name='capacity'
                            value={dataObj[selected].capacity}
                            type={'number'}
                        />
                        <BlockInputSingle
                            label='Price per person'
                            labelPos='top'
                            className='text-xs'
                            height='h-10'
                            width='w-full'
                            rounded='rounded-lg'
                            handleChange={handleUpdateDefaults}
                            id={`price${selected}`}
                            name='price'
                            value={dataObj[selected].price}
                            type={'number'}
                        />

                        <div className='flex items-center gap-4 justify-center'>
                            <GenericBtn
                                bgColor='bg-green-400 hover:bg-gray-900'
                                className='font-semibold'
                                params={[true]}
                                handleActionBtn={closeCal}
                            >
                                Cancel
                            </GenericBtn>
                            <GenericBtn
                                disabled={disableUpdate}
                                bgColor='bg-green-400 hover:bg-gray-900'
                                className='font-semibold'
                                params={[false]}
                                handleActionBtn={closeCal}
                            >
                                Add Dates
                            </GenericBtn>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className='w-1/2'
                // style={{
                //     width: '320px',
                //     height: '270px',
                //     position: 'absolute',
                //     top: 20,
                //     right: 20,
                //     background: 'white'
                // }}
            >
                <Calendar
                    onDayClick={handleDayClick}
                    modifiers={modifiers}
                    modifiersClassNames={modifiersClassNames}
                    locale={enGB}
                />
            </div>
        </div>
    );
}

export default CalenderSelect;
