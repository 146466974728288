import moment from 'moment';

export function createPurchaseCsv (data=[], guided=false) {
    const lastRow = {};
    let grandTotal = 0;
    let discountTotal = 0;
    let overalSubTotal = 0;
    let jsonData =  data.map((purch) => {
        const row = {};
        const { 
            id,
            createdAt,
            title,
            travel_date,
            canceled,
            order: {
                items, // items is an array but will ony ever have 1 entry per order
                sub_total: subTotal,
                discount_total: purchaseDiscountTotal,
                grand_total: purchaseTotal
            },
            payout
            // user
        } = purch;
        const { price } = items[0];

        row.id = id;
        row['Order Date'] = `${moment(createdAt).format('MMM Do YYYY')}`;
        if(guided) row['Booking Date'] = `${moment(travel_date).format('MMM Do YYYY')}`;
        row.title = title;
        row.price = price;
        row.quantity = 1;

        row['Sub Total'] = subTotal;
        row.discount = purchaseDiscountTotal;
        row['Grand Total'] =`${purchaseTotal}`;
        row['canceled/refunded'] = canceled ? 'refunded' : '';
        
        if(!canceled) {
            overalSubTotal = overalSubTotal + Number (subTotal);
            discountTotal = discountTotal + Number(purchaseDiscountTotal);
            grandTotal = grandTotal + Number(payout || 0);
        }

        return row;
    });

    lastRow.id = '';
    lastRow['Order Date'] = '';
    if(guided) lastRow['Booking Date'] = '';
    lastRow.title = '';
    lastRow.price = '';
    lastRow.quantity = '';
    lastRow['Sub Total'] = overalSubTotal;
    lastRow.discount = discountTotal;
    lastRow['Grand Total'] = grandTotal;

    jsonData = [...jsonData, lastRow];

    return JSON.stringify(jsonData);
}
