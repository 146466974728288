import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ModalContainer, {
    ModalBody,
    ModalFooter,
    ModalCTA,
    ModalButton,
    ModalHelp
} from 'components/blocks/ModalTre';
import { fetchPublishedPriceData, updateDigitalPrice } from 'fetch/experiences';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { BlockInputSingle } from 'components/blocks/Blocks';
import Spinner from 'components/blocks/Spinner';

// should be in all modals
const targetElement = document.querySelector('#root');

function DigitalPriceModal({
    expId,
    isAdminStaff = false,
    isOwner = true,
    close,
    setModalIsOpen,
    globalState: { lang, isSuperAdmin },
    icon = uiStruct.ui.modals.editor.icon,
    hintObj = null,
    placeholder = null,
    actionBtnObj = uiStruct.ui.modals.editor.buttons.action,
    cancelBtnObj = uiStruct.ui.modals.editor.buttons.cancel,
    children,
    type = 'text',
    width = '3xl',
    inputIcon,
    fetchPublishedPriceData,
    updateDigitalPrice
}) {
    //
    const rtl = !!translations[lang].rtl;
    const [loadingData, setLoadingData] = useState(true);
    const originalPrice = useRef(0);
    const [dataObj, updateDataObj] = useState({
        errorObj: {
            error: false,
            msg: ''
        },
        skuId: null,
        price: 0
    }); // set to a default or editing

    // Copy Below to all modals
    //////////////////////////////////////////////////////////////////////

    // State for triggering animation of modal in and out

    const [transitionState, setTransitionState] = useState('off');
    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    ///////////////////////////////////////////////////////////////////

    // Paul stuff
    const updateDescriptionHtml = (e) => {
        const price = Number(e.target.value);

        updateDataObj({
            ...dataObj,
            errorObj: {
                error: price < 0, 
                msg: ''
            },
            price: Number(e.target.value)
        });

    };

    // Paul stuff Handle Action BTN function
    const handleActionClick = () => {
        setLoadingData(true);
        updateDigitalPrice(expId, dataObj.price).then(() => {
            setLoadingData(false);
            handleTransitionStateOff();
        })
    };


    useEffect(() => {
        fetchPublishedPriceData(expId, 'digital').then((res) => {
            setLoadingData(false);
            if(res?.value?.data?.count) {
                const { price } = res.value.data.results[0];

                originalPrice.current = price;
                updateDataObj({
                    errorObj: {
                        error: false,
                        msg: ''
                    },
                    price
                })
            } else {
                setModalIsOpen(false);
            }
        });

    }, [])

    ////////////////////////////////

    return (
        <>
            <ModalContainer
                width={width}
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={icon}
                close={close}
                rtl={rtl}
            >
                <ModalBody rtlOff>
                    {children}
                    {!loadingData 
                    ? <BlockInputSingle
                        normal
                        error={dataObj.errorObj.error}
                        handleChange={updateDescriptionHtml}
                        id='name'
                        margins='mb-4'
                        value={dataObj.price}
                        placeholder={
                            translations[lang][placeholder]
                        }
                        rtl={rtl}
                        type={type}
                        icon={inputIcon}
                        fontSize='text-xl'
                    />
                    : null}
                </ModalBody>

                <ModalFooter>
                    {hintObj && (
                        <ModalHelp
                            helpText={translations[lang][hintObj]}
                            isHtml
                        />
                    )}
                    {(isOwner || (isAdminStaff && isSuperAdmin)) && !loadingData ? (
                        <ModalCTA>
                            <ModalButton
                                handleClick={handleTransitionStateOff}
                                label={translations[lang][cancelBtnObj.label]}
                                color={cancelBtnObj.color}
                                icon={cancelBtnObj.icon}
                            />
                            <ModalButton
                                isDisabled={dataObj.errorObj.error ||  (originalPrice.current === dataObj.price && dataObj.skuId)}
                                handleClick={handleActionClick}
                                label={translations[lang][actionBtnObj.label]}
                                color={actionBtnObj.color}
                                icon={actionBtnObj.icon}
                            />
                        </ModalCTA>
                    ): 
                    <div className='flex flex-col justify-center items-center w-full'>
                            <span className='flex justify-center'>
                                <Spinner
                                    size='64'
                                    strokeWidth='20'
                                    strokeColor={'black'}
                                />
                            </span>
                    </div>}
                </ModalFooter>
            </ModalContainer>
        </>
    );
}

// fetchPublishedPriceData
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchPublishedPriceData,
            updateDigitalPrice
        },
        dispatch
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, mapDispatchToProps)(DigitalPriceModal);
