import classNames from 'classnames';

const socialIcons = {
    instagram: {
        type: 'icon',
        value: 'ri-instagram-line text-xl',
        order: 1
    },
    facebook: {
        type: 'icon',
        value: 'ri-facebook-fill text-xl',
        order: 2
    },

    youtube: {
        type: 'icon',
        value: 'ri-youtube-line text-2xl',
        order: 3
    },

    twitter: {
        type: 'icon',
        value: 'ri-twitter-fill text-xl',
        order: 4
    },
    tiktok: {
        type: 'text',
        value: 'Tiktok',
        order: 5
    },

    website: {
        type: 'text',
        value: 'www',
        order: 6
    }
};

// const SocialMediaKreator = ({ social }) => {
//     return (
//         <div className='flex items-center gap-2 text-xl'>
//             {social &&
//                 Object.keys(social)
//                     .filter((item) => social[item])
//                     .map((item) => (
//                         <a
//                             target='_blank'
//                             noreferrer
//                             href={social[item]}
//                             className={classNames(
//                                 'hover:text-green-400 border-2',
//                                 socialIcons[item].type !== 'icon'
//                                     ? 'rounded-full border-gray-600 hover:border-green-400 flex h-6 px-2 items-center -mt-1'
//                                     : 'border-transparent'
//                             )}
//                         >
//                             {socialIcons[item].type === 'icon' ? (
//                                 <i className={classNames(socialIcons[item].value)}></i>
//                             ) : (
//                                 <span className='text-xs'>{socialIcons[item].value}</span>
//                             )}
//                         </a>
//                     ))}
//         </div>
//     );
// };

const SocialMediaKreator = ({ social }) => {
    return (
        <div className='flex items-center gap-2 text-xl'>
            {social?.website && <SocialLink social={social} item='website' />}
            {social?.tiktok && <SocialLink social={social} item='tiktok' />}
            {social?.instagram && <SocialLink social={social} item='instagram' />}
            {social?.facebook && <SocialLink social={social} item='facebook' />}
            {social?.youtube && <SocialLink social={social} item='youtube' />}
            {social?.twitter && <SocialLink social={social} item='twitter' />}
        </div>
    );
};

export default SocialMediaKreator;

const SocialLink = ({ social, item }) => {
    return socialIcons[item] ? (
        <a
            target='_blank'
            rel='noreferrer'
            href={social[item]}
            className={classNames(
                'hover:text-green-400 border-2',
                socialIcons[item].type !== 'icon'
                    ? 'rounded-full border-gray-600 hover:border-green-400 flex h-6 px-2 items-center -mt-1'
                    : 'border-transparent'
            )}
        >
            {socialIcons[item]?.type === 'icon' ? (
                <i className={classNames(socialIcons[item]?.value)}></i>
            ) : (
                <span className='text-xs'>{socialIcons[item]?.value}</span>
            )}
        </a>
    ) : null;
};
