/* eslint-disable react-hooks/exhaustive-deps */
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import GenericSelectList from 'components/blocks/GenericSelectList';
import { setFilterStatus } from 'store/actions/globalState/master';
import GenericBtn from './GenericBtn';
import { useOnlineStatus } from 'helpers/useOnlineStatus';

function ExperienceFilters({
    usersData = [],
    statusData = [],
    typeData = [],
    listData = [],
    isPublishedData = [],
    extraList = [],
    countrySelect = true,
    setDataCopy,
    setFilterStatus,
    filtersStatuses,
    countryList
}) {
    const isOnline = useOnlineStatus();

    const updateStatusFilter = (filterStr) => {
        setFilterStatus('status', filterStr);
    };
    const setFilterType = (filterStr) => {
        setFilterStatus('type', filterStr);
    };
    const updateUserFilter = (filterStr) => {
        setFilterStatus('user', filterStr);
    };
    const updateCountryFilter = (filterStr) => {
        setFilterStatus('country', filterStr);
    };
    const updatePublishedFilter = (filterStr) => {
        setFilterStatus('published', filterStr.toLowerCase());
    };
    const updateExtraFilter = (filterStr) => {
        setFilterStatus('extra', filterStr);
    };
    const updateExtraOrderBy = () => {
        const {
            extra: { orderType }
        } = filtersStatuses;
        setFilterStatus('order', orderType === 'asc' ? 'desc' : 'asc');
    };

    const {
        status,
        type,
        user,
        country,
        published,
        extra: { extraSelected, orderType }
    } = filtersStatuses;

    return (
        <div className='relative px-4 lg:px-0'>
            <div className='mt-10 flex flex-col md:flex-row gap-y-4 md:gap-y-0 md:gap-x-4 mb-10 bg-white shadow-cards rounded-xl px-8 pb-8 pt-12 relative'>
                <div className='w-min absolute top-0 left-1/2 transform -translate-x-1/2 italic -translate-y-1/2 whitespace-nowrap font-bold tracking-tight flex flex-none justify-center items-center bg-green-200 rounded-full px-6 text-green-700 h-8'>
                    Filters
                </div>
                {typeData.length ? (
                    <div className='md:w-1/4'>
                        <div className='text-sm px-4 mb-4'>By type</div>
                        <GenericSelectList
                            disabled={!isOnline}
                            initialSelected={type}
                            selectOptions={typeData}
                            handleChange={setFilterType}
                        />
                    </div>
                ) : null}
                {statusData.length ? (
                    <div className='md:w-1/4'>
                        <div className='text-sm px-4 mb-4'>By status</div>
                        <GenericSelectList
                            disabled={!isOnline}
                            initialSelected={status}
                            selectOptions={statusData}
                            handleChange={updateStatusFilter}
                        />
                    </div>
                ) : null}
                {isPublishedData.length ? (
                    <div className='md:w-1/4'>
                        <div className='text-sm px-4 mb-4'>
                            By published status
                        </div>
                        <GenericSelectList
                            disabled={!isOnline}
                            initialSelected={published}
                            selectOptions={isPublishedData}
                            handleChange={updatePublishedFilter}
                        />
                    </div>
                ) : null}
                {usersData.length ? (
                    <div className='md:w-1/4'>
                        <div className='text-sm px-4 mb-4'>By user</div>
                        <GenericSelectList
                            disabled={!isOnline}
                            initialSelected={user}
                            selectOptions={usersData}
                            handleChange={updateUserFilter}
                            keys={{ id: 'id', label: 'username' }}
                        />
                    </div>
                ) : null}
                {countrySelect ? (
                    <div className='md:w-1/4'>
                        <div className='text-sm px-4 mb-4'>By destination</div>
                        <GenericSelectList
                            disabled={!isOnline}
                            initialSelected={country}
                            selectOptions={countryList}
                            handleChange={updateCountryFilter}
                            keys={{ id: 'id', label: 'name' }}
                        />
                    </div>
                ) : null}
            </div>
            {extraList.length ? (
                <div className='md:absolute md:right-4 lg:right-2 top-52 mb-4'>
                    <div className='flex items-center justify-end gap-4'>
                        <div className='text-xs'>Sort by</div>
                        <div>
                            <GenericBtn
                                disabled={!isOnline}
                                params={[]}
                                handleActionBtn={updateExtraOrderBy}
                            >
                                {orderType === 'asc' ? (
                                    <i className='ri-sort-desc'></i>
                                ) : (
                                    <i className='ri-sort-asc'></i>
                                )}
                            </GenericBtn>
                        </div>
                        <div className='w-48'>
                            <GenericSelectList
                                disabled={!isOnline}
                                initialSelected={extraSelected}
                                selectOptions={extraList}
                                keys={{ id: 'id', label: 'label' }}
                                handleChange={updateExtraFilter}
                                bgColor='#ffffff'
                                height='2.5rem'
                            />
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setFilterStatus
        },
        dispatch
    );
}

const mapStateToProps = (state) => ({
    filtersStatuses: state.globalState.filters,
    auth: state.auth
});

export default connect(mapStateToProps, mapDispatchToProps)(ExperienceFilters);
