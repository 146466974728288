import React, { useRef } from 'react';
import moment from 'moment';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as types from 'store/actions/types';
import { feedbackStyling } from 'constants/feedbackStyling';

const FeedbackElement = ({ experienceDetailsAction, isFeedbackMode, isAdminStaff, feedback_object, objId, handleScroll, modal, chosen = 'remaining' }) => {
    const elementRef = useRef();

    const completeComment = (index) => {
        experienceDetailsAction({
            type: `${types.UPDATE_COMMENT}`,
            objId,
            index
        });
    };

    const deleteComment = (index) => {
        // console.log('tried to delete with index', index);
        experienceDetailsAction({
            type: `${types.DELETE_COMMENT}`,
            objId,
            index
        });
    };

    // const scrollToElement=(myRef)=>{
    //     handleScroll(myRef)
    // }

    return (
        <div className={`px-4 pb-4 ${feedbackStyling.cardBg[chosen]}`}>
            <div className='py-4 flex justify-between'>
                <div>
                    <div className='text-sm'>Feedback</div>
                    <div style={{ fontSize: '8px' }}>{`id: ${objId}`}</div>
                </div>
                {!modal && (
                    <button onClick={() => handleScroll(objId)} className={`focus:outline-none outline-none ${feedbackStyling.text[chosen]} hover:text-black`}>
                        <i className='text-lg ri-link '></i>
                    </button>
                )}
            </div>
            <div className='overflow-y-auto max-h-64'>
                <div className='flex  flex-col-reverse' ref={elementRef}>
                    {feedback_object?.data?.length > 0 &&
                        feedback_object?.data.map((elm, index) => {
                            return (
                                <div key={`feedback_elm_${index}`} className={`text-xs  border-t ${feedbackStyling.border[chosen]} px-2`}>
                                    <div className='flex justify-between items-center  mt-4 '>
                                        <div className='flex items-center gap-2 '>
                                            <div className='text-xxs bg-gray-900 text-white font-semibold px-2 py-0.5 rounded-md '>
                                                {moment(elm.date).format('MMM Do YYYY')}
                                            </div>
                                            <div className='text-xxs font-semibold '>{moment(elm.date).format('HH:mm')}</div>
                                            <div className='text-xxs font-semibold '>{`by ${elm.author}`}</div>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            {isAdminStaff && (
                                                <button
                                                    onClick={() => deleteComment(index)}
                                                    className={`focus:outline-none outline-none ${feedbackStyling.text[chosen]} hover:text-black`}
                                                >
                                                    <i className='text-lg ri-delete-bin-line '></i>
                                                </button>
                                            )}
                                            <button
                                                onClick={() => completeComment(index)}
                                                className={`focus:outline-none outline-none ${elm.completed ? 'text-green-400' : 'text-yellow-400'} `}
                                            >
                                                <i className='text-xl ri-checkbox-circle-line '></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        className={`mt-2 mb-4 ${elm.completed ? 'line-through' : ''}`}
                                        dangerouslySetInnerHTML={{
                                            __html: elm.text
                                        }}
                                    />
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            experienceDetailsAction
        },
        dispatch
    );
}

export default connect(null, mapDispatchToProps)(FeedbackElement);
