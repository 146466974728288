/* eslint-disable react/jsx-pascal-case */

import { CardXlDragHandleItem } from 'components/blocks/CardXlDrag';

import uiStruct from 'constants/uiStruct';

import CardXlEditDelete from './CardXlEditDelete';
import PanelMarketing from 'components/blocks/PanelMarketing';
import FeedbackBox from './FeedbackBox';

const IncludeItem = ({ deleteClick, index, edit, isFeedbackMode, provided = {}, rtl, itemData, handleUpdateAction, includeType }) => {
    const { title, desc, objId } = itemData;
    const deleteAction = () => {
        deleteClick(index);
    };
    const handleEdit = () => {
        handleUpdateAction(index);
    };

    const pillClass = includeType === 'includes' ? 'ri-check-line text-xl text-green-500' : 'ri-close-line text-xl text-red-500';

    const pillObj = {
        subClass: includeType === 'includes' ? 'kn-include' : 'kn-exclude',
        class: includeType === 'includes' ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'
    };

    return (
        <div
            className={`mb-4 sm:items-start relative flex group flex-col  sm:flex-row py-5 px-4 w-full  bg-gray-50 
             transition-colors ease-in-out duration-300 rounded-2xl `}
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            <div className={`w-full h-full ${edit ? '' : ''}`}>
                {edit && !isFeedbackMode && (
                    <>
                        <CardXlEditDelete
                            handleDelete={deleteAction}
                            handleEdit={handleEdit}
                            colors={uiStruct.ui.tip_list.UI_LBL_GREEN}
                            rtl={rtl}
                            close='center'
                            rounded='rounded-xl'
                        />
                    </>
                )}
                <CardXlDragHandleItem dragOff={!edit || isFeedbackMode} dragProps={provided.dragHandleProps} rtl={rtl} />

                <div className='flex flex-col md:flex-row md:items-start gap-4 md:gap-0 whitespace-normal'>
                    <div className='flex items-center'>
                        <span className={`px-4 py-1 ${pillObj.class} text-sm rounded-full whitespace-nowrap font-bold italic mr-4`}>{title}</span>
                    </div>
                    <div className='px-2 flex w-full '>
                        <div
                            className={`mr-4 text-sm underline-none w-full block-html mt-1 -mb-2 ${pillObj.subClass}`}
                            dangerouslySetInnerHTML={{
                                __html: desc
                            }}
                        />
                    </div>
                </div>

                {/* <div className='flex flex-col gap-4'>
                        <div className='flex items-center'>
                                <span className='px-4 py-1 bg-blue-200 text-blue-800 text-sm rounded-full whitespace-nowrap font-bold italic mr-4'>
                                    {title}
                                </span>
                                <span className='mr-4'>
                                    {includeType === 'includes' && (
                                        <i className='ri-check-line text-xl text-green-500'></i>
                                    )}
                                    {includeType === 'excluded' && (
                                        <i className='ri-close-line text-xl text-red-500'></i>
                                    )}
                                </span>
                            </div>
                        <div className='px-2 md:pl-12 md:pr-12 flex '>
                            <div
                                className='mr-4 text-sm underline-none block-html'
                                dangerouslySetInnerHTML={{
                                    __html: desc
                                }}
                            />
                        </div>
                    </div> */}
            </div>

            {objId && (
                <FeedbackBox
                    objId={objId}
                    // zLevel=''
                    label='List item'
                    posY='bottom-full'
                    labelPosX='left-24'
                    // root
                />
            )}
        </div>
    );
};

export default IncludeItem;
