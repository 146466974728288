import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CurrencyVendorsModal from 'components/modals/overview/CurrencyVendorsModal';
import { updateLocalCurrencyVendors } from 'store/actions/experienceDetails/currency';
import CurrencyVendors from './CurrencyVendors';

function CurrencyVendorsList ({
    index,
    vendors,
    updateLocalCurrencyVendors
}) {
    const [updateIndex, setUpdateIndex] = useState(null);
    const [vendorsModalState, setVendorsModalState] = useState(false);
    const handleCreateVendors = () => {
        setVendorsModalState(true);
        setUpdateIndex(null);
    };
    

    const updateCurrencyVendors = (dataObj) => {
        updateLocalCurrencyVendors(index, dataObj);
    }

    return (
        <div>
            {!vendors.length && <button onClick={handleCreateVendors}>Add Currency type</button>}
            <CurrencyVendors
                index={index}
                vendors={vendors}
                handleEdit={setVendorsModalState}
            />
            {vendorsModalState
            && <CurrencyVendorsModal
                setModalIsOpen={setVendorsModalState}
                handleActionBtn={updateCurrencyVendors}
                updateObj={vendors}
                update={updateIndex !== null}
            />}
        </div>
    );
}
const mapStateToProps = (state) => ({
    globalState: state.globalState,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateLocalCurrencyVendors
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyVendorsList);