/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ModalContainer, {
    ModalBody,
    ModalFooter,
    ModalCTA,
    ModalButton,
    ModalHelp
} from 'components/blocks/ModalTre';
import LinkTitle from 'components/blocks/LinkTitle';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { _GETTING_THERE, _ACTIVITIES } from 'store/actions/types';
import { testValidUrl } from 'helpers/FEutils';
import ModalTopBlock from 'components/blocks/ModalTopBlock';

// should be in all modals
const targetElement = document.querySelector('#root');

function VendorsModal({
    actionType = {},
    modalTitle,
    modalSubTitle,
    close,
    setModalIsOpen,
    handleActionBtn,
    updateObj = null,
    update = false,
    globalState: { lang },
    icon = uiStruct.itinerary.moving_around.uiEdit.modals.vendor.icon,

    actionBtnObj = uiStruct.itinerary.moving_around.uiEdit.modals.vendor.buttons
        .action,
    cancelBtnObj = uiStruct.itinerary.moving_around.uiEdit.modals.vendor.buttons
        .cancel,
    labels
}) {
    //

    const rtl = !!translations[lang].rtl;
    const defaultDataObj = {
        name: '',
        url: [
            {
                label: '',
                url: ''
            }
        ]
    };
    const defaultUrl = {
        label: '',
        url: ''
    };
    const isGettingThereType = actionType === _GETTING_THERE || _ACTIVITIES;
    const [dataObj, updateDataObj] = useState(() =>
        update ? updateObj : defaultDataObj
    ); // set to a default or editing

    // validation stuff

    const createValidationObj = (testObj) => {
        const valObj = JSON.parse(JSON.stringify(testObj));
        valObj.name =
            testObj.name.length === 0
                ? { error: true, message: '' }
                : { error: false, message: '' };
        if (!isGettingThereType) {
            testObj.url.forEach((obj, index) => {
                if (obj.url.length === 0) {
                    valObj.url[index].url = { error: true, message: '' };
                } else if (!testValidUrl(obj.url)) {
                    valObj.url[index].url = {
                        error: true,
                        message: 'Invalid url'
                    };
                } else {
                    valObj.url[index].url = { error: false, message: '' };
                }
            });
        } else if (isGettingThereType) {
            testObj.url.forEach((obj, index) => {
                if (obj.url.length && !testValidUrl(obj.url)) {
                    valObj.url[index].url = {
                        error: true,
                        message: 'Invalid url'
                    };
                } else {
                    valObj.url[index].url = { error: false, message: '' };
                }
                if (!obj.label.length) {
                    valObj.url[index].label = {
                        error: true,
                        message: 'Must have a label'
                    };
                } else {
                    valObj.url[index].label = { error: false, message: '' };
                }
            });
        }

        return valObj;
    };

    const [validationObj, setValidationObj] = useState(
        createValidationObj(dataObj)
    );
    const [saveAttempted, setSaveAttempted] = useState(false);

    useEffect(() => {
        setValidationObj(createValidationObj(dataObj));
    }, [dataObj]);

    const validateForm = () => {
        if (validationObj.name.error) {
            return false;
        }
        for (let i = 0; i < validationObj.url.length; i++) {
            if (
                validationObj.url[i].url.error ||
                validationObj.url[i].label.error
            ) {
                return false;
            }
        }

        return true;
    };

    // State for triggering animation of modal in and out
    const [transitionState, setTransitionState] = useState('off');
    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    ///////////////////////////////////////////////////////////////////

    // Paul stuff Handle Action BTN function
    const handleActionClick = () => {
        setSaveAttempted(true);
        if (validateForm()) {
            handleActionBtn(dataObj);
            handleTransitionStateOff(); // important... don't forget to add it in all modals
        }
    };

    // add / edit / delete on the modal

    const updateVendorUrls = (type, index, name, val) => {
        if (type === 'url') {
            updateDataObj({
                ...dataObj,
                url: dataObj.url.map((obj, i) => {
                    if (i === index) {
                        return {
                            ...obj,
                            [name]: name === 'url' ? val.replace(/ /g, '') : val
                        };
                    }
                    return obj;
                })
            });
        } else {
            updateDataObj({
                ...dataObj,
                name: val
            });
        }
    };

    const addVendorUrls = () => {
        const newObj = {
            ...dataObj,
            url: [...dataObj.url, defaultUrl]
        };

        setValidationObj(createValidationObj({ ...newObj }));
        updateDataObj({ ...newObj });
        setSaveAttempted(false);
    };

    const deleteVendorUrls = (index) => {
        const newObj = {
            ...dataObj,
            url: dataObj.url.filter((item, i) => i !== index)
        };
        setValidationObj(createValidationObj({ ...newObj }));
        updateDataObj({ ...newObj });
        setSaveAttempted(false);
    };

    ///////////////////////

    return (
        <>
            <ModalContainer
                width='3xl'
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={icon}
                close={close}
                rtl={rtl}
            >
                <ModalBody rtlOff maxheight='screen-2/3'>
                    {modalTitle && (
                        <div className='w-full px-2 mt-4'>
                            <ModalTopBlock
                                title={modalTitle}
                                subtitle={modalSubTitle}
                                text={labels.labelTitle}
                                rtl={rtl}
                                lang={lang}
                            />
                        </div>
                    )}

                    <LinkTitle
                        actionType={actionType}
                        saveAttempted={saveAttempted}
                        validationObj={validationObj}
                        data={dataObj}
                        updateVendorUrls={updateVendorUrls}
                        deleteVendorUrls={deleteVendorUrls}
                        addVendorUrls={addVendorUrls}
                        labels={labels}
                    />
                </ModalBody>

                <ModalFooter>
                    <ModalHelp
                        isHtml
                        helpText={
                            translations[lang][
                                uiStruct.itinerary.moving_around.uiEdit.modals
                                    .vendor.hint
                            ]
                        }
                    />
                    <ModalCTA>
                        <ModalButton
                            handleClick={handleTransitionStateOff}
                            label={translations[lang][cancelBtnObj.label]}
                            color={cancelBtnObj.color}
                            icon={cancelBtnObj.icon}
                        />
                        <ModalButton
                            handleClick={handleActionClick}
                            label={translations[lang][actionBtnObj.label]}
                            color={actionBtnObj.color}
                            icon={actionBtnObj.icon}
                        />
                    </ModalCTA>
                </ModalFooter>
            </ModalContainer>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(VendorsModal);
