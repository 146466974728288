import React from 'react';
import { Route, Navigate } from 'react-router-dom';

import { connect } from 'react-redux';

export const SemiPublicRoute = ({
    component: Component,
    auth: { isAuthenticated, loading },
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={(
                props // if isAuthenticated === null it hasnt finished authenticating
            ) =>
                isAuthenticated !== null && !isAuthenticated && !loading ? (
                    <Navigate to='/accounts/signup' />
                ) : (
                    isAuthenticated !== null && <Component {...props} />
                )
            }
        />
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(SemiPublicRoute);
