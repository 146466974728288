import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CardXl from 'components/blocks/CardXl';
import translations from 'constants/translations';
import GenericGallery from 'components/blocks/GenericGallery';
import uiStruct from 'constants/uiStruct';
import GenericTitle from 'components/blocks/GenericTitle';
import GenericDescription from 'components/blocks/GenericDescription';
import TipList from 'components/blocks/TipList.js';

import ExpSubsection from 'components/blocks/ExpSubsection';
import { capitalize, kreatorName } from 'helpers/FEutils';

import CollapseButton from 'components/blocks/CollapseButton';
import AccommodationList from 'components/blocks/marketing/AccommodationList';
import HotelMap from 'components/map/HotelMap';

function SectionWhereToStay({
    actionType,
    experienceDetails: {
        experience,
        id,
        days,
        budget_min,
        budget_max,
        type,
        cats_list: tmpCats,
        places_lists: tmpPlaces,
        user,
        user: { profile }
    },

    marketingData: {
        whereToStay: { title, subtitle, desc, gallery: images, tips, objId }
    },
    globalState: { lang, edit }
}) {
    const rtl = !!translations[lang].rtl;
    const {
        accommodation: { isVisible: accommodationVisible }
    } = experience;
    return (
        <>
            {accommodationVisible && (
                <ExpSubsection>
                    <div className='marketing-title'>Where you'll stay</div>
                    {/* <GenericTitle
                    nohover
                    canBeEmpty={false}
                    actionType={actionType}
                    titleTxt={title}
                    defaultTxt={'Title'}
                    hintObj={''}
                    placeholder={'Place holder txt'}
                    icon={uiStruct.itinerary.intro.uiEdit.modals.title.icon}
                    tour='tour-ov-intro-title'
                />
                <GenericTitle
                    nohover
                    canBeEmpty={false}
                    actionPrefix={`UPDATE_SUBTITLE`}
                    actionType={actionType}
                    titleTxt={subtitle}
                    defaultTxt={'Subtitle'}
                    hintObj={''}
                    placeholder={'Place holder txt'}
                    icon={uiStruct.itinerary.intro.uiEdit.modals.title.icon}
                    tour='tour-ov-intro-title'
                /> */}

                    <div className={`${edit ? 'py-4' : 'py-4'}`}>
                        <GenericDescription
                            actionType={actionType}
                            descriptionHtml={desc}
                            objId={objId?.description}
                            title="Where you'll stay"
                            subtitle='Description'
                        >
                            {desc ? (
                                <div
                                    className={` block-html leading-7 ${
                                        desc ? 'text-gray-800' : 'italic'
                                    }text-sm md:text-base`}
                                    dangerouslySetInnerHTML={{
                                        __html: desc
                                    }}
                                />
                            ) : (
                                <div
                                    className={` leading-7 text-gray-400 italic text-sm md:text-base`}
                                >
                                    Tell us a bit about where we'll be staying.
                                    Add images if you want.
                                </div>
                            )}
                        </GenericDescription>
                    </div>

                    <div
                        className={` w-full ${
                            images?.length > 0 ? 'min-h-10 mb-10' : ''
                        } ${edit ? 'mt-16' : ''}`}
                    >
                        {/* {edit && <CarouselContainer slides={expImages} />} */}
                        <div className='relative '>
                            <GenericGallery
                                actionType={actionType}
                                images={images}
                                lang={lang}
                                edit={edit}
                                slider={false}
                                max={20}
                                className=''
                                modalTitle="Marketing - Where you'll go and What you'll do"
                                scroll={4}
                                showThumnails={true}
                                objId={objId?.gallery}
                            >
                                <div className='absolute left-1/2 transform -translate-x-1/2 -top-16'>
                                    <CollapseButton
                                        labelHover='Manage Gallery'
                                        handleClick={() => console.log('test')}
                                        icon='ri-image-add-line text-2xl -ml-0.5'
                                        size='10'
                                        textSize='text-xs'
                                        sizeHover='w-40'
                                        offsetCenter='2'
                                        btnColor='bg-green-400 hover:bg-gray-900 text-green-900'
                                    />
                                </div>
                            </GenericGallery>
                        </div>
                    </div>

                    <AccommodationList
                        locations={experience.accommodation.locations}
                    />
                    {/* <HotelMap destinations={experience.accommodation} /> */}
                    {/* <TipList
                    actionType={actionType}
                    tips={tips}
                    dayIndex={null}
                    index={null}
                    offset={20}
                /> */}
                    {/* <DestinationList
                    destinations={experience.destination.locations}
                /> */}
                    {/* <DestinationMap
                    destinations={experience.destination.locations}
                /> */}
                </ExpSubsection>
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    marketingData: state.experienceDetails.content_marketing,
    globalState: state.globalState,
    experienceDetails: state.experienceDetails
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionWhereToStay);
