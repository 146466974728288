import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PillContainer, PillIcon, PillTitle } from 'components/blocks/Pills';
import MovingAroundTypeModal from 'components/modals/movingAround/MovingAroundTypeModal';
// import * as types from 'store/actions/types';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic.js';
import FeedbackBox from 'components/blocks/FeedbackBox';
function MovingAroundPill({
    objId,
    index,
    rtl,
    edit,
    isFeedbackMode,
    actionType,
    dayIndex,
    experienceDetailsAction,
    title,
    pillType
    // specific types here
}) {
    const [pillModalState, setPillModalState] = useState(false);
    const handleEdit = () => {
        setPillModalState(true);
    };
    const updateType = (movingAroundType) => {
        experienceDetailsAction({
            type: `UPDATE_TYPE${actionType}`,
            dayIndex,
            index,
            movingAroundType
        });
    };

    return (
        <>
            <div onClick={edit && !isFeedbackMode ? handleEdit : null}>
                <PillContainer
                    rtl={rtl}
                    edit={edit}
                    isFeedbackMode={isFeedbackMode}
                >
                    <PillIcon icon={pillType} />
                    <PillTitle title={title} />

                    <FeedbackBox objId={objId} label='Title' />
                </PillContainer>
            </div>

            {pillModalState && (
                <MovingAroundTypeModal
                    setModalIsOpen={setPillModalState}
                    pillType={pillType}
                    handleActionBtn={updateType}
                />
            )}
        </>
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            experienceDetailsAction
        },
        dispatch
    );
}

export default connect(null, mapDispatchToProps)(MovingAroundPill);
