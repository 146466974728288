import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import ModalContainer, { ModalBody, ModalFooter, ModalCTA, ModalButton, ModalHelp } from 'components/blocks/ModalTre';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import useDidMountEffect from 'helpers/useDidMountEffect';

// should be in all modals
const targetElement = document.querySelector('#root');

function EmptyModal({
    maxWidth = '2xl',
    justifyButtons = 'justify-end',
    close,
    setModalIsOpen,
    handleActionBtn,
    globalState: { lang },
    icon = uiStruct.ui.modals.submit.icon,
    hintObj = null,
    actionBtnObj = uiStruct.ui.modals.submit.buttons.action,
    cancelBtnObj = uiStruct.ui.modals.submit.buttons.cancel,
    children,
    modalError = false
}) {
    const rtl = !!translations[lang].rtl;

    const [transitionState, setTransitionState] = useState('off');
    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    ///////////////////////////////////////////////////////////////////

    // Paul stuff Handle Action BTN function
    const handleActionClick = () => {
        if (!modalError) {
            handleActionBtn();
            handleTransitionStateOff();
        }
    };

    // useDidMountEffect(() => {
    //     setEditorValidationError(() => !dataObj.length);
    // }, [dataObj]);

    ////////////////////////////////

    return (
        <>
            <ModalContainer
                width={maxWidth}
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={icon}
                close={close}
                rtl={rtl}
            >
                <ModalBody rtlOff>
                    <div className='mt-4 mb-4'>{children}</div>
                </ModalBody>

                <ModalFooter>
                    {hintObj && <ModalHelp helpText={translations[lang][hintObj]} isHtml />}
                    <ModalCTA justifyButtons={justifyButtons}>
                        <ModalButton
                            handleClick={handleTransitionStateOff}
                            label={translations[lang][cancelBtnObj.label]}
                            color={cancelBtnObj.color}
                            icon={cancelBtnObj.icon}
                        />
                        <ModalButton
                            handleClick={handleActionClick}
                            label={translations[lang][actionBtnObj.label]}
                            color={actionBtnObj.color}
                            icon={actionBtnObj.icon}
                        />
                    </ModalCTA>
                </ModalFooter>
            </ModalContainer>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(EmptyModal);
