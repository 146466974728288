/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from 'react';
import { ShepherdTour } from 'react-shepherd';
import { singleSteps, wanderSteps, tourOptions } from './steps';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { find } from 'lodash';
import uiStruct from 'constants/uiStruct';
import LayoutOverview from 'components/layouts/LayoutOverview';
import LayoutMarketing from 'components/layouts/LayoutMarketing';
import LayoutDay from 'components/layouts/LayoutDay';
import LayoutLoading from 'components/layouts/LayoutLoading';
import LayoutExperienceSidebar, {
    LayoutExperienceSidebarOverview,
    LayoutExperienceSidebarDay,
    LayoutExperienceSidebarMarketing
} from 'components/layouts/LayoutExperienceSidebar';
import { handleRowReverse } from 'helpers/FEutils';
import {
    updateTagsAxios,
    updateIncludedGuide,
    fetchPublishedIdList,
    updateDataAdmin,
    createDataAdmin
} from 'fetch/experiences';
import {
    toggleEdit,
    toggleToolTips,
    toggleTour,
    toggleSuperAdmin,
    toggleFeedbackMode,
    togglePrintMode,
    setIsOwnerMode,
    setIsAdminStaffMode,
    setIsPublishedViewMode,
    setIsEduContentMode
} from 'store/actions/globalState/master';
import { updateExperience, updateFeedback } from 'fetch/experiences';
import { setExperienceSingle } from 'store/actions/experienceDetails/master';
import {
    fetchExperienceSingle,
    fetchExperiencePublishedSingle
} from 'fetch/experiences';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic';
import {
    clearExperience,
    experienceHasUpdated
} from 'store/actions/experienceDetails/general';
import translations from 'constants/translations';
import { animateScroll as scroll } from 'react-scroll';
import LayoutMenuEdit from 'components/layouts/LayoutMenuEdit';
import { getOverallCompletion } from 'helpers/menuHelpers';
import useAutosave from 'components/blocks/Autosave';
import { AxiosAbortService } from 'helpers/AxiosAbortService';
import { useNavigate, useParams } from 'react-router-dom';
import * as types from 'store/actions/types';
import TagsCatsListModal from 'components/modals/TagsCatsListModal';
import GuidedIncludeListModal from 'components/modals/GuidedIncludeListModal';
import 'styles/shepherd.css';
import LayoutMenuWander from 'components/layouts/LayoutMenuWander';
import SubmitExperienceDialogs from 'components/blocks/SubmitExperienceDialogs';
import { useWindowSize } from 'helpers/useWindowSize';
import { MenuRefsHolder } from 'helpers/MenuRefsHolder';
import { useScroll } from 'helpers/useScroll';
import useStatuses from 'helpers/useStatuses';
import {
    isPublishedViewFunc,
    isAdminStaffFunc,
    isOwnerFunc
} from 'helpers/statusHelper';
import ErrorPage from 'components/pages/ErrorPage';
import LayoutNavbar from 'components/layouts/LayoutNavbar';


const getDimensions = (ele) => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return {
        height,
        offsetTop,
        offsetBottom
    };
};

const scrollTo = (ele, width = 1280) => {
    const { offsetTop } = getDimensions(ele);
    if (width > 1280) {
        scroll.scrollTo(offsetTop, { smooth: true });
    } else scroll.scrollTo(offsetTop, { duration: 0, smooth: false });
};

const PageExperienceSingle = (props) => {
    const history = useNavigate();
    const [width, height] = useWindowSize();
    const {
        toggleEdit,
        toggleSuperAdmin,
        toggleFeedbackMode,
        togglePrintMode,
        toggleToolTips,
        toggleTour,
        setIsOwnerMode,
        setIsAdminStaffMode,
        setIsPublishedViewMode,
        setIsEduContentMode,
        globalState: {
            lang,
            edit,
            isSuperAdmin,
            isFeedbackMode,
            isPrintMode,
            isOwner,
            tour,
            toolTips,
            status: {
                priceSaving,
                tagsCatsSaving,
                includesSaving,
                feedbackSaving
            },
            siteData: {
                loading: siteDataLoading,
                tags: expTags,
                categories: expCats
            }
        },
        // match: {
        //     params: { day = 0, id }
        // },
        experienceDetails,
        experienceDetails: {
            pubId,
            id: expId,
            user: author,
            loading,
            experience,
            saving,
            updated,
            budget_min,
            budget_max,
            type,
            tags_list: tags,
            cats_list: cats,
            guided_extra,
            experience_status,
            invalid,
            content_marketing,
            places_lists,
            isPublished: expPublishStatus,
            inPublished,
            isEdu
        },
        auth: {
            user: { role: userRole, _id: userId }
        },
        fetchExperienceSingle,
        fetchExperiencePublishedSingle,
        fetchPublishedIdList,
        updateExperience,
        updateFeedback,
        clearExperience,
        experienceHasUpdated,
        experienceDetailsAction,
        updateIncludedGuide,
        updateDataAdmin,
        createDataAdmin,
    } = props;

    const {day = 0, id} = useParams();

    const showHeader = useScroll();
    const { isEduContent } = useStatuses();
    const isPublishedView = isPublishedViewFunc();
    const isAdminStaff = isAdminStaffFunc(userRole);
    const [toggleKreo, setToggleKreo] = useState(false);
    // const isOwner = isOwnerFunc(author?.id);

    const fetchFunc = useRef(
        isPublishedView ? fetchExperiencePublishedSingle : fetchExperienceSingle
    );

    const handlePrintMode = () => {
        togglePrintMode(!isPrintMode);

        const expPath = isPublishedView
            ? process.env.REACT_APP_BASENAME_EXPERIENCE_PUBLISHED
            : process.env.REACT_APP_BASENAME_EXPERIENCE;

        history(`${expPath}/${expId}/print`, { replace: true });
    };

    const handlePublishedView = () => {
        setIsPublishedViewMode(!isPublishedView);

        const expPath = isPublishedView
            ? process.env.REACT_APP_BASENAME_EXPERIENCE
            : process.env.REACT_APP_BASENAME_EXPERIENCE_PUBLISHED;

        history(`${expPath}/${expId}/0`, { replace: true });
    };
    const isValidDay = (day) => {
        let isValid = true;
        const tmpDay = Number(day);
        if (isNaN(tmpDay)) {
            isValid = false;
        } else {
            if (tmpDay >= 0 && tmpDay <= experience.itinerary.trip_days.length)
                isValid = true;
            else isValid = false;
        }
        return isValid;
    };
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [visibleSection, setVisibleSection] = useState();
    const dayIndex = Number(day) - 1; // Array starts at zero
    const [pageLoad, setPageLoading] = useState(true);
    const [tagsModalStatus, setTagsModalStatus] = useState(false);
    const [IncludesModalStatus, setIncludesModalStatus] = useState(false);
    const [update, setUpdate] = useState({ id: null });
    const hasUpdated = useRef(updated);
    const watchList = {
        experience,
        content_marketing,
        places_lists,
        cats,
        tags,
        budget_min,
        budget_max
    };
    const rtl = !!translations[lang].rtl;
    const [submitDialogsInfo, setSubmitDialogsInfo] = useState({
        status: false,
        experience: null
    });

    const handleSubmitAction = () => {
        setSubmitDialogsInfo({
            type: 'submit',
            status: true,
            experience: experienceDetails
        });
    };
    const handleApproveAction = () => {
        setSubmitDialogsInfo({
            type: 'approve',
            status: true,
            experience: experienceDetails
        });
    };
    const handleSave = () => {
        updateExperience();
    };

    useAutosave(
        watchList,
        !pageLoad,
        saving,
        updateExperience,
        // 2000
        process.env.REACT_APP_EXPERIENCE_SAVE_DELAY || 30000
    );

    useAutosave(
        { feedback_object: experience_status?.feedback_object },
        !pageLoad,
        feedbackSaving,
        updateFeedback,
        process.env.REACT_APP_FEEDBACK_SAVE_DELAY || 5000
    );

    const handleTagsAction = (index) => {
        setUpdate({
            id,
            isUpdate: true,
            tags,
            cats
        });
        setTagsModalStatus(true);
    };

    const handleIncludesAction = (index) => {
        const { included = [], excluded = [] } = guided_extra || {};

        setUpdate({
            isUpdate: true,
            included,
            excluded
        });
        setIncludesModalStatus(true);
    };
    const updateTags = (tags, cats) => {
        experienceDetailsAction({
            type: types.UPDATE_TAGS_SINGLE,
            dataObj: tags
        });

        experienceDetailsAction({
            type: types.UPDATE_CATS_SINGLE,
            dataObj: cats
        });
    };

    const updateGuideIncludes = (included, excluded) => {
        const dataObj = {
            included,
            excluded
        };

        updateIncludedGuide(isPublishedView ? pubId : expId, dataObj).then(
            (res) => {}
        );

        experienceDetailsAction({
            type: types.UPDATE_GUIDE_INCLUDES_SINGLE,
            dataObj
        });
    };

    const handleCompleteSubmit = () => {
        setPageLoading(true);

        updateExperience({
            status: 'submitted'
        }).then(() => {
            fetchFunc.current(id).then((res) => {
                setPageLoading(false);
            });
        });
    };
    const createPublished = (expId) => {
        const {
            experience: content,
            content_marketing,
            type,
            budget_min,
            budget_max,
            budget_currency,
            cats_list,
            tags_list,
            places_lists,
            days,
            experience: {
                featured_image,
                overview_intro: { title }
            }
        } = experienceDetails;

        const publishData = {
            experience_id: expId,
            content,
            content_marketing,
            type,
            budget_min,
            budget_max,
            budget_currency,
            user: author.id,
            cats_list,
            tags_list,
            places_lists,
            days,
            title,
            featured_image
        };
        // publishData.short_content = buildShortContent();

        createDataAdmin(
            types.CREATE_PUBLISHED_EXP,
            'publisheds',
            publishData
        ).then((res) => {
            if (res?.error) {
                setPageLoading(false);
            } else {
                fetchFunc.current(expId).then((res) => {
                    setPageLoading(false);
                });
            }
        });
    };
    const updatePublished = (pubId) => {
        const {
            experience: content,
            // short_content,
            content_marketing,
            type,
            budget_min,
            budget_max,
            budget_currency,
            cats_list,
            tags_list,
            places_lists,
            days,
            experience: {
                featured_image,
                overview_intro: { title }
            }
        } = experienceDetails;

        const publishData = {
            content,
            // short_content,
            content_marketing,
            featured_image,
            type,
            budget_min,
            budget_max,
            budget_currency,
            title,
            cats_list,
            tags_list,
            places_lists,
            days
        };
        //  publishData.short_content = buildShortContent();
        updateDataAdmin(
            types.UPDATE_EXP_PUBLISHED,
            'publisheds',
            publishData,
            pubId
        ).then((res) => {
            if (res?.error) {
                setPageLoading(false);
            } else {
                fetchFunc.current(expId).then((res) => {
                    setPageLoading(false);
                });
            }
        });
    };
    const handleApprove = () => {
        setPageLoading(true);
        fetchPublishedIdList().then((res) => {
            if (res.error) {
                setPageLoading(false);
            } else {
                setPageLoading(false);
                if (res.action?.payload?.data?.data?.publisheds) {
                    const publishedData =
                        res.action?.payload?.data?.data?.publisheds;
                    setPageLoading(true);

                    // check if id already in published
                    const publishedExp = find(publishedData, function (obj) {
                        return obj.experience_id === expId;
                    });
                    if (publishedExp) {
                        // updating
                        updateExperience({
                            status: 'approved',
                            inPublished: true
                        }).then(() => {
                            updatePublished(publishedExp.id);
                        });
                    } else {
                        // creating
                        updateExperience({
                            status: 'approved',
                            inPublished: true
                        }).then(() => {
                            createPublished(expId);
                        });
                    }
                }
            }
        });
    };
    useEffect(() => {
        const handleScroll = () => {
            // const { height: headerHeight } = getDimensions(headerRef.current);
            const scrollPosition = window.scrollY + 150;

            const selected = MenuRefsHolder.sectionRefs.find(
                ({ section, ref }) => {
                    const ele = ref;
                    if (ele) {
                        const { offsetBottom, offsetTop } = getDimensions(ele);
                        return (
                            scrollPosition > offsetTop &&
                            scrollPosition < offsetBottom
                        );
                    }
                }
            );

            if (selected && selected.section !== visibleSection) {
                setVisibleSection(selected.section);
            } else if (!selected && visibleSection) {
                setVisibleSection(undefined);
            }
        };

        handleScroll();
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [visibleSection]);

    useEffect(() => {
        if (width < 640) setMenuIsOpen(false);
        else setMenuIsOpen(true);
    }, [width]);

    useEffect(() => {
        setIsAdminStaffMode(isAdminStaff);
        setIsPublishedViewMode(isPublishedView);
        setIsEduContentMode(isEduContent);
    }, [isAdminStaff, isPublishedView, isEduContent]);

    useEffect(() => {
        const isOwnerState = isOwnerFunc(author?.id);
        setIsOwnerMode(isOwnerState);
        if (isOwner) {
            toggleEdit(true);
        } else toggleEdit(false);
    }, [isOwner]);

    useEffect(() => {
        togglePrintMode(false);
    }, []);

    useEffect(() => {
        if (!pageLoad) {
            experienceHasUpdated();
        }
    }, [...Object.values(watchList)]);

    useEffect(() => {
        hasUpdated.current = updated;
    }, [updated]);

    useEffect(() => {
        return () => {
            if (hasUpdated.current) {
                updateExperience();
            }
            AxiosAbortService.clearRequest('singleExperience');
            clearExperience();
        };
    }, []);

    useEffect(() => {
        // setPublishedPageChanged(!publishedPageChanged);
        if (!pageLoad) {
            fetchFunc.current = isPublishedView
                ? fetchExperiencePublishedSingle
                : fetchExperienceSingle;
            toggleSuperAdmin(false);
            togglePrintMode(false);
            toggleFeedbackMode(false);
            setToggleKreo(!toggleKreo);
            setPageLoading(true);
        }
    }, [isPublishedView]);

    useEffect(() => {
        if (
            !priceSaving.length &&
            !tagsCatsSaving.length &&
            !includesSaving.length &&
            pageLoad &&
            !siteDataLoading
        ) {
            fetchFunc.current(id).then((res) => {
                setPageLoading(false);
                if (res.error || invalid) {
                    history(`/404`, { replace: true });
                } else {
                    if (isPublishedView) {
                        experienceDetailsAction({
                            type: types.SET_TO_PUBLISHED,
                            dataObj: 'marketplace'
                        });
                        if (
                            userId !==
                            res?.value?.data?.data?.publisheds[0].user.id
                        ) {
                            toggleEdit(false);
                        } else toggleEdit(true);
                    } else {
                        experienceDetailsAction({
                            type: types.SET_TO_PUBLISHED,
                            dataObj: 'kreo'
                        });
                        if (userId !== res?.value?.data?.user._id) {
                            toggleEdit(false);
                        } else toggleEdit(true);
                    }
                }
            });
        }
    }, [
        priceSaving,
        tagsCatsSaving,
        includesSaving,
        siteDataLoading,
        toggleKreo
    ]);

    return (
        <>
            <LayoutNavbar />
            {!pageLoad && !loading && experienceDetails ? (
                <>
                    {isOwnerFunc(author?.id) || isAdminStaff || isEdu ? ( //  {userId === author?.id || isAdminStaff || false ? (
                        <ShepherdTour
                            steps={
                                userId == author?._id
                                    ? singleSteps
                                    : wanderSteps
                            }
                            tourOptions={tourOptions}
                        >
                            <div
                                className={`overflow-x-hidden pt-16 pb-32 min-h-screen ${
                                    handleRowReverse(rtl).flex
                                } `}
                            >
                                {!pageLoad && !loading && experienceDetails ? (
                                    isValidDay(day) ? (
                                        <>
                                            <LayoutExperienceSidebar
                                                dayIndex={dayIndex}
                                                experienceId={id}
                                                menuIsOpen={menuIsOpen}
                                                handleMenuIsOpen={setMenuIsOpen}
                                            >
                                                {dayIndex === -1 && (
                                                    <LayoutExperienceSidebarOverview
                                                        handleScroll={scrollTo}
                                                        // sections={
                                                        //     MenuRefsHolder.sectionsMainNav
                                                        // }
                                                        visibleSection={
                                                            visibleSection
                                                        }
                                                        menuIsOpen={menuIsOpen}
                                                        handleMenuIsOpen={
                                                            setMenuIsOpen
                                                        }
                                                    />
                                                )}
                                                {dayIndex >= 0 && (
                                                    <LayoutExperienceSidebarDay
                                                        handleScroll={scrollTo}
                                                        sections={
                                                            MenuRefsHolder.sectionsMainNav
                                                        }
                                                        visibleSection={
                                                            visibleSection
                                                        }
                                                        dayIndex={dayIndex}
                                                        menuIsOpen={menuIsOpen}
                                                        handleMenuIsOpen={
                                                            setMenuIsOpen
                                                        }
                                                    />
                                                )}
                                            </LayoutExperienceSidebar>

                                            {userId === author?._id ||
                                            isAdminStaff ? (
                                                <LayoutMenuEdit
                                                    expPublishStatus={
                                                        expPublishStatus
                                                    }
                                                    showHeader={showHeader}
                                                    day={day}
                                                    menuIsOpen={menuIsOpen}
                                                    approvExperienceAction={
                                                        handleApproveAction
                                                    }
                                                    submitExperienceAction={
                                                        handleSubmitAction
                                                    }
                                                    inPublished={inPublished}
                                                    isPublishedView={
                                                        isPublishedView
                                                    }
                                                    isOwner={
                                                        userId === author?.id
                                                    }
                                                    isPrintMode={isPrintMode}
                                                    handlePrintMode={
                                                        handlePrintMode
                                                    }
                                                    handlePublishedView={
                                                        handlePublishedView
                                                    }
                                                    isAdminStaff={isAdminStaff}
                                                    isSuperAdmin={isSuperAdmin}
                                                    expStatus={
                                                        experience_status
                                                    }
                                                    tagsActionBtn={
                                                        handleTagsAction
                                                    }
                                                    includesActionBtn={
                                                        handleIncludesAction
                                                    }
                                                    type={type}
                                                    edit={edit}
                                                    author={author}
                                                    handleEdit={toggleEdit}
                                                    handleSuperAdmin={
                                                        toggleSuperAdmin
                                                    }
                                                    handleFeedbackMode={
                                                        toggleFeedbackMode
                                                    }
                                                    isFeedbackMode={
                                                        isFeedbackMode
                                                    }
                                                    toolTips={toolTips}
                                                    tour={tour}
                                                    handleToolTips={
                                                        toggleToolTips
                                                    }
                                                    handleTour={toggleTour}
                                                    handleSave={handleSave}
                                                    isSaving={saving}
                                                    rtl={rtl}
                                                    completion={getOverallCompletion(
                                                        experience,
                                                        experienceDetails
                                                    )}
                                                />
                                            ) : (
                                                <LayoutMenuWander
                                                    showHeader={showHeader}
                                                    rtl={rtl}
                                                    author={author}
                                                />
                                            )}

                                            <div
                                                className={`pt-20 md:pt-0 transition duration-500 ease-in-out transform ${
                                                    menuIsOpen
                                                        ? handleRowReverse(rtl)
                                                              .pageTranslate
                                                        : 'translate-x-0'
                                                }`}
                                            >
                                                {dayIndex === -1 && (
                                                    // this one
                                                    <LayoutOverview
                                                        sectionRefs={
                                                            MenuRefsHolder.sectionRefs
                                                        }
                                                        sectionsMainNav={
                                                            MenuRefsHolder.sectionsMainNav
                                                        }
                                                        type={type}
                                                        sections={
                                                            MenuRefsHolder.sectionsMainNav
                                                        }
                                                    />
                                                )}

                                                {dayIndex >= 0 && (
                                                    <LayoutDay
                                                        dayIndex={dayIndex}
                                                        sections={
                                                            MenuRefsHolder.sectionsMainNav
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </>
                                    ) : day === 'marketing' ? (
                                        <>
                                            <LayoutExperienceSidebar
                                                dayIndex={
                                                    experience.itinerary
                                                        .trip_days.length
                                                }
                                                experienceId={id}
                                                menuIsOpen={menuIsOpen}
                                                handleMenuIsOpen={setMenuIsOpen}
                                            >
                                                <LayoutExperienceSidebarMarketing
                                                    handleScroll={scrollTo}
                                                    sections={
                                                        MenuRefsHolder.sectionsMainNav
                                                    }
                                                    visibleSection={
                                                        visibleSection
                                                    }
                                                    menuIsOpen={menuIsOpen}
                                                    handleMenuIsOpen={
                                                        setMenuIsOpen
                                                    }
                                                />
                                            </LayoutExperienceSidebar>

                                            {userId === author?._id ||
                                            isAdminStaff ? (
                                                <LayoutMenuEdit
                                                    expPublishStatus={
                                                        expPublishStatus
                                                    }
                                                    showHeader={showHeader}
                                                    day={day}
                                                    approvExperienceAction={
                                                        handleApproveAction
                                                    }
                                                    submitExperienceAction={
                                                        handleSubmitAction
                                                    }
                                                    inPublished={inPublished}
                                                    isPublishedView={
                                                        isPublishedView
                                                    }
                                                    isOwner={
                                                        userId === author?.id
                                                    }
                                                    isPrintMode={isPrintMode}
                                                    handlePrintMode={
                                                        handlePrintMode
                                                    }
                                                    handlePublishedView={
                                                        handlePublishedView
                                                    }
                                                    isAdminStaff={isAdminStaff}
                                                    isSuperAdmin={isSuperAdmin}
                                                    expStatus={
                                                        experience_status
                                                    }
                                                    tagsActionBtn={
                                                        handleTagsAction
                                                    }
                                                    includesActionBtn={
                                                        handleIncludesAction
                                                    }
                                                    type={type}
                                                    edit={edit}
                                                    author={author}
                                                    handleEdit={toggleEdit}
                                                    handleSuperAdmin={
                                                        toggleSuperAdmin
                                                    }
                                                    handleFeedbackMode={
                                                        toggleFeedbackMode
                                                    }
                                                    isFeedbackMode={
                                                        isFeedbackMode
                                                    }
                                                    toolTips={toolTips}
                                                    tour={tour}
                                                    handleToolTips={
                                                        toggleToolTips
                                                    }
                                                    handleTour={toggleTour}
                                                    handleSave={handleSave}
                                                    isSaving={saving}
                                                    rtl={rtl}
                                                    completion={getOverallCompletion(
                                                        experience,
                                                        experienceDetails
                                                    )}
                                                />
                                            ) : (
                                                <LayoutMenuWander
                                                    showHeader={showHeader}
                                                    rtl={rtl}
                                                    author={author}
                                                />
                                            )}

                                            <div
                                                className={`transition duration-500 ease-in-out transform ${
                                                    menuIsOpen
                                                        ? handleRowReverse(rtl).pageTranslate
                                                        : 'translate-x-0'
                                                }`}
                                            >
                                                {day === 'marketing' && (
                                                    <LayoutMarketing
                                                        type={type}
                                                        sections={
                                                            MenuRefsHolder.sectionsMainNav
                                                        }
                                                        tagsActionBtn={
                                                            handleTagsAction
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <ErrorPage err={404} />
                                    )
                                ) : (
                                    <LayoutLoading />
                                )}
                                {tagsModalStatus && (
                                    <TagsCatsListModal
                                        tagList={expTags}
                                        catList={expCats}
                                        setModalIsOpen={setTagsModalStatus}
                                        selected={update.tags}
                                        selectedCatsList={update.cats}
                                        handleActionBtn={updateTags}
                                        isAdminStaff={isAdminStaff}
                                        isOwner={userId === author?.id}
                                    />
                                )}
                                {IncludesModalStatus && (
                                    <GuidedIncludeListModal
                                        list={uiStruct.experience.included}
                                        selectedIncludeList={update.included}
                                        selectedExcludeList={update.excluded}
                                        setModalIsOpen={setIncludesModalStatus}
                                        handleActionBtn={updateGuideIncludes}
                                        isAdminStaff={isAdminStaff}
                                        isOwner={userId === author?.id}
                                    />
                                )}
                                {submitDialogsInfo.status && (
                                    <SubmitExperienceDialogs
                                        handleCompleteSubmit={
                                            submitDialogsInfo.type === 'submit'
                                                ? handleCompleteSubmit
                                                : handleApprove
                                        }
                                        status={submitDialogsInfo.type}
                                        lang={lang}
                                        handleClose={setSubmitDialogsInfo}
                                        experience={
                                            submitDialogsInfo.experience
                                        }
                                        completion={
                                            getOverallCompletion(
                                                experience,
                                                experienceDetails
                                            ).total
                                        }
                                    />
                                )}
                            </div>
                        </ShepherdTour>
                    ) : (
                        <ErrorPage err={403} />
                    )}
                </>
            ) : (
                <LayoutLoading />
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,

    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleEdit,
            toggleSuperAdmin,
            togglePrintMode,
            toggleFeedbackMode,
            toggleToolTips,
            setIsOwnerMode,
            setIsAdminStaffMode,
            setIsPublishedViewMode,
            setIsEduContentMode,
            toggleTour,
            updateExperience,
            updateFeedback,
            experienceDetailsAction,
            setExperienceSingle,
            fetchExperienceSingle,
            fetchExperiencePublishedSingle,
            fetchPublishedIdList,
            clearExperience,
            experienceHasUpdated,
            updateTagsAxios,
            updateIncludedGuide,
            updateDataAdmin,
            createDataAdmin
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageExperienceSingle);
