/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { ShepherdTour } from 'react-shepherd';
import { firstSteps, tourOptions } from './steps';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic';
import { fetchPurchasedAll } from 'fetch/experiences';
//  import { defaultMarketing } from 'constants/experiencesConsts';
import {
    toggleSuperAdmin,
    setIsAdminStaffMode,
    setIsEduContentMode
} from 'store/actions/globalState/master';
import translations from 'constants/translations';
import ExperiencePurchasedList from 'components/blocks/ExperiencePurchasedList';
import { handleRowReverse } from 'helpers/FEutils';
import * as types from 'store/actions/types';
import LayoutMenuListEdit from 'components/layouts/LayoutMenuListEdit';
import 'styles/shepherd.css';
import { useScroll } from 'helpers/useScroll';
import useStatuses from 'helpers/useStatuses';
import {
    // isPublishedViewFunc,
    isAdminStaffFunc
} from 'helpers/statusHelper';
import {Navigate} from 'react-router-dom';
import LayoutNavbar from 'components/layouts/LayoutNavbar';


const PagePurchases = (props) => {
    const {
        toggleSuperAdmin,
        setIsAdminStaffMode,
        setIsEduContentMode,
        globalState: {
            inMode,
            lang,
            status: { priceSaving, tagsCatsSaving, includesSaving },
            // siteData,
            siteData: { loading: siteDataLoading },
            isSuperAdmin
        },
        auth,
        auth: {
            user: { role: userRole }
        },

        experiences: { purchased },
        experienceDetails: { updated: waitForUpdateToFinish },
        fetchPurchasedAll,
        experienceDetailsAction,
    } = props;
    const { isOwner, isEduContent } = useStatuses();
    // const isPublishedView = isPublishedViewFunc();
    const isAdminStaff = isAdminStaffFunc(userRole);

    useEffect(() => {
        setIsAdminStaffMode(isAdminStaff);
        setIsEduContentMode(isEduContent);
    }, [isAdminStaff, isEduContent]);



    const showHeader = useScroll();
    const rtl = !!translations[lang].rtl;
    const [pageLoad, setPageLoading] = useState(true);
    const fetchExperiences = () => {
        fetchPurchasedAll().then((res) => {
            setPageLoading(false);
        });
    };

    useEffect(() => {
        if (inMode !== 'owned') {
            experienceDetailsAction({
                type: types.SET_TO_PUBLISHED,
                dataObj: 'owned'
            });
        }
        if (!priceSaving.length && !tagsCatsSaving.length && !includesSaving.length && pageLoad && !waitForUpdateToFinish && !siteDataLoading) {
            fetchExperiences();
        }
    }, [priceSaving, tagsCatsSaving, includesSaving, waitForUpdateToFinish, siteDataLoading]);

    useEffect(() => {
        if (inMode !== 'owned') {
            experienceDetailsAction({
                type: types.SET_TO_PUBLISHED,
                dataObj: 'owned'
            });
        }
    }, []);
    
    return auth.isProfile ? (
        <ShepherdTour steps={firstSteps} tourOptions={tourOptions}>
            <LayoutNavbar />
            {/* <LayoutHelpBar /> */}
            <div className='w-full mt-28 md:mt-36'>
                <LayoutMenuListEdit
                    rtl={rtl}
                    isOwner={isOwner}
                    isAdminStaff={isAdminStaff}
                    isSuperAdmin={isSuperAdmin}
                    handleSuperAdmin={toggleSuperAdmin}
                    showHeader={showHeader}
                />

                <div
                    className={`relative 
                        max-w-2xl md:max-w-3xl lg:max-w-4xl xl:max-w-6xl mx-auto pt-12 md:pt-0 px-0 md:px-4 lg:px-0`}
                >
                    <div className='flex justify-between items-center mb-8 px-4'>
                        <div
                            className={`${
                                handleRowReverse(rtl).rtl
                            }  text-transparent bg-clip-text bg-gradient-to-tr from-gray-900 via-blue-500 to-green-400 text-3xl md:text-4xl leading-none  font-bold l py-2 `}
                        >
                            My Purchases
                        </div>
                    </div>
                    <ExperiencePurchasedList
                        pageLoad={pageLoad}
                        experiences={purchased}
                    />
                </div>
            </div>
        </ShepherdTour>
    ) : (
        <Navigate to='/accounts/login' />
    )
};

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    experiences: state.experiences,
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchPurchasedAll,
            toggleSuperAdmin,
            experienceDetailsAction,
            setIsAdminStaffMode,
            setIsEduContentMode
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PagePurchases);
