import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Range } from 'rc-slider';
import { updateTimeToGo } from 'store/actions/experienceDetails/bestTimeToGo';
import translations from 'constants/translations';
import 'styles/rcSlider.css';
import CollapseButton from 'components/blocks/CollapseButton';
import { useWindowSize } from 'helpers/useWindowSize';
import { configResponsive, useResponsive } from 'ahooks';
import FeedbackBox from './FeedbackBox';

configResponsive({
    small: 0,
    middle: 800,
    large: 1200
});

function BestTimeToGoRanges({
    experienceDetails: {
        experience: {
            best_time_to_go: { time_range: timeRange, objId }
        }
    },
    globalState: { lang, edit },
    updateTimeToGo
}) {
    const responsive = useResponsive();

    useEffect(() => {}, [responsive]);

    const [width] = useWindowSize();

    const fixTimeToGo = (theRange) => {
        if (Array.isArray(theRange[0])) {
            return [theRange[0][0], theRange[0][1]];
        } else return [theRange[0], theRange[1]];
    };

    const [localRange, setLocalRange] = useState(fixTimeToGo(timeRange));
    const [isReverse, setIsReverse] = useState(
        timeRange[2] ? timeRange[2] : false
    );

    useEffect(() => {
        if (timeRange[2] !== isReverse) {
            const newRange = [...localRange];
            newRange.push(isReverse);
            updateTimeToGo(newRange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReverse]);

    const handleRangeChange = (range) => {
        const newRange = [...range];

        newRange.push(isReverse);
        updateTimeToGo(newRange);
        setLocalRange(range);
    };
    const marks = {
        15: translations[lang]['UI_DATE_MONTH_SHORT_1'], // 31 31
        45: translations[lang]['UI_DATE_MONTH_SHORT_2'], // 59 28
        75: translations[lang]['UI_DATE_MONTH_SHORT_3'], // 90 31
        105: translations[lang]['UI_DATE_MONTH_SHORT_4'], // 120 30
        135: translations[lang]['UI_DATE_MONTH_SHORT_5'], // 151 31
        165: translations[lang]['UI_DATE_MONTH_SHORT_6'], // 181 30
        195: translations[lang]['UI_DATE_MONTH_SHORT_7'], // 212 31
        225: translations[lang]['UI_DATE_MONTH_SHORT_8'], // 243 31
        255: translations[lang]['UI_DATE_MONTH_SHORT_9'], // 273 30
        285: translations[lang]['UI_DATE_MONTH_SHORT_10'], // 304 31
        315: translations[lang]['UI_DATE_MONTH_SHORT_11'], // 334 30
        345: translations[lang]['UI_DATE_MONTH_SHORT_12'] // 365 31
    };

    const marksShort = {
        15: 'J', // 31 31
        45: 'F', // 59 28
        75: 'M', // 90 31
        105: 'A', // 120 30
        135: 'M', // 151 31
        165: 'J', // 181 30
        195: 'J', // 212 31
        225: 'A', // 243 31
        255: 'S', // 273 30
        285: 'O', // 304 31
        315: 'N', // 334 30
        345: 'D' // 365 31
    };

    // const dateFromDay = (year=2021, day) => {
    //     const date = new Date(year, 0); // initialize a date in `year-01-01`

    //     return new Date(date.setDate(Number(day))).toLocaleDateString(); // add the number of days
    // }

    const buildCountries = () => {
        return (
            <div className='md:mb-12 bg-green-50 rounded-3xl py-6 relative px-4'>
                <FeedbackBox objId={objId?.range} label='Best time to go' />
                <div className='w-min italic whitespace-nowrap mb-3 sm:mb-0 font-bold tracking-tight flex flex-none justify-center items-center bg-green-200 rounded-full px-6 text-green-700 h-8'>
                    Best Time to go
                </div>

                {/* <div className='mb-4'>
                         {countriesTranslations[lang][countryCode].name}
                     </div> */}
                {edit && (
                    <div className='absolute top-4 right-4'>
                        <CollapseButton
                            labelHover='Reverse Range'
                            handleClick={() => setIsReverse(!isReverse)}
                            icon='ri-arrow-left-right-line text-2xl -ml-0.5'
                            size='10'
                            textSize='text-xs'
                            sizeHover='w-40'
                            offsetCenter='2'
                            btnColor='bg-green-400 hover:bg-gray-900 text-green-900'
                        />
                    </div>
                )}

                <div className='px:2 md:px-4 lg:px-24 py-12'>
                    <Range
                        trackStyle={[
                            { background: isReverse ? '#A7F3D0' : '#34D399' }
                        ]}
                        railStyle={{
                            background: !isReverse ? '#A7F3D0' : '#34D399'
                        }}
                        activeDotStyle={{
                            borderColor: isReverse ? '#A7F3D0' : '#34D399'
                        }}
                        dotStyle={{
                            borderColor: !isReverse ? '#A7F3D0' : '#34D399'
                        }}
                        handleStyle={[{}]}
                        disabled={!edit}
                        step={1}
                        min={1}
                        max={365}
                        defaultValue={localRange}
                        marks={width > 640 ? marks : marksShort}
                        allowCross={true}
                        onAfterChange={(data) => {
                            handleRangeChange(data);
                        }}
                    />
                </div>
            </div>
        );
        // return timeRange.map((obj, index) => {
        //     const { countryCode, range } = obj;
        //     const key = `${countryCode}_${index}`;

        //     return (
        //         <div key={key} className='mb-12'>
        //             <div className='mb-4'>
        //                 {countriesTranslations[lang][countryCode].name}
        //             </div>
        //             <Range
        //                 disabled={!edit}
        //                 step={1}
        //                 min={1}
        //                 max={365}
        //                 defaultValue={range}
        //                 marks={marks}
        //                 onAfterChange={(data) => {
        //                     handleRangeChange(data, index);
        //                 }}
        //             />
        //             <br />
        //         </div>
        //     );
        // });
    };

    return <div className='mb-6 md:mb-12'>{buildCountries()}</div>;
}
const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateTimeToGo
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(BestTimeToGoRanges);
