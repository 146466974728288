import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ListBoxGeneric from 'components/blocks/ListBoxGeneric';
import ModalContainer, {
    ModalBody,
    ModalFooter,
    ModalCTA,
    ModalButton,
    ModalHelp
} from 'components/blocks/ModalTre';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';

// should be in all modals
const targetElement = document.querySelector('#root');

function CurrencyVendorsModal({
    close,
    setModalIsOpen,
    handleActionBtn,
    updateObj = [],
    globalState: { lang },
    hintObj = null,
    icon = uiStruct.overview.currency_paying.uiEdit.modals.vendor.icon,
    actionBtnObj = uiStruct.overview.currency_paying.uiEdit.modals.vendor
        .buttons.action,
    cancelBtnObj = uiStruct.overview.currency_paying.uiEdit.modals.vendor
        .buttons.cancel
}) {
    const rtl = !!translations[lang].rtl;
    const currentState = {};

    updateObj.forEach((vendor) => {
        const { type, label } = vendor;

        currentState[type] = label;
    });

    const {
        overview: {
            currency_paying: {
                uiEdit: { currency_vendors: currencyVendors }
            }
        }
    } = uiStruct;
    const [vendorsState, setVendorsState] = useState(() => {
        return currencyVendors.reduce((prev, next) => {
            return {
                ...prev,
                [next]: {
                    checked: currentState[next] ? true : false,
                    label: currentState[next] ? currentState[next] : '',
                    error: false
                }
            };
        }, {});
    });
    // Copy Below to all modals
    //////////////////////////////////////////////////////////////////////

    // State for triggering animation of modal in and out
    const [transitionState, setTransitionState] = useState('off');
    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    ///////////////////////////////////////////////////////////////////

    // Paul stuff Handle Action BTN function
    const handleActionClick = () => {
        const tmpObj = [];
        const vendorStateCpy = JSON.parse(JSON.stringify(vendorsState));
        let isError = false;

        Object.keys(vendorsState).forEach((type) => {
            const { checked, label } = vendorsState[type];
            vendorStateCpy[type].error = false;
            if (checked && !label.trim().length) {
                vendorStateCpy[type].error = true;
                isError = true;
            } else if (checked) {
                tmpObj.push({
                    type: type,
                    label: label.trim()
                });
            }
            vendorStateCpy[type].label = label.trim();
        });

        if (!isError) {
            handleActionBtn(tmpObj);
            handleTransitionStateOff(); // important... don't forget to add it in all modals
        } else {
            setVendorsState({
                ...vendorStateCpy
            });
        }
    };

    ///////////////////////////////

    const handleCheckChange = (index) => {
        const name = currencyVendors[index];
        const checked = !vendorsState[name].checked;

        setVendorsState({
            ...vendorsState,
            [name]: {
                ...vendorsState[name],
                checked
            }
        });
    };
    const handleLabelChange = (index, event) => {
        const name = currencyVendors[index];
        const {
            target: { value: label }
        } = event;

        setVendorsState({
            ...vendorsState,
            [name]: {
                ...vendorsState[name],
                label,
                error: false
            }
        });
    };

    const buildVendors = () => {
        // console.log(vendorsState)
        return Object.keys(vendorsState).map((curr, index) => {
            const key = `${curr}_${index}`;
            const { checked, label, error } = vendorsState[curr];
            // console.log(checked, label, error)
            return (
                <li key={key}>
                    <input
                        type='checkbox'
                        id={curr}
                        name={curr}
                        onChange={() => {
                            handleCheckChange(index);
                        }}
                        checked={checked}
                    />
                    <label htmlFor={curr}>{translations[lang][curr]}</label>
                    <input
                        style={{ border: error ? '1px solid red' : '' }}
                        type='text'
                        onChange={(e) => {
                            handleLabelChange(index, e);
                        }}
                        value={label}
                    />
                </li>
            );
        });
    };

    return (
        <>
            <ModalContainer
                width='xl'
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={icon}
                close={close}
                rtl={rtl}
            >
                <ModalBody height='24'>
                    <ul>{buildVendors()}</ul>
                </ModalBody>

                <ModalFooter>
                    {hintObj && (
                        <ModalHelp
                            helpText={translations[lang][hintObj]}
                            isHtml
                        />
                    )}
                    <ModalCTA>
                        <ModalButton
                            handleClick={handleTransitionStateOff}
                            label={translations[lang][cancelBtnObj.label]}
                            color={cancelBtnObj.color}
                            icon={cancelBtnObj.icon}
                        />
                        <ModalButton
                            handleClick={handleActionClick}
                            label={translations[lang][actionBtnObj.label]}
                            color={actionBtnObj.color}
                            icon={actionBtnObj.icon}
                        />
                    </ModalCTA>
                </ModalFooter>
            </ModalContainer>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(CurrencyVendorsModal);
