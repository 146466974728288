export const deletePublishedMutation = (pubId, expId) => {
    let mutation = `mutation {`
    let muteData = ``;

    muteData = `${muteData}
    update_status: updateExperience(input: { 
        where: {
            id: "${expId}"
        },
        data: {status: initial, inPublished: false} }) { 
            experience {
                id
            }
        }
    `;

    muteData = `${muteData}
        delete_pub: 
            deletePublished(input: { where: {id: "${pubId}" } }) {
                published {
                    id
                }
            }
        `;

    mutation = `${mutation}
        ${muteData}
    }`;

    return mutation;
}
