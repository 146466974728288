import React, { useState, useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import translations from 'constants/translations';

import { handleRowReverse } from 'helpers/FEutils';
import { ReactComponent as KLogoIcon } from 'components/svg/kn_logoicon.svg';
import { ReactComponent as KLogoText } from 'components/svg/kn_logotext.svg';
import ButtonLoad from 'components/blocks/ButtonLoad';
import { animateIn, randomItem } from 'helpers/FEutils';
import { buildCountryData } from 'helpers/LocaleHelper';
import { updateDataAdmin } from 'fetch/user';
import { logout, clearLoginErrors, loadUser } from 'store/actions/auth';
import FormIkInput from 'components/forms/FormIkInput';
import { regexString, regexWhiteSpace } from 'components/utility/regexPatterns';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ui from 'translations/componentNames.json';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Images from 'constants/imagesTmp';
import LoadingPageNp from 'components/utility/LoadingPageNp';
import MadeWithLove from 'components/blocks/MadeWithLove';
import ListBoxGeneric from 'components/blocks/ListBoxGeneric';
import * as types from 'store/actions/types';
import { countries } from 'constants/countries';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import { updateUsernameAdmin } from 'fetch/user';
import LayoutNavbar from 'components/layouts/LayoutNavbar';
import LayoutLoading from 'components/layouts/LayoutLoading';


function SocialLoginUsername(props) {
    const {
        globalState: { lang },
        inModal = true,
        // globalState: { lang },
        auth,
        auth: { user, loading: userLoading },
        actionBtnObj = 'uiStruct.ui.modals.editor.buttons.action',
        cancelBtnObj = 'uiStruct.ui.modals.editor.buttons.cancel',
        updateUsernameAdmin
    } = props;
    const router = useNavigate();

    //  const rtl = !!translations[lang].rtl;
    // const countryList = buildCountryData(lang);
    const [busyCreating, setBusyCreating] = useState(false);
    const userDataDefault = {
        username: '',
        email: '',
        password: ''
    };
    const profileDataDefault = {
        first: '',
        last: '',
        displayname: '',
        city: '',
        country: '',
        currency: '',
        avatar: '',
        settings: ''
    };
    const socialDataDefault = {
        instagram: '',
        facebook: '',
        twitter: '',
        tiktok: '',
        youtube: '',
        website: ''
    };
    const [userDataObj, updateUserDataObj] = useState({ ...userDataDefault });
    const [profileDataObj, updateProfileDataObj] = useState({
        ...profileDataDefault
    });
    const [socialDataObj, updateSocialDataObj] = useState({
        ...socialDataDefault
    });
    // const [selectedCountry, setSelectedCountry] = useState(countryList[0]);
    const [formLocked, setFormLocked] = useState(false);
    const [lockedInputs, setLockedInputs] = useState({
        username: true && inModal,
        password: true && inModal,
        email: true && inModal
    });
    const [isLoading, setIsLoading] = useState(true);

    // const resetForm = () => {
    //     updateUserDataObj({
    //         ...userDataDefault
    //     });
    //     updateProfileDataObj({
    //         ...profileDataDefault
    //     });

    //     updateSocialDataObj({
    //         ...socialDataDefault
    //     });
    // };

    const toggleLock = (type) => {
        setLockedInputs({
            ...lockedInputs,
            [type]: !lockedInputs[type]
        });
    };

    const [validationObj, setValidationObj] = useState({
        username: { error: false },
        email: { error: false },
        password: { error: false },
        first: { error: false },
        last: { error: false },
        instagram: { error: false },
        facebook: { error: false },
        twitter: { error: false },
        tiktok: { error: false },
        youtube: { error: false },
        website: { error: false }
    });

    const validateForm = () => {
        const { username, email, password } = userDataObj;
        const socialKeys = [
            'instagram',
            'facebook',
            'twitter',
            'tiktok',
            'youtube',
            'website'
        ];
        const { first, last } = profileDataObj;
        let error = false;
        const tmpErrorObj = {
            username: { error: false },
            email: { error: false },
            password: { error: false },
            first: { error: false },
            last: { error: false },
            instagram: { error: false },
            facebook: { error: false },
            twitter: { error: false },
            tiktok: { error: false },
            youtube: { error: false },
            website: { error: false }
        };
        if (!first.length) {
            tmpErrorObj.first.error = true;
            error = true;
        }
        if (!last.length) {
            tmpErrorObj.last.error = true;
            error = true;
        }

        const regexUsername = /^(?=[a-zA-Z0-9._]{4,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/
        const regexPassword = /^[a-zA-Z0-9$|~=[\]'_+@.-]{8,}$/;

        const validateEmail = (email) => {
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        };

        const testValidUrl = (str) => {
            var regexp =
                /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

            return regexp.test(str);
        };

        if (!username.length || !username.match(regexUsername)) {
            tmpErrorObj.username.error = true;
            error = true;
        }
        if (!validateEmail(email)) {
            tmpErrorObj.email.error = true;
            error = true;
        }

        if (password.length && inModal) {
            if (!password.match(regexPassword)) {
                tmpErrorObj.password.error = true;
                error = true;
            }
        } else if (inModal) {
            tmpErrorObj.password.error = false;
        }

        if (!inModal) {
            if (!password.match(regexPassword)) {
                tmpErrorObj.password.error = true;
                error = true;
            }
        }

        socialKeys.forEach((key) => {
            if (socialDataObj[key].length) {
                if (!testValidUrl(socialDataObj[key])) {
                    tmpErrorObj[key].error = true;
                    error = true;
                }
            }
        });

        if (error) {
            setValidationObj({
                ...tmpErrorObj
            });
        }

        return error;
    };

    const updateErrorObj = (key) => {
        if (validationObj[key]) {
            setValidationObj({
                ...validationObj,
                [key]: { error: false }
            });
        }
    };

    const updateUserDataObjInputs = (e, name) => {
        const data =
            name === 'description'
                ? e
                : name === 'email' || name === 'password' || name === 'username'
                    ? e.target.value.replace(/ /g, '')
                    : e.target.value;
        updateUserDataObj({
            ...userDataObj,
            [name]: data
        });

        updateErrorObj(name);
    };

    const updateProfileDataObjInputs = (e, name) => {
        updateProfileDataObj({
            ...profileDataObj,
            [name]: name === 'description' ? e : e.target.value
        });

        updateErrorObj(name);
    };

    const handleCurrencyChange = (val) => {
        updateProfileDataObj({
            ...profileDataObj,
            currency: val
        });
    };

    const handleCountryChange = (val) => {
        updateProfileDataObj({
            ...profileDataObj,
            country: val
        });
    };

    const updateSocialDataObjInputs = (e, name) => {
        updateSocialDataObj({
            ...socialDataObj,
            [name]: name === 'description' ? e : e.target.value
        });

        updateErrorObj(name);
    };

    const updateDescriptionHtml = (html) => {
        if (!isLoading) {
            updateProfileDataObj({
                ...profileDataObj,
                bio: html
            });
        }
    };

    const handleAvatarUpdate = (imageData) => {
        const { url } = imageData[0];
        updateProfileDataObj({ ...profileDataObj, avatar: url || '' });
    };
    const handleDeleteAvatar = () => {
        updateProfileDataObj({ ...profileDataObj, avatar: '' });
    };
    const finishUpdateCreate = () => {
        setBusyCreating(false);
        router('/c/settings/account/profile', { replace: true });
    };

    const regroupData = () => {
        const fullData = {
            ...userDataObj,
            profile: {
                ...profileDataObj,
                social: socialDataObj
                // country: selectedCountry.id
            }
        };

        if (!validateForm()) {
            const {
                id: userId,
                profile: { _id: profileId }
            } = user;

            setBusyCreating(true);

            updateUsernameAdmin(userId, fullData).then(() => {
                finishUpdateCreate();
            });
        }
    };

    const { username, email, password } = userDataObj;

    const { first, last, displayname, city, currency, country, bio, avatar } =
        profileDataObj;

    const { instagram, facebook, tiktok, youtube, twitter, website } =
        socialDataObj;
    // console.log('avatar', avatar);
    useEffect(() => {
        if (inModal && user) {
            updateUserDataObj({
                username: user.username,
                email: user.email,
                password: ''
            });
            updateProfileDataObj({
                // ...user.profile,
                avatar: user.profile.avatar,
                first: user.profile.first,
                last: user.profile.last,
                city: user.profile.city || '',
                currency: user.profile.currency || 'EUR',
                country: user.profile.country || '000',
                displayname: user.profile.displayname || '',
                bio: user.profile.bio || ''
            });
            updateSocialDataObj({
                instagram: user.profile.social?.instagram || '',
                facebook: user.profile.social?.facebook || '',
                twitter: user.profile.social?.twitter || '',
                tiktok: user.profile.social?.tiktok || '',
                youtube: user.profile.social?.youtube || '',
                website: user.profile.social?.website || ''
            });

            setIsLoading(false);
        }
    }, [user]);

    useEffect(() => {
        if (!auth.isAuthenticated && !auth.loading) {
            router('/', { replace: true });
        }
    }, [auth]);

    return (
        <>
            <LayoutNavbar />
            {/* <Script>UPLOADCARE_PUBLIC_KEY = '8655037f335d8f4f0419';</Script> */}
            {!isLoading && user ? (
                <div className="w-full mt-16 d-hdpi-2:mt-vw-16 d-hdpi-2:text-vw-base mb-16 d-hdpi-2:mb-vw-16" style={{ marginTop: '100px' }}>
                    <div
                        className={`relative 
                        px-4 md-px-0 max-w-2xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl mx-auto d-hdpi-2:max-w-screen-2/3 `}>
                        <div className="mt-12 mb-0 px-4 d-hdpi-2:mt-vw-12 d-hdpi-2:px-vw-4">
                            <div className={`mb-12 d-hdpi-2:mb-vw-12`}>
                                Choose Your Username
                            </div>

                            <div className="flex flex-col md:flex-row gap-8 md:gap-16 d-hdpi-2:gap-8">
                                <div className="w-full">
                                    <div className="mb-16 d-hdpi-2:mb-vw-16">

                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-8 d-hdpi-2:gap-4">

                                            <div className="flex flex-col gap-6 mb-4 d-hdpi-2:gap-3 d-hdpi-2:mb-vw-4">
                                                <input
                                                    onChange={(e) =>
                                                        updateUserDataObjInputs(
                                                            e,
                                                            'username'
                                                        )
                                                    }
                                                    id="username"
                                                    value={username}
                                                    placeholder={username}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="">
                            <div>
                                <div className="w-full flex-col md:flex-row flex md:items-center justify-end gap-8 d-hdpi-2:gap-4">
                                    <ButtonLoad
                                        // disabled={!formLocked}
                                        handleClick={regroupData}
                                        isLoading={busyCreating}
                                        label={'Save changes'}
                                        margins=""
                                        animate={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={``}>
                    <LayoutLoading
                        height="h-screen-1/2"
                        message="Loading User data"
                    />
                </div>
                // <><div>Loading</div></>
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth
});
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateUsernameAdmin
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialLoginUsername);
