import React, { useLayoutEffect, useState, useRef } from 'react';

function debounce(fn, ms) {
    let timer;
    return (_) => {
        clearTimeout(timer);
        timer = setTimeout((_) => {
            timer = null;
            fn.apply(this, arguments);
        }, ms);
    };
}

// export const useWindowSize = () => {
//     const [size, setSize] = useState([0, 0]);
//     useLayoutEffect(() => {
//         function updateSize() {
//             setSize([window.innerWidth, window.innerHeight]);
//         }
//         window.addEventListener('resize', updateSize);
//         updateSize();
//         return () => window.removeEventListener('resize', updateSize);
//     }, []);
//     return size;
// };

export const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    const hasUnloaded =  useRef(false)
    useLayoutEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            if(!hasUnloaded.current) setSize([window.innerWidth, window.innerHeight]);
        }, 1000);

        window.addEventListener('resize', debouncedHandleResize);
        debouncedHandleResize();
        return (_) => {
            hasUnloaded.current = true;
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, []);
    return size;
};
