/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DescriptionTitleModal from 'components/modals/DescriptionTitleModal';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic';
import * as types from 'store/actions/types';
import 'styles/rcSlider.css';
import { CardXlContent } from './CardXl';
import useXchangeRate from 'helpers/useXchangeRate';
import { formatPrice, roundNumber } from 'helpers/LocaleHelper';
import { currenciesObject } from 'constants/currenciesObject';
import FeedbackBox from './FeedbackBox';

// const currencyOptions = {
//     rounding: 1
// };

function BudgetRange({
    objId,
    experienceDetailsAction,
    experienceDetails: { budget_max, budget_min, budget_currency = 'USD' },
    globalState: { lang, edit, isFeedbackMode },
    auth: {
        user: {
            profile: { currency: preferredCurrency = 'USD' }
        }
    }
}) {
    const [budgetCurrency, setBudgetCurrency] = useState(budget_currency);

    const { rate, currency: enteredCurrency } = useXchangeRate(
        [1],
        'USD',
        budgetCurrency
    );

    const { rate: preferredRate, currency: userCurrency } = useXchangeRate(
        [1],
        'USD',
        preferredCurrency
    );

    // const [key, rerender] = useReducer((v) => !v);
    const [maxMinModalStatus, setMaxMinModalStatus] = useState(false);
    const updateBudget = (budgetData) => {
        const xchangedBudgetDate = {
            min: budgetData.min / rate,
            max: budgetData.max / rate,
            currency: budgetCurrency
        };
        experienceDetailsAction({
            type: types.UPDATE_BUDGET,
            dataObj: xchangedBudgetDate
        });
    };

    const handleCurrencyChange = (val) => {
        setBudgetCurrency(val);
    };

    const openMaxMinModal = () => {
        setMaxMinModalStatus(true);
    };
    const [formattedPricesEntered, setFormattedPricesEntered] = useState({
        min: '',
        max: ''
    });
    // console.log('formatted entered', formattedPricesEntered);

    const [formattedPricesUser, setFormattedPricesUser] = useState({
        min: formatPrice(
            budget_min * preferredRate,
            preferredCurrency,
            window?.navigator?.language
        ),
        max: formatPrice(
            budget_max * preferredRate,
            preferredCurrency,
            window?.navigator?.language
        )
    });

    const updateCurrencyValues = () => {
        setFormattedPricesEntered({
            min: formatPrice(
                Number(budget_min * rate),
                budgetCurrency,
                window?.navigator?.language
            ),
            max: formatPrice(
                Number(budget_max * rate),
                budgetCurrency,
                window?.navigator?.language
            )
        });
        setFormattedPricesUser({
            min: formatPrice(
                budget_min * preferredRate,
                preferredCurrency,
                window?.navigator?.language
            ),
            max: formatPrice(
                budget_max * preferredRate,
                preferredCurrency,
                window?.navigator?.language
            )
        });
    };

    useEffect(() => {
        updateCurrencyValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        budget_min,
        budget_max,
        budgetCurrency,
        preferredCurrency,
        rate,
        preferredRate
    ]);

    return (
        <div className='mb-12 relative'>
            <CardXlContent
                edit={edit}
                handleEdit={openMaxMinModal}
                className='mt-2'
                padding=''
                isFeedbackMode={isFeedbackMode}
            >
                <div className='bg-green-50 rounded-3xl py-6 relative px-4 w-full'>
                    <div className='flex items-center gap-8'>
                        <div className='w-min italic whitespace-nowrap mb-3 sm:mb-0 font-bold tracking-tight flex flex-none justify-center items-center bg-green-200 rounded-full px-6 text-green-700 h-8'>
                            Budget range
                        </div>
                    </div>

                    <div className='flex flex-col w-full md:w-max mx-auto mt-4 md:mt-0 md:mb-4'>
                        <div className='flex flex-col lg:flex-row justify-center items-center text-green-700 gap-2'>
                            <div
                                className={`flex items-center gap-2 justify-center text-normal md:text-2xl font-semibold`}
                            >
                                <span>{formattedPricesEntered.min}</span>
                                <span>
                                    <i className='text-green-500 las la-long-arrow-alt-right text-2xl'></i>
                                </span>
                                <span>{formattedPricesEntered.max}</span>
                                <div className=' uppercase'>
                                    {enteredCurrency.symbol}
                                </div>
                            </div>
                            <div
                                className={`flex items-center justify-center text-xs uppercase font-normal `}
                            >
                                {`(${enteredCurrency.name})`}
                            </div>
                        </div>
                        {preferredCurrency !== budgetCurrency && (
                            <div>
                                <div className='flex items-center justify-items py-4 opacity-75'>
                                    <span className='flex-1 border-b border-green-300 mr-3' />
                                    <span className='flex-shrink-0 text-green-900 text-sm'>
                                        or
                                    </span>
                                    <span className='flex-1 border-b border-green-300 ml-3' />
                                </div>
                                <div className='flex flex-col lg:flex-row justify-center items-center text-green-700 gap-2'>
                                    <div
                                        className={`flex items-center gap-2 justify-center text-sm font-semibold`}
                                    >
                                        <span>{formattedPricesUser.min}</span>
                                        <span>
                                            <i className='text-green-500 las la-long-arrow-alt-right text-2xl'></i>
                                        </span>
                                        <span>{formattedPricesUser.max}</span>
                                        <div className=' uppercase'>
                                            {userCurrency.symbol}
                                        </div>
                                    </div>
                                    <div
                                        className={`flex items-center justify-center text-xs uppercase font-normal `}
                                    >
                                        {`(${userCurrency.name})`}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className='flex text-xxs mt-4'>
                            Note: All Values are rounded to simplify legibility.
                            They are not indicative of exact exchange rates.
                        </div>
                    </div>
                </div>
                {maxMinModalStatus && (
                    <DescriptionTitleModal
                        handleCurrencyChange={handleCurrencyChange}
                        width='sm'
                        update
                        txtOnly
                        maxMin={{
                            min: roundNumber(
                                budget_min * rate,
                                currenciesObject[budgetCurrency].budget_rounding
                            ),
                            max: roundNumber(
                                budget_max * rate,
                                currenciesObject[budgetCurrency].budget_rounding
                            ),
                            currency: budgetCurrency
                        }}
                        showCurrency
                        setModalIsOpen={setMaxMinModalStatus}
                        updateObj={''}
                        handleActionBtn={updateBudget}
                        placeholder={'placeholder'}
                        inputIcon='las la-dollar-sign'
                        icon={{
                            name: 'COINS',
                            size: '2xl'
                        }}
                        type='number'
                    >
                        <div className='mt-4 mb-6'>
                            Enter the required budget range for this experience.
                        </div>
                        <div className='mt-4 mb-6 text-xs'>
                            PS: values for selected currency will rounded to the
                            nearest{' '}
                            {currenciesObject[budgetCurrency]
                                ?.budget_rounding || '1'}
                        </div>
                    </DescriptionTitleModal>
                )}
            </CardXlContent>
            <FeedbackBox objId={objId} label='Budget range' />
        </div>
    );
}
const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            experienceDetailsAction
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(BudgetRange);
