import Slider from 'components/slider/Slider';
import CarouselContainer from 'components/specialty/CarouselContainer';
import GalleryUpdater from 'components/specialty/GalleryUpdater';
import FeedbackBox from 'components/blocks/FeedbackBox';

function GalleryWithUpdater({
    objId,
    images = [],
    lang,
    edit,
    handleUpdateCaption,
    handleUpdateGallery,
    slider = true,
    max = 12,
    children,
    className,
    modalTitle,
    scroll,
    showThumnails = false
}) {
    return (
        <div className='relative'>
            <FeedbackBox
                objId={objId}
                label='Gallery'
                boxHeight='min-h-16 h-full'
                // zLevel=''
            />
            {images &&
                images.length > 0 &&
                (edit || showThumnails) &&
                !slider && (
                    <CarouselContainer
                        objId={objId}
                        slides={images}
                        handleUpdateCaption={handleUpdateCaption}
                        scroll={scroll}
                    />
                )}

            {slider && images && images.length > 0 && (
                <Slider
                    handleUpdateCaption={handleUpdateCaption}
                    edit={edit}
                    slides={images}
                />
            )}
            {edit && (
                <GalleryUpdater
                    handleUpdateGallery={handleUpdateGallery}
                    imageList={images}
                    max={max}
                    className={className}
                    modalTitle={modalTitle}
                >
                    {children}
                </GalleryUpdater>
            )}
        </div>
    );
}

export default GalleryWithUpdater;
