/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep, isEqual } from 'lodash';
import { fetchPublishedPriceData, createGuidedSku, getDate } from 'fetch/experiences';
import ModalFullscreen, { ModalBody } from 'components/blocks/ModalFullscreen';
import ModalTopBlock from 'components/blocks/ModalTopBlock';
// import ModalContainer, {
//     ModalFooter,
//     ModalCTA,
//     ModalButton
// } from 'components/blocks/ModalTre';

import ModalContainer, {
    ModalFooter,
    ModalCTA,
    ModalButton,
    ModalHelp
} from 'components/blocks/ModalTre';

import CalendarBooking from 'components/blocks/CalendarBooking';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { isSameDay, isBefore } from 'date-fns';
import {
    convertStringsToDates,
    convertToDbVersionObj,
    validateForm,
    createSkuPostTypes,
    convertToModalCalType,
    defaultDataObj,
    createLiveDataObj,
    creatExpPriceData,
    createAddPost,
    createUpdatePost,
} from 'helpers/calenderBooking';
import CalenderSelect from './CalenderSelect';
import InfoModal from '../InfoModal';
import Spinner from 'components/blocks/Spinner';

// should be in all modals
const targetElement = document.querySelector('#root');

function CalendarBookingModal({
    actionType = {},
    isAdminStaff = false,
    isOwner = true,
    close,
    setModalIsOpen,
    globalState: { lang, isSuperAdmin },
    icon = {
        name: 'CALENDAR',
        size: '2xl'
    },
    type = 'default',
    modalTitle = 'Calendar',
    modalSubTitle = 'Add Location Pin',
    actionBtnObj = uiStruct.itinerary.moving_around.uiEdit.modals.vendor.buttons.action,
    cancelBtnObj = uiStruct.itinerary.moving_around.uiEdit.modals.vendor.buttons.cancel,
    labels,
    fetchPublishedPriceData,
    createGuidedSku,
    expId
}) {
    const rtl = !!translations[lang].rtl;
    const publishedExpId = useRef(expId);
    const [loadingData, setLoadingData] = useState(true);
    const [showCal, setShowCal] = useState(false);
    const selected= useRef(0);
    const [modifiers, setModifiers] = useState(null);
    const [errorModal, setErrorModalStatus] = useState(false);
    const serverValidationError = useRef(false);
    const dataObjCpy = useRef([]);
    const [dataObj, updateDataObj] = useState([]); // set to a default or editing
    const originalData = useRef([]);
    const openCal = useRef(false);
    const currentLivePrices = useRef(null);
    const [currentDate, setCurrentDate] = useState(new Date().toString());
    const infoTxt = useRef('');

    // State for triggering animation of modal in and out
    const [transitionState, setTransitionState] = useState('off');
    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    ///////////////////////////////////////////////////////////////////
    // Paul stuff Handle Action BTN function



    const updateCalendarDays = (index, name, val) => {
        updateDataObj(
            dataObj.map((obj, i) => {
                if (i === index) {
                    return {
                        ...obj,
                        [name]: val
                    };
                }
                return obj;
            })
        );
    };

    const updateCapacityPrice = (name, val, groupIndex, dateKey) => {
        let newObj = JSON.parse(
            JSON.stringify(dataObj[groupIndex].individualDayData)
        );

        newObj[dateKey][name] = Number(val);
        newObj[dateKey].errorObj[name] = {
            error: false,
            msg: ''
        };
        if (name === 'price') {
            if (newObj[dateKey][name] < 1) {
                newObj[dateKey].errorObj.price = {
                    error: true,
                    msg: 'Price error'
                };
            }
        } else {
            if (newObj[dateKey][name] < newObj[dateKey].minCapacity) {
                newObj[dateKey].errorObj.capacity = {
                    error: true,
                    msg: 'Capacity error'
                };
            }
        }
        updateDataObj(
            dataObj.map((obj, i) => {
                if (i === groupIndex) {
                    return {
                        ...obj,
                        individualDayData: newObj
                    };
                }
                return obj;
            })
        );
    };
    const addCalendarDays = () => {
        const newObj = [...dataObj, ...cloneDeep(defaultDataObj)];
        originalData.current = cloneDeep(dataObj);
        updateDataObj(newObj);
        openCal.current = true;
    };

    const deleteCalendarDays = (index) => {
        const newObj = dataObj.filter((item, i) => i !== index);

        updateDataObj(newObj);
    };

    const deleteCalendarDay = (groupIndex, deleteDay) => {
        let newObj = JSON.parse(JSON.stringify(dataObj));
        delete newObj[groupIndex].individualDayData[deleteDay];
        newObj.forEach((obj) => {
            obj.days = obj.days
                .filter((d) => d !== deleteDay)
                .map((d) => new Date(d));
        });

        newObj = newObj.filter( obj => obj.days.length)
        updateDataObj(newObj);
    };

    const handleUpdateDefaults = (e) => {
        const { name, value } = e.target;

        updateCalendarDays(
            selected.current,
            name,
            name !== 'name' ? Number(value) : value
        );
    };

    const closeCal = (cancel) => {
        if(cancel) {
            updateDataObj(cloneDeep(originalData.current));
        }
        setShowCal(false);
        
    };

    const showCalFromAGroup = (index) => {
        selected.current = index;
        originalData.current = cloneDeep(dataObj);
        handleShowCal(index);
    }

    const handleDayClick = (date) => {
        const newObj = dataObj.map((item, i) => {
            if (i === selected.current) {
                const datesLength = item.days.length;
                item.days = item.days.filter((obj) => {
                    return obj.toString() !== date.toString();
                });

                if (datesLength === item.days.length) {
                    item.days = [...item.days, date];
                }
                if (item.individualDayData[date.toISOString()]) {
                    delete item.individualDayData[date.toISOString()];
                } else {
                    item.individualDayData[date.toISOString()] = {
                        price: Number(dataObj[selected.current].price),
                        capacity: Number(dataObj[selected.current].capacity),
                        minCapacity: 1,
                        minPrice: 1,
                        allowDelete: true,
                        errorObj: {
                            price: { error: false, msg: '' },
                            capacity: { error: false, msg: '' }
                        }
                    };
                }
            }

            return item;
        });

        updateDataObj(newObj);

    };

    const renderCalander = (index) => {
        let disableList = dataObj
            .filter((obj, i) => i !== index)
            .map((obj) => {
                return obj.days;
            })
            .flat()
            .map((d) => d.toISOString());
        const bookingList = dataObj
            .map((group) => group.individualDayData)
            .map((data) => {
                let tmpArr = [];

                Object.keys(data).forEach((key) => {
                    if (!data[key].allowDelete) {
                        tmpArr.push(key);
                    }
                });
                return tmpArr;
            })
            .flat();
        disableList = [...disableList, ...bookingList];

        setModifiers({
            selected: (date) =>
                dataObj[index].days.some((selectedDate) =>
                    isSameDay(selectedDate, date)
                ),
            disabled: (date) => disableList.includes(date.toISOString())|| isBefore(new Date(date), new Date(currentDate)),
            disabledColor: (date) => disableList.includes(date.toISOString()),

            highlight: (date) => bookingList.includes(date.toISOString())
        });
    };

    const handleShowCal = (index) => {
        renderCalander(index);
        selected.current = index;
        setShowCal(true);
    };

    const checkAddDateInPast = (add, theDate) => {
        let dayError = false;

        add.forEach((obj) => {
            const { day } = obj;

            if(isBefore(new Date(day), new Date(theDate))) {
                dayError = true;
                handleDayClick(new Date(day));
            };
        })

        return dayError;
    }

    const handleActionClick = () => {
        let postDataObj = {};


        setLoadingData(true);
        getDate().then((data) => {
            if(!data?.isAxiosError) {
                const theDate = data.data;
                setCurrentDate(theDate);
                if (validateForm(dataObj)) {
                    const guidedPrice = convertToDbVersionObj(
                        convertStringsToDates(dataObj)
                    );
                    const dataToPost = createSkuPostTypes(
                        guidedPrice,
                        currentLivePrices
                    );
                    const { add, update, remove } = dataToPost;

                    
                    if(add.length) {
                        if(checkAddDateInPast(add, theDate)) {
                            setLoadingData(false);
                            infoTxt.current = 'Please check your dates. New dates in the past have been removed';
                            setErrorModalStatus(true);

                            return; // return from save
                        }
                    }

                    if (update.length || remove.length) {
                        setLoadingData(true);
                        fetchPublishedPriceData(expId, 'guided').then((res) => {
                            if (res?.action && res?.action?.payload?.data?.variants) {
                                const {
                                    id,
                                    stock: { results: stockData },
                                    variants: { results: variantsData=[] }
                                } = res.action.payload.data;
                
                                publishedExpId.current = id;
                                if(variantsData?.length) {
                                    currentLivePrices.current = createLiveDataObj(variantsData, stockData);
                                    const expPrice = creatExpPriceData(variantsData, stockData)
                                    const allDates = convertStringsToDates(
                                        convertToModalCalType(
                                            expPrice,
                                            currentLivePrices,
                                            serverValidationError,
                                            currentDate
                                        ),
                                        'dates'
                                    );
                                    dataObjCpy.current = cloneDeep(allDates);
                                    updateDataObj(allDates);
                                }
                                
                                if (!serverValidationError.current) {
                                    if(add.length) {
                                        postDataObj = { ...postDataObj, ...createAddPost(add) }
                                    }
                                    if(update.length) {
                                        postDataObj = { ...postDataObj, ...createUpdatePost(update) }
                                    }
                                    postDataObj = {
                                        ...postDataObj,
                                        remove,
                                        lowestPrice: guidedPrice.reduce((prev, next) => { return next.price < prev ? next.price : prev; }, Infinity)}
    
    
                                    createGuidedSku(expId, postDataObj).then(() => {
                                        fetchAllData();
                                    });
                                } else {
                                    setLoadingData(false);
                                    setErrorModalStatus(true);
                                }
                            }
                        });
                    } else if (add.length){
                        postDataObj = { 
                            lowestPrice: guidedPrice.reduce((prev, next) => { return next.price < prev ? next.price : prev; }, Infinity),
                            ...createAddPost(dataToPost.add)
                        }
                        setLoadingData(true);
                        createGuidedSku(expId, postDataObj).then(() => {
                            fetchAllData();
                        });
                    }

                } else {
                    setLoadingData(false);
                }
            } else {
                setLoadingData(false);
            }
        })
    };

    const  fetchTheData = () => {
        fetchPublishedPriceData(expId, 'guided').then((res) => {
            if (res?.action && res?.action?.payload?.data?.variants) {
                const {
                    id,
                    stock: { results: stockData },
                    variants: { results: variantsData=[] }
                } = res.action.payload.data;

                publishedExpId.current = id;
                if(variantsData?.length) {
                    currentLivePrices.current = createLiveDataObj(variantsData, stockData);
                    const expPrice = creatExpPriceData(variantsData, stockData)
                    const allDates = convertStringsToDates(
                        convertToModalCalType(
                            expPrice,
                            currentLivePrices,
                            serverValidationError,
                            currentDate
                        ),
                        'dates'
                    );
                    dataObjCpy.current = cloneDeep(allDates);
                    updateDataObj(allDates);
                }
                
                setLoadingData(false);
            }
        });
    }
    const fetchAllData = () => {
        getDate().then((data) => {
            if(!data?.isAxiosError) {
                setCurrentDate(data.data);
                currentLivePrices.current = [];
                fetchTheData();
            }
        })
    }

    useEffect(() => {
        fetchAllData();
    }, []);

    useEffect(() => {
        if (showCal) {
            renderCalander(selected.current);
        } else if (openCal.current) {
            openCal.current = false;
            handleShowCal(dataObj.length - 1);
        }
    }, [dataObj]);

    return (
        <>
            <ModalFullscreen
                width='4xl'
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={icon}
                close={close}
                rtl={rtl}
                fullscreen={true}
            >
                <ModalBody rtlOff>
                    <div className='max-w-7xl mx-auto'>
                        <div className='w-full  px-4 md:px-8 lg:px-2 mb-8 mt-4'>
                            <ModalTopBlock
                                title={modalTitle}
                                subtitle='Manage Calendar'
                                text='You can set your calendar availability as well as capacity in the tables below. Start by adding a new group, select the dates, capacity, and price. You can always change them individually.'
                                rtl={rtl}
                                lang={lang}
                            />
                        </div>
                
                        <div className='w-full  px-4 md:px-8 lg:px-2 mb-8 mt-4'>
                            {!loadingData ? (
                                <>
                                    <div
                                        style={{
                                            display: showCal
                                                ? 'none'
                                                : 'initial'
                                        }}
                                    >
                                        <CalendarBooking
                                            setShowCal={showCalFromAGroup}
                                            actionType={actionType}
                                            data={dataObj}
                                            updateCalendarDays={updateCalendarDays}
                                            deleteCalendarDays={deleteCalendarDays}
                                            deleteCalendarDay={deleteCalendarDay}
                                            addCalendarDays={addCalendarDays}
                                            labels={labels}
                                            updateCapacityPrice={
                                                updateCapacityPrice
                                            }
                                        />
                                    </div>
                                    {showCal && (
                                        <div className=''>
                                            <CalenderSelect
                                                closeCal={closeCal}
                                                handleUpdateDefaults={
                                                    handleUpdateDefaults
                                                }
                                                selected={selected.current}
                                                dataObj={dataObj}
                                                originalData={selected.current < originalData.current.length  ? originalData.current[selected.current] : false}
                                                handleDayClick={handleDayClick}
                                                modifiers={modifiers}
                                            />
                                        </div>
                                    )}
                                    {errorModal && (
                                        <InfoModal
                                            setModalIsOpen={setErrorModalStatus}
                                            messagesArr={infoTxt.current}
                                            isArray={false}
                                        />
                                    )}
                                </>
                            ) : 
                            <div className='flex flex-col justify-center items-center'>
                                    <span className='flex justify-center'>
                                        <Spinner
                                            size='64'
                                            strokeWidth='20'
                                            strokeColor={'black'}
                                        />
                                    </span>
                            </div>
                            
                            }
                        </div>
                        
                        <div className='mt-8 mb-8 px-4 md:px-10'>
                            <ModalFooter>
                                {(isOwner ||
                                    (isAdminStaff && isSuperAdmin)) && (
                                    <ModalCTA>
                                        <ModalButton
                                            handleClick={
                                                handleTransitionStateOff
                                            }
                                            label={
                                                translations[lang][
                                                    cancelBtnObj.label
                                                ]
                                            }
                                            color={cancelBtnObj.color}
                                            icon={cancelBtnObj.icon}
                                        />
                                        <ModalButton
                                            handleClick={handleActionClick}
                                            isDisabled={
                                                isEqual(
                                                    dataObj,
                                                    dataObjCpy.current
                                                ) || !validateForm(dataObj) || showCal || loadingData
                                            }
                                            label={'Publish Calendar'}
                                            color={actionBtnObj.color}
                                            icon={actionBtnObj.icon}
                                        />
                                    </ModalCTA>
                                )}
                            </ModalFooter>
                        </div>
                    </div>
                </ModalBody>
            </ModalFullscreen>
        </>
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchPublishedPriceData,
            createGuidedSku
        },
        dispatch
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CalendarBookingModal);
