import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ListBoxGeneric from 'components/blocks/ListBoxGeneric';
import ModalContainer, {
    ModalBody,
    ModalFooter,
    ModalCTA,
    ModalButton
} from 'components/blocks/ModalTre';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';

// should be in all modals
const targetElement = document.querySelector('#root');

function MovingAroundTypeModal({
    close,
    setModalIsOpen,
    handleActionBtn,
    updateObj = null,
    // update = false,
    pillType,
    globalState: { lang },
    // icon = { name: 'ADDTEXT', size: '2xl' },

    actionBtnObj = uiStruct.itinerary.moving_around.uiEdit.modals.add.buttons
        .action,
    cancelBtnObj = uiStruct.itinerary.moving_around.uiEdit.modals.add.buttons
        .cancel
}) {
    const rtl = !!translations[lang].rtl;

    const [dataObj, updateDataObj] = useState(updateObj); // set to a default or editing
    const movingTypes =
        uiStruct.itinerary.moving_around.uiEdit.transport_list.map((key) => {
            return {
                id: key,
                name: translations[lang][key],
                unavailable: false
            };
        });

    const [selectedType, setSelectedType] = useState(() => {
        return movingTypes[
            movingTypes.findIndex((obj) => {
                return obj.id === pillType;
            })
        ];
    });

    // Copy Below to all modals
    //////////////////////////////////////////////////////////////////////

    // State for triggering animation of modal in and out
    const [transitionState, setTransitionState] = useState('off');
    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    ///////////////////////////////////////////////////////////////////

    // Paul stuff Handle Action BTN function
    const handleActionClick = () => {
        handleActionBtn(selectedType.id);
        handleTransitionStateOff(); // important... don't forget to add it in all modals
    };

    ///////////////////////////////

    return (
        <>
            <ModalContainer
                width='xl'
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={{ name: selectedType.id, size: '4xl' }}
                close={close}
                rtl={rtl}
            >
                <ModalBody height='24'>
                    <ListBoxGeneric
                        listData={movingTypes}
                        val={selectedType}
                        handleChange={setSelectedType}
                    />
                </ModalBody>

                <ModalFooter>
                    <ModalCTA>
                        <ModalButton
                            handleClick={handleTransitionStateOff}
                            label={translations[lang][cancelBtnObj.label]}
                            color={cancelBtnObj.color}
                            icon={cancelBtnObj.icon}
                        />
                        <ModalButton
                            handleClick={handleActionClick}
                            label={translations[lang][actionBtnObj.label]}
                            color={actionBtnObj.color}
                            icon={actionBtnObj.icon}
                        />
                    </ModalCTA>
                </ModalFooter>
            </ModalContainer>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(MovingAroundTypeModal);
