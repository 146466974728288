import { PillLogo } from 'components/blocks/Pills';

import classNames from 'classnames';
export const OptionCard = ({ title, children, image }) => {
    return (
        <div className='w-full lg:w-72 xl:w-80 mb-16 md:mb-0  flex flex-col md:flex-row lg:flex-col md:gap-8 lg:gap-0'>
            <div className='w-72 md:w-80 lg:w-72 md:h-80 flex items-center justify-center'>
                <img src={image} className='' />
            </div>
            <div className='w-72 md:w-80 lg:w-72 md:mt-16 lg:mt-0 px-4 xl:px-0'>
                <div className='text-4xl mb-4 text-green-400 font-semibold'>{title}</div>
                <div className='text-base text-gray-600 '>{children}</div>
            </div>
        </div>
    );
};

export const Section = ({
    id,
    className,
    borders = 'border-ba',
    padding = 'py-16',
    margins = 'mx-auto',
    display = 'block',
    flex = 'flex-row',
    justify = 'justify-start',
    align = 'items-start',
    gap,
    width = 'w-full',
    height = 'h-auto',
    minHeight = 'min-h-60',
    maxHeight = '',
    maxWidth = '',
    position = 'relative',
    background = 'bg-white',
    textColor = 'text-gray-900',
    textSize = 'text-base d-hdpi-2:text-vw-base',
    overflow = '',
    transform = '',
    pill,
    children
}) => {
    return (
        <div
            id={id}
            className={classNames(
                className,
                borders,
                padding,
                margins,
                display,
                flex,
                gap,
                justify,
                align,
                width,
                maxWidth,
                minHeight,
                maxHeight,
                height,
                position,
                background,
                textColor,
                textSize,
                overflow,
                transform
            )}
        >
            {pill && (
                <div
                    className={classNames(
                        'h-auto absolute left-1/2 transform  -translate-x-1/2',
                        pill === 'top' && 'top-0 -translate-y-1/2',
                        pill === 'bottom' && 'bottom-0 translate-y-1/2'
                    )}
                >
                    <PillLogo />
                </div>
            )}
            {children}
        </div>
    );
};

export const Row = ({
    zIndex,
    className,
    borders = '',
    padding = 'px-8 md:px-24 lg:px-24 xl:px-20 2xl:px-0 ',
    margins = 'mx-auto',
    display = 'flex',
    flex = 'flex-col-reverse lg:flex-row',
    gap,
    justify = 'justify-start',
    align = 'items-start',
    width = 'w-full',
    maxWidth = 'max-w-7xl',
    position = '',
    background = '',
    textColor = 'text-gray-900',
    textSize = 'text-base d-hdpi-2:text-vw-base',
    overflow = '',
    transform = '',
    children
}) => {
    return (
        <div
            className={classNames(
                zIndex,
                className,
                flex,
                gap,
                justify,
                align,
                borders,
                padding,
                margins,
                display,
                width,
                maxWidth,
                position,
                background,
                textColor,
                textSize,
                overflow,
                transform
            )}
        >
            {children}
        </div>
    );
};

export const Column = ({
    className,
    borders = '',
    padding = '',
    margins = '',
    display = 'flex',
    flex = 'flex-col',
    gap = '',
    justify = 'justify-start',
    align = 'items-start',
    width = 'w-full lg:w-1/2',
    maxWidth,
    position = '',
    background = '',
    textColor = '',
    textSize = 'text-base',
    overflow = '',
    transform = '',
    children
}) => {
    return (
        <div
            className={classNames(
                className,
                flex,
                gap,
                justify,
                align,
                borders,
                padding,
                margins,
                display,
                width,
                maxWidth,
                position,
                background,
                textColor,
                textSize,
                overflow,
                transform
            )}
        >
            {children}
        </div>
    );
};
