import React from 'react';
import {
    RiInstagramLine,
    RiGoogleFill,
    RiFacebookFill,
    RiTwitterFill,
    RiAppleFill,
} from 'react-icons/ri';
const ButtonAuth = ({
    handleClick,
    icon = 'google',
    label,
    size,
    className,
}) => {
    return (
        <button className='focus:outline-none rounded-full h-10 my-2 border-2 border-gray-200 text-gray-800 hover:border-gray-900 hover:bg-gray-900 hover:text-white transition-all duration-200 flex items-center px-6'>
            <span className='w-10 h-10 text-xl flex items-center justify-center '>
                {React.createElement(IconMap[icon], { size, className })}
            </span>
            <div className='flex-1 flex justify-center text-sm'>{label}</div>
        </button>
    );
};

export default ButtonAuth;

const IconMap = {
    instagram: RiInstagramLine,
    google: RiGoogleFill,
    facebook: RiFacebookFill,
    twitter: RiTwitterFill,
    apple: RiAppleFill,
};
