export const settingRefs = (draft, action) => {

    draft.menuRefsLoading = true;
    return draft;
};


export const refsSet = (draft, action) => {

    draft.menuRefsLoading = false;
    return draft;
};
