import axios from 'axios';
// Singleton
class AxiosAbortService {

    constructor() {
        this.CancelToken = axios.CancelToken;
        this.axiosRequests = {};
    }

    addRequest(req) {
        this.axiosRequests[req] = this.CancelToken.source();

        return  this.axiosRequests[req].token;
    }

    clearRequest(key) {
        if(this.axiosRequests[key]) {
            this.axiosRequests[key].cancel();
            delete this.axiosRequests[key]
        }
    }
    
    static instance = null;
    static createInstance() {
        const object = new AxiosAbortService();
        return object;
    }

    static getInstance() {
        if (!AxiosAbortService.instance) {
            AxiosAbortService.instance = AxiosAbortService.createInstance();
        }
        return AxiosAbortService.instance;
    }
}

const instance =  AxiosAbortService.getInstance();
export { instance as AxiosAbortService }