import { handleRowReverse } from 'helpers/FEutils';
const TitleGradient = ({ text, size = 'text-4xl', rtl }) => {
    return (
        <div className='flex justify-between items-center mb-8 px-4'>
            <div
                className={`${
                    handleRowReverse(rtl).rtl
                }  text-transparent bg-clip-text bg-gradient-to-tr from-gray-900 via-blue-500 to-green-400 ${size} leading-none  font-bold l py-2 `}
            >
                {text}
            </div>
        </div>
    );
};

export default TitleGradient;
