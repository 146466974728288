import { User, Clock, MapPin, Users, Layers } from 'lucide-react';
import { capitalize, getDays, kreatorName } from 'helpers/FEutils';
import { country, findLowestPrice } from 'helpers/LocaleHelper';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const MarketingInfo = (props) => {
    const {
        experienceDetails: {
            experience,
            id,
            days,
            budget_min,
            budget_max,
            type,
            cats_list: tmpCats,
            places_lists: tmpPlaces,
            user,
            user: { profile }
        },
        globalState: { lang, edit, siteData }
    } = props;

    const {
        overview_intro: { title }
    } = experience;

    const places_lists = tmpPlaces.map((place) => {
        const found = siteData.placesLists.find((elm) => elm.id == place);
        return found;
    });
    const cats_list = tmpCats.map((cat) => {
        const found = siteData.categories.find((elm) => elm.id == cat);
        return found?.name;
    });

    const EmptyData = <span className='w-20 bg-gray-300 rounded-full h-2' />;
    const ContentDays = days ? <span> {getDays(days)}</span> : EmptyData;

    return (
        <div className='mt-2 flex flex-wrap items-center font-sans text-sm text-gray-900'>
            <div className='flex  mr-8 py-1'>
                <span className='text-green-400 mr-2'>
                    <MapPin size={18} />
                </span>

                <span className='flex flex-wrap items-center'>
                    {places_lists?.length > 0 ? (
                        places_lists.map((item, index, itemArray) => {
                            return (
                                <span key={`${item}_${index}`}>
                                    <span className='whitespace-nowrap'>{country('en', item.code)}</span>
                                    {index < itemArray.length - 1 && <span className='px-1'>.</span>}
                                </span>
                            );
                        })
                    ) : (
                        <span className='w-20 bg-gray-300 rounded-full h-2' />
                    )}
                </span>
            </div>
            <div className='flex items-center mr-8 py-1'>
                <span className='text-green-400 mr-2'>
                    <Clock size={18} />
                </span>
                {ContentDays}
            </div>
            <div className='flex items-center mr-8 py-1'>
                <span className='text-green-400 mr-2'>
                    <Layers size={18} />
                </span>
                <div className='flex items-center gap-2'>
                    {cats_list.map((cat, index) => {
                        return (
                            <span key={`cats_${index}`} className='uppercase text-xs tracking-wide'>
                                {index < cats_list.length - 1 ? `${cat}, ` : cat}
                            </span>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketingInfo);
