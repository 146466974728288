import { handleRowReverse } from 'helpers/FEutils';

const ModalTopBlock = ({ rtl, lang, title, subtitle, text }) => {
    return (
        <div className='w-full px-2 mb-8 mt-4'>
            <div className='md:flex-row flex flex-col  px-6 py-2 md:items-center gap-2 mb-6 bg-green-100 text-green-800 rounded-lg '>
                <span className='text-lg font-medium '>{title}</span>
                <span className='flex items-center gap-2'>
                    <i className='las la-long-arrow-alt-right text-2xl'></i>
                    <span className='text-sm font-medium '>{subtitle}</span>
                </span>
            </div>
            <div
                className={`px-2 lg:px-4 ${rtl ? '' : 'text-sm'} mb-6 ${
                    handleRowReverse(rtl).rtl
                }`}
            >
                {text}
            </div>
        </div>
    );
};

export default ModalTopBlock;
