import { arrangeArray } from '../common';

export const updateTipActivities = (draft, action) => {
    const { index, tipIndex, dataObj, dayIndex } = action;

    draft.experience.itinerary.trip_days[dayIndex].activities[index].tips_html[
        tipIndex
    ] = {
        ...draft.experience.itinerary.trip_days[dayIndex].activities[index]
            .tips_html[tipIndex],
        ...dataObj,
    };

    return draft;
};

export const addTipActivities = (draft, action) => {
    const { dataObj, dayIndex, index } = action;

    draft.experience.itinerary.trip_days[dayIndex].activities[
        index
    ].tips_html.push(dataObj);

    return draft;
};

export const deleteTipActivities = (draft, action) => {
    const { index, dayIndex, tipIndex } = action;

    draft.experience.itinerary.trip_days[dayIndex].activities[
        index
    ].tips_html.splice(tipIndex, 1);

    return draft;
};

export const arrangeTipsActivities = (draft, action) => {
    const { index, dayIndex } = action;

    return arrangeArray(
        draft,
        action,
        draft.experience.itinerary.trip_days[dayIndex].activities[index]
            .tips_html
    );
};

export const addActivity = (draft, action) => {
    const { dataObj, dayIndex } = action;

    draft.experience.itinerary.trip_days[dayIndex].activities.push(dataObj);

    return draft;
};

export const arrangeActivities = (draft, action) => {
    const { dayIndex } = action;

    return arrangeArray(
        draft,
        action,
        draft.experience.itinerary.trip_days[dayIndex].activities
    );
};

export const deleteActivity = (draft, action) => {
    const { index, dayIndex } = action;

    draft.experience.itinerary.trip_days[dayIndex].activities.splice(index, 1);

    return draft;
};

export const updateActivity = (draft, action) => {
    const { dataObj, index, dayIndex } = action;

    draft.experience.itinerary.trip_days[dayIndex].activities[index] = {
        ...draft.experience.itinerary.trip_days[dayIndex].activities[index],
        ...dataObj,
    };

    return draft;
};

export const updateGalleryActivity = (draft, action) => {
    const { index, galleryArray, dayIndex } = action;

    draft.experience.itinerary.trip_days[dayIndex].activities[index].images =
        galleryArray;

    return draft;
};

export const updateCaptionSlideActivity = (draft, action) => {
    const { index, slideIndex, caption, dayIndex } = action;

    draft.experience.itinerary.trip_days[dayIndex].activities[index].images[
        slideIndex
    ].caption = caption;

    return draft;
};

export const updateDescActivities = (draft, action) => {
    const { index, descHtml, dayIndex } = action;

    draft.experience.itinerary.trip_days[dayIndex].activities[
        index
    ].description_html = descHtml;

    return draft;
};

export const updateMapActivity = (draft, action) => {
    const { index, locationData, dayIndex } = action;

    draft.experience.itinerary.trip_days[dayIndex].activities[index].location =
        locationData;

    return draft;
};

export const arrangeVendorActivity = (draft, action) => {
    const { dayIndex, index } = action;

    return arrangeArray(
        draft,
        action,
        draft.experience.itinerary.trip_days[dayIndex].activities[index]
            .vendors
    );
};

export const deleteVendorActivity = (draft, action) => {
    const { dayIndex, index, vendorIndex } = action;

    draft.experience.itinerary.trip_days[dayIndex].activities[index].vendors.splice(vendorIndex, 1);

    return draft;
};

export const updateVendorActivity = (draft, action) => {
    const { dayIndex, index, vendorIndex, dataObj } = action;

    draft.experience.itinerary.trip_days[dayIndex].activities[index].vendors[
        vendorIndex
    ] = {
        ...draft.experience.itinerary.trip_days[dayIndex].activities[index]
            .vendors[vendorIndex],
        ...dataObj,
    };

    return draft;
};

export const addVendorActivity = (draft, action) => {
    const { dayIndex, dataObj, index } = action;

    draft.experience.itinerary.trip_days[dayIndex].activities[index].vendors.push(dataObj);

    return draft;
};