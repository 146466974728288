import * as types from 'store/actions/types';

export function toggleNav(navIsOpen) {
    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_NAV,
            navIsOpen
        });
    };
}

export function toggleLang(lang) {
    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_LANG,
            lang
        });
    };
}

export function toggleEdit(edit) {
    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_EDITMODE,
            edit
        });
    };
}

export function togglePrintMode(isPrintMode) {
    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_PRINTMODE,
            isPrintMode
        });
    };
}

export function toggleFeedbackMode(isFeedbackMode) {
    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_FEEDBACKMODE,
            isFeedbackMode
        });
    };
}

export function toggleSuperAdmin(isSuperAdmin) {
    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_SUPERADMIN,
            isSuperAdmin
        });
    };
}

export function setIsAdminStaffMode(isAdminStaff) {
    return (dispatch) => {
        dispatch({
            type: types.SET_ISADMINSTAFFMODE,
            isAdminStaff
        });
    };
}

export function setIsOwnerMode(isOwner) {
    return (dispatch) => {
        dispatch({
            type: types.SET_ISOWNERMODE,
            isOwner
        });
    };
}

export function setIsPublishedViewMode(isPublishedView) {
    return (dispatch) => {
        dispatch({
            type: types.SET_ISPUBLISHEDVIEWMODE,
            isPublishedView
        });
    };
}

export function setIsEduContentMode(isEduContent) {
    return (dispatch) => {
        dispatch({
            type: types.SET_ISEDUCONTENTMODE,
            isEduContent
        });
    };
}

export function toggleToolTips(toolTips) {
    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_TOOLTIPS,
            toolTips
        });
    };
}

export function toggleTour(tour) {
    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_TOUR,
            tour
        });
    };
}

export function googleMapsInitialised() {
    return (dispatch) => {
        dispatch({
            type: types.GOOGLE_MAP_INITIALISED
        });
    };
}

export function googleMapsInitialisedFailed() {
    return (dispatch) => {
        dispatch({
            type: types.GOOGLE_MAP_INITIALISED_FAILED
        });
    };
}

export function setFilterStatus(filterType, value) {
    return (dispatch) => {
        dispatch({
            type: types.SET_FILTER,
            filterType,
            value
        });
    };
}
export function setPagination(currentPage, value) {
    return (dispatch) => {
        dispatch({
            type: types.SET_EXP_LIST_PAGINATION,
            currentPage
        });
    };
}
export function menuRefsSetting() {
    return (dispatch) => {
        dispatch({
            type: types.MENU_REFS_SETTING
        });
    };
}

export function menuRefsSet() {
    return (dispatch) => {
        dispatch({
            type: types.MENU_REFS_SET
        });
    };
}
