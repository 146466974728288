const MadeWithLove = ({ className }) => {
    return (
        <div
            className={`flex items-center justify-center text-xs mt-8 gap-2 ${className}`}
        >
            Made with
            <i className='text-xl ri-heart-line text-green-400' />
        </div>
    );
};

export default MadeWithLove;
