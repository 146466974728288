/* eslint-disable react/jsx-pascal-case */
import { connect } from 'react-redux';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { handleRowReverse } from 'helpers/FEutils';
import CardXlEditDelete from 'components/blocks/CardXlEditDelete';
import { CardXlDragHandleItem } from 'components/blocks/CardXl';
import AccomodationDaysSelect from 'components/blocks/AccomodationDaysSelect';
import FeedbackBox from './FeedbackBox';

function Accommodation({
    objId,
    byDay = 'initial',
    edit,
    globalState: {
        lang,
        isPublishedView,
        isAdminStaff,
        isOwner,
        isFeedbackMode
    },
    accommInfo,
    // defaultHtml = '-tanslate',
    // hintObj = uiStruct.itinerary.activities.uiEdit.modals.description.hint,
    // icon = uiStruct.itinerary.activities.uiEdit.modals.description.icon,
    // nohover = null,
    provided = { draggableProps: [], innerRef: '' },
    handleDeleteBtnAction,
    index,
    handleClickBtnAction,
    handleDayUpdate
}) {
    const rtl = !!translations[lang].rtl;
    const handleEdit = (e) => {
        handleClickBtnAction(index);
    };

    const handleDelete = () => {
        handleDeleteBtnAction(index);
    };
    const {
        info: { rating, type: hotelType },
        address,
        data: {
            // zoom,
            location: { lat, lng }
        }
    } = accommInfo;

    const googleMapLink = `https://maps.google.com/?q=${lat},${lng}&ll=${lat},${lng}&z=16`;

    // const googleMapLink = (
    //     <a
    //         href={`https://maps.google.com/?q=${lat},${lng}&ll=${lat},${lng}&z=${zoom}`}
    //         rel='noreferrer'
    //         target='_blank'
    //         className={`${
    //             address
    //                 ? 'text-sm underline-none'
    //                 : 'text-sm italic text-gray-400'
    //         }`}
    //     >
    //         {address}
    //     </a>
    // );

    const getStars = (str, prefix) => {
        const nb = Number(str.charAt(0));
        if (isNaN(nb)) return '';
        else {
            const stars = [];
            for (let i = 0; i < nb; i++) {
                stars.push(
                    <span key={'sp_' + index + '_' + i}>
                        <i className='ri-star-s-fill text-sm text-blue-700'></i>
                    </span>
                );
            }

            return stars;
        }
    };

    const handleDayUpdateAction = (data) => {
        const days = data
            .map((day) => {
                return day.value;
            })
            .sort((a, b) => a - b);

        handleDayUpdate(days, index);
    };

    const makeDays = () => {
        if (byDay !== 'initial') {
            return null;
        }
        const { days } = accommInfo;

        if (days) {
            return (
                <div className='mt-6 flex gap-2 items-center flex-wrap'>
                    {days.map((day) => {
                        const key = `Day${day}`;
                        return (
                            <div
                                key={key}
                                className={`text-gray-900 group text-xs bg-white flex flex-shrink-0 gap-2 flex-nowrap items-center hover:bg-blue-200 rounded-full px-3 py-0.5 ${
                                    handleRowReverse(rtl).flex
                                }`}
                            >
                                <i className='group-hover:text-blue-700 text-blue-400 text-lg ri-check-line'></i>

                                <span
                                    className={`break-all ${
                                        handleRowReverse(rtl).text
                                    }`}
                                >
                                    Day {day}
                                </span>
                            </div>
                        );
                    })}
                </div>
            );
        }
        return null;
    };

    const openLink = () => {
        if (edit) return null;
        else return window.open(googleMapLink, '_blank', 'noopener,noreferrer');
    };

    return (
        <li
            className={`mb-4 sm:items-start relative flex group flex-col  ${
                rtl ? 'kn-rtl sm:kn-ltr' : ''
            } sm:flex-row py-5 px-4 w-full  bg-blue-50 hover:bg-blue-100
            } transition-colors ease-in-out duration-300 rounded-3xl sm:${
                handleRowReverse(rtl).flex
            }`}
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            <div
                onClick={openLink}
                // href={googleMapLink}
                // rel='noreferrer'
                // target='_blank'
                className={`w-full h-full ${edit ? '' : 'cursor-pointer'}`}
            >
                <span className='absolute right-4 top-4'>
                    <i className='ri-map-pin-line text-xl text-blue-600'></i>
                </span>
                {edit && !isFeedbackMode && (
                    <>
                        <CardXlEditDelete
                            handleDelete={handleDelete}
                            handleEdit={handleEdit}
                            colors={uiStruct.ui.tip_list.UI_LBL_NOTE}
                            rtl={rtl}
                            close='center'
                        />
                    </>
                )}
                <CardXlDragHandleItem
                    dragOff={!edit || isFeedbackMode}
                    dragProps={provided.dragHandleProps}
                    rtl={rtl}
                />
                <div className='flex flex-col md:flex-row md:items-center2 gap-4 md:gap-0'>
                    <div className='flex items-center'>
                        <span className='px-4 py-1 bg-blue-200 text-blue-800 text-sm rounded-full whitespace-nowrap font-bold italic mr-4'>
                            {translations[lang][hotelType]}
                        </span>
                        <span className='mr-4'>
                            {getStars(translations[lang][rating], index)}
                        </span>
                    </div>
                    <div className='px-2 flex '>
                        <span className='mr-4 text-sm underline-none'>
                            {address}
                        </span>
                    </div>
                </div>
                {edit && !isFeedbackMode ? (
                    <AccomodationDaysSelect
                        key={`day${Math.random()}`}
                        handleDayUpdate={handleDayUpdateAction}
                        days={accommInfo.days}
                        index={index}
                    />
                ) : (
                    makeDays()
                )}
            </div>
            <FeedbackBox
                objId={objId}
                label='Accommodation'
                isAdminStaff={isAdminStaff}
                isPublishedView={isPublishedView}
                isOwner={isOwner}
            />
        </li>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(Accommodation);
