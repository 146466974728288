import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { arrangeActivities } from 'store/actions/experienceDetails/activities';
import useDraggableInPortal from 'helpers/useDraggableInPortal';
import NavigationActivity from './navTypes/NavigationActivity';
import NavigationMoveAround from './navTypes/NavigationMoveAround';
import NavigationDaysOverview from './navTypes/NavigationDaysOverview';
import NavigationCurrencyOverview from './navTypes/NavigationCurrencyOverview';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic';
import { arrangeItineraryDays } from 'store/actions/experienceDetails/overview';
import { arrangeCurrencyCountries } from 'store/actions/experienceDetails/currency';
import { countriesTranslations } from 'constants/countriesTranslations';
import * as types from 'store/actions/types';
import { getCompletion, getDayCompletion } from 'helpers/menuHelpers';
import { useWindowSize } from 'helpers/useWindowSize';

function LayoutNavList(props) {
    const {
        navType,
        rtl,
        dayIndex,
        experienceDetails: { experience },
        globalState: { lang, edit, isFeedbackMode },
        arrangeActivities,
        experienceDetailsAction,
        arrangeItineraryDays,
        arrangeCurrencyCountries,
        handleScroll,
        handleMenuIsOpen,
        visibleSection
    } = props;
    const renderDraggable = useDraggableInPortal();
    let navList = null;
    const [width] = useWindowSize();
    const handleBtnClick = (theRef) => {
        handleScroll(theRef, width);
        if (width < 1280 && width !== 0) handleMenuIsOpen(false);
    };

    const handleOnDragEnd = (result) => {
        const { source, destination } = result;
        if (source && destination && source.index !== destination.index) {
            if (navType === 'activity') {
                arrangeActivities(dayIndex, source.index, destination.index);
            } else if (navType === 'movingAround') {
                experienceDetailsAction({
                    type: types.ARRANGE_MOVING_AROUND,
                    source: source.index,
                    destination: destination.index,
                    dayIndex
                });
            } else if (navType === 'daysOverview') {
                arrangeItineraryDays(source.index, destination.index);
            } else if (navType === 'currencyOverview') {
                arrangeCurrencyCountries(source.index, destination.index);
            } else {
                experienceDetailsAction({
                    type: types.ARRANGE_MOVING_AROUND_OVERVIEW,
                    source: source.index,
                    destination: destination.index,
                    dayIndex
                });
            }
        }
    };

    const getNavType = (provided, obj, key, index) => {
        const activityCompletion =
            experience.itinerary.trip_days[dayIndex]?.activities[index];
        const movingCompletion = experience.moving_around[index];
        const itineraryCompletion = experience.itinerary.trip_days[index];

        switch (navType) {
            case 'activity':
                return (
                    <NavigationActivity
                        isFeedbackMode={isFeedbackMode}
                        visibleSection={visibleSection}
                        handleBtnClick={handleBtnClick}
                        lang={lang}
                        edit={edit}
                        provided={provided}
                        key={key}
                        rtl={rtl}
                        activity={{
                            title: obj.title,
                            type: obj.type
                        }}
                        completion={getCompletion({
                            title: activityCompletion.title,
                            description_html:
                                activityCompletion.description_html,
                            images: activityCompletion.images,
                            location: activityCompletion.location.address
                        })}
                        index={index}
                    />
                );
            case 'movingAroundOverview':
                return (
                    <NavigationMoveAround
                        isFeedbackMode={isFeedbackMode}
                        visibleSection={visibleSection}
                        handleBtnClick={handleBtnClick}
                        index={index}
                        lang={lang}
                        edit={edit}
                        provided={provided}
                        key={key}
                        rtl={rtl}
                        type={obj.type}
                        completion={getCompletion({
                            type: movingCompletion.type,
                            description_html: movingCompletion.description_html,
                            vendors: movingCompletion.vendors
                        })}
                    />
                );
            case 'daysOverview':
                return (
                    <NavigationDaysOverview
                        isFeedbackMode={isFeedbackMode}
                        visibleSection={visibleSection}
                        handleBtnClick={handleBtnClick}
                        lang={lang}
                        edit={edit}
                        provided={provided}
                        key={key}
                        rtl={rtl}
                        day={index}
                        title={obj.day_intro.title}
                        completion={getDayCompletion(itineraryCompletion).total}
                    />
                );
            case 'currencyOverview':
                return (
                    <NavigationCurrencyOverview
                        isFeedbackMode={isFeedbackMode}
                        visibleSection={visibleSection}
                        lang={lang}
                        edit={edit}
                        provided={provided}
                        key={key}
                        rtl={rtl}
                        day={index}
                        title={
                            countriesTranslations[lang][obj.countryCode].name
                        }
                    />
                );
            default:
                break;
        }
    };

    switch (navType) {
        case 'activity':
            navList = experience.itinerary.trip_days[dayIndex].activities;
            break;
        case 'movingAround':
            navList = experience.itinerary.trip_days[dayIndex].moving_around;
            break;
        case 'daysOverview':
            navList = experience.itinerary.trip_days;
            break;
        case 'movingAroundOverview':
            navList = experience.moving_around;
            break;
        case 'currencyOverview':
            navList = experience.local_currency;
            break;
        default:
            break;
    }

    const buildActivityNav = () => {
        return navList.map((obj, index, arr) => {
            const key = `${navType}_${index}`;

            return (
                <Draggable key={key} draggableId={key} index={index}>
                    {renderDraggable((provided) =>
                        getNavType(provided, obj, key, index)
                    )}
                </Draggable>
            );
        });
    };

    return (
        <div className=''>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId={navType}>
                    {(provided) => (
                        <ul
                            className='h-full'
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {buildActivityNav(provided)}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
}
const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            arrangeActivities,
            experienceDetailsAction,
            arrangeItineraryDays,
            arrangeCurrencyCountries
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutNavList);
