import React, { useState, useEffect } from 'react';
import store from 'store/index';

const useStatuses = () => {
    const {
        auth: {
            user: { role: userRole, id: userId, isEdu }
        },
        experienceDetails: { user: expAuthor }
    } = store.getState();

    const [isAdminStaff, setIsAdminStaff] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [isEduContent, setIsEduContent] = useState(false);
    const [isPublishedView, setIsPublishedView] = useState(false);

    useEffect(() => {
        setIsAdminStaff(
            userRole === process.env.REACT_APP_STRAPI_ADMIN_ROLE_ID ||
                userRole === process.env.REACT_APP_STRAPI_ADMIN_ROLE_ID
        );
        setIsOwner(expAuthor?.id === userId);
        // setIsEduContent(
        //     expAuthor?.role === process.env.REACT_APP_STRAPI_STAFF_ROLE_ID
        // );
        setIsEduContent(isEdu);
        setIsPublishedView(
            window.location.pathname.includes(
                process.env.REACT_APP_BASENAME_EXPERIENCE_PUBLISHED
            )
        );
    }, [userRole, window.location.pathname, expAuthor]);

    return { isAdminStaff, isPublishedView, isOwner, isEduContent };
};

export default useStatuses;
