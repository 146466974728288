import Image from 'components/blocks/Image';
import { PillLogo } from 'components/blocks/Pills';
import Button from 'components/blocks/Title/Button';
import GradientTitle from 'components/blocks/Title/GradientTitle';
import LandingCapture from 'components/landing/LandingCapture';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Row, Section, Column, OptionCard } from 'components/layouts/LayoutElements';
import { marketPath } from 'constants/globalConsts';

const list1 = [
    {
        text: 'Check your completion progress',
        tag: ''
    },
    {
        text: 'Follow the status of your submission',
        tag: ''
    },
    {
        text: 'Edit the price and calendar',
        tag: ''
    },
    {
        text: 'Get detailed performance analytics',
        tag: 'soon'
    }
];

const LayoutHowTo = () => {
    const [tab, setTab] = useState('kreate');
    const tabClasses = {
        on: 'border-green-400',
        off: 'text-gray-400 border-transparent hover:border-gray-200'
    };
    const containerClasses = {
        on: 'translate-x-0',
        off: '-translate-x-full'
    };

    const path = window.location.hash;

    const [firstOpen, setFirstOpen] = useState(true);
    useEffect(() => {
        // const path = window.location.hash;
        if (path && path.includes('#')) {
            setTimeout(() => {
                const id = path.replace('#', '');
                const el = window.document.getElementById(id);
                const r = el?.getBoundingClientRect();
                window.top.scroll({
                    top: r?.top ?? 0
                    // behavior: 'smooth'
                });
            }, 100);
        }
    }, [path]);

    return (
        <>
            <Section id='video' background='landing-gradient' padding='py-16 md:pt-24 md:pb-24 lg:pb-0' margins='mt-16'>
                <Row justify='justify-end'>
                    <Column textSize='text-2xl'>
                        <GradientTitle label='How-to videos' textSize='text-4xl md:text-5xl 2xl:text-6xl' justify='' containerClass='mb-2 md:mb-4' />
                        <h3 className='text-2xl md:text-3xl xl:text-4xl font-bold md:mb-4 leading-tight tracking-tight mb-4'>
                            Step by step videos on using the platform
                        </h3>
                        <div className='text-lg'>
                            Check the video playlist below to learn how to use various parts of the platform. New videos are constantly being added.
                        </div>
                    </Column>
                    <Column textSize='text-2xl' align='items-center'>
                        <img src='/assets/media/basics/exp_videos@2x.png' className='h-96 w-auto object-contain -mt-4' />
                    </Column>
                </Row>
            </Section>

            <Section id='how-to' pill='top' background='bg-white' maxWidth=''>
                <Row justify='justify-center' padding='pt-8' maxWidth='' flex='flex-row'>
                    <div class='relative flex justify-center'>
                        <div className='md:hidden w-full'>
                            <iframe
                                width='390'
                                height='300'
                                src={`https://www.youtube-nocookie.com/embed/playlist?list=${process.env.REACT_APP_TUTORIAL_VID_CODE}&modestbranding=1&rel=0&iv_load_policy=3&theme=light&color=white`}
                                frameorder='0'
                                allowFullScreen
                            ></iframe>
                        </div>
                        <div className='hidden md:block lg:hidden mx-auto'>
                            <iframe
                                width='700'
                                height='350'
                                src={`https://www.youtube-nocookie.com/embed/playlist?list=${process.env.REACT_APP_TUTORIAL_VID_CODE}&modestbranding=1&rel=0&iv_load_policy=3&theme=light&color=white`}
                                frameorder='0'
                                allowFullScreen
                            ></iframe>
                        </div>
                        <div className='hidden lg:block mx-auto '>
                            <iframe
                                width='1024'
                                height='533'
                                src={`https://www.youtube-nocookie.com/embed/playlist?list=${process.env.REACT_APP_TUTORIAL_VID_CODE}&modestbranding=1&rel=0&iv_load_policy=3&theme=light&color=white`}
                                frameorder='0'
                                allowFullScreen
                            ></iframe>
                        </div>

                        {firstOpen && (
                            <>
                                <div className='w-44a absolute top-0 right-8 transform -translate-y-full pointer-events-none'>
                                    <div className='flex flex-col items-end'>
                                        <span className='mb-1'>Open Video playlist here</span>
                                        <i className='las la-arrow-down text-3xl animate-bounce'></i>
                                    </div>
                                </div>
                                <div className='absolute top-0 right-0 w-8 h-8 ' onClick={() => setFirstOpen(false)}></div>
                            </>
                        )}
                    </div>
                </Row>
            </Section>
        </>
    );
};

export default LayoutHowTo;

const Card = ({ number, title, children, image }) => {
    return (
        <div className='w-full mb-16 md:mb-0  flex flex-col md:flex-row lg:flex-col md:gap-8 lg:gap-0'>
            <img src={image} className='h-96 w-auto object-contain' />
            <div className='md:mt-16 lg:mt-0 px-4 xl:px-0'>
                <div className='flex items-center gap-4 mb-4'>
                    <NbPill value={number} />

                    <div className='text-4xl text-green-400 font-semibold'>{title}</div>
                </div>
                <div className='text-lg text-gray-600 '>{children}</div>
            </div>
        </div>
    );
};

const Question = ({ title, children, number }) => {
    return (
        <div>
            {/* <h2 className='text-green-400 text-4xl font-bold mb-2'>{number}</h2> */}

            <h3 className='text-gray-800 text-2xl font-bold  flex items-center gap-4'>
                <div className='-ml-12'>
                    <NbPill value={number} />
                </div>
                <div>{title}</div>
            </h3>
            <div className='text-xl'>{children}</div>
        </div>
    );
};

const NbPill = ({ value }) => {
    return (
        <div className='rounded-full h-12 w-8 flex justify-center items-center text-xl bg-green-400 text-gray-600 font-bold shadow-2xl-green-500 '>
            <span className='mix-blend-multiply'>{value}</span>
        </div>
    );
};
