import React, { useState } from 'react';
import CollapseButton from 'components/blocks/CollapseButton';
import DestinationModal from 'components/modals/DestinationModal';
import Destination from './Destination';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic.js';
import {
    updateDestinations,
    arrangeDesinations,
    updatePlacePlacesList
} from 'store/actions/experienceDetails/destinations';
import { _DESTINATIONS_OVERVIEW } from 'store/actions/types';

function DestinationList({
    experienceDetailsAction,
    destinations,
    edit,

    updateDestinations,
    arrangeDesinations,
    updatePlacePlacesList
}) {
    const [destinationModalState, setDestinationModalState] = useState(false);
    const updateDestinationsList = (dataObj) => {
        updatePlacePlacesList(dataObj);
        updateDestinations(dataObj);
    };

    const updateSingleDestinations = (countryIndex, destIndex) => {
        const tmpDestinations = JSON.parse(JSON.stringify(destinations));
        tmpDestinations[countryIndex].splice(destIndex, 1);
        updateDestinations(tmpDestinations);
    };
    return (
        <>
            {edit && (
                <div className='relative pb-0.5 '>
                    <div className='absolute left-1/2 transform -translate-x-1/2 top-0 flex gap-2'>
                        <CollapseButton
                            labelHover={`${
                                destinations.length && destinations.length > 0
                                    ? 'Edit Destinations'
                                    : 'Add Destinations'
                            }`}
                            handleClick={() => setDestinationModalState(true)}
                            icon='ri-map-pin-add-line text-2xl -ml-0.5'
                            size='10'
                            textSize='text-xs'
                            sizeHover='w-44'
                            offsetCenter='2'
                            btnColor='bg-green-400 hover:bg-gray-900 text-green-900'
                        />
                    </div>
                </div>
            )}

            <div className={`${edit ? 'mt-16' : 'mt-4'}  mb-16 `}>
                {destinations.length
                    ? destinations.map((obj, index) => {
                          return (
                              <Destination
                                  index={index}
                                  key={`dest${index}`}
                                  destInf={obj}
                                  label={'text'}
                                  icon='CHECK'
                                  bullet='TRAIN'
                              />
                          );
                      })
                    : null}
            </div>

            {destinationModalState && (
                <DestinationModal
                    actionType={_DESTINATIONS_OVERVIEW}
                    setModalIsOpen={setDestinationModalState}
                    handleActionBtn={updateDestinationsList}
                    destinations={destinations}
                />
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            experienceDetailsAction,
            updateDestinations,
            arrangeDesinations,
            updatePlacePlacesList
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(DestinationList);
