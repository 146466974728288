import { arrangeArray } from '../common';
import { getCountryObjs } from 'helpers/countryHelpers.js';
import { defaultCurrency } from 'constants/experienceDetailConsts';
// import { defaultBestTimeToGoRange } from 'constants/experienceDetailConsts';

export const updatePlacePlacesList = (draft, action) => {
    const { placeIds } = action;

    draft.places_lists = placeIds

    return draft;
}



export const updateDescriptionDestinations = (draft, action) => {
    const { descHtml } = action;

    draft.experience.destination.description_html = descHtml;

    return draft;
};

export const arrangeDestinations = (draft, action) => {
    // arrangeArray(draft, action, draft.experience.best_time_to_go.time_range);

    return arrangeArray(draft, action, draft.experience.destination.locations);
};

export const deleteDestination = (draft, action) => {
    const { index } = action;
    const countryCode = draft.experience.destination.locations[index][0].countryCode;
    const { countriesCodeObj } = getCountryObjs();

    // draft.tags  = [...draft.tags.filter((tag) => {
    //     return tag !== countriesCodeObj[countryCode];
    // })];
    // Countries in other lists keep in sync
    // draft.experience.best_time_to_go.time_range =
    // draft.experience.best_time_to_go.time_range.filter((obj) => {
    //     return obj.countryCode !== countryCode
    // });

    draft.experience.local_currency = draft.experience.local_currency.filter(
        (obj) => {
            return obj.countryCode !== countryCode;
        }
    );

    draft.experience.destination.locations.splice(index, 1);

    return draft;
};


export const updateDestinations = (draft, action) => {
    const { dataObj } = action;
    const countryCodesDest = [];
    let goTimeCountryCodes = [];
    let currencyCountyCodes = [];

    // draft.experience.best_time_to_go.time_range.forEach(obj => {
    //     goTimeCountryCodes.push(obj.countryCode)
    // });

    draft.experience.local_currency.forEach((obj) => {
        currencyCountyCodes.push(obj.countryCode);
    });
    // Check for new country and add to best Time to go list
    // but not currency as this is added by the user
    dataObj.forEach((obj) => {
        const { countryCode } = obj[0];

        // if(!goTimeCountryCodes.includes(countryCode)) {
        //     draft.experience.best_time_to_go.time_range.push({
        //             countryCode: countryCode,
        //             range: [...defaultBestTimeToGoRange]
        //     });
        // }

        if (!currencyCountyCodes.includes(countryCode)) {
            draft.experience.local_currency.push({
                ...defaultCurrency(),
                countryCode,
            });
        }

        currencyCountyCodes.push(countryCode);
        countryCodesDest.push(countryCode);
    });

    // goTimeCountryCodes = []
    // draft.experience.best_time_to_go.time_range.forEach(obj => {
    //     goTimeCountryCodes.push(obj.countryCode)
    // });

    // Remove deleted countries from best time to go list
    // goTimeCountryCodes.forEach(countryCode => {
    //     if(!countryCodesDest.includes(countryCode)) {
    //         draft.experience.best_time_to_go.time_range =
    //             draft.experience.best_time_to_go.time_range.filter((obj) => {
    //                 return obj.countryCode !== countryCode
    //             });
    //     }
    // });

    // and if its in the currency list
    currencyCountyCodes.forEach((countryCode) => {
        if (!countryCodesDest.includes(countryCode)) {
            draft.experience.local_currency =
                draft.experience.local_currency.filter((obj) => {
                    return obj.countryCode !== countryCode;
                });
        }
    });

    draft.experience.destination.locations = JSON.parse(
        JSON.stringify(dataObj)
    );

    return draft;
};

export const arrangeTipsDestinations = (draft, action) => {
    return arrangeArray(draft, action, draft.experience.destination.tips_html);
};

export const deleteTipsDestinations = (draft, action) => {
    const { tipIndex } = action;

    draft.experience.destination.tips_html.splice(tipIndex, 1);

    return draft;
};

export const addTipDestinations = (draft, action) => {
    const { dataObj } = action;

    draft.experience.destination.tips_html.push(dataObj);

    return draft;
};

export const updateTipDestinations = (draft, action) => {
    const { tipIndex, dataObj } = action;

    draft.experience.destination.tips_html[tipIndex] = {
        ...draft.experience.destination.tips_html[tipIndex],
        ...dataObj,
    };

    return draft;
};
