import { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import translations from 'constants/translations';
import PoliciesModal from 'components/modals/PoliciesModal';

import { experienceDetailsAction } from 'store/actions/experienceDetails/generic.js';
import ConfirmModal from 'components/modals/ConfirmModal';

import ExpSubsection from 'components/blocks/ExpSubsection';
import CollapseButton from 'components/blocks/CollapseButton';

import FeedbackBox from 'components/blocks/FeedbackBox';
import PoliciesList from 'components/blocks/PoliciesList';
import { nanoid } from 'nanoid';

function SectionTags({
    tagsActionBtn,
    experienceDetailsAction,
    experienceDetails: { cats_list: tmpCats, tags_list: tmpTags },
    globalState: { lang, edit, isFeedbackMode, siteData },
    marketingData: {
        policies: { data, objId: rootObjId }
    },
    actionType
}) {
    const rtl = !!translations[lang].rtl;

    const cats_list = tmpCats.map((cat) => {
        const found = siteData?.categories?.find((elm) => elm.id == cat);

        return found?.name;
    });

    const tags_list = tmpTags.map((tag) => {
        const found = siteData?.tags?.find((elm) => elm.id == tag);

        return found?.name;
    });
    return (
        <>
            <ExpSubsection>
                <div className='marketing-title'>Categories & tags</div>

                <div className='relative'>
                    {edit && (
                        <div className='flex justify-center w-full pt-8'>
                            <CollapseButton
                                labelHover='Add / Edit'
                                handleClick={tagsActionBtn}
                                icon='ri-play-list-add-line text-2xl -ml-0.5'
                                size='10'
                                textSize='text-xs'
                                sizeHover='w-32'
                                offsetCenter='2'
                                btnColor='bg-green-400 hover:bg-gray-900 text-green-900'
                            />
                        </div>
                    )}
                </div>
                <div className='mt-6'>
                    Categories
                    <div className='flex items-center gap-2 mt-2'>
                        {cats_list.map((cat, index) => {
                            return (
                                <span key={`cats_${index}`} className='uppercase text-xs tracking-wide'>
                                    {index < cats_list.length - 1 ? `${cat}, ` : cat}
                                </span>
                            );
                        })}
                    </div>
                </div>
                <div className='mt-6'>
                    Hashtags
                    <div className='flex flex-wrap items-center gap-2 mt-2'>
                        {tags_list.map((tag, index) => {
                            return (
                                <span key={`cats_${index}`} className='uppercase text-xs tracking-wide'>
                                    #{index < tags_list.length - 1 ? `${tag}, ` : tag}
                                </span>
                            );
                        })}
                    </div>
                </div>
            </ExpSubsection>
        </>
    );
}

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    marketingData: state.experienceDetails.content_marketing,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            experienceDetailsAction
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionTags);
