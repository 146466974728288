/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import ModalContainer, { ModalFooter, ModalCTA, ModalButton, ModalHelp } from 'components/blocks/ModalTre';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import Editor from 'components/blocks/Editor';
import { BlockInputSingle } from 'components/blocks/Blocks';
import useDidMountEffect from 'helpers/useDidMountEffect';
import CurrencyList from 'components/blocks/CurrencyList';
import ModalFullscreen, { ModalBody } from 'components/blocks/ModalFullscreen';
import ModalTopBlock from 'components/blocks/ModalTopBlock';

// should be in all modals
const targetElement = document.querySelector('#root');

function DescriptionTitleModal({
    isAdminStaff = false,
    isOwner = true,
    txtOnly = false,
    maxMin = false,
    canBeEmpty = false,
    close,
    setModalIsOpen,
    handleActionBtn,
    updateObj = '',
    update = false,
    globalState: { lang, isSuperAdmin },
    icon = uiStruct.ui.modals.editor.icon,
    hintObj = null,
    placeholder = null,
    actionBtnObj = uiStruct.ui.modals.editor.buttons.action,
    cancelBtnObj = uiStruct.ui.modals.editor.buttons.cancel,
    children,
    type = 'text',
    width = '3xl',
    inputIcon,
    empty,
    showCurrency,
    handleCurrencyChange,
    title = '',
    subtitle = '',
    subtitleDescribe = ''
}) {
    //

    const rtl = !!translations[lang].rtl;

    const defaultDataObj = '';

    //
    const [editorValidationError, setEditorValidationError] = useState(false);

    const [dataObj, updateDataObj] = useState(() => (update ? updateObj : defaultDataObj)); // set to a default or editing
    const maxMinErrorDefault = {
        max: { error: false },
        min: { error: false }
    };
    const [maxMinData, setMaxMinData] = useState(() => (maxMin ? maxMin : false));
    const [maxMinDataValObj, setMaxMinDataValObj] = useState({
        ...maxMinErrorDefault
    });

    // Copy Below to all modals
    //////////////////////////////////////////////////////////////////////

    // State for triggering animation of modal in and out

    const [transitionState, setTransitionState] = useState('off');
    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    ///////////////////////////////////////////////////////////////////

    // Paul stuff
    const updateDescriptionHtml = (html) => {
        updateDataObj(txtOnly ? html.target.value : html);
        setEditorValidationError(false);
    };
    const tidyNumber = (num) => {
        return Math.floor(Math.abs(num)).toString().replace(/\./g, '');
    };
    const updateMin = (event) => {
        setMaxMinData({
            ...maxMinData,
            min: tidyNumber(event.target.value)
        });
        setMaxMinDataValObj({
            ...maxMinErrorDefault
        });
    };
    const updateMax = (event) => {
        setMaxMinData({
            ...maxMinData,
            max: tidyNumber(event.target.value)
        });
        setMaxMinDataValObj({
            ...maxMinErrorDefault
        });
    };

    // Paul stuff Handle Action BTN function
    const handleActionClick = () => {
        if (maxMin) {
            if (
                Number(maxMinData.min) > Number(maxMinData.max)
                // Number(maxMinData.min) > Number(maxMinData.max) ||
                // Number(maxMinData.min) === Number(maxMinData.max)
            ) {
                setMaxMinDataValObj({
                    max: { error: true },
                    min: { error: true }
                });
            } else {
                handleActionBtn(maxMinData);
                handleTransitionStateOff(); // important... don't forget to add it in all modals
            }
        } else {
            if (dataObj.replace(/(<([^>]+)>)/gi, '').length === 0 && !canBeEmpty) {
                setEditorValidationError(true);
            } else {
                handleActionBtn(dataObj);
                handleTransitionStateOff(); // important... don't forget to add it in all modals
            }
        }
    };

    useDidMountEffect(() => {
        setEditorValidationError(() => !dataObj.length);
    }, [dataObj]);

    ////////////////////////////////

    return (
        <>
            <ModalFullscreen
                width='4xl'
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={icon}
                close={close}
                closeButton={true}
                rtl={rtl}
                fullscreen={true}
            >
                {/* <ModalContainer
                width={width}
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={icon}
                close={close}
                rtl={rtl}
            > */}
                <ModalBody rtlOff>
                    <div className='max-w-7xl mx-auto'>
                        {title && (
                            <div className='w-full  px-4 md:px-8 lg:px-2 mb-8 mt-4'>
                                <ModalTopBlock title={title} subtitle={subtitle} text='' rtl={rtl} lang={lang} />
                            </div>
                        )}
                        Hello
                        <div className='flex flex-col-reverse portrait:flex-col-reverse lg:flex-row gap-16 lg:gap-4 xl:gap-8 px-0 md:px-8 lg:px-0'>
                            <div className='w-full'>
                                {children}
                                {!empty && (
                                    <>
                                        {txtOnly ? (
                                            <>
                                                {!maxMin ? (
                                                    <BlockInputSingle
                                                        normal
                                                        error={editorValidationError}
                                                        handleChange={updateDescriptionHtml}
                                                        id='name'
                                                        margins='mb-4'
                                                        value={dataObj}
                                                        placeholder={translations[lang][placeholder]}
                                                        rtl={rtl}
                                                        type={type}
                                                        icon={inputIcon}
                                                        fontSize='text-xl'
                                                    />
                                                ) : (
                                                    <div>
                                                        <BlockInputSingle
                                                            normal
                                                            iconText='Min'
                                                            iconClass='text-xs'
                                                            replaceIcon
                                                            error={maxMinDataValObj.min.error}
                                                            handleChange={updateMin}
                                                            id='name'
                                                            margins='mb-4'
                                                            value={maxMinData.min}
                                                            placeholder={translations[lang][placeholder]}
                                                            rtl={rtl}
                                                            type={type}
                                                            fontSize='text-xl'
                                                        />
                                                        <BlockInputSingle
                                                            normal
                                                            iconText='Max'
                                                            iconClass='text-xs'
                                                            error={maxMinDataValObj.max.error}
                                                            handleChange={updateMax}
                                                            id='name'
                                                            margins='mb-4'
                                                            value={maxMinData.max}
                                                            placeholder={translations[lang][placeholder]}
                                                            rtl={rtl}
                                                            type={type}
                                                            fontSize='text-xl'
                                                        />
                                                        {showCurrency && (
                                                            <CurrencyList
                                                                handleChange={handleCurrencyChange}
                                                                selectedValue={maxMinData.currency}
                                                                // height='2.5rem'
                                                                width='w-full'
                                                                // bgColor='white'
                                                                panelHeight='150px'
                                                                labelTextSize='0.875rem'
                                                                menuTextSize='0.75rem'
                                                                isLoading={false}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div className='mb-8 border-2 border-gray-200 rounded-lg pt-4 min-h-96'>
                                                <Editor valError={editorValidationError} handleChange={updateDescriptionHtml} html={dataObj} />
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        <ModalFooter>
                            {hintObj && <ModalHelp helpText={translations[lang][hintObj]} isHtml />}
                            {(isOwner || (isAdminStaff && isSuperAdmin)) && (
                                <ModalCTA>
                                    <ModalButton
                                        handleClick={handleTransitionStateOff}
                                        label={translations[lang][cancelBtnObj.label]}
                                        color={cancelBtnObj.color}
                                        icon={cancelBtnObj.icon}
                                    />
                                    <ModalButton
                                        handleClick={handleActionClick}
                                        label={translations[lang][actionBtnObj.label]}
                                        color={actionBtnObj.color}
                                        icon={actionBtnObj.icon}
                                    />
                                </ModalCTA>
                            )}
                        </ModalFooter>
                    </div>
                </ModalBody>

                {/* </ModalContainer> */}
            </ModalFullscreen>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(DescriptionTitleModal);
