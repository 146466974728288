/* eslint-disable import/no-anonymous-default-export */
import {
    GENERAL_LOADING,
    SUBMIT_SUCCESS,
    SUBMIT_FAIL,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    USER_PROFILE_MISSING,
    USER_PROFILE_SUCCESS,
    USER_PROFILE_FAIL,
    AUTH_ERROR,
    SWITCH_ERROR,
    AUTH_LOADING,
    AUTH_SWITCHING,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    SWITCH_SUCCESS,
    SWITCH_FAIL,
    LOGOUT,
    AUTH_CLEAR_ERRORS,
    CREATE_PROFILE,
    UPDATE_USER_BY_ID,
    UPDATE_PROFILE
} from 'store/actions/types';

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    isProfile: false,
    loading: false,
    error: null,
    user: {
        profile: {
            currency: 'USD'
        }
    }
};

export default function (state = initialState, action) {
    const { payload, type } = action;
    switch (type) {
        case AUTH_SWITCHING:
            return {
                ...state,
                loading: true,
                error: null
            };
        case `${UPDATE_PROFILE}_PENDING`:
        case `${CREATE_PROFILE}_PENDING`:
        case GENERAL_LOADING:
        case AUTH_LOADING:
            return {
                ...state,
                loading: true,
                error: null
            };

        case USER_LOADED:
        case USER_PROFILE_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                isProfile: true,
                loading: false,
                user: payload,
                error: null
            };
        case USER_PROFILE_MISSING:
        case USER_PROFILE_FAIL:
            return {
                ...state,
                isAuthenticated: true,
                isProfile: false,
                loading: false,
                user: payload,
                error: null
            };

        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
            localStorage.setItem('token', payload.jwt);
            return {
                ...state,
                token: payload.jwt,
                isAuthenticated: true,
                isProfile: false,
                loading: true,
                error: null
            };
        case SUBMIT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null
            };
        case SUBMIT_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            };

        case SWITCH_SUCCESS:
            localStorage.setItem('token', payload.jwt);
            return {
                ...state,
                token: payload.jwt,
                isAuthenticated: true,
                isProfile: false,
                loading: false,
                error: null
            };

        case SWITCH_ERROR:
        case SWITCH_FAIL:
            return state;

        case REGISTER_FAIL:
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT:
            localStorage.removeItem('token');
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
                isProfile: false,
                error: payload
            };
        case AUTH_CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        case `${UPDATE_USER_BY_ID}_FULFILLED`:
            const user = payload.data.data.updateUser.user;

            user.role = user.role.id;
            return {
                ...state,
                user
            };
        default:
            return state;
    }
}
