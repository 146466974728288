// import { get } from 'lodash';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { nanoid } from 'nanoid';

export const MOVING_AROUND = (lang) => {
    const objId = nanoid();
    return {
        type: uiStruct.itinerary.moving_around.uiEdit.modals.add.defaults.type,
        description_html: '',
        vendors: [],
        tips_html: [],
        objId: {
            section: `overview_moving_around_section_${objId}`,
            title: `overview_moving_around_title_${objId}`,
            checklist: `overview_moving_around_checklist_${objId}`,
            description: `overview_moving_around_description_${objId}`,
            tiplist: `overview_moving_around_tiplist_${objId}`,
            vendorlist: `overview_moving_around_vendorlist_${objId}`,
            gallery: `overview_moving_around_gallery_${objId}`
        }
    };
};

export const ACTIVITY = (lang, type) => {
    const objId = nanoid();
    return {
        title: '',
        type: uiStruct.itinerary.activities.uiEdit.modals.add.defaults.type,
        best_time_to_go: {
            min: 720,
            max: 780,
            isVisible: type === 'digital' ? false : false
        },
        duration: {
            min: 60,
            max: 120,
            isVisible: type === 'digital' ? false : false
        },
        pricing: {
            type: uiStruct.itinerary.activities.uiEdit.modals.add.defaults.pricingType,
            free: false,
            min: 0,
            max: 0,
            currency: '',
            suffix: '',
            isVisible: type === 'digital' ? false : false
        },
        reservation: {
            type: uiStruct.itinerary.activities.uiEdit.modals.add.defaults.reservation,
            isVisible: type === 'digital' ? false : false
        },

        tips_html: [],
        description_html: '',
        getting_there: {
            type: uiStruct.itinerary.activities.uiEdit.modals.add.defaults.gettingThere,
            duration: 10
        },
        vendors: [],
        images: [],
        location: {
            data: {
                zoom: 2,
                location: {
                    lat: 0,
                    lng: 0
                }
            },
            address: ''
        },
        objId: {
            activity: `activity_${objId}`,
            title: `activity_title_${objId}`,
            description: `activity_description_${objId}`,
            location: `activity_location_${objId}`,
            details: `activity_details_${objId}`,
            gallery: `activity_gallery_${objId}`,
            best_time_to_go: `activity_best_time_to_go_${objId}`,
            duration: `activity_duration_${objId}`,
            pricing: `activity_pricing_${objId}`,
            reservation: `activity_reservation_${objId}`,
            tiplist: `activity_tiplist_${objId}`,
            vendorlist: `activity_vendorlist_${objId}`
        }
    };
};

export const VENDOR = (lang) => {
    return {
        name: translations[lang][uiStruct.itinerary.moving_around.uiEdit.modals.vendor.defaultName],
        url: [
            {
                label: translations[lang][uiStruct.itinerary.moving_around.uiEdit.modals.vendor.defaultLabel],
                url: translations[lang][uiStruct.itinerary.moving_around.uiEdit.modals.vendor.defaultUrl]
            }
        ]
    };
};

export const TIP = (lang) => {
    return {
        title: translations[lang][uiStruct.itinerary.moving_around.uiEdit.modals.tip.defaultTitle],
        tip: translations[lang][uiStruct.itinerary.moving_around.uiEdit.modals.tip.defaultTip]
    };
};

export const defaultDay = (lang, type) => {
    return {
        day_intro: {
            day_featured_image: '',
            title: '',
            description_html: '',
            objId: {
                section: `day_intro_section_${nanoid()}`,
                image: `day_intro_image_${nanoid()}`,
                title: `day_intro_title_${nanoid()}`,
                description: `day_intro_description_${nanoid()}`
            }
        },
        upon_arrival: [],
        what_you_need: {
            description_html: '',
            checklist: [],
            isVisible: type === 'digital' ? false : false,
            objId: {
                section: `day_what_you_need_section_${nanoid()}`,
                description: `day_what_you_need_description_${nanoid()}`,
                checklist: `day_what_you_need_checklist_${nanoid()}`,
                tiplist: `day_what_you_need_tiplist_${nanoid()}`,
                vendorlist: `day_what_you_need_vendorlist_${nanoid()}`,
                gallery: `day_what_you_need_gallery_${nanoid()}`
            }
        },
        moving_around: [],
        activities: []
    };
};

export const defaultCurrency = (lang) => {
    const objId = nanoid();
    return {
        countryCode: '',
        description_html: '',
        tips_html: [],
        vendors: [],
        objId: {
            section: `overview_currency_section_${objId}`,
            xchange: `overview_currency_xchange_${objId}`,
            checklist: `overview_currency_checklist_${objId}`,
            description: `overview_currency_description_${objId}`,
            tiplist: `overview_currency_tiplist_${objId}`,
            vendorlist: `overview_currency_vendorlist_${objId}`,
            gallery: `overview_currency_gallery_${objId}`
        }
    };
};

export const defaultBestTimeToGoRange = [125, 175];

export const youtubeCode = (code) => {
    return (
        <iframe
            title='youtube'
            width='100%'
            height='100%'
            src={`https://www.youtube-nocookie.com/embed/${code}?modestbranding=1&rel=0&iv_load_policy=3&theme=light&color=white`}
            frameorder='0'
            allowFullScreen
        ></iframe>
    );
};
export const marketingGallerySize = 5;

export const galleryVidtType = 'popup';
