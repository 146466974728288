import { arrangeArray } from '../common';

export const deleteMovingAround = (draft, action) => {
    const { dayIndex, index } = action;

    draft.experience.itinerary.trip_days[dayIndex].moving_around.splice(
        index,
        1
    );

    return draft;
};

export const addMovingAround = (draft, action) => {
    const { dayIndex, dataObj } = action;

    draft.experience.itinerary.trip_days[dayIndex].moving_around.push(dataObj);

    return draft;
};

export const arrangeMovingAround = (draft, action) => {
    const { dayIndex } = action;

    return arrangeArray(
        draft,
        action,
        draft.experience.itinerary.trip_days[dayIndex].moving_around
    );
};

export const arrangeVendorsMovingAround = (draft, action) => {
    const { dayIndex, index } = action;

    return arrangeArray(
        draft,
        action,
        draft.experience.itinerary.trip_days[dayIndex].moving_around[index]
            .vendors
    );
};

export const deletVendorMovingAround = (draft, action) => {
    const { dayIndex, index, vendorIndex } = action;

    draft.experience.itinerary.trip_days[dayIndex].moving_around[
        index
    ].vendors.splice(vendorIndex, 1);

    return draft;
};

export const updateVendoeMovingAround = (draft, action) => {
    const { dayIndex, index, vendorIndex, dataObj } = action;

    draft.experience.itinerary.trip_days[dayIndex].moving_around[index].vendors[
        vendorIndex
    ] = {
        ...draft.experience.itinerary.trip_days[dayIndex].moving_around[index]
            .vendors[vendorIndex],
        ...dataObj,
    };

    return draft;
};

export const addVendorMovingAround = (draft, action) => {
    const { dayIndex, dataObj, index } = action;

    draft.experience.itinerary.trip_days[dayIndex].moving_around[
        index
    ].vendors.push(dataObj);

    return draft;
};

export const arrangeTipsMovingAround = (draft, action) => {
    const { dayIndex, index } = action;

    return arrangeArray(
        draft,
        action,
        draft.experience.itinerary.trip_days[dayIndex].moving_around[index]
            .tips_html
    );
};

export const deleteTipMovingAround = (draft, action) => {
    const { dayIndex, index, tipIndex } = action;

    draft.experience.itinerary.trip_days[dayIndex].moving_around[
        index
    ].tips_html.splice(tipIndex, 1);

    return draft;
};

export const addTipMovingAround = (draft, action) => {
    const { dayIndex, dataObj, index } = action;

    draft.experience.itinerary.trip_days[dayIndex].moving_around[
        index
    ].tips_html.push(dataObj);

    return draft;
};

export const updateTipMovingAround = (draft, action) => {
    const { dayIndex, index, tipIndex, dataObj } = action;

    draft.experience.itinerary.trip_days[dayIndex].moving_around[
        index
    ].tips_html[tipIndex] = {
        ...draft.experience.itinerary.trip_days[dayIndex].moving_around[index]
            .tips_html[tipIndex],
        ...dataObj,
    };

    return draft;
};

export const updateDescriptionMovingAround = (draft, action) => {
    const { dayIndex, index, descHtml } = action;

    draft.experience.itinerary.trip_days[dayIndex].moving_around[
        index
    ].description_html = descHtml;

    return draft;
};

export const updateTypeMovingAround = (draft, action) => {
    const { dayIndex, index, movingAroundType } = action;

    draft.experience.itinerary.trip_days[dayIndex].moving_around[index].type =
        movingAroundType;

    return draft;
};
