import store from 'store';
import * as types from 'store/actions/types';

export function updateDestinations(dataObj) {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_DESTINATIONS_OVERVIEW, dataObj });
    };
}

export function arrangeDesinations(source, destination) {
    return (dispatch) => {
        dispatch({ type: types.ARRANGE_DESTINATIONS_OVERVIEW, source, destination });
    };
}

export function updatePlacePlacesList(dataObj) {
    const {
        globalState: {
            siteData: { placesLists }
        },
    } = store.getState();

    const places = [...dataObj.map((dest) => { return dest[0].countryCode; })];

    const placeIds = places.map((cnCode) => {
        const foundPlace = placesLists.find((obj) =>  obj.code === cnCode);
        if(foundPlace) {
            return foundPlace.id;
        }
    });

    return (dispatch) => {
        dispatch({ type: types.UPDATE_DESTINATIONS_PLACES_LIST, placeIds });
    };
}
