import { Link } from 'react-router-dom';
const Button = ({
    label,
    color = 'bg-gradient-to-r from-green-300 via-green-400 to-green-500 font-bold text-green-800',
    width = 'w-72',
    height = 'h-12',
    handleClick,
    link = '/',
    url,
    as = 'button',
    type = 'button',
    form,
    animation = true,
    fontSize = 'text-base',
    className,
    containerClass = 'flex',
    margins = '',
    shadow = 'shadow-2xl-green-500 hover:shadow-none'
}) => {
    const defaultStyle = `overflow-hidden rounded-lg ${color} ${shadow} hover:bg-gray-900 hover:text-white`;

    const btnJSX = (
        <div
            target='_blank'
            className={`${containerClass} overflow-hidden rounded-lg relative  ${width} ${height} items-center justify-center 
                    transition-all ${className ?? defaultStyle}
             transform-gpu duration-300 ${animation ? 'hover:-translate-y-2' : ''} ease-in-out `}
        >
            <span className={'absolute left-1/2 top-1/2 transform-gpu -translate-x-1/2 -translate-y-1/2 ' + margins}>
                <span className={`whitespace-nowrap ${fontSize}`}>{label}</span>
            </span>

            <span className='absolute inset-0 font-semibold text-white bg-gray-900 transition-opacity duration-200 hover:opacity-100 opacity-0'>
                <span className={'absolute left-1/2 top-1/2 transform-gpu -translate-x-1/2 -translate-y-1/2 ' + margins}>
                    <span className={`whitespace-nowrap ${fontSize}`}>{label}</span>
                </span>
            </span>
        </div>
    );

    return as == 'link' ? (
        <Link to={link} className={`${width}`}>
            {btnJSX}
        </Link>
    ) : as == 'url' ? (
        <a href={url} target='_blank'>
            {btnJSX}
        </a>
    ) : (
        <button form={form} type={type} className={`focus:outline-none outline-none ${width}`} onClick={handleClick}>
            {btnJSX}
        </button>
    );
};

export default Button;
