import * as types from 'store/actions/types';

export function createActivity(dayIndex, dataObj) {
    return (dispatch) => {
        dispatch({ type: types.ADD_ACTIVITY, dayIndex, dataObj });
    };
}

export function arrangeActivities(dayIndex, source, destination) {
    return (dispatch) => {
        dispatch({
            type: types.ARRANGE_ACTIVITY,
            dayIndex,
            source,
            destination,
        });
    };
}

export function updateActivity(dayIndex, dataObj, index) {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_ACTIVITY, dayIndex, dataObj, index });
    };
}

export function deleteActivity(dayIndex, index) {
    return (dispatch) => {
        dispatch({ type: types.DELETE_ACTIVITY, dayIndex, index });
    };
}

export function updateGalleryActivity(dayIndex, index, galleryArray) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_GALLERY_ACTIVITY,
            dayIndex,
            index,
            galleryArray,
        });
    };
}

export function updateCaptionSlideActivity(
    dayIndex,
    index,
    slideIndex,
    caption,
    type=types.UPDATE_CAPTION_SLIDE_ACTIVITY
) {
    return (dispatch) => {
        dispatch({
            type,
            dayIndex,
            index,
            slideIndex,
            caption,
        });
    };
}

export function updateMapDataActivity(dayIndex, index, locationData) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_MAP_DATA_ACTIVITY,
            dayIndex,
            index,
            locationData,
        });
    };
}
