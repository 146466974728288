import React from 'react';
import { connect } from 'react-redux';
// import translations from 'constants/translations';

const ExperienceCardSkeleton = ({ globalState: { lang } }) => {
    // const rtl = !!translations[lang].rtl;
    return (
        <div className='my-3 px-2 lg:my-4 lg:px-2 animate-pulse'>
            <div className='group relative bg-transparent transition-all duration-300 transform overflow-hidden rounded-xl hover:shadow-xl hover:-translate-y2-1 hover:bg-white'>
                <div>
                    <div
                        // className=' rounded-xl bg-gray-300 w-full h-40 xs360:h-44 xs390:h-52 xs410:h-56 sm:h-64 md:h-64 lg:h-64 xl:h-72 2xl:h-96'
                        className=' rounded-xl bg-gray-300 w-full h-56 md:h-36 lg:h-44 xl:h-56'
                    ></div>
                </div>

                <div className='p-2 sm:p-4 mt-4'>
                    <div className='w-32 bg-gray-300 rounded-lg h-3.5 mb-4' />
                    <div className='flex items-center mb-4'>
                        <div className='w-4 h-4 bg-gray-300 rounded-full mr-2' />
                        <div className='w-48 bg-gray-300 rounded-md h-2 mr-2' />
                    </div>
                    <div className='flex items-center  mb-20'>
                        <div className='w-4 h-4 bg-gray-300 rounded-full mr-2' />
                        <div className='w-12 bg-gray-300 rounded-md h-2 mr-4' />
                        <div className='w-4 h-4 bg-gray-300 rounded-full mr-2' />
                        <div className='w-8 bg-gray-300 rounded-md h-2 ' />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(ExperienceCardSkeleton);
