import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Popover } from '@headlessui/react';
import Icons from 'components/blocks/Icons';
import Editor from './Editor';
import GenericBtn from './GenericBtn';
// import { cloneDeep } from 'lodash';
// import moment from 'moment';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic.js';
import * as types from 'store/actions/types';
import FeedbackElement from './FeedbackElement';

const FeedbackBox = ({
    experienceDetailsAction,
    globalState: {
        edit,
        isFeedbackMode,
        isPrintMode,
        isAdminStaff,
        isOwner,
    },
    auth: { user, isAuthenticated },
    experienceDetails: {
        experience_status: { feedback_object }
        // experience_refs
    },
    objId,
    // children,
    inset = '-inset-2',
    dot = '-right-3.5 top-1  md:top-1/2 md:-right-8 md:-translate-y-1/2 ',
    root,

    posY = 'bottom-3/4',
    posX = '-translate-x-1/2 left-1/2',
    boxHeight = 'h-full',
    zLevel = 'z-200',
    zLevelPrint,
    label = 'element',
    labelPosX = 'left-1/2 -translate-x-1/2',
    labelPosY = 'top-0 -translate-y-1/2',
    type = 'default'
}) => {
    // const isAdminStaff =
    //     userRole === process.env.REACT_APP_STRAPI_ADMIN_ROLE_ID;

    const feedbackRef = useRef();

    // const elementRef = useRef();
    // if (!experience_refs[objId]) {
    //     experienceDetailsAction({
    //         type: `${types.ADD_REF}`,
    //         objId,
    //         elmRef: feedbackRef
    //     });
    // }

    // const feedbackRef = useRef();

    const defaultDataObj = '';

    const [dataObj, updateDataObj] = useState(defaultDataObj); // set to a default or editing

    const updateDescriptionHtml = (html) => {
        updateDataObj(html);
        // setEditorValidationError(false);
    };

    const addComment = () => {
        const singleFeedbackObject = {
            date: Date.now(),
            author: user?.profile
                ? `${user?.profile.first} ${user?.profile.last?.charAt(0)}.`
                : '-',
            text: dataObj,
            completed: false
        };

        experienceDetailsAction({
            type: `${types.ADD_COMMENT}`,
            comment: singleFeedbackObject,
            objId
            // elmRef: feedbackRef
        });
        updateDataObj(''); // clear editor
        // if (elementRef.current) elementRef.current.scrollTo(0, 1000);
    };

    const areTasksComplete = () => {
        return feedback_object[objId]?.data.every((elm) => elm.completed);
    };

    const [isOpen, setIsOpen] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const [zClass, setZClass] = useState('');

    useEffect(() => {
        setZClass(zLevelPerType());
    }, [isOpen]);

    const zLevelPerType = () => {
        if (type === 'gallery') {
            return '';
        }

        if (type === 'image') {
            return 'z-200';
        }

        if (type === 'default') {
            if (isOpen) {
                if (isPrintMode) {
                    if (isFeedbackMode) return zLevelPrint || zLevel;
                    else return '';
                } else {
                    if (isFeedbackMode) return zLevel;
                    else return '';
                }
            } else {
                return '';
            }
        }
    };

    return (
        <>
            {edit && isAuthenticated ? (
                <Popover className={` absolute ${inset} ${zClass}`}>
                    {({ open }) => {
                        setIsOpen(open);
                        return (
                            <>
                                <Popover.Button
                                    className={` group-scope focus:outline-none outline-none ${
                                        isFeedbackMode || open
                                            ? ` w-full ${boxHeight}  border-dotted border-2 ${
                                                  open
                                                      ? 'border-gray-400'
                                                      : 'border-transparent'
                                              }  hover:border-gray-400`
                                            : ''
                                    }  `}
                                >
                                    {!isFeedbackMode && !open && isHover && (
                                        <div
                                            className={`hidden group-scope-hover:block absolute 
                                         w-full2 inset-0  border-dotted border-2 border-gray-400
                                            
                                    `}
                                        ></div>
                                    )}
                                    <div
                                        className={` text-xs ${
                                            open ? 'block' : 'hidden'
                                        }  group-scope-hover:block absolute  transform ${labelPosX} ${labelPosY} `}
                                    >
                                        <span className='bg-yellow-200 text-yellow-900 px-2 rounded-full'>
                                            {label}
                                        </span>
                                    </div>

                                    {feedback_object[objId]?.data?.length >
                                        0 && (
                                        <span
                                            onMouseEnter={() =>
                                                setIsHover(true)
                                            }
                                            onMouseLeave={() =>
                                                setIsHover(false)
                                            }
                                            id={objId}
                                            ref={feedbackRef}
                                            className={`flex absolute  ${
                                                root ? 'h-5 w-5' : 'h-3 w-3'
                                            }  transform ${dot}`}
                                        >
                                            {!areTasksComplete() && (
                                                <span
                                                    className={`animate-ping absolute inline-flex h-full w-full rounded-full ${
                                                        root
                                                            ? areTasksComplete()
                                                                ? 'bg-green-500'
                                                                : 'bg-yellow-500'
                                                            : areTasksComplete()
                                                            ? 'bg-green-400'
                                                            : 'bg-yellow-400'
                                                    } opacity-75`}
                                                ></span>
                                            )}
                                            <span
                                                className={`relative inline-flex rounded-full  ${
                                                    root
                                                        ? areTasksComplete()
                                                            ? 'bg-green-500 h-5 w-5'
                                                            : 'bg-yellow-500 h-5 w-5'
                                                        : areTasksComplete()
                                                        ? 'bg-green-500 h-3 w-3'
                                                        : 'bg-yellow-500 h-3 w-3'
                                                }`}
                                            ></span>
                                        </span>
                                    )}
                                </Popover.Button>

                                {open && (
                                    <Popover.Panel
                                        className={`absolute  z-200 transform ${posX} ${posY}`}
                                    >
                                        <div
                                            className={`w-5 h-5 absolute ${
                                                isAdminStaff && isFeedbackMode
                                                    ? 'right-1/3'
                                                    : 'left-1/2'
                                            } -translate-x-1/2 translate-y-1/2 -bottom-0 transform rotate-45 ${
                                                isAdminStaff
                                                    ? 'bg-yellow-200'
                                                    : 'bg-yellow-200'
                                            }`}
                                        ></div>
                                        <div
                                            className={`relative max-w-80 md:max-w-full min-w-2xl1 max-w-4xl2 ${
                                                isAdminStaff && isFeedbackMode
                                                    ? 'w-192 '
                                                    : 'w-96'
                                            } overflow-hidden rounded-2xl border border-yellow-400 ring-4 ring-yellow-200 shadow-2xl-yellow-400`}
                                        >
                                            <div
                                                className={`absolute top-0 transform  rounded-full flex z-50 right-2  `}
                                            >
                                                <Popover.Button
                                                    className={`border-2 p-1 border-transparent rounded-full text-yellow-900 opacity-30 hover:opacity-100 focus:outline-none  flex items-center justify-center`}
                                                >
                                                    <Icons
                                                        iName='CLOSEALT'
                                                        Classes=''
                                                    />
                                                </Popover.Button>
                                            </div>

                                            <div className={`flex `}>
                                                {isAdminStaff &&
                                                    isFeedbackMode && (
                                                        <div className='w-1/2 relative'>
                                                            <div className='bg-white comment'>
                                                                <Editor
                                                                    // valError={editorValidationError}
                                                                    handleChange={
                                                                        updateDescriptionHtml
                                                                    }
                                                                    html={
                                                                        dataObj
                                                                    }
                                                                    minHeight=''
                                                                    commentMode
                                                                />
                                                            </div>
                                                            <div className='p-4 absolute bottom-0 bg-gray-100 inset-x-0 h-16 flex items-center justify-end '>
                                                                <GenericBtn
                                                                    handleActionBtn={
                                                                        addComment
                                                                    }
                                                                    disabled={
                                                                        dataObj.length ===
                                                                            0 ||
                                                                        dataObj ===
                                                                            '<p><br></p>'
                                                                    }
                                                                    params={[]}
                                                                    bgColor='bg-gray-300 hover:bg-gray-900'
                                                                    textColor='text-gray-700 hover:text-white'
                                                                >
                                                                    Post
                                                                </GenericBtn>
                                                            </div>
                                                        </div>
                                                    )}

                                                <div
                                                    className={`bg-yellow-50 ${
                                                        isAdminStaff &&
                                                        isFeedbackMode
                                                            ? 'w-1/2'
                                                            : 'w-full'
                                                    } `}
                                                >
                                                    <FeedbackElement
                                                        isAdminStaff={
                                                            isAdminStaff
                                                        }
                                                        isFeedbackMode={
                                                            isFeedbackMode
                                                        }
                                                        objId={objId}
                                                        feedback_object={
                                                            feedback_object[
                                                                objId
                                                            ]
                                                        }
                                                        modal
                                                    />
                                                    {/* {false && (
                                                <>
                                                    <div className='py-4'>
                                                        <div className='text-sm'>
                                                            Feedback
                                                        </div>
                                                        <div
                                                            style={{
                                                                fontSize: '8px'
                                                            }}
                                                        >
                                                            {`id: ${objId}`}
                                                        </div>
                                                    </div>
                                                    <div className='overflow-y-auto max-h-64'>
                                                        <div
                                                            className='flex  flex-col-reverse'
                                                            ref={elementRef}
                                                        >
                                                            {feedback_object[
                                                                objId
                                                            ]?.data?.length >
                                                                0 &&
                                                                feedback_object[
                                                                    objId
                                                                ]?.data.map(
                                                                    (
                                                                        elm,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <div
                                                                                key={`feedback_elm_${index}`}
                                                                                className='text-xs  border-t border-yellow-300 px-2'
                                                                            >
                                                                                <div className='flex justify-between items-center  mt-4 '>
                                                                                    <div className='flex items-center gap-2 '>
                                                                                        <div className='text-xxs bg-gray-900 text-white font-semibold px-2 py-0.5 rounded-md '>
                                                                                            {moment(
                                                                                                elm.date
                                                                                            ).format(
                                                                                                'MMM Do YYYY'
                                                                                            )}
                                                                                        </div>
                                                                                        <div className='text-xxs font-semibold '>
                                                                                            {moment(
                                                                                                elm.date
                                                                                            ).format(
                                                                                                'HH:mm'
                                                                                            )}
                                                                                        </div>
                                                                                        <div className='text-xxs font-semibold '>
                                                                                            {`by ${elm.author}`}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='flex items-center gap-4'>
                                                                                        {isAdminStaff && (
                                                                                            <button
                                                                                                onClick={() =>
                                                                                                    deleteComment(
                                                                                                        index
                                                                                                    )
                                                                                                }
                                                                                                className='focus:outline-none outline-none text-yellow-600 hover:text-black'
                                                                                            >
                                                                                                <i className='text-lg ri-delete-bin-line '></i>
                                                                                            </button>
                                                                                        )}
                                                                                        <button
                                                                                            onClick={() =>
                                                                                                completeComment(
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                            className={`focus:outline-none outline-none ${
                                                                                                elm.completed
                                                                                                    ? 'text-green-400'
                                                                                                    : 'text-yellow-400'
                                                                                            } `}
                                                                                        >
                                                                                            <i className='text-xl ri-checkbox-circle-line '></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className={`mt-2 mb-4 ${
                                                                                        elm.completed
                                                                                            ? 'line-through'
                                                                                            : ''
                                                                                    }`}
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: elm.text
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                        </div>
                                                    </div>
                                                </>
                                            )} */}
                                                </div>
                                            </div>
                                        </div>
                                    </Popover.Panel>
                                )}
                            </>
                        );
                    }}
                </Popover>
            ) : (
                <div id={objId} />
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            experienceDetailsAction
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackBox);
