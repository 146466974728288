import Spinner from 'components/blocks/Spinner';
import classNames from 'classnames';
const SpinnerButton = ({
    disabled,
    isBusy,
    handleClick,
    labelHover,
    labelActive,
    noHover = false
}) => {
    const handleBtnClick = () => {
        if (!disabled) handleClick();
    };

    return (
        <button
            className={classNames(
                'group ring-2 transition-all duration-300 out-expo ring-transparent outline-none focus:outline-none h-12 rounded-full flex items-center overflow-hidden',
                {
                    'bg-green-100  hover:bg-gray-900 hover:text-white':
                        !isBusy && !disabled,

                    'bg-gray-900  hover:ring-transparent': isBusy && !disabled,
                    'bg-gray-200 text-gray-600': disabled
                },

                noHover ? 'w-12' : 'hover:w-full',
                isBusy ? (noHover ? 'w-12' : 'w-full') : 'w-12'
            )}
            onClick={handleBtnClick}
        >
            {isBusy ? (
                <span className='flex items-center -ml-0.5 gap-3 px-4 '>
                    <Spinner size='20' strokeWidth='40' strokeColor='#00cc97' />
                    {!noHover && (
                        <span className={`block text-sm text-white`}>
                            {labelActive}
                        </span>
                    )}
                </span>
            ) : (
                <span
                    className={classNames(
                        'flex items-center px-3 transition-all duration-300 out-expo ',
                        noHover ? '' : 'gap-2  group-hover:px-4',
                        disabled
                            ? 'text-gray-600'
                            : 'group-hover:text-white text-green-800'
                    )}
                >
                    <i className='ri-save-line '></i>
                    {!noHover && (
                        <span
                            className={`block text-sm translate-x-2 transform group-hover:translate-x-0 transition-transform duration-300 `}
                        >
                            {labelHover}
                        </span>
                    )}
                </span>
            )}
        </button>
    );
};

export default SpinnerButton;
