import React, { useState, useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import translations from 'constants/translations';

import { handleRowReverse } from 'helpers/FEutils';
import { ReactComponent as KLogoIcon } from 'components/svg/kn_logoicon.svg';
import { ReactComponent as KLogoText } from 'components/svg/kn_logotext.svg';
import ButtonLoad from 'components/blocks/ButtonLoad';
import { animateIn, randomItem } from 'helpers/FEutils';
import { buildCountryData } from 'helpers/LocaleHelper';
import { updateDataAdmin } from 'fetch/user';
import { logout, clearLoginErrors, loadUser } from 'store/actions/auth';
import FormIkInput from 'components/forms/FormIkInput';
import { regexString, regexWhiteSpace } from 'components/utility/regexPatterns';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ui from 'translations/componentNames.json';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Images from 'constants/imagesTmp';
import LoadingPageNp from 'components/utility/LoadingPageNp';
import MadeWithLove from 'components/blocks/MadeWithLove';
import ListBoxGeneric from 'components/blocks/ListBoxGeneric';
import * as types from 'store/actions/types';
import { countries } from 'constants/countries';
//  import session from 'store/reducers/session';
import LayoutNavbar from 'components/layouts/LayoutNavbar';

const targetElement = document.querySelector('#root');

const AuthProfile = ({ auth, globalState: { lang }, updateDataAdmin, loadUser, session: { ip }, clearLoginErrors, ...props }) => {
    const splashImage = useRef(randomItem(Images).url);
    const handleLogout = () => {
        props.logout();
    };

    const handleSubmit = (values, actions) => {
        const {
            user: {
                profile: { id }
            }
        } = auth;

        const profile = {
            ...values,
            country: selectedCountry.id,
            user: auth.user._id,
            currency: countries[selectedCountry.id]?.currencyCode || 'USD'
        };
        updateDataAdmin(types.UPDATE_PROFILE, 'profiles', profile, id).then((res) => {
            if (!res.error) {
                loadUser();
            }
        });
    };

    const getCountryFromIp = () => {
        return ip?.status === 'success' ? { id: ip.countryCode, name: ip.country } : { id: 'US', name: 'USA' };
    };

    useEffect(() => {
        clearLoginErrors();
        animateIn('.kn-slide');
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const rtl = !!translations[lang].rtl;

    const countryList = buildCountryData(lang);
    // console.log(countryList);

    const [selectedCountry, setSelectedCountry] = useState(getCountryFromIp());

    if (auth.isAuthenticated == null) {
        return <LoadingPageNp />;
    }

    return auth.isAuthenticated ? (
        auth.isProfile ? (
            <Navigate to='/c' />
        ) : (
            <>
                <LayoutNavbar />
                <div className='fixed inset-0 hidden md:block lg:hidden'>
                    <img
                        alt=''
                        data-blink-src={splashImage.current}
                        className=' object-cover w-full h-96 md:h-screen-1/2 lg:h-full lg:rounded-3xl lg:overflow-hidden lg:shadow-3xl '
                    />
                </div>
                <div className='lg:relative kn-slide h-screen  overflow-y-scroll no-scrollbar bg-white md:bg-transparent'>
                    <div
                        style={{ zIndex: -50 }}
                        className='hidden lg:block px-0 py-0 fixed w-full lg:w-7/12 lg:min-h-screen inset-0 lg:px-16 xl:px-32 2xl:px-48 lg:py-32'
                    >
                        <img
                            alt=''
                            data-blink-src={splashImage.current}
                            className=' object-cover w-full h-80 lg:h-full lg:rounded-3xl lg:overflow-hidden lg:shadow-3xl '
                        />
                    </div>
                    <div className='w-full lg:w-5/12 ml-auto md:rounded-t-3xl lg:rounded-none bg-white  shadow-2xl-green-600-rev lg:shadow-2xl-green-600 mt-0 md:mt-96 lg:mt-0 '>
                        <div className={`flex flex-col px-12 lg:px-16 xl:px-24 2xl:px-32 py-16 lg:h-screen  h-full md:pb-20`}>
                            <div className={`flex items-center gap-3 ${handleRowReverse(rtl).flex}`}>
                                <KLogoIcon />
                                <KLogoText />
                                
                            </div>
                            <div className='flex'>
                                <div className='flex-shrink-0 text-transparent bg-clip-text bg-gradient-to-tl from-gray-900 via-blue-500 to-green-400 text-2xl font-bold tracking-tighter mt-10 '>
                                    Finish your profile
                                </div>
                            </div>
                            <div>
                                <Formik
                                    initialValues={{
                                        first: '',
                                        last: '',
                                        displayname: ''
                                    }}
                                    validationSchema={Yup.object({
                                        first: Yup.string()
                                            .min(2, ui.validation.short[lang])
                                            .max(50, ui.validation.long[lang])

                                            .matches(regexString, ui.validation.lettersAllowed[lang])
                                            .required(ui.validation.required[lang]),
                                        last: Yup.string()
                                            .min(2, ui.validation.short[lang])
                                            .max(50, ui.validation.long[lang])

                                            .matches(regexString, ui.validation.lettersAllowed[lang])
                                            .required(ui.validation.required[lang]),
                                        displayname: Yup.string()
                                            .max(50, ui.validation.long[lang])

                                            .matches(regexWhiteSpace, ui.validation.lettersAllowedWhite[lang])
                                    })}
                                    onSubmit={handleSubmit}
                                >
                                    {(props) => (
                                        <Form id='profileForm'>
                                            <div className='flex flex-col md:flex-row lg:flex-col mt-6 gap-12 lg:gap-0'>
                                                <div className='md:w-1/2 lg:w-full flex-1 flex flex-col'>
                                                    <div className='flex flex-col xl:flex-row w-full gap-4 mb-4'>
                                                        <FormIkInput name='first' type='text' placeholder={ui.forms.firstname[lang]} />

                                                        <FormIkInput name='last' type='text' placeholder={ui.forms.lastname[lang]} />
                                                    </div>
                                                    <div className='mb-4'>
                                                        <FormIkInput name='displayname' type='text' placeholder={ui.forms.displayname[lang]} />
                                                    </div>

                                                    <ListBoxGeneric
                                                        listData={countryList}
                                                        val={selectedCountry}
                                                        handleChange={setSelectedCountry}
                                                        textClass={'text-sm'}
                                                    />

                                                    <ButtonLoad
                                                        // handleClick={handleClick}
                                                        isLoading={auth.loading}
                                                        label="Let's go!"
                                                        width='w-full'
                                                        // handleClick={handleSubmit}
                                                        form='profileForm'
                                                        type='submit'
                                                    />
                                                </div>
                                                <div className='flex-1 flex flex-col md:mt-24 lg:mt-0'>
                                                    <div className='flex justify-center items-center mt-8 text-sm gap-2'>
                                                        <span>Not your account?</span>
                                                        <button className='focus:outline-none text-base text-green-400 font-medium' onClick={handleLogout}>
                                                            Log out
                                                        </button>
                                                    </div>

                                                    <MadeWithLove />
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    ) : (
        <Navigate to='/accounts/login' />
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth,
    session: state.session
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateDataAdmin,
            logout,
            clearLoginErrors,
            loadUser
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthProfile);
