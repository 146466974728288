import { arrangeArray } from '../common';

export const updateDescriptionAccommodation = (draft, action) => {
    const { descHtml } = action;

    draft.experience.accommodation.description_html = descHtml;

    return draft;
}

export const arrangeTipsAccommodation = (draft, action) => {
    return arrangeArray(draft, action, draft.experience.accommodation.tips_html);
}

export const deleteTipsAccommodation = (draft, action) => {
    const { tipIndex } = action;

    draft.experience.accommodation.tips_html.splice(tipIndex, 1);

    return draft;
}

export const addTipAccommodation = (draft, action) => {
    const { dataObj } = action;

    draft.experience.accommodation.tips_html.push(dataObj);

    return draft;
}

export const updateTipAccommodation = (draft, action) => {
    const { tipIndex, dataObj } = action;

    draft.experience.accommodation.tips_html[tipIndex] = {
        ...draft.experience.accommodation.tips_html[tipIndex],
        ...dataObj,
    };

    return draft;
}

export const createAccommodation = (draft, action) => {
    const { dataObj } = action;

    draft.experience.accommodation.locations.push(dataObj);

    return draft;
}

export const updateAccommodation = (draft, action) => {
    const { accommIndex, dataObj } = action;

    draft.experience.accommodation.locations[accommIndex] = {
        ...draft.experience.accommodation.locations[accommIndex],
        ...dataObj,
    };

    return draft;
}

export const arrangeAccommodation = (draft, action) => {
    return arrangeArray(draft, action, draft.experience.accommodation.locations);
}

export const deleteAccommodation = (draft, action) => {
    const { accommIndex } = action;

    draft.experience.accommodation.locations.splice(accommIndex, 1);

    return draft;
}

export const deleteACircleAccommodation = (draft, action) => {
    const { accommIndex } = action;

    draft.experience.accommodation.circles.splice(accommIndex, 1);

    return draft;
}

export const addCircleAccommodation = (draft, action) => {
    const { dataObj } = action;

    draft.experience.accommodation.circles.push(dataObj);

    return draft;
}

export const updateAccomGalleryCaption = (draft, action) => {
    const {  slideIndex, caption } = action;

    draft.experience.accommodation.images[
        slideIndex
    ].caption = caption;

    return draft;
}

export const updateGalleryActivityAccom = (draft, action) => {
    const { galleryArray } = action;
    if(!draft.experience.accommodation.images) {
        draft.experience.accommodation['images'] = [...galleryArray];
    } else {
        draft.experience.accommodation.images = galleryArray;
    }
    

    return draft;
};