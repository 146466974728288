export const versionLog = [
    // major arr
    [
        // minor arr
        [
            // patch arr
            {
                release: '24/07/2021',
                changes: []
            },
            {
                release: '25/07/2021',
                changes: []
            },
            {
                release: '25/07/2021',
                changes: []
            }
        ],
        [
            // patch arr
            {
                release: '26/07/2021',
                changes: [
                    'Before you go modal skinned.',
                    'Activity modal detail skinned',
                    'Area tools in accommodation map skinned',
                    'Currency component skinned',
                    'Added minimum zoom on accommodation',
                    'Added version log',
                    'Added Landing page',
                    'Removed OAuth options from login/signup pages',
                    'Added camping site option in accommodation list',
                    'Restored function to Add Day button in experience navbar',
                    'Tags and Categories are now loaded from Admin',
                    'Added intro block in modals',
                    'Added Video Tutorial/How to in the dashboard',
                    'Fixed When to go slider not showing correct values',
                    'Added random images to signup and login',
                    'Fixed completion rate for currency and general tips'
                ]
            },
            {
                release: '27/07/2021',
                changes: ['Release Candidate 1']
            },
            {
                release: '05/08/2021',
                changes: [
                    'Added videos to tour as a playlist',
                    'Accommodation map: explanation of circle usage',
                    'Activity modal dropdown: fixed cut by smaller screens',
                    'Hyperlinks fixed in Text editor',
                    'Added COVID19 type in tips/misc',
                    'Removed clutter in landing page image',
                    'Improved completion rate algorithm'
                ]
            },
            {
                release: '14/08/2021',
                changes: [
                    'Added feedback module to admins and creators',
                    'Added submit from within the experience building process',
                    'configured admin and staff roles for viewing, editing and approving experiences'
                ]
            },
            {
                release: '23/08/2021',
                changes: [
                    'Added superadmin toggle to enable site wide changes to experiences',
                    'Added extra layer for deleting experiences',
                    'Added filters for admin and staff for a granular overview of experiences',
                    'Added sorting of experiences based on date, completion rate and title',
                    'Added approve button for admin and staff from within the experience',
                    'Added public viewing capability for published experiences'
                ]
            },
            {
                release: '11/09/2021',
                changes: [
                    'Added Kreator Profile in side menu',
                    'Added Display Name in profile. Use it to display your brand, business or organization name',
                    'For Admins, added filter to see both Kreo and Marketplace experiences',
                    'For Admins, added an approve button inside the experience below the feedback button',
                    'In Activities, fixed the upper cap limit and added currency dropdown',
                    'Signup: Added info overlays showing the requirements for username and passwords',
                    'Bug fixes: Signup error code'
                ]
            },
            {
                release: '20/09/2021',
                changes: [
                    'Added Day Assignment to accommodations',
                    'Added Day Assignment ordering by day or by place (in preview mode)',
                    'Changed Modal maps to fullscreen maps in Overview>Accommodation and Day>Activitiy',
                    'Fixed Map zooming on page scrolling',
                    'Added acceptable default zoom to single accommodations to avoid extreme close up',
                    'Drop down list height fixed',
                    'Digital Price window incorrect icon showing fixed',
                    'Added file upload restrictions to manage large file sizes and incorrect file types'
                ]
            },
            {
                release: '26/09/2021',
                changes: [
                    'Separated Educational content from user content section',
                    'Adapted experience menu for smaller screens and mobile',
                    'Adapted right menu for mobile',
                    'Responsive layout for both creators and public viewing'
                ]
            },
            {
                release: '25/10/2021',
                changes: [
                    'Added Budget Section for Digital Experiences',
                    'Made Budget section optional and activated only if a max price is present',
                    'Added automatic conversion of any price to selected currency in profile',
                    'Added automatic Currency display and their exchange rate in the currency section based on chosen destinations',
                    'Prices and budgets can be defined in any currency of your choice and displayed back to user in their own currency',
                    'Added marketing section from the drop down in the nav menu that shows a preview of the marketplace',
                    'Marketplace has its own sections for granular control over what information to show on the marketing level with its own gallery and titles',
                    'Added Navigation bar at the bottom for when the left menu is closed',
                    'Added ability to edit Published experiences for Super Admins - by selecting Marketplace from the "Where" drop down box in the experience list page',
                    'Added filtering by latest updated experiences / not just created',
                    'Added Created and Updated date tags for experiences in the experience list',
                    'Guided experiences had their Included/Excluded list moved to the marketing section where one can describe with great precision what services are included in the experience',
                    'Fixed long titles overflowing',
                    'Added hovering for activities in menu (available previously only for sections)',
                    'Added Dynamic Jump to subsections from menu (previously available only to sections from menu)',
                    'Fixed bug that allowed signed in users to view all experiences in progress (not just published)',
                    'New registered users will have their Preferred Currency assigned based on chosen country - if that currency is available',
                    'New registered users will attempt to have their country of residence detected automatically based on their ip address.',
                    'Fixed Published bug that was jumping navigation back to normal edited experiences',
                    'Changed structure of reservation field in activity to include a visible state'
                ]
            },
            {
                release: '30/10/2021',
                changes: [
                    'Non-core sections (budget, when to go, moving around, currency, and general tips can all be hidden from an experience by clicking the eye icon on top of each section. Click the Preview button to see the effect',
                    'Added breakdown of completion on the top right: Overall, Overview, Itinerary, and Marketing',
                    'Made Activity details optional: You can choose which of the four items you want to keep (best time to go, duration , price, reservation)'
                ]
            },
            {
                release: '12/11/2021',
                changes: [
                    'Admins can change experience type from main experience list - settings button. Available only for non published exp.',
                    'Admins can change ownership of experience',
                    'Admins can change state of experience, reverting it back or forward',
                    'New Simplified booking module in place. Need to still connect it with the checkout module',
                    'Added Policy Module in Marketing section for Guided experiences',
                    'Print mode button available to give you a full rundown of the experience which you can print as pdf as a normal print job',
                    'New feedback module for Admins and Kreators on a per element level.',
                    'Print mode allows to view both feedback and full experience'
                ]
            },
            {
                release: '23/11/2021',
                changes: [
                    'Enabled Subscriptions of users to content through the Users model',
                    'Experiences can be made completely public',
                    'Kreators can view now their approved / live version as well as their Kreo versions in the Where drop down',
                    'Fixed popping of images on first load with spinners',
                    'An indicator on top right shows you if you are in Kreo or Marketplace view',
                    'Added a switcher between Kreo and Live/Marketplace versions in top right',
                    'Feedback mode now removes all normal interactivity to prevent any accidental editing',
                    'Initial Loading time improved x20'
                ]
            }
        ]
    ]
];

export const createLog = (majorArray) => {
    const htmlArr = majorArray.reverse().map((major, index) => {
        return createMinor(major, majorArray.length - index - 1);
    });
    return `<div class="mb-16 h-128 overflow-y-scroll">
                
                ${htmlArr.join('')}          
            </div>`;
};

const createMinor = (minorArray, majorIndex) => {
    const htmlArr = minorArray.reverse().map((minor, index) => {
        return createPatch(minor, majorIndex, minorArray.length - index - 1);
    });
    return `<div class="mb-16">
                
                ${htmlArr.join('')}          
            </div>`;
};

const createPatch = (patchArray, majorIndex, minorIndex) => {
    const htmlArr = patchArray.reverse().map((patch, index) => {
        return createSingle(
            patch,
            majorIndex,
            minorIndex,
            patchArray.length - index - 1
        );
    });
    return `<div class="mb-16">
                
                ${htmlArr.join('')}          
            </div>`;
};

const createSingle = (changesArray, majorIndex, minorIndex, patchIndex) => {
    const htmlArr = changesArray.changes.map((change, index) => {
        return `<li class="list-disc text-xs"> 
        ${change}
    </li>`;
    });
    return `<div class="mb-16">
                <div class="flex flex-nowrap items-center gap-4">
                    <div class="rounded-lg flex items-center justify-center w-36 text-sm font-bold border-2 py-1 mb-4">${majorIndex}.${minorIndex}.${patchIndex}</div>
                    <div class="rounded-lg flex items-center text-xs  py-1 mb-4">${
                        changesArray.release
                    }</div>
                </div>
                <ul class="block px-4">${htmlArr.join('')}</ul>                
            </div>`;
};
