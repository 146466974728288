import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import FeedbackElement from './FeedbackElement';
import GenericBtn from './GenericBtn';
import { feedbackStyling } from 'constants/feedbackStyling';

const FeedbackList = ({
    experienceDetails: {
        experience_status: { feedback_object }
    },
    auth: {
        user: { profile, role: userRole }
    },
    globalState: { isFeedbackMode }
}) => {
    const isAdminStaff = userRole === process.env.REACT_APP_STRAPI_ADMIN_ROLE_ID;

    const areTasksComplete = (taskArr) => {
        return taskArr.every((elm) => elm.completed);
    };
    const [chosen, setChosen] = useState('remaining');
    const handleFilter = (filerVal) => {
        setChosen(filerVal);
    };

    useEffect(() => {
        filterCommentsByStatus();
        // console.log(filteredComments);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chosen, feedback_object]);

    const [filteredComments, setFilteredComment] = useState([]);

    const filterCommentsByStatus = () => {
        // obj to array
        const arr = Object.entries(feedback_object);
        // obj to array with keys
        const objArr = arr.map((elm) => {
            return {
                objId: elm[0],
                data: elm[1]
            };
        });

        const objArrFiltered = objArr.filter((elm) => {
            if (chosen === 'all') {
                return elm.data.data.length > 0;
            }
            if (chosen === 'remaining') {
                return elm.data.data.length > 0 && !areTasksComplete(elm.data.data);
            }
            if (chosen === 'completed') {
                // console.log('we found a complete one', elm);
                return elm.data.data.length > 0 && areTasksComplete(elm.data.data);
            }
        });
        setFilteredComment(objArrFiltered);
    };

    const handleScroll = (elmObjId) => {
        const feedbackElement = document.getElementById(elmObjId);
        if (feedbackElement && feedbackElement.id === elmObjId) {
            feedbackElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }
    };

    return (
        <div className={`relative h-screen-100  ${feedbackStyling.bg[chosen]}`}>
            <div className=' px-4'>
                <div className='pt-4 mb-4 px-4'>Consolidated Feedback</div>
                <div className={`flex items-center justify-center gap-2 mb-4 bg-white py-4 rounded-xl`}>
                    <GenericBtn
                        handleActionBtn={handleFilter}
                        disabled={chosen === 'remaining'}
                        params={['remaining']}
                        bgColor='bg-gray-200 hover:bg-yellow-200'
                        textColor='text-gray-900 hover:text-yellow-900'
                        textSize='text-xs'
                        padding='px-4'
                        className='flex items-center justify-center  h-6 whitespace-nowrap '
                        disabledClass='bg-gray-900 text-white '
                    >
                        Remaining
                    </GenericBtn>
                    <GenericBtn
                        handleActionBtn={handleFilter}
                        disabled={chosen === 'completed'}
                        params={['completed']}
                        bgColor='bg-gray-200 hover:bg-green-200'
                        textColor='text-gray-900 hover:text-green-900'
                        textSize='text-xs'
                        padding='px-4'
                        className='flex items-center justify-center  h-6 whitespace-nowrap '
                        disabledClass='bg-gray-900 text-white '
                    >
                        Completed
                    </GenericBtn>
                    <GenericBtn
                        handleActionBtn={handleFilter}
                        disabled={chosen === 'all'}
                        params={['all']}
                        bgColor='bg-gray-200 hover:bg-gray-300'
                        textColor='text-gray-900'
                        textSize='text-xs'
                        padding='px-4'
                        className='flex items-center justify-center  h-6 whitespace-nowrap '
                        disabledClass='bg-gray-900 text-white '
                    >
                        All Tasks
                    </GenericBtn>
                </div>
            </div>

            <div className='absolute bottom-0 inset-x-0 top-32 overflow-y-scroll px-4'>
                <div className='mb-8'>
                    {filteredComments.map((elm, index) => {
                        return (
                            <div key={index} className={` rounded-xl overflow-hidden mb-4 ${feedbackStyling.cardContainer[chosen]}`}>
                                <FeedbackElement
                                    isAdminStaff={isAdminStaff}
                                    isFeedbackMode={isFeedbackMode}
                                    objId={elm.objId}
                                    feedback_object={feedback_object[elm.objId]}
                                    handleScroll={handleScroll}
                                    chosen={chosen}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,

    globalState: state.globalState,
    auth: state.auth
});

export default connect(mapStateToProps, null)(FeedbackList);
