import { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import translations from 'constants/translations';
import PoliciesModal from 'components/modals/PoliciesModal';

import { experienceDetailsAction } from 'store/actions/experienceDetails/generic.js';
import ConfirmModal from 'components/modals/ConfirmModal';

import ExpSubsection from 'components/blocks/ExpSubsection';
import CollapseButton from 'components/blocks/CollapseButton';

import FeedbackBox from 'components/blocks/FeedbackBox';
import PoliciesList from 'components/blocks/PoliciesList';
import { nanoid } from 'nanoid';

function SectionPolicies({
    experienceDetailsAction,
    globalState: { lang, edit, isFeedbackMode },
    marketingData: {
        policies: { data, objId: rootObjId }
    },
    actionType
}) {
    const [policiesModalStatus, setPoliciesModalStatus] = useState(false);
    const [delConfirmModalState, setDelConfirmModalState] = useState(false);
    const [updateObj, setUpdateObject] = useState({
        create: false,
        update: false,
        index: 0,
        data: {
            type: 'cancellation',
            title: '',
            desc: ''
        }
    });
    const rtl = !!translations[lang].rtl;

    const openModal = () => {
        setUpdateObject({
            ...updateObj,
            create: true,
            data: {
                type: 'cancellation',
                title: '',
                desc: ''
            }
        });
        setPoliciesModalStatus(true);
    };

    const addUpdatePolicy = (dataObj) => {
        const { type, title, desc, objId } = dataObj;
        const { create, index } = updateObj;
        if (create) {
            experienceDetailsAction({
                type: `ADD${actionType}`,
                policyData: {
                    type,
                    title,
                    desc,
                    objId: `policy_${nanoid()}`
                }
            });
        } else {
            experienceDetailsAction({
                type: `UPDATE${actionType}`,
                policyData: {
                    type,
                    title,
                    desc,
                    objId
                },
                index
            });
        }
    };

    const handleDelete = (index) => {
        setUpdateObject({
            ...updateObj,
            index
        });
        setDelConfirmModalState(true);
    };

    const confirmDelete = () => {
        const { index } = updateObj;

        experienceDetailsAction({
            type: `DELETE${actionType}`,
            index
        });
    };

    const handleUpdate = (index) => {
        setUpdateObject({
            ...updateObj,
            index,
            create: false,
            update: true,
            data: data[index]
        });
        setPoliciesModalStatus(true);
    };

    return (
        <>
            <ExpSubsection>
                <div className='marketing-title'>
                    Refund & Cancellation policies
                </div>

                <div className='relative'>
                    <FeedbackBox
                        inset={`-inset-x-8 -bottom-8 -top-12`}
                        dot='top-1/2 -translate-y-1/2 -left-8'
                        posY='bottom-full'
                        root
                        objId={rootObjId.section}
                        label='Policy list'
                        boxHeight='min-h-16 h-full'
                    />
                    {edit && (
                        <div className='flex justify-center w-full pt-8'>
                            <CollapseButton
                                labelHover='Add Policy'
                                handleClick={openModal}
                                icon='ri-play-list-add-line text-2xl -ml-0.5'
                                size='10'
                                textSize='text-xs'
                                sizeHover='w-32'
                                offsetCenter='2'
                                btnColor='bg-green-400 hover:bg-gray-900 text-green-900'
                            />
                        </div>
                    )}

                    {data?.length && data?.length > 0 ? (
                        <div className=''>
                            <PoliciesList
                                handleUpdateAction={handleUpdate}
                                handleDeleteAction={handleDelete}
                                edit={edit}
                                policyData={data}
                                isFeedbackMode={isFeedbackMode}
                            />
                        </div>
                    ) : null}
                </div>
            </ExpSubsection>

            {policiesModalStatus ? (
                <PoliciesModal
                    updateObject={updateObj}
                    handleActionBtn={addUpdatePolicy}
                    setModalIsOpen={setPoliciesModalStatus}
                />
            ) : null}
            {delConfirmModalState && (
                <ConfirmModal
                    setModalIsOpen={setDelConfirmModalState}
                    handleActionBtn={confirmDelete}
                />
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    marketingData: state.experienceDetails.content_marketing,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            experienceDetailsAction
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionPolicies);
