import React, { useState, useEffect } from 'react';
import CarouselCards from 'components/specialty/CarouselCards';
import { getSlidesPerBp } from 'helpers/responsive';
import debounce from 'helpers/debounce';

const SliderList = ({
    children,
    breakPoints,
    boxed,
    classes,
    dataLoading = false
}) => {
    const [slidesPerBp, setSlidesPerBp] = useState(getSlidesPerBp(breakPoints));

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setSlidesPerBp(getSlidesPerBp(breakPoints));
        }, 1000);

        window.addEventListener('resize', debouncedHandleResize);

        return (_) => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, []);

    return (
        <>
            {!dataLoading && (
                <div>
                    <CarouselCards scrollSlides={slidesPerBp}>
                        {children}
                    </CarouselCards>
                </div>
            )}
        </>
    );
};

export default SliderList;

SliderList.defaultProps = {
    breakPoints: {
        default: { width: 320, slides: 2 },
        sm: { width: 640, slides: 2 },
        md: { width: 768, slides: 3 },
        lg: { width: 1024, slides: 4 },
        xl: { width: 1280, slides: 5 }
    }
};
