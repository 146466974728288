// import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CardXlContent } from 'components/blocks/CardXl';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic.js';
// import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
// import { handleRowReverse } from 'helpers/FEutils';

function CurrencyVendors({
    globalState: { lang, edit },
    nohover = null,
    vendors,
    handleEdit
}) {
    const rtl = !!translations[lang].rtl;
    // const handleEdit = () => {

    // }

    return (
        <>
            <CardXlContent
                nohover={nohover}
                rtl={rtl}
                edit={edit}
                handleEdit={handleEdit}
                className='mt-2'
            >
                {vendors.map((vendObj, index) => {
                    const { type, label } = vendObj;
                    const key = `${type}_${index}`;
                    return (
                        <div key={key}>
                            <p>
                                {translations[lang][type]} {label}
                            </p>
                        </div>
                    );
                })}
            </CardXlContent>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            experienceDetailsAction
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyVendors);
