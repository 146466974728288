/* eslint-disable arrow-body-style */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as types from 'store/actions/types';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic';
import React, { useState } from 'react';
import { createWhatYouNeed, deleteWhatYouNeed } from 'store/actions/experienceDetails/checkList.js';
// import WhatYouNeedDescription from 'components/blocks/WhatYouNeedDescription';
import GenericDescription from 'components/blocks/GenericDescription';
import { _CHECKLIST } from 'store/actions/types';
import CardXl from 'components/blocks/CardXl';
import { PillContainer, PillIcon, PillTitle } from 'components/blocks/Pills';
import CheckList from 'components/blocks/CheckList';
import { Section__Title } from 'components/blocks/Sections';
import { ButtonsAddMain } from 'components/blocks/Buttons';
import DescriptionTitleModal from 'components/modals/DescriptionTitleModal';
import ConfirmModal from 'components/modals/ConfirmModal';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import OnboardingSection from 'components/specialty/OnboardingSection';

function SectionWhatYouNeed(props) {
    const {
        dayIndex,
        experienceDetailsAction,
        experienceDetails: { experience },

        globalState: { lang, edit, isPrintMode, isFeedbackMode },
        createWhatYouNeed,
        deleteWhatYouNeed,
        isMarketingMode
    } = props;
    const {
        what_you_need: whatYouNeed,
        what_you_need: { isVisible: isSectionVisible = false, objId }
    } = experience.itinerary.trip_days[dayIndex];
    const hasData = Object.keys(whatYouNeed).length;
    const rtl = !!translations[lang].rtl;
    const [delConfirmModalState, setDelConfirmModalState] = useState(false);
    const [newNeedForDayModal, setNewNeedForDayModal] = useState(false);

    const show = edit ? true : !edit && !hasData ? false : true;

    const [isMinimized, setIsMinimized] = useState(false);

    const handleVisible = () => {
        experienceDetailsAction({
            type: types.UPDATE_SECTION_VISIBILITY,
            val: !isSectionVisible,
            objName: 'what_you_need',
            dayIndex: dayIndex
        });
    };

    // console.log('dayIndex', dayIndex);
    return (
        <>
            {(edit || isSectionVisible) && (
                <div className={`w-full pb-10`}>
                    <div className={edit ? 'mt-24' : 'mt-16'}>
                        <Section__Title
                            title={translations[lang][uiStruct.itinerary.what_you_need.uiPreview.headTitle]}
                            edit={edit}
                            hasData={hasData}
                            hintObj={uiStruct.itinerary.what_you_need.uiPreview.hint}
                            handleVisible={handleVisible}
                            isVisible={isSectionVisible}
                        />
                        <OnboardingSection visible={hasData} data={uiStruct.itinerary.what_you_need.uiPreview.hintEmpty} />
                    </div>

                    {isSectionVisible && (
                        <div className={`${isMinimized ? ' -mt-41 xl:-mt-81 mb-16' : ''}`}>
                            <CardXl
                                isFeedbackMode={isFeedbackMode}
                                objId={objId?.checklist}
                                edit={edit}
                                isPrintMode={isPrintMode}
                                isMarketingMode={isMarketingMode}
                                rtl={rtl}
                                hidden={isMinimized}
                                isVisible={isSectionVisible}
                                handleVisible={handleVisible}
                                handleMinimize={() => setIsMinimized(!isMinimized)}
                                hideEye
                            >
                                <div className={`z-200 ${isMinimized ? 'mt-2 xl:mt-8' : ''}`}>
                                    <PillContainer rtl={rtl}>
                                        <PillIcon icon={uiStruct.itinerary.what_you_need.uiPreview.icon.name} />
                                        <PillTitle title={translations[lang][uiStruct.itinerary.what_you_need.uiPreview.subTitle]} />
                                    </PillContainer>
                                </div>
                                <div className={`${isMinimized ? 'hidden' : ''}`}>
                                    <GenericDescription
                                        // canBeEmpty
                                        actionType={_CHECKLIST}
                                        dayIndex={dayIndex}
                                        descriptionHtml={whatYouNeed.description_html}
                                        defaultHtml={translations[lang][uiStruct.itinerary.what_you_need.uiPreview.descriptionEmpty]}
                                        hintObj={uiStruct.itinerary.what_you_need.uiEdit.modals.description.hint}
                                        icon={uiStruct.itinerary.what_you_need.uiEdit.modals.description.icon}
                                        objId={objId?.description}
                                        title='Checklist'
                                        subtitle='Description'
                                    />

                                    {/* <WhatYouNeedDescription
                                        rtl={rtl}
                                        edit={edit}
                                        dayIndex={dayIndex}
                                        descriptionHtml={
                                            whatYouNeed.description_html
                                        }
                                    /> */}
                                    <CheckList
                                        actionType={'_MOVING_AROUND'}
                                        dontForgetList={whatYouNeed.checklist}
                                        edit={edit}
                                        dayIndex={dayIndex}
                                        index={0}
                                        objId={objId?.checklist}
                                    />
                                </div>
                            </CardXl>
                        </div>
                    )}

                    {/* {newNeedForDayModal && (
                        <DescriptionTitleModal
                            setModalIsOpen={setNewNeedForDayModal}
                            handleActionBtn={updateCreate}
                            update={false}
                            updateObj=''
                            icon={
                                uiStruct.itinerary.what_you_need.uiEdit.modals
                                    .add.icon
                            }
                            hintObj={
                                uiStruct.itinerary.what_you_need.uiEdit.modals
                                    .add.hint
                            }
                        />
                    )} */}
                </div>
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createWhatYouNeed,
            deleteWhatYouNeed,
            experienceDetailsAction
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionWhatYouNeed);
