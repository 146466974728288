/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import 'react-quill/dist/quill.snow.css';
import { checkMultiLine } from 'helpers/FEutils';
import classNames from 'classnames';
import PillType from './PillType';

const NoteStatic = (props) => {
    const {
        selected,
        handleClick,
        type,
        rows,
        text,
        rtl,
        label,
        children,
        pillColorBg = 'bg-kn-yellow',
        pillColorText = '',
        containerColorBg = type ? 'bg-gray-100' : 'bg-yellow-50',
        containerColorHover = handleClick ? 'hover:bg-green-100' : '',

        containerColorText
    } = props;

    return (
        <div
            onClick={handleClick}
            className={classNames(
                handleClick && 'cursor-pointer',
                rows === true && 'flex-col',
                rows === false && 'flex-row',
                rows == null && 'flex-col sm:flex-row',
                'gap-4 relative flex group  py-5 px-4 w-full transition-colors ease-in-out duration-300 rounded-3xl',
                (text && checkMultiLine(text, 70)) || children ? 'sm:items-start' : 'sm:items-center',
                containerColorBg,
                containerColorHover,
                rtl && 'kn-rtl sm:kn-ltr flex-row-reverse',
                {
                    'ring-2 ring-green-400': selected?.toLowerCase() === type && type != null
                }
            )}
        >
            {type ? (
                <PillType type={type} label={label ?? type} />
            ) : (
                <div
                    className={classNames(
                        'w-min italic whitespace-nowrap mb-3 sm:mb-0 font-bold tracking-tight flex flex-none justify-center items-center rounded-full px-4 h-8',
                        pillColorBg,
                        pillColorText
                    )}
                >
                    {label}
                </div>
            )}

            {children}
            {!children && (
                <div
                    className={classNames('w-full block-html text-sm px-2', rtl ? 'kn-rtl' : '', containerColorText)}
                    dangerouslySetInnerHTML={{
                        __html: text
                    }}
                />
            )}
        </div>
    );
};

export default NoteStatic;
