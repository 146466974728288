import { captureEmail } from 'store/actions/dataCapture';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ButtonLoad from 'components/blocks/ButtonLoad';

// import FormIkInput from '@/components/forms/FormIkInput';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormIkInput from 'components/forms/FormIkInput';
import GradientTitle from 'components/blocks/Title/GradientTitle';

const LandingCapture = ({ captureEmail, auth, ...props }) => {
    const handleSubmit = (values, actions) => {
        captureEmail({ ...values });
        actions.setSubmitting(false);
        actions.resetForm();
    };

    return (
        <div className='w-full h-full '>
            <div className='flex flex-col md:flex-row md:items-center md:justify-center md:gap-0 mx-auto lg:max-w-7xl w-full'>
                <div className='md:w-2/5 lg:w-auto '>
                    <img className='lg:h-96' src='/assets/media/landing/joinus@2x.png' />
                </div>
                <div className='md:w-3/5 xl:w-1/2'>
                    <div className=''>
                        <div className={`flex flex-col  lg:px-8  pt-4 md:pt-0`}>
                            <GradientTitle label='Join us' textSize='text-4xl' containerClass='' />

                            <div className='flex text-gray-600 mt-4 mb-4 leading-7'>
                                If you want to know more about the development and launch, subscribe to our list, and join thousands of people who - like you -
                                are excited about new innovations in experiencing travel.
                            </div>

                            <div>
                                <Formik
                                    initialValues={{
                                        email: ''
                                    }}
                                    validationSchema={Yup.object({
                                        email: Yup.string().email('Invalid Email Syntax').required('Cannot be empty')
                                    })}
                                    onSubmit={handleSubmit}
                                >
                                    {(props) => (
                                        <Form id='joinForm'>
                                            <div className='flex flex-col md:flex-row lg:flex-col mt-6 gap-12 lg:gap-0'>
                                                <div className='md:w-1/2 lg:w-full flex-1 flex flex-col'>
                                                    <FormIkInput name='email' type='text' placeholder={'E-mail address'} autoComplete='off' className='mb-4' />

                                                    <ButtonLoad
                                                        // handleClick={handleClick}
                                                        isLoading={auth.loading}
                                                        label='Join the list'
                                                        width='w-full'
                                                        // handleClick={handleSubmit}
                                                        form='joinForm'
                                                        type='submit'
                                                        // disabled={!termsChecked}
                                                        margins='mt-2'
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            captureEmail
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingCapture);
