import React, { useState, useRef, useEffect, useCallback } from 'react';
import KnIcons from 'constants/KnIcons';
import {
    ButtonsGroupNextPrev,
    ButtonsNextPrev
} from 'components/blocks/Buttons';
import Icons from 'components/blocks/Icons';
import ImageModal from 'components/modals/ImageModal';
import { connect } from 'react-redux';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { handleRowReverse } from 'helpers/FEutils';
import { PillLogo } from 'components/blocks/Pills';
import { youtubeCode, galleryVidtType } from 'constants/experienceDetailConsts';
import FeedbackBox from 'components/blocks/FeedbackBox';

// const browserSize = window.innerWidth;

function debounce(fn, ms) {
    let timer;
    return (_) => {
        clearTimeout(timer);
        timer = setTimeout((_) => {
            timer = null;
            fn.apply(this, arguments);
        }, ms);
    };
}

const slideDist = {
    xl: {
        size: 640,
        gap: 16,
        container: 896,
        slideX: 640 * 2 + 16 * 2,
        offsetStart: (window.innerWidth - 1250) / 2
    },
    lg: {
        size: 512,
        gap: 12,
        container: 668,
        slideX: 512 * 3 + 12 * 3,
        offsetStart: (window.innerWidth - 668) / 2
    },
    md: {
        size: 320,
        gap: 16,
        container: 540,
        slideX: 320 * 3 + 16 * 3,
        offsetStart: (window.innerWidth - 540) / 2
    },
    sm: {
        size: 300,
        gap: 16,
        container: 540,
        slideX: 300 * 3 + 16 * 3,
        offsetStart: (window.innerWidth - 540) / 2
    },

    default: {
        size: 300,
        gap: 8,
        container: window.innerWidth - 24,
        slideX: window.innerWidth,
        offsetStart: 32
    }
};

const Slider = ({
    objId,
    slides,
    globalState: { lang, isPrintMode, isFeedbackMode },
    edit,
    handleUpdateCaption,
    fromColor = 'from-gray-100'
}) => {
    const rtl = !!translations[lang].rtl;
    const [currentSlide, setCurrentSlide] = useState(0);
    const [translateSlide, setTranslateSlide] = useState(0);
    const [nextBtnState, setNextBtnState] = useState(true);
    const [prevBtnState, setPrevBtnState] = useState(false);
    const [sliderProps, setSliderProps] = useState({
        type: slideDist.xl,
        offset: slideDist.xl.offsetStart
    });
    const [sliderWidth, setSliderWidth] = useState(1024);
    const [selectedSlide, setSelectedSlide] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    // order of things
    // on load, get screen dimensions and save them
    // on screen dimensions changed (useEffect) set new sliderWidth and and new sliderProps
    // Do calculation when dimensions or sliderwidth or currentslide or rtl or the sliderprops(offset) change
    // Check btn status (next prev) on load and when dimensions, rtl, slider props or the translation amount changes
    // on screen resize, save screen dimensions

    useEffect(() => {
        saveDimensions();
        checkBtnStatus();
    }, []);

    useEffect(() => {
        const sliderObj = getSliderProps();
        setSliderProps(sliderObj);
        setSliderWidth(getSliderWidth(sliderObj.type));
    }, [dimensions, slides]);

    useEffect(() => {
        checkBtnStatus();
    }, [sliderProps, sliderWidth, translateSlide, rtl, dimensions, slides]);

    useEffect(() => {
        calculateTranslation();
    }, [sliderProps, sliderWidth, currentSlide, rtl, dimensions, slides]);

    const saveDimensions = () => {
        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    const getTheSlideTranslation = () => {
        if (slides.length === 1)
            return `translate(calc(50vw - ${sliderProps.type.size / 2}px))`;
        else
            return `translate(${
                rtl
                    ? -sliderProps.offset - translateSlide
                    : translateSlide + sliderProps.offset
            }px)`;
    };

    const getSliderProps = () => {
        let cWidth = window.innerWidth;
        if (cWidth >= 1280)
            return {
                type: slideDist.xl,
                offset: (cWidth - slideDist.xl.container) / 2
            };
        else if (cWidth >= 1024)
            return {
                type: slideDist.lg,
                offset: (cWidth - slideDist.lg.container) / 2
            };
        else if (cWidth >= 768)
            return {
                type: slideDist.md,
                offset: (cWidth - slideDist.md.container) / 2
            };
        else if (cWidth >= 640)
            return {
                type: slideDist.sm,
                offset: (cWidth - slideDist.sm.container) / 2
            };
        else
            return {
                type: slideDist.default,
                offset: slideDist.default.offsetStart
            };
    };

    const getSliderWidth = (sliderObj, lastGap = false) => {
        const totalWidth =
            slides.length * (sliderObj.size + sliderObj.gap) -
            lastGap * sliderObj.gap;
        return totalWidth;
    };

    const calculateTranslation = () => {
        let rtlDir = rtl ? 1 : 1;
        let windowDir = rtl ? window.innerWidth : 0;
        let totalSlid = (currentSlide - 1) * sliderProps.type.slideX;
        let totalWidth = sliderWidth;
        let totalRemaining = totalWidth - totalSlid;
        let totalRaminingOutFrame = totalRemaining - sliderProps.type.container;

        if (totalRaminingOutFrame > sliderProps.type.slideX) {
            setTranslateSlide(currentSlide * -sliderProps.type.slideX * rtlDir);
        } else {
            setTranslateSlide(
                ((currentSlide - 1) * -sliderProps.type.slideX -
                    totalRaminingOutFrame) *
                    rtlDir
            );
        }
    };

    // Listen for window resize with debounce of 1000ms
    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            saveDimensions();
        }, 1000);

        window.addEventListener('resize', debouncedHandleResize);

        return (_) => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, []);

    // for next prev buttons
    const checkBtnStatus = () => {
        setPrevBtnState(translateSlide * (rtl ? 1 : 1) < 0);
        setNextBtnState(
            translateSlide * (rtl ? 1 : 1) >
                sliderProps.type.container - sliderWidth
        );
    };

    const handleNextSlide = () => {
        setCurrentSlide(currentSlide + 1);
    };

    const handlePrevSlide = () => {
        setCurrentSlide(currentSlide - 1);
    };

    // Modal for slide
    const openSlide = (nb) => {
        setSelectedSlide(nb);
        setIsOpen(true);
    };
    // Build slides JSX
    const buildSlides = () => {
        return slides.map((slide, i) => {
            const { url, caption, type, vidCode, objId } = slide;

            return (
                <div
                    key={`${url}_${i}`}
                    className={`relative ${
                        isPrintMode ? '' : 'inline-block px-1 md:px-1.5 lg:px-2'
                    } `}
                >
                    {!isFeedbackMode && (
                        <FeedbackBox
                            inset={`${
                                isPrintMode
                                    ? '-inset-2'
                                    : 'inset-x-0 -inset-y-2'
                            }`}
                            dot='top-0 right-0'
                            objId={objId}
                            label='Image'
                            zLevel='z-0'
                            tmp='ring-8'
                            // type='image'
                        />
                    )}
                    <div
                        className={`group relative ${
                            isPrintMode
                                ? ''
                                : 'shadow-images w-screen-4/5 md:w-80 lg:w-128 xl:w-160'
                        } overflow-hidden rounded-3xl  ring-white ring-4 ring-opacity-0  hover:ring-opacity-100 transition-all duration-300 `}
                        onClick={() => {
                            openSlide(i);
                        }}
                    >
                        {type === 'youtube' && galleryVidtType === 'popup' && (
                            <img
                                alt=''
                                src={`https://img.youtube.com/vi/${vidCode}/0.jpg`}
                                className='object-cover w-full h-48 md:h-56 lg:h-80 xl:h-96 '
                            />
                        )}
                        {type === 'youtube' && galleryVidtType !== 'popup' && (
                            <div className='object-cover w-full h-48 md:h-56 lg:h-80 xl:h-96 '>
                                {youtubeCode(vidCode)}
                            </div>
                        )}
                        {type !== 'youtube' && (
                            <img
                                alt=''
                                src={`${url}-/format/webp/-/quality/lighter/-/progressive/yes/-/max_icc_size/10/-/scale_crop/640x480/smart_faces_objects_points/`}
                                className='object-cover w-full h-48 md:h-56 lg:h-80 xl:h-96 '
                            />
                        )}

                        {caption && (
                            <div
                                className={`${
                                    isPrintMode
                                        ? 'opacity-100 translate-y-0'
                                        : 'opacity-0 translate-y-4'
                                } group-hover:opacity-100  transition-all transform group-hover:translate-y-0 duration-500 z-50 absolute bottom-4 left-4 right-4`}
                            >
                                <div
                                    className={`relative bg-kn-yellow ${
                                        isPrintMode
                                            ? 'text-xxs rounded-lg px-2 py-2 inline-block'
                                            : 'text-sm md:max-w-xs w-max xl:max-w-xs p-4 rounded-2xl'
                                    } text-gray-900 whitespace-pre-wrap `}
                                >
                                    {caption}
                                </div>
                            </div>
                        )}
                    </div>
                    {isFeedbackMode && (
                        <FeedbackBox
                            inset={`${
                                isPrintMode
                                    ? '-inset-2'
                                    : 'inset-x-0 -inset-y-2'
                            }`}
                            dot='top-0 right-0'
                            objId={objId}
                            label='Image'
                            // zLevel=''
                        />
                    )}
                </div>
            );
        });
    };

    const btnSide = handleRowReverse(rtl).right;

    const handleActionBtn = (caption) => {
        handleUpdateCaption(selectedSlide, caption);
    };
    return (
        <>
            {isOpen && (
                <ImageModal
                    edit={edit}
                    setModalIsOpen={setIsOpen}
                    handleActionBtn={handleActionBtn}
                    slide={slides[selectedSlide]}
                    galleryVidtType={galleryVidtType}
                    hintObj={''}
                />
            )}
            {!isPrintMode ? (
                <div className={`z-50 pb-12 `}>
                    {/* Panel with drop shadow pointing up and define main size of container */}
                    <div className='full-width '>
                        {/* Panel Inside offset/ empty  shadow-cards xl:rounded-3xl border-t-2 border-b-2 xl:border-2 border-glass-25*/}
                        <div className='relative '>
                            {/* Pill shape Bottom */}

                            <div className='z-20 absolute bottom-12  left-1/2 transform -translate-x-1/2'>
                                <PillLogo />
                            </div>
                            {/* Left right arrows and faded panels */}

                            <div className='z-10 absolute inset-0 flex flex-nowrap pointer-events-none'>
                                <div
                                    className={`flex-shrink-0 md:w-24 xl:w-64 bg-gradient-to-r ${fromColor} to-transparent`}
                                ></div>
                                <div className='flex-grow'></div>
                                <div
                                    className={`flex-shrink-0 md:w-24 xl:w-64 bg-gradient-to-l ${fromColor} to-transparent`}
                                ></div>
                            </div>
                            {/* Glass Container */}
                            <div className='relative'>
                                {/* overscroll container */}
                                <div
                                    className={`touch:overflow-x-scroll  no-scrollbar touch:overflow-y-visible -mb-8 touch:-mb-32 -mt-10 ${
                                        rtl ? handleRowReverse(rtl).rtl : ''
                                    }`}
                                >
                                    {/* Images container */}
                                    <div
                                        className={`py-16 bg-white2 shadow-glass-images3 transform duration-500 ease-in-out transition-transform whitespace-nowrap rounded-3xl inline-block`}
                                        // style={{
                                        //     transform: `translate(${
                                        //         !rtl
                                        //             ? translateSlide +
                                        //               sliderProps.offset
                                        //             : window.innerWidth -
                                        //               sliderProps.offset +
                                        //               translateSlide
                                        //     }px)`,
                                        // }}

                                        style={{
                                            transform: getTheSlideTranslation()
                                        }}
                                    >
                                        {buildSlides()}
                                        {slides.length > 1 && (
                                            <div className='px-1 md:px-1.5 lg:px-2 inline-block '>
                                                <div className='w-6 md:w-24 lg:w-36 xl:w-16 h-48 md:h-56 lg:h-80 xl:h-96 '></div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <ButtonsGroupNextPrev
                            nextAction={handleNextSlide}
                            isNext={!nextBtnState}
                            prevAction={handlePrevSlide}
                            isPrev={!prevBtnState}
                            className={`z-50 touch:hidden`}
                            rtl={rtl}
                        />
                    </div>
                </div>
            ) : (
                <div className='grid grid-cols-2 gap-4 mb-16 relative'>
                    {/* <FeedbackBox
                        inset='-inset-8'
                        dot='top-1/2 -translate-y-1/2 -right-20'
                        root
                        objId={objId}
                        label='Gallery'
                        zLevel=''
                    /> */}
                    {buildSlides()}
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps)(Slider);

{
    /* <div className='z-50'>
            <div className='full-width overflow-x-scroll pb-16'>
                <div className='relative rounded-4xl bg-glass-50 shadow-cards p-4 border-2 border-glass-75 xl:full-width-left-xl lg:full-width-left-lg md:full-width-left-md inline-block'>
                    <div className='relative rounded-3xl p-4 pb-12 md:px-12  shadow-2xl-green-400 whitespace-nowrap space-x-8 px-12 py-12 inline-block'>
                        {buildSlides()}
                    </div>
                </div>
            </div>
        </div> */
}

{
    /* <div
                        className='bg-cover bg-center h-56 w-96'
                        style={{ backgroundImage: `url( ${url})` }}
                    ></div> */
}

//     <div
//     className='inline-block w-screen-100 md:w-screen-1/2 lg:w-screen-1/3 xl:w-screen-1/5 rounded-lg overflow-hidden shadow-images'
//     key={`${url}_${i}`}
// >

//     <img
//         src={url}
//         className='object-cover h-screen-3/5 md:h-screen-2/5 lg:h-screen-2/5 xl:h-screen-2/5 w-full'
//     />
// </div>
