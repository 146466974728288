import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import EntryModal from 'components/modals/overview/EntryModal';

import { experienceDetailsAction } from 'store/actions/experienceDetails/generic.js';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { handleRowReverse } from 'helpers/FEutils';
import Icons from 'components/blocks/Icons';
import CardXlEditDelete from 'components/blocks/CardXlEditDelete';
import FeedbackBox from './FeedbackBox';

function GettingThereEntry({
    // index,
    objId,
    globalState: { lang, edit, isFeedbackMode },
    entryData,
    actionType,
    // dayIndex,
    hintObj = uiStruct.itinerary.activities.uiEdit.modals.description.hint,
    icon = uiStruct.itinerary.activities.uiEdit.modals.description.icon,
    experienceDetailsAction
    // nohover = null
}) {
    const rtl = !!translations[lang].rtl;

    const [entryModState, setEntryModState] = useState(false);
    const handleEdit = () => {
        setEntryModState(true);
    };

    const updateEntry = (dataObj) => {
        experienceDetailsAction({
            type: `UPDATE_ENTRY${actionType}`,
            dataObj
        });
    };
    // entry: {
    //     label: '',
    //     url: '',
    // }
    const makeEntryLink = () => {
        const { label, url } = entryData;
        if (!label && !url) {
            return (
                <p>
                    {
                        translations[lang][
                            uiStruct.overview.getting_there.uiPreview.entryEmpty
                        ]
                    }
                </p>
            );
        }
        if (label) {
            return (
                <a href={url} target='_blank' rel='noreferrer'>
                    {label}
                </a>
            );
        } else {
            return (
                <a href={url} target='_blank' rel='noreferrer'>
                    {url}
                </a>
            );
        }
    };

    return (
        <>
            <div
                className={`relative w-full justify-between bg-gray-200 hover:bg-gray-300 transition-colors ease-in-out duration-300 rounded-3xl px-4 md:px-8 py-4 flex flex-col md:flex-row md:${
                    handleRowReverse(rtl).flex
                } flex-wrap md:flex-nowrap  mb-4`}
            >
                {edit && !isFeedbackMode && (
                    <>
                        <CardXlEditDelete
                            // handleDelete={handleDelete}
                            handleEdit={handleEdit}
                            colors={uiStruct.ui.tip_list.gray}
                            rtl={rtl}
                        />
                    </>
                )}

                <div
                    className={`flex flex-col md:flex-row md:${
                        handleRowReverse(rtl).flex
                    } flex-wrap lg:flex-nowrap md:items-center`}
                >
                    <div
                        className={`flex items-center flex-shrink-0 ${
                            handleRowReverse(rtl).flex
                        }`}
                    >
                        <div className=''>
                            <Icons
                                iName='MAP_MARKERALT'
                                size='4xl'
                                iClasses='bg-clip-text text-transparent bg-gradient-to-tr from-gray-600 to-gray-800'
                            />
                        </div>
                        <div className='px-6 text-xl font-bold bg-clip-text text-transparent bg-gradient-to-tr from-gray-600 to-gray-800 '>
                            {
                                translations[lang][
                                    uiStruct.overview.getting_there.uiPreview
                                        .entry
                                ]
                            }
                        </div>
                    </div>
                    <div
                        className={`flex flex-col text-sm ${
                            handleRowReverse(rtl).flex
                        }`}
                    >
                        {/* {destObj.places.length > 0 &&
                            destObj.places.map((place, index) => {
                                return ( */}
                        <div
                            // key={index}
                            className={`flex flex-nowrap items-center ${
                                handleRowReverse(rtl).flex
                            }`}
                        >
                            <div className='flex-shrink-0 mx-1.5 text-gray-800 text-lg'>
                                <Icons
                                    size='xl'
                                    iName={`${handleRowReverse(rtl).arrowLong}`}
                                />
                            </div>

                            <span
                                className={`break-all hover:text-white hover:bg-kn-primary rounded-full px-4 leading-tight py-2 ${
                                    handleRowReverse(rtl).text
                                }`}
                            >
                                {makeEntryLink()}
                            </span>
                        </div>
                        {/* );
                            })} */}
                    </div>
                </div>
                <FeedbackBox objId={objId} label='Entry requirements' />
            </div>

            {entryModState && (
                <EntryModal
                    setModalIsOpen={setEntryModState}
                    updateObj={entryData}
                    update={true}
                    handleActionBtn={updateEntry}
                    icon={icon}
                    hintObj={hintObj}
                />
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            experienceDetailsAction
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(GettingThereEntry);
