/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { produce } from 'immer';

import * as types from 'store/actions/types';

import {
    setExperiencesAll,
    creatExperiencePending,
    creatExperienceFufilled,
    clearAllExperiences,
    updateTags,
    updateCats,
    updateGuideIncludes,
    setPurchasedAll
} from './experienceDetails/master/master';

const initialState = {
    loading: true,
    totalExperiences: 0,
    experiences: [],
    purchased: []
};

function createReducer(initialState, handlers) {
    return produce((draft = initialState, action) => {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](draft, action);
        } else {
            return draft;
        }
    });
}
export default createReducer(initialState, {
    [`${types.GET_EXPERIENCES}_PENDING`]: () => {
        // console.log('_PENDING');
    },
    [`${types.GET_EXPERIENCES}_FULFILLED`]: setExperiencesAll,
    [`${types.GET_EXPERIENCES_ISADMIN}_FULFILLED`]: setExperiencesAll,
    [`${types.GET_EXPERIENCES}_REJECTED`]: () => {
        // console.log('_REJECTED');
    },
    [`${types.GET_PUBLISHED_EXPERIENCES}_PENDING`]: () => {
        // console.log('_PENDING');
    },
    [`${types.GET_PUBLISHED_EXPERIENCES}_FULFILLED`]: setExperiencesAll,
    [`${types.GET_PUBLISHED_EXPERIENCES}_REJECTED`]: () => {
        // console.log('_REJECTED');
    },
    [`${types.CREATE_EXPERIENCE}_PENDING`]: creatExperiencePending,
    [`${types.CREATE_EXPERIENCE}_FULFILLED`]: creatExperienceFufilled,
    [`${types.CREATE_EXPERIENCE}_REJECTED`]: () => {
        // console.log('_REJECTED');
    },
    [types.CLEAR_ALL_EXPERIENCES]: clearAllExperiences,
    [types.UPDATE_TAGS]: updateTags,
    [types.UPDATE_CATS]: updateCats,
    [types.UPDATE_GUIDE_INCLUDES]: updateGuideIncludes,

    [`${types.GET_PURCHASED_EXPERIENCES}_FULFILLED`]: setPurchasedAll,
    [`${types.GET_PURCHASED_EXPERIENCES}_REJECTED`]: () => {
        // console.log('_REJECTED');
    }
});
