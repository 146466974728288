import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import ModalContainer, {
    ModalBody,
    ModalFooter,
    ModalCTA,
    ModalButton,
    ModalHelp
} from 'components/blocks/ModalTre';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { testValidUrl } from 'helpers/FEutils';
import { BlockInputSingle } from 'components/blocks/Blocks';
import useDidMountEffect from 'helpers/useDidMountEffect';
import ModalTopBlock from 'components/blocks/ModalTopBlock';

// should be in all modals
const targetElement = document.querySelector('#root');

function EntryModal({
    close,
    setModalIsOpen,
    handleActionBtn,
    updateObj = null,
    update = false,
    globalState: { lang },
    icon = uiStruct.ui.modals.editor.icon,
    hintObj = null,
    actionBtnObj = uiStruct.ui.modals.editor.buttons.action,
    cancelBtnObj = uiStruct.ui.modals.editor.buttons.cancel
}) {
    const rtl = !!translations[lang].rtl;

    const defaultDataObj = {
        label: '',
        url: ''
    };

    const [editorValidationError, setEditorValidationError] = useState(false);

    const [dataObj, updateDataObj] = useState(() =>
        update ? updateObj : defaultDataObj
    ); // set to a default or editing

    // Copy Below to all modals
    //////////////////////////////////////////////////////////////////////

    // State for triggering animation of modal in and out

    const [transitionState, setTransitionState] = useState('off');
    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    ///////////////////////////////////////////////////////////////////

    // Paul stuff
    const updateLabel = (event) => {
        updateDataObj({
            ...dataObj,
            label: event.target.value
        });
    };

    const updateLink = (event) => {
        updateDataObj({
            ...dataObj,
            url: event.target.value
        });
        if (
            dataObj.url.replace(/(<([^>]+)>)/gi, '').length &&
            testValidUrl(dataObj.url)
        ) {
            setEditorValidationError(false);
        }
    };

    const handleActionClick = () => {
        // check
        // if both empty proceed
        // if text empty only, check url
        // if url empty only error

        if (!dataObj.label && !dataObj.url) {
            handleActionBtn(dataObj);
            handleTransitionStateOff(); // important... don't forget to add it in all modals
        } else if (
            dataObj.url.replace(/(<([^>]+)>)/gi, '').length === 0 ||
            !testValidUrl(dataObj.url)
        ) {
            setEditorValidationError(true);
        } else {
            handleActionBtn(dataObj);
            handleTransitionStateOff(); // important... don't forget to add it in all modals
        }
    };

    useDidMountEffect(() => {
        // setEditorValidationError(() => !dataObj.length);
    }, [dataObj]);

    ////////////////////////////////

    return (
        <>
            <ModalContainer
                width='3xl'
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={icon}
                close={close}
                rtl={rtl}
            >
                <ModalBody rtlOff>
                    <div className='w-full px-2 mt-4'>
                        <ModalTopBlock
                            title='Aiports & Visas'
                            subtitle='Visa Requirements'
                            text='Enter the name of an entity that provides relevant info on visa requirements to your selected destinations. Then enter its web address in the second box.'
                            rtl={rtl}
                            lang={lang}
                        />
                    </div>

                    <div className='px-4'>
                        <BlockInputSingle
                            normal
                            error={false}
                            handleChange={updateLabel}
                            id='name'
                            margins='mb-4'
                            value={dataObj.label}
                            placeholder={
                                translations[lang][
                                    uiStruct.overview.getting_there.uiEdit
                                        .modals.entry.placeholders.name
                                ]
                            }
                            rtl={rtl}
                        />
                        <BlockInputSingle
                            normal
                            error={editorValidationError}
                            handleChange={updateLink}
                            id='name'
                            margins='mb-4'
                            value={dataObj.url}
                            placeholder={
                                translations[lang][
                                    uiStruct.overview.getting_there.uiEdit
                                        .modals.entry.placeholders.url
                                ]
                            }
                            rtl={rtl}
                        />
                    </div>
                </ModalBody>

                <ModalFooter>
                    {hintObj && (
                        <ModalHelp
                            helpText={translations[lang][hintObj]}
                            isHtml
                        />
                    )}
                    <ModalCTA>
                        <ModalButton
                            handleClick={handleTransitionStateOff}
                            label={translations[lang][cancelBtnObj.label]}
                            color={cancelBtnObj.color}
                            icon={cancelBtnObj.icon}
                        />
                        <ModalButton
                            handleClick={handleActionClick}
                            label={translations[lang][actionBtnObj.label]}
                            color={actionBtnObj.color}
                            icon={actionBtnObj.icon}
                        />
                    </ModalCTA>
                </ModalFooter>
            </ModalContainer>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(EntryModal);
