/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { BlockInputSingle } from 'components/blocks/Blocks';
import ModalContainer, {
    ModalFooter,
    ModalCTA,
    ModalButton,
    ModalHelp
} from 'components/blocks/ModalTre';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { BlockHTML } from 'components/blocks/Blocks';
import { PopoverFooterDefault } from 'components/blocks/Popover';
import { handleRowReverse } from 'helpers/FEutils';
import { youtubeCode } from 'constants/experienceDetailConsts';
import ModalFullscreen, { ModalBody } from 'components/blocks/ModalFullscreen';

const targetElement = document.querySelector('#root');

function ImageModal({
    hidecaption = false,
    close,
    setModalIsOpen,
    handleActionBtn,
    globalState: { lang, edit },
    icon = uiStruct.ui.modals.lightbox.icon,
    slide: { url: imgUrl, caption, type, vidCode },
    galleryVidtType,
    hintObj = translations[lang][
        uiStruct.itinerary.moving_around.uiEdit.modals.vendor.hint
    ],
    actionBtnObj = uiStruct.itinerary.moving_around.uiEdit.modals.add.buttons
        .action,
    cancelBtnObj = uiStruct.itinerary.moving_around.uiEdit.modals.add.buttons
        .cancel
}) {
    const rtl = !!translations[lang].rtl;
    const rtlClass = handleRowReverse(rtl);

    // Copy Below to all modals
    //////////////////////////////////////////////////////////////////////

    // State for triggering animation of modal in and out
    const [captionText, setCaptionText] = useState(caption);
    const [transitionState, setTransitionState] = useState('off');
    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    ///////////////////////////////////////////////////////////////////

    const handleActionClick = () => {
        handleActionBtn(captionText);
        handleTransitionStateOff(); // important... don't forget to add it in all modals
    };

    const onCaptionChange = (event) => {
        setCaptionText(event.target.value);
    };

    return (
        <>
            <ModalFullscreen
                width='4xl'
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={icon}
                close={close}
                closeButton={true}
                rtl={rtl}
                fullscreen={true}
            >
                <ModalBody rtlOff>
                    <div className=''>
                        <div className={`${handleRowReverse(rtl).rtl}`}>
                            <div
                                className='group relative flex flex-col md:h-screen-4/5'
                                style={
                                    rtl
                                        ? {
                                              direction: 'rtl',
                                              textAlign: 'right'
                                          }
                                        : {}
                                }
                            >
                                {type === 'youtube' &&
                                    galleryVidtType === 'popup' && (
                                        <div className='object-contain   h-full w-full '>
                                            {youtubeCode(vidCode)}
                                        </div>
                                    )}
                                {type !== 'youtube' && (
                                    <img
                                        alt=''
                                        data-blink-src={imgUrl}
                                        className='object-contain h-full w-full'
                                    />
                                )}
                                {edit && !hidecaption ? (
                                    <div className='z-50 md:absolute mt-4 transform md:left-1/2 md:-translate-x-1/2 md:bottom-4 px-4 max-w-full w-128'>
                                        <BlockInputSingle
                                            normal
                                            error={false}
                                            handleChange={onCaptionChange}
                                            id='name'
                                            margins='mb-4'
                                            value={captionText}
                                            placeholder={
                                                translations[lang][
                                                    uiStruct.itinerary
                                                        .activities.uiEdit
                                                        .modals.type
                                                        .placeholders
                                                ]
                                            }
                                        />
                                    </div>
                                ) : (
                                    <>
                                        {!hidecaption && captionText ? (
                                            <div className='group-hover:opacity-100 opacity-0 transition-all transform group-hover:translate-y-0 translate-y-4 duration-500 z-50 absolute bottom-4 left-1/2 -translate-x-1/2'>
                                                <div className='md:max-w-xs w-max xl:max-w-xs p-4 rounded-2xl bg-kn-yellow text-sm text-gray-900 whitespace-pre-wrap '>
                                                    {captionText}
                                                </div>
                                            </div>
                                        ) : null}
                                    </>
                                )}

                                {/* <BlockHTML
                                    className='break-words md:break-normal px-0 sm:px-8 pt-8 text-xs sm:text-base'
                                    html={translations[lang][hintObj]}
                                /> */}
                            </div>
                        </div>
                        <div className='md:mt-8 mb-8 px-4 md:px-10'>
                            <ModalFooter>
                                {edit && (
                                    <ModalCTA>
                                        <ModalButton
                                            handleClick={
                                                handleTransitionStateOff
                                            }
                                            label={
                                                translations[lang][
                                                    cancelBtnObj.label
                                                ]
                                            }
                                            color={cancelBtnObj.color}
                                            icon={cancelBtnObj.icon}
                                        />
                                        <ModalButton
                                            handleClick={handleActionClick}
                                            label={
                                                translations[lang][
                                                    actionBtnObj.label
                                                ]
                                            }
                                            color={actionBtnObj.color}
                                            icon={actionBtnObj.icon}
                                        />
                                    </ModalCTA>
                                )}
                            </ModalFooter>
                        </div>
                    </div>
                </ModalBody>
            </ModalFullscreen>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(ImageModal);
