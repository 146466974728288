/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import ModalContainer, {
    ModalBody,
    ModalFooter,
    ModalCTA,
    ModalButton,
    ModalHelp
} from 'components/blocks/ModalTre';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import Editor from 'components/blocks/Editor';
import { BlockInputSingle } from 'components/blocks/Blocks';
import useDidMountEffect from 'helpers/useDidMountEffect';

// should be in all modals
const targetElement = document.querySelector('#root');

function YoutubeModal({
    close,
    setModalIsOpen,
    handleActionBtn,
    updateObj = null,
    update = false,
    globalState: { lang },
    icon = { name: 'GALLERY' },
    hintObj = null,
    placeholder = null,
    actionBtnObj = uiStruct.ui.modals.editor.buttons.action,
    cancelBtnObj = uiStruct.ui.modals.editor.buttons.cancel
}) {
    //

    const rtl = !!translations[lang].rtl;

    const defaultDataObj = '';

    //
    const [editorValidationError, setEditorValidationError] = useState(false);

    const [dataObj, updateDataObj] = useState(() =>
        update ? updateObj : defaultDataObj
    ); // set to a default or editing

    // Copy Below to all modals
    //////////////////////////////////////////////////////////////////////

    // State for triggering animation of modal in and out

    const [transitionState, setTransitionState] = useState('off');
    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    ///////////////////////////////////////////////////////////////////

    // Paul stuff
    const updateDescriptionHtml = (html) => {
        updateDataObj(html.target.value);
        // setEditorValidationError(false);
    };

    // Paul stuff Handle Action BTN function
    const handleActionClick = () => {
        const regex = new RegExp(
            /(?:\/|%3D|v=|vi=)([0-9A-z-_]{11})(?:[%#?&]|$)/gm
        );
        const youtubeDataArray = regex.exec(dataObj);

        if (!youtubeDataArray || youtubeDataArray.length < 2) {
            setEditorValidationError(true);
        } else {
            handleActionBtn(youtubeDataArray[1]);
            handleTransitionStateOff(); // important... don't forget to add it in all modals
        }
        // if (dataObj.replace(/(<([^>]+)>)/gi, '').length === 0 && !canBeEmpty) {
        //     setEditorValidationError(true);
        // } else {
        //     handleActionBtn(dataObj);
        //     handleTransitionStateOff(); // important... don't forget to add it in all modals
        // }
    };

    useDidMountEffect(() => {
        setEditorValidationError(() => !dataObj.length);
    }, [dataObj]);

    ////////////////////////////////

    return (
        <>
            <ModalContainer
                width='lg'
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={icon}
                close={close}
                rtl={rtl}
            >
                <ModalBody rtlOff>
                    <div className='mt-4 mb-6'>
                        Paste a Youtube Link in the box below.
                    </div>
                    <BlockInputSingle
                        normal
                        error={editorValidationError}
                        handleChange={updateDescriptionHtml}
                        id='name'
                        margins='mb-4'
                        value={dataObj}
                        placeholder={'https://youtube.com/kjhkjh87'}
                        rtl={rtl}
                        icon='ri-youtube-fill text-4xl text-red-500'
                        fontSize='text-xl'
                    />
                </ModalBody>

                <ModalFooter>
                    {hintObj && (
                        <ModalHelp
                            helpText={translations[lang][hintObj]}
                            isHtml
                        />
                    )}
                    <ModalCTA>
                        <ModalButton
                            handleClick={handleTransitionStateOff}
                            label={translations[lang][cancelBtnObj.label]}
                            color={cancelBtnObj.color}
                            icon={cancelBtnObj.icon}
                        />
                        <ModalButton
                            handleClick={handleActionClick}
                            label={translations[lang][actionBtnObj.label]}
                            color={actionBtnObj.color}
                            icon={actionBtnObj.icon}
                        />
                    </ModalCTA>
                </ModalFooter>
            </ModalContainer>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(YoutubeModal);
