import React, { useEffect } from 'react';

import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from 'components/utility/ScrollToTop';
import AuthLogin from 'components/auth/AuthLogin';
import AuthRegister from 'components/auth/AuthRegister';
import AuthProfile from 'components/auth/AuthProfile';
import AuthPasswordReset from 'components/auth/AuthPasswordReset';
// import history from 'components/utility/history';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ErrorPage from 'components/pages/ErrorPage';
import PageExperienceSingle from 'components/pages/PageExperienceSingle';
import PageExperienceSinglePrint from 'components/pages/PageExperienceSinglePrint';
import PageProfileAdmin from 'components/pages/PageProfileAdmin';
import PageExperienceSinglePublic from 'components/pages/PageExperienceSinglePublic';
import PageExperienceSinglePurchased from 'components/pages/PageExperienceSinglePurchased';
import PageExperiences from 'components/pages/PageExperiences';
import PagePublished from 'components/pages/PagePublished';
import PageLanding from 'components/pages/PageLanding';
import PageBasics from 'components/pages/PageBasics';
import PageBasicsDigital from 'components/pages/PageBasicsDigital';
import PageBasicsGuided from 'components/pages/PageBasicsGuided';
import PageBasicsHowTo from 'components/pages/PageBasicsHowTo';
import PagePurchases from 'components/pages/PagePurchases';
import LayoutFooter from 'components/layouts/LayoutFooter';
import SocialLoginUsername from 'components/auth/SocialLoginUsername';

import { GoogleOAuthProvider } from '@react-oauth/google';


// Redux
import { Provider } from 'react-redux';
import store from 'store';
import { loadUser } from 'store/actions/auth';
import setAuthToken from 'utils/setAuthToken';

import LayoutNavbar from 'components/layouts/LayoutNavbar';
import PrivateRoute from 'components/routing/PrivateRoute';
import SemiPublicRoute from 'components/routing/SemiPublicRoute';

import GoogleMaps from 'components/blocks/GoogleMaps';
import { fetchStartupData, fetchIpData } from 'fetch/experiences';
import { OnlineStatusProvider } from 'helpers/useOnlineStatus';

if (localStorage.token) {
    setAuthToken(localStorage.token);
}

const contextClass = {
    success: 'bg-green-400',
    error: 'bg-red-600',
    info: 'bg-gray-600',
    warning: 'bg-orange-400',
    default: 'bg-indigo-600',
    dark: 'bg-white-600 font-gray-300'
};

const App = () => {
    useEffect(() => {
        store.dispatch(fetchStartupData());
        store.dispatch(fetchIpData());
        store.dispatch(loadUser());
    }, []);

    return (
        <Provider store={store}>
            <OnlineStatusProvider>
                <GoogleOAuthProvider clientId="923684735717-v5mh2fbatms0qml3mdkv2ph02djvmc8n.apps.googleusercontent.com">

                    <GoogleMaps store={store} />
                    <React.Fragment>
                        <ToastContainer
                            className='mt-24'
                            toastClassName={({ type }) =>
                                contextClass[type || 'default'] + ' relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer'
                            }
                            bodyClassName={() => 'text-sm font-white font-med block p-3'}
                            position='top-right'
                            hideProgressBar={true}
                            autoClose={1750}
                            transition={Slide}
                        />
                        <Router basename={process.env.PUBLIC_URL}>
                            <ScrollToTop />
                            {/* <PrivateRoute path='/c' component={LayoutNavbar} /> */}
                            <Routes>
                                {/* <Route exact path='/' element={<LayoutNavbar />} />
                                <Route path='/c' element={<LayoutNavbar />} />
                                <Route path='/experience' element={<LayoutNavbar />} />
                                <Route path='/purchased' element={<LayoutNavbar />} />
                                <Route path='/basics' element={<LayoutNavbar />} /> */}

                                <Route exact path='/' element={<PageLanding />} />
                                <Route exact path='/basics' element={<PageBasics />} />
                                <Route exact path='/basics/digital' element={<PageBasicsDigital />} />
                                <Route exact path='/basics/guided' element={<PageBasicsGuided />} />
                                <Route exact path='/basics/how-to' element={<PageBasicsHowTo />} />
                                <Route exact path='/accounts/login' element={<AuthLogin />} />

                                <Route exact path='/socialloginusername' element={<SocialLoginUsername />} />

                                <Route exact path='/accounts/signup' element={<AuthRegister />} />
                                <Route exact path='/accounts/reset' element={<AuthPasswordReset />} />

                                <Route element={<PrivateRoute />} >
                                    <Route exact path='/c' element={
                                        <Navigate to={`${process.env.REACT_APP_BASENAME_EXPERIENCE}/all`} />
                                    } />
                                    <Route exact path={`${process.env.REACT_APP_BASENAME}/accounts/profile`} element={
                                        <AuthProfile />
                                    } />
                                    <Route exact path={`${process.env.REACT_APP_BASENAME_EXPERIENCE}/all`} element={<PageExperiences />} />
                                    <Route exact path={`${process.env.REACT_APP_BASENAME_PUBLISHED}/all`} element={<PagePublished />} />
                                    <Route exact path={`${process.env.REACT_APP_BASENAME_EXPERIENCE}/create`} element={<PageExperienceSingle />} />
                                    <Route
                                        exact
                                        path={`${process.env.REACT_APP_BASENAME_EXPERIENCE_PUBLISHED}/:id/print`}
                                        element={<><PageExperienceSinglePrint /></>}
                                    />
                                    <Route
                                        exact
                                        path={`${process.env.REACT_APP_BASENAME_EXPERIENCE_PUBLISHED}/:id`}
                                        element={<><PageExperienceSingle /></>}
                                    />
                                    <Route exact path={`${process.env.REACT_APP_BASENAME_EXPERIENCE_PUBLISHED}/:id/:day`} element={<PageExperienceSingle />} />
                                    <Route
                                        exact
                                        path={`${process.env.REACT_APP_BASENAME_EXPERIENCE}/:id/print`}
                                        element={<><PageExperienceSinglePrint /></>}
                                    />
                                    <Route exact path={`${process.env.REACT_APP_BASENAME_EXPERIENCE}/:id`} element={<PageExperienceSingle />} />
                                    <Route exact path={`${process.env.REACT_APP_BASENAME_EXPERIENCE}/:id/:day`} element={<PageExperienceSingle />} />
                                    <Route exact path={`${process.env.REACT_APP_BASENAME}/settings/account/profile`} element={<PageProfileAdmin />} />
                                    <Route exact path={`${process.env.REACT_APP_BASENAME}/purchased`} element={<PagePurchases />} />


                                </Route>


                                <Route exact path={`/experience/:id/:day`} element={<PageExperienceSinglePublic />} />
                                <Route exact path={`/purchased/:id/:day`} element={<PageExperienceSinglePurchased />} />
                                <Route element={<ErrorPage />} />
                            </Routes>
                            <LayoutFooter />

                        </Router>
                    </React.Fragment>
                </GoogleOAuthProvider>
            </OnlineStatusProvider>
        </Provider>
    );
};

export default App;
