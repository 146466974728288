import * as types from 'store/actions/types';

export function createWhatYouNeed(props) {
    return (dispatch) => {
        dispatch({ type: types.CREATE_WHAT_YOU_NEED, ...props });
    };
}

export function deleteWhatYouNeed(props) {
    return (dispatch) => {
        dispatch({ type: types.DELETE_WHAT_YOU_NEED, ...props });
    };
}

export function updateDescriptionWhatYouNeed(props) {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_DESCRIPTION_CHECKLIST, ...props });
    };
}

export function updateCheckListUrls(props) {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_CHECKLIST_LIST, ...props });
    };
}
