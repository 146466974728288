/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as types from 'store/actions/types';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic';
import CardXl from 'components/blocks/CardXl';
import TipList from 'components/blocks/TipList';
import GenericDescription from 'components/blocks/GenericDescription';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { PillContainer, PillIcon, PillTitle } from 'components/blocks/Pills';
import BudgetRange from 'components/blocks/BudgetRange';
import { Section__Title } from 'components/blocks/Sections';

function SectionBudgetRange({
    experienceDetailsAction,
    actionType,
    experienceDetails: {
        experience: {
            budget: { description_html: descriptionHtml, tips_html: tipsHtml, isVisible: isSectionVisible = true, objId }
        }
    },

    globalState: { lang, edit, isPrintMode, isFeedbackMode }
}) {
    const rtl = !!translations[lang].rtl;
    const typeText = 'Tmp title';
    const [isMinimized, setIsMinimized] = useState(false);

    const handleVisible = () => {
        experienceDetailsAction({
            type: types.UPDATE_SECTION_VISIBILITY,
            val: !isSectionVisible,
            objName: 'budget'
        });
    };

    return (
        <>
            {(edit || isSectionVisible) && (
                <div className={`w-full py-10`}>
                    <Section__Title
                        edit={edit}
                        title={'Budget'}
                        hasData
                        hintObj={uiStruct.itinerary.what_you_need.uiPreview.hint}
                        handleVisible={handleVisible}
                        isVisible={isSectionVisible}
                        className='mt-16 md:mt-8 -mb-4 lg:-mb-8'
                    />

                    {isSectionVisible && (
                        <div className={`${isMinimized ? '-mt-4 xl:-mt-8 mb-16' : ''}`}>
                            <CardXl
                                isFeedbackMode={isFeedbackMode}
                                objId={objId?.section}
                                edit={edit}
                                isPrintMode={isPrintMode}
                                rtl={rtl}
                                hidden={isMinimized}
                                handleMinimize={() => setIsMinimized(!isMinimized)}
                                isVisible={isSectionVisible}
                                handleVisible={handleVisible}
                                hideEye
                            >
                                {/* <div
                                className={`z-200 w-max mx-auto ${
                                    isMinimized ? 'mt-2 xl:mt-8' : ''
                                }`}
                            >
                                <PillContainer rtl={rtl}>
                                    <PillIcon icon='WALLET' />
                                    <PillTitle
                                        title={
                                            translations[lang][
                                                uiStruct.overview.budget
                                                    .uiPreview.menuTitle
                                            ]
                                        }
                                    />
                                </PillContainer>
                            </div> */}
                                <div className={`${isMinimized ? 'hidden' : ''}`}>
                                    <div className='min-h-10'>
                                        {(edit || descriptionHtml) && (
                                            <GenericDescription
                                                actionType={actionType}
                                                dayIndex={null}
                                                descriptionHtml={descriptionHtml}
                                                defaultHtml={translations[lang][uiStruct.overview.budget.uiPreview.descriptionEmpty]}
                                                objId={objId?.description}
                                                title='Budget Range'
                                                subtitle='Description'
                                            />
                                        )}
                                    </div>
                                    <BudgetRange objId={objId?.range} />
                                    <TipList
                                        // max
                                        objId={objId?.tiplist}
                                        actionType={actionType}
                                        tips={tipsHtml}
                                        offset='0'
                                        isVisible={isSectionVisible}
                                    />
                                </div>
                            </CardXl>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            experienceDetailsAction
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionBudgetRange);
