import React, { useContext, useEffect } from 'react';
import { ShepherdTourContext } from 'react-shepherd';
import { ProgressPage } from 'components/blocks/ProgressMenu';
import SpinnerButton from 'components/blocks/SpinnerButton';
import CollapseButton from 'components/blocks/CollapseButton';
import { getBtnLabel } from 'helpers/misc.js';
import { useOnlineStatus } from 'helpers/useOnlineStatus';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
// import { useScroll } from 'helpers/useScroll';

const LayoutMenuEdit = ({
    showHeader,
    day,
    isPublishedView,
    isOwner,
    isAdminStaff,
    isSuperAdmin,
    isFeedbackMode,
    isPrintMode,
    inPublished = false,
    expPublishStatus = false,
    handleSuperAdmin,
    handlePublishedView,
    handleFeedbackMode,
    handlePrintMode,
    author,
    expStatus,
    tagsActionBtn,
    guidedActionBtn,
    includesActionBtn,
    feedbackAction,
    submitExperienceAction,
    approvExperienceAction,
    type,
    edit,
    handleEdit,
    toolTips,
    handleToolTips,
    isSaving,
    handleSave,
    rtl,
    completion = {
        total: 0,
        overview: 0,
        days: 0,
        marketing: 0
    },
    menuIsOpen
}) => {
    const konnectTour = useContext(ShepherdTourContext);

    const handleTour = () => {
        konnectTour.start();
    };

    const history = useNavigate();

    // const showHeader = useScroll();

    const handleFeedbackToggling = () => {
        const cState = isFeedbackMode;
        if (isSuperAdmin) {
            handleFeedbackMode(!cState);
        } else {
            handleFeedbackMode(!cState);
            handleEdit(!cState);
        }
    };

    const handleSuperAdminToggling = () => {
        const cState = isSuperAdmin;
        handleSuperAdmin(!cState);
        if (isFeedbackMode) handleEdit(true);
        else handleEdit(!cState);
    };

    const handlePrintToggling = () => {
        handlePrintMode(!isPrintMode);
        // const cState = isSuperAdmin;
        // handleSuperAdmin(!cState);
        // handleEdit(!cState);
    };

    const handleVersionToggling = () => {
        handlePublishedView(!isPublishedView);
        // const cState = isSuperAdmin;
        // handleSuperAdmin(!cState);
        // handleEdit(!cState);
    };

    const handleHowTo = () => {
        history(`/basics/how-to`, { replace: true });
    };

    // console.log('completion is:', completion);

    useEffect(() => {
        if (isPublishedView && !isAdminStaff && edit) {
            handleEdit(false);
        }
    }, [edit]);

    const isOnline = useOnlineStatus();

    return (
        <>
            <div className='print:hidden block xl:hidden'>
                <div
                    // style={{ zIndex: 100 }}
                    className={`lg:z-1000 z-50 bg-white lg:bg-transparent fixed tour-right-bar bottom-16 md:bottom-20 lg:bottom-0  origin-top-right left-0 right-0  lg:left-auto overflow-x-scroll no-scrollbar shadow-cards-top lg:shadow-none
                    transform-gpu duration-300 
                    
                    
                    `}
                >
                    <div className='flex flex-row flex-nowrap items-center md:pl-28 md:justify-center2 lg:pr-24 text-2xl px-8 lg:justify-end gap-2 py-4'>
                        {!isOnline && (
                            <div className='flex justify-end'>
                                <CollapseButton
                                    labelHover='You are offline'
                                    noHover={true}
                                    handleClick={null}
                                    icon={'ri-wifi-off-line text-2xl'}
                                    size='12'
                                    textSize='text-xs'
                                    sizeHover={'w-44'}
                                    offsetCenter='2'
                                    padding='px-3'
                                    btnColor='bg-red-100 text-red-600'
                                    hoverText=''
                                />
                            </div>
                        )}
                        {(isPublishedView || inPublished) && !isPrintMode && (
                            <div className='flex justify-end'>
                                <CollapseButton
                                    disabled={!isOnline}
                                    noHover={true}
                                    labelHover={isPublishedView ? 'Back to Saved version' : 'View Approved version'}
                                    handleClick={handleVersionToggling}
                                    icon={`${isPublishedView ? 'ri-arrow-go-back-line' : 'ri-shield-check-fill'} text-2xl`}
                                    size='12'
                                    textSize='text-xs'
                                    sizeHover={isPublishedView ? 'w-52' : 'w-52'}
                                    offsetCenter='2'
                                    padding='px-3'
                                    btnColor='bg-gray-300 text-gray-900 hover:bg-gray-900 hover:text-white'
                                    hoverText=''
                                />
                            </div>
                        )}
                        {(isOwner || isAdminStaff) && (
                            <div className='flex justify-end'>
                                <CollapseButton
                                    disabled={!isOnline}
                                    noHover={false}
                                    labelHover={`${completion.total}% completed`}
                                    size='12'
                                    sizeHover='w-44'
                                    rounded='rounded-3xl'
                                    textSize='text-xs'
                                    noicon
                                    offsetCenter='2'
                                    padding='px-3.5'
                                    nolink
                                >
                                    <ProgressPage completion={completion.total} size='5' color='' nolabel strokeWidth={12} />
                                </CollapseButton>
                            </div>
                        )}
                        {author.role === process.env.REACT_APP_STRAPI_STAFF_ROLE_ID && (
                            <div className='flex justify-end'>
                                <CollapseButton
                                    disabled={!isOnline}
                                    noHover={true}
                                    labelHover='Inspirational Sample'
                                    icon='las la-chalkboard-teacher text-2xl'
                                    size='8'
                                    textSize='text-xs font-bold'
                                    sizeHover='w-48'
                                    offsetCenter='2'
                                    padding='px-3'
                                    btnColor={`bg-purple-200 text-purple-800 hover:ring-purple-400`}
                                    hoverText=''
                                />
                            </div>
                        )}

                        {isAdminStaff && (
                            <div className='flex justify-end'>
                                <CollapseButton
                                    disabled={!isOnline}
                                    noHover={true}
                                    labelHover={isSuperAdmin ? 'Superadmin is On' : 'Superadmin is Off'}
                                    handleClick={handleSuperAdminToggling}
                                    icon={`${isSuperAdmin ? 'ri-vip-crown-line' : 'ri-vip-crown-line'} text-2xl`}
                                    size='12'
                                    textSize='text-xs font-bold'
                                    sizeHover='w-48'
                                    offsetCenter='2'
                                    padding='px-3'
                                    btnColor={
                                        isSuperAdmin
                                            ? 'bg-yellow-300 text-yellow-900 hover:ring-yellow-600'
                                            : 'bg-yellow-100 text-yellow-800 hover:ring-yellow-400'
                                    }
                                    hoverText=''
                                />
                            </div>
                        )}

                        {isAdminStaff && !isPublishedView && (
                            <div className='flex justify-end'>
                                <CollapseButton
                                    disabled={!isOnline}
                                    noHover={true}
                                    labelHover={isFeedbackMode ? 'Feedback Mode is On' : 'Feedback Mode is Off'}
                                    handleClick={handleFeedbackToggling}
                                    icon={`${isFeedbackMode ? 'ri-feedback-line' : 'ri-feedback-line'} text-2xl`}
                                    size='12'
                                    textSize='text-xs font-bold'
                                    sizeHover='w-56'
                                    offsetCenter='2'
                                    padding='px-3'
                                    btnColor={
                                        isFeedbackMode
                                            ? 'bg-yellow-300 text-yellow-900 hover:ring-yellow-600'
                                            : 'bg-yellow-100 text-yellow-800 hover:ring-yellow-400'
                                    }
                                    hoverText=''
                                />
                            </div>
                        )}

                        <div className='flex justify-end'>
                            <CollapseButton
                                disabled={!isOnline}
                                noHover={true}
                                labelHover={isPrintMode ? 'Print Mode is On' : 'Print Mode is Off'}
                                handleClick={handlePrintToggling}
                                icon={`${isPrintMode ? 'ri-printer-line' : 'ri-printer-line'} text-2xl`}
                                size='12'
                                textSize='text-xs font-bold'
                                sizeHover='w-48'
                                offsetCenter='2'
                                padding='px-3'
                                btnColor={
                                    isPrintMode ? 'bg-yellow-300 text-yellow-900 hover:ring-yellow-600' : 'bg-yellow-100 text-yellow-800 hover:ring-yellow-400'
                                }
                                hoverText=''
                            />
                        </div>

                        {day !== 'print' && expStatus && (isOwner || (isAdminStaff && isSuperAdmin)) && !isPublishedView ? (
                            <div className='flex justify-end'>
                                <CollapseButton
                                    disabled={!isOnline}
                                    noHover={true}
                                    labelHover={getBtnLabel(expStatus)}
                                    handleClick={submitExperienceAction}
                                    icon='ri-upload-cloud-2-line text-xl'
                                    size='12'
                                    textSize='text-xs'
                                    sizeHover={expStatus.status === 'pending' || expStatus.status === 'submitted' ? `w-52` : `w-48`}
                                    offsetCenter='2'
                                    padding='px-3.5'
                                    warning={false}
                                    btnColor='bg-blue-200 text-blue-900 hover:bg-gray-900 hover:text-white'
                                />
                            </div>
                        ) : null}
                        {day !== 'print' && expStatus && isAdminStaff && !isPublishedView ? (
                            <div className='flex justify-end'>
                                <CollapseButton
                                    disabled={!isOnline}
                                    noHover={true}
                                    labelHover={'Approve'}
                                    handleClick={approvExperienceAction}
                                    icon='ri-shield-check-line text-xl'
                                    size='12'
                                    textSize='text-xs'
                                    sizeHover='w-32'
                                    offsetCenter='2'
                                    padding='px-3.5'
                                    warning={false}
                                />
                            </div>
                        ) : null}

                        {((isOwner && !isPublishedView) || isAdminStaff) && (
                            <>
                                {(isOwner || (isAdminStaff && isSuperAdmin)) && (
                                    <>
                                        <div className='flex justify-end'>
                                            <SpinnerButton
                                                disabled={!isOnline}
                                                labelHover='Save'
                                                labelActive='Saving'
                                                handleClick={handleSave}
                                                isBusy={isSaving}
                                                noHover={true}
                                            />
                                        </div>

                                        <div className='flex justify-end'>
                                            <CollapseButton
                                                disabled={!isOnline}
                                                noHover={true}
                                                labelHover={edit ? 'Edit Mode ' : 'Preview Mode'}
                                                handleClick={() => handleEdit(!edit)}
                                                icon={`${edit ? 'ri-pencil-line' : 'ri-eye-line'} text-2xl`}
                                                size='12'
                                                textSize='text-xs font-bold'
                                                sizeHover={edit ? 'w-36' : 'w-44'}
                                                offsetCenter='2'
                                                padding='px-3'
                                                btnColor={
                                                    edit
                                                        ? 'bg-green-400 text-green-900 hover:ring-green-600'
                                                        : 'bg-green-100 text-green-800 hover:ring-green-400'
                                                }
                                                hoverText=''
                                            />
                                        </div>

                                        {false && (
                                            <div className='flex justify-end'>
                                                <CollapseButton
                                                    disabled={!isOnline}
                                                    noHover={true}
                                                    labelHover={toolTips ? 'Tooltips are on' : 'Tooltips are off'}
                                                    handleClick={() => handleToolTips(!toolTips)}
                                                    icon='ri-message-line text-2xl'
                                                    size='12'
                                                    textSize='text-xs font-bold'
                                                    sizeHover='w-44'
                                                    offsetCenter='2'
                                                    padding='px-3'
                                                    btnColor={
                                                        toolTips
                                                            ? 'bg-green-400 text-green-900 hover:ring-green-600'
                                                            : 'bg-green-100 text-green-800 hover:ring-green-400'
                                                    }
                                                    hoverText=''
                                                />
                                            </div>
                                        )}
                                        {day !== 'print' && (
                                            <div className='flex justify-end'>
                                                <CollapseButton
                                                    disabled={!isOnline}
                                                    noHover={true}
                                                    labelHover='How-to Videos'
                                                    handleClick={handleHowTo}
                                                    icon='ri-slideshow-2-line text-2xl'
                                                    size='12'
                                                    textSize='text-xs'
                                                    sizeHover='w-40'
                                                    offsetCenter='2'
                                                    padding='px-3'
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                                {/* {day === 'marketing' ? (
                                    <>
                                        <div className='flex pr-4'>
                                            <CollapseButton
                                                noHover={true}
                                                labelHover={
                                                    isAdminStaff
                                                        ? isSuperAdmin
                                                            ? 'Add Hashtags'
                                                            : 'View Hashtags'
                                                        : 'Add Hashtags'
                                                }
                                                handleClick={tagsActionBtn}
                                                icon='ri-hashtag text-xl'
                                                size='12'
                                                textSize='text-xs'
                                                sizeHover='w-40'
                                                offsetCenter='2'
                                                padding='px-3.5'
                                            />
                                        </div>
                                    </>
                                ) : null} */}
                            </>
                        )}
                    </div>
                </div>
            </div>
            {/* Start other zone here */}
            <div className='print:hidden hidden xl:block tour-right-bar'>
                <div className={`z-50 fixed top-16 md:top-24 ${rtl ? 'left-0' : 'right-0'}`}>
                    <div className='flex px-4 justify-end'>
                        <div
                            className={`rounded-lg ${
                                isPublishedView ? (expPublishStatus ? 'bg-green-100 text-green-700' : 'bg-gray-200') : ''
                            } w-max overflow-hidden flex flex-col justify-center`}
                        >
                            <div className='text-xxs px-4 py-2 rounded-lg bg-gray-900 text-white justify-center'>
                                {isPublishedView ? 'Publishing View' : 'Design View'}
                            </div>
                            {isPublishedView && <div className='text-xxs px-4 py-1 flex justify-center'>{expPublishStatus ? 'Published' : 'Unpublished'}</div>}
                        </div>
                    </div>
                    <div className='flex-1 flex flex-col p-4 justify-end text-2xl gap-2'>
                        {!isOnline && (
                            <div className='flex justify-end'>
                                <CollapseButton
                                    labelHover='You are offline'
                                    handleClick={null}
                                    icon={'ri-wifi-off-line text-2xl'}
                                    size='12'
                                    textSize='text-xs'
                                    sizeHover={'w-44'}
                                    offsetCenter='2'
                                    padding='px-3'
                                    btnColor='bg-red-100 text-red-600'
                                    hoverText=''
                                />
                            </div>
                        )}

                        {(isPublishedView || inPublished) && !isPrintMode && (
                            <div className='flex justify-end'>
                                <CollapseButton
                                    disabled={!isOnline}
                                    labelHover={isPublishedView ? 'Back to Saved version' : 'View Approved version'}
                                    handleClick={handleVersionToggling}
                                    icon={`${isPublishedView ? 'ri-arrow-go-back-line' : 'ri-shield-check-fill'} text-2xl`}
                                    size='12'
                                    textSize='text-xs'
                                    sizeHover={isPublishedView ? 'w-52' : 'w-52'}
                                    offsetCenter='2'
                                    padding='px-3'
                                    btnColor='bg-purple-200 text-purple-600 hover:bg-gray-900 hover:text-white'
                                    hoverText=''
                                />
                            </div>
                        )}

                        {(isOwner || isAdminStaff) && (
                            <div className='flex justify-end'>
                                <button
                                    className={classNames(
                                        'bg-green-100 text-green-800',
                                        ' outline-none focus:outline-none w-12 h-12 hover:w-48 hover:h-44 transform transition-all duration-300 out-expo  rounded-3xl py-4 overflow-hidden'
                                    )}
                                >
                                    <div className='flex flex-col gap-4 text-xs '>
                                        <div className='flex flex-nowrap items-center gap-4 px-4 transform -translate-x-0.5 -translate-y-0.5'>
                                            <div>
                                                <ProgressPage completion={completion.total} size='5' color='' nolabel strokeWidth={12} />
                                            </div>
                                            <span className='text-sm font-semibold whitespace-nowrap translate-y-0.5 transform'>{`${completion.total}% completed`}</span>
                                        </div>
                                        <div className={classNames('w-full border-t border-green-400')}></div>
                                        <div className='flex flex-col gap-4 px-4'>
                                            <div className='flex flex-nowrap items-center gap-4'>
                                                <div>
                                                    <ProgressPage completion={completion.overview} size='4' color='' nolabel strokeWidth={16} />
                                                </div>
                                                <span className='text-xs whitespace-nowrap'>{`${completion.overview}% Overview`}</span>
                                            </div>
                                            <div className='flex flex-nowrap items-center gap-4'>
                                                <div>
                                                    <ProgressPage completion={completion.days} size='4' color='' nolabel strokeWidth={16} />
                                                </div>
                                                <span className='text-xs whitespace-nowrap'>{`${completion.days}% Itinerary`}</span>
                                            </div>
                                            <div className='flex flex-nowrap items-center gap-4'>
                                                <div>
                                                    <ProgressPage completion={completion.marketing} size='4' color='' nolabel strokeWidth={16} />
                                                </div>
                                                <span className='text-xs whitespace-nowrap'>{`${completion.marketing}% Marketing`}</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        )}
                        {author.role === process.env.REACT_APP_STRAPI_STAFF_ROLE_ID && (
                            <div className='flex justify-end'>
                                <CollapseButton
                                    disabled={!isOnline}
                                    labelHover='Inspirational Sample'
                                    icon='las la-chalkboard-teacher text-2xl'
                                    size='12'
                                    textSize='text-xs font-bold'
                                    sizeHover='w-48'
                                    offsetCenter='2'
                                    padding='px-3'
                                    btnColor={`bg-purple-200 text-purple-800 hover:ring-purple-400`}
                                    hoverText=''
                                />
                            </div>
                        )}

                        {isAdminStaff && (
                            <div className='flex justify-end'>
                                <CollapseButton
                                    disabled={!isOnline}
                                    labelHover={isSuperAdmin ? 'Superadmin is On' : 'Superadmin is Off'}
                                    handleClick={handleSuperAdminToggling}
                                    icon={`${isSuperAdmin ? 'ri-vip-crown-line' : 'ri-vip-crown-line'} text-2xl`}
                                    size='12'
                                    textSize='text-xs font-bold'
                                    sizeHover='w-48'
                                    offsetCenter='2'
                                    padding='px-3'
                                    btnColor={
                                        isSuperAdmin
                                            ? 'bg-yellow-300 text-yellow-900 hover:ring-yellow-600'
                                            : 'bg-yellow-100 text-yellow-800 hover:ring-yellow-400'
                                    }
                                    hoverText=''
                                />
                            </div>
                        )}

                        {isAdminStaff && !isPublishedView && (
                            <div className='flex justify-end'>
                                <CollapseButton
                                    disabled={!isOnline}
                                    labelHover={isFeedbackMode ? 'Feedback Mode is On' : 'Feedback Mode is Off'}
                                    handleClick={handleFeedbackToggling}
                                    icon={`${isFeedbackMode ? 'ri-feedback-line' : 'ri-feedback-line'} text-2xl`}
                                    size='12'
                                    textSize='text-xs font-bold'
                                    sizeHover='w-56'
                                    offsetCenter='2'
                                    padding='px-3'
                                    btnColor={
                                        isFeedbackMode
                                            ? 'bg-yellow-300 text-yellow-900 hover:ring-yellow-600'
                                            : 'bg-yellow-100 text-yellow-800 hover:ring-yellow-400'
                                    }
                                    hoverText=''
                                />
                            </div>
                        )}

                        <div className='flex justify-end'>
                            <CollapseButton
                                disabled={!isOnline}
                                labelHover={isPrintMode ? 'Print Mode is On' : 'Print Mode is Off'}
                                handleClick={handlePrintToggling}
                                icon={`${isPrintMode ? 'ri-printer-line' : 'ri-printer-line'} text-2xl`}
                                size='12'
                                textSize='text-xs font-bold'
                                sizeHover='w-48'
                                offsetCenter='2'
                                padding='px-3'
                                btnColor={
                                    isPrintMode ? 'bg-yellow-300 text-yellow-900 hover:ring-yellow-600' : 'bg-yellow-100 text-yellow-800 hover:ring-yellow-400'
                                }
                                hoverText=''
                            />
                        </div>

                        {day !== 'print' && expStatus && (isOwner || (isAdminStaff && isSuperAdmin)) && !isPublishedView ? (
                            <div className='flex justify-end'>
                                <CollapseButton
                                    disabled={!isOnline}
                                    labelHover={getBtnLabel(expStatus)}
                                    handleClick={submitExperienceAction}
                                    icon='ri-upload-cloud-2-line text-xl'
                                    size='12'
                                    textSize='text-xs'
                                    sizeHover={expStatus.status === 'pending' || expStatus.status === 'submitted' ? `w-52` : `w-48`}
                                    offsetCenter='2'
                                    padding='px-3.5'
                                    warning={false}
                                    btnColor='bg-blue-200 text-blue-900 hover:bg-gray-900 hover:text-white'
                                />
                            </div>
                        ) : null}
                        {day !== 'print' && expStatus && isAdminStaff && !isPublishedView ? (
                            <div className='flex justify-end'>
                                <CollapseButton
                                    disabled={!isOnline}
                                    labelHover={'Approve'}
                                    handleClick={approvExperienceAction}
                                    icon='ri-shield-check-line text-xl'
                                    size='12'
                                    textSize='text-xs'
                                    sizeHover='w-32'
                                    offsetCenter='2'
                                    padding='px-3.5'
                                    warning={false}
                                />
                            </div>
                        ) : null}
                    </div>
                    <div className='flex-1 flex flex-col p-4 justify-end text-2xl gap-2'>
                        {((isOwner && !isPublishedView) || isAdminStaff) && (
                            <>
                                {(isOwner || (isAdminStaff && isSuperAdmin)) && (
                                    <>
                                        <div className='flex justify-end'>
                                            <SpinnerButton
                                                disabled={!isOnline}
                                                labelHover='Save'
                                                labelActive='Saving'
                                                handleClick={handleSave}
                                                isBusy={isSaving}
                                            />
                                        </div>

                                        <div className='flex justify-end'>
                                            <CollapseButton
                                                disabled={!isOnline}
                                                labelHover={edit ? 'Edit Mode ' : 'Preview Mode'}
                                                handleClick={() => handleEdit(!edit)}
                                                icon={`${edit ? 'ri-pencil-line' : 'ri-eye-line'} text-2xl`}
                                                size='12'
                                                textSize='text-xs font-bold'
                                                sizeHover={edit ? 'w-36' : 'w-44'}
                                                offsetCenter='2'
                                                padding='px-3'
                                                btnColor={
                                                    edit
                                                        ? 'bg-green-400 text-green-900 hover:ring-green-600'
                                                        : 'bg-green-100 text-green-800 hover:ring-green-400'
                                                }
                                                hoverText=''
                                            />
                                        </div>

                                        {false && (
                                            <div className='flex justify-end'>
                                                <CollapseButton
                                                    disabled={!isOnline}
                                                    labelHover={toolTips ? 'Tooltips are on' : 'Tooltips are off'}
                                                    handleClick={() => handleToolTips(!toolTips)}
                                                    icon='ri-message-line text-2xl'
                                                    size='12'
                                                    textSize='text-xs font-bold'
                                                    sizeHover='w-44'
                                                    offsetCenter='2'
                                                    padding='px-3'
                                                    btnColor={
                                                        toolTips
                                                            ? 'bg-green-400 text-green-900 hover:ring-green-600'
                                                            : 'bg-green-100 text-green-800 hover:ring-green-400'
                                                    }
                                                    hoverText=''
                                                />
                                            </div>
                                        )}
                                        {day !== 'print' && (
                                            <div className='flex justify-end'>
                                                <CollapseButton
                                                    disabled={!isOnline}
                                                    labelHover='How-to Videos'
                                                    handleClick={handleHowTo}
                                                    icon='ri-slideshow-2-line text-2xl'
                                                    size='12'
                                                    textSize='text-xs'
                                                    sizeHover='w-40'
                                                    offsetCenter='2'
                                                    padding='px-3'
                                                />
                                            </div>
                                        )}
                                    </>
                                )}

                                {/* {day === 'marketing' ? (
                                    <>
                                        <div className='flex justify-end'>
                                            <CollapseButton
                                                labelHover={
                                                    isAdminStaff
                                                        ? isSuperAdmin
                                                            ? 'Add Hashtags'
                                                            : 'View Hashtags'
                                                        : 'Add Hashtags'
                                                }
                                                handleClick={tagsActionBtn}
                                                icon='ri-hashtag text-xl'
                                                size='12'
                                                textSize='text-xs'
                                                sizeHover='w-40'
                                                offsetCenter='2'
                                                padding='px-3.5'
                                            />
                                        </div>
                                    </>
                                ) : null} */}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default LayoutMenuEdit;
