import { formatPrice } from 'helpers/LocaleHelper';
import moment from 'moment';

export function PurchaseReportDigitalStruct(handleBtnAction) {
    return [
        // {
        //     Header: 'id',
        //     accessor: 'id',
        //     disableSortBy: true,
        //     Cell: (row) => {
        //         return (
        //             <div className='text-center'>{row.value}</div>
        //         );
        //     }
        // },
        {
            Header: 'Order Date',
            accessor: 'createdAt',
            disableSortBy: true,
            Cell: (row) => {
                return <div className='text-xxs text-center'>{moment(row.value).format('MMM Do YYYY')}</div>;
            }
        },
        {
            Header: 'Booking Date',
            accessor: 'travel_date',
            disableSortBy: true,
            Cell: (row) => {
                return <div className='text-xxs text-center'>{moment(row.value).format('MMM Do YYYY')}</div>;
            }
        },
        {
            Header: 'Title',
            accessor: 'title',
            disableSortBy: true,
            Cell: (row) => {
                return <div className='text-center'>{row.value}</div>;
            }
        },
        {
            Header: 'Price',
            accessor: 'price',
            disableSortBy: true,
            Cell: (row) => {
                return <div className='text-center'>{formatPrice(row.value, 'USD', undefined, undefined, undefined, '$US ')}</div>;
            }
        },
        {
            Header: 'Quantity',
            accessor: 'people',
            disableSortBy: true,
            Cell: (row) => {
                return <div className='text-center'>{row.value}</div>;
            }
        },
        {
            Header: 'Sub Total',
            accessor: 'subTotal',
            disableSortBy: true,
            Cell: (row) => {
                return <div className='text-center'>{formatPrice(row.value, 'USD', undefined, undefined, undefined, '$US ')}</div>;
            }
        },
        {
            Header: 'Discount',
            accessor: 'discountTotal',
            disableSortBy: true,
            Cell: (row) => {
                return <div className='text-center'>{row.value == 0 ? '-' : formatPrice(row.value, 'USD', undefined, undefined, undefined, '$US ')}</div>;
            }
        },

        {
            Header: 'Grand Total',
            accessor: 'grandTotal',
            disableSortBy: true,
            Cell: (row) => {
                const refunded = row?.row?.original?.canceled;

                return <div className='text-center'>{formatPrice(row.value, 'USD', undefined, undefined, undefined, '$US ')}</div>;
            }
        },
        {
            Header: 'Status',
            accessor: 'canceled',
            disableSortBy: true,
            Cell: (row) => {
                return (
                    <div className='text-center'>{row.value ? <span className=' rounded-full bg-yellow-300 text-yellow-800 px-4'>Refunded</span> : '-'}</div>
                );
            }
        },
        {
            Header: 'Payout',
            accessor: 'payout',
            disableSortBy: true,
            Cell: (row) => {
                const refunded = row.row.original.canceled;
                return <div className='text-center'>{refunded ? '-' : formatPrice(row.value, 'USD', undefined, undefined, undefined, '$US ')}</div>;
            }
        }
        // {
        //     Header: '',
        //     accessor: 'purchaseId',
        //     disableSortBy: true,
        //     Cell: (row) => {
        //         return (
        //             <>
        //                 { handleBtnAction
        //                 ?  <div className='text-center'><button onClick={() => handleBtnAction(row.value) }>Btn</button></div>
        //                 : null}
        //             </>

        //         );
        //     }
        // },
    ];
}

export default PurchaseReportDigitalStruct;
