/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';
import TablePaginationControls from 'components/table/TablePaginationControls';
import { useMemo } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import Spinner from 'components/blocks/Spinner';

function BasicTable({
    columns,
    data,
    pagination = true,
    disableSortBy = true,
    sortColumn = '',
    manualFunc,
    pCount,
    nextPageLoading,
    currentPage = 0,
    limit

}) {
    // const tableInstance = useTable({ columns, data });
    // const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    //     tableInstance;
    const defaultSize = limit;
    const firstTime = useRef(true);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: currentPage,
                pageSize: pagination ? defaultSize : data.length + 1,
                // sortBy: [
                //     {
                //         id: sortColumn,
                //         desc: false
                //     }
                // ]
            },
            disableSortBy,
            sortTypes: useMemo(
                () => ({
                    caseInsensitive: (a, b, id) => {
                        const valueA = a.values[id].toLowerCase();
                        const valueB = b.values[id].toLowerCase();
                        return valueB > valueA ? 1 : valueB < valueA ? -1 : 0;
                    }
                }),
                []
            ),
            manualPagination: true,
            pageCount: pCount,
        },

        useSortBy,
        usePagination
    );

    useEffect(() => {
        if(!firstTime.current) {
            manualFunc(pageIndex, pageSize);
        } else {
            firstTime.current = false;
        }
    }, [pageIndex, pageSize])
    
    return (
        <>
            <table {...getTableProps()} className='table-auto w-full'>
                <thead>
                    {
                        // Loop over the header rows
                        headerGroups.map((headerGroup) => (
                            // Apply the header row props
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                <th className='text-center text-green-600 font-medium bg-green-100 py-5 px-4 text-sm'>
                                    #
                                </th>
                                {
                                    // Loop over the headers in each row
                                    headerGroup.headers.map((column) => (
                                        // Apply the header cell props
                                        <th
                                            {...column.getHeaderProps([
                                                column.getSortByToggleProps(),
                                                {
                                                    className:
                                                        column.className ||
                                                        'text-center font-medium bg-green-100 py-5 px-4 text-sm'
                                                }
                                            ])}
                                        >
                                            <div className='flex items-center justify-center h-8'>
                                                <span
                                                    className={`h-5 flex items-center justify-center ${
                                                        column.canSort
                                                            ? 'border-b text-green-600 border-green-600 hover:text-green-900 hover:border-green-900'
                                                            : 'text-green-600'
                                                    }`}
                                                >
                                                    {
                                                        // Render the header
                                                        column.render('Header')
                                                    }
                                                </span>
                                                <span className='ml-1 text-lg text-green-900'>
                                                    {column.isSorted ? (
                                                        column.isSortedDesc ? (
                                                            <i className='ri-sort-desc'></i>
                                                        ) : (
                                                            <i className='ri-sort-asc'></i>
                                                        )
                                                    ) : (
                                                        ''
                                                    )}
                                                </span>
                                            </div>
                                        </th>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </thead>
                {/* Apply the table body props */}
                    <tbody {...getTableBodyProps()}>
                    {!nextPageLoading
                    ? <>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr className='odd:bg-white' {...row.getRowProps()}>
                                    <td className='text-xs text-center'>{(currentPage*limit) + i + 1}</td>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td
                                                {...cell.getCellProps({
                                                    className: 'py-2 text-xs'
                                                })}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                        </>
                        : <tr>
                            <th className='text-xs text-center' colSpan={columns.length}>
                                <div style={{width: 20, margin: '0 auto'}}>
                                    <Spinner
                                        size='64'
                                        strokeWidth='20'
                                        strokeColor={'black'}
                                    />
                                </div>
                            </th>
                        </tr>}
                    </tbody>
            </table>
            {/* { pCount*limit > defaultSize ? ( */}
                <TablePaginationControls
                    gotoPage={gotoPage}
                    canPreviousPage={canPreviousPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    canNextPage={canNextPage}
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                />
            {/* ) : null} */}
        </>
    );
}

export default BasicTable;
