import Icons from 'components/blocks/Icons';
import { handleRowReverse } from 'helpers/FEutils';
import KnIcons from 'constants/KnIcons';

const PillContainer = ({ edit, rtl, children, className, isFeedbackMode }) => {
    return (
        <div
            className={`${className || ''} z-10 border-2 border-transparent relative ${
                edit && !isFeedbackMode ? 'hover:border-kn-primary  cursor-pointer' : ''
            } transition-colors ease-in-out duration-500 rounded-3xl flex flex-col lg:${
                handleRowReverse(rtl).flex
            } items-center justify-center p-2 px-4 lg:p-2 -mt-14 lg:-mt-12 lg:bg-white max-w-full lg:w-max mx-auto  lg:rounded-full lg:shadow-2xl-green-400 lg:mb-8`}
        >
            {children}
        </div>
    );
};

const PillIcon = ({ icon, size }) => {
    return (
        <div className='w-24 h-16 lg:w-24 lg:h-12 flex flex-shrink-0-a items-center justify-center rounded-full bg-gradient-to-tr from-green-300 via-green-400 to-green-500 shadow-2xl-green-500'>
            {/* icon with coloring and size*/}
            {/* <Icons iName={icon} iClasses='text-transparent bg-clip-text  bg-gradient-to-tr from-gray-900 to-blue-400 mix-blend-multiply' /> */}
            <Icons iName={icon} iClasses='text-gray-500 mix-blend-multiply' />
        </div>
    );
};

const PillLogo = ({ noshadow }) => {
    return (
        <div
            className={`w-8 h-11 flex flex-shrink-0 items-center justify-center rounded-full bg-gradient-to-tr from-green-300 via-green-400 to-green-500 ${
                noshadow ? '' : 'shadow-2xl-green-500'
            }`}
        >
            <KnIcons icon='KnLogo2' size='22' className='text-gray-600 mix-blend-multiply' />
        </div>
    );
};

const PillTitle = ({ title }) => {
    return (
        <div className='leading-8 whitespace-wrap max-w-64 md:max-w-128 text-2xl font-bold  lg:text-2xl pt-6 lg:py-0 lg:px-8 bg-clip-text text-transparent bg-gradient-to-tr from-black to-green-400'>
            {title}
        </div>
    );
};

export { PillIcon, PillTitle, PillContainer, PillLogo };
