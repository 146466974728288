import store from 'store';
import { nanoid } from 'nanoid';
import { getOverallCompletion } from 'helpers/menuHelpers';

export const buildShortContent = () => {
    const {
        experienceDetails,
        experienceDetails: { experience }
        //   auth: { user },
    } = store.getState();
    const completionStatus = getOverallCompletion(experience, experienceDetails).total;

    return {
        completionStatus,
        title: experience?.overview_intro?.title || '',
        featured_image: experience.featured_image,
        recommended_for: experience.recommended_for,
        destination: experience.destination.locations.map((group) => group[0].countryCode),
        days: experience.days
    };
};

export const defaultShortExperience = () => {
    return {
        completionStatus: 0,
        title: '',
        featured_image: '',
        recommended_for: '',
        destination: [],
        days: 1
    };
};
export const defaultMarketing = () => {
    return {
        gallery: [],
        intro: {
            title: '',
            subtitle: '',
            desc: '',
            objId: {
                section: 'marketing_intro_section',
                title: 'marketing_intro_title',
                subtitle: 'marketing_intro_subtitle',
                description: 'marketing_intro_description',
                gallery: 'marketing_intro_gallery',
                tiplist: 'marketing_intro_tiplist'
            }
        },
        whatToDo: {
            title: '',
            subtitle: '',
            desc: '',
            tips: [], //*------
            gallery: [],
            objId: {
                section: 'marketing_whatToDo_section',
                title: 'marketing_whatToDo_title',
                subtitle: 'marketing_whatToDo_subtitle',
                description: 'marketing_whatToDo_description',
                gallery: 'marketing_whatToDo_gallery',
                tiplist: 'marketing_whatToDo_tiplist'
            }
        },
        whereToStay: {
            title: '',
            subtitle: '',
            desc: '',
            tips: [], //*----
            gallery: [],
            objId: {
                section: 'marketing_whereToStay_section',
                title: 'marketing_whereToStay_title',
                subtitle: 'marketing_whereToStay_subtitle',
                description: 'marketing_whereToStay_description',
                gallery: 'marketing_whereToStay_gallery',
                tiplist: 'marketing_whereToStay_tiplist'
            }
        },
        whatsIncluded: {
            includes: [], //  { title: '', desc: '' }
            excluded: [],
            desc: '', //*----
            tips: [], //*----
            gallery: [], //*---
            objId: {
                section: 'marketing_whatsIncluded_section',
                title: 'marketing_whatsIncluded_title',
                subtitle: 'marketing_whatsIncluded_subtitle',
                description: 'marketing_whatsIncluded_description',
                gallery: 'marketing_whatsIncluded_gallery',
                tiplist: 'marketing_whatsIncluded_tiplist',
                includedlist: 'marketing_whatsIncluded_includedlist'
            }
        },
        policies: {
            objId: {
                section: 'marketing_policy_section',
                tiplist: 'marketing_policy_tiplist'
            },
            refund: {
                description: '',
                objId: 'marketing_policy_refund'
            },
            cancellation: {
                description: '',
                objId: 'marketing_policy_cancellation'
            }
        },
        thankYou: {
            title: '',
            subtitle: '',
            desc: '',
            tips: [], //*----
            gallery: [],
            objId: {
                section: 'marketing_thankYou_section',
                title: 'marketing_thankYou_title',
                subtitle: 'marketing_thankYou_subtitle',
                description: 'marketing_thankYou_description',
                gallery: 'marketing_thankYou_gallery',
                tiplist: 'marketing_thankYou_tiplist'
            }
        }
    };
};

export const defaultExperience = (type) => {
    return {
        recommended_for: '',
        days: 1,
        featured_image: '',
        overview_intro: {
            overview_featured_image: {
                type: '',
                data: ''
            },
            type: null,
            vidCode: null,
            title: '',
            description_html: '',
            objId: {
                section: 'overview_intro_section',
                featured: 'overview_intro_featured',
                image: 'overview_intro_image',
                title: 'overview_intro_title',
                description: 'overview_intro_description'
            }
        },
        budget: {
            description_html: '',
            tips_html: [],
            isVisible: type === 'digital' ? true : false,
            objId: {
                section: 'overview_budget_section',
                tiplist: 'overview_budget_tiplist',
                vendorlist: 'overview_budget_vendorlist',
                description: 'overview_budget_description',
                range: 'overview_budget_range',
                gallery: 'overview_budget_gallery'
            }
        },
        best_time_to_go: {
            description_html: '',
            time_range: [121, 242, true],
            tips_html: [],
            isVisible: type === 'digital' ? true : false,
            objId: {
                section: 'overview_best_time_to_go_section',
                tiplist: 'overview_best_time_to_go_tiplist',
                vendorlist: 'overview_best_time_to_go_vendorlist',
                description: 'overview_best_time_to_go_description',
                range: 'overview_best_time_to_go_range',
                gallery: 'overview_best_time_to_go_gallery'
            }
        },
        destination: {
            description_html: '',
            tips_html: [],
            locations: [],
            isVisible: true,
            objId: {
                section: 'overview_destination_section',
                tiplist: 'overview_destination_tiplist',
                vendorlist: 'overview_destination_vendorlist',
                destinationlist: 'overview_destination_destinationlist',
                description: 'overview_destination_description',
                gallery: 'overview_destination_gallery'
            }
        },

        accommodation: {
            locations: [],
            circles: [],
            description_html: '',
            tips_html: [],
            images: [],
            isVisible: type === 'digital' ? true : false,
            objId: {
                section: 'overview_accommodation_section',
                tiplist: 'overview_accommodation_tiplist',
                vendorlist: 'overview_accommodation_vendorlist',
                accommodationlist: 'overview_accommodation_locationlist',
                description: 'overview_accommodation_description',
                gallery: 'overview_accommodation_gallery'
            }
        },
        getting_there: {
            description_html: '',
            vendors: [],
            tips_html: [],
            entry: {
                label: '',
                url: ''
            },
            isVisible: type === 'digital' ? true : false,
            objId: {
                section: 'overview_getting_there_section',
                tiplist: 'overview_getting_there_tiplist',
                vendorlist: 'overview_getting_there_vendorlist',
                description: 'overview_getting_there_description',
                entry: 'overview_getting_there_entry',
                gallery: 'overview_getting_there_gallery'
            }
        },

        moving_around: [],

        local_currency: [],

        moving_around_visible: {
            isVisible: type === 'digital' ? false : false,
            objId: {
                section: 'overview_moving_around_section'
            }
        },

        local_currency_visible: {
            isVisible: type === 'digital' ? true : false,
            objId: {
                section: 'overview_currency_section'
            }
        },

        general_tips_visible: {
            isVisible: type === 'digital' ? true : false,
            objId: {
                section: 'overview_general_tips_section',
                tiplist: 'overview_general_tips_tiplist',
                vendorlist: 'overview_general_tips_vendorlist',
                description: 'overview_general_tips_description',
                gallery: 'overview_general_tips_gallery'
            }
        },
        tips_html: [],

        itinerary: {
            trip_days: [
                {
                    day_intro: {
                        day_featured_image: '',
                        title: '',
                        description_html: '',
                        objId: {
                            section: `day_intro_section_${nanoid()}`,
                            image: `day_intro_image_${nanoid()}`,
                            title: `day_intro_title_${nanoid()}`,
                            description: `day_intro_description_${nanoid()}`
                        }
                    },
                    upon_arrival: [],
                    what_you_need: {
                        description_html: '',
                        checklist: [],
                        isVisible: type === 'digital' ? true : false,
                        objId: {
                            section: `day_what_you_need_section_${nanoid()}`,
                            description: `day_what_you_need_description_${nanoid()}`,
                            checklist: `day_what_you_need_checklist_${nanoid()}`,
                            tiplist: `day_what_you_need_tiplist_${nanoid()}`,
                            vendorlist: `day_what_you_need_vendorlist_${nanoid()}`,
                            gallery: `day_what_you_need_gallery_${nanoid()}`
                        }
                    },
                    moving_around: [],
                    activities: []
                }
            ]
        },

        status: 'initial',

        title: '',

        inPublished: false,

        completion: 0,

        isEdu: false
    };
};

export const defaultProfile = {
    first: '',
    last: '',
    displayname: null,
    country: 'AF',
    city: null,
    bio: null,
    avatar: null,
    settings: {},
    social: null,
    currency: null
};
