import React, { useRef, useState, useEffect } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import translations from 'constants/translations';

import { handleRowReverse, randomItem } from 'helpers/FEutils';
import { ReactComponent as KLogoIcon } from 'components/svg/kn_logoicon.svg';
import { ReactComponent as KLogoText } from 'components/svg/kn_logotext.svg';

import ButtonLoad from 'components/blocks/ButtonLoad';
import ButtonAuth from 'components/blocks/ButtonAuth';
import { animateIn } from 'helpers/FEutils';
import { register, clearLoginErrors } from 'store/actions/auth';
import { createDataAdmin, deleteDataAdmin } from 'fetch/user';
import FormIkInput from 'components/forms/FormIkInput';
import { regexPassword, regexUsername } from 'components/utility/regexPatterns';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ui from 'translations/componentNames.json';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import LoadingPageNp from 'components/utility/LoadingPageNp';
import MadeWithLove from 'components/blocks/MadeWithLove';
import Checkbox from 'components/blocks/Checkbox';
import Images from 'constants/imagesTmp';
import * as types from 'store/actions/types';
import { defaultProfile } from 'constants/experiencesConsts';
import LayoutNavbar from 'components/layouts/LayoutNavbar';

const targetElement = document.querySelector('#root');

const AuthRegister = ({ auth, globalState: { lang }, register, clearLoginErrors, ...props }) => {
    const splashImage = useRef(randomItem(Images).url);
    const handleSubmit = (values, actions) => {
        props.createDataAdmin(types.CREATE_PROFILE, 'profiles', defaultProfile).then((res) => {
            if (!res.error) {
                register({
                    ...values,
                    username: values.username.toLowerCase(),
                    email: values.email.toLowerCase(),
                    profile: res.value.data._id
                }).then((regResolution) => {
                    if (regResolution?.response?.data?.error) {
                        props.deleteDataAdmin(types.DELETE_PROFILE, 'profiles', res.value.data._id);
                    }
                });
            }
        });
        actions.setSubmitting(false);

        // trackPromise(this.postAxiosData(values, actions, this.props.type));
    };
    const [termsChecked, setTermsChecked] = useState(false);
    useEffect(() => {
        clearLoginErrors();
        animateIn('.kn-slide');
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const rtl = !!translations[lang].rtl;

    if (auth.isAuthenticated == null) {
        return <LoadingPageNp />;
    }

    if (auth.isAuthenticated) {
        if (auth.isProfile) return <Navigate to='/c/experience/all' />;
        return <Navigate to='/c/accounts/profile' />;
    }

    return (
        auth.isAuthenticated === false && (
            <>
                {/* <LayoutNavbar /> */}
                <div className='fixed inset-0 hidden md:block lg:hidden'>
                    <img
                        alt=''
                        data-blink-src={splashImage.current}
                        className=' object-cover w-full h-96 md:h-screen-1/2 lg:h-full lg:rounded-3xl lg:overflow-hidden lg:shadow-3xl '
                    />
                </div>
                <div className='lg:relative kn-slide h-screen  overflow-y-scroll no-scrollbar bg-white md:bg-transparent'>
                    <div
                        style={{ zIndex: -50 }}
                        className='hidden lg:block px-0 py-0 fixed w-full lg:w-7/12 lg:min-h-screen inset-0 lg:px-16 xl:px-32 2xl:px-48 lg:py-32'
                    >
                        <img
                            alt=''
                            data-blink-src={splashImage.current}
                            className=' object-cover w-full h-80 lg:h-full lg:rounded-3xl lg:overflow-hidden lg:shadow-3xl '
                        />
                    </div>

                    <div className='w-full lg:w-5/12 ml-auto md:rounded-t-3xl lg:rounded-none bg-white  shadow-2xl-green-600-rev lg:shadow-2xl-green-600 mt-0 md:mt-96 lg:mt-0 '>
                        <div className={`flex flex-col px-12 lg:px-16 xl:px-24 2xl:px-32 py-16 lg:h-screen  h-full md:pb-64`}>
                            <div className={`flex items-center gap-3 ${handleRowReverse(rtl).flex}`}>
                                <KLogoIcon />
                                <KLogoText />
                                
                            </div>
                            <div className='flex'>
                                <div className='flex-shrink-0 text-transparent bg-clip-text bg-gradient-to-tl from-gray-900 via-blue-500 to-green-400 text-2xl font-bold tracking-tighter mt-10 '>
                                    Sign up as a kreator
                                </div>
                            </div>
                            <div>
                                <Formik
                                    initialValues={{
                                        username: '',
                                        email: '',
                                        password: ''
                                    }}
                                    validationSchema={Yup.object({
                                        username: Yup.string().matches(regexUsername, ui.validation.username[lang]).required(ui.validation.required[lang]),

                                        email: Yup.string().email(ui.validation.email[lang]).required(ui.validation.required[lang]),

                                        password: Yup.string()
                                            .min(8, ui.validation.passwordSyntax[lang])
                                            .matches(regexPassword, ui.validation.passwordSyntax[lang])
                                            .required(ui.validation.required[lang])
                                    })}
                                    onSubmit={handleSubmit}
                                >
                                    {(props) => (
                                        <Form id='signupForm'>
                                            <div className='flex flex-col md:flex-row lg:flex-col mt-6 gap-12 lg:gap-0'>
                                                <div className='md:w-1/2 lg:w-full flex-1 flex flex-col'>
                                                    <FormIkInput name='username' type='text' placeholder={'Username'} className='mb-4' autoComplete='off' />

                                                    <FormIkInput
                                                        name='email'
                                                        type='text'
                                                        placeholder={ui.forms.email[lang]}
                                                        autoComplete='off'
                                                        className='mb-4'
                                                    />

                                                    <FormIkInput name='password' type='password' placeholder={ui.forms.password[lang]} autoComplete='off' />
                                                    <Checkbox name='terms' isChecked={termsChecked} setIsChecked={setTermsChecked} />
                                                    <ButtonLoad
                                                        // handleClick={handleClick}
                                                        isLoading={auth.loading}
                                                        label='Sign up'
                                                        width='w-full'
                                                        // handleClick={handleSubmit}
                                                        form='signupForm'
                                                        type='submit'
                                                        disabled={!termsChecked}
                                                    />
                                                </div>
                                                <div className='flex-1 flex flex-col -mt-12 lg:mt-0'>
                                                    <div className='flex items-center justify-items py-10'>
                                                        <span className='flex-1 border-b border-gray-300 mr-3' />
                                                        <span className='flex-shrink-0 text-gray-400 text-sm'>or</span>
                                                        <span className='flex-1 border-b border-gray-300 ml-3' />
                                                    </div>
                                                    {process.env.REACT_APP_OAUTH_GOOGLE_VISIBLE === 'true' && (
                                                        <ButtonAuth label={'Continue with Google'} icon='google' />
                                                    )}
                                                    {process.env.REACT_APP_OAUTH_INSTAGRAM_VISIBLE === 'true' && (
                                                        <ButtonAuth label={'Continue with Instagram'} icon='instagram' />
                                                    )}
                                                    {process.env.REACT_APP_OAUTH_TWITTER_VISIBLE === 'true' && (
                                                        <ButtonAuth label={'Continue with Twitter'} icon='twitter' />
                                                    )}

                                                    <div className='flex justify-center items-center mt-0 lg:-mt-4 xl:mt-8 text-sm gap-2'>
                                                        <span>Already have an account?</span>
                                                        <Link className='text-base text-green-400 font-medium' to='/accounts/login'>
                                                            Sign in
                                                        </Link>
                                                    </div>
                                                    <MadeWithLove />
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth
});

export default connect(mapStateToProps, {
    createDataAdmin,
    deleteDataAdmin,
    register,
    clearLoginErrors
})(AuthRegister);
