/* eslint-disable react-hooks/exhaustive-deps */
import { useOnlineStatus } from 'helpers/useOnlineStatus';
import { useEffect, useRef } from 'react';

function useAutosave(updateObjects, ready, reset, updateFunc, delayTime) {
    const timeoutInterval = useRef(null);

    const isOnline = useOnlineStatus();

    useEffect(() => {
        if (ready && isOnline) {
            clearInterval(timeoutInterval.current);
            timeoutInterval.current = setTimeout(() => {
                updateFunc();
            }, delayTime);
        }
        return () => {
            clearInterval(timeoutInterval.current);
        };
    }, [...Object.values(updateObjects), isOnline]);

    useEffect(() => {
        if (reset || !isOnline) {
            clearInterval(timeoutInterval.current);
        }
    }, [reset, isOnline]);

    useEffect(() => {
        // console.log('reset', reset);
        // console.log('ready', ready);
    }, [isOnline]);
}

export default useAutosave;
