const GenericBtn = ({
    handleActionBtn,
    className = '',
    padding = 'py-2 px-6',
    bgColor = 'bg-green-100 hover:bg-gray-900',
    textColor = 'text-green-900  hover:text-white',
    rounded = 'rounded-full',
    textSize = 'text-sm',
    children,
    params = [],
    style = {},
    disabled = false,
    disabledClass = 'bg-gray-200 text-gray-400'
}) => {
    const handleClick = () => {
        if (!disabled) handleActionBtn(...params);
    };

    return (
        <button
            className={`focus:outline-none outline-none ${padding} ${rounded} ${textSize} ${className} ${
                disabled ? disabledClass : `${bgColor} ${textColor} `
            }`}
            onClick={handleClick}
            style={style}
        >
            {children}
        </button>
    );
};

export default GenericBtn;
