import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as types from 'store/actions/types';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic';
import CardXl from 'components/blocks/CardXl';
import TipList from 'components/blocks/TipList';
import GenericDescription from 'components/blocks/GenericDescription';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { PillContainer, PillIcon, PillTitle } from 'components/blocks/Pills';
import BestTimeToGoRanges from 'components/blocks/BestTimeToGoRanges';
import { Section__Title } from 'components/blocks/Sections';

import CollapseButton from 'components/blocks/CollapseButton';
import classNames from '../../../node_modules/classnames/index';

function SectionBestTimeToGo({
    experienceDetailsAction,
    actionType,
    experienceDetails: {
        experience: {
            best_time_to_go: { description_html: descriptionHtml, tips_html: tipsHtml, isVisible: isSectionVisible = true, objId }
        }
    },

    globalState: { lang, edit, isPrintMode, isFeedbackMode }
}) {
    const rtl = !!translations[lang].rtl;
    const typeText = 'Tmp title';
    const [isMinimized, setIsMinimized] = useState(false);

    const handleVisible = () => {
        experienceDetailsAction({
            type: types.UPDATE_SECTION_VISIBILITY,
            val: !isSectionVisible,
            objName: 'best_time_to_go'
        });
    };
    return (
        <>
            {(edit || isSectionVisible) && (
                // <div className={`w-full ${edit ? 'touch:-mt-16' : 'touch:-mt-28'} touch:py-10`}>
                <div className={classNames('w-full touch:py-10', edit ? 'touch:-mt-8 ' : 'touch:-mt-28', 'mt-8')}>
                    <Section__Title
                        edit={edit}
                        title={'When to go'}
                        hasData
                        hintObj={uiStruct.itinerary.what_you_need.uiPreview.hint}
                        handleVisible={handleVisible}
                        isVisible={isSectionVisible}
                        className={`${isSectionVisible ? '-mb-4 lg:-mb-8' : 'mb-4 lg:mb-0'}`}
                    />
                    {isSectionVisible && (
                        <div className={`${isMinimized ? '-mt-4 xl:-mt-8 mb-16' : ''}`}>
                            <CardXl
                                isFeedbackMode={isFeedbackMode}
                                objId={objId?.section}
                                edit={edit}
                                isPrintMode={isPrintMode}
                                rtl={rtl}
                                hidden={isMinimized}
                                isVisible={isSectionVisible}
                                handleVisible={handleVisible}
                                handleMinimize={() => setIsMinimized(!isMinimized)}
                                hideEye
                            >
                                {/* <div
                                className={`z-200 w-max mx-auto  ${
                                    isMinimized ? 'mt-2 xl:mt-8' : ''
                                }`}
                            >
                                <PillContainer rtl={rtl}>
                                    <PillIcon icon='CALENDAR' />
                                    <PillTitle title={'When To Go'} />
                                </PillContainer>
                            </div> */}

                                <div className={`${isMinimized ? 'hidden' : ''}`}>
                                    <div className='min-h-10'>
                                        {(edit || descriptionHtml) && (
                                            <GenericDescription
                                                actionType={actionType}
                                                dayIndex={null}
                                                descriptionHtml={descriptionHtml}
                                                defaultHtml={translations[lang][uiStruct.overview.when_to_go.uiPreview.descriptionEmpty]}
                                                objId={objId?.description}
                                                title='When to go'
                                                subtitle='Description'
                                            />
                                        )}
                                    </div>
                                    <BestTimeToGoRanges />
                                    <TipList
                                        // max
                                        objId={objId?.tiplist}
                                        actionType={actionType}
                                        tips={tipsHtml}
                                        offset='0'
                                        isVisible={isSectionVisible}
                                    />
                                </div>
                            </CardXl>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            experienceDetailsAction
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionBestTimeToGo);
