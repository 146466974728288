/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CardXlContent } from 'components/blocks/CardXl';
import DescriptionTitleModal from 'components/modals/DescriptionTitleModal';
import * as types from 'store/actions/types';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic.js';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { handleRowReverse } from 'helpers/FEutils';
import FeedbackBox from './FeedbackBox';

function GenericTitle({
    objId,
    index,
    canBeEmpty = false,
    tour,
    globalState: { lang, edit, isFeedbackMode },
    titleTxt,
    defaultTxt,
    actionType,
    actionPrefix,
    dayIndex,
    experienceDetailsAction,
    icon = uiStruct.itinerary.intro.uiEdit.modals.title.icon,
    hintObj = uiStruct.itinerary.intro.uiEdit.modals.title.hint,
    placeholder = null,
    nohover = null,
    noStyling,
    title,
    subtitle
}) {
    const rtl = !!translations[lang].rtl;

    const [descModalState, setDescModalState] = useState(false);
    const handleEdit = () => {
        setDescModalState(true);
    };

    const updateDesc = (titleTxt) => {
        experienceDetailsAction({
            type: `${actionPrefix || types.UPDATE_TITLE}${actionType}`,
            dayIndex,
            index,
            titleTxt
        });
    };

    return (
        <>
            {noStyling ? (
                edit ? (
                    <button
                        onClick={handleEdit}
                        className='hover:bg-green-100 px-4 py-1 rounded-lg focus:outline-none outline-none'
                    >
                        {titleTxt || 'Add Exciting Title'}
                    </button>
                ) : (
                    <span>{titleTxt || 'Add Exciting Title'}</span>
                )
            ) : (
                <CardXlContent
                    tour={tour}
                    nohover={nohover}
                    rtl={rtl}
                    edit={edit}
                    isFeedbackMode={isFeedbackMode}
                    handleEdit={handleEdit}
                    className={`${edit ? '' : '-mt-8 -mb-6'}`}
                >
                    <div className='xl:px-4'>
                        <div
                            className={`${handleRowReverse(rtl).rtl} ${
                                titleTxt ? '' : 'italic text-sm text-gray-400'
                            } text-transparent bg-clip-text bg-gradient-to-tr from-gray-900 via-blue-500 to-green-400 ${
                                edit
                                    ? 'text-2xl lg:text-4xl'
                                    : 'text-3xl lg:text-4xl xl:text-5xl leading-tight '
                            }  font-bold l py-2 `}
                        >
                            {titleTxt || defaultTxt}
                        </div>
                    </div>
                    <FeedbackBox objId={objId} label='Title' />
                </CardXlContent>
            )}
            {descModalState && (
                <DescriptionTitleModal
                    txtOnly
                    canBeEmpty={canBeEmpty}
                    setModalIsOpen={setDescModalState}
                    updateObj={titleTxt}
                    update={true}
                    handleActionBtn={updateDesc}
                    icon={icon}
                    hintObj={hintObj}
                    placeholder={placeholder}
                    title={title}
                    subtitle={subtitle}
                />
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            experienceDetailsAction
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(GenericTitle);
