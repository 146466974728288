/* eslint-disable react/jsx-pascal-case */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CardXl from 'components/blocks/CardXl';
import { CardXlDragHandleMain } from 'components/blocks/CardXlDrag';
import TipList from 'components/blocks/TipList';
import { PillContainer, PillIcon, PillTitle } from 'components/blocks/Pills';
import { _ACTIVITIES, _ACTIVITY } from 'store/actions/types';

import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { minsToTime, minsToDuration } from 'helpers/time';
import GenericDescription from 'components/blocks/GenericDescription';

import Icons from 'components/blocks/Icons';
import KnIcons from 'constants/KnIcons';
import { handleRowReverse } from 'helpers/FEutils';
import Slider from 'components/slider/Slider';
import { updateGalleryActivity, updateCaptionSlideActivity } from 'store/actions/experienceDetails/activities';
import GoogleMapLink from 'components/specialty/GoogleMapLink';
import GalleryUpdater from 'components/specialty/GalleryUpdater';
import VendorList from 'components/blocks/VendorList';
import { currenciesObject } from 'constants/currenciesObject';
import useXchangeRate from 'helpers/useXchangeRate';
import { formatPrice } from 'helpers/LocaleHelper';
import { MenuRefsHolder } from 'helpers/MenuRefsHolder';
import CollapseButton from 'components/blocks/CollapseButton';
import FeedbackBox from 'components/blocks/FeedbackBox';
import classNames from 'classnames';
import SliderNew from 'components/slider/SliderNew';
const currencyOptions = {
    rounding: 1
};

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        partialVisibilityGutter: 40 // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    }
};

function SectionActivitySingle({
    type,
    totalActivities,
    index,
    dayIndex,
    data,
    provided = {},
    removeModal = null,
    globalState: { lang, edit, isPrintMode, isFeedbackMode },
    handleUpdate,
    updateGalleryActivity,
    updateCaptionSlideActivity,
    openMapModal,
    isMarketingMode,
    auth: {
        user: {
            profile: { currency: preferredCurrency = 'USD' }
        }
    }
}) {
    const rtl = !!translations[lang].rtl;
    const handleEditDetails = () => {
        handleUpdate(index, false);
    };

    const handleEditType = () => {
        handleUpdate(index, true);
    };

    const handleOpenMapModal = () => {
        openMapModal(index);
    };

    const {
        objId,
        best_time_to_go: { max: goMax, min: goMin, isVisible: bestTimeToGoVisible },
        duration: { max: durMax, min: durMin, isVisible: durationVisible },
        pricing: { free, type: priceType, max: pMax, min: pMin, isVisible: pricingVisible },
        reservation: { type: reservationType, isVisible: reservationVisible },
        vendors,
        getting_there: { type: gettingThereType, duration },
        images
    } = data;

    const activityCurrency = data.pricing.currency || 'USD';

    const { values: budgetRange, currency } = useXchangeRate([pMin, pMax], activityCurrency, preferredCurrency);

    const {
        UI_DATE_RANGE_LABEL: bestTimeToGoLabel,
        UI_TIME_DURATION_LABEL: durationLabel,
        UI_PRICING_RANGE_LABEL: pricingLabel,
        UI_RESERVATION_LABEL: reservationLabel,
        UI_GETTING_THERE_LABEL: gettingThereLabel
    } = translations[lang];

    const handleUpdateCaption = (slideIndex, caption) => {
        updateCaptionSlideActivity(dayIndex, index, slideIndex, caption);
    };

    const handleUpdateGallery = (galleryArray) => {
        updateGalleryActivity(dayIndex, index, galleryArray);
    };

    const [isMinimized, setIsMinimized] = useState(false);

    return (
        <>
            <li
                className={`${isMinimized ? '-mt-4 xl:-mt-8 last:mb-16' : ''}`}
                id={`dayActivity${index}`}
                ref={(el) => {
                    MenuRefsHolder.setRef(el);
                }}
            >
                <CardXl
                    isFeedbackMode={isFeedbackMode}
                    objId={objId?.activity}
                    edit={edit}
                    isPrintMode={isPrintMode}
                    isMarketingMode={isMarketingMode}
                    handleDelete={() => {
                        removeModal(index);
                    }}
                    showDnd={false}
                    rtl={rtl}
                    hidden={isMinimized}
                    handleMinimize={() => setIsMinimized(!isMinimized)}
                >
                    {(type.toLowerCase() !== 'guided' || (type.toLowerCase() === 'guided' && totalActivities > 1)) && (
                        <div onClick={edit && !isFeedbackMode ? handleEditType : null} className={`z-200 w-max mx-auto ${isMinimized ? 'mt-2 xl:mt-8' : ''}`}>
                            <PillContainer rtl={rtl} edit={edit} isFeedbackMode={isFeedbackMode}>
                                <PillIcon icon={data.type} />

                                <PillTitle title={data.title} />
                                <FeedbackBox objId={data?.objId?.title} label='Title' />
                            </PillContainer>
                        </div>
                    )}

                    <div className={edit ? 'hidden' : 'hidden'}>
                        <CardXlDragHandleMain dragProps={provided.dragHandleProps} rtl={rtl} />
                    </div>

                    {/* Body to hide */}
                    <div className={`${isMinimized ? 'hidden' : ''}`}>
                        <GenericDescription
                            // canBeEmpty
                            actionType={_ACTIVITIES}
                            dayIndex={dayIndex}
                            index={index}
                            descriptionHtml={data.description_html}
                            defaultHtml={translations[lang][uiStruct.itinerary.activities.uiPreview.descriptionEmpty]}
                            hintObj={uiStruct.itinerary.activities.uiEdit.modals.description.hint}
                            icon={uiStruct.itinerary.activities.uiEdit.modals.description.icon}
                            objId={data?.objId?.description}
                            title='Activity'
                            subtitle='Description'
                        />
                        <TipList
                            // max
                            objId={`tiplist_${data?.objId?.activity}`}
                            actionType={_ACTIVITIES}
                            tips={data.tips_html}
                            dayIndex={dayIndex}
                            index={index}
                            hintObj={uiStruct.itinerary.activities.uiEdit.modals.tip.hint}
                            icon={uiStruct.itinerary.activities.uiEdit.modals.tip.icon}
                            offset='20'
                        />

                        <GoogleMapLink
                            rtl={rtl}
                            mapData={data.location}
                            openMapModal={handleOpenMapModal}
                            index={index}
                            dayIndex={dayIndex}
                            edit={edit}
                            isFeedbackMode={isFeedbackMode}
                            placeholder={translations[lang][uiStruct.itinerary.activities.uiPreview.addressEmpty]}
                            objId={data?.objId?.location}
                        />

                        <div className='relative'>
                            <FeedbackBox
                                inset='-inset-y-4 -inset-x-8'
                                dot='top-1/2 -translate-y-1/2 -right-20'
                                root
                                objId={data?.objId?.details}
                                label='Activity Details List'
                            />
                            {edit && !isFeedbackMode && (
                                <div className='flex justify-center w-full pt-8 mb-4'>
                                    <CollapseButton
                                        alwaysOn
                                        labelHover={`${
                                            !bestTimeToGoVisible && !durationVisible && !pricingVisible && !reservationVisible ? 'Add Details' : 'Edit Details'
                                        } `}
                                        handleClick={handleEditDetails}
                                        icon='ri-list-unordered text-2xl -ml-0.5a'
                                        size='10'
                                        textSize='text-xs'
                                        sizeHover='w-36'
                                        offsetCenter='3'
                                        padding='px-4'
                                        btnColor='bg-green-400 hover:bg-gray-900 text-green-900'
                                    />
                                </div>
                            )}

                            {(bestTimeToGoVisible || durationVisible || pricingVisible || reservationVisible) && (
                                <div
                                    className='mb-8'
                                    // rtl={rtl}
                                    // edit={edit}
                                    // handleEdit={handleEditDetails}
                                    // className='mt-2'
                                    // color='bg-transparent'
                                >
                                    <div className={`flex flex-col py-4 gap-4 relative w-full ${handleRowReverse(rtl).rtl}`}>
                                        {bestTimeToGoVisible && (
                                            <SectionActivitySingle__DetailItem
                                                icon='KnBestTime'
                                                label={bestTimeToGoLabel}
                                                min={minsToTime(goMin)}
                                                max={minsToTime(goMax)}
                                                rtl={rtl}
                                                objId={data?.objId?.best_time_to_go}
                                            />
                                        )}

                                        {durationVisible && (
                                            <SectionActivitySingle__DetailItem
                                                icon='KnDuration'
                                                label={durationLabel}
                                                min={minsToDuration(durMin)}
                                                max={minsToDuration(durMax, true)}
                                                rtl={rtl}
                                                objId={data?.objId?.duration}
                                            />
                                        )}

                                        {pricingVisible && (
                                            <SectionActivitySingle__DetailItem
                                                icon='KnWallet'
                                                label={pricingLabel}
                                                value={free ? 'Free Activity' : null}
                                                min={free ? null : formatPrice(pMin, activityCurrency, window.navigator.language, currencyOptions)}
                                                max={free ? null : formatPrice(pMax, activityCurrency, window.navigator.language, currencyOptions)}
                                                rtl={rtl}
                                                type='price'
                                                objId={data?.objId?.pricing}
                                            >
                                                {free ? null : (
                                                    <>
                                                        <div className='ml-2 flex flex-col items-center gap-2'>
                                                            <div className='flex items-center gap-2'>
                                                                <span className='font-semibold'>{currenciesObject[activityCurrency].symbol}</span>
                                                                <span className='text-xs'>({currenciesObject[activityCurrency].name_plural})</span>
                                                            </div>
                                                        </div>
                                                        <div className='ml-2 flex flex-nowrap text-xs items-center gap-2'>
                                                            <span>~</span>
                                                            <span>
                                                                {formatPrice(budgetRange[0], preferredCurrency, window.navigator.language, currencyOptions)}
                                                                {budgetRange[1] !== budgetRange[0] && (
                                                                    <>
                                                                        {`-${formatPrice(
                                                                            budgetRange[1],
                                                                            preferredCurrency,
                                                                            window.navigator.language,
                                                                            currencyOptions
                                                                        )}`}
                                                                    </>
                                                                )}
                                                            </span>
                                                            <span className='font-semibold'>{currenciesObject[preferredCurrency].symbol}</span>
                                                            <span className='text-xs'>({currenciesObject[preferredCurrency].name_plural})</span>
                                                        </div>
                                                    </>
                                                )}
                                            </SectionActivitySingle__DetailItem>
                                        )}
                                        {reservationVisible && reservationType != 'UI_RESERVATION_SELECTION_NO' && (
                                            <SectionActivitySingle__DetailItem
                                                icon='KnReservation'
                                                label={reservationLabel}
                                                value={`${translations[lang][reservationType]}`}
                                                rtl={rtl}
                                                objId={data?.objId?.reservation}
                                            />
                                        )}
                                        {/* <SectionActivitySingle__DetailItem
                                        icon='KnGettingThere'
                                        label={gettingThereLabel}
                                        value={translations[lang][gettingThereType]}
                                        prefix='We recommend'
                                        rtl={rtl}
                                    /> */}
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* <div className='relative'>
                            <GenericGallery
                                actionType={_ACTIVITY}
                                images={images}
                                lang={lang}
                                edit={edit}
                                slider={true}
                                max={20}
                                className=''
                                modalTitle='Activity'
                            >
                                <div className='absolute left-1/2 transform -translate-x-1/2 top-0'>
                                    <CollapseButton
                                        labelHover='Manage Gallery'
                                        handleClick={() => console.log('test')}
                                        icon='ri-image-add-line text-2xl -ml-0.5'
                                        size='10'
                                        textSize='text-xs'
                                        sizeHover='w-40'
                                        offsetCenter='2'
                                        btnColor='bg-green-400 hover:bg-gray-900 text-green-900'
                                    />
                                </div>
                            </GenericGallery>
                        </div> */}

                        <div className='relative'>
                            <FeedbackBox
                                objId={objId?.gallery}
                                posY='bottom-full'
                                dot='top-8 right-8'
                                // boxHeight='h-4/5'
                                zLevel={'z-200'}
                                zLevelPrint='z-200'
                                zLevelOff=''
                                label='Gallery'
                                tmp='ring-8'
                            />
                            {edit && <GalleryUpdater handleUpdateGallery={handleUpdateGallery} imageList={images} />}

                            {images && images.length > 0 && (
                                <>
                                    <SliderNew handleUpdateCaption={handleUpdateCaption} edit={edit} slides={images} objId={objId} />
                                </>
                            )}
                        </div>

                        <VendorList
                            objId={`vendorlist_${data?.objId?.activity}`}
                            actionType={_ACTIVITIES}
                            modalTitle='Activity'
                            modalSubTitle='Add Extra Info'
                            vendors={vendors}
                            dayIndex={dayIndex}
                            index={index}
                            btnLabel='More'
                            labelTitle='What kind of extra information would you like to add?'
                            labelVendors={'List them out below. Click on + to add more'}
                            labelVendorsPlaceholderName={'Ex: Burger King - Reservation'}
                            labelVendorsPlaceholderUrl={'https://www.burgerking.com/order'}
                            labelVendorsPlaceholderTitle={'hello'}
                            btnOffset='16'
                        />
                    </div>
                </CardXl>
            </li>
        </>
    );
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateGalleryActivity,
            updateCaptionSlideActivity
        },
        dispatch
    );
}

const SectionActivitySingle__DetailItem = ({ objId, icon, label, value, min, max, prefix, suffix, children, rtl, ref }) => {
    return (
        <div className='relative'>
            <div
                // ref={ref}
                className={` bg-kn-primary-75 flex flex-col md:flex-row text-sm md:items-center md:gap-4 rounded-2xl overflow-hidden md:rounded-full p-2 `}
            >
                <div className={`flex bg-green-200 items-center py-2 rounded-xl md:rounded-full px-4 ${handleRowReverse(rtl).pr}-6`}>
                    <div className={`${handleRowReverse(rtl).mr}-4 text-kn-primary`}>
                        <KnIcons icon={icon} className='text-green-600 mix-blend-multiply' />
                    </div>

                    <div className={`flex-grow ${rtl ? 'text-sm' : 'text-sm lg:text-xs'}`}>{label}</div>
                </div>
                <div className='flex flex-row flex-wrap justify-center md:justify-start py-2 sm:py-0 items-center '>
                    {prefix && <span className='mr-2'>{prefix}</span>}
                    {max && (
                        <>
                            <span>{min || 0}</span>
                            {max !== min && (
                                <>
                                    <span className='px-2 text-kn-primary'>
                                        <Icons iName={handleRowReverse(rtl).arrowLong} size='xl' />
                                    </span>
                                    <span>{max}</span>
                                </>
                            )}
                        </>
                    )}
                    {value && <span>{value}</span>}
                    {suffix && <span className={`${handleRowReverse(rtl).ml}-2`}>{suffix}</span>}
                    {children}
                </div>
            </div>
            <FeedbackBox objId={objId} label='Activity Details Element' />
        </div>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth
});

export default connect(mapStateToProps, mapDispatchToProps)(SectionActivitySingle);
