export const createWhatYouNeed = (draft, action) => {
    const { dataObj, dayIndex } = action;

    draft.experience.itinerary.trip_days[dayIndex].what_you_need = dataObj;

    return draft;
};

export const updateDescriptionCheckList = (draft, action) => {
    const { descHtml, dayIndex } = action;

    draft.experience.itinerary.trip_days[
        dayIndex
    ].what_you_need.description_html = descHtml;

    return draft;
};

export const updateCheckList = (draft, action) => {
    const { dataObj, dayIndex } = action;

    draft.experience.itinerary.trip_days[dayIndex].what_you_need.checklist =
        dataObj;

    return draft;
};

export const deletWhatYouNeed = (draft, action) => {
    const { dayIndex } = action;

    draft.experience.itinerary.trip_days[dayIndex].what_you_need = {};

    return draft;
};
