/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LayoutOverview from 'components/layouts/LayoutOverview';
import LayoutMarketing from 'components/layouts/LayoutMarketing';
import LayoutDay from 'components/layouts/LayoutDay';
import LayoutLoading from 'components/layouts/LayoutLoading';
import { handleRowReverse } from 'helpers/FEutils';

import {
    toggleEdit,
    toggleToolTips,
    toggleTour,
    toggleSuperAdmin,
    toggleFeedbackMode,
    togglePrintMode,
    setIsOwnerMode,
    setIsAdminStaffMode,
    setIsPublishedViewMode,
    setIsEduContentMode
} from 'store/actions/globalState/master';
import {
    fetchExperienceSingle,
    fetchExperiencePublishedSingle
} from 'fetch/experiences';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic';
import translations from 'constants/translations';
import { useNavigate, Navigate, useParams } from 'react-router-dom';
import { MenuRefsHolder } from 'helpers/MenuRefsHolder';
import { countries } from 'constants/countries';
import { currenciesObject } from 'constants/currenciesObject';
import { countriesArray } from 'constants/countriesArray';
import LayoutMenuEdit from 'components/layouts/LayoutMenuEdit';
import { updateExperience, updateFeedback } from 'fetch/experiences';
import useAutosave from 'components/blocks/Autosave';
import { AxiosAbortService } from 'helpers/AxiosAbortService';
import {
    clearExperience,
    experienceHasUpdated
} from 'store/actions/experienceDetails/general';
import { getOverallCompletion } from 'helpers/menuHelpers';
import LayoutCommentsSidebar from 'components/layouts/LayoutCommentsSidebar';
import { useWindowSize } from 'helpers/useWindowSize';
import FeedbackList from 'components/blocks/FeedbackList';
import useStatuses from 'helpers/useStatuses';
import { isPublishedViewFunc, isAdminStaffFunc } from 'helpers/statusHelper';
import ErrorPage from 'components/pages/ErrorPage';

const PageExperienceSinglePrint = (props) => {
    const history = useNavigate();
    const [width, height] = useWindowSize();

    const {
        toggleEdit,
        toggleSuperAdmin,
        toggleFeedbackMode,
        togglePrintMode,
        toggleToolTips,
        toggleTour,
        setIsOwnerMode,
        setIsAdminStaffMode,
        setIsPublishedViewMode,
        setIsEduContentMode,
        globalState: {
            lang,
            edit,
            isSuperAdmin,
            isFeedbackMode,
            isPrintMode,
            tour,
            toolTips,
            status: { feedbackSaving },
            siteData: { loading: siteDataLoading }
        },
        // match: {
        //     params: { id }
        // },
        experienceDetails,
        experienceDetails: {
            pubId,
            id: expId,
            user: author,
            loading,
            experience,
            saving,
            updated,
            type,
            budget_min,
            budget_max,
            experience_status,
            content_marketing,
            places_lists,
            invalid
        },
        auth: {
            user: { role: userRole, _id: userId }
        },
        fetchExperienceSingle,
        fetchExperiencePublishedSingle,
        updateExperience,
        updateFeedback,
        clearExperience,
        experienceHasUpdated,
        location
    } = props;

    const {id} = useParams();

    // const isAdminStaff =
    //     userRole === process.env.REACT_APP_STRAPI_ADMIN_ROLE_ID ||
    //     userRole === process.env.REACT_APP_STRAPI_ADMIN_ROLE_ID;
    // const isPublishedView = location.pathname.includes(
    //     process.env.REACT_APP_BASENAME_EXPERIENCE_PUBLISHED
    // );

    const { isOwner, isEduContent } = useStatuses();
    const isPublishedView = isPublishedViewFunc();
    const isAdminStaff = isAdminStaffFunc(userRole);

    useEffect(() => {
        if (isPublishedView) setMenuIsOpen(false);
        else {
            if (width < 640) setMenuIsOpen(false);
            else setMenuIsOpen(true);
        }
    }, [width, isPublishedView]);

    useEffect(() => {
        setIsAdminStaffMode(isAdminStaff);
        setIsPublishedViewMode(isPublishedView);
        setIsOwnerMode(isOwner);
        setIsEduContentMode(isEduContent);
    }, [isAdminStaff, isPublishedView, isOwner, isEduContent]);

    // const isEduContent =
    //     author?.role === process.env.REACT_APP_STRAPI_STAFF_ROLE_ID;

    // if (isPublishedView && !isAdminStaff) {
    //     // redirect to 404 prob need not authed page
    //     history(`/404`, { replace: true });
    // }
    const fetchFunc = useRef(
        isPublishedView ? fetchExperiencePublishedSingle : fetchExperienceSingle
    );

    const handlePrintMode = () => {
        togglePrintMode(!isPrintMode);
        const expPath = isPublishedView
            ? process.env.REACT_APP_BASENAME_EXPERIENCE_PUBLISHED
            : process.env.REACT_APP_BASENAME_EXPERIENCE;

        history(`${expPath}/${expId}/0`, { replace: true });
    };

    const [pageLoad, setPageLoading] = useState(true);

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [visibleSection, setVisibleSection] = useState();

    const [IncludesModalStatus, setIncludesModalStatus] = useState(false);
    const [update, setUpdate] = useState({ id: null });
    const hasUpdated = useRef(updated);
    const [infoModalStatus, setInfoModalStatus] = useState(false);

    const watchList = {
        experience,
        content_marketing,
        places_lists,
        budget_min,
        budget_max
    };

    // const missingCurrencies = {};

    // const verifyCurrencies = () => {
    //     const results = countriesArray.forEach((country) => {
    //         const countryCode = country.code;
    //         const currencyCode = countries[countryCode].currencyCode;
    //         const currencySymbol = countries[countryCode].currencySymbol;
    //         const countryName = country.name;

    //         if (!currenciesObject[currencyCode]) {
    //             missingCurrencies[currencyCode] = {
    //                 symbol: currencySymbol,
    //                 name: 'N/A',
    //                 symbol_native: currencySymbol,
    //                 decimal_digits: 2,
    //                 rounding: 0,
    //                 code: currencyCode,
    //                 name_plural: '',
    //                 budget_rounding: 1
    //             };
    //         }
    //     });
    // };

    // verifyCurrencies();
    // console.log(missingCurrencies);

    const rtl = !!translations[lang].rtl;

    useEffect(() => {
        // toggleEdit(false);
        togglePrintMode(true);

        if (pageLoad && !siteDataLoading) {
            fetchFunc.current(id).then((res) => {
                setPageLoading(false);
                if (res.error || invalid) {
                    history(`/404`, { replace: true });
                } else {
                    // toggleEdit(false);
                }
            });
        }
    }, [siteDataLoading]);

    const handleSave = () => {
        updateExperience();
    };

    useAutosave(
        watchList,
        !pageLoad,
        saving,
        updateExperience,
        process.env.REACT_APP_EXPERIENCE_SAVE_DELAY
    );

    useAutosave(
        { feedback_object: experience_status?.feedback_object },
        !pageLoad,
        feedbackSaving,
        updateFeedback,
        process.env.REACT_APP_FEEDBACK_SAVE_DELAY || 5000
    );

    useEffect(() => {
        return () => {
            if (hasUpdated.current) {
                updateExperience();
            }
            AxiosAbortService.clearRequest('singleExperience');
            clearExperience();
        };
    }, []);
    useEffect(() => {
        if (!pageLoad) {
            experienceHasUpdated();
        }
    }, [...Object.values(watchList)]);

    useEffect(() => {
        hasUpdated.current = updated;
    }, [updated]);

    return (
        <>
            {experienceDetails ? (
                <>
                    {userId === author?.id || isAdminStaff || isEduContent ? (
                        <div
                            className={`no-scrollbar pt-16 pb-32 min-h-screen overflow-hidden bg-white ${
                                handleRowReverse(rtl).flex
                            } `}
                        >
                            {!pageLoad && !loading && experienceDetails ? (
                                <>
                                    {(userId === author?._id ||
                                        isAdminStaff) && (
                                        <>
                                            {!isPublishedView && (
                                                <LayoutCommentsSidebar
                                                    experienceId={id}
                                                    menuIsOpen={menuIsOpen}
                                                    handleMenuIsOpen={
                                                        setMenuIsOpen
                                                    }
                                                >
                                                    <FeedbackList />
                                                </LayoutCommentsSidebar>
                                            )}

                                            <LayoutMenuEdit
                                                day={'print'}
                                                menuIsOpen={menuIsOpen}
                                                isPublishedView={
                                                    isPublishedView
                                                }
                                                isOwner={userId === author?.id}
                                                isPrintMode={isPrintMode}
                                                handlePrintMode={
                                                    handlePrintMode
                                                }
                                                isAdminStaff={isAdminStaff}
                                                isSuperAdmin={isSuperAdmin}
                                                expStatus={experience_status}
                                                type={type}
                                                edit={edit}
                                                author={author}
                                                handleEdit={toggleEdit}
                                                handleSuperAdmin={
                                                    toggleSuperAdmin
                                                }
                                                handleFeedbackMode={
                                                    toggleFeedbackMode
                                                }
                                                isFeedbackMode={isFeedbackMode}
                                                toolTips={toolTips}
                                                tour={tour}
                                                handleToolTips={toggleToolTips}
                                                handleTour={toggleTour}
                                                handleSave={handleSave}
                                                isSaving={saving}
                                                rtl={rtl}
                                                completion={getOverallCompletion(
                                                    experience,
                                                    experienceDetails
                                                )}
                                            />
                                        </>
                                    )}

                                    <div
                                        className={`pt-20 md:pt-0 transition duration-500 ease-in-out transform ${
                                            menuIsOpen
                                                ? handleRowReverse(rtl)
                                                      .pageTranslate
                                                : 'translate-x-0'
                                        }`}
                                    >
                                        <LayoutOverview
                                            sectionRefs={
                                                MenuRefsHolder.sectionRefs
                                            }
                                            sectionsMainNav={
                                                MenuRefsHolder.sectionsMainNav
                                            }
                                            type={type}
                                            sections={
                                                MenuRefsHolder.sectionsMainNav
                                            }
                                            isPublishedView={isPublishedView}
                                        />

                                        {experienceDetails.experience.itinerary.trip_days.map(
                                            (day, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className='border-t-4 border-dotted border-gray-300 mt-20 pt-20'
                                                    >
                                                        <LayoutDay
                                                            key={index}
                                                            dayIndex={index}
                                                            sections={
                                                                MenuRefsHolder.sectionsMainNav
                                                            }
                                                        />
                                                    </div>
                                                );
                                            }
                                        )}

                                        <div className='border-t-4 border-dotted border-gray-300 mt-20 pt-24'>
                                            <LayoutMarketing
                                                type={type}
                                                sections={
                                                    MenuRefsHolder.sectionsMainNav
                                                }
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <LayoutLoading
                                    height='h-screen-4/5'
                                    // spinnerColor='#00cc97'
                                    // message='Loading Owner data'
                                />
                            )}
                        </div>
                    ) : (
                        <ErrorPage err={403} />
                    )}
                </>
            ) : (
                <LayoutLoading
                    height='h-screen-4/5'
                    // spinnerColor='#00cc97'
                    // message='Loading Owner data'
                />
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleEdit,
            toggleSuperAdmin,
            togglePrintMode,
            toggleFeedbackMode,
            toggleToolTips,
            toggleTour,
            setIsOwnerMode,
            setIsAdminStaffMode,
            setIsPublishedViewMode,
            setIsEduContentMode,
            updateExperience,

            updateFeedback,
            experienceDetailsAction,

            fetchExperienceSingle,
            fetchExperiencePublishedSingle,

            clearExperience,
            experienceHasUpdated
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageExperienceSinglePrint);
