import Icons from 'components/blocks/Icons';

const ButtonTags = ({ handleActionBtn, params = [], label }) => {
    const handleClick = () => {
        handleActionBtn(...params);
    };

    return (
        <button
            onClick={handleClick}
            className='focus:outline-none flex-shrink-0 justify-between rounded-full bg-green-400 px-2 flex items-center text-sm group hover:bg-gray-900 transition duration-200'
        >
            <span className='px-2 group-hover:text-white duration-200 transition'>
                {label}
            </span>
            <span className='group-hover:text-white duration-200 transition'>
                <Icons iName='CLOSEALT' iClasses='text-sm' size='lg' />
            </span>
        </button>
    );
};

export default ButtonTags;
