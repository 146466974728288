import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';

import { connect } from 'react-redux';

export const PrivateRoute = ({
    auth: { isAuthenticated, loading },
}) => {
    
    return (
        (typeof isAuthenticated == 'undefined' || !isAuthenticated) && !loading ? (
            <Navigate to='/accounts/login' replace />
        ) : (
            <Outlet />
        )
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
