import translations from 'constants/translations';
import { connect } from 'react-redux';

const ErrorSuccessLangNS = ({ errorId, globalState: { lang } }) => {
    return translations['en'][errorId] || translations['en'].DEFAULTERROR;
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth,
});

const ErrorSuccessLang = connect(mapStateToProps)(ErrorSuccessLangNS);
export { ErrorSuccessLang };
