import Icons from 'components/blocks/Icons';
import uiStruct from 'constants/uiStruct';

const CardXlEditDelete = ({
    handleDelete,
    handleEdit,
    // isVisible,
    handleVisible,
    // handleMinimize,
    // isMinimized = false,
    windowMode,
    rtl,
    // color,
    colors = uiStruct.ui.tip_list.genericEdit,
    day = false,
    close,
    rounded = 'rounded-3xl',

    className = 'z-40'
}) => {
    const rtlClass = rtl ? 'left' : 'right';

    const windowClass = windowMode
        ? `${rtlClass}-4`
        : `top-4 lg:top-1/2 lg:-translate-y-1/2 ${rtlClass}-4 lg:${rtlClass}-4`;

    const hoverClass = `hover:border-${colors.border_color}`;

    return (
        <>
            {handleEdit && (
                <div
                    onClick={handleEdit}
                    className={`${className} absolute inset-0 border-2 border-transparent transition-colors ease-in-out duration-300 ${hoverClass} ${rounded} cursor-pointer`}
                ></div>
            )}

            {handleDelete ? (
                !close ? (
                    <div
                        className={`${className} absolute transform rounded-full flex ${windowClass} ${
                            day ? 'top-4' : ''
                        } `}
                    >
                        <button
                            onClick={handleDelete}
                            className={`border-2 p-1 border-transparent rounded-full text-gray-900 opacity-30 hover:opacity-100 outline-none focus:outline-none  flex items-center justify-center`}
                        >
                            <Icons iName='CLOSEALT' size='xl' iClasses='' />
                        </button>
                    </div>
                ) : (
                    <div
                        className={`absolute -mr-2 top-16 md:top-1/2 transform -translate-y-1/2 rounded-full flex z-50 ${windowClass}`}
                    >
                        <button
                            onClick={handleDelete}
                            className={`border-2 p-1 border-transparent rounded-full text-gray-900 opacity-30 hover:opacity-100 outline-none focus:outline-none  flex items-center justify-center`}
                        >
                            <Icons iName='CLOSEALT' size='xl' iClasses='' />
                        </button>
                    </div>
                )
            ) : null}
            {handleVisible ? (
                <div
                    className={`absolute transform rounded-full flex z-50 ${windowClass} ${
                        day ? 'top-4' : ''
                    } `}
                >
                    <button
                        onClick={handleVisible}
                        className={`border-2 p-1 border-transparent rounded-full text-gray-900 opacity-30 hover:opacity-100 outline-none focus:outline-none  flex items-center justify-center`}
                    >
                        {' '}
                        <i className='ri-eye-line text-xl'></i>
                        {/* <Icons
                            iName={isVisible ? 'EYE' : 'EYE_SLASH'}
                            size='xl'
                            iClasses=''
                        /> */}
                    </button>
                </div>
            ) : null}
        </>
    );
};

export default CardXlEditDelete;
