import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CheckListModal from 'components/modals/whatYouNeed/CheckListModal';
import { updateCheckListUrls } from 'store/actions/experienceDetails/checkList';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import CheckListTitlesLinks from 'components/blocks/CheckListTitlesLinks';
import { handleRowReverse } from 'helpers/FEutils';
import FeedbackBox from './FeedbackBox';

function CheckList({
    objId,
    dayIndex,
    globalState: { lang, edit, isFeedbackMode },
    updateCheckListUrls,
    dontForgetList
}) {
    const rtl = !!translations[lang].rtl;
    const [updateObj, setUpdateObj] = useState({});
    const [checkListModalState, setCheckListModalState] = useState(false);
    const editCheckList = () => {
        setCheckListModalState(true);
        setUpdateObj([...dontForgetList]);
    };

    const updateCheckList = (dataObj) => {
        updateCheckListUrls({ dayIndex, dataObj });
    };

    return (
        <div className='relative mt-8'>
            <div
                className={`z-50 absolute ${
                    handleRowReverse(rtl).left
                }-1/2 transform ${
                    handleRowReverse(rtl).negReverse
                }translate-x-1/2 md:${
                    handleRowReverse(rtl).left
                }-32 italic -translate-y-1/2 font-bold tracking-tight flex flex-none justify-center items-center bg-kn-yellow rounded-full px-6 text-black h-8`}
            >
                {
                    translations[lang][
                        uiStruct.itinerary.what_you_need.uiPreview.tip
                    ]
                }
            </div>

            <CheckListTitlesLinks
                dontForgetList={dontForgetList}
                rtl={rtl}
                icon='CHECK'
                bullet='TRAIN'
                isFeedbackMode={isFeedbackMode}
                edit={edit}
                editIcon={editCheckList}
            />

            {checkListModalState && (
                <CheckListModal
                    setModalIsOpen={setCheckListModalState}
                    updateObj={updateObj}
                    update={true}
                    handleActionBtn={updateCheckList}
                    hintObj={
                        uiStruct.itinerary.what_you_need.uiEdit.modals.checklist
                            .hint
                    }
                />
            )}
            <FeedbackBox objId={objId} label='Checklist' />
        </div>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateCheckListUrls
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckList);
