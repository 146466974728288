import classNames from 'classnames';

const RecommendBar = ({
    children,
    margins,
    bgColor = 'bg-yellow-100',
    textColor = 'text-yellow-800',
    msg = 'We recommend using a tablet or desktop for building and managing experiences',
    visibility = 'md:hidden',
    textSize = 'text-sm',
    padding = 'py-3 px-8'
}) => {
    return (
        <div className={classNames('flex items-center justify-center w-full text-center', bgColor, textColor, msg, visibility, textSize, padding, margins)}>
            {children ?? msg}
        </div>
    );
};

export default RecommendBar;
