export const clearAllExperiences = (draft, action) => {
    draft.experiences = [];
    draft.loading = true;

    return draft;
};

export const setExperiencesAll = (draft, action) => {
    const isPublisheds = action.type === `GET_PUBLISHED_EXPERIENCES_FULFILLED`;
    const isAdmin = action.type === `GET_EXPERIENCES_ISADMIN_FULFILLED`;
    let experiencesData = [];
    let not_edu = [];
    let is_edu = [];

    if (isPublisheds) {
        experiencesData = action.payload?.data?.data?.publishedsByLoggedUser;
    } else {
        not_edu = action.payload?.data?.data?.not_edu;
        is_edu = action.payload?.data?.data?.is_edu;
        experiencesData = [...not_edu, ...is_edu];
    }

    const totalExp = isPublisheds ? action.payload?.data?.data?.publishedCount : action.payload?.data?.data?.experiencesCount - (isAdmin ? is_edu.length : 0);

    draft.totalExperiences = totalExp < 0 ? 0 : totalExp;

    draft.experiences =
        experiencesData.map((obj) => {
            const {
                createdAt,
                updatedAt,
                content_marketing,
                id,
                user,
                places_lists,
                days,
                type,
                experience_status = {
                    feedback: ''
                },
                isPublished,
                isLive,
                experience_id,
                status,
                isEdu,
                inPublished,
                completion,
                title,
                featured_image
            } = obj;
            // const exp =
            //     typeof short_content === 'object' &&
            //     short_content.hasOwnProperty('completionStatus')
            //         ? short_content
            //         : obj.content;
            // flatten role here rather than re do code
            let userCpy = {
                username: '-none-',
                profile: {
                    first: '-',
                    last: '-',
                    displayname: '-'
                }
            };
            if (user) {
                userCpy = JSON.parse(JSON.stringify(user));
                // console.log('userCopy', userCpy);
                // console.log('user', user);
                userCpy.role = user.role.id;
            }

            return {
                id: isPublisheds ? experience_id : id,
                _id: isPublisheds ? experience_id : id,
                pubId: isPublisheds ? id : null,
                content_marketing,
                createdAt,
                updatedAt,
                // experience_tmp: obj.content,
                // experience: short_content,
                expAuthor: userCpy,
                days,
                places_lists,
                type,
                experience_status,
                isPublished,
                status,
                isEdu,
                inPublished,
                completion,
                title,
                featured_image,
                isLive
            };
        }) || [];

    draft.loading = false;

    return draft;
};

export const creatExperienceFufilled = (draft, action) => {
    const {
        payload: { data: experiencesData }
    } = action;

    const { _id: id, content } = experiencesData;

    draft.experiences.push({
        id,
        experience: content
    });

    draft.loading = false;

    return draft;
};

export const creatExperiencePending = (draft, action) => {
    draft.loading = true;

    return draft;
};

export const updateTags = (draft, action) => {
    const { dataObj, index } = action;

    draft.experiences[index].tags = dataObj;
    return draft;
};

export const updateCats = (draft, action) => {
    const { dataObj, index } = action;

    draft.experiences[index].cats = dataObj;
    return draft;
};

export const updateGuideIncludes = (draft, action) => {
    const { dataObj, index } = action;

    draft.experiences[index].guided_extra = dataObj;
    return draft;
};

export const setPurchasedAll = (draft, action) => {
    const purchased = action.payload?.data?.data?.purchasesByUser
        ? action.payload?.data?.data?.purchasesByUser.map((exp) => {
              const { id: purchaseId, experience, travel_date, people, swellbypass } = exp;

              return {
                  swellbypass,
                  purchaseId,
                  travel_date,
                  people,
                  ...experience
              };
          })
        : [];

    draft.purchased = purchased;
    return draft;
};
