import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ListBoxGeneric from 'components/blocks/ListBoxGeneric';
import ModalContainer, {
    ModalBody,
    ModalFooter,
    ModalCTA,
    ModalButton,
    ModalHelp
} from 'components/blocks/ModalTre';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';

import { countriesTranslations } from 'constants/countriesTranslations';

// should be in all modals
const targetElement = document.querySelector('#root');

function CurrencyTypeModal({
    countryList,
    close,
    setModalIsOpen,
    handleActionBtn,
    updateObj = null,
    update = false,
    defaultObj,
    globalState: { lang },
    hintObj = null,
    icon = uiStruct.overview.currency_paying.uiEdit.modals.add.icon,
    actionBtnObj = uiStruct.overview.currency_paying.uiEdit.modals.add.buttons
        .action,
    cancelBtnObj = uiStruct.overview.currency_paying.uiEdit.modals.add.buttons
        .cancel
}) {
    const rtl = !!translations[lang].rtl;

    const dataObj = update ? updateObj : defaultObj(lang);
    const countryTypes = countryList.map((key) => {
        return {
            id: key,
            name: countriesTranslations[lang][key].name,
            unavailable: false
        };
    });

    const [selectedType, setSelectedType] = useState(() => {
        const countryCode = countryTypes[0].id;

        return countryTypes[
            countryTypes.findIndex((obj) => {
                return obj.id === countryCode;
            })
        ];
    });

    // Copy Below to all modals
    //////////////////////////////////////////////////////////////////////

    // State for triggering animation of modal in and out
    const [transitionState, setTransitionState] = useState('off');
    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    ///////////////////////////////////////////////////////////////////

    // Paul stuff Handle Action BTN function
    const handleActionClick = () => {
        handleActionBtn({
            ...dataObj,
            countryCode: selectedType.id
        });
        handleTransitionStateOff(); // important... don't forget to add it in all modals
    };
    const g = 1;
    ///////////////////////////////

    return (
        <>
            <ModalContainer
                width='xl'
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={icon}
                close={close}
                rtl={rtl}
            >
                <ModalBody height='24'>
                    <ListBoxGeneric
                        listData={countryTypes}
                        val={selectedType}
                        handleChange={setSelectedType}
                    />
                </ModalBody>

                <ModalFooter>
                    {hintObj && (
                        <ModalHelp
                            helpText={translations[lang][hintObj]}
                            isHtml
                        />
                    )}
                    <ModalCTA>
                        <ModalButton
                            handleClick={handleTransitionStateOff}
                            label={translations[lang][cancelBtnObj.label]}
                            color={cancelBtnObj.color}
                            icon={cancelBtnObj.icon}
                        />
                        <ModalButton
                            handleClick={handleActionClick}
                            label={translations[lang][actionBtnObj.label]}
                            color={actionBtnObj.color}
                            icon={actionBtnObj.icon}
                        />
                    </ModalCTA>
                </ModalFooter>
            </ModalContainer>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(CurrencyTypeModal);
