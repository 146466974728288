import { connect } from 'react-redux';
import CardXl from 'components/blocks/CardXl';
import GenericGallery from 'components/blocks/GenericGallery';
import ImageHover from 'components/blocks/ImageHover';
import { PillLogo } from 'components/blocks/Pills';
import SliderList from 'components/specialty/SliderList';
import CarouselContainer from 'components/specialty/CarouselContainer';
import CollapseButton from 'components/blocks/CollapseButton';
import FeedbackBox from 'components/blocks/FeedbackBox';
import { useOnlineStatus } from 'helpers/useOnlineStatus';
import PillType from 'components/blocks/PillType';

function GalleryMarketing({
    actionType,
    marketingData: {
        gallery: images,
        intro: { objId }
    },
    experienceDetails: { type },
    globalState: { lang, edit, siteData }
}) {
    const isOnline = useOnlineStatus();
    const expImages = images.map((singleImage) => {
        return {
            type: singleImage.type,
            src: singleImage.type === 'img' ? singleImage.url + '-/preview/-/quality/lightest/' : singleImage.url,
            thumbnail: singleImage.url + '-/preview/80x80/',
            caption: singleImage.caption
        };
    });

    const lightBoxHandler = (imageIndex) => {
        // console.log('clicked');
    };

    return (
        <>
            <div className={` mt-12 mx-auto px-5 md:px-12 lg:px-12 xl:px-241 2xl:px-401 xl:max-w-7xl `}>
                {/* <div className={`z-100 mx-auto px-5 md:px-9 lg:px-12 xl:px-24 2xl:px-40  mt-12 md:mt-0 `}> */}
                <div className='full relative '>
                    <div className='hidden md:grid md:h-72 lg:h-96 xl:h-96 overflow-hidden rounded-xl w-full gap-2 grid-rows-4 grid-cols-8 grid-flow-col relative '>
                        {/* <div className='hidden md:grid md:h-72 lg:h-96 xl:h-128 overflow-hidden rounded-xl w-full gap-2 grid-rows-4 grid-cols-8 grid-flow-col relative '> */}
                        <ImageHover
                            url={expImages[1]?.src}
                            type={expImages[1]?.type}
                            handleActionBtn={lightBoxHandler}
                            params={[1]}
                            className='row-span-4 col-span-2'
                            portrait={true}
                        />

                        <ImageHover
                            url={expImages[0]?.src}
                            type={expImages[0]?.type}
                            handleActionBtn={lightBoxHandler}
                            params={[0]}
                            className='row-span-4 col-span-4'
                        />
                        <ImageHover
                            url={expImages[2]?.src}
                            type={expImages[2]?.type}
                            handleActionBtn={lightBoxHandler}
                            params={[2]}
                            className='row-span-2 col-span-1'
                            portrait={true}
                        />
                        <ImageHover
                            url={expImages[3]?.src}
                            type={expImages[3]?.type}
                            handleActionBtn={lightBoxHandler}
                            params={[3]}
                            className='row-span-2 col-span-2'
                        />
                        <ImageHover
                            url={expImages[4]?.src}
                            type={expImages[4]?.type}
                            handleActionBtn={lightBoxHandler}
                            params={[4]}
                            className='row-span-2 col-span-1'
                            portrait={true}
                        />

                        {/* <div className='absolute bottom-4 right-4'>
                            <ButtonGeneric
                                handleAction={lightBoxHandler}
                                shadow='shadow-double'
                                params={[0]}
                                label='View All'
                                icon='ri-image-line text-lg'
                                fontSize='text-xs'
                                ring=''
                            />
                        </div> */}
                    </div>
                    <div className='md:hidden h-96 overflow-hidden rounded-xl w-full grid gap-2 grid-rows-4 grid-cols-8 grid-flow-col relative'>
                        <ImageHover url={expImages[0]?.src} handleActionBtn={lightBoxHandler} params={[1]} className='row-span-2 col-span-8' />

                        <ImageHover url={expImages[1]?.src} handleActionBtn={lightBoxHandler} params={[0]} className='row-span-2 col-span-4' />
                        <ImageHover url={expImages[2]?.src} handleActionBtn={lightBoxHandler} params={[2]} className='row-span-2 col-span-4' />

                        {/* <div className='absolute bottom-4 right-4'>
                            <ButtonGeneric
                                handleAction={lightBoxHandler}
                                shadow='shadow-double'
                                params={[0]}
                                label='View All'
                                icon='ri-image-line text-lg'
                                fontSize='text-xs'
                                ring=''
                            />
                        </div> */}
                    </div>

                    <div className={`absolute z-100 top-0 -translate-y-1/2 left-1/2 transform -translate-x-1/2`}>
                        {/* <div className='uppercase rounded-full h-8 w-24 flex justify-center items-center bg-gray-900 text-xxs text-kn-primary tracking-widest px-6'>
                            {type}
                        </div> */}
                        <PillType type={type} />
                    </div>
                    <div className={`absolute z-100 bottom-0 translate-y-1/2 left-1/2 transform -translate-x-1/2`}>
                        <PillLogo />
                    </div>
                    <FeedbackBox objId={objId?.gallery} posY='bottom-1/4' labelPosX='left-24' label='Marketing gallery' zLevel='' />
                    {/* <FeedbackBox
                        objId={objId}
                        // zLevel=''
                        label='List item'
                        posY='bottom-full'
                        labelPosX='left-24'
                        root
                        dot='top-8 right-8'
                    /> */}
                </div>
            </div>

            {edit && (
                // <div className='mx-auto px-5 md:px-9 lg:px-12 max-w-6xl w-full mt-12'>
                <div className={`w-full ${images?.length > 0 ? 'min-h-10 mb-10' : ''} ${edit ? 'mt-32' : ''}`}>
                    {/* {edit && <CarouselContainer slides={expImages} />} */}

                    <div className='relative'>
                        <GenericGallery
                            actionType={actionType}
                            images={images}
                            lang={lang}
                            edit={edit}
                            slider={false}
                            max={20}
                            className=''
                            modalTitle='Marketing - Main'
                            objId={objId?.gallery}
                        >
                            <div className='absolute left-1/2 transform -translate-x-1/2 -top-16'>
                                <CollapseButton
                                    disabled={!isOnline}
                                    labelHover='Manage Gallery'
                                    handleClick={() => console.log('clicked')}
                                    icon='ri-image-add-line text-2xl -ml-0.5'
                                    size='10'
                                    textSize='text-xs'
                                    sizeHover='w-40'
                                    offsetCenter='2'
                                    btnColor='bg-green-400 hover:bg-gray-900 text-green-900'
                                />
                            </div>
                        </GenericGallery>
                    </div>
                </div>
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    marketingData: state.experienceDetails.content_marketing,
    experienceDetails: state.experienceDetails,
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(GalleryMarketing);
