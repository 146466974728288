/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TipModal from 'components/modals/TipModal';
import ConfirmModal from 'components/modals/ConfirmModal';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic.js';
import Tip from 'components/blocks/Tip';
import { ButtonsAdd } from 'components/blocks/Buttons';
import * as types from 'store/actions/types';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import useDraggableInPortal from 'helpers/useDraggableInPortal';
import FeedbackBox from './FeedbackBox';
// Amine
const defaultDataObj = {
    type: 'UI_LBL_TIP',
    tip: ''
};

function TipList({
    index = null,
    dayIndex = null,
    objId,
    // tipLabel = translations[lang][uiStruct.ui.buttons.add_extra],
    globalState: { lang, edit, isFeedbackMode },
    experienceDetailsAction,
    tips,
    actionType,
    icon = uiStruct.itinerary.moving_around.uiEdit.modals.tip.icon,
    hintObj = uiStruct.itinerary.moving_around.uiEdit.modals.tip.hint,
    max,
    offset = '16',
    isVisible = true
}) {
    const rtl = !!translations[lang].rtl;
    const renderDraggable = useDraggableInPortal();
    const tipLabel = translations[lang][uiStruct.ui.buttons.add_extra];
    // translations[lang][uiStruct.ui.buttons.add_extra],
    const [delConfirmModalState, setDelConfirmModalState] = useState(false);
    const [tipIndex, setTipIndex] = useState(0);
    const [updateObj, setUpdateObj] = useState({});
    const [tipModalState, setTipModalState] = useState(false);
    const [updateTip, setUpdateTip] = useState(false);

    const handleDelete = (tipIndex) => {
        setTipIndex(tipIndex);
        setDelConfirmModalState(true);
    };

    const handleEdit = (tipIndex) => {
        setTipModalState(true);
        setTipIndex(tipIndex);
        setUpdateTip(true);
        setUpdateObj({
            ...tips[tipIndex]
        });
    };

    const addTip = () => {
        setUpdateObj({ ...defaultDataObj });
        setUpdateTip(false);
        setTipModalState(true);
    };

    const updateCreateTip = (dataObj) => {
        if (updateTip) {
            experienceDetailsAction({
                type: `${types.UPDATE_TIP}${actionType}`,
                dayIndex,
                index,
                dataObj,
                tipIndex
            });
        } else {
            experienceDetailsAction({
                type: `${types.ADD_TIP}${actionType}`,
                dayIndex,
                index,
                dataObj
            });
        }
    };

    const confirmDelete = () => {
        experienceDetailsAction({
            type: `${types.DELETE_TIP}${actionType}`,
            dayIndex,
            index,
            tipIndex
        });
    };

    const buildTipSingle = (provided) => {
        return tips.map((obj, index, arr) => {
            const key = `nt${index}`;

            return edit ? (
                <Draggable key={key} draggableId={key} index={index}>
                    {renderDraggable((provided) => (
                        <Tip
                            index={index}
                            handleDelete={handleDelete}
                            handleEdit={handleEdit}
                            edit={edit}
                            isFeedbackMode={isFeedbackMode}
                            key={key}
                            data={obj}
                            dragOff={max}
                            rtl={rtl}
                            provided={provided}
                            label={translations[lang][obj.type]}
                            colors={uiStruct.ui.tip_list[obj.type]}
                        />
                    ))}
                </Draggable>
            ) : (
                <Tip
                    key={key}
                    name={obj.name}
                    data={obj}
                    rtl={rtl}
                    edit={edit}
                    isFeedbackMode={isFeedbackMode}
                    label={
                        translations[lang][uiStruct.ui.tip_list[obj.type].label]
                    }
                    colors={uiStruct.ui.tip_list[obj.type]}
                />
            );
        });
    };

    const buildTips = () => {
        return (
            <DragDropContext onDragEnd={tipDragEnd}>
                <Droppable droppableId='movingAround'>
                    {(provided) => (
                        <ul
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {buildTipSingle(provided)}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
        );
    };

    const tipDragEnd = (result) => {
        const { source, destination } = result;

        if (source && destination && source.index !== destination.index) {
            experienceDetailsAction({
                type: `${types.ARRANGE_TIPS}${actionType}`,
                dayIndex,
                index,
                source: source.index,
                destination: destination.index
            });
        }
    };

    return (
        <div className=''>
            <div className='relative'>
                <FeedbackBox
                    inset='-inset-y-4 -inset-x-8'
                    dot='right-2 -top-3 md:top-1/2 md:-translate-y-1/2 md:-right-16 lg:-right-20'
                    posY='bottom-full'
                    root
                    objId={objId}
                    label='Tip List'
                />
                {tips.length ? buildTips() : null}
            </div>
            {isVisible && !isFeedbackMode && (
                <div className='flex items-center justify-center w-full'>
                    {(!max && edit) || (max && tips.length === 0) ? (
                        <ButtonsAdd
                            handleClick={addTip}
                            label={tipLabel}
                            btnClass='p-1 rainbow-gradient'
                            text='text-gray-900'
                            color='bg-white '
                            className={`${rtl ? '-' : ''}translate-x-${offset}`}
                            rtl={rtl}
                            textClass=''
                        />
                    ) : null}
                </div>
            )}

            {tipModalState && (
                <TipModal
                    actionType={actionType}
                    index={index}
                    setModalIsOpen={setTipModalState}
                    updateObj={updateObj}
                    update={updateTip}
                    handleActionBtn={updateCreateTip}
                    icon={icon}
                    hintObj={hintObj}
                />
            )}
            {delConfirmModalState && (
                <ConfirmModal
                    setModalIsOpen={setDelConfirmModalState}
                    handleActionBtn={confirmDelete}
                />
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            experienceDetailsAction
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(TipList);
