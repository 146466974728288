import { countriesTranslations } from 'constants/countriesTranslations.js';

export function getCountryObjs () {
    const countriesObj = {};
    const countriesCodeObj = {};
    

    Object.keys(countriesTranslations['en']).forEach((key) => {
        const { name } = countriesTranslations['en'][key];

        countriesObj[name.toLowerCase()] = name.toLowerCase();
        countriesCodeObj[key] = name.toLowerCase();
    })

    return {
        countriesObj,
        countriesCodeObj
    }
}