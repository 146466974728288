/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { find } from 'lodash';
import ExperienceCard from 'components/blocks/ExperienceCard';
import ExperienceCardSkeleton from 'components/blocks/ExperienceCardSkeleton';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic.js';
import Icons from 'components/blocks/Icons';
import { useNavigate } from 'react-router-dom';
import {
    cloneExperience,
    createExperience,
    updateExperienceStatus,
    updateTagsAxios,
    updateIncludedGuide,
    updatePublished,
    createExperienceStatus
} from 'fetch/experiences';
import { setPagination } from 'store/actions/globalState/master.js';
import GenericListModal from 'components/modals/GenericModalList';
import CalendarBookingModal from 'components/modals/Calender/CalendarBookingModal';
import ExpAccountsModal from 'components/modals/ExpAccountsModal';
import DescriptionTitleModal from 'components/modals/DescriptionTitleModal';
import ConfirmModal from 'components/modals/ConfirmModal';
import InfoModal from 'components/modals/InfoModal';
import SubmitExperienceDialogs from './SubmitExperienceDialogs';
import ExperienceFilters from 'components/blocks/ExperienceFilters';
import GenericSelectList from 'components/blocks/GenericSelectList';
import EmptyModal from 'components/modals/EmptyModal';
import DigitalPriceModal from 'components/modals/DigitalPriceModal';
import EditExpModal from 'components/modals/EditExpModal';
import Paginate from 'components/blocks/Paginate';
import { useOnlineStatus } from 'helpers/useOnlineStatus';
import NoteStatic from './NoteStatic';
import classNames from 'classnames';

const ExperienceList = ({
    totalExperiences,
    experiences,
    auth: {
        user: { role: userRole, _id: userId }
    },
    globalState: {
        lang,
        filters,
        pagination,
        pagination: { currentPage, maxPerPage },
        siteData: { users: siteUsers, countryList }
    },
    createExperience,
    cloneExperience,
    pageLoad,
    setPageLoading,
    handleDeleteExperience,
    fetchExperiences,
    updateExperienceStatus,
    setTotExpDisplayed,
    totExpDisplayed,
    viewPublished,
    setPagination,
    isAdminStaff,
    isOwner,
    updatePublished,
    createExperienceStatus,
    myPublished = false
}) => {
    const isOnline = useOnlineStatus();
    const rtl = !!translations[lang].rtl;
    const history = useNavigate();
    const [newExpStatus, setNewExpStatus] = useState(false);
    const [approveModalState, setApproveModalState] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState('');
    const currentPubId = useRef(null);
    const currentType = useRef(null);
    const [guidedModalStatus, setGuidedModalStatus] = useState(false);
    const [expAccountModalStatus, setExpAccountModalStatus] = useState(false);
    const [digitalModalStatus, setDigitalModalStatus] = useState(false);
    const [delConfirmModalState, setDelConfirmModalState] = useState(false);
    const [editModalState, setEditModalState] = useState(false);
    const [dataObj, setDataObj] = useState(null);
    const [experiencesCopy, setExperiencesCopy] = useState([]);
    const [userNames, setUserNames] = useState([]);
    const [expTyp, setExpType] = useState(null);
    const [newExpType, setNewExpType] = useState('DIGITAL');
    const updateId = useRef(null);
    const [feedbackStatus, setFeedbackStatus] = useState(false);
    const [infoDisplayData, setInfoDisplayData] = useState({
        isArray: true,
        message: ''
    });
    const skeletonArray = [1, 2, 3, 4, 5];
    const [update, setUpdate] = useState({
        creatingNew: false,
        id: null,
        isUpdate: false
    });
    const getExpIndex = (expId) => {
        return experiences.findIndex((obj) => {
            return obj.id === expId;
        });
    };
    const handleNewExperience = () => {
        setNewExpStatus(true);
        setExpType(null);
        setUpdate({
            ...update,
            creatingNew: true,
            isUpdate: false
        });
    };
    const handleClone = (expId) => {
        setConfirmMsg('Clone this experience and assign it to following user?');
        setUpdate({
            expId,
            type: 'clone',
            userId
        });
        setApproveModalState(true);
    };
    const handleUserChange = (uId) => {
        setUpdate({
            ...update,
            userId: uId
        });
    };
    const handleEditExp = (expId) => {
        const experience = find(experiences, function (obj) {
            return obj.id === expId;
        });

        const {
            id,
            type,
            expAuthor: { id: userId },
            inPublished,
            status
        } = experience;

        setDataObj({
            id,
            type,
            userId,
            inPublished,
            status
        });

        setEditModalState(true);
    };
    const fetchAll = () => {
        setPageLoading(true);
        fetchExperiences();
    };

    const addUpdateExperienceType = (type) => {
        const { isUpdate } = update;
        if (!isUpdate && newExpType) {
            setPageLoading(true);
            createExperience(newExpType).then((res) => {
                createExperienceStatus(res.value.data.id).then(() => {
                    history(
                        `${process.env.REACT_APP_BASENAME_EXPERIENCE}/${res.value.data.id}/${newExpType.toLowerCase() === 'digital' ? '0' : 'marketing'}`
                        , { replace: true });
                });
            });
        }
    };
    const handlePublish = (id) => {
        const experience = find(experiences, function (obj) {
            return obj.id === id;
        });

        const { isPublished, pubId } = experience;

        if (isPublished) {
            setConfirmMsg('Do you want to unpublish');
        } else {
            setConfirmMsg('Do you want to publish');
        }

        setUpdate({
            id: pubId,
            isPublished: !isPublished
        });
        setApproveModalState(true);
    };
    const confirmApprove = () => {
        const { id, isPublished, type, expId, userId } = update;

        setUpdate({});
        setPageLoading(true);

        if (type === 'clone') {
            cloneExperience(expId, userId).then(() => {
                fetchExperiences();
            });
        } else {
            updatePublished(id, { isPublished }).then(() => {
                fetchExperiences();
            });
        }
    };
    const expReportAction = (expId) => {
        const index = getExpIndex(expId);
        const { pubId, type } = experiences[index];
        currentPubId.current = pubId;
        currentType.current = type;
        setExpAccountModalStatus(true);
    };
    const handleGuidedAction = (expId) => {
        const index = getExpIndex(expId);
        const { type, pubId } = experiences[index];
        currentPubId.current = pubId;

        if (type.toLowerCase() === 'guided') {
            setGuidedModalStatus(true);
        } else {
            setDigitalModalStatus(true);
        }
    };

    const handleDeleteAction = (expId) => {
        const index = getExpIndex(expId);
        const { id, pubId } = experiences[index];

        setUpdate({
            index,
            id,
            pubId
        });
        setDelConfirmModalState(true);
    };

    const [submitDialogsInfo, setSubmitDialogsInfo] = useState({
        status: false,
        experience: null
    });

    const handleSubmitAction = (expId) => {
        const index = getExpIndex(expId);
        updateId.current = index;
        setSubmitDialogsInfo({
            status: true,
            experience: experiences[index]
        });
    };

    const handleCompleteSubmit = () => {
        const {
            experience_status: { _id: statusId }
        } = experiences[updateId.current];

        setPageLoading(true);

        updateExperienceStatus(statusId, { status: 'submitted' }).then(() => {
            // fetchall let main page handle this
            fetchExperiences();
        });
    };

    const confirmDelete = () => {
        const { id, pubId } = update;

        handleDeleteExperience(id, pubId);
    };

    const handleSubmitFeedback = (feedback) => {
        // const { statusId } = infoDisplayData;
        // setPageLoading(true);
        // updateExperienceStatus(statusId, { status: 'pending', feedback }).then(
        //     () => {
        //         // fetchall let main page handle this
        //         fetchExperiences();
        //     }
        // );
    };

    useEffect(() => {
        if (experiences.length && !update.creatingNew) {
            if (!isAdminStaff) {
                setExperiencesCopy(experiences);
            }

            setUserNames([
                'all',
                ...Object.keys(
                    experiences
                        .map((exp) => exp.expAuthor.username)
                        .reduce((acc, next) => {
                            return { ...acc, [next]: next };
                        }, {})
                ).map((key) => key)
            ]);
        }
        setTotExpDisplayed(experiences.length - experiences.filter((obj) => obj.isEdu).length);
    }, [experiences]);

    useEffect(() => {
        fetchAll();
    }, [filters, pagination]);

    return (
        <>
            {isAdminStaff && !pageLoad ? (
                <ExperienceFilters
                    extraList={[
                        { id: 'updatedAt', label: 'last updated' },
                        { id: 'createdAt', label: 'created date' },
                        { id: 'completion', label: 'completion' },
                        { id: 'title', label: 'title' }
                    ]}
                    usersData={[{ id: 'all', username: 'all' }, ...siteUsers]}
                    statusData={viewPublished ? [] : ['all', 'submitted', 'approved', 'pending', 'initial']}
                    isPublishedData={viewPublished ? ['all', 'published', 'not published'] : []}
                    typeData={['all', 'GUIDED', 'DIGITAL']}
                    listData={experiences}
                    setDataCopy={setExperiencesCopy}
                    countryList={[{ id: 'all', name: 'all' }, ...countryList]}
                />
            ) : null}

            {!pageLoad && isAdminStaff && (
                <>
                    {totExpDisplayed === totalExperiences ? (
                        <div className='flex items-center mb-8 px-4'>{`Result:  ${totalExperiences} experiences`}</div>
                    ) : (
                        <div className='flex items-center mb-8 px-4'>{`Result:  ${totExpDisplayed} of ${totalExperiences} experiences`}</div>
                    )}
                </>
            )}

            {!pageLoad ? (
                <div className='mb-4 md:mb-16'>
                    {/* {!experiences?.filter((obj) => {
                        return !obj.isEdu;
                    })?.length && (
                        <div className='mt-8 w-full rounded-xl bg-gray-50 h-96 flex items-center justify-center'>
                            You don't have any approved experiences yet
                        </div>
                    )} */}
                    {myPublished && totalExperiences === 0 && (
                        <div className='px-4'>
                            <div className={classNames('bg-gray-50 rounded-lg px-8 text-center flex w-full h-96 items-center justify-center')}>
                                You don't have any Approved Experiences yet
                            </div>
                        </div>
                    )}

                    <Paginate paginateTotal={totalExperiences} maxPerPage={maxPerPage} setPagination={setPagination} currentPage={currentPage} />
                    <div className='grid grid-cols-1 px-2 lg-px-0 gap-y-12 md:gap-y-4 md:grid-cols-3 '>
                        {experiences
                            .filter((obj) => {
                                return !obj.isEdu;
                            })
                            .map((item, index) => {
                                const { id, expAuthor, type, isEdu, pubId } = item;
                                return (
                                    <ExperienceCard
                                        disabled={!isOnline}
                                        isEdu={isEdu}
                                        handlePublish={handlePublish}
                                        viewPublished={viewPublished}
                                        index={index}
                                        isAdminStaff={isAdminStaff}
                                        isOwner={isOwner}
                                        expReportBtn={expReportAction}
                                        guidedActionBtn={handleGuidedAction}
                                        deleteActionBtn={handleDeleteAction}
                                        submitActionBtn={handleSubmitAction}
                                        handleFeedbackBtn={handleEditExp}
                                        handleCloneBtn={handleClone}
                                        type={type}
                                        key={id}
                                        expId={id}
                                        pubId={pubId}
                                        data={item}
                                        author={expAuthor}
                                        myPublished={myPublished}
                                    />
                                );
                            })}
                        {!viewPublished ? (
                            <div className='my-3 px-2 lg:my-4 lg:px-2 pb-8'>
                                <button
                                    // className={`focus:outline-none transition-colors duration-200 ease-in-out text-gray-800 bg-gray-50 hover:bg-gray-200 w-full rounded-2xl justify-center items-center h-64 xl:h-128`}
                                    className={`focus:outline-none transition-colors duration-200 ease-in-out text-gray-800 bg-gray-50 hover:bg-gray-200 w-full rounded-2xl justify-center items-center h-96 md:h-80 lg:h-80 xl:h-96`}
                                    onClick={isOnline ? handleNewExperience : null}
                                >
                                    <Icons iName='PLUS' size='2xl' iClasses={`mt-0.5 ${rtl ? 'ml-2' : 'mr-2'}`} />
                                    <div className={`uppercase tracking-widest text-md font-base`}>
                                        {translations[lang][uiStruct.ui.experience.add_experience]}
                                    </div>
                                </button>
                            </div>
                        ) : null}
                    </div>
                    <Paginate paginateTotal={totalExperiences} maxPerPage={maxPerPage} setPagination={setPagination} currentPage={currentPage} />
                    {/* {!viewPublished && (
                        <>
                            <div
                                id='inspiration'
                                className='flex justify-between items-center mt-16 mb-8 px-4'
                            >
                                <div
                                    className={`${
                                        handleRowReverse(rtl).rtl
                                    }  text-transparent bg-clip-text bg-gradient-to-tr from-gray-900 via-blue-500 to-green-400 text-4xl leading-none  font-bold l py-2 `}
                                >
                                    Inspiration
                                </div>
                            </div>
                            <div className='grid grid-cols-1 px-2 gap-y-12 md:gap-y-4 md:grid-cols-3 '>
                                {experiences
                                    .filter((obj) => {
                                        return obj.isEdu;
                                    })
                                    .map((item, index) => {
                                        const {
                                            id,
                                            expAuthor,
                                            type,
                                            status,
                                            isEdu
                                        } = item;

                                        return (
                                            <ExperienceCard
                                                disabled={!isOnline}
                                                isEdu={isEdu}
                                                handlePublish={handlePublish}
                                                viewPublished={viewPublished}
                                                index={index}
                                                isAdminStaff={isAdminStaff}
                                                isOwner={isOwner}
                                                guidedActionBtn={
                                                    handleGuidedAction
                                                }
                                                deleteActionBtn={
                                                    handleDeleteAction
                                                }
                                                submitActionBtn={
                                                    handleSubmitAction
                                                }
                                                handleFeedbackBtn={
                                                    handleFeedbackAction
                                                }
                                                type={type}
                                                key={id}
                                                expId={id}
                                                data={item}
                                                author={expAuthor}
                                            />
                                        );
                                    })}
                            </div>
                        </>
                    )} */}
                </div>
            ) : (
                <div className='grid grid-cols-1 gap-y-12 md:gap-y-4 md:grid-cols-3 '>
                    {skeletonArray.map((item, index) => (
                        <ExperienceCardSkeleton key={`sk_${index}`} />
                    ))}
                </div>
            )}

            {newExpStatus && (
                <GenericListModal
                    // list={uiStruct.experience.types}
                    selected={expTyp}
                    setModalIsOpen={setNewExpStatus}
                    handleActionBtn={addUpdateExperienceType}
                    intro='Please select the type of experience you would like to create'
                >
                    {({ selectedType }) => {
                        return (
                            <div className='flex flex-col gap-4 mt-4'>
                                {(true || selectedType?.id?.toLowerCase() === 'digital') && (
                                    <NoteStatic
                                        handleClick={() => setNewExpType('DIGITAL')}
                                        type='digital'
                                        selected={newExpType}
                                        rows={true}
                                        label='Digital Experience'
                                        text='A detailed itinerary and description that will allow a traveler to explore a place on his own and that can be purchased and accessed from the Konnect website.'
                                    />
                                )}
                                {(true || selectedType?.id?.toLowerCase() === 'guided') && (
                                    <NoteStatic
                                        handleClick={() => setNewExpType('GUIDED')}
                                        type='guided'
                                        selected={newExpType}
                                        rows={true}
                                        label='Guided Experience'
                                        text='A traditional guided tour with a set itinerary and date that you can book via the Konnect website. '
                                    />
                                )}
                            </div>
                        );
                    }}
                </GenericListModal>
            )}
            {guidedModalStatus && <CalendarBookingModal expId={currentPubId.current} setModalIsOpen={setGuidedModalStatus} isAdminStaff={isAdminStaff} />}
            {expAccountModalStatus && <ExpAccountsModal type={currentType.current} expId={currentPubId.current} setModalIsOpen={setExpAccountModalStatus} />}
            {digitalModalStatus && (
                <DigitalPriceModal
                    expId={currentPubId.current}
                    width='sm'
                    update
                    txtOnly
                    setModalIsOpen={setDigitalModalStatus}
                    updateObj={update.updateObj || 0}
                    placeholder={'placeholder'}
                    inputIcon='las la-dollar-sign'
                    isAdminStaff={isAdminStaff}
                    // isOwner={userId === user?.id}
                    icon={{
                        name: 'COINS',
                        size: '2xl'
                    }}
                    type='number'
                >
                    <div className='mt-4 mb-6'>Set a price for your Digital Experience in US Dollars</div>
                </DigitalPriceModal>
            )}
            {delConfirmModalState && <ConfirmModal setModalIsOpen={setDelConfirmModalState} handleActionBtn={confirmDelete} doubleConfirm={true} />}
            {feedbackStatus && !isAdminStaff && (
                <InfoModal setModalIsOpen={setFeedbackStatus} messagesArr={infoDisplayData.message} isArray={infoDisplayData.isArray} />
            )}

            {feedbackStatus && isAdminStaff && (
                <DescriptionTitleModal
                    setModalIsOpen={setFeedbackStatus}
                    updateObj={infoDisplayData.message}
                    update={true}
                    handleActionBtn={handleSubmitFeedback}
                    icon={uiStruct.itinerary.what_you_need.uiEdit.modals.description.icon}
                />
            )}

            {submitDialogsInfo.status && (
                <SubmitExperienceDialogs
                    handleCompleteSubmit={handleCompleteSubmit}
                    lang={lang}
                    handleClose={setSubmitDialogsInfo}
                    experience={submitDialogsInfo.experience}
                />
            )}
            {approveModalState && (
                <EmptyModal
                    icon={{
                        name: 'CLONE',
                        size: '2xl'
                    }}
                    setModalIsOpen={setApproveModalState}
                    handleActionBtn={confirmApprove}
                    maxWidth='sm'
                    justifyButtons='justify-between'
                >
                    <div>{confirmMsg}</div>
                    <div>
                        {update.type === 'clone' ? (
                            <div className='w-full mt-6'>
                                {/* <div className='text-sm px-4 mb-4'>By user</div> */}
                                <GenericSelectList
                                    disabled={!isOnline}
                                    initialSelected={update.userId}
                                    selectOptions={siteUsers}
                                    handleChange={handleUserChange}
                                    keys={{ id: 'id', label: 'username' }}
                                />
                            </div>
                        ) : null}
                    </div>
                </EmptyModal>
            )}
            {editModalState && <EditExpModal dataObj={dataObj} fetchAll={fetchAll} setModalIsOpen={setEditModalState} />}
        </>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createExperience,
            cloneExperience,
            experienceDetailsAction,
            updateTagsAxios,
            updateIncludedGuide,
            updateExperienceStatus,
            updatePublished,
            setPagination,
            createExperienceStatus
        },
        dispatch
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth
});

export default connect(mapStateToProps, mapDispatchToProps)(ExperienceList);
