import React, { useState } from 'react';
import { connect } from 'react-redux';
import CardXl from 'components/blocks/CardXl';
import { CardXlDragHandleMain } from 'components/blocks/CardXlDrag';
import VendorList from 'components/blocks/VendorList';
import TipList from 'components/blocks/TipList';
import GenericDescription from 'components/blocks/GenericDescription';
import MovingAroundPill from 'components/blocks/MovingAroundPill';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { MenuRefsHolder } from 'helpers/MenuRefsHolder';

function SectionMovingAroundSingle({
    key,
    actionType,
    index,
    dayIndex,
    data,
    updateModal = null,
    removeModal = null,
    globalState: { lang, edit, isPrintMode, isFeedbackMode },
    handleVisible,
    isVisible
}) {
    const typeText = translations[lang][data.type];
    const rtl = !!translations[lang].rtl;

    const [isMinimized, setIsMinimized] = useState(false);

    return (
        <>
            <li
                key={key}
                className={`list-none ${
                    isMinimized ? '-mt-4 xl:-mt-8 last:mb-16' : ''
                }`}
                id={`ovMovingAround${index}`}
                ref={(el) => {
                    MenuRefsHolder.setRef(el, `ovMovingAround${index}`);
                }}
            >
                <CardXl
                    isFeedbackMode={isFeedbackMode}
                    objId={data?.objId?.section}
                    edit={edit}
                    isPrintMode={isPrintMode}
                    handleDelete={() => {
                        removeModal(index);
                    }}
                    rtl={rtl}
                    hidden={isMinimized}
                    handleMinimize={() => setIsMinimized(!isMinimized)}
                    isVisible={isVisible}
                    handleVisible={handleVisible}
                    handleMinimize={() => setIsMinimized(!isMinimized)}
                    hideEye={true}
                >
                    <div
                        className={`z-200 w-max mx-auto ${
                            isMinimized ? 'mt-2 xl:mt-8' : ''
                        }`}
                    >
                        <MovingAroundPill
                            objId={data?.objId?.title}
                            isFeedbackMode={isFeedbackMode}
                            actionType={actionType}
                            index={index}
                            rtl={rtl}
                            edit={edit}
                            title={typeText}
                            pillType={data.type}
                            dayIndex={dayIndex}
                        />
                    </div>
                    <div className={`${isMinimized ? 'hidden' : ''}`}>
                        <GenericDescription
                            actionType={actionType}
                            dayIndex={dayIndex}
                            index={index}
                            descriptionHtml={data.description_html}
                            defaultHtml={
                                translations[lang][
                                    uiStruct.itinerary.moving_around.uiPreview
                                        .descriptionEmpty
                                ]
                            }
                            objId={data?.objId?.description}
                            title='Moving Around'
                            subtitle='Description'
                        />
                        <VendorList
                            objId={data?.objId?.vendorlist}
                            actionType={actionType}
                            vendors={data.vendors}
                            dayIndex={dayIndex}
                            index={index}
                            btnLabel={
                                translations[lang][uiStruct.ui.buttons.add_link]
                            }
                            modalTitle='Moving Around'
                            modalSubTitle='Links & References'
                            labelTitle={
                                'You may want to provide users with information on how to buy tickets or book cars or even call road assistance in case your rental breaks down. Start first with a big title (ex: Booking a car).'
                            }
                            labelVendors={
                                'Then list the references. For example put a name for a car rental place and their website. Or put the name for the website to buy train tickets and its url'
                            }
                            labelVendorsPlaceholderName={'ex: Hertz'}
                            labelVendorsPlaceholderUrl={
                                'https://wwww.hertz.com'
                            }
                            labelVendorsPlaceholderTitle={
                                'Ex: Road Assistance - Buy Tickets - Rent Bikes'
                            }
                            isVisible={isVisible}
                        />
                        <TipList
                            // max
                            objId={data?.objId?.tiplist}
                            actionType={actionType}
                            tips={data.tips_html}
                            dayIndex={dayIndex}
                            index={index}
                            isVisible={isVisible}
                        />
                    </div>
                </CardXl>
            </li>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(SectionMovingAroundSingle);
