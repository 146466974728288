/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CardXlContent } from 'components/blocks/CardXl';
import { BlockHTML } from 'components/blocks/Blocks';
import DescriptionTitleModal from 'components/modals/DescriptionTitleModal';
import * as types from 'store/actions/types';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic.js';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { handleRowReverse } from 'helpers/FEutils';
import FeedbackBox from './FeedbackBox';

function GenericDescription({
    objId,
    index,
    canBeEmpty = true,
    tour,
    globalState: { lang, edit, isFeedbackMode },
    descriptionHtml,
    defaultHtml,
    actionType,
    dayIndex,
    hintObj = uiStruct.itinerary.activities.uiEdit.modals.description.hint,
    icon = uiStruct.itinerary.activities.uiEdit.modals.description.icon,
    experienceDetailsAction,
    nohover = null,
    textSize,
    textSizeEmpty,
    children,
    title,
    subtitle,
    justifyClass = ''
}) {
    const rtl = !!translations[lang].rtl;

    const [descModalState, setDescModalState] = useState(false);
    const handleEdit = () => {
        setDescModalState(true);
    };

    const updateDesc = (descHtml) => {
        experienceDetailsAction({
            type: `${types.UPDATE_DESCRIPTION}${actionType}`,
            dayIndex,
            index,
            descHtml:
                descHtml === '' ||
                descHtml === '<p><br></p>' ||
                descHtml === '<p>&nbsp;</p>' ||
                descHtml === '<p> </p>' ||
                descHtml === '<p></p>' ||
                descHtml.trim() === '<p> </p>'
                    ? ''
                    : descHtml
        });
    };

    return (
        <div className='relative'>
            <div>
                {children ? (
                    <div className=' relative p-4 '>
                        {children}
                        {/* {edit && !isFeedbackMode && (
                            <CardXlEditDelete
                                handleEdit={handleEdit}
                                bgColor={'hover:bg-green-200'}
                                rtl={rtl}
                            />
                        )} */}
                        {edit && !isFeedbackMode && (
                            <div
                                className={`z-40 cursor-pointer absolute inset-0 opacity-30 rounded-xl ring-transparent ring-4 hover:ring-green-400 transition-all ease-in-out duration-300 ${
                                    edit ? 'hover:bg-green-100' : ''
                                }`}
                                onClick={handleEdit}
                            />
                        )}
                    </div>
                ) : null}
                {!children && (
                    <CardXlContent
                        tour={tour}
                        nohover={nohover}
                        rtl={rtl}
                        edit={edit}
                        isFeedbackMode={isFeedbackMode}
                        handleEdit={handleEdit}
                        className={`mt-2 ${
                            justifyClass ? 'justify-center' : ''
                        }`}
                    >
                        <BlockHTML
                            justifyClass={justifyClass}
                            className={`${handleRowReverse(rtl).rtl} ${
                                descriptionHtml
                                    ? `${textSize ? textSize : ''}`
                                    : `italic ${
                                          textSizeEmpty
                                              ? textSizeEmpty
                                              : 'text-lg'
                                      }  text-gray-400 `
                            }`}
                            html={descriptionHtml || defaultHtml}
                        />
                    </CardXlContent>
                )}
                {descModalState && (
                    <DescriptionTitleModal
                        canBeEmpty={canBeEmpty}
                        setModalIsOpen={setDescModalState}
                        updateObj={descriptionHtml}
                        update={true}
                        handleActionBtn={updateDesc}
                        icon={icon}
                        hintObj={hintObj}
                        title={title}
                        subtitle={subtitle}
                    />
                )}
            </div>
            <FeedbackBox objId={objId} label='Description' />
        </div>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            experienceDetailsAction
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(GenericDescription);
