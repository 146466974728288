import UIkit from 'uikit';
import { gsap } from 'gsap';

import * as ui from '../translations/componentNames.json';

gsap.config({ nullTargetWarn: false });

export const animateIn = (classes, delay, y, duration, stagger) => {
    gsap.fromTo(
        classes,
        {
            y: y || 30,
            opacity: 0
        },
        {
            duration: duration || 0.5,
            y: 0,
            opacity: 1,
            delay: delay || 0.1,
            stagger: stagger || 0,
            ease: 'power3',
            force3D: true
        }
    );
};

export const randomNumber = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

const randomWords = [
    'hello',
    'darkness',
    'my',
    'old',
    'friend',
    'have',
    'come',
    'to',
    'talk',
    'with',
    'you',
    'again',
    'because',
    'vision',
    'softly',
    'creeping',
    'sleeping',
    'planted',
    'in',
    'brain',
    'remains'
];

export const genAutoComplete = (words) => {
    let sentence = '';
    for (let i = 0; i < words; i++) {
        sentence =
            sentence + randomWords[randomNumber(0, randomWords.length - 1)];
        if (i < words - 1) sentence += '-';
    }
    return sentence;
};

const randomMsgList = {
    good: [
        'The Web is on your side Today!',
        'Woohoo, Success!',
        'Anything else sire? Coffee perhaps?',
        'Can I go now?',
        'Boom! Done!',
        'Have a good day.',
        'Pixel pushing....done!',
        "At your service. You can call me Steve. Actually...don't",
        'Mission accomplished. Would you like some elevator music next time?',
        "Patience is virtue. You're a winner"
    ],
    bad: ['My God! What did you do?', 'This is Bad!', 'Im too old for this...']
};

export const randomMsg = (type) => {
    // return randomMsgList[type][randomNumber(0, 9)].msg;
    return randomMsgList[type][randomNumber(0, randomMsgList[type].length - 1)];
};

export const randomItem = (array) => {
    return array[randomNumber(0, array.length - 1)];
};

export const setAlert = (status, text) => {
    let msg = { text: '', color: '' };

    switch (status) {
        case 100:
            msg.text = text;
            msg.color = `danger`;
            break;

        case 200:
            msg.text = randomMsg('good');
            msg.color = `success`;
            break;
        case 400:
            msg.text = `Bad Request: Please make sure you have a valid address`;
            msg.color = `danger`;
            break;
        case 401:
            msg.text = `Unauthorized: You need credentials to check this resource`;
            msg.color = `warning`;
            break;
        case 403:
            msg.text = `Forbidden: You're un-authorized to check this resource`;
            msg.color = `warning`;
            break;
        case 404:
            msg.text = `Nothing Found: We couldn't retrieve any information from this link`;
            msg.color = `warning`;
            break;
        case 500:
            msg.text = `Internal Server Error`;
            msg.color = `danger`;
            break;
        default:
            msg.text = randomMsg('bad');
            msg.color = `danger`;
            break;
    }

    UIkit.notification({
        message: msg.text,
        status: msg.color,
        pos: 'top-center',
        timeout: 3000
    });
};

// Returns true if text is longer than count or if it has any of the words in findWords

export const checkMultiLine = (str, count) => {
    const findWords = [, '<br>', '<br/>', '\r', '\n'];
    let result = false;
    if (str.length > count) {
        result = true;
    } else {
        for (let i = 0; i < findWords.length; i++) {
            if (str.indexOf(findWords[i]) > 0) {
                result = true;
                break;
            }
        }
    }

    return result;
};

export const handleRowReverse = (rtl) => {
    return {
        flex: rtl ? 'flex-row-reverse ' : 'flex-row ',
        flexreverse: rtl ? 'flex-row' : 'flex-row-reverse',
        text: rtl ? 'text-right ' : '',
        arrowLong: rtl ? 'ARROW_LEFT_LONG' : 'ARROW_RIGHT_LONG',
        arrowShort: rtl ? 'ARROW_LEFT_SHORT' : 'ARROW_RIGHT_SHORT',
        editor: rtl ? 'ql-rtl' : '',
        mr: rtl ? 'ml' : 'mr',
        ml: rtl ? 'mr' : 'ml',
        right: rtl ? 'left' : 'right',
        left: rtl ? 'right' : 'left',
        RIGHT: rtl ? 'LEFT' : 'RIGHT',
        LEFT: rtl ? 'RIGHT' : 'LEFT',
        rtl: rtl ? 'kn-rtl' : '',
        ltr: rtl ? '' : 'kn-ltr',
        pr: rtl ? 'pl' : 'pr',
        pl: rtl ? 'pr' : 'pl',
        menuTranslate: rtl ? 'translate-x-full' : '-translate-x-full',
        menuTranslateReverse: rtl ? '-translate-x-full' : 'translate-x-full',
        pageTranslate: rtl ? '-translate-x-48' : 'translate-x-48',
        justify: rtl ? 'justify-end' : 'justify-start',
        justifyreverse: rtl ? 'justify-start' : 'justify-end',
        smText: rtl ? '' : '',
        neg: rtl ? '-' : '',
        negReverse: rtl ? '' : '-'
    };
};

export const objLength = (arrObj) => {
    const newObj = arrObj.map((item) => {
        return item.text;
    });
    return newObj;
};

export const urlArrLength = (arrObj) => {
    const newObj = arrObj.map((item) => {
        return item.label || item.url;
    });
    return newObj;
};

export const testValidUrl = (str) => {
    var regexp =
        /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

    return regexp.test(str);
};

export const validateEmail = (email) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const kreatorName = (profile) => {
    return profile?.displayname || profile?.first;
};

export const getDays = (days) => {
    return `${days}  ${days > 1 ? 'Days' : 'Day'}`;
};

export const pluralize = (nb, str) => {
    return nb > 1 ? 'nb ' + str + 's' : 'nb ' + str;
};
