import { connect } from 'react-redux';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import 'styles/progressCircle.css';
import translations from 'constants/translations';
import Icons from 'components/blocks/Icons';
import { handleRowReverse } from 'helpers/FEutils';
import classNames from 'classnames';

const ProgressMenu = ({
    disabled,
    completion,
    globalState: { lang, edit },
    isAdminStaff
}) => {
    const rtl = !!translations[lang].rtl;

    return edit || isAdminStaff ? (
        <div
            className={classNames(
                'h-3.5 w-3.5 flex items-center',
                disabled ? 'text-gray-600' : 'text-green-400',
                completion === 100 ? handleRowReverse(rtl).mr + '-2' : ''
            )}
        >
            {completion < 100 ? (
                <CircularProgressbar
                    strokeWidth={14}
                    value={completion}
                    className=''
                />
            ) : (
                <Icons iName='CHECKALT' iClasses='' />
            )}
        </div>
    ) : (
        <>{null}</>
    );
};

const ProgressPage_NS = ({
    disabled,
    size = '12',
    strokeWidth = 8,
    color,
    nolabel,
    completion = 0,
    globalState: { lang, edit }
}) => {
    return (
        <div
            className={classNames(
                `h-${size} w-${size}`,
                'rounded-full flex items-center relative  justify-center',
                disabled ? 'text-gray-600' : 'text-green-800',
                {
                    'border-4 bg-green-100 border-green-400':
                        completion >= 100 && !nolabel && !disabled,
                    'border-4 bg-gray-100 border-gray-400':
                        completion >= 100 && !nolabel && disabled
                }
            )}
        >
            {completion < 100 ? (
                <>
                    <CircularProgressbar
                        disabled={disabled}
                        strokeWidth={strokeWidth}
                        value={completion}
                        className=''
                    />
                    {!nolabel && (
                        <div className='text-sm absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                            {completion}
                        </div>
                    )}
                </>
            ) : (
                <Icons iName='CHECKALT' iClasses='' />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

const ProgressPage = connect(mapStateToProps)(ProgressPage_NS);

export { ProgressPage };

export default connect(mapStateToProps)(ProgressMenu);
