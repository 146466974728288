import * as types from 'store/actions/types';

export function setExperienceSingle(experience) {
    return (dispatch) => {
        dispatch({
            type: types.SET_EXPERIENCE_SINGLE_DIGITAL,
            experience,
            loading: false,
        });
    };
}

export function setExperiencesAll(experiences) {
    return (dispatch) => {
        dispatch({
            type: types.SET_EXPERIENCES_ALL,
            experiences,
            loading: false,
        });
    };
}
