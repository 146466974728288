import { useState } from 'react';
import GenericBtn from 'components/blocks/marketing/GenericBtn.js';
import ListBoxGeneric from 'components/blocks/ListBoxGeneric';

function TablePaginationControls({
    gotoPage,
    canPreviousPage,
    previousPage,
    nextPage,
    canNextPage,
    pageCount,
    pageIndex,
    pageOptions,
    pageSize,
    setPageSize,
    pageList = process.env.REACT_APP_PAGINATION_DEFAULTS.split(',').map(Number)
}) {
    const perPageList = pageList.map((key) => {
        return {
            id: key,
            name: key,
            unavailable: false
        };
    });
    const [selectedType, setSelectedType] = useState(() => {
        const sizeIndex = pageList.indexOf(pageSize);
        if (sizeIndex > -1) {
            return perPageList[sizeIndex];
        }

        return perPageList[0];
    });

    const changePageSize = (selected) => {
        setPageSize(selected.id);
        setSelectedType(selected);
    };

    return (
        <div className='flex items-center gap-2 mt-8'>
            <GenericBtn
                className='text-lg rounded-lg bg-gray-200 text-gray-800 hover:bg-gray-900 hover:text-white h-10 flex items-center px-4'
                params={[0]}
                disabled={!canPreviousPage}
                handleActionBtn={gotoPage}
            >
                <i className='ri-arrow-left-s-line  -mr-3'></i>
                <i className='ri-arrow-left-s-line '></i>
            </GenericBtn>
            <GenericBtn
                className='text-lg rounded-lg bg-gray-200 text-gray-800 hover:bg-gray-900 hover:text-white h-10 flex items-center px-4'
                disabled={!canPreviousPage}
                handleActionBtn={previousPage}
            >
                <i className='ri-arrow-left-s-line '></i>
            </GenericBtn>

            <GenericBtn
                className='text-lg rounded-lg bg-gray-200 text-gray-800 hover:bg-gray-900 hover:text-white h-10 flex items-center px-4'
                disabled={!canNextPage}
                handleActionBtn={nextPage}
            >
                <i className='ri-arrow-right-s-line '></i>
            </GenericBtn>

            <GenericBtn
                className='text-lg rounded-lg bg-gray-200 text-gray-800 hover:bg-gray-900 hover:text-white h-10 flex items-center px-4'
                params={[pageCount - 1]}
                disabled={!canNextPage}
                handleActionBtn={gotoPage}
            >
                <i className='ri-arrow-right-s-line  -mr-3'></i>
                <i className='ri-arrow-right-s-line '></i>
            </GenericBtn>
            <div className='mx-6 text-sm'>
                Page{' '}
                <strong>
                    {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
            </div>

            <div>
                <ListBoxGeneric
                    listData={perPageList}
                    val={selectedType}
                    handleChange={changePageSize}
                    color='bg-white'
                    height='h-10'
                    width='w-28'
                    direction='top'
                />
            </div>
        </div>
    );
}

export default TablePaginationControls;
