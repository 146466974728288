// import Link from 'next/link';
import { Link, useLocation } from 'react-router-dom';
const NavLink = ({ href, exact, children, sameWindow = false, type, ...props }) => {
    const { pathname } = useLocation();
    const isActive = exact ? pathname === href : pathname.startsWith(href);

    if (isActive) {
        props.className += ' pointer-events-none select-none';
    }
    return isActive ? (
        <div {...props}>{children(isActive)}</div>
    ) : type === 'url' ? (
        <a href={href} {...props} target={sameWindow ? '_self' : '_blank'}>
            {children(isActive)}
        </a>
    ) : (
        <Link to={href} {...props}>
            {children(isActive)}
        </Link>
    );
};

export default NavLink;
