import Select from 'react-select';

function AccomodationDaysSelect({
    index,
    days = [],
    handleDayUpdate,
    height = '3.5rem',
    bgColor = 'white',

    panelHeight = '',
    placement = 'bottom',
    labelTextSize = '0.875rem',
    menuTextSize = '0.875rem'
}) {
    const colourStyles = {
        control: (styles, state) => ({
            ...styles,
            backgroundColor: state.isFocused ? 'white' : bgColor,
            borderRadius: '0.75rem',
            borderColor: state.isFocused ? '#60A5FA' : 'transparent',
            minHeight: height,
            boxShadow: state.isFocused ? '0 0 0 4px #DBEAFE' : 'none',
            '&:hover': {
                borderColor: state.isFocused ? '#60A5FA' : 'transparent',
                boxShadow: '0 0 0 4px #BFDBFE',
                backgroundColor: 'white'
            }
        }),
        valueContainer: (styles) => ({
            ...styles,
            padding: '0 0.5rem 0 1.5rem',
            fontSize: labelTextSize,
            whiteSpace: 'nowrap'
        }),
        singleValue: (styles) => ({
            ...styles
        }),
        indicatorSeparator: (styles) => ({
            ...styles,
            width: 0
        }),
        option: (styles, state) => ({
            ...styles,
            backgroundColor: state.isDisabled ? '#F3F4F6' : 'white',
            borderRadius: '4px',
            color: '#111827',
            padding: '0.5rem 1.5rem',

            fontSize: menuTextSize,
            cursor: state.isDisabled ? 'not-allowed' : 'default',
            '&:hover': {
                backgroundColor: '#DBEAFE',
                color: '#1E3A8A'
            }
        }),
        menu: (styles) => ({
            ...styles,
            overflow: 'hidden',
            borderRadius: '0.75rem',
            padding: '0.75rem 0',
            borderWidth: '5px',
            borderColor: 'transparent',
            boxShadow: '0 25px 50px -12px rgba(0, 128, 87, 0.25)',
            height: panelHeight
        }),
        multiValue: (styles) => {
            return {
                ...styles,
                backgroundColor: '#EFF6FF',
                borderRadius: '6px'
            };
        },
        multiValueLabel: (styles) => ({
            ...styles,
            fontSize: menuTextSize,
            // padding: '0.25rem 1.5rem',
            marginLeft: '0.5rem',
            marginRight: '0.25rem'
        }),
        multiValueRemove: (styles) => ({
            ...styles,

            ':hover': {
                backgroundColor: '#60A5FA',
                color: 'white'
            }
        })
    };

    const dayOptions = new Array(30).fill(undefined).map((val, i) => {
        return { label: `Day ${i + 1}`, value: i + 1 };
    });

    const defaultValues = days
        ? days.map((val) => {
              return { label: `Day ${val}`, value: val };
          })
        : [];

    return (
        <div style={{ height: 100 }} className='mt-6 med:mx-6'>
            <div
                style={{ zIndex: 99 + (100 - index) }}
                className='absolute left-4 right-4 md:left-8 md:right-16'
            >
                <Select
                    closeMenuOnSelect={false}
                    defaultValue={defaultValues}
                    isMulti
                    options={dayOptions}
                    onChange={handleDayUpdate}
                    placeholder={'Assign a day to this accommodation'}
                    styles={colourStyles}
                />
            </div>
        </div>
    );
}

export default AccomodationDaysSelect;
