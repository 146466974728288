import { arrangeArray } from '../common';

export const deleteDayItinerary = (draft, action) => {
    const { index } = action;
    
    draft.experience.days = Number(draft.experience.days) - 1
    draft.experience.itinerary.trip_days.splice(index, 1);
    draft.days = draft.experience.days;

    return draft;
};

export const addDayItinerary = (draft, action) => {
    const { defaultDay } = action;

    draft.experience.days = Number(draft.experience.days) + 1;
    draft.experience.itinerary.trip_days.push(defaultDay);
    draft.days = draft.experience.days;

    return draft;
};

export const arrangeDaysItinerary = (draft, action, arr) => {
    return arrangeArray(draft, action, draft.experience.itinerary.trip_days);
};
