import { CardXlDragHandleMenuItem } from 'components/blocks/CardXlDrag';
import Icons from 'components/blocks/Icons';

const NavigationCurrencyOverview = ({
    edit,
    provided = {},
    rtl,
    day,
    refs,
    handleScroll,
    dragOff,
    title,
    isFeedbackMode
}) => {
    return (
        <li
            className={`group relative flex py-2 px-10`}
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            <div style={{ display: !edit ? 'none' : '' }}>
                <CardXlDragHandleMenuItem
                    dragOff={edit || isFeedbackMode}
                    dragProps={provided.dragHandleProps}
                    rtl={rtl}
                />
            </div>

            <button
                style={{ direction: rtl ? 'rtl' : 'ltr' }}
                // onClick={() => handleScroll(refs.current)}
                className={`shadow-2xl-green-400 border border-glass-75 group-hover:border-green-400 ring-4 ring-transparent group-hover:ring-green-200 bg-white group-hover:bg-green-50 transition-all duration-200 flex-1 ease-in-out flex gap-4 items-center py-2 rounded-xl px-4 focus:outline-none`}
            >
                <span
                    className={`w-12 h-8 flex-shrink-0 flex items-center  justify-center rounded-full bg-gradient-to-tr from-green-300 via-green-400 to-green-500 shadow-2xl-green-500 text-xl  `}
                >
                    <i className='ri-calendar-check-line text-gray-500 mix-blend-multiply'></i>
                </span>
                <span
                    className={`${
                        rtl ? 'text-right' : 'text-left'
                    } bg-green-1002 text-xs text-green-900`}
                >
                    {title}
                </span>
            </button>
        </li>
    );
};

export default NavigationCurrencyOverview;
