/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Editor from 'components/blocks/Editor';
import {
    ModalFooter,
    ModalCTA,
    ModalButton,
    ModalHelp
} from 'components/blocks/ModalTre';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import GenericSelectList from 'components/blocks/GenericSelectList';
import { BlockInputSingle } from 'components/blocks/Blocks';
import ModalFullscreen, { ModalBody } from 'components/blocks/ModalFullscreen';
import ModalTopBlock from 'components/blocks/ModalTopBlock';

// should be in all modals
const targetElement = document.querySelector('#root');

const dropList = {
    cancellation: { id: 'cancellation', label: 'Cancellation Policy' },
    refund: { id: 'refund', label: 'Refund Policy' }
};

function PoliciesModal({
    close,
    setModalIsOpen,
    handleActionBtn,
    globalState: { lang },
    updateObject,
    hintObj = null,
    icon = uiStruct.itinerary.moving_around.uiEdit.modals.add.icon,
    actionBtnObj = uiStruct.itinerary.moving_around.uiEdit.modals.add.buttons
        .action,
    cancelBtnObj = uiStruct.itinerary.moving_around.uiEdit.modals.add.buttons
        .cancel
}) {
    const rtl = !!translations[lang].rtl;
    // Copy Below to all modals
    //////////////////////////////////////////////////////////////////////

    // State for triggering animation of modal in and out
    const [transitionState, setTransitionState] = useState('off');
    const [validationObj, setValidationObj] = useState({
        title: { error: false }
    });
    const [dataObj, setDataObj] = useState(() => {
        return {
            ...updateObject.data,
            title: dropList[updateObject.data.type].label
        };
    });

    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    ///////////////////////////////////////////////////////////////////

    // Paul stuff Handle Action BTN function
    const validate = () => {
        // let error = false;

        // if (!dataObj.title.length) {
        //     setValidationObj({
        //         ...validationObj,
        //         title: { error: true }
        //     });
        //     error = true;
        // }

        return false;
    };
    const handleActionClick = () => {
        // Validate first
        if (!validate()) {
            handleActionBtn(dataObj);
            handleTransitionStateOff(); // important... don't forget to add it in all modals
        }
    };

    ///////////////////////////////

    const updateDescriptionHtml = (desc) => {
        setDataObj({
            ...dataObj,
            desc
        });
    };

    const changeType = (type) => {
        setDataObj({
            ...dataObj,
            type,
            title: dropList[type].label
        });
    };

    return (
        <>
            <ModalFullscreen
                width='4xl'
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={icon}
                close={close}
                closeButton={true}
                rtl={rtl}
                fullscreen={true}
            >
                <ModalBody rtlOff>
                    <div className='max-w-7xl mx-auto'>
                        <div className='w-full  px-4 md:px-8 lg:px-2 mb-8 mt-4'>
                            <ModalTopBlock
                                title={'Refund & Cancellation Policies'}
                                subtitle={'Add Policy'}
                                text={
                                    'Refund and Cancellation policies should be explicitely stated and are your sole reponsibility.'
                                }
                                rtl={rtl}
                                lang={lang}
                            />
                        </div>
                        <div className='flex flex-col-reverse portrait:flex-col-reverse lg:flex-row gap-16 lg:gap-4 xl:gap-8 px-0 md:px-8 lg:px-0'>
                            <div className='lg:w-1/2 portrait:w-full'>
                                <div className='mb-8 border-2 border-gray-200 rounded-lg pt-4 min-h-96'>
                                    <Editor
                                        valError={false}
                                        handleChange={updateDescriptionHtml}
                                        html={dataObj.desc}
                                    />
                                </div>
                            </div>
                            <div
                                className={`flex flex-col lg:w-1/2 mt-4 py-4 px-8 md:px-4 portrait:w-full`}
                            >
                                <div className='mt-4 mb-4'>
                                    1. What type of policy are you adding?
                                </div>
                                <div className='flex items-center w-full mb-6 gap-4'>
                                    <GenericSelectList
                                        initialSelected={dataObj.type}
                                        selectOptions={[
                                            dropList.cancellation,
                                            dropList.refund
                                        ]}
                                        handleChange={changeType}
                                        height='2.5rem'
                                        width='w-full'
                                    />
                                </div>
                            </div>
                        </div>

                        <ModalFooter>
                            {hintObj && (
                                <ModalHelp
                                    helpText={translations[lang][hintObj]}
                                    isHtml
                                />
                            )}
                            <ModalCTA>
                                <ModalButton
                                    handleClick={handleTransitionStateOff}
                                    label={
                                        translations[lang][cancelBtnObj.label]
                                    }
                                    color={cancelBtnObj.color}
                                    icon={cancelBtnObj.icon}
                                />
                                <ModalButton
                                    handleClick={handleActionClick}
                                    label={
                                        translations[lang][actionBtnObj.label]
                                    }
                                    color={actionBtnObj.color}
                                    icon={actionBtnObj.icon}
                                />
                            </ModalCTA>
                        </ModalFooter>
                    </div>
                </ModalBody>
            </ModalFullscreen>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(PoliciesModal);
