/* eslint-disable react/jsx-pascal-case */
/* eslint-disable arrow-body-style */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as types from 'store/actions/types';
import React, { useState } from 'react';
import { updateCaptionSlideActivityAccom, updateGalleryActivityAccom } from 'store/actions/experienceDetails/accommodation.js';
import { Section__Title } from 'components/blocks/Sections';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import OnboardingSection from 'components/specialty/OnboardingSection';
import GenericDescription from 'components/blocks/GenericDescription';
import CardXl from 'components/blocks/CardXl';
import { PillContainer, PillIcon, PillTitle } from 'components/blocks/Pills';
import TipList from 'components/blocks/TipList';
import AccommodationList from 'components/blocks/AccommodationList';
import HotelMap from 'components/map/HotelMap';
import Slider from 'components/slider/Slider';
import GalleryUpdater from 'components/specialty/GalleryUpdater';
import FeedbackBox from 'components/blocks/FeedbackBox';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic';
import SliderNew from 'components/slider/SliderNew';
import classNames from '../../../node_modules/classnames/index';

function SectionAccommodation(props) {
    const {
        experienceDetailsAction,
        actionType,
        experienceDetails: {
            experience: {
                accommodation: { locations: locationData, images = [], objId, isVisible: isSectionVisible = true },
                accommodation
            }
        },

        globalState: { lang, edit, isPrintMode, isFeedbackMode },
        updateCaptionSlideActivityAccom,
        updateGalleryActivityAccom
    } = props;

    const rtl = !!translations[lang].rtl;
    const accommodationObj = accommodation;
    const hintObj = uiStruct.overview.accommodation.uiPreview.hint;
    const hintObjEmpty = uiStruct.overview.accommodation.uiPreview.hintEmpty;
    // const hintObjModal = uiStruct.overview.destination.uiEdit.modals.type.hint;
    const [isMinimized, setIsMinimized] = useState(false);
    const handleUpdateCaption = (slideIndex, caption) => {
        updateCaptionSlideActivityAccom(slideIndex, caption);
    };
    const handleUpdateGallery = (galleryArray) => {
        updateGalleryActivityAccom(galleryArray);
    };

    const handleVisible = () => {
        experienceDetailsAction({
            type: types.UPDATE_SECTION_VISIBILITY,
            val: !isSectionVisible,
            objName: 'accommodation'
        });
    };

    return (
        <>
            {(edit || isSectionVisible) && (
                <div className={classNames('w-full touch:py-10', edit ? 'touch:-mt-8 ' : 'touch:-mt-28', 'mt-8')}>
                    <>
                        <Section__Title
                            edit={edit}
                            title={translations[lang][uiStruct.overview.accommodation.uiPreview.headTitle]}
                            hasData
                            hintObj={hintObj}
                            handleVisible={handleVisible}
                            isVisible={isSectionVisible}
                            className={`${isSectionVisible ? '-mb-4 lg:-mb-8' : 'mb-4 lg:mb-0'}`}
                        />
                        <OnboardingSection visible data={hintObjEmpty} />
                    </>
                    {isSectionVisible && (
                        <CardXl
                            isFeedbackMode={isFeedbackMode}
                            objId={objId?.section}
                            rtl={rtl}
                            isPrintMode={isPrintMode}
                            hidden={isMinimized}
                            handleMinimize={() => setIsMinimized(!isMinimized)}
                            isVisible={isSectionVisible}
                            handleVisible={handleVisible}
                        >
                            <div className={`z-200 w-max mx-auto ${isMinimized ? 'mt-2 xl:mt-8' : ''}`}>
                                <PillContainer rtl={rtl}>
                                    <PillIcon
                                        icon={
                                            uiStruct.overview.accommodation.uiPreview.icon.name
                                            // uiStruct.overview.getting_there
                                        }
                                    />
                                    <PillTitle title={translations[lang][uiStruct.overview.accommodation.uiPreview.subTitle]} />
                                </PillContainer>
                            </div>
                            <div className={`${isMinimized ? 'hidden' : ''}`}>
                                <GenericDescription
                                    // objId='accommodation_description'
                                    actionType={actionType}
                                    descriptionHtml={accommodationObj.description_html}
                                    defaultHtml={translations[lang][uiStruct.overview.accommodation.uiPreview.descriptionEmpty]}
                                    objId={objId?.description}
                                    title='Accommodations'
                                    subtitle='Description'
                                />

                                <div className='relative touch:mb-20'>
                                    <FeedbackBox
                                        objId={objId?.gallery}
                                        posY='bottom-full'
                                        dot='top-8 right-8'
                                        // boxHeight='h-4/5'
                                        zLevel=''
                                        label='Gallery'
                                    />
                                    {edit && <GalleryUpdater handleUpdateGallery={handleUpdateGallery} imageList={images} />}

                                    {images && images.length > 0 && (
                                        <>
                                            <SliderNew handleUpdateCaption={handleUpdateCaption} edit={edit} slides={images} objId={objId?.gallery} />
                                        </>
                                    )}
                                </div>

                                <HotelMap handleEditlocations={null} destinations={accommodationObj} />
                                <AccommodationList locations={locationData} objId={objId?.accommodationlist} isVisible={isSectionVisible} />
                                <TipList
                                    // max
                                    objId={objId?.tiplist}
                                    actionType={actionType}
                                    tips={accommodationObj.tips_html}
                                    dayIndex={null}
                                    index={null}
                                    offset={20}
                                    isVisible={isSectionVisible}
                                />
                            </div>
                        </CardXl>
                    )}
                </div>
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateCaptionSlideActivityAccom,
            updateGalleryActivityAccom,
            experienceDetailsAction
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionAccommodation);
