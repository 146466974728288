import Icons from 'components/blocks/Icons';

const MapFindMe = ({ findMe }) => {
    return (
        <button
            className='absolute top-8 right-8 z-10 focus:outline-none'
            onClick={findMe}
        >
            Find Me
        </button>
    );
};

export default MapFindMe;
