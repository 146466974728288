export const arrangeArray = (draft, action, arr) => {
    const { source, destination } = action;
    const [reorderedItem] = arr.splice(source, 1);

    arr.splice(destination, 0, reorderedItem);

    return draft;
};

export const changeVisibility = (draft, action) => {
    const { objName, val, dayIndex } = action;

    if ('dayIndex' in action) {
        draft.experience.itinerary.trip_days[dayIndex].what_you_need.isVisible =
            val;
    } else draft.experience[objName].isVisible = val;

    return draft;
};
