import * as types from 'store/actions/types';

export function addLocalCurrency( dataObj) {
    return (dispatch) => {
        dispatch({
            type: types.ADD_CURRENCY_OVERVIEW,
            dataObj,
        });
    };
}

export function deleteLocalCurrency( index) {
    return (dispatch) => {
        dispatch({
            type: types.DELETE_CURRENCY_OVERVIEW,
            index
        });
    };
}

export function updateLocalCurrency( index, dataObj) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_CURRENCY_OVERVIEW,
            index,
            dataObj
        });
    };
}

export function updateLocalCurrencyVendors(index, dataObj) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_CURRENCY_VENDORS_OVERVIEW,
            index,
            dataObj
        });
    };
}

export function arrangeCurrencyCountries(source, destination) {
    return (dispatch) => {
        dispatch({
            type: types.ARANGE_CURRENCY_OVERVIEW,
            source,
            destination,
        });
    };
}