/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import DescriptionTitleModal from 'components/modals/DescriptionTitleModal';
import React, { useRef,  useState } from 'react';
import { usePapaParse  } from 'react-papaparse';
import { createPurchaseCsv } from 'helpers/csv';

function CsvDownloadLink({ children, filename = 'exportedcsv',  type = 'digital' , getCsvData, expId}) {
    const { jsonToCSV } = usePapaParse();
    const csvDownloadHref = useRef(null);
    const [whatFileName, setWhatFileName] = useState(false);
    const convertToCsvJson = (data) => {
        const jsonData = createPurchaseCsv(data, type !== 'digital')
        return jsonToCSV(jsonData);
    }

    const getData = () => {
        setWhatFileName(true);
    }

    const nextStep = (name) => {
        getCsvData({ pubId: expId }, true).then((res) => {
            if (res?.value?.data?.data?.purchaseCount) {
                const purchaseCount = res?.value?.data?.data?.purchaseCount;
                if(purchaseCount) {
                    const blob = new Blob([convertToCsvJson(res?.value?.data?.data?.purchasesByPubId)], { type: 'data:text/plain;charset=utf-8' });

                    if (window.navigator.msSaveBlob) { // IE 10+
                        window.navigator.msSaveBlob(blob, `${name}.csv`);
                    } else {
                        const url = URL.createObjectURL(blob);

                        csvDownloadHref.current.setAttribute("href", url);
                        csvDownloadHref.current.setAttribute("download", `${name}.csv`);
                        csvDownloadHref.current.click();
                    }
                }
                
            }

        });
    }

    return (
        <>
        {whatFileName && (
                    <DescriptionTitleModal
                        width='sm'
                        update
                        txtOnly
                        setModalIsOpen={setWhatFileName}
                        updateObj={""}
                        handleActionBtn={nextStep}
                        placeholder={'placeholder'}
                        // inputIcon='las la-dollar-sign'
                        // icon={{
                        //     name: 'COINS',
                        //     size: '2xl'
                        // }}
                    >
                        <div className='mt-4 mb-6'>
                            Csv name
                        </div>
                    </DescriptionTitleModal>
                )}
    <button onClick={getData}>csv</button>
        <a style={{position: "absolute", left: -1000}}ref={csvDownloadHref} href="#">{children || 'download csv'}</a>
        </>
    )
}

export default CsvDownloadLink;