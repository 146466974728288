import Icons from 'components/blocks/Icons';

const ImageHover = ({
    url,
    handleActionBtn,
    params = [],
    disabled = false,
    className = '',
    nohover,
    bg = 'bg-white',
    type,
    portrait = false
}) => {
    const handleClick = () => {
        if (!disabled && handleActionBtn) handleActionBtn(...params);
    };

    return url ? (
        <div
            className={`${
                !disabled ? 'cursor-pointer' : 'cursor-default'
            } relative h-full ${className} ${
                type !== 'img' ? 'overflow-hidden' : ''
            }`}
            onClick={handleClick}
        >
            <img
                alt='Placeholder'
                className={`transform object-cover object-center w-full h-full ${className} ${
                    type !== 'img' ? (portrait ? 'scale-135' : 'scale-120') : ''
                } `}
                src={url}
            />
            {type === 'youtube' && (
                <div className='absolute top-4 left-4'>
                    <i className='ri-play-fill text-2xl text-white'></i>
                </div>
            )}
            {!nohover && (
                <div className='absolute inset-0 bg-transparent hover:bg-green-400 opacity-20 duration-200'></div>
            )}
        </div>
    ) : (
        <div className={`relative min-h-60 h-full ${className} ${bg}`}>
            <div className=' absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                <Icons iName='IMAGEALT2' />
            </div>
        </div>
    );
};

export default ImageHover;
