/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LayoutOverview from 'components/layouts/LayoutOverview';
import LayoutDay from 'components/layouts/LayoutDay';
import LayoutLoading from 'components/layouts/LayoutLoading';
import LayoutExperiencePurchasedSidebar, {
    LayoutExperiencePurchasedSidebarOverview,
    LayoutExperiencePurchasedSidebarDay
} from 'components/layouts/LayoutExperiencePurchasedSidebar';
import { handleRowReverse } from 'helpers/FEutils';
import { toggleEdit } from 'store/actions/globalState/master';
import { fetchExperiencePurchased } from 'fetch/experiences';
import translations from 'constants/translations';
import { animateScroll as scroll } from 'react-scroll';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import 'styles/shepherd.css';
import { MenuRefsHolder } from 'helpers/MenuRefsHolder';
import ErrorPage from 'components/pages/ErrorPage';
import LayoutNavbar from 'components/layouts/LayoutNavbar';

const getDimensions = (ele) => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return {
        height,
        offsetTop,
        offsetBottom
    };
};

const scrollTo = (ele, width) => {
    const { offsetTop } = getDimensions(ele);
    if (width > 640) {
        scroll.scrollTo(offsetTop, { smooth: true });
    } else scroll.scrollTo(offsetTop, { duration: 0, smooth: false });
};

const PageExperienceSinglePurchased = (props) => {
    const history = useNavigate();

    const {
        toggleEdit,
        globalState: {
            lang,
            siteData: { loading: siteDataLoading }
        },
        auth: { isAuthenticated },
        // match: {
        //     params: { day = 0, id }
        // },
        experienceDetails,
        experienceDetails: { loading, experience, invalid, type },
        fetchExperiencePurchased
    } = props;

    const { day = 0, id } = useParams();

    const isValidDay = (day) => {
        let isValid = true;
        const tmpDay = Number(day);
        if (isNaN(tmpDay)) {
            isValid = false;
        } else {
            if (tmpDay >= 0 && tmpDay <= experience.itinerary.trip_days.length) isValid = true;
            else isValid = false;
        }
        return isValid;
    };
    const [menuIsOpen, setMenuIsOpen] = useState(true);
    const [visibleSection, setVisibleSection] = useState();
    const dayIndex = Number(day) - 1; // Array starts at zero
    const [pageLoad, setPageLoading] = useState(true);
    const rtl = !!translations[lang].rtl;
    const fetchFunc = useRef(fetchExperiencePurchased);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + 150;
            const selected = MenuRefsHolder.sectionRefs.find(({ section, ref }) => {
                const ele = ref;
                if (ele) {
                    const { offsetBottom, offsetTop } = getDimensions(ele);
                    return scrollPosition > offsetTop && scrollPosition < offsetBottom;
                }
                return false;
            });

            if (selected && selected.section !== visibleSection) {
                setVisibleSection(selected.section);
            } else if (!selected && visibleSection) {
                setVisibleSection(undefined);
            }
        };

        handleScroll();
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [visibleSection]);

    useEffect(() => {
        toggleEdit(false);
        if (pageLoad && !siteDataLoading && isAuthenticated) {
            fetchFunc.current(id).then((res) => {
                if (res.error) {
                    console.log(res);
                    history(`/c/experience/all`, { replace: true });
                } else {
                    setPageLoading(false);
                }
            });
        } else if (isAuthenticated === false && !siteDataLoading) {
            history(`/accounts/login`, {
                replace: true,
                state: { from: window.location.pathname }
            });
        }
    }, [isAuthenticated, siteDataLoading]);

    return (
        <>
            <LayoutNavbar />
            <div className={`${day === 'marketing' ? '' : 'overflow-x-hidden'} no-scrollbar pt-16 pb-32 min-h-screen ${handleRowReverse(rtl).flex} `}>
                {!pageLoad && !loading && experienceDetails && !invalid ? (
                    isValidDay(day) ? (
                        <>
                            <LayoutExperiencePurchasedSidebar
                                publicView
                                dayIndex={dayIndex}
                                experienceId={id}
                                menuIsOpen={menuIsOpen}
                                handleMenuIsOpen={setMenuIsOpen}
                            >
                                {dayIndex === -1 && (
                                    <LayoutExperiencePurchasedSidebarOverview
                                        publicView
                                        handleScroll={scrollTo}
                                        sections={MenuRefsHolder.sectionsMainNav}
                                        visibleSection={visibleSection}
                                        menuIsOpen={menuIsOpen}
                                        handleMenuIsOpen={setMenuIsOpen}
                                    />
                                )}
                                {dayIndex >= 0 && (
                                    <LayoutExperiencePurchasedSidebarDay
                                        publicView
                                        handleScroll={scrollTo}
                                        sections={MenuRefsHolder.sectionsMainNav}
                                        visibleSection={visibleSection}
                                        dayIndex={dayIndex}
                                        menuIsOpen={menuIsOpen}
                                        handleMenuIsOpen={setMenuIsOpen}
                                    />
                                )}
                            </LayoutExperiencePurchasedSidebar>
                            <div
                                className={`transition duration-500 ease-in-out transform ${menuIsOpen ? handleRowReverse(rtl).pageTranslate : 'translate-x-0'
                                    }`}
                            >
                                {dayIndex === -1 && (
                                    <LayoutOverview
                                        type={type}
                                        purchased
                                        sectionRefs={MenuRefsHolder.sectionRefs}
                                        sectionsMainNav={MenuRefsHolder.sectionsMainNav}
                                    />
                                )}

                                {dayIndex >= 0 && <LayoutDay dayIndex={dayIndex} sections={MenuRefsHolder.sectionsMainNav} />}
                            </div>
                        </>
                    ) : <ErrorPage err={403} />
                ) : (
                    <LayoutLoading />
                )}
                {invalid && <Navigate to={`${process.env.REACT_APP_BASENAME}/purchased`} />}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleEdit,
            fetchExperiencePurchased
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageExperienceSinglePurchased);
