import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLoadScript } from '@react-google-maps/api';
import Geocode from 'react-geocode';
import {
    googleMapsInitialised,
    googleMapsInitialisedFailed
} from 'store/actions/globalState/master';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
Geocode.enableDebug();

function LoadMap({ store }) {
    const libraries = ['places', 'drawing'];

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries
    });

    useEffect(() => {
        if (isLoaded) {
            store.dispatch(googleMapsInitialised());
        } else if (loadError) {
            store.dispatch(googleMapsInitialisedFailed());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded, loadError]);

    return null;
}

function GoogleMaps({ store, isAuthenticated }) {
    return <>{<LoadMap store={store} />}</>;
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(GoogleMaps);
