import * as types from 'store/actions/types';

export function updateTimeToGo(dataObj) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_GO_TIME_OVERVIEW,
            dataObj,
        });
    };
}
