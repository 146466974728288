/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import ListBoxGeneric from 'components/blocks/ListBoxGeneric';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import LayoutLoading from 'components/layouts/LayoutLoading';
import InfoModal from 'components/modals/InfoModal';
import GenericCheckBox from 'components/blocks/GenericCheckBox';
import { ModalButton, ModalCTA, ModalFooter } from 'components/blocks/ModalTre';

function EditExpForm(props) {
    const {
        userData,
        inModal = true,
        globalState: { lang },
        actionBtnObj = uiStruct.ui.modals.editor.buttons.action,
        cancelBtnObj = uiStruct.ui.modals.editor.buttons.cancel,
        handleTransitionStateOff,
        handleEditExp,
        dataObj: { type, userId, inPublished, status }
    } = props;
    // const rtl = !!translations[lang].rtl;
    const userDataList = useRef(
        userData.map((user) => {
            const { id, username: name } = user;

            return { id, name };
        }) || []
    );
    const [selectedUser, setSelectedUser] = useState(() => {
        const selected = userDataList.current.find((obj) => {
            return obj.id === userId;
        });

        if (selected) {
            return selected;
        } else {
            return userDataList.current[0];
        }
    });

    const typeList = useRef([
        { id: 'GUIDED', name: 'GUIDED' },
        { id: 'DIGITAL', name: 'DIGITAL' }
    ]);

    const statusList = useRef([
        { id: 'initial', name: 'Initial' },
        { id: 'submitted', name: 'Submitted' },
        { id: 'pending', name: 'Pending' },
        { id: 'approved', name: 'approved' }
        // { id: 'rejected', name: 'Rejected' },
    ]);

    const [selectedType, setSelectedType] = useState(
        type.toLowerCase() === 'guided'
            ? typeList.current[0]
            : typeList.current[1]
    );

    const [selectedStatus, setSelectedStatus] = useState(() => {
        const selected = statusList.current.find((obj) => {
            return obj.id === status;
        });

        if (selected) {
            return selected;
        } else {
            return statusList.current[0];
        }
    });

    const [busyCreating, setBusyCreating] = useState(false);
    const [infoModalStatus, setInfoModalStatus] = useState(false);
    const [formLocked, setFormLocked] = useState(false);

    const handleSubmit = () => {
        handleEditExp(selectedUser.id, selectedType.id, selectedStatus.id);
    };
    ////////////////////////////////

    const saveButtonDisabled = () => {
        if (formLocked && inModal) {
            if (
                userId !== selectedUser.id ||
                type !== selectedType.id ||
                status !== selectedStatus.id
            ) {
                return false;
            }
        }

        return true;
    };

    return (
        <>
            {!busyCreating ? (
                <>
                    <div className='mb-10 px-4'>
                        <div className='flex flex-col md:flex-row gap-8 md:gap-16 '>
                            <div className='md:w-1/2 '>
                                <div className='mb-16'>
                                    <div className='mb-4 ml2-40 flex items-center gap-2 text-green-500'>
                                        <i className='ri-account-circle-line text-2xl'></i>
                                        <span>Admin Settings</span>
                                    </div>
                                    <div className='flex flex-col-reverse gap-6 mb-4'>
                                        <ListBoxGeneric
                                            color='bg-white'
                                            listData={statusList.current}
                                            val={selectedStatus}
                                            handleChange={setSelectedStatus}
                                            label='Experience Status'
                                            height='h-10'
                                            fontSize='text-sm'
                                            textClass='text-sm'
                                            labelWidth='w-48'
                                            labelPos='top'
                                            direction='top2'
                                        />

                                        <ListBoxGeneric
                                            color='bg-white'
                                            listData={userDataList.current}
                                            val={selectedUser}
                                            handleChange={setSelectedUser}
                                            label='Experience Kreator'
                                            height='h-10'
                                            fontSize='text-sm'
                                            textClass='text-sm'
                                            labelWidth='w-48'
                                            labelPos='top'
                                            direction='top2'
                                        />

                                        {!inPublished && (
                                            <ListBoxGeneric
                                                color='bg-white'
                                                listData={typeList.current}
                                                val={selectedType}
                                                handleChange={setSelectedType}
                                                label='Type'
                                                height='h-10'
                                                fontSize='text-sm'
                                                textClass='text-sm'
                                                labelWidth='w-48'
                                                labelPos='top'
                                                direction='top2'
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {infoModalStatus && (
                            <InfoModal
                                setModalIsOpen={setInfoModalStatus}
                                messagesArr={`Created User`}
                            />
                        )}
                    </div>
                    <div className='mb-10 '>
                        <ModalFooter>
                            <ModalCTA>
                                {inModal && (
                                    <div className='flex items-center mr-8 mb-8 md:mb-0'>
                                        <GenericCheckBox
                                            name='terms'
                                            isChecked={formLocked}
                                            setIsChecked={setFormLocked}
                                            bgColor='bg-white'
                                        />
                                        <div className='text-sm '>
                                            Confirm Changes
                                        </div>
                                    </div>
                                )}

                                <ModalButton
                                    handleClick={handleTransitionStateOff}
                                    label={
                                        translations[lang][cancelBtnObj.label]
                                    }
                                    color={'gray200'}
                                    icon={cancelBtnObj.icon}
                                />
                                <ModalButton
                                    isDisabled={saveButtonDisabled()}
                                    handleClick={handleSubmit}
                                    label={
                                        translations[lang][actionBtnObj.label]
                                    }
                                    color={actionBtnObj.color}
                                    icon={actionBtnObj.icon}
                                />
                            </ModalCTA>
                        </ModalFooter>
                    </div>
                </>
            ) : (
                <div className={``}>
                    <LayoutLoading
                        height='h-screen-4/5'
                        spinnerColor='#00cc97'
                        // message='Loading Owner data'
                    />
                </div>
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(EditExpForm);
