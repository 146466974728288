/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ListBoxGeneric from 'components/blocks/ListBoxGeneric';
import ModalContainer, { ModalBody, ModalFooter, ModalCTA, ModalButton, ModalHelp } from 'components/blocks/ModalTre';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';

// should be in all modals
const targetElement = document.querySelector('#root');

function GenericModalList({
    children,
    intro,
    introClass = 'mb-6 text-lg',
    list,
    selected,
    setModalIsOpen,
    handleActionBtn,
    globalState: { lang },
    hintObj = null,
    icon = uiStruct.itinerary.moving_around.uiEdit.modals.add.icon,
    actionBtnObj = uiStruct.itinerary.moving_around.uiEdit.modals.add.buttons.action,
    cancelBtnObj = uiStruct.itinerary.moving_around.uiEdit.modals.add.buttons.cancel
}) {
    const rtl = !!translations[lang].rtl;

    const listType = list?.map((key) => {
        return {
            id: key,
            name: translations[lang][key],
            unavailable: false
        };
    });

    const [selectedType, setSelectedType] = useState(() => {
        if (list) {
            if (!selected) {
                return listType[0];
            }
            return listType[
                listType.findIndex((obj) => {
                    return obj.id === selected;
                })
            ];
        }
        return null;
    });

    // Copy Below to all modals
    //////////////////////////////////////////////////////////////////////

    // State for triggering animation of modal in and out
    const [transitionState, setTransitionState] = useState('off');
    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    ///////////////////////////////////////////////////////////////////

    // Paul stuff Handle Action BTN function
    const handleActionClick = () => {
        if (list) handleActionBtn(selectedType.id);
        else handleActionBtn();

        handleTransitionStateOff(); // important... don't forget to add it in all modals
    };

    ///////////////////////////////

    // console.log('selected is ', selected);
    // console.log('selected Type is ', selectedType);

    return (
        <>
            <ModalContainer
                width='xl'
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={{ name: icon.name ?? selectedType.id, size: '4xl' }}
                rtl={rtl}
            >
                <ModalBody height='24'>
                    {intro ? <div className={introClass}>{intro}</div> : null}
                    {list && <ListBoxGeneric listData={listType} val={selectedType} handleChange={setSelectedType} />}
                    {children({ selectedType })}
                </ModalBody>

                <ModalFooter>
                    {hintObj && <ModalHelp helpText={translations[lang][hintObj]} isHtml />}
                    <ModalCTA>
                        <ModalButton
                            handleClick={handleTransitionStateOff}
                            label={translations[lang][cancelBtnObj.label]}
                            color={cancelBtnObj.color}
                            icon={cancelBtnObj.icon}
                        />
                        <ModalButton
                            handleClick={handleActionClick}
                            label={translations[lang][actionBtnObj.label]}
                            color={actionBtnObj.color}
                            icon={actionBtnObj.icon}
                        />
                    </ModalCTA>
                </ModalFooter>
            </ModalContainer>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(GenericModalList);
