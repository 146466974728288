/* eslint-disable react/jsx-pascal-case */
import { connect } from 'react-redux';
import moment from 'moment';
import CardXlEditDelete from 'components/blocks/CardXlEditDelete';
import { handleRowReverse } from 'helpers/FEutils';
import translations from 'constants/translations';
import { BlockInputSingle } from 'components/blocks/Blocks';
import { ModalButton } from 'components/blocks/ModalTre';
import GenericBtn from './GenericBtn';

function CalendarBooking({
    single = false,
    data = [],
    deleteCalendarDays,
    deleteCalendarDay,
    addCalendarDays,
    globalState: { lang },
    setShowCal,
    updateCapacityPrice
}) {
    const rtl = !!translations[lang].rtl;

    const handleCapacityPriceChange = (e, groupIndex, dateKey) => {
        const { name, value } = e.target;

        updateCapacityPrice(name, value, groupIndex, dateKey);
    };
    const checkForDeleteAllStatus = (individualDayData) => {
        // find if any day in the group has a booking and so dont allow delete of whole group
        return !Object.keys(individualDayData)
            .map((key) => {
                return individualDayData[key].allowDelete;
            })
            .includes(false);
    };

    const buildIndividualDays = (individualDayData, groupIndex) => {
        return Object.keys(individualDayData).map((key) => {
            const {
                price,
                capacity,
                allowDelete,
                // minCapacity,
                // minPrice,
                booked,
                isDisabled,
                errorObj: {
                    price: { error: priceError, msg: priceMsg },
                    capacity: { error: capacityError, msg: capacityMsg }
                }
            } = individualDayData[key];

            return (
                <div key={key}>
                    <div
                        className={`relative w-full items-center py-2 flex flex-col  md:flex-row md:${
                            handleRowReverse(rtl).flex
                        } flex-wrap md:flex-nowrap  
                    `}
                    >
                        {allowDelete && (
                            <CardXlEditDelete
                                handleDelete={() => {
                                    deleteCalendarDay(groupIndex, key);
                                }}
                                rtl={rtl}
                            />
                        )}
                        <div
                            className={`flex px-4 w-full flex-col gap-4 md:${
                                handleRowReverse(rtl).flex
                            } ${
                                handleRowReverse(rtl).pr
                            }-16 flex-wrap md:flex-nowrap  md:items-center`}
                        >
                            <div className='text-xs w-24'>
                                {moment(key).format('MMM Do YYYY')}
                            </div>
                            <BlockInputSingle
                                isDisabled={isDisabled}
                                normal
                                error={capacityError}
                                handleChange={(e) =>
                                    handleCapacityPriceChange(
                                        e,
                                        groupIndex,
                                        key
                                    )
                                }
                                id='capacity'
                                iconText='Max Capacity'
                                iconClass='text-xs'
                                iconPadding='pl-32 pr-4'
                                width='w-48'
                                height='h-10'
                                margins=''
                                value={capacity}
                                placeholder=''
                                rtl={rtl}
                                type={'number'}
                            />

                            <BlockInputSingle
                                isDisabled={isDisabled}
                                normal
                                error={priceError}
                                handleChange={(e) =>
                                    handleCapacityPriceChange(
                                        e,
                                        groupIndex,
                                        key
                                    )
                                }
                                id='price'
                                iconText='Price per person'
                                iconClass='text-xs'
                                iconPadding='pl-36 pr-4'
                                width='w-60'
                                height='h-10'
                                margins=''
                                value={price}
                                placeholder=''
                                rtl={rtl}
                                type={'number'}
                            />
                            <div className='w-full flex-shrink-0 flex justify-center md:w-48 '>{booked}</div>
                            <div className='flex-1 text-xs text-red-500 flex items-center gap-2'>
                                {priceError && <span>{priceMsg}</span>}
                                {capacityError && <span>{capacityMsg}</span>}
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    };

    const buildGroups = () => {
        return data.map((obj, index) => {
            const { individualDayData } = obj;
            const key = `group_${index}`;
            const allowDelete = checkForDeleteAllStatus(individualDayData);

            return (
                <li key={key}>
                    <div
                        className={`relative bg-green-100 mb-4 rounded-lg w-full items-center justify-between py-2 flex flex-col md:flex-row md:${
                            handleRowReverse(rtl).flex
                        } flex-wrap md:flex-nowrap  
                    `}
                    >
                        {allowDelete && (
                            <CardXlEditDelete
                                handleDelete={() => {
                                    deleteCalendarDays(index);
                                }}
                                rtl={rtl}
                            />
                        )}
                        <div
                            className={`flex px-4 w-full flex-col gap-3 md:flex-row md:${
                                handleRowReverse(rtl).flex
                            } ${
                                handleRowReverse(rtl).pr
                            }-16 flex-wrap md:flex-nowrap  md:items-center`}
                        >
                            <GenericBtn
                                params={[index]}
                                handleActionBtn={setShowCal}
                                bgColor='bg-green-400 hover:bg-gray-900'
                                textColor='text-green-900  hover:text-white'
                                rounded='rounded-full'
                                className={`transition-all 
                                    
                                    duration-300 out-expo w-24
                                    h-10 flex items-center justify-center `}
                            >
                                <i className={`ri-calendar-2-line text-xl`}></i>
                            </GenericBtn>
                            <div className='text-sm flex items-center gap-4'>
                                <span className='text-xs'>Group Name:</span>
                                <span>{obj.name}</span>
                            </div>
                        </div>
                    </div>
                    <div>{buildIndividualDays(individualDayData, index)}</div>
                </li>
            );
        });
    };

    return (
        <>
            <div className=''>
                <div className='w-full px-2'>
                    <div
                        className={`relative w-full items-center justify-between bg-kn-primary-75 hover:bg-green-100 transition-colors ease-in-out duration-300 rounded-lg py-2 flex flex-col md:flex-row md:${
                            handleRowReverse(rtl).flex
                        } flex-wrap md:flex-nowrap  mb-4 
                    `}
                    >
                        <div
                            className={`flex px-4 w-full flex-col gap-4 md:flex-row md:${
                                handleRowReverse(rtl).flex
                            } ${
                                handleRowReverse(rtl).pr
                            }-16 flex-wrap md:flex-nowrap  md:items-center text-xs`}
                        >
                            <div className='w-full flex-shrink-0 flex justify-center md:w-24 '>
                                Dates
                            </div>

                            <div className='w-full flex-shrink-0 flex justify-center md:w-48 '>
                                Capacity
                            </div>
                            <div className='w-full flex-shrink-0 flex justify-center md:w-60 '>
                                Price (US$)
                            </div>
                            <div className='w-full flex-shrink-0 flex justify-center md:w-60 '>
                                Booked
                            </div>
                            <div className='w-full flex-1 flex justify-center '>
                                Notes
                            </div>
                        </div>
                    </div>

                    <ul>{buildGroups()}</ul>
                </div>
                {!single && (
                    <div className='flex justify-center pt-4'>
                        <ModalButton
                            handleClick={addCalendarDays}
                            icon={{ name: 'PLUS' }}
                            color='plusdark'
                        />
                    </div>
                )}
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(CalendarBooking);
