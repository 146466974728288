/* eslint-disable arrow-body-style */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as types from 'store/actions/types';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic';
import React, { useState } from 'react';
import ConfirmModal from 'components/modals/ConfirmModal';
import CurrencyTypeModal from 'components/modals/overview/CurrencyTypeModal';
import { Section__Title } from 'components/blocks/Sections';
import { ButtonsAddMain } from 'components/blocks/Buttons';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import SectionCurrencySingle from './SectionCurrencySingle';
import { defaultCurrency } from 'constants/experienceDetailConsts';
import { addLocalCurrency, deleteLocalCurrency, updateLocalCurrency } from 'store/actions/experienceDetails/currency';
import OnboardingSection from 'components/specialty/OnboardingSection';
import classNames from '../../../node_modules/classnames/index';

function SectionCurrency(props) {
    const {
        experienceDetailsAction,
        actionType,
        experienceDetails: { experience },
        globalState: { lang, edit, isFeedbackMode },
        addLocalCurrency,
        deleteLocalCurrency,
        updateLocalCurrency,
        title = uiStruct.overview.currency_paying.uiPreview.headTitle,
        addBtn = uiStruct.overview.currency_paying.uiEdit.buttons.addMain
    } = props;

    const hintObj = uiStruct.overview.currency_paying.uiPreview.hint;
    const hintObjEmpty = uiStruct.overview.currency_paying.uiPreview.hintEmpty;

    const rtl = !!translations[lang].rtl;

    const {
        local_currency: currencyList,
        destination: { locations: currCountryList },
        local_currency_visible: { isVisible: isSectionVisible = true }
    } = experience;
    const currencySetList = currencyList.reduce((last, next) => [...last, next.countryCode], []);
    const currencyLocationCodes = currCountryList.reduce((last, next) => [...last, next[0].countryCode], []);
    const countriesNotUsedYet = currencyLocationCodes.filter((code) => !currencySetList.includes(code));
    const [delConfirmModalState, setDelConfirmModalState] = useState(false);
    const [cTypeModal, setCTypeModal] = useState(false);
    const [updateIndex, setUpdateIndex] = useState(null);
    const [updateObj, setUpdateObj] = useState(null);
    const hasData = currCountryList.length;
    const show = edit ? true : !edit && !hasData ? false : true;

    const handleCreateCurrency = () => {
        if (countriesNotUsedYet.length === 1) {
            addLocalCurrency({
                ...defaultCurrency(),
                countryCode: countriesNotUsedYet[0]
            });
        } else {
            setUpdateIndex(null);
            setCTypeModal(true);
        }
    };
    const updateCurrency = (index) => {
        setUpdateIndex(index);
        setUpdateObj({
            ...currencyList[index]
        });
        setCTypeModal(true);
    };

    const updateCreateCurrency = (dataObj) => {
        if (updateIndex !== null) {
            updateLocalCurrency(updateIndex, dataObj);
        } else {
            addLocalCurrency(dataObj);
        }
    };

    const removeModal = (index) => {
        setUpdateIndex(index);
        setDelConfirmModalState(true);
    };

    const confirmRemove = (index) => {
        deleteLocalCurrency(updateIndex);
        setUpdateIndex(null);
    };

    const buildPanel = (provided) => {
        return (
            <div>
                {currencyList.map((datObj, index, arr) => {
                    const key = `mr_${datObj.countryCode}${index}`;

                    return (
                        <div key={key}>
                            <SectionCurrencySingle
                                index={index}
                                edit={edit}
                                data={datObj}
                                removeModal={removeModal}
                                actionType={actionType}
                                updateCurrency={updateCurrency}
                                countriesNotUsedYet={countriesNotUsedYet.length !== 0}
                                isVisible={isSectionVisible}
                                handleVisible={handleVisible}
                            />
                        </div>
                    );
                })}

                {edit && !isFeedbackMode && !!countriesNotUsedYet.length && (
                    <div className='flex items-center justify-center w-full'>
                        <ButtonsAddMain
                            handleClick={handleCreateCurrency}
                            label={hasData ? translations[lang][addBtn.label] : translations[lang][addBtn.label_empty]}
                            rtl={rtl}
                        />
                    </div>
                )}
                {delConfirmModalState && <ConfirmModal setModalIsOpen={setDelConfirmModalState} handleActionBtn={confirmRemove} />}
            </div>
        );
    };

    const handleVisible = () => {
        experienceDetailsAction({
            type: types.UPDATE_SECTION_VISIBILITY,
            val: !isSectionVisible,
            objName: 'local_currency_visible'
        });
    };

    return (
        <>
            {(edit || isSectionVisible) && (
                // <div
                // className={`w-full ${edit ? 'touch:-mt-8' : 'touch:-mt-28'} touch:py-10`}>
                <div className={classNames('w-full touch:py-10', edit ? 'touch:-mt-8 ' : 'touch:-mt-28', 'mt-8')}>
                    <Section__Title
                        title={translations[lang][title]}
                        edit={edit}
                        hasData={true}
                        hintObj={hintObj}
                        handleVisible={handleVisible}
                        isVisible={isSectionVisible}
                        className={`${isSectionVisible ? '-mb-4 lg:-mb-8' : 'mb-4 lg:mb-0'}`}
                    />
                    {isSectionVisible && <OnboardingSection visible={hasData} data={hintObjEmpty} />}

                    {isSectionVisible && buildPanel()}
                    {cTypeModal && (
                        <CurrencyTypeModal
                            setModalIsOpen={setCTypeModal}
                            handleActionBtn={updateCreateCurrency}
                            defaultObj={defaultCurrency}
                            countryList={countriesNotUsedYet}
                            updateObj={updateObj}
                            update={updateIndex !== null}
                        />
                    )}
                </div>
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addLocalCurrency,
            deleteLocalCurrency,
            updateLocalCurrency,
            experienceDetailsAction
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionCurrency);
