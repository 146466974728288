/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchExpReport } from 'fetch/experiences';
import ModalFullscreen, { ModalBody } from 'components/blocks/ModalFullscreen';
import { ModalFooter, ModalCTA, ModalButton } from 'components/blocks/ModalTre';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import Spinner from 'components/blocks/Spinner';
import BasicTable from 'components/table/BasicTable';
import PurchaseReportDigitalStruct from 'components/table/tableStructs/PurchaseReportDigitalStruct';
import PurchaseReportGuidedStruct from 'components/table/tableStructs/PurchaseReportGuidedStruct';
import CsvDownloadLink from 'components/blocks/CsvDownloadLink';

// should be in all modals
const targetElement = document.querySelector('#root');

function ExpAccountsModal({
    type,
    fetchExpReport,
    isAdminStaff = false,
    isOwner = true,
    close,
    setModalIsOpen,
    globalState: { lang, isSuperAdmin },
    icon = {
        name: 'CALENDAR',
        size: '2xl'
    },
    modalTitle = 'Calendar',
    cancelBtnObj = uiStruct.itinerary.moving_around.uiEdit.modals.vendor.buttons.cancel,
    expId
}) {
    const rtl = !!translations[lang].rtl;
    const [loadingData, setLoadingData] = useState(true);
    const [columData, setColumnData] = useState([]);
    const handlePurchaseClick = (purchaseId) => {
        console.log(currentData.current[purchaseId]);
    };
    const columns = type.toLowerCase() === 'digital' ? PurchaseReportDigitalStruct(handlePurchaseClick) : PurchaseReportGuidedStruct(handlePurchaseClick);
    // State for triggering animation of modal in and out
    const [transitionState, setTransitionState] = useState('off');
    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    ///////////////////////////////////////////////////////////////////
    // Paul stuff Handle Action BTN function

    const createTableData = (data) => {
        currentData.current = {};
        const tData = data.map((purchase, index) => {
            const {
                id,
                createdAt,
                experience_id,
                publish_id,
                people,
                travel_date,
                title,
                featured_image,
                canceled,
                order: {
                    items, // items is an array but will ony ever have 1 entry per order
                    sub_total: subTotal,
                    discount_total: discountTotal,
                    grand_total: grandTotal
                },
                user,
                payout
            } = purchase;
            const { price } = items[0];
            const returnData = {
                id,
                purchaseId: id,
                createdAt,
                experience_id,
                publish_id,
                people,
                travel_date,
                title,
                featured_image,
                canceled,
                price,
                subTotal,
                discountTotal,
                grandTotal,
                payout
                //order
            };

            currentData.current[id] = {
                user,
                ...returnData
            };

            return returnData;
        });

        setColumnData(tData);
    };
    const [nextPageLoading, setNextPageLoading] = useState(false);
    const pageCount = useRef(0);
    const currentPage = useRef(0);
    const currentData = useRef({});
    const limit = Number(process.env.REACT_APP_TABLE_ROW_COUNT);
    const fetchTheData = (pageIndex = 0, pageSize = limit) => {
        setNextPageLoading(true);
        currentPage.current = pageIndex;
        const start = pageIndex * pageSize;
        fetchExpReport({ pubId: expId, start, limit: pageSize }).then((res) => {
            if (res?.value?.data?.data?.purchaseCount) {
                const purchaseCount = res?.value?.data?.data?.purchaseCount;
                pageCount.current = Math.ceil(purchaseCount / pageSize);

                if (res?.value?.data?.data?.purchasesByPubId) {
                    createTableData(res?.value?.data?.data?.purchasesByPubId);
                }
            }
            setNextPageLoading(false);
            setLoadingData(false);
        });
    };

    useEffect(() => {
        fetchTheData();
    }, []);

    return (
        <>
            <ModalFullscreen
                width='4xl'
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={icon}
                close={close}
                rtl={rtl}
                fullscreen={true}
            >
                <ModalBody rtlOff>
                    <div className='max-w-7xl mx-auto'>
                        <div className='w-full  px-4 md:px-8 lg:px-2 mb-8 mt-4'>Purchase Data</div>
                        <div className='w-full  px-4 md:px-8 lg:px-2 mb-8 mt-4'>
                            <>
                                {columData.length ? (
                                    <BasicTable
                                        nextPageLoading={nextPageLoading}
                                        disableSortBy={true}
                                        columns={columns}
                                        data={columData}
                                        pCount={pageCount.current}
                                        manualFunc={fetchTheData}
                                        currentPage={currentPage.current}
                                        limit={limit}
                                    />
                                ) : (
                                    <>
                                        {!loadingData ? (
                                            <p>No orders</p>
                                        ) : (
                                            <div className='flex flex-col justify-center items-center'>
                                                <span className='flex justify-center'>
                                                    <Spinner size='64' strokeWidth='20' strokeColor={'black'} />
                                                </span>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        </div>
                        <div className='mt-8 mb-8 px-4 md:px-10'>
                            <>
                                {columData.length ? (
                                    <CsvDownloadLink getCsvData={fetchExpReport} expId={expId} type={type.toLowerCase()}>
                                        Export CSV
                                    </CsvDownloadLink>
                                ) : null}
                            </>
                        </div>
                        <div className='mt-8 mb-8 px-4 md:px-10'>
                            <ModalFooter>
                                <ModalCTA>
                                    <ModalButton handleClick={handleTransitionStateOff} label='Close' color={cancelBtnObj.color} icon={cancelBtnObj.icon} />
                                </ModalCTA>
                            </ModalFooter>
                        </div>
                    </div>
                </ModalBody>
            </ModalFullscreen>
        </>
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchExpReport
        },
        dispatch
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpAccountsModal);
