/* eslint-disable arrow-body-style */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as types from 'store/actions/types';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic';
import React, { useState } from 'react';
import CardXl from 'components/blocks/CardXl';
import { PillContainer, PillIcon, PillTitle } from 'components/blocks/Pills';

import ConfirmModal from 'components/modals/ConfirmModal';
import { Section__Title } from 'components/blocks/Sections';
import { ButtonsAddMain } from 'components/blocks/Buttons';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import TipList from 'components/blocks/TipList';
import OnboardingSection from 'components/specialty/OnboardingSection';
import classNames from '../../../node_modules/classnames/index';

function SectionTips(props) {
    const {
        actionType,
        experienceDetails: {
            experience: {
                tips_html: tipsHtml,
                general_tips_visible: { isVisible: isSectionVisible = true, objId }
            }
        },

        globalState: { lang, edit, isPrintMode, isFeedbackMode },
        title = uiStruct.overview.general_tips.uiPreview.headTitle,
        subTitle = uiStruct.overview.general_tips.uiPreview.subTitle,
        icon = uiStruct.general_tips.uiPreview.icon,
        experienceDetailsAction
    } = props;

    const rtl = !!translations[lang].rtl;

    const [isMinimized, setIsMinimized] = useState(false);

    const handleVisible = () => {
        experienceDetailsAction({
            type: types.UPDATE_SECTION_VISIBILITY,
            val: !isSectionVisible,
            objName: 'general_tips_visible'
        });
    };

    return (
        <>
            {(edit || isSectionVisible) && (
                <div className={classNames('w-full touch:py-10', edit ? 'touch:-mt-8 ' : 'touch:-mt-28', 'mt-8')}>
                    <Section__Title
                        title={translations[lang][title]}
                        edit={edit}
                        hasData
                        hintObj={uiStruct.overview.general_tips.uiPreview.hint}
                        handleVisible={handleVisible}
                        isVisible={isSectionVisible}
                        className={classNames(isSectionVisible && '-mb-4 lg:-mb-8')}
                    />

                    {isSectionVisible && (
                        <div className={`${isMinimized ? '-mt-4 xl:-mt-8 mb-16' : ''}`}>
                            <CardXl
                                isFeedbackMode={isFeedbackMode}
                                objId={objId?.section}
                                edit={edit}
                                isPrintMode={isPrintMode}
                                rtl={rtl}
                                hidden={isMinimized}
                                isVisible={isSectionVisible}
                                handleVisible={handleVisible}
                                handleMinimize={() => setIsMinimized(!isMinimized)}
                                hideEye
                            >
                                <div className={`mt-12 min-h-32 ${isMinimized ? 'hidden' : ''}`}>
                                    <TipList
                                        objId={objId?.tiplist}
                                        // max
                                        actionType={actionType}
                                        tips={tipsHtml}
                                        hintObj={uiStruct.itinerary.activities.uiEdit.modals.tip.hint}
                                        icon={uiStruct.itinerary.activities.uiEdit.modals.tip.icon}
                                        offset={0}
                                        isVisible={isSectionVisible}
                                    />

                                    {tipsHtml && tipsHtml.length === 0 ? (
                                        <div className='text-lg italic text-gray-400'>{translations.en['OV_TIPS_V_HINT_EMPTY']}</div>
                                    ) : null}
                                </div>
                            </CardXl>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            experienceDetailsAction
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionTips);
