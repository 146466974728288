/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ShepherdTour } from 'react-shepherd';
import { firstSteps, tourOptions } from './steps';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setExperiencesAll } from 'store/actions/experienceDetails/master';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic';
import { fetchExperiencesAll, fetchPublishedExpData, genericMutation, fetchUsersListAdmin, deleteExperience } from 'fetch/experiences';
//  import { defaultMarketing } from 'constants/experiencesConsts';
import {
    togglePrintMode,
    toggleEdit,
    toggleFeedbackMode,
    toggleSuperAdmin,
    setIsOwnerMode,
    setIsAdminStaffMode,
    setIsEduContentMode
} from 'store/actions/globalState/master';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import ExperienceList from 'components/blocks/ExperienceList';
import { AxiosAbortService } from 'helpers/AxiosAbortService';
import { clearAllExperiences } from 'store/actions/experienceDetails/general';
import { handleRowReverse } from 'helpers/FEutils';
import * as types from 'store/actions/types';
import LayoutMenuListEdit from 'components/layouts/LayoutMenuListEdit';
import 'styles/shepherd.css';
import GenericSelectList from 'components/blocks/GenericSelectList.js';
import { useScroll } from 'helpers/useScroll';
import { deletePublishedMutation } from 'helpers/graphql';
import useStatuses from 'helpers/useStatuses';
import {
    // isPublishedViewFunc,
    isAdminStaffFunc
} from 'helpers/statusHelper';
import LayoutHelpBar from 'components/layouts/LayoutHelpBar';
import { useOnlineStatus } from 'helpers/useOnlineStatus';
import classNames from 'classnames';
import Footer2 from 'components/layouts/Footer2';
import RecommendBar from 'components/blocks/RecommendBar';

import LayoutNavbar from 'components/layouts/LayoutNavbar';


const PageExperiences = (props) => {
    const {
        toggleEdit,
        togglePrintMode,
        toggleFeedbackMode,
        toggleSuperAdmin,
        setIsOwnerMode,
        setIsAdminStaffMode,
        setIsEduContentMode,
        globalState: {
            inMode,
            lang,
            status: { priceSaving, tagsCatsSaving, includesSaving },
            // siteData,
            siteData: { loading: siteDataLoading },
            isSuperAdmin
        },
        auth,
        auth: {
            user: { role: userRole }
        },

        experiences: { experiences, totalExperiences },
        experienceDetails: { updated: waitForUpdateToFinish },
        fetchExperiencesAll,
        clearAllExperiences,
        experienceDetailsAction,
        fetchPublishedExpData,
        genericMutation,
        fetchUsersListAdmin,
        deleteExperience
    } = props;
    const history = useNavigate();
    const isOnline = useOnlineStatus();
    const { isOwner, isEduContent } = useStatuses();
    // const isPublishedView = isPublishedViewFunc();
    const isAdminStaff = isAdminStaffFunc(userRole);

    useEffect(() => {
        setIsAdminStaffMode(isAdminStaff);
        // setIsPublishedViewMode(isPublishedView);
        setIsEduContentMode(isEduContent);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAdminStaff, isEduContent]);

    useEffect(() => {
        setIsOwnerMode(false);
        togglePrintMode(false);
        toggleFeedbackMode(false);
        toggleSuperAdmin(false);
        toggleEdit(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showHeader = useScroll();
    const rtl = !!translations[lang].rtl;
    const currentViewMode = useRef(inMode);
    const [pageLoad, setPageLoading] = useState(true);
    const fetchExperiences = () => {
        if (inMode === 'owned') {
            experienceDetailsAction({
                type: types.SET_TO_PUBLISHED,
                dataObj: 'kreo'
            });
        } else {
            fetchExperiencesAll().then((res) => {
                setPageLoading(false);
            });
        }
    };
    const [totExpDisplayed, setTotExpDisplayed] = useState(0);

    // const isAdminStaff =
    //     userRole === process.env.REACT_APP_STRAPI_ADMIN_ROLE_ID ||
    //     userRole === process.env.REACT_APP_STRAPI_ADMIN_ROLE_ID;

    // const [videoModalState, setVideoModalStatus] = useState(false);
    useEffect(() => {
        if (!priceSaving.length && !tagsCatsSaving.length && !includesSaving.length && pageLoad && !waitForUpdateToFinish && !siteDataLoading) {
            fetchExperiences();
        }
    }, [priceSaving, tagsCatsSaving, includesSaving, waitForUpdateToFinish, siteDataLoading]);

    useEffect(() => {
        if (isAdminStaff) {
            fetchUsersListAdmin();
        }
        return () => {
            AxiosAbortService.clearRequest('allExperiences');
            clearAllExperiences();
        };
    }, []);

    const handleDeleteExperience = (expId, pubId) => {
        setPageLoading(true);
        if (inMode === 'marketplace') {
            fetchPublishedExpData(expId).then((res) => {
                if (res.action) {
                    genericMutation(deletePublishedMutation(pubId, expId), types.DELETE_PUBLISHED).then(() => {
                        fetchExperiences();
                    });
                }
            });
        } else {
            deleteExperience(expId).then(() => {
                fetchExperiences();
            });
        }
    };

    const handleViewType = (type) => {
        experienceDetailsAction({
            type: types.SET_TO_PUBLISHED,
            dataObj: type
        });
        if (type === 'owned') {
            history(`${process.env.REACT_APP_BASENAME}/purchased`, { replace: true });
        }
    };
    useEffect(() => {
        if (inMode !== currentViewMode.current) {
            currentViewMode.current = inMode;
            setPageLoading(true);
            fetchExperiences();
        }
    }, [inMode]);

    return auth.isProfile ? (
        <ShepherdTour steps={firstSteps} tourOptions={tourOptions}>
            <LayoutNavbar />
            <LayoutHelpBar />
            <div className='w-full md:mt-16'>
                <LayoutMenuListEdit
                    rtl={rtl}
                    isOwner={isOwner}
                    isAdminStaff={isAdminStaff}
                    isSuperAdmin={isSuperAdmin}
                    handleSuperAdmin={toggleSuperAdmin}
                    showHeader={showHeader}
                />
                <RecommendBar />

                <div
                    className={`relative 
                        max-w-2xl md:max-w-3xl lg:max-w-4xl xl:max-w-6xl mx-auto pt-12 md:pt-0 px-0 md:px-4 lg:px-0`}
                >
                    <div className='flex justify-between items-center mb-4 px-4'>
                        <div
                            className={`${
                                handleRowReverse(rtl).rtl
                            }  text-transparent bg-clip-text bg-gradient-to-tr from-gray-900 via-blue-500 to-green-400 text-3xl md:text-4xl leading-none  font-bold l py-2 `}
                        >
                            {isAdminStaff ? 'Kreations' : translations[lang][uiStruct.ui.experience.mykreations]}
                        </div>
                    </div>

                    <div className={classNames('flex flex-col gap-2 w-full mb-8 text-sm md:text-sm px-4 ')}>
                        <div>All your built experiences are here.</div>
                        <div className='flex items-center gap-2 flex-wrap'>
                            To manage pricing, booking or orders for Approved Experiences, go to
                            <Link
                                className='bg-green-100 text-xs text-green-600 rounded-lg px-3 py-1 flex items-center w-max gap-2 hover:bg-gray-900 hover:text-white'
                                to={`${process.env.REACT_APP_BASENAME_PUBLISHED}/all`}
                            >
                                <i className='ri-store-line text-lg'></i>
                                <span>My Published Kreations</span>
                            </Link>
                        </div>
                    </div>

                    {!pageLoad && isAdminStaff ? (
                        <div className={classNames('mt-4 flex flex-col md:flex-row gap-6 w-full items-center mb-4 ')}>
                            <div className='flex items-center px-4 md:px-0 gap-4 w-full md:w-auto'>
                                <div className='text-sm ml-4'>Where?</div>

                                <div className='w-full md:w-48'>
                                    <GenericSelectList
                                        selectOptions={[
                                            {
                                                id: 'marketplace',
                                                label: 'Publishing Section'
                                            },
                                            {
                                                id: 'kreo',
                                                label: 'Design Section'
                                            }
                                            // {
                                            //     id: 'owned',
                                            //     label: 'Purchased'
                                            // }
                                        ]}
                                        disabled={!isOnline}
                                        handleChange={handleViewType}
                                        initialSelected={inMode}
                                        bgColor='#ffffff'
                                        height='2.5rem'
                                    />
                                </div>
                            </div>

                            <div className='text-sm'>
                                {inMode === 'marketplace' && (
                                    <>
                                        This section holds all approved experiences. They will be published by Konnect once all criterias like pricing and
                                        booking information are filled and completed.
                                    </>
                                )}
                                {inMode === 'kreo' && <>This is where you build and edit your experiences</>}
                            </div>
                        </div>
                    ) : null}

                    <ExperienceList
                        inMode={inMode}
                        viewPublished={inMode === 'marketplace'}
                        isAdminStaff={isAdminStaff}
                        setTotExpDisplayed={setTotExpDisplayed}
                        totExpDisplayed={totExpDisplayed}
                        fetchExperiences={fetchExperiences}
                        setPageLoading={setPageLoading}
                        pageLoad={pageLoad}
                        experiences={experiences}
                        totalExperiences={totalExperiences}
                        handleNewLabel={translations[lang][uiStruct.ui.experience.add_experience]}
                        handleDeleteExperience={handleDeleteExperience}
                    />
                </div>
            </div>
            <Footer2 isBody={true} isBottomBar={true} />
        </ShepherdTour>
    ) : (
        <Navigate to='/c/accounts/profile' />
    );
};

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    experiences: state.experiences,
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleEdit,
            togglePrintMode,
            toggleFeedbackMode,
            setExperiencesAll,
            fetchExperiencesAll,
            clearAllExperiences,
            toggleSuperAdmin,
            experienceDetailsAction,
            fetchPublishedExpData,
            genericMutation,
            fetchUsersListAdmin,
            setIsOwnerMode,
            setIsAdminStaffMode,
            setIsEduContentMode,
            deleteExperience
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageExperiences);
