import React from 'react';
import moment from 'moment';
// import { destinationObj } from 'helpers/LocaleHelper';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import KreatorBadge from 'components/blocks/KreatorBadge';
import { toggleNav } from 'store/actions/globalState/master';
import { Clock, MapPin } from 'lucide-react';
// import { toggleLang, toggleNav } from 'store/actions/globalState/master';
// import { User, Clock, MapPin, Users } from 'lucide-react';
// import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import Icons from 'components/blocks/Icons';
import Image from 'components/blocks/Image';

import { country } from 'helpers/LocaleHelper';
import ExperiencePurchasedCardBar from 'components/blocks/ExperiencePurchasedCardBar';

const baseUrl = {
    kreatorPage: '/kreator',
    experiencePage: '/experience',
    avatar: '/assets/media/kreators',
    experienceImageFeatured: '/assets/media/results'
};

// const currencyPrefix = '$US';

const ExperiencePurchasedCard = ({
    disabled = false,
    guided = false,
    expId,
    data: { type, user: author, createdAt, featured_image, title, days, places_lists, people, travel_date, swellbypass },
    globalState: { lang, isSuperAdmin },
    toggleNav,
    auth: {
        user: { role: userRole, _id: userId }
    },
    index
}) => {
    const linkType = type.toLowerCase() === 'guided' ? 'marketing' : '0';

    return (
        <div className={`my-3 px-2 lg:my-4 lg:px-2 `}>
            <div className='group-scope relative bg-transparent transition-all duration-300 transform rounded-xl hover:shadow-xl hover:-translate-y2-1 hover:bg-white'>
                <div className=''>
                    <KreatorBadge author={author} baseUrl={baseUrl} nolink fixEdge={true} />
                </div>
                {true && (
                    <ExperiencePurchasedCardBar
                        swellbypass={swellbypass}
                        guided={guided}
                        // viewPublished={viewPublished}
                        // completion={completion}
                        // type={type}
                        // handleGuided={handleGuidedAction}
                        // handleDigital={handleDigitalAction}
                        // handleDelete={handleDelete}
                        // isOwner={isOwner}
                        // isAdminStaff={isAdminStaff}
                        // experienceStatus={experienceStatus}
                        // inPublished={inPublished}
                        // feedbackAction={handleFeedback}
                        // isSuperAdmin={isSuperAdmin}
                    />
                )}

                <div className=''>
                    <Link
                        to={`/purchased/${expId}/0`}
                        onClick={(e) => (disabled ? e.preventDefault() : toggleNav(false))}
                        className={disabled ? 'pointer-events-none cursor-default' : ''}
                    >
                        <div className={` w-full h-56 md:h-36 lg:h-44 xl:h-56 relative rounded-xl overflow-hidden  bg-gray-200 `}>
                            {featured_image ? (
                                <Image disabled={disabled} size='600' src={featured_image} className='object-cover w-full md:h-36 lg:h-44 xl:h-56' />
                            ) : (
                                <div className='rounded-xl bg-gray-200 w-full md:h-36 lg:h-44 xl:h-56'>
                                    <div className='relative h-full'>
                                        <div className='text-xs whitespace-nowrap tracking-widest uppercase absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                                            <Icons iName='IMAGEALT2' />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Link>
                </div>
                <div className='flex justify-around w-full transform -mt-4 px-4'>
                    <div className='uppercase rounded-full h-8 flex justify-center items-center bg-gray-900 text-xxs text-kn-primary tracking-widest px-6'>
                        {type || 'digital'}
                    </div>
                </div>
                <div className='p-2 sm:p-4'>
                    <div className='flex items-center justify-between leading-tight text-black font-sans text-xs md:text-sm'>
                        <span className='flex'>
                            {title ? (
                                title
                            ) : (
                                <span className='flex'>
                                    <span className='w-48 bg-gray-300 rounded-xl h-3' />
                                </span>
                            )}
                        </span>
                    </div>
                    <div className='mt-2 flex flex-wrap items-center font-sans text-xs text-gray-900'>
                        <div className='flex items-center mr-8 py-1'>
                            <span className='text-kn-primary mr-2'>
                                <MapPin size={18} />
                            </span>
                            {places_lists.length > 0 ? (
                                places_lists.map((item, index, itemArray) => {
                                    return (
                                        <span key={`${item.name}_${index}`}>
                                            <span className=''>{country(lang, item.code)}</span>
                                            {index < itemArray.length - 1 && <span className='px-1'>.</span>}
                                        </span>
                                    );
                                })
                            ) : (
                                <span key={index} className='w-20 bg-gray-300 rounded-full h-2' />
                            )}
                        </div>
                        <div className='flex items-center mr-8 py-1'>
                            <span className='text-kn-primary mr-2'>
                                <Clock size={18} />
                            </span>

                            {days ? `${days}  ${days > 1 ? 'Days' : 'Day'}` : <span className='w-8 bg-gray-300 rounded-full h-2' />}
                        </div>
                    </div>
                    {guided && (
                        <div className='mt-4'>
                            <div className='flex flex-wrap items-center gap-2'>
                                <span className='text-xxs bg-green-100 text-green-600 whitespace-nowrap rounded-full px-2 py-0.5'>
                                    {`Travel Date:  ${moment(travel_date).format('MMM Do YYYY')}`}
                                </span>
                                <span className='text-xxs bg-blue-100 text-blue-600 whitespace-nowrap rounded-full px-2 py-0.5'>People: {people}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleNav
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ExperiencePurchasedCard);
