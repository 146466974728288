/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import Geocode from 'react-geocode';
import { GoogleMap, Marker } from '@react-google-maps/api';

import MapSearch from 'components/map/MapSearch';
import MapFindMe from 'components/map/MapFindMe';
import { BlockInputSingle } from 'components/blocks/Blocks';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { ModalButton } from 'components/blocks/ModalTre';
import { handleRowReverse } from 'helpers/FEutils';
import ListBoxGeneric from 'components/blocks/ListBoxGeneric';
import MapStatus from 'components/blocks/MapStatus';
import CollapseButton from 'components/blocks/CollapseButton';

const libraries = ['places'];
const mapContainerStyle = {
    height: '60vh',
    width: '100%'
};

const mapOptions = {
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    clickableIcons: false,
    gestureHandling: 'greedy',
    mapId: '8e0ece1c437d65ae'
};

const center = {
    lat: 0,
    lng: 0
};

const MapBox = ({
    globalState: {
        edit,
        lang,
        googleMaps: {
            loading: { isLoading },
            error: { isError }
        }
    },
    handleMarkerUpdate,
    handleAddressUpdate,
    locationData,
    addresError,
    type = 'default',
    accommodationData = {},
    setAccommData = null
}) => {
    const rtl = !!translations[lang].rtl;
    // const { isLoaded, loadError } = useLoadScript({
    //     googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    //     libraries,
    // });
    const [singleMarker, setSingleMarker] = useState({ ...locationData.data });
    const [address, setAddress] = useState('tertetert');
    const [panPlace, setPanPlace] = useState(null);
    const mapRef = useRef();
    const [mapReady, setMapReady] = useState(null);
    const containerRef = useRef(null);
    // Map states
    const accommTypes =
        type === 'accommodation'
            ? uiStruct.overview.accommodation.uiEdit.hotel_list.map((key) => {
                  return {
                      id: key,
                      name: translations[lang][key],
                      unavailable: false
                  };
              })
            : null;
    const [selectedAccommType, setSelectedAccommType] = useState(() => {
        return type === 'accommodation'
            ? accommTypes[
                  accommTypes.findIndex((obj) => {
                      return obj.id === accommodationData.type;
                  })
              ]
            : null;
    });

    const ratingTypes =
        type === 'accommodation'
            ? uiStruct.overview.accommodation.uiEdit.rating_list.map((key) => {
                  return {
                      id: key,
                      name: translations[lang][key],
                      unavailable: false
                  };
              })
            : null;
    const [selectedRatingType, setSelectedRatingType] = useState(() => {
        return type === 'accommodation'
            ? ratingTypes[
                  ratingTypes.findIndex((obj) => {
                      return obj.id === accommodationData.rating;
                  })
              ]
            : null;
    });

    const handleSetAddress = (address) => {
        handleAddressUpdate(address);
        setAddress(address);
    };

    const handleSetMarker = (marker) => {
        handleMarkerUpdate({
            ...marker
        });
        setSingleMarker({
            ...marker
        });
    };

    const updateZoomOnIdle = (zoom) => {
        handleMarkerUpdate({
            ...singleMarker,
            zoom
        });
        setSingleMarker({
            ...singleMarker,
            zoom
        });
    };

    // Adding the marker(s)
    const onMapClick = useCallback((e) => {
        handleSetMarker({
            zoom: mapRef.current.getZoom(),
            location: {
                lat: e.latLng.lat(),
                lng: e.latLng.lng()
            }
        });
    }, []);

    // reference to the G-map object
    // On Map Load and On map Idle (ie not panning or zooming)
    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
        setMapReady(mapRef.current);
    }, []);

    const onMapIdle = () => {
        if (mapRef.current) {
            // window.google.maps.event.trigger(mapRef.current, 'resize');
            updateZoomOnIdle(mapRef.current.getZoom());
        }
    };

    const findMe = () => {
        if (mapRef.current) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    panTo({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    });
                    mapRef.current.setZoom(15);
                },
                () => null
            );
        }
    };

    const onMapBoundsChanged = () => {
        if (panPlace) {
            handleSetMarker({
                zoom: mapRef.current.getZoom(),
                location: {
                    lat: panPlace.location.lat,
                    lng: panPlace.location.lng
                },
                time: new Date()
            });
        }
    };

    // Pan to location
    const panTo = useCallback(({ lat, lng }, placeBounds) => {
        if (mapRef.current) {
            mapRef.current.panTo({ lat: Number(lat), lng: Number(lng) });

            setPanPlace({
                bounds: placeBounds,
                location: {
                    lat,
                    lng
                }
            });
        }
    }, []);

    useEffect(() => {
        if (mapRef.current && panPlace && panPlace.bounds)
            mapRef.current.fitBounds(panPlace.bounds);
    }, [panPlace]);

    useEffect(() => {
        setPanPlace(null);
    }, [singleMarker]);

    useEffect(() => {
        let resizeTmRef = null;
        let tmRef = null;
        if (mapReady) {
            tmRef = setTimeout(() => {
                handleSetMarker(locationData.data);
                handleSetAddress(locationData.address);
                panTo(locationData.data.location);
                mapRef.current.setZoom(locationData.data.zoom);
                window.google.maps.event.addDomListener(
                    containerRef.current,
                    'resize',
                    function () {
                        const center = mapRef.current.getCenter();
                        window.google.maps.event.trigger(
                            mapRef.current,
                            'resize'
                        );
                        mapRef.current.setCenter(center);
                    }
                );
            }, 1);
            resizeTmRef = setTimeout(() => {
                containerRef.current.style.width = '99%';
            }, 1000);
        }

        return () => {
            clearTimeout(tmRef);
            clearTimeout(resizeTmRef);
        };
    }, [mapReady]);

    // Guess address from Geolocation coordinates

    // const cacheKeywordArray = (response) => {
    //     const plusCodePrefix = response.plus_code.global_code.slice(4, 6);
    //     const newArr = response.results.flatMap((singleArr) => {
    //         return singleArr.address_components
    //             .filter(
    //                 (item) =>
    //                     item.long_name.slice(0, 2) !== plusCodePrefix &&
    //                     item.short_name.slice(0, 2) !== plusCodePrefix &&
    //                     !item.types.includes('administrative_area_level_1') &&
    //                     !item.types.includes('administrative_area_level_2')
    //             )
    //             .flatMap((item) => [item.long_name, item.short_name]);
    //     });
    //     return Array.from(new Set(newArr));
    // };

    const filterData = (data, plusCodePrefix) => {
        return data.address_components
            .filter(
                (item) =>
                    item.long_name.slice(0, 2) !== plusCodePrefix &&
                    item.short_name.slice(0, 2) !== plusCodePrefix
            )
            .flatMap((item) => [item.long_name, item.short_name]);
    };

    const cacheKeywordArray = (response) => {
        const plusCodePrefix = response.plus_code.global_code.slice(4, 6);

        const newObj = {
            l1: [],
            l2: [],
            l3: [],
            other: []
        };
        const checkAgainstArr = [
            { name: 'administrative_area_level_1', key: 'l1' },
            { name: 'administrative_area_level_2', key: 'l2' },
            { name: 'administrative_area_level_3', key: 'l3' },
            { name: '', key: 'other' }
        ];
        response.results.forEach((singleArr) => {
            for (const checkOne of checkAgainstArr) {
                if (singleArr.types.includes(checkOne.name)) {
                    newObj[checkOne.key] = [
                        ...newObj[checkOne.key],
                        filterData(singleArr, plusCodePrefix).flat()
                    ].flat();
                } else {
                    newObj.other = [
                        ...newObj.other,
                        filterData(singleArr, plusCodePrefix).flat()
                    ].flat();
                }
            }
        });
        const newObj2 = {
            l1: Array.from(new Set(newObj.l1)),
            l2: Array.from(new Set(newObj.l2)),
            l3: Array.from(new Set(newObj.l3)),
            other: Array.from(new Set(newObj.other))
        };
        return newObj2;

        // const newArr = response.results.flatMap((singleArr) => {
        //     return singleArr.address_components
        //         .filter(
        //             (item) =>
        //                 item.long_name.slice(0, 2) !== plusCodePrefix &&
        //                 item.short_name.slice(0, 2) !== plusCodePrefix &&
        //                 !item.types.includes('administrative_area_level_1') &&
        //                 !item.types.includes('administrative_area_level_2')
        //         )
        //         .flatMap((item) => [item.long_name, item.short_name]);
        // });
        // return Array.from(new Set(newArr));
    };

    const reverseGeocode = () => {
        // error check paul
        Geocode.fromLatLng(
            singleMarker.location.lat,
            singleMarker.location.lng
        ).then(
            (response) => {
                console.log(response);
                const address = response.results[2].formatted_address;
                const plusCodePrefix = response.plus_code.global_code.slice(
                    4,
                    7
                );
                // console.log(plusCodePrefix);
                // console.log(response);
                // console.log(cacheKeywordArray(response));
                handleSetAddress(`${address}`);
            },
            (error) => {
                // console.error(error);
            }
        );
    };

    // Edit address box
    const handleAddressChange = (e) => {
        handleSetAddress(e.target.value);
    };

    const handleSearch = (data, value) => {
        if (type === 'accommodation') {
            setAddress(value);
            handleSetAddress(value);
        }
    };
    const handlePlaceName = (address) => {
        setAddress(address);
    };

    const handleAccomTypeChange = (type) => {
        setAccommData({
            type: type.id,
            rating: selectedRatingType.id
        });
        setSelectedAccommType(type);
    };
    const handleRatingTypeChange = (type) => {
        setAccommData({
            type: selectedAccommType.id,
            rating: type.id
        });
        setSelectedRatingType(type);
    };
    return (
        <>
            {isLoading || isError ? (
                <MapStatus />
            ) : (
                <div className='flex flex-col-reverse portrait:flex-col-reverse lg:flex-row gap-16 lg:gap-4 xl:gap-8 px-0 md:px-8 lg:px-0'>
                    <div className='lg:w-1/2 portrait:w-full'>
                        <div className='relative' ref={containerRef}>
                            {type === 'default' && edit && (
                                <>
                                    <div className='absolute top-8 right-8 z-10'>
                                        <CollapseButton
                                            labelHover='Find me'
                                            handleClick={findMe}
                                            icon='ri-compass-discover-line text-2xl -ml-0.5'
                                            size='10'
                                            textSize='text-xs'
                                            sizeHover='w-32'
                                            offsetCenter='2'
                                            btnColor='bg-green-400 hover:bg-gray-900 text-green-900'
                                        />
                                    </div>
                                </>
                            )}

                            <GoogleMap
                                id='map'
                                mapContainerStyle={mapContainerStyle}
                                zoom={locationData.data.zoom}
                                center={center}
                                options={mapOptions}
                                onClick={onMapClick}
                                onLoad={onMapLoad}
                                onIdle={onMapIdle}
                                onBoundsChanged={onMapBoundsChanged}
                            >
                                {singleMarker && (
                                    <Marker
                                        position={{
                                            lat: singleMarker.location.lat,
                                            lng: singleMarker.location.lng
                                        }}
                                        icon={{
                                            url: `/assets/media/map-pin-fill-green.svg`,
                                            origin: new window.google.maps.Point(
                                                0,
                                                0
                                            ),
                                            anchor: new window.google.maps.Point(
                                                15,
                                                30
                                            ),
                                            scaledSize:
                                                new window.google.maps.Size(
                                                    30,
                                                    30
                                                )
                                        }}
                                    />
                                )}
                            </GoogleMap>
                        </div>
                    </div>
                    <div
                        className={`flex flex-col lg:w-1/2 mt-4 py-4 px-8 md:px-4 portrait:w-full`}
                    >
                        <div className='mt-4 mb-4'>
                            1. Search and / or pin on map
                        </div>
                        <div className='flex items-center w-full mb-6 gap-4'>
                            <MapSearch
                                handleSearch={
                                    type === 'accommodation'
                                        ? handleSearch
                                        : null
                                }
                                panTo={panTo}
                                placeholder={
                                    translations[lang][
                                        uiStruct.itinerary.activities.uiEdit
                                            .modals.map.placeholders
                                            .autocomplete
                                    ]
                                }
                                handlePlaceName={handleSetAddress}
                            />
                        </div>

                        {type === 'accommodation' ? (
                            <div className='mt-8 mb-4'>2. Edit Name & Type</div>
                        ) : (
                            <div className='mt-8 mb-4'>2. Edit Name</div>
                        )}
                        <div
                            className={`flex flex-col md:flex-row lg:flex-col xl:flex-row md:items-center lg:items-start xl:items-center gap-2  md:gap-4 mb-4
                        }`}
                        >
                            {type === 'accommodation' && (
                                <span className='text-xs whitespace-nowrap w-36 ml-2 md:ml-0'>
                                    Name & Address
                                </span>
                            )}
                            <div className='w-full flex flex-1 flex-col md:flex-row md:items-center gap-4'>
                                <BlockInputSingle
                                    responsive='true'
                                    normal
                                    rtl={rtl}
                                    error={addresError}
                                    handleChange={handleAddressChange}
                                    className='text-sm'
                                    id='address'
                                    margins=''
                                    height='h-12'
                                    value={address}
                                    placeholder={
                                        translations[lang][
                                            uiStruct.itinerary.activities.uiEdit
                                                .modals.map.placeholders.address
                                        ]
                                    }
                                />
                                {/* {type === 'default' && (
                                    <ModalButton
                                        handleClick={reverseGeocode}
                                        label={
                                            translations[lang][
                                                uiStruct.itinerary.activities
                                                    .uiEdit.modals.map.buttons
                                                    .address.label
                                            ]
                                        }
                                        color={
                                            uiStruct.itinerary.activities.uiEdit
                                                .modals.map.buttons.address
                                                .color
                                        }
                                        icon={
                                            uiStruct.itinerary.activities.uiEdit
                                                .modals.map.buttons.address.icon
                                        }
                                    />
                                )} */}
                            </div>
                        </div>
                        {type === 'accommodation' && (
                            <div
                                className={`flex flex-col md:flex-row lg:flex-col xl:flex-row md:items-center lg:items-start xl:items-center gap-2  md:gap-4 mb-4
                                }`}
                            >
                                <span className='text-xs whitespace-nowrap w-36 ml-2 md:ml-0'>
                                    Type & Rating
                                </span>
                                <div className='flex w-full flex-col-reverse md:flex-row flex-1 md:items-center gap-4'>
                                    <ListBoxGeneric
                                        listData={accommTypes}
                                        val={selectedAccommType}
                                        handleChange={handleAccomTypeChange}
                                        direction='bottom'
                                        height='h-10'
                                        textClass='text-sm'
                                        textBtn='text-sm'
                                    />
                                    <ListBoxGeneric
                                        listData={ratingTypes}
                                        val={selectedRatingType}
                                        handleChange={handleRatingTypeChange}
                                        className=''
                                        direction='bottom'
                                        height='h-10'
                                        textClass='text-sm'
                                        textBtn='text-sm'
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(MapBox);
