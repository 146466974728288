export const clearExperience = (draft) => {
    draft.loading = true;
    draft.experience = {};
    
    return draft;
};

export const setExperienceUpdated = (draft) => {
    draft.updated = true;
    
    return draft;
};
