import FooterMenuLink from 'components/blocks/FooterMenuLink';
import { marketPath, whatsApp } from 'constants/globalConsts';

const Footer2 = ({ isBody = true, isBottomBar = true }) => {
    // console.log('footer');
    return (
        <footer className=''>
            {isBody && (
                <div className='bg-gray-800 pt-12 pb-6 px-8 w-full d-hdpi-2:pt-vw-12 d-hdpi-2:pb-vw-6 d-hdpi-2:px-vw-8'>
                    <div className='mx-auto max-w-6xl gap-8 flex flex-col md:flex-row w-full text-white justify-center'>
                        <section>
                            <div className='flex items-center gap-3 -mt-2'>
                                <img
                                    className='h-8 d-hdpi-2:h-vw-8'
                                    src={process.env.PUBLIC_URL + '/assets/media/kn_logoicon_white.svg'}
                                    // height={45}
                                    // width={31}
                                />
                                <img
                                    className='flex-shrink-0 w-24 d-hdpi-2:w-vw-24'
                                    src={process.env.PUBLIC_URL + '/assets/media/kn_logotext_white.svg'}
                                    // height={45}
                                    // width={31}
                                />
                            </div>
                        </section>
                        <div className='grid grid-cols-1 md:grid-cols-4 gap-8 md:gap-8 text-white'>
                            <section>
                                <h3 className='text-2xl d-hdpi-2:text-vw-base md:text-base text-green-400 mb-2 d-hdpi-2:mb-vw-2'>About</h3>
                                <nav className='flex flex-col gap-2 md:text-xs font-light'>
                                    <FooterMenuLink url={`${marketPath}/help/article/1001`} sameWindow label='What is Konnect' />
                                    <FooterMenuLink url={`${marketPath}/about/marketplace`} sameWindow label='How Konnect works' />
                                    <FooterMenuLink url={`${marketPath}/help/article/2001`} sameWindow label='FAQs' />
                                    <FooterMenuLink url={`${marketPath}/help/article/4001`} sameWindow label='Terms of Service' />
                                    <FooterMenuLink url={`${marketPath}/help/article/4004`} sameWindow label='Privacy Policy' />
                                </nav>
                            </section>
                            <section>
                                <h3 className='text-2xl d-hdpi-2:text-vw-base md:text-base text-green-400 mb-2 d-hdpi-2:mb-vw-2'>Explore</h3>
                                <nav className='flex flex-col gap-2 md:text-xs font-light'>
                                    <FooterMenuLink url={`${marketPath}`} label='Marketplace' sameWindow />
                                    <FooterMenuLink url={`${marketPath}/experiences/search/all`} label='All Experiences' sameWindow />
                                    <FooterMenuLink url={`${marketPath}/experiences/destination/world/all`} label='By Destination' sameWindow />
                                    <FooterMenuLink url={`${marketPath}/experiences/interest/all/all`} label='By Interest' sameWindow />
                                </nav>
                            </section>
                            <section>
                                <h3 className='text-2xl md:text-base text-green-400 mb-2 d-hdpi-2:text-vw-base d-hdpi-2:mb-vw-2'>Support</h3>
                                <nav className='flex flex-col gap-2 md:text-xs font-light d-hdpi-2:text-vw-xs'>
                                    <FooterMenuLink isLink url='/basics' label='Experience Basics' sameWindow />
                                    <FooterMenuLink isLink url='/basics/how-to' label='How-to videos' sameWindow />
                                    <FooterMenuLink isLink url='/basics/digital' label='Digital Sample' sameWindow />
                                    <FooterMenuLink isLink url='/basics/guided' label='Guided Sample' sameWindow />
                                    <FooterMenuLink url={`${marketPath}/contact`} label='Contact us' sameWindow />
                                </nav>
                            </section>
                            <section>
                                <h3 className='text-2xl md:text-base text-green-400 mb-2 d-hdpi-2:text-vw-base d-hdpi-2:mb-vw-2'>Stay in touch</h3>
                                <nav className='flex gap-2 text-2xl font-light d-hdpi-2:text-vw-2xl'>
                                    <a target='_blank' rel='noreferrer' href={`https://wa.me/${whatsApp}`} className='hover:text-green-400'>
                                        <i className='ri-whatsapp-line'></i>
                                    </a>
                                    <a target='_blank' rel='noreferrer' href='https://www.instagram.com/viakonnect/' className='hover:text-green-400'>
                                        <i className='ri-instagram-line'></i>
                                    </a>
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href='https://www.facebook.com/Viakonnect-115723371137514/'
                                        className='hover:text-green-400'
                                    >
                                        <i className='ri-facebook-fill'></i>
                                    </a>
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href='https://www.youtube.com/channel/UCrv_eGOq3Almjhved39LR6Q'
                                        className='hover:text-green-400'
                                    >
                                        <i className='ri-youtube-line'></i>
                                    </a>
                                </nav>
                            </section>
                        </div>
                    </div>
                </div>
            )}
            {isBottomBar && (
                <div className='bg-gray-800 py-3 d-hdpi-2:py-vw-3'>
                    <div className='mx-auto max-w-6xl text-gray-400 text-xs d-hdpi-2:text-vw-xs justify-center flex items-center'>
                        © Copyright 2022 Viakonnect. All rights reserved.
                    </div>
                </div>
            )}
        </footer>
    );
};

export default Footer2;
