import classNames from 'classnames';

// import Spinner from 'components/blocks/Spinner';
const CollapseButton = ({
    alwaysOn,
    noLabel = false,
    disabled,
    handleClick,
    labelHover,
    noHover = false,
    icon = 'ri-check-line text-sm',
    size = '8',
    textSize = 'text-sm',
    noicon,
    children,
    sizeHover = 'w-full',
    heightHover = '',
    offsetCenter = '0',
    nolink,
    padding = 'px-2.5',
    hoverText = 'hover:text-white',
    warning = false,
    rounded = 'rounded-full',
    openPanel = '',
    btnColor = `${
        !warning
            ? `bg-green-100 ${
                  nolink
                      ? 'text-green-800'
                      : 'hover:bg-gray-900 hover:text-white text-green-800'
              }`
            : `bg-yellow-300 text-yellow-800 hover:text-yellow-800`
    }`
}) => {
    const handleBtnClick = () => {
        if (!disabled && handleClick) handleClick();
    };

    return (
        <button
            className={`${
                disabled ? 'cursor-default' : ''
            } group-scope ring-2  ${
                noHover ? '' : alwaysOn ? '' : `hover:${sizeHover} `
            } transition-all ${
                handleClick ? (noHover ? '' : !disabled && hoverText) : ''
            } duration-300 out-expo ring-transparent outline-none focus:outline-none w-${
                alwaysOn ? sizeHover : size
            }
            } h-${size} ${heightHover} ${rounded} flex items-center overflow-hidden ${
                disabled ? 'bg-gray-200 text-gray-600' : btnColor
            } `}
            onClick={handleBtnClick}
        >
            <span
                className={`flex items-center gap-3 ${padding} ${
                    alwaysOn ? `` : ''
                } transform transition-transform duration-300 out-expo ${
                    noHover
                        ? ''
                        : alwaysOn
                        ? ''
                        : `group-scope-hover:translate-x-${offsetCenter} ${openPanel}`
                }`}
            >
                {noicon ? children : <i className={`${icon} `}></i>}

                {!noHover && !noLabel && (
                    <span
                        className={`${textSize} whitespace-nowrap block group-scope-hover:opacity-100  ${
                            alwaysOn ? `opacity-100 font-semibold` : ''
                        }  transition-opacity duration-300 out-expo`}
                    >
                        {labelHover}
                    </span>
                )}
            </span>
        </button>
    );
};

export default CollapseButton;
