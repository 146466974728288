import { connect } from 'react-redux';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { BlockHTML } from 'components/blocks/Blocks';
import { handleRowReverse } from 'helpers/FEutils';

const OnboardingSection = ({
    visible = false,
    data = null,
    globalState: { lang }
}) => {
    const rtl = !!translations[lang].rtl;

    return (
        !visible && (
            <div
                className={`max-w-2xl md:max-w-2xl xl:max-w-4xl  mx-auto flex items-center justify-center px-8 md:px-8 lg:px-4 mb-4 lg:mb-0`}
            >
                <BlockHTML
                    html={data && translations[lang][data]}
                    className={`text-xl text-gray-600 mt-10  ${
                        handleRowReverse(rtl).rtl
                    }`}
                />

                {/* //     className={`w-max text-4xl md:text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500 py-8 whitespace-pre-wrap`}
                // > */}
            </div>
        )
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps)(OnboardingSection);
