import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CardXl, { CardXlContent } from 'components/blocks/CardXl';
import { PillContainer } from 'components/blocks/Pills';
import translations from 'constants/translations';
import { handleRowReverse } from 'helpers/FEutils';
import { MenuRefsHolder } from 'helpers/MenuRefsHolder';
import SkeletonParagraph from 'components/blocks/SkeletonParagraph';
import ImageHover from 'components/blocks/ImageHover';
import { useNavigate } from 'react-router-dom';
import { useOnlineStatus } from 'helpers/useOnlineStatus';
import BioBlock from 'components/blocks/BioBlock';

function SectionItinerarySingle({
    purchased = false,
    days,
    index,
    experienceId,
    removeModal = null,
    globalState: { lang, edit, isPrintMode, isFeedbackMode },
    data: {
        day_intro: {
            description_html: descriptionHtml,
            title: titleTxt,
            day_featured_image,
            day_featured_image: { data: featuredImage, type: featuredImageType }
        }
    },
    publicView = false,
    isPublishedView = false,
    disableDeletBtn = false
}) {
    const isOnline = useOnlineStatus();
    const rtl = !!translations[lang].rtl;
    const [isMinimized, setIsMinimized] = useState(false);

    const featuredImageUrl = day_featured_image
        ? day_featured_image.type !== 'vid'
            ? `${day_featured_image.data}-/format/webp/-/quality/lighter/-/progressive/yes/-/max_icc_size/10/-/scale_crop/320x320/smart_faces_objects_points/`
            : `https://img.youtube.com/vi/${day_featured_image.data}/sddefault.jpg`
        : '';

    const imgProp = day_featured_image.type !== 'vid' ? { src: featuredImageUrl } : day_featured_image.type === 'vid' ? { src: featuredImageUrl } : {};

    let history = useNavigate();
    // const handleClick = () => {
    //     history.push(
    //         `${!publicView ? '/c' : ''}/experience/${experienceId}/${
    //             index + 1
    //         }/`
    //     );
    // };

    const handleClick = () => {
        history(
            `${publicView ? '/experience/' : isPublishedView ? '/c/published/' : purchased ? '/purchased/' : '/c/experience/'}${experienceId}/${index + 1}/`,
            { replace: true }
        );
    };

    return (
        <>
            <li
                className={`list-none ${isMinimized ? '-mt-4 xl:-mt-8 last:mb-16' : ''}`}
                id={`ovDay${index}`}
                ref={(el) => {
                    MenuRefsHolder.setRef(el, `ovDay${index}`);
                }}
            >
                <>
                    <CardXl
                        padding='md:px-12 pb-16'
                        isFeedbackMode={isFeedbackMode}
                        edit={edit}
                        isPrintMode={isPrintMode}
                        handleDelete={
                            !disableDeletBtn
                                ? () => {
                                    removeModal(index);
                                }
                                : null
                        }
                        handleEdit={isOnline ? handleClick : null}
                        rtl={rtl}
                        hidden={isMinimized}
                        handleMinimize={() => setIsMinimized(!isMinimized)}
                        day
                        nominimize={true}
                    >
                        <div className={`overflow-hidden rounded-3xl flex flex-col md:flex-row gap-4 md:gap-8 xl:gap-12 w-full   ${handleRowReverse(rtl).rtl}`}>
                            {/* <div className=''>
                                    {featuredImageUrl &&
                                        featuredImageType !== 'vid' && (
                                            <img
                                                className='rounded-3xl object-cover w-full md:w-80 h-96'
                                                data-blink-src={featuredImageUrl}
                                                alt=''
                                            />
                                        )}
                                    {featuredImageUrl && featuredImageType === 'vid' && (
                                        <div className='rounded-3xl object-cover w-full md:w-80 h-96 overflow-hidden'>
                                            <img
                                                className='object-cover w-full md:w-80 h-96 transform scale-135'
                                                src={featuredImageUrl}
                                                alt=''
                                            />
                                        </div>
                                    )}
                                </div> */}
                            <div>
                                {/* <div className='rounded-3xl object-cover w-full xl:w-80 lg:w-44 md:w-40 overflow-hidden h-full xl:min-h-96 lg:min-h-60 md:min-h-48'> */}
                                <div className='rounded-3xl object-cover w-full xl:w-80 lg:w-44 md:w-40 overflow-hidden h-full xl:min-h-96 lg:min-h-60 md:min-h-48'>
                                    <ImageHover
                                        disabled={!isOnline}
                                        bg='bg-gray-100'
                                        handleActionBtn={isOnline ? handleClick : null}
                                        url={imgProp.src}
                                        className={`${day_featured_image.type === 'vid' ? 'scale-135' : ''}`}
                                    />
                                    {/* <img
                                        className='object-cover w-full h-full transform scale-135'
                                        {...imgProp}
                                        alt=''
                                    /> */}
                                </div>
                            </div>

                            <div className='flex-1 flex flex-col px-6 md:px-0 md:pt-4 lg:pt-8 xl:pt-12 pb-4 md:pr-4 xl:pr-8'>
                                {days > 1 && (
                                    <div className={`z-10`}>
                                        <h2 className='text-xl lg:text-2xl text-green-400 font-bold'>Day {index + 1}</h2>
                                    </div>
                                )}
                                <div className=''>
                                    <div
                                        className={`${handleRowReverse(rtl).rtl} ${titleTxt ? '' : 'italic text-sm text-gray-400'
                                            } text-transparent bg-clip-text bg-gradient-to-tr from-gray-900 via-blue-500 to-green-400 text-xl lg:text-2xl leading-tight
                                                    font-bold mt-2 line-clamp-3`}
                                    >
                                        {titleTxt}
                                    </div>
                                </div>

                                {descriptionHtml ? (
                                    <BioBlock
                                        bio={descriptionHtml}
                                        className='block-html mt-4 mb-6 text-sm lg:text-sm break-words z-1000'
                                        containerClass='pb-8 md:pb-8'
                                        mode='charCount'
                                        max={300}
                                        absolute={true}
                                    />
                                ) : (
                                    // <div
                                    //     className='block-html mt-4 mb-6 line-clamp-5 lg:line-clamp-4 xl:line-clamp-5 text-sm lg:text-sm break-words'
                                    //     dangerouslySetInnerHTML={{
                                    //         __html: descriptionHtml
                                    //     }}
                                    // />
                                    <SkeletonParagraph words={16} className='mt-4 mb-6' />
                                )}

                                {/* <div className='flex'>
                                        <Link
                                            className='flex-shrink-0 rounded-full border-2 border-green-400 px-4 py-2'
                                            to={`${
                                                !publicView ? '/c' : ''
                                            }/experience/${experienceId}/${index + 1}/`}
                                        >
                                            <span>Detailed Schedule</span>
                                        </Link>
                                    </div> */}
                            </div>
                        </div>
                    </CardXl>
                </>
            </li>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(SectionItinerarySingle);
