/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import SectionItinerary from 'components/sections/SectionItinerary';
import SectionMovingAround from 'components/sections/SectionMovingAround';
import {
    _MOVING_AROUND_OVERVIEW,
    _INTRO_OVERVIEW,
    _OVERVIEW,
    _GETTING_THERE,
    _DESTINATIONS_OVERVIEW,
    _ACCOMMODATION_OVERVIEW,
    _CURRENCY_OVERVIEW,
    _GO_TIME_OVERVIEW,
    _BUDGET_OVERVIEW,
    _THANKYOU_MARKETING
} from 'store/actions/types';

import SectionIntroTop from 'components/sections/SectionIntroTop';
import SectionTips from 'components/sections/SectionTips';
import SectionGettingThere from 'components/sections/SectionGettingThere';
import SectionDestination from 'components/sections/SectionDestinations';
import SectionAccommodation from 'components/sections/SectionAccommodation';
import uiStruct from 'constants/uiStruct';
import SectionCurrency from 'components/sections/SectionCurrency';
import SectionBestTimeToGo from 'components/sections/SectionBestTimeToGo';
import SectionBudgetRange from 'components/sections/SectionBudgetRange';
import { MenuRefsHolder } from 'helpers/MenuRefsHolder';
import * as types from 'store/actions/types';
import SectionThankyou from 'components/sections/SectionThankyou';

const LayoutOverview = ({ purchased = false, publicView = false, type, experienceDetails, globalState: { edit }, isPublishedView }) => {
    const {
        experience: {
            moving_around_visible,
            itinerary: { trip_days },
            overview_intro,
            budget: { isVisible: budgetVisible = true },
            best_time_to_go: { isVisible: bestTimeToGoVisible = true },
            getting_there: { isVisible: gettingThereVisible = true },
            moving_around
        }
    } = experienceDetails;

    // const asd = [1000, 5000, 5, 2];

    // const isHidden = (valueArr) => {
    //     const isVis = valueArr.every((val) => {
    //         return Number(val) === 0;
    //     });

    //     return isVis;
    // };

    useEffect(() => {
        MenuRefsHolder.reset(types.REFS_OVERVIEW);
    }, [trip_days, moving_around, moving_around_visible]); // if these change update menu items

    return (
        <div className=''>
            <div
                id='ovIntro'
                ref={(ref) => {
                    MenuRefsHolder.setRef(ref);
                }}
            >
                <SectionIntroTop actionType={_INTRO_OVERVIEW} page='overview' objId={overview_intro.objId} />
            </div>
            {type.toLowerCase() === 'digital' ? (
                <div
                    id='ovBudget'
                    ref={(ref) => {
                        MenuRefsHolder.setRef(ref);
                    }}
                    className={`${!edit && !budgetVisible ? 'hidden' : ''}`}
                >
                    <SectionBudgetRange actionType={_BUDGET_OVERVIEW} />
                </div>
            ) : null}

            <div
                id='ovDestination'
                ref={(ref) => {
                    MenuRefsHolder.setRef(ref);
                }}
            >
                <SectionDestination actionType={_DESTINATIONS_OVERVIEW} />
            </div>
            <div
                id='ovWhenToGo'
                ref={(ref) => {
                    MenuRefsHolder.setRef(ref);
                }}
                className={`${!edit && !bestTimeToGoVisible ? 'hidden' : ''}`}
            >
                <SectionBestTimeToGo actionType={_GO_TIME_OVERVIEW} />
            </div>
            <div
                id='ovGettingThere'
                ref={(ref) => {
                    MenuRefsHolder.setRef(ref);
                }}
                className={`${!edit && !gettingThereVisible ? 'hidden' : ''}`}
            >
                <SectionGettingThere actionType={_GETTING_THERE} />
            </div>
            <div
                id='ovAccommodation'
                ref={(ref) => {
                    MenuRefsHolder.setRef(ref);
                }}
            >
                <SectionAccommodation actionType={_ACCOMMODATION_OVERVIEW} />
            </div>

            <div
                className='hidden'
                id='ovMovingAround'
                ref={(ref) => {
                    MenuRefsHolder.setRef(ref);
                }}
            >
                <SectionMovingAround actionType={_MOVING_AROUND_OVERVIEW} />
            </div>
            <div
                id='ovCurrency'
                ref={(ref) => {
                    MenuRefsHolder.setRef(ref);
                }}
            >
                <SectionCurrency actionType={_CURRENCY_OVERVIEW} />
            </div>

            <div
                id='ovTips'
                ref={(ref) => {
                    MenuRefsHolder.setRef(ref);
                }}
            >
                <SectionTips
                    actionType={_OVERVIEW}
                    title={uiStruct.overview.general_tips.uiPreview.headTitle}
                    subTitle={uiStruct.overview.general_tips.uiPreview.subTitle}
                    icon={uiStruct.overview.general_tips.uiPreview.icon}
                />
            </div>
            <SectionItinerary purchased={purchased} publicView={publicView} isPublishedView={isPublishedView} />

            {type.toLowerCase() === 'digital' ? (
                <div
                    id='mkThankyou'
                    ref={(ref) => {
                        MenuRefsHolder.setRef(ref);
                    }}
                >
                    <SectionThankyou actionType={_THANKYOU_MARKETING} />
                </div>
            ) : null}
        </div>
    );
};

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(LayoutOverview);
