/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { produce } from 'immer';

import * as types from 'store/actions/types';

import {
    toggleLang,
    toggleNav,
    toggleEdit,
    togglePrintMode,
    toggleFeedbackMode,
    toggleSuperAdmin,
    setIsAdminStaffMode,
    setIsOwnerMode,
    setIsPublishedViewMode,
    setIsEduContentMode,
    toggleToolTips,
    toggleTour,
    googleMapReady,
    googleMapLoadFail,
    setUpdateFeedbackPending,
    setUpdateFeedbackFufilled
} from './globalState/master/master';

import {
    setPriceStatusSaving,
    setPriceStatusSaved,
    setPriceStatusRejected,
    setTagsCatsStatusSaving,
    setTagsCatsStatusSaved,
    setTagsCatsStatusRejected,
    setIncludesStatusSaving,
    setIncludesStatusSaved,
    setIncludesStatusRejected,
    setStartUpData,
    setExpType,
    setUsers
} from './globalState/updateStatus';

import { updateFilter, updateExpeListPagination } from './globalState/updateFiltersPagination';

import { settingRefs, refsSet } from './globalState/updateMenuRefs';

const initialState = {
    filters: {
        status: 'all',
        type: 'all',
        user: 'all',
        country: 'all',
        published: 'all',
        extra: {
            extraSelected: 'updatedAt',
            orderType: 'desc'
        }
    },
    pagination: { currentPage: 0, maxPerPage: Number(process.env.REACT_APP_MAX_LIST_TOTAL) || 30 },
    menuRefsLoading: false,
    inPublished: false,
    inMode: 'kreo',
    lang: 'en',
    navIsOpen: false,
    edit: true,
    isPrintMode: false,
    isFeedbackMode: false,
    isSuperAdmin: false,
    isAdminStaff: false,
    isOwner: false,
    isPublishedView: false,
    isEduContent: false,
    toolTips: true,
    tour: false,
    googleMaps: {
        loading: { isLoading: true, msg: 'GOOGLE_MAP_NOT_INITIALISED' },
        error: { isError: false, msg: 'GOOGLE_MAP_INITIALISE_FAIL' }
    },

    status: {
        priceSaving: [],
        tagsCatsSaving: [],
        includesSaving: [],
        feedbackSaving: false
    },
    siteData: {
        loading: true,
        tags: [],
        placesLists: [],
        countryList: [],
        categories: [],
        users: [],
        settings: {
            whatsIncluded: {},
            xchangeRates: {}
        }
    }
};

function createReducer(initialState, handlers) {
    return produce((draft = initialState, action) => {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](draft, action);
        } else {
            return draft;
        }
    });
}

export default createReducer(initialState, {
    [types.TOGGLE_LANG]: toggleLang,
    [types.TOGGLE_NAV]: toggleNav,
    [types.TOGGLE_EDITMODE]: toggleEdit,
    [types.TOGGLE_PRINTMODE]: togglePrintMode,
    [types.TOGGLE_FEEDBACKMODE]: toggleFeedbackMode,
    [types.SET_ISADMINSTAFFMODE]: setIsAdminStaffMode,
    [types.SET_ISOWNERMODE]: setIsOwnerMode,
    [types.SET_ISPUBLISHEDVIEWMODE]: setIsPublishedViewMode,
    [types.SET_ISEDUCONTENTMODE]: setIsEduContentMode,
    [types.TOGGLE_SUPERADMIN]: toggleSuperAdmin,
    [types.TOGGLE_TOOLTIPS]: toggleToolTips,
    [types.TOGGLE_TOUR]: toggleTour,
    [types.GOOGLE_MAP_INITIALISED]: googleMapReady,
    [types.GOOGLE_MAP_INITIALISED_FAILED]: googleMapLoadFail,
    [`${types.UPDATE_PRICE_STATUS}_PENDING`]: setPriceStatusSaving,
    [`${types.UPDATE_PRICE_STATUS}_FULFILLED`]: setPriceStatusSaved,
    [`${types.UPDATE_PRICE_STATUS}_REJECTED`]: setPriceStatusRejected,

    [`${types.UPDATE_TAGS_CATS_STATUS}_PENDING`]: setTagsCatsStatusSaving,
    [`${types.UPDATE_TAGS_CATS_STATUS}_FULFILLED`]: setTagsCatsStatusSaved,
    [`${types.UPDATE_TAGS_CATS_STATUS}_REJECTED`]: setTagsCatsStatusRejected,

    [`${types.UPDATE_INCLUDES_STATUS}_PENDING`]: setIncludesStatusSaving,
    [`${types.UPDATE_INCLUDES_STATUS}_FULFILLED`]: setIncludesStatusSaved,
    [`${types.UPDATE_INCLUDES_STATUS}_REJECTED`]: setIncludesStatusRejected,

    [`${types.UPDATE_FEEDBACK}_PENDING`]: setUpdateFeedbackPending,
    [`${types.UPDATE_FEEDBACK}_FULFILLED`]: setUpdateFeedbackFufilled,
    [`${types.UPDATE_FEEDBACK}_REJECTED`]: () => {
        // console.log('_REJECTED');
    },

    [`${types.GET_STARTUP_DATA}_FULFILLED`]: setStartUpData,
    [`${types.SET_TO_PUBLISHED}`]: setExpType,

    [`${types.SET_FILTER}`]: updateFilter,
    [`${types.SET_EXP_LIST_PAGINATION}`]: updateExpeListPagination,

    [`${types.MENU_REFS_SETTING}`]: settingRefs,
    [`${types.MENU_REFS_SET}`]: refsSet,

    [`${types.FETCH_USER_LIST}_FULFILLED`]: setUsers
});

// const globalState = {
//     lang: 'en',
//     user: {
//         firstname: 'Paul',
//         lastname: 'Thompson',
//         email: 'souad@gmail.com',
//         image2: 'https://ucarecdn.com/25c22d1a-3f4c-429b-b465-100a1afeab99/download2.jpg',
//     },
// };

// export default function (state = globalState, action) {
//     const { type, lang } = action;
//     switch (type) {
//         case types.TOGGLE_LANG:
//             return {
//                 ...state,
//                 lang: lang,
//             };
//         default:
//             return state;
//     }
// }
