import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleLang } from 'store/actions/globalState/master';
import { handleRowReverse } from 'helpers/FEutils';
import LangList from 'components/blocks/LangList';
import translations from 'constants/translations';
import uiStruct from 'constants/uiStruct';

import { ReactComponent as KLogoIcon } from 'components/svg/kn_logoicon_black.svg';
import { ReactComponent as KLogoText } from 'components/svg/kn_logotext.svg';
import debounce from 'helpers/debounce';
// import ButtonLoad from 'components/blocks/ButtonLoad';
import IconsLucide from 'components/blocks/IconsLucide';
import Button from 'components/blocks/Title/Button';
import MenuLink from 'components/blocks/MenuLink';

const LayoutHeader = ({
    toggleLang,

    globalState: { lang }
}) => {
    const [selectedLang, setSelectedLang] = useState(uiStruct.ui.languages[0]);
    const [scrollPos, setScrollPos] = useState(0);
    const [showHeader, setShowHeader] = useState(true);

    const rtl = !!translations[lang].rtl;

    useEffect(() => {
        const debouncedHandleScroll = debounce(handleScroll, 100);

        window.addEventListener('scroll', debouncedHandleScroll);

        return () => {
            window.removeEventListener('scroll', debouncedHandleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollPos]);

    const handleScroll = () => {
        if (typeof window !== 'undefined') {
            const currentScrollPos = window.scrollY;

            setShowHeader(currentScrollPos - scrollPos < 0 || scrollPos < 400);
            setScrollPos(currentScrollPos);
        }
    };

    const handleLangChange = (selectedLang) => {
        setSelectedLang(selectedLang);
        toggleLang(selectedLang.id);
    };

    return (
        <>
            <div
                style={{ zIndex: 51 }}
                className={`${handleRowReverse(rtl).rtl} fixed top-0 inset-x-0 h-16  md:h-20 bg-green-400 transform-gpu duration-300 ${
                    showHeader ? 'translate-y-0 shadow-cards' : '-translate-y-16 md:-translate-y-24 shadow-none md:shadow-none'
                }`}
            >
                <div className='flex flex-1 flex-row justify-end md:justify-between items-center h-full'>
                    <div className='flex flex-grow justify-between max-w-2xl '>
                        <div className={`ml-6 md:ml-20 flex items-center gap-3 ${handleRowReverse(rtl).flex}`}>
                            <KLogoIcon className='opacity-90a mix-blend-multiplya' />
                            <span className=''>
                                <KLogoText className='opacity-80a mix-blend-multiply' />
                            </span>
                            
                        </div>
                    </div>

                    <div className='flex justify-end items-center h-full'>
                        <div className='hidden lg:flex text-sma items-center gap-4 lg:gap-8 mr-6 d-hdpi-2:mr-vw-6'>
                            <MenuLink
                                label={'Marketplace'}
                                href={process.env.REACT_APP_LINK_MARKETPLACE || 'https://marketplace.viakonnect.com/'}
                                type='url'
                                sameWindow
                            />

                            <MenuLink
                                label={'How it works'}
                                href={`${process.env.REACT_APP_LINK_MARKETPLACE}/about/marketplace` || 'https://marketplace.viakonnect.com/about/marketplace'}
                                type='url'
                                sameWindow
                            />
                            <MenuLink label={'Kreator Portal'} href='/' />
                        </div>

                        <div className='hidden md:flex md:pr-4 md:items-center md:gap-4'>
                            {/* <MenuLink label={'Sign in'} href={'/accounts/login'} /> */}
                            <Button
                                color='bg-white text-gray-800 font-bold'
                                animation={false}
                                as='link'
                                label='Sign in'
                                link='/accounts/login'
                                width='md:w-28'
                                height='h-10'
                            />
                            <Button
                                color='bg-gray-900 text-white font-semibold'
                                animation={false}
                                as='link'
                                label='Become a Kreator'
                                link='/accounts/signup'
                                width='md:w-48'
                                height='h-10'
                            />
                        </div>
                        {/* <button
                            onClick={() => toggleNav(!navIsOpen)}
                            className={`focus:outline-none w-20 
                               flex flex-shrink-0 h-12 items-center justify-center text-2xl text-white bg-gray-900 hover:bg-white bg-green-400-t  hover:text-gray-900 ${
                                   rtl ? 'rounded-r-lg' : 'rounded-l-lg'
                               }`}
                        >
                            <i className={`${rtl ? 'ri-menu-2-line' : 'ri-menu-3-line'}`}></i>
                        </button> */}
                    </div>
                </div>
                <div className='flex items-center gap-4 w-full justify-center bg-gray-900 h-16'>
                    {/* <MenuLink label={'Sign in'} href={'/accounts/login'} /> */}
                    <Button
                        color='bg-white text-gray-800 font-bold'
                        animation={false}
                        as='link'
                        label='Sign in'
                        link='/accounts/login'
                        width='w-28 md:w-28'
                        height='h-8'
                        fontSize='text-sm'
                    />
                    <Button
                        color='bg-green-400 text-green-900 font-semibold'
                        animation={false}
                        as='link'
                        label='Become a Kreator'
                        link='/accounts/signup'
                        width='w-48 md:w-48'
                        height='h-8'
                        fontSize='text-sm'
                    />
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleLang
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutHeader);
