import { useEffect, useState } from 'react';
import SectionMarketingItinerary from 'components/sections/SectionMarketingItinerary';
import {
    _INTRO_MARKETING,
    _WHAT_TO_DO_MARKETING,
    _WHERE_TO_STAY_MARKETING,
    _WHATS_INCLUDED_MARKETING,
    _POLICIES_MARKETING,
    _THANKYOU_MARKETING
} from 'store/actions/types';
import SectionWhatToDo from 'components/sections/SectionWhatToDo';
import SectionWhereToStay from 'components/sections/SectionWhereToStay';
import SectionWhatsIncluded from 'components/sections/SectionWhatsIncluded';
import SectionPolicies from 'components/sections/SectionPolicies';
import SectionTags from 'components/sections/SectionTags';
import SectionMarketingGallery from 'components/sections/SectionMarketingGallery';
import SectionMarketingIntro from 'components/sections/SectionMarketingIntro';

import SectionThankyou from 'components/sections/SectionThankyou';

import SectionMarketingTitles from 'components/sections/SectionMarketingTitles';
// import ExpSubsection from 'components/blocks/ExpSubsection';
// import BuyingCard from 'components/blocks/BuyingCard';
import SectionPricingBooking from 'components/sections/SectionPricingBooking';
import { MenuRefsHolder } from 'helpers/MenuRefsHolder';
import * as types from 'store/actions/types';
import ItineraryModal from 'components/modals/ItineraryModal';
import ExpSubsection from 'components/blocks/ExpSubsection';
import RawCard from 'components/blocks/marketing/RawCard';
import NoteStatic from 'components/blocks/NoteStatic';

const LayoutMarketing = ({ publicView = false, type, tagsActionBtn }) => {
    useEffect(() => {
        MenuRefsHolder.reset(types.REFS_MARKETING);
    }, []);

    const [itineraryModalStatus, setItineraryModalStatus] = useState(false);
    const [selectedDay, setSelectedDay] = useState(1);

    return (
        <div className=''>
            <div className={`mb-12 mt-16 md:mt-16 lg:mt-24 mx-auto px-5 md:px-12 lg:px-12 xl:px-241 2xl:px-401 xl:max-w-7xl `}>
                <section className='w-full lg:w-4/6a mx-auto'>
                    <ExpSubsection className='' borders=''>
                        <NoteStatic rows={true} label='Marketing Section Guidance' containerColorBg='bg-gray-50'>
                            <div className=' leading-7 text-gray-400 italic text-sm md:text-base px-4'>
                                <div className='mb-4'>
                                    This is your <span className='font-bold text-gray-900'>‘Marketing Section’</span>. It is what travelers see on the Konnect
                                    platform and it is what will encourage them to purchase your experience. Add the best photos you have to the gallery below
                                    to highlight your experience. You will also need to{' '}
                                    <span className='font-bold text-gray-900'> complete your profile and add a personal photo</span> as travelers what to know
                                    who you are and what your background is.
                                </div>
                                <div>
                                    If you have completed your ‘Overview’ and ‘Day’ sections, the text you provided will automatically be copied below as your
                                    intro, but you can still edit it if you want to provide more or less detail. Finally, make sure to add a few{' '}
                                    <span className='font-bold text-gray-900'>‘Categories’ and ‘Hashtags’</span> so that people can find your experience based
                                    on interests and keywords.
                                </div>
                            </div>
                        </NoteStatic>
                        {/* <RawCard padding='p-8 md:p-8' margins='mt-4'>
                                
                            </RawCard> */}
                    </ExpSubsection>
                </section>
            </div>
            <div>
                <SectionMarketingTitles />
            </div>

            <div
                id='mkGallery'
                ref={(ref) => {
                    MenuRefsHolder.setRef(ref);
                }}
            >
                <SectionMarketingGallery actionType={_INTRO_MARKETING} />
            </div>

            <div className={`mb-12 mt-12 md:mt-12 lg:mt-12 mx-auto px-5 md:px-12 lg:px-12 xl:px-241 2xl:px-401 xl:max-w-7xl`}>
                <main className={` flex items-start gap-4 lg:gap-8 xl:gap-12`}>
                    <section className='w-full lg:w-4/6 mb-24'>
                        <div
                            id='mkOverview'
                            ref={(ref) => {
                                MenuRefsHolder.setRef(ref);
                            }}
                        >
                            <SectionMarketingIntro actionType={_INTRO_MARKETING} />
                        </div>

                        <div
                            id='mkWhatYouDo'
                            ref={(ref) => {
                                MenuRefsHolder.setRef(ref);
                            }}
                        >
                            <SectionWhatToDo actionType={_WHAT_TO_DO_MARKETING} />
                        </div>
                        {type.toLowerCase() !== 'guided' && false ? (
                            <div
                                id='mkWhereStay'
                                ref={(ref) => {
                                    MenuRefsHolder.setRef(ref);
                                }}
                            >
                                <SectionWhereToStay actionType={_WHERE_TO_STAY_MARKETING} />
                            </div>
                        ) : null}
                        <div
                            id='mkItinerary'
                            ref={(ref) => {
                                MenuRefsHolder.setRef(ref);
                            }}
                        >
                            <SectionMarketingItinerary setSelectedDay={setSelectedDay} setItineraryModalStatus={setItineraryModalStatus} />
                        </div>

                        {type.toLowerCase() === 'guided' ? (
                            <div
                                id='mkGuided'
                                ref={(ref) => {
                                    MenuRefsHolder.setRef(ref);
                                }}
                            >
                                <SectionWhatsIncluded actionType={_WHATS_INCLUDED_MARKETING} />
                            </div>
                        ) : null}
                        {type.toLowerCase() === 'guided' ? (
                            <div
                                id='mkPolicies'
                                ref={(ref) => {
                                    MenuRefsHolder.setRef(ref);
                                }}
                            >
                                <SectionPolicies actionType={_POLICIES_MARKETING} />
                            </div>
                        ) : null}
                        {type.toLowerCase() === 'digital' && false ? (
                            <div
                                id='mkThankyou'
                                ref={(ref) => {
                                    MenuRefsHolder.setRef(ref);
                                }}
                            >
                                <SectionThankyou actionType={_THANKYOU_MARKETING} />
                            </div>
                        ) : null}
                        <div
                            id='mkTags'
                            ref={(ref) => {
                                MenuRefsHolder.setRef(ref);
                            }}
                        >
                            <SectionTags tagsActionBtn={tagsActionBtn} />
                        </div>
                    </section>

                    <aside className='z-0 hidden lg:block lg:w-2/6 sticky top-24  py-4 pb-24 '>
                        <SectionPricingBooking />
                    </aside>
                </main>
            </div>
            {itineraryModalStatus ? <ItineraryModal index={selectedDay} setModalIsOpen={setItineraryModalStatus} /> : null}
        </div>
    );
};

export default LayoutMarketing;
