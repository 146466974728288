import { arrangeArray } from '../common';

export const updateDescriptionGettingThere = (draft, action) => {
    const { descHtml } = action;

    draft.experience.getting_there.description_html = descHtml;

    return draft;
};

export const arrangeTipsGettingThere = (draft, action) => {
    return arrangeArray(
        draft,
        action,
        draft.experience.getting_there.tips_html
    );
};

export const deleteTipGettingThere = (draft, action) => {
    const { tipIndex } = action;

    draft.experience.getting_there.tips_html.splice(tipIndex, 1);

    return draft;
};

export const addTipGettingThere = (draft, action) => {
    const { dataObj } = action;

    draft.experience.getting_there.tips_html.push(dataObj);

    return draft;
};

export const updateTipGettingThere = (draft, action) => {
    const { tipIndex, dataObj } = action;

    draft.experience.getting_there.tips_html[tipIndex] = {
        ...draft.experience.getting_there.tips_html[tipIndex],
        ...dataObj,
    };

    return draft;
};

export const arrangeVendorsGettingThere = (draft, action) => {
    return arrangeArray(draft, action, draft.experience.getting_there.vendors);
};

export const deletVendorGettingThere = (draft, action) => {
    const { vendorIndex } = action;

    draft.experience.getting_there.vendors.splice(vendorIndex, 1);

    return draft;
};

export const updateVendoeGettingThere = (draft, action) => {
    const { vendorIndex, dataObj } = action;

    draft.experience.getting_there.vendors[vendorIndex] = {
        ...draft.experience.getting_there.vendors[vendorIndex],
        ...dataObj,
    };

    return draft;
};

export const addVendorGettingThere = (draft, action) => {
    const { dataObj } = action;

    draft.experience.getting_there.vendors.push(dataObj);

    return draft;
};

export const updateEntryGettingThere = (draft, action) => {
    const { dataObj } = action;

    draft.experience.getting_there.entry = dataObj;

    return draft;
};
