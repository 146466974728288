import React, { useEffect } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from '@reach/combobox';

const MapSearch = ({ panTo = () => {}, handleSearch = null, placeholder = 'Find a place', rtl, clearInput = null, handlePlaceName }) => {
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions
    } = usePlacesAutocomplete({
        // cacheKey: 'lb',
        // requestOptions: {
        //     // types: ['geocode'],
        //     componentRestrictions: {
        //         country: 'lb'
        //     }
        // },
        debounce: 300
    });

    const handleInput = (e) => {
        setValue(e.target.value);
    };

    const filterDown = (results) => {
        const searchElements = [
            'geocode',
            'locality',
            'sublocality',
            'neighborhood',
            'administrative_area_level_1',
            'administrative_area_level_2',
            'administrative_area_level_3'
        ];
        const filtered = results.filter((result) => {
            return result.types.some((one) => searchElements.includes(one));
        });
        // console.log('filtered', filtered);
        return filtered;
    };
    // console.log('data', data);
    // When you select a result
    const handleSelect = async (address) => {
        if (handlePlaceName) {
            setValue('');
            // setValue(address, false);
            handlePlaceName(address);
            // console.log(address);
        } else {
            setValue(address, false);
        }

        clearSuggestions();

        try {
            const results = await getGeocode({ address });
            // console.log('results', results);
            // console.log('address', address);
            const { lat, lng } = await getLatLng(results[0]);
            // console.log('lng lat pts:', lng, lat);
            panTo({ lat, lng }, results[0].geometry.viewport);
            if (handleSearch) {
                handleSearch(results, address);
            }
        } catch (error) {
            // console.log('Error: ', error);
        }
    };
    useEffect(() => {
        setValue('');
    }, [clearInput]);

    // useEffect(() => {
    //     setTimeout(() => {
    //         setValue('defaultAddress');;
    //     }, 1000);

    // }, []);
    return (
        <div style={{ zIndex: '1200' }} className='absolute2 max-w-2xl w-full top2-8 transform2 left2-1/2 -translate2-x-1/2'>
            <Combobox onSelect={handleSelect}>
                <ComboboxInput
                    style={{ zIndex: '1200' }}
                    value={value}
                    onChange={handleInput}
                    disabled={!ready}
                    placeholder={placeholder}
                    className={`px-4 py-3 w-full rounded-xl block appearance-none placeholder-gray-400 placeholder-opacity-100 border-transparent focus:border-green-400 focus:ring-green-200 border  text-gray-700 leading-5 focus:outline-none ring-transparent ring-4 hover:ring-green-200 bg-kn-gray-100 hover:bg-white focus:bg-white transition duration-200`}
                />
                {/* Amine to style */}
                <ComboboxPopover style={{ zIndex: '1200' }} className='rounded-lg shadow-2xl-green-400 overflow-hidden mt-2'>
                    <ComboboxList className=''>
                        {status === 'OK' &&
                            data.map(({ id, description }, index) => (
                                <ComboboxOption className='px-8 py-4 text-xs hover:bg-green-100' key={index} value={description} />
                            ))}
                    </ComboboxList>
                </ComboboxPopover>
            </Combobox>
        </div>
    );
};

export default MapSearch;
