/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ExperiencePurchasedCard from 'components/blocks/ExperiencePurchasedCard';
import ExperienceCardSkeleton from 'components/blocks/ExperienceCardSkeleton';
// import { experienceDetailsAction } from 'store/actions/experienceDetails/generic.js';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

const ExperiencePurchasedList = ({
    experiences,
    auth: {
        user: { role: userRole, _id: userId }
    },
    globalState: {
        siteData: { users: siteUsers, countryList }
    },
    pageLoad
}) => {
    const history = useNavigate();
    const skeletonArray = [1, 2, 3, 4, 5];
    const [digitalExp, setDigitalExp] = useState([]);
    const [guidedExp, setGuidedExp] = useState([]);
    const filterExpByType = (type) => {
        return experiences.length ? experiences.filter((exp) => exp?.type?.toLowerCase() === type) || [] : [];
    };

    useEffect(() => {
        setDigitalExp(filterExpByType('digital'));
        setGuidedExp(filterExpByType('guided'));
    }, [experiences]);

    return (
        <>
            {!pageLoad ? (
                <div className={classNames('flex flex-col gap-8')}>
                    {(!digitalExp || digitalExp?.length === 0) && (!guidedExp || guidedExp?.length === 0) && (
                        <div className={classNames('bg-gray-50 rounded-lg  flex w-full h-96 items-center justify-center')}>
                            You don't have any purchases yet
                        </div>
                    )}

                    {digitalExp?.length > 0 && (
                        <div className={classNames('flex flex-col gap-2')}>
                            <h2
                                className={classNames('bg-green-100 h-12 flex items-center px-4 rounded-lg text-green-700 font-medium uppercase tracking-wide')}
                            >
                                Digital Experiences
                            </h2>
                            <div className='grid grid-cols-1 px-2 lg-px-0 gap-y-12 md:gap-y-4 md:grid-cols-3 '>
                                {digitalExp.map((item, index) => {
                                    const { experience_id } = item;

                                    return <ExperiencePurchasedCard index={index} key={experience_id} expId={experience_id} data={item} />;
                                })}
                            </div>
                        </div>
                    )}
                    {guidedExp?.length > 0 && (
                        <div className={classNames('flex flex-col gap-2')}>
                            <h2
                                className={classNames('bg-green-100 h-12 flex items-center px-4 rounded-lg text-green-700 font-medium uppercase tracking-wide')}
                            >
                                Guided Experiences
                            </h2>
                            <div className='grid grid-cols-1 px-2 lg-px-0 gap-y-12 md:gap-y-4 md:grid-cols-3 '>
                                {guidedExp.map((item, index) => {
                                    const { experience_id } = item;

                                    return <ExperiencePurchasedCard guided index={index} key={experience_id} expId={experience_id} data={item} />;
                                })}
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className='grid grid-cols-1 gap-y-12 md:gap-y-4 md:grid-cols-3 '>
                    {skeletonArray.map((item, index) => (
                        <ExperienceCardSkeleton key={`sk_${index}`} />
                    ))}
                </div>
            )}
        </>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            // experienceDetailsAction,
        },
        dispatch
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth
});

export default connect(mapStateToProps, mapDispatchToProps)(ExperiencePurchasedList);
