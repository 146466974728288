import Footer2 from 'components/layouts/Footer2';
import LayoutHeader from 'components/layouts/LayoutHeader';
import LayoutHowTo from 'components/layouts/LayoutHowTo';

import { connect } from 'react-redux';

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import LayoutNavbar from 'components/layouts/LayoutNavbar';

const PageBasicsHowTo = ({ auth: { isAuthenticated, loading } }) => {
    return (
        <>
            <LayoutNavbar />
            {!isAuthenticated && <LayoutHeader />}
            <LayoutHowTo />
            <Footer2 isBody={true} isBottomBar={true} />
        </>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PageBasicsHowTo);
