import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleLang, toggleNav } from 'store/actions/globalState/master';
import { handleRowReverse } from 'helpers/FEutils';
// import LangList from 'components/blocks/LangList';
import translations from 'constants/translations';
import uiStruct from 'constants/uiStruct';
import Avatar from 'components/specialty/Avatar';
import LayoutNavbarSidebar from 'components/layouts/LayoutNavbarSidebar';
import { logout } from 'store/actions/auth';
import IconsLucide from 'components/blocks/IconsLucide';
import { ReactComponent as KLogoIcon } from 'components/svg/kn_logoicon_black.svg';
import { ReactComponent as KLogoText } from 'components/svg/kn_logotext.svg';
import debounce from 'helpers/debounce';
import { getHeadTitle } from 'helpers/menuHelpers';
import OfflineMode from 'components/blocks/OfflineMode';
import classNames from '../../../node_modules/classnames/index';
import MenuLink from 'components/blocks/MenuLink';
import Button from 'components/blocks/Title/Button';

const LayoutNavbar = ({
    // toggleLang,
    toggleNav,
    logout,
    globalState: { lang, navIsOpen, isPrintMode },
    auth: { user, isProfile }
}) => {
    // const [selectedLang, setSelectedLang] = useState(uiStruct.ui.languages[0]);
    const [scrollPos, setScrollPos] = useState(0);
    const [showHeader, setShowHeader] = useState(true);

    const rtl = !!translations[lang].rtl;

    useEffect(() => {
        const debouncedHandleScroll = debounce(handleScroll, 100);

        window.addEventListener('scroll', debouncedHandleScroll);

        return () => {
            window.removeEventListener('scroll', debouncedHandleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollPos]);

    const handleScroll = () => {
        if (typeof window !== 'undefined') {
            const currentScrollPos = window.scrollY;

            setShowHeader(currentScrollPos - scrollPos < 0 || scrollPos < 400);
            setScrollPos(currentScrollPos);
        }
    };

    // const handleLangChange = (selectedLang) => {
    //     setSelectedLang(selectedLang);
    //     toggleLang(selectedLang.id);
    // };

    const handleLogout = () => {
        toggleNav();
        logout();
    };

    return (
        <>
            <div
                style={{ zIndex: 51 }}
                className={`${handleRowReverse(rtl).rtl} print:hidden fixed top-0 inset-x-0 h-16 md:h-20 bg-green-400 bg-white-t transform-gpu duration-300 ${
                    showHeader ? 'translate-y-0 shadow-cards' : '-translate-y-16 md:-translate-y-24 shadow-none d-hdpi-2:-translate-y-vw-24'
                }`}
            >
                <div className='flex flex-1 flex-row justify-end md:justify-between items-center h-full'>
                    <div className='flex flex-grow justify-between max-w-2xl '>
                        <div className={`ml-6 md:ml-20 flex items-center gap-3 ${handleRowReverse(rtl).flex}`}>
                            <KLogoIcon className='opacity-90a mix-blend-multiplya' />
                            <span className=''>
                                <KLogoText className='opacity-80a mix-blend-multiply' />
                            </span>
                            
                            {/* <span className='text-xs mt-1'>{getHeadTitle(user.role)}</span> */}
                        </div>
                    </div>

                    <div className='flex justify-end items-center h-full'>
                        <div className='hidden md:flex text-sma items-center gap-4 lg:gap-8 mr-6 d-hdpi-2:mr-vw-6'>
                            <MenuLink
                                label={'Marketplace'}
                                href={process.env.REACT_APP_LINK_MARKETPLACE || 'https://marketplace.viakonnect.com/'}
                                type='url'
                                sameWindow
                            />

                            {/* <MenuLink label={'Home'} href='/c/experience/all' /> */}
                            <MenuLink
                                label={'How it works'}
                                href={`${process.env.REACT_APP_LINK_MARKETPLACE}/about/marketplace` || 'https://marketplace.viakonnect.com/about/marketplace'}
                                type='url'
                                sameWindow
                            />
                            <MenuLink label={'Kreator Portal'} href='/c/experience/all' />
                            {/* <MenuLink
                                label={'The Marketplace'}
                                href={process.env.REACT_APP_LINK_MARKETPLACE || 'https://marketplace.viakonnect.com/'}
                                type='url'
                                sameWindow
                            /> */}
                        </div>
                        {!isProfile ? (
                            <div className='hidden lg:flex md:pr-4 md:items-center md:gap-4'>
                                {/* <MenuLink label={'Sign in'} href={'/accounts/login'} /> */}
                                <Button
                                    color='bg-white text-gray-800 font-bold'
                                    animation={false}
                                    as='link'
                                    label='Sign in'
                                    link='/accounts/login'
                                    width='md:w-28'
                                    height='h-10'
                                />
                                <Button
                                    color='bg-gray-900 text-white font-semibold'
                                    animation={false}
                                    as='link'
                                    label='Become a Kreator'
                                    link='/accounts/signup'
                                    width='md:w-48'
                                    height='h-10'
                                />
                            </div>
                        ) : null}
                        {isProfile ? (
                            <div className='hidden lg:flex items-center mr-12'>
                                <div className='mx-4 text-sm'>{`${translations[lang][uiStruct.ui.messages.hello]} ${user?.profile?.first || 'Guest'}`}</div>
                                <Avatar profile={user?.profile} username={user?.username} />
                            </div>
                        ) : null}
                        {/* <LangList
                            langType
                            listData={uiStruct.ui.languages}
                            val={selectedLang}
                            handleChange={handleLangChange}
                        /> */}

                        <button
                            onClick={() => toggleNav(!navIsOpen)}
                            className={`focus:outline-none w-20 
                               flex flex-shrink-0 h-12 items-center justify-center text-2xl text-white bg-gray-900 hover:bg-white bg-green-400-t  hover:text-gray-900 ${
                                   rtl ? 'rounded-r-lg' : 'rounded-l-lg'
                               }`}
                        >
                            <i className={`${rtl ? 'ri-menu-2-line' : 'ri-menu-3-line'}`}></i>
                        </button>
                    </div>
                </div>
                {!isProfile ? (
                    <div className='lg:hidden flex items-center gap-4 w-full justify-center bg-gray-900 h-16'>
                        {/* <MenuLink label={'Sign in'} href={'/accounts/login'} /> */}
                        <Button
                            color='bg-white text-gray-800 font-bold'
                            animation={false}
                            as='link'
                            label='Sign in'
                            link='/accounts/login'
                            width='w-28 md:w-28'
                            height='h-8'
                            fontSize='text-sm'
                        />
                        <Button
                            color='bg-green-400 text-green-900 font-semibold'
                            animation={false}
                            as='link'
                            label='Become a Kreator'
                            link='/accounts/signup'
                            width='w-48 md:w-48'
                            height='h-8'
                            fontSize='text-sm'
                        />
                    </div>
                ) : null}
            </div>
            <LayoutNavbarSidebar>
                {isProfile ? (
                    <>
                        <LayoutNavbarItem
                            label={translations[lang][uiStruct.ui.navmain.profile.title]}
                            icon='ri-user-3-line text-xl'
                            iconType='la'
                            link={`${process.env.REACT_APP_BASENAME}/settings/account/profile`}
                            handleClick={toggleNav}
                            hidden={!isProfile}
                            rtl={rtl}
                        />
                        <div className='border-b border-gray-200 my-4'></div>
                    </>
                ) : null}
                <div className='md:hidden'>
                    <LayoutNavbarItemUrl
                        label={'Marketplace'}
                        icon='ri-store-line text-xl'
                        iconType='la'
                        link={process.env.REACT_APP_LINK_MARKETPLACE || 'https://marketplace.viakonnect.com/'}
                        rtl={rtl}
                    />
                    <LayoutNavbarItemUrl
                        label={'How it works'}
                        icon='ri-book-read-line text-xl'
                        iconType='la'
                        link={`${process.env.REACT_APP_LINK_MARKETPLACE}/about/marketplace` || 'https://marketplace.viakonnect.com/about/marketplace'}
                        rtl={rtl}
                    />
                    <LayoutNavbarItem
                        label={'Kreator Portal'}
                        icon='ri-layout-masonry-line text-xl'
                        iconType='la'
                        link={'/c/experience/all'}
                        handleClick={toggleNav}
                        rtl={rtl}
                        trackActive={false}
                    />
                    <div className='border-b border-gray-200 my-4'></div>
                </div>

                {isProfile ? (
                    <>
                        <LayoutNavbarItem
                            label={'Kreator Dashboard'}
                            icon='ri-dashboard-line text-xl'
                            iconType='la'
                            link={`${process.env.REACT_APP_BASENAME_EXPERIENCE}/all`}
                            handleClick={toggleNav}
                            rtl={rtl}
                            pill='Soon'
                            disabled={true}
                            hidden
                        />
                        <LayoutNavbarItem
                            label={translations[lang][uiStruct.ui.navmain.kreations.title]}
                            icon='ri-lightbulb-line text-xl'
                            iconType='la'
                            link={`${process.env.REACT_APP_BASENAME_EXPERIENCE}/all`}
                            handleClick={toggleNav}
                            rtl={rtl}
                        />
                        <LayoutNavbarItem
                            label='My Published Kreations'
                            icon='ri-store-line text-xl'
                            iconType='la'
                            link={`${process.env.REACT_APP_BASENAME_PUBLISHED}/all`}
                            handleClick={toggleNav}
                            rtl={rtl}
                        />
                        <LayoutNavbarItem
                            label={translations[lang][uiStruct.ui.navmain.payout.title]}
                            // icon='DollarSign'
                            icon='ri-bank-line text-xl'
                            iconType='la'
                            link={`${process.env.REACT_APP_BASENAME}/payout`}
                            handleClick={toggleNav}
                            rtl={rtl}
                            pill='Soon'
                            disabled={true}
                            hidden
                        />
                        <div className='border-b border-gray-200 my-4'></div>
                        <LayoutNavbarItem
                            label={'My Purchases'}
                            icon='ri-wallet-line text-xl'
                            iconType='la'
                            link={`/c/purchased`}
                            handleClick={toggleNav}
                            rtl={rtl}
                            // pill='Soon'
                            disabled={false}
                        />
                    </>
                ) : null}

                <LayoutNavbarItem
                    label={'Sign in'}
                    icon='ri-login-box-line text-xl'
                    iconType='la'
                    // icon='LogOut'
                    link='/accounts/login'
                    trackActive={false}
                    // handleClick={handleLogout}
                    rtl={rtl}
                    hidden={isProfile}
                />
                <LayoutNavbarItem
                    label={'Register'}
                    icon='ri-user-add-line text-xl'
                    iconType='la'
                    // icon='LogOut'
                    link='/accounts/signup'
                    trackActive={false}
                    // handleClick={handleLogout}
                    rtl={rtl}
                    hidden={isProfile}
                />

                <div className='border-b border-gray-200 my-4'></div>
                <LayoutNavbarItem
                    label='Experience Basics'
                    // icon='GraduationCap'
                    icon='las la-graduation-cap text-2xl'
                    iconType='la'
                    link={`/basics`}
                    handleClick={toggleNav}
                    rtl={rtl}
                    pill='New'
                    // disabled={true}
                />
                <LayoutNavbarItem
                    label='How-to videos'
                    // icon='GraduationCap'
                    icon='ri-video-line text-2xl'
                    iconType='la'
                    link={`/basics/how-to`}
                    // hash='how-to'
                    handleClick={toggleNav}
                    rtl={rtl}
                    pill='New'
                    // disabled={true}
                />
                <div className='border-b border-gray-200 my-4'></div>
                <LayoutNavbarItem
                    label={translations[lang][uiStruct.ui.navmain.signout.title]}
                    icon='ri-logout-box-r-line text-xl'
                    iconType='la'
                    // icon='LogOut'
                    link='/'
                    trackActive={false}
                    handleClick={handleLogout}
                    rtl={rtl}
                    hidden={!isProfile}
                />
            </LayoutNavbarSidebar>
        </>
    );
};

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleLang,
            toggleNav,
            logout
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutNavbar);

const LayoutNavbarItem = ({ trackActive = true, ...rest }) => {
    const [linkActive, setLinkActive] = useState(false);
    return rest.hidden ? null : !rest.disabled ? (
        <NavLink
            exact
            className='flex items-center px-12'
            to={{ pathname: rest.link, hash: rest.hash }}
            onClick={rest.handleClick ? () => rest.handleClick(false) : null}
            isActive={(match, location) => {
                if (trackActive) {
                    if (!match) {
                        setLinkActive(false);
                    } else setLinkActive(true);
                } else setLinkActive(false);
            }}
        >
            <LayoutNavbarItemStatic {...rest} isActive={linkActive} />
        </NavLink>
    ) : (
        <div className='flex items-center px-12'>
            <LayoutNavbarItemStatic {...rest} />
        </div>
    );
};

const LayoutNavbarItemUrl = ({ trackActive = true, ...rest }) => {
    return rest.hidden ? null : !rest.disabled ? (
        <a className='flex items-center px-12' href={rest.link}>
            <LayoutNavbarItemStatic {...rest} />
        </a>
    ) : (
        <div className='flex items-center px-12'>
            <LayoutNavbarItemStatic {...rest} />
        </div>
    );
};

const LayoutNavbarItemStatic = ({
    isActive,
    label,
    icon,
    iconType = 'lucide',
    link = '/',

    handleClick = () => {},
    rtl = false,
    pill = null,
    disabled = false
}) => {
    return (
        <div
            className={classNames(
                rtl ? 'text-base' : 'text-sm',
                isActive && 'bg-green-100 text-green-600',
                !disabled && 'hover:bg-green-50 hover:text-green-600 select-none',
                'rounded-lg flex flex-grow py-2 px-8 items-center text-gray-400 transition duration-200'
            )}
        >
            {iconType === 'lucide' && (
                <span className='mx-2 w-8'>
                    <IconsLucide icon={icon} />
                </span>
            )}

            {iconType === 'la' && (
                <span className='mx-2 w-8'>
                    <i className={icon}></i>
                </span>
            )}

            <span className='whitespace-nowrap'>{label}</span>
            {pill && <span className={`${handleRowReverse(rtl).ml}-2 rounded-full bg-gray-900 text-white text-xs italic px-2 py-1`}>{pill}</span>}
        </div>
    );
};
