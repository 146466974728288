import ImageHover from 'components/blocks/ImageHover';
import { connect } from 'react-redux';
import SkeletonParagraph from 'components/blocks/SkeletonParagraph';
// import SkeletonText from 'components/blocks/SkeletonText';
import { handleRowReverse } from 'helpers/FEutils';
import CardXlEditDelete from '../CardXlEditDelete';
import RawCard from './RawCard';

import uiStruct from 'constants/uiStruct';
import { useNavigate } from 'react-router-dom';
import { useOnlineStatus } from 'helpers/useOnlineStatus';

const ItineraryCard = ({
    days,
    setSelectedDay,
    setModalIsOpen,
    data,
    index,
    experienceId,
    globalState: { lang, edit, isPrintMode, isFeedbackMode, isPublishedView },
    publicView = false,
    type
}) => {
    let history = useNavigate();
    const isOnline = useOnlineStatus();
    const rtl = false;
    const { day_featured_image, description_html, title } = data;

    const featuredImageUrl = day_featured_image
        ? day_featured_image.type !== 'vid'
            ? `${day_featured_image.data}-/format/webp/-/quality/lighter/-/progressive/yes/-/max_icc_size/10/-/scale_crop/320x320/smart_faces_objects_points/`
            : `https://img.youtube.com/vi/${day_featured_image.data}/sddefault.jpg`
        : '';

    const imgProp =
        day_featured_image.type !== 'vid'
            ? { src: featuredImageUrl }
            : day_featured_image.type === 'vid'
            ? { src: featuredImageUrl }
            : {};

    const handleClick = () => {
        history(
            `${
                publicView
                    ? '/experience/'
                    : isPublishedView
                    ? '/c/published/'
                    : '/c/experience/'
            }${experienceId}/${index + 1}/`,
            { replace: true }
        );
    };

    const openDay = () => {
        setModalIsOpen(true);
        setSelectedDay(index);
    };

    const dayLabel = days > 1 ? `Day ${index + 1}` : null;

    return (
        <RawCard label={dayLabel}>
            {edit && !isFeedbackMode && isOnline && (
                <>
                    <CardXlEditDelete
                        handleDelete={null}
                        handleEdit={isOnline ? handleClick : null}
                        colors={uiStruct.ui.tip_list.UI_LBL_GREEN}
                        rtl={rtl}
                        close='center'
                        rounded='rounded-xl'
                    />
                </>
            )}
            <div
                onClick={
                    type.toLowerCase() === 'guided' && isOnline ? openDay : null
                }
                className={`${
                    type.toLowerCase() === 'guided'
                        ? 'hover:border-green-400 transition-all duration-300 cursor-pointer'
                        : ''
                }  border-2 border-transparent   overflow-hidden2 rounded-2xl px-6 py-6 flex flex-col md:flex-row gap-4 md:gap-8 w-full  ${
                    handleRowReverse(rtl).rtl
                }`}
            >
                <div>
                    <div className='rounded-2xl object-cover w-full xl:w-52 lg:w-44 md:w-40 overflow-hidden h-full xl:min-h-60 lg:min-h-52 md:min-h-48'>
                        <ImageHover
                            bg='bg-gray-50'
                            disabled
                            nohover
                            url={imgProp.src}
                            className={`${
                                day_featured_image.type === 'vid'
                                    ? 'scale-135'
                                    : ''
                            }`}
                        />
                        {/* <img
                                className='object-cover w-full h-full transform scale-135'
                                {...imgProp}
                                alt=''
                            /> */}
                    </div>
                </div>

                <div className='flex-1 flex flex-col md:pt-4 pb-4 md:pr-2 '>
                    {/* <div className={`z-10`}>
                        <h2 className='text-xl lg:text-2xl text-green-400 font-bold'>
                            Day {index + 1}
                        </h2>
                    </div> */}
                    <div className=''>
                        <div
                            className={`${handleRowReverse(rtl).rtl} ${
                                title ? '' : 'italic text-sm text-gray-400'
                            } text-transparent bg-clip-text bg-gradient-to-tr from-gray-900 via-blue-500 to-green-400 text-xl lg:text-2xl leading-tight
                                            font-bold mt-2 line-clamp-3`}
                        >
                            {title}
                        </div>
                    </div>

                    {description_html ? (
                        <div
                            className='text-gray-800 mt-4 block-html line-clamp-7 md:line-clamp-7 lg:line-clamp-7 xl:line-clamp-none text-sm lg:text-sm break-words'
                            dangerouslySetInnerHTML={{
                                __html: description_html
                            }}
                        />
                    ) : (
                        <SkeletonParagraph words={24} />
                    )}

                    <div className='flex'>
                        {/* <Link
                                    className='flex-shrink-0 rounded-full border-2 border-green-400 px-4 py-2'
                                    to={`${
                                        !publicView ? '/c' : ''
                                    }/experience/${experienceId}/${index + 1}/`}
                                >
                                    <span>Detailed Schedule</span>
                                </Link> */}
                    </div>
                </div>
            </div>
        </RawCard>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(ItineraryCard);
