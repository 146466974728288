import { ProgressPage } from 'components/blocks/ProgressMenu';
import CollapseButton from 'components/blocks/CollapseButton';
import { useOnlineStatus } from 'helpers/useOnlineStatus';

const ExperienceCardBar = ({
    isOwner,
    isAdminStaff,
    isSuperAdmin = false,
    expStatus,
    feedbackAction,
    cloneAction,
    handleGuided,
    handleDigital,
    handleDelete,
    type,
    rtl,
    completion = 100,
    inPublished = false,
    experienceStatus,
    viewPublished,
    myPublished = false,
    handleExpReport
}) => {
    const isOnline = useOnlineStatus();

    return (
        <div className={`z-50 absolute top-0 ${rtl ? 'left-0' : 'right-0'}`}>
            <div className='flex-1 flex flex-col p-4 justify-end text-2xl gap-2'>
                {(isOwner || isAdminStaff) && !myPublished && (
                    <div className='flex justify-end'>
                        <CollapseButton labelHover={`${completion}% completed`} size='10' sizeHover='w-40' textSize='text-xs' noicon offsetCenter='2' nolink>
                            <ProgressPage completion={completion} size='5' color='' nolabel strokeWidth={12} />
                        </CollapseButton>
                    </div>
                )}
                {/* {isAdminStaff && !viewPublished && isSuperAdmin ? (
                    <div className='flex justify-end'>
                        <CollapseButton
                            disabled={!isOnline}
                            labelHover={'Admin Settings'}
                            handleClick={feedbackAction}
                            icon={`ri-equalizer-line text-xl`}
                            size='10'
                            textSize='text-xs'
                            sizeHover='w-44'
                            offsetCenter='2'
                            padding='px-2.5'
                        />
                    </div>
                ) : null} */}
                {isAdminStaff && !viewPublished ? (
                    <div className='flex justify-end'>
                        <CollapseButton
                            disabled={!isOnline}
                            labelHover={'Clone Experience'}
                            handleClick={cloneAction}
                            icon={`ri-file-copy-2-line text-xl`}
                            size='10'
                            textSize='text-xs'
                            sizeHover='w-44'
                            offsetCenter='2'
                            padding='px-2.5'
                        />
                    </div>
                ) : null}

                {((isAdminStaff && isSuperAdmin) || isOwner) && viewPublished && true && (
                    <>
                        {type?.toUpperCase() === 'GUIDED' ? (
                            <>
                                <div className='hidden lg:flex justify-end'>
                                    <CollapseButton
                                        disabled={!isOnline}
                                        labelHover='Availability & Price'
                                        handleClick={handleGuided}
                                        icon='ri-calendar-2-line text-xl'
                                        size='10'
                                        textSize='text-xs'
                                        sizeHover='w-40'
                                    />
                                </div>
                            </>
                        ) : (
                            <div className='hidden lg:flex justify-end'>
                                <CollapseButton
                                    disabled={!isOnline}
                                    labelHover='Set Price'
                                    handleClick={handleDigital}
                                    icon='ri-coins-line text-2xl -ml-0.5'
                                    size='10'
                                    textSize='text-xs'
                                    sizeHover='w-32'
                                    offsetCenter='2'
                                />
                            </div>
                        )}
                        {myPublished && (
                            <div className='hidden lg:flex justify-end'>
                                <CollapseButton
                                    disabled={!isOnline}
                                    labelHover='Orders'
                                    handleClick={handleExpReport}
                                    icon='ri-file-list-3-line text-2xl -ml-0.5'
                                    size='10'
                                    textSize='text-xs'
                                    sizeHover='w-32'
                                    offsetCenter='2'
                                />
                            </div>
                        )}
                    </>
                )}

                {((isOwner && !inPublished && !viewPublished) ||
                    (isAdminStaff && isSuperAdmin && !inPublished) ||
                    (viewPublished && (isAdminStaff || isSuperAdmin))) && (
                    <div className='flex justify-end'>
                        <CollapseButton
                            disabled={!isOnline}
                            labelHover='Delete Experience'
                            handleClick={handleDelete}
                            icon='ri-delete-bin-line text-xl'
                            size='10'
                            textSize='text-xs'
                            sizeHover='w-44'
                            offsetCenter='2'
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ExperienceCardBar;
