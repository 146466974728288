import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useDraggableInPortal from 'helpers/useDraggableInPortal';
import IncludeItem from 'components/blocks/IncludeItem';
import PanelMarketing from './PanelMarketing';

function IncludesExcludesList({
    handleDeleteAction,
    includeType,
    edit,
    isFeedbackMode,
    includeData,
    handleArrangeAction,
    handleUpdateAction
}) {
    const renderDraggable = useDraggableInPortal();
    const handleOnDragEnd = (result) => {
        const { source, destination } = result;
        if (source && destination && source.index !== destination.index) {
            handleArrangeAction(source.index, destination.index, includeType);
        }
    };
    const deleteClick = (index) => {
        handleDeleteAction(index, includeType);
    };
    const editClick = (index) => {
        handleUpdateAction(index, includeType);
    };
    const buildList = () => {
        return includeData.map((obj, index, arr) => {
            const key = `${obj.title}_${index}`;

            return (
                <Draggable key={key} draggableId={key} index={index}>
                    {renderDraggable((provided) => (
                        <IncludeItem
                            handleUpdateAction={editClick}
                            deleteClick={deleteClick}
                            index={index}
                            provided={provided}
                            edit={edit}
                            itemData={obj}
                            includeType={includeType}
                            isFeedbackMode={isFeedbackMode}
                        />
                    ))}
                </Draggable>
            );
        });
    };

    const pillObj = {
        class:
            includeType === 'includes'
                ? 'ri-check-line text-xl text-green-500'
                : 'ri-close-line text-xl text-red-500',
        title: includeType === 'includes' ? 'Included' : 'Excluded'
    };

    return (
        <div className=''>
            <PanelMarketing
                title={pillObj.title}
                padding='pt-6 pb-0'
                paddingX='md:px-4'
                pillClass='text-white bg-gray-900 px-6'
                textStyle='text-sm'
                iconClass={pillObj.class}
            >
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId={'xxxx'}>
                        {(provided) => (
                            <ul
                                className='h-full'
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {buildList(provided)}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            </PanelMarketing>
        </div>
    );
}

export default IncludesExcludesList;
