import React, { useEffect, useRef } from 'react';
import TitleGradient from 'components/blocks/TitleGradient';
import LayoutUserForm from 'components/layouts/LayoutUserForm';
import LayoutNavbar from 'components/layouts/LayoutNavbar';

function PageProfileAdmin() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <LayoutNavbar />
            <div className='w-full mt-32'>
                <div
                    className={`relative 
                    px-4 md-px-0 max-w-2xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl mx-auto `}
                >
                    <TitleGradient size='text-4xl' text='Kreator Profile' />

                    <LayoutUserForm />
                </div>
            </div>
        </>
    );
}

export default PageProfileAdmin;
