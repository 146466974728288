import { cloneDeep } from 'lodash';

export const addRef = (draft, action) => {
    const { objId, elmRef } = action;
    draft.experience_refs[objId] = elmRef;

    return draft;
};

export const addComment = (draft, action) => {
    const { objId, comment, elmRef } = action;

    if (draft.experience_status.feedback_object[objId]) {
        draft.experience_status.feedback_object[objId].data.push(comment);
    } else {
        draft.experience_status.feedback_object[objId] = {
            read: false,
            data: [comment]
        };
    }
    if (!draft.experience_refs[objId]) {
        draft.experience_refs[objId] = elmRef;
    }

    return draft;
};

export const deleteComment = (draft, action) => {
    const { objId, index } = action;
    const draftCopy = cloneDeep(draft.experience_status.feedback_object[objId]);
    draft.experience_status.feedback_object[objId].data = draftCopy.data.filter(
        (elm, i) => index !== i
    );

    return draft;
};

export const updateComment = (draft, action) => {
    const { objId, index } = action;
    const currentState =
        draft.experience_status.feedback_object[objId].data[index].completed;
    draft.experience_status.feedback_object[objId].data[index].completed =
        !currentState;

    return draft;
};
