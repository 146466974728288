/* eslint-disable react/jsx-pascal-case */
// import React, { useState, useEffect, useReducer, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { Range } from 'rc-slider';
// import DescriptionTitleModal from 'components/modals/DescriptionTitleModal';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic';
// import * as types from 'store/actions/types';
// import translations from 'constants/translations';
import 'styles/rcSlider.css';
// import { CardXlContent } from './CardXl';
import useXchangeRate from 'helpers/useXchangeRate';
import { formatPrice } from 'helpers/LocaleHelper';
import { currenciesObject } from 'constants/currenciesObject';
import { countries } from 'constants/countries';

// const currencyOptions = {
//     rounding: 0.01
// };

function CurrencyBox({
    country,
    // experienceDetailsAction,
    experienceDetails: { budget_max, budget_min, budget_currency = 'USD' },
    globalState: { lang, edit },
    auth: {
        user: {
            profile: { currency: preferredCurrency = 'USD' }
        }
    }
}) {
    const currencyCode = countries[country].currencyCode;
    // const currencyCode = undefined;

    const {
        rate,
        currency: toCurrency,
        fromCurrency
    } = useXchangeRate([1], currencyCode, preferredCurrency);

    // console.log('from', fromCurrency);
    // console.log('to', toCurrency);

    // const [formattedPricesUser, setFormattedPricesUser] = useState({
    //     min: formatPrice(
    //         budget_min * preferredRate,
    //         preferredCurrency,
    //         window?.navigator?.language
    //     ),
    //     max: formatPrice(
    //         budget_max * preferredRate,
    //         preferredCurrency,
    //         window?.navigator?.language
    //     )
    // });

    return currenciesObject[currencyCode] ? (
        <div className=''>
            <>
                <div className='bg-green-50 rounded-3xl py-6 relative px-4 w-full'>
                    <div className='flex items-center gap-8'>
                        <div className='w-min italic whitespace-nowrap mb-3 sm:mb-0 font-bold tracking-tight flex flex-none justify-center items-center bg-green-200 rounded-full px-6 text-green-700 h-8'>
                            Official Currency
                        </div>
                    </div>
                    <div className='flex flex-col w-max mx-auto mt-4 md:mt-0 md:mb-4'>
                        <div className='flex flex-col lg:flex-row justify-center items-center text-green-700 gap-2'>
                            <div
                                className={`flex items-center gap-2 justify-center text-normal md:text-2xl uppercase`}
                            >
                                {`${fromCurrency?.name_single}`}
                            </div>
                        </div>
                        {currencyCode !== preferredCurrency && (
                            <>
                                <div className='flex items-center justify-items py-4 opacity-75'>
                                    <span className='flex-1 border-b border-green-300 ' />
                                </div>
                                <div className='flex flex-col lg:flex-row justify-center items-center text-green-700 gap-2'>
                                    <div
                                        className={`flex items-center justify-center text-normal md:text-2xl`}
                                    >
                                        <span>1</span>
                                        <span className='ml-2'>
                                            {toCurrency?.symbol}
                                        </span>
                                        <span className='px-4'>~</span>
                                        <span className='font-semibold'>
                                            {formatPrice(
                                                1 / rate,
                                                preferredCurrency,
                                                window?.navigator?.language,
                                                {
                                                    rounding:
                                                        1 /
                                                        Math.pow(
                                                            10,
                                                            toCurrency.decimals
                                                        )
                                                }
                                            )}
                                        </span>
                                        <span className='ml-2 font-semibold'>
                                            {fromCurrency?.symbol}
                                        </span>
                                    </div>
                                    <div
                                        className={`flex items-center justify-center text-xs uppercase font-normal `}
                                    >
                                        {`(${fromCurrency?.name})`}
                                    </div>
                                </div>
                                <div className='flex justify-center text-xxs mt-4'>
                                    Note: Rates are updated every hour.
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </>
        </div>
    ) : null;
}
const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            experienceDetailsAction
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyBox);
