import Icons from 'components/blocks/Icons';
import { handleRowReverse } from 'helpers/FEutils';

const CardXlDragHandleMain = ({ dragProps, rtl }) => {
    const rtlClass = rtl ? 'right' : 'left';
    return (
        <div {...dragProps}>
            <div
                className={`absolute transform top-6 -${rtlClass}-2.5 sm:-${rtlClass}-12 `}
            >
                <div
                    className={`bg-gray-300 rounded-lg py-2 flex items-start justify-center sm:bg-transparent`}
                >
                    <Icons
                        iName='DOTS6V'
                        size='xl'
                        iClasses='text-gray-900 opacity-50 sm:text-gray-900 sm:opacity-30 hover:opacity-100'
                    />
                </div>
            </div>
        </div>
    );
};

const CardXlDragHandleItem = ({ dragProps, dragOff, rtl }) => {
    const rtlClass = rtl ? 'right' : 'left';
    return (
        <div
            {...dragProps}
            className={`z-50 absolute transform top-1/2 -translate-y-1/2 xl:-${rtlClass}-6 lg:-${rtlClass}-8 md:-${rtlClass}-8 -${rtlClass}-7 `}
            style={{ display: dragOff ? 'none' : 'block' }}
        >
            <div
                className={`bg-gray-100 rounded-lg py-2 flex items-start justify-center sm:bg-transparent`}
            >
                <Icons
                    iName='DOTS6V'
                    size='xl'
                    iClasses='text-gray-900 opacity-50 sm:text-gray-900 sm:opacity-30 hover:opacity-100'
                />
            </div>
        </div>
    );
};

const CardXlDragHandleMenuItem = ({ dragProps, dragOff, rtl }) => {
    // const rtlClass = rtl ? 'right' : 'left';
    return (
        <div
            {...dragProps}
            className={`z-50 absolute ${
                dragOff ? 'hidden' : ''
            } transform top-1/2 -translate-y-1/2 ${
                handleRowReverse(rtl).right
            }-2`}
        >
            <div
                className={`bg-gray-100 rounded-lg py-2 flex items-start justify-center sm:bg-transparent`}
            >
                <Icons
                    iName='DOTS6V'
                    size='xl'
                    iClasses='text-gray-900 opacity-50 sm:text-gray-900 sm:opacity-30 hover:opacity-100'
                />
            </div>
        </div>
    );
};

export { CardXlDragHandleMain, CardXlDragHandleItem, CardXlDragHandleMenuItem };
