/* eslint-disable react/jsx-pascal-case */
import { connect } from 'react-redux';

// import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { handleRowReverse } from 'helpers/FEutils';

function CircleListItem({
    byDay = 'initial',
    // edit,
    globalState: { lang },
    accommInfo
    // defaultHtml = 'Amine tanslate',
    // hintObj = uiStruct.itinerary.activities.uiEdit.modals.description.hint,
    // icon = uiStruct.itinerary.activities.uiEdit.modals.description.icon,
    // nohover = null,

    // index
}) {
    const rtl = !!translations[lang].rtl;

    const {
        label,
        circle: {
            location: { lat, lng }
        }
    } = accommInfo;

    // const googleMapLink = `https://maps.google.com/?q=${lat},${lng}&ll=${lat},${lng}&z=16`;

    const makeDays = () => {
        if (byDay !== 'initial') {
            return null;
        }
        const { days } = accommInfo;

        if (days) {
            return (
                <div className='mt-6 flex gap-2 items-center flex-wrap'>
                    {days.map((day) => {
                        const key = `Day${day}`;
                        return (
                            <div
                                key={key}
                                className={`text-gray-900 group text-xs bg-white flex flex-shrink-0 gap-2 flex-nowrap items-center hover:bg-blue-200 rounded-full px-3 py-0.5 ${
                                    handleRowReverse(rtl).flex
                                }`}
                            >
                                <i className='group-hover:text-blue-700 text-blue-400 text-lg ri-check-line'></i>

                                <span
                                    className={`break-all ${
                                        handleRowReverse(rtl).text
                                    }`}
                                >
                                    Day {day}
                                </span>
                            </div>
                        );
                    })}
                </div>
            );
        }
        return null;
    };

    // const openLink = () => {
    //     if (edit) return null;
    //     else return window.open(googleMapLink, '_blank', 'noopener,noreferrer');
    // };

    return (
        <div
            className={`relative  transition-colors ease-in-out duration-500 rounded-2xl px-4 md:px-8 py-4 flex flex-col md:flex-row md:${
                handleRowReverse(rtl).flex
            } ${handleRowReverse(rtl).rtl} ${
                handleRowReverse(rtl).justify
            } flex-wrap md:flex-nowrap mb-4  hover:transparent mt-2 bg-blue-100 shadow-cards2-blue`}
        >
            <div className=''>
                <div className='flex items-center '>
                    <span className='px-4 py-1 bg-blue-300 text-blue-900 text-sm rounded-full font-bold italic mr-4'>
                        Region
                    </span>

                    <span className='mr-4 text-sm'>{label} </span>
                    <span>
                        <i className='ri-map-pin-line text-xl text-blue-600'></i>
                    </span>
                </div>
                {makeDays()}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(CircleListItem);
