import CollapseButton from 'components/blocks/CollapseButton';

const LayoutMenuEditPublic = ({
    // isOwner,
    // isAdminStaff,
    author,
    // expStatus,
    // showExtra = true,
    // tagsActionBtn,
    // guidedActionBtn,
    // includesActionBtn,
    // feedbackAction,
    // submitExperienceAction,
    // type,
    // edit,
    // handleEdit,
    // toolTips,
    // handleToolTips,
    // isSaving,
    // handleSave,
    rtl
    // completion = 0
}) => {
    return (
        <div className=''>
            <div className={`z-50 fixed top-24 ${rtl ? 'left-0' : 'right-0'}`}>
                {' '}
                <div className='flex-1 flex flex-col p-4 justify-end text-2xl gap-2'>
                    {author.role ===
                        process.env.REACT_APP_STRAPI_STAFF_ROLE_ID && (
                        <div className='flex justify-end'>
                            <CollapseButton
                                labelHover='Educational Sample'
                                icon='las la-chalkboard-teacher text-2xl'
                                size='12'
                                textSize='text-xs font-bold'
                                sizeHover='w-48'
                                offsetCenter='2'
                                padding='px-3'
                                btnColor={`bg-purple-200 text-purple-800 hover:ring-purple-400`}
                                hoverText=''
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LayoutMenuEditPublic;
