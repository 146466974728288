/* eslint-disable react/jsx-pascal-case */

import { BlockInputSingle } from 'components/blocks/Blocks';
import CheckboxStd from 'components/blocks/CheckboxStd';
// import ListBoxGeneric from './ListBoxGeneric';
import GenericSelectList from 'components/blocks/GenericSelectList';
// import { countriesTranslations } from 'constants/countriesTranslations.js';
import { currenciesArray } from 'constants/currenciesArray';

const Pricing = ({
    onChange,
    free,
    // types,
    // selected,
    // setSelectedType,
    minPrice,
    maxPrice,
    handleSetPrices,
    currency,
    handleCurrencyChange
    // errorObj
}) => {
    const currencyList = [
        'Select currency',
        ...currenciesArray.map((currency) => {
            return {
                id: currency.code,
                label: `${currency.name} - ${currency.symbol}`
            };
        })
    ];

    // const updateCurrencyFilter = (currencyStr) => {
    //     // console.log('logged is ', currencyStr);
    // };

    return (
        <div className=''>
            <div className='flex flex-col md:items-center md:flex-row flex-nowrap gap-8 w-full'>
                <CheckboxStd
                    color='bg-white'
                    name='terms'
                    isChecked={free}
                    setIsChecked={onChange}
                    text='Free'
                />

                {!free ? (
                    <div className='flex flex-col md:flex-row md:items-center gap-4 w-full'>
                        <BlockInputSingle
                            whiteBg={true}
                            normal
                            error={
                                Number(minPrice) <= 0 ||
                                Number(maxPrice) < Number(minPrice)
                            }
                            handleChange={handleSetPrices}
                            width='w-full md:w-24'
                            height='h-10'
                            type='number'
                            className='text-sm'
                            id='min'
                            name='min'
                            value={minPrice === null ? 0 : minPrice}
                            placeholder={'min'}
                        />

                        <BlockInputSingle
                            whiteBg={true}
                            normal
                            error={
                                Number(maxPrice) <= 0 ||
                                Number(maxPrice) < Number(minPrice)
                            }
                            handleChange={handleSetPrices}
                            width='w-full md:w-24'
                            height='h-10'
                            type='number'
                            className='text-sm'
                            id='max'
                            name='max'
                            value={maxPrice === null ? 0 : maxPrice}
                            placeholder={'max'}
                        />

                        <GenericSelectList
                            selectOptions={currencyList}
                            handleChange={handleCurrencyChange}
                            keys={{ id: 'id', label: 'label' }}
                            selectedValue={currency}
                            height='2.5rem'
                            width='w-full'
                            bgColor='white'
                            panelHeight='150px'
                            placement='top'
                            labelTextSize='0.875rem'
                            menuTextSize='0.75rem'
                        />
                        {/* <ListBoxGeneric
                            listData={types}
                            val={selected}
                            handleChange={setSelectedType}
                            color='bg-white'
                            className='flex-1'
                            textBtn='text-sm'
                            textClass='text-sm'
                            height='h-10'
                            direction='top'
                        /> */}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Pricing;
