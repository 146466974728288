/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Editor from 'components/blocks/Editor';
import {
    ModalFooter,
    ModalCTA,
    ModalButton,
    ModalHelp
} from 'components/blocks/ModalTre';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import GenericSelectList from 'components/blocks/GenericSelectList';
import { BlockInputSingle } from 'components/blocks/Blocks';
import ModalFullscreen, { ModalBody } from 'components/blocks/ModalFullscreen';
import ModalTopBlock from 'components/blocks/ModalTopBlock';

// should be in all modals
const targetElement = document.querySelector('#root');

function IncludesModal({
    close,
    setModalIsOpen,
    handleActionBtn,
    globalState: {
        lang,
        siteData: {
            settings: { whatsIncluded }
        }
    },
    updateObject,
    hintObj = null,
    icon = uiStruct.itinerary.moving_around.uiEdit.modals.add.icon,
    actionBtnObj = uiStruct.itinerary.moving_around.uiEdit.modals.add.buttons
        .action,
    cancelBtnObj = uiStruct.itinerary.moving_around.uiEdit.modals.add.buttons
        .cancel
}) {
    const rtl = !!translations[lang].rtl;
    // Copy Below to all modals
    //////////////////////////////////////////////////////////////////////

    // State for triggering animation of modal in and out
    const [transitionState, setTransitionState] = useState('off');
    const [validationObj, setValidationObj] = useState({
        title: { error: false }
    });
    const [dataObj, setDataObj] = useState(() => {
        return {
            ...updateObject.data,
            includeType: updateObject.includeType
        };
    });

    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    ///////////////////////////////////////////////////////////////////

    // Paul stuff Handle Action BTN function
    const validate = () => {
        let error = false;

        if (!dataObj.title.length) {
            setValidationObj({
                ...validationObj,
                title: { error: true }
            });
            error = true;
        }

        return error;
    };
    const handleActionClick = () => {
        // Validate first
        if (!validate()) {
            handleActionBtn(dataObj);
            handleTransitionStateOff(); // important... don't forget to add it in all modals
        }
    };

    ///////////////////////////////

    const changeIncludeTxt = (title) => {
        setDataObj({
            ...dataObj,
            title
        });
    };

    const updateIncludeText = (event) => {
        setDataObj({
            ...dataObj,
            title: event.target.value
        });
        setValidationObj({
            ...validationObj,
            title: { error: false }
        });
    };
    const updateDescriptionHtml = (desc) => {
        setDataObj({
            ...dataObj,
            desc
        });
    };

    const changeType = (includeType) => {
        setDataObj({
            ...dataObj,
            includeType
        });
    };

    return (
        <>
            <ModalFullscreen
                width='4xl'
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={icon}
                close={close}
                closeButton={true}
                rtl={rtl}
                fullscreen={true}
            >
                <ModalBody rtlOff>
                    <div className='max-w-7xl mx-auto'>
                        <div className='w-full  px-4 md:px-8 lg:px-2 mb-8 mt-4'>
                            <ModalTopBlock
                                title={"What's included or excluded?"}
                                subtitle={'Add an item'}
                                text={
                                    'Add all relevant information necessary for travellers regarding what services you will provide / are included. It is your responsibility to give as much information as possible.'
                                }
                                rtl={rtl}
                                lang={lang}
                            />
                        </div>
                        <div className='flex flex-col-reverse portrait:flex-col-reverse lg:flex-row gap-16 lg:gap-4 xl:gap-8 px-0 md:px-8 lg:px-0'>
                            <div className='lg:w-1/2 portrait:w-full'>
                                <div className='mb-8 border-2 border-gray-200 rounded-lg pt-4 min-h-96'>
                                    <Editor
                                        valError={false}
                                        handleChange={updateDescriptionHtml}
                                        html={dataObj.desc}
                                    />
                                </div>
                            </div>
                            <div
                                className={`flex flex-col lg:w-1/2 mt-4 py-4 px-8 md:px-4 portrait:w-full`}
                            >
                                <div className='mt-4 mb-4'>
                                    1. Do you want to add an included or exluded
                                    item?
                                </div>
                                <div className='flex items-center w-full mb-6 gap-4'>
                                    {/* <ListBoxGeneric
                                        listData={ratingTypes}
                                        val={selectedRatingType}
                                        handleChange={handleRatingTypeChange}
                                        className=''
                                        direction='bottom'
                                        height='h-10'
                                        textClass='text-sm'
                                        textBtn='text-sm'
                                    /> */}
                                    {/* <GenericSelectList
                            selectOptions={currencyList}
                            handleChange={handleCurrencyChange}
                            keys={{ id: 'id', label: 'label' }}
                            selectedValue={currency}
                            height='2.5rem'
                            width='w-full'
                            bgColor='white'
                            panelHeight='150px'
                            placement='top'
                            labelTextSize='0.875rem'
                            menuTextSize='0.75rem'
                        /> */}
                                    <GenericSelectList
                                        initialSelected={dataObj.includeType}
                                        selectOptions={[
                                            {
                                                id: 'includes',
                                                label: 'included'
                                            },
                                            {
                                                id: 'excluded',
                                                label: 'excluded'
                                            }
                                        ]}
                                        handleChange={changeType}
                                        height='2.5rem'
                                        width='w-full'
                                    />
                                </div>
                                <div className='mt-8 mb-4'>
                                    2. Select a type of item or type it in the
                                    box below
                                </div>

                                <div
                                    className={`flex flex-col md:flex-row lg:flex-col xl:flex-row md:items-center lg:items-start xl:items-center gap-2  md:gap-4 mb-4
                                }`}
                                >
                                    <span className='text-xs whitespace-nowrap w-36 ml-2 md:ml-0'>
                                        Item Title / Category
                                    </span>
                                    <div className='flex w-full flex-col-reverse md:flex-row flex-1 md:items-center gap-4'>
                                        <GenericSelectList
                                            // initialSelected={type}
                                            selectOptions={whatsIncluded[lang]}
                                            handleChange={changeIncludeTxt}
                                            height='2.5rem'
                                            width='w-full'
                                        />
                                    </div>
                                </div>

                                <div
                                    className={`flex flex-col md:flex-row lg:flex-col xl:flex-row md:items-center lg:items-start xl:items-center gap-2  md:gap-4 mb-4
                        }`}
                                >
                                    <span className='text-xs whitespace-nowrap w-36 ml-2 md:ml-0'>
                                        Or type your own
                                    </span>

                                    <div className='w-full flex flex-1 flex-col md:flex-row md:items-center gap-4'>
                                        <BlockInputSingle
                                            responsive='true'
                                            normal
                                            error={validationObj.title.error}
                                            handleChange={updateIncludeText}
                                            id='name'
                                            margins=''
                                            value={dataObj.title}
                                            placeholder={'Place holder text'}
                                            rtl={rtl}
                                            type={'text'}
                                            className='text-sm'
                                            height='h-12'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ModalFooter>
                            {hintObj && (
                                <ModalHelp
                                    helpText={translations[lang][hintObj]}
                                    isHtml
                                />
                            )}
                            <ModalCTA>
                                <ModalButton
                                    handleClick={handleTransitionStateOff}
                                    label={
                                        translations[lang][cancelBtnObj.label]
                                    }
                                    color={cancelBtnObj.color}
                                    icon={cancelBtnObj.icon}
                                />
                                <ModalButton
                                    handleClick={handleActionClick}
                                    label={
                                        translations[lang][actionBtnObj.label]
                                    }
                                    color={actionBtnObj.color}
                                    icon={actionBtnObj.icon}
                                />
                            </ModalCTA>
                        </ModalFooter>
                    </div>
                </ModalBody>
            </ModalFullscreen>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(IncludesModal);
