import { defaultMarketing } from 'constants/experiencesConsts.js';
import { nanoid } from 'nanoid';
import * as types from 'store/actions/types';

const forceId = false;

export const setExperinceRejected = (draft) => {
    draft.invalid = true;
    return draft;
};

export const setExperienceSingle = (draft, action) => {
    let catsObj = {};
    const {
        payload: { data: experienceData }
    } = action;
    const {
        _id: id,
        user,
        cats_list,
        cats,
        tags_list,
        places_lists,
        updatedAt,
        content: experience,
        type,
        budget_min,
        budget_max,
        budget_currency,
        content_marketing,
        guided_extra = {
            included: [],
            excluded: []
        },
        days,
        status,
        inPublished,
        completion,
        isEdu,
        title,
        experience_status
    } = experienceData;

    catsObj = cats.reduce((prev, next) => {
        return {...prev, [next.id]: next.id }
    }, {});

    if (!experience_status.feedback_object) experience_status.feedback_object = {};

    if (content_marketing) {
        if (!content_marketing?.intro?.objId || forceId) {
            content_marketing.intro.objId = {
                section: 'marketing_intro_section',
                title: 'marketing_intro_title',
                subtitle: 'marketing_intro_subtitle',
                description: 'marketing_intro_description',
                gallery: 'marketing_intro_gallery',
                tiplist: 'marketing_intro_tiplist'
            };
        }

        if (!content_marketing?.whatToDo?.objId || forceId) {
            content_marketing.whatToDo.objId = {
                section: 'marketing_whatToDo_section',
                title: 'marketing_whatToDo_title',
                subtitle: 'marketing_whatToDo_subtitle',
                description: 'marketing_whatToDo_description',
                gallery: 'marketing_whatToDo_gallery',
                tiplist: 'marketing_whatToDo_tiplist'
            };
        }

        if (!content_marketing?.whereToStay?.objId || forceId) {
            content_marketing.whereToStay.objId = {
                section: 'marketing_whereToStay_section',
                title: 'marketing_whereToStay_title',
                subtitle: 'marketing_whereToStay_subtitle',
                description: 'marketing_whereToStay_description',
                gallery: 'marketing_whereToStay_gallery',
                tiplist: 'marketing_whereToStay_tiplist'
            };
        }

        if (!content_marketing?.whatsIncluded?.objId || forceId) {
            content_marketing.whatsIncluded.objId = {
                section: 'marketing_whatsIncluded_section',
                title: 'marketing_whatsIncluded_title',
                subtitle: 'marketing_whatsIncluded_subtitle',
                description: 'marketing_whatsIncluded_description',
                gallery: 'marketing_whatsIncluded_gallery',
                tiplist: 'marketing_whatsIncluded_tiplist',
                includedlist: 'marketing_whatsIncluded_includedlist'
            };
        }

        if (!content_marketing?.whatsIncluded?.objId.includedlist || forceId) {
            content_marketing.whatsIncluded.objId.includedlist = 'marketing_whatsIncluded_includedlist';
        }

        if (!content_marketing?.policies || forceId) {
            content_marketing.policies = {
                objId: {
                    section: 'marketing_policy_section',
                    tiplist: 'marketing_policy_tiplist'
                },
                refund: {
                    description: '',
                    objId: 'marketing_policy_refund'
                },
                cancellation: {
                    description: '',
                    objId: 'marketing_policy_cancellation'
                }
            };
        }

        if (!content_marketing?.thankYou) {
            content_marketing.thankYou = defaultMarketing().thankYou;
        }

        if (!content_marketing?.thankYou?.objId || forceId) {
            content_marketing.thankYou.objId = {
                section: 'marketing_thankYou_section',
                title: 'marketing_thankYou_title',
                subtitle: 'marketing_thankYou_subtitle',
                description: 'marketing_thankYou_description',
                gallery: 'marketing_thankYou_gallery',
                tiplist: 'marketing_thankYou_tiplist'
            };
        }

        if (content_marketing?.whatsIncluded?.includes?.length > 0) {
            content_marketing.whatsIncluded.includes = setArrayObjId(content_marketing.whatsIncluded.includes, `inc_`);
        }
        if (content_marketing?.whatsIncluded?.excluded?.length > 0) {
            content_marketing.whatsIncluded.excluded = setArrayObjId(content_marketing.whatsIncluded.excluded, `inc_`);
        }
    }

    if (experience.budget == null) {
        experience.budget = {
            description_html: '',
            tips_html: [],
            isVisible: type.toLowerCase() === 'digital' ? true : false,
            objId: {
                section: 'overview_budget_section',
                tiplist: 'overview_budget_tiplist',
                vendorlist: 'overview_budget_vendorlist',
                description: 'overview_budget_description',
                range: 'overview_budget_range',
                gallery: 'overview_budget_gallery'
            }
        };
    }

    if (experience.accommodation.isVisible == null) experience.accommodation.isVisible = type.toLowerCase() === 'digital' ? true : false;

    if (experience.budget.isVisible == null) experience.budget.isVisible = type.toLowerCase() === 'digital' ? true : false;
    if (experience.best_time_to_go.isVisible == null) experience.best_time_to_go.isVisible = type.toLowerCase() === 'digital' ? true : false;
    if (experience.getting_there.isVisible == null) experience.getting_there.isVisible = type.toLowerCase() === 'digital' ? true : false;

    if (experience.general_tips_visible == null) {
        experience.general_tips_visible = {
            isVisible: type.toLowerCase() === 'digital' ? true : false,
            objId: {
                section: 'overview_general_tips_section',
                tiplist: 'overview_general_tips_tiplist',
                vendorlist: 'overview_general_tips_vendorlist',
                description: 'overview_general_tips_description',
                gallery: 'overview_general_tips_gallery'
            }
        };
    }

    if (experience.moving_around_visible == null) {
        experience.moving_around_visible = {
            isVisible: type.toLowerCase() === 'digital' ? true : false,
            objId: {
                section: 'overview_moving_around_section'
            }
        };
    }

    if (experience.local_currency_visible == null) {
        experience.local_currency_visible = {
            isVisible: type.toLowerCase() === 'digital' ? true : false,
            objId: {
                section: 'overview_currency_section'
            }
        };
    }

    if (experience.itinerary.trip_days.length > 0) {
        experience.itinerary.trip_days.forEach((day) => {
            if (Object.keys(day.what_you_need).length === 0) {
                day.what_you_need = {
                    description_html: '',
                    checklist: [],
                    isVisible: type.toLowerCase() === 'digital' ? true : false
                };
            }
            if (day.activities.length > 0) {
                day.activities.forEach((activity) => {
                    if (!('isVisible' in activity.best_time_to_go)) {
                        activity.best_time_to_go.isVisible = type.toLowerCase() === 'digital' ? true : false;
                    }
                    if (!('isVisible' in activity.duration)) {
                        activity.duration.isVisible = true;
                    }
                    if (!('isVisible' in activity.pricing)) {
                        activity.pricing.isVisible = type.toLowerCase() === 'digital' ? true : false;
                    }
                    if (typeof activity.reservation === 'string' && activity.reservation !== null) {
                        activity.reservation = {
                            type: activity.reservation,
                            isVisible: type.toLowerCase() === 'digital' ? true : false
                        };
                    }
                });
            }
        });
    }

    const fixedExperience = setInitialIds(experience, forceId);

    const tmpExp = {
        id,
        user,
        cats_list: cats_list.filter(cat => catsObj[cat]),
        tags_list,
        places_lists,
        updatedAt,
        type,
        experience: fixedExperience,
        dayIndex: 0,
        loading: false,
        saving: false,
        budget_min,
        budget_max,
        budget_currency,
        guided_extra,
        content_marketing: content_marketing || defaultMarketing(),
        days,
        status,
        inPublished,
        completion,
        isEdu,
        title,
        experience_status,
        experience_refs: {}
    };

    draft = tmpExp;

    return draft;
};

const setInitialIds = (experience, forceId) => {
    if (!experience.overview_intro.objId || forceId) {
        experience.overview_intro.objId = {
            section: 'overview_intro_section',
            featured: 'overview_intro_featured',
            image: 'overview_intro_image',
            title: 'overview_intro_title',
            description: 'overview_intro_description'
        };
    }

    if (!experience.budget.objId || forceId) {
        experience.budget.objId = {
            section: 'overview_budget_section',
            tiplist: 'overview_budget_tiplist',
            vendorlist: 'overview_budget_vendorlist',
            description: 'overview_budget_description',
            range: 'overview_budget_range'
        };
    }

    if (!experience.destination.objId || forceId) {
        experience.destination.objId = {
            section: 'overview_destination_section',
            tiplist: 'overview_destination_tiplist',
            vendorlist: 'overview_destination_vendorlist',
            destinationlist: 'overview_destination_destinationlist',
            description: 'overview_destination_description'
        };
    }

    if (!experience.best_time_to_go.objId || forceId) {
        experience.best_time_to_go.objId = {
            section: 'overview_best_time_to_go_section',
            tiplist: 'overview_best_time_to_go_tiplist',
            vendorlist: 'overview_best_time_to_go_vendorlist',
            description: 'overview_best_time_to_go_description',
            range: 'overview_best_time_to_go_range'
        };
    }

    if (!experience.accommodation.objId || forceId) {
        experience.accommodation.objId = {
            section: 'overview_accommodation_section',
            tiplist: 'overview_accommodation_tiplist',
            vendorlist: 'overview_accommodation_vendorlist',
            accommodationlist: 'overview_accommodation_locationlist',
            description: 'overview_accommodation_description',
            gallery: 'overview_accommodation_gallery'
        };
    }

    if (!experience.getting_there.objId || forceId) {
        experience.getting_there.objId = {
            section: 'overview_getting_there_section',
            tiplist: 'overview_getting_there_tiplist',
            vendorlist: 'overview_getting_there_vendorlist',
            description: 'overview_getting_there_description',
            entry: 'overview_getting_there_entry'
        };
    }

    if (!experience.general_tips_visible.objId || forceId) {
        experience.general_tips_visible.objId = {
            section: 'overview_general_tips_section',
            tiplist: 'overview_general_tips_tiplist',
            vendorlist: 'overview_general_tips_vendorlist',
            description: 'overview_general_tips_description',
            gallery: 'overview_general_tips_gallery'
        };
    }

    if (!experience.moving_around_visible.objId || forceId) {
        experience.moving_around_visible.objId = {
            section: 'overview_moving_around_section'
        };
    }

    if (!experience.local_currency_visible.objId || forceId) {
        experience.local_currency_visible.objId = {
            section: 'overview_currency_section'
        };
    }

    const tipsGroups = ['budget', 'destination', 'best_time_to_go', 'getting_there', 'accommodation'];

    tipsGroups.forEach((groupTitle) => {
        experience[groupTitle].tips_html = setArrayObjId(experience[groupTitle].tips_html, 'tip_');
    });
    experience.tips_html = setArrayObjId(experience.tips_html, 'tip_');

    const vendorGroups = ['getting_there'];

    vendorGroups.forEach((groupTitle) => {
        if (experience[groupTitle].vendors?.length > 0) {
            experience[groupTitle].vendors = setArrayObjId(experience[groupTitle].vendors, 'vendor_');
        }
    });

    if (experience.accommodation.locations?.length > 0) {
        experience.accommodation.locations = setArrayObjId(experience.accommodation.locations, 'overview_accommodation_');
    }

    if (experience.accommodation.images?.length > 0) {
        experience.accommodation.images = setArrayObjId(experience.accommodation.images, `image_`);
    }
    if (experience.moving_around.length > 0) {
        experience.moving_around.forEach((moving_around) => {
            if (!moving_around.objId || forceId) {
                const objId = nanoid();
                moving_around.objId = {
                    section: `overview_moving_around_section_${objId}`,
                    title: `overview_moving_around_title_${objId}`,
                    checklist: `overview_moving_around_checklist_${objId}`,
                    description: `overview_moving_around_description_${objId}`,
                    tiplist: `overview_moving_around_tiplist_${objId}`,
                    vendorlist: `overview_moving_around_vendorlist_${objId}`,
                    gallery: `overview_moving_around_gallery_${objId}`
                };
                if (moving_around.vendors?.length > 0) {
                    moving_around.vendors = setArrayObjId(moving_around.vendors, `vendor_`);
                }

                if (moving_around.tips_html?.length > 0) {
                    moving_around.tips_html = setArrayObjId(moving_around.tips_html, `tip_`);
                }
            }
        });
    }

    if (experience.local_currency.length > 0) {
        experience.local_currency.forEach((local_currency) => {
            if (!local_currency.objId || forceId) {
                const objId = nanoid();
                local_currency.objId = {
                    section: `overview_currency_section_${objId}`,
                    xchange: `overview_currency_xchange_${objId}`,
                    checklist: `overview_currency_checklist_${objId}`,
                    description: `overview_currency_description_${objId}`,
                    tiplist: `overview_currency_tiplist_${objId}`,
                    vendorlist: `overview_currency_vendorlist_${objId}`,
                    gallery: `overview_currency_gallery_${objId}`
                };

                if (local_currency.tips_html?.length > 0) {
                    local_currency.tips_html = setArrayObjId(local_currency.tips_html, `tip_`);
                }
            }
        });
    }

    if (experience.itinerary.trip_days.length > 0) {
        experience.itinerary.trip_days.forEach((day, index) => {
            if (!day.objId || forceId) {
                day.objId = `day_${nanoid()}`;
            }
            if (!day.day_intro.objId || forceId) {
                day.day_intro.objId = {
                    section: `day_intro_section_${nanoid()}`,
                    image: `day_intro_image_${nanoid()}`,
                    title: `day_intro_title_${nanoid()}`,
                    description: `day_intro_description_${nanoid()}`
                };
            }

            if (!day.what_you_need.objId || forceId) {
                day.what_you_need.objId = {
                    section: `day_what_you_need_section_${nanoid()}`,
                    description: `day_what_you_need_description_${nanoid()}`,
                    checklist: `day_what_you_need_checklist_${nanoid()}`,
                    tiplist: `day_what_you_need_tiplist_${nanoid()}`,
                    vendorlist: `day_what_you_need_vendorlist_${nanoid()}`
                };
            }
            if (day.activities.length > 0) {
                day.activities.forEach((activity) => {
                    if (!activity.objId || forceId) {
                        const objId = nanoid();
                        activity.objId = {
                            activity: `activity_${objId}`,
                            title: `activity_title_${objId}`,
                            description: `activity_description_${objId}`,
                            location: `activity_location_${objId}`,
                            details: `activity_details_${objId}`,
                            gallery: `activity_gallery_${objId}`,
                            best_time_to_go: `activity_best_time_to_go_${objId}`,
                            duration: `activity_duration_${objId}`,
                            pricing: `activity_pricing_${objId}`,
                            reservation: `activity_reservation_${objId}`,
                            tiplist: `activity_tiplist_${objId}`,
                            vendorlist: `activity_vendorlist_${objId}`
                        };
                        if (activity.vendors?.length > 0) {
                            activity.vendors = setArrayObjId(activity.vendors, `vendor_`);
                        }

                        if (activity.tips_html?.length > 0) {
                            activity.tips_html = setArrayObjId(activity.tips_html, `tip_`);
                        }

                        if (activity.images?.length > 0) {
                            activity.images = setArrayObjId(activity.images, `image_`);
                        }
                    }
                });
            }
        });
    }

    return experience;
};

const setArrayObjId = (tips, prefix) => {
    return tips.map((tip, index) => {
        if (tip.objId) return tip;
        else return { ...tip, objId: `${prefix}${nanoid()}` };
    });
};

const getPublishedPurchased = (action) => {
    const { type } = action;

    if (type === 'GET_PURCHASED_EXPERIENCE_FULFILLED') {
        const exps = action?.payload?.data?.data?.purchasedByUser[0]?.experience;
        return exps ? [exps] : [];
    }

    return action?.payload?.data?.data?.publisheds;
};

export const setExperienceSinglePublished = (draft, action) => {
    const publisheds = getPublishedPurchased(action);
    const cats = action?.payload?.data?.data?.categories || [];
    const catsObj = cats.reduce((prev, next) => {
        return {...prev, [next.id]: next.id }
    }, {});

    if (!publisheds.length) {
        draft.invalid = true;
        draft.loading = false;
        return draft;
    }
    const {
        id: pubId,
        experience_id: id,
        user,
        updatedAt,
        content: experience,
        type,
        isPublished,
        publicAccess,
        budget_min,
        budget_max,
        budget_currency,
        content_marketing,
        cats_list,
        tags_list,
        places_lists,
        guided_extra = {
            included: [],
            excluded: []
        },
        days
    } = publisheds[0];

   

    if (!experience.budget) {
        experience.budget = {
            description_html: '',
            tips_html: []
        };
    }

    if (experience.general_tips_visible == null) {
        experience.general_tips_visible = {
            isVisible: type.toLowerCase() === 'digital' ? true : false
        };
    }

    if (experience.moving_around_visible == null) {
        experience.moving_around_visible = {
            isVisible: type.toLowerCase() === 'digital' ? true : false
        };
    }

    if (experience.local_currency_visible == null) {
        experience.local_currency_visible = {
            isVisible: type.toLowerCase() === 'digital' ? true : false
        };
    }
    if (experience.itinerary.trip_days.length > 0) {
        experience.itinerary.trip_days.forEach((day) => {
            if (Object.keys(day.what_you_need).length === 0) {
                day.what_you_need = {
                    description_html: '',
                    checklist: [],
                    isVisible: type.toLowerCase() === 'digital' ? true : false
                };
            }
            if (day.activities.length > 0) {
                day.activities.forEach((activity) => {
                    if (!('isVisible' in activity.best_time_to_go)) {
                        activity.best_time_to_go.isVisible = type.toLowerCase() === 'digital' ? true : false;
                    }
                    if (!('isVisible' in activity.duration)) {
                        activity.duration.isVisible = true;
                    }
                    if (!('isVisible' in activity.pricing)) {
                        activity.pricing.isVisible = type.toLowerCase() === 'digital' ? true : false;
                    }
                    if (typeof activity.reservation === 'string' && activity.reservation !== null) {
                        activity.reservation = {
                            type: activity.reservation,
                            isVisible: type.toLowerCase() === 'digital' ? true : false
                        };
                    }
                });
            }
        });
    }

    if (!content_marketing?.policies || forceId) {
        content_marketing.policies = {
            objId: {
                section: 'marketing_policy_section',
                tiplist: 'marketing_policy_tiplist'
            },
            refund: {
                description: '',
                objId: 'marketing_policy_refund'
            },
            cancellation: {
                description: '',
                objId: 'marketing_policy_cancellation'
            }
        };
    }

    if (!content_marketing?.thankYou) {
        content_marketing.thankYou = defaultMarketing().thankYou;
    }

    if (!content_marketing?.thankYou?.objId || forceId) {
        content_marketing.thankYou.objId = {
            section: 'marketing_thankYou_section',
            title: 'marketing_thankYou_title',
            subtitle: 'marketing_thankYou_subtitle',
            description: 'marketing_thankYou_description',
            gallery: 'marketing_thankYou_gallery',
            tiplist: 'marketing_thankYou_tiplist'
        };
    }

    const tmpExp = {
        pubId,
        id,
        user,
        updatedAt,
        type,
        experience,
        dayIndex: 0,
        loading: false,
        saving: false,
        guided_extra,
        budget_min,
        budget_max,
        budget_currency,
        days,
        isPublished,
        publicAccess,
        experience_status: { status: 'approved', feedback_object: {} },
        content_marketing: content_marketing || defaultMarketing(),
        cats_list: cats_list.reduce((prev, next) => {
            return [...prev, next.id];
        }, []).filter(cat => catsObj[cat]),
        tags_list: tags_list.reduce((prev, next) => {
            return [...prev, next.id];
        }, []),
        places_lists: places_lists.reduce((prev, next) => {
            return [...prev, next.id];
        }, [])
    };

    draft = tmpExp;

    return draft;
};
export const setExperienceSinglePending = (draft, action) => {
    draft.loading = true;

    return draft;
};

export const setUpdateExperiencePending = (draft, action) => {
    draft.saving = true;

    return draft;
};

export const setUpdateExperienceFufilled = (draft, action) => {
    draft.saving = false;
    draft.updated = false;

    return draft;
};

export const setUpdateTags = (draft, action) => {
    const { dataObj } = action;

    draft.tags_list = dataObj;

    return draft;
};

export const setUpdateCats = (draft, action) => {
    const { dataObj } = action;

    draft.cats_list = dataObj;

    return draft;
};

export const setUpdateGuideIncludes = (draft, action) => {
    const { dataObj } = action;

    draft.guided_extra = dataObj;

    return draft;
};

export const setUpdateBudget = (draft, action) => {
    const {
        dataObj: { min, max, currency }
    } = action;

    draft.budget_min = min;
    draft.budget_max = max;
    draft.budget_currency = currency;

    return draft;
};
