import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const LayoutHelpBar = () => {
    // const { topBar } = useContext(AuthContext);

    return (
        <div
            // className={`w-full mt-36 md:mt-20 bg-green-200 text-green-700 text-sm flex items-center justify-center`}
            className={`w-full mt-36 md:mt-20 bg-gray-800 text-white text-sm flex items-center justify-center`}
        >
            <div className='flex flex-col md:flex-row items-center gap-x-4 w-full justify-center'>
                <div className='flex items-center justify-center gap-4 px-4 w-full md:w-auto h-12 bg-transparent'>
                    <span className='hidden md:block font-bold'>Learn how to build your first experience with Konnect</span>
                    <span className='md:hidden font-bold'>Start with</span>

                    <Link
                        to={'/basics'}
                        className='shadow-xl-green focus:outline-none text-xs uppercase tracking-wide font-medium flex items-center justify-center px-4 h-8 rounded-lg whitespace-nowrap bg-yellow-300 text-yellow-900 hover:bg-white hover:text-gray-900'
                    >
                        Experience Basics
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default LayoutHelpBar;
