import moment from 'moment';
import { isSameDay, isAfter, isBefore } from 'date-fns';

export const createUpdatePost = (update) => {
    const updatePost = update.reduce((prev, next) => {
        const { skuId: id, price, name: groupname, capacity, pubCapacity, day } =  next;

        const name = moment(day).format('MMDDYYYY')
        return  {
            ...prev,
            [name]: {
                id,
                name, 
                price,
                groupname,
                stockChange: capacity - pubCapacity
            }
        };
    }, {});

    return { update: updatePost };
}

export const createAddPost = (add) => {
    const addPost = add.map((obj) => {
        return  {
            name: moment(obj.day).format('MMDDYYYY'),
            price: obj.price,
            date: obj.day,
            groupname: obj.name
        };
    });
    const addStock = add.reduce((prev, next) => {
        const { day, capacity } = next;

        return { ...prev, [moment(day).format('MMDDYYYY')]: capacity }
    }, {})
    const postObj = {
        add: {
            post: addPost,
            stock: addStock
        }
    }

    return postObj;
}

export const isAtLeastOneDayInThefuture = (dates, theDate) => {
    const currDate = new Date(theDate);
    let dayInfuture = false;
    
    dates.forEach((obj) => {
        const { day } = obj;
        const dayToTest = new Date(day)

        if(isSameDay(dayToTest, currDate) || isAfter(dayToTest, currDate)) {
            dayInfuture = true;
        };
    })

    return dayInfuture;
}
export const convertVariantNameToData = (name) => {
    return name.split('').reduce((prev, next, index) => {
        switch (index) {
            case 2:
            case 4:
                return `${prev}-${next}`;
            default:
                return `${prev}${next}`;
        }

    }, '');
}

export const parseOptions = (optionsData = []) => {
    return optionsData.reduce((prev, next) => {
        return { ...prev, [next.name]: next}
    }, {})
}
export const creatExpPriceData = (variants = [], stockData =[], optionsData = []) => {
    const variantsData = variants.filter(variant => !variant.archived)

    return variantsData.map((variant) => {
        const { name, id, stock_level, group_name: label, price } = variant;
     //   const { name, id, stock_level } = variant;
       // const { date:bDate, groupname: label, price } = optionsData[name];
        const stockSold = stockData.filter((stData) => {
            const { reason, variant_id } = stData;

            return reason === 'sold' && variant_id === id;
        }).reduce((prev, next) => { return prev + Math.abs(next.quantity)}, 0);
        const stockCanceled = stockData.filter((stData) => {
            const { reason, variant_id } = stData;

            return reason === 'canceled' && variant_id === id;
        }).reduce((prev, next) => { return prev + Math.abs(next.quantity)}, 0);
        return {
            day: convertVariantNameToData(name),
            name: label,
            price,
            capacity: (stock_level + stockSold) - stockCanceled
        }
    })
}
export const createLiveDataObj = (variants = [], stockData =[], optionsData = []) => {
    const tmpObj = {};
    const variantsData = variants.filter(variant => !variant.archived)

    variantsData.forEach((variant) => {
        const { name, id, stock_level, group_name: label, price } = variant;
        const stockSold = stockData.filter((stData) => {
            const { reason, variant_id } = stData;

            return reason === 'sold' && variant_id === id;
        }).reduce((prev, next) => { return prev + Math.abs(next.quantity)}, 0);
        const stockCanceled = stockData.filter((stData) => {
            const { reason, variant_id } = stData;

            return reason === 'canceled' && variant_id === id;
        }).reduce((prev, next) => { return prev + Math.abs(next.quantity)}, 0);
        const bDate = convertVariantNameToData(name);

        tmpObj[bDate] = {
            id,
            booking_date: bDate,
            label,
            price,
            capacity: (stock_level + stockSold) - stockCanceled,
            inventory: stock_level
        };
    });

    return tmpObj;
}

export const defaultDataObj = [
    {
        name: '',
        days: [], // 'Tue Jul 13 2021 00:00:00 GMT+0100 (British Summer Time)'
        price: 200,
        capacity: 5,
        individualDayData: {}
    }
];

export const convertToModalCalType = (data, currentLivePrices, serverValidationError, currentDate) => {
    const tmpDayData = {};
    let currentLiveDates = Object.keys(currentLivePrices.current); // used to check if a day has been deleted but a booking has been made
    serverValidationError.current = false;
    data.forEach((obj) => {
        const { name } = obj;
        if(tmpDayData[name]) {
            tmpDayData[name].push(obj);
        } else {
            tmpDayData[name] = [];
            tmpDayData[name].push(obj);
        }
    })

    return Object.keys(tmpDayData).map((key, index) => {
        const groupData = {
                name: '',
                days: [], // 'Tue Jul 13 2021 00:00:00 GMT+0100 (British Summer Time)'
                price: 0,
                capacity: 0,
                individualDayData: {} // date string as key for price
        };

        groupData.name = key;
        groupData.price = Number(tmpDayData[key][tmpDayData[key].length - 1].price);
        groupData.capacity = Number(tmpDayData[key][tmpDayData[key].length - 1].capacity);
        groupData.days = tmpDayData[key].map( obj => obj.day )
        tmpDayData[key].forEach((obj) => {
            const{ day, price, capacity } = obj;
            const publishedDataForDayIfExists = { 
                pubPrice: null,
                pubCapacity: null,
                pubInventory: null,
                minCapacity: 1,
                minPrice: 1,
                allowDelete: true,
                isDisabled:  isBefore(new Date(day), new Date(currentDate)),
                booked: 0,
            };

            const errorObj = {
                price: { error: false, msg: ''},
                capacity: { error: false, msg: ''},
            }
            if(currentLivePrices.current[day]) {
                const { price: pubPrice, capacity: pubCapacity, inventory: pubInventory } =  currentLivePrices.current[day];
                publishedDataForDayIfExists.pubPrice = pubPrice;
                publishedDataForDayIfExists.pubCapacity = pubCapacity;
                publishedDataForDayIfExists.pubInventory = pubInventory;
                publishedDataForDayIfExists.minPrice = 1;//pubCapacity - pubInventory > 0 ? pubPrice : 1;
                publishedDataForDayIfExists.minCapacity = pubCapacity - pubInventory > 0 ? pubCapacity - pubInventory : 1;
                publishedDataForDayIfExists.allowDelete = !(pubCapacity - pubInventory);
                publishedDataForDayIfExists.booked = pubCapacity - pubInventory;
                if(pubCapacity - pubInventory > 0) {
                    if(capacity  < publishedDataForDayIfExists.minCapacity) {
                        errorObj.capacity.error = true;
                        errorObj.capacity.msg = 'Capacity error';
                        serverValidationError.current = true;
                    }
                }
                
                currentLiveDates = currentLiveDates.filter( d => d !== day );
            }

            groupData.individualDayData[day] = { 
                price: Number(price), 
                capacity: Number(capacity),
                errorObj,
                ...publishedDataForDayIfExists
            }
        });

        // check deleted days have no bookings
        if(index ===  Object.keys(tmpDayData).length - 1) {
            currentLiveDates.forEach((dateKey) => {
                const { price: pubPrice, capacity: pubCapacity, inventory: pubInventory } = currentLivePrices.current[dateKey];

                if((pubCapacity - pubInventory) > 0) {
                    serverValidationError.current = true; // set error
                    if(groupData.individualDayData[dateKey]) { // does the booked date exist in the group
                            groupData.individualDayData[dateKey] = { 
                            price: Number(pubPrice), 
                            capacity: Number(pubCapacity),
                            pubPrice,
                            pubCapacity,
                            pubInventory,
                            booked: pubCapacity - pubInventory,
                            minCapacity: pubCapacity - pubInventory > 0 ? pubCapacity - pubInventory : 1,
                            minPrice: pubCapacity - pubInventory > 0 ? pubPrice : 1,
                            allowDelete: false,
                            errorObj: {
                                price: { error: false, msg: ''},
                                capacity: { error: false, msg: ''},
                            },
                            isDisabled:  isBefore(new Date(groupData.individualDayData[dateKey]), new Date(currentDate))
                        }
                    }
                }
            });
        }

        return groupData
    })
}
export const createSkuPostTypes = (allData, currentLivePrices) => {
    // Check if new sku, or updated sku or removed sku
    // create seperate objects that can be used to make the graphql
    const dataToPost = {
        add: [],
        update: [],
        remove: []
    };
    const currentDays = Object.keys(currentLivePrices.current);
    const postDays = allData.map( d => d.day);

    currentDays.forEach((currDayData) => {
        if(!postDays.includes(currDayData)) { // Check if date has been deleted
            const { id } = currentLivePrices.current[currDayData];

            dataToPost.remove.push({ id , name: moment(currDayData).format('MMDDYYYY') });
        };
    });

    allData.forEach((dayData) => {
        const { day, name, price, capacity } = dayData;

        if(currentDays.includes(day)) { // check if is an update
            const currentDayData = currentLivePrices.current[day];

            if(currentDayData.label !== name || currentDayData.price !== price || currentDayData.capacity !== capacity) {
                dataToPost.update.push({ ...dayData, skuId: currentLivePrices.current[day].id });
            }
        } else { // add add day
            dataToPost.add.push(dayData);
        }
    });

    return dataToPost;
}

export const validateForm = (dataObj) => {
    let isError = !dataObj.length;
    dataObj.forEach((obj) => {
        const { individualDayData, days } = obj;
        if(!days.length) {
            isError = true;
        }
        Object.keys(individualDayData).forEach((dateKey) => {
            const { 
                errorObj: { 
                    price: { error: priceError },
                    capacity: { error: capacityError }
                }
            } = individualDayData[dateKey];
            
            if(priceError || capacityError) {
                isError = true;
            }
        })

    })

    return !isError;
};

export const convertToDbVersionObj = (data) => {
    let dbObj = [];

    data.forEach((obj) => {
        const { name, individualDayData } = obj;
        dbObj = [...dbObj, ...Object.keys(individualDayData).map((day) => {
            const { price, capacity, pubInventory, minPrice, pubCapacity } = individualDayData[day];

            return {
                day,
                name,
                price,
                capacity,
                pubInventory,
                minPrice,
                pubCapacity
            }
        })]
    });

    return dbObj;
}
export const convertStringsToDates = (data, type = 'strings') => {
    const newObj = JSON.parse(JSON.stringify(data)).map((item, i) => {
        item.days = item.days.map((obj) => {
            return type === 'strings' ? obj : new Date(obj);
        });

        return item;
    });

    return newObj;
};
export const createUpdateDigitalSku = (skuPostObj, pubId) => {
    const { price, skuId } = skuPostObj;
    let mutation = `mutation {`
    let muteData = ``;
    const sData = `{
        price: ${price},
        type: DIGITAL,
        product: "${pubId}",
    }
    `;

    if(!skuId) { // is Update
        muteData = `${muteData}
            createSku(input: { data: ${sData} }) {
                sku {
                    id,
                    price
                }
            }`;
    } else {
        muteData = `updateSku(input: { 
            where: {
                id: "${skuId}"
            },
            data: ${sData} }) {
                sku {
                    id,
                    price
                }
            }
            `
    }

    mutation = `${mutation}
        ${muteData}
    }`;

    return mutation;
}