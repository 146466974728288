import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import ModalContainer, {
    ModalBody,
    ModalFooter,
    ModalCTA,
    ModalButton,
    ModalHelp
} from 'components/blocks/ModalTre';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import ListBoxGeneric from 'components/blocks/ListBoxGeneric';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import Editor from 'components/blocks/Editor';
import { nanoid } from 'nanoid';

// should be in all modals
const targetElement = document.querySelector('#root');

function TipModal({
    txtOnly = false,
    canBeEmpty = false,
    close,
    setModalIsOpen,
    handleActionBtn,
    updateObj = null,
    update = false,
    globalState: { lang },
    icon = uiStruct.ui.modals.editor.icon,
    hintObj = null,
    actionBtnObj = uiStruct.ui.modals.editor.buttons.action,
    cancelBtnObj = uiStruct.ui.modals.editor.buttons.cancel
}) {
    const rtl = !!translations[lang].rtl;
    const [editorValidationError, setEditorValidationError] = useState(false);
    const [dataObj, updateDataObj] = useState({ ...updateObj }); // set to a default or editing
    const tipTypes = uiStruct.ui.note_list.map((key) => {
        return {
            id: key,
            name: translations[lang][key],
            unavailable: false
        };
    });
    const [selectedType, setSelectedType] = useState(() => {
        return tipTypes[
            tipTypes.findIndex((obj) => {
                return obj.id === updateObj.type;
            })
        ];
    });

    // Copy Below to all modals
    //////////////////////////////////////////////////////////////////////

    // State for triggering animation of modal in and out
    const [transitionState, setTransitionState] = useState('off');
    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    ///////////////////////////////////////////////////////////////////

    // Paul stuff
    const updateDescriptionHtml = (html) => {
        updateDataObj({
            ...dataObj,
            tip: txtOnly ? html.target.value : html
        });
        setEditorValidationError(false);
    };

    // Paul stuff Handle Action BTN function
    const handleActionClick = () => {
        if (
            dataObj.tip.replace(/(<([^>]+)>)/gi, '').length === 0 &&
            !canBeEmpty
        ) {
            setEditorValidationError(true);
        } else {
            handleActionBtn({
                ...dataObj,
                objId: dataObj.objId ?? `tip_${nanoid()}`,
                type: selectedType.id
            });
            handleTransitionStateOff(); // important... don't forget to add it in all modals
        }
    };

    ////////////////////////////////
    return (
        <>
            <ModalContainer
                width='3xl'
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={icon}
                close={close}
                rtl={rtl}
            >
                <ModalBody rtlOff>
                    <ListBoxGeneric
                        listData={tipTypes}
                        val={selectedType}
                        handleChange={setSelectedType}
                        className='mb-4'
                    />
                    {txtOnly ? (
                        <input
                            type='text'
                            style={{
                                border: editorValidationError
                                    ? '1px solid red'
                                    : ''
                            }}
                            onChange={updateDescriptionHtml}
                            value={dataObj.tip}
                        />
                    ) : (
                        <Editor
                            valError={editorValidationError}
                            handleChange={updateDescriptionHtml}
                            html={dataObj.tip}
                        />
                    )}
                </ModalBody>

                <ModalFooter>
                    {hintObj && (
                        <ModalHelp
                            helpText={translations[lang][hintObj]}
                            isHtml
                        />
                    )}
                    <ModalCTA>
                        <ModalButton
                            handleClick={handleTransitionStateOff}
                            label={translations[lang][cancelBtnObj.label]}
                            color={cancelBtnObj.color}
                            icon={cancelBtnObj.icon}
                        />
                        <ModalButton
                            handleClick={handleActionClick}
                            label={translations[lang][actionBtnObj.label]}
                            color={actionBtnObj.color}
                            icon={actionBtnObj.icon}
                        />
                    </ModalCTA>
                </ModalFooter>
            </ModalContainer>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(TipModal);
