import React, { useContext } from 'react';
import { ShepherdTourContext } from 'react-shepherd';
import CollapseButton from 'components/blocks/CollapseButton';

const LayoutMenuWander = ({ author, rtl }) => {
    const experienceTour = useContext(ShepherdTourContext);

    const handleExperienceTour = () => {
        experienceTour.start();
    };

    return (
        <div className=''>
            <div
                className={`z-50 tour-wander-bar fixed top-24  ${
                    rtl ? 'left-0' : 'right-0'
                }`}
            >
                <div className='flex-1 flex flex-col p-4 justify-end text-2xl gap-2'>
                    {author.role ===
                        process.env.REACT_APP_STRAPI_STAFF_ROLE_ID && (
                        <div className='flex justify-end'>
                            <CollapseButton
                                labelHover='Inspirational Sample'
                                icon='las la-chalkboard-teacher text-2xl'
                                size='12'
                                textSize='text-xs font-bold'
                                sizeHover='w-52'
                                offsetCenter='2'
                                padding='px-3'
                                btnColor={`bg-purple-200 text-purple-800 hover:ring-purple-400`}
                                hoverText=''
                            />
                        </div>
                    )}

                    <div className='flex justify-end'>
                        <CollapseButton
                            labelHover='Experience Tour'
                            handleClick={handleExperienceTour}
                            icon='ri-slideshow-2-line text-2xl'
                            size='12'
                            textSize='text-xs'
                            sizeHover='w-44'
                            offsetCenter='2'
                            padding='px-3'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LayoutMenuWander;
