/* eslint-disable react/jsx-pascal-case */
/* eslint-disable arrow-body-style */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as types from 'store/actions/types';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic';
import React, { useState } from 'react';

import SectionMovingAroundSingle from 'components/sections/SectionMovingAroundSingle';
import MovingAroundModal from 'components/modals/movingAround/MovingAroundModal';
import ConfirmModal from 'components/modals/ConfirmModal';
import { Section__Title, Section__ListContainer } from 'components/blocks/Sections';
import { ButtonsAddMain, ButtonsHelp } from 'components/blocks/Buttons';
import Icons from 'components/blocks/Icons';
import { MOVING_AROUND } from 'constants/experienceDetailConsts';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { _MOVING_AROUND_OVERVIEW } from 'store/actions/types';
import useDraggableInPortal from 'helpers/useDraggableInPortal';
import OnboardingSection from 'components/specialty/OnboardingSection';
import { MenuRefsHolder } from 'helpers/MenuRefsHolder';

function SectionMovingAround(props) {
    const {
        experienceDetailsAction,
        actionType,
        dayIndex = null,
        experienceDetails: { experience },

        globalState: { lang, edit, isFeedbackMode }
    } = props;
    const renderDraggable = useDraggableInPortal();
    const rtl = !!translations[lang].rtl;
    let movingAroundList = null;
    let hintObj = null;
    let hintObjEmpty = null;
    let hintObjModal = null;

    switch (actionType) {
        case _MOVING_AROUND_OVERVIEW:
            movingAroundList = experience.moving_around;
            hintObj = uiStruct.overview.moving_around.uiPreview.hint;
            hintObjEmpty = uiStruct.overview.moving_around.uiPreview.hintEmpty;
            hintObjModal = uiStruct.overview.moving_around.uiEdit.modals.type.hint;

            break;
        default:
            movingAroundList = experience.itinerary.trip_days[dayIndex].moving_around;
            hintObj = uiStruct.itinerary.moving_around.uiPreview.hint;
            hintObjEmpty = uiStruct.itinerary.moving_around.uiPreview.hintEmpty;
            hintObjModal = uiStruct.itinerary.moving_around.uiEdit.modals.type.hint;
            break;
    }

    const {
        moving_around_visible: { isVisible: isSectionVisible = true }
    } = experience;

    const [editModalState, setEditModalState] = useState(false);
    const [updateObj, setUpdateObj] = useState();
    const [updateStatus, setUpdateStatus] = useState(false);
    const [mrObjIndex, setMrObjIndex] = useState(0);
    const hasData = movingAroundList.length;
    const show = edit ? true : !edit && !hasData ? false : true;
    const [delConfirmModalState, setDelConfirmModalState] = useState(false);

    const updateCreate = (dataObj) => {
        experienceDetailsAction({
            type: `ADD${actionType}`,
            dataObj,
            dayIndex
        });
    };

    const removeModal = (index) => {
        setMrObjIndex(index);
        setDelConfirmModalState(true);
    };

    const confirmDelete = () => {
        experienceDetailsAction({
            type: `DELETE${actionType}`,
            index: mrObjIndex,
            dayIndex
        });
    };

    const buildSingleEdit = () => {
        return movingAroundList.map((mAObj, index, arr) => {
            const key = `mr${index}`;

            return (
                <SectionMovingAroundSingle
                    key={key}
                    actionType={actionType}
                    dayIndex={dayIndex}
                    index={index}
                    data={arr[index]}
                    removeModal={removeModal}
                    isVisible={isSectionVisible}
                    handleVisible={handleVisible}
                />
            );
        });
    };

    const buildPanel = () => {
        return (
            <div>
                <ul>{buildSingleEdit()}</ul>
                {edit && !isFeedbackMode && (
                    <div className='flex items-center justify-center w-full'>
                        <ButtonsAddMain
                            handleClick={() => {
                                setEditModalState(true);
                                setUpdateStatus(false);
                            }}
                            label={translations[lang][uiStruct.itinerary.moving_around.uiEdit.buttons.addMain.label]}
                            rtl={rtl}
                        />
                    </div>
                )}
                {editModalState && (
                    <MovingAroundModal
                        defaultObj={MOVING_AROUND}
                        setModalIsOpen={setEditModalState}
                        handleActionBtn={updateCreate}
                        update={updateStatus}
                        updateObj={updateObj}
                        hintObj={hintObjModal}
                    />
                )}

                {delConfirmModalState && <ConfirmModal setModalIsOpen={setDelConfirmModalState} handleActionBtn={confirmDelete} />}
            </div>
        );
    };

    const handleVisible = () => {
        experienceDetailsAction({
            type: types.UPDATE_SECTION_VISIBILITY,
            val: !isSectionVisible,
            objName: 'moving_around_visible'
        });
    };
    return (
        (edit || isSectionVisible) && (
            <div className={`w-full ${edit ? 'touch:-mt-8' : 'touch:-mt-28'} touch:py-10`}>
                <Section__Title
                    title={translations[lang][uiStruct.itinerary.moving_around.uiPreview.headTitle]}
                    hasData={true}
                    hintObj={hintObj}
                    handleVisible={handleVisible}
                    isVisible={isSectionVisible}
                />
                {isSectionVisible && <OnboardingSection visible={hasData} data={hintObjEmpty} />}
                {isSectionVisible && buildPanel()}
            </div>
        )
    );
}

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            experienceDetailsAction
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionMovingAround);
