import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CardXl from 'components/blocks/CardXl';
import translations from 'constants/translations';
import GenericTitle from 'components/blocks/GenericTitle';
import uiStruct from 'constants/uiStruct';
import GenericDescription from 'components/blocks/GenericDescription';
import ExpSubsection from 'components/blocks/ExpSubsection';
import KreatorBadgeStatic from 'components/blocks/KreatorBadgeStatic';
import BestTimeToGoRanges from 'components/blocks/marketing/BestTimeToGoRanges';
import { capitalize, getDays, kreatorName } from 'helpers/FEutils';

import MarketingBudget from 'components/blocks/marketing/MarketingBudget';
import RawCard from 'components/blocks/marketing/RawCard';
import classNames from 'classnames';
import SocialMediaKreator from 'components/blocks/SocialMediaKreator';
import BioBlock from 'components/blocks/BioBlock';
import { marketPath } from 'constants/globalConsts';

const socialIcons = {
    instagram: {
        type: 'icon',
        value: 'ri-instagram-line text-xl'
    },
    facebook: {
        type: 'icon',
        value: 'ri-facebook-fill text-xl'
    },

    youtube: {
        type: 'icon',
        value: 'ri-youtube-line text-2xl'
    },

    twitter: {
        type: 'icon',
        value: 'ri-twitter-fill text-xl'
    },

    website: {
        type: 'text',
        value: 'www'
    },
    tiktok: {
        type: 'text',
        value: 'Tiktok'
    }
};

function SectionMarketingIntro({
    actionType,
    marketingData: {
        intro: { title, subtitle, desc, objId }
    },
    experienceDetails: {
        experience,
        id,
        days,
        budget_min,
        budget_max,
        type,
        cats_list: tmpCats,
        places_lists: tmpPlaces,
        user,
        user: { profile }
    },
    auth: {
        user: {
            profile: { currency: preferredCurrency }
        }
    },
    globalState: { lang, edit, siteData }
}) {
    const {
        budget: { isVisible: budgetVisible },
        best_time_to_go: { isVisible: bestTimeToGoVisible },
        overview_intro: { description_html: overview_desc }
    } = experience;

    const rtl = !!translations[lang].rtl;
    return (
        <>
            <ExpSubsection borders='' className='pb-6'>
                {/* <div className='flex justify-between w-full items-center mb-4'>
                    <div className='flex-1'>
                        <div className='text-green-400 flex flex-col lg:flex-row gap-2 font-bold lg:items-center text-2xl tracking-tight leading-none flex-shrink-0 flex-initial mb-2  lg:mr-8 '>
                            <div className='mr-3 whitespace-nowrap'>{`A ${capitalize(
                                type
                            )} Experience`}</div>
                            <div className='flex items-center'>
                                <span className='mr-3 text-sm text-green-700'>
                                    by
                                </span>

                                <div className=' underline text-base lg:text-lg text-green-700'>
                                    {`${kreatorName(profile)}`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <RawCard padding='p-8 md:p-8' margins='mt-4'>
                    <div className='flex flex-col md:flex-row gap-8 w-full'>
                        <div className='mx-auto md:mx-0'>
                            <KreatorBadgeStatic author={user} card={false} avatarOnly={true} size='w-36 h-36' />
                        </div>
                        <div>
                            <div className='flex justify-between w-full items-center mb-4'>
                                <div className='flex-1'>
                                    <div className='text-green-400 flex flex-col lg:flex-row gap-2 font-bold lg:items-center text-2xl tracking-tight leading-none flex-shrink-0 flex-initial mb-2  lg:mr-8 '>
                                        <div className='mr-3 whitespace-nowrap'>{`A ${capitalize(type)} Experience`}</div>
                                        <div className='flex items-center'>
                                            <span className='mr-3 text-sm text-green-700'>by</span>

                                            <a
                                                href={`${marketPath}/experiences/user/${user.username}/all`}
                                                target='_blank'
                                                className='underline text-base lg:text-lg text-green-700'
                                            >{`${kreatorName(profile)}`}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div
                                className={`block-html leading-6 ${desc ? 'text-gray-800' : 'italic'} text-xs md:text-sm`}
                                dangerouslySetInnerHTML={{
                                    __html: profile.bio
                                }}
                            /> */}
                            <BioBlock bio={profile.bio} className={`block-html leading-6 ${desc ? 'text-gray-800' : 'italic'} text-xs md:text-sm`} />

                            <div className='flex items-center gap-2 text-xl'>
                                <SocialMediaKreator social={profile?.social} />
                            </div>
                        </div>
                    </div>
                </RawCard>
            </ExpSubsection>

            <ExpSubsection className='border-ba border-green-600 border-opacity-20 pb-4 mt-8'>
                <div className='marketing-title w-full'>Introduction</div>
                {/* <GenericTitle
                    nohover
                    canBeEmpty={false}
                    actionType={actionType}
                    titleTxt={title}
                    defaultTxt={'Title'}
                    hintObj={''}
                    placeholder={'Place holder txt'}
                    icon={uiStruct.itinerary.intro.uiEdit.modals.title.icon}
                    tour='tour-ov-intro-title'
                />
                <GenericTitle
                    nohover
                    canBeEmpty={false}
                    actionPrefix={`UPDATE_SUBTITLE`}
                    actionType={actionType}
                    titleTxt={subtitle}
                    defaultTxt={'Subtitle'}
                    hintObj={''}
                    placeholder={'Place holder txt'}
                    icon={uiStruct.itinerary.intro.uiEdit.modals.title.icon}
                    tour='tour-ov-intro-title'
                /> */}
                <div className='pt-4'>
                    <GenericDescription
                        actionType={actionType}
                        descriptionHtml={desc || overview_desc}
                        objId={objId?.description}
                        title='Intro'
                        subtitle='Description'
                    >
                        {desc ? (
                            <div
                                className={`block-html leading-7 text-gray-800 text-sm md:text-base`}
                                dangerouslySetInnerHTML={{
                                    __html: desc
                                }}
                            />
                        ) : overview_desc ? (
                            <div
                                className={`block-html leading-7 text-gray-800 text-sm md:text-base`}
                                dangerouslySetInnerHTML={{
                                    __html: overview_desc
                                }}
                            />
                        ) : (
                            <div className={` leading-7 text-gray-400 italic text-sm md:text-base`}>
                                Use this space to provide a general description of your experience. If you have already completed the general description of the
                                experience section in the ‘Overview’ section, the text you provided will automatically be copied below as your intro but you can
                                still click on it to edit it if you want to provide more or less detail.
                            </div>
                        )}
                    </GenericDescription>
                </div>
                {budgetVisible && type.toLowerCase() === 'digital' && <MarketingBudget />}
                {bestTimeToGoVisible && type.toLowerCase() === 'digital' && <BestTimeToGoRanges timeRange={experience.best_time_to_go.time_range} />}
            </ExpSubsection>
        </>
    );
}

const mapStateToProps = (state) => ({
    marketingData: state.experienceDetails.content_marketing,
    globalState: state.globalState,
    auth: state.auth,
    experienceDetails: state.experienceDetails
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionMarketingIntro);
