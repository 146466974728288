import { marketingGallerySize } from 'constants/experienceDetailConsts';

export const buildMenuData = (experience, experienceId, publicView = false, publishView = false, purchasedView) => {
    let sitePath = '/';
    if (publicView) sitePath = process.env.REACT_APP_BASENAME_PUBLIC;
    else if (publishView) sitePath = process.env.REACT_APP_BASENAME_EXPERIENCE_PUBLISHED;
    else if (purchasedView) sitePath = process.env.REACT_APP_BASENAME_PURCHASED;
    else sitePath = process.env.REACT_APP_BASENAME_EXPERIENCE;

    // console.log(sitePath);
    // const sitePath = publicView
    //     ? process.env.REACT_APP_BASENAME_PUBLIC
    //     : publishView
    //     ? process.env.REACT_APP_BASENAME_EXPERIENCE_PUBLISHED
    //     : purchasedView
    //     ? process.env.REACT_APP_BASENAME_PURCHASED
    //     : process.env.REACT_APP_BASENAME_EXPERIENCE;

    if (experience?.itinerary?.trip_days?.length > 0) {
        // const daysList = experience?.itinerary?.trip_days?.map((day, index) => {
        //     return {
        //         id: `${
        //             process.env.REACT_APP_BASENAME_EXPERIENCE
        //         }/${experienceId}/${index + 1}`,
        //         name: `Day ${index + 1}: ${day.day_intro.title}`,
        //     };
        // });

        const daysList = experience?.itinerary?.trip_days?.map((day, index) => {
            return {
                id: `${sitePath}/${experienceId}/${index + 1}`,
                name: `Day ${index + 1}`
            };
        });
        daysList.unshift({
            id: `${sitePath}/${experienceId}/0`,
            name: 'Overview'
        });
        if (!publicView && !purchasedView)
            daysList.push({
                id: `${sitePath}/${experienceId}/marketing`,
                name: 'Marketing'
            });

        return daysList;
    }

    return [
        {
            id: `${sitePath}/${experienceId}/0`,
            name: 'Overview'
        },
        {
            id: `${sitePath}/${experienceId}/marketing`,
            name: 'Marketing'
        }
    ];
};

export const getCompletion = (data) => {
    if (Array.isArray(data)) {
        if (data.length > 0) return 100;
        else return 0;
    } else {
        // check what type of object it is
        const dKeys = Object.keys(data);
        if (dKeys.length > 0) {
            // check for each enum key if its empty. Return percentage completion
            let completion = 0;

            for (let i = 0; i < dKeys.length; i++) {
                if (data[dKeys[i]]) {
                    if (Object.keys(data[dKeys[i]]).length > 0) {
                        completion += 1;
                    } else if (data[dKeys[i]].length > 0) {
                        completion += 1;
                    } else if (!isNaN(data[dKeys[i]]) && data[dKeys[i]] > 0) {
                        completion += 1;
                    }
                }
            }
            return (completion / dKeys.length) * 100;
        } else return 0;
    }
};

export const getAllDaysCompletion = (allDays) => {
    if (allDays.length > 0) return Math.floor(allDays.reduce((total, day) => total + getDayCompletion(day).total, 0) / allDays.length);
    else return 0;
};

export const getDayCompletion = (dayData) => {
    ////////// Calculate Day completion

    // Define each section completion and totals

    let day_summary = 0;
    let day_whatYouNeed = 0;
    let day_activities = 0;
    let day_activitiesQty = dayData.activities?.length || 1;
    let dayTotalElements = 1 + day_activitiesQty;
    let dayCompletion = 0;

    // Summary
    day_summary = getCompletion({
        day_featured_image: dayData.day_intro.day_featured_image.data,
        title: dayData.day_intro.title,
        description_html: dayData.day_intro.description_html
    });

    // What you need
    if (dayData.what_you_need.isVisible === true || !('isVisible' in dayData.what_you_need)) {
        day_whatYouNeed = getCompletion({
            description_html: dayData.what_you_need.description_html,
            checklist: dayData.what_you_need.checklist
        });
        dayTotalElements += 1;
    }

    // Activities
    day_activities = getAllActivitiesCompletion(dayData.activities) * day_activitiesQty;

    dayCompletion = (day_summary + day_whatYouNeed + day_activities) / dayTotalElements;

    return {
        intro: day_summary,
        whatYouNeed: day_whatYouNeed,
        activities: day_activities,
        total: dayCompletion
    };
};

export const getOverviewCompletion = (experience, expDetails) => {
    ////////// Calculate Overview completion

    // Define each section completion and totals
    let ov_summary = 0;
    let ov_budget = 0;
    let ov_destination = 0;
    let ov_bestTimeToGo = 0;
    let ov_gettingThere = 0;
    let ov_accommodation = 0;
    let ov_movingAround = 0;
    let ov_currency = 0;
    let ov_generalTips = 0;
    let ov_itinerary = 0;
    let ov_thankYou = 0;
    let overviewTotalElements = 2;
    let overviewCompletion = 0;

    // Summary
    ov_summary = getCompletion({
        overview_featured_image: experience.overview_intro.overview_featured_image.data,
        title: experience?.overview_intro?.title || '',
        description_html: experience.overview_intro.description_html
    });

    // Budget
    if (expDetails.type !== 'GUIDED' && (experience.budget.isVisible === true || !('isVisible' in experience.budget))) {
        ov_budget = getCompletion({
            budget_min: expDetails.budget_min,
            budget_max: expDetails.budget_max
        });
        overviewTotalElements += 1;
    }

    // Destination

    ov_destination = getCompletion({
        description_html: experience.destination.description_html,
        locations: experience.destination.locations
    });

    // Best time to go
    if (experience.best_time_to_go.isVisible === true || !('isVisible' in experience.best_time_to_go)) {
        ov_bestTimeToGo = getCompletion({
            time_range_min: experience.best_time_to_go.time_range[0],
            time_range_min: experience.best_time_to_go.time_range[1]
        });
        overviewTotalElements += 1;
    }

    // Getting there
    if (experience.getting_there.isVisible === true || !('isVisible' in experience.getting_there)) {
        ov_gettingThere = getCompletion({
            description_html: experience.getting_there?.description_html
            // vendors: experience.getting_there?.vendors,
            // entry: experience.getting_there?.entry.url
        });
        overviewTotalElements += 1;
    }

    // Accommodation
    if (experience.accommodation.isVisible === true || !('isVisible' in experience.accommodation)) {
        ov_accommodation =
            getCompletion({
                description_html: experience.accommodation?.description_html
            }) /
                2 +
            !!getCompletion({
                locations: experience.accommodation?.locations
            }) *
                50;
        overviewTotalElements += 1;
    }

    // Moving Around

    // if (experience.moving_around_visible?.isVisible === true || !('isVisible' in experience.moving_around_visible)) {
    //     ov_movingAround = getAllMovingCompletion(experience.moving_around);
    //     overviewTotalElements += 1;
    // }

    // Currency
    if (experience.local_currency_visible?.isVisible === true || !('isVisible' in experience.local_currency_visible)) {
        ov_currency = getAllCurrencyCompletion(experience.local_currency);
        overviewTotalElements += 1;
    }

    // General tips
    if (experience.general_tips_visible?.isVisible === true || !('isVisible' in experience.general_tips_visible)) {
        ov_generalTips = experience.tips_html.length > 0 ? 100 : 0;
        overviewTotalElements += 1;
    }

    // Itininerary (not included in OV total calculation)
    ov_itinerary = getAllDaysCompletion(experience.itinerary.trip_days);

    // Thank you
    if (expDetails.type.toLowerCase() === 'digital') {
        ov_thankYou = getCompletion({
            description_html: expDetails.content_marketing?.thankYou?.desc
        });
        overviewTotalElements += 1;
    }

    overviewCompletion =
        (ov_summary +
            ov_budget +
            ov_destination +
            ov_bestTimeToGo +
            ov_gettingThere +
            ov_accommodation +
            ov_movingAround +
            ov_currency +
            ov_generalTips +
            ov_thankYou) /
        overviewTotalElements;

    return {
        intro: ov_summary,
        budget: ov_budget,
        destination: ov_destination,
        whenToGo: ov_bestTimeToGo,
        gettingThere: ov_gettingThere,
        accommodation: ov_accommodation,
        movingAround: ov_movingAround,
        currency: ov_currency,
        generalTips: ov_generalTips,
        itinerary: ov_itinerary,
        thankYou: ov_thankYou,
        total: overviewCompletion
    };
};

export const getMarketingCompletion = (expDetails) => {
    const { type, content_marketing: marketing, experience } = expDetails;

    ////////// Calculate Marketing completion

    // Define each section completion and totals
    let mk_gallery = 0;
    let mk_intro = 0;
    let mk_whatToDo = 0;
    let mk_whereToStay = 0;
    let mk_includes = 0;
    let mk_policies = 0;
    let mk_catsTags = 0;
    let mk_thankYou = 0;
    // let mk_pricing = 0;
    let marketingTotalElements = 4;
    let marketingCompletion = 0;

    // Gallery
    mk_gallery = marketing.gallery.length >= marketingGallerySize ? 100 : (100 / marketingGallerySize) * marketing.gallery.length;

    // Intro
    mk_intro = getCompletion({ description_html: marketing.intro.desc });

    // What to do
    mk_whatToDo = getCompletion({
        description_html: marketing.whatToDo.desc
    });

    // Where to Stay
    // if (
    //     type.toLowerCase() === 'digital' &&
    //     experience.accommodation.isVisible
    // ) {
    //     mk_whereToStay = getCompletion({
    //         description_html: marketing.whereToStay.desc
    //     });
    //     marketingTotalElements += 1;
    // }

    // Included excluded
    if (type.toLowerCase() === 'guided') {
        mk_includes = getCompletion({
            included: marketing.whatsIncluded.includes
        });
        marketingTotalElements += 1;
    }

    // Policies
    if (type.toLowerCase() === 'guided') {
        mk_policies = getCompletion({
            policies: marketing.policies.data
        });
        marketingTotalElements += 1;
    }

    // Thank you
    // if (type.toLowerCase() === 'digital' && false) {
    //     mk_thankYou = getCompletion({
    //         description_html: marketing.thankYou?.desc
    //     });
    //     marketingTotalElements += 1;
    // }

    // Tags and cats
    mk_catsTags = getCompletion({
        cats: expDetails.cats_list
        // tags: expDetails.tags_list
    });

    // Pricing
    // mk_pricing = getCompletion({});

    marketingCompletion =
        (mk_gallery + mk_intro + mk_whatToDo + mk_whereToStay + mk_includes + mk_policies + mk_catsTags + mk_thankYou) / marketingTotalElements;

    return {
        marketingGallery: mk_gallery,
        marketingIntro: mk_intro,
        marketingWhatToDo: mk_whatToDo,
        marketingWhereToStay: mk_whereToStay,
        marketingIncludes: mk_includes,
        marketingPolicies: mk_policies,
        marketingCats: mk_catsTags,
        marketingThankYou: mk_thankYou,
        marketingTotal: marketingCompletion
    };
};

export const getOverallCompletion = (experience, expDetails) => {
    if (!experience.itinerary) return;
    const overview = getOverviewCompletion(experience, expDetails);
    const marketing = getMarketingCompletion(expDetails);
    const totDays = Number(experience.itinerary.trip_days.length || 1);
    const total = (overview.total + overview.itinerary * totDays + marketing.marketingTotal) / (2 + totDays);

    // console.log('overview completion is:', overview.total);
    // console.log('day completion is:', overview.itinerary);
    // console.log('marketing completion is:', marketing.marketingTotal);
    // console.log('total days is:', totDays);
    // console.log('total completion is:', total);
    return {
        total: Math.floor(total).toFixed(0),
        overview: Math.floor(overview.total).toFixed(0),
        days: Math.floor(overview.itinerary).toFixed(0),
        marketing: Math.floor(marketing.marketingTotal).toFixed(0)
    };
    // const totCompletion = (overviewCompletion + daysCompletion + marketingCompletion) / (2 + (days>0? days : 1)) * 100
    // const days = getAllDaysCompletion(experience?.itinerary?.trip_days);
    // const overview = getOverviewCompletion (experience).total;
    // const totalCompletion = (days * experience?.itinerary?.trip_days?.length + overview)
    //     / (experience?.itinerary?.trip_days?.length + 1);
};

export const getAllActivitiesCompletion = (allActivities) => {
    if (allActivities.length > 0)
        return Math.floor(
            allActivities.reduce(
                (total, activity) =>
                    total +
                    getCompletion({
                        title: activity.title,
                        description_html: activity.description_html,
                        // images: activity.images,
                        location: activity.location.address
                    }),
                0
            ) / allActivities.length
        );
    else return 0;
};

export const getAllMovingCompletion = (allMoving) => {
    if (allMoving.length > 0)
        return Math.floor(
            allMoving.reduce(
                (total, moving) =>
                    total +
                    getCompletion({
                        // type: moving.type,
                        description_html: moving.description_html
                        // vendors: moving.vendors
                    }),
                0
            ) / allMoving.length
        );
    else return 0;
};

export const getAllCurrencyCompletion = (allCurrency) => {
    if (allCurrency.length > 0)
        return Math.floor(
            allCurrency.reduce(
                (total, currency) =>
                    total +
                    getCompletion({
                        description_html: currency.description_html
                    }),
                0
            ) / allCurrency.length
        );
    else return 0;
};

export const getHeadTitle = (role) => {
    if (role === process.env.REACT_APP_STRAPI_ADMIN_ROLE_ID || role === process.env.REACT_APP_STRAPI_STAFF_ROLE_ID) {
        return '(for Admins)';
    } else return '';
};
