/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import ModalFullscreen, { ModalBody } from 'components/blocks/ModalFullscreen';
import {
    ModalFooter,
    ModalCTA,
    ModalButton,
    ModalHelp
} from 'components/blocks/ModalTre';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import MapBox from 'components/map/MapBox';
import { handleRowReverse } from 'helpers/FEutils';
import ModalTopBlock from 'components/blocks/ModalTopBlock';
import { nanoid } from 'nanoid';

// should be in all modals
const targetElement = document.querySelector('#root');
const MapModal = React.memo(
    ({
        close,
        setModalIsOpen,
        handleActionBtn,
        globalState: { lang },
        icon = uiStruct.itinerary.activities.uiEdit.modals.map.icon,
        hintObj = uiStruct.itinerary.activities.uiEdit.modals.map.hint,
        actionBtnObj = uiStruct.ui.modals.editor.buttons.action,
        cancelBtnObj = uiStruct.ui.modals.editor.buttons.cancel,
        locationData,
        type = 'default',
        modalTitle = 'Activity',
        modalSubTitle = 'Add Location Pin',
        text = translations[lang][
            uiStruct.itinerary.activities.uiEdit.modals.map.description
        ]
    }) => {
        //

        const rtl = !!translations[lang].rtl;

        //
        const [addresError, setAddressError] = useState(false);

        // Copy Below to all modals
        //////////////////////////////////////////////////////////////////////

        // State for triggering animation of modal in and out
        const [transitionState, setTransitionState] = useState('off');
        useEffect(() => {
            setTransitionState('on');
        }, []);

        // Prevent BG from scrolling
        useEffect(() => {
            disableBodyScroll(targetElement, { reserveScrollBarGap: true });

            return () => {
                clearAllBodyScrollLocks();
            };
        }, []);

        // Handle When Modal is loaded
        const handleTransitionStateOn = () => {
            setTransitionState('on');
        };

        // Handle When Modal is about to unload (trigger animation then change modal state)
        const handleTransitionStateOff = () => {
            setTransitionState('off');
            setTimeout(() => setModalIsOpen(false), 300);
        };

        ///////////////////////////////////////////////////////////////////

        // Paul stuff

        const [marker, setMarker] = useState(null);
        const [address, setAddress] = useState(null);
        const [accommData, setAccommData] = useState({ ...locationData.info });
        const handleMarkerUpdate = (markerObj) => {
            setMarker(markerObj);
        };
        const handleAddressUpdate = (address, accomData) => {
            setAddressError(false);
            setAddress(address);
        };

        // Paul stuff Handle Action BTN function
        const handleActionClick = () => {
            // console.log('address', address);
            if (!address.length) {
                setAddressError(true);
            } else {
                if (type === 'accommodation') {
                    handleActionBtn(
                        marker,
                        address,
                        accommData,
                        locationData?.objId ??
                            `overview_accommodation_${nanoid()}`
                    );
                } else {
                    handleActionBtn(marker, address);
                }
                handleTransitionStateOff(); // important... don't forget to add it in all modals
            }
        };

        return (
            <>
                <ModalFullscreen
                    width='4xl'
                    handleOpen={handleTransitionStateOff}
                    handleTransition={handleTransitionStateOn}
                    animationCss={uiStruct.ui.modals.animation[transitionState]}
                    icon={icon}
                    close={close}
                    closeButton={true}
                    rtl={rtl}
                    fullscreen={true}
                >
                    <ModalBody rtlOff>
                        <div className='max-w-7xl mx-auto'>
                            <div className='w-full  px-4 md:px-8 lg:px-2 mb-8 mt-4'>
                                <ModalTopBlock
                                    title={modalTitle}
                                    subtitle={modalSubTitle}
                                    text={text}
                                    rtl={rtl}
                                    lang={lang}
                                />
                            </div>
                            <MapBox
                                defaultAddress={address}
                                addresError={addresError}
                                locationData={locationData}
                                handleMarkerUpdate={handleMarkerUpdate}
                                handleAddressUpdate={handleAddressUpdate}
                                type={type}
                                accommodationData={accommData}
                                setAccommData={setAccommData}
                            />
                            <div className='mt-8 mb-8 px-4 md:px-10'>
                                <ModalFooter>
                                    {hintObj && (
                                        <ModalHelp
                                            helpText={
                                                translations[lang][hintObj]
                                            }
                                            isHtml
                                        />
                                    )}
                                    <ModalCTA>
                                        <ModalButton
                                            handleClick={
                                                handleTransitionStateOff
                                            }
                                            label={
                                                translations[lang][
                                                    cancelBtnObj.label
                                                ]
                                            }
                                            color={cancelBtnObj.color}
                                            icon={cancelBtnObj.icon}
                                        />
                                        <ModalButton
                                            handleClick={handleActionClick}
                                            label={
                                                translations[lang][
                                                    actionBtnObj.label
                                                ]
                                            }
                                            color={actionBtnObj.color}
                                            icon={actionBtnObj.icon}
                                        />
                                    </ModalCTA>
                                </ModalFooter>
                            </div>
                        </div>
                    </ModalBody>
                </ModalFullscreen>
            </>
        );
    }
);

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(MapModal);
