import React, { useState, useEffect } from 'react';
import Gallery from 'react-photo-gallery';
import Photo from 'components/specialty/Photo';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { filter } from 'lodash';

/* popout the browser and maximize to see more rows! -> */
const SortablePhoto = SortableElement((item) => <Photo {...item} />);
const SortableGallery = SortableContainer(({ items, handleDelete }) => {
    return (
        <Gallery
            photos={items}
            renderImage={(props) => (
                <SortablePhoto {...props} handleDelete={handleDelete} />
            )}
        />
    );
});

function GalleryArrange({ imageList, handleReorder }) {
    const [items, setItems] = useState(imageList);

    useEffect(() => {
        setItems(imageList);
    }, [imageList]);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newOrderArray = arrayMove(items, oldIndex, newIndex);

        if (handleReorder) {
            handleReorder(newOrderArray);
        }
        setItems(newOrderArray);
    };
    const handleDelete = (photo) => {
        const newArray = filter(items, (o) => o.key !== photo.key);

        if (handleReorder) {
            handleReorder(newArray);
        }
        setItems(newArray);
    };

    return (
        <div className='px-4 mt-6 mx-auto max-w-2xl'>
            <SortableGallery
                items={items}
                handleDelete={handleDelete}
                onSortEnd={onSortEnd}
                axis={'xy'}
            />
        </div>
    );
}
export default GalleryArrange;
