export const borders = {
    FJ: {
        name: 'Fiji',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: 180,
                    lat: -16.067132663642447
                },
                {
                    lng: 180,
                    lat: -16.555216566639196
                },
                {
                    lng: 179.36414266196414,
                    lat: -16.801354076946883
                },
                {
                    lng: 178.72505936299711,
                    lat: -17.01204167436804
                },
                {
                    lng: 178.59683859511713,
                    lat: -16.63915
                },
                {
                    lng: 179.0966093629971,
                    lat: -16.433984277547403
                },
                {
                    lng: 179.4135093629971,
                    lat: -16.379054277547404
                },
                {
                    lng: 180,
                    lat: -16.067132663642447
                }
            ],
            [
                {
                    lng: 178.12557,
                    lat: -17.50481
                },
                {
                    lng: 178.3736,
                    lat: -17.33992
                },
                {
                    lng: 178.71806,
                    lat: -17.62846
                },
                {
                    lng: 178.55271,
                    lat: -18.15059
                },
                {
                    lng: 177.93266,
                    lat: -18.28799
                },
                {
                    lng: 177.38146,
                    lat: -18.16432
                },
                {
                    lng: 177.28504,
                    lat: -17.72465
                },
                {
                    lng: 177.67087,
                    lat: -17.38114
                },
                {
                    lng: 178.12557,
                    lat: -17.50481
                }
            ],
            [
                {
                    lng: -179.79332010904864,
                    lat: -16.020882256741224
                },
                {
                    lng: -179.9173693847653,
                    lat: -16.501783135649397
                },
                {
                    lng: -180,
                    lat: -16.555216566639196
                },
                {
                    lng: -180,
                    lat: -16.067132663642447
                },
                {
                    lng: -179.79332010904864,
                    lat: -16.020882256741224
                }
            ]
        ]
    },
    TZ: {
        name: 'Tanzania',
        type: 'Polygon',
        coordinates: [
            {
                lng: 33.90371119710453,
                lat: -0.95
            },
            {
                lng: 34.07262,
                lat: -1.05982
            },
            {
                lng: 37.69869,
                lat: -3.09699
            },
            {
                lng: 37.7669,
                lat: -3.67712
            },
            {
                lng: 39.20222,
                lat: -4.67677
            },
            {
                lng: 38.74054,
                lat: -5.90895
            },
            {
                lng: 38.79977,
                lat: -6.47566
            },
            {
                lng: 39.44,
                lat: -6.839999999999861
            },
            {
                lng: 39.47000000000014,
                lat: -7.1
            },
            {
                lng: 39.19469,
                lat: -7.7039
            },
            {
                lng: 39.25203,
                lat: -8.00781
            },
            {
                lng: 39.18652,
                lat: -8.48551
            },
            {
                lng: 39.53574,
                lat: -9.112369999999885
            },
            {
                lng: 39.9496,
                lat: -10.0984
            },
            {
                lng: 40.316586229110854,
                lat: -10.317097752817492
            },
            {
                lng: 40.31659,
                lat: -10.317099999999868
            },
            {
                lng: 39.521,
                lat: -10.89688
            },
            {
                lng: 38.42755659358775,
                lat: -11.285202325081656
            },
            {
                lng: 37.82764,
                lat: -11.26879
            },
            {
                lng: 37.47129,
                lat: -11.56876
            },
            {
                lng: 36.775150994622805,
                lat: -11.594537448780805
            },
            {
                lng: 36.51408165868426,
                lat: -11.720938002166735
            },
            {
                lng: 35.31239790216904,
                lat: -11.439146416879147
            },
            {
                lng: 34.55998904799935,
                lat: -11.520020033415925
            },
            {
                lng: 34.28,
                lat: -10.16
            },
            {
                lng: 33.940837724096525,
                lat: -9.693673841980285
            },
            {
                lng: 33.73972,
                lat: -9.41715
            },
            {
                lng: 32.75937544122132,
                lat: -9.23059905358906
            },
            {
                lng: 32.19186486179194,
                lat: -8.930358981973257
            },
            {
                lng: 31.556348097466497,
                lat: -8.762048841998642
            },
            {
                lng: 31.15775133695005,
                lat: -8.594578747317366
            },
            {
                lng: 30.740009731422095,
                lat: -8.34000593035372
            },
            {
                lng: 30.74001549655179,
                lat: -8.340007419470915
            },
            {
                lng: 30.199996779101696,
                lat: -7.079980970898163
            },
            {
                lng: 29.620032179490014,
                lat: -6.520015150583426
            },
            {
                lng: 29.419992710088167,
                lat: -5.939998874539434
            },
            {
                lng: 29.519986606572928,
                lat: -5.419978936386315
            },
            {
                lng: 29.339997592900346,
                lat: -4.499983412294092
            },
            {
                lng: 29.753512404099865,
                lat: -4.452389418153302
            },
            {
                lng: 30.11632,
                lat: -4.09012
            },
            {
                lng: 30.50554,
                lat: -3.56858
            },
            {
                lng: 30.75224,
                lat: -3.35931
            },
            {
                lng: 30.74301,
                lat: -3.03431
            },
            {
                lng: 30.52766,
                lat: -2.80762
            },
            {
                lng: 30.469673645761223,
                lat: -2.41385475710134
            },
            {
                lng: 30.46967,
                lat: -2.41383
            },
            {
                lng: 30.75830895358311,
                lat: -2.287250257988369
            },
            {
                lng: 30.816134881317712,
                lat: -1.698914076345389
            },
            {
                lng: 30.419104852019245,
                lat: -1.134659112150416
            },
            {
                lng: 30.769860000000108,
                lat: -1.01455
            },
            {
                lng: 31.86617,
                lat: -1.02736
            },
            {
                lng: 33.90371119710453,
                lat: -0.95
            }
        ]
    },
    EH: {
        name: 'Western Sahara',
        type: 'Polygon',
        coordinates: [
            {
                lng: -8.665589565454809,
                lat: 27.656425889592356
            },
            {
                lng: -8.665124477564191,
                lat: 27.589479071558227
            },
            {
                lng: -8.684399786809053,
                lat: 27.395744126896005
            },
            {
                lng: -8.6872936670174,
                lat: 25.881056219988906
            },
            {
                lng: -11.96941891117116,
                lat: 25.933352769468268
            },
            {
                lng: -11.937224493853321,
                lat: 23.374594224536168
            },
            {
                lng: -12.874221564169575,
                lat: 23.284832261645178
            },
            {
                lng: -13.118754441774712,
                lat: 22.771220201096256
            },
            {
                lng: -12.929101935263532,
                lat: 21.327070624267563
            },
            {
                lng: -16.845193650773993,
                lat: 21.33332347257488
            },
            {
                lng: -17.06342322434257,
                lat: 20.999752102130827
            },
            {
                lng: -17.02042843267577,
                lat: 21.422310288981578
            },
            {
                lng: -17.00296179856109,
                lat: 21.420734157796577
            },
            {
                lng: -14.750954555713534,
                lat: 21.500600083903663
            },
            {
                lng: -14.630832688851072,
                lat: 21.860939846274903
            },
            {
                lng: -14.221167771857253,
                lat: 22.31016307218816
            },
            {
                lng: -13.891110398809047,
                lat: 23.691009019459305
            },
            {
                lng: -12.50096269372537,
                lat: 24.7701162785782
            },
            {
                lng: -12.03075883630163,
                lat: 26.030866197203068
            },
            {
                lng: -11.718219773800357,
                lat: 26.104091701760623
            },
            {
                lng: -11.392554897497007,
                lat: 26.883423977154393
            },
            {
                lng: -10.551262579785273,
                lat: 26.990807603456886
            },
            {
                lng: -10.189424200877582,
                lat: 26.860944729107405
            },
            {
                lng: -9.735343390328879,
                lat: 26.860944729107405
            },
            {
                lng: -9.41303748212448,
                lat: 27.088476060488574
            },
            {
                lng: -8.794883999049077,
                lat: 27.120696316022507
            },
            {
                lng: -8.817828334986672,
                lat: 27.656425889592356
            },
            {
                lng: -8.665589565454809,
                lat: 27.656425889592356
            }
        ]
    },
    CA: {
        name: 'Canada',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: -122.84,
                    lat: 49.000000000000114
                },
                {
                    lng: -122.97421,
                    lat: 49.00253777777778
                },
                {
                    lng: -124.91024,
                    lat: 49.98456
                },
                {
                    lng: -125.62461,
                    lat: 50.41656
                },
                {
                    lng: -127.43561,
                    lat: 50.83061
                },
                {
                    lng: -127.99276,
                    lat: 51.71583
                },
                {
                    lng: -127.85032,
                    lat: 52.32961
                },
                {
                    lng: -129.12979,
                    lat: 52.75538
                },
                {
                    lng: -129.30523,
                    lat: 53.56159
                },
                {
                    lng: -130.51497,
                    lat: 54.28757
                },
                {
                    lng: -130.53610895273684,
                    lat: 54.80275447679924
                },
                {
                    lng: -130.53611,
                    lat: 54.80278
                },
                {
                    lng: -129.98,
                    lat: 55.285
                },
                {
                    lng: -130.00778,
                    lat: 55.91583
                },
                {
                    lng: -131.70781,
                    lat: 56.55212
                },
                {
                    lng: -132.73042,
                    lat: 57.69289
                },
                {
                    lng: -133.35556,
                    lat: 58.41028
                },
                {
                    lng: -134.27111,
                    lat: 58.86111
                },
                {
                    lng: -134.945,
                    lat: 59.2705600000001
                },
                {
                    lng: -135.47583,
                    lat: 59.78778
                },
                {
                    lng: -136.47972,
                    lat: 59.46389
                },
                {
                    lng: -137.4525,
                    lat: 58.905
                },
                {
                    lng: -138.34089,
                    lat: 59.56211
                },
                {
                    lng: -139.039,
                    lat: 60
                },
                {
                    lng: -140.013,
                    lat: 60.27682
                },
                {
                    lng: -140.99778,
                    lat: 60.30639
                },
                {
                    lng: -140.9925,
                    lat: 66.00003
                },
                {
                    lng: -140.986,
                    lat: 69.712
                },
                {
                    lng: -140.98598761037601,
                    lat: 69.71199839952635
                },
                {
                    lng: -139.12052,
                    lat: 69.47102
                },
                {
                    lng: -137.54636,
                    lat: 68.99002
                },
                {
                    lng: -136.50358,
                    lat: 68.89804
                },
                {
                    lng: -135.62576,
                    lat: 69.31512
                },
                {
                    lng: -134.41464,
                    lat: 69.62743
                },
                {
                    lng: -132.92925,
                    lat: 69.50534
                },
                {
                    lng: -131.43136,
                    lat: 69.94451
                },
                {
                    lng: -129.79471,
                    lat: 70.19369
                },
                {
                    lng: -129.10773,
                    lat: 69.77927
                },
                {
                    lng: -128.36156,
                    lat: 70.01286
                },
                {
                    lng: -128.13817,
                    lat: 70.48384
                },
                {
                    lng: -127.44712,
                    lat: 70.37721
                },
                {
                    lng: -125.75632,
                    lat: 69.48058
                },
                {
                    lng: -124.42483,
                    lat: 70.1584
                },
                {
                    lng: -124.28968,
                    lat: 69.39969
                },
                {
                    lng: -123.06108,
                    lat: 69.56372
                },
                {
                    lng: -122.6835,
                    lat: 69.85553
                },
                {
                    lng: -121.47226,
                    lat: 69.79778
                },
                {
                    lng: -119.94288,
                    lat: 69.37786
                },
                {
                    lng: -117.60268,
                    lat: 69.01128
                },
                {
                    lng: -116.22643,
                    lat: 68.84151
                },
                {
                    lng: -115.2469,
                    lat: 68.90591
                },
                {
                    lng: -113.89794,
                    lat: 68.3989
                },
                {
                    lng: -115.30489,
                    lat: 67.90261
                },
                {
                    lng: -113.49727,
                    lat: 67.68815
                },
                {
                    lng: -110.798,
                    lat: 67.80612
                },
                {
                    lng: -109.94619,
                    lat: 67.98104
                },
                {
                    lng: -108.8802,
                    lat: 67.38144
                },
                {
                    lng: -107.79239,
                    lat: 67.88736
                },
                {
                    lng: -108.81299,
                    lat: 68.31164
                },
                {
                    lng: -108.16721,
                    lat: 68.65392
                },
                {
                    lng: -106.95,
                    lat: 68.7
                },
                {
                    lng: -106.15,
                    lat: 68.8
                },
                {
                    lng: -105.34282,
                    lat: 68.56122
                },
                {
                    lng: -104.33791,
                    lat: 68.018
                },
                {
                    lng: -103.22115,
                    lat: 68.09775
                },
                {
                    lng: -101.45433,
                    lat: 67.64689
                },
                {
                    lng: -99.90195,
                    lat: 67.80566
                },
                {
                    lng: -98.4432,
                    lat: 67.78165
                },
                {
                    lng: -98.5586,
                    lat: 68.40394
                },
                {
                    lng: -97.66948,
                    lat: 68.57864
                },
                {
                    lng: -96.11991,
                    lat: 68.23939
                },
                {
                    lng: -96.12588,
                    lat: 67.29338
                },
                {
                    lng: -95.48943,
                    lat: 68.0907
                },
                {
                    lng: -94.685,
                    lat: 68.06383
                },
                {
                    lng: -94.23282,
                    lat: 69.06903
                },
                {
                    lng: -95.30408,
                    lat: 69.68571
                },
                {
                    lng: -96.47131,
                    lat: 70.08976
                },
                {
                    lng: -96.39115,
                    lat: 71.19482
                },
                {
                    lng: -95.2088,
                    lat: 71.92053
                },
                {
                    lng: -93.88997,
                    lat: 71.76015
                },
                {
                    lng: -92.87818,
                    lat: 71.31869
                },
                {
                    lng: -91.51964,
                    lat: 70.19129
                },
                {
                    lng: -92.40692,
                    lat: 69.69997
                },
                {
                    lng: -90.5471,
                    lat: 69.49766
                },
                {
                    lng: -90.55151,
                    lat: 68.47499
                },
                {
                    lng: -89.21515,
                    lat: 69.25873
                },
                {
                    lng: -88.01966,
                    lat: 68.61508
                },
                {
                    lng: -88.31749,
                    lat: 67.87338
                },
                {
                    lng: -87.35017,
                    lat: 67.19872
                },
                {
                    lng: -86.30607,
                    lat: 67.92146
                },
                {
                    lng: -85.57664,
                    lat: 68.78456
                },
                {
                    lng: -85.52197,
                    lat: 69.88211
                },
                {
                    lng: -84.10081,
                    lat: 69.80539
                },
                {
                    lng: -82.62258,
                    lat: 69.65826
                },
                {
                    lng: -81.28043,
                    lat: 69.16202
                },
                {
                    lng: -81.2202,
                    lat: 68.66567
                },
                {
                    lng: -81.96436,
                    lat: 68.13253
                },
                {
                    lng: -81.25928,
                    lat: 67.59716
                },
                {
                    lng: -81.38653,
                    lat: 67.11078
                },
                {
                    lng: -83.34456,
                    lat: 66.41154
                },
                {
                    lng: -84.73542,
                    lat: 66.2573
                },
                {
                    lng: -85.76943,
                    lat: 66.55833
                },
                {
                    lng: -86.0676,
                    lat: 66.05625
                },
                {
                    lng: -87.03143,
                    lat: 65.21297
                },
                {
                    lng: -87.32324,
                    lat: 64.77563
                },
                {
                    lng: -88.48296,
                    lat: 64.09897
                },
                {
                    lng: -89.91444,
                    lat: 64.03273
                },
                {
                    lng: -90.70398,
                    lat: 63.61017
                },
                {
                    lng: -90.77004,
                    lat: 62.96021
                },
                {
                    lng: -91.93342,
                    lat: 62.83508
                },
                {
                    lng: -93.15698,
                    lat: 62.02469
                },
                {
                    lng: -94.24153,
                    lat: 60.89865
                },
                {
                    lng: -94.62931,
                    lat: 60.11021
                },
                {
                    lng: -94.6846,
                    lat: 58.94882
                },
                {
                    lng: -93.21502,
                    lat: 58.78212
                },
                {
                    lng: -92.76462,
                    lat: 57.84571
                },
                {
                    lng: -92.29703,
                    lat: 57.08709
                },
                {
                    lng: -90.89769,
                    lat: 57.28468
                },
                {
                    lng: -89.03953,
                    lat: 56.85172
                },
                {
                    lng: -88.03978,
                    lat: 56.47162
                },
                {
                    lng: -87.32421,
                    lat: 55.99914
                },
                {
                    lng: -86.07121,
                    lat: 55.72383
                },
                {
                    lng: -85.01181,
                    lat: 55.3026
                },
                {
                    lng: -83.36055,
                    lat: 55.24489
                },
                {
                    lng: -82.27285,
                    lat: 55.14832
                },
                {
                    lng: -82.4362,
                    lat: 54.28227
                },
                {
                    lng: -82.12502,
                    lat: 53.27703
                },
                {
                    lng: -81.40075,
                    lat: 52.15788
                },
                {
                    lng: -79.91289,
                    lat: 51.20842
                },
                {
                    lng: -79.14301,
                    lat: 51.53393
                },
                {
                    lng: -78.60191,
                    lat: 52.56208
                },
                {
                    lng: -79.12421,
                    lat: 54.14145
                },
                {
                    lng: -79.82958,
                    lat: 54.66772
                },
                {
                    lng: -78.22874,
                    lat: 55.13645
                },
                {
                    lng: -77.0956,
                    lat: 55.83741
                },
                {
                    lng: -76.54137,
                    lat: 56.53423
                },
                {
                    lng: -76.62319,
                    lat: 57.20263
                },
                {
                    lng: -77.30226,
                    lat: 58.05209
                },
                {
                    lng: -78.51688,
                    lat: 58.80458
                },
                {
                    lng: -77.33676,
                    lat: 59.85261
                },
                {
                    lng: -77.77272,
                    lat: 60.75788
                },
                {
                    lng: -78.10687,
                    lat: 62.31964
                },
                {
                    lng: -77.41067,
                    lat: 62.55053
                },
                {
                    lng: -75.69621,
                    lat: 62.2784
                },
                {
                    lng: -74.6682,
                    lat: 62.18111
                },
                {
                    lng: -73.83988,
                    lat: 62.4438
                },
                {
                    lng: -72.90853,
                    lat: 62.10507
                },
                {
                    lng: -71.67708,
                    lat: 61.52535
                },
                {
                    lng: -71.37369,
                    lat: 61.13717
                },
                {
                    lng: -69.59042,
                    lat: 61.06141
                },
                {
                    lng: -69.62033,
                    lat: 60.22125
                },
                {
                    lng: -69.2879,
                    lat: 58.95736
                },
                {
                    lng: -68.37455,
                    lat: 58.80106
                },
                {
                    lng: -67.64976,
                    lat: 58.21206
                },
                {
                    lng: -66.20178,
                    lat: 58.76731
                },
                {
                    lng: -65.24517,
                    lat: 59.87071
                },
                {
                    lng: -64.58352,
                    lat: 60.33558
                },
                {
                    lng: -63.80475,
                    lat: 59.4426
                },
                {
                    lng: -62.50236,
                    lat: 58.16708
                },
                {
                    lng: -61.39655,
                    lat: 56.96745
                },
                {
                    lng: -61.79866,
                    lat: 56.33945
                },
                {
                    lng: -60.46853,
                    lat: 55.77548
                },
                {
                    lng: -59.56962,
                    lat: 55.20407
                },
                {
                    lng: -57.97508,
                    lat: 54.94549
                },
                {
                    lng: -57.3332,
                    lat: 54.6265
                },
                {
                    lng: -56.93689,
                    lat: 53.78032
                },
                {
                    lng: -56.15811,
                    lat: 53.64749
                },
                {
                    lng: -55.75632,
                    lat: 53.27036
                },
                {
                    lng: -55.68338,
                    lat: 52.14664
                },
                {
                    lng: -56.40916,
                    lat: 51.7707
                },
                {
                    lng: -57.12691,
                    lat: 51.41972
                },
                {
                    lng: -58.77482,
                    lat: 51.0643
                },
                {
                    lng: -60.03309,
                    lat: 50.24277
                },
                {
                    lng: -61.72366,
                    lat: 50.08046
                },
                {
                    lng: -63.86251,
                    lat: 50.29099
                },
                {
                    lng: -65.36331,
                    lat: 50.2982
                },
                {
                    lng: -66.39905,
                    lat: 50.22897
                },
                {
                    lng: -67.23631,
                    lat: 49.51156
                },
                {
                    lng: -68.51114,
                    lat: 49.06836
                },
                {
                    lng: -69.95362,
                    lat: 47.74488
                },
                {
                    lng: -71.10458,
                    lat: 46.82171
                },
                {
                    lng: -70.25522,
                    lat: 46.98606
                },
                {
                    lng: -68.65,
                    lat: 48.3
                },
                {
                    lng: -66.55243,
                    lat: 49.1331
                },
                {
                    lng: -65.05626,
                    lat: 49.23278
                },
                {
                    lng: -64.17099,
                    lat: 48.74248
                },
                {
                    lng: -65.11545,
                    lat: 48.07085
                },
                {
                    lng: -64.79854,
                    lat: 46.99297
                },
                {
                    lng: -64.47219,
                    lat: 46.23849
                },
                {
                    lng: -63.17329,
                    lat: 45.73902
                },
                {
                    lng: -61.52072,
                    lat: 45.88377
                },
                {
                    lng: -60.51815,
                    lat: 47.00793
                },
                {
                    lng: -60.4486,
                    lat: 46.28264
                },
                {
                    lng: -59.80287,
                    lat: 45.9204
                },
                {
                    lng: -61.03988,
                    lat: 45.26525
                },
                {
                    lng: -63.25471,
                    lat: 44.67014
                },
                {
                    lng: -64.24656,
                    lat: 44.26553
                },
                {
                    lng: -65.36406,
                    lat: 43.54523
                },
                {
                    lng: -66.1234,
                    lat: 43.61867
                },
                {
                    lng: -66.16173,
                    lat: 44.46512
                },
                {
                    lng: -64.42549,
                    lat: 45.29204
                },
                {
                    lng: -66.02605,
                    lat: 45.25931
                },
                {
                    lng: -67.13741,
                    lat: 45.13753
                },
                {
                    lng: -67.79134,
                    lat: 45.70281
                },
                {
                    lng: -67.79046,
                    lat: 47.06636
                },
                {
                    lng: -68.23444,
                    lat: 47.35486
                },
                {
                    lng: -68.905,
                    lat: 47.185
                },
                {
                    lng: -69.237216,
                    lat: 47.447781
                },
                {
                    lng: -69.99997,
                    lat: 46.69307
                },
                {
                    lng: -70.305,
                    lat: 45.915
                },
                {
                    lng: -70.66,
                    lat: 45.46
                },
                {
                    lng: -71.08482,
                    lat: 45.30524000000014
                },
                {
                    lng: -71.405,
                    lat: 45.255
                },
                {
                    lng: -71.50506,
                    lat: 45.0082
                },
                {
                    lng: -73.34783,
                    lat: 45.00738
                },
                {
                    lng: -74.867,
                    lat: 45.00048
                },
                {
                    lng: -75.31821,
                    lat: 44.81645
                },
                {
                    lng: -76.375,
                    lat: 44.09631
                },
                {
                    lng: -76.5,
                    lat: 44.01845889375865
                },
                {
                    lng: -76.82003414580558,
                    lat: 43.628784288093755
                },
                {
                    lng: -77.7378850979577,
                    lat: 43.62905558936328
                },
                {
                    lng: -78.72027991404235,
                    lat: 43.62508942318493
                },
                {
                    lng: -79.17167355011186,
                    lat: 43.46633942318426
                },
                {
                    lng: -79.01,
                    lat: 43.27
                },
                {
                    lng: -78.92,
                    lat: 42.965
                },
                {
                    lng: -78.93936214874375,
                    lat: 42.86361135514798
                },
                {
                    lng: -80.24744767934794,
                    lat: 42.36619985612255
                },
                {
                    lng: -81.27774654816716,
                    lat: 42.209025987306816
                },
                {
                    lng: -82.4392777167916,
                    lat: 41.675105088867326
                },
                {
                    lng: -82.69008928092023,
                    lat: 41.675105088867326
                },
                {
                    lng: -83.029810146807,
                    lat: 41.83279572200598
                },
                {
                    lng: -83.14199968131264,
                    lat: 41.975681057292874
                },
                {
                    lng: -83.12,
                    lat: 42.08
                },
                {
                    lng: -82.9,
                    lat: 42.43
                },
                {
                    lng: -82.43,
                    lat: 42.98
                },
                {
                    lng: -82.13764238150395,
                    lat: 43.57108755143997
                },
                {
                    lng: -82.33776312543114,
                    lat: 44.44
                },
                {
                    lng: -82.55092464875821,
                    lat: 45.34751658790543
                },
                {
                    lng: -83.59285071484311,
                    lat: 45.81689362241252
                },
                {
                    lng: -83.46955074739469,
                    lat: 45.994686387712534
                },
                {
                    lng: -83.61613094759059,
                    lat: 46.116926988299014
                },
                {
                    lng: -83.89076534700574,
                    lat: 46.116926988299014
                },
                {
                    lng: -84.0918512641615,
                    lat: 46.27541860613826
                },
                {
                    lng: -84.1421195136734,
                    lat: 46.51222585711571
                },
                {
                    lng: -84.3367,
                    lat: 46.40877
                },
                {
                    lng: -84.6049,
                    lat: 46.4396
                },
                {
                    lng: -84.54374874544584,
                    lat: 46.538684190449146
                },
                {
                    lng: -84.77923824739992,
                    lat: 46.63710195574902
                },
                {
                    lng: -84.8760798815149,
                    lat: 46.90008331968238
                },
                {
                    lng: -85.65236324740341,
                    lat: 47.22021881773051
                },
                {
                    lng: -86.46199083122826,
                    lat: 47.553338019392
                },
                {
                    lng: -87.43979262330028,
                    lat: 47.94
                },
                {
                    lng: -88.37811418328671,
                    lat: 48.302917588893706
                },
                {
                    lng: -89.27291744663665,
                    lat: 48.01980825458281
                },
                {
                    lng: -89.6,
                    lat: 48.010000000000105
                },
                {
                    lng: -90.83,
                    lat: 48.27
                },
                {
                    lng: -91.64,
                    lat: 48.14
                },
                {
                    lng: -92.61,
                    lat: 48.45
                },
                {
                    lng: -93.63087,
                    lat: 48.60926
                },
                {
                    lng: -94.32914,
                    lat: 48.67074
                },
                {
                    lng: -94.64,
                    lat: 48.84
                },
                {
                    lng: -94.81758,
                    lat: 49.38905
                },
                {
                    lng: -95.15609,
                    lat: 49.38425
                },
                {
                    lng: -95.15906950917206,
                    lat: 49
                },
                {
                    lng: -97.2287200000048,
                    lat: 49.0007
                },
                {
                    lng: -100.65,
                    lat: 49.000000000000114
                },
                {
                    lng: -104.04826,
                    lat: 48.99986
                },
                {
                    lng: -107.05,
                    lat: 49
                },
                {
                    lng: -110.05,
                    lat: 49
                },
                {
                    lng: -113,
                    lat: 49
                },
                {
                    lng: -116.04818,
                    lat: 49
                },
                {
                    lng: -117.03121,
                    lat: 49
                },
                {
                    lng: -120,
                    lat: 49.000000000000114
                },
                {
                    lng: -122.84,
                    lat: 49.000000000000114
                }
            ],
            [
                {
                    lng: -83.99367,
                    lat: 62.4528
                },
                {
                    lng: -83.25048,
                    lat: 62.91409
                },
                {
                    lng: -81.87699,
                    lat: 62.90458
                },
                {
                    lng: -81.89825,
                    lat: 62.7108
                },
                {
                    lng: -83.06857,
                    lat: 62.15922
                },
                {
                    lng: -83.77462,
                    lat: 62.18231
                },
                {
                    lng: -83.99367,
                    lat: 62.4528
                }
            ],
            [
                {
                    lng: -79.77583312988281,
                    lat: 72.8029022216797
                },
                {
                    lng: -80.87609863281251,
                    lat: 73.33318328857422
                },
                {
                    lng: -80.83388519287111,
                    lat: 73.69318389892578
                },
                {
                    lng: -80.35305786132812,
                    lat: 73.75971984863281
                },
                {
                    lng: -78.06443786621094,
                    lat: 73.65193176269531
                },
                {
                    lng: -76.34,
                    lat: 73.10268498995305
                },
                {
                    lng: -76.25140380859375,
                    lat: 72.82638549804688
                },
                {
                    lng: -77.31443786621094,
                    lat: 72.85554504394531
                },
                {
                    lng: -78.39167022705078,
                    lat: 72.87665557861328
                },
                {
                    lng: -79.4862518310547,
                    lat: 72.74220275878906
                },
                {
                    lng: -79.77583312988281,
                    lat: 72.8029022216797
                }
            ],
            [
                {
                    lng: -80.315395,
                    lat: 62.085565
                },
                {
                    lng: -79.92939,
                    lat: 62.3856
                },
                {
                    lng: -79.52002,
                    lat: 62.36371
                },
                {
                    lng: -79.26582,
                    lat: 62.158675
                },
                {
                    lng: -79.65752,
                    lat: 61.63308
                },
                {
                    lng: -80.09956,
                    lat: 61.7181
                },
                {
                    lng: -80.36215,
                    lat: 62.01649
                },
                {
                    lng: -80.315395,
                    lat: 62.085565
                }
            ],
            [
                {
                    lng: -93.61275590694046,
                    lat: 74.97999726022438
                },
                {
                    lng: -94.15690873897391,
                    lat: 74.59234650338688
                },
                {
                    lng: -95.60868058956564,
                    lat: 74.66686391875176
                },
                {
                    lng: -96.82093217648455,
                    lat: 74.92762319609658
                },
                {
                    lng: -96.28858740922982,
                    lat: 75.37782827422338
                },
                {
                    lng: -94.85081987178917,
                    lat: 75.64721751576089
                },
                {
                    lng: -93.97774654821797,
                    lat: 75.29648956979595
                },
                {
                    lng: -93.61275590694046,
                    lat: 74.97999726022438
                }
            ],
            [
                {
                    lng: -93.84000301794399,
                    lat: 77.51999726023455
                },
                {
                    lng: -94.29560828324529,
                    lat: 77.49134267852868
                },
                {
                    lng: -96.16965410031007,
                    lat: 77.55511139597685
                },
                {
                    lng: -96.43630449093614,
                    lat: 77.83462921824362
                },
                {
                    lng: -94.42257727738641,
                    lat: 77.820004787905
                },
                {
                    lng: -93.7206562975659,
                    lat: 77.63433136668031
                },
                {
                    lng: -93.84000301794399,
                    lat: 77.51999726023455
                }
            ],
            [
                {
                    lng: -96.75439876990876,
                    lat: 78.76581268992702
                },
                {
                    lng: -95.5592779202946,
                    lat: 78.41831452098033
                },
                {
                    lng: -95.83029496944934,
                    lat: 78.05694122996324
                },
                {
                    lng: -97.30984290239799,
                    lat: 77.85059723582181
                },
                {
                    lng: -98.12428931353404,
                    lat: 78.08285696075761
                },
                {
                    lng: -98.55286780474668,
                    lat: 78.45810537384507
                },
                {
                    lng: -98.63198442258553,
                    lat: 78.87193024363837
                },
                {
                    lng: -97.33723141151266,
                    lat: 78.83198436147676
                },
                {
                    lng: -96.75439876990876,
                    lat: 78.76581268992702
                }
            ],
            [
                {
                    lng: -88.15035030796028,
                    lat: 74.39230703398503
                },
                {
                    lng: -89.7647220527584,
                    lat: 74.51555532500116
                },
                {
                    lng: -92.42244096552946,
                    lat: 74.83775788034099
                },
                {
                    lng: -92.76828548864282,
                    lat: 75.38681997344214
                },
                {
                    lng: -92.88990597204175,
                    lat: 75.88265534128267
                },
                {
                    lng: -93.89382402217599,
                    lat: 76.31924367950056
                },
                {
                    lng: -95.9624574450358,
                    lat: 76.4413809272224
                },
                {
                    lng: -97.1213789538295,
                    lat: 76.7510777859476
                },
                {
                    lng: -96.74512285031237,
                    lat: 77.16138865834507
                },
                {
                    lng: -94.68408586299944,
                    lat: 77.09787832305837
                },
                {
                    lng: -93.57392106807313,
                    lat: 76.77629588490605
                },
                {
                    lng: -91.6050231595366,
                    lat: 76.7785179714946
                },
                {
                    lng: -90.7418458727493,
                    lat: 76.44959747995681
                },
                {
                    lng: -90.96966142450802,
                    lat: 76.07401317005947
                },
                {
                    lng: -89.82223792189926,
                    lat: 75.84777374948565
                },
                {
                    lng: -89.18708289259985,
                    lat: 75.61016551380762
                },
                {
                    lng: -87.83827633334965,
                    lat: 75.56618886992725
                },
                {
                    lng: -86.37919226758864,
                    lat: 75.4824213731821
                },
                {
                    lng: -84.78962521029058,
                    lat: 75.69920400664653
                },
                {
                    lng: -82.75344458691006,
                    lat: 75.78431509063124
                },
                {
                    lng: -81.12853084992436,
                    lat: 75.71398346628199
                },
                {
                    lng: -80.05751095245915,
                    lat: 75.33684886341591
                },
                {
                    lng: -79.83393286814837,
                    lat: 74.92312734648716
                },
                {
                    lng: -80.45777075877587,
                    lat: 74.65730377877777
                },
                {
                    lng: -81.94884253612557,
                    lat: 74.44245901152432
                },
                {
                    lng: -83.22889360221143,
                    lat: 74.56402781849094
                },
                {
                    lng: -86.09745235873332,
                    lat: 74.41003205026117
                },
                {
                    lng: -88.15035030796028,
                    lat: 74.39230703398503
                }
            ],
            [
                {
                    lng: -111.26444332563088,
                    lat: 78.15295604116154
                },
                {
                    lng: -109.85445187054711,
                    lat: 77.99632477488488
                },
                {
                    lng: -110.18693803591302,
                    lat: 77.69701487905034
                },
                {
                    lng: -112.0511911690585,
                    lat: 77.4092288276169
                },
                {
                    lng: -113.53427893761912,
                    lat: 77.73220652944111
                },
                {
                    lng: -112.7245867582539,
                    lat: 78.05105011668196
                },
                {
                    lng: -111.26444332563088,
                    lat: 78.15295604116154
                }
            ],
            [
                {
                    lng: -110.96366065147602,
                    lat: 78.8044408230652
                },
                {
                    lng: -109.6631457182026,
                    lat: 78.60197256134565
                },
                {
                    lng: -110.88131425661892,
                    lat: 78.40691986765997
                },
                {
                    lng: -112.54209143761516,
                    lat: 78.4079017198735
                },
                {
                    lng: -112.52589087609164,
                    lat: 78.55055451121522
                },
                {
                    lng: -111.5000103422334,
                    lat: 78.8499935981305
                },
                {
                    lng: -110.96366065147602,
                    lat: 78.8044408230652
                }
            ],
            [
                {
                    lng: -55.600218268442056,
                    lat: 51.31707469339794
                },
                {
                    lng: -56.13403581401709,
                    lat: 50.68700979267928
                },
                {
                    lng: -56.795881720595276,
                    lat: 49.81230866149089
                },
                {
                    lng: -56.14310502788433,
                    lat: 50.15011749938286
                },
                {
                    lng: -55.471492275603,
                    lat: 49.93581533466846
                },
                {
                    lng: -55.82240108908096,
                    lat: 49.58712860777905
                },
                {
                    lng: -54.935142584845636,
                    lat: 49.3130109726868
                },
                {
                    lng: -54.473775397343786,
                    lat: 49.556691189159125
                },
                {
                    lng: -53.47654944519137,
                    lat: 49.24913890237404
                },
                {
                    lng: -53.786013759971254,
                    lat: 48.516780503933624
                },
                {
                    lng: -53.08613399922626,
                    lat: 48.68780365660358
                },
                {
                    lng: -52.958648240762216,
                    lat: 48.15716421161447
                },
                {
                    lng: -52.64809872090421,
                    lat: 47.53554840757552
                },
                {
                    lng: -53.069158291218386,
                    lat: 46.65549876564492
                },
                {
                    lng: -53.521456264853,
                    lat: 46.61829173439477
                },
                {
                    lng: -54.17893551290251,
                    lat: 46.80706574155698
                },
                {
                    lng: -53.9618686590605,
                    lat: 47.62520701760193
                },
                {
                    lng: -54.24048214376214,
                    lat: 47.752279364607645
                },
                {
                    lng: -55.40077307801157,
                    lat: 46.884993801453135
                },
                {
                    lng: -55.99748084168583,
                    lat: 46.919720363953275
                },
                {
                    lng: -55.29121904155279,
                    lat: 47.38956248635099
                },
                {
                    lng: -56.250798712780586,
                    lat: 47.632545070987376
                },
                {
                    lng: -57.32522925477708,
                    lat: 47.57280711525797
                },
                {
                    lng: -59.26601518414682,
                    lat: 47.60334788674247
                },
                {
                    lng: -59.419494188053676,
                    lat: 47.899453843774886
                },
                {
                    lng: -58.79658647320744,
                    lat: 48.25152537697942
                },
                {
                    lng: -59.23162451845657,
                    lat: 48.52318838153781
                },
                {
                    lng: -58.3918049790652,
                    lat: 49.12558055276418
                },
                {
                    lng: -57.35868974468606,
                    lat: 50.71827403421587
                },
                {
                    lng: -56.738650071832026,
                    lat: 51.28743825947855
                },
                {
                    lng: -55.87097693543532,
                    lat: 51.63209422464921
                },
                {
                    lng: -55.40697424988659,
                    lat: 51.5882726100657
                },
                {
                    lng: -55.600218268442056,
                    lat: 51.31707469339794
                }
            ],
            [
                {
                    lng: -83.88262630891977,
                    lat: 65.10961782496354
                },
                {
                    lng: -82.78757687043883,
                    lat: 64.76669302027467
                },
                {
                    lng: -81.6420137193926,
                    lat: 64.45513580998697
                },
                {
                    lng: -81.55344031444432,
                    lat: 63.97960928003714
                },
                {
                    lng: -80.81736121287886,
                    lat: 64.057485663501
                },
                {
                    lng: -80.10345130076664,
                    lat: 63.72598135034862
                },
                {
                    lng: -80.99101986359572,
                    lat: 63.41124603947496
                },
                {
                    lng: -82.54717810741704,
                    lat: 63.65172231714521
                },
                {
                    lng: -83.10879757356511,
                    lat: 64.10187571883971
                },
                {
                    lng: -84.10041663281388,
                    lat: 63.569711819098
                },
                {
                    lng: -85.52340471061905,
                    lat: 63.052379055424055
                },
                {
                    lng: -85.8667687649824,
                    lat: 63.63725291610349
                },
                {
                    lng: -87.22198320183678,
                    lat: 63.54123810490519
                },
                {
                    lng: -86.35275977247133,
                    lat: 64.0358332383707
                },
                {
                    lng: -86.2248864407651,
                    lat: 64.82291697860823
                },
                {
                    lng: -85.88384782585486,
                    lat: 65.7387783881171
                },
                {
                    lng: -85.1613079495499,
                    lat: 65.6572846543928
                },
                {
                    lng: -84.97576371940592,
                    lat: 65.21751821558898
                },
                {
                    lng: -84.4640120104195,
                    lat: 65.37177236598022
                },
                {
                    lng: -83.88262630891977,
                    lat: 65.10961782496354
                }
            ],
            [
                {
                    lng: -78.77063859731078,
                    lat: 72.35217316353418
                },
                {
                    lng: -77.8246239895596,
                    lat: 72.74961660429098
                },
                {
                    lng: -75.60584469267573,
                    lat: 72.2436784939374
                },
                {
                    lng: -74.228616095665,
                    lat: 71.76714427355789
                },
                {
                    lng: -74.09914079455771,
                    lat: 71.33084015571758
                },
                {
                    lng: -72.24222571479768,
                    lat: 71.55692454699452
                },
                {
                    lng: -71.20001542833518,
                    lat: 70.92001251899718
                },
                {
                    lng: -68.7860542466849,
                    lat: 70.52502370877427
                },
                {
                    lng: -67.91497046575694,
                    lat: 70.12194753689765
                },
                {
                    lng: -66.9690333726542,
                    lat: 69.18608734809182
                },
                {
                    lng: -68.8051228502006,
                    lat: 68.72019847276444
                },
                {
                    lng: -66.4498660956339,
                    lat: 68.06716339789203
                },
                {
                    lng: -64.86231441919524,
                    lat: 67.84753856065159
                },
                {
                    lng: -63.424934454996794,
                    lat: 66.92847321234059
                },
                {
                    lng: -61.851981370680605,
                    lat: 66.86212067327783
                },
                {
                    lng: -62.16317684594226,
                    lat: 66.16025136988962
                },
                {
                    lng: -63.918444383384184,
                    lat: 64.9986685248329
                },
                {
                    lng: -65.14886023625368,
                    lat: 65.42603261988667
                },
                {
                    lng: -66.72121904159852,
                    lat: 66.38804108343219
                },
                {
                    lng: -68.015016038674,
                    lat: 66.26272573512439
                },
                {
                    lng: -68.1412874009792,
                    lat: 65.68978913030439
                },
                {
                    lng: -67.08964616562342,
                    lat: 65.10845510523696
                },
                {
                    lng: -65.73208045109976,
                    lat: 64.64840566675856
                },
                {
                    lng: -65.32016760930125,
                    lat: 64.38273712834605
                },
                {
                    lng: -64.66940629744968,
                    lat: 63.392926744227495
                },
                {
                    lng: -65.01380388045888,
                    lat: 62.67418508569598
                },
                {
                    lng: -66.27504472519048,
                    lat: 62.94509878198612
                },
                {
                    lng: -68.7831862046927,
                    lat: 63.74567007105183
                },
                {
                    lng: -67.36968075221309,
                    lat: 62.88396556258484
                },
                {
                    lng: -66.32829728866726,
                    lat: 62.28007477482201
                },
                {
                    lng: -66.16556820338015,
                    lat: 61.93089712182582
                },
                {
                    lng: -68.87736650254465,
                    lat: 62.330149237712824
                },
                {
                    lng: -71.02343705919385,
                    lat: 62.91070811629588
                },
                {
                    lng: -72.23537858751902,
                    lat: 63.39783600529522
                },
                {
                    lng: -71.88627844917127,
                    lat: 63.67998932560887
                },
                {
                    lng: -73.37830624051838,
                    lat: 64.19396312118384
                },
                {
                    lng: -74.83441891142263,
                    lat: 64.6790756293238
                },
                {
                    lng: -74.81850257027673,
                    lat: 64.38909332951793
                },
                {
                    lng: -77.70997982452008,
                    lat: 64.22954234481678
                },
                {
                    lng: -78.5559488593542,
                    lat: 64.57290639918013
                },
                {
                    lng: -77.89728105336198,
                    lat: 65.30919220647475
                },
                {
                    lng: -76.01827429879717,
                    lat: 65.32696889918314
                },
                {
                    lng: -73.95979529488268,
                    lat: 65.45476471624094
                },
                {
                    lng: -74.29388342964964,
                    lat: 65.81177134872938
                },
                {
                    lng: -73.94491248238262,
                    lat: 66.31057811142666
                },
                {
                    lng: -72.65116716173942,
                    lat: 67.28457550726391
                },
                {
                    lng: -72.92605994331605,
                    lat: 67.72692576768235
                },
                {
                    lng: -73.31161780464572,
                    lat: 68.06943716091287
                },
                {
                    lng: -74.84330725777684,
                    lat: 68.55462718370127
                },
                {
                    lng: -76.86910091826672,
                    lat: 68.89473562283025
                },
                {
                    lng: -76.22864905465738,
                    lat: 69.14776927354741
                },
                {
                    lng: -77.28736996123715,
                    lat: 69.76954010688321
                },
                {
                    lng: -78.1686339993266,
                    lat: 69.82648753526887
                },
                {
                    lng: -78.95724219431673,
                    lat: 70.16688019477543
                },
                {
                    lng: -79.49245500356366,
                    lat: 69.87180776638884
                },
                {
                    lng: -81.30547095409176,
                    lat: 69.74318512641436
                },
                {
                    lng: -84.94470618359851,
                    lat: 69.96663401964442
                },
                {
                    lng: -87.06000342481789,
                    lat: 70.26000112576538
                },
                {
                    lng: -88.68171322300148,
                    lat: 70.4107412787608
                },
                {
                    lng: -89.51341956252303,
                    lat: 70.76203766548095
                },
                {
                    lng: -88.46772111688082,
                    lat: 71.21818553332132
                },
                {
                    lng: -89.88815121128755,
                    lat: 71.22255219184997
                },
                {
                    lng: -90.20516028518205,
                    lat: 72.23507436796079
                },
                {
                    lng: -89.436576707705,
                    lat: 73.12946421985238
                },
                {
                    lng: -88.40824154331287,
                    lat: 73.53788890247121
                },
                {
                    lng: -85.82615108920098,
                    lat: 73.80381582304518
                },
                {
                    lng: -86.56217851433412,
                    lat: 73.15744700793844
                },
                {
                    lng: -85.77437130404454,
                    lat: 72.53412588163387
                },
                {
                    lng: -84.85011247428822,
                    lat: 73.34027822538708
                },
                {
                    lng: -82.31559017610101,
                    lat: 73.7509508328106
                },
                {
                    lng: -80.60008765330768,
                    lat: 72.71654368762417
                },
                {
                    lng: -80.74894161652443,
                    lat: 72.06190664335072
                },
                {
                    lng: -78.77063859731078,
                    lat: 72.35217316353418
                }
            ],
            [
                {
                    lng: -94.50365759965237,
                    lat: 74.13490672473922
                },
                {
                    lng: -92.42001217321173,
                    lat: 74.1000251329422
                },
                {
                    lng: -90.50979285354263,
                    lat: 73.85673248971206
                },
                {
                    lng: -92.00396521682987,
                    lat: 72.96624420845852
                },
                {
                    lng: -93.19629553910026,
                    lat: 72.77199249947334
                },
                {
                    lng: -94.26904659704726,
                    lat: 72.02459625923599
                },
                {
                    lng: -95.40985551632266,
                    lat: 72.06188080513458
                },
                {
                    lng: -96.03374508338244,
                    lat: 72.94027680123183
                },
                {
                    lng: -96.01826799191102,
                    lat: 73.43742991809582
                },
                {
                    lng: -95.49579342322404,
                    lat: 73.86241689726417
                },
                {
                    lng: -94.50365759965237,
                    lat: 74.13490672473922
                }
            ],
            [
                {
                    lng: -122.85492448615902,
                    lat: 76.11654287383568
                },
                {
                    lng: -122.85492529360326,
                    lat: 76.11654287383568
                },
                {
                    lng: -121.15753536032824,
                    lat: 76.86450755482828
                },
                {
                    lng: -119.1039389718211,
                    lat: 77.51221995717462
                },
                {
                    lng: -117.570130784966,
                    lat: 77.4983189968881
                },
                {
                    lng: -116.19858659550738,
                    lat: 77.6452867703262
                },
                {
                    lng: -116.33581336145845,
                    lat: 76.87696157501061
                },
                {
                    lng: -117.10605058476882,
                    lat: 76.53003184681911
                },
                {
                    lng: -118.04041215703819,
                    lat: 76.48117178008714
                },
                {
                    lng: -119.89931758688572,
                    lat: 76.053213406062
                },
                {
                    lng: -121.49999507712648,
                    lat: 75.90001862253276
                },
                {
                    lng: -122.85492448615902,
                    lat: 76.11654287383568
                }
            ],
            [
                {
                    lng: -132.71000788443126,
                    lat: 54.04000931542356
                },
                {
                    lng: -131.74998958400334,
                    lat: 54.12000438090922
                },
                {
                    lng: -132.049480347351,
                    lat: 52.98462148702447
                },
                {
                    lng: -131.1790425218266,
                    lat: 52.180432847698285
                },
                {
                    lng: -131.57782954982298,
                    lat: 52.18237071390928
                },
                {
                    lng: -132.18042842677852,
                    lat: 52.639707139692405
                },
                {
                    lng: -132.54999243231384,
                    lat: 53.100014960332146
                },
                {
                    lng: -133.05461117875552,
                    lat: 53.411468817755406
                },
                {
                    lng: -133.2396644827927,
                    lat: 53.851080227262344
                },
                {
                    lng: -133.1800040417117,
                    lat: 54.169975490935315
                },
                {
                    lng: -132.71000788443126,
                    lat: 54.04000931542356
                }
            ],
            [
                {
                    lng: -105.4922891914932,
                    lat: 79.30159393992916
                },
                {
                    lng: -103.52928239623795,
                    lat: 79.16534902619163
                },
                {
                    lng: -100.8251580472688,
                    lat: 78.80046173777872
                },
                {
                    lng: -100.0601918200522,
                    lat: 78.32475434031589
                },
                {
                    lng: -99.67093909381364,
                    lat: 77.90754466420744
                },
                {
                    lng: -101.30394019245301,
                    lat: 78.01898489044486
                },
                {
                    lng: -102.94980872273302,
                    lat: 78.34322866486023
                },
                {
                    lng: -105.17613277873151,
                    lat: 78.3803323432458
                },
                {
                    lng: -104.21042945027713,
                    lat: 78.67742015249176
                },
                {
                    lng: -105.41958045125853,
                    lat: 78.91833567983649
                },
                {
                    lng: -105.4922891914932,
                    lat: 79.30159393992916
                }
            ],
            [
                {
                    lng: -123.51000158755119,
                    lat: 48.51001089130341
                },
                {
                    lng: -124.01289078839955,
                    lat: 48.37084625914139
                },
                {
                    lng: -125.65501277733838,
                    lat: 48.8250045843385
                },
                {
                    lng: -125.95499446679275,
                    lat: 49.17999583596759
                },
                {
                    lng: -126.85000443587185,
                    lat: 49.53000031188043
                },
                {
                    lng: -127.02999344954443,
                    lat: 49.81499583597008
                },
                {
                    lng: -128.0593363043662,
                    lat: 49.9949590114266
                },
                {
                    lng: -128.44458410710214,
                    lat: 50.539137681676095
                },
                {
                    lng: -128.35841365625546,
                    lat: 50.77064809834371
                },
                {
                    lng: -127.30858109602994,
                    lat: 50.552573554071955
                },
                {
                    lng: -126.69500097721235,
                    lat: 50.400903225295394
                },
                {
                    lng: -125.7550066738232,
                    lat: 50.29501821552935
                },
                {
                    lng: -125.4150015875588,
                    lat: 49.95000051533259
                },
                {
                    lng: -124.92076818911934,
                    lat: 49.475274970083376
                },
                {
                    lng: -123.92250870832106,
                    lat: 49.06248362893581
                },
                {
                    lng: -123.51000158755119,
                    lat: 48.51001089130341
                }
            ],
            [
                {
                    lng: -121.53788,
                    lat: 74.44893
                },
                {
                    lng: -120.10978,
                    lat: 74.24135
                },
                {
                    lng: -117.55564,
                    lat: 74.18577
                },
                {
                    lng: -116.58442,
                    lat: 73.89607
                },
                {
                    lng: -115.51081,
                    lat: 73.47519
                },
                {
                    lng: -116.76794,
                    lat: 73.22292
                },
                {
                    lng: -119.22,
                    lat: 72.52
                },
                {
                    lng: -120.46,
                    lat: 71.82
                },
                {
                    lng: -120.46,
                    lat: 71.38360179308756
                },
                {
                    lng: -123.09219,
                    lat: 70.90164
                },
                {
                    lng: -123.62,
                    lat: 71.34
                },
                {
                    lng: -125.92894873747338,
                    lat: 71.86868846301138
                },
                {
                    lng: -125.5,
                    lat: 72.29226081179502
                },
                {
                    lng: -124.80729,
                    lat: 73.02256
                },
                {
                    lng: -123.94,
                    lat: 73.68000000000012
                },
                {
                    lng: -124.91775,
                    lat: 74.29275000000013
                },
                {
                    lng: -121.53788,
                    lat: 74.44893
                }
            ],
            [
                {
                    lng: -107.81943,
                    lat: 75.84552
                },
                {
                    lng: -106.92893,
                    lat: 76.01282
                },
                {
                    lng: -105.881,
                    lat: 75.9694
                },
                {
                    lng: -105.70498,
                    lat: 75.47951
                },
                {
                    lng: -106.31347,
                    lat: 75.00527
                },
                {
                    lng: -109.7,
                    lat: 74.85
                },
                {
                    lng: -112.22307,
                    lat: 74.41696
                },
                {
                    lng: -113.74381,
                    lat: 74.39427
                },
                {
                    lng: -113.87135,
                    lat: 74.72029
                },
                {
                    lng: -111.79421,
                    lat: 75.1625
                },
                {
                    lng: -116.31221,
                    lat: 75.04343
                },
                {
                    lng: -117.7104,
                    lat: 75.2222
                },
                {
                    lng: -116.34602,
                    lat: 76.19903
                },
                {
                    lng: -115.40487,
                    lat: 76.47887
                },
                {
                    lng: -112.59056,
                    lat: 76.14134
                },
                {
                    lng: -110.81422,
                    lat: 75.54919
                },
                {
                    lng: -109.0671,
                    lat: 75.47321
                },
                {
                    lng: -110.49726,
                    lat: 76.42982
                },
                {
                    lng: -109.5811,
                    lat: 76.79417
                },
                {
                    lng: -108.54859,
                    lat: 76.67832
                },
                {
                    lng: -108.21141,
                    lat: 76.20168
                },
                {
                    lng: -107.81943,
                    lat: 75.84552
                }
            ],
            [
                {
                    lng: -106.52259,
                    lat: 73.07601
                },
                {
                    lng: -105.40246,
                    lat: 72.67259
                },
                {
                    lng: -104.77484,
                    lat: 71.6984000000001
                },
                {
                    lng: -104.4647599999999,
                    lat: 70.99297
                },
                {
                    lng: -102.78537,
                    lat: 70.49776
                },
                {
                    lng: -100.98078,
                    lat: 70.02432
                },
                {
                    lng: -101.08929,
                    lat: 69.58447000000012
                },
                {
                    lng: -102.73116,
                    lat: 69.50402
                },
                {
                    lng: -102.09329,
                    lat: 69.11962000000011
                },
                {
                    lng: -102.43024,
                    lat: 68.75282
                },
                {
                    lng: -104.24,
                    lat: 68.91
                },
                {
                    lng: -105.96,
                    lat: 69.18000000000012
                },
                {
                    lng: -107.12254,
                    lat: 69.11922
                },
                {
                    lng: -109,
                    lat: 68.78
                },
                {
                    lng: -111.53414887520017,
                    lat: 68.63005915681794
                },
                {
                    lng: -113.3132,
                    lat: 68.53554
                },
                {
                    lng: -113.85495999999989,
                    lat: 69.00744
                },
                {
                    lng: -115.22,
                    lat: 69.28
                },
                {
                    lng: -116.10794,
                    lat: 69.16821
                },
                {
                    lng: -117.34,
                    lat: 69.96
                },
                {
                    lng: -116.67473,
                    lat: 70.06655
                },
                {
                    lng: -115.13112,
                    lat: 70.2373
                },
                {
                    lng: -113.72141,
                    lat: 70.19237
                },
                {
                    lng: -112.4161,
                    lat: 70.36638
                },
                {
                    lng: -114.35,
                    lat: 70.6
                },
                {
                    lng: -116.48684,
                    lat: 70.52045
                },
                {
                    lng: -117.9048,
                    lat: 70.54056000000014
                },
                {
                    lng: -118.43238,
                    lat: 70.9092
                },
                {
                    lng: -116.11311,
                    lat: 71.30918
                },
                {
                    lng: -117.65568,
                    lat: 71.2952
                },
                {
                    lng: -119.40199,
                    lat: 71.55859
                },
                {
                    lng: -118.56267,
                    lat: 72.30785
                },
                {
                    lng: -117.86642,
                    lat: 72.70594
                },
                {
                    lng: -115.18909,
                    lat: 73.31459000000012
                },
                {
                    lng: -114.16717,
                    lat: 73.12145
                },
                {
                    lng: -114.66634,
                    lat: 72.65277
                },
                {
                    lng: -112.44102,
                    lat: 72.95540000000011
                },
                {
                    lng: -111.05039,
                    lat: 72.4504
                },
                {
                    lng: -109.92035,
                    lat: 72.96113
                },
                {
                    lng: -109.00654,
                    lat: 72.63335
                },
                {
                    lng: -108.18835,
                    lat: 71.65089
                },
                {
                    lng: -107.68599,
                    lat: 72.06548
                },
                {
                    lng: -108.39639,
                    lat: 73.08953
                },
                {
                    lng: -107.51645,
                    lat: 73.23598
                },
                {
                    lng: -106.52259,
                    lat: 73.07601
                }
            ],
            [
                {
                    lng: -100.43836,
                    lat: 72.70588
                },
                {
                    lng: -101.54,
                    lat: 73.36
                },
                {
                    lng: -100.35642,
                    lat: 73.84389
                },
                {
                    lng: -99.16387,
                    lat: 73.63339
                },
                {
                    lng: -97.38,
                    lat: 73.76
                },
                {
                    lng: -97.12,
                    lat: 73.47
                },
                {
                    lng: -98.05359,
                    lat: 72.99052
                },
                {
                    lng: -96.54,
                    lat: 72.56
                },
                {
                    lng: -96.72,
                    lat: 71.66
                },
                {
                    lng: -98.35966,
                    lat: 71.27285
                },
                {
                    lng: -99.32286,
                    lat: 71.35639
                },
                {
                    lng: -100.01482,
                    lat: 71.73827
                },
                {
                    lng: -102.5,
                    lat: 72.51
                },
                {
                    lng: -102.48,
                    lat: 72.83
                },
                {
                    lng: -100.43836,
                    lat: 72.70588
                }
            ],
            [
                {
                    lng: -106.6,
                    lat: 73.6
                },
                {
                    lng: -105.26,
                    lat: 73.64
                },
                {
                    lng: -104.5,
                    lat: 73.42
                },
                {
                    lng: -105.38,
                    lat: 72.76
                },
                {
                    lng: -106.94,
                    lat: 73.46
                },
                {
                    lng: -106.6,
                    lat: 73.6
                }
            ],
            [
                {
                    lng: -98.5,
                    lat: 76.72
                },
                {
                    lng: -97.735585,
                    lat: 76.25656
                },
                {
                    lng: -97.704415,
                    lat: 75.74344
                },
                {
                    lng: -98.16,
                    lat: 75
                },
                {
                    lng: -99.80874,
                    lat: 74.89744
                },
                {
                    lng: -100.88366,
                    lat: 75.05736
                },
                {
                    lng: -100.86292,
                    lat: 75.64075
                },
                {
                    lng: -102.50209,
                    lat: 75.5638
                },
                {
                    lng: -102.56552,
                    lat: 76.3366
                },
                {
                    lng: -101.48973,
                    lat: 76.30537
                },
                {
                    lng: -99.98349,
                    lat: 76.64634
                },
                {
                    lng: -98.57699,
                    lat: 76.58859
                },
                {
                    lng: -98.5,
                    lat: 76.72
                }
            ],
            [
                {
                    lng: -96.01644,
                    lat: 80.60233
                },
                {
                    lng: -95.32345,
                    lat: 80.90729
                },
                {
                    lng: -94.29843,
                    lat: 80.97727
                },
                {
                    lng: -94.73542,
                    lat: 81.20646
                },
                {
                    lng: -92.40984,
                    lat: 81.25739
                },
                {
                    lng: -91.13289,
                    lat: 80.72345
                },
                {
                    lng: -89.45,
                    lat: 80.50932203389831
                },
                {
                    lng: -87.81,
                    lat: 80.32
                },
                {
                    lng: -87.02,
                    lat: 79.66
                },
                {
                    lng: -85.81435,
                    lat: 79.3369
                },
                {
                    lng: -87.18756,
                    lat: 79.0393
                },
                {
                    lng: -89.03535,
                    lat: 78.28723
                },
                {
                    lng: -90.80436,
                    lat: 78.21533
                },
                {
                    lng: -92.87669,
                    lat: 78.34333
                },
                {
                    lng: -93.95116,
                    lat: 78.75099
                },
                {
                    lng: -93.93574,
                    lat: 79.11373
                },
                {
                    lng: -93.14524,
                    lat: 79.3801
                },
                {
                    lng: -94.974,
                    lat: 79.37248
                },
                {
                    lng: -96.07614,
                    lat: 79.70502
                },
                {
                    lng: -96.70972,
                    lat: 80.15777
                },
                {
                    lng: -96.01644,
                    lat: 80.60233
                }
            ],
            [
                {
                    lng: -91.58702,
                    lat: 81.89429
                },
                {
                    lng: -90.1,
                    lat: 82.085
                },
                {
                    lng: -88.93227,
                    lat: 82.11751
                },
                {
                    lng: -86.97024,
                    lat: 82.27961
                },
                {
                    lng: -85.5,
                    lat: 82.65227345805702
                },
                {
                    lng: -84.260005,
                    lat: 82.6
                },
                {
                    lng: -83.18,
                    lat: 82.32
                },
                {
                    lng: -82.42,
                    lat: 82.86
                },
                {
                    lng: -81.1,
                    lat: 83.02
                },
                {
                    lng: -79.30664,
                    lat: 83.13056
                },
                {
                    lng: -76.25,
                    lat: 83.17205882352941
                },
                {
                    lng: -75.71878,
                    lat: 83.06404
                },
                {
                    lng: -72.83153,
                    lat: 83.23324
                },
                {
                    lng: -70.665765,
                    lat: 83.16978075838284
                },
                {
                    lng: -68.5,
                    lat: 83.10632151676572
                },
                {
                    lng: -65.82735,
                    lat: 83.02801
                },
                {
                    lng: -63.68,
                    lat: 82.9
                },
                {
                    lng: -61.85,
                    lat: 82.6286
                },
                {
                    lng: -61.89388,
                    lat: 82.36165
                },
                {
                    lng: -64.334,
                    lat: 81.92775
                },
                {
                    lng: -66.75342,
                    lat: 81.72527
                },
                {
                    lng: -67.65755,
                    lat: 81.50141
                },
                {
                    lng: -65.48031,
                    lat: 81.50657
                },
                {
                    lng: -67.84,
                    lat: 80.9
                },
                {
                    lng: -69.4697,
                    lat: 80.61683
                },
                {
                    lng: -71.18,
                    lat: 79.8
                },
                {
                    lng: -73.2428,
                    lat: 79.63415
                },
                {
                    lng: -73.88,
                    lat: 79.43016220480206
                },
                {
                    lng: -76.90773,
                    lat: 79.32309
                },
                {
                    lng: -75.52924,
                    lat: 79.19766
                },
                {
                    lng: -76.22046,
                    lat: 79.01907
                },
                {
                    lng: -75.39345,
                    lat: 78.52581
                },
                {
                    lng: -76.34354,
                    lat: 78.18296
                },
                {
                    lng: -77.88851,
                    lat: 77.89991
                },
                {
                    lng: -78.36269,
                    lat: 77.50859
                },
                {
                    lng: -79.75951,
                    lat: 77.20968
                },
                {
                    lng: -79.61965,
                    lat: 76.98336
                },
                {
                    lng: -77.91089,
                    lat: 77.022045
                },
                {
                    lng: -77.88911,
                    lat: 76.777955
                },
                {
                    lng: -80.56125,
                    lat: 76.17812
                },
                {
                    lng: -83.17439,
                    lat: 76.45403
                },
                {
                    lng: -86.11184,
                    lat: 76.29901
                },
                {
                    lng: -87.6,
                    lat: 76.42
                },
                {
                    lng: -89.49068,
                    lat: 76.47239
                },
                {
                    lng: -89.6161,
                    lat: 76.95213
                },
                {
                    lng: -87.76739,
                    lat: 77.17833
                },
                {
                    lng: -88.26,
                    lat: 77.9
                },
                {
                    lng: -87.65,
                    lat: 77.97022222222223
                },
                {
                    lng: -84.97634,
                    lat: 77.53873
                },
                {
                    lng: -86.34,
                    lat: 78.18
                },
                {
                    lng: -87.96192,
                    lat: 78.37181
                },
                {
                    lng: -87.15198,
                    lat: 78.75867
                },
                {
                    lng: -85.37868,
                    lat: 78.9969
                },
                {
                    lng: -85.09495,
                    lat: 79.34543
                },
                {
                    lng: -86.50734,
                    lat: 79.73624
                },
                {
                    lng: -86.93179,
                    lat: 80.25145
                },
                {
                    lng: -84.19844,
                    lat: 80.20836
                },
                {
                    lng: -83.40869565217389,
                    lat: 80.1
                },
                {
                    lng: -81.84823,
                    lat: 80.46442
                },
                {
                    lng: -84.1,
                    lat: 80.58
                },
                {
                    lng: -87.59895,
                    lat: 80.51627
                },
                {
                    lng: -89.36663,
                    lat: 80.85569
                },
                {
                    lng: -90.2,
                    lat: 81.26
                },
                {
                    lng: -91.36786,
                    lat: 81.5531
                },
                {
                    lng: -91.58702,
                    lat: 81.89429
                }
            ],
            [
                {
                    lng: -75.21597,
                    lat: 67.44425
                },
                {
                    lng: -75.86588,
                    lat: 67.14886
                },
                {
                    lng: -76.98687,
                    lat: 67.09873
                },
                {
                    lng: -77.2364,
                    lat: 67.58809
                },
                {
                    lng: -76.81166,
                    lat: 68.14856
                },
                {
                    lng: -75.89521,
                    lat: 68.28721
                },
                {
                    lng: -75.1145,
                    lat: 68.01036
                },
                {
                    lng: -75.10333,
                    lat: 67.58202
                },
                {
                    lng: -75.21597,
                    lat: 67.44425
                }
            ],
            [
                {
                    lng: -96.25740120380055,
                    lat: 69.49003035832177
                },
                {
                    lng: -95.64768120380054,
                    lat: 69.10769035832178
                },
                {
                    lng: -96.26952120380055,
                    lat: 68.75704035832177
                },
                {
                    lng: -97.61740120380055,
                    lat: 69.06003035832177
                },
                {
                    lng: -98.43180120380055,
                    lat: 68.95070035832177
                },
                {
                    lng: -99.79740120380055,
                    lat: 69.40003035832177
                },
                {
                    lng: -98.91740120380055,
                    lat: 69.71003035832177
                },
                {
                    lng: -98.21826120380055,
                    lat: 70.14354035832177
                },
                {
                    lng: -97.15740120380055,
                    lat: 69.86003035832177
                },
                {
                    lng: -96.55740120380055,
                    lat: 69.68003035832177
                },
                {
                    lng: -96.25740120380055,
                    lat: 69.49003035832177
                }
            ],
            [
                {
                    lng: -64.51912,
                    lat: 49.87304
                },
                {
                    lng: -64.17322,
                    lat: 49.95718
                },
                {
                    lng: -62.85829,
                    lat: 49.70641
                },
                {
                    lng: -61.835585,
                    lat: 49.28855
                },
                {
                    lng: -61.806305,
                    lat: 49.10506
                },
                {
                    lng: -62.29318,
                    lat: 49.08717
                },
                {
                    lng: -63.58926,
                    lat: 49.40069
                },
                {
                    lng: -64.51912,
                    lat: 49.87304
                }
            ],
            [
                {
                    lng: -64.01486,
                    lat: 47.03601
                },
                {
                    lng: -63.6645,
                    lat: 46.55001
                },
                {
                    lng: -62.9393,
                    lat: 46.41587
                },
                {
                    lng: -62.01208,
                    lat: 46.44314
                },
                {
                    lng: -62.50391,
                    lat: 46.03339
                },
                {
                    lng: -62.87433,
                    lat: 45.96818
                },
                {
                    lng: -64.1428,
                    lat: 46.39265
                },
                {
                    lng: -64.39261,
                    lat: 46.72747
                },
                {
                    lng: -64.01486,
                    lat: 47.03601
                }
            ]
        ]
    },
    US: {
        name: 'United States of America',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: -122.84,
                    lat: 49.000000000000114
                },
                {
                    lng: -120,
                    lat: 49.000000000000114
                },
                {
                    lng: -117.03121,
                    lat: 49
                },
                {
                    lng: -116.04818,
                    lat: 49
                },
                {
                    lng: -113,
                    lat: 49
                },
                {
                    lng: -110.05,
                    lat: 49
                },
                {
                    lng: -107.05,
                    lat: 49
                },
                {
                    lng: -104.04826,
                    lat: 48.99986
                },
                {
                    lng: -100.65,
                    lat: 49.000000000000114
                },
                {
                    lng: -97.2287200000048,
                    lat: 49.0007
                },
                {
                    lng: -95.15906950917206,
                    lat: 49
                },
                {
                    lng: -95.15609,
                    lat: 49.38425
                },
                {
                    lng: -94.81758,
                    lat: 49.38905
                },
                {
                    lng: -94.64,
                    lat: 48.84
                },
                {
                    lng: -94.32914,
                    lat: 48.67074
                },
                {
                    lng: -93.63087,
                    lat: 48.60926
                },
                {
                    lng: -92.61,
                    lat: 48.45
                },
                {
                    lng: -91.64,
                    lat: 48.14
                },
                {
                    lng: -90.83,
                    lat: 48.27
                },
                {
                    lng: -89.6,
                    lat: 48.010000000000105
                },
                {
                    lng: -89.27291744663665,
                    lat: 48.01980825458281
                },
                {
                    lng: -88.37811418328671,
                    lat: 48.302917588893706
                },
                {
                    lng: -87.43979262330028,
                    lat: 47.94
                },
                {
                    lng: -86.46199083122826,
                    lat: 47.553338019392
                },
                {
                    lng: -85.65236324740341,
                    lat: 47.22021881773051
                },
                {
                    lng: -84.8760798815149,
                    lat: 46.90008331968238
                },
                {
                    lng: -84.77923824739992,
                    lat: 46.63710195574902
                },
                {
                    lng: -84.54374874544584,
                    lat: 46.538684190449146
                },
                {
                    lng: -84.6049,
                    lat: 46.4396
                },
                {
                    lng: -84.3367,
                    lat: 46.40877
                },
                {
                    lng: -84.1421195136734,
                    lat: 46.51222585711571
                },
                {
                    lng: -84.0918512641615,
                    lat: 46.27541860613826
                },
                {
                    lng: -83.89076534700574,
                    lat: 46.116926988299014
                },
                {
                    lng: -83.61613094759059,
                    lat: 46.116926988299014
                },
                {
                    lng: -83.46955074739469,
                    lat: 45.994686387712534
                },
                {
                    lng: -83.59285071484311,
                    lat: 45.81689362241252
                },
                {
                    lng: -82.55092464875821,
                    lat: 45.34751658790543
                },
                {
                    lng: -82.33776312543114,
                    lat: 44.44
                },
                {
                    lng: -82.13764238150395,
                    lat: 43.57108755143997
                },
                {
                    lng: -82.43,
                    lat: 42.98
                },
                {
                    lng: -82.9,
                    lat: 42.43
                },
                {
                    lng: -83.12,
                    lat: 42.08
                },
                {
                    lng: -83.14199968131264,
                    lat: 41.975681057292874
                },
                {
                    lng: -83.029810146807,
                    lat: 41.83279572200598
                },
                {
                    lng: -82.69008928092023,
                    lat: 41.675105088867326
                },
                {
                    lng: -82.4392777167916,
                    lat: 41.675105088867326
                },
                {
                    lng: -81.27774654816716,
                    lat: 42.209025987306816
                },
                {
                    lng: -80.24744767934794,
                    lat: 42.36619985612255
                },
                {
                    lng: -78.93936214874375,
                    lat: 42.86361135514798
                },
                {
                    lng: -78.92,
                    lat: 42.965
                },
                {
                    lng: -79.01,
                    lat: 43.27
                },
                {
                    lng: -79.17167355011186,
                    lat: 43.46633942318426
                },
                {
                    lng: -78.72027991404235,
                    lat: 43.62508942318493
                },
                {
                    lng: -77.7378850979577,
                    lat: 43.62905558936328
                },
                {
                    lng: -76.82003414580558,
                    lat: 43.628784288093755
                },
                {
                    lng: -76.5,
                    lat: 44.01845889375865
                },
                {
                    lng: -76.375,
                    lat: 44.09631
                },
                {
                    lng: -75.31821,
                    lat: 44.81645
                },
                {
                    lng: -74.867,
                    lat: 45.00048
                },
                {
                    lng: -73.34783,
                    lat: 45.00738
                },
                {
                    lng: -71.50506,
                    lat: 45.0082
                },
                {
                    lng: -71.405,
                    lat: 45.255
                },
                {
                    lng: -71.08482,
                    lat: 45.30524000000014
                },
                {
                    lng: -70.66,
                    lat: 45.46
                },
                {
                    lng: -70.305,
                    lat: 45.915
                },
                {
                    lng: -69.99997,
                    lat: 46.69307
                },
                {
                    lng: -69.237216,
                    lat: 47.447781
                },
                {
                    lng: -68.905,
                    lat: 47.185
                },
                {
                    lng: -68.23444,
                    lat: 47.35486
                },
                {
                    lng: -67.79046,
                    lat: 47.06636
                },
                {
                    lng: -67.79134,
                    lat: 45.70281
                },
                {
                    lng: -67.13741,
                    lat: 45.13753
                },
                {
                    lng: -66.96466,
                    lat: 44.809700000000134
                },
                {
                    lng: -68.03252,
                    lat: 44.3252
                },
                {
                    lng: -69.06,
                    lat: 43.98
                },
                {
                    lng: -70.11617,
                    lat: 43.68405000000013
                },
                {
                    lng: -70.64547563341102,
                    lat: 43.09023834896402
                },
                {
                    lng: -70.81489,
                    lat: 42.8653
                },
                {
                    lng: -70.825,
                    lat: 42.335
                },
                {
                    lng: -70.495,
                    lat: 41.805
                },
                {
                    lng: -70.08,
                    lat: 41.78
                },
                {
                    lng: -70.185,
                    lat: 42.145
                },
                {
                    lng: -69.88497,
                    lat: 41.92283
                },
                {
                    lng: -69.96503,
                    lat: 41.63717000000014
                },
                {
                    lng: -70.64,
                    lat: 41.475
                },
                {
                    lng: -71.12039,
                    lat: 41.49445000000014
                },
                {
                    lng: -71.86,
                    lat: 41.32
                },
                {
                    lng: -72.295,
                    lat: 41.27
                },
                {
                    lng: -72.87643,
                    lat: 41.22065
                },
                {
                    lng: -73.71,
                    lat: 40.93110235165449
                },
                {
                    lng: -72.24126,
                    lat: 41.119480000000124
                },
                {
                    lng: -71.94499999999988,
                    lat: 40.93
                },
                {
                    lng: -73.345,
                    lat: 40.63
                },
                {
                    lng: -73.982,
                    lat: 40.628
                },
                {
                    lng: -73.952325,
                    lat: 40.75075
                },
                {
                    lng: -74.25671,
                    lat: 40.47351
                },
                {
                    lng: -73.96244,
                    lat: 40.42763
                },
                {
                    lng: -74.17838,
                    lat: 39.70926
                },
                {
                    lng: -74.90604,
                    lat: 38.93954
                },
                {
                    lng: -74.98041,
                    lat: 39.1964
                },
                {
                    lng: -75.20002,
                    lat: 39.248450000000105
                },
                {
                    lng: -75.52805,
                    lat: 39.4985
                },
                {
                    lng: -75.32,
                    lat: 38.96
                },
                {
                    lng: -75.07183476478986,
                    lat: 38.782032230179254
                },
                {
                    lng: -75.05673,
                    lat: 38.40412
                },
                {
                    lng: -75.37747,
                    lat: 38.01551
                },
                {
                    lng: -75.94023,
                    lat: 37.21689
                },
                {
                    lng: -76.03127,
                    lat: 37.2566
                },
                {
                    lng: -75.72204999999985,
                    lat: 37.93705000000011
                },
                {
                    lng: -76.23287,
                    lat: 38.319215
                },
                {
                    lng: -76.35,
                    lat: 39.15
                },
                {
                    lng: -76.542725,
                    lat: 38.717615
                },
                {
                    lng: -76.32933,
                    lat: 38.08326
                },
                {
                    lng: -76.98999793161352,
                    lat: 38.23999176691336
                },
                {
                    lng: -76.30162,
                    lat: 37.917945
                },
                {
                    lng: -76.25874,
                    lat: 36.9664
                },
                {
                    lng: -75.9718,
                    lat: 36.89726
                },
                {
                    lng: -75.8680399999999,
                    lat: 36.55125
                },
                {
                    lng: -75.72749,
                    lat: 35.55074000000013
                },
                {
                    lng: -76.36318,
                    lat: 34.80854000000011
                },
                {
                    lng: -77.397635,
                    lat: 34.51201
                },
                {
                    lng: -78.05496,
                    lat: 33.92547
                },
                {
                    lng: -78.55434999999989,
                    lat: 33.86133000000012
                },
                {
                    lng: -79.06067,
                    lat: 33.49395
                },
                {
                    lng: -79.20357,
                    lat: 33.15839
                },
                {
                    lng: -80.301325,
                    lat: 32.509355
                },
                {
                    lng: -80.86498,
                    lat: 32.0333
                },
                {
                    lng: -81.33629,
                    lat: 31.44049
                },
                {
                    lng: -81.49042,
                    lat: 30.7299900000001
                },
                {
                    lng: -81.31371,
                    lat: 30.03552
                },
                {
                    lng: -80.98,
                    lat: 29.18000000000012
                },
                {
                    lng: -80.535585,
                    lat: 28.47213
                },
                {
                    lng: -80.52999999999986,
                    lat: 28.04
                },
                {
                    lng: -80.05653928497759,
                    lat: 26.88000000000011
                },
                {
                    lng: -80.088015,
                    lat: 26.205765
                },
                {
                    lng: -80.13156,
                    lat: 25.816775
                },
                {
                    lng: -80.38103,
                    lat: 25.20616
                },
                {
                    lng: -80.68,
                    lat: 25.08
                },
                {
                    lng: -81.17213,
                    lat: 25.201260000000104
                },
                {
                    lng: -81.33,
                    lat: 25.64
                },
                {
                    lng: -81.70999999999987,
                    lat: 25.87
                },
                {
                    lng: -82.24,
                    lat: 26.730000000000132
                },
                {
                    lng: -82.70515,
                    lat: 27.49504
                },
                {
                    lng: -82.85526,
                    lat: 27.88624
                },
                {
                    lng: -82.65,
                    lat: 28.550000000000125
                },
                {
                    lng: -82.93,
                    lat: 29.1
                },
                {
                    lng: -83.70959,
                    lat: 29.93656
                },
                {
                    lng: -84.1,
                    lat: 30.09
                },
                {
                    lng: -85.10882,
                    lat: 29.63615
                },
                {
                    lng: -85.28784,
                    lat: 29.68612000000013
                },
                {
                    lng: -85.7731,
                    lat: 30.15261
                },
                {
                    lng: -86.4,
                    lat: 30.4
                },
                {
                    lng: -87.53036,
                    lat: 30.27433
                },
                {
                    lng: -88.41782,
                    lat: 30.3849
                },
                {
                    lng: -89.18049,
                    lat: 30.31598
                },
                {
                    lng: -89.5938311784198,
                    lat: 30.159994004836847
                },
                {
                    lng: -89.413735,
                    lat: 29.89419
                },
                {
                    lng: -89.43,
                    lat: 29.48864
                },
                {
                    lng: -89.21767,
                    lat: 29.29108
                },
                {
                    lng: -89.40823,
                    lat: 29.15961
                },
                {
                    lng: -89.77928,
                    lat: 29.307140000000118
                },
                {
                    lng: -90.15463,
                    lat: 29.11743
                },
                {
                    lng: -90.880225,
                    lat: 29.148535
                },
                {
                    lng: -91.626785,
                    lat: 29.677000000000135
                },
                {
                    lng: -92.49906,
                    lat: 29.5523
                },
                {
                    lng: -93.22637,
                    lat: 29.78375
                },
                {
                    lng: -93.84842,
                    lat: 29.71363
                },
                {
                    lng: -94.69,
                    lat: 29.480000000000132
                },
                {
                    lng: -95.60026,
                    lat: 28.73863
                },
                {
                    lng: -96.59404,
                    lat: 28.30748
                },
                {
                    lng: -97.13999999999987,
                    lat: 27.83
                },
                {
                    lng: -97.37,
                    lat: 27.38
                },
                {
                    lng: -97.38,
                    lat: 26.69
                },
                {
                    lng: -97.33,
                    lat: 26.21
                },
                {
                    lng: -97.13999999999987,
                    lat: 25.87
                },
                {
                    lng: -97.53,
                    lat: 25.84
                },
                {
                    lng: -98.24,
                    lat: 26.06
                },
                {
                    lng: -99.02,
                    lat: 26.37
                },
                {
                    lng: -99.3,
                    lat: 26.84
                },
                {
                    lng: -99.52,
                    lat: 27.54
                },
                {
                    lng: -100.11,
                    lat: 28.110000000000127
                },
                {
                    lng: -100.45584,
                    lat: 28.69612000000012
                },
                {
                    lng: -100.9576,
                    lat: 29.380710000000136
                },
                {
                    lng: -101.6624,
                    lat: 29.7793
                },
                {
                    lng: -102.48,
                    lat: 29.76
                },
                {
                    lng: -103.11,
                    lat: 28.97
                },
                {
                    lng: -103.94,
                    lat: 29.27
                },
                {
                    lng: -104.4569699999999,
                    lat: 29.57196
                },
                {
                    lng: -104.70575,
                    lat: 30.12173
                },
                {
                    lng: -105.03737,
                    lat: 30.64402
                },
                {
                    lng: -105.63159,
                    lat: 31.08383
                },
                {
                    lng: -106.1429,
                    lat: 31.39995
                },
                {
                    lng: -106.50759,
                    lat: 31.75452
                },
                {
                    lng: -108.24,
                    lat: 31.754853718166373
                },
                {
                    lng: -108.24194,
                    lat: 31.34222
                },
                {
                    lng: -109.035,
                    lat: 31.341940000000136
                },
                {
                    lng: -111.02361,
                    lat: 31.33472
                },
                {
                    lng: -113.30498,
                    lat: 32.03914
                },
                {
                    lng: -114.815,
                    lat: 32.52528
                },
                {
                    lng: -114.72139,
                    lat: 32.72083
                },
                {
                    lng: -115.99135,
                    lat: 32.61239000000012
                },
                {
                    lng: -117.12775999999985,
                    lat: 32.53534
                },
                {
                    lng: -117.29593769127393,
                    lat: 33.04622461520387
                },
                {
                    lng: -117.944,
                    lat: 33.621236431201396
                },
                {
                    lng: -118.41060227589753,
                    lat: 33.74090922312445
                },
                {
                    lng: -118.51989482279976,
                    lat: 34.02778157757575
                },
                {
                    lng: -119.081,
                    lat: 34.078
                },
                {
                    lng: -119.43884064201671,
                    lat: 34.34847717828427
                },
                {
                    lng: -120.36778,
                    lat: 34.44711
                },
                {
                    lng: -120.62286,
                    lat: 34.60855
                },
                {
                    lng: -120.74433,
                    lat: 35.15686000000011
                },
                {
                    lng: -121.71457,
                    lat: 36.16153
                },
                {
                    lng: -122.54747,
                    lat: 37.551760000000115
                },
                {
                    lng: -122.51201,
                    lat: 37.78339000000011
                },
                {
                    lng: -122.95319,
                    lat: 38.11371
                },
                {
                    lng: -123.7272,
                    lat: 38.95166000000012
                },
                {
                    lng: -123.86517,
                    lat: 39.76699
                },
                {
                    lng: -124.39807,
                    lat: 40.3132
                },
                {
                    lng: -124.17886,
                    lat: 41.142020000000116
                },
                {
                    lng: -124.2137,
                    lat: 41.99964000000011
                },
                {
                    lng: -124.53284,
                    lat: 42.7659900000001
                },
                {
                    lng: -124.14214,
                    lat: 43.70838
                },
                {
                    lng: -124.020535,
                    lat: 44.615895
                },
                {
                    lng: -123.89893,
                    lat: 45.52341
                },
                {
                    lng: -124.079635,
                    lat: 46.86475
                },
                {
                    lng: -124.39567,
                    lat: 47.72017000000011
                },
                {
                    lng: -124.68721008300781,
                    lat: 48.18443298339855
                },
                {
                    lng: -124.56610107421875,
                    lat: 48.37971496582037
                },
                {
                    lng: -123.12,
                    lat: 48.04
                },
                {
                    lng: -122.58736,
                    lat: 47.096
                },
                {
                    lng: -122.34,
                    lat: 47.36
                },
                {
                    lng: -122.5,
                    lat: 48.18
                },
                {
                    lng: -122.84,
                    lat: 49.000000000000114
                }
            ],
            [
                {
                    lng: -155.40214,
                    lat: 20.07975
                },
                {
                    lng: -155.22452,
                    lat: 19.99302
                },
                {
                    lng: -155.06226,
                    lat: 19.8591
                },
                {
                    lng: -154.80741,
                    lat: 19.50871
                },
                {
                    lng: -154.83147,
                    lat: 19.45328
                },
                {
                    lng: -155.22217,
                    lat: 19.23972
                },
                {
                    lng: -155.54211,
                    lat: 19.08348
                },
                {
                    lng: -155.68817,
                    lat: 18.91619
                },
                {
                    lng: -155.93665,
                    lat: 19.05939
                },
                {
                    lng: -155.90806,
                    lat: 19.33888
                },
                {
                    lng: -156.07347,
                    lat: 19.70294
                },
                {
                    lng: -156.02368,
                    lat: 19.81422
                },
                {
                    lng: -155.85008,
                    lat: 19.97729
                },
                {
                    lng: -155.91907,
                    lat: 20.17395
                },
                {
                    lng: -155.86108,
                    lat: 20.26721
                },
                {
                    lng: -155.78505,
                    lat: 20.2487
                },
                {
                    lng: -155.40214,
                    lat: 20.07975
                }
            ],
            [
                {
                    lng: -155.99566,
                    lat: 20.76404
                },
                {
                    lng: -156.07926,
                    lat: 20.64397
                },
                {
                    lng: -156.41445,
                    lat: 20.57241
                },
                {
                    lng: -156.58673,
                    lat: 20.783
                },
                {
                    lng: -156.70167,
                    lat: 20.8643
                },
                {
                    lng: -156.71055,
                    lat: 20.92676
                },
                {
                    lng: -156.61258,
                    lat: 21.01249
                },
                {
                    lng: -156.25711,
                    lat: 20.91745
                },
                {
                    lng: -155.99566,
                    lat: 20.76404
                }
            ],
            [
                {
                    lng: -156.75824,
                    lat: 21.17684
                },
                {
                    lng: -156.78933,
                    lat: 21.06873
                },
                {
                    lng: -157.32521,
                    lat: 21.09777
                },
                {
                    lng: -157.25027,
                    lat: 21.21958
                },
                {
                    lng: -156.75824,
                    lat: 21.17684
                }
            ],
            [
                {
                    lng: -158.0252,
                    lat: 21.71696
                },
                {
                    lng: -157.94161,
                    lat: 21.65272
                },
                {
                    lng: -157.65283,
                    lat: 21.32217
                },
                {
                    lng: -157.70703,
                    lat: 21.26442
                },
                {
                    lng: -157.7786,
                    lat: 21.27729
                },
                {
                    lng: -158.12667,
                    lat: 21.31244
                },
                {
                    lng: -158.2538,
                    lat: 21.53919
                },
                {
                    lng: -158.29265,
                    lat: 21.57912
                },
                {
                    lng: -158.0252,
                    lat: 21.71696
                }
            ],
            [
                {
                    lng: -159.36569,
                    lat: 22.21494
                },
                {
                    lng: -159.34512,
                    lat: 21.982
                },
                {
                    lng: -159.46372,
                    lat: 21.88299
                },
                {
                    lng: -159.80051,
                    lat: 22.06533
                },
                {
                    lng: -159.74877,
                    lat: 22.1382
                },
                {
                    lng: -159.5962,
                    lat: 22.23618
                },
                {
                    lng: -159.36569,
                    lat: 22.21494
                }
            ],
            [
                {
                    lng: -166.46779212142462,
                    lat: 60.384169826897754
                },
                {
                    lng: -165.67442969466364,
                    lat: 60.29360687930625
                },
                {
                    lng: -165.57916419173358,
                    lat: 59.90998688418753
                },
                {
                    lng: -166.19277014876727,
                    lat: 59.75444082298899
                },
                {
                    lng: -166.84833736882197,
                    lat: 59.941406155020985
                },
                {
                    lng: -167.45527706609008,
                    lat: 60.21306915957936
                },
                {
                    lng: -166.46779212142462,
                    lat: 60.384169826897754
                }
            ],
            [
                {
                    lng: -153.22872941792113,
                    lat: 57.96896841087248
                },
                {
                    lng: -152.56479061583514,
                    lat: 57.901427313866996
                },
                {
                    lng: -152.1411472239064,
                    lat: 57.591058661522
                },
                {
                    lng: -153.00631405333692,
                    lat: 57.11584219016593
                },
                {
                    lng: -154.0050902984581,
                    lat: 56.734676825581076
                },
                {
                    lng: -154.51640275777004,
                    lat: 56.99274892844669
                },
                {
                    lng: -154.67099280497118,
                    lat: 57.46119578717253
                },
                {
                    lng: -153.7627795074415,
                    lat: 57.81657461204373
                },
                {
                    lng: -153.22872941792113,
                    lat: 57.96896841087248
                }
            ],
            [
                {
                    lng: -140.98598761037601,
                    lat: 69.71199839952635
                },
                {
                    lng: -140.986,
                    lat: 69.712
                },
                {
                    lng: -140.9925,
                    lat: 66.00003
                },
                {
                    lng: -140.99778,
                    lat: 60.30639
                },
                {
                    lng: -140.013,
                    lat: 60.27682
                },
                {
                    lng: -139.039,
                    lat: 60
                },
                {
                    lng: -138.34089,
                    lat: 59.56211
                },
                {
                    lng: -137.4525,
                    lat: 58.905
                },
                {
                    lng: -136.47972,
                    lat: 59.46389
                },
                {
                    lng: -135.47583,
                    lat: 59.78778
                },
                {
                    lng: -134.945,
                    lat: 59.2705600000001
                },
                {
                    lng: -134.27111,
                    lat: 58.86111
                },
                {
                    lng: -133.35556,
                    lat: 58.41028
                },
                {
                    lng: -132.73042,
                    lat: 57.69289
                },
                {
                    lng: -131.70781,
                    lat: 56.55212
                },
                {
                    lng: -130.00778,
                    lat: 55.91583
                },
                {
                    lng: -129.98,
                    lat: 55.285
                },
                {
                    lng: -130.53611,
                    lat: 54.80278
                },
                {
                    lng: -130.53610895273684,
                    lat: 54.80275447679924
                },
                {
                    lng: -130.5361101894673,
                    lat: 54.8027534043494
                },
                {
                    lng: -131.08581823797215,
                    lat: 55.17890615500204
                },
                {
                    lng: -131.9672114671423,
                    lat: 55.497775580459006
                },
                {
                    lng: -132.2500107428595,
                    lat: 56.3699962428974
                },
                {
                    lng: -133.53918108435641,
                    lat: 57.17888743756214
                },
                {
                    lng: -134.07806292029608,
                    lat: 58.12306753196691
                },
                {
                    lng: -135.0382110322791,
                    lat: 58.18771474876394
                },
                {
                    lng: -136.62806230995471,
                    lat: 58.21220937767043
                },
                {
                    lng: -137.800006279686,
                    lat: 58.49999542910376
                },
                {
                    lng: -139.867787041413,
                    lat: 59.53776154238915
                },
                {
                    lng: -140.825273817133,
                    lat: 59.727517401765056
                },
                {
                    lng: -142.57444353556446,
                    lat: 60.08444651960497
                },
                {
                    lng: -143.9588809948799,
                    lat: 59.999180406323376
                },
                {
                    lng: -145.92555681682788,
                    lat: 60.45860972761426
                },
                {
                    lng: -147.11437394914665,
                    lat: 60.884656073644635
                },
                {
                    lng: -148.22430620012761,
                    lat: 60.67298940697714
                },
                {
                    lng: -148.01806555885082,
                    lat: 59.97832896589364
                },
                {
                    lng: -148.57082251686086,
                    lat: 59.914172675203304
                },
                {
                    lng: -149.72785783587585,
                    lat: 59.70565827090553
                },
                {
                    lng: -150.60824337461642,
                    lat: 59.368211168039466
                },
                {
                    lng: -151.7163927886833,
                    lat: 59.15582103131993
                },
                {
                    lng: -151.85943315326722,
                    lat: 59.744984035879554
                },
                {
                    lng: -151.40971900124717,
                    lat: 60.72580272077937
                },
                {
                    lng: -150.3469414947325,
                    lat: 61.03358755150987
                },
                {
                    lng: -150.62111080625704,
                    lat: 61.2844249538544
                },
                {
                    lng: -151.89583919981683,
                    lat: 60.727197984451266
                },
                {
                    lng: -152.57832984109558,
                    lat: 60.061657212964235
                },
                {
                    lng: -154.01917212625764,
                    lat: 59.35027944603428
                },
                {
                    lng: -153.28751135965317,
                    lat: 58.86472768821977
                },
                {
                    lng: -154.23249243875847,
                    lat: 58.14637360293051
                },
                {
                    lng: -155.3074914215102,
                    lat: 57.727794501366304
                },
                {
                    lng: -156.30833472392305,
                    lat: 57.422774359763594
                },
                {
                    lng: -156.55609737854638,
                    lat: 56.97998484967064
                },
                {
                    lng: -158.11721655986779,
                    lat: 56.46360809999419
                },
                {
                    lng: -158.43332129619714,
                    lat: 55.99415355083852
                },
                {
                    lng: -159.60332739971741,
                    lat: 55.56668610292013
                },
                {
                    lng: -160.28971961163427,
                    lat: 55.643580634170576
                },
                {
                    lng: -161.22304765525777,
                    lat: 55.364734605523495
                },
                {
                    lng: -162.23776607974105,
                    lat: 55.02418691672011
                },
                {
                    lng: -163.06944658104638,
                    lat: 54.68973704692712
                },
                {
                    lng: -164.78556922102717,
                    lat: 54.40417308208214
                },
                {
                    lng: -164.94222632552007,
                    lat: 54.57222483989534
                },
                {
                    lng: -163.84833960676565,
                    lat: 55.03943146424609
                },
                {
                    lng: -162.87000139061595,
                    lat: 55.34804311789321
                },
                {
                    lng: -161.80417497459607,
                    lat: 55.89498647727038
                },
                {
                    lng: -160.5636047027812,
                    lat: 56.00805451112501
                },
                {
                    lng: -160.07055986228448,
                    lat: 56.41805532492873
                },
                {
                    lng: -158.6844429189195,
                    lat: 57.01667511659787
                },
                {
                    lng: -158.46109737855403,
                    lat: 57.21692129172885
                },
                {
                    lng: -157.72277035218391,
                    lat: 57.57000051536306
                },
                {
                    lng: -157.55027442119362,
                    lat: 58.328326321030204
                },
                {
                    lng: -157.04167497457698,
                    lat: 58.91888458926172
                },
                {
                    lng: -158.19473120830554,
                    lat: 58.61580231386978
                },
                {
                    lng: -158.51721798402303,
                    lat: 58.78778148053732
                },
                {
                    lng: -159.0586061269288,
                    lat: 58.42418610293163
                },
                {
                    lng: -159.71166704001737,
                    lat: 58.93139028587632
                },
                {
                    lng: -159.98128882550017,
                    lat: 58.572549140041644
                },
                {
                    lng: -160.3552711659965,
                    lat: 59.07112335879361
                },
                {
                    lng: -161.3550034251151,
                    lat: 58.670837714260756
                },
                {
                    lng: -161.96889360252632,
                    lat: 58.67166453717738
                },
                {
                    lng: -162.05498653872465,
                    lat: 59.26692536074745
                },
                {
                    lng: -161.8741707021354,
                    lat: 59.63362132429057
                },
                {
                    lng: -162.51805904849212,
                    lat: 59.98972361921386
                },
                {
                    lng: -163.8183414378202,
                    lat: 59.79805573184336
                },
                {
                    lng: -164.66221757714652,
                    lat: 60.26748444278263
                },
                {
                    lng: -165.3463877024748,
                    lat: 60.50749563256238
                },
                {
                    lng: -165.3508318756519,
                    lat: 61.073895168697504
                },
                {
                    lng: -166.12137915755602,
                    lat: 61.50001902937623
                },
                {
                    lng: -165.73445187077058,
                    lat: 62.074996853271784
                },
                {
                    lng: -164.9191786367179,
                    lat: 62.63307648380794
                },
                {
                    lng: -164.56250790103934,
                    lat: 63.14637848576302
                },
                {
                    lng: -163.75333248599708,
                    lat: 63.21944896102377
                },
                {
                    lng: -163.06722449445786,
                    lat: 63.05945872664802
                },
                {
                    lng: -162.26055538638175,
                    lat: 63.54193573674115
                },
                {
                    lng: -161.53444983624863,
                    lat: 63.455816962326764
                },
                {
                    lng: -160.7725066803211,
                    lat: 63.766108100023246
                },
                {
                    lng: -160.9583351308426,
                    lat: 64.22279857040274
                },
                {
                    lng: -161.51806840721218,
                    lat: 64.40278758407527
                },
                {
                    lng: -160.77777767641481,
                    lat: 64.78860382756642
                },
                {
                    lng: -161.39192623598765,
                    lat: 64.77723501246231
                },
                {
                    lng: -162.4530500966689,
                    lat: 64.55944468856819
                },
                {
                    lng: -162.75778601789415,
                    lat: 64.33860545516876
                },
                {
                    lng: -163.54639421288428,
                    lat: 64.5591604681905
                },
                {
                    lng: -164.96082984114514,
                    lat: 64.44694509546883
                },
                {
                    lng: -166.42528825586447,
                    lat: 64.68667206487066
                },
                {
                    lng: -166.8450042389391,
                    lat: 65.08889557561452
                },
                {
                    lng: -168.11056006576715,
                    lat: 65.66999705673675
                },
                {
                    lng: -166.70527116602193,
                    lat: 66.08831777613938
                },
                {
                    lng: -164.47470964257548,
                    lat: 66.5766600612975
                },
                {
                    lng: -163.65251176659564,
                    lat: 66.5766600612975
                },
                {
                    lng: -163.78860165103623,
                    lat: 66.07720734319668
                },
                {
                    lng: -161.67777442121013,
                    lat: 66.11611969671242
                },
                {
                    lng: -162.48971452538004,
                    lat: 66.73556509059512
                },
                {
                    lng: -163.71971696679117,
                    lat: 67.11639455837008
                },
                {
                    lng: -164.4309913808565,
                    lat: 67.61633820257777
                },
                {
                    lng: -165.39028683170673,
                    lat: 68.04277212185025
                },
                {
                    lng: -166.76444068099605,
                    lat: 68.35887685817966
                },
                {
                    lng: -166.20470740462667,
                    lat: 68.88303091091615
                },
                {
                    lng: -164.43081051334346,
                    lat: 68.91553538682774
                },
                {
                    lng: -163.1686136546145,
                    lat: 69.37111481391287
                },
                {
                    lng: -162.930566169262,
                    lat: 69.85806183539927
                },
                {
                    lng: -161.90889726463556,
                    lat: 70.33332998318764
                },
                {
                    lng: -160.93479651593367,
                    lat: 70.44768992784958
                },
                {
                    lng: -159.03917578838713,
                    lat: 70.89164215766891
                },
                {
                    lng: -158.11972286683394,
                    lat: 70.82472117785102
                },
                {
                    lng: -156.58082455139808,
                    lat: 71.35776357694175
                },
                {
                    lng: -155.06779029032427,
                    lat: 71.14777639432367
                },
                {
                    lng: -154.3441652089412,
                    lat: 70.69640859647018
                },
                {
                    lng: -153.9000062733926,
                    lat: 70.88998851183567
                },
                {
                    lng: -152.21000606993528,
                    lat: 70.82999217394485
                },
                {
                    lng: -152.27000240782613,
                    lat: 70.60000621202983
                },
                {
                    lng: -150.73999243874448,
                    lat: 70.43001658800569
                },
                {
                    lng: -149.7200030181675,
                    lat: 70.53001048449045
                },
                {
                    lng: -147.61336157935705,
                    lat: 70.2140349392418
                },
                {
                    lng: -145.68998980022533,
                    lat: 70.12000967068673
                },
                {
                    lng: -144.9200109590764,
                    lat: 69.98999176704046
                },
                {
                    lng: -143.58944618042523,
                    lat: 70.15251414659832
                },
                {
                    lng: -142.07251034871348,
                    lat: 69.85193817817265
                },
                {
                    lng: -140.98598752156073,
                    lat: 69.71199839952635
                },
                {
                    lng: -140.98598761037601,
                    lat: 69.71199839952635
                }
            ],
            [
                {
                    lng: -171.73165686753944,
                    lat: 63.782515367275934
                },
                {
                    lng: -171.1144335602453,
                    lat: 63.59219106714495
                },
                {
                    lng: -170.4911124339407,
                    lat: 63.694975490973505
                },
                {
                    lng: -169.6825054596536,
                    lat: 63.43111562769119
                },
                {
                    lng: -168.6894394603007,
                    lat: 63.297506212000556
                },
                {
                    lng: -168.77194088445466,
                    lat: 63.18859813094544
                },
                {
                    lng: -169.5294398672051,
                    lat: 62.97693146427792
                },
                {
                    lng: -170.29055620021595,
                    lat: 63.194437567794424
                },
                {
                    lng: -170.67138566799093,
                    lat: 63.3758218451389
                },
                {
                    lng: -171.55306311753873,
                    lat: 63.317789211675105
                },
                {
                    lng: -171.79111060289122,
                    lat: 63.40584585230046
                },
                {
                    lng: -171.73165686753944,
                    lat: 63.782515367275934
                }
            ]
        ]
    },
    KZ: {
        name: 'Kazakhstan',
        type: 'Polygon',
        coordinates: [
            {
                lng: 87.35997033076265,
                lat: 49.21498078062912
            },
            {
                lng: 86.59877648310336,
                lat: 48.549181626980626
            },
            {
                lng: 85.7682328633083,
                lat: 48.45575063739699
            },
            {
                lng: 85.72048383987067,
                lat: 47.452969468773105
            },
            {
                lng: 85.16429039911324,
                lat: 47.0009557155161
            },
            {
                lng: 83.18048383986047,
                lat: 47.33003123635086
            },
            {
                lng: 82.45892581576906,
                lat: 45.539649563166506
            },
            {
                lng: 81.9470707539181,
                lat: 45.31702749285312
            },
            {
                lng: 79.96610639844141,
                lat: 44.91751699480463
            },
            {
                lng: 80.86620649610126,
                lat: 43.18036204688101
            },
            {
                lng: 80.1801501809943,
                lat: 42.92006785742694
            },
            {
                lng: 80.2599902688853,
                lat: 42.34999929459906
            },
            {
                lng: 79.64364546094012,
                lat: 42.49668284765953
            },
            {
                lng: 79.14217736197978,
                lat: 42.85609243424952
            },
            {
                lng: 77.65839196158322,
                lat: 42.96068553320826
            },
            {
                lng: 76.00035363149846,
                lat: 42.98802236589067
            },
            {
                lng: 75.63696495962202,
                lat: 42.87789988867668
            },
            {
                lng: 74.21286583852256,
                lat: 43.29833934180337
            },
            {
                lng: 73.64530358266092,
                lat: 43.09127187760987
            },
            {
                lng: 73.48975752146237,
                lat: 42.50089447689132
            },
            {
                lng: 71.8446382994506,
                lat: 42.8453954127651
            },
            {
                lng: 71.18628055205212,
                lat: 42.70429291439214
            },
            {
                lng: 70.96231489449914,
                lat: 42.266154283205495
            },
            {
                lng: 70.3889648782208,
                lat: 42.08130768489745
            },
            {
                lng: 69.07002729683524,
                lat: 41.38424428971234
            },
            {
                lng: 68.63248294462002,
                lat: 40.66868073176681
            },
            {
                lng: 68.25989586779562,
                lat: 40.6623245305949
            },
            {
                lng: 67.98585574735182,
                lat: 41.13599070898222
            },
            {
                lng: 66.71404707221652,
                lat: 41.1684435084615
            },
            {
                lng: 66.51064863471572,
                lat: 41.987644151368556
            },
            {
                lng: 66.02339155463562,
                lat: 41.99464630794404
            },
            {
                lng: 66.09801232286509,
                lat: 42.997660020513095
            },
            {
                lng: 64.90082441595928,
                lat: 43.72808055274258
            },
            {
                lng: 63.18578698105657,
                lat: 43.650074978198006
            },
            {
                lng: 62.01330040878625,
                lat: 43.50447663021565
            },
            {
                lng: 61.05831994003245,
                lat: 44.40581696225051
            },
            {
                lng: 60.23997195825834,
                lat: 44.78403677019473
            },
            {
                lng: 58.68998904809581,
                lat: 45.50001373959873
            },
            {
                lng: 58.50312706892845,
                lat: 45.586804307632974
            },
            {
                lng: 55.928917270741096,
                lat: 44.99585846615911
            },
            {
                lng: 55.96819135928291,
                lat: 41.30864166926936
            },
            {
                lng: 55.45525109235377,
                lat: 41.25985911718584
            },
            {
                lng: 54.75534549339264,
                lat: 42.043971462566574
            },
            {
                lng: 54.07941775901495,
                lat: 42.32410940202083
            },
            {
                lng: 52.944293247291654,
                lat: 42.11603424739759
            },
            {
                lng: 52.50245975119615,
                lat: 41.78331553808637
            },
            {
                lng: 52.44633914572722,
                lat: 42.027150783855575
            },
            {
                lng: 52.692112257707265,
                lat: 42.44389537207337
            },
            {
                lng: 52.50142622255032,
                lat: 42.7922978785852
            },
            {
                lng: 51.342427199108215,
                lat: 43.132974758469345
            },
            {
                lng: 50.89129194520024,
                lat: 44.03103363705378
            },
            {
                lng: 50.339129266161365,
                lat: 44.284015611338475
            },
            {
                lng: 50.30564293803627,
                lat: 44.609835516938915
            },
            {
                lng: 51.278503452363225,
                lat: 44.51485423438646
            },
            {
                lng: 51.31689904155604,
                lat: 45.2459982366679
            },
            {
                lng: 52.16738976421573,
                lat: 45.40839142514511
            },
            {
                lng: 53.0408764992452,
                lat: 45.25904653582177
            },
            {
                lng: 53.220865512917726,
                lat: 46.234645901059935
            },
            {
                lng: 53.042736850807785,
                lat: 46.85300608986449
            },
            {
                lng: 52.04202273947561,
                lat: 46.80463694923924
            },
            {
                lng: 51.191945428274266,
                lat: 47.048704738953916
            },
            {
                lng: 50.03408328634248,
                lat: 46.60898997658222
            },
            {
                lng: 49.10116000000011,
                lat: 46.399330000000134
            },
            {
                lng: 48.59325000000018,
                lat: 46.56104
            },
            {
                lng: 48.694733514201744,
                lat: 47.07562816017793
            },
            {
                lng: 48.05725,
                lat: 47.74377
            },
            {
                lng: 47.31524000000013,
                lat: 47.7158500000001
            },
            {
                lng: 46.46644575377627,
                lat: 48.39415233010493
            },
            {
                lng: 47.04367150247651,
                lat: 49.152038886097614
            },
            {
                lng: 46.75159630716274,
                lat: 49.35600576435377
            },
            {
                lng: 47.54948042174931,
                lat: 50.454698391311126
            },
            {
                lng: 48.57784142435753,
                lat: 49.87475962991567
            },
            {
                lng: 48.70238162618102,
                lat: 50.60512848571284
            },
            {
                lng: 50.76664839051216,
                lat: 51.6927623561599
            },
            {
                lng: 52.32872358583097,
                lat: 51.718652248738124
            },
            {
                lng: 54.532878452376224,
                lat: 51.02623973245932
            },
            {
                lng: 55.71694,
                lat: 50.62171000000018
            },
            {
                lng: 56.77798000000013,
                lat: 51.04355
            },
            {
                lng: 58.36332,
                lat: 51.06364
            },
            {
                lng: 59.642282342370606,
                lat: 50.545442206415714
            },
            {
                lng: 59.93280724471549,
                lat: 50.842194118851864
            },
            {
                lng: 61.337424350840934,
                lat: 50.79907013610426
            },
            {
                lng: 61.58800337102417,
                lat: 51.272658799843214
            },
            {
                lng: 59.967533807215545,
                lat: 51.9604204372157
            },
            {
                lng: 60.92726850774028,
                lat: 52.44754832621504
            },
            {
                lng: 60.73999311711458,
                lat: 52.71998647725775
            },
            {
                lng: 61.699986199800605,
                lat: 52.97999644633427
            },
            {
                lng: 60.978066440683165,
                lat: 53.66499339457914
            },
            {
                lng: 61.43660000000017,
                lat: 54.00625
            },
            {
                lng: 65.17853356309593,
                lat: 54.35422781027211
            },
            {
                lng: 65.66687,
                lat: 54.60125000000011
            },
            {
                lng: 68.16910037625883,
                lat: 54.97039175070432
            },
            {
                lng: 69.06816694527288,
                lat: 55.38525014914353
            },
            {
                lng: 70.86526655465514,
                lat: 55.169733588270105
            },
            {
                lng: 71.18013105660941,
                lat: 54.13328522400826
            },
            {
                lng: 72.22415001820218,
                lat: 54.376655381886735
            },
            {
                lng: 73.5085160663844,
                lat: 54.0356167669766
            },
            {
                lng: 73.42567874542044,
                lat: 53.489810289109755
            },
            {
                lng: 74.38482000000016,
                lat: 53.54685000000012
            },
            {
                lng: 76.89110029491343,
                lat: 54.49052440044193
            },
            {
                lng: 76.52517947785475,
                lat: 54.17700348572714
            },
            {
                lng: 77.80091556184425,
                lat: 53.404414984747575
            },
            {
                lng: 80.03555952344169,
                lat: 50.86475088154725
            },
            {
                lng: 80.56844689323549,
                lat: 51.38833649352847
            },
            {
                lng: 81.94598554883993,
                lat: 50.81219594990637
            },
            {
                lng: 83.38300377801238,
                lat: 51.069182847693924
            },
            {
                lng: 83.93511478061885,
                lat: 50.88924551045358
            },
            {
                lng: 84.41637739455308,
                lat: 50.311399644565824
            },
            {
                lng: 85.11555952346203,
                lat: 50.11730296487764
            },
            {
                lng: 85.54126997268247,
                lat: 49.69285858824816
            },
            {
                lng: 86.82935672398963,
                lat: 49.82667470966817
            },
            {
                lng: 87.35997033076265,
                lat: 49.21498078062912
            }
        ]
    },
    UZ: {
        name: 'Uzbekistan',
        type: 'Polygon',
        coordinates: [
            {
                lng: 55.96819135928291,
                lat: 41.30864166926936
            },
            {
                lng: 55.928917270741096,
                lat: 44.99585846615911
            },
            {
                lng: 58.50312706892845,
                lat: 45.586804307632974
            },
            {
                lng: 58.68998904809581,
                lat: 45.50001373959873
            },
            {
                lng: 60.23997195825834,
                lat: 44.78403677019473
            },
            {
                lng: 61.05831994003245,
                lat: 44.40581696225051
            },
            {
                lng: 62.01330040878625,
                lat: 43.50447663021565
            },
            {
                lng: 63.18578698105657,
                lat: 43.650074978198006
            },
            {
                lng: 64.90082441595928,
                lat: 43.72808055274258
            },
            {
                lng: 66.09801232286509,
                lat: 42.997660020513095
            },
            {
                lng: 66.02339155463562,
                lat: 41.99464630794404
            },
            {
                lng: 66.51064863471572,
                lat: 41.987644151368556
            },
            {
                lng: 66.71404707221652,
                lat: 41.1684435084615
            },
            {
                lng: 67.98585574735182,
                lat: 41.13599070898222
            },
            {
                lng: 68.25989586779562,
                lat: 40.6623245305949
            },
            {
                lng: 68.63248294462002,
                lat: 40.66868073176681
            },
            {
                lng: 69.07002729683524,
                lat: 41.38424428971234
            },
            {
                lng: 70.3889648782208,
                lat: 42.08130768489745
            },
            {
                lng: 70.96231489449914,
                lat: 42.266154283205495
            },
            {
                lng: 71.25924767444823,
                lat: 42.16771067968946
            },
            {
                lng: 70.42002241402821,
                lat: 41.51999827734314
            },
            {
                lng: 71.1578585142916,
                lat: 41.14358714452912
            },
            {
                lng: 71.87011478057047,
                lat: 41.392900092121266
            },
            {
                lng: 73.05541710804917,
                lat: 40.866033026689465
            },
            {
                lng: 71.77487511585656,
                lat: 40.14584442805378
            },
            {
                lng: 71.01419803252017,
                lat: 40.24436554621823
            },
            {
                lng: 70.60140669137269,
                lat: 40.21852733007229
            },
            {
                lng: 70.45815962105962,
                lat: 40.49649485937029
            },
            {
                lng: 70.66662234892505,
                lat: 40.960213324541414
            },
            {
                lng: 69.32949466337283,
                lat: 40.72782440852485
            },
            {
                lng: 69.0116329283455,
                lat: 40.08615814875667
            },
            {
                lng: 68.53641645698943,
                lat: 39.53345286717894
            },
            {
                lng: 67.70142866401736,
                lat: 39.58047842056453
            },
            {
                lng: 67.44221967964131,
                lat: 39.140143541005486
            },
            {
                lng: 68.17602501818592,
                lat: 38.901553453113905
            },
            {
                lng: 68.39203250516596,
                lat: 38.15702525486874
            },
            {
                lng: 67.82999962755952,
                lat: 37.144994004864685
            },
            {
                lng: 67.07578209825962,
                lat: 37.35614390720929
            },
            {
                lng: 66.51860680528867,
                lat: 37.36278432875879
            },
            {
                lng: 66.54615034370022,
                lat: 37.97468496352687
            },
            {
                lng: 65.2159989765074,
                lat: 38.4026950139843
            },
            {
                lng: 64.17022301621677,
                lat: 38.892406724598246
            },
            {
                lng: 63.51801476426103,
                lat: 39.36325653742564
            },
            {
                lng: 62.374260288345006,
                lat: 40.05388621679039
            },
            {
                lng: 61.88271406438469,
                lat: 41.084856879229406
            },
            {
                lng: 61.54717898951356,
                lat: 41.266370347654615
            },
            {
                lng: 60.465952996670694,
                lat: 41.22032664648255
            },
            {
                lng: 60.083340691981675,
                lat: 41.425146185871405
            },
            {
                lng: 59.976422153569786,
                lat: 42.22308197689021
            },
            {
                lng: 58.62901085799146,
                lat: 42.75155101172305
            },
            {
                lng: 57.78652998233708,
                lat: 42.17055288346552
            },
            {
                lng: 56.932215203687804,
                lat: 41.826026109375604
            },
            {
                lng: 57.0963912290791,
                lat: 41.32231008561057
            },
            {
                lng: 55.96819135928291,
                lat: 41.30864166926936
            }
        ]
    },
    PG: {
        name: 'Papua New Guinea',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: 141.00021040259185,
                    lat: -2.60015105551566
                },
                {
                    lng: 142.73524661679147,
                    lat: -3.28915292726321
                },
                {
                    lng: 144.58397098203324,
                    lat: -3.861417738463416
                },
                {
                    lng: 145.27317955950997,
                    lat: -4.373737888205049
                },
                {
                    lng: 145.8297864117257,
                    lat: -4.876497897972683
                },
                {
                    lng: 145.981921828393,
                    lat: -5.465609226100043
                },
                {
                    lng: 147.64807335834757,
                    lat: -6.083659356310847
                },
                {
                    lng: 147.89110761941623,
                    lat: -6.614014580922343
                },
                {
                    lng: 146.97090538959486,
                    lat: -6.721656589386313
                },
                {
                    lng: 147.19187381407494,
                    lat: -7.388024183790023
                },
                {
                    lng: 148.08463585834932,
                    lat: -8.044108168167647
                },
                {
                    lng: 148.73410525939357,
                    lat: -9.104663588093764
                },
                {
                    lng: 149.30683515848443,
                    lat: -9.07143564213009
                },
                {
                    lng: 149.26663089416132,
                    lat: -9.514406019736029
                },
                {
                    lng: 150.03872846903425,
                    lat: -9.684318129111709
                },
                {
                    lng: 149.7387984560122,
                    lat: -9.872937106977048
                },
                {
                    lng: 150.80162763895913,
                    lat: -10.293686618697478
                },
                {
                    lng: 150.6905749859639,
                    lat: -10.582712904505925
                },
                {
                    lng: 150.02839318257583,
                    lat: -10.652476088099952
                },
                {
                    lng: 149.78231001200197,
                    lat: -10.393267103723923
                },
                {
                    lng: 148.92313764871727,
                    lat: -10.280922539921384
                },
                {
                    lng: 147.913018426708,
                    lat: -10.13044076908745
                },
                {
                    lng: 147.13544315001218,
                    lat: -9.492443536011983
                },
                {
                    lng: 146.56788089415056,
                    lat: -8.942554619994155
                },
                {
                    lng: 146.04848107318492,
                    lat: -8.067414239131281
                },
                {
                    lng: 144.74416792213805,
                    lat: -7.630128269077446
                },
                {
                    lng: 143.89708784400966,
                    lat: -7.915330498896296
                },
                {
                    lng: 143.28637576718432,
                    lat: -8.24549122480908
                },
                {
                    lng: 143.41391320208066,
                    lat: -8.983068942910982
                },
                {
                    lng: 142.62843143124417,
                    lat: -9.326820570516524
                },
                {
                    lng: 142.06825890520025,
                    lat: -9.159595635620022
                },
                {
                    lng: 141.03385176001382,
                    lat: -9.117892754760483
                },
                {
                    lng: 141.01705691951895,
                    lat: -5.859021905138071
                },
                {
                    lng: 141.00021040259185,
                    lat: -2.60015105551566
                }
            ],
            [
                {
                    lng: 152.64001671774253,
                    lat: -3.659983005389691
                },
                {
                    lng: 153.0199935243847,
                    lat: -3.980015150573265
                },
                {
                    lng: 153.14003787659874,
                    lat: -4.499983412294092
                },
                {
                    lng: 152.82729210836828,
                    lat: -4.766427097190991
                },
                {
                    lng: 152.63867313050298,
                    lat: -4.176127211120921
                },
                {
                    lng: 152.40602583232493,
                    lat: -3.789742526874583
                },
                {
                    lng: 151.95323693258354,
                    lat: -3.462062269711816
                },
                {
                    lng: 151.38427941305002,
                    lat: -3.035421644710112
                },
                {
                    lng: 150.66204959533883,
                    lat: -2.741486097833935
                },
                {
                    lng: 150.93996544820448,
                    lat: -2.500002129734007
                },
                {
                    lng: 151.47998416565457,
                    lat: -2.779985039891379
                },
                {
                    lng: 151.8200150901351,
                    lat: -2.999971612157886
                },
                {
                    lng: 152.23998945537113,
                    lat: -3.24000864015364
                },
                {
                    lng: 152.64001671774253,
                    lat: -3.659983005389691
                }
            ],
            [
                {
                    lng: 151.30139041565388,
                    lat: -5.840728448106752
                },
                {
                    lng: 150.75444705627666,
                    lat: -6.083762709175431
                },
                {
                    lng: 150.2411967307538,
                    lat: -6.317753594593028
                },
                {
                    lng: 149.70996300679332,
                    lat: -6.316513360218025
                },
                {
                    lng: 148.89006473205046,
                    lat: -6.026040134305404
                },
                {
                    lng: 148.31893680236067,
                    lat: -5.747142429226166
                },
                {
                    lng: 148.40182579975686,
                    lat: -5.437755629094717
                },
                {
                    lng: 149.29841190002082,
                    lat: -5.58374155031926
                },
                {
                    lng: 149.84556196512722,
                    lat: -5.505503431829368
                },
                {
                    lng: 149.99625044169028,
                    lat: -5.026101169457654
                },
                {
                    lng: 150.13975589416486,
                    lat: -5.001348158389852
                },
                {
                    lng: 150.23690758687354,
                    lat: -5.532220147324267
                },
                {
                    lng: 150.80746707580812,
                    lat: -5.455842380396874
                },
                {
                    lng: 151.08967207255404,
                    lat: -5.113692722192383
                },
                {
                    lng: 151.6478808941709,
                    lat: -4.757073662946162
                },
                {
                    lng: 151.53786176982146,
                    lat: -4.167807305521933
                },
                {
                    lng: 152.1367916200843,
                    lat: -4.14879037843852
                },
                {
                    lng: 152.33874311748093,
                    lat: -4.312966403829805
                },
                {
                    lng: 152.3186926617517,
                    lat: -4.867661228050771
                },
                {
                    lng: 151.98279585185452,
                    lat: -5.478063246282382
                },
                {
                    lng: 151.45910688700866,
                    lat: -5.560280450058754
                },
                {
                    lng: 151.30139041565388,
                    lat: -5.840728448106752
                }
            ],
            [
                {
                    lng: 154.75999067608439,
                    lat: -5.339983819198495
                },
                {
                    lng: 155.06291792217934,
                    lat: -5.56679168052753
                },
                {
                    lng: 155.5477462099417,
                    lat: -6.200654799019645
                },
                {
                    lng: 156.0199654482248,
                    lat: -6.540013929880381
                },
                {
                    lng: 155.8800256695784,
                    lat: -6.819996840037753
                },
                {
                    lng: 155.59999108298877,
                    lat: -6.919990736522522
                },
                {
                    lng: 155.16699425681514,
                    lat: -6.535931491729322
                },
                {
                    lng: 154.72919152243838,
                    lat: -5.900828138862195
                },
                {
                    lng: 154.51411421123964,
                    lat: -5.139117526879986
                },
                {
                    lng: 154.65250369691728,
                    lat: -5.04243092206189
                },
                {
                    lng: 154.75999067608439,
                    lat: -5.339983819198495
                }
            ]
        ]
    },
    ID: {
        name: 'Indonesia',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: 141.00021040259185,
                    lat: -2.60015105551566
                },
                {
                    lng: 141.01705691951895,
                    lat: -5.859021905138071
                },
                {
                    lng: 141.03385176001382,
                    lat: -9.117892754760483
                },
                {
                    lng: 140.14341515519254,
                    lat: -8.29716765710095
                },
                {
                    lng: 139.1277665549281,
                    lat: -8.096042982620979
                },
                {
                    lng: 138.881476678625,
                    lat: -8.380935153846075
                },
                {
                    lng: 137.61447391169287,
                    lat: -8.41168263105974
                },
                {
                    lng: 138.03909915583517,
                    lat: -7.597882175327321
                },
                {
                    lng: 138.66862145401478,
                    lat: -7.320224704623087
                },
                {
                    lng: 138.4079138531023,
                    lat: -6.232849216337485
                },
                {
                    lng: 137.92783979711078,
                    lat: -5.393365573756
                },
                {
                    lng: 135.98925011611345,
                    lat: -4.54654387778907
                },
                {
                    lng: 135.16459760959975,
                    lat: -4.462931410340822
                },
                {
                    lng: 133.66288048719787,
                    lat: -3.538853448097541
                },
                {
                    lng: 133.36770470594672,
                    lat: -4.024818617370315
                },
                {
                    lng: 132.98395551974727,
                    lat: -4.112978610860253
                },
                {
                    lng: 132.75694095268904,
                    lat: -3.746282647317123
                },
                {
                    lng: 132.75378869031925,
                    lat: -3.31178720460705
                },
                {
                    lng: 131.98980431531618,
                    lat: -2.820551039240499
                },
                {
                    lng: 133.0668445171434,
                    lat: -2.460417982598436
                },
                {
                    lng: 133.78003095920354,
                    lat: -2.479848321140182
                },
                {
                    lng: 133.69621178602614,
                    lat: -2.214541517753702
                },
                {
                    lng: 132.23237348849426,
                    lat: -2.212526136894319
                },
                {
                    lng: 131.83622195854474,
                    lat: -1.617161960459647
                },
                {
                    lng: 130.94283979708285,
                    lat: -1.432522067880783
                },
                {
                    lng: 130.5195581401801,
                    lat: -0.937720228686089
                },
                {
                    lng: 131.8675378765136,
                    lat: -0.695461114101789
                },
                {
                    lng: 132.3801164084167,
                    lat: -0.369537855636949
                },
                {
                    lng: 133.98554813042836,
                    lat: -0.780210463060456
                },
                {
                    lng: 134.14336795464772,
                    lat: -1.151867364103623
                },
                {
                    lng: 134.42262739475302,
                    lat: -2.769184665542376
                },
                {
                    lng: 135.45760298069467,
                    lat: -3.367752780779149
                },
                {
                    lng: 136.29331424371884,
                    lat: -2.307042331556154
                },
                {
                    lng: 137.44073774632756,
                    lat: -1.703513278819365
                },
                {
                    lng: 138.3297274110447,
                    lat: -1.702686455902693
                },
                {
                    lng: 139.18492068904288,
                    lat: -2.051295668143673
                },
                {
                    lng: 139.92668419816044,
                    lat: -2.409051608900313
                },
                {
                    lng: 141.00021040259185,
                    lat: -2.60015105551566
                }
            ],
            [
                {
                    lng: 124.96868248911623,
                    lat: -8.892790215697083
                },
                {
                    lng: 125.07001997284061,
                    lat: -9.089987481322872
                },
                {
                    lng: 125.08852013560109,
                    lat: -9.393173109579294
                },
                {
                    lng: 124.43595014861933,
                    lat: -10.140000909061449
                },
                {
                    lng: 123.57998172413669,
                    lat: -10.359987481327956
                },
                {
                    lng: 123.459989048355,
                    lat: -10.239994805546223
                },
                {
                    lng: 123.55000939340744,
                    lat: -9.900015557497987
                },
                {
                    lng: 123.9800089865081,
                    lat: -9.290026950724716
                },
                {
                    lng: 124.96868248911623,
                    lat: -8.892790215697083
                }
            ],
            [
                {
                    lng: 134.21013390516885,
                    lat: -6.89523772545472
                },
                {
                    lng: 134.11277550673094,
                    lat: -6.142467136259
                },
                {
                    lng: 134.29033572808584,
                    lat: -5.783057549669017
                },
                {
                    lng: 134.49962527886788,
                    lat: -5.445042006047871
                },
                {
                    lng: 134.72700158095216,
                    lat: -5.737582289252167
                },
                {
                    lng: 134.7246244650667,
                    lat: -6.214400730009288
                },
                {
                    lng: 134.21013390516885,
                    lat: -6.89523772545472
                }
            ],
            [
                {
                    lng: 117.88203494677018,
                    lat: 4.137551377779516
                },
                {
                    lng: 117.3132324565335,
                    lat: 3.234428208830593
                },
                {
                    lng: 118.0483297058854,
                    lat: 2.287690131027333
                },
                {
                    lng: 117.87562706916597,
                    lat: 1.827640692548925
                },
                {
                    lng: 118.99674726773816,
                    lat: 0.902219143066063
                },
                {
                    lng: 117.8118583517178,
                    lat: 0.784241848143708
                },
                {
                    lng: 117.47833865770603,
                    lat: 0.102474676917026
                },
                {
                    lng: 117.52164350796664,
                    lat: -0.803723239753268
                },
                {
                    lng: 116.56004845587947,
                    lat: -1.48766082113621
                },
                {
                    lng: 116.5337968282752,
                    lat: -2.483517347832901
                },
                {
                    lng: 116.14808393764866,
                    lat: -4.012726332214022
                },
                {
                    lng: 116.00085778204911,
                    lat: -3.657037448749058
                },
                {
                    lng: 114.86480309454456,
                    lat: -4.106984144714396
                },
                {
                    lng: 114.46865156459506,
                    lat: -3.495703627133828
                },
                {
                    lng: 113.75567182826407,
                    lat: -3.43916961020652
                },
                {
                    lng: 113.25699425664752,
                    lat: -3.118775729996905
                },
                {
                    lng: 112.06812625534067,
                    lat: -3.478392022316051
                },
                {
                    lng: 111.70329064336005,
                    lat: -2.994442233902654
                },
                {
                    lng: 111.04824018762824,
                    lat: -3.049425957861211
                },
                {
                    lng: 110.223846063276,
                    lat: -2.934032484553455
                },
                {
                    lng: 110.07093550012434,
                    lat: -1.592874037282463
                },
                {
                    lng: 109.571947869914,
                    lat: -1.314906507984475
                },
                {
                    lng: 109.0918738139225,
                    lat: -0.459506524257094
                },
                {
                    lng: 108.9526575053282,
                    lat: 0.415375474444318
                },
                {
                    lng: 109.06913618371408,
                    lat: 1.341933905437614
                },
                {
                    lng: 109.66326012577375,
                    lat: 2.006466986494956
                },
                {
                    lng: 109.83022667850881,
                    lat: 1.338135687664163
                },
                {
                    lng: 110.51406090702716,
                    lat: 0.773131415200965
                },
                {
                    lng: 111.15913781132662,
                    lat: 0.976478176269481
                },
                {
                    lng: 111.79754845586041,
                    lat: 0.904441229654608
                },
                {
                    lng: 112.38025190638359,
                    lat: 1.410120957846743
                },
                {
                    lng: 112.85980919805218,
                    lat: 1.497790025229904
                },
                {
                    lng: 113.8058496440195,
                    lat: 1.217548732911069
                },
                {
                    lng: 114.62135542201753,
                    lat: 1.430688177898901
                },
                {
                    lng: 115.13403730678522,
                    lat: 2.821481838386234
                },
                {
                    lng: 115.51907840379198,
                    lat: 3.169238389494396
                },
                {
                    lng: 115.8655172058767,
                    lat: 4.3065591495901
                },
                {
                    lng: 117.01521447150628,
                    lat: 4.306094061699469
                },
                {
                    lng: 117.88203494677018,
                    lat: 4.137551377779516
                }
            ],
            [
                {
                    lng: 129.37099775606094,
                    lat: -2.802154229344595
                },
                {
                    lng: 130.47134402885177,
                    lat: -3.093764336767634
                },
                {
                    lng: 130.83483605359282,
                    lat: -3.858472181822776
                },
                {
                    lng: 129.99054650280817,
                    lat: -3.446300957862796
                },
                {
                    lng: 129.15524865124235,
                    lat: -3.362636813982248
                },
                {
                    lng: 128.59068362845363,
                    lat: -3.428679294451264
                },
                {
                    lng: 127.89889122936235,
                    lat: -3.393435967628207
                },
                {
                    lng: 128.13587934785284,
                    lat: -2.843650404474971
                },
                {
                    lng: 129.37099775606094,
                    lat: -2.802154229344595
                }
            ],
            [
                {
                    lng: 126.87492272349886,
                    lat: -3.790982761249587
                },
                {
                    lng: 126.18380211802736,
                    lat: -3.607376397316564
                },
                {
                    lng: 125.98903364471926,
                    lat: -3.177273451351305
                },
                {
                    lng: 127.00065148326497,
                    lat: -3.129317722184446
                },
                {
                    lng: 127.24921512258891,
                    lat: -3.45906503663889
                },
                {
                    lng: 126.87492272349886,
                    lat: -3.790982761249587
                }
            ],
            [
                {
                    lng: 127.93237755748748,
                    lat: 2.174596258956569
                },
                {
                    lng: 128.00415612194087,
                    lat: 1.628531398928345
                },
                {
                    lng: 128.5945593608755,
                    lat: 1.540810655112878
                },
                {
                    lng: 128.6882487326207,
                    lat: 1.132385972494063
                },
                {
                    lng: 128.63595218314134,
                    lat: 0.258485826006194
                },
                {
                    lng: 128.1201697124361,
                    lat: 0.356412665199286
                },
                {
                    lng: 127.96803429576886,
                    lat: -0.252077325037519
                },
                {
                    lng: 128.3799988139997,
                    lat: -0.7800037573313
                },
                {
                    lng: 128.1000159038423,
                    lat: -0.899996433113031
                },
                {
                    lng: 127.69647464407507,
                    lat: -0.266598402511534
                },
                {
                    lng: 127.39949018769369,
                    lat: 1.011721503092545
                },
                {
                    lng: 127.60051150930906,
                    lat: 1.810690822757195
                },
                {
                    lng: 127.93237755748748,
                    lat: 2.174596258956569
                }
            ],
            [
                {
                    lng: 122.9275667664518,
                    lat: 0.875192368977409
                },
                {
                    lng: 124.07752241424288,
                    lat: 0.917101955566125
                },
                {
                    lng: 125.0659892111218,
                    lat: 1.64325918213153
                },
                {
                    lng: 125.2405005229715,
                    lat: 1.419836127117605
                },
                {
                    lng: 124.4370353536974,
                    lat: 0.427881171058957
                },
                {
                    lng: 123.6855049988767,
                    lat: 0.235593166500891
                },
                {
                    lng: 122.72308312387287,
                    lat: 0.431136786293337
                },
                {
                    lng: 121.05672488818911,
                    lat: 0.381217352699394
                },
                {
                    lng: 120.18308312386272,
                    lat: 0.237246812334234
                },
                {
                    lng: 120.04086958219548,
                    lat: -0.519657891444837
                },
                {
                    lng: 120.93590538949073,
                    lat: -1.408905938323393
                },
                {
                    lng: 121.4758207540762,
                    lat: -0.95596200928513
                },
                {
                    lng: 123.34056481332846,
                    lat: -0.615672702643138
                },
                {
                    lng: 123.25839928598441,
                    lat: -1.076213067228309
                },
                {
                    lng: 122.82271528533161,
                    lat: -0.930950616055853
                },
                {
                    lng: 122.38852990121529,
                    lat: -1.516858005381117
                },
                {
                    lng: 121.50827355355551,
                    lat: -1.904482924002458
                },
                {
                    lng: 122.4545723816843,
                    lat: -3.186058444840924
                },
                {
                    lng: 122.2718961935325,
                    lat: -3.529500013852712
                },
                {
                    lng: 123.17096276254655,
                    lat: -4.683693129091701
                },
                {
                    lng: 123.1623327983538,
                    lat: -5.340603936385996
                },
                {
                    lng: 122.62851525277875,
                    lat: -5.634591159694466
                },
                {
                    lng: 122.23639448454801,
                    lat: -5.282933037948268
                },
                {
                    lng: 122.71956912647701,
                    lat: -4.464171644715826
                },
                {
                    lng: 121.73823367725436,
                    lat: -4.851331475446543
                },
                {
                    lng: 121.48946333220127,
                    lat: -4.574552504091265
                },
                {
                    lng: 121.61917117725386,
                    lat: -4.188477878438682
                },
                {
                    lng: 120.89818159391766,
                    lat: -3.602105401222794
                },
                {
                    lng: 120.97238895068878,
                    lat: -2.627642917494939
                },
                {
                    lng: 120.30545291552986,
                    lat: -2.931603692235733
                },
                {
                    lng: 120.39004723519167,
                    lat: -4.097579034037274
                },
                {
                    lng: 120.43071658740537,
                    lat: -5.528241062037793
                },
                {
                    lng: 119.79654341031949,
                    lat: -5.673400160345665
                },
                {
                    lng: 119.36690555224489,
                    lat: -5.37987802492782
                },
                {
                    lng: 119.65360639860017,
                    lat: -4.459417412944973
                },
                {
                    lng: 119.49883548388601,
                    lat: -3.494411716326532
                },
                {
                    lng: 119.07834435432704,
                    lat: -3.487021986508793
                },
                {
                    lng: 118.76776899625287,
                    lat: -2.801999200047718
                },
                {
                    lng: 119.18097374885869,
                    lat: -2.147103773612805
                },
                {
                    lng: 119.3233939962551,
                    lat: -1.353147067880464
                },
                {
                    lng: 119.82599897672587,
                    lat: 0.154254462073482
                },
                {
                    lng: 120.0357019389663,
                    lat: 0.566477362465761
                },
                {
                    lng: 120.88577925016762,
                    lat: 1.30922272379685
                },
                {
                    lng: 121.66681684782696,
                    lat: 1.013943589681091
                },
                {
                    lng: 122.9275667664518,
                    lat: 0.875192368977409
                }
            ],
            [
                {
                    lng: 120.29501427620689,
                    lat: -10.258649997603591
                },
                {
                    lng: 118.96780846565471,
                    lat: -9.557969252158074
                },
                {
                    lng: 119.90030968636157,
                    lat: -9.361340427287502
                },
                {
                    lng: 120.42575564990534,
                    lat: -9.665921319215798
                },
                {
                    lng: 120.77550174365675,
                    lat: -9.96967538822743
                },
                {
                    lng: 120.71560875863045,
                    lat: -10.239581394087885
                },
                {
                    lng: 120.29501427620689,
                    lat: -10.258649997603591
                }
            ],
            [
                {
                    lng: 121.34166873584651,
                    lat: -8.536739597206072
                },
                {
                    lng: 122.00736453663043,
                    lat: -8.460620212440148
                },
                {
                    lng: 122.90353722543607,
                    lat: -8.094234307490765
                },
                {
                    lng: 122.75698286345632,
                    lat: -8.649807631060696
                },
                {
                    lng: 121.2544905945701,
                    lat: -8.933666273639957
                },
                {
                    lng: 119.92439090380958,
                    lat: -8.81041798262384
                },
                {
                    lng: 119.92092858284605,
                    lat: -8.444858900591122
                },
                {
                    lng: 120.71509199430757,
                    lat: -8.236964613480914
                },
                {
                    lng: 121.34166873584651,
                    lat: -8.536739597206072
                }
            ],
            [
                {
                    lng: 118.26061648974044,
                    lat: -8.362383314653293
                },
                {
                    lng: 118.87845991422208,
                    lat: -8.280682875199844
                },
                {
                    lng: 119.12650678922307,
                    lat: -8.705824883665088
                },
                {
                    lng: 117.97040164598928,
                    lat: -8.906639499551304
                },
                {
                    lng: 117.27773074754901,
                    lat: -9.040894870645594
                },
                {
                    lng: 116.74014082241665,
                    lat: -9.032936700072646
                },
                {
                    lng: 117.0837374207253,
                    lat: -8.45715789147659
                },
                {
                    lng: 117.6320243673421,
                    lat: -8.449303073768228
                },
                {
                    lng: 117.90001834520776,
                    lat: -8.09568124759494
                },
                {
                    lng: 118.26061648974044,
                    lat: -8.362383314653293
                }
            ],
            [
                {
                    lng: 108.48684614464926,
                    lat: -6.42198495852574
                },
                {
                    lng: 108.62347863162896,
                    lat: -6.777673841990705
                },
                {
                    lng: 110.53922732955328,
                    lat: -6.877357679881726
                },
                {
                    lng: 110.75957563684585,
                    lat: -6.465186455921747
                },
                {
                    lng: 112.6148112325564,
                    lat: -6.946035658397626
                },
                {
                    lng: 112.97876834518806,
                    lat: -7.594213148634594
                },
                {
                    lng: 114.47893517462114,
                    lat: -7.776527601760328
                },
                {
                    lng: 115.70552697150106,
                    lat: -8.370806573116873
                },
                {
                    lng: 114.56451134649649,
                    lat: -8.751816908404855
                },
                {
                    lng: 113.46473351446085,
                    lat: -8.348947442257405
                },
                {
                    lng: 112.55967247930097,
                    lat: -8.376180922075221
                },
                {
                    lng: 111.52206139531245,
                    lat: -8.302128594600973
                },
                {
                    lng: 110.58614953007432,
                    lat: -8.122604668819001
                },
                {
                    lng: 109.42766727095511,
                    lat: -7.740664157749762
                },
                {
                    lng: 108.69365522668133,
                    lat: -7.641600437046243
                },
                {
                    lng: 108.27776329959633,
                    lat: -7.766657403192576
                },
                {
                    lng: 106.45410200401612,
                    lat: -7.354899590690934
                },
                {
                    lng: 106.28062422081231,
                    lat: -6.924899997590252
                },
                {
                    lng: 105.36548628135552,
                    lat: -6.851416110871206
                },
                {
                    lng: 106.05164594932702,
                    lat: -5.895918877794472
                },
                {
                    lng: 107.2650085795402,
                    lat: -5.954985039904081
                },
                {
                    lng: 108.07209109907467,
                    lat: -6.345762220895224
                },
                {
                    lng: 108.48684614464926,
                    lat: -6.42198495852574
                }
            ],
            [
                {
                    lng: 104.36999148968489,
                    lat: -1.084843031421059
                },
                {
                    lng: 104.53949018760221,
                    lat: -1.782371514496766
                },
                {
                    lng: 104.88789269411402,
                    lat: -2.340425306816705
                },
                {
                    lng: 105.62211144411697,
                    lat: -2.428843682468099
                },
                {
                    lng: 106.10859337771265,
                    lat: -3.061776625178965
                },
                {
                    lng: 105.85744591677414,
                    lat: -4.305524997579774
                },
                {
                    lng: 105.8176550639094,
                    lat: -5.85235564537242
                },
                {
                    lng: 104.71038414919144,
                    lat: -5.873284600450632
                },
                {
                    lng: 103.86821333213078,
                    lat: -5.037314955264996
                },
                {
                    lng: 102.5842606954069,
                    lat: -4.220258884298183
                },
                {
                    lng: 102.156173130301,
                    lat: -3.614146009946801
                },
                {
                    lng: 101.39911339722507,
                    lat: -2.799777113459164
                },
                {
                    lng: 100.90250288290015,
                    lat: -2.050262139497832
                },
                {
                    lng: 100.14198082886065,
                    lat: -0.650347588710986
                },
                {
                    lng: 99.26373986206028,
                    lat: 0.183141587724634
                },
                {
                    lng: 98.97001102091326,
                    lat: 1.042882391764536
                },
                {
                    lng: 98.60135135294306,
                    lat: 1.823506577965574
                },
                {
                    lng: 97.69959760944985,
                    lat: 2.45318390544206
                },
                {
                    lng: 97.17694217324984,
                    lat: 3.308790594898596
                },
                {
                    lng: 96.42401655475726,
                    lat: 3.868859768077925
                },
                {
                    lng: 95.3808760925135,
                    lat: 4.970782172053688
                },
                {
                    lng: 95.29302615761729,
                    lat: 5.479820868344788
                },
                {
                    lng: 95.93686282754174,
                    lat: 5.439513251157123
                },
                {
                    lng: 97.4848820332771,
                    lat: 5.246320909033955
                },
                {
                    lng: 98.36916914265566,
                    lat: 4.268370266126396
                },
                {
                    lng: 99.1425586283358,
                    lat: 3.590349636240873
                },
                {
                    lng: 99.69399783732241,
                    lat: 3.174328518075143
                },
                {
                    lng: 100.64143354696162,
                    lat: 2.099381211755741
                },
                {
                    lng: 101.65801232300734,
                    lat: 2.083697414555161
                },
                {
                    lng: 102.49827111207323,
                    lat: 1.398700466310231
                },
                {
                    lng: 103.07684044801303,
                    lat: 0.561361395668868
                },
                {
                    lng: 103.83839603069836,
                    lat: 0.104541734208695
                },
                {
                    lng: 103.4376452982749,
                    lat: -0.711945896002902
                },
                {
                    lng: 104.01078860882404,
                    lat: -1.059211521004286
                },
                {
                    lng: 104.36999148968489,
                    lat: -1.084843031421059
                }
            ]
        ]
    },
    AR: {
        name: 'Argentina',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: -68.63401022758323,
                    lat: -52.63637045887449
                },
                {
                    lng: -68.25,
                    lat: -53.1
                },
                {
                    lng: -67.75,
                    lat: -53.85
                },
                {
                    lng: -66.45,
                    lat: -54.45
                },
                {
                    lng: -65.05,
                    lat: -54.7
                },
                {
                    lng: -65.5,
                    lat: -55.2
                },
                {
                    lng: -66.45,
                    lat: -55.25
                },
                {
                    lng: -66.95992,
                    lat: -54.89681
                },
                {
                    lng: -67.56244,
                    lat: -54.87001
                },
                {
                    lng: -68.63335,
                    lat: -54.8695
                },
                {
                    lng: -68.63401022758323,
                    lat: -52.63637045887449
                }
            ],
            [
                {
                    lng: -57.62513342958296,
                    lat: -30.21629485445426
                },
                {
                    lng: -57.87493730328188,
                    lat: -31.016556084926208
                },
                {
                    lng: -58.14244035504076,
                    lat: -32.044503676076154
                },
                {
                    lng: -58.13264767112145,
                    lat: -33.040566908502015
                },
                {
                    lng: -58.349611172098875,
                    lat: -33.26318897881541
                },
                {
                    lng: -58.42707414410439,
                    lat: -33.909454441057576
                },
                {
                    lng: -58.49544206402655,
                    lat: -34.43148976007008
                },
                {
                    lng: -57.22582963726366,
                    lat: -35.28802662530788
                },
                {
                    lng: -57.36235877137878,
                    lat: -35.977390232081476
                },
                {
                    lng: -56.73748735210545,
                    lat: -36.41312590916655
                },
                {
                    lng: -56.78828528504836,
                    lat: -36.901571547189334
                },
                {
                    lng: -57.74915686708346,
                    lat: -38.18387053807989
                },
                {
                    lng: -59.23185706240189,
                    lat: -38.720220228837235
                },
                {
                    lng: -61.23744523786564,
                    lat: -38.9284245745412
                },
                {
                    lng: -62.33595699731013,
                    lat: -38.827707208004334
                },
                {
                    lng: -62.125763108962936,
                    lat: -39.42410491308485
                },
                {
                    lng: -62.330530971919494,
                    lat: -40.17258635840034
                },
                {
                    lng: -62.145994432205214,
                    lat: -40.67689666113672
                },
                {
                    lng: -62.745802781816984,
                    lat: -41.0287614886121
                },
                {
                    lng: -63.77049475773255,
                    lat: -41.16678923926369
                },
                {
                    lng: -64.73208980981973,
                    lat: -40.80267709733515
                },
                {
                    lng: -65.11803524439158,
                    lat: -41.06431487402891
                },
                {
                    lng: -64.97856055363582,
                    lat: -42.05800099056934
                },
                {
                    lng: -64.3034079657425,
                    lat: -42.35901620866951
                },
                {
                    lng: -63.75594784204239,
                    lat: -42.043686618824495
                },
                {
                    lng: -63.458059048095876,
                    lat: -42.563138116222405
                },
                {
                    lng: -64.37880388045633,
                    lat: -42.87355844499969
                },
                {
                    lng: -65.18180396183975,
                    lat: -43.495380954767796
                },
                {
                    lng: -65.32882341171013,
                    lat: -44.501366062193696
                },
                {
                    lng: -65.5652689276616,
                    lat: -45.036785577169795
                },
                {
                    lng: -66.50996578638934,
                    lat: -45.03962778094586
                },
                {
                    lng: -67.29379391139247,
                    lat: -45.55189625425519
                },
                {
                    lng: -67.58054643418008,
                    lat: -46.30177296324257
                },
                {
                    lng: -66.59706641301729,
                    lat: -47.033924655953825
                },
                {
                    lng: -65.64102657740149,
                    lat: -47.23613453551193
                },
                {
                    lng: -65.98508826360079,
                    lat: -48.133289076531135
                },
                {
                    lng: -67.16617896184769,
                    lat: -48.697337334996945
                },
                {
                    lng: -67.81608761256643,
                    lat: -49.86966887797038
                },
                {
                    lng: -68.72874508327321,
                    lat: -50.26421843851883
                },
                {
                    lng: -69.13853919134777,
                    lat: -50.732510267947795
                },
                {
                    lng: -68.81556148952356,
                    lat: -51.771104011594126
                },
                {
                    lng: -68.14999487982038,
                    lat: -52.34998340612768
                },
                {
                    lng: -68.57154537624133,
                    lat: -52.299443855346226
                },
                {
                    lng: -69.49836218939609,
                    lat: -52.14276091263727
                },
                {
                    lng: -71.91480383979638,
                    lat: -52.0090223058659
                },
                {
                    lng: -72.32940385607407,
                    lat: -51.42595631287243
                },
                {
                    lng: -72.30997351753234,
                    lat: -50.67700977966632
                },
                {
                    lng: -72.97574683296469,
                    lat: -50.741450290734285
                },
                {
                    lng: -73.32805091011453,
                    lat: -50.378785088909915
                },
                {
                    lng: -73.4154357571201,
                    lat: -49.31843637471297
                },
                {
                    lng: -72.64824744331494,
                    lat: -48.87861825947683
                },
                {
                    lng: -72.33116085477201,
                    lat: -48.2442383766618
                },
                {
                    lng: -72.44735531278027,
                    lat: -47.73853281025352
                },
                {
                    lng: -71.91725847033024,
                    lat: -46.88483814879177
                },
                {
                    lng: -71.55200944689128,
                    lat: -45.5607329241771
                },
                {
                    lng: -71.65931555854536,
                    lat: -44.973688653341426
                },
                {
                    lng: -71.22277889675976,
                    lat: -44.784242852559416
                },
                {
                    lng: -71.32980078803622,
                    lat: -44.407521661151655
                },
                {
                    lng: -71.79362260607193,
                    lat: -44.207172133156064
                },
                {
                    lng: -71.46405615913051,
                    lat: -43.787611179378345
                },
                {
                    lng: -71.91542395698389,
                    lat: -43.40856454851745
                },
                {
                    lng: -72.14889807807856,
                    lat: -42.254888197601375
                },
                {
                    lng: -71.7468037584155,
                    lat: -42.05138640723598
                },
                {
                    lng: -71.91573401557763,
                    lat: -40.83233936947069
                },
                {
                    lng: -71.68076127794649,
                    lat: -39.808164157878046
                },
                {
                    lng: -71.41351660834906,
                    lat: -38.91602223079114
                },
                {
                    lng: -70.81466427273469,
                    lat: -38.55299529394074
                },
                {
                    lng: -71.11862504747549,
                    lat: -37.57682748794724
                },
                {
                    lng: -71.12188066270987,
                    lat: -36.65812387466232
                },
                {
                    lng: -70.36476925320164,
                    lat: -36.00508879978992
                },
                {
                    lng: -70.38804948594913,
                    lat: -35.16968759535949
                },
                {
                    lng: -69.81730912950152,
                    lat: -34.1935714657983
                },
                {
                    lng: -69.81477698431922,
                    lat: -33.273886000299825
                },
                {
                    lng: -70.0743993801536,
                    lat: -33.09120981214805
                },
                {
                    lng: -70.53506893581951,
                    lat: -31.36501026787031
                },
                {
                    lng: -69.91900834825194,
                    lat: -30.33633920666828
                },
                {
                    lng: -70.01355038112992,
                    lat: -29.367922865518572
                },
                {
                    lng: -69.65613033718317,
                    lat: -28.459141127233686
                },
                {
                    lng: -69.00123491074825,
                    lat: -27.52121388113618
                },
                {
                    lng: -68.29554155137043,
                    lat: -26.89933969493578
                },
                {
                    lng: -68.59479977077268,
                    lat: -26.506908868111296
                },
                {
                    lng: -68.38600114609736,
                    lat: -26.185016371365215
                },
                {
                    lng: -68.41765296087614,
                    lat: -24.51855478281688
                },
                {
                    lng: -67.32844295924417,
                    lat: -24.02530323659095
                },
                {
                    lng: -66.9852339341777,
                    lat: -22.98634856536284
                },
                {
                    lng: -67.1066735500636,
                    lat: -22.735924574476417
                },
                {
                    lng: -66.27333940292485,
                    lat: -21.83231047942072
                },
                {
                    lng: -64.96489213729461,
                    lat: -22.075861504812327
                },
                {
                    lng: -64.37702104354226,
                    lat: -22.79809132252354
                },
                {
                    lng: -63.986838141522476,
                    lat: -21.99364430103595
                },
                {
                    lng: -62.84646847192156,
                    lat: -22.03498544686945
                },
                {
                    lng: -62.685057135657885,
                    lat: -22.249029229422387
                },
                {
                    lng: -60.846564704009914,
                    lat: -23.880712579038292
                },
                {
                    lng: -60.02896603050403,
                    lat: -24.032796319273274
                },
                {
                    lng: -58.80712846539498,
                    lat: -24.77145924245331
                },
                {
                    lng: -57.77721716981794,
                    lat: -25.16233977630904
                },
                {
                    lng: -57.63366004091113,
                    lat: -25.60365650808164
                },
                {
                    lng: -58.61817359071975,
                    lat: -27.123718763947096
                },
                {
                    lng: -57.60975969097614,
                    lat: -27.395898532828387
                },
                {
                    lng: -56.486701626192996,
                    lat: -27.548499037386293
                },
                {
                    lng: -55.69584550639816,
                    lat: -27.387837009390864
                },
                {
                    lng: -54.78879492859505,
                    lat: -26.621785577096134
                },
                {
                    lng: -54.625290696823576,
                    lat: -25.739255466415514
                },
                {
                    lng: -54.13004960795439,
                    lat: -25.547639255477254
                },
                {
                    lng: -53.628348965048744,
                    lat: -26.124865004177472
                },
                {
                    lng: -53.64873531758789,
                    lat: -26.92347258881609
                },
                {
                    lng: -54.490725267135524,
                    lat: -27.47475676850579
                },
                {
                    lng: -55.16228634298457,
                    lat: -27.881915378533463
                },
                {
                    lng: -56.29089962423908,
                    lat: -28.852760512000895
                },
                {
                    lng: -57.62513342958296,
                    lat: -30.21629485445426
                }
            ]
        ]
    },
    CL: {
        name: 'Chile',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: -68.63401022758323,
                    lat: -52.63637045887449
                },
                {
                    lng: -68.63335,
                    lat: -54.8695
                },
                {
                    lng: -67.56244,
                    lat: -54.87001
                },
                {
                    lng: -66.95992,
                    lat: -54.89681
                },
                {
                    lng: -67.29103,
                    lat: -55.30124
                },
                {
                    lng: -68.14863,
                    lat: -55.61183
                },
                {
                    lng: -68.63999081081187,
                    lat: -55.58001799908692
                },
                {
                    lng: -69.2321,
                    lat: -55.49906
                },
                {
                    lng: -69.95809,
                    lat: -55.19843
                },
                {
                    lng: -71.00568,
                    lat: -55.05383
                },
                {
                    lng: -72.2639,
                    lat: -54.49514
                },
                {
                    lng: -73.2852,
                    lat: -53.95752
                },
                {
                    lng: -74.66253,
                    lat: -52.83749
                },
                {
                    lng: -73.8381,
                    lat: -53.04743
                },
                {
                    lng: -72.43418,
                    lat: -53.7154
                },
                {
                    lng: -71.10773,
                    lat: -54.07433
                },
                {
                    lng: -70.59177999999986,
                    lat: -53.61583
                },
                {
                    lng: -70.26748,
                    lat: -52.93123
                },
                {
                    lng: -69.34565,
                    lat: -52.5183
                },
                {
                    lng: -68.63401022758323,
                    lat: -52.63637045887449
                }
            ],
            [
                {
                    lng: -69.59042375352405,
                    lat: -17.580011895419332
                },
                {
                    lng: -69.10024695501949,
                    lat: -18.260125420812678
                },
                {
                    lng: -68.96681840684187,
                    lat: -18.981683444904107
                },
                {
                    lng: -68.44222510443092,
                    lat: -19.40506845467143
                },
                {
                    lng: -68.75716712103375,
                    lat: -20.372657972904463
                },
                {
                    lng: -68.21991309271128,
                    lat: -21.494346612231865
                },
                {
                    lng: -67.82817989772273,
                    lat: -22.872918796482175
                },
                {
                    lng: -67.1066735500636,
                    lat: -22.735924574476417
                },
                {
                    lng: -66.9852339341777,
                    lat: -22.98634856536284
                },
                {
                    lng: -67.32844295924417,
                    lat: -24.02530323659095
                },
                {
                    lng: -68.41765296087614,
                    lat: -24.51855478281688
                },
                {
                    lng: -68.38600114609736,
                    lat: -26.185016371365215
                },
                {
                    lng: -68.59479977077268,
                    lat: -26.506908868111296
                },
                {
                    lng: -68.29554155137043,
                    lat: -26.89933969493578
                },
                {
                    lng: -69.00123491074825,
                    lat: -27.52121388113618
                },
                {
                    lng: -69.65613033718317,
                    lat: -28.459141127233686
                },
                {
                    lng: -70.01355038112992,
                    lat: -29.367922865518572
                },
                {
                    lng: -69.91900834825194,
                    lat: -30.33633920666828
                },
                {
                    lng: -70.53506893581951,
                    lat: -31.36501026787031
                },
                {
                    lng: -70.0743993801536,
                    lat: -33.09120981214805
                },
                {
                    lng: -69.81477698431922,
                    lat: -33.273886000299825
                },
                {
                    lng: -69.81730912950152,
                    lat: -34.1935714657983
                },
                {
                    lng: -70.38804948594913,
                    lat: -35.16968759535949
                },
                {
                    lng: -70.36476925320164,
                    lat: -36.00508879978992
                },
                {
                    lng: -71.12188066270987,
                    lat: -36.65812387466232
                },
                {
                    lng: -71.11862504747549,
                    lat: -37.57682748794724
                },
                {
                    lng: -70.81466427273469,
                    lat: -38.55299529394074
                },
                {
                    lng: -71.41351660834906,
                    lat: -38.91602223079114
                },
                {
                    lng: -71.68076127794649,
                    lat: -39.808164157878046
                },
                {
                    lng: -71.91573401557763,
                    lat: -40.83233936947069
                },
                {
                    lng: -71.7468037584155,
                    lat: -42.05138640723598
                },
                {
                    lng: -72.14889807807856,
                    lat: -42.254888197601375
                },
                {
                    lng: -71.91542395698389,
                    lat: -43.40856454851745
                },
                {
                    lng: -71.46405615913051,
                    lat: -43.787611179378345
                },
                {
                    lng: -71.79362260607193,
                    lat: -44.207172133156064
                },
                {
                    lng: -71.32980078803622,
                    lat: -44.407521661151655
                },
                {
                    lng: -71.22277889675976,
                    lat: -44.784242852559416
                },
                {
                    lng: -71.65931555854536,
                    lat: -44.973688653341426
                },
                {
                    lng: -71.55200944689128,
                    lat: -45.5607329241771
                },
                {
                    lng: -71.91725847033024,
                    lat: -46.88483814879177
                },
                {
                    lng: -72.44735531278027,
                    lat: -47.73853281025352
                },
                {
                    lng: -72.33116085477201,
                    lat: -48.2442383766618
                },
                {
                    lng: -72.64824744331494,
                    lat: -48.87861825947683
                },
                {
                    lng: -73.4154357571201,
                    lat: -49.31843637471297
                },
                {
                    lng: -73.32805091011453,
                    lat: -50.378785088909915
                },
                {
                    lng: -72.97574683296469,
                    lat: -50.741450290734285
                },
                {
                    lng: -72.30997351753234,
                    lat: -50.67700977966632
                },
                {
                    lng: -72.32940385607407,
                    lat: -51.42595631287243
                },
                {
                    lng: -71.91480383979638,
                    lat: -52.0090223058659
                },
                {
                    lng: -69.49836218939609,
                    lat: -52.14276091263727
                },
                {
                    lng: -68.57154537624133,
                    lat: -52.299443855346226
                },
                {
                    lng: -69.46128434922667,
                    lat: -52.29195077266391
                },
                {
                    lng: -69.9427795071062,
                    lat: -52.53793059037322
                },
                {
                    lng: -70.8451016913546,
                    lat: -52.89920052852571
                },
                {
                    lng: -71.00633216010525,
                    lat: -53.83325204220132
                },
                {
                    lng: -71.429794684521,
                    lat: -53.85645476030037
                },
                {
                    lng: -72.55794287788488,
                    lat: -53.53141000118449
                },
                {
                    lng: -73.7027567206629,
                    lat: -52.835069268607235
                },
                {
                    lng: -73.7027567206629,
                    lat: -52.835070076051494
                },
                {
                    lng: -74.94676347522517,
                    lat: -52.262753588419
                },
                {
                    lng: -75.2600260077785,
                    lat: -51.62935475037325
                },
                {
                    lng: -74.97663245308988,
                    lat: -51.0433956846157
                },
                {
                    lng: -75.47975419788355,
                    lat: -50.37837167745158
                },
                {
                    lng: -75.60801510283198,
                    lat: -48.67377288187184
                },
                {
                    lng: -75.18276974150216,
                    lat: -47.7119194476232
                },
                {
                    lng: -74.1265809801047,
                    lat: -46.93925343199511
                },
                {
                    lng: -75.64439531116545,
                    lat: -46.64764332457207
                },
                {
                    lng: -74.69215369332312,
                    lat: -45.76397633238103
                },
                {
                    lng: -74.35170935738425,
                    lat: -44.10304412208794
                },
                {
                    lng: -73.24035600451522,
                    lat: -44.454960625995604
                },
                {
                    lng: -72.7178039211798,
                    lat: -42.38335580827898
                },
                {
                    lng: -73.38889990913822,
                    lat: -42.117532240569574
                },
                {
                    lng: -73.70133561877488,
                    lat: -43.365776462579774
                },
                {
                    lng: -74.33194312203261,
                    lat: -43.22495818458442
                },
                {
                    lng: -74.0179571194272,
                    lat: -41.79481292090683
                },
                {
                    lng: -73.67709937202999,
                    lat: -39.94221282324317
                },
                {
                    lng: -73.21759253609065,
                    lat: -39.25868865331856
                },
                {
                    lng: -73.50555945503712,
                    lat: -38.282882582351114
                },
                {
                    lng: -73.58806087919109,
                    lat: -37.15628468195598
                },
                {
                    lng: -73.1667170884993,
                    lat: -37.12378020604439
                },
                {
                    lng: -72.55313696968174,
                    lat: -35.50884002049106
                },
                {
                    lng: -71.86173214383263,
                    lat: -33.90909270603153
                },
                {
                    lng: -71.4384504869299,
                    lat: -32.41889942803078
                },
                {
                    lng: -71.66872066922247,
                    lat: -30.920644626592495
                },
                {
                    lng: -71.37008256700773,
                    lat: -30.09568206148503
                },
                {
                    lng: -71.48989437527645,
                    lat: -28.861442152625923
                },
                {
                    lng: -70.90512386746161,
                    lat: -27.640379734001247
                },
                {
                    lng: -70.72495398627599,
                    lat: -25.705924167587256
                },
                {
                    lng: -70.40396582709502,
                    lat: -23.628996677344574
                },
                {
                    lng: -70.09124589708074,
                    lat: -21.39331918710126
                },
                {
                    lng: -70.16441972520605,
                    lat: -19.756468194256165
                },
                {
                    lng: -70.37257239447771,
                    lat: -18.34797535570887
                },
                {
                    lng: -69.85844356960587,
                    lat: -18.092693780187012
                },
                {
                    lng: -69.59042375352405,
                    lat: -17.580011895419332
                }
            ]
        ]
    },
    CD: {
        name: 'Congo, Dem. Rep.',
        type: 'Polygon',
        coordinates: [
            {
                lng: 29.339997592900346,
                lat: -4.499983412294092
            },
            {
                lng: 29.519986606572928,
                lat: -5.419978936386315
            },
            {
                lng: 29.419992710088167,
                lat: -5.939998874539434
            },
            {
                lng: 29.620032179490014,
                lat: -6.520015150583426
            },
            {
                lng: 30.199996779101696,
                lat: -7.079980970898163
            },
            {
                lng: 30.74001549655179,
                lat: -8.340007419470915
            },
            {
                lng: 30.740009731422095,
                lat: -8.34000593035372
            },
            {
                lng: 30.346086053190817,
                lat: -8.238256524288218
            },
            {
                lng: 29.00291222506047,
                lat: -8.407031752153472
            },
            {
                lng: 28.734866570762502,
                lat: -8.526559340044578
            },
            {
                lng: 28.449871046672826,
                lat: -9.164918308146085
            },
            {
                lng: 28.67368167492893,
                lat: -9.605924981324932
            },
            {
                lng: 28.49606977714177,
                lat: -10.789883721564046
            },
            {
                lng: 28.372253045370428,
                lat: -11.793646742401393
            },
            {
                lng: 28.642417433392353,
                lat: -11.971568698782315
            },
            {
                lng: 29.34154788586909,
                lat: -12.360743910372413
            },
            {
                lng: 29.61600141777123,
                lat: -12.178894545137311
            },
            {
                lng: 29.69961388521949,
                lat: -13.257226657771831
            },
            {
                lng: 28.934285922976837,
                lat: -13.248958428605135
            },
            {
                lng: 28.523561639121027,
                lat: -12.698604424696683
            },
            {
                lng: 28.155108676879987,
                lat: -12.272480564017897
            },
            {
                lng: 27.388798862423783,
                lat: -12.132747491100666
            },
            {
                lng: 27.164419793412463,
                lat: -11.608748467661075
            },
            {
                lng: 26.553087599399618,
                lat: -11.924439792532127
            },
            {
                lng: 25.752309604604733,
                lat: -11.784965101776358
            },
            {
                lng: 25.418118116973204,
                lat: -11.330935967659961
            },
            {
                lng: 24.78316979340295,
                lat: -11.238693536018964
            },
            {
                lng: 24.31451622894795,
                lat: -11.26282642989927
            },
            {
                lng: 24.25715538910399,
                lat: -10.951992689663657
            },
            {
                lng: 23.912215203555718,
                lat: -10.926826267137514
            },
            {
                lng: 23.45679080576744,
                lat: -10.867863457892483
            },
            {
                lng: 22.83734541188474,
                lat: -11.01762175867433
            },
            {
                lng: 22.402798292742375,
                lat: -10.993075453335692
            },
            {
                lng: 22.155268182064308,
                lat: -11.084801120653772
            },
            {
                lng: 22.208753289486395,
                lat: -9.894796237836509
            },
            {
                lng: 21.875181919042348,
                lat: -9.523707777548566
            },
            {
                lng: 21.8018013851879,
                lat: -8.90870655684298
            },
            {
                lng: 21.949130893652043,
                lat: -8.305900974158277
            },
            {
                lng: 21.74645592620331,
                lat: -7.920084730667149
            },
            {
                lng: 21.7281107927397,
                lat: -7.290872491081302
            },
            {
                lng: 20.5147481625265,
                lat: -7.299605808138629
            },
            {
                lng: 20.6018229509383,
                lat: -6.939317722199682
            },
            {
                lng: 20.091621534920648,
                lat: -6.943090101756994
            },
            {
                lng: 20.037723016040218,
                lat: -7.116361179231646
            },
            {
                lng: 19.41750247567316,
                lat: -7.155428562044299
            },
            {
                lng: 19.16661339689611,
                lat: -7.738183688999754
            },
            {
                lng: 19.01675174324967,
                lat: -7.988245944860132
            },
            {
                lng: 18.464175652752687,
                lat: -7.847014255406443
            },
            {
                lng: 18.13422163256905,
                lat: -7.987677504104923
            },
            {
                lng: 17.472970004962235,
                lat: -8.0685511206417
            },
            {
                lng: 17.08999596524717,
                lat: -7.545688978712526
            },
            {
                lng: 16.8601908708452,
                lat: -7.222297865429987
            },
            {
                lng: 16.573179965896145,
                lat: -6.622644545115087
            },
            {
                lng: 16.326528354567046,
                lat: -5.877470391466268
            },
            {
                lng: 13.375597364971895,
                lat: -5.864241224799549
            },
            {
                lng: 13.024869419006961,
                lat: -5.984388929878158
            },
            {
                lng: 12.735171339578699,
                lat: -5.965682061388499
            },
            {
                lng: 12.32243167486351,
                lat: -6.10009246177966
            },
            {
                lng: 12.182336866920252,
                lat: -5.789930515163839
            },
            {
                lng: 12.436688266660868,
                lat: -5.684303887559246
            },
            {
                lng: 12.468004184629736,
                lat: -5.248361504745005
            },
            {
                lng: 12.63161176926579,
                lat: -4.991271254092936
            },
            {
                lng: 12.995517205465177,
                lat: -4.781103203961884
            },
            {
                lng: 13.258240187237048,
                lat: -4.882957452009165
            },
            {
                lng: 13.600234816144678,
                lat: -4.50013844159097
            },
            {
                lng: 14.144956088933299,
                lat: -4.510008640158716
            },
            {
                lng: 14.209034864975223,
                lat: -4.793092136253598
            },
            {
                lng: 14.582603794013181,
                lat: -4.97023894615014
            },
            {
                lng: 15.170991652088444,
                lat: -4.343507175314301
            },
            {
                lng: 15.753540073314753,
                lat: -3.855164890156097
            },
            {
                lng: 16.0062895036543,
                lat: -3.535132744972529
            },
            {
                lng: 15.972803175529151,
                lat: -2.712392266453612
            },
            {
                lng: 16.407091912510054,
                lat: -1.740927015798682
            },
            {
                lng: 16.865306837642123,
                lat: -1.225816338713287
            },
            {
                lng: 17.523716261472856,
                lat: -0.743830254726987
            },
            {
                lng: 17.638644646889986,
                lat: -0.424831638189247
            },
            {
                lng: 17.66355268725468,
                lat: -0.058083998213817
            },
            {
                lng: 17.826540154703252,
                lat: 0.288923244626105
            },
            {
                lng: 17.774191928791566,
                lat: 0.855658677571085
            },
            {
                lng: 17.898835483479587,
                lat: 1.741831976728278
            },
            {
                lng: 18.094275750407434,
                lat: 2.365721543788055
            },
            {
                lng: 18.393792351971143,
                lat: 2.90044342692822
            },
            {
                lng: 18.45306521980993,
                lat: 3.504385891123349
            },
            {
                lng: 18.54298221199778,
                lat: 4.201785183118318
            },
            {
                lng: 18.93231245288476,
                lat: 4.709506130385975
            },
            {
                lng: 19.46778364429315,
                lat: 5.03152781821278
            },
            {
                lng: 20.290679152108936,
                lat: 4.691677761245288
            },
            {
                lng: 20.927591180106276,
                lat: 4.322785549329737
            },
            {
                lng: 21.659122755630023,
                lat: 4.22434194581372
            },
            {
                lng: 22.405123732195538,
                lat: 4.029160061047321
            },
            {
                lng: 22.70412356943629,
                lat: 4.633050848810157
            },
            {
                lng: 22.841479526468106,
                lat: 4.710126247573484
            },
            {
                lng: 23.29721398285014,
                lat: 4.609693101414223
            },
            {
                lng: 24.410531040146253,
                lat: 5.10878408448913
            },
            {
                lng: 24.805028924262416,
                lat: 4.89724660890235
            },
            {
                lng: 25.12883344900328,
                lat: 4.92724477784779
            },
            {
                lng: 25.278798455514305,
                lat: 5.170408229997192
            },
            {
                lng: 25.650455356557472,
                lat: 5.256087754737123
            },
            {
                lng: 26.402760857862543,
                lat: 5.150874538590871
            },
            {
                lng: 27.04406538260471,
                lat: 5.127852688004836
            },
            {
                lng: 27.37422610851749,
                lat: 5.233944403500061
            },
            {
                lng: 27.97997724784281,
                lat: 4.408413397637375
            },
            {
                lng: 28.428993768026913,
                lat: 4.287154649264494
            },
            {
                lng: 28.696677687298802,
                lat: 4.455077215996937
            },
            {
                lng: 29.1590784034465,
                lat: 4.389267279473231
            },
            {
                lng: 29.71599531425602,
                lat: 4.600804755060153
            },
            {
                lng: 29.953500197069474,
                lat: 4.173699042167684
            },
            {
                lng: 30.833852421715427,
                lat: 3.509171604222463
            },
            {
                lng: 30.83385989759381,
                lat: 3.509165961110341
            },
            {
                lng: 30.77334679538004,
                lat: 2.339883327642127
            },
            {
                lng: 31.174149204235817,
                lat: 2.204465236821264
            },
            {
                lng: 30.85267011894806,
                lat: 1.849396470543809
            },
            {
                lng: 30.46850752129029,
                lat: 1.583805446779706
            },
            {
                lng: 30.086153598762706,
                lat: 1.062312730306289
            },
            {
                lng: 29.875778842902434,
                lat: 0.597379868976361
            },
            {
                lng: 29.819503208136638,
                lat: -0.205310153813372
            },
            {
                lng: 29.58783776217217,
                lat: -0.587405694179381
            },
            {
                lng: 29.579466180140884,
                lat: -1.341313164885626
            },
            {
                lng: 29.291886834436614,
                lat: -1.620055840667987
            },
            {
                lng: 29.254834832483343,
                lat: -2.215109958508911
            },
            {
                lng: 29.117478875451553,
                lat: -2.292211195488385
            },
            {
                lng: 29.024926385216787,
                lat: -2.839257907730158
            },
            {
                lng: 29.276383904749053,
                lat: -3.293907159034063
            },
            {
                lng: 29.339997592900346,
                lat: -4.499983412294092
            }
        ]
    },
    SO: {
        name: 'Somalia',
        type: 'Polygon',
        coordinates: [
            {
                lng: 41.58513,
                lat: -1.68325
            },
            {
                lng: 40.993,
                lat: -0.85829
            },
            {
                lng: 40.98105,
                lat: 2.78452
            },
            {
                lng: 41.85508309264397,
                lat: 3.918911920483727
            },
            {
                lng: 42.12861,
                lat: 4.23413
            },
            {
                lng: 42.76967,
                lat: 4.25259
            },
            {
                lng: 43.66087,
                lat: 4.95755
            },
            {
                lng: 44.9636,
                lat: 5.00162
            },
            {
                lng: 47.78942,
                lat: 8.003
            },
            {
                lng: 48.48673587422695,
                lat: 8.837626247589995
            },
            {
                lng: 48.93812951029645,
                lat: 9.451748968946617
            },
            {
                lng: 48.93823286316103,
                lat: 9.973500067581512
            },
            {
                lng: 48.938491245322496,
                lat: 10.982327378783467
            },
            {
                lng: 48.94200524271835,
                lat: 11.394266058798138
            },
            {
                lng: 48.94820475850974,
                lat: 11.410617281697963
            },
            {
                lng: 48.94820475850985,
                lat: 11.41061728169797
            },
            {
                lng: 49.26776,
                lat: 11.43033
            },
            {
                lng: 49.72862,
                lat: 11.5789
            },
            {
                lng: 50.25878,
                lat: 11.67957
            },
            {
                lng: 50.73202,
                lat: 12.0219
            },
            {
                lng: 51.1112,
                lat: 12.02464
            },
            {
                lng: 51.13387,
                lat: 11.74815
            },
            {
                lng: 51.04153,
                lat: 11.16651
            },
            {
                lng: 51.04531,
                lat: 10.6409
            },
            {
                lng: 50.83418,
                lat: 10.27972
            },
            {
                lng: 50.55239,
                lat: 9.19874
            },
            {
                lng: 50.07092,
                lat: 8.08173
            },
            {
                lng: 49.4527,
                lat: 6.80466
            },
            {
                lng: 48.59455,
                lat: 5.33911
            },
            {
                lng: 47.74079,
                lat: 4.2194
            },
            {
                lng: 46.56476,
                lat: 2.85529
            },
            {
                lng: 45.56399,
                lat: 2.04576
            },
            {
                lng: 44.06815,
                lat: 1.05283
            },
            {
                lng: 43.13597,
                lat: 0.2922
            },
            {
                lng: 42.04157,
                lat: -0.91916
            },
            {
                lng: 41.81095,
                lat: -1.44647
            },
            {
                lng: 41.58513,
                lat: -1.68325
            }
        ]
    },
    KE: {
        name: 'Kenya',
        type: 'Polygon',
        coordinates: [
            {
                lng: 39.20222,
                lat: -4.67677
            },
            {
                lng: 37.7669,
                lat: -3.67712
            },
            {
                lng: 37.69869,
                lat: -3.09699
            },
            {
                lng: 34.07262,
                lat: -1.05982
            },
            {
                lng: 33.90371119710453,
                lat: -0.95
            },
            {
                lng: 33.893568969666944,
                lat: 0.109813537861896
            },
            {
                lng: 34.18,
                lat: 0.515
            },
            {
                lng: 34.6721,
                lat: 1.17694
            },
            {
                lng: 35.03599,
                lat: 1.90584
            },
            {
                lng: 34.59607,
                lat: 3.053740000000118
            },
            {
                lng: 34.47913,
                lat: 3.5556
            },
            {
                lng: 34.005,
                lat: 4.249884947362048
            },
            {
                lng: 34.62019626785388,
                lat: 4.847122742081988
            },
            {
                lng: 35.29800711823298,
                lat: 5.506
            },
            {
                lng: 35.817447662353516,
                lat: 5.338232082790797
            },
            {
                lng: 35.817447662353516,
                lat: 4.77696566346189
            },
            {
                lng: 36.159078632855646,
                lat: 4.447864127672769
            },
            {
                lng: 36.85509323800812,
                lat: 4.447864127672769
            },
            {
                lng: 38.120915,
                lat: 3.598605
            },
            {
                lng: 38.43697,
                lat: 3.58851
            },
            {
                lng: 38.67114,
                lat: 3.61607
            },
            {
                lng: 38.89251,
                lat: 3.50074
            },
            {
                lng: 39.55938425876585,
                lat: 3.42206
            },
            {
                lng: 39.85494,
                lat: 3.83879
            },
            {
                lng: 40.76848,
                lat: 4.25702
            },
            {
                lng: 41.1718,
                lat: 3.91909
            },
            {
                lng: 41.85508309264397,
                lat: 3.918911920483727
            },
            {
                lng: 40.98105,
                lat: 2.78452
            },
            {
                lng: 40.993,
                lat: -0.85829
            },
            {
                lng: 41.58513,
                lat: -1.68325
            },
            {
                lng: 40.88477,
                lat: -2.08255
            },
            {
                lng: 40.63785,
                lat: -2.49979
            },
            {
                lng: 40.26304,
                lat: -2.57309
            },
            {
                lng: 40.12119,
                lat: -3.27768
            },
            {
                lng: 39.80006,
                lat: -3.68116
            },
            {
                lng: 39.60489,
                lat: -4.34653
            },
            {
                lng: 39.20222,
                lat: -4.67677
            }
        ]
    },
    SD: {
        name: 'Sudan',
        type: 'Polygon',
        coordinates: [
            {
                lng: 24.567369012152085,
                lat: 8.229187933785468
            },
            {
                lng: 23.805813429466752,
                lat: 8.666318874542526
            },
            {
                lng: 23.459012892355986,
                lat: 8.954285793488893
            },
            {
                lng: 23.394779087017184,
                lat: 9.265067857292223
            },
            {
                lng: 23.55724979014283,
                lat: 9.681218166538684
            },
            {
                lng: 23.554304233502194,
                lat: 10.089255275915308
            },
            {
                lng: 22.97754357269261,
                lat: 10.71446259199854
            },
            {
                lng: 22.864165480244225,
                lat: 11.142395127807546
            },
            {
                lng: 22.87622,
                lat: 11.384610000000123
            },
            {
                lng: 22.50869,
                lat: 11.67936
            },
            {
                lng: 22.49762,
                lat: 12.26024
            },
            {
                lng: 22.28801,
                lat: 12.64605
            },
            {
                lng: 21.93681,
                lat: 12.588180000000136
            },
            {
                lng: 22.03759,
                lat: 12.95546
            },
            {
                lng: 22.29658,
                lat: 13.37232
            },
            {
                lng: 22.18329,
                lat: 13.78648
            },
            {
                lng: 22.51202,
                lat: 14.09318
            },
            {
                lng: 22.30351,
                lat: 14.32682
            },
            {
                lng: 22.56795000000011,
                lat: 14.944290000000137
            },
            {
                lng: 23.024590000000103,
                lat: 15.68072
            },
            {
                lng: 23.886890000000108,
                lat: 15.61084
            },
            {
                lng: 23.83766000000014,
                lat: 19.580470000000105
            },
            {
                lng: 23.850000000000136,
                lat: 20
            },
            {
                lng: 25.000000000000114,
                lat: 20.00304
            },
            {
                lng: 25,
                lat: 22
            },
            {
                lng: 29.02,
                lat: 22
            },
            {
                lng: 32.9,
                lat: 22
            },
            {
                lng: 36.86623,
                lat: 22
            },
            {
                lng: 37.1887200000001,
                lat: 21.01885
            },
            {
                lng: 36.96941,
                lat: 20.83744000000013
            },
            {
                lng: 37.11470000000014,
                lat: 19.80796
            },
            {
                lng: 37.4817900000001,
                lat: 18.61409
            },
            {
                lng: 37.86276,
                lat: 18.36786
            },
            {
                lng: 38.410089959473225,
                lat: 17.998307399970315
            },
            {
                lng: 37.90400000000011,
                lat: 17.42754
            },
            {
                lng: 37.16747,
                lat: 17.263140000000135
            },
            {
                lng: 36.852530000000115,
                lat: 16.95655
            },
            {
                lng: 36.75389,
                lat: 16.29186
            },
            {
                lng: 36.32322,
                lat: 14.82249
            },
            {
                lng: 36.42951,
                lat: 14.42211
            },
            {
                lng: 36.27022,
                lat: 13.563330000000121
            },
            {
                lng: 35.86363,
                lat: 12.57828
            },
            {
                lng: 35.26049,
                lat: 12.08286
            },
            {
                lng: 34.83163000000013,
                lat: 11.318960000000118
            },
            {
                lng: 34.73115000000013,
                lat: 10.910170000000107
            },
            {
                lng: 34.25745,
                lat: 10.63009
            },
            {
                lng: 33.96162,
                lat: 9.58358
            },
            {
                lng: 33.97498,
                lat: 8.68456
            },
            {
                lng: 33.963392794971185,
                lat: 9.464285229420625
            },
            {
                lng: 33.82496348090751,
                lat: 9.484060845715362
            },
            {
                lng: 33.84213085302815,
                lat: 9.981914637215993
            },
            {
                lng: 33.72195924818311,
                lat: 10.325262079630193
            },
            {
                lng: 33.206938084561784,
                lat: 10.720111638406593
            },
            {
                lng: 33.086766479716744,
                lat: 11.441141267476496
            },
            {
                lng: 33.206938084561784,
                lat: 12.179338268667095
            },
            {
                lng: 32.743419037302544,
                lat: 12.248007757149992
            },
            {
                lng: 32.67474954881965,
                lat: 12.02483191958072
            },
            {
                lng: 32.073891524594785,
                lat: 11.973329803218519
            },
            {
                lng: 32.31423473428475,
                lat: 11.68148447716652
            },
            {
                lng: 32.400071594888345,
                lat: 11.080626452941488
            },
            {
                lng: 31.850715687025513,
                lat: 10.531270545078826
            },
            {
                lng: 31.35286189552488,
                lat: 9.810240916008695
            },
            {
                lng: 30.837840731903384,
                lat: 9.70723668328452
            },
            {
                lng: 29.996639497988554,
                lat: 10.290927335388687
            },
            {
                lng: 29.61895731133285,
                lat: 10.084918869940225
            },
            {
                lng: 29.515953078608618,
                lat: 9.793073543888056
            },
            {
                lng: 29.000931914987177,
                lat: 9.60423245056029
            },
            {
                lng: 28.966597170745786,
                lat: 9.398223985111656
            },
            {
                lng: 27.970889587744352,
                lat: 9.398223985111656
            },
            {
                lng: 27.833550610778786,
                lat: 9.60423245056029
            },
            {
                lng: 27.112520981708883,
                lat: 9.638567194801624
            },
            {
                lng: 26.752006167173818,
                lat: 9.466893473594496
            },
            {
                lng: 26.477328213242515,
                lat: 9.552730334198088
            },
            {
                lng: 25.962307049621018,
                lat: 10.136420986302426
            },
            {
                lng: 25.790633328413946,
                lat: 10.411098940233728
            },
            {
                lng: 25.069603699343986,
                lat: 10.273759963267992
            },
            {
                lng: 24.794925745412684,
                lat: 9.810240916008695
            },
            {
                lng: 24.53741516360202,
                lat: 8.91753756573172
            },
            {
                lng: 24.19406772118765,
                lat: 8.728696472403897
            },
            {
                lng: 23.886979580860668,
                lat: 8.619729712933065
            },
            {
                lng: 24.567369012152085,
                lat: 8.229187933785468
            }
        ]
    },
    TD: {
        name: 'Chad',
        type: 'Polygon',
        coordinates: [
            {
                lng: 23.83766000000014,
                lat: 19.580470000000105
            },
            {
                lng: 23.886890000000108,
                lat: 15.61084
            },
            {
                lng: 23.024590000000103,
                lat: 15.68072
            },
            {
                lng: 22.56795000000011,
                lat: 14.944290000000137
            },
            {
                lng: 22.30351,
                lat: 14.32682
            },
            {
                lng: 22.51202,
                lat: 14.09318
            },
            {
                lng: 22.18329,
                lat: 13.78648
            },
            {
                lng: 22.29658,
                lat: 13.37232
            },
            {
                lng: 22.03759,
                lat: 12.95546
            },
            {
                lng: 21.93681,
                lat: 12.588180000000136
            },
            {
                lng: 22.28801,
                lat: 12.64605
            },
            {
                lng: 22.49762,
                lat: 12.26024
            },
            {
                lng: 22.50869,
                lat: 11.67936
            },
            {
                lng: 22.87622,
                lat: 11.384610000000123
            },
            {
                lng: 22.864165480244225,
                lat: 11.142395127807546
            },
            {
                lng: 22.23112918466876,
                lat: 10.97188873946061
            },
            {
                lng: 21.723821648859456,
                lat: 10.567055568885976
            },
            {
                lng: 21.000868361096167,
                lat: 9.475985215691509
            },
            {
                lng: 20.05968549976427,
                lat: 9.012706000194854
            },
            {
                lng: 19.09400800952602,
                lat: 9.07484691002584
            },
            {
                lng: 18.812009718509273,
                lat: 8.982914536978598
            },
            {
                lng: 18.911021762780507,
                lat: 8.630894680206353
            },
            {
                lng: 18.38955488452322,
                lat: 8.281303615751824
            },
            {
                lng: 17.964929640380888,
                lat: 7.890914008002994
            },
            {
                lng: 16.705988396886255,
                lat: 7.508327541529979
            },
            {
                lng: 16.456184523187346,
                lat: 7.734773667832968
            },
            {
                lng: 16.290561557691888,
                lat: 7.754307359239419
            },
            {
                lng: 16.106231723706742,
                lat: 7.497087917506462
            },
            {
                lng: 15.279460483469109,
                lat: 7.421924546737969
            },
            {
                lng: 15.436091749745742,
                lat: 7.692812404811889
            },
            {
                lng: 15.120865512765306,
                lat: 8.382150173369439
            },
            {
                lng: 14.97999555833769,
                lat: 8.796104234243472
            },
            {
                lng: 14.54446658698177,
                lat: 8.965861314322268
            },
            {
                lng: 13.954218377344006,
                lat: 9.549494940626687
            },
            {
                lng: 14.171466098699028,
                lat: 10.021378282099931
            },
            {
                lng: 14.62720055508106,
                lat: 9.920919297724538
            },
            {
                lng: 14.909353875394716,
                lat: 9.992129421422732
            },
            {
                lng: 15.467872755605242,
                lat: 9.982336737503545
            },
            {
                lng: 14.92356489427496,
                lat: 10.891325181517473
            },
            {
                lng: 14.9601518083376,
                lat: 11.555574042197224
            },
            {
                lng: 14.89336,
                lat: 12.21905
            },
            {
                lng: 14.495787387762846,
                lat: 12.85939626713733
            },
            {
                lng: 14.595781284247607,
                lat: 13.33042694747786
            },
            {
                lng: 13.95447675950561,
                lat: 13.353448798063766
            },
            {
                lng: 13.956698846094127,
                lat: 13.996691189016929
            },
            {
                lng: 13.540393507550789,
                lat: 14.367133693901224
            },
            {
                lng: 13.97217,
                lat: 15.68437
            },
            {
                lng: 15.247731154041844,
                lat: 16.627305813050782
            },
            {
                lng: 15.30044111497972,
                lat: 17.927949937405003
            },
            {
                lng: 15.685740594147774,
                lat: 19.957180080642388
            },
            {
                lng: 15.903246697664315,
                lat: 20.387618923417506
            },
            {
                lng: 15.487148064850146,
                lat: 20.730414537025638
            },
            {
                lng: 15.47106,
                lat: 21.04845
            },
            {
                lng: 15.096887648181848,
                lat: 21.30851878507491
            },
            {
                lng: 14.8513,
                lat: 22.862950000000126
            },
            {
                lng: 15.86085,
                lat: 23.40972
            },
            {
                lng: 19.84926,
                lat: 21.49509
            },
            {
                lng: 23.83766000000014,
                lat: 19.580470000000105
            }
        ]
    },
    HT: {
        name: 'Haiti',
        type: 'Polygon',
        coordinates: [
            {
                lng: -71.71236141629296,
                lat: 19.714455878167357
            },
            {
                lng: -71.62487321642283,
                lat: 19.169837958243306
            },
            {
                lng: -71.7013026597825,
                lat: 18.785416978424053
            },
            {
                lng: -71.94511206733556,
                lat: 18.61690013272026
            },
            {
                lng: -71.68773759630588,
                lat: 18.31666006110447
            },
            {
                lng: -71.70830481635805,
                lat: 18.044997056546094
            },
            {
                lng: -72.37247616238935,
                lat: 18.21496084235406
            },
            {
                lng: -72.84441118029488,
                lat: 18.145611070218365
            },
            {
                lng: -73.45455481636503,
                lat: 18.2179063989947
            },
            {
                lng: -73.92243323433566,
                lat: 18.030992743395004
            },
            {
                lng: -74.45803361682478,
                lat: 18.342549953682706
            },
            {
                lng: -74.36992529976713,
                lat: 18.66490753831941
            },
            {
                lng: -73.44954220243272,
                lat: 18.526052964751145
            },
            {
                lng: -72.69493709989064,
                lat: 18.445799465401862
            },
            {
                lng: -72.334881557897,
                lat: 18.668421535715254
            },
            {
                lng: -72.79164954292489,
                lat: 19.10162506761803
            },
            {
                lng: -72.78410478381028,
                lat: 19.48359141690341
            },
            {
                lng: -73.41502234566175,
                lat: 19.639550889560283
            },
            {
                lng: -73.18979061551762,
                lat: 19.915683905511912
            },
            {
                lng: -72.57967281766362,
                lat: 19.871500555902358
            },
            {
                lng: -71.71236141629296,
                lat: 19.714455878167357
            }
        ]
    },
    DO: {
        name: 'Dominican Republic',
        type: 'Polygon',
        coordinates: [
            {
                lng: -71.70830481635805,
                lat: 18.044997056546094
            },
            {
                lng: -71.68773759630588,
                lat: 18.31666006110447
            },
            {
                lng: -71.94511206733556,
                lat: 18.61690013272026
            },
            {
                lng: -71.7013026597825,
                lat: 18.785416978424053
            },
            {
                lng: -71.62487321642283,
                lat: 19.169837958243306
            },
            {
                lng: -71.71236141629296,
                lat: 19.714455878167357
            },
            {
                lng: -71.58730445014663,
                lat: 19.8849105900821
            },
            {
                lng: -70.80670610216174,
                lat: 19.880285549391985
            },
            {
                lng: -70.21436499701613,
                lat: 19.62288524014616
            },
            {
                lng: -69.95081519232758,
                lat: 19.64799998624001
            },
            {
                lng: -69.76925004747008,
                lat: 19.29326711677244
            },
            {
                lng: -69.22212582057988,
                lat: 19.313214219637103
            },
            {
                lng: -69.25434607611385,
                lat: 19.015196234609874
            },
            {
                lng: -68.80941199408083,
                lat: 18.979074408437853
            },
            {
                lng: -68.31794328476897,
                lat: 18.612197577381693
            },
            {
                lng: -68.68931596543452,
                lat: 18.205142320218613
            },
            {
                lng: -69.16494584824892,
                lat: 18.42264842373511
            },
            {
                lng: -69.62398759629764,
                lat: 18.38071299893025
            },
            {
                lng: -69.95293392605154,
                lat: 18.42830699307106
            },
            {
                lng: -70.1332329983179,
                lat: 18.245915025296895
            },
            {
                lng: -70.51713721381422,
                lat: 18.184290879788833
            },
            {
                lng: -70.66929846869763,
                lat: 18.426885891183034
            },
            {
                lng: -70.99995012071719,
                lat: 18.283328762276213
            },
            {
                lng: -71.4002099270339,
                lat: 17.5985643579766
            },
            {
                lng: -71.65766191271202,
                lat: 17.7575727401387
            },
            {
                lng: -71.70830481635805,
                lat: 18.044997056546094
            }
        ]
    },
    RU: {
        name: 'Russian Federation',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: 178.7253,
                    lat: 71.0988
                },
                {
                    lng: 180,
                    lat: 71.51571433642829
                },
                {
                    lng: 180,
                    lat: 70.83219920854673
                },
                {
                    lng: 178.903425,
                    lat: 70.78114
                },
                {
                    lng: 178.7253,
                    lat: 71.0988
                }
            ],
            [
                {
                    lng: 49.10116000000011,
                    lat: 46.399330000000134
                },
                {
                    lng: 48.64541,
                    lat: 45.806290000000104
                },
                {
                    lng: 47.67591,
                    lat: 45.64149000000015
                },
                {
                    lng: 46.68201,
                    lat: 44.6092000000001
                },
                {
                    lng: 47.59094,
                    lat: 43.66016
                },
                {
                    lng: 47.49252,
                    lat: 42.98658000000012
                },
                {
                    lng: 48.58437000000015,
                    lat: 41.80888
                },
                {
                    lng: 48.58435339611342,
                    lat: 41.80886879162067
                },
                {
                    lng: 47.98728315612598,
                    lat: 41.40581920019423
                },
                {
                    lng: 47.81566572448463,
                    lat: 41.15141612402135
                },
                {
                    lng: 47.37331546406623,
                    lat: 41.2197323675112
                },
                {
                    lng: 46.68607059101661,
                    lat: 41.82713715266988
                },
                {
                    lng: 46.404950799348825,
                    lat: 41.860675157227305
                },
                {
                    lng: 45.7764,
                    lat: 42.09244000000018
                },
                {
                    lng: 45.47027916848572,
                    lat: 42.50278066666998
                },
                {
                    lng: 44.537622918481986,
                    lat: 42.71199270280363
                },
                {
                    lng: 43.931210000000135,
                    lat: 42.55496000000011
                },
                {
                    lng: 43.75599000000011,
                    lat: 42.74083
                },
                {
                    lng: 42.39440000000013,
                    lat: 43.2203
                },
                {
                    lng: 40.922190000000114,
                    lat: 43.38215000000014
                },
                {
                    lng: 40.07696495947977,
                    lat: 43.553104153002316
                },
                {
                    lng: 39.955008579270924,
                    lat: 43.43499766699922
                },
                {
                    lng: 38.68,
                    lat: 44.28
                },
                {
                    lng: 37.53912,
                    lat: 44.65721
                },
                {
                    lng: 36.6754600000001,
                    lat: 45.244690000000105
                },
                {
                    lng: 37.4031700000001,
                    lat: 45.40451000000013
                },
                {
                    lng: 38.23295,
                    lat: 46.24087
                },
                {
                    lng: 37.67372,
                    lat: 46.63657
                },
                {
                    lng: 39.14767000000012,
                    lat: 47.044750000000136
                },
                {
                    lng: 39.1212,
                    lat: 47.26336
                },
                {
                    lng: 38.223538038899335,
                    lat: 47.10218984637595
                },
                {
                    lng: 38.25511233902978,
                    lat: 47.54640045835691
                },
                {
                    lng: 38.77057,
                    lat: 47.825620000000185
                },
                {
                    lng: 39.738277622238854,
                    lat: 47.89893707945197
                },
                {
                    lng: 39.89562000000018,
                    lat: 48.23241
                },
                {
                    lng: 39.67465,
                    lat: 48.78382000000016
                },
                {
                    lng: 40.080789015469406,
                    lat: 49.30742991799934
                },
                {
                    lng: 40.06904000000014,
                    lat: 49.60105
                },
                {
                    lng: 38.59498823421342,
                    lat: 49.9264619004237
                },
                {
                    lng: 38.01063113785693,
                    lat: 49.9156615260747
                },
                {
                    lng: 37.39345950699516,
                    lat: 50.38395335550365
                },
                {
                    lng: 36.62616784032542,
                    lat: 50.225590928745135
                },
                {
                    lng: 35.356116163887975,
                    lat: 50.57719737405904
                },
                {
                    lng: 35.37791,
                    lat: 50.77394
                },
                {
                    lng: 35.02218305841791,
                    lat: 51.2075723333715
                },
                {
                    lng: 34.22481570815427,
                    lat: 51.25599315042888
                },
                {
                    lng: 34.141978387190534,
                    lat: 51.566413479206346
                },
                {
                    lng: 34.39173058445709,
                    lat: 51.768881740925906
                },
                {
                    lng: 33.75269982273579,
                    lat: 52.3350745713318
                },
                {
                    lng: 32.715760532367085,
                    lat: 52.23846548116211
                },
                {
                    lng: 32.41205813978769,
                    lat: 52.28869497334978
                },
                {
                    lng: 32.15944000000013,
                    lat: 52.06125000000014
                },
                {
                    lng: 31.78599244755525,
                    lat: 52.1016775699397
                },
                {
                    lng: 31.78597,
                    lat: 52.1016800000001
                },
                {
                    lng: 31.54001834486226,
                    lat: 52.74205231384636
                },
                {
                    lng: 31.305200636528014,
                    lat: 53.07399587667321
                },
                {
                    lng: 31.49764,
                    lat: 53.16743000000014
                },
                {
                    lng: 32.30451948418823,
                    lat: 53.13272614197291
                },
                {
                    lng: 32.69364301934604,
                    lat: 53.35142080343218
                },
                {
                    lng: 32.405598585751164,
                    lat: 53.61804535584204
                },
                {
                    lng: 31.731272820774507,
                    lat: 53.79402944601202
                },
                {
                    lng: 31.79142418796224,
                    lat: 53.974638576872124
                },
                {
                    lng: 31.38447228366374,
                    lat: 54.15705638286238
                },
                {
                    lng: 30.75753380709872,
                    lat: 54.81177094178432
                },
                {
                    lng: 30.971835971813135,
                    lat: 55.08154775656404
                },
                {
                    lng: 30.87390913262001,
                    lat: 55.55097646750341
                },
                {
                    lng: 29.896294386522356,
                    lat: 55.78946320253041
                },
                {
                    lng: 29.371571893030673,
                    lat: 55.670090643936184
                },
                {
                    lng: 29.229513380660308,
                    lat: 55.91834422466636
                },
                {
                    lng: 28.17670942557794,
                    lat: 56.16912995057879
                },
                {
                    lng: 27.855282016722526,
                    lat: 56.75932648378429
                },
                {
                    lng: 27.77001590344093,
                    lat: 57.24425812441123
                },
                {
                    lng: 27.288184848751513,
                    lat: 57.47452830670383
                },
                {
                    lng: 27.71668582531572,
                    lat: 57.79189911562436
                },
                {
                    lng: 27.42015000000015,
                    lat: 58.72457000000014
                },
                {
                    lng: 28.13169925305175,
                    lat: 59.300825100330925
                },
                {
                    lng: 27.98112,
                    lat: 59.47537000000011
                },
                {
                    lng: 27.981126857000987,
                    lat: 59.47537333432527
                },
                {
                    lng: 29.1177,
                    lat: 60.02805000000012
                },
                {
                    lng: 28.070001921525666,
                    lat: 60.50351912796823
                },
                {
                    lng: 28.07,
                    lat: 60.50352
                },
                {
                    lng: 30.21110721204445,
                    lat: 61.780027777749694
                },
                {
                    lng: 31.139991082490894,
                    lat: 62.35769277612441
                },
                {
                    lng: 31.516092156711125,
                    lat: 62.86768748641289
                },
                {
                    lng: 30.035872430142717,
                    lat: 63.55281362573855
                },
                {
                    lng: 30.44468468600371,
                    lat: 64.20445343693909
                },
                {
                    lng: 29.54442955904699,
                    lat: 64.94867157659048
                },
                {
                    lng: 30.21765,
                    lat: 65.80598
                },
                {
                    lng: 29.054588657352326,
                    lat: 66.94428620062206
                },
                {
                    lng: 29.97742638522061,
                    lat: 67.69829702419275
                },
                {
                    lng: 28.445943637818658,
                    lat: 68.36461294216404
                },
                {
                    lng: 28.591929559043194,
                    lat: 69.06477692328666
                },
                {
                    lng: 29.39955,
                    lat: 69.15692000000013
                },
                {
                    lng: 31.101042202597625,
                    lat: 69.55810108805622
                },
                {
                    lng: 31.10108,
                    lat: 69.55811000000011
                },
                {
                    lng: 32.13272000000018,
                    lat: 69.90595000000019
                },
                {
                    lng: 33.77547,
                    lat: 69.30142000000012
                },
                {
                    lng: 36.51396,
                    lat: 69.06342
                },
                {
                    lng: 40.29234000000014,
                    lat: 67.9324
                },
                {
                    lng: 41.05987,
                    lat: 67.45713000000012
                },
                {
                    lng: 41.1259500000001,
                    lat: 66.79158
                },
                {
                    lng: 40.01583,
                    lat: 66.26618000000013
                },
                {
                    lng: 38.38295,
                    lat: 65.9995300000001
                },
                {
                    lng: 33.91871000000015,
                    lat: 66.75961
                },
                {
                    lng: 33.18444,
                    lat: 66.63253
                },
                {
                    lng: 34.814770000000124,
                    lat: 65.90015000000017
                },
                {
                    lng: 34.87857425307874,
                    lat: 65.4362128770482
                },
                {
                    lng: 34.94391,
                    lat: 64.41437000000019
                },
                {
                    lng: 36.23129,
                    lat: 64.10945
                },
                {
                    lng: 37.01273,
                    lat: 63.84983000000011
                },
                {
                    lng: 37.141970000000185,
                    lat: 64.33471
                },
                {
                    lng: 36.53957903508979,
                    lat: 64.76446
                },
                {
                    lng: 37.17604,
                    lat: 65.14322000000016
                },
                {
                    lng: 39.59345,
                    lat: 64.5207900000002
                },
                {
                    lng: 40.4356,
                    lat: 64.76446
                },
                {
                    lng: 39.76260000000019,
                    lat: 65.49682
                },
                {
                    lng: 42.09309000000013,
                    lat: 66.47623
                },
                {
                    lng: 43.01604,
                    lat: 66.41858000000013
                },
                {
                    lng: 43.94975000000011,
                    lat: 66.06908
                },
                {
                    lng: 44.53226,
                    lat: 66.75634000000014
                },
                {
                    lng: 43.69839,
                    lat: 67.35245
                },
                {
                    lng: 44.18795000000017,
                    lat: 67.95051000000012
                },
                {
                    lng: 43.45282,
                    lat: 68.57079
                },
                {
                    lng: 46.250000000000114,
                    lat: 68.25
                },
                {
                    lng: 46.82134,
                    lat: 67.68997
                },
                {
                    lng: 45.55517,
                    lat: 67.56652
                },
                {
                    lng: 45.56202000000013,
                    lat: 67.01005000000015
                },
                {
                    lng: 46.34915000000012,
                    lat: 66.6676700000001
                },
                {
                    lng: 47.89416000000017,
                    lat: 66.8845500000001
                },
                {
                    lng: 48.13876,
                    lat: 67.52238
                },
                {
                    lng: 50.22766000000013,
                    lat: 67.99867000000017
                },
                {
                    lng: 53.71743000000015,
                    lat: 68.85738000000015
                },
                {
                    lng: 54.47171,
                    lat: 68.80815
                },
                {
                    lng: 53.48582000000016,
                    lat: 68.20131
                },
                {
                    lng: 54.72628,
                    lat: 68.09702
                },
                {
                    lng: 55.44268000000011,
                    lat: 68.43866
                },
                {
                    lng: 57.31702,
                    lat: 68.46628
                },
                {
                    lng: 58.802000000000135,
                    lat: 68.88082
                },
                {
                    lng: 59.94142000000011,
                    lat: 68.27844
                },
                {
                    lng: 61.07784000000021,
                    lat: 68.94069
                },
                {
                    lng: 60.03,
                    lat: 69.52
                },
                {
                    lng: 60.55,
                    lat: 69.85
                },
                {
                    lng: 63.50400000000013,
                    lat: 69.54739
                },
                {
                    lng: 64.888115,
                    lat: 69.234835
                },
                {
                    lng: 68.51216000000016,
                    lat: 68.09233000000012
                },
                {
                    lng: 69.18068000000011,
                    lat: 68.61563000000012
                },
                {
                    lng: 68.16444,
                    lat: 69.14436
                },
                {
                    lng: 68.13522,
                    lat: 69.35649000000012
                },
                {
                    lng: 66.93008000000015,
                    lat: 69.45461000000012
                },
                {
                    lng: 67.25976,
                    lat: 69.92873
                },
                {
                    lng: 66.72492000000017,
                    lat: 70.70889000000017
                },
                {
                    lng: 66.69466,
                    lat: 71.02897000000019
                },
                {
                    lng: 68.54006,
                    lat: 71.93450000000018
                },
                {
                    lng: 69.19636,
                    lat: 72.84336000000019
                },
                {
                    lng: 69.94,
                    lat: 73.04
                },
                {
                    lng: 72.58754,
                    lat: 72.77629000000013
                },
                {
                    lng: 72.79603,
                    lat: 72.22006
                },
                {
                    lng: 71.84811,
                    lat: 71.40898
                },
                {
                    lng: 72.47011,
                    lat: 71.09019
                },
                {
                    lng: 72.79188,
                    lat: 70.39114
                },
                {
                    lng: 72.56470000000013,
                    lat: 69.02085
                },
                {
                    lng: 73.66787,
                    lat: 68.4079
                },
                {
                    lng: 73.2387,
                    lat: 67.7404
                },
                {
                    lng: 71.28,
                    lat: 66.3200000000001
                },
                {
                    lng: 72.42301,
                    lat: 66.17267000000021
                },
                {
                    lng: 72.82077,
                    lat: 66.53267
                },
                {
                    lng: 73.92099000000013,
                    lat: 66.78946000000013
                },
                {
                    lng: 74.18651000000011,
                    lat: 67.28429
                },
                {
                    lng: 75.052,
                    lat: 67.76047000000011
                },
                {
                    lng: 74.46926000000013,
                    lat: 68.32899
                },
                {
                    lng: 74.93584,
                    lat: 68.98918
                },
                {
                    lng: 73.84236,
                    lat: 69.07146
                },
                {
                    lng: 73.60187000000019,
                    lat: 69.62763
                },
                {
                    lng: 74.3998,
                    lat: 70.63175
                },
                {
                    lng: 73.1011,
                    lat: 71.4471700000002
                },
                {
                    lng: 74.89082000000019,
                    lat: 72.12119000000013
                },
                {
                    lng: 74.65926,
                    lat: 72.83227
                },
                {
                    lng: 75.1580100000001,
                    lat: 72.85497000000015
                },
                {
                    lng: 75.68351000000013,
                    lat: 72.30056000000013
                },
                {
                    lng: 75.28898,
                    lat: 71.3355600000001
                },
                {
                    lng: 76.35911,
                    lat: 71.15287000000018
                },
                {
                    lng: 75.90313000000015,
                    lat: 71.87401
                },
                {
                    lng: 77.57665,
                    lat: 72.26717
                },
                {
                    lng: 79.6520200000001,
                    lat: 72.32011
                },
                {
                    lng: 81.5,
                    lat: 71.75
                },
                {
                    lng: 80.61071,
                    lat: 72.58285000000012
                },
                {
                    lng: 80.51109,
                    lat: 73.6482
                },
                {
                    lng: 82.25,
                    lat: 73.85000000000014
                },
                {
                    lng: 84.65526,
                    lat: 73.80591000000021
                },
                {
                    lng: 86.82230000000015,
                    lat: 73.93688
                },
                {
                    lng: 86.00956,
                    lat: 74.45967000000019
                },
                {
                    lng: 87.16682000000014,
                    lat: 75.11643
                },
                {
                    lng: 88.31571,
                    lat: 75.14393
                },
                {
                    lng: 90.26,
                    lat: 75.64
                },
                {
                    lng: 92.90058,
                    lat: 75.7733300000001
                },
                {
                    lng: 93.23421,
                    lat: 76.0472
                },
                {
                    lng: 95.86000000000018,
                    lat: 76.1400000000001
                },
                {
                    lng: 96.67821,
                    lat: 75.91548
                },
                {
                    lng: 98.92254000000014,
                    lat: 76.44689
                },
                {
                    lng: 100.75967000000014,
                    lat: 76.43028
                },
                {
                    lng: 101.03532,
                    lat: 76.86189
                },
                {
                    lng: 101.9908400000001,
                    lat: 77.28754000000015
                },
                {
                    lng: 104.35160000000013,
                    lat: 77.69792
                },
                {
                    lng: 106.06664000000018,
                    lat: 77.37389
                },
                {
                    lng: 104.70500000000015,
                    lat: 77.1274
                },
                {
                    lng: 106.97013,
                    lat: 76.97419
                },
                {
                    lng: 107.24000000000012,
                    lat: 76.48
                },
                {
                    lng: 108.1538000000001,
                    lat: 76.72335
                },
                {
                    lng: 111.0772600000002,
                    lat: 76.71
                },
                {
                    lng: 113.33151,
                    lat: 76.22224
                },
                {
                    lng: 114.13417,
                    lat: 75.84764
                },
                {
                    lng: 113.88539,
                    lat: 75.32779000000016
                },
                {
                    lng: 112.77918,
                    lat: 75.03186000000011
                },
                {
                    lng: 110.15125000000012,
                    lat: 74.47673
                },
                {
                    lng: 109.4,
                    lat: 74.18
                },
                {
                    lng: 110.64,
                    lat: 74.04
                },
                {
                    lng: 112.11919,
                    lat: 73.78774000000016
                },
                {
                    lng: 113.01954000000023,
                    lat: 73.97693000000015
                },
                {
                    lng: 113.52958000000024,
                    lat: 73.33505000000014
                },
                {
                    lng: 113.96881,
                    lat: 73.59488
                },
                {
                    lng: 115.56782,
                    lat: 73.75285
                },
                {
                    lng: 118.77633000000014,
                    lat: 73.58772
                },
                {
                    lng: 119.02,
                    lat: 73.12
                },
                {
                    lng: 123.20066,
                    lat: 72.97122
                },
                {
                    lng: 123.25777000000016,
                    lat: 73.73503
                },
                {
                    lng: 125.38000000000011,
                    lat: 73.56
                },
                {
                    lng: 126.97644,
                    lat: 73.56549000000012
                },
                {
                    lng: 128.59126,
                    lat: 73.03871
                },
                {
                    lng: 129.05157,
                    lat: 72.39872
                },
                {
                    lng: 128.46,
                    lat: 71.98
                },
                {
                    lng: 129.71599000000015,
                    lat: 71.19304000000011
                },
                {
                    lng: 131.28858000000025,
                    lat: 70.78699000000012
                },
                {
                    lng: 132.25350000000014,
                    lat: 71.83630000000011
                },
                {
                    lng: 133.85766000000024,
                    lat: 71.3864200000001
                },
                {
                    lng: 135.56193,
                    lat: 71.65525000000014
                },
                {
                    lng: 137.49755,
                    lat: 71.34763
                },
                {
                    lng: 138.23409000000015,
                    lat: 71.62803
                },
                {
                    lng: 139.86983,
                    lat: 71.48783000000014
                },
                {
                    lng: 139.14791,
                    lat: 72.41619000000014
                },
                {
                    lng: 140.46817,
                    lat: 72.84941000000015
                },
                {
                    lng: 149.5,
                    lat: 72.2
                },
                {
                    lng: 150.3511800000001,
                    lat: 71.60643
                },
                {
                    lng: 152.9689000000002,
                    lat: 70.84222
                },
                {
                    lng: 157.00688,
                    lat: 71.03141
                },
                {
                    lng: 158.99779,
                    lat: 70.86672
                },
                {
                    lng: 159.83031000000017,
                    lat: 70.45324
                },
                {
                    lng: 159.70866,
                    lat: 69.72198
                },
                {
                    lng: 160.9405300000003,
                    lat: 69.4372800000001
                },
                {
                    lng: 162.27907,
                    lat: 69.64204
                },
                {
                    lng: 164.05248,
                    lat: 69.66823
                },
                {
                    lng: 165.94037000000014,
                    lat: 69.47199
                },
                {
                    lng: 167.83567,
                    lat: 69.58269000000013
                },
                {
                    lng: 169.5776300000001,
                    lat: 68.6938
                },
                {
                    lng: 170.8168800000002,
                    lat: 69.01363
                },
                {
                    lng: 170.0082000000001,
                    lat: 69.65276
                },
                {
                    lng: 170.4534500000002,
                    lat: 70.09703
                },
                {
                    lng: 173.64391000000012,
                    lat: 69.81743
                },
                {
                    lng: 175.7240300000002,
                    lat: 69.87725000000017
                },
                {
                    lng: 178.6,
                    lat: 69.4
                },
                {
                    lng: 180,
                    lat: 68.96363636363651
                },
                {
                    lng: 180,
                    lat: 64.97970870219848
                },
                {
                    lng: 179.99281,
                    lat: 64.97433
                },
                {
                    lng: 178.70720000000028,
                    lat: 64.53493
                },
                {
                    lng: 177.41128,
                    lat: 64.60821
                },
                {
                    lng: 178.3130000000001,
                    lat: 64.07593
                },
                {
                    lng: 178.90825000000018,
                    lat: 63.25197
                },
                {
                    lng: 179.37034,
                    lat: 62.98262000000011
                },
                {
                    lng: 179.48636,
                    lat: 62.56894
                },
                {
                    lng: 179.22825,
                    lat: 62.304100000000176
                },
                {
                    lng: 177.3643,
                    lat: 62.5219
                },
                {
                    lng: 174.56929000000025,
                    lat: 61.76915
                },
                {
                    lng: 173.68013,
                    lat: 61.65261
                },
                {
                    lng: 172.15,
                    lat: 60.95
                },
                {
                    lng: 170.6985,
                    lat: 60.33618000000013
                },
                {
                    lng: 170.33085000000028,
                    lat: 59.88177
                },
                {
                    lng: 168.90046,
                    lat: 60.57355
                },
                {
                    lng: 166.29498000000024,
                    lat: 59.78855000000016
                },
                {
                    lng: 165.84000000000015,
                    lat: 60.16
                },
                {
                    lng: 164.87674,
                    lat: 59.7316
                },
                {
                    lng: 163.53929,
                    lat: 59.86871
                },
                {
                    lng: 163.21711000000016,
                    lat: 59.21101
                },
                {
                    lng: 162.01733,
                    lat: 58.24328
                },
                {
                    lng: 162.05297,
                    lat: 57.83912
                },
                {
                    lng: 163.19191,
                    lat: 57.615030000000104
                },
                {
                    lng: 163.05794000000014,
                    lat: 56.159240000000125
                },
                {
                    lng: 162.12958000000015,
                    lat: 56.12219
                },
                {
                    lng: 161.70146,
                    lat: 55.285680000000184
                },
                {
                    lng: 162.11749,
                    lat: 54.85514
                },
                {
                    lng: 160.36877000000027,
                    lat: 54.34433
                },
                {
                    lng: 160.02173000000016,
                    lat: 53.20257
                },
                {
                    lng: 158.5309400000001,
                    lat: 52.958680000000186
                },
                {
                    lng: 158.23118,
                    lat: 51.94269
                },
                {
                    lng: 156.78979000000027,
                    lat: 51.01105
                },
                {
                    lng: 156.42,
                    lat: 51.7
                },
                {
                    lng: 155.99182,
                    lat: 53.15895
                },
                {
                    lng: 155.43366,
                    lat: 55.38103000000012
                },
                {
                    lng: 155.9144200000003,
                    lat: 56.767920000000174
                },
                {
                    lng: 156.75815,
                    lat: 57.3647
                },
                {
                    lng: 156.81035,
                    lat: 57.83204
                },
                {
                    lng: 158.3643300000001,
                    lat: 58.05575
                },
                {
                    lng: 160.15064,
                    lat: 59.31477
                },
                {
                    lng: 161.87204,
                    lat: 60.343
                },
                {
                    lng: 163.66969,
                    lat: 61.1409000000001
                },
                {
                    lng: 164.47355,
                    lat: 62.55061
                },
                {
                    lng: 163.25842000000011,
                    lat: 62.46627000000012
                },
                {
                    lng: 162.65791,
                    lat: 61.6425
                },
                {
                    lng: 160.12148,
                    lat: 60.54423
                },
                {
                    lng: 159.30232,
                    lat: 61.7739600000001
                },
                {
                    lng: 156.72068,
                    lat: 61.43442
                },
                {
                    lng: 154.21806000000026,
                    lat: 59.75818000000015
                },
                {
                    lng: 155.04375,
                    lat: 59.14495
                },
                {
                    lng: 152.81185,
                    lat: 58.88385
                },
                {
                    lng: 151.2657300000002,
                    lat: 58.78089
                },
                {
                    lng: 151.33815,
                    lat: 59.50396
                },
                {
                    lng: 149.78371,
                    lat: 59.655730000000176
                },
                {
                    lng: 148.54481,
                    lat: 59.16448
                },
                {
                    lng: 145.48722,
                    lat: 59.33637
                },
                {
                    lng: 142.19782,
                    lat: 59.03998000000013
                },
                {
                    lng: 138.95848000000024,
                    lat: 57.08805
                },
                {
                    lng: 135.12619,
                    lat: 54.72959
                },
                {
                    lng: 136.70171,
                    lat: 54.603550000000155
                },
                {
                    lng: 137.19342,
                    lat: 53.97732
                },
                {
                    lng: 138.1647,
                    lat: 53.7550100000002
                },
                {
                    lng: 138.80463,
                    lat: 54.25455
                },
                {
                    lng: 139.90151,
                    lat: 54.18968000000012
                },
                {
                    lng: 141.34531,
                    lat: 53.08957000000015
                },
                {
                    lng: 141.37923,
                    lat: 52.23877
                },
                {
                    lng: 140.59742000000017,
                    lat: 51.2396700000001
                },
                {
                    lng: 140.51308,
                    lat: 50.045530000000156
                },
                {
                    lng: 140.0619300000002,
                    lat: 48.44671000000011
                },
                {
                    lng: 138.5547200000002,
                    lat: 46.99965
                },
                {
                    lng: 138.21971,
                    lat: 46.30795
                },
                {
                    lng: 136.86232,
                    lat: 45.14350000000013
                },
                {
                    lng: 135.51535000000013,
                    lat: 43.989
                },
                {
                    lng: 134.86939000000018,
                    lat: 43.39821
                },
                {
                    lng: 133.53687000000025,
                    lat: 42.81147
                },
                {
                    lng: 132.90627,
                    lat: 42.79849000000013
                },
                {
                    lng: 132.27807000000018,
                    lat: 43.28456000000011
                },
                {
                    lng: 130.93587000000014,
                    lat: 42.55274
                },
                {
                    lng: 130.78000485358513,
                    lat: 42.22001036108258
                },
                {
                    lng: 130.78000366004676,
                    lat: 42.220007813203225
                },
                {
                    lng: 130.78,
                    lat: 42.22000000000014
                },
                {
                    lng: 130.77999231657833,
                    lat: 42.22000960427719
                },
                {
                    lng: 130.6400000000001,
                    lat: 42.395
                },
                {
                    lng: 130.63999970690955,
                    lat: 42.39502427522179
                },
                {
                    lng: 130.63386640840974,
                    lat: 42.90301463477053
                },
                {
                    lng: 131.1446879416149,
                    lat: 42.92998973242689
                },
                {
                    lng: 131.28855512911548,
                    lat: 44.11151968034828
                },
                {
                    lng: 131.02519000000018,
                    lat: 44.96796
                },
                {
                    lng: 131.8834542176595,
                    lat: 45.32116160743652
                },
                {
                    lng: 133.09712000000013,
                    lat: 45.14409
                },
                {
                    lng: 133.76964399631288,
                    lat: 46.116926988299014
                },
                {
                    lng: 134.1123500000001,
                    lat: 47.21248
                },
                {
                    lng: 134.50081,
                    lat: 47.57845
                },
                {
                    lng: 135.02631147678665,
                    lat: 48.478229885443874
                },
                {
                    lng: 133.37359581922794,
                    lat: 48.18344167743487
                },
                {
                    lng: 132.50669,
                    lat: 47.78896
                },
                {
                    lng: 130.9872600000001,
                    lat: 47.79013
                },
                {
                    lng: 130.58229332898242,
                    lat: 48.72968740497614
                },
                {
                    lng: 129.39781782442043,
                    lat: 49.44060008401544
                },
                {
                    lng: 127.65740000000028,
                    lat: 49.760270000000105
                },
                {
                    lng: 127.28745568248485,
                    lat: 50.73979726826548
                },
                {
                    lng: 126.93915652883766,
                    lat: 51.35389415140592
                },
                {
                    lng: 126.56439904185697,
                    lat: 51.7842554795327
                },
                {
                    lng: 125.94634891164617,
                    lat: 52.79279857035698
                },
                {
                    lng: 125.06821129771038,
                    lat: 53.16104482686886
                },
                {
                    lng: 123.57147,
                    lat: 53.4588
                },
                {
                    lng: 122.24574791879283,
                    lat: 53.431725979213695
                },
                {
                    lng: 121.00308475147017,
                    lat: 53.25140106873119
                },
                {
                    lng: 120.17708865771681,
                    lat: 52.75388621684124
                },
                {
                    lng: 120.72578901579197,
                    lat: 52.516226304730935
                },
                {
                    lng: 120.7382,
                    lat: 51.96411
                },
                {
                    lng: 120.18208000000016,
                    lat: 51.64355000000012
                },
                {
                    lng: 119.27939,
                    lat: 50.58292
                },
                {
                    lng: 119.2884607280258,
                    lat: 50.142882798862004
                },
                {
                    lng: 117.8792444194264,
                    lat: 49.510983384796965
                },
                {
                    lng: 116.67880089728612,
                    lat: 49.888531399121405
                },
                {
                    lng: 115.48569542853136,
                    lat: 49.80517731383475
                },
                {
                    lng: 114.96210981655018,
                    lat: 50.140247300815126
                },
                {
                    lng: 114.36245649623527,
                    lat: 50.24830272073741
                },
                {
                    lng: 112.89773969935436,
                    lat: 49.54356537535699
                },
                {
                    lng: 111.58123091028662,
                    lat: 49.37796824807769
                },
                {
                    lng: 110.66201053267878,
                    lat: 49.13012807880585
                },
                {
                    lng: 109.40244917199664,
                    lat: 49.292960516957635
                },
                {
                    lng: 108.47516727095129,
                    lat: 49.28254771585074
                },
                {
                    lng: 107.86817589725095,
                    lat: 49.793705145865815
                },
                {
                    lng: 106.8888041524553,
                    lat: 50.27429596618032
                },
                {
                    lng: 105.88659142458675,
                    lat: 50.406019192092224
                },
                {
                    lng: 104.62158,
                    lat: 50.27532000000019
                },
                {
                    lng: 103.67654544476022,
                    lat: 50.089966132195116
                },
                {
                    lng: 102.25589,
                    lat: 50.51056000000011
                },
                {
                    lng: 102.06521,
                    lat: 51.25991
                },
                {
                    lng: 100.88948042196262,
                    lat: 51.51685578063832
                },
                {
                    lng: 99.98173221232354,
                    lat: 51.63400625264399
                },
                {
                    lng: 98.86149051310034,
                    lat: 52.04736603454669
                },
                {
                    lng: 97.82573978067431,
                    lat: 51.01099518493318
                },
                {
                    lng: 98.23176150919156,
                    lat: 50.422400621128745
                },
                {
                    lng: 97.25976000000014,
                    lat: 49.72605
                },
                {
                    lng: 95.8140200000002,
                    lat: 49.97746000000012
                },
                {
                    lng: 94.81594933469873,
                    lat: 50.01343333597085
                },
                {
                    lng: 94.14756635943559,
                    lat: 50.48053660745717
                },
                {
                    lng: 93.10421,
                    lat: 50.49529
                },
                {
                    lng: 92.23471154171968,
                    lat: 50.80217072204172
                },
                {
                    lng: 90.71366743364068,
                    lat: 50.33181183532109
                },
                {
                    lng: 88.80556684769552,
                    lat: 49.47052073831242
                },
                {
                    lng: 87.75126427607671,
                    lat: 49.297197984405486
                },
                {
                    lng: 87.35997033076265,
                    lat: 49.21498078062912
                },
                {
                    lng: 86.82935672398963,
                    lat: 49.82667470966817
                },
                {
                    lng: 85.54126997268247,
                    lat: 49.69285858824816
                },
                {
                    lng: 85.11555952346203,
                    lat: 50.11730296487764
                },
                {
                    lng: 84.41637739455308,
                    lat: 50.311399644565824
                },
                {
                    lng: 83.93511478061885,
                    lat: 50.88924551045358
                },
                {
                    lng: 83.38300377801238,
                    lat: 51.069182847693924
                },
                {
                    lng: 81.94598554883993,
                    lat: 50.81219594990637
                },
                {
                    lng: 80.56844689323549,
                    lat: 51.38833649352847
                },
                {
                    lng: 80.03555952344169,
                    lat: 50.86475088154725
                },
                {
                    lng: 77.80091556184425,
                    lat: 53.404414984747575
                },
                {
                    lng: 76.52517947785475,
                    lat: 54.17700348572714
                },
                {
                    lng: 76.89110029491343,
                    lat: 54.49052440044193
                },
                {
                    lng: 74.38482000000016,
                    lat: 53.54685000000012
                },
                {
                    lng: 73.42567874542044,
                    lat: 53.489810289109755
                },
                {
                    lng: 73.5085160663844,
                    lat: 54.0356167669766
                },
                {
                    lng: 72.22415001820218,
                    lat: 54.376655381886735
                },
                {
                    lng: 71.18013105660941,
                    lat: 54.13328522400826
                },
                {
                    lng: 70.86526655465514,
                    lat: 55.169733588270105
                },
                {
                    lng: 69.06816694527288,
                    lat: 55.38525014914353
                },
                {
                    lng: 68.16910037625883,
                    lat: 54.97039175070432
                },
                {
                    lng: 65.66687,
                    lat: 54.60125000000011
                },
                {
                    lng: 65.17853356309593,
                    lat: 54.35422781027211
                },
                {
                    lng: 61.43660000000017,
                    lat: 54.00625
                },
                {
                    lng: 60.978066440683165,
                    lat: 53.66499339457914
                },
                {
                    lng: 61.699986199800605,
                    lat: 52.97999644633427
                },
                {
                    lng: 60.73999311711458,
                    lat: 52.71998647725775
                },
                {
                    lng: 60.92726850774028,
                    lat: 52.44754832621504
                },
                {
                    lng: 59.967533807215545,
                    lat: 51.9604204372157
                },
                {
                    lng: 61.58800337102417,
                    lat: 51.272658799843214
                },
                {
                    lng: 61.337424350840934,
                    lat: 50.79907013610426
                },
                {
                    lng: 59.93280724471549,
                    lat: 50.842194118851864
                },
                {
                    lng: 59.642282342370606,
                    lat: 50.545442206415714
                },
                {
                    lng: 58.36332,
                    lat: 51.06364
                },
                {
                    lng: 56.77798000000013,
                    lat: 51.04355
                },
                {
                    lng: 55.71694,
                    lat: 50.62171000000018
                },
                {
                    lng: 54.532878452376224,
                    lat: 51.02623973245932
                },
                {
                    lng: 52.32872358583097,
                    lat: 51.718652248738124
                },
                {
                    lng: 50.76664839051216,
                    lat: 51.6927623561599
                },
                {
                    lng: 48.70238162618102,
                    lat: 50.60512848571284
                },
                {
                    lng: 48.57784142435753,
                    lat: 49.87475962991567
                },
                {
                    lng: 47.54948042174931,
                    lat: 50.454698391311126
                },
                {
                    lng: 46.75159630716274,
                    lat: 49.35600576435377
                },
                {
                    lng: 47.04367150247651,
                    lat: 49.152038886097614
                },
                {
                    lng: 46.46644575377627,
                    lat: 48.39415233010493
                },
                {
                    lng: 47.31524000000013,
                    lat: 47.7158500000001
                },
                {
                    lng: 48.05725,
                    lat: 47.74377
                },
                {
                    lng: 48.694733514201744,
                    lat: 47.07562816017793
                },
                {
                    lng: 48.59325000000018,
                    lat: 46.56104
                },
                {
                    lng: 49.10116000000011,
                    lat: 46.399330000000134
                }
            ],
            [
                {
                    lng: 93.77766,
                    lat: 81.0246
                },
                {
                    lng: 95.940895,
                    lat: 81.2504
                },
                {
                    lng: 97.88385,
                    lat: 80.746975
                },
                {
                    lng: 100.186655,
                    lat: 79.780135
                },
                {
                    lng: 99.93976,
                    lat: 78.88094
                },
                {
                    lng: 97.75794,
                    lat: 78.7562
                },
                {
                    lng: 94.97259,
                    lat: 79.044745
                },
                {
                    lng: 93.31288,
                    lat: 79.4265
                },
                {
                    lng: 92.5454,
                    lat: 80.14379
                },
                {
                    lng: 91.18107,
                    lat: 80.34146
                },
                {
                    lng: 93.77766,
                    lat: 81.0246
                }
            ],
            [
                {
                    lng: 102.837815,
                    lat: 79.28129
                },
                {
                    lng: 105.37243,
                    lat: 78.71334
                },
                {
                    lng: 105.07547,
                    lat: 78.30689
                },
                {
                    lng: 99.43814,
                    lat: 77.921
                },
                {
                    lng: 101.2649,
                    lat: 79.23399
                },
                {
                    lng: 102.08635,
                    lat: 79.34641
                },
                {
                    lng: 102.837815,
                    lat: 79.28129
                }
            ],
            [
                {
                    lng: 138.831075,
                    lat: 76.13676
                },
                {
                    lng: 141.471615,
                    lat: 76.09289
                },
                {
                    lng: 145.086285,
                    lat: 75.562625
                },
                {
                    lng: 144.3,
                    lat: 74.82
                },
                {
                    lng: 140.61381,
                    lat: 74.84768
                },
                {
                    lng: 138.95544,
                    lat: 74.61148
                },
                {
                    lng: 136.97439,
                    lat: 75.26167
                },
                {
                    lng: 137.51176,
                    lat: 75.94917
                },
                {
                    lng: 138.831075,
                    lat: 76.13676
                }
            ],
            [
                {
                    lng: 148.22223,
                    lat: 75.345845
                },
                {
                    lng: 150.73167,
                    lat: 75.08406
                },
                {
                    lng: 149.575925,
                    lat: 74.68892
                },
                {
                    lng: 147.977465,
                    lat: 74.778355
                },
                {
                    lng: 146.11919,
                    lat: 75.17298
                },
                {
                    lng: 146.358485,
                    lat: 75.49682
                },
                {
                    lng: 148.22223,
                    lat: 75.345845
                }
            ],
            [
                {
                    lng: 139.86312,
                    lat: 73.36983
                },
                {
                    lng: 140.81171,
                    lat: 73.76506
                },
                {
                    lng: 142.06207,
                    lat: 73.85758
                },
                {
                    lng: 143.48283,
                    lat: 73.47525
                },
                {
                    lng: 143.60385,
                    lat: 73.21244
                },
                {
                    lng: 142.08763,
                    lat: 73.20544
                },
                {
                    lng: 140.038155,
                    lat: 73.31692
                },
                {
                    lng: 139.86312,
                    lat: 73.36983
                }
            ],
            [
                {
                    lng: 44.84695804218114,
                    lat: 80.58980988231714
                },
                {
                    lng: 46.79913862487123,
                    lat: 80.77191762971368
                },
                {
                    lng: 48.31847741068461,
                    lat: 80.78400991486998
                },
                {
                    lng: 48.52280602396667,
                    lat: 80.51456899690017
                },
                {
                    lng: 49.097189568890855,
                    lat: 80.7539859077084
                },
                {
                    lng: 50.03976769389462,
                    lat: 80.91888540315178
                },
                {
                    lng: 51.522932977103665,
                    lat: 80.69972565380193
                },
                {
                    lng: 51.13618655783128,
                    lat: 80.54728017854093
                },
                {
                    lng: 49.79368452332071,
                    lat: 80.4154277615482
                },
                {
                    lng: 48.89441124857755,
                    lat: 80.33956675894375
                },
                {
                    lng: 48.75493655782177,
                    lat: 80.17546824820089
                },
                {
                    lng: 47.58611901224418,
                    lat: 80.01018117951533
                },
                {
                    lng: 46.50282596210963,
                    lat: 80.2472468126543
                },
                {
                    lng: 47.07245527526294,
                    lat: 80.55942414012951
                },
                {
                    lng: 44.84695804218114,
                    lat: 80.58980988231714
                }
            ],
            [
                {
                    lng: 22.731098667092652,
                    lat: 54.327536932993326
                },
                {
                    lng: 20.892244500418627,
                    lat: 54.31252492941253
                },
                {
                    lng: 19.660640089606403,
                    lat: 54.42608388937393
                },
                {
                    lng: 19.888481479581287,
                    lat: 54.86616038677151
                },
                {
                    lng: 21.268448927503467,
                    lat: 55.190481675835315
                },
                {
                    lng: 22.315723504330577,
                    lat: 55.015298570365864
                },
                {
                    lng: 22.75776370615526,
                    lat: 54.85657440858138
                },
                {
                    lng: 22.65105187347254,
                    lat: 54.582740993866736
                },
                {
                    lng: 22.731098667092652,
                    lat: 54.327536932993326
                }
            ],
            [
                {
                    lng: 53.50828982932515,
                    lat: 73.7498139513002
                },
                {
                    lng: 55.90245893740766,
                    lat: 74.62748647734536
                },
                {
                    lng: 55.631932814359686,
                    lat: 75.08141225859718
                },
                {
                    lng: 57.86864383324885,
                    lat: 75.60939036732326
                },
                {
                    lng: 61.170044386647476,
                    lat: 76.25188345000812
                },
                {
                    lng: 64.49836836127017,
                    lat: 76.43905548776927
                },
                {
                    lng: 66.21097700385513,
                    lat: 76.80978221303117
                },
                {
                    lng: 68.1570597675348,
                    lat: 76.93969676381293
                },
                {
                    lng: 68.85221113472508,
                    lat: 76.5448113064546
                },
                {
                    lng: 68.1805725442276,
                    lat: 76.23364166940907
                },
                {
                    lng: 64.63732628770302,
                    lat: 75.73775462513625
                },
                {
                    lng: 61.58350752141476,
                    lat: 75.26088450794684
                },
                {
                    lng: 58.47708214705335,
                    lat: 74.30905630156285
                },
                {
                    lng: 56.98678551618803,
                    lat: 73.33304352486623
                },
                {
                    lng: 55.419335971910925,
                    lat: 72.37126760526603
                },
                {
                    lng: 55.62283776227633,
                    lat: 71.54059479439032
                },
                {
                    lng: 57.535692579992315,
                    lat: 70.72046397570212
                },
                {
                    lng: 56.94497928246388,
                    lat: 70.63274323188666
                },
                {
                    lng: 53.67737511578417,
                    lat: 70.76265778266846
                },
                {
                    lng: 53.412016635965394,
                    lat: 71.20666168892022
                },
                {
                    lng: 51.601894565645665,
                    lat: 71.47475901965045
                },
                {
                    lng: 51.455753615124216,
                    lat: 72.01488108996513
                },
                {
                    lng: 52.47827518088354,
                    lat: 72.22944163684097
                },
                {
                    lng: 52.44416873557088,
                    lat: 72.77473135038481
                },
                {
                    lng: 54.42761355979758,
                    lat: 73.6275475124976
                },
                {
                    lng: 53.50828982932515,
                    lat: 73.7498139513002
                }
            ],
            [
                {
                    lng: 142.91461551327654,
                    lat: 53.704577541714784
                },
                {
                    lng: 143.2608476096321,
                    lat: 52.74076040303906
                },
                {
                    lng: 143.23526777564763,
                    lat: 51.75666026468876
                },
                {
                    lng: 143.64800744036285,
                    lat: 50.747600409541505
                },
                {
                    lng: 144.6541475770856,
                    lat: 48.97639069273754
                },
                {
                    lng: 143.1739278505172,
                    lat: 49.306551418650315
                },
                {
                    lng: 142.55866824765013,
                    lat: 47.86157501890495
                },
                {
                    lng: 143.53349246640403,
                    lat: 46.83672801369252
                },
                {
                    lng: 143.50527713437265,
                    lat: 46.137907619809525
                },
                {
                    lng: 142.74770063697383,
                    lat: 46.74076487892651
                },
                {
                    lng: 142.09203006405454,
                    lat: 45.966755276058834
                },
                {
                    lng: 141.906925083585,
                    lat: 46.80592886004656
                },
                {
                    lng: 142.01844282447087,
                    lat: 47.78013296161296
                },
                {
                    lng: 141.90444461483503,
                    lat: 48.85918854429957
                },
                {
                    lng: 142.1358000022057,
                    lat: 49.61516307229739
                },
                {
                    lng: 142.17998335181528,
                    lat: 50.9523424342819
                },
                {
                    lng: 141.59407596249002,
                    lat: 51.9354348822025
                },
                {
                    lng: 141.6825460145737,
                    lat: 53.301966457728795
                },
                {
                    lng: 142.60693403541075,
                    lat: 53.762145087287934
                },
                {
                    lng: 142.20974897681543,
                    lat: 54.225475979216874
                },
                {
                    lng: 142.65478641171302,
                    lat: 54.36588084575389
                },
                {
                    lng: 142.91461551327654,
                    lat: 53.704577541714784
                }
            ],
            [
                {
                    lng: -174.92825,
                    lat: 67.20589
                },
                {
                    lng: -175.01425,
                    lat: 66.58435
                },
                {
                    lng: -174.33983,
                    lat: 66.33556
                },
                {
                    lng: -174.57182,
                    lat: 67.06219
                },
                {
                    lng: -171.85731,
                    lat: 66.91308
                },
                {
                    lng: -169.89958,
                    lat: 65.97724
                },
                {
                    lng: -170.89107,
                    lat: 65.54139
                },
                {
                    lng: -172.53025,
                    lat: 65.43791
                },
                {
                    lng: -172.555,
                    lat: 64.46079
                },
                {
                    lng: -172.95533,
                    lat: 64.25269
                },
                {
                    lng: -173.89184,
                    lat: 64.2826
                },
                {
                    lng: -174.65392,
                    lat: 64.63125
                },
                {
                    lng: -175.98353,
                    lat: 64.92288
                },
                {
                    lng: -176.20716,
                    lat: 65.35667
                },
                {
                    lng: -177.22266,
                    lat: 65.52024
                },
                {
                    lng: -178.35993,
                    lat: 65.39052
                },
                {
                    lng: -178.90332,
                    lat: 65.74044
                },
                {
                    lng: -178.68611,
                    lat: 66.11211
                },
                {
                    lng: -179.88377,
                    lat: 65.87456
                },
                {
                    lng: -179.43268,
                    lat: 65.40411
                },
                {
                    lng: -180,
                    lat: 64.9797087021984
                },
                {
                    lng: -180,
                    lat: 68.96363636363635
                },
                {
                    lng: -177.55,
                    lat: 68.2
                },
                {
                    lng: -174.92825,
                    lat: 67.20589
                }
            ],
            [
                {
                    lng: -178.69378,
                    lat: 70.89302
                },
                {
                    lng: -180,
                    lat: 70.83219920854673
                },
                {
                    lng: -180,
                    lat: 71.51571433642827
                },
                {
                    lng: -179.871875,
                    lat: 71.55762
                },
                {
                    lng: -179.02433,
                    lat: 71.55553
                },
                {
                    lng: -177.577945,
                    lat: 71.26948
                },
                {
                    lng: -177.663575,
                    lat: 71.13277
                },
                {
                    lng: -178.69378,
                    lat: 70.89302
                }
            ],
            [
                {
                    lng: 33.435988094713366,
                    lat: 45.971917370797485
                },
                {
                    lng: 33.69946184910907,
                    lat: 46.219572831556434
                },
                {
                    lng: 34.41040172853718,
                    lat: 46.005162391728845
                },
                {
                    lng: 34.73201738827845,
                    lat: 45.96566573176062
                },
                {
                    lng: 34.861792128174045,
                    lat: 45.76818243191957
                },
                {
                    lng: 35.01265897004737,
                    lat: 45.73772519982549
                },
                {
                    lng: 35.02078779474607,
                    lat: 45.65121898048466
                },
                {
                    lng: 35.51000857925311,
                    lat: 45.40999339454612
                },
                {
                    lng: 36.52999799983019,
                    lat: 45.46998973243717
                },
                {
                    lng: 36.334712762199274,
                    lat: 45.11321564389402
                },
                {
                    lng: 35.239999220528205,
                    lat: 44.93999624285175
                },
                {
                    lng: 33.882511020652885,
                    lat: 44.361478583344194
                },
                {
                    lng: 33.32642093276013,
                    lat: 44.564877020844904
                },
                {
                    lng: 33.546924269349404,
                    lat: 45.03477081967486
                },
                {
                    lng: 32.4541744321055,
                    lat: 45.327466132176085
                },
                {
                    lng: 32.63080447767919,
                    lat: 45.51918569597899
                },
                {
                    lng: 33.58816206231842,
                    lat: 45.85156850848023
                },
                {
                    lng: 33.435988094713366,
                    lat: 45.971917370797485
                }
            ]
        ]
    },
    BS: {
        name: 'Bahamas, The',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: -78.98,
                    lat: 26.79
                },
                {
                    lng: -78.51,
                    lat: 26.87
                },
                {
                    lng: -77.85,
                    lat: 26.84
                },
                {
                    lng: -77.82,
                    lat: 26.58
                },
                {
                    lng: -78.91,
                    lat: 26.42
                },
                {
                    lng: -78.98,
                    lat: 26.79
                }
            ],
            [
                {
                    lng: -77.79,
                    lat: 27.04
                },
                {
                    lng: -77,
                    lat: 26.59
                },
                {
                    lng: -77.17255,
                    lat: 25.87918
                },
                {
                    lng: -77.35641,
                    lat: 26.00735
                },
                {
                    lng: -77.34,
                    lat: 26.53
                },
                {
                    lng: -77.78802,
                    lat: 26.92516
                },
                {
                    lng: -77.79,
                    lat: 27.04
                }
            ],
            [
                {
                    lng: -78.19087,
                    lat: 25.2103
                },
                {
                    lng: -77.89,
                    lat: 25.17
                },
                {
                    lng: -77.54,
                    lat: 24.34
                },
                {
                    lng: -77.53466,
                    lat: 23.75975
                },
                {
                    lng: -77.78,
                    lat: 23.71
                },
                {
                    lng: -78.03405,
                    lat: 24.28615
                },
                {
                    lng: -78.40848,
                    lat: 24.57564
                },
                {
                    lng: -78.19087,
                    lat: 25.2103
                }
            ]
        ]
    },
    FK: {
        name: 'Falkland Islands',
        type: 'Polygon',
        coordinates: [
            {
                lng: -61.2,
                lat: -51.85
            },
            {
                lng: -60,
                lat: -51.25
            },
            {
                lng: -59.15,
                lat: -51.5
            },
            {
                lng: -58.55,
                lat: -51.1
            },
            {
                lng: -57.75,
                lat: -51.55
            },
            {
                lng: -58.05,
                lat: -51.9
            },
            {
                lng: -59.4,
                lat: -52.2
            },
            {
                lng: -59.85,
                lat: -51.85
            },
            {
                lng: -60.7,
                lat: -52.3
            },
            {
                lng: -61.2,
                lat: -51.85
            }
        ]
    },
    NO: {
        name: 'Norway',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: 31.101042202597625,
                    lat: 69.55810108805622
                },
                {
                    lng: 29.39955,
                    lat: 69.15692000000013
                },
                {
                    lng: 28.591929559043194,
                    lat: 69.06477692328666
                },
                {
                    lng: 29.015572950971972,
                    lat: 69.76649119737799
                },
                {
                    lng: 27.732292107867863,
                    lat: 70.16419302029625
                },
                {
                    lng: 26.179622023226244,
                    lat: 69.82529897732614
                },
                {
                    lng: 25.689212680776365,
                    lat: 69.09211375596904
                },
                {
                    lng: 24.735679152126725,
                    lat: 68.64955678982146
                },
                {
                    lng: 23.66204959483076,
                    lat: 68.89124746365054
                },
                {
                    lng: 22.356237827247412,
                    lat: 68.84174144151491
                },
                {
                    lng: 21.244936150810673,
                    lat: 69.37044302029308
                },
                {
                    lng: 20.645592889089528,
                    lat: 69.10624726020087
                },
                {
                    lng: 20.025268995857886,
                    lat: 69.0651386583127
                },
                {
                    lng: 19.878559604581255,
                    lat: 68.40719432237258
                },
                {
                    lng: 17.993868442464333,
                    lat: 68.56739126247736
                },
                {
                    lng: 17.729181756265348,
                    lat: 68.01055186631628
                },
                {
                    lng: 16.768878614985482,
                    lat: 68.0139366726314
                },
                {
                    lng: 16.108712192456778,
                    lat: 67.30245555283689
                },
                {
                    lng: 15.108411492583002,
                    lat: 66.19386688909547
                },
                {
                    lng: 13.55568973150909,
                    lat: 64.78702769638151
                },
                {
                    lng: 13.919905226302204,
                    lat: 64.44542064071608
                },
                {
                    lng: 13.571916131248713,
                    lat: 64.04911408146971
                },
                {
                    lng: 12.579935336973934,
                    lat: 64.06621898055833
                },
                {
                    lng: 11.930569288794231,
                    lat: 63.12831757267698
                },
                {
                    lng: 11.992064243221563,
                    lat: 61.80036245385655
                },
                {
                    lng: 12.631146681375185,
                    lat: 61.293571682370136
                },
                {
                    lng: 12.3003658382749,
                    lat: 60.11793284773003
                },
                {
                    lng: 11.468271925511146,
                    lat: 59.43239329694604
                },
                {
                    lng: 11.027368605196868,
                    lat: 58.85614940045936
                },
                {
                    lng: 10.356556837616067,
                    lat: 59.469807033925356
                },
                {
                    lng: 8.382000359743586,
                    lat: 58.313288479233215
                },
                {
                    lng: 7.048748406613271,
                    lat: 58.078884182357285
                },
                {
                    lng: 5.665835402050419,
                    lat: 58.5881554225937
                },
                {
                    lng: 5.308234490590678,
                    lat: 59.663231919993834
                },
                {
                    lng: 4.992078077828978,
                    lat: 61.97099803328432
                },
                {
                    lng: 5.912900424837886,
                    lat: 62.614472968182724
                },
                {
                    lng: 8.553411085655739,
                    lat: 63.45400828719648
                },
                {
                    lng: 10.527709181366758,
                    lat: 64.4860383164975
                },
                {
                    lng: 12.358346795306373,
                    lat: 65.87972585719318
                },
                {
                    lng: 14.761145867581604,
                    lat: 67.81064158799516
                },
                {
                    lng: 16.435927361728943,
                    lat: 68.56320547146173
                },
                {
                    lng: 19.18402835457846,
                    lat: 69.81744415961778
                },
                {
                    lng: 21.378416375420585,
                    lat: 70.2551693793461
                },
                {
                    lng: 23.023742303161526,
                    lat: 70.2020718451662
                },
                {
                    lng: 24.546543409938465,
                    lat: 71.03049673123722
                },
                {
                    lng: 26.37004967622181,
                    lat: 70.98626170519539
                },
                {
                    lng: 28.165547316202943,
                    lat: 71.18547435168055
                },
                {
                    lng: 31.293418409965454,
                    lat: 70.4537877468599
                },
                {
                    lng: 30.005435011522792,
                    lat: 70.18625885688489
                },
                {
                    lng: 31.101042202597625,
                    lat: 69.55810108805622
                }
            ]
        ]
    },
    GL: {
        name: 'Greenland',
        type: 'Polygon',
        coordinates: [
            {
                lng: -46.76379,
                lat: 82.62796
            },
            {
                lng: -43.40644,
                lat: 83.22516
            },
            {
                lng: -39.89753,
                lat: 83.18018
            },
            {
                lng: -38.62214,
                lat: 83.54905
            },
            {
                lng: -35.08787,
                lat: 83.64513
            },
            {
                lng: -27.10046,
                lat: 83.51966
            },
            {
                lng: -20.84539,
                lat: 82.72669
            },
            {
                lng: -22.69182,
                lat: 82.34165
            },
            {
                lng: -26.51753,
                lat: 82.29765
            },
            {
                lng: -31.9,
                lat: 82.2
            },
            {
                lng: -31.39646,
                lat: 82.02154
            },
            {
                lng: -27.85666,
                lat: 82.13178
            },
            {
                lng: -24.84448,
                lat: 81.78697
            },
            {
                lng: -22.90328,
                lat: 82.09317
            },
            {
                lng: -22.07175,
                lat: 81.73449
            },
            {
                lng: -23.16961,
                lat: 81.15271
            },
            {
                lng: -20.62363,
                lat: 81.52462
            },
            {
                lng: -15.76818,
                lat: 81.91245
            },
            {
                lng: -12.77018,
                lat: 81.71885
            },
            {
                lng: -12.20855,
                lat: 81.29154
            },
            {
                lng: -16.28533,
                lat: 80.58004
            },
            {
                lng: -16.85,
                lat: 80.35
            },
            {
                lng: -20.04624,
                lat: 80.17708
            },
            {
                lng: -17.73035,
                lat: 80.12912
            },
            {
                lng: -18.9,
                lat: 79.4
            },
            {
                lng: -19.70499,
                lat: 78.75128
            },
            {
                lng: -19.67353,
                lat: 77.63859
            },
            {
                lng: -18.47285,
                lat: 76.98565
            },
            {
                lng: -20.03503,
                lat: 76.94434
            },
            {
                lng: -21.67944,
                lat: 76.62795
            },
            {
                lng: -19.83407,
                lat: 76.09808
            },
            {
                lng: -19.59896,
                lat: 75.24838
            },
            {
                lng: -20.66818,
                lat: 75.15585
            },
            {
                lng: -19.37281,
                lat: 74.29561
            },
            {
                lng: -21.59422,
                lat: 74.22382
            },
            {
                lng: -20.43454,
                lat: 73.81713
            },
            {
                lng: -20.76234,
                lat: 73.46436
            },
            {
                lng: -22.17221,
                lat: 73.30955
            },
            {
                lng: -23.56593,
                lat: 73.30663
            },
            {
                lng: -22.31311,
                lat: 72.62928
            },
            {
                lng: -22.29954,
                lat: 72.18409
            },
            {
                lng: -24.27834,
                lat: 72.59788
            },
            {
                lng: -24.79296,
                lat: 72.3302
            },
            {
                lng: -23.44296,
                lat: 72.08016
            },
            {
                lng: -22.13281,
                lat: 71.46898
            },
            {
                lng: -21.75356,
                lat: 70.66369
            },
            {
                lng: -23.53603,
                lat: 70.471
            },
            {
                lng: -24.30702,
                lat: 70.85649
            },
            {
                lng: -25.54341,
                lat: 71.43094
            },
            {
                lng: -25.20135,
                lat: 70.75226
            },
            {
                lng: -26.36276,
                lat: 70.22646
            },
            {
                lng: -23.72742,
                lat: 70.18401
            },
            {
                lng: -22.34902,
                lat: 70.12946
            },
            {
                lng: -25.02927,
                lat: 69.2588
            },
            {
                lng: -27.74737,
                lat: 68.47046
            },
            {
                lng: -30.67371,
                lat: 68.12503
            },
            {
                lng: -31.77665,
                lat: 68.12078
            },
            {
                lng: -32.81105,
                lat: 67.73547
            },
            {
                lng: -34.20196,
                lat: 66.67974
            },
            {
                lng: -36.35284,
                lat: 65.9789
            },
            {
                lng: -37.04378,
                lat: 65.93768
            },
            {
                lng: -38.37505,
                lat: 65.69213
            },
            {
                lng: -39.81222,
                lat: 65.45848
            },
            {
                lng: -40.66899,
                lat: 64.83997
            },
            {
                lng: -40.68281,
                lat: 64.13902
            },
            {
                lng: -41.1887,
                lat: 63.48246
            },
            {
                lng: -42.81938,
                lat: 62.68233
            },
            {
                lng: -42.41666,
                lat: 61.90093
            },
            {
                lng: -42.86619,
                lat: 61.07404
            },
            {
                lng: -43.3784,
                lat: 60.09772
            },
            {
                lng: -44.7875,
                lat: 60.03676
            },
            {
                lng: -46.26364,
                lat: 60.85328
            },
            {
                lng: -48.26294,
                lat: 60.85843
            },
            {
                lng: -49.23308,
                lat: 61.40681
            },
            {
                lng: -49.90039,
                lat: 62.38336
            },
            {
                lng: -51.63325,
                lat: 63.62691
            },
            {
                lng: -52.14014,
                lat: 64.27842
            },
            {
                lng: -52.27659,
                lat: 65.1767
            },
            {
                lng: -53.66166,
                lat: 66.09957
            },
            {
                lng: -53.30161,
                lat: 66.8365
            },
            {
                lng: -53.96911,
                lat: 67.18899
            },
            {
                lng: -52.9804,
                lat: 68.35759
            },
            {
                lng: -51.47536,
                lat: 68.72958
            },
            {
                lng: -51.08041,
                lat: 69.14781
            },
            {
                lng: -50.87122,
                lat: 69.9291
            },
            {
                lng: -52.013585,
                lat: 69.574925
            },
            {
                lng: -52.55792,
                lat: 69.42616
            },
            {
                lng: -53.45629,
                lat: 69.283625
            },
            {
                lng: -54.68336,
                lat: 69.61003
            },
            {
                lng: -54.75001,
                lat: 70.28932
            },
            {
                lng: -54.35884,
                lat: 70.821315
            },
            {
                lng: -53.431315,
                lat: 70.835755
            },
            {
                lng: -51.39014,
                lat: 70.56978
            },
            {
                lng: -53.10937,
                lat: 71.20485
            },
            {
                lng: -54.00422,
                lat: 71.54719
            },
            {
                lng: -55,
                lat: 71.40653696727257
            },
            {
                lng: -55.83468,
                lat: 71.65444
            },
            {
                lng: -54.71819,
                lat: 72.58625
            },
            {
                lng: -55.32634,
                lat: 72.95861
            },
            {
                lng: -56.12003,
                lat: 73.64977
            },
            {
                lng: -57.32363,
                lat: 74.71026
            },
            {
                lng: -58.59679,
                lat: 75.09861
            },
            {
                lng: -58.58516,
                lat: 75.51727
            },
            {
                lng: -61.26861,
                lat: 76.10238
            },
            {
                lng: -63.39165,
                lat: 76.1752
            },
            {
                lng: -66.06427,
                lat: 76.13486
            },
            {
                lng: -68.50438,
                lat: 76.06141
            },
            {
                lng: -69.66485,
                lat: 76.37975
            },
            {
                lng: -71.40257,
                lat: 77.00857
            },
            {
                lng: -68.77671,
                lat: 77.32312
            },
            {
                lng: -66.76397,
                lat: 77.37595
            },
            {
                lng: -71.04293,
                lat: 77.63595
            },
            {
                lng: -73.297,
                lat: 78.04419
            },
            {
                lng: -73.15938,
                lat: 78.43271
            },
            {
                lng: -69.37345,
                lat: 78.91388
            },
            {
                lng: -65.7107,
                lat: 79.39436
            },
            {
                lng: -65.3239,
                lat: 79.75814
            },
            {
                lng: -68.02298,
                lat: 80.11721
            },
            {
                lng: -67.15129,
                lat: 80.51582
            },
            {
                lng: -63.68925,
                lat: 81.21396
            },
            {
                lng: -62.23444,
                lat: 81.3211
            },
            {
                lng: -62.65116,
                lat: 81.77042
            },
            {
                lng: -60.28249,
                lat: 82.03363
            },
            {
                lng: -57.20744,
                lat: 82.19074
            },
            {
                lng: -54.13442,
                lat: 82.19962
            },
            {
                lng: -53.04328,
                lat: 81.88833
            },
            {
                lng: -50.39061,
                lat: 82.43883
            },
            {
                lng: -48.00386,
                lat: 82.06481
            },
            {
                lng: -46.59984,
                lat: 81.985945
            },
            {
                lng: -44.523,
                lat: 81.6607
            },
            {
                lng: -46.9007,
                lat: 82.19979
            },
            {
                lng: -46.76379,
                lat: 82.62796
            }
        ]
    },

    TL: {
        name: 'Timor-Leste',
        type: 'Polygon',
        coordinates: [
            {
                lng: 124.96868248911623,
                lat: -8.892790215697083
            },
            {
                lng: 125.08624637258026,
                lat: -8.65688730228468
            },
            {
                lng: 125.94707238169826,
                lat: -8.432094821815035
            },
            {
                lng: 126.64470421763855,
                lat: -8.398246758663852
            },
            {
                lng: 126.95724328013983,
                lat: -8.273344821814398
            },
            {
                lng: 127.33592817597463,
                lat: -8.397316582882603
            },
            {
                lng: 126.96799197805655,
                lat: -8.668256117388893
            },
            {
                lng: 125.9258850444586,
                lat: -9.106007175333353
            },
            {
                lng: 125.08852013560109,
                lat: -9.393173109579294
            },
            {
                lng: 125.07001997284061,
                lat: -9.089987481322872
            },
            {
                lng: 124.96868248911623,
                lat: -8.892790215697083
            }
        ]
    },
    ZA: {
        name: 'South Africa',
        type: 'Polygon',
        coordinates: [
            {
                lng: 16.344976840895242,
                lat: -28.5767050106977
            },
            {
                lng: 16.824017368240902,
                lat: -28.08216155366447
            },
            {
                lng: 17.218928663815404,
                lat: -28.35594329194681
            },
            {
                lng: 17.387497185951503,
                lat: -28.78351409272978
            },
            {
                lng: 17.83615197110953,
                lat: -28.85637786226132
            },
            {
                lng: 18.464899122804752,
                lat: -29.04546192801728
            },
            {
                lng: 19.002127312911085,
                lat: -28.972443129188868
            },
            {
                lng: 19.894734327888614,
                lat: -28.461104831660776
            },
            {
                lng: 19.895767856534434,
                lat: -24.76779021576059
            },
            {
                lng: 20.16572553882719,
                lat: -24.91796192800077
            },
            {
                lng: 20.75860924651184,
                lat: -25.86813648855145
            },
            {
                lng: 20.66647016773544,
                lat: -26.477453301704923
            },
            {
                lng: 20.88960900237174,
                lat: -26.828542982695915
            },
            {
                lng: 21.605896030369394,
                lat: -26.726533705351756
            },
            {
                lng: 22.105968865657868,
                lat: -26.280256036079138
            },
            {
                lng: 22.57953169118059,
                lat: -25.979447523708146
            },
            {
                lng: 22.8242712745149,
                lat: -25.50045867279477
            },
            {
                lng: 23.312096795350186,
                lat: -25.26868987396572
            },
            {
                lng: 23.73356977712271,
                lat: -25.390129489851617
            },
            {
                lng: 24.211266717228796,
                lat: -25.670215752873574
            },
            {
                lng: 25.025170525825786,
                lat: -25.7196700985769
            },
            {
                lng: 25.66466637543772,
                lat: -25.486816094669713
            },
            {
                lng: 25.76584882986521,
                lat: -25.17484547292368
            },
            {
                lng: 25.94165205252216,
                lat: -24.69637338633322
            },
            {
                lng: 26.4857532081233,
                lat: -24.616326592713104
            },
            {
                lng: 26.786406691197413,
                lat: -24.240690606383485
            },
            {
                lng: 27.119409620886245,
                lat: -23.574323011979775
            },
            {
                lng: 28.01723595552525,
                lat: -22.82775359465908
            },
            {
                lng: 29.43218834810904,
                lat: -22.091312758067588
            },
            {
                lng: 29.839036899542972,
                lat: -22.102216485281176
            },
            {
                lng: 30.322883335091774,
                lat: -22.271611830333935
            },
            {
                lng: 30.65986535006709,
                lat: -22.151567478119915
            },
            {
                lng: 31.19140913262129,
                lat: -22.2515096981724
            },
            {
                lng: 31.670397983534656,
                lat: -23.658969008073864
            },
            {
                lng: 31.930588820124253,
                lat: -24.36941659922254
            },
            {
                lng: 31.75240848158188,
                lat: -25.484283949487413
            },
            {
                lng: 31.83777794772806,
                lat: -25.84333180105135
            },
            {
                lng: 31.333157586397903,
                lat: -25.66019052500895
            },
            {
                lng: 31.04407962415715,
                lat: -25.731452325139443
            },
            {
                lng: 30.949666782359913,
                lat: -26.02264902110415
            },
            {
                lng: 30.67660851412964,
                lat: -26.398078301704608
            },
            {
                lng: 30.68596194837448,
                lat: -26.743845310169533
            },
            {
                lng: 31.28277306491333,
                lat: -27.285879408478998
            },
            {
                lng: 31.86806033705108,
                lat: -27.177927341421277
            },
            {
                lng: 32.07166548028107,
                lat: -26.73382008230491
            },
            {
                lng: 32.830120477028885,
                lat: -26.742191664336197
            },
            {
                lng: 32.580264926897684,
                lat: -27.470157566031816
            },
            {
                lng: 32.46213260267845,
                lat: -28.301011244420557
            },
            {
                lng: 32.20338870619304,
                lat: -28.75240488049007
            },
            {
                lng: 31.521001417778876,
                lat: -29.257386976846256
            },
            {
                lng: 31.325561150851,
                lat: -29.401977634398914
            },
            {
                lng: 30.901762729625347,
                lat: -29.909956963828037
            },
            {
                lng: 30.62281334811382,
                lat: -30.42377573010613
            },
            {
                lng: 30.05571618014278,
                lat: -31.140269463832958
            },
            {
                lng: 28.92555260591954,
                lat: -32.1720411109725
            },
            {
                lng: 28.2197558936771,
                lat: -32.771952813448856
            },
            {
                lng: 27.464608188595975,
                lat: -33.2269637997788
            },
            {
                lng: 26.419452345492825,
                lat: -33.61495045342619
            },
            {
                lng: 25.90966434093349,
                lat: -33.6670402971764
            },
            {
                lng: 25.780628289500697,
                lat: -33.94464609144834
            },
            {
                lng: 25.172861769315972,
                lat: -33.796851495093584
            },
            {
                lng: 24.677853224392123,
                lat: -33.98717579522455
            },
            {
                lng: 23.594043409934642,
                lat: -33.794474379208154
            },
            {
                lng: 22.988188917744736,
                lat: -33.91643075941698
            },
            {
                lng: 22.574157342222236,
                lat: -33.86408253350531
            },
            {
                lng: 21.542799106541025,
                lat: -34.258838799782936
            },
            {
                lng: 20.689052768647002,
                lat: -34.417175388325234
            },
            {
                lng: 20.071261020597632,
                lat: -34.79513681410799
            },
            {
                lng: 19.61640506356457,
                lat: -34.81916635512371
            },
            {
                lng: 19.193278435958717,
                lat: -34.46259897230979
            },
            {
                lng: 18.85531456876987,
                lat: -34.444305515278465
            },
            {
                lng: 18.42464318204938,
                lat: -33.99787281670897
            },
            {
                lng: 18.377410922934615,
                lat: -34.13652068454807
            },
            {
                lng: 18.24449913907992,
                lat: -33.86775156019803
            },
            {
                lng: 18.250080193767445,
                lat: -33.28143075941444
            },
            {
                lng: 17.92519046394844,
                lat: -32.61129078545343
            },
            {
                lng: 18.247909783611192,
                lat: -32.42913136162457
            },
            {
                lng: 18.22176150887148,
                lat: -31.66163298922567
            },
            {
                lng: 17.56691775886887,
                lat: -30.725721123987547
            },
            {
                lng: 17.064416131262703,
                lat: -29.878641045859162
            },
            {
                lng: 17.062917514726223,
                lat: -29.875953871379984
            },
            {
                lng: 16.344976840895242,
                lat: -28.5767050106977
            }
        ]
    },
    LS: {
        name: 'Lesotho',
        type: 'Polygon',
        coordinates: [
            {
                lng: 28.978262566857243,
                lat: -28.95559661226171
            },
            {
                lng: 29.32516645683259,
                lat: -29.257386976846256
            },
            {
                lng: 29.018415154748027,
                lat: -29.74376555757737
            },
            {
                lng: 28.84839969250774,
                lat: -30.070050551068256
            },
            {
                lng: 28.29106937023991,
                lat: -30.2262167294543
            },
            {
                lng: 28.107204624145425,
                lat: -30.54573211031495
            },
            {
                lng: 27.749397006956485,
                lat: -30.645105889612225
            },
            {
                lng: 26.999261915807637,
                lat: -29.875953871379984
            },
            {
                lng: 27.532511020627478,
                lat: -29.24271087007536
            },
            {
                lng: 28.074338413207784,
                lat: -28.851468601193588
            },
            {
                lng: 28.541700066855498,
                lat: -28.64750172293757
            },
            {
                lng: 28.978262566857243,
                lat: -28.95559661226171
            }
        ]
    },
    MX: {
        name: 'Mexico',
        type: 'Polygon',
        coordinates: [
            {
                lng: -117.12775999999985,
                lat: 32.53534
            },
            {
                lng: -115.99135,
                lat: 32.61239000000012
            },
            {
                lng: -114.72139,
                lat: 32.72083
            },
            {
                lng: -114.815,
                lat: 32.52528
            },
            {
                lng: -113.30498,
                lat: 32.03914
            },
            {
                lng: -111.02361,
                lat: 31.33472
            },
            {
                lng: -109.035,
                lat: 31.341940000000136
            },
            {
                lng: -108.24194,
                lat: 31.34222
            },
            {
                lng: -108.24,
                lat: 31.754853718166373
            },
            {
                lng: -106.50759,
                lat: 31.75452
            },
            {
                lng: -106.1429,
                lat: 31.39995
            },
            {
                lng: -105.63159,
                lat: 31.08383
            },
            {
                lng: -105.03737,
                lat: 30.64402
            },
            {
                lng: -104.70575,
                lat: 30.12173
            },
            {
                lng: -104.4569699999999,
                lat: 29.57196
            },
            {
                lng: -103.94,
                lat: 29.27
            },
            {
                lng: -103.11,
                lat: 28.97
            },
            {
                lng: -102.48,
                lat: 29.76
            },
            {
                lng: -101.6624,
                lat: 29.7793
            },
            {
                lng: -100.9576,
                lat: 29.380710000000136
            },
            {
                lng: -100.45584,
                lat: 28.69612000000012
            },
            {
                lng: -100.11,
                lat: 28.110000000000127
            },
            {
                lng: -99.52,
                lat: 27.54
            },
            {
                lng: -99.3,
                lat: 26.84
            },
            {
                lng: -99.02,
                lat: 26.37
            },
            {
                lng: -98.24,
                lat: 26.06
            },
            {
                lng: -97.53,
                lat: 25.84
            },
            {
                lng: -97.14000830767071,
                lat: 25.8699974634784
            },
            {
                lng: -97.52807247596655,
                lat: 24.9921440699203
            },
            {
                lng: -97.70294552284224,
                lat: 24.272343044526735
            },
            {
                lng: -97.77604183631905,
                lat: 22.93257986092766
            },
            {
                lng: -97.87236670611111,
                lat: 22.44421173755336
            },
            {
                lng: -97.69904395220419,
                lat: 21.898689480064263
            },
            {
                lng: -97.38895952023677,
                lat: 21.411018988525825
            },
            {
                lng: -97.18933346229329,
                lat: 20.635433254473128
            },
            {
                lng: -96.52557552772032,
                lat: 19.890930894444068
            },
            {
                lng: -96.29212724484177,
                lat: 19.320371405509547
            },
            {
                lng: -95.90088497595997,
                lat: 18.82802419684873
            },
            {
                lng: -94.83906348344271,
                lat: 18.562717393462208
            },
            {
                lng: -94.42572953975622,
                lat: 18.144370835843347
            },
            {
                lng: -93.5486512926824,
                lat: 18.423836981677937
            },
            {
                lng: -92.7861138577835,
                lat: 18.52483856859226
            },
            {
                lng: -92.0373481920904,
                lat: 18.704569200103435
            },
            {
                lng: -91.40790340855926,
                lat: 18.87608327888023
            },
            {
                lng: -90.77186987991087,
                lat: 19.28412038825678
            },
            {
                lng: -90.53358985061305,
                lat: 19.8674181177513
            },
            {
                lng: -90.45147599970124,
                lat: 20.707521877520435
            },
            {
                lng: -90.2786183336849,
                lat: 20.999855454995554
            },
            {
                lng: -89.60132117385149,
                lat: 21.26172577563449
            },
            {
                lng: -88.54386633986286,
                lat: 21.49367544197662
            },
            {
                lng: -87.65841651075772,
                lat: 21.45884552661198
            },
            {
                lng: -87.05189022494807,
                lat: 21.5435431991383
            },
            {
                lng: -86.81198238803296,
                lat: 21.331514797444754
            },
            {
                lng: -86.84590796583262,
                lat: 20.849864610268355
            },
            {
                lng: -87.38329118523586,
                lat: 20.25540477139873
            },
            {
                lng: -87.62105445021075,
                lat: 19.64655304613592
            },
            {
                lng: -87.43675045444178,
                lat: 19.47240346931227
            },
            {
                lng: -87.58656043165593,
                lat: 19.04013011319074
            },
            {
                lng: -87.83719112827151,
                lat: 18.25981598558343
            },
            {
                lng: -88.09066402866318,
                lat: 18.51664785407405
            },
            {
                lng: -88.3000310940937,
                lat: 18.4999822046599
            },
            {
                lng: -88.49012285027935,
                lat: 18.486830552641607
            },
            {
                lng: -88.84834387892661,
                lat: 17.883198147040233
            },
            {
                lng: -89.02985734735182,
                lat: 18.001511338772488
            },
            {
                lng: -89.15090938999553,
                lat: 17.95546763760042
            },
            {
                lng: -89.14308041050333,
                lat: 17.808318996649405
            },
            {
                lng: -90.06793351923098,
                lat: 17.819326076727478
            },
            {
                lng: -91.00151994501596,
                lat: 17.81759491624571
            },
            {
                lng: -91.00226925328421,
                lat: 17.25465770107418
            },
            {
                lng: -91.45392127151516,
                lat: 17.252177232324172
            },
            {
                lng: -91.08167009150065,
                lat: 16.918476670799407
            },
            {
                lng: -90.71182186558772,
                lat: 16.687483018454728
            },
            {
                lng: -90.60084672724093,
                lat: 16.47077789963879
            },
            {
                lng: -90.43886695022204,
                lat: 16.410109768128095
            },
            {
                lng: -90.46447262242266,
                lat: 16.069562079324655
            },
            {
                lng: -91.74796017125595,
                lat: 16.066564846251765
            },
            {
                lng: -92.2292486234063,
                lat: 15.251446641495875
            },
            {
                lng: -92.08721594925207,
                lat: 15.064584662328441
            },
            {
                lng: -92.20322953974733,
                lat: 14.830102850804069
            },
            {
                lng: -92.22775000686983,
                lat: 14.538828640190928
            },
            {
                lng: -93.35946387406176,
                lat: 15.615429592343673
            },
            {
                lng: -93.87516883011853,
                lat: 15.940164292865916
            },
            {
                lng: -94.69165646033014,
                lat: 16.200975246642884
            },
            {
                lng: -95.25022701697304,
                lat: 16.128318182840644
            },
            {
                lng: -96.05338212765332,
                lat: 15.752087917539598
            },
            {
                lng: -96.55743404822829,
                lat: 15.653515122942792
            },
            {
                lng: -97.26359249549665,
                lat: 15.917064927631316
            },
            {
                lng: -98.01302995480961,
                lat: 16.107311713113916
            },
            {
                lng: -98.94767574745651,
                lat: 16.566043402568766
            },
            {
                lng: -99.69739742714705,
                lat: 16.70616404872817
            },
            {
                lng: -100.82949886758132,
                lat: 17.17107107184205
            },
            {
                lng: -101.66608862995446,
                lat: 17.649026394109626
            },
            {
                lng: -101.91852800170022,
                lat: 17.916090196193977
            },
            {
                lng: -102.47813208698892,
                lat: 17.975750637275098
            },
            {
                lng: -103.50098954955808,
                lat: 18.29229462327885
            },
            {
                lng: -103.91752743204682,
                lat: 18.74857168220001
            },
            {
                lng: -104.9920096504755,
                lat: 19.316133938061682
            },
            {
                lng: -105.49303849976144,
                lat: 19.946767279535436
            },
            {
                lng: -105.73139604370766,
                lat: 20.434101874264115
            },
            {
                lng: -105.39777299683135,
                lat: 20.531718654863425
            },
            {
                lng: -105.50066077352443,
                lat: 20.81689504646613
            },
            {
                lng: -105.27075232625793,
                lat: 21.07628489835514
            },
            {
                lng: -105.26581722697404,
                lat: 21.42210358325235
            },
            {
                lng: -105.6031609769754,
                lat: 21.87114594165257
            },
            {
                lng: -105.69341386597313,
                lat: 22.269080308516152
            },
            {
                lng: -106.02871639689897,
                lat: 22.773752346278627
            },
            {
                lng: -106.90998043498837,
                lat: 23.767774359628902
            },
            {
                lng: -107.91544877809139,
                lat: 24.54891531015295
            },
            {
                lng: -108.40190487347098,
                lat: 25.172313951105934
            },
            {
                lng: -109.26019873740665,
                lat: 25.58060944264406
            },
            {
                lng: -109.44408932171734,
                lat: 25.82488393808768
            },
            {
                lng: -109.29164384645628,
                lat: 26.442934068298428
            },
            {
                lng: -109.80145768923182,
                lat: 26.676175645447927
            },
            {
                lng: -110.3917317370857,
                lat: 27.16211497650454
            },
            {
                lng: -110.64101884646163,
                lat: 27.859876003525528
            },
            {
                lng: -111.17891883018785,
                lat: 27.94124054616907
            },
            {
                lng: -111.75960689985163,
                lat: 28.46795258230395
            },
            {
                lng: -112.2282346260904,
                lat: 28.95440867768349
            },
            {
                lng: -112.27182369672869,
                lat: 29.266844387320077
            },
            {
                lng: -112.80959448937398,
                lat: 30.02111359305235
            },
            {
                lng: -113.16381059451868,
                lat: 30.786880804969428
            },
            {
                lng: -113.14866939985717,
                lat: 31.170965887978923
            },
            {
                lng: -113.87188106978186,
                lat: 31.567608344035193
            },
            {
                lng: -114.20573666060352,
                lat: 31.524045111613134
            },
            {
                lng: -114.77645117883503,
                lat: 31.79953217216115
            },
            {
                lng: -114.93669979537214,
                lat: 31.393484605427602
            },
            {
                lng: -114.7712318591735,
                lat: 30.913617255165263
            },
            {
                lng: -114.67389929895177,
                lat: 30.162681179315996
            },
            {
                lng: -114.33097449426293,
                lat: 29.750432440707414
            },
            {
                lng: -113.58887508833544,
                lat: 29.061611436473015
            },
            {
                lng: -113.42405310754054,
                lat: 28.82617361095123
            },
            {
                lng: -113.27196936730553,
                lat: 28.7547826197399
            },
            {
                lng: -113.14003943566439,
                lat: 28.41128937429596
            },
            {
                lng: -112.9622983467965,
                lat: 28.42519033458251
            },
            {
                lng: -112.76158708377488,
                lat: 27.780216783147523
            },
            {
                lng: -112.45791052941166,
                lat: 27.52581370697476
            },
            {
                lng: -112.2449519519368,
                lat: 27.17172679291076
            },
            {
                lng: -111.61648902061921,
                lat: 26.662817287700477
            },
            {
                lng: -111.28467464887302,
                lat: 25.732589830014433
            },
            {
                lng: -110.9878193835724,
                lat: 25.294606228124564
            },
            {
                lng: -110.71000688357134,
                lat: 24.82600434010186
            },
            {
                lng: -110.65504899782889,
                lat: 24.298594672131117
            },
            {
                lng: -110.17285620811344,
                lat: 24.265547593680424
            },
            {
                lng: -109.77184709352855,
                lat: 23.811182562754198
            },
            {
                lng: -109.40910437705571,
                lat: 23.36467234953625
            },
            {
                lng: -109.43339230023292,
                lat: 23.1855876734287
            },
            {
                lng: -109.85421932660171,
                lat: 22.818271592698068
            },
            {
                lng: -110.03139197471444,
                lat: 22.823077500901206
            },
            {
                lng: -110.29507097048366,
                lat: 23.43097321216669
            },
            {
                lng: -110.94950130902805,
                lat: 24.000964260345995
            },
            {
                lng: -111.6705684070127,
                lat: 24.484423122652515
            },
            {
                lng: -112.18203589562148,
                lat: 24.738412787367167
            },
            {
                lng: -112.14898881717085,
                lat: 25.47012523040405
            },
            {
                lng: -112.3007108223797,
                lat: 26.012004299416617
            },
            {
                lng: -112.77729671919155,
                lat: 26.32195954030317
            },
            {
                lng: -113.46467078332194,
                lat: 26.768185533143424
            },
            {
                lng: -113.59672990604383,
                lat: 26.639459540304472
            },
            {
                lng: -113.84893673384425,
                lat: 26.90006378835244
            },
            {
                lng: -114.46574662968004,
                lat: 27.142090358991368
            },
            {
                lng: -115.05514217818501,
                lat: 27.72272675222291
            },
            {
                lng: -114.98225257043742,
                lat: 27.798200181585116
            },
            {
                lng: -114.57036556685495,
                lat: 27.74148529714489
            },
            {
                lng: -114.19932878299926,
                lat: 28.115002549750557
            },
            {
                lng: -114.16201839888464,
                lat: 28.566111965442303
            },
            {
                lng: -114.93184221073665,
                lat: 29.27947927501549
            },
            {
                lng: -115.518653937627,
                lat: 29.5563615992354
            },
            {
                lng: -115.88736528202958,
                lat: 30.180793768834178
            },
            {
                lng: -116.25835038945291,
                lat: 30.836464341753583
            },
            {
                lng: -116.72152625208497,
                lat: 31.635743720012044
            },
            {
                lng: -117.12775999999985,
                lat: 32.53534
            }
        ]
    },
    UY: {
        name: 'Uruguay',
        type: 'Polygon',
        coordinates: [
            {
                lng: -57.62513342958296,
                lat: -30.21629485445426
            },
            {
                lng: -56.976025763564735,
                lat: -30.109686374636127
            },
            {
                lng: -55.97324459494094,
                lat: -30.883075860316303
            },
            {
                lng: -55.601510179249345,
                lat: -30.853878676071393
            },
            {
                lng: -54.57245154480512,
                lat: -31.494511407193748
            },
            {
                lng: -53.78795162618219,
                lat: -32.047242526987624
            },
            {
                lng: -53.209588995971544,
                lat: -32.727666110974724
            },
            {
                lng: -53.6505439927181,
                lat: -33.20200408298183
            },
            {
                lng: -53.373661668498244,
                lat: -33.768377780900764
            },
            {
                lng: -53.806425950726535,
                lat: -34.39681487400223
            },
            {
                lng: -54.93586605489773,
                lat: -34.952646579733624
            },
            {
                lng: -55.67408972840329,
                lat: -34.75265878676407
            },
            {
                lng: -56.21529700379607,
                lat: -34.85983570733742
            },
            {
                lng: -57.1396850246331,
                lat: -34.430456231424245
            },
            {
                lng: -57.81786068381551,
                lat: -34.4625472958775
            },
            {
                lng: -58.42707414410439,
                lat: -33.909454441057576
            },
            {
                lng: -58.349611172098875,
                lat: -33.26318897881541
            },
            {
                lng: -58.13264767112145,
                lat: -33.040566908502015
            },
            {
                lng: -58.14244035504076,
                lat: -32.044503676076154
            },
            {
                lng: -57.87493730328188,
                lat: -31.016556084926208
            },
            {
                lng: -57.62513342958296,
                lat: -30.21629485445426
            }
        ]
    },
    BR: {
        name: 'Brazil',
        type: 'Polygon',
        coordinates: [
            {
                lng: -53.373661668498244,
                lat: -33.768377780900764
            },
            {
                lng: -53.6505439927181,
                lat: -33.20200408298183
            },
            {
                lng: -53.209588995971544,
                lat: -32.727666110974724
            },
            {
                lng: -53.78795162618219,
                lat: -32.047242526987624
            },
            {
                lng: -54.57245154480512,
                lat: -31.494511407193748
            },
            {
                lng: -55.601510179249345,
                lat: -30.853878676071393
            },
            {
                lng: -55.97324459494094,
                lat: -30.883075860316303
            },
            {
                lng: -56.976025763564735,
                lat: -30.109686374636127
            },
            {
                lng: -57.62513342958296,
                lat: -30.21629485445426
            },
            {
                lng: -56.29089962423908,
                lat: -28.852760512000895
            },
            {
                lng: -55.16228634298457,
                lat: -27.881915378533463
            },
            {
                lng: -54.490725267135524,
                lat: -27.47475676850579
            },
            {
                lng: -53.64873531758789,
                lat: -26.92347258881609
            },
            {
                lng: -53.628348965048744,
                lat: -26.124865004177472
            },
            {
                lng: -54.13004960795439,
                lat: -25.547639255477254
            },
            {
                lng: -54.625290696823576,
                lat: -25.739255466415514
            },
            {
                lng: -54.42894609233059,
                lat: -25.162184747012166
            },
            {
                lng: -54.29347632507745,
                lat: -24.570799655863965
            },
            {
                lng: -54.29295956075452,
                lat: -24.02101409271073
            },
            {
                lng: -54.65283423523513,
                lat: -23.83957813893396
            },
            {
                lng: -55.02790178080955,
                lat: -24.00127369557523
            },
            {
                lng: -55.40074723979542,
                lat: -23.956935316668805
            },
            {
                lng: -55.517639329639636,
                lat: -23.571997572526637
            },
            {
                lng: -55.610682745981144,
                lat: -22.655619398694846
            },
            {
                lng: -55.79795813660691,
                lat: -22.356929620047822
            },
            {
                lng: -56.47331743022939,
                lat: -22.086300144135283
            },
            {
                lng: -56.8815095689029,
                lat: -22.28215382252148
            },
            {
                lng: -57.937155727761294,
                lat: -22.090175876557172
            },
            {
                lng: -57.8706739976178,
                lat: -20.73268767668195
            },
            {
                lng: -58.166392381408045,
                lat: -20.176700941653678
            },
            {
                lng: -57.85380164247451,
                lat: -19.96999521248619
            },
            {
                lng: -57.949997321185826,
                lat: -19.40000416430682
            },
            {
                lng: -57.67600887717431,
                lat: -18.96183969490403
            },
            {
                lng: -57.49837114117099,
                lat: -18.174187513911292
            },
            {
                lng: -57.734558274961,
                lat: -17.55246835700777
            },
            {
                lng: -58.28080400250225,
                lat: -17.271710300366017
            },
            {
                lng: -58.38805843772404,
                lat: -16.877109063385276
            },
            {
                lng: -58.24121985536668,
                lat: -16.299573256091293
            },
            {
                lng: -60.158389655179036,
                lat: -16.258283786690086
            },
            {
                lng: -60.54296566429515,
                lat: -15.093910414289596
            },
            {
                lng: -60.251148851142936,
                lat: -15.07721892665932
            },
            {
                lng: -60.26432634137737,
                lat: -14.645979099183641
            },
            {
                lng: -60.45919816755003,
                lat: -14.354007256734555
            },
            {
                lng: -60.503304002511136,
                lat: -13.775954685117659
            },
            {
                lng: -61.08412126325565,
                lat: -13.479383640194598
            },
            {
                lng: -61.71320431176078,
                lat: -13.489202162330052
            },
            {
                lng: -62.127080857986385,
                lat: -13.198780612849724
            },
            {
                lng: -62.803060268796386,
                lat: -13.000653171442686
            },
            {
                lng: -63.19649878605057,
                lat: -12.627032565972435
            },
            {
                lng: -64.3163529120316,
                lat: -12.461978041232193
            },
            {
                lng: -65.40228146021303,
                lat: -11.566270440317155
            },
            {
                lng: -65.32189876978302,
                lat: -10.895872084194679
            },
            {
                lng: -65.44483700220539,
                lat: -10.511451104375432
            },
            {
                lng: -65.33843522811642,
                lat: -9.761987806846392
            },
            {
                lng: -66.6469083319628,
                lat: -9.931331475466862
            },
            {
                lng: -67.17380123561074,
                lat: -10.306812432499612
            },
            {
                lng: -68.04819230820539,
                lat: -10.712059014532485
            },
            {
                lng: -68.27125362819326,
                lat: -11.01452117273682
            },
            {
                lng: -68.78615759954948,
                lat: -11.03638030359628
            },
            {
                lng: -69.52967810736496,
                lat: -10.951734307502194
            },
            {
                lng: -70.0937522040469,
                lat: -11.123971856331012
            },
            {
                lng: -70.54868567572841,
                lat: -11.009146823778465
            },
            {
                lng: -70.48189388699117,
                lat: -9.490118096558845
            },
            {
                lng: -71.30241227892154,
                lat: -10.079436130415374
            },
            {
                lng: -72.18489071316985,
                lat: -10.053597914269432
            },
            {
                lng: -72.56303300646564,
                lat: -9.520193780152717
            },
            {
                lng: -73.22671342639016,
                lat: -9.462212823121234
            },
            {
                lng: -73.01538265653255,
                lat: -9.032833347208062
            },
            {
                lng: -73.57105933296707,
                lat: -8.424446709835834
            },
            {
                lng: -73.98723548042966,
                lat: -7.523829847853065
            },
            {
                lng: -73.7234014553635,
                lat: -7.340998630404414
            },
            {
                lng: -73.72448666044164,
                lat: -6.91859547285064
            },
            {
                lng: -73.1200274319236,
                lat: -6.629930922068239
            },
            {
                lng: -73.21971126981461,
                lat: -6.089188734566078
            },
            {
                lng: -72.9645072089412,
                lat: -5.741251315944893
            },
            {
                lng: -72.89192765978726,
                lat: -5.274561455916981
            },
            {
                lng: -71.74840572781655,
                lat: -4.593982842633011
            },
            {
                lng: -70.92884334988358,
                lat: -4.401591485210368
            },
            {
                lng: -70.7947688463023,
                lat: -4.251264743673303
            },
            {
                lng: -69.89363521999663,
                lat: -4.298186944194327
            },
            {
                lng: -69.44410193548961,
                lat: -1.556287123219818
            },
            {
                lng: -69.42048580593223,
                lat: -1.122618503426409
            },
            {
                lng: -69.5770653957766,
                lat: -0.549991957200163
            },
            {
                lng: -70.02065589057005,
                lat: -0.185156345219539
            },
            {
                lng: -70.01556576198931,
                lat: 0.541414292804205
            },
            {
                lng: -69.45239600287246,
                lat: 0.706158758950693
            },
            {
                lng: -69.25243404811906,
                lat: 0.602650865070075
            },
            {
                lng: -69.21863766140018,
                lat: 0.985676581217433
            },
            {
                lng: -69.80459672715773,
                lat: 1.089081122233466
            },
            {
                lng: -69.81697323269162,
                lat: 1.714805202639624
            },
            {
                lng: -67.86856502955884,
                lat: 1.692455145673392
            },
            {
                lng: -67.5378100246747,
                lat: 2.03716278727633
            },
            {
                lng: -67.2599975246736,
                lat: 1.719998684084956
            },
            {
                lng: -67.0650481838525,
                lat: 1.130112209473225
            },
            {
                lng: -66.87632585312258,
                lat: 1.253360500489336
            },
            {
                lng: -66.32576514348496,
                lat: 0.724452215982012
            },
            {
                lng: -65.54826738143757,
                lat: 0.78925446207603
            },
            {
                lng: -65.35471330428837,
                lat: 1.0952822941085
            },
            {
                lng: -64.61101192895987,
                lat: 1.328730576987042
            },
            {
                lng: -64.19930579289051,
                lat: 1.49285492594602
            },
            {
                lng: -64.08308549666609,
                lat: 1.91636912679408
            },
            {
                lng: -63.368788011311665,
                lat: 2.200899562993129
            },
            {
                lng: -63.42286739770512,
                lat: 2.411067613124175
            },
            {
                lng: -64.2699991522658,
                lat: 2.497005520025567
            },
            {
                lng: -64.40882788761792,
                lat: 3.126786200366624
            },
            {
                lng: -64.3684944322141,
                lat: 3.797210394705246
            },
            {
                lng: -64.81606401229402,
                lat: 4.056445217297423
            },
            {
                lng: -64.62865943058755,
                lat: 4.14848094320925
            },
            {
                lng: -63.88834286157416,
                lat: 4.020530096854571
            },
            {
                lng: -63.093197597899106,
                lat: 3.770571193858785
            },
            {
                lng: -62.804533047116706,
                lat: 4.006965033377952
            },
            {
                lng: -62.08542965355913,
                lat: 4.162123521334308
            },
            {
                lng: -60.96689327660154,
                lat: 4.536467596856639
            },
            {
                lng: -60.601179165271944,
                lat: 4.91809804933213
            },
            {
                lng: -60.73357418480372,
                lat: 5.200277207861901
            },
            {
                lng: -60.21368343773133,
                lat: 5.244486395687602
            },
            {
                lng: -59.980958624904886,
                lat: 5.014061184098139
            },
            {
                lng: -60.11100236676738,
                lat: 4.574966538914083
            },
            {
                lng: -59.767405768458715,
                lat: 4.423502915866607
            },
            {
                lng: -59.53803992373123,
                lat: 3.958802598481938
            },
            {
                lng: -59.815413174057866,
                lat: 3.606498521332085
            },
            {
                lng: -59.97452490908456,
                lat: 2.755232652188056
            },
            {
                lng: -59.71854570172675,
                lat: 2.24963043864436
            },
            {
                lng: -59.64604366722126,
                lat: 1.786893825686789
            },
            {
                lng: -59.03086157900265,
                lat: 1.317697658692722
            },
            {
                lng: -58.540012986878295,
                lat: 1.268088283692521
            },
            {
                lng: -58.429477098205965,
                lat: 1.463941962078721
            },
            {
                lng: -58.11344987652502,
                lat: 1.507195135907025
            },
            {
                lng: -57.66097103537737,
                lat: 1.682584947105639
            },
            {
                lng: -57.335822923396904,
                lat: 1.948537705895759
            },
            {
                lng: -56.78270423036083,
                lat: 1.863710842288654
            },
            {
                lng: -56.539385748914555,
                lat: 1.899522609866921
            },
            {
                lng: -55.995698004771754,
                lat: 1.817667141116601
            },
            {
                lng: -55.905600145070885,
                lat: 2.02199575439866
            },
            {
                lng: -56.0733418442903,
                lat: 2.220794989425499
            },
            {
                lng: -55.973322109589375,
                lat: 2.510363877773017
            },
            {
                lng: -55.569755011606,
                lat: 2.421506252447131
            },
            {
                lng: -55.09758744975514,
                lat: 2.523748073736613
            },
            {
                lng: -54.524754197799716,
                lat: 2.311848863123785
            },
            {
                lng: -54.08806250671725,
                lat: 2.105556545414629
            },
            {
                lng: -53.77852067728892,
                lat: 2.376702785650082
            },
            {
                lng: -53.554839240113544,
                lat: 2.334896551925951
            },
            {
                lng: -53.41846513529531,
                lat: 2.053389187015981
            },
            {
                lng: -52.939657151894956,
                lat: 2.124857692875636
            },
            {
                lng: -52.55642473001842,
                lat: 2.504705308437053
            },
            {
                lng: -52.249337531123956,
                lat: 3.241094468596245
            },
            {
                lng: -51.65779741067889,
                lat: 4.156232408053029
            },
            {
                lng: -51.31714636901086,
                lat: 4.203490505383954
            },
            {
                lng: -51.069771287629656,
                lat: 3.650397650564031
            },
            {
                lng: -50.508875291533656,
                lat: 1.901563828942457
            },
            {
                lng: -49.97407589374506,
                lat: 1.736483465986069
            },
            {
                lng: -49.94710079608871,
                lat: 1.046189683431223
            },
            {
                lng: -50.699251268096916,
                lat: 0.222984117021682
            },
            {
                lng: -50.38821082213214,
                lat: -0.078444512536819
            },
            {
                lng: -48.62056677915632,
                lat: -0.235489190271821
            },
            {
                lng: -48.58449662941659,
                lat: -1.237805271005001
            },
            {
                lng: -47.824956427590635,
                lat: -0.5816179337628
            },
            {
                lng: -46.566583624851226,
                lat: -0.941027520352776
            },
            {
                lng: -44.905703090990414,
                lat: -1.551739597178134
            },
            {
                lng: -44.417619187993665,
                lat: -2.137750339367976
            },
            {
                lng: -44.58158850765578,
                lat: -2.691308282078524
            },
            {
                lng: -43.418791266440195,
                lat: -2.383110039889793
            },
            {
                lng: -41.47265682632825,
                lat: -2.912018324397116
            },
            {
                lng: -39.97866533055404,
                lat: -2.873054294449041
            },
            {
                lng: -38.50038347019657,
                lat: -3.700652357603396
            },
            {
                lng: -37.2232521225352,
                lat: -4.820945733258917
            },
            {
                lng: -36.45293738457639,
                lat: -5.109403578312154
            },
            {
                lng: -35.59779578301047,
                lat: -5.149504489770649
            },
            {
                lng: -35.23538896334756,
                lat: -5.464937432480247
            },
            {
                lng: -34.89602983248683,
                lat: -6.738193047719711
            },
            {
                lng: -34.729993455533034,
                lat: -7.343220716992967
            },
            {
                lng: -35.12821204277422,
                lat: -8.996401462442286
            },
            {
                lng: -35.636966518687714,
                lat: -9.649281508017815
            },
            {
                lng: -37.046518724097,
                lat: -11.040721123908803
            },
            {
                lng: -37.68361161960736,
                lat: -12.171194756725823
            },
            {
                lng: -38.42387651218844,
                lat: -13.038118584854288
            },
            {
                lng: -38.67388709161652,
                lat: -13.057652276260619
            },
            {
                lng: -38.953275722802545,
                lat: -13.793369642800023
            },
            {
                lng: -38.88229814304965,
                lat: -15.667053724838768
            },
            {
                lng: -39.16109249526431,
                lat: -17.208406670808472
            },
            {
                lng: -39.2673392400564,
                lat: -17.867746270420483
            },
            {
                lng: -39.58352149103423,
                lat: -18.262295830968938
            },
            {
                lng: -39.76082333022764,
                lat: -19.59911345792741
            },
            {
                lng: -40.77474077001034,
                lat: -20.904511814052423
            },
            {
                lng: -40.94475623225061,
                lat: -21.93731698983781
            },
            {
                lng: -41.754164191238225,
                lat: -22.370675551037458
            },
            {
                lng: -41.98828426773656,
                lat: -22.970070489190896
            },
            {
                lng: -43.07470374202475,
                lat: -22.96769337330547
            },
            {
                lng: -44.64781185563781,
                lat: -23.351959323827842
            },
            {
                lng: -45.35213578955992,
                lat: -23.796841729428582
            },
            {
                lng: -46.47209326840554,
                lat: -24.088968601174543
            },
            {
                lng: -47.64897233742066,
                lat: -24.885199069927722
            },
            {
                lng: -48.4954581365777,
                lat: -25.877024834905654
            },
            {
                lng: -48.64100480812774,
                lat: -26.623697605090932
            },
            {
                lng: -48.474735887228654,
                lat: -27.17591196056189
            },
            {
                lng: -48.661520351747626,
                lat: -28.18613453543572
            },
            {
                lng: -48.8884574041574,
                lat: -28.674115085567884
            },
            {
                lng: -49.587329474472675,
                lat: -29.224469089476337
            },
            {
                lng: -50.696874152211485,
                lat: -30.98446502047296
            },
            {
                lng: -51.576226162306156,
                lat: -31.77769825615321
            },
            {
                lng: -52.256081305538046,
                lat: -32.24536996839467
            },
            {
                lng: -52.712099982297694,
                lat: -33.19657805759118
            },
            {
                lng: -53.373661668498244,
                lat: -33.768377780900764
            }
        ]
    },
    BO: {
        name: 'Bolivia',
        type: 'Polygon',
        coordinates: [
            {
                lng: -69.52967810736496,
                lat: -10.951734307502194
            },
            {
                lng: -68.78615759954948,
                lat: -11.03638030359628
            },
            {
                lng: -68.27125362819326,
                lat: -11.01452117273682
            },
            {
                lng: -68.04819230820539,
                lat: -10.712059014532485
            },
            {
                lng: -67.17380123561074,
                lat: -10.306812432499612
            },
            {
                lng: -66.6469083319628,
                lat: -9.931331475466862
            },
            {
                lng: -65.33843522811642,
                lat: -9.761987806846392
            },
            {
                lng: -65.44483700220539,
                lat: -10.511451104375432
            },
            {
                lng: -65.32189876978302,
                lat: -10.895872084194679
            },
            {
                lng: -65.40228146021303,
                lat: -11.566270440317155
            },
            {
                lng: -64.3163529120316,
                lat: -12.461978041232193
            },
            {
                lng: -63.19649878605057,
                lat: -12.627032565972435
            },
            {
                lng: -62.803060268796386,
                lat: -13.000653171442686
            },
            {
                lng: -62.127080857986385,
                lat: -13.198780612849724
            },
            {
                lng: -61.71320431176078,
                lat: -13.489202162330052
            },
            {
                lng: -61.08412126325565,
                lat: -13.479383640194598
            },
            {
                lng: -60.503304002511136,
                lat: -13.775954685117659
            },
            {
                lng: -60.45919816755003,
                lat: -14.354007256734555
            },
            {
                lng: -60.26432634137737,
                lat: -14.645979099183641
            },
            {
                lng: -60.251148851142936,
                lat: -15.07721892665932
            },
            {
                lng: -60.54296566429515,
                lat: -15.093910414289596
            },
            {
                lng: -60.158389655179036,
                lat: -16.258283786690086
            },
            {
                lng: -58.24121985536668,
                lat: -16.299573256091293
            },
            {
                lng: -58.38805843772404,
                lat: -16.877109063385276
            },
            {
                lng: -58.28080400250225,
                lat: -17.271710300366017
            },
            {
                lng: -57.734558274961,
                lat: -17.55246835700777
            },
            {
                lng: -57.49837114117099,
                lat: -18.174187513911292
            },
            {
                lng: -57.67600887717431,
                lat: -18.96183969490403
            },
            {
                lng: -57.949997321185826,
                lat: -19.40000416430682
            },
            {
                lng: -57.85380164247451,
                lat: -19.96999521248619
            },
            {
                lng: -58.166392381408045,
                lat: -20.176700941653678
            },
            {
                lng: -58.183471442280506,
                lat: -19.868399346600363
            },
            {
                lng: -59.11504248720611,
                lat: -19.3569060197754
            },
            {
                lng: -60.04356462262649,
                lat: -19.342746677327426
            },
            {
                lng: -61.78632646345377,
                lat: -19.633736667562964
            },
            {
                lng: -62.2659612697708,
                lat: -20.513734633061276
            },
            {
                lng: -62.291179368729225,
                lat: -21.051634616787393
            },
            {
                lng: -62.685057135657885,
                lat: -22.249029229422387
            },
            {
                lng: -62.84646847192156,
                lat: -22.03498544686945
            },
            {
                lng: -63.986838141522476,
                lat: -21.99364430103595
            },
            {
                lng: -64.37702104354226,
                lat: -22.79809132252354
            },
            {
                lng: -64.96489213729461,
                lat: -22.075861504812327
            },
            {
                lng: -66.27333940292485,
                lat: -21.83231047942072
            },
            {
                lng: -67.1066735500636,
                lat: -22.735924574476417
            },
            {
                lng: -67.82817989772273,
                lat: -22.872918796482175
            },
            {
                lng: -68.21991309271128,
                lat: -21.494346612231865
            },
            {
                lng: -68.75716712103375,
                lat: -20.372657972904463
            },
            {
                lng: -68.44222510443092,
                lat: -19.40506845467143
            },
            {
                lng: -68.96681840684187,
                lat: -18.981683444904107
            },
            {
                lng: -69.10024695501949,
                lat: -18.260125420812678
            },
            {
                lng: -69.59042375352405,
                lat: -17.580011895419332
            },
            {
                lng: -68.9596353827533,
                lat: -16.50069793057127
            },
            {
                lng: -69.38976416693471,
                lat: -15.660129082911652
            },
            {
                lng: -69.16034664577495,
                lat: -15.323973890853019
            },
            {
                lng: -69.33953467474701,
                lat: -14.953195489158832
            },
            {
                lng: -68.9488866848366,
                lat: -14.453639418193283
            },
            {
                lng: -68.92922380234954,
                lat: -13.602683607643009
            },
            {
                lng: -68.88007951523997,
                lat: -12.899729099176653
            },
            {
                lng: -68.66507971868963,
                lat: -12.561300144097173
            },
            {
                lng: -69.52967810736496,
                lat: -10.951734307502194
            }
        ]
    },
    PE: {
        name: 'Peru',
        type: 'Polygon',
        coordinates: [
            {
                lng: -69.89363521999663,
                lat: -4.298186944194327
            },
            {
                lng: -70.7947688463023,
                lat: -4.251264743673303
            },
            {
                lng: -70.92884334988358,
                lat: -4.401591485210368
            },
            {
                lng: -71.74840572781655,
                lat: -4.593982842633011
            },
            {
                lng: -72.89192765978726,
                lat: -5.274561455916981
            },
            {
                lng: -72.9645072089412,
                lat: -5.741251315944893
            },
            {
                lng: -73.21971126981461,
                lat: -6.089188734566078
            },
            {
                lng: -73.1200274319236,
                lat: -6.629930922068239
            },
            {
                lng: -73.72448666044164,
                lat: -6.91859547285064
            },
            {
                lng: -73.7234014553635,
                lat: -7.340998630404414
            },
            {
                lng: -73.98723548042966,
                lat: -7.523829847853065
            },
            {
                lng: -73.57105933296707,
                lat: -8.424446709835834
            },
            {
                lng: -73.01538265653255,
                lat: -9.032833347208062
            },
            {
                lng: -73.22671342639016,
                lat: -9.462212823121234
            },
            {
                lng: -72.56303300646564,
                lat: -9.520193780152717
            },
            {
                lng: -72.18489071316985,
                lat: -10.053597914269432
            },
            {
                lng: -71.30241227892154,
                lat: -10.079436130415374
            },
            {
                lng: -70.48189388699117,
                lat: -9.490118096558845
            },
            {
                lng: -70.54868567572841,
                lat: -11.009146823778465
            },
            {
                lng: -70.0937522040469,
                lat: -11.123971856331012
            },
            {
                lng: -69.52967810736496,
                lat: -10.951734307502194
            },
            {
                lng: -68.66507971868963,
                lat: -12.561300144097173
            },
            {
                lng: -68.88007951523997,
                lat: -12.899729099176653
            },
            {
                lng: -68.92922380234954,
                lat: -13.602683607643009
            },
            {
                lng: -68.9488866848366,
                lat: -14.453639418193283
            },
            {
                lng: -69.33953467474701,
                lat: -14.953195489158832
            },
            {
                lng: -69.16034664577495,
                lat: -15.323973890853019
            },
            {
                lng: -69.38976416693471,
                lat: -15.660129082911652
            },
            {
                lng: -68.9596353827533,
                lat: -16.50069793057127
            },
            {
                lng: -69.59042375352405,
                lat: -17.580011895419332
            },
            {
                lng: -69.85844356960587,
                lat: -18.092693780187012
            },
            {
                lng: -70.37257239447771,
                lat: -18.34797535570887
            },
            {
                lng: -71.37525021023693,
                lat: -17.773798516513857
            },
            {
                lng: -71.46204077827113,
                lat: -17.363487644116383
            },
            {
                lng: -73.44452958850042,
                lat: -16.359362888252996
            },
            {
                lng: -75.23788265654144,
                lat: -15.265682875227782
            },
            {
                lng: -76.00920508492995,
                lat: -14.649286390850321
            },
            {
                lng: -76.42346920439775,
                lat: -13.823186944232432
            },
            {
                lng: -76.25924150257417,
                lat: -13.535039157772943
            },
            {
                lng: -77.10619238962184,
                lat: -12.22271615972082
            },
            {
                lng: -78.09215287953464,
                lat: -10.377712497604065
            },
            {
                lng: -79.03695309112695,
                lat: -8.386567884965892
            },
            {
                lng: -79.44592037628485,
                lat: -7.93083342858386
            },
            {
                lng: -79.76057817251005,
                lat: -7.194340915560084
            },
            {
                lng: -80.53748165558608,
                lat: -6.541667575713717
            },
            {
                lng: -81.24999630402642,
                lat: -6.136834405139183
            },
            {
                lng: -80.92634680858244,
                lat: -5.690556735866565
            },
            {
                lng: -81.41094255239946,
                lat: -4.736764825055459
            },
            {
                lng: -81.09966956248937,
                lat: -4.036394138203697
            },
            {
                lng: -80.30256059438722,
                lat: -3.404856459164713
            },
            {
                lng: -80.18401485870967,
                lat: -3.821161797708044
            },
            {
                lng: -80.46929460317695,
                lat: -4.059286797708999
            },
            {
                lng: -80.44224199087216,
                lat: -4.425724379090674
            },
            {
                lng: -80.02890804718561,
                lat: -4.346090996928893
            },
            {
                lng: -79.62497921417618,
                lat: -4.454198093283495
            },
            {
                lng: -79.20528906931773,
                lat: -4.959128513207389
            },
            {
                lng: -78.63989722361234,
                lat: -4.547784112164074
            },
            {
                lng: -78.45068396677564,
                lat: -3.873096612161376
            },
            {
                lng: -77.83790483265861,
                lat: -3.003020521663103
            },
            {
                lng: -76.63539425322672,
                lat: -2.608677666843818
            },
            {
                lng: -75.54499569365204,
                lat: -1.56160979574588
            },
            {
                lng: -75.23372270374195,
                lat: -0.911416924649529
            },
            {
                lng: -75.37322323271385,
                lat: -0.15203175212045
            },
            {
                lng: -75.10662451852008,
                lat: -0.05720549886486
            },
            {
                lng: -74.44160051135597,
                lat: -0.530820000819887
            },
            {
                lng: -74.12239518908906,
                lat: -1.002832533373848
            },
            {
                lng: -73.6595035468346,
                lat: -1.260491224781134
            },
            {
                lng: -73.07039221870724,
                lat: -2.308954359550953
            },
            {
                lng: -72.32578650581365,
                lat: -2.434218031426454
            },
            {
                lng: -71.7747607082854,
                lat: -2.169789727388938
            },
            {
                lng: -71.41364579942979,
                lat: -2.342802422702128
            },
            {
                lng: -70.81347571479196,
                lat: -2.256864515800743
            },
            {
                lng: -70.04770850287485,
                lat: -2.725156345229699
            },
            {
                lng: -70.69268205430971,
                lat: -3.742872002785859
            },
            {
                lng: -70.39404395209499,
                lat: -3.766591485207825
            },
            {
                lng: -69.89363521999663,
                lat: -4.298186944194327
            }
        ]
    },
    CO: {
        name: 'Colombia',
        type: 'Polygon',
        coordinates: [
            {
                lng: -66.87632585312258,
                lat: 1.253360500489336
            },
            {
                lng: -67.0650481838525,
                lat: 1.130112209473225
            },
            {
                lng: -67.2599975246736,
                lat: 1.719998684084956
            },
            {
                lng: -67.5378100246747,
                lat: 2.03716278727633
            },
            {
                lng: -67.86856502955884,
                lat: 1.692455145673392
            },
            {
                lng: -69.81697323269162,
                lat: 1.714805202639624
            },
            {
                lng: -69.80459672715773,
                lat: 1.089081122233466
            },
            {
                lng: -69.21863766140018,
                lat: 0.985676581217433
            },
            {
                lng: -69.25243404811906,
                lat: 0.602650865070075
            },
            {
                lng: -69.45239600287246,
                lat: 0.706158758950693
            },
            {
                lng: -70.01556576198931,
                lat: 0.541414292804205
            },
            {
                lng: -70.02065589057005,
                lat: -0.185156345219539
            },
            {
                lng: -69.5770653957766,
                lat: -0.549991957200163
            },
            {
                lng: -69.42048580593223,
                lat: -1.122618503426409
            },
            {
                lng: -69.44410193548961,
                lat: -1.556287123219818
            },
            {
                lng: -69.89363521999663,
                lat: -4.298186944194327
            },
            {
                lng: -70.39404395209499,
                lat: -3.766591485207825
            },
            {
                lng: -70.69268205430971,
                lat: -3.742872002785859
            },
            {
                lng: -70.04770850287485,
                lat: -2.725156345229699
            },
            {
                lng: -70.81347571479196,
                lat: -2.256864515800743
            },
            {
                lng: -71.41364579942979,
                lat: -2.342802422702128
            },
            {
                lng: -71.7747607082854,
                lat: -2.169789727388938
            },
            {
                lng: -72.32578650581365,
                lat: -2.434218031426454
            },
            {
                lng: -73.07039221870724,
                lat: -2.308954359550953
            },
            {
                lng: -73.6595035468346,
                lat: -1.260491224781134
            },
            {
                lng: -74.12239518908906,
                lat: -1.002832533373848
            },
            {
                lng: -74.44160051135597,
                lat: -0.530820000819887
            },
            {
                lng: -75.10662451852008,
                lat: -0.05720549886486
            },
            {
                lng: -75.37322323271385,
                lat: -0.15203175212045
            },
            {
                lng: -75.8014658271166,
                lat: 0.084801337073202
            },
            {
                lng: -76.29231441924097,
                lat: 0.416047268064119
            },
            {
                lng: -76.5763797675494,
                lat: 0.256935533037435
            },
            {
                lng: -77.4249843004304,
                lat: 0.395686753741117
            },
            {
                lng: -77.66861284047044,
                lat: 0.825893052570962
            },
            {
                lng: -77.85506140817952,
                lat: 0.809925034992773
            },
            {
                lng: -78.85525875518871,
                lat: 1.380923773601822
            },
            {
                lng: -78.99093522817104,
                lat: 1.691369940595251
            },
            {
                lng: -78.61783138702371,
                lat: 1.766404120283056
            },
            {
                lng: -78.66211808949785,
                lat: 2.267355454920477
            },
            {
                lng: -78.42761043975733,
                lat: 2.629555568854215
            },
            {
                lng: -77.93154252797149,
                lat: 2.696605739752926
            },
            {
                lng: -77.51043128122501,
                lat: 3.325016994638247
            },
            {
                lng: -77.12768978545526,
                lat: 3.849636135265357
            },
            {
                lng: -77.49627193877703,
                lat: 4.087606105969428
            },
            {
                lng: -77.3076012844794,
                lat: 4.667984117039452
            },
            {
                lng: -77.53322058786573,
                lat: 5.582811997902497
            },
            {
                lng: -77.31881507028675,
                lat: 5.84535411216136
            },
            {
                lng: -77.47666073272228,
                lat: 6.691116441266303
            },
            {
                lng: -77.88157141794525,
                lat: 7.223771267114785
            },
            {
                lng: -77.7534138658614,
                lat: 7.709839789252143
            },
            {
                lng: -77.43110795765699,
                lat: 7.638061224798734
            },
            {
                lng: -77.24256649444008,
                lat: 7.935278225125444
            },
            {
                lng: -77.47472286651133,
                lat: 8.524286200388218
            },
            {
                lng: -77.35336076527386,
                lat: 8.67050466555807
            },
            {
                lng: -76.83667395700357,
                lat: 8.638749497914716
            },
            {
                lng: -76.08638383655786,
                lat: 9.336820583529487
            },
            {
                lng: -75.67460018584006,
                lat: 9.443248195834599
            },
            {
                lng: -75.66470414905618,
                lat: 9.774003200718738
            },
            {
                lng: -75.48042599150335,
                lat: 10.618990383339309
            },
            {
                lng: -74.90689510771199,
                lat: 11.083044745320322
            },
            {
                lng: -74.27675269234489,
                lat: 11.102035834187587
            },
            {
                lng: -74.1972226630477,
                lat: 11.310472723836867
            },
            {
                lng: -73.41476396350029,
                lat: 11.22701528568548
            },
            {
                lng: -72.62783525255963,
                lat: 11.731971543825523
            },
            {
                lng: -72.23819495307892,
                lat: 11.955549628136326
            },
            {
                lng: -71.75409013536864,
                lat: 12.437303168177309
            },
            {
                lng: -71.3998223537917,
                lat: 12.376040757695293
            },
            {
                lng: -71.13746110704588,
                lat: 12.112981879113505
            },
            {
                lng: -71.3315836249503,
                lat: 11.776284084515808
            },
            {
                lng: -71.97392167833829,
                lat: 11.60867157637712
            },
            {
                lng: -72.22757544624294,
                lat: 11.10870209395324
            },
            {
                lng: -72.61465776232521,
                lat: 10.821975409381778
            },
            {
                lng: -72.9052860175347,
                lat: 10.450344346554772
            },
            {
                lng: -73.02760413276957,
                lat: 9.736770331252444
            },
            {
                lng: -73.30495154488005,
                lat: 9.151999823437606
            },
            {
                lng: -72.7887298245004,
                lat: 9.085027167187334
            },
            {
                lng: -72.6604947577681,
                lat: 8.625287787302682
            },
            {
                lng: -72.43986223009796,
                lat: 8.405275376820029
            },
            {
                lng: -72.36090064155597,
                lat: 8.002638454617895
            },
            {
                lng: -72.47967892117885,
                lat: 7.632506008327354
            },
            {
                lng: -72.44448727078807,
                lat: 7.423784898300482
            },
            {
                lng: -72.19835242378188,
                lat: 7.340430813013683
            },
            {
                lng: -71.96017574734864,
                lat: 6.991614895043539
            },
            {
                lng: -70.67423356798152,
                lat: 7.087784735538719
            },
            {
                lng: -70.09331295437242,
                lat: 6.96037649172311
            },
            {
                lng: -69.38947994655712,
                lat: 6.099860541198836
            },
            {
                lng: -68.98531856960236,
                lat: 6.206804917826858
            },
            {
                lng: -68.26505245631823,
                lat: 6.153268133972475
            },
            {
                lng: -67.69508724635502,
                lat: 6.267318020040647
            },
            {
                lng: -67.34143958196557,
                lat: 6.095468044454023
            },
            {
                lng: -67.52153194850275,
                lat: 5.556870428891969
            },
            {
                lng: -67.74469662135522,
                lat: 5.221128648291668
            },
            {
                lng: -67.82301225449355,
                lat: 4.503937282728899
            },
            {
                lng: -67.62183590358129,
                lat: 3.839481716319995
            },
            {
                lng: -67.33756384954368,
                lat: 3.542342230641722
            },
            {
                lng: -67.30317318385345,
                lat: 3.31845408773718
            },
            {
                lng: -67.8099381171237,
                lat: 2.820655015469569
            },
            {
                lng: -67.44709204778631,
                lat: 2.600280869960869
            },
            {
                lng: -67.18129431829307,
                lat: 2.250638129074062
            },
            {
                lng: -66.87632585312258,
                lat: 1.253360500489336
            }
        ]
    },
    PA: {
        name: 'Panama',
        type: 'Polygon',
        coordinates: [
            {
                lng: -77.35336076527386,
                lat: 8.67050466555807
            },
            {
                lng: -77.47472286651133,
                lat: 8.524286200388218
            },
            {
                lng: -77.24256649444008,
                lat: 7.935278225125444
            },
            {
                lng: -77.43110795765699,
                lat: 7.638061224798734
            },
            {
                lng: -77.7534138658614,
                lat: 7.709839789252143
            },
            {
                lng: -77.88157141794525,
                lat: 7.223771267114785
            },
            {
                lng: -78.21493608266012,
                lat: 7.512254950384161
            },
            {
                lng: -78.42916073272607,
                lat: 8.052041123888927
            },
            {
                lng: -78.18209570993864,
                lat: 8.319182440621773
            },
            {
                lng: -78.4354652574657,
                lat: 8.38770538984079
            },
            {
                lng: -78.62212053090394,
                lat: 8.718124497915028
            },
            {
                lng: -79.12030717641375,
                lat: 8.996092027213024
            },
            {
                lng: -79.55787736684519,
                lat: 8.932374986197146
            },
            {
                lng: -79.76057817251005,
                lat: 8.5845150822244
            },
            {
                lng: -80.16448116730334,
                lat: 8.333315944853595
            },
            {
                lng: -80.38265906443962,
                lat: 8.298408514840432
            },
            {
                lng: -80.4806892564973,
                lat: 8.09030752200107
            },
            {
                lng: -80.00368994822716,
                lat: 7.547524115423372
            },
            {
                lng: -80.276670701809,
                lat: 7.419754136581716
            },
            {
                lng: -80.42115800649708,
                lat: 7.271571966984765
            },
            {
                lng: -80.8864009264208,
                lat: 7.220541490096537
            },
            {
                lng: -81.05954281281473,
                lat: 7.817921047390597
            },
            {
                lng: -81.18971574575795,
                lat: 7.64790558515034
            },
            {
                lng: -81.51951473664468,
                lat: 7.706610012233909
            },
            {
                lng: -81.72131120474447,
                lat: 8.108962714058435
            },
            {
                lng: -82.13144120962892,
                lat: 8.175392767769637
            },
            {
                lng: -82.39093441438257,
                lat: 8.29236237226229
            },
            {
                lng: -82.82008134635042,
                lat: 8.290863755725823
            },
            {
                lng: -82.85095801464482,
                lat: 8.073822740099956
            },
            {
                lng: -82.96578304719736,
                lat: 8.225027980985985
            },
            {
                lng: -82.91317643912421,
                lat: 8.42351715741907
            },
            {
                lng: -82.82977067740516,
                lat: 8.62629547773237
            },
            {
                lng: -82.86865719270477,
                lat: 8.807266343618522
            },
            {
                lng: -82.71918311230053,
                lat: 8.925708726431495
            },
            {
                lng: -82.92715491405916,
                lat: 9.074330145702916
            },
            {
                lng: -82.93289099804358,
                lat: 9.476812038608173
            },
            {
                lng: -82.54619625520348,
                lat: 9.566134751824677
            },
            {
                lng: -82.18712256542341,
                lat: 9.20744863528678
            },
            {
                lng: -82.20758643261097,
                lat: 8.9955752628901
            },
            {
                lng: -81.80856686066929,
                lat: 8.950616766796173
            },
            {
                lng: -81.71415401887204,
                lat: 9.031955471223583
            },
            {
                lng: -81.43928707551154,
                lat: 8.786234035675719
            },
            {
                lng: -80.94730160187676,
                lat: 8.858503526235907
            },
            {
                lng: -80.52190121125008,
                lat: 9.111072089062432
            },
            {
                lng: -79.91459977895599,
                lat: 9.31276520429762
            },
            {
                lng: -79.57330278188431,
                lat: 9.611610012241528
            },
            {
                lng: -79.02119177927793,
                lat: 9.552931423374105
            },
            {
                lng: -79.05845048696037,
                lat: 9.454565334506526
            },
            {
                lng: -78.50088762074719,
                lat: 9.420458889193881
            },
            {
                lng: -78.05592770049802,
                lat: 9.2477304142583
            },
            {
                lng: -77.72951351592641,
                lat: 8.946844387238869
            },
            {
                lng: -77.35336076527386,
                lat: 8.67050466555807
            }
        ]
    },
    CR: {
        name: 'Costa Rica',
        type: 'Polygon',
        coordinates: [
            {
                lng: -82.54619625520348,
                lat: 9.566134751824677
            },
            {
                lng: -82.93289099804358,
                lat: 9.476812038608173
            },
            {
                lng: -82.92715491405916,
                lat: 9.074330145702916
            },
            {
                lng: -82.71918311230053,
                lat: 8.925708726431495
            },
            {
                lng: -82.86865719270477,
                lat: 8.807266343618522
            },
            {
                lng: -82.82977067740516,
                lat: 8.62629547773237
            },
            {
                lng: -82.91317643912421,
                lat: 8.42351715741907
            },
            {
                lng: -82.96578304719736,
                lat: 8.225027980985985
            },
            {
                lng: -83.50843726269431,
                lat: 8.446926581247283
            },
            {
                lng: -83.71147396516908,
                lat: 8.656836249216866
            },
            {
                lng: -83.59631303580665,
                lat: 8.830443223501419
            },
            {
                lng: -83.63264156770784,
                lat: 9.051385809765321
            },
            {
                lng: -83.90988562695374,
                lat: 9.29080272057358
            },
            {
                lng: -84.30340165885636,
                lat: 9.487354030795714
            },
            {
                lng: -84.64764421256866,
                lat: 9.61553742109571
            },
            {
                lng: -84.71335079622777,
                lat: 9.908051866083852
            },
            {
                lng: -84.97566036654133,
                lat: 10.086723130733006
            },
            {
                lng: -84.91137488477024,
                lat: 9.795991522658923
            },
            {
                lng: -85.11092342806532,
                lat: 9.55703969974131
            },
            {
                lng: -85.33948828809227,
                lat: 9.83454214114866
            },
            {
                lng: -85.66078650586698,
                lat: 9.933347479690724
            },
            {
                lng: -85.79744483106285,
                lat: 10.134885565629034
            },
            {
                lng: -85.79170874707843,
                lat: 10.439337266476613
            },
            {
                lng: -85.65931372754667,
                lat: 10.75433095951172
            },
            {
                lng: -85.94172543002176,
                lat: 10.895278428587801
            },
            {
                lng: -85.7125404528073,
                lat: 11.088444932494824
            },
            {
                lng: -85.5618519762442,
                lat: 11.217119248901597
            },
            {
                lng: -84.90300330273895,
                lat: 10.952303371621896
            },
            {
                lng: -84.67306901725627,
                lat: 11.082657172078143
            },
            {
                lng: -84.35593075228104,
                lat: 10.999225572142905
            },
            {
                lng: -84.19017859570485,
                lat: 10.793450018756674
            },
            {
                lng: -83.89505449088595,
                lat: 10.726839097532446
            },
            {
                lng: -83.65561174186158,
                lat: 10.938764146361422
            },
            {
                lng: -83.40231970898296,
                lat: 10.395438137244653
            },
            {
                lng: -83.01567664257517,
                lat: 9.992982082555557
            },
            {
                lng: -82.54619625520348,
                lat: 9.566134751824677
            }
        ]
    },
    NI: {
        name: 'Nicaragua',
        type: 'Polygon',
        coordinates: [
            {
                lng: -83.65561174186158,
                lat: 10.938764146361422
            },
            {
                lng: -83.89505449088595,
                lat: 10.726839097532446
            },
            {
                lng: -84.19017859570485,
                lat: 10.793450018756674
            },
            {
                lng: -84.35593075228104,
                lat: 10.999225572142905
            },
            {
                lng: -84.67306901725627,
                lat: 11.082657172078143
            },
            {
                lng: -84.90300330273895,
                lat: 10.952303371621896
            },
            {
                lng: -85.5618519762442,
                lat: 11.217119248901597
            },
            {
                lng: -85.7125404528073,
                lat: 11.088444932494824
            },
            {
                lng: -86.05848832878526,
                lat: 11.403438625529944
            },
            {
                lng: -86.52584998243296,
                lat: 11.806876532432597
            },
            {
                lng: -86.74599158399633,
                lat: 12.143961900272487
            },
            {
                lng: -87.16751624220116,
                lat: 12.458257961471658
            },
            {
                lng: -87.66849341505471,
                lat: 12.909909979702633
            },
            {
                lng: -87.55746660027562,
                lat: 13.064551703336065
            },
            {
                lng: -87.39238623731923,
                lat: 12.914018256069838
            },
            {
                lng: -87.31665442579549,
                lat: 12.984685777228975
            },
            {
                lng: -87.00576900912758,
                lat: 13.025794379117158
            },
            {
                lng: -86.88055701368438,
                lat: 13.254204209847217
            },
            {
                lng: -86.7338217841916,
                lat: 13.263092556201443
            },
            {
                lng: -86.75508663607971,
                lat: 13.754845485890913
            },
            {
                lng: -86.52070817741992,
                lat: 13.778487453664468
            },
            {
                lng: -86.31214209668993,
                lat: 13.77135610600817
            },
            {
                lng: -86.09626380079061,
                lat: 14.038187364147234
            },
            {
                lng: -85.80129472526859,
                lat: 13.83605499923759
            },
            {
                lng: -85.69866533073696,
                lat: 13.960078436738002
            },
            {
                lng: -85.51441301140028,
                lat: 14.079011745657908
            },
            {
                lng: -85.16536454948482,
                lat: 14.354369615125051
            },
            {
                lng: -85.14875057650296,
                lat: 14.560196844943617
            },
            {
                lng: -85.05278744173694,
                lat: 14.551541042534723
            },
            {
                lng: -84.9245006985724,
                lat: 14.79049286545235
            },
            {
                lng: -84.82003679069436,
                lat: 14.81958669683267
            },
            {
                lng: -84.64958207877964,
                lat: 14.666805324761867
            },
            {
                lng: -84.4493359036486,
                lat: 14.621614284722511
            },
            {
                lng: -84.22834164095241,
                lat: 14.74876414637663
            },
            {
                lng: -83.97572140169359,
                lat: 14.749435939996488
            },
            {
                lng: -83.62858496777292,
                lat: 14.880073960830302
            },
            {
                lng: -83.48998877636612,
                lat: 15.016267198135537
            },
            {
                lng: -83.14721900097413,
                lat: 14.99582916916411
            },
            {
                lng: -83.23323442252394,
                lat: 14.899866034398102
            },
            {
                lng: -83.2841615465476,
                lat: 14.6766238468972
            },
            {
                lng: -83.18212643098728,
                lat: 14.31070302983845
            },
            {
                lng: -83.41249996614445,
                lat: 13.970077826386557
            },
            {
                lng: -83.51983191601468,
                lat: 13.567699286345883
            },
            {
                lng: -83.55220720084554,
                lat: 13.127054348193086
            },
            {
                lng: -83.49851538769427,
                lat: 12.869292303921227
            },
            {
                lng: -83.47332312695198,
                lat: 12.419087225794428
            },
            {
                lng: -83.62610449902292,
                lat: 12.320850328007566
            },
            {
                lng: -83.71961300325506,
                lat: 11.893124497927728
            },
            {
                lng: -83.65085751009072,
                lat: 11.62903209070012
            },
            {
                lng: -83.8554703437504,
                lat: 11.373311265503787
            },
            {
                lng: -83.80893571647155,
                lat: 11.103043524617275
            },
            {
                lng: -83.65561174186158,
                lat: 10.938764146361422
            }
        ]
    },
    HN: {
        name: 'Honduras',
        type: 'Polygon',
        coordinates: [
            {
                lng: -83.14721900097413,
                lat: 14.99582916916411
            },
            {
                lng: -83.48998877636612,
                lat: 15.016267198135537
            },
            {
                lng: -83.62858496777292,
                lat: 14.880073960830302
            },
            {
                lng: -83.97572140169359,
                lat: 14.749435939996488
            },
            {
                lng: -84.22834164095241,
                lat: 14.74876414637663
            },
            {
                lng: -84.4493359036486,
                lat: 14.621614284722511
            },
            {
                lng: -84.64958207877964,
                lat: 14.666805324761867
            },
            {
                lng: -84.82003679069436,
                lat: 14.81958669683267
            },
            {
                lng: -84.9245006985724,
                lat: 14.79049286545235
            },
            {
                lng: -85.05278744173694,
                lat: 14.551541042534723
            },
            {
                lng: -85.14875057650296,
                lat: 14.560196844943617
            },
            {
                lng: -85.16536454948482,
                lat: 14.354369615125051
            },
            {
                lng: -85.51441301140028,
                lat: 14.079011745657908
            },
            {
                lng: -85.69866533073696,
                lat: 13.960078436738002
            },
            {
                lng: -85.80129472526859,
                lat: 13.83605499923759
            },
            {
                lng: -86.09626380079061,
                lat: 14.038187364147234
            },
            {
                lng: -86.31214209668993,
                lat: 13.77135610600817
            },
            {
                lng: -86.52070817741992,
                lat: 13.778487453664468
            },
            {
                lng: -86.75508663607971,
                lat: 13.754845485890913
            },
            {
                lng: -86.7338217841916,
                lat: 13.263092556201443
            },
            {
                lng: -86.88055701368438,
                lat: 13.254204209847217
            },
            {
                lng: -87.00576900912758,
                lat: 13.025794379117158
            },
            {
                lng: -87.31665442579549,
                lat: 12.984685777228975
            },
            {
                lng: -87.48940873894713,
                lat: 13.297534898323931
            },
            {
                lng: -87.79311113152657,
                lat: 13.384480495655055
            },
            {
                lng: -87.7235029772294,
                lat: 13.785050360565506
            },
            {
                lng: -87.85951534702161,
                lat: 13.893312486217098
            },
            {
                lng: -88.06534257684014,
                lat: 13.964625962779778
            },
            {
                lng: -88.50399797234971,
                lat: 13.845485948130857
            },
            {
                lng: -88.541230841816,
                lat: 13.980154730683479
            },
            {
                lng: -88.84307288283284,
                lat: 14.14050670008517
            },
            {
                lng: -89.05851192905766,
                lat: 14.340029405164087
            },
            {
                lng: -89.35332597528281,
                lat: 14.424132798719086
            },
            {
                lng: -89.14553504103719,
                lat: 14.678019110569153
            },
            {
                lng: -89.22522009963127,
                lat: 14.874286200413621
            },
            {
                lng: -89.15481096063357,
                lat: 15.06641917567481
            },
            {
                lng: -88.68067969435563,
                lat: 15.346247056535304
            },
            {
                lng: -88.22502275262202,
                lat: 15.727722479713904
            },
            {
                lng: -88.12115312371537,
                lat: 15.688655096901357
            },
            {
                lng: -87.90181250685242,
                lat: 15.864458319558196
            },
            {
                lng: -87.61568010125234,
                lat: 15.878798529519202
            },
            {
                lng: -87.52292090528846,
                lat: 15.797278957578783
            },
            {
                lng: -87.36776241733213,
                lat: 15.846940009011291
            },
            {
                lng: -86.90319129102818,
                lat: 15.75671295822957
            },
            {
                lng: -86.44094560417739,
                lat: 15.782835394753192
            },
            {
                lng: -86.11923397494434,
                lat: 15.893448798073962
            },
            {
                lng: -86.00195431185784,
                lat: 16.00540578863439
            },
            {
                lng: -85.68331743034628,
                lat: 15.953651841693954
            },
            {
                lng: -85.44400387240256,
                lat: 15.885749009662447
            },
            {
                lng: -85.18244361035721,
                lat: 15.909158433490632
            },
            {
                lng: -84.98372188997882,
                lat: 15.995923163308703
            },
            {
                lng: -84.52697974316715,
                lat: 15.857223619037427
            },
            {
                lng: -84.36825558138258,
                lat: 15.835157782448732
            },
            {
                lng: -84.06305457226682,
                lat: 15.648244126849136
            },
            {
                lng: -83.77397661002612,
                lat: 15.424071763566872
            },
            {
                lng: -83.41038123242038,
                lat: 15.270902818253774
            },
            {
                lng: -83.14721900097413,
                lat: 14.99582916916411
            }
        ]
    },
    SV: {
        name: 'El Salvador',
        type: 'Polygon',
        coordinates: [
            {
                lng: -89.35332597528281,
                lat: 14.424132798719086
            },
            {
                lng: -89.05851192905766,
                lat: 14.340029405164087
            },
            {
                lng: -88.84307288283284,
                lat: 14.14050670008517
            },
            {
                lng: -88.541230841816,
                lat: 13.980154730683479
            },
            {
                lng: -88.50399797234971,
                lat: 13.845485948130857
            },
            {
                lng: -88.06534257684014,
                lat: 13.964625962779778
            },
            {
                lng: -87.85951534702161,
                lat: 13.893312486217098
            },
            {
                lng: -87.7235029772294,
                lat: 13.785050360565506
            },
            {
                lng: -87.79311113152657,
                lat: 13.384480495655055
            },
            {
                lng: -87.90411210808952,
                lat: 13.149016831917137
            },
            {
                lng: -88.48330156121682,
                lat: 13.163951320849492
            },
            {
                lng: -88.84322791212972,
                lat: 13.259733588102478
            },
            {
                lng: -89.2567427233293,
                lat: 13.458532823129303
            },
            {
                lng: -89.81239356154767,
                lat: 13.520622056527998
            },
            {
                lng: -90.09555457229098,
                lat: 13.735337632700734
            },
            {
                lng: -90.0646779039966,
                lat: 13.881969509328925
            },
            {
                lng: -89.72193396682073,
                lat: 14.134228013561696
            },
            {
                lng: -89.53421932652051,
                lat: 14.244815578666305
            },
            {
                lng: -89.58734269891656,
                lat: 14.36258616785949
            },
            {
                lng: -89.35332597528281,
                lat: 14.424132798719086
            }
        ]
    },
    GT: {
        name: 'Guatemala',
        type: 'Polygon',
        coordinates: [
            {
                lng: -92.22775000686983,
                lat: 14.538828640190928
            },
            {
                lng: -92.20322953974733,
                lat: 14.830102850804069
            },
            {
                lng: -92.08721594925207,
                lat: 15.064584662328441
            },
            {
                lng: -92.2292486234063,
                lat: 15.251446641495875
            },
            {
                lng: -91.74796017125595,
                lat: 16.066564846251765
            },
            {
                lng: -90.46447262242266,
                lat: 16.069562079324655
            },
            {
                lng: -90.43886695022204,
                lat: 16.410109768128095
            },
            {
                lng: -90.60084672724093,
                lat: 16.47077789963879
            },
            {
                lng: -90.71182186558772,
                lat: 16.687483018454728
            },
            {
                lng: -91.08167009150065,
                lat: 16.918476670799407
            },
            {
                lng: -91.45392127151516,
                lat: 17.252177232324172
            },
            {
                lng: -91.00226925328421,
                lat: 17.25465770107418
            },
            {
                lng: -91.00151994501596,
                lat: 17.81759491624571
            },
            {
                lng: -90.06793351923098,
                lat: 17.819326076727478
            },
            {
                lng: -89.14308041050333,
                lat: 17.808318996649405
            },
            {
                lng: -89.15080603713095,
                lat: 17.015576687075836
            },
            {
                lng: -89.22912167026928,
                lat: 15.886937567605171
            },
            {
                lng: -88.93061275913527,
                lat: 15.887273464415076
            },
            {
                lng: -88.60458614780585,
                lat: 15.70638011317736
            },
            {
                lng: -88.51836402052686,
                lat: 15.855389105690975
            },
            {
                lng: -88.22502275262202,
                lat: 15.727722479713904
            },
            {
                lng: -88.68067969435563,
                lat: 15.346247056535304
            },
            {
                lng: -89.15481096063357,
                lat: 15.06641917567481
            },
            {
                lng: -89.22522009963127,
                lat: 14.874286200413621
            },
            {
                lng: -89.14553504103719,
                lat: 14.678019110569153
            },
            {
                lng: -89.35332597528281,
                lat: 14.424132798719086
            },
            {
                lng: -89.58734269891656,
                lat: 14.36258616785949
            },
            {
                lng: -89.53421932652051,
                lat: 14.244815578666305
            },
            {
                lng: -89.72193396682073,
                lat: 14.134228013561696
            },
            {
                lng: -90.0646779039966,
                lat: 13.881969509328925
            },
            {
                lng: -90.09555457229098,
                lat: 13.735337632700734
            },
            {
                lng: -90.60862403030085,
                lat: 13.909771429901951
            },
            {
                lng: -91.23241024449605,
                lat: 13.927832342987957
            },
            {
                lng: -91.68974667027913,
                lat: 14.126218166556455
            },
            {
                lng: -92.22775000686983,
                lat: 14.538828640190928
            }
        ]
    },
    BZ: {
        name: 'Belize',
        type: 'Polygon',
        coordinates: [
            {
                lng: -89.14308041050333,
                lat: 17.808318996649405
            },
            {
                lng: -89.15090938999553,
                lat: 17.95546763760042
            },
            {
                lng: -89.02985734735182,
                lat: 18.001511338772488
            },
            {
                lng: -88.84834387892661,
                lat: 17.883198147040233
            },
            {
                lng: -88.49012285027935,
                lat: 18.486830552641607
            },
            {
                lng: -88.3000310940937,
                lat: 18.4999822046599
            },
            {
                lng: -88.29633622918482,
                lat: 18.35327281338327
            },
            {
                lng: -88.10681291375438,
                lat: 18.348673610909287
            },
            {
                lng: -88.1234785631685,
                lat: 18.07667470954101
            },
            {
                lng: -88.2853549873228,
                lat: 17.644142971258034
            },
            {
                lng: -88.19786678745265,
                lat: 17.489475409408456
            },
            {
                lng: -88.30264075392444,
                lat: 17.131693630435663
            },
            {
                lng: -88.23951799187991,
                lat: 17.036066392479555
            },
            {
                lng: -88.35542822951057,
                lat: 16.530774237529627
            },
            {
                lng: -88.55182451043585,
                lat: 16.265467434143147
            },
            {
                lng: -88.73243364129594,
                lat: 16.233634751851355
            },
            {
                lng: -88.93061275913527,
                lat: 15.887273464415076
            },
            {
                lng: -89.22912167026928,
                lat: 15.886937567605171
            },
            {
                lng: -89.15080603713095,
                lat: 17.015576687075836
            },
            {
                lng: -89.14308041050333,
                lat: 17.808318996649405
            }
        ]
    },
    VE: {
        name: 'Venezuela, RB',
        type: 'Polygon',
        coordinates: [
            {
                lng: -60.73357418480372,
                lat: 5.200277207861901
            },
            {
                lng: -60.601179165271944,
                lat: 4.91809804933213
            },
            {
                lng: -60.96689327660154,
                lat: 4.536467596856639
            },
            {
                lng: -62.08542965355913,
                lat: 4.162123521334308
            },
            {
                lng: -62.804533047116706,
                lat: 4.006965033377952
            },
            {
                lng: -63.093197597899106,
                lat: 3.770571193858785
            },
            {
                lng: -63.88834286157416,
                lat: 4.020530096854571
            },
            {
                lng: -64.62865943058755,
                lat: 4.14848094320925
            },
            {
                lng: -64.81606401229402,
                lat: 4.056445217297423
            },
            {
                lng: -64.3684944322141,
                lat: 3.797210394705246
            },
            {
                lng: -64.40882788761792,
                lat: 3.126786200366624
            },
            {
                lng: -64.2699991522658,
                lat: 2.497005520025567
            },
            {
                lng: -63.42286739770512,
                lat: 2.411067613124175
            },
            {
                lng: -63.368788011311665,
                lat: 2.200899562993129
            },
            {
                lng: -64.08308549666609,
                lat: 1.91636912679408
            },
            {
                lng: -64.19930579289051,
                lat: 1.49285492594602
            },
            {
                lng: -64.61101192895987,
                lat: 1.328730576987042
            },
            {
                lng: -65.35471330428837,
                lat: 1.0952822941085
            },
            {
                lng: -65.54826738143757,
                lat: 0.78925446207603
            },
            {
                lng: -66.32576514348496,
                lat: 0.724452215982012
            },
            {
                lng: -66.87632585312258,
                lat: 1.253360500489336
            },
            {
                lng: -67.18129431829307,
                lat: 2.250638129074062
            },
            {
                lng: -67.44709204778631,
                lat: 2.600280869960869
            },
            {
                lng: -67.8099381171237,
                lat: 2.820655015469569
            },
            {
                lng: -67.30317318385345,
                lat: 3.31845408773718
            },
            {
                lng: -67.33756384954368,
                lat: 3.542342230641722
            },
            {
                lng: -67.62183590358129,
                lat: 3.839481716319995
            },
            {
                lng: -67.82301225449355,
                lat: 4.503937282728899
            },
            {
                lng: -67.74469662135522,
                lat: 5.221128648291668
            },
            {
                lng: -67.52153194850275,
                lat: 5.556870428891969
            },
            {
                lng: -67.34143958196557,
                lat: 6.095468044454023
            },
            {
                lng: -67.69508724635502,
                lat: 6.267318020040647
            },
            {
                lng: -68.26505245631823,
                lat: 6.153268133972475
            },
            {
                lng: -68.98531856960236,
                lat: 6.206804917826858
            },
            {
                lng: -69.38947994655712,
                lat: 6.099860541198836
            },
            {
                lng: -70.09331295437242,
                lat: 6.96037649172311
            },
            {
                lng: -70.67423356798152,
                lat: 7.087784735538719
            },
            {
                lng: -71.96017574734864,
                lat: 6.991614895043539
            },
            {
                lng: -72.19835242378188,
                lat: 7.340430813013683
            },
            {
                lng: -72.44448727078807,
                lat: 7.423784898300482
            },
            {
                lng: -72.47967892117885,
                lat: 7.632506008327354
            },
            {
                lng: -72.36090064155597,
                lat: 8.002638454617895
            },
            {
                lng: -72.43986223009796,
                lat: 8.405275376820029
            },
            {
                lng: -72.6604947577681,
                lat: 8.625287787302682
            },
            {
                lng: -72.7887298245004,
                lat: 9.085027167187334
            },
            {
                lng: -73.30495154488005,
                lat: 9.151999823437606
            },
            {
                lng: -73.02760413276957,
                lat: 9.736770331252444
            },
            {
                lng: -72.9052860175347,
                lat: 10.450344346554772
            },
            {
                lng: -72.61465776232521,
                lat: 10.821975409381778
            },
            {
                lng: -72.22757544624294,
                lat: 11.10870209395324
            },
            {
                lng: -71.97392167833829,
                lat: 11.60867157637712
            },
            {
                lng: -71.3315836249503,
                lat: 11.776284084515808
            },
            {
                lng: -71.36000566271082,
                lat: 11.539993597861212
            },
            {
                lng: -71.94704993354651,
                lat: 11.423282375530022
            },
            {
                lng: -71.62086829292019,
                lat: 10.969459947142795
            },
            {
                lng: -71.63306393094109,
                lat: 10.446494452349029
            },
            {
                lng: -72.07417395698451,
                lat: 9.865651353388373
            },
            {
                lng: -71.69564409044654,
                lat: 9.072263088411248
            },
            {
                lng: -71.26455929226773,
                lat: 9.137194525585983
            },
            {
                lng: -71.03999935574339,
                lat: 9.859992784052409
            },
            {
                lng: -71.35008378771079,
                lat: 10.211935126176215
            },
            {
                lng: -71.40062333849224,
                lat: 10.968969021036015
            },
            {
                lng: -70.15529883490652,
                lat: 11.37548167566004
            },
            {
                lng: -70.29384334988103,
                lat: 11.846822414594214
            },
            {
                lng: -69.94324459499683,
                lat: 12.162307033736099
            },
            {
                lng: -69.58430009629747,
                lat: 11.459610907431212
            },
            {
                lng: -68.88299923366445,
                lat: 11.443384507691563
            },
            {
                lng: -68.23327145045873,
                lat: 10.885744126829946
            },
            {
                lng: -68.19412655299763,
                lat: 10.554653225135922
            },
            {
                lng: -67.29624854192633,
                lat: 10.54586823164631
            },
            {
                lng: -66.227864142508,
                lat: 10.648626817258688
            },
            {
                lng: -65.65523759628175,
                lat: 10.200798855017323
            },
            {
                lng: -64.89045223657817,
                lat: 10.0772146671913
            },
            {
                lng: -64.32947872583374,
                lat: 10.38959870039568
            },
            {
                lng: -64.31800655786495,
                lat: 10.64141795495398
            },
            {
                lng: -63.07932247582873,
                lat: 10.7017243514386
            },
            {
                lng: -61.880946010980196,
                lat: 10.715625311725104
            },
            {
                lng: -62.73011898461641,
                lat: 10.420268662960906
            },
            {
                lng: -62.388511928950976,
                lat: 9.94820445397464
            },
            {
                lng: -61.58876746280194,
                lat: 9.873066921422264
            },
            {
                lng: -60.83059668643172,
                lat: 9.381339829948942
            },
            {
                lng: -60.67125240745973,
                lat: 8.580174261911878
            },
            {
                lng: -60.15009558779618,
                lat: 8.602756862823426
            },
            {
                lng: -59.758284878159195,
                lat: 8.367034816924047
            },
            {
                lng: -60.5505879380582,
                lat: 7.779602972846178
            },
            {
                lng: -60.637972785063766,
                lat: 7.414999904810855
            },
            {
                lng: -60.2956680975624,
                lat: 7.043911444522919
            },
            {
                lng: -60.54399919294099,
                lat: 6.856584377464883
            },
            {
                lng: -61.15933631045648,
                lat: 6.696077378766319
            },
            {
                lng: -61.13941504580795,
                lat: 6.234296779806144
            },
            {
                lng: -61.410302903881956,
                lat: 5.959068101419618
            },
            {
                lng: -60.73357418480372,
                lat: 5.200277207861901
            }
        ]
    },
    GY: {
        name: 'Guyana',
        type: 'Polygon',
        coordinates: [
            {
                lng: -56.539385748914555,
                lat: 1.899522609866921
            },
            {
                lng: -56.78270423036083,
                lat: 1.863710842288654
            },
            {
                lng: -57.335822923396904,
                lat: 1.948537705895759
            },
            {
                lng: -57.66097103537737,
                lat: 1.682584947105639
            },
            {
                lng: -58.11344987652502,
                lat: 1.507195135907025
            },
            {
                lng: -58.429477098205965,
                lat: 1.463941962078721
            },
            {
                lng: -58.540012986878295,
                lat: 1.268088283692521
            },
            {
                lng: -59.03086157900265,
                lat: 1.317697658692722
            },
            {
                lng: -59.64604366722126,
                lat: 1.786893825686789
            },
            {
                lng: -59.71854570172675,
                lat: 2.24963043864436
            },
            {
                lng: -59.97452490908456,
                lat: 2.755232652188056
            },
            {
                lng: -59.815413174057866,
                lat: 3.606498521332085
            },
            {
                lng: -59.53803992373123,
                lat: 3.958802598481938
            },
            {
                lng: -59.767405768458715,
                lat: 4.423502915866607
            },
            {
                lng: -60.11100236676738,
                lat: 4.574966538914083
            },
            {
                lng: -59.980958624904886,
                lat: 5.014061184098139
            },
            {
                lng: -60.21368343773133,
                lat: 5.244486395687602
            },
            {
                lng: -60.73357418480372,
                lat: 5.200277207861901
            },
            {
                lng: -61.410302903881956,
                lat: 5.959068101419618
            },
            {
                lng: -61.13941504580795,
                lat: 6.234296779806144
            },
            {
                lng: -61.15933631045648,
                lat: 6.696077378766319
            },
            {
                lng: -60.54399919294099,
                lat: 6.856584377464883
            },
            {
                lng: -60.2956680975624,
                lat: 7.043911444522919
            },
            {
                lng: -60.637972785063766,
                lat: 7.414999904810855
            },
            {
                lng: -60.5505879380582,
                lat: 7.779602972846178
            },
            {
                lng: -59.758284878159195,
                lat: 8.367034816924047
            },
            {
                lng: -59.10168412945866,
                lat: 7.999201971870492
            },
            {
                lng: -58.48296220562806,
                lat: 7.347691351750697
            },
            {
                lng: -58.45487606467742,
                lat: 6.832787380394464
            },
            {
                lng: -58.078103196837375,
                lat: 6.809093736188643
            },
            {
                lng: -57.542218593970645,
                lat: 6.321268215353356
            },
            {
                lng: -57.14743648947689,
                lat: 5.973149929219161
            },
            {
                lng: -57.307245856339506,
                lat: 5.073566595882227
            },
            {
                lng: -57.91428890647214,
                lat: 4.812626451024414
            },
            {
                lng: -57.8602095200787,
                lat: 4.57680105226045
            },
            {
                lng: -58.04469438336068,
                lat: 4.060863552258382
            },
            {
                lng: -57.60156897645787,
                lat: 3.334654649260685
            },
            {
                lng: -57.28143347840971,
                lat: 3.333491929534119
            },
            {
                lng: -57.15009782573991,
                lat: 2.768926906745406
            },
            {
                lng: -56.539385748914555,
                lat: 1.899522609866921
            }
        ]
    },
    SR: {
        name: 'Suriname',
        type: 'Polygon',
        coordinates: [
            {
                lng: -54.524754197799716,
                lat: 2.311848863123785
            },
            {
                lng: -55.09758744975514,
                lat: 2.523748073736613
            },
            {
                lng: -55.569755011606,
                lat: 2.421506252447131
            },
            {
                lng: -55.973322109589375,
                lat: 2.510363877773017
            },
            {
                lng: -56.0733418442903,
                lat: 2.220794989425499
            },
            {
                lng: -55.905600145070885,
                lat: 2.02199575439866
            },
            {
                lng: -55.995698004771754,
                lat: 1.817667141116601
            },
            {
                lng: -56.539385748914555,
                lat: 1.899522609866921
            },
            {
                lng: -57.15009782573991,
                lat: 2.768926906745406
            },
            {
                lng: -57.28143347840971,
                lat: 3.333491929534119
            },
            {
                lng: -57.60156897645787,
                lat: 3.334654649260685
            },
            {
                lng: -58.04469438336068,
                lat: 4.060863552258382
            },
            {
                lng: -57.8602095200787,
                lat: 4.57680105226045
            },
            {
                lng: -57.91428890647214,
                lat: 4.812626451024414
            },
            {
                lng: -57.307245856339506,
                lat: 5.073566595882227
            },
            {
                lng: -57.14743648947689,
                lat: 5.973149929219161
            },
            {
                lng: -55.9493184067898,
                lat: 5.772877915872002
            },
            {
                lng: -55.841779751190415,
                lat: 5.95312531170606
            },
            {
                lng: -55.033250291551774,
                lat: 6.025291449401664
            },
            {
                lng: -53.9580446030709,
                lat: 5.756548163267765
            },
            {
                lng: -54.47863298197923,
                lat: 4.896755682795586
            },
            {
                lng: -54.399542202356514,
                lat: 4.212611395683467
            },
            {
                lng: -54.00693050801901,
                lat: 3.620037746592558
            },
            {
                lng: -54.181726040246275,
                lat: 3.189779771330421
            },
            {
                lng: -54.2697051662232,
                lat: 2.732391669115046
            },
            {
                lng: -54.524754197799716,
                lat: 2.311848863123785
            }
        ]
    },
    FR: {
        name: 'France',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: -51.65779741067889,
                    lat: 4.156232408053029
                },
                {
                    lng: -52.249337531123956,
                    lat: 3.241094468596245
                },
                {
                    lng: -52.55642473001842,
                    lat: 2.504705308437053
                },
                {
                    lng: -52.939657151894956,
                    lat: 2.124857692875636
                },
                {
                    lng: -53.41846513529531,
                    lat: 2.053389187015981
                },
                {
                    lng: -53.554839240113544,
                    lat: 2.334896551925951
                },
                {
                    lng: -53.77852067728892,
                    lat: 2.376702785650082
                },
                {
                    lng: -54.08806250671725,
                    lat: 2.105556545414629
                },
                {
                    lng: -54.524754197799716,
                    lat: 2.311848863123785
                },
                {
                    lng: -54.2697051662232,
                    lat: 2.732391669115046
                },
                {
                    lng: -54.181726040246275,
                    lat: 3.189779771330421
                },
                {
                    lng: -54.00693050801901,
                    lat: 3.620037746592558
                },
                {
                    lng: -54.399542202356514,
                    lat: 4.212611395683467
                },
                {
                    lng: -54.47863298197923,
                    lat: 4.896755682795586
                },
                {
                    lng: -53.9580446030709,
                    lat: 5.756548163267765
                },
                {
                    lng: -53.618452928264844,
                    lat: 5.646529038918374
                },
                {
                    lng: -52.88214128275409,
                    lat: 5.409850979021584
                },
                {
                    lng: -51.8233428615259,
                    lat: 4.565768133966131
                },
                {
                    lng: -51.65779741067889,
                    lat: 4.156232408053029
                }
            ],
            [
                {
                    lng: 6.186320428094177,
                    lat: 49.463802802114515
                },
                {
                    lng: 6.658229607783568,
                    lat: 49.20195831969157
                },
                {
                    lng: 8.099278598674744,
                    lat: 49.01778351500333
                },
                {
                    lng: 7.593676385131062,
                    lat: 48.33301911070372
                },
                {
                    lng: 7.466759067422231,
                    lat: 47.62058197691181
                },
                {
                    lng: 7.192202182655507,
                    lat: 47.44976552997102
                },
                {
                    lng: 6.736571079138059,
                    lat: 47.541801255882845
                },
                {
                    lng: 6.768713820023606,
                    lat: 47.2877082383037
                },
                {
                    lng: 6.037388950229001,
                    lat: 46.725778713561866
                },
                {
                    lng: 6.022609490593538,
                    lat: 46.27298981382047
                },
                {
                    lng: 6.500099724970426,
                    lat: 46.42967275652944
                },
                {
                    lng: 6.843592970414505,
                    lat: 45.99114655210061
                },
                {
                    lng: 6.802355177445605,
                    lat: 45.70857982032864
                },
                {
                    lng: 7.096652459347837,
                    lat: 45.33309886329589
                },
                {
                    lng: 6.749955275101655,
                    lat: 45.02851797136758
                },
                {
                    lng: 7.007562290076635,
                    lat: 44.25476675066136
                },
                {
                    lng: 7.549596388386107,
                    lat: 44.12790110938481
                },
                {
                    lng: 7.435184767291872,
                    lat: 43.69384491634922
                },
                {
                    lng: 6.52924523278304,
                    lat: 43.128892320318315
                },
                {
                    lng: 4.556962517931424,
                    lat: 43.399650987311595
                },
                {
                    lng: 3.100410597352663,
                    lat: 43.075200507167054
                },
                {
                    lng: 2.985998976258458,
                    lat: 42.47301504166986
                },
                {
                    lng: 1.826793247087153,
                    lat: 42.34338471126569
                },
                {
                    lng: 0.701590610363894,
                    lat: 42.795734361332606
                },
                {
                    lng: 0.338046909190581,
                    lat: 42.57954600683955
                },
                {
                    lng: -1.502770961910528,
                    lat: 43.03401439063043
                },
                {
                    lng: -1.901351284177764,
                    lat: 43.42280202897834
                },
                {
                    lng: -1.384225226232985,
                    lat: 44.022610378590116
                },
                {
                    lng: -1.193797573237418,
                    lat: 46.01491771095486
                },
                {
                    lng: -2.225724249673846,
                    lat: 47.06436269793822
                },
                {
                    lng: -2.963276129559603,
                    lat: 47.57032664650795
                },
                {
                    lng: -4.491554938159481,
                    lat: 47.954954332056374
                },
                {
                    lng: -4.592349819344776,
                    lat: 48.68416046812699
                },
                {
                    lng: -3.295813971357802,
                    lat: 48.90169240985963
                },
                {
                    lng: -1.616510789384961,
                    lat: 48.64442129169454
                },
                {
                    lng: -1.933494025063311,
                    lat: 49.776341864615745
                },
                {
                    lng: -0.98946895995536,
                    lat: 49.34737580016091
                },
                {
                    lng: 1.338761020522696,
                    lat: 50.12717316344526
                },
                {
                    lng: 1.6390010921385,
                    lat: 50.9466063502975
                },
                {
                    lng: 2.513573032246143,
                    lat: 51.14850617126183
                },
                {
                    lng: 2.658422071960274,
                    lat: 50.796848049515745
                },
                {
                    lng: 3.123251580425688,
                    lat: 50.78036326761455
                },
                {
                    lng: 3.588184441755658,
                    lat: 50.37899241800356
                },
                {
                    lng: 4.286022983425084,
                    lat: 49.907496649772554
                },
                {
                    lng: 4.799221632515724,
                    lat: 49.98537303323637
                },
                {
                    lng: 5.674051954784829,
                    lat: 49.529483547557504
                },
                {
                    lng: 5.897759230176348,
                    lat: 49.44266714130711
                },
                {
                    lng: 6.186320428094177,
                    lat: 49.463802802114515
                }
            ],
            [
                {
                    lng: 8.746009148807559,
                    lat: 42.62812185319392
                },
                {
                    lng: 9.390000848028876,
                    lat: 43.00998484961471
                },
                {
                    lng: 9.560016310269134,
                    lat: 42.15249197037952
                },
                {
                    lng: 9.229752231491773,
                    lat: 41.380006822264455
                },
                {
                    lng: 8.775723097375362,
                    lat: 41.58361196549443
                },
                {
                    lng: 8.544212680707773,
                    lat: 42.25651662858306
                },
                {
                    lng: 8.746009148807559,
                    lat: 42.62812185319392
                }
            ]
        ]
    },
    EC: {
        name: 'Ecuador',
        type: 'Polygon',
        coordinates: [
            {
                lng: -75.37322323271385,
                lat: -0.15203175212045
            },
            {
                lng: -75.23372270374195,
                lat: -0.911416924649529
            },
            {
                lng: -75.54499569365204,
                lat: -1.56160979574588
            },
            {
                lng: -76.63539425322672,
                lat: -2.608677666843818
            },
            {
                lng: -77.83790483265861,
                lat: -3.003020521663103
            },
            {
                lng: -78.45068396677564,
                lat: -3.873096612161376
            },
            {
                lng: -78.63989722361234,
                lat: -4.547784112164074
            },
            {
                lng: -79.20528906931773,
                lat: -4.959128513207389
            },
            {
                lng: -79.62497921417618,
                lat: -4.454198093283495
            },
            {
                lng: -80.02890804718561,
                lat: -4.346090996928893
            },
            {
                lng: -80.44224199087216,
                lat: -4.425724379090674
            },
            {
                lng: -80.46929460317695,
                lat: -4.059286797708999
            },
            {
                lng: -80.18401485870967,
                lat: -3.821161797708044
            },
            {
                lng: -80.30256059438722,
                lat: -3.404856459164713
            },
            {
                lng: -79.77029334178093,
                lat: -2.65751189535964
            },
            {
                lng: -79.98655921092242,
                lat: -2.220794366061014
            },
            {
                lng: -80.36878394236925,
                lat: -2.685158786635788
            },
            {
                lng: -80.96776546906436,
                lat: -2.246942640800704
            },
            {
                lng: -80.76480628123804,
                lat: -1.965047702648533
            },
            {
                lng: -80.93365902375172,
                lat: -1.057454522306358
            },
            {
                lng: -80.58337032746127,
                lat: -0.906662692878683
            },
            {
                lng: -80.39932471385376,
                lat: -0.283703301600141
            },
            {
                lng: -80.02089820018037,
                lat: 0.360340074053468
            },
            {
                lng: -80.09060970734211,
                lat: 0.768428859862397
            },
            {
                lng: -79.5427620103998,
                lat: 0.982937730305963
            },
            {
                lng: -78.85525875518871,
                lat: 1.380923773601822
            },
            {
                lng: -77.85506140817952,
                lat: 0.809925034992773
            },
            {
                lng: -77.66861284047044,
                lat: 0.825893052570962
            },
            {
                lng: -77.4249843004304,
                lat: 0.395686753741117
            },
            {
                lng: -76.5763797675494,
                lat: 0.256935533037435
            },
            {
                lng: -76.29231441924097,
                lat: 0.416047268064119
            },
            {
                lng: -75.8014658271166,
                lat: 0.084801337073202
            },
            {
                lng: -75.37322323271385,
                lat: -0.15203175212045
            }
        ]
    },
    PR: {
        name: 'Puerto Rico',
        type: 'Polygon',
        coordinates: [
            {
                lng: -66.28243445500821,
                lat: 18.514761664295364
            },
            {
                lng: -65.7713028632093,
                lat: 18.426679185453878
            },
            {
                lng: -65.59100379094295,
                lat: 18.228034979723915
            },
            {
                lng: -65.84716386581377,
                lat: 17.97590566657186
            },
            {
                lng: -66.59993445500949,
                lat: 17.981822618069273
            },
            {
                lng: -67.18416236028527,
                lat: 17.946553453030077
            },
            {
                lng: -67.24242753769435,
                lat: 18.374460150622937
            },
            {
                lng: -67.10067908391774,
                lat: 18.52060110114435
            },
            {
                lng: -66.28243445500821,
                lat: 18.514761664295364
            }
        ]
    },
    JM: {
        name: 'Jamaica',
        type: 'Polygon',
        coordinates: [
            {
                lng: -77.56960079619921,
                lat: 18.490525417550487
            },
            {
                lng: -76.89661861846213,
                lat: 18.400866807524082
            },
            {
                lng: -76.36535905628554,
                lat: 18.160700588447597
            },
            {
                lng: -76.19965857614164,
                lat: 17.886867173732966
            },
            {
                lng: -76.9025614081757,
                lat: 17.868237819891746
            },
            {
                lng: -77.20634131540348,
                lat: 17.70111623785982
            },
            {
                lng: -77.76602291534061,
                lat: 17.86159739834224
            },
            {
                lng: -78.33771928578561,
                lat: 18.225967922432233
            },
            {
                lng: -78.21772661000388,
                lat: 18.454532782459196
            },
            {
                lng: -77.79736467152563,
                lat: 18.524218451404778
            },
            {
                lng: -77.56960079619921,
                lat: 18.490525417550487
            }
        ]
    },
    CU: {
        name: 'Cuba',
        type: 'Polygon',
        coordinates: [
            {
                lng: -82.26815121125706,
                lat: 23.188610744717707
            },
            {
                lng: -81.40445716014683,
                lat: 23.117271429938782
            },
            {
                lng: -80.6187686835812,
                lat: 23.105980129483
            },
            {
                lng: -79.67952368846025,
                lat: 22.76530324959883
            },
            {
                lng: -79.28148596873208,
                lat: 22.399201565027056
            },
            {
                lng: -78.34743445505649,
                lat: 22.512166246017088
            },
            {
                lng: -77.99329586456028,
                lat: 22.277193508385935
            },
            {
                lng: -77.14642249216105,
                lat: 21.657851467367834
            },
            {
                lng: -76.52382483590856,
                lat: 21.206819566324373
            },
            {
                lng: -76.19462012399319,
                lat: 21.220565497314013
            },
            {
                lng: -75.59822241891267,
                lat: 21.016624457274133
            },
            {
                lng: -75.67106035022806,
                lat: 20.735091254148003
            },
            {
                lng: -74.9338960435845,
                lat: 20.693905137611385
            },
            {
                lng: -74.17802486845126,
                lat: 20.28462779385974
            },
            {
                lng: -74.29664811877726,
                lat: 20.05037852628068
            },
            {
                lng: -74.96159461129294,
                lat: 19.92343537035569
            },
            {
                lng: -75.63468014189459,
                lat: 19.873774318923196
            },
            {
                lng: -76.323656175426,
                lat: 19.95289093676206
            },
            {
                lng: -77.75548092315307,
                lat: 19.855480861891877
            },
            {
                lng: -77.08510840524674,
                lat: 20.413353786698792
            },
            {
                lng: -77.49265458851661,
                lat: 20.673105373613893
            },
            {
                lng: -78.13729224314159,
                lat: 20.739948838783434
            },
            {
                lng: -78.48282670766119,
                lat: 21.02861338956585
            },
            {
                lng: -78.71986650258401,
                lat: 21.598113511638434
            },
            {
                lng: -79.28499996612794,
                lat: 21.5591753199065
            },
            {
                lng: -80.21747534861865,
                lat: 21.827324327069036
            },
            {
                lng: -80.51753455272141,
                lat: 22.03707896574176
            },
            {
                lng: -81.82094336620318,
                lat: 22.19205658618507
            },
            {
                lng: -82.16999182811864,
                lat: 22.387109279870753
            },
            {
                lng: -81.79500179719267,
                lat: 22.636964830001958
            },
            {
                lng: -82.77589799674085,
                lat: 22.688150336187064
            },
            {
                lng: -83.49445878775936,
                lat: 22.16851797127613
            },
            {
                lng: -83.90880042187563,
                lat: 22.154565334557333
            },
            {
                lng: -84.05215084505326,
                lat: 21.910575059491254
            },
            {
                lng: -84.54703019889638,
                lat: 21.801227728761646
            },
            {
                lng: -84.97491105827311,
                lat: 21.89602814380109
            },
            {
                lng: -84.44706214062776,
                lat: 22.204949856041907
            },
            {
                lng: -84.23035702181178,
                lat: 22.565754706303764
            },
            {
                lng: -83.7782399156902,
                lat: 22.788118394455694
            },
            {
                lng: -83.26754757356575,
                lat: 22.983041897060644
            },
            {
                lng: -82.51043616405751,
                lat: 23.078746649665188
            },
            {
                lng: -82.26815121125706,
                lat: 23.188610744717707
            }
        ]
    },
    ZW: {
        name: 'Zimbabwe',
        type: 'Polygon',
        coordinates: [
            {
                lng: 31.19140913262129,
                lat: -22.2515096981724
            },
            {
                lng: 30.65986535006709,
                lat: -22.151567478119915
            },
            {
                lng: 30.322883335091774,
                lat: -22.271611830333935
            },
            {
                lng: 29.839036899542972,
                lat: -22.102216485281176
            },
            {
                lng: 29.43218834810904,
                lat: -22.091312758067588
            },
            {
                lng: 28.794656202924216,
                lat: -21.63945403410745
            },
            {
                lng: 28.021370070108617,
                lat: -21.485975030200585
            },
            {
                lng: 27.72722781750326,
                lat: -20.851801853114715
            },
            {
                lng: 27.724747348753255,
                lat: -20.49905852629039
            },
            {
                lng: 27.296504754350508,
                lat: -20.391519870691
            },
            {
                lng: 26.164790887158485,
                lat: -19.29308562589494
            },
            {
                lng: 25.85039147309473,
                lat: -18.714412937090536
            },
            {
                lng: 25.649163445750162,
                lat: -18.53602589281899
            },
            {
                lng: 25.264225701608012,
                lat: -17.736539808831417
            },
            {
                lng: 26.381935255648926,
                lat: -17.8460421688579
            },
            {
                lng: 26.70677330903564,
                lat: -17.961228936436484
            },
            {
                lng: 27.044427117630732,
                lat: -17.938026218337434
            },
            {
                lng: 27.59824344250276,
                lat: -17.290830580314008
            },
            {
                lng: 28.467906121542683,
                lat: -16.468400160388846
            },
            {
                lng: 28.8258687680285,
                lat: -16.389748630440614
            },
            {
                lng: 28.947463413211267,
                lat: -16.04305144619444
            },
            {
                lng: 29.516834344203147,
                lat: -15.644677829656388
            },
            {
                lng: 30.27425581230511,
                lat: -15.507786960515213
            },
            {
                lng: 30.338954705534544,
                lat: -15.880839125230246
            },
            {
                lng: 31.17306399915768,
                lat: -15.860943698797874
            },
            {
                lng: 31.636498243951195,
                lat: -16.071990248277885
            },
            {
                lng: 31.8520406430406,
                lat: -16.319417006091378
            },
            {
                lng: 32.32823896661023,
                lat: -16.392074069893752
            },
            {
                lng: 32.847638787575846,
                lat: -16.713398125884616
            },
            {
                lng: 32.84986087416439,
                lat: -17.97905730557718
            },
            {
                lng: 32.65488569512715,
                lat: -18.672089939043495
            },
            {
                lng: 32.61199425632489,
                lat: -19.419382826416275
            },
            {
                lng: 32.772707960752626,
                lat: -19.715592136313298
            },
            {
                lng: 32.65974327976258,
                lat: -20.304290052982317
            },
            {
                lng: 32.50869306817344,
                lat: -20.395292250248307
            },
            {
                lng: 32.244988234188014,
                lat: -21.116488539313693
            },
            {
                lng: 31.19140913262129,
                lat: -22.2515096981724
            }
        ]
    },
    BW: {
        name: 'Botswana',
        type: 'Polygon',
        coordinates: [
            {
                lng: 29.43218834810904,
                lat: -22.091312758067588
            },
            {
                lng: 28.01723595552525,
                lat: -22.82775359465908
            },
            {
                lng: 27.119409620886245,
                lat: -23.574323011979775
            },
            {
                lng: 26.786406691197413,
                lat: -24.240690606383485
            },
            {
                lng: 26.4857532081233,
                lat: -24.616326592713104
            },
            {
                lng: 25.94165205252216,
                lat: -24.69637338633322
            },
            {
                lng: 25.76584882986521,
                lat: -25.17484547292368
            },
            {
                lng: 25.66466637543772,
                lat: -25.486816094669713
            },
            {
                lng: 25.025170525825786,
                lat: -25.7196700985769
            },
            {
                lng: 24.211266717228796,
                lat: -25.670215752873574
            },
            {
                lng: 23.73356977712271,
                lat: -25.390129489851617
            },
            {
                lng: 23.312096795350186,
                lat: -25.26868987396572
            },
            {
                lng: 22.8242712745149,
                lat: -25.50045867279477
            },
            {
                lng: 22.57953169118059,
                lat: -25.979447523708146
            },
            {
                lng: 22.105968865657868,
                lat: -26.280256036079138
            },
            {
                lng: 21.605896030369394,
                lat: -26.726533705351756
            },
            {
                lng: 20.88960900237174,
                lat: -26.828542982695915
            },
            {
                lng: 20.66647016773544,
                lat: -26.477453301704923
            },
            {
                lng: 20.75860924651184,
                lat: -25.86813648855145
            },
            {
                lng: 20.16572553882719,
                lat: -24.91796192800077
            },
            {
                lng: 19.895767856534434,
                lat: -24.76779021576059
            },
            {
                lng: 19.89545779794068,
                lat: -21.84915699634787
            },
            {
                lng: 20.88113406747587,
                lat: -21.814327080983148
            },
            {
                lng: 20.910641310314535,
                lat: -18.252218926672022
            },
            {
                lng: 21.655040317478978,
                lat: -18.219146010005225
            },
            {
                lng: 23.1968583513393,
                lat: -17.869038181227786
            },
            {
                lng: 23.579005568137717,
                lat: -18.28126108162006
            },
            {
                lng: 24.217364536239213,
                lat: -17.88934701911849
            },
            {
                lng: 24.520705193792537,
                lat: -17.887124932529936
            },
            {
                lng: 25.08444339366457,
                lat: -17.661815687737374
            },
            {
                lng: 25.264225701608012,
                lat: -17.736539808831417
            },
            {
                lng: 25.649163445750162,
                lat: -18.53602589281899
            },
            {
                lng: 25.85039147309473,
                lat: -18.714412937090536
            },
            {
                lng: 26.164790887158485,
                lat: -19.29308562589494
            },
            {
                lng: 27.296504754350508,
                lat: -20.391519870691
            },
            {
                lng: 27.724747348753255,
                lat: -20.49905852629039
            },
            {
                lng: 27.72722781750326,
                lat: -20.851801853114715
            },
            {
                lng: 28.021370070108617,
                lat: -21.485975030200585
            },
            {
                lng: 28.794656202924216,
                lat: -21.63945403410745
            },
            {
                lng: 29.43218834810904,
                lat: -22.091312758067588
            }
        ]
    },
    NA: {
        name: 'Namibia',
        type: 'Polygon',
        coordinates: [
            {
                lng: 19.895767856534434,
                lat: -24.76779021576059
            },
            {
                lng: 19.894734327888614,
                lat: -28.461104831660776
            },
            {
                lng: 19.002127312911085,
                lat: -28.972443129188868
            },
            {
                lng: 18.464899122804752,
                lat: -29.04546192801728
            },
            {
                lng: 17.83615197110953,
                lat: -28.85637786226132
            },
            {
                lng: 17.387497185951503,
                lat: -28.78351409272978
            },
            {
                lng: 17.218928663815404,
                lat: -28.35594329194681
            },
            {
                lng: 16.824017368240902,
                lat: -28.08216155366447
            },
            {
                lng: 16.344976840895242,
                lat: -28.5767050106977
            },
            {
                lng: 15.601818068105816,
                lat: -27.821247247022804
            },
            {
                lng: 15.21047244635946,
                lat: -27.090955905874047
            },
            {
                lng: 14.989710727608553,
                lat: -26.117371921495156
            },
            {
                lng: 14.743214145576331,
                lat: -25.39292001719538
            },
            {
                lng: 14.408144158595833,
                lat: -23.853014011329847
            },
            {
                lng: 14.385716586981149,
                lat: -22.65665292734069
            },
            {
                lng: 14.257714064194175,
                lat: -22.111208184499958
            },
            {
                lng: 13.86864220546866,
                lat: -21.699036960539978
            },
            {
                lng: 13.35249799973744,
                lat: -20.872834161057504
            },
            {
                lng: 12.826845330464494,
                lat: -19.673165785401665
            },
            {
                lng: 12.608564080463621,
                lat: -19.0453488094877
            },
            {
                lng: 11.794918654028066,
                lat: -18.069129327061916
            },
            {
                lng: 11.734198846085121,
                lat: -17.301889336824473
            },
            {
                lng: 12.215461460019355,
                lat: -17.111668389558083
            },
            {
                lng: 12.814081251688407,
                lat: -16.94134286872407
            },
            {
                lng: 13.462362094789967,
                lat: -16.971211846588773
            },
            {
                lng: 14.05850141770901,
                lat: -17.423380629142663
            },
            {
                lng: 14.209706658595024,
                lat: -17.35310068122572
            },
            {
                lng: 18.263309360434164,
                lat: -17.309950860262006
            },
            {
                lng: 18.956186964603603,
                lat: -17.789094740472258
            },
            {
                lng: 21.377176141045567,
                lat: -17.930636488519696
            },
            {
                lng: 23.215048455506064,
                lat: -17.523116143465984
            },
            {
                lng: 24.033861525170778,
                lat: -17.295843194246324
            },
            {
                lng: 24.682349074001507,
                lat: -17.353410739819473
            },
            {
                lng: 25.07695031098226,
                lat: -17.57882333747662
            },
            {
                lng: 25.08444339366457,
                lat: -17.661815687737374
            },
            {
                lng: 24.520705193792537,
                lat: -17.887124932529936
            },
            {
                lng: 24.217364536239213,
                lat: -17.88934701911849
            },
            {
                lng: 23.579005568137717,
                lat: -18.28126108162006
            },
            {
                lng: 23.1968583513393,
                lat: -17.869038181227786
            },
            {
                lng: 21.655040317478978,
                lat: -18.219146010005225
            },
            {
                lng: 20.910641310314535,
                lat: -18.252218926672022
            },
            {
                lng: 20.88113406747587,
                lat: -21.814327080983148
            },
            {
                lng: 19.89545779794068,
                lat: -21.84915699634787
            },
            {
                lng: 19.895767856534434,
                lat: -24.76779021576059
            }
        ]
    },
    SN: {
        name: 'Senegal',
        type: 'Polygon',
        coordinates: [
            {
                lng: -16.71372880702347,
                lat: 13.594958604379855
            },
            {
                lng: -17.126106736712615,
                lat: 14.373515733289224
            },
            {
                lng: -17.62504269049066,
                lat: 14.729540513564073
            },
            {
                lng: -17.18517289882223,
                lat: 14.919477240452862
            },
            {
                lng: -16.700706346085923,
                lat: 15.621527411354108
            },
            {
                lng: -16.463098110407884,
                lat: 16.13503611903846
            },
            {
                lng: -16.12069007004193,
                lat: 16.455662543193384
            },
            {
                lng: -15.623666144258692,
                lat: 16.369337063049812
            },
            {
                lng: -15.135737270558819,
                lat: 16.587282416240782
            },
            {
                lng: -14.577347581428981,
                lat: 16.59826365810281
            },
            {
                lng: -14.099521450242179,
                lat: 16.304302273010492
            },
            {
                lng: -13.435737677453062,
                lat: 16.03938304286619
            },
            {
                lng: -12.830658331747516,
                lat: 15.303691514542948
            },
            {
                lng: -12.170750291380301,
                lat: 14.616834214735505
            },
            {
                lng: -12.12488745772126,
                lat: 13.994727484589788
            },
            {
                lng: -11.927716030311615,
                lat: 13.422075100147396
            },
            {
                lng: -11.55339779300543,
                lat: 13.141213690641067
            },
            {
                lng: -11.467899135778524,
                lat: 12.754518947800975
            },
            {
                lng: -11.51394283695059,
                lat: 12.442987575729418
            },
            {
                lng: -11.658300950557932,
                lat: 12.386582749882836
            },
            {
                lng: -12.203564825885634,
                lat: 12.465647691289405
            },
            {
                lng: -12.27859900557344,
                lat: 12.354440008997287
            },
            {
                lng: -12.499050665730564,
                lat: 12.332089952031057
            },
            {
                lng: -13.217818162478238,
                lat: 12.575873521367967
            },
            {
                lng: -13.700476040084325,
                lat: 12.586182969610194
            },
            {
                lng: -15.54847693527401,
                lat: 12.628170070847347
            },
            {
                lng: -15.816574266004254,
                lat: 12.515567124883347
            },
            {
                lng: -16.147716844130585,
                lat: 12.547761542201187
            },
            {
                lng: -16.677451951554573,
                lat: 12.384851589401052
            },
            {
                lng: -16.841524624081273,
                lat: 13.15139394780256
            },
            {
                lng: -15.931295945692211,
                lat: 13.130284125211332
            },
            {
                lng: -15.691000535534995,
                lat: 13.270353094938457
            },
            {
                lng: -15.511812506562933,
                lat: 13.278569647672867
            },
            {
                lng: -15.141163295949468,
                lat: 13.509511623585238
            },
            {
                lng: -14.712197231494628,
                lat: 13.298206691943777
            },
            {
                lng: -14.277701788784555,
                lat: 13.280585028532244
            },
            {
                lng: -13.844963344772408,
                lat: 13.505041612192002
            },
            {
                lng: -14.046992356817482,
                lat: 13.79406789800045
            },
            {
                lng: -14.376713833055788,
                lat: 13.625680243377374
            },
            {
                lng: -14.687030808968487,
                lat: 13.630356960499784
            },
            {
                lng: -15.08173539881382,
                lat: 13.876491807505985
            },
            {
                lng: -15.39877031092446,
                lat: 13.86036876063092
            },
            {
                lng: -15.624596320039942,
                lat: 13.62358734786956
            },
            {
                lng: -16.71372880702347,
                lat: 13.594958604379855
            }
        ]
    },
    ML: {
        name: 'Mali',
        type: 'Polygon',
        coordinates: [
            {
                lng: -11.51394283695059,
                lat: 12.442987575729418
            },
            {
                lng: -11.467899135778524,
                lat: 12.754518947800975
            },
            {
                lng: -11.55339779300543,
                lat: 13.141213690641067
            },
            {
                lng: -11.927716030311615,
                lat: 13.422075100147396
            },
            {
                lng: -12.12488745772126,
                lat: 13.994727484589788
            },
            {
                lng: -12.170750291380301,
                lat: 14.616834214735505
            },
            {
                lng: -11.834207526079467,
                lat: 14.79909699142894
            },
            {
                lng: -11.666078253617854,
                lat: 15.388208319556298
            },
            {
                lng: -11.349095017939504,
                lat: 15.411256008358478
            },
            {
                lng: -10.650791388379417,
                lat: 15.132745876521426
            },
            {
                lng: -10.086846482778213,
                lat: 15.330485744686271
            },
            {
                lng: -9.700255092802706,
                lat: 15.264107367407362
            },
            {
                lng: -9.55023840985939,
                lat: 15.486496893775438
            },
            {
                lng: -5.537744309908447,
                lat: 15.501689764869257
            },
            {
                lng: -5.315277268891933,
                lat: 16.20185374599184
            },
            {
                lng: -5.488522508150439,
                lat: 16.325102037007966
            },
            {
                lng: -5.971128709324248,
                lat: 20.64083344164763
            },
            {
                lng: -6.453786586930335,
                lat: 24.956590684503425
            },
            {
                lng: -4.923337368174231,
                lat: 24.974574082941
            },
            {
                lng: -1.550054897457613,
                lat: 22.792665920497384
            },
            {
                lng: 1.823227573259032,
                lat: 20.610809434486043
            },
            {
                lng: 2.06099083823392,
                lat: 20.142233384679486
            },
            {
                lng: 2.683588494486429,
                lat: 19.856230170160117
            },
            {
                lng: 3.1466610042539,
                lat: 19.693578599521445
            },
            {
                lng: 3.158133172222705,
                lat: 19.057364203360038
            },
            {
                lng: 4.267419467800039,
                lat: 19.155265204337
            },
            {
                lng: 4.270209995143802,
                lat: 16.852227484601215
            },
            {
                lng: 3.723421665063483,
                lat: 16.184283759012615
            },
            {
                lng: 3.638258904646477,
                lat: 15.568119818580456
            },
            {
                lng: 2.749992709981484,
                lat: 15.409524847876696
            },
            {
                lng: 1.385528191746858,
                lat: 15.323561102759172
            },
            {
                lng: 1.015783318698482,
                lat: 14.96818227788799
            },
            {
                lng: 0.374892205414682,
                lat: 14.92890818934613
            },
            {
                lng: -0.26625729003058,
                lat: 14.924308986872148
            },
            {
                lng: -0.515854458000348,
                lat: 15.116157741755728
            },
            {
                lng: -1.066363491205664,
                lat: 14.973815009007765
            },
            {
                lng: -2.001035122068771,
                lat: 14.559008287000893
            },
            {
                lng: -2.191824510090385,
                lat: 14.246417548067356
            },
            {
                lng: -2.967694464520577,
                lat: 13.79815033615151
            },
            {
                lng: -3.10370683431276,
                lat: 13.541266791228594
            },
            {
                lng: -3.522802700199861,
                lat: 13.337661647998615
            },
            {
                lng: -4.006390753587226,
                lat: 13.472485459848116
            },
            {
                lng: -4.28040503581488,
                lat: 13.228443508349741
            },
            {
                lng: -4.427166103523803,
                lat: 12.542645575404295
            },
            {
                lng: -5.220941941743121,
                lat: 11.713858954307227
            },
            {
                lng: -5.197842576508648,
                lat: 11.37514577885014
            },
            {
                lng: -5.470564947929006,
                lat: 10.951269842976048
            },
            {
                lng: -5.404341599946974,
                lat: 10.370736802609146
            },
            {
                lng: -5.816926235365287,
                lat: 10.222554633012194
            },
            {
                lng: -6.050452032892267,
                lat: 10.096360785355444
            },
            {
                lng: -6.205222947606431,
                lat: 10.524060777219134
            },
            {
                lng: -6.493965013037268,
                lat: 10.411302801958271
            },
            {
                lng: -6.666460944027548,
                lat: 10.430810655148449
            },
            {
                lng: -6.850506557635057,
                lat: 10.138993841996239
            },
            {
                lng: -7.622759161804809,
                lat: 10.147236232946796
            },
            {
                lng: -7.899589809592372,
                lat: 10.297382106970828
            },
            {
                lng: -8.029943610048619,
                lat: 10.206534939001713
            },
            {
                lng: -8.33537716310974,
                lat: 10.494811916541934
            },
            {
                lng: -8.282357143578281,
                lat: 10.792597357623846
            },
            {
                lng: -8.407310756860028,
                lat: 10.909256903522762
            },
            {
                lng: -8.620321010767128,
                lat: 10.810890814655183
            },
            {
                lng: -8.581305304386774,
                lat: 11.136245632364805
            },
            {
                lng: -8.376304897484914,
                lat: 11.393645941610629
            },
            {
                lng: -8.786099005559464,
                lat: 11.812560939984706
            },
            {
                lng: -8.90526485842453,
                lat: 12.088358059126437
            },
            {
                lng: -9.127473517279583,
                lat: 12.308060411015333
            },
            {
                lng: -9.327616339546012,
                lat: 12.334286200403454
            },
            {
                lng: -9.567911749703214,
                lat: 12.194243068892476
            },
            {
                lng: -9.890992804392013,
                lat: 12.060478623904972
            },
            {
                lng: -10.165213792348837,
                lat: 11.844083563682744
            },
            {
                lng: -10.593223842806282,
                lat: 11.92397532800598
            },
            {
                lng: -10.870829637078215,
                lat: 12.17788747807211
            },
            {
                lng: -11.03655595543826,
                lat: 12.211244615116515
            },
            {
                lng: -11.297573614944511,
                lat: 12.077971096235771
            },
            {
                lng: -11.456168585648271,
                lat: 12.076834214725338
            },
            {
                lng: -11.51394283695059,
                lat: 12.442987575729418
            }
        ]
    },
    MR: {
        name: 'Mauritania',
        type: 'Polygon',
        coordinates: [
            {
                lng: -17.06342322434257,
                lat: 20.999752102130827
            },
            {
                lng: -16.845193650773993,
                lat: 21.33332347257488
            },
            {
                lng: -12.929101935263532,
                lat: 21.327070624267563
            },
            {
                lng: -13.118754441774712,
                lat: 22.771220201096256
            },
            {
                lng: -12.874221564169575,
                lat: 23.284832261645178
            },
            {
                lng: -11.937224493853321,
                lat: 23.374594224536168
            },
            {
                lng: -11.96941891117116,
                lat: 25.933352769468268
            },
            {
                lng: -8.6872936670174,
                lat: 25.881056219988906
            },
            {
                lng: -8.684399786809053,
                lat: 27.395744126896005
            },
            {
                lng: -4.923337368174231,
                lat: 24.974574082941
            },
            {
                lng: -6.453786586930335,
                lat: 24.956590684503425
            },
            {
                lng: -5.971128709324248,
                lat: 20.64083344164763
            },
            {
                lng: -5.488522508150439,
                lat: 16.325102037007966
            },
            {
                lng: -5.315277268891933,
                lat: 16.20185374599184
            },
            {
                lng: -5.537744309908447,
                lat: 15.501689764869257
            },
            {
                lng: -9.55023840985939,
                lat: 15.486496893775438
            },
            {
                lng: -9.700255092802706,
                lat: 15.264107367407362
            },
            {
                lng: -10.086846482778213,
                lat: 15.330485744686271
            },
            {
                lng: -10.650791388379417,
                lat: 15.132745876521426
            },
            {
                lng: -11.349095017939504,
                lat: 15.411256008358478
            },
            {
                lng: -11.666078253617854,
                lat: 15.388208319556298
            },
            {
                lng: -11.834207526079467,
                lat: 14.79909699142894
            },
            {
                lng: -12.170750291380301,
                lat: 14.616834214735505
            },
            {
                lng: -12.830658331747516,
                lat: 15.303691514542948
            },
            {
                lng: -13.435737677453062,
                lat: 16.03938304286619
            },
            {
                lng: -14.099521450242179,
                lat: 16.304302273010492
            },
            {
                lng: -14.577347581428981,
                lat: 16.59826365810281
            },
            {
                lng: -15.135737270558819,
                lat: 16.587282416240782
            },
            {
                lng: -15.623666144258692,
                lat: 16.369337063049812
            },
            {
                lng: -16.12069007004193,
                lat: 16.455662543193384
            },
            {
                lng: -16.463098110407884,
                lat: 16.13503611903846
            },
            {
                lng: -16.549707810929064,
                lat: 16.673892116761962
            },
            {
                lng: -16.270551723688357,
                lat: 17.166962795474873
            },
            {
                lng: -16.14634741867485,
                lat: 18.108481553616656
            },
            {
                lng: -16.256883307347167,
                lat: 19.096715806550307
            },
            {
                lng: -16.37765112961327,
                lat: 19.593817246981985
            },
            {
                lng: -16.277838100641517,
                lat: 20.0925206568147
            },
            {
                lng: -16.536323614965468,
                lat: 20.567866319251493
            },
            {
                lng: -17.06342322434257,
                lat: 20.999752102130827
            }
        ]
    },
    BJ: {
        name: 'Benin',
        type: 'Polygon',
        coordinates: [
            {
                lng: 2.691701694356254,
                lat: 6.258817246928629
            },
            {
                lng: 1.865240512712319,
                lat: 6.142157701029731
            },
            {
                lng: 1.618950636409238,
                lat: 6.832038072126238
            },
            {
                lng: 1.664477573258381,
                lat: 9.12859039960938
            },
            {
                lng: 1.46304284018467,
                lat: 9.334624335157088
            },
            {
                lng: 1.425060662450136,
                lat: 9.825395412633
            },
            {
                lng: 1.077795037448738,
                lat: 10.175606594275024
            },
            {
                lng: 0.772335646171484,
                lat: 10.470808213742359
            },
            {
                lng: 0.899563022474069,
                lat: 10.99733938236426
            },
            {
                lng: 1.243469679376489,
                lat: 11.110510769083461
            },
            {
                lng: 1.447178175471066,
                lat: 11.547719224488858
            },
            {
                lng: 1.935985548519881,
                lat: 11.641150214072553
            },
            {
                lng: 2.154473504249921,
                lat: 11.940150051313339
            },
            {
                lng: 2.49016360841793,
                lat: 12.233052069543675
            },
            {
                lng: 2.848643019226586,
                lat: 12.23563589115821
            },
            {
                lng: 3.611180454125559,
                lat: 11.660167141155968
            },
            {
                lng: 3.572216424177469,
                lat: 11.32793935795152
            },
            {
                lng: 3.797112257511714,
                lat: 10.734745591673105
            },
            {
                lng: 3.600070021182802,
                lat: 10.332186184119408
            },
            {
                lng: 3.705438266625919,
                lat: 10.063210354040208
            },
            {
                lng: 3.220351596702101,
                lat: 9.444152533399702
            },
            {
                lng: 2.912308383810256,
                lat: 9.137607937044322
            },
            {
                lng: 2.723792758809509,
                lat: 8.50684540448971
            },
            {
                lng: 2.74906253420022,
                lat: 7.870734361192888
            },
            {
                lng: 2.691701694356254,
                lat: 6.258817246928629
            }
        ]
    },
    NE: {
        name: 'Niger',
        type: 'Polygon',
        coordinates: [
            {
                lng: 14.8513,
                lat: 22.862950000000126
            },
            {
                lng: 15.096887648181848,
                lat: 21.30851878507491
            },
            {
                lng: 15.47106,
                lat: 21.04845
            },
            {
                lng: 15.487148064850146,
                lat: 20.730414537025638
            },
            {
                lng: 15.903246697664315,
                lat: 20.387618923417506
            },
            {
                lng: 15.685740594147774,
                lat: 19.957180080642388
            },
            {
                lng: 15.30044111497972,
                lat: 17.927949937405003
            },
            {
                lng: 15.247731154041844,
                lat: 16.627305813050782
            },
            {
                lng: 13.97217,
                lat: 15.68437
            },
            {
                lng: 13.540393507550789,
                lat: 14.367133693901224
            },
            {
                lng: 13.956698846094127,
                lat: 13.996691189016929
            },
            {
                lng: 13.95447675950561,
                lat: 13.353448798063766
            },
            {
                lng: 14.595781284247607,
                lat: 13.33042694747786
            },
            {
                lng: 14.495787387762846,
                lat: 12.85939626713733
            },
            {
                lng: 14.213530714584635,
                lat: 12.802035427293347
            },
            {
                lng: 14.181336297266794,
                lat: 12.483656927943116
            },
            {
                lng: 13.995352817448293,
                lat: 12.461565253138303
            },
            {
                lng: 13.318701613018561,
                lat: 13.556356309457826
            },
            {
                lng: 13.083987257548813,
                lat: 13.596147162322495
            },
            {
                lng: 12.302071160540523,
                lat: 13.037189032437524
            },
            {
                lng: 11.527803175511394,
                lat: 13.328980007373588
            },
            {
                lng: 10.989593133191534,
                lat: 13.387322699431195
            },
            {
                lng: 10.701031935273706,
                lat: 13.246917832894084
            },
            {
                lng: 10.114814487354693,
                lat: 13.27725189864941
            },
            {
                lng: 9.524928012742947,
                lat: 12.851102199754479
            },
            {
                lng: 9.014933302454438,
                lat: 12.826659247280418
            },
            {
                lng: 7.804671258178786,
                lat: 13.343526923063747
            },
            {
                lng: 7.330746697630019,
                lat: 13.0980380314612
            },
            {
                lng: 6.820441928747754,
                lat: 13.115091254117518
            },
            {
                lng: 6.445426059605637,
                lat: 13.492768459522678
            },
            {
                lng: 5.443058302440136,
                lat: 13.865923977102227
            },
            {
                lng: 4.368343540066007,
                lat: 13.747481594289411
            },
            {
                lng: 4.107945997747322,
                lat: 13.531215725147831
            },
            {
                lng: 3.967282749048849,
                lat: 12.956108710171575
            },
            {
                lng: 3.680633579125811,
                lat: 12.552903347214226
            },
            {
                lng: 3.611180454125559,
                lat: 11.660167141155968
            },
            {
                lng: 2.848643019226586,
                lat: 12.23563589115821
            },
            {
                lng: 2.49016360841793,
                lat: 12.233052069543675
            },
            {
                lng: 2.154473504249921,
                lat: 11.940150051313339
            },
            {
                lng: 2.177107781593776,
                lat: 12.625017808477535
            },
            {
                lng: 1.024103224297477,
                lat: 12.851825669806574
            },
            {
                lng: 0.993045688490071,
                lat: 13.335749620003824
            },
            {
                lng: 0.429927605805517,
                lat: 13.988733018443924
            },
            {
                lng: 0.295646396495101,
                lat: 14.444234930880654
            },
            {
                lng: 0.374892205414682,
                lat: 14.92890818934613
            },
            {
                lng: 1.015783318698482,
                lat: 14.96818227788799
            },
            {
                lng: 1.385528191746858,
                lat: 15.323561102759172
            },
            {
                lng: 2.749992709981484,
                lat: 15.409524847876696
            },
            {
                lng: 3.638258904646477,
                lat: 15.568119818580456
            },
            {
                lng: 3.723421665063483,
                lat: 16.184283759012615
            },
            {
                lng: 4.270209995143802,
                lat: 16.852227484601215
            },
            {
                lng: 4.267419467800039,
                lat: 19.155265204337
            },
            {
                lng: 5.677565952180686,
                lat: 19.601206976799716
            },
            {
                lng: 8.572893100629784,
                lat: 21.565660712159143
            },
            {
                lng: 11.999505649471613,
                lat: 23.47166840259645
            },
            {
                lng: 13.581424594790462,
                lat: 23.04050608976928
            },
            {
                lng: 14.143870883855243,
                lat: 22.491288967371133
            },
            {
                lng: 14.8513,
                lat: 22.862950000000126
            }
        ]
    },
    NG: {
        name: 'Nigeria',
        type: 'Polygon',
        coordinates: [
            {
                lng: 2.691701694356254,
                lat: 6.258817246928629
            },
            {
                lng: 2.74906253420022,
                lat: 7.870734361192888
            },
            {
                lng: 2.723792758809509,
                lat: 8.50684540448971
            },
            {
                lng: 2.912308383810256,
                lat: 9.137607937044322
            },
            {
                lng: 3.220351596702101,
                lat: 9.444152533399702
            },
            {
                lng: 3.705438266625919,
                lat: 10.063210354040208
            },
            {
                lng: 3.600070021182802,
                lat: 10.332186184119408
            },
            {
                lng: 3.797112257511714,
                lat: 10.734745591673105
            },
            {
                lng: 3.572216424177469,
                lat: 11.32793935795152
            },
            {
                lng: 3.611180454125559,
                lat: 11.660167141155968
            },
            {
                lng: 3.680633579125811,
                lat: 12.552903347214226
            },
            {
                lng: 3.967282749048849,
                lat: 12.956108710171575
            },
            {
                lng: 4.107945997747322,
                lat: 13.531215725147831
            },
            {
                lng: 4.368343540066007,
                lat: 13.747481594289411
            },
            {
                lng: 5.443058302440136,
                lat: 13.865923977102227
            },
            {
                lng: 6.445426059605637,
                lat: 13.492768459522678
            },
            {
                lng: 6.820441928747754,
                lat: 13.115091254117518
            },
            {
                lng: 7.330746697630019,
                lat: 13.0980380314612
            },
            {
                lng: 7.804671258178786,
                lat: 13.343526923063747
            },
            {
                lng: 9.014933302454438,
                lat: 12.826659247280418
            },
            {
                lng: 9.524928012742947,
                lat: 12.851102199754479
            },
            {
                lng: 10.114814487354693,
                lat: 13.27725189864941
            },
            {
                lng: 10.701031935273706,
                lat: 13.246917832894084
            },
            {
                lng: 10.989593133191534,
                lat: 13.387322699431195
            },
            {
                lng: 11.527803175511394,
                lat: 13.328980007373588
            },
            {
                lng: 12.302071160540523,
                lat: 13.037189032437524
            },
            {
                lng: 13.083987257548813,
                lat: 13.596147162322495
            },
            {
                lng: 13.318701613018561,
                lat: 13.556356309457826
            },
            {
                lng: 13.995352817448293,
                lat: 12.461565253138303
            },
            {
                lng: 14.181336297266794,
                lat: 12.483656927943116
            },
            {
                lng: 14.577177768622533,
                lat: 12.085360826053503
            },
            {
                lng: 14.468192172918975,
                lat: 11.904751695193411
            },
            {
                lng: 14.415378859116684,
                lat: 11.572368882692075
            },
            {
                lng: 13.572949659894562,
                lat: 10.798565985553566
            },
            {
                lng: 13.308676385153918,
                lat: 10.160362046748928
            },
            {
                lng: 13.167599724997103,
                lat: 9.640626328973411
            },
            {
                lng: 12.955467970438974,
                lat: 9.417771714714704
            },
            {
                lng: 12.753671502339216,
                lat: 8.717762762888995
            },
            {
                lng: 12.218872104550599,
                lat: 8.305824082874324
            },
            {
                lng: 12.063946160539558,
                lat: 7.799808457872302
            },
            {
                lng: 11.839308709366803,
                lat: 7.397042344589437
            },
            {
                lng: 11.74577436691851,
                lat: 6.981382961449754
            },
            {
                lng: 11.058787876030351,
                lat: 6.644426784690594
            },
            {
                lng: 10.497375115611419,
                lat: 7.055357774275564
            },
            {
                lng: 10.118276808318257,
                lat: 7.03876963950988
            },
            {
                lng: 9.522705926154401,
                lat: 6.453482367372117
            },
            {
                lng: 9.233162876023044,
                lat: 6.444490668153335
            },
            {
                lng: 8.757532993208628,
                lat: 5.479665839047911
            },
            {
                lng: 8.500287713259695,
                lat: 4.771982937026849
            },
            {
                lng: 7.462108188515941,
                lat: 4.412108262546241
            },
            {
                lng: 7.082596469764439,
                lat: 4.464689032403228
            },
            {
                lng: 6.6980721370806,
                lat: 4.240594183769517
            },
            {
                lng: 5.898172641634687,
                lat: 4.262453314628985
            },
            {
                lng: 5.362804803090881,
                lat: 4.887970689305959
            },
            {
                lng: 5.033574252959369,
                lat: 5.611802476418234
            },
            {
                lng: 4.325607130560684,
                lat: 6.270651149923467
            },
            {
                lng: 3.574180128604553,
                lat: 6.258300482605719
            },
            {
                lng: 2.691701694356254,
                lat: 6.258817246928629
            }
        ]
    },
    CM: {
        name: 'Cameroon',
        type: 'Polygon',
        coordinates: [
            {
                lng: 14.495787387762846,
                lat: 12.85939626713733
            },
            {
                lng: 14.89336,
                lat: 12.21905
            },
            {
                lng: 14.9601518083376,
                lat: 11.555574042197224
            },
            {
                lng: 14.92356489427496,
                lat: 10.891325181517473
            },
            {
                lng: 15.467872755605242,
                lat: 9.982336737503545
            },
            {
                lng: 14.909353875394716,
                lat: 9.992129421422732
            },
            {
                lng: 14.62720055508106,
                lat: 9.920919297724538
            },
            {
                lng: 14.171466098699028,
                lat: 10.021378282099931
            },
            {
                lng: 13.954218377344006,
                lat: 9.549494940626687
            },
            {
                lng: 14.54446658698177,
                lat: 8.965861314322268
            },
            {
                lng: 14.97999555833769,
                lat: 8.796104234243472
            },
            {
                lng: 15.120865512765306,
                lat: 8.382150173369439
            },
            {
                lng: 15.436091749745742,
                lat: 7.692812404811889
            },
            {
                lng: 15.279460483469109,
                lat: 7.421924546737969
            },
            {
                lng: 14.776545444404576,
                lat: 6.408498033062045
            },
            {
                lng: 14.536560092841114,
                lat: 6.22695872642069
            },
            {
                lng: 14.459407179429348,
                lat: 5.4517605656103
            },
            {
                lng: 14.558935988023507,
                lat: 5.03059764243153
            },
            {
                lng: 14.47837243008047,
                lat: 4.732605495620447
            },
            {
                lng: 14.950953403389661,
                lat: 4.210389309094921
            },
            {
                lng: 15.036219516671252,
                lat: 3.851367295747124
            },
            {
                lng: 15.405395948964383,
                lat: 3.33530060466434
            },
            {
                lng: 15.862732374747482,
                lat: 3.013537298998983
            },
            {
                lng: 15.907380812247652,
                lat: 2.557389431158612
            },
            {
                lng: 16.012852410555354,
                lat: 2.267639675298085
            },
            {
                lng: 15.940918816805066,
                lat: 1.727672634280296
            },
            {
                lng: 15.146341993885244,
                lat: 1.964014797367184
            },
            {
                lng: 14.33781253424658,
                lat: 2.227874660649491
            },
            {
                lng: 13.075822381246752,
                lat: 2.267097072759015
            },
            {
                lng: 12.951333855855609,
                lat: 2.32161570882694
            },
            {
                lng: 12.359380323952221,
                lat: 2.19281220133945
            },
            {
                lng: 11.75166548019979,
                lat: 2.326757513839993
            },
            {
                lng: 11.276449008843713,
                lat: 2.261050930180872
            },
            {
                lng: 9.649158155972628,
                lat: 2.283866075037736
            },
            {
                lng: 9.795195753629457,
                lat: 3.073404445809117
            },
            {
                lng: 9.404366896206,
                lat: 3.734526882335203
            },
            {
                lng: 8.948115675501072,
                lat: 3.904128933117136
            },
            {
                lng: 8.744923943729418,
                lat: 4.35221527751996
            },
            {
                lng: 8.48881554529089,
                lat: 4.495617377129918
            },
            {
                lng: 8.500287713259695,
                lat: 4.771982937026849
            },
            {
                lng: 8.757532993208628,
                lat: 5.479665839047911
            },
            {
                lng: 9.233162876023044,
                lat: 6.444490668153335
            },
            {
                lng: 9.522705926154401,
                lat: 6.453482367372117
            },
            {
                lng: 10.118276808318257,
                lat: 7.03876963950988
            },
            {
                lng: 10.497375115611419,
                lat: 7.055357774275564
            },
            {
                lng: 11.058787876030351,
                lat: 6.644426784690594
            },
            {
                lng: 11.74577436691851,
                lat: 6.981382961449754
            },
            {
                lng: 11.839308709366803,
                lat: 7.397042344589437
            },
            {
                lng: 12.063946160539558,
                lat: 7.799808457872302
            },
            {
                lng: 12.218872104550599,
                lat: 8.305824082874324
            },
            {
                lng: 12.753671502339216,
                lat: 8.717762762888995
            },
            {
                lng: 12.955467970438974,
                lat: 9.417771714714704
            },
            {
                lng: 13.167599724997103,
                lat: 9.640626328973411
            },
            {
                lng: 13.308676385153918,
                lat: 10.160362046748928
            },
            {
                lng: 13.572949659894562,
                lat: 10.798565985553566
            },
            {
                lng: 14.415378859116684,
                lat: 11.572368882692075
            },
            {
                lng: 14.468192172918975,
                lat: 11.904751695193411
            },
            {
                lng: 14.577177768622533,
                lat: 12.085360826053503
            },
            {
                lng: 14.181336297266794,
                lat: 12.483656927943116
            },
            {
                lng: 14.213530714584635,
                lat: 12.802035427293347
            },
            {
                lng: 14.495787387762846,
                lat: 12.85939626713733
            }
        ]
    },
    TG: {
        name: 'Togo',
        type: 'Polygon',
        coordinates: [
            {
                lng: 0.899563022474069,
                lat: 10.99733938236426
            },
            {
                lng: 0.772335646171484,
                lat: 10.470808213742359
            },
            {
                lng: 1.077795037448738,
                lat: 10.175606594275024
            },
            {
                lng: 1.425060662450136,
                lat: 9.825395412633
            },
            {
                lng: 1.46304284018467,
                lat: 9.334624335157088
            },
            {
                lng: 1.664477573258381,
                lat: 9.12859039960938
            },
            {
                lng: 1.618950636409238,
                lat: 6.832038072126238
            },
            {
                lng: 1.865240512712319,
                lat: 6.142157701029731
            },
            {
                lng: 1.060121697604927,
                lat: 5.928837388528876
            },
            {
                lng: 0.836931186536333,
                lat: 6.279978745952149
            },
            {
                lng: 0.570384148774849,
                lat: 6.914358628767189
            },
            {
                lng: 0.490957472342245,
                lat: 7.411744289576475
            },
            {
                lng: 0.712029249686879,
                lat: 8.31246450442383
            },
            {
                lng: 0.461191847342121,
                lat: 8.677222601756014
            },
            {
                lng: 0.365900506195885,
                lat: 9.465003973829482
            },
            {
                lng: 0.367579990245389,
                lat: 10.19121287682718
            },
            {
                lng: -0.049784715159944,
                lat: 10.706917832883931
            },
            {
                lng: 0.023802524423701,
                lat: 11.018681748900804
            },
            {
                lng: 0.899563022474069,
                lat: 10.99733938236426
            }
        ]
    },
    GH: {
        name: 'Ghana',
        type: 'Polygon',
        coordinates: [
            {
                lng: 0.023802524423701,
                lat: 11.018681748900804
            },
            {
                lng: -0.049784715159944,
                lat: 10.706917832883931
            },
            {
                lng: 0.367579990245389,
                lat: 10.19121287682718
            },
            {
                lng: 0.365900506195885,
                lat: 9.465003973829482
            },
            {
                lng: 0.461191847342121,
                lat: 8.677222601756014
            },
            {
                lng: 0.712029249686879,
                lat: 8.31246450442383
            },
            {
                lng: 0.490957472342245,
                lat: 7.411744289576475
            },
            {
                lng: 0.570384148774849,
                lat: 6.914358628767189
            },
            {
                lng: 0.836931186536333,
                lat: 6.279978745952149
            },
            {
                lng: 1.060121697604927,
                lat: 5.928837388528876
            },
            {
                lng: -0.507637905265938,
                lat: 5.343472601742675
            },
            {
                lng: -1.063624640294194,
                lat: 5.000547797053812
            },
            {
                lng: -1.964706590167594,
                lat: 4.710462144383371
            },
            {
                lng: -2.856125047202397,
                lat: 4.994475816259509
            },
            {
                lng: -2.81070146321784,
                lat: 5.38905121502411
            },
            {
                lng: -3.244370083011262,
                lat: 6.250471503113502
            },
            {
                lng: -2.983584967450327,
                lat: 7.379704901555513
            },
            {
                lng: -2.562189500326241,
                lat: 8.219627793811483
            },
            {
                lng: -2.827496303712707,
                lat: 9.642460842319778
            },
            {
                lng: -2.963896246747112,
                lat: 10.395334784380083
            },
            {
                lng: -2.940409308270461,
                lat: 10.962690334512558
            },
            {
                lng: -1.203357713211432,
                lat: 11.009819240762738
            },
            {
                lng: -0.761575893548183,
                lat: 10.936929633015055
            },
            {
                lng: -0.438701544588582,
                lat: 11.098340969278722
            },
            {
                lng: 0.023802524423701,
                lat: 11.018681748900804
            }
        ]
    },
    CI: {
        name: "Côte d'Ivoire",
        type: 'Polygon',
        coordinates: [
            {
                lng: -8.029943610048619,
                lat: 10.206534939001713
            },
            {
                lng: -7.899589809592372,
                lat: 10.297382106970828
            },
            {
                lng: -7.622759161804809,
                lat: 10.147236232946796
            },
            {
                lng: -6.850506557635057,
                lat: 10.138993841996239
            },
            {
                lng: -6.666460944027548,
                lat: 10.430810655148449
            },
            {
                lng: -6.493965013037268,
                lat: 10.411302801958271
            },
            {
                lng: -6.205222947606431,
                lat: 10.524060777219134
            },
            {
                lng: -6.050452032892267,
                lat: 10.096360785355444
            },
            {
                lng: -5.816926235365287,
                lat: 10.222554633012194
            },
            {
                lng: -5.404341599946974,
                lat: 10.370736802609146
            },
            {
                lng: -4.954653286143099,
                lat: 10.152713934769736
            },
            {
                lng: -4.779883592131966,
                lat: 9.821984768101743
            },
            {
                lng: -4.330246954760383,
                lat: 9.610834865757141
            },
            {
                lng: -3.980449184576685,
                lat: 9.8623440617217
            },
            {
                lng: -3.511898972986273,
                lat: 9.90032623945622
            },
            {
                lng: -2.827496303712707,
                lat: 9.642460842319778
            },
            {
                lng: -2.562189500326241,
                lat: 8.219627793811483
            },
            {
                lng: -2.983584967450327,
                lat: 7.379704901555513
            },
            {
                lng: -3.244370083011262,
                lat: 6.250471503113502
            },
            {
                lng: -2.81070146321784,
                lat: 5.38905121502411
            },
            {
                lng: -2.856125047202397,
                lat: 4.994475816259509
            },
            {
                lng: -3.311084357100071,
                lat: 4.984295559098015
            },
            {
                lng: -4.008819545904942,
                lat: 5.179813340674315
            },
            {
                lng: -4.649917364917911,
                lat: 5.168263658057086
            },
            {
                lng: -5.834496222344526,
                lat: 4.993700669775137
            },
            {
                lng: -6.528769090185847,
                lat: 4.705087795425015
            },
            {
                lng: -7.518941209330436,
                lat: 4.338288479017308
            },
            {
                lng: -7.71215938966975,
                lat: 4.364565944837722
            },
            {
                lng: -7.635368211284031,
                lat: 5.188159084489456
            },
            {
                lng: -7.539715135111763,
                lat: 5.313345241716519
            },
            {
                lng: -7.570152553731688,
                lat: 5.707352199725904
            },
            {
                lng: -7.993692592795881,
                lat: 6.126189683451543
            },
            {
                lng: -8.311347622094019,
                lat: 6.193033148621083
            },
            {
                lng: -8.60288021486862,
                lat: 6.46756419517166
            },
            {
                lng: -8.385451626000574,
                lat: 6.911800645368743
            },
            {
                lng: -8.48544552248535,
                lat: 7.39520783124307
            },
            {
                lng: -8.439298468448698,
                lat: 7.686042792181738
            },
            {
                lng: -8.280703497744938,
                lat: 7.687179673692157
            },
            {
                lng: -8.221792364932199,
                lat: 8.123328762235573
            },
            {
                lng: -8.299048631208564,
                lat: 8.316443589710303
            },
            {
                lng: -8.20349890790088,
                lat: 8.455453192575447
            },
            {
                lng: -7.832100389019188,
                lat: 8.575704250518626
            },
            {
                lng: -8.07911373537435,
                lat: 9.376223863152035
            },
            {
                lng: -8.30961646161225,
                lat: 9.789531968622441
            },
            {
                lng: -8.229337124046822,
                lat: 10.1290202905639
            },
            {
                lng: -8.029943610048619,
                lat: 10.206534939001713
            }
        ]
    },
    GN: {
        name: 'Guinea',
        type: 'Polygon',
        coordinates: [
            {
                lng: -13.700476040084325,
                lat: 12.586182969610194
            },
            {
                lng: -13.217818162478238,
                lat: 12.575873521367967
            },
            {
                lng: -12.499050665730564,
                lat: 12.332089952031057
            },
            {
                lng: -12.27859900557344,
                lat: 12.354440008997287
            },
            {
                lng: -12.203564825885634,
                lat: 12.465647691289405
            },
            {
                lng: -11.658300950557932,
                lat: 12.386582749882836
            },
            {
                lng: -11.51394283695059,
                lat: 12.442987575729418
            },
            {
                lng: -11.456168585648271,
                lat: 12.076834214725338
            },
            {
                lng: -11.297573614944511,
                lat: 12.077971096235771
            },
            {
                lng: -11.03655595543826,
                lat: 12.211244615116515
            },
            {
                lng: -10.870829637078215,
                lat: 12.17788747807211
            },
            {
                lng: -10.593223842806282,
                lat: 11.92397532800598
            },
            {
                lng: -10.165213792348837,
                lat: 11.844083563682744
            },
            {
                lng: -9.890992804392013,
                lat: 12.060478623904972
            },
            {
                lng: -9.567911749703214,
                lat: 12.194243068892476
            },
            {
                lng: -9.327616339546012,
                lat: 12.334286200403454
            },
            {
                lng: -9.127473517279583,
                lat: 12.308060411015333
            },
            {
                lng: -8.90526485842453,
                lat: 12.088358059126437
            },
            {
                lng: -8.786099005559464,
                lat: 11.812560939984706
            },
            {
                lng: -8.376304897484914,
                lat: 11.393645941610629
            },
            {
                lng: -8.581305304386774,
                lat: 11.136245632364805
            },
            {
                lng: -8.620321010767128,
                lat: 10.810890814655183
            },
            {
                lng: -8.407310756860028,
                lat: 10.909256903522762
            },
            {
                lng: -8.282357143578281,
                lat: 10.792597357623846
            },
            {
                lng: -8.33537716310974,
                lat: 10.494811916541934
            },
            {
                lng: -8.029943610048619,
                lat: 10.206534939001713
            },
            {
                lng: -8.229337124046822,
                lat: 10.1290202905639
            },
            {
                lng: -8.30961646161225,
                lat: 9.789531968622441
            },
            {
                lng: -8.07911373537435,
                lat: 9.376223863152035
            },
            {
                lng: -7.832100389019188,
                lat: 8.575704250518626
            },
            {
                lng: -8.20349890790088,
                lat: 8.455453192575447
            },
            {
                lng: -8.299048631208564,
                lat: 8.316443589710303
            },
            {
                lng: -8.221792364932199,
                lat: 8.123328762235573
            },
            {
                lng: -8.280703497744938,
                lat: 7.687179673692157
            },
            {
                lng: -8.439298468448698,
                lat: 7.686042792181738
            },
            {
                lng: -8.722123582382125,
                lat: 7.71167430259851
            },
            {
                lng: -8.926064622422004,
                lat: 7.309037380396376
            },
            {
                lng: -9.208786383490846,
                lat: 7.313920803247953
            },
            {
                lng: -9.40334815106975,
                lat: 7.526905218938907
            },
            {
                lng: -9.337279832384581,
                lat: 7.928534450711354
            },
            {
                lng: -9.755342169625834,
                lat: 8.541055202666925
            },
            {
                lng: -10.016566534861255,
                lat: 8.428503933135232
            },
            {
                lng: -10.23009355309128,
                lat: 8.406205552601293
            },
            {
                lng: -10.505477260774668,
                lat: 8.348896389189605
            },
            {
                lng: -10.494315151399633,
                lat: 8.715540676300435
            },
            {
                lng: -10.654770473665891,
                lat: 8.977178452994195
            },
            {
                lng: -10.622395188835041,
                lat: 9.267910061068278
            },
            {
                lng: -10.839151984083301,
                lat: 9.688246161330369
            },
            {
                lng: -11.11748124840733,
                lat: 10.045872911006285
            },
            {
                lng: -11.917277390988659,
                lat: 10.046983954300558
            },
            {
                lng: -12.150338100625005,
                lat: 9.858571682164381
            },
            {
                lng: -12.425928514037565,
                lat: 9.835834051955956
            },
            {
                lng: -12.59671912276221,
                lat: 9.62018830000197
            },
            {
                lng: -12.71195756677308,
                lat: 9.342711696810767
            },
            {
                lng: -13.246550258832515,
                lat: 8.903048610871508
            },
            {
                lng: -13.685153977909792,
                lat: 9.49474376061346
            },
            {
                lng: -14.074044969122282,
                lat: 9.886166897008252
            },
            {
                lng: -14.33007585291237,
                lat: 10.015719712763968
            },
            {
                lng: -14.579698859098258,
                lat: 10.214467271358515
            },
            {
                lng: -14.693231980843505,
                lat: 10.656300767454042
            },
            {
                lng: -14.839553798877944,
                lat: 10.87657156009814
            },
            {
                lng: -15.130311245168173,
                lat: 11.040411688679526
            },
            {
                lng: -14.685687221728898,
                lat: 11.527823798056488
            },
            {
                lng: -14.382191534878729,
                lat: 11.509271958863692
            },
            {
                lng: -14.121406419317779,
                lat: 11.677117010947697
            },
            {
                lng: -13.900799729863776,
                lat: 11.678718980348748
            },
            {
                lng: -13.743160773157413,
                lat: 11.811269029177412
            },
            {
                lng: -13.828271857142125,
                lat: 12.142644151249044
            },
            {
                lng: -13.718743658899513,
                lat: 12.24718557377551
            },
            {
                lng: -13.700476040084325,
                lat: 12.586182969610194
            }
        ]
    },
    GW: {
        name: 'Guinea-Bissau',
        type: 'Polygon',
        coordinates: [
            {
                lng: -16.677451951554573,
                lat: 12.384851589401052
            },
            {
                lng: -16.147716844130585,
                lat: 12.547761542201187
            },
            {
                lng: -15.816574266004254,
                lat: 12.515567124883347
            },
            {
                lng: -15.54847693527401,
                lat: 12.628170070847347
            },
            {
                lng: -13.700476040084325,
                lat: 12.586182969610194
            },
            {
                lng: -13.718743658899513,
                lat: 12.24718557377551
            },
            {
                lng: -13.828271857142125,
                lat: 12.142644151249044
            },
            {
                lng: -13.743160773157413,
                lat: 11.811269029177412
            },
            {
                lng: -13.900799729863776,
                lat: 11.678718980348748
            },
            {
                lng: -14.121406419317779,
                lat: 11.677117010947697
            },
            {
                lng: -14.382191534878729,
                lat: 11.509271958863692
            },
            {
                lng: -14.685687221728898,
                lat: 11.527823798056488
            },
            {
                lng: -15.130311245168173,
                lat: 11.040411688679526
            },
            {
                lng: -15.664180467175527,
                lat: 11.458474025920795
            },
            {
                lng: -16.085214199273565,
                lat: 11.52459402103824
            },
            {
                lng: -16.314786749730203,
                lat: 11.80651479740655
            },
            {
                lng: -16.30894731288123,
                lat: 11.95870189050612
            },
            {
                lng: -16.61383826340328,
                lat: 12.170911159712702
            },
            {
                lng: -16.677451951554573,
                lat: 12.384851589401052
            }
        ]
    },
    LR: {
        name: 'Liberia',
        type: 'Polygon',
        coordinates: [
            {
                lng: -8.439298468448698,
                lat: 7.686042792181738
            },
            {
                lng: -8.48544552248535,
                lat: 7.39520783124307
            },
            {
                lng: -8.385451626000574,
                lat: 6.911800645368743
            },
            {
                lng: -8.60288021486862,
                lat: 6.46756419517166
            },
            {
                lng: -8.311347622094019,
                lat: 6.193033148621083
            },
            {
                lng: -7.993692592795881,
                lat: 6.126189683451543
            },
            {
                lng: -7.570152553731688,
                lat: 5.707352199725904
            },
            {
                lng: -7.539715135111763,
                lat: 5.313345241716519
            },
            {
                lng: -7.635368211284031,
                lat: 5.188159084489456
            },
            {
                lng: -7.71215938966975,
                lat: 4.364565944837722
            },
            {
                lng: -7.974107224957251,
                lat: 4.355755113131963
            },
            {
                lng: -9.004793667018674,
                lat: 4.8324185245922
            },
            {
                lng: -9.913420376006684,
                lat: 5.593560695819207
            },
            {
                lng: -10.765383876986645,
                lat: 6.140710760925558
            },
            {
                lng: -11.438779466182055,
                lat: 6.785916856305747
            },
            {
                lng: -11.19980180504828,
                lat: 7.105845648624737
            },
            {
                lng: -11.146704270868383,
                lat: 7.396706447779536
            },
            {
                lng: -10.69559485517648,
                lat: 7.939464016141088
            },
            {
                lng: -10.23009355309128,
                lat: 8.406205552601293
            },
            {
                lng: -10.016566534861255,
                lat: 8.428503933135232
            },
            {
                lng: -9.755342169625834,
                lat: 8.541055202666925
            },
            {
                lng: -9.337279832384581,
                lat: 7.928534450711354
            },
            {
                lng: -9.40334815106975,
                lat: 7.526905218938907
            },
            {
                lng: -9.208786383490846,
                lat: 7.313920803247953
            },
            {
                lng: -8.926064622422004,
                lat: 7.309037380396376
            },
            {
                lng: -8.722123582382125,
                lat: 7.71167430259851
            },
            {
                lng: -8.439298468448698,
                lat: 7.686042792181738
            }
        ]
    },
    SL: {
        name: 'Sierra Leone',
        type: 'Polygon',
        coordinates: [
            {
                lng: -13.246550258832515,
                lat: 8.903048610871508
            },
            {
                lng: -12.71195756677308,
                lat: 9.342711696810767
            },
            {
                lng: -12.59671912276221,
                lat: 9.62018830000197
            },
            {
                lng: -12.425928514037565,
                lat: 9.835834051955956
            },
            {
                lng: -12.150338100625005,
                lat: 9.858571682164381
            },
            {
                lng: -11.917277390988659,
                lat: 10.046983954300558
            },
            {
                lng: -11.11748124840733,
                lat: 10.045872911006285
            },
            {
                lng: -10.839151984083301,
                lat: 9.688246161330369
            },
            {
                lng: -10.622395188835041,
                lat: 9.267910061068278
            },
            {
                lng: -10.654770473665891,
                lat: 8.977178452994195
            },
            {
                lng: -10.494315151399633,
                lat: 8.715540676300435
            },
            {
                lng: -10.505477260774668,
                lat: 8.348896389189605
            },
            {
                lng: -10.23009355309128,
                lat: 8.406205552601293
            },
            {
                lng: -10.69559485517648,
                lat: 7.939464016141088
            },
            {
                lng: -11.146704270868383,
                lat: 7.396706447779536
            },
            {
                lng: -11.19980180504828,
                lat: 7.105845648624737
            },
            {
                lng: -11.438779466182055,
                lat: 6.785916856305747
            },
            {
                lng: -11.70819454593574,
                lat: 6.860098374860726
            },
            {
                lng: -12.428098924193819,
                lat: 7.26294200279203
            },
            {
                lng: -12.949049038128194,
                lat: 7.798645738145738
            },
            {
                lng: -13.124025437868482,
                lat: 8.163946438016978
            },
            {
                lng: -13.246550258832515,
                lat: 8.903048610871508
            }
        ]
    },
    BF: {
        name: 'Burkina Faso',
        type: 'Polygon',
        coordinates: [
            {
                lng: -5.404341599946974,
                lat: 10.370736802609146
            },
            {
                lng: -5.470564947929006,
                lat: 10.951269842976048
            },
            {
                lng: -5.197842576508648,
                lat: 11.37514577885014
            },
            {
                lng: -5.220941941743121,
                lat: 11.713858954307227
            },
            {
                lng: -4.427166103523803,
                lat: 12.542645575404295
            },
            {
                lng: -4.28040503581488,
                lat: 13.228443508349741
            },
            {
                lng: -4.006390753587226,
                lat: 13.472485459848116
            },
            {
                lng: -3.522802700199861,
                lat: 13.337661647998615
            },
            {
                lng: -3.10370683431276,
                lat: 13.541266791228594
            },
            {
                lng: -2.967694464520577,
                lat: 13.79815033615151
            },
            {
                lng: -2.191824510090385,
                lat: 14.246417548067356
            },
            {
                lng: -2.001035122068771,
                lat: 14.559008287000893
            },
            {
                lng: -1.066363491205664,
                lat: 14.973815009007765
            },
            {
                lng: -0.515854458000348,
                lat: 15.116157741755728
            },
            {
                lng: -0.26625729003058,
                lat: 14.924308986872148
            },
            {
                lng: 0.374892205414682,
                lat: 14.92890818934613
            },
            {
                lng: 0.295646396495101,
                lat: 14.444234930880654
            },
            {
                lng: 0.429927605805517,
                lat: 13.988733018443924
            },
            {
                lng: 0.993045688490071,
                lat: 13.335749620003824
            },
            {
                lng: 1.024103224297477,
                lat: 12.851825669806574
            },
            {
                lng: 2.177107781593776,
                lat: 12.625017808477535
            },
            {
                lng: 2.154473504249921,
                lat: 11.940150051313339
            },
            {
                lng: 1.935985548519881,
                lat: 11.641150214072553
            },
            {
                lng: 1.447178175471066,
                lat: 11.547719224488858
            },
            {
                lng: 1.243469679376489,
                lat: 11.110510769083461
            },
            {
                lng: 0.899563022474069,
                lat: 10.99733938236426
            },
            {
                lng: 0.023802524423701,
                lat: 11.018681748900804
            },
            {
                lng: -0.438701544588582,
                lat: 11.098340969278722
            },
            {
                lng: -0.761575893548183,
                lat: 10.936929633015055
            },
            {
                lng: -1.203357713211432,
                lat: 11.009819240762738
            },
            {
                lng: -2.940409308270461,
                lat: 10.962690334512558
            },
            {
                lng: -2.963896246747112,
                lat: 10.395334784380083
            },
            {
                lng: -2.827496303712707,
                lat: 9.642460842319778
            },
            {
                lng: -3.511898972986273,
                lat: 9.90032623945622
            },
            {
                lng: -3.980449184576685,
                lat: 9.8623440617217
            },
            {
                lng: -4.330246954760383,
                lat: 9.610834865757141
            },
            {
                lng: -4.779883592131966,
                lat: 9.821984768101743
            },
            {
                lng: -4.954653286143099,
                lat: 10.152713934769736
            },
            {
                lng: -5.404341599946974,
                lat: 10.370736802609146
            }
        ]
    },
    CF: {
        name: 'Central African Republic',
        type: 'Polygon',
        coordinates: [
            {
                lng: 27.37422610851749,
                lat: 5.233944403500061
            },
            {
                lng: 27.04406538260471,
                lat: 5.127852688004836
            },
            {
                lng: 26.402760857862543,
                lat: 5.150874538590871
            },
            {
                lng: 25.650455356557472,
                lat: 5.256087754737123
            },
            {
                lng: 25.278798455514305,
                lat: 5.170408229997192
            },
            {
                lng: 25.12883344900328,
                lat: 4.92724477784779
            },
            {
                lng: 24.805028924262416,
                lat: 4.89724660890235
            },
            {
                lng: 24.410531040146253,
                lat: 5.10878408448913
            },
            {
                lng: 23.29721398285014,
                lat: 4.609693101414223
            },
            {
                lng: 22.841479526468106,
                lat: 4.710126247573484
            },
            {
                lng: 22.70412356943629,
                lat: 4.633050848810157
            },
            {
                lng: 22.405123732195538,
                lat: 4.029160061047321
            },
            {
                lng: 21.659122755630023,
                lat: 4.22434194581372
            },
            {
                lng: 20.927591180106276,
                lat: 4.322785549329737
            },
            {
                lng: 20.290679152108936,
                lat: 4.691677761245288
            },
            {
                lng: 19.46778364429315,
                lat: 5.03152781821278
            },
            {
                lng: 18.93231245288476,
                lat: 4.709506130385975
            },
            {
                lng: 18.54298221199778,
                lat: 4.201785183118318
            },
            {
                lng: 18.45306521980993,
                lat: 3.504385891123349
            },
            {
                lng: 17.809900343505262,
                lat: 3.56019643799857
            },
            {
                lng: 17.133042433346304,
                lat: 3.728196519379452
            },
            {
                lng: 16.537058139724138,
                lat: 3.198254706226279
            },
            {
                lng: 16.012852410555354,
                lat: 2.267639675298085
            },
            {
                lng: 15.907380812247652,
                lat: 2.557389431158612
            },
            {
                lng: 15.862732374747482,
                lat: 3.013537298998983
            },
            {
                lng: 15.405395948964383,
                lat: 3.33530060466434
            },
            {
                lng: 15.036219516671252,
                lat: 3.851367295747124
            },
            {
                lng: 14.950953403389661,
                lat: 4.210389309094921
            },
            {
                lng: 14.47837243008047,
                lat: 4.732605495620447
            },
            {
                lng: 14.558935988023507,
                lat: 5.03059764243153
            },
            {
                lng: 14.459407179429348,
                lat: 5.4517605656103
            },
            {
                lng: 14.536560092841114,
                lat: 6.22695872642069
            },
            {
                lng: 14.776545444404576,
                lat: 6.408498033062045
            },
            {
                lng: 15.279460483469109,
                lat: 7.421924546737969
            },
            {
                lng: 16.106231723706742,
                lat: 7.497087917506462
            },
            {
                lng: 16.290561557691888,
                lat: 7.754307359239419
            },
            {
                lng: 16.456184523187346,
                lat: 7.734773667832968
            },
            {
                lng: 16.705988396886255,
                lat: 7.508327541529979
            },
            {
                lng: 17.964929640380888,
                lat: 7.890914008002994
            },
            {
                lng: 18.38955488452322,
                lat: 8.281303615751824
            },
            {
                lng: 18.911021762780507,
                lat: 8.630894680206353
            },
            {
                lng: 18.812009718509273,
                lat: 8.982914536978598
            },
            {
                lng: 19.09400800952602,
                lat: 9.07484691002584
            },
            {
                lng: 20.05968549976427,
                lat: 9.012706000194854
            },
            {
                lng: 21.000868361096167,
                lat: 9.475985215691509
            },
            {
                lng: 21.723821648859456,
                lat: 10.567055568885976
            },
            {
                lng: 22.23112918466876,
                lat: 10.97188873946061
            },
            {
                lng: 22.864165480244225,
                lat: 11.142395127807546
            },
            {
                lng: 22.97754357269261,
                lat: 10.71446259199854
            },
            {
                lng: 23.554304233502194,
                lat: 10.089255275915308
            },
            {
                lng: 23.55724979014283,
                lat: 9.681218166538684
            },
            {
                lng: 23.394779087017184,
                lat: 9.265067857292223
            },
            {
                lng: 23.459012892355986,
                lat: 8.954285793488893
            },
            {
                lng: 23.805813429466752,
                lat: 8.666318874542526
            },
            {
                lng: 24.567369012152085,
                lat: 8.229187933785468
            },
            {
                lng: 25.11493248871679,
                lat: 7.825104071479174
            },
            {
                lng: 25.124130893664727,
                lat: 7.500085150579437
            },
            {
                lng: 25.79664798351118,
                lat: 6.979315904158071
            },
            {
                lng: 26.213418409945117,
                lat: 6.546603298362072
            },
            {
                lng: 26.465909458123235,
                lat: 5.94671743410187
            },
            {
                lng: 27.21340905122517,
                lat: 5.550953477394557
            },
            {
                lng: 27.37422610851749,
                lat: 5.233944403500061
            }
        ]
    },
    CG: {
        name: 'Congo, Rep.',
        type: 'Polygon',
        coordinates: [
            {
                lng: 18.45306521980993,
                lat: 3.504385891123349
            },
            {
                lng: 18.393792351971143,
                lat: 2.90044342692822
            },
            {
                lng: 18.094275750407434,
                lat: 2.365721543788055
            },
            {
                lng: 17.898835483479587,
                lat: 1.741831976728278
            },
            {
                lng: 17.774191928791566,
                lat: 0.855658677571085
            },
            {
                lng: 17.826540154703252,
                lat: 0.288923244626105
            },
            {
                lng: 17.66355268725468,
                lat: -0.058083998213817
            },
            {
                lng: 17.638644646889986,
                lat: -0.424831638189247
            },
            {
                lng: 17.523716261472856,
                lat: -0.743830254726987
            },
            {
                lng: 16.865306837642123,
                lat: -1.225816338713287
            },
            {
                lng: 16.407091912510054,
                lat: -1.740927015798682
            },
            {
                lng: 15.972803175529151,
                lat: -2.712392266453612
            },
            {
                lng: 16.0062895036543,
                lat: -3.535132744972529
            },
            {
                lng: 15.753540073314753,
                lat: -3.855164890156097
            },
            {
                lng: 15.170991652088444,
                lat: -4.343507175314301
            },
            {
                lng: 14.582603794013181,
                lat: -4.97023894615014
            },
            {
                lng: 14.209034864975223,
                lat: -4.793092136253598
            },
            {
                lng: 14.144956088933299,
                lat: -4.510008640158716
            },
            {
                lng: 13.600234816144678,
                lat: -4.50013844159097
            },
            {
                lng: 13.258240187237048,
                lat: -4.882957452009165
            },
            {
                lng: 12.995517205465177,
                lat: -4.781103203961884
            },
            {
                lng: 12.620759718484493,
                lat: -4.438023369976136
            },
            {
                lng: 12.318607618873926,
                lat: -4.606230157086188
            },
            {
                lng: 11.91496300624209,
                lat: -5.037986748884791
            },
            {
                lng: 11.093772820691925,
                lat: -3.978826592630547
            },
            {
                lng: 11.855121697648116,
                lat: -3.426870619321051
            },
            {
                lng: 11.478038771214303,
                lat: -2.765618991714241
            },
            {
                lng: 11.820963575903193,
                lat: -2.514161472181982
            },
            {
                lng: 12.495702752338161,
                lat: -2.391688327650243
            },
            {
                lng: 12.575284458067642,
                lat: -1.948511244315135
            },
            {
                lng: 13.109618767965628,
                lat: -2.428740329603514
            },
            {
                lng: 13.99240726080771,
                lat: -2.4708049454891
            },
            {
                lng: 14.299210239324566,
                lat: -1.998275648612214
            },
            {
                lng: 14.425455763413595,
                lat: -1.333406670744971
            },
            {
                lng: 14.316418491277744,
                lat: -0.552627455247048
            },
            {
                lng: 13.843320753645656,
                lat: 0.038757635901149
            },
            {
                lng: 14.276265903386957,
                lat: 1.196929836426619
            },
            {
                lng: 14.026668735417218,
                lat: 1.395677395021153
            },
            {
                lng: 13.282631463278818,
                lat: 1.31418366129688
            },
            {
                lng: 13.003113641012078,
                lat: 1.83089630778332
            },
            {
                lng: 13.075822381246752,
                lat: 2.267097072759015
            },
            {
                lng: 14.33781253424658,
                lat: 2.227874660649491
            },
            {
                lng: 15.146341993885244,
                lat: 1.964014797367184
            },
            {
                lng: 15.940918816805066,
                lat: 1.727672634280296
            },
            {
                lng: 16.012852410555354,
                lat: 2.267639675298085
            },
            {
                lng: 16.537058139724138,
                lat: 3.198254706226279
            },
            {
                lng: 17.133042433346304,
                lat: 3.728196519379452
            },
            {
                lng: 17.809900343505262,
                lat: 3.56019643799857
            },
            {
                lng: 18.45306521980993,
                lat: 3.504385891123349
            }
        ]
    },
    GA: {
        name: 'Gabon',
        type: 'Polygon',
        coordinates: [
            {
                lng: 11.276449008843713,
                lat: 2.261050930180872
            },
            {
                lng: 11.75166548019979,
                lat: 2.326757513839993
            },
            {
                lng: 12.359380323952221,
                lat: 2.19281220133945
            },
            {
                lng: 12.951333855855609,
                lat: 2.32161570882694
            },
            {
                lng: 13.075822381246752,
                lat: 2.267097072759015
            },
            {
                lng: 13.003113641012078,
                lat: 1.83089630778332
            },
            {
                lng: 13.282631463278818,
                lat: 1.31418366129688
            },
            {
                lng: 14.026668735417218,
                lat: 1.395677395021153
            },
            {
                lng: 14.276265903386957,
                lat: 1.196929836426619
            },
            {
                lng: 13.843320753645656,
                lat: 0.038757635901149
            },
            {
                lng: 14.316418491277744,
                lat: -0.552627455247048
            },
            {
                lng: 14.425455763413595,
                lat: -1.333406670744971
            },
            {
                lng: 14.299210239324566,
                lat: -1.998275648612214
            },
            {
                lng: 13.99240726080771,
                lat: -2.4708049454891
            },
            {
                lng: 13.109618767965628,
                lat: -2.428740329603514
            },
            {
                lng: 12.575284458067642,
                lat: -1.948511244315135
            },
            {
                lng: 12.495702752338161,
                lat: -2.391688327650243
            },
            {
                lng: 11.820963575903193,
                lat: -2.514161472181982
            },
            {
                lng: 11.478038771214303,
                lat: -2.765618991714241
            },
            {
                lng: 11.855121697648116,
                lat: -3.426870619321051
            },
            {
                lng: 11.093772820691925,
                lat: -3.978826592630547
            },
            {
                lng: 10.06613528813574,
                lat: -2.969482517105682
            },
            {
                lng: 9.40524539555497,
                lat: -2.144313246269043
            },
            {
                lng: 8.79799563969317,
                lat: -1.111301364754496
            },
            {
                lng: 8.830086704146424,
                lat: -0.779073581550037
            },
            {
                lng: 9.048419630579588,
                lat: -0.459351494960217
            },
            {
                lng: 9.29135053878369,
                lat: 0.268666083167687
            },
            {
                lng: 9.492888624721985,
                lat: 1.010119533691494
            },
            {
                lng: 9.830284051155644,
                lat: 1.067893784993799
            },
            {
                lng: 11.285078973036462,
                lat: 1.057661851400013
            },
            {
                lng: 11.276449008843713,
                lat: 2.261050930180872
            }
        ]
    },
    GQ: {
        name: 'Equatorial Guinea',
        type: 'Polygon',
        coordinates: [
            {
                lng: 9.649158155972628,
                lat: 2.283866075037736
            },
            {
                lng: 11.276449008843713,
                lat: 2.261050930180872
            },
            {
                lng: 11.285078973036462,
                lat: 1.057661851400013
            },
            {
                lng: 9.830284051155644,
                lat: 1.067893784993799
            },
            {
                lng: 9.492888624721985,
                lat: 1.010119533691494
            },
            {
                lng: 9.305613234096256,
                lat: 1.160911363119183
            },
            {
                lng: 9.649158155972628,
                lat: 2.283866075037736
            }
        ]
    },
    ZM: {
        name: 'Zambia',
        type: 'Polygon',
        coordinates: [
            {
                lng: 30.740009731422095,
                lat: -8.34000593035372
            },
            {
                lng: 31.15775133695005,
                lat: -8.594578747317366
            },
            {
                lng: 31.556348097466497,
                lat: -8.762048841998642
            },
            {
                lng: 32.19186486179194,
                lat: -8.930358981973257
            },
            {
                lng: 32.75937544122132,
                lat: -9.23059905358906
            },
            {
                lng: 33.2313879737753,
                lat: -9.6767216935648
            },
            {
                lng: 33.48568769708359,
                lat: -10.525558770391115
            },
            {
                lng: 33.315310499817286,
                lat: -10.796549981329697
            },
            {
                lng: 33.114289178201915,
                lat: -11.607198174692314
            },
            {
                lng: 33.306422153463075,
                lat: -12.435778090060218
            },
            {
                lng: 32.991764357237884,
                lat: -12.783870537978274
            },
            {
                lng: 32.68816531752313,
                lat: -13.712857761289277
            },
            {
                lng: 33.214024692525214,
                lat: -13.971860039936153
            },
            {
                lng: 30.17948123548183,
                lat: -14.796099134991529
            },
            {
                lng: 30.27425581230511,
                lat: -15.507786960515213
            },
            {
                lng: 29.516834344203147,
                lat: -15.644677829656388
            },
            {
                lng: 28.947463413211267,
                lat: -16.04305144619444
            },
            {
                lng: 28.8258687680285,
                lat: -16.389748630440614
            },
            {
                lng: 28.467906121542683,
                lat: -16.468400160388846
            },
            {
                lng: 27.59824344250276,
                lat: -17.290830580314008
            },
            {
                lng: 27.044427117630732,
                lat: -17.938026218337434
            },
            {
                lng: 26.70677330903564,
                lat: -17.961228936436484
            },
            {
                lng: 26.381935255648926,
                lat: -17.8460421688579
            },
            {
                lng: 25.264225701608012,
                lat: -17.736539808831417
            },
            {
                lng: 25.08444339366457,
                lat: -17.661815687737374
            },
            {
                lng: 25.07695031098226,
                lat: -17.57882333747662
            },
            {
                lng: 24.682349074001507,
                lat: -17.353410739819473
            },
            {
                lng: 24.033861525170778,
                lat: -17.295843194246324
            },
            {
                lng: 23.215048455506064,
                lat: -17.523116143465984
            },
            {
                lng: 22.56247846852426,
                lat: -16.898451429921813
            },
            {
                lng: 21.887842644953874,
                lat: -16.08031015387688
            },
            {
                lng: 21.933886346125917,
                lat: -12.898437188369359
            },
            {
                lng: 24.016136508894675,
                lat: -12.911046237848574
            },
            {
                lng: 23.930922072045377,
                lat: -12.565847670138856
            },
            {
                lng: 24.079905226342845,
                lat: -12.191296888887365
            },
            {
                lng: 23.904153680118185,
                lat: -11.722281589406322
            },
            {
                lng: 24.01789350759259,
                lat: -11.23729827234709
            },
            {
                lng: 23.912215203555718,
                lat: -10.926826267137514
            },
            {
                lng: 24.25715538910399,
                lat: -10.951992689663657
            },
            {
                lng: 24.31451622894795,
                lat: -11.26282642989927
            },
            {
                lng: 24.78316979340295,
                lat: -11.238693536018964
            },
            {
                lng: 25.418118116973204,
                lat: -11.330935967659961
            },
            {
                lng: 25.752309604604733,
                lat: -11.784965101776358
            },
            {
                lng: 26.553087599399618,
                lat: -11.924439792532127
            },
            {
                lng: 27.164419793412463,
                lat: -11.608748467661075
            },
            {
                lng: 27.388798862423783,
                lat: -12.132747491100666
            },
            {
                lng: 28.155108676879987,
                lat: -12.272480564017897
            },
            {
                lng: 28.523561639121027,
                lat: -12.698604424696683
            },
            {
                lng: 28.934285922976837,
                lat: -13.248958428605135
            },
            {
                lng: 29.69961388521949,
                lat: -13.257226657771831
            },
            {
                lng: 29.61600141777123,
                lat: -12.178894545137311
            },
            {
                lng: 29.34154788586909,
                lat: -12.360743910372413
            },
            {
                lng: 28.642417433392353,
                lat: -11.971568698782315
            },
            {
                lng: 28.372253045370428,
                lat: -11.793646742401393
            },
            {
                lng: 28.49606977714177,
                lat: -10.789883721564046
            },
            {
                lng: 28.67368167492893,
                lat: -9.605924981324932
            },
            {
                lng: 28.449871046672826,
                lat: -9.164918308146085
            },
            {
                lng: 28.734866570762502,
                lat: -8.526559340044578
            },
            {
                lng: 29.00291222506047,
                lat: -8.407031752153472
            },
            {
                lng: 30.346086053190817,
                lat: -8.238256524288218
            },
            {
                lng: 30.740009731422095,
                lat: -8.34000593035372
            }
        ]
    },
    MW: {
        name: 'Malawi',
        type: 'Polygon',
        coordinates: [
            {
                lng: 32.75937544122132,
                lat: -9.23059905358906
            },
            {
                lng: 33.73972,
                lat: -9.41715
            },
            {
                lng: 33.940837724096525,
                lat: -9.693673841980285
            },
            {
                lng: 34.28,
                lat: -10.16
            },
            {
                lng: 34.55998904799935,
                lat: -11.520020033415925
            },
            {
                lng: 34.28000613784198,
                lat: -12.280025323132506
            },
            {
                lng: 34.55998904799935,
                lat: -13.579997653866876
            },
            {
                lng: 34.907151320136165,
                lat: -13.565424899960568
            },
            {
                lng: 35.26795617039801,
                lat: -13.887834161029566
            },
            {
                lng: 35.68684533055594,
                lat: -14.611045830954332
            },
            {
                lng: 35.77190473810836,
                lat: -15.896858819240727
            },
            {
                lng: 35.339062941231646,
                lat: -16.10744028083011
            },
            {
                lng: 35.033810255683534,
                lat: -16.801299737213093
            },
            {
                lng: 34.38129194513405,
                lat: -16.183559665596043
            },
            {
                lng: 34.307291294092096,
                lat: -15.478641452702597
            },
            {
                lng: 34.51766604995231,
                lat: -15.013708591372612
            },
            {
                lng: 34.45963341648854,
                lat: -14.613009535381423
            },
            {
                lng: 34.064825473778626,
                lat: -14.35995004644812
            },
            {
                lng: 33.789700148256685,
                lat: -14.45183074306307
            },
            {
                lng: 33.214024692525214,
                lat: -13.971860039936153
            },
            {
                lng: 32.68816531752313,
                lat: -13.712857761289277
            },
            {
                lng: 32.991764357237884,
                lat: -12.783870537978274
            },
            {
                lng: 33.306422153463075,
                lat: -12.435778090060218
            },
            {
                lng: 33.114289178201915,
                lat: -11.607198174692314
            },
            {
                lng: 33.315310499817286,
                lat: -10.796549981329697
            },
            {
                lng: 33.48568769708359,
                lat: -10.525558770391115
            },
            {
                lng: 33.2313879737753,
                lat: -9.6767216935648
            },
            {
                lng: 32.75937544122132,
                lat: -9.23059905358906
            }
        ]
    },
    MZ: {
        name: 'Mozambique',
        type: 'Polygon',
        coordinates: [
            {
                lng: 34.55998904799935,
                lat: -11.520020033415925
            },
            {
                lng: 35.31239790216904,
                lat: -11.439146416879147
            },
            {
                lng: 36.51408165868426,
                lat: -11.720938002166735
            },
            {
                lng: 36.775150994622805,
                lat: -11.594537448780805
            },
            {
                lng: 37.47129,
                lat: -11.56876
            },
            {
                lng: 37.82764,
                lat: -11.26879
            },
            {
                lng: 38.42755659358775,
                lat: -11.285202325081656
            },
            {
                lng: 39.521,
                lat: -10.89688
            },
            {
                lng: 40.31659,
                lat: -10.317099999999868
            },
            {
                lng: 40.316586229110854,
                lat: -10.317097752817492
            },
            {
                lng: 40.31658857601719,
                lat: -10.317096042525698
            },
            {
                lng: 40.47838748552303,
                lat: -10.765440769089993
            },
            {
                lng: 40.437253045418686,
                lat: -11.761710707245015
            },
            {
                lng: 40.56081139502857,
                lat: -12.639176527561027
            },
            {
                lng: 40.59962039567975,
                lat: -14.201975192931862
            },
            {
                lng: 40.775475294768995,
                lat: -14.691764418194241
            },
            {
                lng: 40.4772506040126,
                lat: -15.406294447493972
            },
            {
                lng: 40.08926395036522,
                lat: -16.10077402106446
            },
            {
                lng: 39.45255862809705,
                lat: -16.72089120856694
            },
            {
                lng: 38.53835086442152,
                lat: -17.101023044505958
            },
            {
                lng: 37.41113284683888,
                lat: -17.586368096591237
            },
            {
                lng: 36.28127933120936,
                lat: -18.65968759529345
            },
            {
                lng: 35.89649661636406,
                lat: -18.842260430580637
            },
            {
                lng: 35.198399692533144,
                lat: -19.552811374593894
            },
            {
                lng: 34.78638349787005,
                lat: -19.784011732667736
            },
            {
                lng: 34.70189253107284,
                lat: -20.49704314543101
            },
            {
                lng: 35.176127150215365,
                lat: -21.25436126066841
            },
            {
                lng: 35.37342776870574,
                lat: -21.840837090748877
            },
            {
                lng: 35.385848253705404,
                lat: -22.14
            },
            {
                lng: 35.562545536369086,
                lat: -22.09
            },
            {
                lng: 35.533934767404304,
                lat: -23.070787855727758
            },
            {
                lng: 35.37177412287238,
                lat: -23.5353589820317
            },
            {
                lng: 35.60747033055563,
                lat: -23.706563002214683
            },
            {
                lng: 35.45874555841962,
                lat: -24.12260995859655
            },
            {
                lng: 35.04073489761066,
                lat: -24.478350518493805
            },
            {
                lng: 34.21582400893547,
                lat: -24.81631438568266
            },
            {
                lng: 33.01321007663901,
                lat: -25.357573337507738
            },
            {
                lng: 32.574632195777866,
                lat: -25.727318210556092
            },
            {
                lng: 32.66036339695009,
                lat: -26.148584486599447
            },
            {
                lng: 32.91595503106569,
                lat: -26.215867201443466
            },
            {
                lng: 32.830120477028885,
                lat: -26.742191664336197
            },
            {
                lng: 32.07166548028107,
                lat: -26.73382008230491
            },
            {
                lng: 31.98577924981197,
                lat: -26.291779880480227
            },
            {
                lng: 31.83777794772806,
                lat: -25.84333180105135
            },
            {
                lng: 31.75240848158188,
                lat: -25.484283949487413
            },
            {
                lng: 31.930588820124253,
                lat: -24.36941659922254
            },
            {
                lng: 31.670397983534656,
                lat: -23.658969008073864
            },
            {
                lng: 31.19140913262129,
                lat: -22.2515096981724
            },
            {
                lng: 32.244988234188014,
                lat: -21.116488539313693
            },
            {
                lng: 32.50869306817344,
                lat: -20.395292250248307
            },
            {
                lng: 32.65974327976258,
                lat: -20.304290052982317
            },
            {
                lng: 32.772707960752626,
                lat: -19.715592136313298
            },
            {
                lng: 32.61199425632489,
                lat: -19.419382826416275
            },
            {
                lng: 32.65488569512715,
                lat: -18.672089939043495
            },
            {
                lng: 32.84986087416439,
                lat: -17.97905730557718
            },
            {
                lng: 32.847638787575846,
                lat: -16.713398125884616
            },
            {
                lng: 32.32823896661023,
                lat: -16.392074069893752
            },
            {
                lng: 31.8520406430406,
                lat: -16.319417006091378
            },
            {
                lng: 31.636498243951195,
                lat: -16.071990248277885
            },
            {
                lng: 31.17306399915768,
                lat: -15.860943698797874
            },
            {
                lng: 30.338954705534544,
                lat: -15.880839125230246
            },
            {
                lng: 30.27425581230511,
                lat: -15.507786960515213
            },
            {
                lng: 30.17948123548183,
                lat: -14.796099134991529
            },
            {
                lng: 33.214024692525214,
                lat: -13.971860039936153
            },
            {
                lng: 33.789700148256685,
                lat: -14.45183074306307
            },
            {
                lng: 34.064825473778626,
                lat: -14.35995004644812
            },
            {
                lng: 34.45963341648854,
                lat: -14.613009535381423
            },
            {
                lng: 34.51766604995231,
                lat: -15.013708591372612
            },
            {
                lng: 34.307291294092096,
                lat: -15.478641452702597
            },
            {
                lng: 34.38129194513405,
                lat: -16.183559665596043
            },
            {
                lng: 35.033810255683534,
                lat: -16.801299737213093
            },
            {
                lng: 35.339062941231646,
                lat: -16.10744028083011
            },
            {
                lng: 35.77190473810836,
                lat: -15.896858819240727
            },
            {
                lng: 35.68684533055594,
                lat: -14.611045830954332
            },
            {
                lng: 35.26795617039801,
                lat: -13.887834161029566
            },
            {
                lng: 34.907151320136165,
                lat: -13.565424899960568
            },
            {
                lng: 34.55998904799935,
                lat: -13.579997653866876
            },
            {
                lng: 34.28000613784198,
                lat: -12.280025323132506
            },
            {
                lng: 34.55998904799935,
                lat: -11.520020033415925
            }
        ]
    },
    SZ: {
        name: 'eSwatini',
        type: 'Polygon',
        coordinates: [
            {
                lng: 32.07166548028107,
                lat: -26.73382008230491
            },
            {
                lng: 31.86806033705108,
                lat: -27.177927341421277
            },
            {
                lng: 31.28277306491333,
                lat: -27.285879408478998
            },
            {
                lng: 30.68596194837448,
                lat: -26.743845310169533
            },
            {
                lng: 30.67660851412964,
                lat: -26.398078301704608
            },
            {
                lng: 30.949666782359913,
                lat: -26.02264902110415
            },
            {
                lng: 31.04407962415715,
                lat: -25.731452325139443
            },
            {
                lng: 31.333157586397903,
                lat: -25.66019052500895
            },
            {
                lng: 31.83777794772806,
                lat: -25.84333180105135
            },
            {
                lng: 31.98577924981197,
                lat: -26.291779880480227
            },
            {
                lng: 32.07166548028107,
                lat: -26.73382008230491
            }
        ]
    },
    AO: {
        name: 'Angola',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: 12.995517205465177,
                    lat: -4.781103203961884
                },
                {
                    lng: 12.63161176926579,
                    lat: -4.991271254092936
                },
                {
                    lng: 12.468004184629736,
                    lat: -5.248361504745005
                },
                {
                    lng: 12.436688266660868,
                    lat: -5.684303887559246
                },
                {
                    lng: 12.182336866920252,
                    lat: -5.789930515163839
                },
                {
                    lng: 11.91496300624209,
                    lat: -5.037986748884791
                },
                {
                    lng: 12.318607618873926,
                    lat: -4.606230157086188
                },
                {
                    lng: 12.620759718484493,
                    lat: -4.438023369976136
                },
                {
                    lng: 12.995517205465177,
                    lat: -4.781103203961884
                }
            ],
            [
                {
                    lng: 12.32243167486351,
                    lat: -6.10009246177966
                },
                {
                    lng: 12.735171339578699,
                    lat: -5.965682061388499
                },
                {
                    lng: 13.024869419006961,
                    lat: -5.984388929878158
                },
                {
                    lng: 13.375597364971895,
                    lat: -5.864241224799549
                },
                {
                    lng: 16.326528354567046,
                    lat: -5.877470391466268
                },
                {
                    lng: 16.573179965896145,
                    lat: -6.622644545115087
                },
                {
                    lng: 16.8601908708452,
                    lat: -7.222297865429987
                },
                {
                    lng: 17.08999596524717,
                    lat: -7.545688978712526
                },
                {
                    lng: 17.472970004962235,
                    lat: -8.0685511206417
                },
                {
                    lng: 18.13422163256905,
                    lat: -7.987677504104923
                },
                {
                    lng: 18.464175652752687,
                    lat: -7.847014255406443
                },
                {
                    lng: 19.01675174324967,
                    lat: -7.988245944860132
                },
                {
                    lng: 19.16661339689611,
                    lat: -7.738183688999754
                },
                {
                    lng: 19.41750247567316,
                    lat: -7.155428562044299
                },
                {
                    lng: 20.037723016040218,
                    lat: -7.116361179231646
                },
                {
                    lng: 20.091621534920648,
                    lat: -6.943090101756994
                },
                {
                    lng: 20.6018229509383,
                    lat: -6.939317722199682
                },
                {
                    lng: 20.5147481625265,
                    lat: -7.299605808138629
                },
                {
                    lng: 21.7281107927397,
                    lat: -7.290872491081302
                },
                {
                    lng: 21.74645592620331,
                    lat: -7.920084730667149
                },
                {
                    lng: 21.949130893652043,
                    lat: -8.305900974158277
                },
                {
                    lng: 21.8018013851879,
                    lat: -8.90870655684298
                },
                {
                    lng: 21.875181919042348,
                    lat: -9.523707777548566
                },
                {
                    lng: 22.208753289486395,
                    lat: -9.894796237836509
                },
                {
                    lng: 22.155268182064308,
                    lat: -11.084801120653772
                },
                {
                    lng: 22.402798292742375,
                    lat: -10.993075453335692
                },
                {
                    lng: 22.83734541188474,
                    lat: -11.01762175867433
                },
                {
                    lng: 23.45679080576744,
                    lat: -10.867863457892483
                },
                {
                    lng: 23.912215203555718,
                    lat: -10.926826267137514
                },
                {
                    lng: 24.01789350759259,
                    lat: -11.23729827234709
                },
                {
                    lng: 23.904153680118185,
                    lat: -11.722281589406322
                },
                {
                    lng: 24.079905226342845,
                    lat: -12.191296888887365
                },
                {
                    lng: 23.930922072045377,
                    lat: -12.565847670138856
                },
                {
                    lng: 24.016136508894675,
                    lat: -12.911046237848574
                },
                {
                    lng: 21.933886346125917,
                    lat: -12.898437188369359
                },
                {
                    lng: 21.887842644953874,
                    lat: -16.08031015387688
                },
                {
                    lng: 22.56247846852426,
                    lat: -16.898451429921813
                },
                {
                    lng: 23.215048455506064,
                    lat: -17.523116143465984
                },
                {
                    lng: 21.377176141045567,
                    lat: -17.930636488519696
                },
                {
                    lng: 18.956186964603603,
                    lat: -17.789094740472258
                },
                {
                    lng: 18.263309360434164,
                    lat: -17.309950860262006
                },
                {
                    lng: 14.209706658595024,
                    lat: -17.35310068122572
                },
                {
                    lng: 14.05850141770901,
                    lat: -17.423380629142663
                },
                {
                    lng: 13.462362094789967,
                    lat: -16.971211846588773
                },
                {
                    lng: 12.814081251688407,
                    lat: -16.94134286872407
                },
                {
                    lng: 12.215461460019355,
                    lat: -17.111668389558083
                },
                {
                    lng: 11.734198846085121,
                    lat: -17.301889336824473
                },
                {
                    lng: 11.64009606288161,
                    lat: -16.67314218512925
                },
                {
                    lng: 11.778537224991537,
                    lat: -15.793816013250735
                },
                {
                    lng: 12.12358076340439,
                    lat: -14.878316338767904
                },
                {
                    lng: 12.175618930722294,
                    lat: -14.449143568583892
                },
                {
                    lng: 12.50009524908299,
                    lat: -13.54769988368445
                },
                {
                    lng: 12.738478631245385,
                    lat: -13.137905775609902
                },
                {
                    lng: 13.312913852601866,
                    lat: -12.483630466362492
                },
                {
                    lng: 13.6337211442698,
                    lat: -12.03864470789717
                },
                {
                    lng: 13.738727654686897,
                    lat: -11.297863050993165
                },
                {
                    lng: 13.686379428775238,
                    lat: -10.73107594161589
                },
                {
                    lng: 13.387327915102162,
                    lat: -10.373578383020714
                },
                {
                    lng: 13.120987583069846,
                    lat: -9.766897067914122
                },
                {
                    lng: 12.875369500386569,
                    lat: -9.166933689005468
                },
                {
                    lng: 12.92906131353783,
                    lat: -8.959091078327553
                },
                {
                    lng: 13.236432732809874,
                    lat: -8.562629489784307
                },
                {
                    lng: 12.93304039882429,
                    lat: -7.596538588087733
                },
                {
                    lng: 12.728298374083892,
                    lat: -6.927122084178805
                },
                {
                    lng: 12.227347039446471,
                    lat: -6.294447523629394
                },
                {
                    lng: 12.32243167486351,
                    lat: -6.10009246177966
                }
            ]
        ]
    },
    BI: {
        name: 'Burundi',
        type: 'Polygon',
        coordinates: [
            {
                lng: 30.469673645761223,
                lat: -2.41385475710134
            },
            {
                lng: 30.52766,
                lat: -2.80762
            },
            {
                lng: 30.74301,
                lat: -3.03431
            },
            {
                lng: 30.75224,
                lat: -3.35931
            },
            {
                lng: 30.50554,
                lat: -3.56858
            },
            {
                lng: 30.11632,
                lat: -4.09012
            },
            {
                lng: 29.753512404099865,
                lat: -4.452389418153302
            },
            {
                lng: 29.339997592900346,
                lat: -4.499983412294092
            },
            {
                lng: 29.276383904749053,
                lat: -3.293907159034063
            },
            {
                lng: 29.024926385216787,
                lat: -2.839257907730158
            },
            {
                lng: 29.632176141078588,
                lat: -2.917857761246097
            },
            {
                lng: 29.93835900240794,
                lat: -2.348486830254238
            },
            {
                lng: 30.469673645761223,
                lat: -2.41385475710134
            }
        ]
    },
    IL: {
        name: 'Israel',
        type: 'Polygon',
        coordinates: [
            {
                lng: 35.71991824722275,
                lat: 32.709192409794866
            },
            {
                lng: 35.54566531753454,
                lat: 32.393992011030576
            },
            {
                lng: 35.183930291491436,
                lat: 32.53251068778894
            },
            {
                lng: 34.97464074070933,
                lat: 31.866582343059722
            },
            {
                lng: 35.22589155451243,
                lat: 31.754341132121766
            },
            {
                lng: 34.970506626125996,
                lat: 31.61677846936081
            },
            {
                lng: 34.92740848159457,
                lat: 31.353435370401414
            },
            {
                lng: 35.397560662586045,
                lat: 31.489086005167582
            },
            {
                lng: 35.420918409981965,
                lat: 31.100065822874356
            },
            {
                lng: 34.92260257339143,
                lat: 29.501326198844524
            },
            {
                lng: 34.823243288783814,
                lat: 29.76108076171822
            },
            {
                lng: 34.26544,
                lat: 31.21936
            },
            {
                lng: 34.26543474464621,
                lat: 31.21935730952032
            },
            {
                lng: 34.26543338393569,
                lat: 31.219360866820153
            },
            {
                lng: 34.55637169773891,
                lat: 31.548823960896996
            },
            {
                lng: 34.48810713068136,
                lat: 31.60553884533732
            },
            {
                lng: 34.75258711115117,
                lat: 32.07292633720117
            },
            {
                lng: 34.95541710789678,
                lat: 32.82737641044638
            },
            {
                lng: 35.098457472480675,
                lat: 33.080539252244265
            },
            {
                lng: 35.126052687324545,
                lat: 33.09090037691878
            },
            {
                lng: 35.460709262846706,
                lat: 33.08904002535628
            },
            {
                lng: 35.55279666519081,
                lat: 33.26427480725802
            },
            {
                lng: 35.82110070165024,
                lat: 33.2774264592763
            },
            {
                lng: 35.836396925608625,
                lat: 32.86812327730851
            },
            {
                lng: 35.70079796727475,
                lat: 32.71601369885738
            },
            {
                lng: 35.71991824722275,
                lat: 32.709192409794866
            }
        ]
    },
    LB: {
        name: 'Lebanon',
        type: 'Polygon',
        coordinates: [
            {
                lng: 35.82110070165024,
                lat: 33.2774264592763
            },
            {
                lng: 35.55279666519081,
                lat: 33.26427480725802
            },
            {
                lng: 35.460709262846706,
                lat: 33.08904002535628
            },
            {
                lng: 35.126052687324545,
                lat: 33.09090037691878
            },
            {
                lng: 35.48220665868013,
                lat: 33.90545014091944
            },
            {
                lng: 35.9795923194894,
                lat: 34.61005829521913
            },
            {
                lng: 35.99840254084364,
                lat: 34.644914048800004
            },
            {
                lng: 36.4481942075121,
                lat: 34.59393524834407
            },
            {
                lng: 36.61175011571589,
                lat: 34.20178864189718
            },
            {
                lng: 36.066460402172055,
                lat: 33.82491242119255
            },
            {
                lng: 35.82110070165024,
                lat: 33.2774264592763
            }
        ]
    },
    MG: {
        name: 'Madagascar',
        type: 'Polygon',
        coordinates: [
            {
                lng: 49.54351891459575,
                lat: -12.469832858940554
            },
            {
                lng: 49.808980747279094,
                lat: -12.895284925999555
            },
            {
                lng: 50.056510857957164,
                lat: -13.555761407121985
            },
            {
                lng: 50.21743126811407,
                lat: -14.758788750876796
            },
            {
                lng: 50.47653689962553,
                lat: -15.226512139550543
            },
            {
                lng: 50.377111443895956,
                lat: -15.706069431219127
            },
            {
                lng: 50.20027469259318,
                lat: -16.000263360256767
            },
            {
                lng: 49.86060550313868,
                lat: -15.414252618066918
            },
            {
                lng: 49.67260664246086,
                lat: -15.710203545802479
            },
            {
                lng: 49.863344354050156,
                lat: -16.451036879138776
            },
            {
                lng: 49.77456424337271,
                lat: -16.8750420060936
            },
            {
                lng: 49.49861209493412,
                lat: -17.106035658438273
            },
            {
                lng: 49.435618523970305,
                lat: -17.953064060134366
            },
            {
                lng: 49.041792433473944,
                lat: -19.118781019774445
            },
            {
                lng: 48.54854088724801,
                lat: -20.496888116134127
            },
            {
                lng: 47.93074913919867,
                lat: -22.391501153251085
            },
            {
                lng: 47.54772342305131,
                lat: -23.781958916928517
            },
            {
                lng: 47.095761346226595,
                lat: -24.941629733990453
            },
            {
                lng: 46.282477654817086,
                lat: -25.178462823184105
            },
            {
                lng: 45.40950768411045,
                lat: -25.60143442149309
            },
            {
                lng: 44.833573846217554,
                lat: -25.34610116953894
            },
            {
                lng: 44.03972049334976,
                lat: -24.988345228782308
            },
            {
                lng: 43.76376834491117,
                lat: -24.46067717864999
            },
            {
                lng: 43.697777540874455,
                lat: -23.574116306250602
            },
            {
                lng: 43.345654331237625,
                lat: -22.776903985283873
            },
            {
                lng: 43.254187046081,
                lat: -22.057413018484123
            },
            {
                lng: 43.43329756040464,
                lat: -21.33647511158019
            },
            {
                lng: 43.893682895692926,
                lat: -21.16330738697013
            },
            {
                lng: 43.896370070172104,
                lat: -20.830459486578174
            },
            {
                lng: 44.37432539243966,
                lat: -20.07236622485639
            },
            {
                lng: 44.46439741392439,
                lat: -19.435454196859048
            },
            {
                lng: 44.23242190936617,
                lat: -18.961994724200906
            },
            {
                lng: 44.042976108584156,
                lat: -18.33138722094317
            },
            {
                lng: 43.96308434426091,
                lat: -17.409944756746782
            },
            {
                lng: 44.31246870298628,
                lat: -16.850495700754955
            },
            {
                lng: 44.4465173683514,
                lat: -16.216219170804507
            },
            {
                lng: 44.94493655780653,
                lat: -16.1793738745804
            },
            {
                lng: 45.50273196796499,
                lat: -15.97437346767854
            },
            {
                lng: 45.87299360533626,
                lat: -15.793454278224687
            },
            {
                lng: 46.31224327981721,
                lat: -15.780018405828798
            },
            {
                lng: 46.882182651564285,
                lat: -15.210182386946315
            },
            {
                lng: 47.70512983581236,
                lat: -14.594302666891764
            },
            {
                lng: 48.005214878131255,
                lat: -14.091232598530375
            },
            {
                lng: 47.869047479042166,
                lat: -13.663868503476586
            },
            {
                lng: 48.29382775248138,
                lat: -13.784067884987486
            },
            {
                lng: 48.84506025573879,
                lat: -13.089174899958664
            },
            {
                lng: 48.86350874206698,
                lat: -12.48786793381042
            },
            {
                lng: 49.194651320193316,
                lat: -12.04055673589197
            },
            {
                lng: 49.54351891459575,
                lat: -12.469832858940554
            }
        ]
    },
    PS: {
        name: 'Palestine (West Bank and Gaza)',
        type: 'Polygon',
        coordinates: [
            {
                lng: 35.397560662586045,
                lat: 31.489086005167582
            },
            {
                lng: 34.92740848159457,
                lat: 31.353435370401414
            },
            {
                lng: 34.970506626125996,
                lat: 31.61677846936081
            },
            {
                lng: 35.22589155451243,
                lat: 31.754341132121766
            },
            {
                lng: 34.97464074070933,
                lat: 31.866582343059722
            },
            {
                lng: 35.183930291491436,
                lat: 32.53251068778894
            },
            {
                lng: 35.54566531753454,
                lat: 32.393992011030576
            },
            {
                lng: 35.5452519060762,
                lat: 31.78250478772084
            },
            {
                lng: 35.397560662586045,
                lat: 31.489086005167582
            }
        ]
    },
    GM: {
        name: 'Gambia, The',
        type: 'Polygon',
        coordinates: [
            {
                lng: -16.71372880702347,
                lat: 13.594958604379855
            },
            {
                lng: -15.624596320039942,
                lat: 13.62358734786956
            },
            {
                lng: -15.39877031092446,
                lat: 13.86036876063092
            },
            {
                lng: -15.08173539881382,
                lat: 13.876491807505985
            },
            {
                lng: -14.687030808968487,
                lat: 13.630356960499784
            },
            {
                lng: -14.376713833055788,
                lat: 13.625680243377374
            },
            {
                lng: -14.046992356817482,
                lat: 13.79406789800045
            },
            {
                lng: -13.844963344772408,
                lat: 13.505041612192002
            },
            {
                lng: -14.277701788784555,
                lat: 13.280585028532244
            },
            {
                lng: -14.712197231494628,
                lat: 13.298206691943777
            },
            {
                lng: -15.141163295949468,
                lat: 13.509511623585238
            },
            {
                lng: -15.511812506562933,
                lat: 13.278569647672867
            },
            {
                lng: -15.691000535534995,
                lat: 13.270353094938457
            },
            {
                lng: -15.931295945692211,
                lat: 13.130284125211332
            },
            {
                lng: -16.841524624081273,
                lat: 13.15139394780256
            },
            {
                lng: -16.71372880702347,
                lat: 13.594958604379855
            }
        ]
    },
    TN: {
        name: 'Tunisia',
        type: 'Polygon',
        coordinates: [
            {
                lng: 9.482139926805274,
                lat: 30.307556057246188
            },
            {
                lng: 9.05560265466815,
                lat: 32.10269196220129
            },
            {
                lng: 8.439102817426118,
                lat: 32.50628489840082
            },
            {
                lng: 8.430472853233368,
                lat: 32.74833730725595
            },
            {
                lng: 7.612641635782182,
                lat: 33.34411489514896
            },
            {
                lng: 7.524481642292244,
                lat: 34.09737641045146
            },
            {
                lng: 8.140981479534304,
                lat: 34.65514598239379
            },
            {
                lng: 8.376367628623768,
                lat: 35.479876003555944
            },
            {
                lng: 8.217824334352315,
                lat: 36.433176988260286
            },
            {
                lng: 8.420964389691676,
                lat: 36.94642731378316
            },
            {
                lng: 9.509993523810607,
                lat: 37.349994411766545
            },
            {
                lng: 10.210002475636317,
                lat: 37.230001735984814
            },
            {
                lng: 10.180650262094531,
                lat: 36.724037787415085
            },
            {
                lng: 11.02886722173335,
                lat: 37.09210317641396
            },
            {
                lng: 11.100025668999251,
                lat: 36.899996039368915
            },
            {
                lng: 10.600004510143094,
                lat: 36.410000108377375
            },
            {
                lng: 10.593286573945138,
                lat: 35.94744436293281
            },
            {
                lng: 10.939518670300687,
                lat: 35.698984076473494
            },
            {
                lng: 10.807847120821009,
                lat: 34.83350718844919
            },
            {
                lng: 10.149592726287125,
                lat: 34.33077301689771
            },
            {
                lng: 10.339658644256616,
                lat: 33.78574168551532
            },
            {
                lng: 10.856836378633687,
                lat: 33.76874013929128
            },
            {
                lng: 11.108500603895122,
                lat: 33.293342800422195
            },
            {
                lng: 11.488787469131012,
                lat: 33.13699575452324
            },
            {
                lng: 11.432253452203696,
                lat: 32.368903103152874
            },
            {
                lng: 10.944789666394456,
                lat: 32.081814683555365
            },
            {
                lng: 10.636901482799487,
                lat: 31.761420803345757
            },
            {
                lng: 9.950225050505082,
                lat: 31.376069647745258
            },
            {
                lng: 10.056575148161699,
                lat: 30.961831366493527
            },
            {
                lng: 9.970017124072854,
                lat: 30.53932485607524
            },
            {
                lng: 9.482139926805274,
                lat: 30.307556057246188
            }
        ]
    },
    DZ: {
        name: 'Algeria',
        type: 'Polygon',
        coordinates: [
            {
                lng: -8.684399786809053,
                lat: 27.395744126896005
            },
            {
                lng: -8.665124477564191,
                lat: 27.589479071558227
            },
            {
                lng: -8.665589565454809,
                lat: 27.656425889592356
            },
            {
                lng: -8.674116176782974,
                lat: 28.84128896739658
            },
            {
                lng: -7.059227667661958,
                lat: 29.5792284205246
            },
            {
                lng: -6.060632290053774,
                lat: 29.731699734001694
            },
            {
                lng: -5.242129278982787,
                lat: 30.00044302013559
            },
            {
                lng: -4.859646165374471,
                lat: 30.501187649043846
            },
            {
                lng: -3.690441046554724,
                lat: 30.896951605751156
            },
            {
                lng: -3.647497931320146,
                lat: 31.637294012980675
            },
            {
                lng: -3.068980271812648,
                lat: 31.724497992473218
            },
            {
                lng: -2.616604783529567,
                lat: 32.09434621838619
            },
            {
                lng: -1.30789913573787,
                lat: 32.2628889023061
            },
            {
                lng: -1.124551153966308,
                lat: 32.65152151135713
            },
            {
                lng: -1.388049282222596,
                lat: 32.86401500094138
            },
            {
                lng: -1.733454555661467,
                lat: 33.91971283623212
            },
            {
                lng: -1.792985805661715,
                lat: 34.527918606091305
            },
            {
                lng: -2.169913702798624,
                lat: 35.16839630791668
            },
            {
                lng: -1.208602871089056,
                lat: 35.7148487411871
            },
            {
                lng: -0.127454392894606,
                lat: 35.888662421200806
            },
            {
                lng: 0.503876580415209,
                lat: 36.30127289483528
            },
            {
                lng: 1.466918572606545,
                lat: 36.605647081034405
            },
            {
                lng: 3.161698846050825,
                lat: 36.78390493422522
            },
            {
                lng: 4.81575809084913,
                lat: 36.86503693292346
            },
            {
                lng: 5.320120070017794,
                lat: 36.71651886651662
            },
            {
                lng: 6.261819695672613,
                lat: 37.11065501560674
            },
            {
                lng: 7.330384962603971,
                lat: 37.11838064223437
            },
            {
                lng: 7.737078484741005,
                lat: 36.885707505840216
            },
            {
                lng: 8.420964389691676,
                lat: 36.94642731378316
            },
            {
                lng: 8.217824334352315,
                lat: 36.433176988260286
            },
            {
                lng: 8.376367628623768,
                lat: 35.479876003555944
            },
            {
                lng: 8.140981479534304,
                lat: 34.65514598239379
            },
            {
                lng: 7.524481642292244,
                lat: 34.09737641045146
            },
            {
                lng: 7.612641635782182,
                lat: 33.34411489514896
            },
            {
                lng: 8.430472853233368,
                lat: 32.74833730725595
            },
            {
                lng: 8.439102817426118,
                lat: 32.50628489840082
            },
            {
                lng: 9.05560265466815,
                lat: 32.10269196220129
            },
            {
                lng: 9.482139926805274,
                lat: 30.307556057246188
            },
            {
                lng: 9.805634392952356,
                lat: 29.424638373323376
            },
            {
                lng: 9.859997999723447,
                lat: 28.959989732371014
            },
            {
                lng: 9.683884718472768,
                lat: 28.1441738957792
            },
            {
                lng: 9.756128370816782,
                lat: 27.688258571884205
            },
            {
                lng: 9.629056023811074,
                lat: 27.14095347748092
            },
            {
                lng: 9.716285841519664,
                lat: 26.512206325785655
            },
            {
                lng: 9.319410841518163,
                lat: 26.094324856057455
            },
            {
                lng: 9.910692579801776,
                lat: 25.365454616796796
            },
            {
                lng: 9.94826134607797,
                lat: 24.936953640232517
            },
            {
                lng: 10.303846876678362,
                lat: 24.379313259370917
            },
            {
                lng: 10.771363559622927,
                lat: 24.56253205006175
            },
            {
                lng: 11.560669386449005,
                lat: 24.097909247325518
            },
            {
                lng: 11.999505649471613,
                lat: 23.47166840259645
            },
            {
                lng: 8.572893100629784,
                lat: 21.565660712159143
            },
            {
                lng: 5.677565952180686,
                lat: 19.601206976799716
            },
            {
                lng: 4.267419467800039,
                lat: 19.155265204337
            },
            {
                lng: 3.158133172222705,
                lat: 19.057364203360038
            },
            {
                lng: 3.1466610042539,
                lat: 19.693578599521445
            },
            {
                lng: 2.683588494486429,
                lat: 19.856230170160117
            },
            {
                lng: 2.06099083823392,
                lat: 20.142233384679486
            },
            {
                lng: 1.823227573259032,
                lat: 20.610809434486043
            },
            {
                lng: -1.550054897457613,
                lat: 22.792665920497384
            },
            {
                lng: -4.923337368174231,
                lat: 24.974574082941
            },
            {
                lng: -8.684399786809053,
                lat: 27.395744126896005
            }
        ]
    },
    JO: {
        name: 'Jordan',
        type: 'Polygon',
        coordinates: [
            {
                lng: 35.54566531753454,
                lat: 32.393992011030576
            },
            {
                lng: 35.71991824722275,
                lat: 32.709192409794866
            },
            {
                lng: 36.834062127435544,
                lat: 32.312937526980775
            },
            {
                lng: 38.792340529136084,
                lat: 33.378686428352225
            },
            {
                lng: 39.19546837744497,
                lat: 32.16100881604267
            },
            {
                lng: 39.00488569515255,
                lat: 32.01021698661498
            },
            {
                lng: 37.00216556168101,
                lat: 31.508412990844747
            },
            {
                lng: 37.998848911294374,
                lat: 30.508499864213135
            },
            {
                lng: 37.66811974462638,
                lat: 30.3386652694859
            },
            {
                lng: 37.503581984209035,
                lat: 30.003776150018407
            },
            {
                lng: 36.74052778498725,
                lat: 29.86528331147619
            },
            {
                lng: 36.50121422704359,
                lat: 29.505253607698705
            },
            {
                lng: 36.06894087092206,
                lat: 29.197494615184453
            },
            {
                lng: 34.95603722508426,
                lat: 29.356554673778845
            },
            {
                lng: 34.92260257339143,
                lat: 29.501326198844524
            },
            {
                lng: 35.420918409981965,
                lat: 31.100065822874356
            },
            {
                lng: 35.397560662586045,
                lat: 31.489086005167582
            },
            {
                lng: 35.5452519060762,
                lat: 31.78250478772084
            },
            {
                lng: 35.54566531753454,
                lat: 32.393992011030576
            }
        ]
    },
    AE: {
        name: 'United Arab Emirates',
        type: 'Polygon',
        coordinates: [
            {
                lng: 51.57951867046327,
                lat: 24.245497137951105
            },
            {
                lng: 51.757440626844186,
                lat: 24.29407298430547
            },
            {
                lng: 51.79438927593287,
                lat: 24.019826158132506
            },
            {
                lng: 52.57708051942561,
                lat: 24.177439276622707
            },
            {
                lng: 53.404006788960146,
                lat: 24.15131684009917
            },
            {
                lng: 54.00800092958758,
                lat: 24.121757920828216
            },
            {
                lng: 54.69302371604863,
                lat: 24.79789236093509
            },
            {
                lng: 55.43902469261414,
                lat: 25.43914520924494
            },
            {
                lng: 56.07082075381456,
                lat: 26.05546417897398
            },
            {
                lng: 56.261041701080956,
                lat: 25.71460643157677
            },
            {
                lng: 56.396847365144005,
                lat: 24.924732163995486
            },
            {
                lng: 55.88623253766801,
                lat: 24.920830593357447
            },
            {
                lng: 55.804118686756226,
                lat: 24.269604193615265
            },
            {
                lng: 55.98121382022046,
                lat: 24.13054291431783
            },
            {
                lng: 55.52863162620824,
                lat: 23.933604030853502
            },
            {
                lng: 55.525841098864475,
                lat: 23.524869289640932
            },
            {
                lng: 55.234489373602884,
                lat: 23.110992743415324
            },
            {
                lng: 55.208341098863194,
                lat: 22.708329982997046
            },
            {
                lng: 55.006803012924905,
                lat: 22.496947536707136
            },
            {
                lng: 52.000733270074335,
                lat: 23.00115448657894
            },
            {
                lng: 51.61770755392698,
                lat: 24.01421926522883
            },
            {
                lng: 51.57951867046327,
                lat: 24.245497137951105
            }
        ]
    },
    QA: {
        name: 'Qatar',
        type: 'Polygon',
        coordinates: [
            {
                lng: 50.81010827006958,
                lat: 24.754742539971378
            },
            {
                lng: 50.74391076030369,
                lat: 25.482424221289396
            },
            {
                lng: 51.013351678273494,
                lat: 26.006991685484195
            },
            {
                lng: 51.28646162293606,
                lat: 26.11458201751587
            },
            {
                lng: 51.58907881043726,
                lat: 25.80111277923338
            },
            {
                lng: 51.60670047384881,
                lat: 25.21567047779874
            },
            {
                lng: 51.38960778179063,
                lat: 24.62738597258806
            },
            {
                lng: 51.11241539897702,
                lat: 24.556330878186724
            },
            {
                lng: 50.81010827006958,
                lat: 24.754742539971378
            }
        ]
    },
    KW: {
        name: 'Kuwait',
        type: 'Polygon',
        coordinates: [
            {
                lng: 47.974519077349896,
                lat: 29.975819200148504
            },
            {
                lng: 48.18318851094449,
                lat: 29.534476630159762
            },
            {
                lng: 48.09394331237642,
                lat: 29.306299343375002
            },
            {
                lng: 48.416094191283946,
                lat: 28.55200429942667
            },
            {
                lng: 47.708850538937384,
                lat: 28.526062730416143
            },
            {
                lng: 47.45982181172283,
                lat: 29.002519436147224
            },
            {
                lng: 46.568713413281756,
                lat: 29.09902517345229
            },
            {
                lng: 47.30262210469096,
                lat: 30.059069932570722
            },
            {
                lng: 47.974519077349896,
                lat: 29.975819200148504
            }
        ]
    },
    IQ: {
        name: 'Iraq',
        type: 'Polygon',
        coordinates: [
            {
                lng: 39.19546837744497,
                lat: 32.16100881604267
            },
            {
                lng: 38.792340529136084,
                lat: 33.378686428352225
            },
            {
                lng: 41.006158888519934,
                lat: 34.41937226006212
            },
            {
                lng: 41.383965285005814,
                lat: 35.628316555314356
            },
            {
                lng: 41.289707472505455,
                lat: 36.35881460219227
            },
            {
                lng: 41.83706424334096,
                lat: 36.605853786763575
            },
            {
                lng: 42.34959109881177,
                lat: 37.2298725449041
            },
            {
                lng: 42.77912560402183,
                lat: 37.385263576805755
            },
            {
                lng: 43.9422587420473,
                lat: 37.25622752537295
            },
            {
                lng: 44.29345177590287,
                lat: 37.0015143906063
            },
            {
                lng: 44.77267710159504,
                lat: 37.17043692561684
            },
            {
                lng: 45.42061811705321,
                lat: 35.977545884742824
            },
            {
                lng: 46.0763403664048,
                lat: 35.67738332777549
            },
            {
                lng: 46.15178795755094,
                lat: 35.09325877536429
            },
            {
                lng: 45.64845950702809,
                lat: 34.748137722303014
            },
            {
                lng: 45.41669070819904,
                lat: 33.967797756479584
            },
            {
                lng: 46.10936160663932,
                lat: 33.017287299119005
            },
            {
                lng: 47.33466149271191,
                lat: 32.46915538179911
            },
            {
                lng: 47.8492037290421,
                lat: 31.70917593029867
            },
            {
                lng: 47.68528608581227,
                lat: 30.984853217079632
            },
            {
                lng: 48.004698113808324,
                lat: 30.985137437457244
            },
            {
                lng: 48.0145683123761,
                lat: 30.452456773392598
            },
            {
                lng: 48.567971225789755,
                lat: 29.926778265903522
            },
            {
                lng: 47.974519077349896,
                lat: 29.975819200148504
            },
            {
                lng: 47.30262210469096,
                lat: 30.059069932570722
            },
            {
                lng: 46.568713413281756,
                lat: 29.09902517345229
            },
            {
                lng: 44.70949873228474,
                lat: 29.178891099559383
            },
            {
                lng: 41.889980910007836,
                lat: 31.19000865327837
            },
            {
                lng: 40.399994337736246,
                lat: 31.889991766887935
            },
            {
                lng: 39.19546837744497,
                lat: 32.16100881604267
            }
        ]
    },
    OM: {
        name: 'Oman',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: 55.208341098863194,
                    lat: 22.708329982997046
                },
                {
                    lng: 55.234489373602884,
                    lat: 23.110992743415324
                },
                {
                    lng: 55.525841098864475,
                    lat: 23.524869289640932
                },
                {
                    lng: 55.52863162620824,
                    lat: 23.933604030853502
                },
                {
                    lng: 55.98121382022046,
                    lat: 24.13054291431783
                },
                {
                    lng: 55.804118686756226,
                    lat: 24.269604193615265
                },
                {
                    lng: 55.88623253766801,
                    lat: 24.920830593357447
                },
                {
                    lng: 56.396847365144005,
                    lat: 24.924732163995486
                },
                {
                    lng: 56.845140415276006,
                    lat: 24.241673081961505
                },
                {
                    lng: 57.4034525897574,
                    lat: 23.878594468678813
                },
                {
                    lng: 58.13694786970825,
                    lat: 23.74793060962881
                },
                {
                    lng: 58.72921146020542,
                    lat: 23.56566783293536
                },
                {
                    lng: 59.18050174341033,
                    lat: 22.992395331305474
                },
                {
                    lng: 59.45009769067703,
                    lat: 22.6602709009656
                },
                {
                    lng: 59.80806033716286,
                    lat: 22.533611965418217
                },
                {
                    lng: 59.806148309168066,
                    lat: 22.310524807214193
                },
                {
                    lng: 59.442191196536385,
                    lat: 21.714540513592027
                },
                {
                    lng: 59.28240766788991,
                    lat: 21.43388580981485
                },
                {
                    lng: 58.86114139184656,
                    lat: 21.114034532144302
                },
                {
                    lng: 58.48798587426694,
                    lat: 20.428985907467094
                },
                {
                    lng: 58.034318475176605,
                    lat: 20.481437486243337
                },
                {
                    lng: 57.826372511634105,
                    lat: 20.24300242764863
                },
                {
                    lng: 57.665762160070955,
                    lat: 19.73600495043307
                },
                {
                    lng: 57.788700392493325,
                    lat: 19.067570298737678
                },
                {
                    lng: 57.694390903560645,
                    lat: 18.94470958096376
                },
                {
                    lng: 57.234263950433814,
                    lat: 18.947991034414287
                },
                {
                    lng: 56.60965091332193,
                    lat: 18.574267076079465
                },
                {
                    lng: 56.51218916201947,
                    lat: 18.087113348863966
                },
                {
                    lng: 56.28352094912793,
                    lat: 17.876066799383963
                },
                {
                    lng: 55.66149173363064,
                    lat: 17.884128322821496
                },
                {
                    lng: 55.26993940615512,
                    lat: 17.632309068263197
                },
                {
                    lng: 55.27490034365513,
                    lat: 17.22835439703762
                },
                {
                    lng: 54.79100223167404,
                    lat: 16.95069692633338
                },
                {
                    lng: 54.23925296409371,
                    lat: 17.044980577049913
                },
                {
                    lng: 53.570508253804576,
                    lat: 16.707662665264706
                },
                {
                    lng: 53.10857262554751,
                    lat: 16.651051133688952
                },
                {
                    lng: 52.78218427919205,
                    lat: 17.349742336491232
                },
                {
                    lng: 52.00000980002224,
                    lat: 19.000003363516058
                },
                {
                    lng: 54.99998172386236,
                    lat: 19.999994004796108
                },
                {
                    lng: 55.666659376859826,
                    lat: 22.00000112557234
                },
                {
                    lng: 55.208341098863194,
                    lat: 22.708329982997046
                }
            ],
            [
                {
                    lng: 56.261041701080956,
                    lat: 25.71460643157677
                },
                {
                    lng: 56.07082075381456,
                    lat: 26.05546417897398
                },
                {
                    lng: 56.36201744977927,
                    lat: 26.39593435312898
                },
                {
                    lng: 56.48567915225374,
                    lat: 26.309117946878633
                },
                {
                    lng: 56.39142133975335,
                    lat: 25.895990708921246
                },
                {
                    lng: 56.261041701080956,
                    lat: 25.71460643157677
                }
            ]
        ]
    },
    VU: {
        name: 'Vanuatu',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: 167.2168013857696,
                    lat: -15.89184620530842
                },
                {
                    lng: 167.84487674384502,
                    lat: -16.46633310309717
                },
                {
                    lng: 167.51518110582288,
                    lat: -16.59784962327999
                },
                {
                    lng: 167.1800077659778,
                    lat: -16.159995212470946
                },
                {
                    lng: 167.2168013857696,
                    lat: -15.89184620530842
                }
            ],
            [
                {
                    lng: 166.79315799384085,
                    lat: -15.668810723536687
                },
                {
                    lng: 166.6498592470955,
                    lat: -15.392703545801211
                },
                {
                    lng: 166.6291369977464,
                    lat: -14.626497084209605
                },
                {
                    lng: 167.10771243720149,
                    lat: -14.933920179913954
                },
                {
                    lng: 167.27002811103023,
                    lat: -15.740020847234888
                },
                {
                    lng: 167.00120731024793,
                    lat: -15.614602146062516
                },
                {
                    lng: 166.79315799384085,
                    lat: -15.668810723536687
                }
            ]
        ]
    },
    KH: {
        name: 'Cambodia',
        type: 'Polygon',
        coordinates: [
            {
                lng: 102.5849324890267,
                lat: 12.186594956913282
            },
            {
                lng: 102.34809939983302,
                lat: 13.394247341358223
            },
            {
                lng: 102.98842207236163,
                lat: 14.225721136934467
            },
            {
                lng: 104.28141808473661,
                lat: 14.416743068901367
            },
            {
                lng: 105.21877689007889,
                lat: 14.273211778210694
            },
            {
                lng: 106.04394616091552,
                lat: 13.881091009979956
            },
            {
                lng: 106.49637332563088,
                lat: 14.570583807834282
            },
            {
                lng: 107.38272749230109,
                lat: 14.202440904186972
            },
            {
                lng: 107.61454796756243,
                lat: 13.535530707244206
            },
            {
                lng: 107.49140302941089,
                lat: 12.337205918827948
            },
            {
                lng: 105.81052371625313,
                lat: 11.567614650921229
            },
            {
                lng: 106.24967003786946,
                lat: 10.961811835163587
            },
            {
                lng: 105.19991499229235,
                lat: 10.889309800658097
            },
            {
                lng: 104.33433475140347,
                lat: 10.48654368737523
            },
            {
                lng: 103.4972799011397,
                lat: 10.632555446815928
            },
            {
                lng: 103.09068973186726,
                lat: 11.153660590047165
            },
            {
                lng: 102.5849324890267,
                lat: 12.186594956913282
            }
        ]
    },
    TH: {
        name: 'Thailand',
        type: 'Polygon',
        coordinates: [
            {
                lng: 105.21877689007889,
                lat: 14.273211778210694
            },
            {
                lng: 104.28141808473661,
                lat: 14.416743068901367
            },
            {
                lng: 102.98842207236163,
                lat: 14.225721136934467
            },
            {
                lng: 102.34809939983302,
                lat: 13.394247341358223
            },
            {
                lng: 102.5849324890267,
                lat: 12.186594956913282
            },
            {
                lng: 101.68715783081996,
                lat: 12.645740057826572
            },
            {
                lng: 100.83180952352487,
                lat: 12.627084865769206
            },
            {
                lng: 100.9784672383692,
                lat: 13.412721665902566
            },
            {
                lng: 100.09779747925111,
                lat: 13.406856390837433
            },
            {
                lng: 100.01873253784456,
                lat: 12.307001044153354
            },
            {
                lng: 99.47892052612363,
                lat: 10.846366685423547
            },
            {
                lng: 99.15377241414316,
                lat: 9.963061428258555
            },
            {
                lng: 99.22239871622676,
                lat: 9.239255479362427
            },
            {
                lng: 99.87383182169813,
                lat: 9.20786204674512
            },
            {
                lng: 100.27964684448622,
                lat: 8.295152899606052
            },
            {
                lng: 100.45927412313276,
                lat: 7.429572658717177
            },
            {
                lng: 101.01732791545273,
                lat: 6.856868597842478
            },
            {
                lng: 101.62307905477806,
                lat: 6.74062246340192
            },
            {
                lng: 102.14118696493638,
                lat: 6.221636053894628
            },
            {
                lng: 101.81428185425798,
                lat: 5.810808417174242
            },
            {
                lng: 101.15421878459387,
                lat: 5.691384182147715
            },
            {
                lng: 101.07551557821336,
                lat: 6.204867051615921
            },
            {
                lng: 100.25959638875696,
                lat: 6.642824815289543
            },
            {
                lng: 100.0857568705271,
                lat: 6.464489447450291
            },
            {
                lng: 99.69069054565576,
                lat: 6.848212795433597
            },
            {
                lng: 99.51964155476963,
                lat: 7.34345388430276
            },
            {
                lng: 98.9882528015123,
                lat: 7.907993068875328
            },
            {
                lng: 98.503786248776,
                lat: 8.382305202666288
            },
            {
                lng: 98.339661899817,
                lat: 7.794511623562386
            },
            {
                lng: 98.15000939330582,
                lat: 8.350007432483878
            },
            {
                lng: 98.25915001830626,
                lat: 8.973922837759801
            },
            {
                lng: 98.55355065307305,
                lat: 9.932959906448545
            },
            {
                lng: 99.03812055867398,
                lat: 10.960545762572437
            },
            {
                lng: 99.58728600463972,
                lat: 11.892762762901697
            },
            {
                lng: 99.19635379435167,
                lat: 12.80474843998867
            },
            {
                lng: 99.21201175333609,
                lat: 13.269293728076464
            },
            {
                lng: 99.09775516153876,
                lat: 13.827502549693278
            },
            {
                lng: 98.43081912637987,
                lat: 14.622027696180837
            },
            {
                lng: 98.1920740091914,
                lat: 15.123702500870351
            },
            {
                lng: 98.53737592976572,
                lat: 15.308497422746084
            },
            {
                lng: 98.90334842325676,
                lat: 16.17782420497612
            },
            {
                lng: 98.49376102091135,
                lat: 16.83783559820793
            },
            {
                lng: 97.85912275593486,
                lat: 17.567946071843664
            },
            {
                lng: 97.37589643757354,
                lat: 18.445437730375815
            },
            {
                lng: 97.79778283080441,
                lat: 18.627080389881755
            },
            {
                lng: 98.25372399291561,
                lat: 19.708203029860044
            },
            {
                lng: 98.95967573445488,
                lat: 19.752980658440947
            },
            {
                lng: 99.54330936075931,
                lat: 20.186597601802063
            },
            {
                lng: 100.11598758341785,
                lat: 20.417849636308187
            },
            {
                lng: 100.54888105672688,
                lat: 20.109237982661128
            },
            {
                lng: 100.60629357300316,
                lat: 19.508344427971224
            },
            {
                lng: 101.2820146016517,
                lat: 19.462584947176765
            },
            {
                lng: 101.03593143107777,
                lat: 18.408928330961615
            },
            {
                lng: 101.05954756063517,
                lat: 17.51249725999449
            },
            {
                lng: 102.11359175009248,
                lat: 18.109101670804165
            },
            {
                lng: 102.41300499879162,
                lat: 17.932781683824288
            },
            {
                lng: 102.99870568238771,
                lat: 17.9616946476916
            },
            {
                lng: 103.20019209189374,
                lat: 18.309632066312773
            },
            {
                lng: 103.9564766784853,
                lat: 18.24095408779688
            },
            {
                lng: 104.7169470560925,
                lat: 17.42885895433008
            },
            {
                lng: 104.7793205098688,
                lat: 16.44186493577145
            },
            {
                lng: 105.58903852745016,
                lat: 15.570316066952858
            },
            {
                lng: 105.54433841351769,
                lat: 14.723933620660418
            },
            {
                lng: 105.21877689007889,
                lat: 14.273211778210694
            }
        ]
    },
    LA: {
        name: 'Lao PDR',
        type: 'Polygon',
        coordinates: [
            {
                lng: 107.38272749230109,
                lat: 14.202440904186972
            },
            {
                lng: 106.49637332563088,
                lat: 14.570583807834282
            },
            {
                lng: 106.04394616091552,
                lat: 13.881091009979956
            },
            {
                lng: 105.21877689007889,
                lat: 14.273211778210694
            },
            {
                lng: 105.54433841351769,
                lat: 14.723933620660418
            },
            {
                lng: 105.58903852745016,
                lat: 15.570316066952858
            },
            {
                lng: 104.7793205098688,
                lat: 16.44186493577145
            },
            {
                lng: 104.7169470560925,
                lat: 17.42885895433008
            },
            {
                lng: 103.9564766784853,
                lat: 18.24095408779688
            },
            {
                lng: 103.20019209189374,
                lat: 18.309632066312773
            },
            {
                lng: 102.99870568238771,
                lat: 17.9616946476916
            },
            {
                lng: 102.41300499879162,
                lat: 17.932781683824288
            },
            {
                lng: 102.11359175009248,
                lat: 18.109101670804165
            },
            {
                lng: 101.05954756063517,
                lat: 17.51249725999449
            },
            {
                lng: 101.03593143107777,
                lat: 18.408928330961615
            },
            {
                lng: 101.2820146016517,
                lat: 19.462584947176765
            },
            {
                lng: 100.60629357300316,
                lat: 19.508344427971224
            },
            {
                lng: 100.54888105672688,
                lat: 20.109237982661128
            },
            {
                lng: 100.11598758341785,
                lat: 20.417849636308187
            },
            {
                lng: 100.32910119018953,
                lat: 20.786121731036232
            },
            {
                lng: 101.18000532430754,
                lat: 21.436572984294028
            },
            {
                lng: 101.27002566935997,
                lat: 21.201651923095184
            },
            {
                lng: 101.80311974488293,
                lat: 21.17436676684507
            },
            {
                lng: 101.65201785686152,
                lat: 22.318198757409547
            },
            {
                lng: 102.17043582561358,
                lat: 22.464753119389304
            },
            {
                lng: 102.75489627483466,
                lat: 21.675137233969465
            },
            {
                lng: 103.20386111858645,
                lat: 20.76656220141375
            },
            {
                lng: 104.43500044150805,
                lat: 20.75873322192153
            },
            {
                lng: 104.8225736836971,
                lat: 19.886641750563882
            },
            {
                lng: 104.18338789267894,
                lat: 19.62466807706022
            },
            {
                lng: 103.89653201702671,
                lat: 19.265180975821806
            },
            {
                lng: 105.09459842328152,
                lat: 18.66697459561108
            },
            {
                lng: 105.92576216026403,
                lat: 17.48531545660896
            },
            {
                lng: 106.55600792849569,
                lat: 16.604283962464805
            },
            {
                lng: 107.3127059265456,
                lat: 15.90853831630318
            },
            {
                lng: 107.5645251811039,
                lat: 15.20217316330556
            },
            {
                lng: 107.38272749230109,
                lat: 14.202440904186972
            }
        ]
    },
    MM: {
        name: 'Myanmar',
        type: 'Polygon',
        coordinates: [
            {
                lng: 100.11598758341785,
                lat: 20.417849636308187
            },
            {
                lng: 99.54330936075931,
                lat: 20.186597601802063
            },
            {
                lng: 98.95967573445488,
                lat: 19.752980658440947
            },
            {
                lng: 98.25372399291561,
                lat: 19.708203029860044
            },
            {
                lng: 97.79778283080441,
                lat: 18.627080389881755
            },
            {
                lng: 97.37589643757354,
                lat: 18.445437730375815
            },
            {
                lng: 97.85912275593486,
                lat: 17.567946071843664
            },
            {
                lng: 98.49376102091135,
                lat: 16.83783559820793
            },
            {
                lng: 98.90334842325676,
                lat: 16.17782420497612
            },
            {
                lng: 98.53737592976572,
                lat: 15.308497422746084
            },
            {
                lng: 98.1920740091914,
                lat: 15.123702500870351
            },
            {
                lng: 98.43081912637987,
                lat: 14.622027696180837
            },
            {
                lng: 99.09775516153876,
                lat: 13.827502549693278
            },
            {
                lng: 99.21201175333609,
                lat: 13.269293728076464
            },
            {
                lng: 99.19635379435167,
                lat: 12.80474843998867
            },
            {
                lng: 99.58728600463972,
                lat: 11.892762762901697
            },
            {
                lng: 99.03812055867398,
                lat: 10.960545762572437
            },
            {
                lng: 98.55355065307305,
                lat: 9.932959906448545
            },
            {
                lng: 98.45717410684873,
                lat: 10.67526601810515
            },
            {
                lng: 98.76454552612078,
                lat: 11.441291612183749
            },
            {
                lng: 98.42833865762985,
                lat: 12.032986761925685
            },
            {
                lng: 98.50957400919268,
                lat: 13.122377631070677
            },
            {
                lng: 98.1036039571077,
                lat: 13.640459703012851
            },
            {
                lng: 97.77773237507517,
                lat: 14.837285874892642
            },
            {
                lng: 97.59707156778276,
                lat: 16.10056793869977
            },
            {
                lng: 97.1645398294998,
                lat: 16.92873444260934
            },
            {
                lng: 96.505768670643,
                lat: 16.42724050543285
            },
            {
                lng: 95.3693522481124,
                lat: 15.7143899601826
            },
            {
                lng: 94.80840457558412,
                lat: 15.80345429123764
            },
            {
                lng: 94.18880415240454,
                lat: 16.037936102762018
            },
            {
                lng: 94.53348595579135,
                lat: 17.277240301985728
            },
            {
                lng: 94.32481652219676,
                lat: 18.2135139022499
            },
            {
                lng: 93.54098839719364,
                lat: 19.366492621330025
            },
            {
                lng: 93.66325483599621,
                lat: 19.726961574781996
            },
            {
                lng: 93.07827762245219,
                lat: 19.855144965081976
            },
            {
                lng: 92.36855350135562,
                lat: 20.670883287025347
            },
            {
                lng: 92.30323449093868,
                lat: 21.47548533780982
            },
            {
                lng: 92.65225711463799,
                lat: 21.324047552978485
            },
            {
                lng: 92.67272098182556,
                lat: 22.041238918541254
            },
            {
                lng: 93.16612755734837,
                lat: 22.278459580977103
            },
            {
                lng: 93.06029422401463,
                lat: 22.70311066333557
            },
            {
                lng: 93.28632693885928,
                lat: 23.043658352139005
            },
            {
                lng: 93.3251876159428,
                lat: 24.078556423432204
            },
            {
                lng: 94.10674197792507,
                lat: 23.85074087167348
            },
            {
                lng: 94.55265791217164,
                lat: 24.675238348890336
            },
            {
                lng: 94.60324913938538,
                lat: 25.162495428970402
            },
            {
                lng: 95.1551534362626,
                lat: 26.001307277932085
            },
            {
                lng: 95.12476769407496,
                lat: 26.5735720891323
            },
            {
                lng: 96.41936567585097,
                lat: 27.264589341739224
            },
            {
                lng: 97.1339990580153,
                lat: 27.083773505149964
            },
            {
                lng: 97.0519885599681,
                lat: 27.69905894623315
            },
            {
                lng: 97.40256147663614,
                lat: 27.882536119085444
            },
            {
                lng: 97.32711388549004,
                lat: 28.26158274994634
            },
            {
                lng: 97.91198774616944,
                lat: 28.335945136014345
            },
            {
                lng: 98.2462309102333,
                lat: 27.74722138112918
            },
            {
                lng: 98.68269005737046,
                lat: 27.50881216075062
            },
            {
                lng: 98.71209394734451,
                lat: 26.743535874940267
            },
            {
                lng: 98.67183800658916,
                lat: 25.918702500913525
            },
            {
                lng: 97.72460900267914,
                lat: 25.083637193293
            },
            {
                lng: 97.60471967976198,
                lat: 23.897404690033042
            },
            {
                lng: 98.66026248575577,
                lat: 24.063286037689966
            },
            {
                lng: 98.89874922078278,
                lat: 23.14272207284253
            },
            {
                lng: 99.5319922220874,
                lat: 22.94903880461258
            },
            {
                lng: 99.24089887898725,
                lat: 22.11831431730458
            },
            {
                lng: 99.98348921102149,
                lat: 21.7429367131364
            },
            {
                lng: 100.41653771362738,
                lat: 21.558839423096614
            },
            {
                lng: 101.15003299357825,
                lat: 21.849984442629022
            },
            {
                lng: 101.18000532430754,
                lat: 21.436572984294028
            },
            {
                lng: 100.32910119018953,
                lat: 20.786121731036232
            },
            {
                lng: 100.11598758341785,
                lat: 20.417849636308187
            }
        ]
    },
    VN: {
        name: 'Vietnam',
        type: 'Polygon',
        coordinates: [
            {
                lng: 104.33433475140347,
                lat: 10.48654368737523
            },
            {
                lng: 105.19991499229235,
                lat: 10.889309800658097
            },
            {
                lng: 106.24967003786946,
                lat: 10.961811835163587
            },
            {
                lng: 105.81052371625313,
                lat: 11.567614650921229
            },
            {
                lng: 107.49140302941089,
                lat: 12.337205918827948
            },
            {
                lng: 107.61454796756243,
                lat: 13.535530707244206
            },
            {
                lng: 107.38272749230109,
                lat: 14.202440904186972
            },
            {
                lng: 107.5645251811039,
                lat: 15.20217316330556
            },
            {
                lng: 107.3127059265456,
                lat: 15.90853831630318
            },
            {
                lng: 106.55600792849569,
                lat: 16.604283962464805
            },
            {
                lng: 105.92576216026403,
                lat: 17.48531545660896
            },
            {
                lng: 105.09459842328152,
                lat: 18.66697459561108
            },
            {
                lng: 103.89653201702671,
                lat: 19.265180975821806
            },
            {
                lng: 104.18338789267894,
                lat: 19.62466807706022
            },
            {
                lng: 104.8225736836971,
                lat: 19.886641750563882
            },
            {
                lng: 104.43500044150805,
                lat: 20.75873322192153
            },
            {
                lng: 103.20386111858645,
                lat: 20.76656220141375
            },
            {
                lng: 102.75489627483466,
                lat: 21.675137233969465
            },
            {
                lng: 102.17043582561358,
                lat: 22.464753119389304
            },
            {
                lng: 102.7069922221001,
                lat: 22.708795070887675
            },
            {
                lng: 103.50451460166056,
                lat: 22.70375661873921
            },
            {
                lng: 104.47685835166448,
                lat: 22.819150092046968
            },
            {
                lng: 105.32920942588663,
                lat: 23.352063300056912
            },
            {
                lng: 105.81124718630522,
                lat: 22.976892401617903
            },
            {
                lng: 106.72540327354847,
                lat: 22.79426788989842
            },
            {
                lng: 106.56727339073532,
                lat: 22.21820486092477
            },
            {
                lng: 107.04342003787264,
                lat: 21.811898912029914
            },
            {
                lng: 108.05018029178294,
                lat: 21.55237986906012
            },
            {
                lng: 106.7150679870901,
                lat: 20.69685069425202
            },
            {
                lng: 105.88168216351903,
                lat: 19.752050482659698
            },
            {
                lng: 105.66200564984631,
                lat: 19.05816518806057
            },
            {
                lng: 106.42681684776602,
                lat: 18.004120998603227
            },
            {
                lng: 107.36195356651974,
                lat: 16.697456569887052
            },
            {
                lng: 108.26949507042963,
                lat: 16.07974233648615
            },
            {
                lng: 108.87710656131748,
                lat: 15.27669057867044
            },
            {
                lng: 109.33526981001722,
                lat: 13.426028347217724
            },
            {
                lng: 109.20013593957398,
                lat: 11.666859239137764
            },
            {
                lng: 108.36612999881545,
                lat: 11.008320624226272
            },
            {
                lng: 107.22092858279524,
                lat: 10.364483954301832
            },
            {
                lng: 106.40511274620343,
                lat: 9.53083974856932
            },
            {
                lng: 105.15826378786511,
                lat: 8.599759629750494
            },
            {
                lng: 104.79518517458239,
                lat: 9.241038316276502
            },
            {
                lng: 105.07620161338562,
                lat: 9.918490505406808
            },
            {
                lng: 104.33433475140347,
                lat: 10.48654368737523
            }
        ]
    },
    KP: {
        name: 'Korea, Dem. Rep.',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: 130.78000366004676,
                    lat: 42.220007813203225
                },
                {
                    lng: 130.78000485358513,
                    lat: 42.22001036108258
                },
                {
                    lng: 130.78000735893113,
                    lat: 42.22000722916885
                },
                {
                    lng: 130.78000366004676,
                    lat: 42.220007813203225
                }
            ],
            [
                {
                    lng: 130.63999970690955,
                    lat: 42.39502427522179
                },
                {
                    lng: 130.6400000000001,
                    lat: 42.395
                },
                {
                    lng: 130.77999231657833,
                    lat: 42.22000960427719
                },
                {
                    lng: 130.40003055228902,
                    lat: 42.28000356705971
                },
                {
                    lng: 129.96594852103726,
                    lat: 41.94136790625106
                },
                {
                    lng: 129.66736209525482,
                    lat: 41.60110443782523
                },
                {
                    lng: 129.70518924369247,
                    lat: 40.88282786718433
                },
                {
                    lng: 129.18811486218,
                    lat: 40.66180776627199
                },
                {
                    lng: 129.01039961152821,
                    lat: 40.485436102859815
                },
                {
                    lng: 128.63336836152672,
                    lat: 40.18984691015031
                },
                {
                    lng: 127.96741417858135,
                    lat: 40.02541250259756
                },
                {
                    lng: 127.53343550019417,
                    lat: 39.7568500839767
                },
                {
                    lng: 127.5021195822253,
                    lat: 39.32393077245153
                },
                {
                    lng: 127.38543419811027,
                    lat: 39.213472398427655
                },
                {
                    lng: 127.78334272675772,
                    lat: 39.05089834243742
                },
                {
                    lng: 128.34971642467661,
                    lat: 38.61224294692785
                },
                {
                    lng: 128.20574588431145,
                    lat: 38.37039724380189
                },
                {
                    lng: 127.78003543509101,
                    lat: 38.30453563084589
                },
                {
                    lng: 127.07330854706737,
                    lat: 38.2561148137884
                },
                {
                    lng: 126.68371992401893,
                    lat: 37.80477285415118
                },
                {
                    lng: 126.23733890188176,
                    lat: 37.84037791600028
                },
                {
                    lng: 126.17475874237624,
                    lat: 37.74968577732804
                },
                {
                    lng: 125.68910363169721,
                    lat: 37.94001007745902
                },
                {
                    lng: 125.5684391622957,
                    lat: 37.75208873142962
                },
                {
                    lng: 125.2753304383362,
                    lat: 37.669070542952724
                },
                {
                    lng: 125.24008711151316,
                    lat: 37.85722443292744
                },
                {
                    lng: 124.98103315643397,
                    lat: 37.94882090916478
                },
                {
                    lng: 124.71216067921938,
                    lat: 38.10834605564979
                },
                {
                    lng: 124.98599409393398,
                    lat: 38.54847422947968
                },
                {
                    lng: 125.2219486837787,
                    lat: 38.66585724543067
                },
                {
                    lng: 125.13285851450752,
                    lat: 38.84855927179859
                },
                {
                    lng: 125.3865897970606,
                    lat: 39.387957872061165
                },
                {
                    lng: 125.32111575734682,
                    lat: 39.55138458918421
                },
                {
                    lng: 124.7374821310424,
                    lat: 39.66034434667162
                },
                {
                    lng: 124.26562462778531,
                    lat: 39.928493353834156
                },
                {
                    lng: 125.07994184784064,
                    lat: 40.56982371679245
                },
                {
                    lng: 126.18204511932943,
                    lat: 41.10733612727637
                },
                {
                    lng: 126.86908328664987,
                    lat: 41.81656932226619
                },
                {
                    lng: 127.34378299368302,
                    lat: 41.50315176041597
                },
                {
                    lng: 128.20843305879066,
                    lat: 41.46677155208249
                },
                {
                    lng: 128.0522152039723,
                    lat: 41.99428457291795
                },
                {
                    lng: 129.59666873587952,
                    lat: 42.42498179785456
                },
                {
                    lng: 129.99426720593323,
                    lat: 42.985386867843786
                },
                {
                    lng: 130.63999970690955,
                    lat: 42.39502427522179
                }
            ]
        ]
    },
    KR: {
        name: 'Korea, Rep.',
        type: 'Polygon',
        coordinates: [
            {
                lng: 126.17475874237624,
                lat: 37.74968577732804
            },
            {
                lng: 126.23733890188176,
                lat: 37.84037791600028
            },
            {
                lng: 126.68371992401893,
                lat: 37.80477285415118
            },
            {
                lng: 127.07330854706737,
                lat: 38.2561148137884
            },
            {
                lng: 127.78003543509101,
                lat: 38.30453563084589
            },
            {
                lng: 128.20574588431145,
                lat: 38.37039724380189
            },
            {
                lng: 128.34971642467661,
                lat: 38.61224294692785
            },
            {
                lng: 129.21291954968007,
                lat: 37.43239248305595
            },
            {
                lng: 129.46044966035817,
                lat: 36.78418915460283
            },
            {
                lng: 129.4683044780665,
                lat: 35.63214061130395
            },
            {
                lng: 129.0913765809296,
                lat: 35.082484239231434
            },
            {
                lng: 128.1858504578791,
                lat: 34.89037710218639
            },
            {
                lng: 127.3865194031884,
                lat: 34.47567373304412
            },
            {
                lng: 126.48574751190876,
                lat: 34.39004588473648
            },
            {
                lng: 126.37391971242914,
                lat: 34.934560451795946
            },
            {
                lng: 126.55923139862779,
                lat: 35.6845405136479
            },
            {
                lng: 126.11739790253229,
                lat: 36.72548472751926
            },
            {
                lng: 126.86014326386339,
                lat: 36.893924058574626
            },
            {
                lng: 126.17475874237624,
                lat: 37.74968577732804
            }
        ]
    },
    MN: {
        name: 'Mongolia',
        type: 'Polygon',
        coordinates: [
            {
                lng: 87.75126427607671,
                lat: 49.297197984405486
            },
            {
                lng: 88.80556684769552,
                lat: 49.47052073831242
            },
            {
                lng: 90.71366743364068,
                lat: 50.33181183532109
            },
            {
                lng: 92.23471154171968,
                lat: 50.80217072204172
            },
            {
                lng: 93.10421,
                lat: 50.49529
            },
            {
                lng: 94.14756635943559,
                lat: 50.48053660745717
            },
            {
                lng: 94.81594933469873,
                lat: 50.01343333597085
            },
            {
                lng: 95.8140200000002,
                lat: 49.97746000000012
            },
            {
                lng: 97.25976000000014,
                lat: 49.72605
            },
            {
                lng: 98.23176150919156,
                lat: 50.422400621128745
            },
            {
                lng: 97.82573978067431,
                lat: 51.01099518493318
            },
            {
                lng: 98.86149051310034,
                lat: 52.04736603454669
            },
            {
                lng: 99.98173221232354,
                lat: 51.63400625264399
            },
            {
                lng: 100.88948042196262,
                lat: 51.51685578063832
            },
            {
                lng: 102.06521,
                lat: 51.25991
            },
            {
                lng: 102.25589,
                lat: 50.51056000000011
            },
            {
                lng: 103.67654544476022,
                lat: 50.089966132195116
            },
            {
                lng: 104.62158,
                lat: 50.27532000000019
            },
            {
                lng: 105.88659142458675,
                lat: 50.406019192092224
            },
            {
                lng: 106.8888041524553,
                lat: 50.27429596618032
            },
            {
                lng: 107.86817589725095,
                lat: 49.793705145865815
            },
            {
                lng: 108.47516727095129,
                lat: 49.28254771585074
            },
            {
                lng: 109.40244917199664,
                lat: 49.292960516957635
            },
            {
                lng: 110.66201053267878,
                lat: 49.13012807880585
            },
            {
                lng: 111.58123091028662,
                lat: 49.37796824807769
            },
            {
                lng: 112.89773969935436,
                lat: 49.54356537535699
            },
            {
                lng: 114.36245649623527,
                lat: 50.24830272073741
            },
            {
                lng: 114.96210981655018,
                lat: 50.140247300815126
            },
            {
                lng: 115.48569542853136,
                lat: 49.80517731383475
            },
            {
                lng: 116.67880089728612,
                lat: 49.888531399121405
            },
            {
                lng: 116.19180219936757,
                lat: 49.134598090199106
            },
            {
                lng: 115.48528201707306,
                lat: 48.13538259540344
            },
            {
                lng: 115.74283735561579,
                lat: 47.72654450132629
            },
            {
                lng: 116.30895267137323,
                lat: 47.85341014260284
            },
            {
                lng: 117.29550744025741,
                lat: 47.69770905210743
            },
            {
                lng: 118.06414269416672,
                lat: 48.06673045510369
            },
            {
                lng: 118.86657433479495,
                lat: 47.74706004494617
            },
            {
                lng: 119.7728239278975,
                lat: 47.04805878355013
            },
            {
                lng: 119.66326989143876,
                lat: 46.69267995867892
            },
            {
                lng: 118.87432579963873,
                lat: 46.80541209572365
            },
            {
                lng: 117.42170128791419,
                lat: 46.67273285581426
            },
            {
                lng: 116.71786828009886,
                lat: 46.38820241961521
            },
            {
                lng: 115.98509647020009,
                lat: 45.727235012386004
            },
            {
                lng: 114.46033165899607,
                lat: 45.339816799493825
            },
            {
                lng: 113.46390669154417,
                lat: 44.80889313412712
            },
            {
                lng: 112.43606245325881,
                lat: 45.01164561622429
            },
            {
                lng: 111.8733061056003,
                lat: 45.10207937273506
            },
            {
                lng: 111.34837690637946,
                lat: 44.45744171811009
            },
            {
                lng: 111.66773725794323,
                lat: 44.07317576758771
            },
            {
                lng: 111.82958784388137,
                lat: 43.74311839453952
            },
            {
                lng: 111.12968224492023,
                lat: 43.40683401140015
            },
            {
                lng: 110.41210330611528,
                lat: 42.87123362891103
            },
            {
                lng: 109.24359581913146,
                lat: 42.5194463160841
            },
            {
                lng: 107.74477257693795,
                lat: 42.48151581478187
            },
            {
                lng: 106.12931562706169,
                lat: 42.13432770442891
            },
            {
                lng: 104.96499393109347,
                lat: 41.59740957291635
            },
            {
                lng: 104.52228193564899,
                lat: 41.908346666016556
            },
            {
                lng: 103.31227827353482,
                lat: 41.9074681666676
            },
            {
                lng: 101.83304039917994,
                lat: 42.51487295182628
            },
            {
                lng: 100.84586551310827,
                lat: 42.66380442969145
            },
            {
                lng: 99.51581749878004,
                lat: 42.524691473961724
            },
            {
                lng: 97.45175744017801,
                lat: 42.74888967546002
            },
            {
                lng: 96.34939578652781,
                lat: 42.725635280928685
            },
            {
                lng: 95.76245486855669,
                lat: 43.319449164394605
            },
            {
                lng: 95.30687544147153,
                lat: 44.24133087826547
            },
            {
                lng: 94.68892866412533,
                lat: 44.35233185482842
            },
            {
                lng: 93.4807336771413,
                lat: 44.975472113619965
            },
            {
                lng: 92.13389082231822,
                lat: 45.11507599545646
            },
            {
                lng: 90.9455395853343,
                lat: 45.28607330991028
            },
            {
                lng: 90.58576826371828,
                lat: 45.71971609148753
            },
            {
                lng: 90.97080936072501,
                lat: 46.88814606382293
            },
            {
                lng: 90.28082563676392,
                lat: 47.69354909930793
            },
            {
                lng: 88.85429772334676,
                lat: 48.069081732772965
            },
            {
                lng: 88.01383222855173,
                lat: 48.599462795600616
            },
            {
                lng: 87.75126427607671,
                lat: 49.297197984405486
            }
        ]
    },
    IN: {
        name: 'India',
        type: 'Polygon',
        coordinates: [
            {
                lng: 97.32711388549004,
                lat: 28.26158274994634
            },
            {
                lng: 97.40256147663614,
                lat: 27.882536119085444
            },
            {
                lng: 97.0519885599681,
                lat: 27.69905894623315
            },
            {
                lng: 97.1339990580153,
                lat: 27.083773505149964
            },
            {
                lng: 96.41936567585097,
                lat: 27.264589341739224
            },
            {
                lng: 95.12476769407496,
                lat: 26.5735720891323
            },
            {
                lng: 95.1551534362626,
                lat: 26.001307277932085
            },
            {
                lng: 94.60324913938538,
                lat: 25.162495428970402
            },
            {
                lng: 94.55265791217164,
                lat: 24.675238348890336
            },
            {
                lng: 94.10674197792507,
                lat: 23.85074087167348
            },
            {
                lng: 93.3251876159428,
                lat: 24.078556423432204
            },
            {
                lng: 93.28632693885928,
                lat: 23.043658352139005
            },
            {
                lng: 93.06029422401463,
                lat: 22.70311066333557
            },
            {
                lng: 93.16612755734837,
                lat: 22.278459580977103
            },
            {
                lng: 92.67272098182556,
                lat: 22.041238918541254
            },
            {
                lng: 92.14603478390681,
                lat: 23.627498684172593
            },
            {
                lng: 91.86992760617132,
                lat: 23.624346421802784
            },
            {
                lng: 91.70647505083211,
                lat: 22.985263983649187
            },
            {
                lng: 91.15896325069973,
                lat: 23.50352692310439
            },
            {
                lng: 91.46772993364368,
                lat: 24.072639471934792
            },
            {
                lng: 91.91509280799443,
                lat: 24.130413723237112
            },
            {
                lng: 92.37620161333481,
                lat: 24.976692816664965
            },
            {
                lng: 91.79959598182208,
                lat: 25.147431748957317
            },
            {
                lng: 90.87221072791212,
                lat: 25.132600612889547
            },
            {
                lng: 89.92069258012185,
                lat: 25.26974986419218
            },
            {
                lng: 89.83248091019962,
                lat: 25.96508209889548
            },
            {
                lng: 89.35509402868729,
                lat: 26.014407253518073
            },
            {
                lng: 88.56304935094977,
                lat: 26.446525580342723
            },
            {
                lng: 88.2097892598025,
                lat: 25.768065700782714
            },
            {
                lng: 88.93155398962308,
                lat: 25.238692328384776
            },
            {
                lng: 88.30637251175602,
                lat: 24.866079413344206
            },
            {
                lng: 88.08442223506242,
                lat: 24.501657212821925
            },
            {
                lng: 88.69994022009092,
                lat: 24.23371491138856
            },
            {
                lng: 88.52976972855379,
                lat: 23.631141872649167
            },
            {
                lng: 88.87631188350309,
                lat: 22.87914642993783
            },
            {
                lng: 89.03196129756623,
                lat: 22.055708319582976
            },
            {
                lng: 88.88876590368542,
                lat: 21.690588487224748
            },
            {
                lng: 88.20849734899522,
                lat: 21.703171698487807
            },
            {
                lng: 86.97570438024027,
                lat: 21.49556163175521
            },
            {
                lng: 87.03316857294887,
                lat: 20.743307806882413
            },
            {
                lng: 86.49935102737379,
                lat: 20.151638495356607
            },
            {
                lng: 85.0602657409097,
                lat: 19.4785788029711
            },
            {
                lng: 83.94100589390001,
                lat: 18.302009792549725
            },
            {
                lng: 83.18921715691785,
                lat: 17.67122142177898
            },
            {
                lng: 82.19279218946592,
                lat: 17.016636053937816
            },
            {
                lng: 82.19124189649719,
                lat: 16.556664130107848
            },
            {
                lng: 81.69271935417748,
                lat: 16.310219224507904
            },
            {
                lng: 80.79199913933014,
                lat: 15.951972357644493
            },
            {
                lng: 80.32489586784388,
                lat: 15.89918488205835
            },
            {
                lng: 80.02506920768644,
                lat: 15.136414903214147
            },
            {
                lng: 80.2332735533904,
                lat: 13.835770778859981
            },
            {
                lng: 80.28629357292186,
                lat: 13.006260687710835
            },
            {
                lng: 79.8625468281285,
                lat: 12.056215318240888
            },
            {
                lng: 79.85799930208682,
                lat: 10.35727509199711
            },
            {
                lng: 79.340511509116,
                lat: 10.30885427493962
            },
            {
                lng: 78.88534549348918,
                lat: 9.546135972527722
            },
            {
                lng: 79.1897196796883,
                lat: 9.216543687370148
            },
            {
                lng: 78.2779407083305,
                lat: 8.933046779816934
            },
            {
                lng: 77.94116539908435,
                lat: 8.252959092639742
            },
            {
                lng: 77.53989790233794,
                lat: 7.965534776232332
            },
            {
                lng: 76.59297895702167,
                lat: 8.89927623131419
            },
            {
                lng: 76.13006147655108,
                lat: 10.299630031775521
            },
            {
                lng: 75.7464673196485,
                lat: 11.308250637248307
            },
            {
                lng: 75.39610110870959,
                lat: 11.781245022015824
            },
            {
                lng: 74.86481570831683,
                lat: 12.741935736537897
            },
            {
                lng: 74.61671715688354,
                lat: 13.99258291264968
            },
            {
                lng: 74.44385949086723,
                lat: 14.617221787977698
            },
            {
                lng: 73.5341992532334,
                lat: 15.99065216721496
            },
            {
                lng: 73.11990929554943,
                lat: 17.928570054592498
            },
            {
                lng: 72.82090945830865,
                lat: 19.208233547436166
            },
            {
                lng: 72.8244751321368,
                lat: 20.419503282141534
            },
            {
                lng: 72.6305334817454,
                lat: 21.356009426351008
            },
            {
                lng: 71.17527347197395,
                lat: 20.757441311114235
            },
            {
                lng: 70.4704586119451,
                lat: 20.877330634031384
            },
            {
                lng: 69.16413008003883,
                lat: 22.0892980005727
            },
            {
                lng: 69.6449276060824,
                lat: 22.450774644454338
            },
            {
                lng: 69.34959679553435,
                lat: 22.84317963306269
            },
            {
                lng: 68.1766451353734,
                lat: 23.69196503345671
            },
            {
                lng: 68.84259931831878,
                lat: 24.35913361256094
            },
            {
                lng: 71.04324018746823,
                lat: 24.3565239527302
            },
            {
                lng: 70.84469933460284,
                lat: 25.21510203704352
            },
            {
                lng: 70.2828731627256,
                lat: 25.72222870533983
            },
            {
                lng: 70.16892662952202,
                lat: 26.491871649678842
            },
            {
                lng: 69.51439293811313,
                lat: 26.940965684511372
            },
            {
                lng: 70.61649620960193,
                lat: 27.989196275335868
            },
            {
                lng: 71.77766564320032,
                lat: 27.913180243434525
            },
            {
                lng: 72.8237516620847,
                lat: 28.961591701772054
            },
            {
                lng: 73.45063846221743,
                lat: 29.97641347911987
            },
            {
                lng: 74.42138024282028,
                lat: 30.979814764931177
            },
            {
                lng: 74.40592898956501,
                lat: 31.69263947196528
            },
            {
                lng: 75.25864179881322,
                lat: 32.2711054550405
            },
            {
                lng: 74.45155927927871,
                lat: 32.7648996038055
            },
            {
                lng: 74.10429365427734,
                lat: 33.44147329358685
            },
            {
                lng: 73.74994835805197,
                lat: 34.31769887952785
            },
            {
                lng: 74.24020267120497,
                lat: 34.748887030571254
            },
            {
                lng: 75.75706098826834,
                lat: 34.50492259372132
            },
            {
                lng: 76.87172163280403,
                lat: 34.65354401299274
            },
            {
                lng: 77.83745079947457,
                lat: 35.494009507787766
            },
            {
                lng: 78.91226891471322,
                lat: 34.32193634697579
            },
            {
                lng: 78.81108646028574,
                lat: 33.50619802503242
            },
            {
                lng: 79.20889163606859,
                lat: 32.994394639613716
            },
            {
                lng: 79.17612877799553,
                lat: 32.48377981213771
            },
            {
                lng: 78.45844648632601,
                lat: 32.61816437431273
            },
            {
                lng: 78.73889448437401,
                lat: 31.515906073527063
            },
            {
                lng: 79.7213668151071,
                lat: 30.88271474865473
            },
            {
                lng: 81.11125613802932,
                lat: 30.183480943313402
            },
            {
                lng: 80.4767212259174,
                lat: 29.72986522065534
            },
            {
                lng: 80.08842451367627,
                lat: 28.79447011974014
            },
            {
                lng: 81.05720258985203,
                lat: 28.416095282499043
            },
            {
                lng: 81.99998742058497,
                lat: 27.925479234319994
            },
            {
                lng: 83.30424889519955,
                lat: 27.36450572357556
            },
            {
                lng: 84.6750179381738,
                lat: 27.234901231387536
            },
            {
                lng: 85.25177859898338,
                lat: 26.726198431906344
            },
            {
                lng: 86.02439293817918,
                lat: 26.63098460540857
            },
            {
                lng: 87.2274719583663,
                lat: 26.397898057556077
            },
            {
                lng: 88.06023766474982,
                lat: 26.41461538340249
            },
            {
                lng: 88.17480431514092,
                lat: 26.81040517832595
            },
            {
                lng: 88.04313276566123,
                lat: 27.445818589786825
            },
            {
                lng: 88.12044070836987,
                lat: 27.876541652939594
            },
            {
                lng: 88.73032596227856,
                lat: 28.086864732367516
            },
            {
                lng: 88.81424848832056,
                lat: 27.299315904239364
            },
            {
                lng: 88.83564253128938,
                lat: 27.098966376243762
            },
            {
                lng: 89.74452762243885,
                lat: 26.719402981059957
            },
            {
                lng: 90.37327477413407,
                lat: 26.87572418874288
            },
            {
                lng: 91.21751264848643,
                lat: 26.808648179628022
            },
            {
                lng: 92.03348351437509,
                lat: 26.83831045176356
            },
            {
                lng: 92.10371178585974,
                lat: 27.452614040633208
            },
            {
                lng: 91.69665652869668,
                lat: 27.771741848251665
            },
            {
                lng: 92.50311893104364,
                lat: 27.89687632904645
            },
            {
                lng: 93.41334760943269,
                lat: 28.640629380807226
            },
            {
                lng: 94.56599043170294,
                lat: 29.277438055939985
            },
            {
                lng: 95.40480228066464,
                lat: 29.03171662039213
            },
            {
                lng: 96.11767866413103,
                lat: 29.452802028922466
            },
            {
                lng: 96.58659061074749,
                lat: 28.83097951915434
            },
            {
                lng: 96.24883344928779,
                lat: 28.411030992134442
            },
            {
                lng: 97.32711388549004,
                lat: 28.26158274994634
            }
        ]
    },
    BD: {
        name: 'Bangladesh',
        type: 'Polygon',
        coordinates: [
            {
                lng: 92.67272098182556,
                lat: 22.041238918541254
            },
            {
                lng: 92.65225711463799,
                lat: 21.324047552978485
            },
            {
                lng: 92.30323449093868,
                lat: 21.47548533780982
            },
            {
                lng: 92.36855350135562,
                lat: 20.670883287025347
            },
            {
                lng: 92.08288618364614,
                lat: 21.19219513598577
            },
            {
                lng: 92.02521528520839,
                lat: 21.701569729086767
            },
            {
                lng: 91.83489098507744,
                lat: 22.182935695885565
            },
            {
                lng: 91.41708702999766,
                lat: 22.76501902922122
            },
            {
                lng: 90.49600630082728,
                lat: 22.80501658781513
            },
            {
                lng: 90.58695682166098,
                lat: 22.392793687422866
            },
            {
                lng: 90.27297081905556,
                lat: 21.83636770272011
            },
            {
                lng: 89.84746707556428,
                lat: 22.039146023033425
            },
            {
                lng: 89.70204959509493,
                lat: 21.857115790285306
            },
            {
                lng: 89.41886274613549,
                lat: 21.9661789006373
            },
            {
                lng: 89.03196129756623,
                lat: 22.055708319582976
            },
            {
                lng: 88.87631188350309,
                lat: 22.87914642993783
            },
            {
                lng: 88.52976972855379,
                lat: 23.631141872649167
            },
            {
                lng: 88.69994022009092,
                lat: 24.23371491138856
            },
            {
                lng: 88.08442223506242,
                lat: 24.501657212821925
            },
            {
                lng: 88.30637251175602,
                lat: 24.866079413344206
            },
            {
                lng: 88.93155398962308,
                lat: 25.238692328384776
            },
            {
                lng: 88.2097892598025,
                lat: 25.768065700782714
            },
            {
                lng: 88.56304935094977,
                lat: 26.446525580342723
            },
            {
                lng: 89.35509402868729,
                lat: 26.014407253518073
            },
            {
                lng: 89.83248091019962,
                lat: 25.96508209889548
            },
            {
                lng: 89.92069258012185,
                lat: 25.26974986419218
            },
            {
                lng: 90.87221072791212,
                lat: 25.132600612889547
            },
            {
                lng: 91.79959598182208,
                lat: 25.147431748957317
            },
            {
                lng: 92.37620161333481,
                lat: 24.976692816664965
            },
            {
                lng: 91.91509280799443,
                lat: 24.130413723237112
            },
            {
                lng: 91.46772993364368,
                lat: 24.072639471934792
            },
            {
                lng: 91.15896325069973,
                lat: 23.50352692310439
            },
            {
                lng: 91.70647505083211,
                lat: 22.985263983649187
            },
            {
                lng: 91.86992760617132,
                lat: 23.624346421802784
            },
            {
                lng: 92.14603478390681,
                lat: 23.627498684172593
            },
            {
                lng: 92.67272098182556,
                lat: 22.041238918541254
            }
        ]
    },
    BT: {
        name: 'Bhutan',
        type: 'Polygon',
        coordinates: [
            {
                lng: 91.69665652869668,
                lat: 27.771741848251665
            },
            {
                lng: 92.10371178585974,
                lat: 27.452614040633208
            },
            {
                lng: 92.03348351437509,
                lat: 26.83831045176356
            },
            {
                lng: 91.21751264848643,
                lat: 26.808648179628022
            },
            {
                lng: 90.37327477413407,
                lat: 26.87572418874288
            },
            {
                lng: 89.74452762243885,
                lat: 26.719402981059957
            },
            {
                lng: 88.83564253128938,
                lat: 27.098966376243762
            },
            {
                lng: 88.81424848832056,
                lat: 27.299315904239364
            },
            {
                lng: 89.47581017452111,
                lat: 28.042758897406397
            },
            {
                lng: 90.01582889197118,
                lat: 28.296438503527217
            },
            {
                lng: 90.7305139505678,
                lat: 28.064953925075756
            },
            {
                lng: 91.25885379431992,
                lat: 28.040614325466294
            },
            {
                lng: 91.69665652869668,
                lat: 27.771741848251665
            }
        ]
    },
    NP: {
        name: 'Nepal',
        type: 'Polygon',
        coordinates: [
            {
                lng: 88.12044070836987,
                lat: 27.876541652939594
            },
            {
                lng: 88.04313276566123,
                lat: 27.445818589786825
            },
            {
                lng: 88.17480431514092,
                lat: 26.81040517832595
            },
            {
                lng: 88.06023766474982,
                lat: 26.41461538340249
            },
            {
                lng: 87.2274719583663,
                lat: 26.397898057556077
            },
            {
                lng: 86.02439293817918,
                lat: 26.63098460540857
            },
            {
                lng: 85.25177859898338,
                lat: 26.726198431906344
            },
            {
                lng: 84.6750179381738,
                lat: 27.234901231387536
            },
            {
                lng: 83.30424889519955,
                lat: 27.36450572357556
            },
            {
                lng: 81.99998742058497,
                lat: 27.925479234319994
            },
            {
                lng: 81.05720258985203,
                lat: 28.416095282499043
            },
            {
                lng: 80.08842451367627,
                lat: 28.79447011974014
            },
            {
                lng: 80.4767212259174,
                lat: 29.72986522065534
            },
            {
                lng: 81.11125613802932,
                lat: 30.183480943313402
            },
            {
                lng: 81.52580447787474,
                lat: 30.42271698660863
            },
            {
                lng: 82.32751264845088,
                lat: 30.115268052688137
            },
            {
                lng: 83.33711510613719,
                lat: 29.463731594352197
            },
            {
                lng: 83.89899295444673,
                lat: 29.320226141877658
            },
            {
                lng: 84.23457970575015,
                lat: 28.839893703724698
            },
            {
                lng: 85.01163821812304,
                lat: 28.642773952747344
            },
            {
                lng: 85.82331994013151,
                lat: 28.203575954698707
            },
            {
                lng: 86.9545170430006,
                lat: 27.974261786403517
            },
            {
                lng: 88.12044070836987,
                lat: 27.876541652939594
            }
        ]
    },
    PK: {
        name: 'Pakistan',
        type: 'Polygon',
        coordinates: [
            {
                lng: 77.83745079947457,
                lat: 35.494009507787766
            },
            {
                lng: 76.87172163280403,
                lat: 34.65354401299274
            },
            {
                lng: 75.75706098826834,
                lat: 34.50492259372132
            },
            {
                lng: 74.24020267120497,
                lat: 34.748887030571254
            },
            {
                lng: 73.74994835805197,
                lat: 34.31769887952785
            },
            {
                lng: 74.10429365427734,
                lat: 33.44147329358685
            },
            {
                lng: 74.45155927927871,
                lat: 32.7648996038055
            },
            {
                lng: 75.25864179881322,
                lat: 32.2711054550405
            },
            {
                lng: 74.40592898956501,
                lat: 31.69263947196528
            },
            {
                lng: 74.42138024282028,
                lat: 30.979814764931177
            },
            {
                lng: 73.45063846221743,
                lat: 29.97641347911987
            },
            {
                lng: 72.8237516620847,
                lat: 28.961591701772054
            },
            {
                lng: 71.77766564320032,
                lat: 27.913180243434525
            },
            {
                lng: 70.61649620960193,
                lat: 27.989196275335868
            },
            {
                lng: 69.51439293811313,
                lat: 26.940965684511372
            },
            {
                lng: 70.16892662952202,
                lat: 26.491871649678842
            },
            {
                lng: 70.2828731627256,
                lat: 25.72222870533983
            },
            {
                lng: 70.84469933460284,
                lat: 25.21510203704352
            },
            {
                lng: 71.04324018746823,
                lat: 24.3565239527302
            },
            {
                lng: 68.84259931831878,
                lat: 24.35913361256094
            },
            {
                lng: 68.1766451353734,
                lat: 23.69196503345671
            },
            {
                lng: 67.44366661974547,
                lat: 23.94484365487699
            },
            {
                lng: 67.14544192898907,
                lat: 24.663611151624647
            },
            {
                lng: 66.37282758979327,
                lat: 25.42514089609385
            },
            {
                lng: 64.53040774929113,
                lat: 25.23703868255143
            },
            {
                lng: 62.90570071803461,
                lat: 25.21840932871021
            },
            {
                lng: 61.49736290878419,
                lat: 25.0782370061185
            },
            {
                lng: 61.87418745305655,
                lat: 26.239974880472104
            },
            {
                lng: 63.31663170761959,
                lat: 26.756532497661667
            },
            {
                lng: 63.2338977395203,
                lat: 27.21704702403071
            },
            {
                lng: 62.75542565292986,
                lat: 27.378923448184988
            },
            {
                lng: 62.72783043808599,
                lat: 28.25964488373539
            },
            {
                lng: 61.77186811711863,
                lat: 28.6993338078908
            },
            {
                lng: 61.36930870956494,
                lat: 29.303276272085924
            },
            {
                lng: 60.87424848820879,
                lat: 29.829238999952608
            },
            {
                lng: 62.54985680527278,
                lat: 29.31857249604431
            },
            {
                lng: 63.55026085801117,
                lat: 29.468330796826166
            },
            {
                lng: 64.14800215033125,
                lat: 29.340819200145972
            },
            {
                lng: 64.35041873561852,
                lat: 29.560030625928093
            },
            {
                lng: 65.0468620136161,
                lat: 29.472180691031905
            },
            {
                lng: 66.34647260932442,
                lat: 29.887943427036177
            },
            {
                lng: 66.38145755398602,
                lat: 30.738899237586452
            },
            {
                lng: 66.93889122911847,
                lat: 31.304911200479353
            },
            {
                lng: 67.68339358914747,
                lat: 31.30315420178142
            },
            {
                lng: 67.79268924344478,
                lat: 31.58293040620963
            },
            {
                lng: 68.55693200060932,
                lat: 31.713310044882018
            },
            {
                lng: 68.92667687365767,
                lat: 31.620189113892067
            },
            {
                lng: 69.31776411324256,
                lat: 31.901412258424443
            },
            {
                lng: 69.26252200712256,
                lat: 32.5019440780883
            },
            {
                lng: 69.68714725126486,
                lat: 33.105498969041236
            },
            {
                lng: 70.3235941913716,
                lat: 33.35853261975839
            },
            {
                lng: 69.9305432473596,
                lat: 34.02012014417511
            },
            {
                lng: 70.8818030129884,
                lat: 33.98885590263852
            },
            {
                lng: 71.15677330921346,
                lat: 34.34891144463215
            },
            {
                lng: 71.11501875192164,
                lat: 34.733125718722235
            },
            {
                lng: 71.61307620635071,
                lat: 35.153203436822864
            },
            {
                lng: 71.49876793812109,
                lat: 35.650563259416
            },
            {
                lng: 71.26234826038575,
                lat: 36.074387518857804
            },
            {
                lng: 71.84629194528392,
                lat: 36.50994232842986
            },
            {
                lng: 72.92002485544447,
                lat: 36.72000702569632
            },
            {
                lng: 74.06755171091783,
                lat: 36.83617564548845
            },
            {
                lng: 74.57589277537298,
                lat: 37.02084137628346
            },
            {
                lng: 75.15802778514092,
                lat: 37.13303091078912
            },
            {
                lng: 75.89689741405013,
                lat: 36.666806138651836
            },
            {
                lng: 76.19284834178569,
                lat: 35.89840342868783
            },
            {
                lng: 77.83745079947457,
                lat: 35.494009507787766
            }
        ]
    },
    AF: {
        name: 'Afghanistan',
        type: 'Polygon',
        coordinates: [
            {
                lng: 66.51860680528867,
                lat: 37.36278432875879
            },
            {
                lng: 67.07578209825962,
                lat: 37.35614390720929
            },
            {
                lng: 67.82999962755952,
                lat: 37.144994004864685
            },
            {
                lng: 68.13556237170138,
                lat: 37.02311513930431
            },
            {
                lng: 68.85944583524594,
                lat: 37.344335842430596
            },
            {
                lng: 69.19627282092438,
                lat: 37.15114350030743
            },
            {
                lng: 69.51878543485796,
                lat: 37.60899669041342
            },
            {
                lng: 70.11657840361033,
                lat: 37.58822276463209
            },
            {
                lng: 70.27057417184014,
                lat: 37.735164699854025
            },
            {
                lng: 70.3763041523093,
                lat: 38.13839590102752
            },
            {
                lng: 70.80682050973289,
                lat: 38.486281643216415
            },
            {
                lng: 71.34813113799026,
                lat: 38.25890534113216
            },
            {
                lng: 71.23940392444817,
                lat: 37.953265082341886
            },
            {
                lng: 71.54191775908478,
                lat: 37.905774441065645
            },
            {
                lng: 71.44869347523024,
                lat: 37.06564484308052
            },
            {
                lng: 71.8446382994506,
                lat: 36.73817129164692
            },
            {
                lng: 72.1930408059624,
                lat: 36.948287665345674
            },
            {
                lng: 72.63688968291729,
                lat: 37.047558091778356
            },
            {
                lng: 73.26005577992501,
                lat: 37.495256862939
            },
            {
                lng: 73.9486959166465,
                lat: 37.4215662704908
            },
            {
                lng: 74.98000247589542,
                lat: 37.419990139305895
            },
            {
                lng: 75.15802778514092,
                lat: 37.13303091078912
            },
            {
                lng: 74.57589277537298,
                lat: 37.02084137628346
            },
            {
                lng: 74.06755171091783,
                lat: 36.83617564548845
            },
            {
                lng: 72.92002485544447,
                lat: 36.72000702569632
            },
            {
                lng: 71.84629194528392,
                lat: 36.50994232842986
            },
            {
                lng: 71.26234826038575,
                lat: 36.074387518857804
            },
            {
                lng: 71.49876793812109,
                lat: 35.650563259416
            },
            {
                lng: 71.61307620635071,
                lat: 35.153203436822864
            },
            {
                lng: 71.11501875192164,
                lat: 34.733125718722235
            },
            {
                lng: 71.15677330921346,
                lat: 34.34891144463215
            },
            {
                lng: 70.8818030129884,
                lat: 33.98885590263852
            },
            {
                lng: 69.9305432473596,
                lat: 34.02012014417511
            },
            {
                lng: 70.3235941913716,
                lat: 33.35853261975839
            },
            {
                lng: 69.68714725126486,
                lat: 33.105498969041236
            },
            {
                lng: 69.26252200712256,
                lat: 32.5019440780883
            },
            {
                lng: 69.31776411324256,
                lat: 31.901412258424443
            },
            {
                lng: 68.92667687365767,
                lat: 31.620189113892067
            },
            {
                lng: 68.55693200060932,
                lat: 31.713310044882018
            },
            {
                lng: 67.79268924344478,
                lat: 31.58293040620963
            },
            {
                lng: 67.68339358914747,
                lat: 31.30315420178142
            },
            {
                lng: 66.93889122911847,
                lat: 31.304911200479353
            },
            {
                lng: 66.38145755398602,
                lat: 30.738899237586452
            },
            {
                lng: 66.34647260932442,
                lat: 29.887943427036177
            },
            {
                lng: 65.0468620136161,
                lat: 29.472180691031905
            },
            {
                lng: 64.35041873561852,
                lat: 29.560030625928093
            },
            {
                lng: 64.14800215033125,
                lat: 29.340819200145972
            },
            {
                lng: 63.55026085801117,
                lat: 29.468330796826166
            },
            {
                lng: 62.54985680527278,
                lat: 29.31857249604431
            },
            {
                lng: 60.87424848820879,
                lat: 29.829238999952608
            },
            {
                lng: 61.781221551363444,
                lat: 30.735850328081238
            },
            {
                lng: 61.69931440618083,
                lat: 31.379506130492672
            },
            {
                lng: 60.94194461451113,
                lat: 31.548074652628753
            },
            {
                lng: 60.863654819588966,
                lat: 32.18291962333443
            },
            {
                lng: 60.536077915290775,
                lat: 32.98126882581157
            },
            {
                lng: 60.963700392506006,
                lat: 33.52883230237626
            },
            {
                lng: 60.52842980331158,
                lat: 33.676446031218006
            },
            {
                lng: 60.80319339380745,
                lat: 34.40410187431986
            },
            {
                lng: 61.210817091725744,
                lat: 35.650072333309225
            },
            {
                lng: 62.230651483005886,
                lat: 35.270663967422294
            },
            {
                lng: 62.98466230657661,
                lat: 35.40404083916762
            },
            {
                lng: 63.19353844590035,
                lat: 35.857165635718914
            },
            {
                lng: 63.98289594915871,
                lat: 36.0079574651466
            },
            {
                lng: 64.5464791197339,
                lat: 36.31207326918427
            },
            {
                lng: 64.7461051776774,
                lat: 37.111817735333304
            },
            {
                lng: 65.58894778835784,
                lat: 37.30521678318564
            },
            {
                lng: 65.74563073106683,
                lat: 37.66116404881207
            },
            {
                lng: 66.21738488145934,
                lat: 37.39379018813392
            },
            {
                lng: 66.51860680528867,
                lat: 37.36278432875879
            }
        ]
    },
    TJ: {
        name: 'Tajikistan',
        type: 'Polygon',
        coordinates: [
            {
                lng: 67.82999962755952,
                lat: 37.144994004864685
            },
            {
                lng: 68.39203250516596,
                lat: 38.15702525486874
            },
            {
                lng: 68.17602501818592,
                lat: 38.901553453113905
            },
            {
                lng: 67.44221967964131,
                lat: 39.140143541005486
            },
            {
                lng: 67.70142866401736,
                lat: 39.58047842056453
            },
            {
                lng: 68.53641645698943,
                lat: 39.53345286717894
            },
            {
                lng: 69.0116329283455,
                lat: 40.08615814875667
            },
            {
                lng: 69.32949466337283,
                lat: 40.72782440852485
            },
            {
                lng: 70.66662234892505,
                lat: 40.960213324541414
            },
            {
                lng: 70.45815962105962,
                lat: 40.49649485937029
            },
            {
                lng: 70.60140669137269,
                lat: 40.21852733007229
            },
            {
                lng: 71.01419803252017,
                lat: 40.24436554621823
            },
            {
                lng: 70.64801883329997,
                lat: 39.93575389257117
            },
            {
                lng: 69.55960981636852,
                lat: 40.10321137141298
            },
            {
                lng: 69.46488691597753,
                lat: 39.5266832545487
            },
            {
                lng: 70.54916181832562,
                lat: 39.6041979029865
            },
            {
                lng: 71.784693637992,
                lat: 39.27946320246437
            },
            {
                lng: 73.6753792662548,
                lat: 39.4312368841056
            },
            {
                lng: 73.92885216664644,
                lat: 38.50581533462274
            },
            {
                lng: 74.25751427602273,
                lat: 38.60650686294345
            },
            {
                lng: 74.86481570831683,
                lat: 38.3788463404816
            },
            {
                lng: 74.8299857929521,
                lat: 37.9900070257014
            },
            {
                lng: 74.98000247589542,
                lat: 37.419990139305895
            },
            {
                lng: 73.9486959166465,
                lat: 37.4215662704908
            },
            {
                lng: 73.26005577992501,
                lat: 37.495256862939
            },
            {
                lng: 72.63688968291729,
                lat: 37.047558091778356
            },
            {
                lng: 72.1930408059624,
                lat: 36.948287665345674
            },
            {
                lng: 71.8446382994506,
                lat: 36.73817129164692
            },
            {
                lng: 71.44869347523024,
                lat: 37.06564484308052
            },
            {
                lng: 71.54191775908478,
                lat: 37.905774441065645
            },
            {
                lng: 71.23940392444817,
                lat: 37.953265082341886
            },
            {
                lng: 71.34813113799026,
                lat: 38.25890534113216
            },
            {
                lng: 70.80682050973289,
                lat: 38.486281643216415
            },
            {
                lng: 70.3763041523093,
                lat: 38.13839590102752
            },
            {
                lng: 70.27057417184014,
                lat: 37.735164699854025
            },
            {
                lng: 70.11657840361033,
                lat: 37.58822276463209
            },
            {
                lng: 69.51878543485796,
                lat: 37.60899669041342
            },
            {
                lng: 69.19627282092438,
                lat: 37.15114350030743
            },
            {
                lng: 68.85944583524594,
                lat: 37.344335842430596
            },
            {
                lng: 68.13556237170138,
                lat: 37.02311513930431
            },
            {
                lng: 67.82999962755952,
                lat: 37.144994004864685
            }
        ]
    },
    KG: {
        name: 'Kyrgyz Republic',
        type: 'Polygon',
        coordinates: [
            {
                lng: 70.96231489449914,
                lat: 42.266154283205495
            },
            {
                lng: 71.18628055205212,
                lat: 42.70429291439214
            },
            {
                lng: 71.8446382994506,
                lat: 42.8453954127651
            },
            {
                lng: 73.48975752146237,
                lat: 42.50089447689132
            },
            {
                lng: 73.64530358266092,
                lat: 43.09127187760987
            },
            {
                lng: 74.21286583852256,
                lat: 43.29833934180337
            },
            {
                lng: 75.63696495962202,
                lat: 42.87789988867668
            },
            {
                lng: 76.00035363149846,
                lat: 42.98802236589067
            },
            {
                lng: 77.65839196158322,
                lat: 42.96068553320826
            },
            {
                lng: 79.14217736197978,
                lat: 42.85609243424952
            },
            {
                lng: 79.64364546094012,
                lat: 42.49668284765953
            },
            {
                lng: 80.2599902688853,
                lat: 42.34999929459906
            },
            {
                lng: 80.11943037305139,
                lat: 42.12394074153825
            },
            {
                lng: 78.54366092317531,
                lat: 41.58224254003869
            },
            {
                lng: 78.18719689322597,
                lat: 41.18531586360481
            },
            {
                lng: 76.90448449087708,
                lat: 41.06648590754965
            },
            {
                lng: 76.52636803579745,
                lat: 40.42794607193512
            },
            {
                lng: 75.4678279967307,
                lat: 40.56207225194867
            },
            {
                lng: 74.77686242055606,
                lat: 40.36642527929163
            },
            {
                lng: 73.8222436868283,
                lat: 39.893973497063186
            },
            {
                lng: 73.96001305531843,
                lat: 39.660008449861735
            },
            {
                lng: 73.6753792662548,
                lat: 39.4312368841056
            },
            {
                lng: 71.784693637992,
                lat: 39.27946320246437
            },
            {
                lng: 70.54916181832562,
                lat: 39.6041979029865
            },
            {
                lng: 69.46488691597753,
                lat: 39.5266832545487
            },
            {
                lng: 69.55960981636852,
                lat: 40.10321137141298
            },
            {
                lng: 70.64801883329997,
                lat: 39.93575389257117
            },
            {
                lng: 71.01419803252017,
                lat: 40.24436554621823
            },
            {
                lng: 71.77487511585656,
                lat: 40.14584442805378
            },
            {
                lng: 73.05541710804917,
                lat: 40.866033026689465
            },
            {
                lng: 71.87011478057047,
                lat: 41.392900092121266
            },
            {
                lng: 71.1578585142916,
                lat: 41.14358714452912
            },
            {
                lng: 70.42002241402821,
                lat: 41.51999827734314
            },
            {
                lng: 71.25924767444823,
                lat: 42.16771067968946
            },
            {
                lng: 70.96231489449914,
                lat: 42.266154283205495
            }
        ]
    },
    TM: {
        name: 'Turkmenistan',
        type: 'Polygon',
        coordinates: [
            {
                lng: 52.50245975119615,
                lat: 41.78331553808637
            },
            {
                lng: 52.944293247291654,
                lat: 42.11603424739759
            },
            {
                lng: 54.07941775901495,
                lat: 42.32410940202083
            },
            {
                lng: 54.75534549339264,
                lat: 42.043971462566574
            },
            {
                lng: 55.45525109235377,
                lat: 41.25985911718584
            },
            {
                lng: 55.96819135928291,
                lat: 41.30864166926936
            },
            {
                lng: 57.0963912290791,
                lat: 41.32231008561057
            },
            {
                lng: 56.932215203687804,
                lat: 41.826026109375604
            },
            {
                lng: 57.78652998233708,
                lat: 42.17055288346552
            },
            {
                lng: 58.62901085799146,
                lat: 42.75155101172305
            },
            {
                lng: 59.976422153569786,
                lat: 42.22308197689021
            },
            {
                lng: 60.083340691981675,
                lat: 41.425146185871405
            },
            {
                lng: 60.465952996670694,
                lat: 41.22032664648255
            },
            {
                lng: 61.54717898951356,
                lat: 41.266370347654615
            },
            {
                lng: 61.88271406438469,
                lat: 41.084856879229406
            },
            {
                lng: 62.374260288345006,
                lat: 40.05388621679039
            },
            {
                lng: 63.51801476426103,
                lat: 39.36325653742564
            },
            {
                lng: 64.17022301621677,
                lat: 38.892406724598246
            },
            {
                lng: 65.2159989765074,
                lat: 38.4026950139843
            },
            {
                lng: 66.54615034370022,
                lat: 37.97468496352687
            },
            {
                lng: 66.51860680528867,
                lat: 37.36278432875879
            },
            {
                lng: 66.21738488145934,
                lat: 37.39379018813392
            },
            {
                lng: 65.74563073106683,
                lat: 37.66116404881207
            },
            {
                lng: 65.58894778835784,
                lat: 37.30521678318564
            },
            {
                lng: 64.7461051776774,
                lat: 37.111817735333304
            },
            {
                lng: 64.5464791197339,
                lat: 36.31207326918427
            },
            {
                lng: 63.98289594915871,
                lat: 36.0079574651466
            },
            {
                lng: 63.19353844590035,
                lat: 35.857165635718914
            },
            {
                lng: 62.98466230657661,
                lat: 35.40404083916762
            },
            {
                lng: 62.230651483005886,
                lat: 35.270663967422294
            },
            {
                lng: 61.210817091725744,
                lat: 35.650072333309225
            },
            {
                lng: 61.123070509694145,
                lat: 36.491597194966246
            },
            {
                lng: 60.37763797388388,
                lat: 36.52738312432837
            },
            {
                lng: 59.23476199731681,
                lat: 37.41298798273034
            },
            {
                lng: 58.436154412678206,
                lat: 37.5223094752438
            },
            {
                lng: 57.330433790928986,
                lat: 38.02922943781094
            },
            {
                lng: 56.61936608259282,
                lat: 38.121394354803485
            },
            {
                lng: 56.18037479027333,
                lat: 37.93512665460743
            },
            {
                lng: 55.51157840355191,
                lat: 37.96411713312317
            },
            {
                lng: 54.800303989486565,
                lat: 37.392420762678185
            },
            {
                lng: 53.92159793479556,
                lat: 37.19891836196126
            },
            {
                lng: 53.73551110211252,
                lat: 37.90613617609169
            },
            {
                lng: 53.880928582581845,
                lat: 38.95209300389536
            },
            {
                lng: 53.1010278664329,
                lat: 39.29057363540713
            },
            {
                lng: 53.35780805849123,
                lat: 39.97528636327445
            },
            {
                lng: 52.69397260926982,
                lat: 40.03362905533197
            },
            {
                lng: 52.915251092343624,
                lat: 40.87652334244473
            },
            {
                lng: 53.858139275941134,
                lat: 40.63103445084218
            },
            {
                lng: 54.73684533063215,
                lat: 40.95101491959346
            },
            {
                lng: 54.008310988181314,
                lat: 41.55121084244742
            },
            {
                lng: 53.72171349469059,
                lat: 42.12319143327003
            },
            {
                lng: 52.916749708880076,
                lat: 41.86811656347733
            },
            {
                lng: 52.81468875510362,
                lat: 41.13537059179471
            },
            {
                lng: 52.50245975119615,
                lat: 41.78331553808637
            }
        ]
    },
    IR: {
        name: 'Iran, Islamic Rep.',
        type: 'Polygon',
        coordinates: [
            {
                lng: 48.567971225789755,
                lat: 29.926778265903522
            },
            {
                lng: 48.0145683123761,
                lat: 30.452456773392598
            },
            {
                lng: 48.004698113808324,
                lat: 30.985137437457244
            },
            {
                lng: 47.68528608581227,
                lat: 30.984853217079632
            },
            {
                lng: 47.8492037290421,
                lat: 31.70917593029867
            },
            {
                lng: 47.33466149271191,
                lat: 32.46915538179911
            },
            {
                lng: 46.10936160663932,
                lat: 33.017287299119005
            },
            {
                lng: 45.41669070819904,
                lat: 33.967797756479584
            },
            {
                lng: 45.64845950702809,
                lat: 34.748137722303014
            },
            {
                lng: 46.15178795755094,
                lat: 35.09325877536429
            },
            {
                lng: 46.0763403664048,
                lat: 35.67738332777549
            },
            {
                lng: 45.42061811705321,
                lat: 35.977545884742824
            },
            {
                lng: 44.77267710159504,
                lat: 37.17043692561684
            },
            {
                lng: 44.77267,
                lat: 37.17045
            },
            {
                lng: 44.22575564960053,
                lat: 37.97158437758935
            },
            {
                lng: 44.421402622257546,
                lat: 38.28128123631454
            },
            {
                lng: 44.10922529478234,
                lat: 39.4281362981681
            },
            {
                lng: 44.79398969908195,
                lat: 39.71300263117705
            },
            {
                lng: 44.95268802265031,
                lat: 39.33576467544637
            },
            {
                lng: 45.45772179543877,
                lat: 38.87413910578306
            },
            {
                lng: 46.14362308124882,
                lat: 38.74120148371222
            },
            {
                lng: 46.50571984231797,
                lat: 38.770605373686294
            },
            {
                lng: 47.685079380083096,
                lat: 39.50836395930122
            },
            {
                lng: 48.06009524922524,
                lat: 39.58223541926246
            },
            {
                lng: 48.35552941263788,
                lat: 39.28876496027691
            },
            {
                lng: 48.01074425638648,
                lat: 38.79401479751452
            },
            {
                lng: 48.63437544128482,
                lat: 38.27037750910097
            },
            {
                lng: 48.88324913920249,
                lat: 38.32024526626262
            },
            {
                lng: 49.19961225769334,
                lat: 37.582874253889884
            },
            {
                lng: 50.14777143738462,
                lat: 37.37456655532134
            },
            {
                lng: 50.84235436381971,
                lat: 36.8728142359834
            },
            {
                lng: 52.264024692601424,
                lat: 36.7004216578577
            },
            {
                lng: 53.82578982932642,
                lat: 36.965030829408235
            },
            {
                lng: 53.92159793479556,
                lat: 37.19891836196126
            },
            {
                lng: 54.800303989486565,
                lat: 37.392420762678185
            },
            {
                lng: 55.51157840355191,
                lat: 37.96411713312317
            },
            {
                lng: 56.18037479027333,
                lat: 37.93512665460743
            },
            {
                lng: 56.61936608259282,
                lat: 38.121394354803485
            },
            {
                lng: 57.330433790928986,
                lat: 38.02922943781094
            },
            {
                lng: 58.436154412678206,
                lat: 37.5223094752438
            },
            {
                lng: 59.23476199731681,
                lat: 37.41298798273034
            },
            {
                lng: 60.37763797388388,
                lat: 36.52738312432837
            },
            {
                lng: 61.123070509694145,
                lat: 36.491597194966246
            },
            {
                lng: 61.210817091725744,
                lat: 35.650072333309225
            },
            {
                lng: 60.80319339380745,
                lat: 34.40410187431986
            },
            {
                lng: 60.52842980331158,
                lat: 33.676446031218006
            },
            {
                lng: 60.963700392506006,
                lat: 33.52883230237626
            },
            {
                lng: 60.536077915290775,
                lat: 32.98126882581157
            },
            {
                lng: 60.863654819588966,
                lat: 32.18291962333443
            },
            {
                lng: 60.94194461451113,
                lat: 31.548074652628753
            },
            {
                lng: 61.69931440618083,
                lat: 31.379506130492672
            },
            {
                lng: 61.781221551363444,
                lat: 30.735850328081238
            },
            {
                lng: 60.87424848820879,
                lat: 29.829238999952608
            },
            {
                lng: 61.36930870956494,
                lat: 29.303276272085924
            },
            {
                lng: 61.77186811711863,
                lat: 28.6993338078908
            },
            {
                lng: 62.72783043808599,
                lat: 28.25964488373539
            },
            {
                lng: 62.75542565292986,
                lat: 27.378923448184988
            },
            {
                lng: 63.2338977395203,
                lat: 27.21704702403071
            },
            {
                lng: 63.31663170761959,
                lat: 26.756532497661667
            },
            {
                lng: 61.87418745305655,
                lat: 26.239974880472104
            },
            {
                lng: 61.49736290878419,
                lat: 25.0782370061185
            },
            {
                lng: 59.616134067630846,
                lat: 25.380156561783778
            },
            {
                lng: 58.525761346272304,
                lat: 25.60996165618573
            },
            {
                lng: 57.39725141788239,
                lat: 25.73990204518364
            },
            {
                lng: 56.970765822177555,
                lat: 26.966106268821363
            },
            {
                lng: 56.492138706290206,
                lat: 27.143304755150197
            },
            {
                lng: 55.723710158110066,
                lat: 26.964633490501043
            },
            {
                lng: 54.71508955263727,
                lat: 26.480657863871514
            },
            {
                lng: 53.49309695823135,
                lat: 26.81236888275305
            },
            {
                lng: 52.48359785340961,
                lat: 27.580849107365495
            },
            {
                lng: 51.52076256694742,
                lat: 27.865689602158298
            },
            {
                lng: 50.85294803243954,
                lat: 28.814520575469388
            },
            {
                lng: 50.115008579311585,
                lat: 30.147772528599717
            },
            {
                lng: 49.576850213423995,
                lat: 29.985715236932407
            },
            {
                lng: 48.94133344909855,
                lat: 30.317090359004037
            },
            {
                lng: 48.567971225789755,
                lat: 29.926778265903522
            }
        ]
    },
    SY: {
        name: 'Syrian Arab Republic',
        type: 'Polygon',
        coordinates: [
            {
                lng: 35.71991824722275,
                lat: 32.709192409794866
            },
            {
                lng: 35.70079796727475,
                lat: 32.71601369885738
            },
            {
                lng: 35.836396925608625,
                lat: 32.86812327730851
            },
            {
                lng: 35.82110070165024,
                lat: 33.2774264592763
            },
            {
                lng: 36.066460402172055,
                lat: 33.82491242119255
            },
            {
                lng: 36.61175011571589,
                lat: 34.20178864189718
            },
            {
                lng: 36.4481942075121,
                lat: 34.59393524834407
            },
            {
                lng: 35.99840254084364,
                lat: 34.644914048800004
            },
            {
                lng: 35.905023227692226,
                lat: 35.410009467097325
            },
            {
                lng: 36.149762811026534,
                lat: 35.82153473565367
            },
            {
                lng: 36.417550083163036,
                lat: 36.04061697035506
            },
            {
                lng: 36.6853890317318,
                lat: 36.259699205056464
            },
            {
                lng: 36.7394942563414,
                lat: 36.81752045343109
            },
            {
                lng: 37.06676110204583,
                lat: 36.62303620050062
            },
            {
                lng: 38.1677274920242,
                lat: 36.90121043552777
            },
            {
                lng: 38.6998913917659,
                lat: 36.71292735447234
            },
            {
                lng: 39.52258019385255,
                lat: 36.71605377862599
            },
            {
                lng: 40.67325931169569,
                lat: 37.09127635349729
            },
            {
                lng: 41.21208947120305,
                lat: 37.074352321921694
            },
            {
                lng: 42.34959109881177,
                lat: 37.2298725449041
            },
            {
                lng: 41.83706424334096,
                lat: 36.605853786763575
            },
            {
                lng: 41.289707472505455,
                lat: 36.35881460219227
            },
            {
                lng: 41.383965285005814,
                lat: 35.628316555314356
            },
            {
                lng: 41.006158888519934,
                lat: 34.41937226006212
            },
            {
                lng: 38.792340529136084,
                lat: 33.378686428352225
            },
            {
                lng: 36.834062127435544,
                lat: 32.312937526980775
            },
            {
                lng: 35.71991824722275,
                lat: 32.709192409794866
            }
        ]
    },
    AM: {
        name: 'Armenia',
        type: 'Polygon',
        coordinates: [
            {
                lng: 46.50571984231797,
                lat: 38.770605373686294
            },
            {
                lng: 46.14362308124882,
                lat: 38.74120148371222
            },
            {
                lng: 45.73537926614301,
                lat: 39.31971914321974
            },
            {
                lng: 45.73997846861698,
                lat: 39.47399913182713
            },
            {
                lng: 45.29814497252146,
                lat: 39.471751207022436
            },
            {
                lng: 45.00198733905675,
                lat: 39.740003567049555
            },
            {
                lng: 44.79398969908195,
                lat: 39.71300263117705
            },
            {
                lng: 44.4000085792887,
                lat: 40.00500031184228
            },
            {
                lng: 43.65643639504094,
                lat: 40.253563951166186
            },
            {
                lng: 43.75265791196841,
                lat: 40.74020091405876
            },
            {
                lng: 43.58274580259273,
                lat: 41.09214325618257
            },
            {
                lng: 44.97248009621808,
                lat: 41.248128567055595
            },
            {
                lng: 45.17949588397934,
                lat: 40.98535390885141
            },
            {
                lng: 45.56035118997045,
                lat: 40.812289537105926
            },
            {
                lng: 45.35917483905817,
                lat: 40.56150381119346
            },
            {
                lng: 45.89190717955509,
                lat: 40.21847565364
            },
            {
                lng: 45.61001224140293,
                lat: 39.89999380142518
            },
            {
                lng: 46.034534132680676,
                lat: 39.628020738273065
            },
            {
                lng: 46.48349897643246,
                lat: 39.464154771475535
            },
            {
                lng: 46.50571984231797,
                lat: 38.770605373686294
            }
        ]
    },
    SE: {
        name: 'Sweden',
        type: 'Polygon',
        coordinates: [
            {
                lng: 11.027368605196868,
                lat: 58.85614940045936
            },
            {
                lng: 11.468271925511146,
                lat: 59.43239329694604
            },
            {
                lng: 12.3003658382749,
                lat: 60.11793284773003
            },
            {
                lng: 12.631146681375185,
                lat: 61.293571682370136
            },
            {
                lng: 11.992064243221563,
                lat: 61.80036245385655
            },
            {
                lng: 11.930569288794231,
                lat: 63.12831757267698
            },
            {
                lng: 12.579935336973934,
                lat: 64.06621898055833
            },
            {
                lng: 13.571916131248713,
                lat: 64.04911408146971
            },
            {
                lng: 13.919905226302204,
                lat: 64.44542064071608
            },
            {
                lng: 13.55568973150909,
                lat: 64.78702769638151
            },
            {
                lng: 15.108411492583002,
                lat: 66.19386688909547
            },
            {
                lng: 16.108712192456778,
                lat: 67.30245555283689
            },
            {
                lng: 16.768878614985482,
                lat: 68.0139366726314
            },
            {
                lng: 17.729181756265348,
                lat: 68.01055186631628
            },
            {
                lng: 17.993868442464333,
                lat: 68.56739126247736
            },
            {
                lng: 19.878559604581255,
                lat: 68.40719432237258
            },
            {
                lng: 20.025268995857886,
                lat: 69.0651386583127
            },
            {
                lng: 20.645592889089528,
                lat: 69.10624726020087
            },
            {
                lng: 21.978534783626117,
                lat: 68.6168456081807
            },
            {
                lng: 23.53947309743444,
                lat: 67.93600861273525
            },
            {
                lng: 23.565879754335583,
                lat: 66.39605093043743
            },
            {
                lng: 23.903378533633802,
                lat: 66.00692739527962
            },
            {
                lng: 22.18317345550193,
                lat: 65.72374054632017
            },
            {
                lng: 21.21351687997722,
                lat: 65.02600535751527
            },
            {
                lng: 21.369631381930958,
                lat: 64.41358795842429
            },
            {
                lng: 19.77887576669022,
                lat: 63.60955434839504
            },
            {
                lng: 17.84777916837521,
                lat: 62.74940013289681
            },
            {
                lng: 17.119554884518124,
                lat: 61.34116567651097
            },
            {
                lng: 17.83134606290639,
                lat: 60.63658336042741
            },
            {
                lng: 18.78772179533209,
                lat: 60.081914374422595
            },
            {
                lng: 17.86922488777634,
                lat: 58.9537661810587
            },
            {
                lng: 16.829185011470088,
                lat: 58.71982697207339
            },
            {
                lng: 16.447709588291474,
                lat: 57.041118069071885
            },
            {
                lng: 15.879785597403783,
                lat: 56.10430186626866
            },
            {
                lng: 14.666681349352075,
                lat: 56.200885118222175
            },
            {
                lng: 14.100721062891465,
                lat: 55.40778107362265
            },
            {
                lng: 12.942910597392057,
                lat: 55.36173737245058
            },
            {
                lng: 12.625100538797028,
                lat: 56.30708018658197
            },
            {
                lng: 11.787942335668674,
                lat: 57.44181712506307
            },
            {
                lng: 11.027368605196868,
                lat: 58.85614940045936
            }
        ]
    },
    BY: {
        name: 'Belarus',
        type: 'Polygon',
        coordinates: [
            {
                lng: 28.17670942557794,
                lat: 56.16912995057879
            },
            {
                lng: 29.229513380660308,
                lat: 55.91834422466636
            },
            {
                lng: 29.371571893030673,
                lat: 55.670090643936184
            },
            {
                lng: 29.896294386522356,
                lat: 55.78946320253041
            },
            {
                lng: 30.87390913262001,
                lat: 55.55097646750341
            },
            {
                lng: 30.971835971813135,
                lat: 55.08154775656404
            },
            {
                lng: 30.75753380709872,
                lat: 54.81177094178432
            },
            {
                lng: 31.38447228366374,
                lat: 54.15705638286238
            },
            {
                lng: 31.79142418796224,
                lat: 53.974638576872124
            },
            {
                lng: 31.731272820774507,
                lat: 53.79402944601202
            },
            {
                lng: 32.405598585751164,
                lat: 53.61804535584204
            },
            {
                lng: 32.69364301934604,
                lat: 53.35142080343218
            },
            {
                lng: 32.30451948418823,
                lat: 53.13272614197291
            },
            {
                lng: 31.49764,
                lat: 53.16743000000014
            },
            {
                lng: 31.305200636528014,
                lat: 53.07399587667321
            },
            {
                lng: 31.54001834486226,
                lat: 52.74205231384636
            },
            {
                lng: 31.78597,
                lat: 52.1016800000001
            },
            {
                lng: 31.78599244755525,
                lat: 52.1016775699397
            },
            {
                lng: 30.927549269338982,
                lat: 52.04235342061439
            },
            {
                lng: 30.619454380014815,
                lat: 51.822806098022454
            },
            {
                lng: 30.555117221811457,
                lat: 51.31950348571566
            },
            {
                lng: 30.157363722460897,
                lat: 51.41613841410147
            },
            {
                lng: 29.25493818534784,
                lat: 51.36823436136689
            },
            {
                lng: 28.992835320763533,
                lat: 51.602044379271476
            },
            {
                lng: 28.61761274589225,
                lat: 51.42771393493484
            },
            {
                lng: 28.24161502453657,
                lat: 51.57222707783907
            },
            {
                lng: 27.454066196408405,
                lat: 51.592303371784396
            },
            {
                lng: 26.337958611768556,
                lat: 51.83228872334797
            },
            {
                lng: 25.32778771332701,
                lat: 51.91065603291855
            },
            {
                lng: 24.553106316839518,
                lat: 51.888461005249184
            },
            {
                lng: 24.00507775238418,
                lat: 51.617443956094405
            },
            {
                lng: 23.52707075368437,
                lat: 51.57845408793031
            },
            {
                lng: 23.508002150168693,
                lat: 52.02364655212473
            },
            {
                lng: 23.199493849386187,
                lat: 52.48697744405367
            },
            {
                lng: 23.79919884613338,
                lat: 52.69109935160657
            },
            {
                lng: 23.80493493011778,
                lat: 53.089731350306074
            },
            {
                lng: 23.527535841575002,
                lat: 53.470121568406555
            },
            {
                lng: 23.48412763844985,
                lat: 53.91249766704114
            },
            {
                lng: 24.450683628037037,
                lat: 53.905702216194754
            },
            {
                lng: 25.536353794056993,
                lat: 54.28242340760253
            },
            {
                lng: 25.7684326514798,
                lat: 54.84696259217509
            },
            {
                lng: 26.58827924979039,
                lat: 55.16717560487167
            },
            {
                lng: 26.494331495883756,
                lat: 55.615106919977634
            },
            {
                lng: 27.10245975109453,
                lat: 55.783313707087686
            },
            {
                lng: 28.17670942557794,
                lat: 56.16912995057879
            }
        ]
    },
    UA: {
        name: 'Ukraine',
        type: 'Polygon',
        coordinates: [
            {
                lng: 31.78599244755525,
                lat: 52.1016775699397
            },
            {
                lng: 32.15944000000013,
                lat: 52.06125000000014
            },
            {
                lng: 32.41205813978769,
                lat: 52.28869497334978
            },
            {
                lng: 32.715760532367085,
                lat: 52.23846548116211
            },
            {
                lng: 33.75269982273579,
                lat: 52.3350745713318
            },
            {
                lng: 34.39173058445709,
                lat: 51.768881740925906
            },
            {
                lng: 34.141978387190534,
                lat: 51.566413479206346
            },
            {
                lng: 34.22481570815427,
                lat: 51.25599315042888
            },
            {
                lng: 35.02218305841791,
                lat: 51.2075723333715
            },
            {
                lng: 35.37791,
                lat: 50.77394
            },
            {
                lng: 35.356116163887975,
                lat: 50.57719737405904
            },
            {
                lng: 36.62616784032542,
                lat: 50.225590928745135
            },
            {
                lng: 37.39345950699516,
                lat: 50.38395335550365
            },
            {
                lng: 38.01063113785693,
                lat: 49.9156615260747
            },
            {
                lng: 38.59498823421342,
                lat: 49.9264619004237
            },
            {
                lng: 40.06904000000014,
                lat: 49.60105
            },
            {
                lng: 40.080789015469406,
                lat: 49.30742991799934
            },
            {
                lng: 39.67465,
                lat: 48.78382000000016
            },
            {
                lng: 39.89562000000018,
                lat: 48.23241
            },
            {
                lng: 39.738277622238854,
                lat: 47.89893707945197
            },
            {
                lng: 38.77057,
                lat: 47.825620000000185
            },
            {
                lng: 38.25511233902978,
                lat: 47.54640045835691
            },
            {
                lng: 38.223538038899335,
                lat: 47.10218984637595
            },
            {
                lng: 37.4251371599899,
                lat: 47.02222056740419
            },
            {
                lng: 36.75985477066445,
                lat: 46.69870026304102
            },
            {
                lng: 35.82368452326489,
                lat: 46.64596446388714
            },
            {
                lng: 34.96234174982385,
                lat: 46.27319651954974
            },
            {
                lng: 35.01265897004737,
                lat: 45.73772519982549
            },
            {
                lng: 34.861792128174045,
                lat: 45.76818243191957
            },
            {
                lng: 34.73201738827845,
                lat: 45.96566573176062
            },
            {
                lng: 34.41040172853718,
                lat: 46.005162391728845
            },
            {
                lng: 33.69946184910907,
                lat: 46.219572831556434
            },
            {
                lng: 33.435988094713366,
                lat: 45.971917370797485
            },
            {
                lng: 33.29856733575474,
                lat: 46.08059845639781
            },
            {
                lng: 31.744140252415207,
                lat: 46.33334788673733
            },
            {
                lng: 31.67530724460255,
                lat: 46.70624502215554
            },
            {
                lng: 30.74874881360921,
                lat: 46.583100084004116
            },
            {
                lng: 30.377608676888883,
                lat: 46.03241018328572
            },
            {
                lng: 29.603289015427436,
                lat: 45.293308010431126
            },
            {
                lng: 29.149724969201653,
                lat: 45.464925442072456
            },
            {
                lng: 28.67977949393938,
                lat: 45.304030870131704
            },
            {
                lng: 28.23355350109904,
                lat: 45.48828318946829
            },
            {
                lng: 28.485269402792767,
                lat: 45.5969070501459
            },
            {
                lng: 28.65998742037158,
                lat: 45.93998688413164
            },
            {
                lng: 28.933717482221596,
                lat: 46.25883047137256
            },
            {
                lng: 28.862972446414062,
                lat: 46.43788930926383
            },
            {
                lng: 29.07210696789929,
                lat: 46.517677720722496
            },
            {
                lng: 29.170653924279804,
                lat: 46.37926239682872
            },
            {
                lng: 29.759971958136394,
                lat: 46.34998769793536
            },
            {
                lng: 30.024658644335375,
                lat: 46.42393667254504
            },
            {
                lng: 29.838210076626297,
                lat: 46.52532583270169
            },
            {
                lng: 29.908851759569302,
                lat: 46.67436066343146
            },
            {
                lng: 29.559674106573112,
                lat: 46.928582872091326
            },
            {
                lng: 29.415135125452736,
                lat: 47.34664520933258
            },
            {
                lng: 29.05086795422727,
                lat: 47.5102269557525
            },
            {
                lng: 29.12269819511303,
                lat: 47.849095160506465
            },
            {
                lng: 28.670891147585166,
                lat: 48.1181485052341
            },
            {
                lng: 28.259546746541844,
                lat: 48.15556224221342
            },
            {
                lng: 27.522537469195157,
                lat: 48.467119452501116
            },
            {
                lng: 26.857823520624805,
                lat: 48.368210761094495
            },
            {
                lng: 26.619336785597795,
                lat: 48.22072622333347
            },
            {
                lng: 26.19745039236693,
                lat: 48.22088125263035
            },
            {
                lng: 25.9459411964024,
                lat: 47.987148749374214
            },
            {
                lng: 25.20774336111299,
                lat: 47.89105642352747
            },
            {
                lng: 24.866317172960578,
                lat: 47.737525743188314
            },
            {
                lng: 24.40205610525038,
                lat: 47.98187775328043
            },
            {
                lng: 23.76095828623741,
                lat: 47.985598456405455
            },
            {
                lng: 23.142236362406805,
                lat: 48.09634105080695
            },
            {
                lng: 22.710531447040495,
                lat: 47.88219391538941
            },
            {
                lng: 22.640819939878725,
                lat: 48.150239569687415
            },
            {
                lng: 22.085608351334855,
                lat: 48.42226430927179
            },
            {
                lng: 22.28084191253356,
                lat: 48.82539215758067
            },
            {
                lng: 22.558137648211755,
                lat: 49.085738023467144
            },
            {
                lng: 22.776418898212626,
                lat: 49.02739533140962
            },
            {
                lng: 22.518450148211603,
                lat: 49.47677358661974
            },
            {
                lng: 23.426508416444392,
                lat: 50.30850576435745
            },
            {
                lng: 23.92275719574326,
                lat: 50.42488108987874
            },
            {
                lng: 24.029985792748903,
                lat: 50.70540660257518
            },
            {
                lng: 23.52707075368437,
                lat: 51.57845408793031
            },
            {
                lng: 24.00507775238418,
                lat: 51.617443956094405
            },
            {
                lng: 24.553106316839518,
                lat: 51.888461005249184
            },
            {
                lng: 25.32778771332701,
                lat: 51.91065603291855
            },
            {
                lng: 26.337958611768556,
                lat: 51.83228872334797
            },
            {
                lng: 27.454066196408405,
                lat: 51.592303371784396
            },
            {
                lng: 28.24161502453657,
                lat: 51.57222707783907
            },
            {
                lng: 28.61761274589225,
                lat: 51.42771393493484
            },
            {
                lng: 28.992835320763533,
                lat: 51.602044379271476
            },
            {
                lng: 29.25493818534784,
                lat: 51.36823436136689
            },
            {
                lng: 30.157363722460897,
                lat: 51.41613841410147
            },
            {
                lng: 30.555117221811457,
                lat: 51.31950348571566
            },
            {
                lng: 30.619454380014815,
                lat: 51.822806098022454
            },
            {
                lng: 30.927549269338982,
                lat: 52.04235342061439
            },
            {
                lng: 31.78599244755525,
                lat: 52.1016775699397
            }
        ]
    },
    PL: {
        name: 'Poland',
        type: 'Polygon',
        coordinates: [
            {
                lng: 23.48412763844985,
                lat: 53.91249766704114
            },
            {
                lng: 23.527535841575002,
                lat: 53.470121568406555
            },
            {
                lng: 23.80493493011778,
                lat: 53.089731350306074
            },
            {
                lng: 23.79919884613338,
                lat: 52.69109935160657
            },
            {
                lng: 23.199493849386187,
                lat: 52.48697744405367
            },
            {
                lng: 23.508002150168693,
                lat: 52.02364655212473
            },
            {
                lng: 23.52707075368437,
                lat: 51.57845408793031
            },
            {
                lng: 24.029985792748903,
                lat: 50.70540660257518
            },
            {
                lng: 23.92275719574326,
                lat: 50.42488108987874
            },
            {
                lng: 23.426508416444392,
                lat: 50.30850576435745
            },
            {
                lng: 22.518450148211603,
                lat: 49.47677358661974
            },
            {
                lng: 22.776418898212626,
                lat: 49.02739533140962
            },
            {
                lng: 22.558137648211755,
                lat: 49.085738023467144
            },
            {
                lng: 21.607808058364213,
                lat: 49.47010732685409
            },
            {
                lng: 20.887955356538413,
                lat: 49.32877228453583
            },
            {
                lng: 20.415839471119853,
                lat: 49.43145335549977
            },
            {
                lng: 19.825022820726872,
                lat: 49.21712535256923
            },
            {
                lng: 19.320712517990472,
                lat: 49.571574001659194
            },
            {
                lng: 18.90957482267632,
                lat: 49.435845852244576
            },
            {
                lng: 18.853144158613617,
                lat: 49.49622976337764
            },
            {
                lng: 18.392913852622172,
                lat: 49.98862864847075
            },
            {
                lng: 17.64944502123899,
                lat: 50.049038397819956
            },
            {
                lng: 17.55456709155112,
                lat: 50.36214590107642
            },
            {
                lng: 16.86876915860566,
                lat: 50.47397370055603
            },
            {
                lng: 16.719475945714436,
                lat: 50.21574656839354
            },
            {
                lng: 16.176253289462267,
                lat: 50.42260732685791
            },
            {
                lng: 16.23862674323857,
                lat: 50.69773265237984
            },
            {
                lng: 15.490972120839729,
                lat: 50.78472992614321
            },
            {
                lng: 15.01699588385867,
                lat: 51.10667409932158
            },
            {
                lng: 14.607098422919535,
                lat: 51.74518809671997
            },
            {
                lng: 14.685026482815687,
                lat: 52.0899474147552
            },
            {
                lng: 14.437599725002201,
                lat: 52.62485016540839
            },
            {
                lng: 14.074521111719434,
                lat: 52.98126251892535
            },
            {
                lng: 14.353315463934138,
                lat: 53.24817129171297
            },
            {
                lng: 14.119686313542559,
                lat: 53.75702912049104
            },
            {
                lng: 14.802900424873458,
                lat: 54.05070628520575
            },
            {
                lng: 16.36347700365573,
                lat: 54.513158677785725
            },
            {
                lng: 17.622831658608675,
                lat: 54.85153595643291
            },
            {
                lng: 18.62085859546164,
                lat: 54.68260569927078
            },
            {
                lng: 18.696254510175464,
                lat: 54.43871877706929
            },
            {
                lng: 19.660640089606403,
                lat: 54.42608388937393
            },
            {
                lng: 20.892244500418627,
                lat: 54.31252492941253
            },
            {
                lng: 22.731098667092652,
                lat: 54.327536932993326
            },
            {
                lng: 23.24398725758951,
                lat: 54.22056671814914
            },
            {
                lng: 23.48412763844985,
                lat: 53.91249766704114
            }
        ]
    },
    AT: {
        name: 'Austria',
        type: 'Polygon',
        coordinates: [
            {
                lng: 16.979666782304037,
                lat: 48.123497015976305
            },
            {
                lng: 16.90375410326726,
                lat: 47.71486562762833
            },
            {
                lng: 16.340584344150415,
                lat: 47.71290192320123
            },
            {
                lng: 16.534267612380376,
                lat: 47.49617096616912
            },
            {
                lng: 16.202298211337364,
                lat: 46.85238597267696
            },
            {
                lng: 16.011663852612656,
                lat: 46.6836107448117
            },
            {
                lng: 15.137091912504985,
                lat: 46.65870270444703
            },
            {
                lng: 14.63247155117483,
                lat: 46.43181732846955
            },
            {
                lng: 13.806475457421527,
                lat: 46.509306138691215
            },
            {
                lng: 12.376485223040817,
                lat: 46.76755910906985
            },
            {
                lng: 12.153088006243054,
                lat: 47.11539317482645
            },
            {
                lng: 11.16482791509327,
                lat: 46.94157949481273
            },
            {
                lng: 11.048555942436536,
                lat: 46.75135854754634
            },
            {
                lng: 10.44270145024663,
                lat: 46.89354625099743
            },
            {
                lng: 9.932448357796659,
                lat: 46.92072805438296
            },
            {
                lng: 9.479969516649021,
                lat: 47.102809963563374
            },
            {
                lng: 9.632931756232978,
                lat: 47.34760122332999
            },
            {
                lng: 9.59422610844635,
                lat: 47.52505809182027
            },
            {
                lng: 9.89606814946319,
                lat: 47.580196845075704
            },
            {
                lng: 10.402083774465211,
                lat: 47.30248769793916
            },
            {
                lng: 10.544504021861599,
                lat: 47.5663992376538
            },
            {
                lng: 11.426414015354737,
                lat: 47.523766181012974
            },
            {
                lng: 12.141357456112788,
                lat: 47.703083401065776
            },
            {
                lng: 12.620759718484493,
                lat: 47.67238760028441
            },
            {
                lng: 12.932626987365948,
                lat: 47.467645575544
            },
            {
                lng: 13.02585127122049,
                lat: 47.63758352313583
            },
            {
                lng: 12.884102817443875,
                lat: 48.28914581968786
            },
            {
                lng: 13.243357374737,
                lat: 48.416114813829054
            },
            {
                lng: 13.595945672264437,
                lat: 48.87717194273715
            },
            {
                lng: 14.338897739324722,
                lat: 48.55530528420721
            },
            {
                lng: 14.901447381254057,
                lat: 48.964401760445824
            },
            {
                lng: 15.253415561593982,
                lat: 49.03907420510758
            },
            {
                lng: 16.02964725105022,
                lat: 48.73389903420793
            },
            {
                lng: 16.499282667718774,
                lat: 48.78580801044511
            },
            {
                lng: 16.960288120194576,
                lat: 48.5969823268506
            },
            {
                lng: 16.879982944413,
                lat: 48.47001333270947
            },
            {
                lng: 16.979666782304037,
                lat: 48.123497015976305
            }
        ]
    },
    HU: {
        name: 'Hungary',
        type: 'Polygon',
        coordinates: [
            {
                lng: 22.085608351334855,
                lat: 48.42226430927179
            },
            {
                lng: 22.640819939878725,
                lat: 48.150239569687415
            },
            {
                lng: 22.710531447040495,
                lat: 47.88219391538941
            },
            {
                lng: 22.099767693782837,
                lat: 47.6724392767167
            },
            {
                lng: 21.626514926853872,
                lat: 46.99423777931816
            },
            {
                lng: 21.02195234547125,
                lat: 46.3160879583519
            },
            {
                lng: 20.220192498462836,
                lat: 46.127468980486555
            },
            {
                lng: 19.596044549241583,
                lat: 46.17172984474454
            },
            {
                lng: 18.82983808764996,
                lat: 45.90887767189193
            },
            {
                lng: 18.829824792873946,
                lat: 45.908872358025285
            },
            {
                lng: 18.45606245288286,
                lat: 45.75948110613615
            },
            {
                lng: 17.630066359129557,
                lat: 45.95176911069419
            },
            {
                lng: 16.8825150895953,
                lat: 46.38063182228444
            },
            {
                lng: 16.564808383864857,
                lat: 46.50375092221983
            },
            {
                lng: 16.370504998447416,
                lat: 46.841327216166505
            },
            {
                lng: 16.202298211337364,
                lat: 46.85238597267696
            },
            {
                lng: 16.534267612380376,
                lat: 47.49617096616912
            },
            {
                lng: 16.340584344150415,
                lat: 47.71290192320123
            },
            {
                lng: 16.90375410326726,
                lat: 47.71486562762833
            },
            {
                lng: 16.979666782304037,
                lat: 48.123497015976305
            },
            {
                lng: 17.48847293464982,
                lat: 47.867466132186216
            },
            {
                lng: 17.857132602620027,
                lat: 47.75842886005037
            },
            {
                lng: 18.696512892336926,
                lat: 47.880953681014404
            },
            {
                lng: 18.77702477384767,
                lat: 48.081768296900634
            },
            {
                lng: 19.17436486173989,
                lat: 48.11137889260387
            },
            {
                lng: 19.661363559658497,
                lat: 48.26661489520866
            },
            {
                lng: 19.769470656013112,
                lat: 48.202691148463614
            },
            {
                lng: 20.239054396249347,
                lat: 48.32756724709692
            },
            {
                lng: 20.473562045989866,
                lat: 48.562850043321816
            },
            {
                lng: 20.801293979584926,
                lat: 48.623854071642384
            },
            {
                lng: 21.872236362401736,
                lat: 48.31997081155002
            },
            {
                lng: 22.085608351334855,
                lat: 48.42226430927179
            }
        ]
    },
    MD: {
        name: 'Moldova',
        type: 'Polygon',
        coordinates: [
            {
                lng: 26.619336785597795,
                lat: 48.22072622333347
            },
            {
                lng: 26.857823520624805,
                lat: 48.368210761094495
            },
            {
                lng: 27.522537469195157,
                lat: 48.467119452501116
            },
            {
                lng: 28.259546746541844,
                lat: 48.15556224221342
            },
            {
                lng: 28.670891147585166,
                lat: 48.1181485052341
            },
            {
                lng: 29.12269819511303,
                lat: 47.849095160506465
            },
            {
                lng: 29.05086795422727,
                lat: 47.5102269557525
            },
            {
                lng: 29.415135125452736,
                lat: 47.34664520933258
            },
            {
                lng: 29.559674106573112,
                lat: 46.928582872091326
            },
            {
                lng: 29.908851759569302,
                lat: 46.67436066343146
            },
            {
                lng: 29.838210076626297,
                lat: 46.52532583270169
            },
            {
                lng: 30.024658644335375,
                lat: 46.42393667254504
            },
            {
                lng: 29.759971958136394,
                lat: 46.34998769793536
            },
            {
                lng: 29.170653924279804,
                lat: 46.37926239682872
            },
            {
                lng: 29.07210696789929,
                lat: 46.517677720722496
            },
            {
                lng: 28.862972446414062,
                lat: 46.43788930926383
            },
            {
                lng: 28.933717482221596,
                lat: 46.25883047137256
            },
            {
                lng: 28.65998742037158,
                lat: 45.93998688413164
            },
            {
                lng: 28.485269402792767,
                lat: 45.5969070501459
            },
            {
                lng: 28.23355350109904,
                lat: 45.48828318946829
            },
            {
                lng: 28.0544429867754,
                lat: 45.944586086605625
            },
            {
                lng: 28.160017937947714,
                lat: 46.37156260841722
            },
            {
                lng: 28.128030226359044,
                lat: 46.810476386088254
            },
            {
                lng: 27.551166212684848,
                lat: 47.40511709247083
            },
            {
                lng: 27.233872918412743,
                lat: 47.82677094175638
            },
            {
                lng: 26.924176059687568,
                lat: 48.123264472030996
            },
            {
                lng: 26.619336785597795,
                lat: 48.22072622333347
            }
        ]
    },
    RO: {
        name: 'Romania',
        type: 'Polygon',
        coordinates: [
            {
                lng: 28.23355350109904,
                lat: 45.48828318946829
            },
            {
                lng: 28.67977949393938,
                lat: 45.304030870131704
            },
            {
                lng: 29.149724969201653,
                lat: 45.464925442072456
            },
            {
                lng: 29.603289015427436,
                lat: 45.293308010431126
            },
            {
                lng: 29.62654340995877,
                lat: 45.0353909368624
            },
            {
                lng: 29.141611769331835,
                lat: 44.820210272799045
            },
            {
                lng: 28.8378577003202,
                lat: 44.913873806328056
            },
            {
                lng: 28.558081495891997,
                lat: 43.70746165625813
            },
            {
                lng: 27.970107049275075,
                lat: 43.81246816667522
            },
            {
                lng: 27.242399529740908,
                lat: 44.175986029632405
            },
            {
                lng: 26.065158725699746,
                lat: 43.94349376075127
            },
            {
                lng: 25.569271681426926,
                lat: 43.68844472917472
            },
            {
                lng: 24.100679152124172,
                lat: 43.74105133724785
            },
            {
                lng: 23.332302280376325,
                lat: 43.897010809904714
            },
            {
                lng: 22.944832391051847,
                lat: 43.82378530534713
            },
            {
                lng: 22.65714969248299,
                lat: 44.23492300066128
            },
            {
                lng: 22.4740084164406,
                lat: 44.40922760678177
            },
            {
                lng: 22.705725538837356,
                lat: 44.57800283464702
            },
            {
                lng: 22.459022251075936,
                lat: 44.7025171982543
            },
            {
                lng: 22.14508792490281,
                lat: 44.47842234962059
            },
            {
                lng: 21.56202273935361,
                lat: 44.7689472519655
            },
            {
                lng: 21.48352623870221,
                lat: 45.18117015235788
            },
            {
                lng: 20.874312778413355,
                lat: 45.416375433934235
            },
            {
                lng: 20.762174920339987,
                lat: 45.734573065771485
            },
            {
                lng: 20.220192498462836,
                lat: 46.127468980486555
            },
            {
                lng: 21.02195234547125,
                lat: 46.3160879583519
            },
            {
                lng: 21.626514926853872,
                lat: 46.99423777931816
            },
            {
                lng: 22.099767693782837,
                lat: 47.6724392767167
            },
            {
                lng: 22.710531447040495,
                lat: 47.88219391538941
            },
            {
                lng: 23.142236362406805,
                lat: 48.09634105080695
            },
            {
                lng: 23.76095828623741,
                lat: 47.985598456405455
            },
            {
                lng: 24.40205610525038,
                lat: 47.98187775328043
            },
            {
                lng: 24.866317172960578,
                lat: 47.737525743188314
            },
            {
                lng: 25.20774336111299,
                lat: 47.89105642352747
            },
            {
                lng: 25.9459411964024,
                lat: 47.987148749374214
            },
            {
                lng: 26.19745039236693,
                lat: 48.22088125263035
            },
            {
                lng: 26.619336785597795,
                lat: 48.22072622333347
            },
            {
                lng: 26.924176059687568,
                lat: 48.123264472030996
            },
            {
                lng: 27.233872918412743,
                lat: 47.82677094175638
            },
            {
                lng: 27.551166212684848,
                lat: 47.40511709247083
            },
            {
                lng: 28.128030226359044,
                lat: 46.810476386088254
            },
            {
                lng: 28.160017937947714,
                lat: 46.37156260841722
            },
            {
                lng: 28.0544429867754,
                lat: 45.944586086605625
            },
            {
                lng: 28.23355350109904,
                lat: 45.48828318946829
            }
        ]
    },
    LT: {
        name: 'Lithuania',
        type: 'Polygon',
        coordinates: [
            {
                lng: 26.494331495883756,
                lat: 55.615106919977634
            },
            {
                lng: 26.58827924979039,
                lat: 55.16717560487167
            },
            {
                lng: 25.7684326514798,
                lat: 54.84696259217509
            },
            {
                lng: 25.536353794056993,
                lat: 54.28242340760253
            },
            {
                lng: 24.450683628037037,
                lat: 53.905702216194754
            },
            {
                lng: 23.48412763844985,
                lat: 53.91249766704114
            },
            {
                lng: 23.24398725758951,
                lat: 54.22056671814914
            },
            {
                lng: 22.731098667092652,
                lat: 54.327536932993326
            },
            {
                lng: 22.65105187347254,
                lat: 54.582740993866736
            },
            {
                lng: 22.75776370615526,
                lat: 54.85657440858138
            },
            {
                lng: 22.315723504330577,
                lat: 55.015298570365864
            },
            {
                lng: 21.268448927503467,
                lat: 55.190481675835315
            },
            {
                lng: 21.055800408622417,
                lat: 56.031076361711065
            },
            {
                lng: 22.201156853939494,
                lat: 56.33780182557949
            },
            {
                lng: 23.878263787539964,
                lat: 56.27367137310527
            },
            {
                lng: 24.86068444184076,
                lat: 56.37252838807963
            },
            {
                lng: 25.000934279080894,
                lat: 56.16453074810484
            },
            {
                lng: 25.533046502390334,
                lat: 56.100296942766036
            },
            {
                lng: 26.494331495883756,
                lat: 55.615106919977634
            }
        ]
    },
    LV: {
        name: 'Latvia',
        type: 'Polygon',
        coordinates: [
            {
                lng: 27.288184848751513,
                lat: 57.47452830670383
            },
            {
                lng: 27.77001590344093,
                lat: 57.24425812441123
            },
            {
                lng: 27.855282016722526,
                lat: 56.75932648378429
            },
            {
                lng: 28.17670942557794,
                lat: 56.16912995057879
            },
            {
                lng: 27.10245975109453,
                lat: 55.783313707087686
            },
            {
                lng: 26.494331495883756,
                lat: 55.615106919977634
            },
            {
                lng: 25.533046502390334,
                lat: 56.100296942766036
            },
            {
                lng: 25.000934279080894,
                lat: 56.16453074810484
            },
            {
                lng: 24.86068444184076,
                lat: 56.37252838807963
            },
            {
                lng: 23.878263787539964,
                lat: 56.27367137310527
            },
            {
                lng: 22.201156853939494,
                lat: 56.33780182557949
            },
            {
                lng: 21.055800408622417,
                lat: 56.031076361711065
            },
            {
                lng: 21.090423618257972,
                lat: 56.78387278912294
            },
            {
                lng: 21.581866489353672,
                lat: 57.411870632549935
            },
            {
                lng: 22.52434126149288,
                lat: 57.75337433535076
            },
            {
                lng: 23.318452996522097,
                lat: 57.00623647727487
            },
            {
                lng: 24.12072960785343,
                lat: 57.02569265403277
            },
            {
                lng: 24.312862583114622,
                lat: 57.79342357037697
            },
            {
                lng: 25.16459354014927,
                lat: 57.97015696881519
            },
            {
                lng: 25.60280968598437,
                lat: 57.84752879498657
            },
            {
                lng: 26.463532342237787,
                lat: 57.47638865826633
            },
            {
                lng: 27.288184848751513,
                lat: 57.47452830670383
            }
        ]
    },
    EE: {
        name: 'Estonia',
        type: 'Polygon',
        coordinates: [
            {
                lng: 27.981126857000987,
                lat: 59.47537333432527
            },
            {
                lng: 27.98112,
                lat: 59.47537000000011
            },
            {
                lng: 28.13169925305175,
                lat: 59.300825100330925
            },
            {
                lng: 27.42015000000015,
                lat: 58.72457000000014
            },
            {
                lng: 27.71668582531572,
                lat: 57.79189911562436
            },
            {
                lng: 27.288184848751513,
                lat: 57.47452830670383
            },
            {
                lng: 26.463532342237787,
                lat: 57.47638865826633
            },
            {
                lng: 25.60280968598437,
                lat: 57.84752879498657
            },
            {
                lng: 25.16459354014927,
                lat: 57.97015696881519
            },
            {
                lng: 24.312862583114622,
                lat: 57.79342357037697
            },
            {
                lng: 24.42892785004216,
                lat: 58.38341339785329
            },
            {
                lng: 24.061198357853186,
                lat: 58.25737457949341
            },
            {
                lng: 23.426560092876684,
                lat: 58.612753404364625
            },
            {
                lng: 23.339795363058645,
                lat: 59.187240302153384
            },
            {
                lng: 24.604214308376186,
                lat: 59.46585378685502
            },
            {
                lng: 25.86418908051664,
                lat: 59.61109039981133
            },
            {
                lng: 26.949135776484525,
                lat: 59.445803331125774
            },
            {
                lng: 27.981114129353244,
                lat: 59.475388088612874
            },
            {
                lng: 27.981126857000987,
                lat: 59.47537333432527
            }
        ]
    },
    DE: {
        name: 'Germany',
        type: 'Polygon',
        coordinates: [
            {
                lng: 14.119686313542559,
                lat: 53.75702912049104
            },
            {
                lng: 14.353315463934138,
                lat: 53.24817129171297
            },
            {
                lng: 14.074521111719434,
                lat: 52.98126251892535
            },
            {
                lng: 14.437599725002201,
                lat: 52.62485016540839
            },
            {
                lng: 14.685026482815687,
                lat: 52.0899474147552
            },
            {
                lng: 14.607098422919535,
                lat: 51.74518809671997
            },
            {
                lng: 15.01699588385867,
                lat: 51.10667409932158
            },
            {
                lng: 14.570718214586066,
                lat: 51.002339382524276
            },
            {
                lng: 14.307013380600637,
                lat: 51.117267767941414
            },
            {
                lng: 14.056227654688172,
                lat: 50.9269176295943
            },
            {
                lng: 13.338131951560285,
                lat: 50.73323436136435
            },
            {
                lng: 12.966836785543194,
                lat: 50.484076443069085
            },
            {
                lng: 12.240111118222558,
                lat: 50.266337795607285
            },
            {
                lng: 12.415190870827445,
                lat: 49.96912079528057
            },
            {
                lng: 12.521024204161193,
                lat: 49.547415269562734
            },
            {
                lng: 13.031328973043431,
                lat: 49.30706818297324
            },
            {
                lng: 13.595945672264437,
                lat: 48.87717194273715
            },
            {
                lng: 13.243357374737,
                lat: 48.416114813829054
            },
            {
                lng: 12.884102817443875,
                lat: 48.28914581968786
            },
            {
                lng: 13.02585127122049,
                lat: 47.63758352313583
            },
            {
                lng: 12.932626987365948,
                lat: 47.467645575544
            },
            {
                lng: 12.620759718484493,
                lat: 47.67238760028441
            },
            {
                lng: 12.141357456112788,
                lat: 47.703083401065776
            },
            {
                lng: 11.426414015354737,
                lat: 47.523766181012974
            },
            {
                lng: 10.544504021861599,
                lat: 47.5663992376538
            },
            {
                lng: 10.402083774465211,
                lat: 47.30248769793916
            },
            {
                lng: 9.89606814946319,
                lat: 47.580196845075704
            },
            {
                lng: 9.59422610844635,
                lat: 47.52505809182027
            },
            {
                lng: 8.522611932009767,
                lat: 47.83082754169129
            },
            {
                lng: 8.317301466514095,
                lat: 47.61357982033627
            },
            {
                lng: 7.466759067422231,
                lat: 47.62058197691181
            },
            {
                lng: 7.593676385131062,
                lat: 48.33301911070372
            },
            {
                lng: 8.099278598674744,
                lat: 49.01778351500333
            },
            {
                lng: 6.658229607783568,
                lat: 49.20195831969157
            },
            {
                lng: 6.186320428094177,
                lat: 49.463802802114515
            },
            {
                lng: 6.242751092156993,
                lat: 49.90222565367873
            },
            {
                lng: 6.043073357781111,
                lat: 50.128051662794235
            },
            {
                lng: 6.15665815595878,
                lat: 50.80372101501058
            },
            {
                lng: 5.988658074577813,
                lat: 51.851615709025054
            },
            {
                lng: 6.589396599970826,
                lat: 51.852029120483394
            },
            {
                lng: 6.842869500362383,
                lat: 52.22844025329755
            },
            {
                lng: 7.092053256873896,
                lat: 53.144043280644894
            },
            {
                lng: 6.905139601274129,
                lat: 53.48216217713065
            },
            {
                lng: 7.100424838905269,
                lat: 53.69393219666267
            },
            {
                lng: 7.936239454793963,
                lat: 53.74829580343379
            },
            {
                lng: 8.121706170289485,
                lat: 53.52779246684429
            },
            {
                lng: 8.800734490604668,
                lat: 54.020785630908904
            },
            {
                lng: 8.57211795414537,
                lat: 54.39564647075406
            },
            {
                lng: 8.526229282270208,
                lat: 54.96274363872516
            },
            {
                lng: 9.282048780971138,
                lat: 54.830865383516176
            },
            {
                lng: 9.921906365609118,
                lat: 54.98310415304803
            },
            {
                lng: 9.9395797054529,
                lat: 54.596641954153256
            },
            {
                lng: 10.950112338920519,
                lat: 54.363607082733154
            },
            {
                lng: 10.93946699386845,
                lat: 54.00869334575259
            },
            {
                lng: 11.956252475643282,
                lat: 54.19648550070116
            },
            {
                lng: 12.518440382546714,
                lat: 54.470370591847995
            },
            {
                lng: 13.647467075259499,
                lat: 54.0755109727059
            },
            {
                lng: 14.119686313542559,
                lat: 53.75702912049104
            }
        ]
    },
    BG: {
        name: 'Bulgaria',
        type: 'Polygon',
        coordinates: [
            {
                lng: 22.65714969248299,
                lat: 44.23492300066128
            },
            {
                lng: 22.944832391051847,
                lat: 43.82378530534713
            },
            {
                lng: 23.332302280376325,
                lat: 43.897010809904714
            },
            {
                lng: 24.100679152124172,
                lat: 43.74105133724785
            },
            {
                lng: 25.569271681426926,
                lat: 43.68844472917472
            },
            {
                lng: 26.065158725699746,
                lat: 43.94349376075127
            },
            {
                lng: 27.242399529740908,
                lat: 44.175986029632405
            },
            {
                lng: 27.970107049275075,
                lat: 43.81246816667522
            },
            {
                lng: 28.558081495891997,
                lat: 43.70746165625813
            },
            {
                lng: 28.03909508638472,
                lat: 43.293171698574184
            },
            {
                lng: 27.67389773937805,
                lat: 42.57789236100622
            },
            {
                lng: 27.99672041190539,
                lat: 42.00735871028779
            },
            {
                lng: 27.13573937349048,
                lat: 42.14148489030134
            },
            {
                lng: 26.1170418637208,
                lat: 41.82690460872456
            },
            {
                lng: 26.106138136507127,
                lat: 41.32889883072784
            },
            {
                lng: 25.197201368925448,
                lat: 41.23448598893053
            },
            {
                lng: 24.492644891057978,
                lat: 41.58389618587205
            },
            {
                lng: 23.692073601992348,
                lat: 41.309080918943856
            },
            {
                lng: 22.952377150166452,
                lat: 41.33799388281115
            },
            {
                lng: 22.881373732197346,
                lat: 41.999297186850356
            },
            {
                lng: 22.380525750424592,
                lat: 42.32025950781509
            },
            {
                lng: 22.54501183440962,
                lat: 42.46136200618804
            },
            {
                lng: 22.43659467946128,
                lat: 42.580321153323936
            },
            {
                lng: 22.60480146657133,
                lat: 42.898518785161144
            },
            {
                lng: 22.986018507588483,
                lat: 43.2111612005271
            },
            {
                lng: 22.500156691180223,
                lat: 43.642814439461006
            },
            {
                lng: 22.410446404721597,
                lat: 44.008063462899955
            },
            {
                lng: 22.65714969248299,
                lat: 44.23492300066128
            }
        ]
    },
    GR: {
        name: 'Greece',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: 26.290002882601698,
                    lat: 35.29999034274793
                },
                {
                    lng: 26.16499759288766,
                    lat: 35.00499542900977
                },
                {
                    lng: 24.724982130642303,
                    lat: 34.91998769788964
                },
                {
                    lng: 24.735007358506916,
                    lat: 35.08499054619759
                },
                {
                    lng: 23.51497846852808,
                    lat: 35.27999156345098
                },
                {
                    lng: 23.699980096133004,
                    lat: 35.70500438083549
                },
                {
                    lng: 24.246665073348705,
                    lat: 35.368022365860185
                },
                {
                    lng: 25.02501549652891,
                    lat: 35.42499563246197
                },
                {
                    lng: 25.769207797964185,
                    lat: 35.35401805270908
                },
                {
                    lng: 25.745023227651586,
                    lat: 35.1799976669662
                },
                {
                    lng: 26.290002882601698,
                    lat: 35.29999034274793
                }
            ],
            [
                {
                    lng: 22.952377150166452,
                    lat: 41.33799388281115
                },
                {
                    lng: 23.692073601992348,
                    lat: 41.309080918943856
                },
                {
                    lng: 24.492644891057978,
                    lat: 41.58389618587205
                },
                {
                    lng: 25.197201368925448,
                    lat: 41.23448598893053
                },
                {
                    lng: 26.106138136507127,
                    lat: 41.32889883072784
                },
                {
                    lng: 26.1170418637208,
                    lat: 41.82690460872456
                },
                {
                    lng: 26.60419559093623,
                    lat: 41.562114569661105
                },
                {
                    lng: 26.294602085075667,
                    lat: 40.93626129817413
                },
                {
                    lng: 26.05694217296534,
                    lat: 40.82412344010076
                },
                {
                    lng: 25.44767703624416,
                    lat: 40.85254547786144
                },
                {
                    lng: 24.92584842296094,
                    lat: 40.947061672523205
                },
                {
                    lng: 23.71481123220076,
                    lat: 40.687129218095095
                },
                {
                    lng: 24.40799889496401,
                    lat: 40.12499298762407
                },
                {
                    lng: 23.899967889102584,
                    lat: 39.96200552017552
                },
                {
                    lng: 23.342999301860743,
                    lat: 39.96099782974579
                },
                {
                    lng: 22.813987664488934,
                    lat: 40.476005153966526
                },
                {
                    lng: 22.626298862404724,
                    lat: 40.25656118423916
                },
                {
                    lng: 22.84974775563478,
                    lat: 39.659310818025745
                },
                {
                    lng: 23.35002729665257,
                    lat: 39.190011298167235
                },
                {
                    lng: 22.973099399515547,
                    lat: 38.97090322524963
                },
                {
                    lng: 23.530016310324925,
                    lat: 38.51000112563844
                },
                {
                    lng: 24.025024855248887,
                    lat: 38.21999298761642
                },
                {
                    lng: 24.040011020613576,
                    lat: 37.655014553369426
                },
                {
                    lng: 23.115002882589124,
                    lat: 37.920011298162194
                },
                {
                    lng: 23.409971958111043,
                    lat: 37.40999074965737
                },
                {
                    lng: 22.77497195810861,
                    lat: 37.30501007745653
                },
                {
                    lng: 23.15422529469862,
                    lat: 36.422505804992056
                },
                {
                    lng: 22.490028110451078,
                    lat: 36.4100001083774
                },
                {
                    lng: 21.67002648284364,
                    lat: 36.8449864771942
                },
                {
                    lng: 21.29501061370155,
                    lat: 37.64498932550464
                },
                {
                    lng: 21.120034213961333,
                    lat: 38.31032339126273
                },
                {
                    lng: 20.730032179454554,
                    lat: 38.769985256498785
                },
                {
                    lng: 20.217712029712857,
                    lat: 39.34023468683961
                },
                {
                    lng: 20.15001590341052,
                    lat: 39.62499766698397
                },
                {
                    lng: 20.615000441172754,
                    lat: 40.11000682225938
                },
                {
                    lng: 20.674996779063633,
                    lat: 40.43499990494303
                },
                {
                    lng: 20.999989861747224,
                    lat: 40.58000397395398
                },
                {
                    lng: 21.0200403174764,
                    lat: 40.84272695572588
                },
                {
                    lng: 21.674160597426976,
                    lat: 40.931274522457954
                },
                {
                    lng: 22.05537763844427,
                    lat: 41.14986583105269
                },
                {
                    lng: 22.597308383889015,
                    lat: 41.130487168943205
                },
                {
                    lng: 22.76177,
                    lat: 41.3048
                },
                {
                    lng: 22.952377150166452,
                    lat: 41.33799388281115
                }
            ]
        ]
    },
    TR: {
        name: 'Turkey',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: 44.77267710159504,
                    lat: 37.17043692561684
                },
                {
                    lng: 44.29345177590287,
                    lat: 37.0015143906063
                },
                {
                    lng: 43.9422587420473,
                    lat: 37.25622752537295
                },
                {
                    lng: 42.77912560402183,
                    lat: 37.385263576805755
                },
                {
                    lng: 42.34959109881177,
                    lat: 37.2298725449041
                },
                {
                    lng: 41.21208947120305,
                    lat: 37.074352321921694
                },
                {
                    lng: 40.67325931169569,
                    lat: 37.09127635349729
                },
                {
                    lng: 39.52258019385255,
                    lat: 36.71605377862599
                },
                {
                    lng: 38.6998913917659,
                    lat: 36.71292735447234
                },
                {
                    lng: 38.1677274920242,
                    lat: 36.90121043552777
                },
                {
                    lng: 37.06676110204583,
                    lat: 36.62303620050062
                },
                {
                    lng: 36.7394942563414,
                    lat: 36.81752045343109
                },
                {
                    lng: 36.6853890317318,
                    lat: 36.259699205056464
                },
                {
                    lng: 36.417550083163036,
                    lat: 36.04061697035506
                },
                {
                    lng: 36.149762811026534,
                    lat: 35.82153473565367
                },
                {
                    lng: 35.782084995269855,
                    lat: 36.27499542901485
                },
                {
                    lng: 36.160821567537,
                    lat: 36.65060557712833
                },
                {
                    lng: 35.55093631362831,
                    lat: 36.565442816711325
                },
                {
                    lng: 34.714553256984345,
                    lat: 36.7955321314909
                },
                {
                    lng: 34.026894972476384,
                    lat: 36.219960028624016
                },
                {
                    lng: 32.509158156064075,
                    lat: 36.107563788389186
                },
                {
                    lng: 31.699595167779567,
                    lat: 36.64427521417258
                },
                {
                    lng: 30.621624790171097,
                    lat: 36.6778648951623
                },
                {
                    lng: 30.391096225717064,
                    lat: 36.26298065850699
                },
                {
                    lng: 29.69997562024554,
                    lat: 36.144357408180994
                },
                {
                    lng: 28.732902866335422,
                    lat: 36.67683136651647
                },
                {
                    lng: 27.641186557737317,
                    lat: 36.65882212986274
                },
                {
                    lng: 27.048767937943264,
                    lat: 37.653360907536
                },
                {
                    lng: 26.318218214633045,
                    lat: 38.208133246405396
                },
                {
                    lng: 26.804700148228733,
                    lat: 38.985760199533516
                },
                {
                    lng: 26.17078535330433,
                    lat: 39.463612168936464
                },
                {
                    lng: 27.28001997244939,
                    lat: 40.42001373957831
                },
                {
                    lng: 28.81997765474722,
                    lat: 40.46001129817221
                },
                {
                    lng: 29.240003696415584,
                    lat: 41.21999074967267
                },
                {
                    lng: 31.14593387220444,
                    lat: 41.08762156835702
                },
                {
                    lng: 32.34797936374571,
                    lat: 41.736264146484615
                },
                {
                    lng: 33.51328291192746,
                    lat: 42.01896006933731
                },
                {
                    lng: 35.167703891751785,
                    lat: 42.040224921225416
                },
                {
                    lng: 36.9131270688421,
                    lat: 41.33535838476427
                },
                {
                    lng: 38.34766482926452,
                    lat: 40.94858612727573
                },
                {
                    lng: 39.512606642420195,
                    lat: 41.10276276301855
                },
                {
                    lng: 40.373432651538224,
                    lat: 41.01367259374735
                },
                {
                    lng: 41.55408410011066,
                    lat: 41.53565623632757
                },
                {
                    lng: 42.61954878110449,
                    lat: 41.58317271581994
                },
                {
                    lng: 43.58274580259273,
                    lat: 41.09214325618257
                },
                {
                    lng: 43.75265791196841,
                    lat: 40.74020091405876
                },
                {
                    lng: 43.65643639504094,
                    lat: 40.253563951166186
                },
                {
                    lng: 44.4000085792887,
                    lat: 40.00500031184228
                },
                {
                    lng: 44.79398969908195,
                    lat: 39.71300263117705
                },
                {
                    lng: 44.10922529478234,
                    lat: 39.4281362981681
                },
                {
                    lng: 44.421402622257546,
                    lat: 38.28128123631454
                },
                {
                    lng: 44.22575564960053,
                    lat: 37.97158437758935
                },
                {
                    lng: 44.77267,
                    lat: 37.17045
                },
                {
                    lng: 44.77267710159504,
                    lat: 37.17043692561684
                }
            ],
            [
                {
                    lng: 26.1170418637208,
                    lat: 41.82690460872456
                },
                {
                    lng: 27.13573937349048,
                    lat: 42.14148489030134
                },
                {
                    lng: 27.99672041190539,
                    lat: 42.00735871028779
                },
                {
                    lng: 28.115524529744416,
                    lat: 41.62288605403624
                },
                {
                    lng: 28.988442824018733,
                    lat: 41.29993419042819
                },
                {
                    lng: 28.806438429486743,
                    lat: 41.05496206314856
                },
                {
                    lng: 27.61901736828409,
                    lat: 40.99982330989314
                },
                {
                    lng: 27.19237674328238,
                    lat: 40.69056570084242
                },
                {
                    lng: 26.35800906749779,
                    lat: 40.151993923496505
                },
                {
                    lng: 26.04335127127257,
                    lat: 40.61775360774317
                },
                {
                    lng: 26.05694217296534,
                    lat: 40.82412344010076
                },
                {
                    lng: 26.294602085075667,
                    lat: 40.93626129817413
                },
                {
                    lng: 26.60419559093623,
                    lat: 41.562114569661105
                },
                {
                    lng: 26.1170418637208,
                    lat: 41.82690460872456
                }
            ]
        ]
    },
    AL: {
        name: 'Albania',
        type: 'Polygon',
        coordinates: [
            {
                lng: 21.0200403174764,
                lat: 40.84272695572588
            },
            {
                lng: 20.999989861747224,
                lat: 40.58000397395398
            },
            {
                lng: 20.674996779063633,
                lat: 40.43499990494303
            },
            {
                lng: 20.615000441172754,
                lat: 40.11000682225938
            },
            {
                lng: 20.15001590341052,
                lat: 39.62499766698397
            },
            {
                lng: 19.980000441170148,
                lat: 39.69499339452341
            },
            {
                lng: 19.960001661873207,
                lat: 39.91500580500605
            },
            {
                lng: 19.406081984136733,
                lat: 40.250773423822466
            },
            {
                lng: 19.319058872157143,
                lat: 40.72723012955356
            },
            {
                lng: 19.40354983895429,
                lat: 41.40956574153546
            },
            {
                lng: 19.540027296637106,
                lat: 41.71998607031276
            },
            {
                lng: 19.37176883309496,
                lat: 41.877547512370654
            },
            {
                lng: 19.37176816334725,
                lat: 41.877550679783496
            },
            {
                lng: 19.304486118250793,
                lat: 42.19574514420782
            },
            {
                lng: 19.73805138517963,
                lat: 42.68824738216557
            },
            {
                lng: 19.801613396898688,
                lat: 42.50009349219084
            },
            {
                lng: 20.0707,
                lat: 42.58863
            },
            {
                lng: 20.283754510181893,
                lat: 42.32025950781508
            },
            {
                lng: 20.52295,
                lat: 42.21787
            },
            {
                lng: 20.590246546680227,
                lat: 41.855408919283626
            },
            {
                lng: 20.59024743010491,
                lat: 41.855404161133606
            },
            {
                lng: 20.463175083099202,
                lat: 41.51508901627534
            },
            {
                lng: 20.605181919037364,
                lat: 41.086226304685226
            },
            {
                lng: 21.0200403174764,
                lat: 40.84272695572588
            }
        ]
    },
    HR: {
        name: 'Croatia',
        type: 'Polygon',
        coordinates: [
            {
                lng: 16.564808383864857,
                lat: 46.50375092221983
            },
            {
                lng: 16.8825150895953,
                lat: 46.38063182228444
            },
            {
                lng: 17.630066359129557,
                lat: 45.95176911069419
            },
            {
                lng: 18.45606245288286,
                lat: 45.75948110613615
            },
            {
                lng: 18.829824792873946,
                lat: 45.908872358025285
            },
            {
                lng: 19.072768995854176,
                lat: 45.52151113543209
            },
            {
                lng: 19.39047570158459,
                lat: 45.236515611342384
            },
            {
                lng: 19.005484597557594,
                lat: 44.86023449354299
            },
            {
                lng: 18.553214145591653,
                lat: 45.08158966733146
            },
            {
                lng: 17.861783481526402,
                lat: 45.067740383477144
            },
            {
                lng: 17.002146030351014,
                lat: 45.23377676043094
            },
            {
                lng: 16.534939406000206,
                lat: 45.21160757097772
            },
            {
                lng: 16.318156772535872,
                lat: 45.00412669532591
            },
            {
                lng: 15.959367303133376,
                lat: 45.23377676043094
            },
            {
                lng: 15.750026075918981,
                lat: 44.818711656262565
            },
            {
                lng: 16.23966027188453,
                lat: 44.35114329688571
            },
            {
                lng: 16.456442905348865,
                lat: 44.04123973243128
            },
            {
                lng: 16.91615644701733,
                lat: 43.66772247982567
            },
            {
                lng: 17.297373488034452,
                lat: 43.44634064388737
            },
            {
                lng: 17.674921502358984,
                lat: 43.02856252702361
            },
            {
                lng: 18.56,
                lat: 42.65
            },
            {
                lng: 18.45001688302086,
                lat: 42.47999224531218
            },
            {
                lng: 18.450016310304818,
                lat: 42.47999136002932
            },
            {
                lng: 17.509970330483327,
                lat: 42.849994615239154
            },
            {
                lng: 16.930005730871642,
                lat: 43.20999848080038
            },
            {
                lng: 16.015384555737683,
                lat: 43.50721548112722
            },
            {
                lng: 15.174453973052096,
                lat: 44.243191229827914
            },
            {
                lng: 15.376250441151797,
                lat: 44.31791535092208
            },
            {
                lng: 14.92030927904051,
                lat: 44.73848399512946
            },
            {
                lng: 14.901602410550879,
                lat: 45.07606028907611
            },
            {
                lng: 14.258747592839995,
                lat: 45.23377676043094
            },
            {
                lng: 13.952254672917036,
                lat: 44.80212352149687
            },
            {
                lng: 13.656975538801191,
                lat: 45.13693512631596
            },
            {
                lng: 13.67940311041582,
                lat: 45.48414907488501
            },
            {
                lng: 13.715059848697223,
                lat: 45.500323798192376
            },
            {
                lng: 14.411968214585414,
                lat: 45.46616567644746
            },
            {
                lng: 14.595109490627806,
                lat: 45.634940904312714
            },
            {
                lng: 14.935243767972935,
                lat: 45.471695054702685
            },
            {
                lng: 15.327674594797429,
                lat: 45.45231639259333
            },
            {
                lng: 15.323953891672405,
                lat: 45.73178253842768
            },
            {
                lng: 15.671529575267556,
                lat: 45.83415355079788
            },
            {
                lng: 15.768732944408553,
                lat: 46.23810822202345
            },
            {
                lng: 16.564808383864857,
                lat: 46.50375092221983
            }
        ]
    },
    CH: {
        name: 'Switzerland',
        type: 'Polygon',
        coordinates: [
            {
                lng: 9.59422610844635,
                lat: 47.52505809182027
            },
            {
                lng: 9.632931756232978,
                lat: 47.34760122332999
            },
            {
                lng: 9.479969516649021,
                lat: 47.102809963563374
            },
            {
                lng: 9.932448357796659,
                lat: 46.92072805438296
            },
            {
                lng: 10.44270145024663,
                lat: 46.89354625099743
            },
            {
                lng: 10.363378126678612,
                lat: 46.48357127540986
            },
            {
                lng: 9.922836541390382,
                lat: 46.31489940040919
            },
            {
                lng: 9.182881707403055,
                lat: 46.44021474871698
            },
            {
                lng: 8.966305779667806,
                lat: 46.03693187111119
            },
            {
                lng: 8.489952426801324,
                lat: 46.005150865251686
            },
            {
                lng: 8.31662967289438,
                lat: 46.16364248309086
            },
            {
                lng: 7.755992058959833,
                lat: 45.82449005795931
            },
            {
                lng: 7.273850945676656,
                lat: 45.776947740250776
            },
            {
                lng: 6.843592970414505,
                lat: 45.99114655210061
            },
            {
                lng: 6.500099724970426,
                lat: 46.42967275652944
            },
            {
                lng: 6.022609490593538,
                lat: 46.27298981382047
            },
            {
                lng: 6.037388950229001,
                lat: 46.725778713561866
            },
            {
                lng: 6.768713820023606,
                lat: 47.2877082383037
            },
            {
                lng: 6.736571079138059,
                lat: 47.541801255882845
            },
            {
                lng: 7.192202182655507,
                lat: 47.44976552997102
            },
            {
                lng: 7.466759067422231,
                lat: 47.62058197691181
            },
            {
                lng: 8.317301466514095,
                lat: 47.61357982033627
            },
            {
                lng: 8.522611932009767,
                lat: 47.83082754169129
            },
            {
                lng: 9.59422610844635,
                lat: 47.52505809182027
            }
        ]
    },
    LU: {
        name: 'Luxembourg',
        type: 'Polygon',
        coordinates: [
            {
                lng: 6.043073357781111,
                lat: 50.128051662794235
            },
            {
                lng: 6.242751092156993,
                lat: 49.90222565367873
            },
            {
                lng: 6.186320428094177,
                lat: 49.463802802114515
            },
            {
                lng: 5.897759230176348,
                lat: 49.44266714130711
            },
            {
                lng: 5.674051954784829,
                lat: 49.529483547557504
            },
            {
                lng: 5.782417433300907,
                lat: 50.09032786722122
            },
            {
                lng: 6.043073357781111,
                lat: 50.128051662794235
            }
        ]
    },
    BE: {
        name: 'Belgium',
        type: 'Polygon',
        coordinates: [
            {
                lng: 6.15665815595878,
                lat: 50.80372101501058
            },
            {
                lng: 6.043073357781111,
                lat: 50.128051662794235
            },
            {
                lng: 5.782417433300907,
                lat: 50.09032786722122
            },
            {
                lng: 5.674051954784829,
                lat: 49.529483547557504
            },
            {
                lng: 4.799221632515724,
                lat: 49.98537303323637
            },
            {
                lng: 4.286022983425084,
                lat: 49.907496649772554
            },
            {
                lng: 3.588184441755658,
                lat: 50.37899241800356
            },
            {
                lng: 3.123251580425688,
                lat: 50.78036326761455
            },
            {
                lng: 2.658422071960274,
                lat: 50.796848049515745
            },
            {
                lng: 2.513573032246143,
                lat: 51.14850617126183
            },
            {
                lng: 3.314971144228537,
                lat: 51.345780951536085
            },
            {
                lng: 3.31501148496416,
                lat: 51.34577662473805
            },
            {
                lng: 3.314971144228537,
                lat: 51.34575511331991
            },
            {
                lng: 4.047071160507528,
                lat: 51.26725861266857
            },
            {
                lng: 4.973991326526914,
                lat: 51.47502370869813
            },
            {
                lng: 5.606975945670001,
                lat: 51.03729848896978
            },
            {
                lng: 6.15665815595878,
                lat: 50.80372101501058
            }
        ]
    },
    NL: {
        name: 'Netherlands',
        type: 'Polygon',
        coordinates: [
            {
                lng: 6.905139601274129,
                lat: 53.48216217713065
            },
            {
                lng: 7.092053256873896,
                lat: 53.144043280644894
            },
            {
                lng: 6.842869500362383,
                lat: 52.22844025329755
            },
            {
                lng: 6.589396599970826,
                lat: 51.852029120483394
            },
            {
                lng: 5.988658074577813,
                lat: 51.851615709025054
            },
            {
                lng: 6.15665815595878,
                lat: 50.80372101501058
            },
            {
                lng: 5.606975945670001,
                lat: 51.03729848896978
            },
            {
                lng: 4.973991326526914,
                lat: 51.47502370869813
            },
            {
                lng: 4.047071160507528,
                lat: 51.26725861266857
            },
            {
                lng: 3.314971144228537,
                lat: 51.34575511331991
            },
            {
                lng: 3.31501148496416,
                lat: 51.34577662473805
            },
            {
                lng: 3.830288527043137,
                lat: 51.62054454203195
            },
            {
                lng: 4.705997348661185,
                lat: 53.091798407597764
            },
            {
                lng: 6.074182570020923,
                lat: 53.510403347378144
            },
            {
                lng: 6.905139601274129,
                lat: 53.48216217713065
            }
        ]
    },
    PT: {
        name: 'Portugal',
        type: 'Polygon',
        coordinates: [
            {
                lng: -9.034817674180246,
                lat: 41.880570583659676
            },
            {
                lng: -8.67194576662672,
                lat: 42.13468943945496
            },
            {
                lng: -8.263856980817792,
                lat: 42.28046865495034
            },
            {
                lng: -8.013174607769912,
                lat: 41.790886135417125
            },
            {
                lng: -7.422512986673795,
                lat: 41.79207469335984
            },
            {
                lng: -7.251308966490824,
                lat: 41.91834605566505
            },
            {
                lng: -6.668605515967656,
                lat: 41.883386949219584
            },
            {
                lng: -6.389087693700915,
                lat: 41.381815497394655
            },
            {
                lng: -6.851126674822552,
                lat: 41.11108266861753
            },
            {
                lng: -6.864019944679385,
                lat: 40.33087189387483
            },
            {
                lng: -7.026413133156595,
                lat: 40.184524237624245
            },
            {
                lng: -7.066591559263529,
                lat: 39.711891587882775
            },
            {
                lng: -7.498632371439726,
                lat: 39.62957103124181
            },
            {
                lng: -7.098036668313128,
                lat: 39.03007274022379
            },
            {
                lng: -7.374092169616318,
                lat: 38.37305858006492
            },
            {
                lng: -7.029281175148796,
                lat: 38.07576406508977
            },
            {
                lng: -7.166507941099865,
                lat: 37.803894354802225
            },
            {
                lng: -7.537105475281024,
                lat: 37.42890432387624
            },
            {
                lng: -7.453725551778092,
                lat: 37.09778758396607
            },
            {
                lng: -7.855613165711986,
                lat: 36.83826854099627
            },
            {
                lng: -8.382816127953689,
                lat: 36.97888011326246
            },
            {
                lng: -8.898856980820327,
                lat: 36.86880931248078
            },
            {
                lng: -8.746101446965554,
                lat: 37.65134552667661
            },
            {
                lng: -8.83999752443988,
                lat: 38.266243394517616
            },
            {
                lng: -9.287463751655224,
                lat: 38.3584858261586
            },
            {
                lng: -9.526570603869715,
                lat: 38.73742910415491
            },
            {
                lng: -9.446988898140233,
                lat: 39.39206614842837
            },
            {
                lng: -9.048305223008427,
                lat: 39.75509308527877
            },
            {
                lng: -8.977353481471681,
                lat: 40.15930613866581
            },
            {
                lng: -8.768684047877102,
                lat: 40.76063894303019
            },
            {
                lng: -8.79085323733031,
                lat: 41.18433401139126
            },
            {
                lng: -8.99078935386757,
                lat: 41.54345937760364
            },
            {
                lng: -9.034817674180246,
                lat: 41.880570583659676
            }
        ]
    },
    ES: {
        name: 'Spain',
        type: 'Polygon',
        coordinates: [
            {
                lng: -7.453725551778092,
                lat: 37.09778758396607
            },
            {
                lng: -7.537105475281024,
                lat: 37.42890432387624
            },
            {
                lng: -7.166507941099865,
                lat: 37.803894354802225
            },
            {
                lng: -7.029281175148796,
                lat: 38.07576406508977
            },
            {
                lng: -7.374092169616318,
                lat: 38.37305858006492
            },
            {
                lng: -7.098036668313128,
                lat: 39.03007274022379
            },
            {
                lng: -7.498632371439726,
                lat: 39.62957103124181
            },
            {
                lng: -7.066591559263529,
                lat: 39.711891587882775
            },
            {
                lng: -7.026413133156595,
                lat: 40.184524237624245
            },
            {
                lng: -6.864019944679385,
                lat: 40.33087189387483
            },
            {
                lng: -6.851126674822552,
                lat: 41.11108266861753
            },
            {
                lng: -6.389087693700915,
                lat: 41.381815497394655
            },
            {
                lng: -6.668605515967656,
                lat: 41.883386949219584
            },
            {
                lng: -7.251308966490824,
                lat: 41.91834605566505
            },
            {
                lng: -7.422512986673795,
                lat: 41.79207469335984
            },
            {
                lng: -8.013174607769912,
                lat: 41.790886135417125
            },
            {
                lng: -8.263856980817792,
                lat: 42.28046865495034
            },
            {
                lng: -8.67194576662672,
                lat: 42.13468943945496
            },
            {
                lng: -9.034817674180246,
                lat: 41.880570583659676
            },
            {
                lng: -8.984433152695672,
                lat: 42.59277517350627
            },
            {
                lng: -9.392883673530648,
                lat: 43.0266246608127
            },
            {
                lng: -7.97818966310831,
                lat: 43.74833771420099
            },
            {
                lng: -6.754491746436756,
                lat: 43.567909450853925
            },
            {
                lng: -5.411886359061597,
                lat: 43.57423981380968
            },
            {
                lng: -4.347842779955783,
                lat: 43.40344920508504
            },
            {
                lng: -3.517531704106091,
                lat: 43.4559007838613
            },
            {
                lng: -1.901351284177764,
                lat: 43.42280202897834
            },
            {
                lng: -1.502770961910528,
                lat: 43.03401439063043
            },
            {
                lng: 0.338046909190581,
                lat: 42.57954600683955
            },
            {
                lng: 0.701590610363894,
                lat: 42.795734361332606
            },
            {
                lng: 1.826793247087153,
                lat: 42.34338471126569
            },
            {
                lng: 2.985998976258458,
                lat: 42.47301504166986
            },
            {
                lng: 3.039484083680549,
                lat: 41.892120266276905
            },
            {
                lng: 2.091841668312185,
                lat: 41.226088568683096
            },
            {
                lng: 0.810524529635188,
                lat: 41.01473196060934
            },
            {
                lng: 0.721331007499401,
                lat: 40.678318386389236
            },
            {
                lng: 0.106691521819869,
                lat: 40.12393362076202
            },
            {
                lng: -0.278711310212941,
                lat: 39.30997813573272
            },
            {
                lng: 0.111290724293838,
                lat: 38.73851430923304
            },
            {
                lng: -0.467123582349103,
                lat: 38.29236583104115
            },
            {
                lng: -0.683389451490598,
                lat: 37.642353827457825
            },
            {
                lng: -1.438382127274849,
                lat: 37.44306366632422
            },
            {
                lng: -2.146452602538119,
                lat: 36.67414419203729
            },
            {
                lng: -3.415780808923387,
                lat: 36.65889964451118
            },
            {
                lng: -4.368900926114719,
                lat: 36.677839056946155
            },
            {
                lng: -4.995219285492212,
                lat: 36.32470815687964
            },
            {
                lng: -5.377159796561457,
                lat: 35.946850083961465
            },
            {
                lng: -5.866432257500904,
                lat: 36.02981659600606
            },
            {
                lng: -6.236693894872175,
                lat: 36.367677110330334
            },
            {
                lng: -6.520190802425404,
                lat: 36.94291331638732
            },
            {
                lng: -7.453725551778092,
                lat: 37.09778758396607
            }
        ]
    },
    IE: {
        name: 'Ireland',
        type: 'Polygon',
        coordinates: [
            {
                lng: -6.197884894220991,
                lat: 53.867565009163364
            },
            {
                lng: -6.032985398777611,
                lat: 53.15316417094435
            },
            {
                lng: -6.788856573910849,
                lat: 52.260117906292336
            },
            {
                lng: -8.56161658368356,
                lat: 51.669301255899356
            },
            {
                lng: -9.977085740590269,
                lat: 51.82045482035308
            },
            {
                lng: -9.166282517930782,
                lat: 52.86462881124268
            },
            {
                lng: -9.688524542672454,
                lat: 53.8813626165853
            },
            {
                lng: -8.327987433292009,
                lat: 54.66451894796863
            },
            {
                lng: -7.572167934591064,
                lat: 55.13162221945487
            },
            {
                lng: -7.366030646178785,
                lat: 54.59584096945272
            },
            {
                lng: -7.572167934591064,
                lat: 54.059956366586
            },
            {
                lng: -6.953730231138067,
                lat: 54.073702297575636
            },
            {
                lng: -6.197884894220991,
                lat: 53.867565009163364
            }
        ]
    },
    NC: {
        name: 'New Caledonia',
        type: 'Polygon',
        coordinates: [
            {
                lng: 165.77998986232637,
                lat: -21.08000497811563
            },
            {
                lng: 166.59999148993384,
                lat: -21.700018812753527
            },
            {
                lng: 167.1200114280869,
                lat: -22.15999073658349
            },
            {
                lng: 166.7400346214448,
                lat: -22.39997608814695
            },
            {
                lng: 166.18973229396866,
                lat: -22.129708347260454
            },
            {
                lng: 165.47437544175222,
                lat: -21.679606621998232
            },
            {
                lng: 164.82981530177568,
                lat: -21.14981983814195
            },
            {
                lng: 164.16799523341365,
                lat: -20.444746595951628
            },
            {
                lng: 164.029605747736,
                lat: -20.105645847252354
            },
            {
                lng: 164.45996707586272,
                lat: -20.1200118954295
            },
            {
                lng: 165.02003624904205,
                lat: -20.45999114347773
            },
            {
                lng: 165.46000939357512,
                lat: -20.80002206795826
            },
            {
                lng: 165.77998986232637,
                lat: -21.08000497811563
            }
        ]
    },
    SB: {
        name: 'Solomon Islands',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: 162.1190246930409,
                    lat: -10.48271900802115
                },
                {
                    lng: 162.3986458681722,
                    lat: -10.826367282762106
                },
                {
                    lng: 161.70003218001835,
                    lat: -10.820011081590211
                },
                {
                    lng: 161.31979699121476,
                    lat: -10.204751478723168
                },
                {
                    lng: 161.91738325423802,
                    lat: -10.446700534713713
                },
                {
                    lng: 162.1190246930409,
                    lat: -10.48271900802115
                }
            ],
            [
                {
                    lng: 161.67998172428912,
                    lat: -9.599982191611367
                },
                {
                    lng: 161.5293966005906,
                    lat: -9.784312025596485
                },
                {
                    lng: 160.78825320866054,
                    lat: -8.917543226764892
                },
                {
                    lng: 160.57999718652434,
                    lat: -8.32000864017396
                },
                {
                    lng: 160.92002811100485,
                    lat: -8.32000864017396
                },
                {
                    lng: 161.28000613835,
                    lat: -9.120011488484451
                },
                {
                    lng: 161.67998172428912,
                    lat: -9.599982191611367
                }
            ],
            [
                {
                    lng: 160.85222863183787,
                    lat: -9.872937106977048
                },
                {
                    lng: 160.4625883323572,
                    lat: -9.895209649294841
                },
                {
                    lng: 159.84944746321412,
                    lat: -9.794027194867354
                },
                {
                    lng: 159.64000288313514,
                    lat: -9.639979750205278
                },
                {
                    lng: 159.70294477766663,
                    lat: -9.242949720906815
                },
                {
                    lng: 160.36295617089843,
                    lat: -9.40030445723557
                },
                {
                    lng: 160.68851769433724,
                    lat: -9.610162448772869
                },
                {
                    lng: 160.85222863183787,
                    lat: -9.872937106977048
                }
            ],
            [
                {
                    lng: 159.64000288313514,
                    lat: -8.020026950719632
                },
                {
                    lng: 159.87502729719859,
                    lat: -8.337320244991737
                },
                {
                    lng: 159.91740197167792,
                    lat: -8.53828989017483
                },
                {
                    lng: 159.13367719953936,
                    lat: -8.114181410355428
                },
                {
                    lng: 158.5861137229747,
                    lat: -7.754823500197737
                },
                {
                    lng: 158.21114953026483,
                    lat: -7.421872246941199
                },
                {
                    lng: 158.35997765526542,
                    lat: -7.320017998893917
                },
                {
                    lng: 158.8200012555277,
                    lat: -7.560003350457379
                },
                {
                    lng: 159.64000288313514,
                    lat: -8.020026950719632
                }
            ],
            [
                {
                    lng: 157.14000044171888,
                    lat: -7.021638278840641
                },
                {
                    lng: 157.5384257346892,
                    lat: -7.347819919466943
                },
                {
                    lng: 157.33941979393325,
                    lat: -7.404767347852592
                },
                {
                    lng: 156.90203047101483,
                    lat: -7.176874281445428
                },
                {
                    lng: 156.4913578635913,
                    lat: -6.765943291860452
                },
                {
                    lng: 156.542827590154,
                    lat: -6.599338474151452
                },
                {
                    lng: 157.14000044171888,
                    lat: -7.021638278840641
                }
            ]
        ]
    },
    NZ: {
        name: 'New Zealand',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: 176.8858236026052,
                    lat: -40.0659778785822
                },
                {
                    lng: 176.50801720611926,
                    lat: -40.60480803808957
                },
                {
                    lng: 176.01244022044023,
                    lat: -41.28962411882147
                },
                {
                    lng: 175.23956749908297,
                    lat: -41.68830779395328
                },
                {
                    lng: 175.06789839100935,
                    lat: -41.42589487077513
                },
                {
                    lng: 174.65097293527847,
                    lat: -41.2818209775454
                },
                {
                    lng: 175.22763024322356,
                    lat: -40.45923552832336
                },
                {
                    lng: 174.9001566917899,
                    lat: -39.9089332008472
                },
                {
                    lng: 173.82404666574394,
                    lat: -39.50885426204351
                },
                {
                    lng: 173.85226199777532,
                    lat: -39.14660247167748
                },
                {
                    lng: 174.57480187408035,
                    lat: -38.797683200842755
                },
                {
                    lng: 174.74347374908098,
                    lat: -38.02780771255843
                },
                {
                    lng: 174.6970166364506,
                    lat: -37.38112883885792
                },
                {
                    lng: 174.2920284365792,
                    lat: -36.71109221776149
                },
                {
                    lng: 174.31900353423552,
                    lat: -36.53482390721391
                },
                {
                    lng: 173.8409965355357,
                    lat: -36.12198088963413
                },
                {
                    lng: 173.0541711774596,
                    lat: -35.23712533950039
                },
                {
                    lng: 172.63600548735377,
                    lat: -34.52910654066943
                },
                {
                    lng: 173.00704227120946,
                    lat: -34.45066171645037
                },
                {
                    lng: 173.55129845610747,
                    lat: -35.00618336358801
                },
                {
                    lng: 174.3293904971262,
                    lat: -35.26549570082862
                },
                {
                    lng: 174.61200890533044,
                    lat: -36.15639739354052
                },
                {
                    lng: 175.33661583892712,
                    lat: -37.20909799575827
                },
                {
                    lng: 175.3575964704376,
                    lat: -36.52619394302117
                },
                {
                    lng: 175.80888675364253,
                    lat: -36.79894215265767
                },
                {
                    lng: 175.9584900251275,
                    lat: -37.55538176854612
                },
                {
                    lng: 176.76319542877656,
                    lat: -37.881253350578675
                },
                {
                    lng: 177.4388131045605,
                    lat: -37.961248467766495
                },
                {
                    lng: 178.01035444570866,
                    lat: -37.579824721020174
                },
                {
                    lng: 178.51709354076274,
                    lat: -37.6953732236248
                },
                {
                    lng: 178.27473107331383,
                    lat: -38.58281259537314
                },
                {
                    lng: 177.97046023997927,
                    lat: -39.166342868812976
                },
                {
                    lng: 177.20699262929918,
                    lat: -39.14577564876082
                },
                {
                    lng: 176.93998050364706,
                    lat: -39.44973642350161
                },
                {
                    lng: 177.0329464053401,
                    lat: -39.879942722331464
                },
                {
                    lng: 176.8858236026052,
                    lat: -40.0659778785822
                }
            ],
            [
                {
                    lng: 169.66781456937315,
                    lat: -43.555325616226376
                },
                {
                    lng: 170.52491987536615,
                    lat: -43.031688327812816
                },
                {
                    lng: 171.125089960004,
                    lat: -42.51275359473782
                },
                {
                    lng: 171.56971398344325,
                    lat: -41.767424411792135
                },
                {
                    lng: 171.94870893787186,
                    lat: -41.514416599291124
                },
                {
                    lng: 172.0972270042787,
                    lat: -40.956104424809716
                },
                {
                    lng: 172.79857954334403,
                    lat: -40.4939620908235
                },
                {
                    lng: 173.02037479074076,
                    lat: -40.919052422856446
                },
                {
                    lng: 173.2472343285021,
                    lat: -41.33199879330081
                },
                {
                    lng: 173.9584053897028,
                    lat: -40.926700534835646
                },
                {
                    lng: 174.24758670480816,
                    lat: -41.34915536882171
                },
                {
                    lng: 174.24851688058942,
                    lat: -41.77000823340673
                },
                {
                    lng: 173.87644656808794,
                    lat: -42.23318409603879
                },
                {
                    lng: 173.2227396995957,
                    lat: -42.970038344088614
                },
                {
                    lng: 172.71124637277074,
                    lat: -43.372287693048555
                },
                {
                    lng: 173.08011274647015,
                    lat: -43.853343601253606
                },
                {
                    lng: 172.3085836123525,
                    lat: -43.86569426857136
                },
                {
                    lng: 171.45292524646362,
                    lat: -44.2425188128437
                },
                {
                    lng: 171.18513797432718,
                    lat: -44.89710418068486
                },
                {
                    lng: 170.61669721911653,
                    lat: -45.908928724959736
                },
                {
                    lng: 169.8314221540093,
                    lat: -46.35577483498757
                },
                {
                    lng: 169.33233117093428,
                    lat: -46.641235446967876
                },
                {
                    lng: 168.41135379462855,
                    lat: -46.61994475686363
                },
                {
                    lng: 167.76374474514682,
                    lat: -46.29019744240919
                },
                {
                    lng: 166.67688602118417,
                    lat: -46.219917494492236
                },
                {
                    lng: 166.50914432196467,
                    lat: -45.85270476662619
                },
                {
                    lng: 167.0464241885033,
                    lat: -45.110941257508635
                },
                {
                    lng: 168.30376346259686,
                    lat: -44.12397307716614
                },
                {
                    lng: 168.94940880765157,
                    lat: -43.935819187191434
                },
                {
                    lng: 169.66781456937315,
                    lat: -43.555325616226376
                }
            ]
        ]
    },
    AU: {
        name: 'Australia',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: 147.68925947488418,
                    lat: -40.808258152022674
                },
                {
                    lng: 148.289067824496,
                    lat: -40.87543751400211
                },
                {
                    lng: 148.35986453673587,
                    lat: -42.06244516374644
                },
                {
                    lng: 148.01730146707303,
                    lat: -42.40702361426865
                },
                {
                    lng: 147.91405195535384,
                    lat: -43.211522312188535
                },
                {
                    lng: 147.56456424376393,
                    lat: -42.937688897473905
                },
                {
                    lng: 146.87034305235488,
                    lat: -43.6345972633621
                },
                {
                    lng: 146.66332726459365,
                    lat: -43.58085377377856
                },
                {
                    lng: 146.04837772032033,
                    lat: -43.549744561538844
                },
                {
                    lng: 145.4319295595106,
                    lat: -42.693776137056254
                },
                {
                    lng: 145.29509036680173,
                    lat: -42.033609714527564
                },
                {
                    lng: 144.71807132383066,
                    lat: -41.16255177181576
                },
                {
                    lng: 144.7437545106797,
                    lat: -40.70397511165767
                },
                {
                    lng: 145.3979781434948,
                    lat: -40.79254851660594
                },
                {
                    lng: 146.3641207216237,
                    lat: -41.13769540788336
                },
                {
                    lng: 146.90858361225088,
                    lat: -41.00054615658073
                },
                {
                    lng: 147.68925947488418,
                    lat: -40.808258152022674
                }
            ],
            [
                {
                    lng: 126.14871382050114,
                    lat: -32.21596607842059
                },
                {
                    lng: 125.08862348846566,
                    lat: -32.72875131605285
                },
                {
                    lng: 124.22164798390492,
                    lat: -32.95948658623607
                },
                {
                    lng: 124.02894656788851,
                    lat: -33.4838473447017
                },
                {
                    lng: 123.65966678273077,
                    lat: -33.89017913181271
                },
                {
                    lng: 122.81103641163364,
                    lat: -33.914467054989885
                },
                {
                    lng: 122.1830644064228,
                    lat: -34.0034021949642
                },
                {
                    lng: 121.29919070850259,
                    lat: -33.821036065406176
                },
                {
                    lng: 120.58026818245806,
                    lat: -33.93017669040661
                },
                {
                    lng: 119.89369510302822,
                    lat: -33.9760653622818
                },
                {
                    lng: 119.29889936734875,
                    lat: -34.50936614353394
                },
                {
                    lng: 119.00734093635802,
                    lat: -34.46414926527854
                },
                {
                    lng: 118.5057178081008,
                    lat: -34.74681934991509
                },
                {
                    lng: 118.02497195848949,
                    lat: -35.0647327613747
                },
                {
                    lng: 117.29550744025741,
                    lat: -35.02545867283287
                },
                {
                    lng: 116.62510908413495,
                    lat: -35.02509693780683
                },
                {
                    lng: 115.56434695847966,
                    lat: -34.38642791111157
                },
                {
                    lng: 115.02680870977957,
                    lat: -34.19651702243893
                },
                {
                    lng: 115.04861616420676,
                    lat: -33.623425388322055
                },
                {
                    lng: 115.54512332566708,
                    lat: -33.48725798923297
                },
                {
                    lng: 115.7146737000167,
                    lat: -33.25957162855497
                },
                {
                    lng: 115.67937869676135,
                    lat: -32.900368747694166
                },
                {
                    lng: 115.80164513556394,
                    lat: -32.205062351207005
                },
                {
                    lng: 115.68961063035516,
                    lat: -31.612437025683807
                },
                {
                    lng: 115.160909051577,
                    lat: -30.601594333622465
                },
                {
                    lng: 114.99704308477948,
                    lat: -30.03072478609414
                },
                {
                    lng: 115.04003787644629,
                    lat: -29.46109547294082
                },
                {
                    lng: 114.64197431850201,
                    lat: -28.81023080822467
                },
                {
                    lng: 114.6164978373821,
                    lat: -28.51639861421308
                },
                {
                    lng: 114.17357913620847,
                    lat: -28.11807667410732
                },
                {
                    lng: 114.04888390508816,
                    lat: -27.334765313427106
                },
                {
                    lng: 113.47749759323692,
                    lat: -26.543134047147902
                },
                {
                    lng: 113.33895307826242,
                    lat: -26.116545098578484
                },
                {
                    lng: 113.77835778204022,
                    lat: -26.549025160429174
                },
                {
                    lng: 113.44096235560656,
                    lat: -25.621278171493167
                },
                {
                    lng: 113.93690107631167,
                    lat: -25.91123463308287
                },
                {
                    lng: 114.23285200404723,
                    lat: -26.29844614024588
                },
                {
                    lng: 114.21616051641698,
                    lat: -25.786281019801123
                },
                {
                    lng: 113.7212553243577,
                    lat: -24.99893889740214
                },
                {
                    lng: 113.62534386602397,
                    lat: -24.683971042583167
                },
                {
                    lng: 113.39352339076264,
                    lat: -24.384764499613226
                },
                {
                    lng: 113.5020438985756,
                    lat: -23.806350192970285
                },
                {
                    lng: 113.70699262904515,
                    lat: -23.56021534596409
                },
                {
                    lng: 113.84341841029567,
                    lat: -23.059987481378755
                },
                {
                    lng: 113.73655154831609,
                    lat: -22.47547535572538
                },
                {
                    lng: 114.1497563009219,
                    lat: -21.75588103606104
                },
                {
                    lng: 114.22530724493262,
                    lat: -22.517488295178673
                },
                {
                    lng: 114.6477620789187,
                    lat: -21.829519952076954
                },
                {
                    lng: 115.46016727097924,
                    lat: -21.495173435148537
                },
                {
                    lng: 115.94737267462702,
                    lat: -21.068687839443704
                },
                {
                    lng: 116.71161543179153,
                    lat: -20.701681817306824
                },
                {
                    lng: 117.16631635952771,
                    lat: -20.623598728113805
                },
                {
                    lng: 117.44154503791424,
                    lat: -20.74689869556221
                },
                {
                    lng: 118.229558953933,
                    lat: -20.37420826587322
                },
                {
                    lng: 118.83608523974274,
                    lat: -20.263310642174858
                },
                {
                    lng: 118.98780724495168,
                    lat: -20.044202569257315
                },
                {
                    lng: 119.25249393115067,
                    lat: -19.952941989829867
                },
                {
                    lng: 119.80522505094451,
                    lat: -19.976506442954964
                },
                {
                    lng: 120.85622033089668,
                    lat: -19.683707777589206
                },
                {
                    lng: 121.39985639860717,
                    lat: -19.239755547769725
                },
                {
                    lng: 121.65513797412902,
                    lat: -18.70531788500717
                },
                {
                    lng: 122.24166548064179,
                    lat: -18.197648614171804
                },
                {
                    lng: 122.28662397673571,
                    lat: -17.798603204013958
                },
                {
                    lng: 122.3127722514754,
                    lat: -17.25496713630345
                },
                {
                    lng: 123.01257449757193,
                    lat: -16.405199883695886
                },
                {
                    lng: 123.43378909718304,
                    lat: -17.268558037996215
                },
                {
                    lng: 123.85934451710659,
                    lat: -17.069035332917288
                },
                {
                    lng: 123.50324222218329,
                    lat: -16.596506036040402
                },
                {
                    lng: 123.81707319549184,
                    lat: -16.111316013252
                },
                {
                    lng: 124.25828657439985,
                    lat: -16.327943617419535
                },
                {
                    lng: 124.37972619028575,
                    lat: -15.56705982835399
                },
                {
                    lng: 124.92615278534004,
                    lat: -15.07510019293536
                },
                {
                    lng: 125.16727501841387,
                    lat: -14.680395603090028
                },
                {
                    lng: 125.67008670461381,
                    lat: -14.510070082256014
                },
                {
                    lng: 125.68579634003055,
                    lat: -14.23065561285385
                },
                {
                    lng: 126.12514936737608,
                    lat: -14.347340996968903
                },
                {
                    lng: 126.14282270721986,
                    lat: -14.095986830301227
                },
                {
                    lng: 126.58258914602374,
                    lat: -13.952791436420448
                },
                {
                    lng: 127.06586714081732,
                    lat: -13.817967624570954
                },
                {
                    lng: 127.80463341686196,
                    lat: -14.27690601975508
                },
                {
                    lng: 128.35968997610894,
                    lat: -14.869169610252243
                },
                {
                    lng: 128.98554324759584,
                    lat: -14.875990899314765
                },
                {
                    lng: 129.62147342337965,
                    lat: -14.969783623924522
                },
                {
                    lng: 129.40960005098293,
                    lat: -14.42066985439107
                },
                {
                    lng: 129.8886405783286,
                    lat: -13.618703301653492
                },
                {
                    lng: 130.33946577364293,
                    lat: -13.357375583553484
                },
                {
                    lng: 130.18350630098604,
                    lat: -13.107520033422276
                },
                {
                    lng: 130.61779503796697,
                    lat: -12.536392103732489
                },
                {
                    lng: 131.22349450086,
                    lat: -12.183648776908166
                },
                {
                    lng: 131.73509118054955,
                    lat: -12.302452894747184
                },
                {
                    lng: 132.5752982931831,
                    lat: -12.114040622611007
                },
                {
                    lng: 132.55721154188097,
                    lat: -11.603012383676678
                },
                {
                    lng: 131.82469811414364,
                    lat: -11.27378183354515
                },
                {
                    lng: 132.3572237489114,
                    lat: -11.128519382372696
                },
                {
                    lng: 133.01956058159635,
                    lat: -11.376411228076812
                },
                {
                    lng: 133.55084598198908,
                    lat: -11.786515394745116
                },
                {
                    lng: 134.39306847548204,
                    lat: -12.042365411022182
                },
                {
                    lng: 134.67863244032696,
                    lat: -11.941182956594693
                },
                {
                    lng: 135.29849124566795,
                    lat: -12.248606052299046
                },
                {
                    lng: 135.8826933127276,
                    lat: -11.962266940969776
                },
                {
                    lng: 136.2583809754895,
                    lat: -12.049341729381588
                },
                {
                    lng: 136.49247521377168,
                    lat: -11.857208754120398
                },
                {
                    lng: 136.951620314685,
                    lat: -12.351958916882793
                },
                {
                    lng: 136.6851249533558,
                    lat: -12.887223402562022
                },
                {
                    lng: 136.3054065288751,
                    lat: -13.291229750219884
                },
                {
                    lng: 135.96175825413417,
                    lat: -13.324509372615852
                },
                {
                    lng: 136.07761681533253,
                    lat: -13.724278252825783
                },
                {
                    lng: 135.78383629775323,
                    lat: -14.2239893530882
                },
                {
                    lng: 135.4286641786112,
                    lat: -14.715432224183912
                },
                {
                    lng: 135.50018436090318,
                    lat: -14.997740573794424
                },
                {
                    lng: 136.2951745952813,
                    lat: -15.55026498785913
                },
                {
                    lng: 137.06536014215942,
                    lat: -15.87076222093333
                },
                {
                    lng: 137.5804708192448,
                    lat: -16.21508228929408
                },
                {
                    lng: 138.30321740127897,
                    lat: -16.807604261952704
                },
                {
                    lng: 138.58516401586343,
                    lat: -16.806622409739155
                },
                {
                    lng: 139.10854292211548,
                    lat: -17.06267913174539
                },
                {
                    lng: 139.2605749859182,
                    lat: -17.371600843986208
                },
                {
                    lng: 140.21524539607827,
                    lat: -17.710804945550066
                },
                {
                    lng: 140.87546349503924,
                    lat: -17.369068698803908
                },
                {
                    lng: 141.07111046769626,
                    lat: -16.83204721442676
                },
                {
                    lng: 141.27409549373874,
                    lat: -16.38887013109165
                },
                {
                    lng: 141.39822228410384,
                    lat: -15.840531508042588
                },
                {
                    lng: 141.70218305884464,
                    lat: -15.044921156476901
                },
                {
                    lng: 141.56338016170866,
                    lat: -14.561333103089552
                },
                {
                    lng: 141.6355204611881,
                    lat: -14.270394789286307
                },
                {
                    lng: 141.5198686057189,
                    lat: -13.698078301653808
                },
                {
                    lng: 141.65092003801107,
                    lat: -12.944687595270585
                },
                {
                    lng: 141.8426912782462,
                    lat: -12.741547539931231
                },
                {
                    lng: 141.68699018775084,
                    lat: -12.407614434461145
                },
                {
                    lng: 141.9286291851476,
                    lat: -11.877465915578817
                },
                {
                    lng: 142.11848839738798,
                    lat: -11.328042087451612
                },
                {
                    lng: 142.1437064963464,
                    lat: -11.042736504768186
                },
                {
                    lng: 142.51526004452495,
                    lat: -10.668185723516686
                },
                {
                    lng: 142.797310011974,
                    lat: -11.157354831591562
                },
                {
                    lng: 142.86676313697427,
                    lat: -11.784706719614903
                },
                {
                    lng: 143.11594689348573,
                    lat: -11.905629571177885
                },
                {
                    lng: 143.15863162655876,
                    lat: -12.325655612846232
                },
                {
                    lng: 143.5221236512998,
                    lat: -12.834358412327433
                },
                {
                    lng: 143.5971578309876,
                    lat: -13.400422051652612
                },
                {
                    lng: 143.5618111513,
                    lat: -13.763655694232192
                },
                {
                    lng: 143.9220992372389,
                    lat: -14.548310642151996
                },
                {
                    lng: 144.56371382057483,
                    lat: -14.171176039285903
                },
                {
                    lng: 144.89490807513346,
                    lat: -14.594457696188641
                },
                {
                    lng: 145.3747237489635,
                    lat: -14.98497649501833
                },
                {
                    lng: 145.27199100156724,
                    lat: -15.428205254785732
                },
                {
                    lng: 145.4852596376358,
                    lat: -16.28567229580478
                },
                {
                    lng: 145.637033319277,
                    lat: -16.78491830817657
                },
                {
                    lng: 145.88890425026761,
                    lat: -16.906926364817686
                },
                {
                    lng: 146.16030887266453,
                    lat: -17.761654554925272
                },
                {
                    lng: 146.06367394427872,
                    lat: -18.28007252367734
                },
                {
                    lng: 146.38747846901964,
                    lat: -18.958274021075887
                },
                {
                    lng: 147.4710815777479,
                    lat: -19.48072275154673
                },
                {
                    lng: 148.17760176004242,
                    lat: -19.9559392229028
                },
                {
                    lng: 148.84841352762322,
                    lat: -20.391209812097244
                },
                {
                    lng: 148.71746544819558,
                    lat: -20.63346892668155
                },
                {
                    lng: 149.28942020080206,
                    lat: -21.260510756111135
                },
                {
                    lng: 149.6783370302307,
                    lat: -22.342511895438385
                },
                {
                    lng: 150.07738244038853,
                    lat: -22.122783705333337
                },
                {
                    lng: 150.48293908101516,
                    lat: -22.556142266532994
                },
                {
                    lng: 150.72726525289113,
                    lat: -22.402404880464665
                },
                {
                    lng: 150.89955447815225,
                    lat: -23.462236830338696
                },
                {
                    lng: 151.60917524638427,
                    lat: -24.07625619883074
                },
                {
                    lng: 152.07353966695905,
                    lat: -24.457886651306225
                },
                {
                    lng: 152.8551973818059,
                    lat: -25.267501316023
                },
                {
                    lng: 153.1361621441768,
                    lat: -26.071173191026215
                },
                {
                    lng: 153.16194868389044,
                    lat: -26.641319268502457
                },
                {
                    lng: 153.0929089703485,
                    lat: -27.260299574494514
                },
                {
                    lng: 153.56946902894418,
                    lat: -28.11006682710208
                },
                {
                    lng: 153.51210818910022,
                    lat: -28.99507740653271
                },
                {
                    lng: 153.339095493787,
                    lat: -29.45820159273248
                },
                {
                    lng: 153.06924116435886,
                    lat: -30.350240166954794
                },
                {
                    lng: 153.08960167868184,
                    lat: -30.923641859665423
                },
                {
                    lng: 152.89157759013938,
                    lat: -31.640445651986
                },
                {
                    lng: 152.45000247620533,
                    lat: -32.550002536755265
                },
                {
                    lng: 151.70911746643674,
                    lat: -33.041342054986394
                },
                {
                    lng: 151.3439717958624,
                    lat: -33.81602345147387
                },
                {
                    lng: 151.0105554547152,
                    lat: -34.31036020277793
                },
                {
                    lng: 150.71413943908902,
                    lat: -35.173459974916796
                },
                {
                    lng: 150.3282198427333,
                    lat: -35.671879164371916
                },
                {
                    lng: 150.0752120302323,
                    lat: -36.42020558039054
                },
                {
                    lng: 149.9461243023672,
                    lat: -37.10905242284121
                },
                {
                    lng: 149.99728397033613,
                    lat: -37.42526051203518
                },
                {
                    lng: 149.42388227762552,
                    lat: -37.77268116633344
                },
                {
                    lng: 148.30462243061584,
                    lat: -37.809061374666925
                },
                {
                    lng: 147.38173302631526,
                    lat: -38.21921721776752
                },
                {
                    lng: 146.92212283751132,
                    lat: -38.606532077795116
                },
                {
                    lng: 146.31792199115478,
                    lat: -39.03575652441141
                },
                {
                    lng: 145.4896521343806,
                    lat: -38.59376799901902
                },
                {
                    lng: 144.87697635312816,
                    lat: -38.41744801203915
                },
                {
                    lng: 145.03221235573295,
                    lat: -37.89618783951102
                },
                {
                    lng: 144.48568240781407,
                    lat: -38.085323581699285
                },
                {
                    lng: 143.60997358619602,
                    lat: -38.8094654274053
                },
                {
                    lng: 142.74542687395297,
                    lat: -38.538267510737555
                },
                {
                    lng: 142.17832970598192,
                    lat: -38.380034275059835
                },
                {
                    lng: 141.60658165910468,
                    lat: -38.30851409276788
                },
                {
                    lng: 140.63857872941327,
                    lat: -38.019332777662555
                },
                {
                    lng: 139.99215823787426,
                    lat: -37.402936293285094
                },
                {
                    lng: 139.8065881695141,
                    lat: -36.64360279718831
                },
                {
                    lng: 139.57414757706528,
                    lat: -36.13836231867066
                },
                {
                    lng: 139.08280805883413,
                    lat: -35.732754001611745
                },
                {
                    lng: 138.12074791885635,
                    lat: -35.61229623793939
                },
                {
                    lng: 138.44946170466494,
                    lat: -35.127261244447865
                },
                {
                    lng: 138.20756432510672,
                    lat: -34.38472258884593
                },
                {
                    lng: 137.71917036351618,
                    lat: -35.076825046531
                },
                {
                    lng: 136.8294055523147,
                    lat: -35.26053476332861
                },
                {
                    lng: 137.35237104710848,
                    lat: -34.7073385556441
                },
                {
                    lng: 137.50388634658827,
                    lat: -34.13026783624075
                },
                {
                    lng: 137.8901160015377,
                    lat: -33.64047861097838
                },
                {
                    lng: 137.81032759007905,
                    lat: -32.90000701266812
                },
                {
                    lng: 136.9968371929404,
                    lat: -33.752771498348615
                },
                {
                    lng: 136.37206912653164,
                    lat: -34.094766127256236
                },
                {
                    lng: 135.98904341038428,
                    lat: -34.89011809666046
                },
                {
                    lng: 135.20821251845405,
                    lat: -34.478670342752565
                },
                {
                    lng: 135.23921837782916,
                    lat: -33.94795338311502
                },
                {
                    lng: 134.6134167827746,
                    lat: -33.222778008763164
                },
                {
                    lng: 134.08590376193916,
                    lat: -32.84807219821479
                },
                {
                    lng: 134.27390262261702,
                    lat: -32.61723357516699
                },
                {
                    lng: 132.99077680880976,
                    lat: -32.01122405368019
                },
                {
                    lng: 132.28808068250487,
                    lat: -31.982646986622782
                },
                {
                    lng: 131.32633060112084,
                    lat: -31.49580331800104
                },
                {
                    lng: 129.53579389863972,
                    lat: -31.590422865527465
                },
                {
                    lng: 128.24093753470225,
                    lat: -31.948488864877852
                },
                {
                    lng: 127.1028674663383,
                    lat: -32.28226694105106
                },
                {
                    lng: 126.14871382050114,
                    lat: -32.21596607842059
                }
            ]
        ]
    },
    LK: {
        name: 'Sri Lanka',
        type: 'Polygon',
        coordinates: [
            {
                lng: 81.7879590188914,
                lat: 7.523055324733164
            },
            {
                lng: 81.63732221876059,
                lat: 6.481775214051922
            },
            {
                lng: 81.21801964714433,
                lat: 6.197141424988288
            },
            {
                lng: 80.34835696810441,
                lat: 5.968369859232155
            },
            {
                lng: 79.87246870312853,
                lat: 6.76346344647493
            },
            {
                lng: 79.69516686393513,
                lat: 8.200843410673386
            },
            {
                lng: 80.14780073437964,
                lat: 9.824077663609557
            },
            {
                lng: 80.83881798698656,
                lat: 9.268426825391188
            },
            {
                lng: 81.30431928907177,
                lat: 8.56420624433369
            },
            {
                lng: 81.7879590188914,
                lat: 7.523055324733164
            }
        ]
    },
    CN: {
        name: 'China',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: 109.47520958866365,
                    lat: 18.197700913968575
                },
                {
                    lng: 108.65520796105616,
                    lat: 18.507681993071387
                },
                {
                    lng: 108.62621748254044,
                    lat: 19.367887885001906
                },
                {
                    lng: 109.11905561730804,
                    lat: 19.821038519769345
                },
                {
                    lng: 110.21159874882281,
                    lat: 20.101253973872033
                },
                {
                    lng: 110.78655073450221,
                    lat: 20.077534491450052
                },
                {
                    lng: 111.01005130416458,
                    lat: 19.69592987719072
                },
                {
                    lng: 110.57064660038677,
                    lat: 19.25587921800927
                },
                {
                    lng: 110.33918786015147,
                    lat: 18.678395087147592
                },
                {
                    lng: 109.47520958866365,
                    lat: 18.197700913968575
                }
            ],
            [
                {
                    lng: 80.2599902688853,
                    lat: 42.34999929459906
                },
                {
                    lng: 80.1801501809943,
                    lat: 42.92006785742694
                },
                {
                    lng: 80.86620649610126,
                    lat: 43.18036204688101
                },
                {
                    lng: 79.96610639844141,
                    lat: 44.91751699480463
                },
                {
                    lng: 81.9470707539181,
                    lat: 45.31702749285312
                },
                {
                    lng: 82.45892581576906,
                    lat: 45.539649563166506
                },
                {
                    lng: 83.18048383986047,
                    lat: 47.33003123635086
                },
                {
                    lng: 85.16429039911324,
                    lat: 47.0009557155161
                },
                {
                    lng: 85.72048383987067,
                    lat: 47.452969468773105
                },
                {
                    lng: 85.7682328633083,
                    lat: 48.45575063739699
                },
                {
                    lng: 86.59877648310336,
                    lat: 48.549181626980626
                },
                {
                    lng: 87.35997033076265,
                    lat: 49.21498078062912
                },
                {
                    lng: 87.75126427607671,
                    lat: 49.297197984405486
                },
                {
                    lng: 88.01383222855173,
                    lat: 48.599462795600616
                },
                {
                    lng: 88.85429772334676,
                    lat: 48.069081732772965
                },
                {
                    lng: 90.28082563676392,
                    lat: 47.69354909930793
                },
                {
                    lng: 90.97080936072501,
                    lat: 46.88814606382293
                },
                {
                    lng: 90.58576826371828,
                    lat: 45.71971609148753
                },
                {
                    lng: 90.9455395853343,
                    lat: 45.28607330991028
                },
                {
                    lng: 92.13389082231822,
                    lat: 45.11507599545646
                },
                {
                    lng: 93.4807336771413,
                    lat: 44.975472113619965
                },
                {
                    lng: 94.68892866412533,
                    lat: 44.35233185482842
                },
                {
                    lng: 95.30687544147153,
                    lat: 44.24133087826547
                },
                {
                    lng: 95.76245486855669,
                    lat: 43.319449164394605
                },
                {
                    lng: 96.34939578652781,
                    lat: 42.725635280928685
                },
                {
                    lng: 97.45175744017801,
                    lat: 42.74888967546002
                },
                {
                    lng: 99.51581749878004,
                    lat: 42.524691473961724
                },
                {
                    lng: 100.84586551310827,
                    lat: 42.66380442969145
                },
                {
                    lng: 101.83304039917994,
                    lat: 42.51487295182628
                },
                {
                    lng: 103.31227827353482,
                    lat: 41.9074681666676
                },
                {
                    lng: 104.52228193564899,
                    lat: 41.908346666016556
                },
                {
                    lng: 104.96499393109347,
                    lat: 41.59740957291635
                },
                {
                    lng: 106.12931562706169,
                    lat: 42.13432770442891
                },
                {
                    lng: 107.74477257693795,
                    lat: 42.48151581478187
                },
                {
                    lng: 109.24359581913146,
                    lat: 42.5194463160841
                },
                {
                    lng: 110.41210330611528,
                    lat: 42.87123362891103
                },
                {
                    lng: 111.12968224492023,
                    lat: 43.40683401140015
                },
                {
                    lng: 111.82958784388137,
                    lat: 43.74311839453952
                },
                {
                    lng: 111.66773725794323,
                    lat: 44.07317576758771
                },
                {
                    lng: 111.34837690637946,
                    lat: 44.45744171811009
                },
                {
                    lng: 111.8733061056003,
                    lat: 45.10207937273506
                },
                {
                    lng: 112.43606245325881,
                    lat: 45.01164561622429
                },
                {
                    lng: 113.46390669154417,
                    lat: 44.80889313412712
                },
                {
                    lng: 114.46033165899607,
                    lat: 45.339816799493825
                },
                {
                    lng: 115.98509647020009,
                    lat: 45.727235012386004
                },
                {
                    lng: 116.71786828009886,
                    lat: 46.38820241961521
                },
                {
                    lng: 117.42170128791419,
                    lat: 46.67273285581426
                },
                {
                    lng: 118.87432579963873,
                    lat: 46.80541209572365
                },
                {
                    lng: 119.66326989143876,
                    lat: 46.69267995867892
                },
                {
                    lng: 119.7728239278975,
                    lat: 47.04805878355013
                },
                {
                    lng: 118.86657433479495,
                    lat: 47.74706004494617
                },
                {
                    lng: 118.06414269416672,
                    lat: 48.06673045510369
                },
                {
                    lng: 117.29550744025741,
                    lat: 47.69770905210743
                },
                {
                    lng: 116.30895267137323,
                    lat: 47.85341014260284
                },
                {
                    lng: 115.74283735561579,
                    lat: 47.72654450132629
                },
                {
                    lng: 115.48528201707306,
                    lat: 48.13538259540344
                },
                {
                    lng: 116.19180219936757,
                    lat: 49.134598090199106
                },
                {
                    lng: 116.67880089728612,
                    lat: 49.888531399121405
                },
                {
                    lng: 117.8792444194264,
                    lat: 49.510983384796965
                },
                {
                    lng: 119.2884607280258,
                    lat: 50.142882798862004
                },
                {
                    lng: 119.27939,
                    lat: 50.58292
                },
                {
                    lng: 120.18208000000016,
                    lat: 51.64355000000012
                },
                {
                    lng: 120.7382,
                    lat: 51.96411
                },
                {
                    lng: 120.72578901579197,
                    lat: 52.516226304730935
                },
                {
                    lng: 120.17708865771681,
                    lat: 52.75388621684124
                },
                {
                    lng: 121.00308475147017,
                    lat: 53.25140106873119
                },
                {
                    lng: 122.24574791879283,
                    lat: 53.431725979213695
                },
                {
                    lng: 123.57147,
                    lat: 53.4588
                },
                {
                    lng: 125.06821129771038,
                    lat: 53.16104482686886
                },
                {
                    lng: 125.94634891164617,
                    lat: 52.79279857035698
                },
                {
                    lng: 126.56439904185697,
                    lat: 51.7842554795327
                },
                {
                    lng: 126.93915652883766,
                    lat: 51.35389415140592
                },
                {
                    lng: 127.28745568248485,
                    lat: 50.73979726826548
                },
                {
                    lng: 127.65740000000028,
                    lat: 49.760270000000105
                },
                {
                    lng: 129.39781782442043,
                    lat: 49.44060008401544
                },
                {
                    lng: 130.58229332898242,
                    lat: 48.72968740497614
                },
                {
                    lng: 130.9872600000001,
                    lat: 47.79013
                },
                {
                    lng: 132.50669,
                    lat: 47.78896
                },
                {
                    lng: 133.37359581922794,
                    lat: 48.18344167743487
                },
                {
                    lng: 135.02631147678665,
                    lat: 48.478229885443874
                },
                {
                    lng: 134.50081,
                    lat: 47.57845
                },
                {
                    lng: 134.1123500000001,
                    lat: 47.21248
                },
                {
                    lng: 133.76964399631288,
                    lat: 46.116926988299014
                },
                {
                    lng: 133.09712000000013,
                    lat: 45.14409
                },
                {
                    lng: 131.8834542176595,
                    lat: 45.32116160743652
                },
                {
                    lng: 131.02519000000018,
                    lat: 44.96796
                },
                {
                    lng: 131.28855512911548,
                    lat: 44.11151968034828
                },
                {
                    lng: 131.1446879416149,
                    lat: 42.92998973242689
                },
                {
                    lng: 130.63386640840974,
                    lat: 42.90301463477053
                },
                {
                    lng: 130.63999970690955,
                    lat: 42.39502427522179
                },
                {
                    lng: 129.99426720593323,
                    lat: 42.985386867843786
                },
                {
                    lng: 129.59666873587952,
                    lat: 42.42498179785456
                },
                {
                    lng: 128.0522152039723,
                    lat: 41.99428457291795
                },
                {
                    lng: 128.20843305879066,
                    lat: 41.46677155208249
                },
                {
                    lng: 127.34378299368302,
                    lat: 41.50315176041597
                },
                {
                    lng: 126.86908328664987,
                    lat: 41.81656932226619
                },
                {
                    lng: 126.18204511932943,
                    lat: 41.10733612727637
                },
                {
                    lng: 125.07994184784064,
                    lat: 40.56982371679245
                },
                {
                    lng: 124.26562462778531,
                    lat: 39.928493353834156
                },
                {
                    lng: 122.86757042856095,
                    lat: 39.63778758397622
                },
                {
                    lng: 122.1313879741309,
                    lat: 39.170451768544666
                },
                {
                    lng: 121.05455447803286,
                    lat: 38.897471014962846
                },
                {
                    lng: 121.58599490772248,
                    lat: 39.36085358332407
                },
                {
                    lng: 121.37675703337268,
                    lat: 39.75026133885949
                },
                {
                    lng: 122.16859500538106,
                    lat: 40.422442531896024
                },
                {
                    lng: 121.64035851449353,
                    lat: 40.94638987890333
                },
                {
                    lng: 120.76862877816194,
                    lat: 40.59338816991754
                },
                {
                    lng: 119.63960208544907,
                    lat: 39.898055935214245
                },
                {
                    lng: 119.02346398323306,
                    lat: 39.252333075511146
                },
                {
                    lng: 118.04274865119791,
                    lat: 39.2042739934797
                },
                {
                    lng: 117.53270226447711,
                    lat: 38.73763580988408
                },
                {
                    lng: 118.05969852098964,
                    lat: 38.06147553156106
                },
                {
                    lng: 118.87814985562832,
                    lat: 37.89732534438593
                },
                {
                    lng: 118.91163618375344,
                    lat: 37.44846385349871
                },
                {
                    lng: 119.70280236214205,
                    lat: 37.15638865818505
                },
                {
                    lng: 120.8234574728236,
                    lat: 37.87042776137801
                },
                {
                    lng: 121.711258579598,
                    lat: 37.48112335870718
                },
                {
                    lng: 122.35793745329849,
                    lat: 37.45448415786072
                },
                {
                    lng: 122.5199947449658,
                    lat: 36.93061432550185
                },
                {
                    lng: 121.10416385303307,
                    lat: 36.651329047180425
                },
                {
                    lng: 120.63700890511453,
                    lat: 36.111439520811075
                },
                {
                    lng: 119.66456180224606,
                    lat: 35.60979055433772
                },
                {
                    lng: 119.1512081238586,
                    lat: 34.90985911716044
                },
                {
                    lng: 120.22752485563373,
                    lat: 34.36033193616865
                },
                {
                    lng: 120.62036909391655,
                    lat: 33.37672272392514
                },
                {
                    lng: 121.22901411345023,
                    lat: 32.46031871187719
                },
                {
                    lng: 121.90814578663003,
                    lat: 31.692174384074647
                },
                {
                    lng: 121.89191938689038,
                    lat: 30.949351508095106
                },
                {
                    lng: 121.26425744027328,
                    lat: 30.6762674016487
                },
                {
                    lng: 121.50351932178467,
                    lat: 30.14291494396429
                },
                {
                    lng: 122.0921138855891,
                    lat: 29.83252045340315
                },
                {
                    lng: 121.93842817595306,
                    lat: 29.01802236583478
                },
                {
                    lng: 121.68443851123851,
                    lat: 28.225512600206617
                },
                {
                    lng: 121.12566124886649,
                    lat: 28.135673122667185
                },
                {
                    lng: 120.39547326058232,
                    lat: 27.05320689544932
                },
                {
                    lng: 119.5854968608395,
                    lat: 25.740780544532612
                },
                {
                    lng: 118.65687137255452,
                    lat: 24.54739085540024
                },
                {
                    lng: 117.28160647997088,
                    lat: 23.624501451099658
                },
                {
                    lng: 115.89073530483512,
                    lat: 22.78287323657807
                },
                {
                    lng: 114.76382734584624,
                    lat: 22.668074042241667
                },
                {
                    lng: 114.15254682826564,
                    lat: 22.22376007739615
                },
                {
                    lng: 113.80677981980074,
                    lat: 22.5483397486214
                },
                {
                    lng: 113.24107791550159,
                    lat: 22.051367499270455
                },
                {
                    lng: 111.84359215703248,
                    lat: 21.550493679281473
                },
                {
                    lng: 110.78546552942407,
                    lat: 21.397143866455338
                },
                {
                    lng: 110.44403934127168,
                    lat: 20.34103261970633
                },
                {
                    lng: 109.88986128137361,
                    lat: 20.282457383703488
                },
                {
                    lng: 109.62765506392466,
                    lat: 21.008227037026703
                },
                {
                    lng: 109.86448815311832,
                    lat: 21.395050970947523
                },
                {
                    lng: 108.5228129415244,
                    lat: 21.715212307211814
                },
                {
                    lng: 108.05018029178294,
                    lat: 21.55237986906012
                },
                {
                    lng: 107.04342003787264,
                    lat: 21.811898912029914
                },
                {
                    lng: 106.56727339073532,
                    lat: 22.21820486092477
                },
                {
                    lng: 106.72540327354847,
                    lat: 22.79426788989842
                },
                {
                    lng: 105.81124718630522,
                    lat: 22.976892401617903
                },
                {
                    lng: 105.32920942588663,
                    lat: 23.352063300056912
                },
                {
                    lng: 104.47685835166448,
                    lat: 22.819150092046968
                },
                {
                    lng: 103.50451460166056,
                    lat: 22.70375661873921
                },
                {
                    lng: 102.7069922221001,
                    lat: 22.708795070887675
                },
                {
                    lng: 102.17043582561358,
                    lat: 22.464753119389304
                },
                {
                    lng: 101.65201785686152,
                    lat: 22.318198757409547
                },
                {
                    lng: 101.80311974488293,
                    lat: 21.17436676684507
                },
                {
                    lng: 101.27002566935997,
                    lat: 21.201651923095184
                },
                {
                    lng: 101.18000532430754,
                    lat: 21.436572984294028
                },
                {
                    lng: 101.15003299357825,
                    lat: 21.849984442629022
                },
                {
                    lng: 100.41653771362738,
                    lat: 21.558839423096614
                },
                {
                    lng: 99.98348921102149,
                    lat: 21.7429367131364
                },
                {
                    lng: 99.24089887898725,
                    lat: 22.11831431730458
                },
                {
                    lng: 99.5319922220874,
                    lat: 22.94903880461258
                },
                {
                    lng: 98.89874922078278,
                    lat: 23.14272207284253
                },
                {
                    lng: 98.66026248575577,
                    lat: 24.063286037689966
                },
                {
                    lng: 97.60471967976198,
                    lat: 23.897404690033042
                },
                {
                    lng: 97.72460900267914,
                    lat: 25.083637193293
                },
                {
                    lng: 98.67183800658916,
                    lat: 25.918702500913525
                },
                {
                    lng: 98.71209394734451,
                    lat: 26.743535874940267
                },
                {
                    lng: 98.68269005737046,
                    lat: 27.50881216075062
                },
                {
                    lng: 98.2462309102333,
                    lat: 27.74722138112918
                },
                {
                    lng: 97.91198774616944,
                    lat: 28.335945136014345
                },
                {
                    lng: 97.32711388549004,
                    lat: 28.26158274994634
                },
                {
                    lng: 96.24883344928779,
                    lat: 28.411030992134442
                },
                {
                    lng: 96.58659061074749,
                    lat: 28.83097951915434
                },
                {
                    lng: 96.11767866413103,
                    lat: 29.452802028922466
                },
                {
                    lng: 95.40480228066464,
                    lat: 29.03171662039213
                },
                {
                    lng: 94.56599043170294,
                    lat: 29.277438055939985
                },
                {
                    lng: 93.41334760943269,
                    lat: 28.640629380807226
                },
                {
                    lng: 92.50311893104364,
                    lat: 27.89687632904645
                },
                {
                    lng: 91.69665652869668,
                    lat: 27.771741848251665
                },
                {
                    lng: 91.25885379431992,
                    lat: 28.040614325466294
                },
                {
                    lng: 90.7305139505678,
                    lat: 28.064953925075756
                },
                {
                    lng: 90.01582889197118,
                    lat: 28.296438503527217
                },
                {
                    lng: 89.47581017452111,
                    lat: 28.042758897406397
                },
                {
                    lng: 88.81424848832056,
                    lat: 27.299315904239364
                },
                {
                    lng: 88.73032596227856,
                    lat: 28.086864732367516
                },
                {
                    lng: 88.12044070836987,
                    lat: 27.876541652939594
                },
                {
                    lng: 86.9545170430006,
                    lat: 27.974261786403517
                },
                {
                    lng: 85.82331994013151,
                    lat: 28.203575954698707
                },
                {
                    lng: 85.01163821812304,
                    lat: 28.642773952747344
                },
                {
                    lng: 84.23457970575015,
                    lat: 28.839893703724698
                },
                {
                    lng: 83.89899295444673,
                    lat: 29.320226141877658
                },
                {
                    lng: 83.33711510613719,
                    lat: 29.463731594352197
                },
                {
                    lng: 82.32751264845088,
                    lat: 30.115268052688137
                },
                {
                    lng: 81.52580447787474,
                    lat: 30.42271698660863
                },
                {
                    lng: 81.11125613802932,
                    lat: 30.183480943313402
                },
                {
                    lng: 79.7213668151071,
                    lat: 30.88271474865473
                },
                {
                    lng: 78.73889448437401,
                    lat: 31.515906073527063
                },
                {
                    lng: 78.45844648632601,
                    lat: 32.61816437431273
                },
                {
                    lng: 79.17612877799553,
                    lat: 32.48377981213771
                },
                {
                    lng: 79.20889163606859,
                    lat: 32.994394639613716
                },
                {
                    lng: 78.81108646028574,
                    lat: 33.50619802503242
                },
                {
                    lng: 78.91226891471322,
                    lat: 34.32193634697579
                },
                {
                    lng: 77.83745079947457,
                    lat: 35.494009507787766
                },
                {
                    lng: 76.19284834178569,
                    lat: 35.89840342868783
                },
                {
                    lng: 75.89689741405013,
                    lat: 36.666806138651836
                },
                {
                    lng: 75.15802778514092,
                    lat: 37.13303091078912
                },
                {
                    lng: 74.98000247589542,
                    lat: 37.419990139305895
                },
                {
                    lng: 74.8299857929521,
                    lat: 37.9900070257014
                },
                {
                    lng: 74.86481570831683,
                    lat: 38.3788463404816
                },
                {
                    lng: 74.25751427602273,
                    lat: 38.60650686294345
                },
                {
                    lng: 73.92885216664644,
                    lat: 38.50581533462274
                },
                {
                    lng: 73.6753792662548,
                    lat: 39.4312368841056
                },
                {
                    lng: 73.96001305531843,
                    lat: 39.660008449861735
                },
                {
                    lng: 73.8222436868283,
                    lat: 39.893973497063186
                },
                {
                    lng: 74.77686242055606,
                    lat: 40.36642527929163
                },
                {
                    lng: 75.4678279967307,
                    lat: 40.56207225194867
                },
                {
                    lng: 76.52636803579745,
                    lat: 40.42794607193512
                },
                {
                    lng: 76.90448449087708,
                    lat: 41.06648590754965
                },
                {
                    lng: 78.18719689322597,
                    lat: 41.18531586360481
                },
                {
                    lng: 78.54366092317531,
                    lat: 41.58224254003869
                },
                {
                    lng: 80.11943037305139,
                    lat: 42.12394074153825
                },
                {
                    lng: 80.2599902688853,
                    lat: 42.34999929459906
                }
            ]
        ]
    },
    TW: {
        name: 'Taiwan',
        type: 'Polygon',
        coordinates: [
            {
                lng: 121.77781782438993,
                lat: 24.3942735865194
            },
            {
                lng: 121.17563235889274,
                lat: 22.790857245367167
            },
            {
                lng: 120.74707970589623,
                lat: 21.970571397382113
            },
            {
                lng: 120.22008344938368,
                lat: 22.81486094816674
            },
            {
                lng: 120.1061885926124,
                lat: 23.556262722258236
            },
            {
                lng: 120.69467980355225,
                lat: 24.538450832613737
            },
            {
                lng: 121.49504438688878,
                lat: 25.295458889257386
            },
            {
                lng: 121.95124393116146,
                lat: 24.997595933527037
            },
            {
                lng: 121.77781782438993,
                lat: 24.3942735865194
            }
        ]
    },
    IT: {
        name: 'Italy',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: 10.44270145024663,
                    lat: 46.89354625099743
                },
                {
                    lng: 11.048555942436536,
                    lat: 46.75135854754634
                },
                {
                    lng: 11.16482791509327,
                    lat: 46.94157949481273
                },
                {
                    lng: 12.153088006243054,
                    lat: 47.11539317482645
                },
                {
                    lng: 12.376485223040817,
                    lat: 46.76755910906985
                },
                {
                    lng: 13.806475457421527,
                    lat: 46.509306138691215
                },
                {
                    lng: 13.698109978905478,
                    lat: 46.01677806251735
                },
                {
                    lng: 13.937630242578308,
                    lat: 45.59101593686462
                },
                {
                    lng: 13.141606479554296,
                    lat: 45.73669179949542
                },
                {
                    lng: 12.32858117030625,
                    lat: 45.381778062514826
                },
                {
                    lng: 12.383874952858548,
                    lat: 44.885374253919096
                },
                {
                    lng: 12.261453484759102,
                    lat: 44.600482082693986
                },
                {
                    lng: 12.589237094786455,
                    lat: 44.09136587175446
                },
                {
                    lng: 13.526905958722494,
                    lat: 43.587727362637864
                },
                {
                    lng: 14.029820997787029,
                    lat: 42.761007798832466
                },
                {
                    lng: 15.142569614327925,
                    lat: 41.95513967545685
                },
                {
                    lng: 15.926191033601896,
                    lat: 41.961315009115715
                },
                {
                    lng: 16.169897088290384,
                    lat: 41.74029490820339
                },
                {
                    lng: 15.889345737377797,
                    lat: 41.541082261718216
                },
                {
                    lng: 16.785001661860548,
                    lat: 41.17960561783656
                },
                {
                    lng: 17.51916873543118,
                    lat: 40.87714345963222
                },
                {
                    lng: 18.37668745288252,
                    lat: 40.35562490494263
                },
                {
                    lng: 18.48024702319543,
                    lat: 40.16886627863981
                },
                {
                    lng: 18.293385044028042,
                    lat: 39.810774441073264
                },
                {
                    lng: 17.738380161213314,
                    lat: 40.277671006830346
                },
                {
                    lng: 16.869595981522338,
                    lat: 40.44223460546381
                },
                {
                    lng: 16.448743116937322,
                    lat: 39.79540070246644
                },
                {
                    lng: 17.17148969897147,
                    lat: 39.42469981542068
                },
                {
                    lng: 17.052840610429314,
                    lat: 38.90287120213735
                },
                {
                    lng: 16.635088331781816,
                    lat: 38.843572496082416
                },
                {
                    lng: 16.100960727613,
                    lat: 37.9858987493342
                },
                {
                    lng: 15.684086948314473,
                    lat: 37.908849188787016
                },
                {
                    lng: 15.687962680736348,
                    lat: 38.21459280044188
                },
                {
                    lng: 15.89198123542468,
                    lat: 38.75094249119924
                },
                {
                    lng: 16.109332309644287,
                    lat: 38.9645470240777
                },
                {
                    lng: 15.718813510814613,
                    lat: 39.54407237401492
                },
                {
                    lng: 15.413612501698793,
                    lat: 40.048356838535156
                },
                {
                    lng: 14.99849572109818,
                    lat: 40.17294871679087
                },
                {
                    lng: 14.703268263414714,
                    lat: 40.604550279292596
                },
                {
                    lng: 14.060671827865264,
                    lat: 40.7863479680954
                },
                {
                    lng: 13.627985060285397,
                    lat: 41.1882872584616
                },
                {
                    lng: 12.888081902730365,
                    lat: 41.253089504555604
                },
                {
                    lng: 12.106682570044939,
                    lat: 41.70453481705741
                },
                {
                    lng: 11.191906365614216,
                    lat: 42.3554253199897
                },
                {
                    lng: 10.51194786951774,
                    lat: 42.93146251074721
                },
                {
                    lng: 10.200028924203991,
                    lat: 43.9200068222746
                },
                {
                    lng: 9.702488234097842,
                    lat: 44.03627879493132
                },
                {
                    lng: 8.88894616052687,
                    lat: 44.36633616797951
                },
                {
                    lng: 8.428560825238577,
                    lat: 44.23122813575242
                },
                {
                    lng: 7.850766635783144,
                    lat: 43.76714793555524
                },
                {
                    lng: 7.435184767291872,
                    lat: 43.69384491634922
                },
                {
                    lng: 7.549596388386107,
                    lat: 44.12790110938481
                },
                {
                    lng: 7.007562290076635,
                    lat: 44.25476675066136
                },
                {
                    lng: 6.749955275101655,
                    lat: 45.02851797136758
                },
                {
                    lng: 7.096652459347837,
                    lat: 45.33309886329589
                },
                {
                    lng: 6.802355177445605,
                    lat: 45.70857982032864
                },
                {
                    lng: 6.843592970414505,
                    lat: 45.99114655210061
                },
                {
                    lng: 7.273850945676656,
                    lat: 45.776947740250776
                },
                {
                    lng: 7.755992058959833,
                    lat: 45.82449005795931
                },
                {
                    lng: 8.31662967289438,
                    lat: 46.16364248309086
                },
                {
                    lng: 8.489952426801324,
                    lat: 46.005150865251686
                },
                {
                    lng: 8.966305779667806,
                    lat: 46.03693187111119
                },
                {
                    lng: 9.182881707403055,
                    lat: 46.44021474871698
                },
                {
                    lng: 9.922836541390382,
                    lat: 46.31489940040919
                },
                {
                    lng: 10.363378126678612,
                    lat: 46.48357127540986
                },
                {
                    lng: 10.44270145024663,
                    lat: 46.89354625099743
                }
            ],
            [
                {
                    lng: 14.761249220446189,
                    lat: 38.14387360285046
                },
                {
                    lng: 15.520376010813806,
                    lat: 38.23115509699143
                },
                {
                    lng: 15.160242954171736,
                    lat: 37.44404551853776
                },
                {
                    lng: 15.309897902089006,
                    lat: 37.13421946873183
                },
                {
                    lng: 15.099988234119422,
                    lat: 36.6199872909954
                },
                {
                    lng: 14.335228712631988,
                    lat: 36.996630967754726
                },
                {
                    lng: 13.826732618879959,
                    lat: 37.10453135838016
                },
                {
                    lng: 12.431003859108756,
                    lat: 37.61294993748375
                },
                {
                    lng: 12.570943637755136,
                    lat: 38.12638113051966
                },
                {
                    lng: 13.741156447004613,
                    lat: 38.03496552179533
                },
                {
                    lng: 14.761249220446189,
                    lat: 38.14387360285046
                }
            ],
            [
                {
                    lng: 8.709990675500109,
                    lat: 40.899984442705225
                },
                {
                    lng: 9.210011834356266,
                    lat: 41.209991360024176
                },
                {
                    lng: 9.80997521326492,
                    lat: 40.50000885676613
                },
                {
                    lng: 9.669518670295616,
                    lat: 39.17737641047178
                },
                {
                    lng: 9.214817742559433,
                    lat: 39.24047333430015
                },
                {
                    lng: 8.806935662479674,
                    lat: 38.906617743478506
                },
                {
                    lng: 8.428302443077115,
                    lat: 39.17184703221655
                },
                {
                    lng: 8.388253208050912,
                    lat: 40.37831085871876
                },
                {
                    lng: 8.159998406617689,
                    lat: 40.95000722916376
                },
                {
                    lng: 8.709990675500109,
                    lat: 40.899984442705225
                }
            ]
        ]
    },
    DK: {
        name: 'Denmark',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: 9.921906365609118,
                    lat: 54.98310415304803
                },
                {
                    lng: 9.282048780971138,
                    lat: 54.830865383516176
                },
                {
                    lng: 8.526229282270208,
                    lat: 54.96274363872516
                },
                {
                    lng: 8.120310906617533,
                    lat: 55.5177226833236
                },
                {
                    lng: 8.08997684086222,
                    lat: 56.54001170513759
                },
                {
                    lng: 8.256581658571207,
                    lat: 56.80996938743033
                },
                {
                    lng: 8.543437534223415,
                    lat: 57.11000275331695
                },
                {
                    lng: 9.424469028367554,
                    lat: 57.1720661484995
                },
                {
                    lng: 9.775558709358535,
                    lat: 57.44794078228966
                },
                {
                    lng: 10.580005730846125,
                    lat: 57.73001658795485
                },
                {
                    lng: 10.546105991262664,
                    lat: 57.21573273378612
                },
                {
                    lng: 10.250000034230226,
                    lat: 56.89001618105044
                },
                {
                    lng: 10.369992710011957,
                    lat: 56.60998159446079
                },
                {
                    lng: 10.912181837618306,
                    lat: 56.458621324277885
                },
                {
                    lng: 10.667803989310016,
                    lat: 56.08138336854721
                },
                {
                    lng: 10.369992710011957,
                    lat: 56.19000722922473
                },
                {
                    lng: 9.649984978889279,
                    lat: 55.469999498102055
                },
                {
                    lng: 9.921906365609118,
                    lat: 54.98310415304803
                }
            ],
            [
                {
                    lng: 12.370904168353292,
                    lat: 56.111407375708794
                },
                {
                    lng: 12.690006137755603,
                    lat: 55.60999095318074
                },
                {
                    lng: 12.089991082414684,
                    lat: 54.80001455343792
                },
                {
                    lng: 11.043543328504226,
                    lat: 55.36486379660424
                },
                {
                    lng: 10.903913608451603,
                    lat: 55.77995473898872
                },
                {
                    lng: 12.370904168353292,
                    lat: 56.111407375708794
                }
            ]
        ]
    },
    GB: {
        name: 'United Kingdom',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: -6.197884894220991,
                    lat: 53.867565009163364
                },
                {
                    lng: -6.953730231138067,
                    lat: 54.073702297575636
                },
                {
                    lng: -7.572167934591064,
                    lat: 54.059956366586
                },
                {
                    lng: -7.366030646178785,
                    lat: 54.59584096945272
                },
                {
                    lng: -7.572167934591064,
                    lat: 55.13162221945487
                },
                {
                    lng: -6.733847011736145,
                    lat: 55.17286001242378
                },
                {
                    lng: -5.661948614921968,
                    lat: 54.55460317648381
                },
                {
                    lng: -6.197884894220991,
                    lat: 53.867565009163364
                }
            ],
            [
                {
                    lng: -3.093830673788716,
                    lat: 53.404547400669685
                },
                {
                    lng: -3.092079637047107,
                    lat: 53.40444082296358
                },
                {
                    lng: -2.945008510744344,
                    lat: 53.98499970154671
                },
                {
                    lng: -3.614700825433033,
                    lat: 54.600936773292574
                },
                {
                    lng: -3.630005458989331,
                    lat: 54.615012925833014
                },
                {
                    lng: -4.844169073903061,
                    lat: 54.790971177786844
                },
                {
                    lng: -5.082526617849283,
                    lat: 55.06160065369937
                },
                {
                    lng: -4.7191121077567,
                    lat: 55.50847260194348
                },
                {
                    lng: -5.047980922862109,
                    lat: 55.78398550070756
                },
                {
                    lng: -5.586397670911197,
                    lat: 55.31114614523682
                },
                {
                    lng: -5.644998745130238,
                    lat: 56.27501496034483
                },
                {
                    lng: -6.149980841486411,
                    lat: 56.785009670633485
                },
                {
                    lng: -5.786824713555291,
                    lat: 57.81884837506465
                },
                {
                    lng: -5.009998745127632,
                    lat: 58.63001333275008
                },
                {
                    lng: -4.211494513353557,
                    lat: 58.55084503847911
                },
                {
                    lng: -3.005004848635281,
                    lat: 58.63500010846633
                },
                {
                    lng: -4.073828497728073,
                    lat: 57.55302480735526
                },
                {
                    lng: -3.055001796877718,
                    lat: 57.69001902936094
                },
                {
                    lng: -1.959280564776918,
                    lat: 57.68479970969952
                },
                {
                    lng: -2.219988165689358,
                    lat: 56.87001740175356
                },
                {
                    lng: -3.119003058271176,
                    lat: 55.973793036515474
                },
                {
                    lng: -2.085009324543023,
                    lat: 55.9099984808513
                },
                {
                    lng: -2.005675679673857,
                    lat: 55.804902850350175
                },
                {
                    lng: -1.11499101399221,
                    lat: 54.624986477265395
                },
                {
                    lng: -0.4304849918542,
                    lat: 54.46437612570219
                },
                {
                    lng: 0.184981316742039,
                    lat: 53.32501414653103
                },
                {
                    lng: 0.469976840831805,
                    lat: 52.929999498092
                },
                {
                    lng: 1.681530795914682,
                    lat: 52.739520168664
                },
                {
                    lng: 1.559987827164321,
                    lat: 52.09999848083601
                },
                {
                    lng: 1.050561557630942,
                    lat: 51.806760565795685
                },
                {
                    lng: 1.449865349950244,
                    lat: 51.28942780212191
                },
                {
                    lng: 0.550333693045502,
                    lat: 50.765738837275876
                },
                {
                    lng: -0.787517462558696,
                    lat: 50.77498891865622
                },
                {
                    lng: -2.489997524414434,
                    lat: 50.50001862243124
                },
                {
                    lng: -2.956273972984093,
                    lat: 50.696879991247044
                },
                {
                    lng: -3.617448085942385,
                    lat: 50.22835561787275
                },
                {
                    lng: -4.542507900399244,
                    lat: 50.341837063185665
                },
                {
                    lng: -5.245023159191135,
                    lat: 49.95999990498109
                },
                {
                    lng: -5.776566941745273,
                    lat: 50.15967763935686
                },
                {
                    lng: -4.309989793301895,
                    lat: 51.21000112568919
                },
                {
                    lng: -3.414850633142123,
                    lat: 51.42600861266925
                },
                {
                    lng: -3.422719467108379,
                    lat: 51.42684816740609
                },
                {
                    lng: -4.98436723471093,
                    lat: 51.593466091511004
                },
                {
                    lng: -5.267295701508942,
                    lat: 51.991400458374585
                },
                {
                    lng: -4.222346564134909,
                    lat: 52.301355699261364
                },
                {
                    lng: -4.77001339356417,
                    lat: 52.840004991255626
                },
                {
                    lng: -4.579999152026971,
                    lat: 53.49500377055517
                },
                {
                    lng: -3.093830673788716,
                    lat: 53.404547400669685
                }
            ]
        ]
    },
    IS: {
        name: 'Iceland',
        type: 'Polygon',
        coordinates: [
            {
                lng: -14.508695441129234,
                lat: 66.45589223903143
            },
            {
                lng: -14.739637417041607,
                lat: 65.8087482774403
            },
            {
                lng: -13.60973222497981,
                lat: 65.12667104761987
            },
            {
                lng: -14.909833746794902,
                lat: 64.36408193628868
            },
            {
                lng: -17.794438035543422,
                lat: 63.678749091233854
            },
            {
                lng: -18.656245896874992,
                lat: 63.49638296167582
            },
            {
                lng: -19.97275468594276,
                lat: 63.64363495549153
            },
            {
                lng: -22.762971971110158,
                lat: 63.960178941495386
            },
            {
                lng: -21.778484259517683,
                lat: 64.40211579045551
            },
            {
                lng: -23.95504391121911,
                lat: 64.8911298692335
            },
            {
                lng: -22.184402635170358,
                lat: 65.0849681667603
            },
            {
                lng: -22.227423265053332,
                lat: 65.37859365504274
            },
            {
                lng: -24.326184047939336,
                lat: 65.61118927678847
            },
            {
                lng: -23.65051469572309,
                lat: 66.26251902939522
            },
            {
                lng: -22.134922451250887,
                lat: 66.41046865504687
            },
            {
                lng: -20.57628373867955,
                lat: 65.73211212835143
            },
            {
                lng: -19.05684160000159,
                lat: 66.27660085719477
            },
            {
                lng: -17.79862382655905,
                lat: 65.99385325790978
            },
            {
                lng: -16.167818976292125,
                lat: 66.52679230413587
            },
            {
                lng: -14.508695441129234,
                lat: 66.45589223903143
            }
        ]
    },
    AZ: {
        name: 'Azerbaijan',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: 46.404950799348825,
                    lat: 41.860675157227305
                },
                {
                    lng: 46.68607059101661,
                    lat: 41.82713715266988
                },
                {
                    lng: 47.37331546406623,
                    lat: 41.2197323675112
                },
                {
                    lng: 47.81566572448463,
                    lat: 41.15141612402135
                },
                {
                    lng: 47.98728315612598,
                    lat: 41.40581920019423
                },
                {
                    lng: 48.58435339611342,
                    lat: 41.80886879162067
                },
                {
                    lng: 49.110263706260696,
                    lat: 41.28228668880051
                },
                {
                    lng: 49.6189148293096,
                    lat: 40.57292430272996
                },
                {
                    lng: 50.08482954285313,
                    lat: 40.52615713150581
                },
                {
                    lng: 50.39282107931268,
                    lat: 40.25656118423912
                },
                {
                    lng: 49.569202101444795,
                    lat: 40.176100979160665
                },
                {
                    lng: 49.395259230350376,
                    lat: 39.39948171646225
                },
                {
                    lng: 49.22322838725071,
                    lat: 39.04921885838793
                },
                {
                    lng: 48.85653242370759,
                    lat: 38.81548635513178
                },
                {
                    lng: 48.88324913920249,
                    lat: 38.32024526626262
                },
                {
                    lng: 48.63437544128482,
                    lat: 38.27037750910097
                },
                {
                    lng: 48.01074425638648,
                    lat: 38.79401479751452
                },
                {
                    lng: 48.35552941263788,
                    lat: 39.28876496027691
                },
                {
                    lng: 48.06009524922524,
                    lat: 39.58223541926246
                },
                {
                    lng: 47.685079380083096,
                    lat: 39.50836395930122
                },
                {
                    lng: 46.50571984231797,
                    lat: 38.770605373686294
                },
                {
                    lng: 46.48349897643246,
                    lat: 39.464154771475535
                },
                {
                    lng: 46.034534132680676,
                    lat: 39.628020738273065
                },
                {
                    lng: 45.61001224140293,
                    lat: 39.89999380142518
                },
                {
                    lng: 45.89190717955509,
                    lat: 40.21847565364
                },
                {
                    lng: 45.35917483905817,
                    lat: 40.56150381119346
                },
                {
                    lng: 45.56035118997045,
                    lat: 40.812289537105926
                },
                {
                    lng: 45.17949588397934,
                    lat: 40.98535390885141
                },
                {
                    lng: 44.97248009621808,
                    lat: 41.248128567055595
                },
                {
                    lng: 45.217426385281584,
                    lat: 41.41145193131405
                },
                {
                    lng: 45.96260053893039,
                    lat: 41.123872585609774
                },
                {
                    lng: 46.50163740416693,
                    lat: 41.06444468847411
                },
                {
                    lng: 46.63790815612058,
                    lat: 41.181672675128226
                },
                {
                    lng: 46.14543175637902,
                    lat: 41.72280243587258
                },
                {
                    lng: 46.404950799348825,
                    lat: 41.860675157227305
                }
            ],
            [
                {
                    lng: 46.14362308124882,
                    lat: 38.74120148371222
                },
                {
                    lng: 45.45772179543877,
                    lat: 38.87413910578306
                },
                {
                    lng: 44.95268802265031,
                    lat: 39.33576467544637
                },
                {
                    lng: 44.79398969908195,
                    lat: 39.71300263117705
                },
                {
                    lng: 45.00198733905675,
                    lat: 39.740003567049555
                },
                {
                    lng: 45.29814497252146,
                    lat: 39.471751207022436
                },
                {
                    lng: 45.73997846861698,
                    lat: 39.47399913182713
                },
                {
                    lng: 45.73537926614301,
                    lat: 39.31971914321974
                },
                {
                    lng: 46.14362308124882,
                    lat: 38.74120148371222
                }
            ]
        ]
    },
    GE: {
        name: 'Georgia',
        type: 'Polygon',
        coordinates: [
            {
                lng: 39.955008579270924,
                lat: 43.43499766699922
            },
            {
                lng: 40.07696495947977,
                lat: 43.553104153002316
            },
            {
                lng: 40.922190000000114,
                lat: 43.38215000000014
            },
            {
                lng: 42.39440000000013,
                lat: 43.2203
            },
            {
                lng: 43.75599000000011,
                lat: 42.74083
            },
            {
                lng: 43.931210000000135,
                lat: 42.55496000000011
            },
            {
                lng: 44.537622918481986,
                lat: 42.71199270280363
            },
            {
                lng: 45.47027916848572,
                lat: 42.50278066666998
            },
            {
                lng: 45.7764,
                lat: 42.09244000000018
            },
            {
                lng: 46.404950799348825,
                lat: 41.860675157227305
            },
            {
                lng: 46.14543175637902,
                lat: 41.72280243587258
            },
            {
                lng: 46.63790815612058,
                lat: 41.181672675128226
            },
            {
                lng: 46.50163740416693,
                lat: 41.06444468847411
            },
            {
                lng: 45.96260053893039,
                lat: 41.123872585609774
            },
            {
                lng: 45.217426385281584,
                lat: 41.41145193131405
            },
            {
                lng: 44.97248009621808,
                lat: 41.248128567055595
            },
            {
                lng: 43.58274580259273,
                lat: 41.09214325618257
            },
            {
                lng: 42.61954878110449,
                lat: 41.58317271581994
            },
            {
                lng: 41.55408410011066,
                lat: 41.53565623632757
            },
            {
                lng: 41.70317060727271,
                lat: 41.96294281673292
            },
            {
                lng: 41.45347008643839,
                lat: 42.64512339941794
            },
            {
                lng: 40.87546919125379,
                lat: 43.013628038091284
            },
            {
                lng: 40.32139448422032,
                lat: 43.128633938156845
            },
            {
                lng: 39.955008579270924,
                lat: 43.43499766699922
            }
        ]
    },
    PH: {
        name: 'Philippines',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: 120.83389611214656,
                    lat: 12.704496161342433
                },
                {
                    lng: 120.32343631396745,
                    lat: 13.466413479053825
                },
                {
                    lng: 121.18012820850211,
                    lat: 13.429697373910443
                },
                {
                    lng: 121.5273938335035,
                    lat: 13.06959015548452
                },
                {
                    lng: 121.26219038298159,
                    lat: 12.205560207564403
                },
                {
                    lng: 120.83389611214656,
                    lat: 12.704496161342433
                }
            ],
            [
                {
                    lng: 122.5860889018671,
                    lat: 9.981044826696134
                },
                {
                    lng: 122.83708133350875,
                    lat: 10.261156927934225
                },
                {
                    lng: 122.9474105164519,
                    lat: 10.88186839440806
                },
                {
                    lng: 123.49884972543845,
                    lat: 10.94062449792392
                },
                {
                    lng: 123.33777428598472,
                    lat: 10.267383938025404
                },
                {
                    lng: 124.0779358257012,
                    lat: 11.232725531453738
                },
                {
                    lng: 123.98243777882584,
                    lat: 10.278778591345755
                },
                {
                    lng: 123.62318322153271,
                    lat: 9.950090643753299
                },
                {
                    lng: 123.30992068897939,
                    lat: 9.318268744336706
                },
                {
                    lng: 122.99588300994166,
                    lat: 9.022188625520414
                },
                {
                    lng: 122.38005496631942,
                    lat: 9.713360907424217
                },
                {
                    lng: 122.5860889018671,
                    lat: 9.981044826696134
                }
            ],
            [
                {
                    lng: 126.37681359263745,
                    lat: 8.414706325713297
                },
                {
                    lng: 126.47851281138789,
                    lat: 7.750354112168978
                },
                {
                    lng: 126.53742394420061,
                    lat: 7.189380601424545
                },
                {
                    lng: 126.19677290253259,
                    lat: 6.274294338400054
                },
                {
                    lng: 125.83142052622907,
                    lat: 7.293715318221842
                },
                {
                    lng: 125.36385216685221,
                    lat: 6.786485297060949
                },
                {
                    lng: 125.68316084198369,
                    lat: 6.049656887227272
                },
                {
                    lng: 125.39651167206063,
                    lat: 5.581003322772276
                },
                {
                    lng: 124.21978763234239,
                    lat: 6.161355495626154
                },
                {
                    lng: 123.9387195171069,
                    lat: 6.885135606306136
                },
                {
                    lng: 124.24366214406128,
                    lat: 7.360610459823661
                },
                {
                    lng: 123.61021243702749,
                    lat: 7.83352732994274
                },
                {
                    lng: 123.29607140512519,
                    lat: 7.418875637232759
                },
                {
                    lng: 122.82550581267537,
                    lat: 7.457374579290204
                },
                {
                    lng: 122.08549930225573,
                    lat: 6.899424139834836
                },
                {
                    lng: 121.91992801319256,
                    lat: 7.192119452336015
                },
                {
                    lng: 122.31235884001705,
                    lat: 8.034962063016465
                },
                {
                    lng: 122.94239790251959,
                    lat: 8.316236883981134
                },
                {
                    lng: 123.48768761606347,
                    lat: 8.69300975182118
                },
                {
                    lng: 123.84115441293983,
                    lat: 8.240324204944372
                },
                {
                    lng: 124.60146976125021,
                    lat: 8.514157619659002
                },
                {
                    lng: 124.76461225799562,
                    lat: 8.960409450715488
                },
                {
                    lng: 125.47139082245155,
                    lat: 8.986996975129657
                },
                {
                    lng: 125.41211795461277,
                    lat: 9.760334784377534
                },
                {
                    lng: 126.2227144715431,
                    lat: 9.286074327018866
                },
                {
                    lng: 126.30663699758514,
                    lat: 8.78248749433456
                },
                {
                    lng: 126.37681359263745,
                    lat: 8.414706325713297
                }
            ],
            [
                {
                    lng: 118.50458092659036,
                    lat: 9.316382554558047
                },
                {
                    lng: 117.17427453010066,
                    lat: 8.367499904814679
                },
                {
                    lng: 117.66447716682137,
                    lat: 9.066888739452892
                },
                {
                    lng: 118.38691369026175,
                    lat: 9.68449961998921
                },
                {
                    lng: 118.98734215706105,
                    lat: 10.376292019080523
                },
                {
                    lng: 119.51149620979751,
                    lat: 11.3696680770272
                },
                {
                    lng: 119.68967654833989,
                    lat: 10.554291490109875
                },
                {
                    lng: 119.02945844937892,
                    lat: 10.003653265823829
                },
                {
                    lng: 118.50458092659036,
                    lat: 9.316382554558047
                }
            ],
            [
                {
                    lng: 122.336956821788,
                    lat: 18.224882717354106
                },
                {
                    lng: 122.17427941293317,
                    lat: 17.810282701076403
                },
                {
                    lng: 122.5156539246533,
                    lat: 17.093504746971973
                },
                {
                    lng: 122.25231082569393,
                    lat: 16.26244436285407
                },
                {
                    lng: 121.66278608610821,
                    lat: 15.931017564350142
                },
                {
                    lng: 121.50506961475342,
                    lat: 15.124813544164622
                },
                {
                    lng: 121.7288285665772,
                    lat: 14.328376369682275
                },
                {
                    lng: 122.25892540902726,
                    lat: 14.21820221603599
                },
                {
                    lng: 122.70127566944569,
                    lat: 14.336541245984378
                },
                {
                    lng: 123.9502950379403,
                    lat: 13.782130642141027
                },
                {
                    lng: 123.85510704965867,
                    lat: 13.237771104378425
                },
                {
                    lng: 124.18128869028493,
                    lat: 12.9975273706535
                },
                {
                    lng: 124.0774190613783,
                    lat: 12.536676947474575
                },
                {
                    lng: 123.29803510955225,
                    lat: 13.02752553959894
                },
                {
                    lng: 122.92865197152994,
                    lat: 13.552919826710422
                },
                {
                    lng: 122.6713550151487,
                    lat: 13.185836289925092
                },
                {
                    lng: 122.03464969288052,
                    lat: 13.784481919810304
                },
                {
                    lng: 121.12638471891857,
                    lat: 13.636687323455547
                },
                {
                    lng: 120.62863732308324,
                    lat: 13.857655747935596
                },
                {
                    lng: 120.67938357959386,
                    lat: 14.271015529838294
                },
                {
                    lng: 120.99181928923053,
                    lat: 14.52539276779504
                },
                {
                    lng: 120.69333621631267,
                    lat: 14.756670640517312
                },
                {
                    lng: 120.56414513558299,
                    lat: 14.396279201713796
                },
                {
                    lng: 120.07042850146644,
                    lat: 14.970869452367126
                },
                {
                    lng: 119.92092858284605,
                    lat: 15.406346747290739
                },
                {
                    lng: 119.88377322802819,
                    lat: 16.363704331929995
                },
                {
                    lng: 120.28648766487875,
                    lat: 16.034628811095345
                },
                {
                    lng: 120.39004723519167,
                    lat: 17.599081122299523
                },
                {
                    lng: 120.71586714079191,
                    lat: 18.505227362537525
                },
                {
                    lng: 121.32130822152351,
                    lat: 18.504064642810945
                },
                {
                    lng: 121.9376013530364,
                    lat: 18.218552354398355
                },
                {
                    lng: 122.24600630095429,
                    lat: 18.478949896717097
                },
                {
                    lng: 122.336956821788,
                    lat: 18.224882717354106
                }
            ],
            [
                {
                    lng: 122.03837039600555,
                    lat: 11.415840969279998
                },
                {
                    lng: 121.88354780485909,
                    lat: 11.891755072471994
                },
                {
                    lng: 122.48382124236149,
                    lat: 11.582187404827538
                },
                {
                    lng: 123.12021650603594,
                    lat: 11.583660183147856
                },
                {
                    lng: 123.10083784392646,
                    lat: 11.16593374271649
                },
                {
                    lng: 122.63771365772669,
                    lat: 10.74130849857417
                },
                {
                    lng: 122.00261030485957,
                    lat: 10.441016750526089
                },
                {
                    lng: 121.96736697803652,
                    lat: 10.905691229694625
                },
                {
                    lng: 122.03837039600555,
                    lat: 11.415840969279998
                }
            ],
            [
                {
                    lng: 125.50255171112354,
                    lat: 12.162694606978292
                },
                {
                    lng: 125.78346479706221,
                    lat: 11.046121934447783
                },
                {
                    lng: 125.01188398651226,
                    lat: 11.311454576050409
                },
                {
                    lng: 125.03276126515817,
                    lat: 10.975816148314692
                },
                {
                    lng: 125.27744917206019,
                    lat: 10.358722032101284
                },
                {
                    lng: 124.80181928924577,
                    lat: 10.134678859899864
                },
                {
                    lng: 124.76016808481853,
                    lat: 10.837995103392258
                },
                {
                    lng: 124.45910119028605,
                    lat: 10.889929917845592
                },
                {
                    lng: 124.30252160044171,
                    lat: 11.495370998577187
                },
                {
                    lng: 124.89101281138153,
                    lat: 11.415582587118536
                },
                {
                    lng: 124.87799035044401,
                    lat: 11.794189968304947
                },
                {
                    lng: 124.26676150929569,
                    lat: 12.557760931849671
                },
                {
                    lng: 125.22711632700788,
                    lat: 12.53572093347718
                },
                {
                    lng: 125.50255171112354,
                    lat: 12.162694606978292
                }
            ]
        ]
    },
    MY: {
        name: 'Malaysia',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: 100.0857568705271,
                    lat: 6.464489447450291
                },
                {
                    lng: 100.25959638875696,
                    lat: 6.642824815289543
                },
                {
                    lng: 101.07551557821336,
                    lat: 6.204867051615921
                },
                {
                    lng: 101.15421878459387,
                    lat: 5.691384182147715
                },
                {
                    lng: 101.81428185425798,
                    lat: 5.810808417174242
                },
                {
                    lng: 102.14118696493638,
                    lat: 6.221636053894628
                },
                {
                    lng: 102.37114708863521,
                    lat: 6.128205064310919
                },
                {
                    lng: 102.96170535686673,
                    lat: 5.524495144061106
                },
                {
                    lng: 103.38121463421217,
                    lat: 4.855001125503748
                },
                {
                    lng: 103.43857547405612,
                    lat: 4.181605536308339
                },
                {
                    lng: 103.33212202353486,
                    lat: 3.726697902842986
                },
                {
                    lng: 103.42942874554049,
                    lat: 3.382868760589005
                },
                {
                    lng: 103.50244754436889,
                    lat: 2.791018581550176
                },
                {
                    lng: 103.8546741068703,
                    lat: 2.515454006353764
                },
                {
                    lng: 104.24793175661145,
                    lat: 1.631141058759084
                },
                {
                    lng: 104.22881147666348,
                    lat: 1.293048000489492
                },
                {
                    lng: 103.51970747275439,
                    lat: 1.226333726400682
                },
                {
                    lng: 102.57361535035477,
                    lat: 1.967115383304687
                },
                {
                    lng: 101.39063846232919,
                    lat: 2.760813706875581
                },
                {
                    lng: 101.27353966675582,
                    lat: 3.270291652841152
                },
                {
                    lng: 100.69543541870664,
                    lat: 3.93913971599487
                },
                {
                    lng: 100.55740766805502,
                    lat: 4.767280381688295
                },
                {
                    lng: 100.19670617065775,
                    lat: 5.312492580583708
                },
                {
                    lng: 100.30626020711652,
                    lat: 6.040561835143905
                },
                {
                    lng: 100.0857568705271,
                    lat: 6.464489447450291
                }
            ],
            [
                {
                    lng: 117.88203494677018,
                    lat: 4.137551377779516
                },
                {
                    lng: 117.01521447150628,
                    lat: 4.306094061699469
                },
                {
                    lng: 115.8655172058767,
                    lat: 4.3065591495901
                },
                {
                    lng: 115.51907840379198,
                    lat: 3.169238389494396
                },
                {
                    lng: 115.13403730678522,
                    lat: 2.821481838386234
                },
                {
                    lng: 114.62135542201753,
                    lat: 1.430688177898901
                },
                {
                    lng: 113.8058496440195,
                    lat: 1.217548732911069
                },
                {
                    lng: 112.85980919805218,
                    lat: 1.497790025229904
                },
                {
                    lng: 112.38025190638359,
                    lat: 1.410120957846743
                },
                {
                    lng: 111.79754845586041,
                    lat: 0.904441229654608
                },
                {
                    lng: 111.15913781132662,
                    lat: 0.976478176269481
                },
                {
                    lng: 110.51406090702716,
                    lat: 0.773131415200965
                },
                {
                    lng: 109.83022667850881,
                    lat: 1.338135687664163
                },
                {
                    lng: 109.66326012577375,
                    lat: 2.006466986494956
                },
                {
                    lng: 110.3961352885371,
                    lat: 1.663774725751395
                },
                {
                    lng: 111.16885298059748,
                    lat: 1.850636704918813
                },
                {
                    lng: 111.37008100794205,
                    lat: 2.697303371588859
                },
                {
                    lng: 111.7969283386729,
                    lat: 2.885896511238059
                },
                {
                    lng: 112.99561486211522,
                    lat: 3.102394924324855
                },
                {
                    lng: 113.71293541875868,
                    lat: 3.893509426281156
                },
                {
                    lng: 114.20401655482837,
                    lat: 4.525873928236805
                },
                {
                    lng: 114.65959598191353,
                    lat: 4.007636826997754
                },
                {
                    lng: 114.8695573263154,
                    lat: 4.348313706881925
                },
                {
                    lng: 115.34746097215066,
                    lat: 4.316636053887009
                },
                {
                    lng: 115.40570031134361,
                    lat: 4.955227565933839
                },
                {
                    lng: 115.45071048386981,
                    lat: 5.447729803891534
                },
                {
                    lng: 116.22074100145102,
                    lat: 6.143191229675566
                },
                {
                    lng: 116.72510298061971,
                    lat: 6.924771429873999
                },
                {
                    lng: 117.12962609260047,
                    lat: 6.92805288332454
                },
                {
                    lng: 117.64339318244627,
                    lat: 6.422166449403249
                },
                {
                    lng: 117.68907514859231,
                    lat: 5.987490139180154
                },
                {
                    lng: 118.34769127815224,
                    lat: 5.708695786965492
                },
                {
                    lng: 119.18190392463997,
                    lat: 5.407835598162207
                },
                {
                    lng: 119.11069380094176,
                    lat: 5.016128241389808
                },
                {
                    lng: 118.43972700406408,
                    lat: 4.966518866389606
                },
                {
                    lng: 118.6183207540648,
                    lat: 4.478202419447555
                },
                {
                    lng: 117.88203494677018,
                    lat: 4.137551377779516
                }
            ]
        ]
    },
    BN: {
        name: 'Brunei',
        type: 'Polygon',
        coordinates: [
            {
                lng: 115.45071048386981,
                lat: 5.447729803891534
            },
            {
                lng: 115.40570031134361,
                lat: 4.955227565933839
            },
            {
                lng: 115.34746097215066,
                lat: 4.316636053887009
            },
            {
                lng: 114.8695573263154,
                lat: 4.348313706881925
            },
            {
                lng: 114.65959598191353,
                lat: 4.007636826997754
            },
            {
                lng: 114.20401655482837,
                lat: 4.525873928236805
            },
            {
                lng: 114.59996137904872,
                lat: 4.900011298029966
            },
            {
                lng: 115.45071048386981,
                lat: 5.447729803891534
            }
        ]
    },
    SI: {
        name: 'Slovenia',
        type: 'Polygon',
        coordinates: [
            {
                lng: 13.806475457421527,
                lat: 46.509306138691215
            },
            {
                lng: 14.63247155117483,
                lat: 46.43181732846955
            },
            {
                lng: 15.137091912504985,
                lat: 46.65870270444703
            },
            {
                lng: 16.011663852612656,
                lat: 46.6836107448117
            },
            {
                lng: 16.202298211337364,
                lat: 46.85238597267696
            },
            {
                lng: 16.370504998447416,
                lat: 46.841327216166505
            },
            {
                lng: 16.564808383864857,
                lat: 46.50375092221983
            },
            {
                lng: 15.768732944408553,
                lat: 46.23810822202345
            },
            {
                lng: 15.671529575267556,
                lat: 45.83415355079788
            },
            {
                lng: 15.323953891672405,
                lat: 45.73178253842768
            },
            {
                lng: 15.327674594797429,
                lat: 45.45231639259333
            },
            {
                lng: 14.935243767972935,
                lat: 45.471695054702685
            },
            {
                lng: 14.595109490627806,
                lat: 45.634940904312714
            },
            {
                lng: 14.411968214585414,
                lat: 45.46616567644746
            },
            {
                lng: 13.715059848697223,
                lat: 45.500323798192376
            },
            {
                lng: 13.937630242578308,
                lat: 45.59101593686462
            },
            {
                lng: 13.698109978905478,
                lat: 46.01677806251735
            },
            {
                lng: 13.806475457421527,
                lat: 46.509306138691215
            }
        ]
    },
    FI: {
        name: 'Finland',
        type: 'Polygon',
        coordinates: [
            {
                lng: 28.591929559043194,
                lat: 69.06477692328666
            },
            {
                lng: 28.445943637818658,
                lat: 68.36461294216404
            },
            {
                lng: 29.97742638522061,
                lat: 67.69829702419275
            },
            {
                lng: 29.054588657352326,
                lat: 66.94428620062206
            },
            {
                lng: 30.21765,
                lat: 65.80598
            },
            {
                lng: 29.54442955904699,
                lat: 64.94867157659048
            },
            {
                lng: 30.44468468600371,
                lat: 64.20445343693909
            },
            {
                lng: 30.035872430142717,
                lat: 63.55281362573855
            },
            {
                lng: 31.516092156711125,
                lat: 62.86768748641289
            },
            {
                lng: 31.139991082490894,
                lat: 62.35769277612441
            },
            {
                lng: 30.21110721204445,
                lat: 61.780027777749694
            },
            {
                lng: 28.07,
                lat: 60.50352
            },
            {
                lng: 28.070001921525666,
                lat: 60.50351912796823
            },
            {
                lng: 28.069997592895277,
                lat: 60.50351654727584
            },
            {
                lng: 26.255172967236973,
                lat: 60.4239606797625
            },
            {
                lng: 24.496623976344523,
                lat: 60.05731639265166
            },
            {
                lng: 22.869694858499457,
                lat: 59.846373196036225
            },
            {
                lng: 22.290763787533592,
                lat: 60.39192129174154
            },
            {
                lng: 21.322244093519316,
                lat: 60.720169989659524
            },
            {
                lng: 21.544866163832694,
                lat: 61.70532949487179
            },
            {
                lng: 21.05921105315369,
                lat: 62.60739329695874
            },
            {
                lng: 21.536029493910803,
                lat: 63.18973501245587
            },
            {
                lng: 22.442744174903993,
                lat: 63.81781037053129
            },
            {
                lng: 24.730511508897536,
                lat: 64.90234365504084
            },
            {
                lng: 25.398067661243942,
                lat: 65.11142650009374
            },
            {
                lng: 25.294043003040404,
                lat: 65.53434642197045
            },
            {
                lng: 23.903378533633802,
                lat: 66.00692739527962
            },
            {
                lng: 23.565879754335583,
                lat: 66.39605093043743
            },
            {
                lng: 23.53947309743444,
                lat: 67.93600861273525
            },
            {
                lng: 21.978534783626117,
                lat: 68.6168456081807
            },
            {
                lng: 20.645592889089528,
                lat: 69.10624726020087
            },
            {
                lng: 21.244936150810673,
                lat: 69.37044302029308
            },
            {
                lng: 22.356237827247412,
                lat: 68.84174144151491
            },
            {
                lng: 23.66204959483076,
                lat: 68.89124746365054
            },
            {
                lng: 24.735679152126725,
                lat: 68.64955678982146
            },
            {
                lng: 25.689212680776365,
                lat: 69.09211375596904
            },
            {
                lng: 26.179622023226244,
                lat: 69.82529897732614
            },
            {
                lng: 27.732292107867863,
                lat: 70.16419302029625
            },
            {
                lng: 29.015572950971972,
                lat: 69.76649119737799
            },
            {
                lng: 28.591929559043194,
                lat: 69.06477692328666
            }
        ]
    },
    SK: {
        name: 'Slovak Republic',
        type: 'Polygon',
        coordinates: [
            {
                lng: 22.558137648211755,
                lat: 49.085738023467144
            },
            {
                lng: 22.28084191253356,
                lat: 48.82539215758067
            },
            {
                lng: 22.085608351334855,
                lat: 48.42226430927179
            },
            {
                lng: 21.872236362401736,
                lat: 48.31997081155002
            },
            {
                lng: 20.801293979584926,
                lat: 48.623854071642384
            },
            {
                lng: 20.473562045989866,
                lat: 48.562850043321816
            },
            {
                lng: 20.239054396249347,
                lat: 48.32756724709692
            },
            {
                lng: 19.769470656013112,
                lat: 48.202691148463614
            },
            {
                lng: 19.661363559658497,
                lat: 48.26661489520866
            },
            {
                lng: 19.17436486173989,
                lat: 48.11137889260387
            },
            {
                lng: 18.77702477384767,
                lat: 48.081768296900634
            },
            {
                lng: 18.696512892336926,
                lat: 47.880953681014404
            },
            {
                lng: 17.857132602620027,
                lat: 47.75842886005037
            },
            {
                lng: 17.48847293464982,
                lat: 47.867466132186216
            },
            {
                lng: 16.979666782304037,
                lat: 48.123497015976305
            },
            {
                lng: 16.879982944413,
                lat: 48.47001333270947
            },
            {
                lng: 16.960288120194576,
                lat: 48.5969823268506
            },
            {
                lng: 17.101984897538898,
                lat: 48.816968899117114
            },
            {
                lng: 17.545006951577108,
                lat: 48.80001902932537
            },
            {
                lng: 17.88648481616181,
                lat: 48.90347524677371
            },
            {
                lng: 17.913511590250465,
                lat: 48.996492824899086
            },
            {
                lng: 18.104972771891852,
                lat: 49.04398346617531
            },
            {
                lng: 18.170498488037964,
                lat: 49.271514797556435
            },
            {
                lng: 18.399993523846177,
                lat: 49.31500051533004
            },
            {
                lng: 18.554971144289482,
                lat: 49.495015367218784
            },
            {
                lng: 18.853144158613617,
                lat: 49.49622976337764
            },
            {
                lng: 18.90957482267632,
                lat: 49.435845852244576
            },
            {
                lng: 19.320712517990472,
                lat: 49.571574001659194
            },
            {
                lng: 19.825022820726872,
                lat: 49.21712535256923
            },
            {
                lng: 20.415839471119853,
                lat: 49.43145335549977
            },
            {
                lng: 20.887955356538413,
                lat: 49.32877228453583
            },
            {
                lng: 21.607808058364213,
                lat: 49.47010732685409
            },
            {
                lng: 22.558137648211755,
                lat: 49.085738023467144
            }
        ]
    },
    CZ: {
        name: 'Czechia',
        type: 'Polygon',
        coordinates: [
            {
                lng: 15.01699588385867,
                lat: 51.10667409932158
            },
            {
                lng: 15.490972120839729,
                lat: 50.78472992614321
            },
            {
                lng: 16.23862674323857,
                lat: 50.69773265237984
            },
            {
                lng: 16.176253289462267,
                lat: 50.42260732685791
            },
            {
                lng: 16.719475945714436,
                lat: 50.21574656839354
            },
            {
                lng: 16.86876915860566,
                lat: 50.47397370055603
            },
            {
                lng: 17.55456709155112,
                lat: 50.36214590107642
            },
            {
                lng: 17.64944502123899,
                lat: 50.049038397819956
            },
            {
                lng: 18.392913852622172,
                lat: 49.98862864847075
            },
            {
                lng: 18.853144158613617,
                lat: 49.49622976337764
            },
            {
                lng: 18.554971144289482,
                lat: 49.495015367218784
            },
            {
                lng: 18.399993523846177,
                lat: 49.31500051533004
            },
            {
                lng: 18.170498488037964,
                lat: 49.271514797556435
            },
            {
                lng: 18.104972771891852,
                lat: 49.04398346617531
            },
            {
                lng: 17.913511590250465,
                lat: 48.996492824899086
            },
            {
                lng: 17.88648481616181,
                lat: 48.90347524677371
            },
            {
                lng: 17.545006951577108,
                lat: 48.80001902932537
            },
            {
                lng: 17.101984897538898,
                lat: 48.816968899117114
            },
            {
                lng: 16.960288120194576,
                lat: 48.5969823268506
            },
            {
                lng: 16.499282667718774,
                lat: 48.78580801044511
            },
            {
                lng: 16.02964725105022,
                lat: 48.73389903420793
            },
            {
                lng: 15.253415561593982,
                lat: 49.03907420510758
            },
            {
                lng: 14.901447381254057,
                lat: 48.964401760445824
            },
            {
                lng: 14.338897739324722,
                lat: 48.55530528420721
            },
            {
                lng: 13.595945672264437,
                lat: 48.87717194273715
            },
            {
                lng: 13.031328973043431,
                lat: 49.30706818297324
            },
            {
                lng: 12.521024204161193,
                lat: 49.547415269562734
            },
            {
                lng: 12.415190870827445,
                lat: 49.96912079528057
            },
            {
                lng: 12.240111118222558,
                lat: 50.266337795607285
            },
            {
                lng: 12.966836785543194,
                lat: 50.484076443069085
            },
            {
                lng: 13.338131951560285,
                lat: 50.73323436136435
            },
            {
                lng: 14.056227654688172,
                lat: 50.9269176295943
            },
            {
                lng: 14.307013380600637,
                lat: 51.117267767941414
            },
            {
                lng: 14.570718214586066,
                lat: 51.002339382524276
            },
            {
                lng: 15.01699588385867,
                lat: 51.10667409932158
            }
        ]
    },
    ER: {
        name: 'Eritrea',
        type: 'Polygon',
        coordinates: [
            {
                lng: 36.42951,
                lat: 14.42211
            },
            {
                lng: 36.32322,
                lat: 14.82249
            },
            {
                lng: 36.75389,
                lat: 16.29186
            },
            {
                lng: 36.852530000000115,
                lat: 16.95655
            },
            {
                lng: 37.16747,
                lat: 17.263140000000135
            },
            {
                lng: 37.90400000000011,
                lat: 17.42754
            },
            {
                lng: 38.410089959473225,
                lat: 17.998307399970315
            },
            {
                lng: 38.990622999840014,
                lat: 16.840626125551694
            },
            {
                lng: 39.26611006038803,
                lat: 15.92272349696725
            },
            {
                lng: 39.814293654140215,
                lat: 15.435647284400318
            },
            {
                lng: 41.17927493669765,
                lat: 14.491079616753211
            },
            {
                lng: 41.73495161313235,
                lat: 13.921036892141558
            },
            {
                lng: 42.27683068214486,
                lat: 13.343992010954423
            },
            {
                lng: 42.58957645037526,
                lat: 13.000421250861905
            },
            {
                lng: 43.08122602720016,
                lat: 12.699638576707116
            },
            {
                lng: 42.77964236834475,
                lat: 12.455415757695675
            },
            {
                lng: 42.35156000000012,
                lat: 12.542230000000131
            },
            {
                lng: 42.00975,
                lat: 12.86582
            },
            {
                lng: 41.59856,
                lat: 13.452090000000112
            },
            {
                lng: 41.1552,
                lat: 13.77333
            },
            {
                lng: 40.8966,
                lat: 14.118640000000141
            },
            {
                lng: 40.02625000000012,
                lat: 14.51959
            },
            {
                lng: 39.34061,
                lat: 14.53155
            },
            {
                lng: 39.0994,
                lat: 14.74064
            },
            {
                lng: 38.51295,
                lat: 14.50547
            },
            {
                lng: 37.90607000000011,
                lat: 14.959430000000168
            },
            {
                lng: 37.59377,
                lat: 14.2131
            },
            {
                lng: 36.42951,
                lat: 14.42211
            }
        ]
    },
    JP: {
        name: 'Japan',
        type: 'MultiPolygon',
        coordinates: [
            [
                {
                    lng: 141.88460086483497,
                    lat: 39.180864569651476
                },
                {
                    lng: 140.95948937394581,
                    lat: 38.17400096287662
                },
                {
                    lng: 140.97638756730527,
                    lat: 37.14207428644019
                },
                {
                    lng: 140.5997697287621,
                    lat: 36.3439834661245
                },
                {
                    lng: 140.77407433488258,
                    lat: 35.842877102190215
                },
                {
                    lng: 140.2532792502451,
                    lat: 35.138113918593646
                },
                {
                    lng: 138.9755277853962,
                    lat: 34.66760000257614
                },
                {
                    lng: 137.21759891169125,
                    lat: 34.60628591566183
                },
                {
                    lng: 135.79298302626893,
                    lat: 33.46480520276663
                },
                {
                    lng: 135.1209827007454,
                    lat: 33.849071153289
                },
                {
                    lng: 135.0794348491827,
                    lat: 34.596544908174806
                },
                {
                    lng: 133.34031619683202,
                    lat: 34.375938218720805
                },
                {
                    lng: 132.15677086805124,
                    lat: 33.90493337659652
                },
                {
                    lng: 130.98614464734345,
                    lat: 33.88576142021624
                },
                {
                    lng: 132.00003624890996,
                    lat: 33.149992377244544
                },
                {
                    lng: 131.33279015515734,
                    lat: 31.450354519164822
                },
                {
                    lng: 130.686317987186,
                    lat: 31.029579169228246
                },
                {
                    lng: 130.2024198752049,
                    lat: 31.418237616495432
                },
                {
                    lng: 130.44767622286213,
                    lat: 32.319474595665696
                },
                {
                    lng: 129.81469160371893,
                    lat: 32.61030955660436
                },
                {
                    lng: 129.40846316947255,
                    lat: 33.29605581311752
                },
                {
                    lng: 130.35393517468464,
                    lat: 33.60415070244167
                },
                {
                    lng: 130.87845096244718,
                    lat: 34.23274282484002
                },
                {
                    lng: 131.8842293641439,
                    lat: 34.74971385348792
                },
                {
                    lng: 132.61767296766243,
                    lat: 35.433393052709405
                },
                {
                    lng: 134.6083008159777,
                    lat: 35.731617743465804
                },
                {
                    lng: 135.67753787652885,
                    lat: 35.52713410088687
                },
                {
                    lng: 136.72383060114242,
                    lat: 37.304984239240326
                },
                {
                    lng: 137.39061160700447,
                    lat: 36.82739065199884
                },
                {
                    lng: 138.8576021669062,
                    lat: 37.82748464614346
                },
                {
                    lng: 139.42640465714283,
                    lat: 38.21596222589761
                },
                {
                    lng: 140.054790073812,
                    lat: 39.43880748143637
                },
                {
                    lng: 139.88337934789985,
                    lat: 40.56331248632368
                },
                {
                    lng: 140.30578250545364,
                    lat: 41.19500519465953
                },
                {
                    lng: 141.3689734234266,
                    lat: 41.378559882160275
                },
                {
                    lng: 141.91426313697048,
                    lat: 39.991616115878685
                },
                {
                    lng: 141.88460086483497,
                    lat: 39.180864569651476
                }
            ],
            [
                {
                    lng: 144.61342654843963,
                    lat: 43.96088288021748
                },
                {
                    lng: 145.32082523008307,
                    lat: 44.38473297787541
                },
                {
                    lng: 145.5431372418027,
                    lat: 43.26208832455062
                },
                {
                    lng: 144.05966189999987,
                    lat: 42.98835826270056
                },
                {
                    lng: 143.18384972551723,
                    lat: 41.9952147486992
                },
                {
                    lng: 141.6114909201724,
                    lat: 42.6787905950561
                },
                {
                    lng: 141.06728641170668,
                    lat: 41.58459381770797
                },
                {
                    lng: 139.955106235921,
                    lat: 41.56955597591103
                },
                {
                    lng: 139.81754357315998,
                    lat: 42.563758856774385
                },
                {
                    lng: 140.31208703019325,
                    lat: 43.33327261003269
                },
                {
                    lng: 141.38054894426,
                    lat: 43.38882477474644
                },
                {
                    lng: 141.67195234595386,
                    lat: 44.77212535255146
                },
                {
                    lng: 141.96764489152798,
                    lat: 45.55148346616134
                },
                {
                    lng: 143.14287031470974,
                    lat: 44.51035838477697
                },
                {
                    lng: 143.91016198137947,
                    lat: 44.17409983985374
                },
                {
                    lng: 144.61342654843963,
                    lat: 43.96088288021748
                }
            ],
            [
                {
                    lng: 132.37117638563024,
                    lat: 33.46364248304006
                },
                {
                    lng: 132.92437259331473,
                    lat: 34.06029857028204
                },
                {
                    lng: 133.4929683778222,
                    lat: 33.94462087659667
                },
                {
                    lng: 133.90410607313635,
                    lat: 34.36493113864263
                },
                {
                    lng: 134.63842817600386,
                    lat: 34.149233710256354
                },
                {
                    lng: 134.76637902235854,
                    lat: 33.80633474378362
                },
                {
                    lng: 134.2034159689709,
                    lat: 33.20117788342964
                },
                {
                    lng: 133.79295006727654,
                    lat: 33.5219851750976
                },
                {
                    lng: 133.28026818250885,
                    lat: 33.28957042086489
                },
                {
                    lng: 133.0148580262578,
                    lat: 32.70456736910474
                },
                {
                    lng: 132.36311486219267,
                    lat: 32.989382025681394
                },
                {
                    lng: 132.37117638563024,
                    lat: 33.46364248304006
                }
            ]
        ]
    },
    PY: {
        name: 'Paraguay',
        type: 'Polygon',
        coordinates: [
            {
                lng: -58.166392381408045,
                lat: -20.176700941653678
            },
            {
                lng: -57.8706739976178,
                lat: -20.73268767668195
            },
            {
                lng: -57.937155727761294,
                lat: -22.090175876557172
            },
            {
                lng: -56.8815095689029,
                lat: -22.28215382252148
            },
            {
                lng: -56.47331743022939,
                lat: -22.086300144135283
            },
            {
                lng: -55.79795813660691,
                lat: -22.356929620047822
            },
            {
                lng: -55.610682745981144,
                lat: -22.655619398694846
            },
            {
                lng: -55.517639329639636,
                lat: -23.571997572526637
            },
            {
                lng: -55.40074723979542,
                lat: -23.956935316668805
            },
            {
                lng: -55.02790178080955,
                lat: -24.00127369557523
            },
            {
                lng: -54.65283423523513,
                lat: -23.83957813893396
            },
            {
                lng: -54.29295956075452,
                lat: -24.02101409271073
            },
            {
                lng: -54.29347632507745,
                lat: -24.570799655863965
            },
            {
                lng: -54.42894609233059,
                lat: -25.162184747012166
            },
            {
                lng: -54.625290696823576,
                lat: -25.739255466415514
            },
            {
                lng: -54.78879492859505,
                lat: -26.621785577096134
            },
            {
                lng: -55.69584550639816,
                lat: -27.387837009390864
            },
            {
                lng: -56.486701626192996,
                lat: -27.548499037386293
            },
            {
                lng: -57.60975969097614,
                lat: -27.395898532828387
            },
            {
                lng: -58.61817359071975,
                lat: -27.123718763947096
            },
            {
                lng: -57.63366004091113,
                lat: -25.60365650808164
            },
            {
                lng: -57.77721716981794,
                lat: -25.16233977630904
            },
            {
                lng: -58.80712846539498,
                lat: -24.77145924245331
            },
            {
                lng: -60.02896603050403,
                lat: -24.032796319273274
            },
            {
                lng: -60.846564704009914,
                lat: -23.880712579038292
            },
            {
                lng: -62.685057135657885,
                lat: -22.249029229422387
            },
            {
                lng: -62.291179368729225,
                lat: -21.051634616787393
            },
            {
                lng: -62.2659612697708,
                lat: -20.513734633061276
            },
            {
                lng: -61.78632646345377,
                lat: -19.633736667562964
            },
            {
                lng: -60.04356462262649,
                lat: -19.342746677327426
            },
            {
                lng: -59.11504248720611,
                lat: -19.3569060197754
            },
            {
                lng: -58.183471442280506,
                lat: -19.868399346600363
            },
            {
                lng: -58.166392381408045,
                lat: -20.176700941653678
            }
        ]
    },
    YE: {
        name: 'Yemen, Rep.',
        type: 'Polygon',
        coordinates: [
            {
                lng: 52.00000980002224,
                lat: 19.000003363516058
            },
            {
                lng: 52.78218427919205,
                lat: 17.349742336491232
            },
            {
                lng: 53.10857262554751,
                lat: 16.651051133688952
            },
            {
                lng: 52.38520592632588,
                lat: 16.382411200419654
            },
            {
                lng: 52.19172936382509,
                lat: 15.93843313238402
            },
            {
                lng: 52.1681649107,
                lat: 15.597420355689948
            },
            {
                lng: 51.172515089732485,
                lat: 15.175249742081492
            },
            {
                lng: 49.57457645040315,
                lat: 14.708766587782748
            },
            {
                lng: 48.67923058451416,
                lat: 14.00320241948566
            },
            {
                lng: 48.23894738138742,
                lat: 13.948089504446372
            },
            {
                lng: 47.938914015500785,
                lat: 14.007233181204427
            },
            {
                lng: 47.354453566279716,
                lat: 13.592219753468383
            },
            {
                lng: 46.717076450391744,
                lat: 13.39969920496502
            },
            {
                lng: 45.87759280781027,
                lat: 13.347764390511685
            },
            {
                lng: 45.62505008319988,
                lat: 13.290946153206763
            },
            {
                lng: 45.406458774605255,
                lat: 13.026905422411433
            },
            {
                lng: 45.14435591002086,
                lat: 12.95393830001531
            },
            {
                lng: 44.989533318874415,
                lat: 12.69958690027471
            },
            {
                lng: 44.49457645038285,
                lat: 12.721652736863348
            },
            {
                lng: 44.17511274595449,
                lat: 12.585950425664876
            },
            {
                lng: 43.48295861183713,
                lat: 12.636800035040084
            },
            {
                lng: 43.22287112811213,
                lat: 13.220950425667425
            },
            {
                lng: 43.25144819516953,
                lat: 13.767583726450852
            },
            {
                lng: 43.08794396339806,
                lat: 14.06263031662131
            },
            {
                lng: 42.892245314308724,
                lat: 14.802249253798749
            },
            {
                lng: 42.60487267433362,
                lat: 15.213335272680595
            },
            {
                lng: 42.80501549660005,
                lat: 15.261962795467255
            },
            {
                lng: 42.70243777850066,
                lat: 15.718885809791999
            },
            {
                lng: 42.823670688657415,
                lat: 15.911742255105267
            },
            {
                lng: 42.77933230975097,
                lat: 16.347891343648683
            },
            {
                lng: 43.21837527850275,
                lat: 16.66688996018641
            },
            {
                lng: 43.11579756040336,
                lat: 17.088440456607373
            },
            {
                lng: 43.380794305196105,
                lat: 17.57998668056767
            },
            {
                lng: 43.79151858905192,
                lat: 17.31997671149111
            },
            {
                lng: 44.06261315285508,
                lat: 17.410358791569593
            },
            {
                lng: 45.21665123879719,
                lat: 17.433328965723334
            },
            {
                lng: 45.39999922056876,
                lat: 17.333335069238558
            },
            {
                lng: 46.366658563020536,
                lat: 17.233315334537636
            },
            {
                lng: 46.74999433776165,
                lat: 17.283338120996177
            },
            {
                lng: 47.000004917189756,
                lat: 16.949999294497445
            },
            {
                lng: 47.46669477721763,
                lat: 17.116681626854884
            },
            {
                lng: 48.18334354024134,
                lat: 18.166669216377315
            },
            {
                lng: 49.11667158386487,
                lat: 18.616667588774945
            },
            {
                lng: 52.00000980002224,
                lat: 19.000003363516058
            }
        ]
    },
    SA: {
        name: 'Saudi Arabia',
        type: 'Polygon',
        coordinates: [
            {
                lng: 34.95603722508426,
                lat: 29.356554673778845
            },
            {
                lng: 36.06894087092206,
                lat: 29.197494615184453
            },
            {
                lng: 36.50121422704359,
                lat: 29.505253607698705
            },
            {
                lng: 36.74052778498725,
                lat: 29.86528331147619
            },
            {
                lng: 37.503581984209035,
                lat: 30.003776150018407
            },
            {
                lng: 37.66811974462638,
                lat: 30.3386652694859
            },
            {
                lng: 37.998848911294374,
                lat: 30.508499864213135
            },
            {
                lng: 37.00216556168101,
                lat: 31.508412990844747
            },
            {
                lng: 39.00488569515255,
                lat: 32.01021698661498
            },
            {
                lng: 39.19546837744497,
                lat: 32.16100881604267
            },
            {
                lng: 40.399994337736246,
                lat: 31.889991766887935
            },
            {
                lng: 41.889980910007836,
                lat: 31.19000865327837
            },
            {
                lng: 44.70949873228474,
                lat: 29.178891099559383
            },
            {
                lng: 46.568713413281756,
                lat: 29.09902517345229
            },
            {
                lng: 47.45982181172283,
                lat: 29.002519436147224
            },
            {
                lng: 47.708850538937384,
                lat: 28.526062730416143
            },
            {
                lng: 48.416094191283946,
                lat: 28.55200429942667
            },
            {
                lng: 48.80759484232718,
                lat: 27.689627997339883
            },
            {
                lng: 49.29955447774583,
                lat: 27.46121816660981
            },
            {
                lng: 49.47091352722566,
                lat: 27.109999294538085
            },
            {
                lng: 50.15242231629088,
                lat: 26.689663194275997
            },
            {
                lng: 50.212935418504685,
                lat: 26.277026882425375
            },
            {
                lng: 50.11330325704594,
                lat: 25.94397227630425
            },
            {
                lng: 50.239858839728754,
                lat: 25.608049628190926
            },
            {
                lng: 50.527386509000735,
                lat: 25.327808335872103
            },
            {
                lng: 50.66055667501689,
                lat: 24.999895534764022
            },
            {
                lng: 50.81010827006958,
                lat: 24.754742539971378
            },
            {
                lng: 51.11241539897702,
                lat: 24.556330878186724
            },
            {
                lng: 51.38960778179063,
                lat: 24.62738597258806
            },
            {
                lng: 51.57951867046327,
                lat: 24.245497137951105
            },
            {
                lng: 51.61770755392698,
                lat: 24.01421926522883
            },
            {
                lng: 52.000733270074335,
                lat: 23.00115448657894
            },
            {
                lng: 55.006803012924905,
                lat: 22.496947536707136
            },
            {
                lng: 55.208341098863194,
                lat: 22.708329982997046
            },
            {
                lng: 55.666659376859826,
                lat: 22.00000112557234
            },
            {
                lng: 54.99998172386236,
                lat: 19.999994004796108
            },
            {
                lng: 52.00000980002224,
                lat: 19.000003363516058
            },
            {
                lng: 49.11667158386487,
                lat: 18.616667588774945
            },
            {
                lng: 48.18334354024134,
                lat: 18.166669216377315
            },
            {
                lng: 47.46669477721763,
                lat: 17.116681626854884
            },
            {
                lng: 47.000004917189756,
                lat: 16.949999294497445
            },
            {
                lng: 46.74999433776165,
                lat: 17.283338120996177
            },
            {
                lng: 46.366658563020536,
                lat: 17.233315334537636
            },
            {
                lng: 45.39999922056876,
                lat: 17.333335069238558
            },
            {
                lng: 45.21665123879719,
                lat: 17.433328965723334
            },
            {
                lng: 44.06261315285508,
                lat: 17.410358791569593
            },
            {
                lng: 43.79151858905192,
                lat: 17.31997671149111
            },
            {
                lng: 43.380794305196105,
                lat: 17.57998668056767
            },
            {
                lng: 43.11579756040336,
                lat: 17.088440456607373
            },
            {
                lng: 43.21837527850275,
                lat: 16.66688996018641
            },
            {
                lng: 42.77933230975097,
                lat: 16.347891343648683
            },
            {
                lng: 42.649572788266084,
                lat: 16.774635321514964
            },
            {
                lng: 42.34798912941071,
                lat: 17.075805568912003
            },
            {
                lng: 42.270887892431226,
                lat: 17.474721787989125
            },
            {
                lng: 41.75438195167396,
                lat: 17.833046169500975
            },
            {
                lng: 41.22139122901558,
                lat: 18.67159963630121
            },
            {
                lng: 40.93934126156654,
                lat: 19.486485297111756
            },
            {
                lng: 40.247652215339826,
                lat: 20.17463450772649
            },
            {
                lng: 39.80168460466095,
                lat: 20.338862209550058
            },
            {
                lng: 39.139399448408284,
                lat: 21.291904812092934
            },
            {
                lng: 39.023695916506796,
                lat: 21.986875311770195
            },
            {
                lng: 39.06632897314759,
                lat: 22.57965566659027
            },
            {
                lng: 38.49277225114008,
                lat: 23.688451036060854
            },
            {
                lng: 38.02386030452362,
                lat: 24.078685614512935
            },
            {
                lng: 37.483634881344386,
                lat: 24.285494696545015
            },
            {
                lng: 37.154817742671185,
                lat: 24.85848297779731
            },
            {
                lng: 37.209491408036,
                lat: 25.084541530858107
            },
            {
                lng: 36.93162723160259,
                lat: 25.60295949961018
            },
            {
                lng: 36.639603712721225,
                lat: 25.826227525327223
            },
            {
                lng: 36.249136590323815,
                lat: 26.57013560638488
            },
            {
                lng: 35.64018151219639,
                lat: 27.376520494083422
            },
            {
                lng: 35.13018680190788,
                lat: 28.06335195567472
            },
            {
                lng: 34.63233605320798,
                lat: 28.058546047471566
            },
            {
                lng: 34.787778761541944,
                lat: 28.6074272730597
            },
            {
                lng: 34.832220493312946,
                lat: 28.957483425404845
            },
            {
                lng: 34.95603722508426,
                lat: 29.356554673778845
            }
        ]
    },

    CY: {
        name: 'Cyprus',
        type: 'Polygon',
        coordinates: [
            {
                lng: 32.73178022637745,
                lat: 35.14002594658844
            },
            {
                lng: 32.919572381326134,
                lat: 35.08783274997364
            },
            {
                lng: 33.19097700372305,
                lat: 35.17312470147138
            },
            {
                lng: 33.3838334490363,
                lat: 35.16271190036457
            },
            {
                lng: 33.45592207208347,
                lat: 35.10142365166641
            },
            {
                lng: 33.47581749851585,
                lat: 35.000344550103506
            },
            {
                lng: 33.5256852556775,
                lat: 35.03868846286407
            },
            {
                lng: 33.675391880027064,
                lat: 35.01786286065045
            },
            {
                lng: 33.86643965021011,
                lat: 35.09359467217419
            },
            {
                lng: 33.97361657078346,
                lat: 35.058506374648005
            },
            {
                lng: 34.00488081232004,
                lat: 34.97809784600186
            },
            {
                lng: 32.97982710137845,
                lat: 34.57186941175544
            },
            {
                lng: 32.49029625827754,
                lat: 34.701654771456475
            },
            {
                lng: 32.25666710788596,
                lat: 35.10323232679663
            },
            {
                lng: 32.73178022637745,
                lat: 35.14002594658844
            }
        ]
    },
    MA: {
        name: 'Morocco',
        type: 'Polygon',
        coordinates: [
            {
                lng: -2.169913702798624,
                lat: 35.16839630791668
            },
            {
                lng: -1.792985805661715,
                lat: 34.527918606091305
            },
            {
                lng: -1.733454555661467,
                lat: 33.91971283623212
            },
            {
                lng: -1.388049282222596,
                lat: 32.86401500094138
            },
            {
                lng: -1.124551153966308,
                lat: 32.65152151135713
            },
            {
                lng: -1.30789913573787,
                lat: 32.2628889023061
            },
            {
                lng: -2.616604783529567,
                lat: 32.09434621838619
            },
            {
                lng: -3.068980271812648,
                lat: 31.724497992473218
            },
            {
                lng: -3.647497931320146,
                lat: 31.637294012980675
            },
            {
                lng: -3.690441046554724,
                lat: 30.896951605751156
            },
            {
                lng: -4.859646165374471,
                lat: 30.501187649043846
            },
            {
                lng: -5.242129278982787,
                lat: 30.00044302013559
            },
            {
                lng: -6.060632290053774,
                lat: 29.731699734001694
            },
            {
                lng: -7.059227667661958,
                lat: 29.5792284205246
            },
            {
                lng: -8.674116176782974,
                lat: 28.84128896739658
            },
            {
                lng: -8.665589565454809,
                lat: 27.656425889592356
            },
            {
                lng: -8.817828334986672,
                lat: 27.656425889592356
            },
            {
                lng: -8.794883999049077,
                lat: 27.120696316022507
            },
            {
                lng: -9.41303748212448,
                lat: 27.088476060488574
            },
            {
                lng: -9.735343390328879,
                lat: 26.860944729107405
            },
            {
                lng: -10.189424200877582,
                lat: 26.860944729107405
            },
            {
                lng: -10.551262579785273,
                lat: 26.990807603456886
            },
            {
                lng: -11.392554897497007,
                lat: 26.883423977154393
            },
            {
                lng: -11.718219773800357,
                lat: 26.104091701760623
            },
            {
                lng: -12.03075883630163,
                lat: 26.030866197203068
            },
            {
                lng: -12.50096269372537,
                lat: 24.7701162785782
            },
            {
                lng: -13.891110398809047,
                lat: 23.691009019459305
            },
            {
                lng: -14.221167771857253,
                lat: 22.31016307218816
            },
            {
                lng: -14.630832688851072,
                lat: 21.860939846274903
            },
            {
                lng: -14.750954555713534,
                lat: 21.500600083903663
            },
            {
                lng: -17.00296179856109,
                lat: 21.420734157796577
            },
            {
                lng: -17.02042843267577,
                lat: 21.422310288981578
            },
            {
                lng: -16.973247849993243,
                lat: 21.885744533774982
            },
            {
                lng: -16.589136928767687,
                lat: 22.158234361250095
            },
            {
                lng: -16.261921759495635,
                lat: 22.679339504481277
            },
            {
                lng: -16.3264139469959,
                lat: 23.017768459560898
            },
            {
                lng: -15.982610642958036,
                lat: 23.723358466074046
            },
            {
                lng: -15.426003790742186,
                lat: 24.35913361256104
            },
            {
                lng: -15.089331834360735,
                lat: 24.520260728447
            },
            {
                lng: -14.824645148161665,
                lat: 25.103532619725343
            },
            {
                lng: -14.800925665739726,
                lat: 25.63626496022232
            },
            {
                lng: -14.439939947964831,
                lat: 26.254418443297652
            },
            {
                lng: -13.773804897506466,
                lat: 26.618892320252314
            },
            {
                lng: -13.13994177901435,
                lat: 27.640147813420526
            },
            {
                lng: -13.121613369914769,
                lat: 27.65414767171984
            },
            {
                lng: -12.618836635783111,
                lat: 28.03818553314869
            },
            {
                lng: -11.688919236690765,
                lat: 28.148643907172527
            },
            {
                lng: -10.900956997104402,
                lat: 28.83214223888092
            },
            {
                lng: -10.399592251008642,
                lat: 29.09858592377782
            },
            {
                lng: -9.564811163765683,
                lat: 29.93357371674989
            },
            {
                lng: -9.814718390329176,
                lat: 31.17773550060906
            },
            {
                lng: -9.434793260119363,
                lat: 32.038096421836485
            },
            {
                lng: -9.300692918321886,
                lat: 32.564679266890664
            },
            {
                lng: -8.657476365585012,
                lat: 33.240245266242425
            },
            {
                lng: -7.654178432638219,
                lat: 33.69706492770251
            },
            {
                lng: -6.912544114601417,
                lat: 34.110476386037476
            },
            {
                lng: -6.244342006851411,
                lat: 35.145865383437524
            },
            {
                lng: -5.92999426921989,
                lat: 35.75998810479399
            },
            {
                lng: -5.193863491222032,
                lat: 35.75518219659085
            },
            {
                lng: -4.591006232105144,
                lat: 35.330711981745594
            },
            {
                lng: -3.640056525070065,
                lat: 35.399855048152006
            },
            {
                lng: -2.604305792644084,
                lat: 35.179093329401155
            },
            {
                lng: -2.169913702798624,
                lat: 35.16839630791668
            }
        ]
    },
    EG: {
        name: 'Egypt, Arab Rep.',
        type: 'Polygon',
        coordinates: [
            {
                lng: 36.86623,
                lat: 22
            },
            {
                lng: 32.9,
                lat: 22
            },
            {
                lng: 29.02,
                lat: 22
            },
            {
                lng: 25,
                lat: 22
            },
            {
                lng: 25,
                lat: 25.682499996361
            },
            {
                lng: 25,
                lat: 29.23865452953346
            },
            {
                lng: 24.70007,
                lat: 30.04419
            },
            {
                lng: 24.95762,
                lat: 30.6616
            },
            {
                lng: 24.80287,
                lat: 31.08929
            },
            {
                lng: 25.16482,
                lat: 31.56915
            },
            {
                lng: 26.49533,
                lat: 31.58568
            },
            {
                lng: 27.45762,
                lat: 31.32126
            },
            {
                lng: 28.45048,
                lat: 31.02577
            },
            {
                lng: 28.91353,
                lat: 30.87005
            },
            {
                lng: 29.68342,
                lat: 31.18686
            },
            {
                lng: 30.09503,
                lat: 31.4734
            },
            {
                lng: 30.97693,
                lat: 31.55586
            },
            {
                lng: 31.68796,
                lat: 31.4296
            },
            {
                lng: 31.96041,
                lat: 30.9336
            },
            {
                lng: 32.19247,
                lat: 31.26034
            },
            {
                lng: 32.99392,
                lat: 31.02407
            },
            {
                lng: 33.7734,
                lat: 30.96746
            },
            {
                lng: 34.26543474464621,
                lat: 31.21935730952032
            },
            {
                lng: 34.26544,
                lat: 31.21936
            },
            {
                lng: 34.823243288783814,
                lat: 29.76108076171822
            },
            {
                lng: 34.9226,
                lat: 29.50133
            },
            {
                lng: 34.64174,
                lat: 29.09942
            },
            {
                lng: 34.42655,
                lat: 28.34399
            },
            {
                lng: 34.15451,
                lat: 27.8233
            },
            {
                lng: 33.92136,
                lat: 27.6487
            },
            {
                lng: 33.58811,
                lat: 27.97136
            },
            {
                lng: 33.13676,
                lat: 28.41765
            },
            {
                lng: 32.42323,
                lat: 29.85108
            },
            {
                lng: 32.32046,
                lat: 29.76043
            },
            {
                lng: 32.73482,
                lat: 28.70523
            },
            {
                lng: 33.34876,
                lat: 27.69989
            },
            {
                lng: 34.10455,
                lat: 26.14227
            },
            {
                lng: 34.47387,
                lat: 25.59856
            },
            {
                lng: 34.79507,
                lat: 25.03375
            },
            {
                lng: 35.69241,
                lat: 23.92671
            },
            {
                lng: 35.49372,
                lat: 23.75237
            },
            {
                lng: 35.52598,
                lat: 23.10244
            },
            {
                lng: 36.69069,
                lat: 22.20485
            },
            {
                lng: 36.86623,
                lat: 22
            }
        ]
    },
    LY: {
        name: 'Libya',
        type: 'Polygon',
        coordinates: [
            {
                lng: 25,
                lat: 22
            },
            {
                lng: 25.000000000000114,
                lat: 20.00304
            },
            {
                lng: 23.850000000000136,
                lat: 20
            },
            {
                lng: 23.83766000000014,
                lat: 19.580470000000105
            },
            {
                lng: 19.84926,
                lat: 21.49509
            },
            {
                lng: 15.86085,
                lat: 23.40972
            },
            {
                lng: 14.8513,
                lat: 22.862950000000126
            },
            {
                lng: 14.143870883855243,
                lat: 22.491288967371133
            },
            {
                lng: 13.581424594790462,
                lat: 23.04050608976928
            },
            {
                lng: 11.999505649471613,
                lat: 23.47166840259645
            },
            {
                lng: 11.560669386449005,
                lat: 24.097909247325518
            },
            {
                lng: 10.771363559622927,
                lat: 24.56253205006175
            },
            {
                lng: 10.303846876678362,
                lat: 24.379313259370917
            },
            {
                lng: 9.94826134607797,
                lat: 24.936953640232517
            },
            {
                lng: 9.910692579801776,
                lat: 25.365454616796796
            },
            {
                lng: 9.319410841518163,
                lat: 26.094324856057455
            },
            {
                lng: 9.716285841519664,
                lat: 26.512206325785655
            },
            {
                lng: 9.629056023811074,
                lat: 27.14095347748092
            },
            {
                lng: 9.756128370816782,
                lat: 27.688258571884205
            },
            {
                lng: 9.683884718472768,
                lat: 28.1441738957792
            },
            {
                lng: 9.859997999723447,
                lat: 28.959989732371014
            },
            {
                lng: 9.805634392952356,
                lat: 29.424638373323376
            },
            {
                lng: 9.482139926805274,
                lat: 30.307556057246188
            },
            {
                lng: 9.970017124072854,
                lat: 30.53932485607524
            },
            {
                lng: 10.056575148161699,
                lat: 30.961831366493527
            },
            {
                lng: 9.950225050505082,
                lat: 31.376069647745258
            },
            {
                lng: 10.636901482799487,
                lat: 31.761420803345757
            },
            {
                lng: 10.944789666394456,
                lat: 32.081814683555365
            },
            {
                lng: 11.432253452203696,
                lat: 32.368903103152874
            },
            {
                lng: 11.488787469131012,
                lat: 33.13699575452324
            },
            {
                lng: 12.66331,
                lat: 32.79278
            },
            {
                lng: 13.08326,
                lat: 32.87882
            },
            {
                lng: 13.91868,
                lat: 32.71196
            },
            {
                lng: 15.24563,
                lat: 32.26508
            },
            {
                lng: 15.71394,
                lat: 31.37626
            },
            {
                lng: 16.61162,
                lat: 31.18218
            },
            {
                lng: 18.02109,
                lat: 30.76357
            },
            {
                lng: 19.08641,
                lat: 30.26639
            },
            {
                lng: 19.57404,
                lat: 30.52582
            },
            {
                lng: 20.05335,
                lat: 30.98576
            },
            {
                lng: 19.82033,
                lat: 31.751790000000142
            },
            {
                lng: 20.13397,
                lat: 32.2382
            },
            {
                lng: 20.85452,
                lat: 32.7068
            },
            {
                lng: 21.54298,
                lat: 32.8432
            },
            {
                lng: 22.89576,
                lat: 32.63858
            },
            {
                lng: 23.2368,
                lat: 32.19149
            },
            {
                lng: 23.609130000000107,
                lat: 32.18726
            },
            {
                lng: 23.9275,
                lat: 32.01667
            },
            {
                lng: 24.92114,
                lat: 31.89936
            },
            {
                lng: 25.16482,
                lat: 31.56915
            },
            {
                lng: 24.80287,
                lat: 31.08929
            },
            {
                lng: 24.95762,
                lat: 30.6616
            },
            {
                lng: 24.70007,
                lat: 30.04419
            },
            {
                lng: 25,
                lat: 29.23865452953346
            },
            {
                lng: 25,
                lat: 25.682499996361
            },
            {
                lng: 25,
                lat: 22
            }
        ]
    },
    ET: {
        name: 'Ethiopia',
        type: 'Polygon',
        coordinates: [
            {
                lng: 47.78942,
                lat: 8.003
            },
            {
                lng: 44.9636,
                lat: 5.00162
            },
            {
                lng: 43.66087,
                lat: 4.95755
            },
            {
                lng: 42.76967,
                lat: 4.25259
            },
            {
                lng: 42.12861,
                lat: 4.23413
            },
            {
                lng: 41.85508309264397,
                lat: 3.918911920483727
            },
            {
                lng: 41.1718,
                lat: 3.91909
            },
            {
                lng: 40.76848,
                lat: 4.25702
            },
            {
                lng: 39.85494,
                lat: 3.83879
            },
            {
                lng: 39.55938425876585,
                lat: 3.42206
            },
            {
                lng: 38.89251,
                lat: 3.50074
            },
            {
                lng: 38.67114,
                lat: 3.61607
            },
            {
                lng: 38.43697,
                lat: 3.58851
            },
            {
                lng: 38.120915,
                lat: 3.598605
            },
            {
                lng: 36.85509323800812,
                lat: 4.447864127672769
            },
            {
                lng: 36.159078632855646,
                lat: 4.447864127672769
            },
            {
                lng: 35.817447662353516,
                lat: 4.77696566346189
            },
            {
                lng: 35.817447662353516,
                lat: 5.338232082790797
            },
            {
                lng: 35.29800711823298,
                lat: 5.506
            },
            {
                lng: 34.70702,
                lat: 6.594220000000121
            },
            {
                lng: 34.25032,
                lat: 6.82607
            },
            {
                lng: 34.0751,
                lat: 7.22595
            },
            {
                lng: 33.568290000000104,
                lat: 7.71334
            },
            {
                lng: 32.95418,
                lat: 7.784970000000101
            },
            {
                lng: 33.29480000000012,
                lat: 8.35458
            },
            {
                lng: 33.82550000000015,
                lat: 8.37916
            },
            {
                lng: 33.97498,
                lat: 8.68456
            },
            {
                lng: 33.96162,
                lat: 9.58358
            },
            {
                lng: 34.25745,
                lat: 10.63009
            },
            {
                lng: 34.73115000000013,
                lat: 10.910170000000107
            },
            {
                lng: 34.83163000000013,
                lat: 11.318960000000118
            },
            {
                lng: 35.26049,
                lat: 12.08286
            },
            {
                lng: 35.86363,
                lat: 12.57828
            },
            {
                lng: 36.27022,
                lat: 13.563330000000121
            },
            {
                lng: 36.42951,
                lat: 14.42211
            },
            {
                lng: 37.59377,
                lat: 14.2131
            },
            {
                lng: 37.90607000000011,
                lat: 14.959430000000168
            },
            {
                lng: 38.51295,
                lat: 14.50547
            },
            {
                lng: 39.0994,
                lat: 14.74064
            },
            {
                lng: 39.34061,
                lat: 14.53155
            },
            {
                lng: 40.02625000000012,
                lat: 14.51959
            },
            {
                lng: 40.8966,
                lat: 14.118640000000141
            },
            {
                lng: 41.1552,
                lat: 13.77333
            },
            {
                lng: 41.59856,
                lat: 13.452090000000112
            },
            {
                lng: 42.00975,
                lat: 12.86582
            },
            {
                lng: 42.35156000000012,
                lat: 12.542230000000131
            },
            {
                lng: 42.000000000000114,
                lat: 12.100000000000136
            },
            {
                lng: 41.66176000000013,
                lat: 11.6312
            },
            {
                lng: 41.73959000000019,
                lat: 11.355110000000138
            },
            {
                lng: 41.755570000000205,
                lat: 11.050910000000101
            },
            {
                lng: 42.31414000000012,
                lat: 11.0342
            },
            {
                lng: 42.55493000000013,
                lat: 11.105110000000195
            },
            {
                lng: 42.77685184100096,
                lat: 10.92687856693442
            },
            {
                lng: 42.55876,
                lat: 10.57258000000013
            },
            {
                lng: 42.92812,
                lat: 10.021940000000143
            },
            {
                lng: 43.29699000000011,
                lat: 9.540480000000173
            },
            {
                lng: 43.67875,
                lat: 9.18358000000012
            },
            {
                lng: 46.94834,
                lat: 7.99688
            },
            {
                lng: 47.78942,
                lat: 8.003
            }
        ]
    },
    DJ: {
        name: 'Djibouti',
        type: 'Polygon',
        coordinates: [
            {
                lng: 42.35156000000012,
                lat: 12.542230000000131
            },
            {
                lng: 42.77964236834475,
                lat: 12.455415757695675
            },
            {
                lng: 43.08122602720016,
                lat: 12.699638576707116
            },
            {
                lng: 43.31785241066467,
                lat: 12.390148423711025
            },
            {
                lng: 43.286381463398925,
                lat: 11.974928290245884
            },
            {
                lng: 42.715873650896526,
                lat: 11.735640570518342
            },
            {
                lng: 43.14530480324214,
                lat: 11.462039699748857
            },
            {
                lng: 42.77685184100096,
                lat: 10.92687856693442
            },
            {
                lng: 42.55493000000013,
                lat: 11.105110000000195
            },
            {
                lng: 42.31414000000012,
                lat: 11.0342
            },
            {
                lng: 41.755570000000205,
                lat: 11.050910000000101
            },
            {
                lng: 41.73959000000019,
                lat: 11.355110000000138
            },
            {
                lng: 41.66176000000013,
                lat: 11.6312
            },
            {
                lng: 42.000000000000114,
                lat: 12.100000000000136
            },
            {
                lng: 42.35156000000012,
                lat: 12.542230000000131
            }
        ]
    },
    UG: {
        name: 'Uganda',
        type: 'Polygon',
        coordinates: [
            {
                lng: 33.90371119710453,
                lat: -0.95
            },
            {
                lng: 31.86617,
                lat: -1.02736
            },
            {
                lng: 30.769860000000108,
                lat: -1.01455
            },
            {
                lng: 30.419104852019245,
                lat: -1.134659112150416
            },
            {
                lng: 29.821518588996014,
                lat: -1.443322442229785
            },
            {
                lng: 29.579466180140884,
                lat: -1.341313164885626
            },
            {
                lng: 29.58783776217217,
                lat: -0.587405694179381
            },
            {
                lng: 29.819503208136638,
                lat: -0.205310153813372
            },
            {
                lng: 29.875778842902434,
                lat: 0.597379868976361
            },
            {
                lng: 30.086153598762706,
                lat: 1.062312730306289
            },
            {
                lng: 30.46850752129029,
                lat: 1.583805446779706
            },
            {
                lng: 30.85267011894806,
                lat: 1.849396470543809
            },
            {
                lng: 31.174149204235817,
                lat: 2.204465236821264
            },
            {
                lng: 30.77334679538004,
                lat: 2.339883327642127
            },
            {
                lng: 30.83385989759381,
                lat: 3.509165961110341
            },
            {
                lng: 30.833852421715427,
                lat: 3.509171604222463
            },
            {
                lng: 31.24556,
                lat: 3.7819
            },
            {
                lng: 31.88145,
                lat: 3.55827
            },
            {
                lng: 32.68642,
                lat: 3.79232
            },
            {
                lng: 33.3900000000001,
                lat: 3.79
            },
            {
                lng: 34.005,
                lat: 4.249884947362048
            },
            {
                lng: 34.47913,
                lat: 3.5556
            },
            {
                lng: 34.59607,
                lat: 3.053740000000118
            },
            {
                lng: 35.03599,
                lat: 1.90584
            },
            {
                lng: 34.6721,
                lat: 1.17694
            },
            {
                lng: 34.18,
                lat: 0.515
            },
            {
                lng: 33.893568969666944,
                lat: 0.109813537861896
            },
            {
                lng: 33.90371119710453,
                lat: -0.95
            }
        ]
    },
    RW: {
        name: 'Rwanda',
        type: 'Polygon',
        coordinates: [
            {
                lng: 30.419104852019245,
                lat: -1.134659112150416
            },
            {
                lng: 30.816134881317712,
                lat: -1.698914076345389
            },
            {
                lng: 30.75830895358311,
                lat: -2.287250257988369
            },
            {
                lng: 30.46967,
                lat: -2.41383
            },
            {
                lng: 30.469673645761223,
                lat: -2.41385475710134
            },
            {
                lng: 29.93835900240794,
                lat: -2.348486830254238
            },
            {
                lng: 29.632176141078588,
                lat: -2.917857761246097
            },
            {
                lng: 29.024926385216787,
                lat: -2.839257907730158
            },
            {
                lng: 29.117478875451553,
                lat: -2.292211195488385
            },
            {
                lng: 29.254834832483343,
                lat: -2.215109958508911
            },
            {
                lng: 29.291886834436614,
                lat: -1.620055840667987
            },
            {
                lng: 29.579466180140884,
                lat: -1.341313164885626
            },
            {
                lng: 29.821518588996014,
                lat: -1.443322442229785
            },
            {
                lng: 30.419104852019245,
                lat: -1.134659112150416
            }
        ]
    },
    BA: {
        name: 'Bosnia and Herzegovina',
        type: 'Polygon',
        coordinates: [
            {
                lng: 18.56,
                lat: 42.65
            },
            {
                lng: 17.674921502358984,
                lat: 43.02856252702361
            },
            {
                lng: 17.297373488034452,
                lat: 43.44634064388737
            },
            {
                lng: 16.91615644701733,
                lat: 43.66772247982567
            },
            {
                lng: 16.456442905348865,
                lat: 44.04123973243128
            },
            {
                lng: 16.23966027188453,
                lat: 44.35114329688571
            },
            {
                lng: 15.750026075918981,
                lat: 44.818711656262565
            },
            {
                lng: 15.959367303133376,
                lat: 45.23377676043094
            },
            {
                lng: 16.318156772535872,
                lat: 45.00412669532591
            },
            {
                lng: 16.534939406000206,
                lat: 45.21160757097772
            },
            {
                lng: 17.002146030351014,
                lat: 45.23377676043094
            },
            {
                lng: 17.861783481526402,
                lat: 45.067740383477144
            },
            {
                lng: 18.553214145591653,
                lat: 45.08158966733146
            },
            {
                lng: 19.005484597557594,
                lat: 44.86023449354299
            },
            {
                lng: 19.00548,
                lat: 44.86023
            },
            {
                lng: 19.36803,
                lat: 44.863
            },
            {
                lng: 19.11761,
                lat: 44.42307000000011
            },
            {
                lng: 19.59976,
                lat: 44.03847
            },
            {
                lng: 19.454,
                lat: 43.56810000000013
            },
            {
                lng: 19.21852,
                lat: 43.52384
            },
            {
                lng: 19.03165,
                lat: 43.43253
            },
            {
                lng: 18.70648,
                lat: 43.20011
            },
            {
                lng: 18.56,
                lat: 42.65
            }
        ]
    },
    MK: {
        name: 'Macedonia, FYR',
        type: 'Polygon',
        coordinates: [
            {
                lng: 22.380525750424592,
                lat: 42.32025950781509
            },
            {
                lng: 22.881373732197346,
                lat: 41.999297186850356
            },
            {
                lng: 22.952377150166452,
                lat: 41.33799388281115
            },
            {
                lng: 22.76177,
                lat: 41.3048
            },
            {
                lng: 22.597308383889015,
                lat: 41.130487168943205
            },
            {
                lng: 22.05537763844427,
                lat: 41.14986583105269
            },
            {
                lng: 21.674160597426976,
                lat: 40.931274522457954
            },
            {
                lng: 21.0200403174764,
                lat: 40.84272695572588
            },
            {
                lng: 20.605181919037364,
                lat: 41.086226304685226
            },
            {
                lng: 20.463175083099202,
                lat: 41.51508901627534
            },
            {
                lng: 20.59024743010491,
                lat: 41.855404161133606
            },
            {
                lng: 20.590246546680227,
                lat: 41.855408919283626
            },
            {
                lng: 20.71731000000011,
                lat: 41.84711
            },
            {
                lng: 20.76216,
                lat: 42.05186
            },
            {
                lng: 21.35270000000014,
                lat: 42.2068
            },
            {
                lng: 21.57663598940212,
                lat: 42.24522439706186
            },
            {
                lng: 21.917080000000112,
                lat: 42.30364
            },
            {
                lng: 22.380525750424592,
                lat: 42.32025950781509
            }
        ]
    },
    RS: {
        name: 'Serbia',
        type: 'Polygon',
        coordinates: [
            {
                lng: 18.829824792873946,
                lat: 45.908872358025285
            },
            {
                lng: 18.82983808764996,
                lat: 45.90887767189193
            },
            {
                lng: 19.596044549241583,
                lat: 46.17172984474454
            },
            {
                lng: 20.220192498462836,
                lat: 46.127468980486555
            },
            {
                lng: 20.762174920339987,
                lat: 45.734573065771485
            },
            {
                lng: 20.874312778413355,
                lat: 45.416375433934235
            },
            {
                lng: 21.48352623870221,
                lat: 45.18117015235788
            },
            {
                lng: 21.56202273935361,
                lat: 44.7689472519655
            },
            {
                lng: 22.14508792490281,
                lat: 44.47842234962059
            },
            {
                lng: 22.459022251075936,
                lat: 44.7025171982543
            },
            {
                lng: 22.705725538837356,
                lat: 44.57800283464702
            },
            {
                lng: 22.4740084164406,
                lat: 44.40922760678177
            },
            {
                lng: 22.65714969248299,
                lat: 44.23492300066128
            },
            {
                lng: 22.410446404721597,
                lat: 44.008063462899955
            },
            {
                lng: 22.500156691180223,
                lat: 43.642814439461006
            },
            {
                lng: 22.986018507588483,
                lat: 43.2111612005271
            },
            {
                lng: 22.60480146657133,
                lat: 42.898518785161144
            },
            {
                lng: 22.43659467946128,
                lat: 42.580321153323936
            },
            {
                lng: 22.54501183440962,
                lat: 42.46136200618804
            },
            {
                lng: 22.380525750424592,
                lat: 42.32025950781509
            },
            {
                lng: 21.917080000000112,
                lat: 42.30364
            },
            {
                lng: 21.57663598940212,
                lat: 42.24522439706186
            },
            {
                lng: 21.54332,
                lat: 42.3202500000001
            },
            {
                lng: 21.66292,
                lat: 42.43922
            },
            {
                lng: 21.77505,
                lat: 42.6827
            },
            {
                lng: 21.63302,
                lat: 42.67717
            },
            {
                lng: 21.43866,
                lat: 42.86255
            },
            {
                lng: 21.27421,
                lat: 42.90959
            },
            {
                lng: 21.143395,
                lat: 43.06868500000013
            },
            {
                lng: 20.95651,
                lat: 43.13094
            },
            {
                lng: 20.81448,
                lat: 43.27205
            },
            {
                lng: 20.63508,
                lat: 43.21671
            },
            {
                lng: 20.49679,
                lat: 42.88469
            },
            {
                lng: 20.25758,
                lat: 42.81275000000011
            },
            {
                lng: 20.3398,
                lat: 42.89852
            },
            {
                lng: 19.95857,
                lat: 43.10604
            },
            {
                lng: 19.63,
                lat: 43.21377997027054
            },
            {
                lng: 19.48389,
                lat: 43.35229
            },
            {
                lng: 19.21852,
                lat: 43.52384
            },
            {
                lng: 19.454,
                lat: 43.56810000000013
            },
            {
                lng: 19.59976,
                lat: 44.03847
            },
            {
                lng: 19.11761,
                lat: 44.42307000000011
            },
            {
                lng: 19.36803,
                lat: 44.863
            },
            {
                lng: 19.00548,
                lat: 44.86023
            },
            {
                lng: 19.005484597557594,
                lat: 44.86023449354299
            },
            {
                lng: 19.39047570158459,
                lat: 45.236515611342384
            },
            {
                lng: 19.072768995854176,
                lat: 45.52151113543209
            },
            {
                lng: 18.829824792873946,
                lat: 45.908872358025285
            }
        ]
    },
    ME: {
        name: 'Montenegro',
        type: 'Polygon',
        coordinates: [
            {
                lng: 20.0707,
                lat: 42.58863
            },
            {
                lng: 19.801613396898688,
                lat: 42.50009349219084
            },
            {
                lng: 19.73805138517963,
                lat: 42.68824738216557
            },
            {
                lng: 19.304486118250793,
                lat: 42.19574514420782
            },
            {
                lng: 19.37176816334725,
                lat: 41.877550679783496
            },
            {
                lng: 19.16246,
                lat: 41.95502
            },
            {
                lng: 18.88214,
                lat: 42.28151
            },
            {
                lng: 18.45001688302086,
                lat: 42.47999224531218
            },
            {
                lng: 18.56,
                lat: 42.65
            },
            {
                lng: 18.70648,
                lat: 43.20011
            },
            {
                lng: 19.03165,
                lat: 43.43253
            },
            {
                lng: 19.21852,
                lat: 43.52384
            },
            {
                lng: 19.48389,
                lat: 43.35229
            },
            {
                lng: 19.63,
                lat: 43.21377997027054
            },
            {
                lng: 19.95857,
                lat: 43.10604
            },
            {
                lng: 20.3398,
                lat: 42.89852
            },
            {
                lng: 20.25758,
                lat: 42.81275000000011
            },
            {
                lng: 20.0707,
                lat: 42.58863
            }
        ]
    },
    XK: {
        name: 'Kosovo',
        type: 'Polygon',
        coordinates: [
            {
                lng: 20.590246546680227,
                lat: 41.855408919283626
            },
            {
                lng: 20.52295,
                lat: 42.21787
            },
            {
                lng: 20.283754510181893,
                lat: 42.32025950781508
            },
            {
                lng: 20.0707,
                lat: 42.58863
            },
            {
                lng: 20.25758,
                lat: 42.81275000000011
            },
            {
                lng: 20.49679,
                lat: 42.88469
            },
            {
                lng: 20.63508,
                lat: 43.21671
            },
            {
                lng: 20.81448,
                lat: 43.27205
            },
            {
                lng: 20.95651,
                lat: 43.13094
            },
            {
                lng: 21.143395,
                lat: 43.06868500000013
            },
            {
                lng: 21.27421,
                lat: 42.90959
            },
            {
                lng: 21.43866,
                lat: 42.86255
            },
            {
                lng: 21.63302,
                lat: 42.67717
            },
            {
                lng: 21.77505,
                lat: 42.6827
            },
            {
                lng: 21.66292,
                lat: 42.43922
            },
            {
                lng: 21.54332,
                lat: 42.3202500000001
            },
            {
                lng: 21.57663598940212,
                lat: 42.24522439706186
            },
            {
                lng: 21.35270000000014,
                lat: 42.2068
            },
            {
                lng: 20.76216,
                lat: 42.05186
            },
            {
                lng: 20.71731000000011,
                lat: 41.84711
            },
            {
                lng: 20.590246546680227,
                lat: 41.855408919283626
            }
        ]
    },
    TT: {
        name: 'Trinidad and Tobago',
        type: 'Polygon',
        coordinates: [
            {
                lng: -61.68,
                lat: 10.76
            },
            {
                lng: -61.105,
                lat: 10.89
            },
            {
                lng: -60.895,
                lat: 10.855
            },
            {
                lng: -60.935,
                lat: 10.11
            },
            {
                lng: -61.77,
                lat: 10
            },
            {
                lng: -61.95,
                lat: 10.09
            },
            {
                lng: -61.66,
                lat: 10.365
            },
            {
                lng: -61.68,
                lat: 10.76
            }
        ]
    },
    SS: {
        name: 'South Sudan',
        type: 'Polygon',
        coordinates: [
            {
                lng: 30.833852421715427,
                lat: 3.509171604222463
            },
            {
                lng: 29.953500197069474,
                lat: 4.173699042167684
            },
            {
                lng: 29.71599531425602,
                lat: 4.600804755060153
            },
            {
                lng: 29.1590784034465,
                lat: 4.389267279473231
            },
            {
                lng: 28.696677687298802,
                lat: 4.455077215996937
            },
            {
                lng: 28.428993768026913,
                lat: 4.287154649264494
            },
            {
                lng: 27.97997724784281,
                lat: 4.408413397637375
            },
            {
                lng: 27.37422610851749,
                lat: 5.233944403500061
            },
            {
                lng: 27.21340905122517,
                lat: 5.550953477394557
            },
            {
                lng: 26.465909458123235,
                lat: 5.94671743410187
            },
            {
                lng: 26.213418409945117,
                lat: 6.546603298362072
            },
            {
                lng: 25.79664798351118,
                lat: 6.979315904158071
            },
            {
                lng: 25.124130893664727,
                lat: 7.500085150579437
            },
            {
                lng: 25.11493248871679,
                lat: 7.825104071479174
            },
            {
                lng: 24.567369012152085,
                lat: 8.229187933785468
            },
            {
                lng: 23.886979580860668,
                lat: 8.619729712933065
            },
            {
                lng: 24.19406772118765,
                lat: 8.728696472403897
            },
            {
                lng: 24.53741516360202,
                lat: 8.91753756573172
            },
            {
                lng: 24.794925745412684,
                lat: 9.810240916008695
            },
            {
                lng: 25.069603699343986,
                lat: 10.273759963267992
            },
            {
                lng: 25.790633328413946,
                lat: 10.411098940233728
            },
            {
                lng: 25.962307049621018,
                lat: 10.136420986302426
            },
            {
                lng: 26.477328213242515,
                lat: 9.552730334198088
            },
            {
                lng: 26.752006167173818,
                lat: 9.466893473594496
            },
            {
                lng: 27.112520981708883,
                lat: 9.638567194801624
            },
            {
                lng: 27.833550610778786,
                lat: 9.60423245056029
            },
            {
                lng: 27.970889587744352,
                lat: 9.398223985111656
            },
            {
                lng: 28.966597170745786,
                lat: 9.398223985111656
            },
            {
                lng: 29.000931914987177,
                lat: 9.60423245056029
            },
            {
                lng: 29.515953078608618,
                lat: 9.793073543888056
            },
            {
                lng: 29.61895731133285,
                lat: 10.084918869940225
            },
            {
                lng: 29.996639497988554,
                lat: 10.290927335388687
            },
            {
                lng: 30.837840731903384,
                lat: 9.70723668328452
            },
            {
                lng: 31.35286189552488,
                lat: 9.810240916008695
            },
            {
                lng: 31.850715687025513,
                lat: 10.531270545078826
            },
            {
                lng: 32.400071594888345,
                lat: 11.080626452941488
            },
            {
                lng: 32.31423473428475,
                lat: 11.68148447716652
            },
            {
                lng: 32.073891524594785,
                lat: 11.973329803218519
            },
            {
                lng: 32.67474954881965,
                lat: 12.02483191958072
            },
            {
                lng: 32.743419037302544,
                lat: 12.248007757149992
            },
            {
                lng: 33.206938084561784,
                lat: 12.179338268667095
            },
            {
                lng: 33.086766479716744,
                lat: 11.441141267476496
            },
            {
                lng: 33.206938084561784,
                lat: 10.720111638406593
            },
            {
                lng: 33.72195924818311,
                lat: 10.325262079630193
            },
            {
                lng: 33.84213085302815,
                lat: 9.981914637215993
            },
            {
                lng: 33.82496348090751,
                lat: 9.484060845715362
            },
            {
                lng: 33.963392794971185,
                lat: 9.464285229420625
            },
            {
                lng: 33.97498,
                lat: 8.68456
            },
            {
                lng: 33.82550000000015,
                lat: 8.37916
            },
            {
                lng: 33.29480000000012,
                lat: 8.35458
            },
            {
                lng: 32.95418,
                lat: 7.784970000000101
            },
            {
                lng: 33.568290000000104,
                lat: 7.71334
            },
            {
                lng: 34.0751,
                lat: 7.22595
            },
            {
                lng: 34.25032,
                lat: 6.82607
            },
            {
                lng: 34.70702,
                lat: 6.594220000000121
            },
            {
                lng: 35.29800711823298,
                lat: 5.506
            },
            {
                lng: 34.62019626785388,
                lat: 4.847122742081988
            },
            {
                lng: 34.005,
                lat: 4.249884947362048
            },
            {
                lng: 33.3900000000001,
                lat: 3.79
            },
            {
                lng: 32.68642,
                lat: 3.79232
            },
            {
                lng: 31.88145,
                lat: 3.55827
            },
            {
                lng: 31.24556,
                lat: 3.7819
            },
            {
                lng: 30.833852421715427,
                lat: 3.509171604222463
            }
        ]
    }
};
