const GradientTitle = ({
    leading = 'leading-tight',
    flex = 'flex flex-shrink-0-a',
    label,
    textSize = 'text-2xl',
    justify = '',
    containerClass = 'mb-4',
    textClass = 'font-bold tracking-tighter ',
    adjust
}) => {
    return (
        <div className={`${containerClass} ${justify} ${flex} `}>
            <div
                className={` ${leading} ${textClass} ${adjust} whitespace-wrap-a  text-transparent bg-clip-text bg-gradient-to-tl from-gray-900 via-blue-500 to-green-400 ${textSize}`}
            >
                {label}
            </div>
        </div>
    );
};

export default GradientTitle;
