/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GoogleMap, Marker, OverlayView, Circle } from '@react-google-maps/api';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { handleRowReverse } from 'helpers/FEutils';
import {
    addCircleAccom,
    deleteCircleAccom
} from 'store/actions/experienceDetails/accommodation';
import MapStatus from 'components/blocks/MapStatus';
import CollapseButton from 'components/blocks/CollapseButton';

const mapContainerStyle = {
    height: '50vh',
    width: '100%'
};

const mapOptions = {
    zoomControl: true,
    scrollwheel: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    clickableIcons: false,
    gestureHandling: 'greedy',
    mapId: '8e0ece1c437d65ae'
};

const HotelMap = ({
    globalState: {
        lang,
        edit,
        googleMaps: {
            loading: { isLoading },
            error: { isError }
        }
    },
    destinations: { locations, circles },
    addCircleAccom,
    deleteCircleAccom
}) => {
    const rtl = !!translations[lang].rtl;
    const mapRef = useRef();
    const [mapReady, setMapReady] = useState(null);
    const containerRef = useRef(null);
    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
        setMapReady(mapRef.current);
    }, []);

    const circleOptions = {
        strokeColor: '#34D399',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#34D399',
        fillOpacity: 0.2
    };

    const buildMarkers = () => {
        const overlays = drawCircles();
        return locations
            .filter((loc) => loc.type !== 'circle')
            .map((obj, index) => {
                const {
                    location: { lat, lng }
                } = obj.data;
                const key = `lat${lat}${index}`;

                return (
                    <Marker
                        key={key}
                        position={{
                            lat: lat,
                            lng: lng
                        }}
                        icon={{
                            url: `/assets/media/map-pin-fill-green.svg`,
                            origin: new window.google.maps.Point(0, 0),
                            anchor: new window.google.maps.Point(15, 30),
                            scaledSize: new window.google.maps.Size(30, 30)
                        }}
                    />
                );
            })
            .concat(overlays);
    };

    const drawCircles = () => {
        if (!mapReady) return;
        const overlayArray = [];
        const circlesArr = [];
        locations
            .filter((loc) => loc.type === 'circle')
            .forEach((obj, index) => {
                const {
                    circle: { location, radius },
                    label
                } = obj;
                const key = `${location.lat}${index}`;

                circlesArr.push(
                    <Circle
                        options={{ ...circleOptions }}
                        key={`circle_${key}`}
                        center={location}
                        radius={radius}
                    />
                );

                overlayArray.push(
                    <OverlayView
                        key={key}
                        position={location}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                        <div style={{ background: 'white' }}>{label}</div>
                    </OverlayView>
                );
            });

        return overlayArray.concat(circlesArr);
    };

    const resetBounds = () => {
        if (mapReady) {
            const bounds = new window.google.maps.LatLngBounds();

            locations
                .filter((loc) => loc.type === 'circle')
                .forEach((obj, index) => {
                    const { bounds: circleBounds } = obj.circle;
                    bounds.union(circleBounds);
                });
            locations
                .filter((loc) => loc.type !== 'circle')
                .forEach((obj) => {
                    const {
                        data: { location }
                    } = obj;

                    bounds.extend(location);
                });

            if (!locations.length) {
                mapRef.current.setZoom(1);
                mapRef.current.setCenter({
                    lat: 0,
                    lng: 0
                });
            } else {
                if (locations.length === 1 ) {
                    if(locations[0].type === 'circle') {
                        mapRef.current.setCenter({
                            lat: locations[0].circle.location.lat,
                            lng: locations[0].circle.location.lng
                        });
                        mapRef.current.fitBounds(bounds);
                    } else {
                        mapRef.current.setCenter({
                            lat: locations[0].data.location.lat,
                            lng: locations[0].data.location.lng
                        });
                        mapRef.current.setZoom(18);
                    }

                } else mapRef.current.fitBounds(bounds);
            }
        }
    };

    useEffect(() => {
        if (mapReady) {
            resetBounds();
        }
    }, [locations, mapReady]);

    return (
        <>
            {isLoading || isError ? (
                <MapStatus />
            ) : (
                <div className='mb-6'>
                    <div className='relative' ref={containerRef}>
                        <div className='absolute top-8 right-8 z-10'>
                            <CollapseButton
                                labelHover='Zoom to fit'
                                handleClick={resetBounds}
                                icon='ri-artboard-2-line text-2xl -ml-0.5'
                                size='10'
                                textSize='text-xs'
                                sizeHover='w-36'
                                offsetCenter='2'
                                btnColor='bg-green-400 hover:bg-gray-900 text-green-900'
                            />
                        </div>

                        <GoogleMap
                            id='map'
                            mapContainerStyle={mapContainerStyle}
                            //   zoom={6}
                            options={mapOptions}
                            onClick={null}
                            onLoad={onMapLoad}
                        >
                            {buildMarkers()}
                            {/* {edit && (
                                <DrawingManager
                                    options={{
                                        drawingMode: drawMode,
                                        drawingControl: false,
                                        drawingControlOptions: {
                                            position:
                                                window.google.maps
                                                    .ControlPosition.TOP_LEFT,
                                            drawingModes: [
                                                window.google.maps.drawing
                                                    .OverlayType.CIRCLE
                                            ]
                                        },
                                        circleOptions
                                    }}
                                    onCircleComplete={onCircleComplete}
                                />
                            )} */}
                        </GoogleMap>
                    </div>
                </div>
            )}
        </>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addCircleAccom,
            deleteCircleAccom
        },
        dispatch
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, mapDispatchToProps)(HotelMap);
