const uiStruct = {
    ui: {
        componentTypes: {
            date_range: {
                label: 'UI_DATE_RANGE_LABEL',
                selections: [
                    {
                        label: 'UI_DATE_MONTH_FULL_1',
                        short_label: 'UI_DATE_MONTH_SHORT_1',
                        position: 1
                    },
                    {
                        label: 'UI_DATE_MONTH_FULL_2',
                        short_label: 'UI_DATE_MONTH_SHORT_2',
                        position: 2
                    },
                    {
                        label: 'UI_DATE_MONTH_FULL_3',
                        short_label: 'UI_DATE_MONTH_SHORT_3',
                        position: 3
                    },
                    {
                        label: 'UI_DATE_MONTH_FULL_4',
                        short_label: 'UI_DATE_MONTH_SHORT_4',
                        position: 4
                    },
                    {
                        label: 'UI_DATE_MONTH_FULL_5',
                        short_label: 'UI_DATE_MONTH_SHORT_5',
                        position: 5
                    },
                    {
                        label: 'UI_DATE_MONTH_FULL_6',
                        short_label: 'UI_DATE_MONTH_SHORT_6',
                        position: 6
                    },
                    {
                        label: 'UI_DATE_MONTH_FULL_7',
                        short_label: 'UI_DATE_MONTH_SHORT_7',
                        position: 7
                    },
                    {
                        label: 'UI_DATE_MONTH_FULL_8',
                        short_label: 'UI_DATE_MONTH_SHORT_8',
                        position: 8
                    },
                    {
                        label: 'UI_DATE_MONTH_FULL_9',
                        short_label: 'UI_DATE_MONTH_SHORT_9',
                        position: 9
                    },
                    {
                        label: 'UI_DATE_MONTH_FULL_10',
                        short_label: 'UI_DATE_MONTH_SHORT_10',
                        position: 10
                    },
                    {
                        label: 'UI_DATE_MONTH_FULL_11',
                        short_label: 'UI_DATE_MONTH_SHORT_11',
                        position: 11
                    },
                    {
                        label: 'UI_DATE_MONTH_FULL_12',
                        short_label: 'UI_DATE_MONTH_SHORT_12',
                        position: 12
                    }
                ]
            },
            time_range: {
                label: 'UI_TIME_RANGE_LABEL'
            },
            time_duration: {
                label: 'UI_TIME_DURATION_LABEL',
                hour_single: 'UI_TIME_HOUR_SINGLE',
                hour_multiple: 'UI_TIME_HOUR_MULTIPLE',
                min_single: 'UI_TIME_MINS_SINGLE',
                min_multiple: 'UI_TIME_MINS_MULTIPLE'
            },
            pricing_range: {
                label: 'UI_PRICING_RANGE_LABEL',
                selections: ['UI_PRICING_PERSON', 'UI_PRICING_COUPLE']
            },
            reservation: {
                label: 'UI_RESERVATION_LABEL',
                selections: ['UI_RESERVATION_SELECTION_NO', 'UI_RESERVATION_SELECTION_YES', 'UI_RESERVATION_SELECTION_RECOMMENDED']
            },
            getting_there: {
                label: ''
            }
        },

        navmain: {
            kreations: {
                title: 'NAVMAIN_KREATIONS',
                icon: 'Grid'
            },
            payout: {
                title: 'NAVMAIN_PAYOUT',
                icon: 'DollarSign'
            },
            profile: {
                title: 'NAVMAIN_PROFILE',
                icon: 'User'
            },
            help: {
                title: 'NAVMAIN_HELP',
                icon: 'HelpCircle'
            },
            signout: {
                title: 'NAVMAIN_SIGNOUT',
                icon: 'LogOut'
            }
        },

        languages: [
            {
                id: 'en',
                name: 'English',
                short: 'en'
            },
            {
                id: 'ar',
                name: 'العربية',
                short: 'ع'
            }
        ],

        links: {
            help: 'https://academy.viakonnect.com'
        },

        messages: {
            hello: 'UI_MSG_HELLO',
            welcome: 'UI_MSG_WELCOME',
            welcomeBack: 'UI_MSG_WELCOME_BACK'
        },

        experience: {
            mykreations: 'UI_LBL_MYKREATIONS',
            add_experience: 'UI_BTN_ADD_EXPERIENCE'
        },

        buttons: {
            save: 'UI_BTN_SAVE',
            save_changes: 'UI_BTN_SAVE_CHANGES',
            discard: 'UI_BTN_DISCARD',
            discard_changes: 'UI_BTN_DISCARD_CHANGES',
            edit: 'UI_BTN_EDIT',
            cancel: 'UI_BTN_CANCEL',
            delete: 'UI_BTN_DELETE',
            apply: 'UI_BTN_APPLY',
            update: 'UI_BTN_UPDATE',
            close: 'UI_BTN_CLOSE',
            confirm: 'UI_BTN_CONFIRM',
            add: 'UI_BTN_ADD',
            remove: 'UI_BTN_REMOVE',
            add_tip: 'UI_BTN_ADD_TIP',
            add_link: 'UI_BTN_ADD_LINK',
            add_extra: 'UI_BTN_ADD_EXTRA'
        },

        gallery: {
            add: 'UI_LBL_GALLERY_ADD',
            manage: 'UI_LBL_GALLERY_MANAGE'
        },
        labels: {
            tip: 'UI_LBL_TIP',
            fun_fact: 'UI_LBL_FUNFACT',
            note: 'UI_LBL_NOTE',
            dont_forget: 'UI_LBL_DONTFORGET',
            watchout: 'UI_LBL_WATCHOUT',
            covid19: 'UI_LBL_COVID19'
        },
        tip_list: {
            UI_LBL_GREEN: {
                label: 'UI_LBL_FUNFACT',
                pill_color: 'green-300',
                text_color: 'green-900',
                bg_color: 'green-50',
                hover_color: 'green-100',
                border_color: 'green-400'
            },
            UI_LBL_TIP: {
                label: 'UI_LBL_TIP',
                pill_color: 'kn-yellow',
                text_color: 'black',
                bg_color: 'yellow-50',
                hover_color: 'kn-yellow-25',
                border_color: 'yellow-300'
            },
            UI_LBL_FUNFACT: {
                label: 'UI_LBL_FUNFACT',
                pill_color: 'purple-300',
                text_color: 'purple-900',
                bg_color: 'purple-50',
                hover_color: 'purple-100',
                border_color: 'purple-400'
            },
            UI_LBL_NOTE: {
                label: 'UI_LBL_NOTE',
                pill_color: 'blue-300',
                text_color: 'blue-800',
                bg_color: 'blue-50',
                hover_color: 'blue-100',
                border_color: 'blue-300'
            },
            UI_LBL_WATCHOUT: {
                label: 'UI_LBL_WATCHOUT',
                pill_color: 'red-300',
                text_color: 'red-800',
                bg_color: 'red-50',
                hover_color: 'red-100',
                border_color: 'red-400'
            },
            UI_LBL_DONTFORGET: {
                label: 'UI_LBL_DONTFORGET',
                pill_color: 'yellow-300',
                text_color: 'yellow-900',
                bg_color: 'yellow-50',
                hover_color: 'yellow-100',
                border_color: 'yellow-200'
            },
            UI_LBL_COVID19: {
                label: 'UI_LBL_COVID19',
                pill_color: 'gray-900',
                text_color: 'white',
                bg_color: 'gray-100',
                hover_color: 'gray-200',
                border_color: 'gray-300'
            },
            genericEdit: {
                label: 'UI_LBL_DONTFORGET',
                pill_color: 'kn-yellow',
                text_color: 'black',
                bg_color: 'yellow-50',
                hover_color: 'kn-yellow-25',
                border_color: 'kn-primary'
            },
            gray: {
                label: 'UI_LBL_DONTFORGET',
                pill_color: 'gray-400',
                text_color: 'gray-800',
                bg_color: 'gray-50',
                hover_color: 'gray-100',
                border_color: 'gray-400'
            },

            map: {
                label: '',
                pill_color: 'cyan-500',
                text_color: 'cyan-900',
                bg_color: 'cyan-50',
                hover_color: 'cyan-100',
                border_color: 'cyan-500'
            }
        },
        note_list: ['UI_LBL_TIP', 'UI_LBL_FUNFACT', 'UI_LBL_NOTE', 'UI_LBL_DONTFORGET', 'UI_LBL_WATCHOUT', 'UI_LBL_COVID19'],
        getting_there_list_type: ['LOCAL_TRAVEL', 'INTERNATIONAL_TRAVEL'],
        getting_there_list_method: ['AIR_TRAVEL', 'SEA', 'RAILWAY', 'VEHICLE'],
        modals: {
            lightbox: {
                icon: {
                    name: 'IMAGEALT',
                    size: '2xl'
                },

                buttons: {
                    action: {
                        label: 'UI_BTN_DELETE',
                        color: 'warning',
                        icon: {
                            name: 'CHECKALT',
                            size: '2xl'
                        }
                    },
                    cancel: {
                        label: 'UI_BTN_CANCEL',
                        color: 'default',
                        icon: {
                            name: 'CLOSEALT',
                            size: '2xl'
                        }
                    }
                },

                description: {
                    need_help: 'UI_HELP_NEEDHELP',
                    need_more_help: 'UI_HELP_NEEDMOREHELP',
                    konnect_academy: 'UI_HELP_KONNECTACADEMY'
                }
            },

            help: {
                icon: {
                    name: 'QUESTION',
                    size: '2xl'
                },

                buttons: {
                    action: {
                        label: 'UI_BTN_DELETE',
                        color: 'warning',
                        icon: {
                            name: 'CHECKALT',
                            size: '2xl'
                        }
                    },
                    cancel: {
                        label: 'UI_BTN_CANCEL',
                        color: 'default',
                        icon: {
                            name: 'CLOSEALT',
                            size: '2xl'
                        }
                    }
                },

                description: {
                    need_help: 'UI_HELP_NEEDHELP',
                    need_more_help: 'UI_HELP_NEEDMOREHELP',
                    konnect_academy: 'UI_HELP_KONNECTACADEMY'
                }
            },
            OK: {
                icon: {
                    name: 'TRASHALT',
                    size: '2xl'
                },

                buttons: {
                    action: {
                        label: 'UI_BTN_OK',
                        color: 'action2',
                        icon: {
                            name: 'CHECKALT',
                            size: '2xl'
                        }
                    },
                    cancel: {
                        label: 'UI_BTN_CANCEL',
                        color: 'default',
                        icon: {
                            name: 'CLOSEALT',
                            size: '2xl'
                        }
                    }
                },

                description: 'UI_MDL_OK_TEXT'
            },

            delete: {
                icon: {
                    name: 'TRASHALT',
                    size: '2xl'
                },

                buttons: {
                    action: {
                        label: 'UI_BTN_DELETE',
                        color: 'warning',
                        icon: {
                            name: 'CHECKALT',
                            size: '2xl'
                        }
                    },
                    cancel: {
                        label: 'UI_BTN_CANCEL',
                        color: 'default',
                        icon: {
                            name: 'CLOSEALT',
                            size: '2xl'
                        }
                    }
                },

                description: 'UI_MDL_DELETE_TEXT'
            },
            editor: {
                icon: {
                    name: 'ADDTEXT',
                    size: '2xl'
                },
                buttons: {
                    action: {
                        label: 'UI_BTN_SAVE',
                        color: 'action2',
                        icon: {
                            name: 'CHECKALT',
                            size: '2xl'
                        }
                    },
                    cancel: {
                        label: 'UI_BTN_CANCEL',
                        color: 'default',
                        icon: {
                            name: 'CLOSEALT',
                            size: '2xl'
                        }
                    }
                },

                hint: 'UI_EDITOR_HELP',
                placeholders: 'UI_EDITOR_PLACEHOLDER'
            },

            submit: {
                icon: {
                    name: 'UPLOAD',
                    size: '2xl'
                },
                buttons: {
                    action: {
                        label: 'UI_BTN_SUBMIT',
                        color: 'action2',
                        icon: {
                            name: 'CHECKALT',
                            size: '2xl'
                        }
                    },
                    cancel: {
                        label: 'UI_BTN_CANCEL',
                        color: 'default',
                        icon: {
                            name: 'CLOSEALT',
                            size: '2xl'
                        }
                    }
                },

                hint: 'UI_EDITOR_HELP',
                placeholders: 'UI_EDITOR_PLACEHOLDER'
            },

            add: {
                title: 'UI_MDL_ADD_TITLE',
                text: ''
            },
            edit: {
                title: 'UI_MDL_EDIT_TITLE',
                text: ''
            },
            animation: {
                off: {
                    glass: 'opacity-0 scale-90',
                    overlay: 'opacity-0'
                },
                on: {
                    glass: 'opacity-100 scale-100',
                    overlay: 'opacity-90'
                }
            }
        },

        editor: {
            placeholder: 'UI_EDITOR_PLACEHOLDER',
            help: 'UI_EDITOR_HELP'
        },

        help: {
            need_help: 'UI_HELP_NEEDHELP',
            need_more_help: 'UI_HELP_NEEDMOREHELP',
            konnect_academy: 'UI_HELP_KONNECTACADEMY'
        },

        styles: {
            buttons: {
                default: 'bg-gray-100 text-gray-600 md:w-32 px-4',
                action: 'bg-kn-primary text-white md:w-32 px-4',
                action2: 'bg-kn-primary text-kn-primary-700 md:w-32 px-4',
                action3: 'bg-green-200 text-kn-primary-700 md:w-32 px-4',
                warning: 'bg-kn-red text-white md:w-32 px-4',
                red: 'bg-kn-red text-white md:w-32 px-4',
                green: 'bg-kn-primary text-white md:w-32 px-4',
                gray: 'bg-gray-100 text-gray-600 md:w-32 px-4',
                yellow: 'bg-kn-yellow text-gray-900 md:w-32 px-4',
                help: 'bg-kn-yellow text-gray-900 sm:w-12 sm:rounded-full',
                plusdark: 'bg-gray-100 text-gray-600 sm:w-12 sm:rounded-full'
            }
        }
    },
    experience_list: {
        modals: {
            submit_message: 'EXP_LIST_SUBMIT'
        }
    },
    experience: {
        types: ['DIGITAL', 'GUIDED'],
        categories: ['Adventure', 'Active', 'Cultural', 'Wellness', 'Luxury', 'Events'],
        tags: [
            {
                name: 'hiking',
                related: ['trekking', 'backpacking']
            },
            {
                name: 'sailing',
                related: ['boat', 'speed']
            },
            {
                name: 'exploring',
                related: []
            },

            {
                name: 'off roading',
                related: ['atv', 'truck']
            },
            {
                name: 'fishing',
                related: ['boat', 'sea', 'lake', 'ocean']
            },
            {
                name: 'camping',
                related: ['tent', 'camper', 'van']
            },
            {
                name: 'outdoors',
                related: []
            },
            {
                name: 'bird watching',
                related: []
            },
            {
                name: 'extreme',
                related: []
            },
            {
                name: 'safari',
                related: ['wild', 'animals']
            },
            {
                name: 'backpacking',
                related: ['back', 'pack', 'hiking']
            },
            {
                name: 'roadtrip',
                related: ['road', 'trip']
            },
            {
                name: 'caving',
                related: ['climbing', 'camping', 'canyoning', 'hiking']
            },
            {
                name: 'ecotourism',
                related: ['eco', 'tourism', 'green', 'environmental']
            },
            {
                name: 'environmental',
                related: ['eco', 'tourism', 'green', 'sustainable']
            },
            {
                name: 'photography',
                related: []
            },
            {
                name: 'golf',
                related: ['sport']
            },
            {
                name: 'diving',
                related: ['snorkling', 'water', 'sea', 'lake', 'pool']
            },
            {
                name: 'trekking',
                related: ['climbing', 'camping', 'canyoning', 'hiking']
            },
            {
                name: 'climbing',
                related: ['trekking', 'camping', 'canyoning', 'hiking']
            },
            {
                name: 'swimming',
                related: ['diving', 'water', 'sea', 'lake', 'pool', 'sport']
            },
            {
                name: 'cycling',
                related: ['bike', 'bicycle', 'sport']
            },
            {
                name: 'paragliding',
                related: ['extreme', 'rush']
            },
            {
                name: 'skiing',
                related: ['snow', 'winter', 'sport']
            },
            {
                name: 'rafting',
                related: ['water', 'river', 'camping', 'hiking', 'sport']
            },
            {
                name: 'horse backriding',
                related: ['horse', 'riding']
            },
            {
                name: 'surfing',
                related: ['sport', 'water', 'beach']
            },
            {
                name: 'kite surfing',
                related: ['sport', 'water', 'beach']
            },
            {
                name: 'canoeing',
                related: ['sport', 'water', 'river', 'lake', 'boat', 'raft']
            },
            {
                name: 'skydiving',
                related: ['sport', 'extreme']
            },

            {
                name: 'city',
                related: []
            },
            {
                name: 'nightlife',
                related: []
            },
            {
                name: 'events',
                related: []
            },
            {
                name: 'arts',
                related: []
            },
            {
                name: 'food',
                related: []
            },
            {
                name: 'attractions',
                related: []
            },
            {
                name: 'volunteering',
                related: ['help', 'ecotourism', 'eco']
            },
            {
                name: 'historical',
                related: []
            },
            {
                name: 'agriculture',
                related: []
            },
            {
                name: 'pilgrimage',
                related: []
            },
            {
                name: 'educational',
                related: []
            },
            {
                name: 'religious',
                related: []
            },
            {
                name: 'festivals',
                related: []
            },
            {
                name: 'expos',
                related: []
            },
            {
                name: 'musical',
                related: []
            },
            {
                name: 'spa',
                related: ['relaxation', 'meditation', 'chill']
            },
            {
                name: 'retreats',
                related: ['relaxation', 'meditation', 'chill', 'yoga']
            },
            {
                name: 'meditation',
                related: ['yoga', 'meditation', 'chill']
            },
            {
                name: 'rehabilitation',
                related: ['relaxation', 'meditation', 'chill', 'yoga']
            },
            {
                name: 'yoga',
                related: ['relaxation', 'meditation', 'chill']
            },
            {
                name: 'cruise',
                related: ['boat', 'honeymoon']
            },
            {
                name: 'honeymoon',
                related: ['boat']
            },
            {
                name: 'shopping',
                related: []
            },
            {
                name: 'yachting',
                related: ['boat', 'yacht', 'marina']
            },
            {
                name: 'jet setting',
                related: ['bling']
            },
            {
                name: 'touring',
                related: []
            },
            {
                name: 'culinary',
                related: ['food', 'wine', 'eating']
            },
            {
                name: 'sports',
                related: []
            },
            {
                name: 'technology',
                related: ['food', 'wine', 'eating']
            },
            {
                name: 'automotive',
                related: ['food', 'wine', 'eating']
            },
            {
                name: 'fashion',
                related: ['food', 'wine', 'eating']
            }
        ],
        included: [
            'ACCOMMODATION',
            'AIRPORT_TRANSFER_FULL',
            'AIRPORT_TRANSFER_HALF',
            'ACTIVITIES_ALL',
            'ACTIVITIES_PARTIAL',
            'FULL_BOARD',
            'BREAKFAST',
            'LUNCH',
            'DINNER',
            'SNACKS',
            'ACTIVITY_GEAR',
            'TRANSPORTATION',
            'VENUE_TICKETS',
            'PLANE_TICKET',
            'TRAIN_TICKET',
            'LOCAL_GUIDE'
        ]
    },
    overview: {
        intro: {
            uiPreview: {
                headTitle: 'OV_INTRO_V_HEADTITLE',
                menuTitle: 'OV_INTRO_V_MENUTITLE',
                titleEmpty: 'OV_INTRO_V_TITLE_EMPTY',
                descriptionEmpty: 'OV_INTRO_V_DESCRIPTION_EMPTY',
                hint: 'OV_INTRO_V_HINT'
            },
            uiEdit: {
                modals: {
                    title: {
                        icon: {
                            name: 'ADDTEXT',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },

                        hint: 'OV_INTRO_E_M_TITLE_HINT',
                        placeholders: 'OV_INTRO_E_M_TITLE_PLACEHOLDER'
                    },
                    description: {
                        icon: {
                            name: 'ADDTEXT',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },

                        hint: 'OV_INTRO_E_M_DESCRIPTION_HINT',
                        placeholders: 'OV_INTRO_E_M_DESCRIPTION_PLACEHOLDER'
                    }
                }
            }
        },
        budget: {
            uiPreview: {
                headTitle: 'OV_BUDGET_V_HEADTITLE',
                subTitle: 'OV_BUDGET_V_SUBTITLE',
                menuTitle: 'OV_BUDGET_V_MENUTITLE',
                tip: 'OV_BUDGET_V_TIP',

                descriptionEmpty: 'OV_BUDGET_V_DESCRIPTION_EMPTY',
                hint: 'OV_BUDGET_V_HINT',
                icon: {
                    name: 'CHECKLIST',
                    size: '2xl'
                },
                hintEmpty: 'OV_WHENTOGO_V_HINT_EMPTY'
            }
        },
        when_to_go: {
            uiPreview: {
                headTitle: 'OV_WHENTOGO_V_HEADTITLE',
                subTitle: 'OV_WHENTOGO_V_SUBTITLE',
                menuTitle: 'OV_WHENTOGO_V_MENUTITLE',
                tip: 'OV_WHENTOGO_V_TIP',

                descriptionEmpty: 'OV_WHENTOGO_V_DESCRIPTION_EMPTY',
                hint: 'OV_WHENTOGO_V_HINT',
                icon: {
                    name: 'CHECKLIST',
                    size: '2xl'
                },
                hintEmpty: 'OV_WHENTOGO_V_HINT_EMPTY'
            }
        },

        destination: {
            uiPreview: {
                headTitle: 'OV_DESTINATION_V_HEADTITLE',
                subTitle: 'OV_DESTINATION_V_SUBTITLE',
                menuTitle: 'OV_DESTINATION_V_MENUTITLE',
                tip: 'OV_DESTINATION_V_TIP',

                descriptionEmpty: 'OV_DESTINATION_V_DESCRIPTION_EMPTY',
                hint: 'OV_DESTINATION_V_HINT',
                icon: {
                    name: 'MAP',
                    size: '2xl'
                },
                hintEmpty: 'OV_DESTINATION_V_HINT_EMPTY'
            },
            uiEdit: {
                buttons: {
                    addMain: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },
                        label: 'OV_DESTINATION_E_I_ADDMAIN'
                    },
                    addlink: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },
                        label: 'OV_DESTINATION_E_I_ADDLINK'
                    },
                    addTip: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },
                        label: 'OV_DESTINATION_E_I_ADDTIP'
                    }
                },

                modals: {
                    delete: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },

                        buttons: {
                            action: {
                                label: 'UI_BTN_DELETE',
                                color: 'warning',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },

                        description: 'OV_DESTINATION_E_M_DELETE_DESCRIPTION'
                    },

                    description: {
                        icon: {
                            name: 'ADDTEXT',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },

                        hint: 'OV_DESTINATION_E_M_DESCRIPTION_HINT',
                        placeholders: 'OV_DESTINATION_E_M_DESCRIPTION_PLACEHOLDER'
                    },
                    places: {
                        icon: {
                            name: 'MAP_MARKERADDALT',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            },
                            address: {
                                label: 'OV_DESTINATION_E_M_PLACE_BTN_ADDRESS',
                                color: 'action2',
                                icon: {
                                    name: 'MAP',
                                    size: '2xl'
                                }
                            }
                        },

                        hint: 'OV_DESTINATION_E_M_PLACE_HINT',
                        placeholders: {
                            autocomplete: 'OV_DESTINATION_E_M_PLACE_PLACEHOLDER_AUTOCOMPLETE',
                            address: 'OV_DESTINATION_E_M_PLACE_PLACEHOLDER_ADDRESS'
                        },
                        description: 'OV_DESTINATION_E_M_PLACE_DESCRIPTION'
                    },

                    tip: {
                        icon: {
                            name: 'ADDTEXT',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },
                        hint: 'ACTIVITIES_E_M_TIP_HINT',
                        placeholders: 'ACTIVITIES_E_M_TIP_PLACEHOLDER'
                    },
                    editor_settings: {}
                }
            }
        },

        getting_there: {
            uiPreview: {
                headTitle: 'OV_GETTINGTHERE_V_HEADTITLE',
                subTitle: 'OV_GETTINGTHERE_V_SUBTITLE',
                menuTitle: 'OV_GETTINGTHERE_V_MENUTITLE',
                tip: 'OV_GETTINGTHERE_V_TIP',
                descriptionEmpty: 'OV_GETTINGTHERE_V_DESCRIPTION_EMPTY',
                entry: 'OV_GETTINGTHERE_V_ENTRY',
                entryEmpty: 'OV_GETTINGTHERE_V_ENTRY_EMPTY',
                hint: 'OV_GETTINGTHERE_V_HINT',
                icon: {
                    name: 'INTERNATIONAL_TRAVEL',
                    size: '2xl'
                },
                hintEmpty: 'OV_GETTINGTHERE_V_HINT_EMPTY'
            },
            uiEdit: {
                buttons: {
                    addMain: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },
                        label: 'OV_GETTINGTHERE_E_I_ADDMAIN'
                    },
                    addlink: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },
                        label: 'OV_GETTINGTHERE_E_I_ADDLINK'
                    },
                    addTip: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },
                        label: 'OV_GETTINGTHERE_E_I_ADDTIP'
                    }
                },

                modals: {
                    add: {
                        defaults: {
                            type: 'CAR_RENTAL'
                        },

                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },

                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },
                        description: 'OV_GETTINGTHERE_E_M_ADD_DESCRIPTION',
                        hint: 'OV_GETTINGTHERE_E_M_ADD_HINT'
                    },

                    delete: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },

                        buttons: {
                            action: {
                                label: 'UI_BTN_DELETE',
                                color: 'warning',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },

                        description: 'OV_GETTINGTHERE_E_M_DELETE_DESCRIPTION'
                    },
                    entry: {
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },
                        hint: 'OV_GETTINGTHERE_E_M_TYPE_HINT',
                        description: 'OV_GETTINGTHERE_E_M_ENTRY_DESCRIPTION',
                        placeholders: {
                            name: 'OV_GETTINGTHERE_E_M_ENTRY_PLACEHOLDER_NAME',
                            url: 'OV_GETTINGTHERE_E_M_ENTRY_PLACEHOLDER_URL'
                        }
                    },
                    description: {
                        icon: {
                            name: 'ADDTEXT',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },

                        hint: 'OV_GETTINGTHERE_E_M_DESCRIPTION_HINT',
                        placeholders: 'OV_GETTINGTHERE_E_M_DESCRIPTION_PLACEHOLDER'
                    },
                    vendor: {
                        icon: {
                            name: 'LIST',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },
                        placeholders: {
                            name: 'OV_GETTINGTHERE_E_M_VENDOR_PLACEHOLDER_NAME',
                            label: 'OV_GETTINGTHERE_E_M_VENDOR_PLACEHOLDER_LABEL',
                            url: 'OV_GETTINGTHERE_E_M_VENDOR_PLACEHOLDER_URL'
                        },
                        hint: 'OV_GETTINGTHERE_E_M_VENDOR_HINT',
                        description: 'OV_GETTINGTHERE_E_M_VENDOR_DESCRIPTION'
                    },
                    tip: {
                        icon: {
                            name: 'ADDTEXT',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },
                        hint: 'OV_GETTINGTHERE_E_M_TIP_HINT',
                        placeholders: 'OV_GETTINGTHERE_E_M_TIP_PLACEHOLDER'
                    },
                    editor_settings: {}
                },
                // transport_list: ['LOCAL_TRAVEL', 'INTERNATIONAL_TRAVEL'],
                transport_list_vendors: ['AIR_TRAVEL', 'SEA', 'RAILWAY', 'VEHICLE']
            }
        },

        currency_paying: {
            uiPreview: {
                headTitle: 'OV_CURRENCY_V_HEADTITLE',
                subTitle: 'OV_CURRENCY_V_SUBTITLE',
                menuTitle: 'OV_CURRENCY_V_MENUTITLE',
                tip: 'OV_CURRENCY_V_TIP',
                descriptionEmpty: 'OV_CURRENCY_V_DESCRIPTION_EMPTY',
                entry: 'OV_CURRENCY_V_ENTRY',
                entryEmpty: 'OV_CURRENCY_V_ENTRY_EMPTY',
                hint: 'OV_CURRENCY_V_HINT',
                hintEmpty: 'OV_CURRENCY_V_HINT_EMPTY'
            },
            uiEdit: {
                buttons: {
                    addMain: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },
                        label: 'OV_CURRENCY_E_I_ADDMAIN',
                        label_more: 'OV_CURRENCY_E_I_ADDMAIN_MORE'
                    },
                    addlink: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },
                        label: 'OV_CURRENCY_E_I_ADDLINK'
                    },
                    addTip: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },
                        label: 'OV_CURRENCY_E_I_ADDMTIP'
                    }
                },

                modals: {
                    add: {
                        defaults: {
                            type: 'CAR_RENTAL'
                        },

                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },

                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },
                        description: 'OV_CURRENCY_E_M_ADD_DESCRIPTION',
                        hint: 'OV_CURRENCY_E_M_ADD_HINT'
                    },

                    delete: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },

                        buttons: {
                            action: {
                                label: 'UI_BTN_DELETE',
                                color: 'warning',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },

                        description: 'OV_CURRENCY_E_M_DELETE_DESCRIPTION'
                    },
                    type: {
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },
                        hint: 'OV_CURRENCY_E_M_TYPE_HINT'
                    },
                    description: {
                        icon: {
                            name: 'ADDTEXT',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },

                        hint: 'OV_CURRENCY_E_M_DESCRIPTION_HINT',
                        placeholders: 'OV_CURRENCY_E_M_DESCRIPTION_PLACEHOLDER'
                    },
                    vendor: {
                        icon: {
                            name: 'LIST',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },
                        placeholders: {
                            name: 'OV_CURRENCY_E_M_VENDOR_PLACEHOLDER_NAME',
                            label: 'OV_CURRENCY_E_M_VENDOR_PLACEHOLDER_LABEL',
                            url: 'OV_CURRENCY_E_M_VENDOR_PLACEHOLDER_URL'
                        },
                        hint: 'OV_CURRENCY_E_M_VENDOR_HINT',
                        description: 'OV_CURRENCY_E_M_VENDOR_DESCRIPTION'
                    },
                    tip: {
                        icon: {
                            name: 'ADDTEXT',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },
                        hint: 'OV_CURRENCY_E_M_TIP_HINT',
                        placeholders: 'OV_CURRENCY_E_M_TIP_PLACEHOLDER'
                    },
                    editor_settings: {}
                },
                // transport_list: ['LOCAL_TRAVEL', 'INTERNATIONAL_TRAVEL'],
                currency_vendors: ['CASH', 'CREDIT', 'CHEQUE', 'AMEX']
            }
        },
        moving_around: {
            uiPreview: {
                headTitle: 'OV_MOVING_AROUND_V_HEADTITLE',
                subTitle: 'OV_MOVING_AROUND_V_SUBTITLE',
                menuTitle: 'OV_MOVING_AROUND_V_MENUTITLE',
                tip: 'OV_MOVING_AROUND_V_TIP',
                descriptionEmpty: 'OV_MOVING_AROUND_V_DESCRIPTION_EMPTY',
                hint: 'OV_MOVING_AROUND_V_HINT',
                hintEmpty: 'OV_MOVING_AROUND_V_HINT_EMPTY'
            },
            uiEdit: {
                buttons: {
                    addMain: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },
                        label: 'OV_MOVING_AROUND_E_I_ADDMAIN'
                    },
                    addlink: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },
                        label: 'OV_MOVING_AROUND_E_I_ADDLINK'
                    },
                    addTip: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },
                        label: 'OV_MOVING_AROUND_E_I_ADDMTIP'
                    }
                },

                modals: {
                    add: {
                        defaults: {
                            type: 'CAR_RENTAL'
                        },

                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },

                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },
                        description: 'MOVING_AROUND_E_M_ADD_DESCRIPTION',
                        hint: 'MOVING_AROUND_E_M_ADD_HINT'
                    },

                    delete: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },

                        buttons: {
                            action: {
                                label: 'UI_BTN_DELETE',
                                color: 'warning',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },

                        description: 'MOVING_AROUND_E_M_DELETE_DESCRIPTION'
                    },
                    type: {
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },
                        hint: 'MOVING_AROUND_E_M_TYPE_HINT'
                    },
                    description: {
                        icon: {
                            name: 'ADDTEXT',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },

                        hint: 'MOVING_AROUND_E_M_DESCRIPTION_HINT',
                        placeholders: 'MOVING_AROUND_E_M_DESCRIPTION_PLACEHOLDER'
                    },
                    vendor: {
                        icon: {
                            name: 'LIST',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },
                        placeholders: {
                            name: 'MOVING_AROUND_E_M_VENDOR_PLACEHOLDER_NAME',
                            label: 'MOVING_AROUND_E_M_VENDOR_PLACEHOLDER_LABEL',
                            url: 'MOVING_AROUND_E_M_VENDOR_PLACEHOLDER_URL'
                        },
                        hint: 'MOVING_AROUND_E_M_VENDOR_HINT',
                        description: 'MOVING_AROUND_E_M_VENDOR_DESCRIPTION'
                    },
                    tip: {
                        icon: {
                            name: 'ADDTEXT',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },
                        hint: 'MOVING_AROUND_E_M_TIP_HINT',
                        placeholders: 'MOVING_AROUND_E_M_TIP_PLACEHOLDER'
                    },
                    editor_settings: {}
                },
                transport_list: [
                    'PLANE_RIDES',
                    'BOAT_RIDES',
                    'FERRY',
                    'TRAIN',
                    'METRO',
                    'TRAMWAY',
                    'BUS',
                    'TAXI',
                    'CAR_RENTAL',
                    'RIDE_SHARING',
                    'RIDE_POOLING',
                    'SCOOTER',
                    'BIKE',
                    'E_BIKE',
                    'WALKING',
                    'HORSE',
                    'CAMEL',
                    'DONKEY',
                    'ELEPHANT'
                ]
            }
        },

        accommodation: {
            uiPreview: {
                headTitle: 'OV_ACCOMMODATION_V_HEADTITLE',
                subTitle: 'OV_ACCOMMODATION_V_SUBTITLE',
                menuTitle: 'OV_ACCOMMODATION_V_MENUTITLE',
                tip: 'OV_ACCOMMODATION_V_TIP',
                descriptionEmpty: 'OV_ACCOMMODATION_V_DESCRIPTION_EMPTY',
                hint: 'OV_ACCOMMODATION_V_HINT',
                icon: {
                    name: 'HOTEL',
                    size: '2xl'
                },
                hintEmpty: 'OV_ACCOMMODATION_V_HINT_EMPTY'
            },

            uiEdit: {
                buttons: {
                    addMain: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },
                        label: 'OV_ACCOMMODATION_E_I_ADDMAIN'
                    },
                    addlink: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },
                        label: 'OV_ACCOMMODATION_E_I_ADDLINK'
                    },
                    addTip: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },
                        label: 'OV_ACCOMMODATION_E_I_ADDMTIP'
                    }
                },

                hotel_list: [
                    'ACC_HOTEL',
                    'ACC_MOTEL',
                    'ACC_APARTMENT',
                    'ACC_CHALET',
                    'ACC_COTTAGE',
                    'ACC_LOG_CABIN',
                    'ACC_CAMPING_TENT',
                    'ACC_TEPEE',
                    'ACC_RESORT',
                    'ACC_BED_N_BREAKFAST',
                    'ACC_APART_HOTEL',
                    'ACC_GUEST_HOUSE',
                    'ACC_YOUTH_HOSTEL',
                    'ACC_BOAT',
                    'ACC_CAMPER',
                    'ACC_YURT',
                    'ACC_TREEHOUSE'
                ],
                rating_list: ['ACC_STAR1', 'ACC_STAR2', 'ACC_STAR3', 'ACC_STAR4', 'ACC_STAR5', 'ACC_NA', 'ACC_BOUTIQUE']
            }
        },
        general_tips: {
            uiPreview: {
                headTitle: 'OV_TIPS_V_HEADTITLE',
                subTitle: 'OV_TIPS_V_SUBTITLE',
                menuTitle: 'OV_TIPS_V_MENUTITLE',
                tip: 'OV_TIPS_V_TIP',
                descriptionEmpty: 'OV_TIPS_V_DESCRIPTION_EMPTY',
                hint: 'OV_TIPS_V_HINT',
                icon: {
                    name: 'CHECKLIST',
                    size: '2xl'
                },
                hintEmpty: 'OV_TIPS_V_HINT_EMPTY'
            },
            uiEdit: {
                buttons: {
                    addMain: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },
                        label: 'OV_TIPS_E_I_ADDMAIN'
                    }
                }
            }
        },
        itinerary: {
            uiPreview: {
                headTitle: 'OV_ITINERARY_V_HEADTITLE',
                menuTitle: 'OV_ITINERARY_V_MENUTITLE',
                tip: 'OV_ITINERARY_V_TIP',
                descriptionEmpty: 'OV_ITINERARY_V_DESCRIPTION_EMPTY',
                hint: 'OV_ITINERARY_V_HINT',
                hintEmpty: 'OV_ITINERARY_V_HINT_EMPTY'
            },
            uiEdit: {
                buttons: {
                    addMain: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },
                        label_empty: 'OV_ITINERARY_E_I_ADDMAIN_EMPTY',
                        label_one: 'OV_ITINERARY_E_I_ADDMAIN_ONE'
                    }
                }
            }
        }
    },
    itinerary: {
        intro: {
            uiPreview: {
                headTitle: 'INTRO_V_HEADTITLE',
                menuTitle: 'INTRO_V_MENUTITLE',
                titleEmpty: 'INTRO_V_TITLE_EMPTY',
                descriptionEmpty: 'INTRO_V_DESCRIPTION_EMPTY',
                hint: 'INTRO_V_HINT'
            },
            uiEdit: {
                modals: {
                    title: {
                        icon: {
                            name: 'ADDTEXT',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },

                        hint: 'INTRO_E_M_TITLE_HINT',
                        placeholders: 'INTRO_E_M_TITLE_PLACEHOLDER'
                    },
                    description: {
                        icon: {
                            name: 'ADDTEXT',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },

                        hint: 'INTRO_E_M_DESCRIPTION_HINT',
                        placeholders: 'INTRO_E_M_DESCRIPTION_PLACEHOLDER'
                    }
                }
            }
        },

        what_you_need: {
            uiPreview: {
                headTitle: 'WHAT_YOU_NEED_V_HEADTITLE',
                subTitle: 'WHAT_YOU_NEED_V_SUBTITLE',
                menuTitle: 'WHAT_YOU_NEED_V_MENUTITLE',
                tip: 'WHAT_YOU_NEED_V_TIP',

                descriptionEmpty: 'WHAT_YOU_NEED_V_DESCRIPTION_EMPTY',
                checklistEmpty: 'WHAT_YOU_NEED_V_CHEKLIST_EMPTY',
                hint: 'WHAT_YOU_NEED_V_HINT',
                icon: {
                    name: 'CHECKLIST',
                    size: '2xl'
                },
                hintEmpty: 'WHAT_YOU_NEED_V_HINT_EMPTY'
            },
            uiEdit: {
                buttons: {
                    addMain: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },
                        label: 'WHAT_YOU_NEED_E_I_ADDMAIN'
                    }
                },

                modals: {
                    add: {
                        defaults: {
                            type: 'CAR_RENTAL'
                        },

                        icon: {
                            name: 'ADDTEXTALT',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },

                        hint: 'WHAT_YOU_NEED_E_M_DESCRIPTION_HINT',
                        placeholders: 'WHAT_YOU_NEED_E_M_DESCRIPTION_PLACEHOLDER'
                    },

                    delete: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },

                        buttons: {
                            action: {
                                label: 'UI_BTN_DELETE',
                                color: 'warning',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },

                        description: 'WHAT_YOU_NEED_E_M_DELETE_DESCRIPTION'
                    },

                    description: {
                        icon: {
                            name: 'ADDTEXT',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },

                        hint: 'WHAT_YOU_NEED_E_M_DESCRIPTION_HINT',
                        placeholders: 'WHAT_YOU_NEED_E_M_DESCRIPTION_PLACEHOLDER'
                    },
                    checklist: {
                        icon: {
                            name: 'CHECKLIST',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },
                        placeholders: {
                            label: 'WHAT_YOU_NEED_E_M_CHECKLIST_PLACEHOLDER_LABEL',
                            url: 'WHAT_YOU_NEED_E_M_CHECKLIST_PLACEHOLDER_URL'
                        },
                        hint: 'WHAT_YOU_NEED_E_M_CHECKLIST_HINT',
                        description: 'WHAT_YOU_NEED_E_M_CHECKLIST_DESCRIPTION'
                    },

                    editor_settings: {}
                }
            }
        },

        moving_around: {
            uiPreview: {
                headTitle: 'MOVING_AROUND_V_HEADTITLE',
                subTitle: 'MOVING_AROUND_V_SUBTITLE',
                menuTitle: 'MOVING_AROUND_V_MENUTITLE',
                tip: 'MOVING_AROUND_V_TIP',
                descriptionEmpty: 'MOVING_AROUND_V_DESCRIPTION_EMPTY',
                hint: 'MOVING_AROUND_V_HINT',
                hintEmpty: 'MOVING_AROUND_V_HINT_EMPTY'
            },
            uiEdit: {
                buttons: {
                    addMain: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },
                        label: 'MOVING_AROUND_E_I_ADDMAIN'
                    },
                    addlink: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },
                        label: 'MOVING_AROUND_E_I_ADDLINK'
                    },
                    addTip: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },
                        label: 'MOVING_AROUND_E_I_ADDMTIP'
                    }
                },

                modals: {
                    add: {
                        defaults: {
                            type: 'CAR_RENTAL'
                        },

                        icon: {
                            name: 'PLUS',
                            size: '2xl'
                        },

                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },
                        description: 'MOVING_AROUND_E_M_ADD_DESCRIPTION',
                        hint: 'MOVING_AROUND_E_M_ADD_HINT'
                    },

                    delete: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },

                        buttons: {
                            action: {
                                label: 'UI_BTN_DELETE',
                                color: 'warning',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },

                        description: 'MOVING_AROUND_E_M_DELETE_DESCRIPTION'
                    },
                    type: {
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },
                        hint: 'MOVING_AROUND_E_M_TYPE_HINT'
                    },
                    description: {
                        icon: {
                            name: 'ADDTEXT',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },

                        hint: 'MOVING_AROUND_E_M_DESCRIPTION_HINT',
                        placeholders: 'MOVING_AROUND_E_M_DESCRIPTION_PLACEHOLDER'
                    },
                    vendor: {
                        icon: {
                            name: 'LIST',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },
                        placeholders: {
                            name: 'MOVING_AROUND_E_M_VENDOR_PLACEHOLDER_NAME',
                            label: 'MOVING_AROUND_E_M_VENDOR_PLACEHOLDER_LABEL',
                            url: 'MOVING_AROUND_E_M_VENDOR_PLACEHOLDER_URL'
                        },
                        hint: 'MOVING_AROUND_E_M_VENDOR_HINT',
                        description: 'MOVING_AROUND_E_M_VENDOR_DESCRIPTION'
                    },
                    tip: {
                        icon: {
                            name: 'ADDTEXT',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },
                        hint: 'MOVING_AROUND_E_M_TIP_HINT',
                        placeholders: 'MOVING_AROUND_E_M_TIP_PLACEHOLDER'
                    },
                    editor_settings: {}
                },
                transport_list: [
                    'PLANE_RIDES',
                    'BOAT_RIDES',
                    'FERRY',
                    'TRAIN',
                    'METRO',
                    'TRAMWAY',
                    'BUS',
                    'TAXI',
                    'CAR_RENTAL',
                    'RIDE_SHARING',
                    'RIDE_POOLING',
                    'SCOOTER',
                    'BIKE',
                    'E_BIKE',
                    'WALKING',
                    'HORSE',
                    'CAMEL',
                    'DONKEY',
                    'ELEPHANT'
                ]
            }
        },

        accommodation: {},
        activities: {
            uiPreview: {
                headTitle: 'ACTIVITIES_V_HEADTITLE',
                subTitle: 'ACTIVITIES_V_SUBTITLE',
                menuTitle: 'ACTIVITIES_V_MENUTITLE',
                details: {
                    time_range: {},
                    time_duration: {
                        label: 'ACTIVITIES_V_DURATION_LABEL'
                    },
                    pricing_range: {
                        label: 'ACTIVITIES_V_PRICING_LABEL'
                    },
                    reservation: {
                        selections: {
                            no: 'UI_RESERVATION_SELECTION_NO',
                            yes: 'UI_RESERVATION_SELECTION_YES',
                            recommended: 'ACTIVITIES_V_RESERVATION_SELECTION_RECOMMENDED',
                            highly: 'ACTIVITIES_V_RESERVATION_SELECTION_HIGHLY'
                        }
                    },

                    getting_there: {}
                },

                tip: 'ACTIVITIES_V_TIP',
                descriptionEmpty: 'ACTIVITIES_V_DESCRIPTION_EMPTY',
                addressEmpty: 'ACTIVITIES_V_ADDRESS_EMPTY',
                hint: 'ACTIVITIES_V_HINT',
                hintEmpty: 'ACTIVITIES_V_HINT_EMPTY'
            },
            uiEdit: {
                buttons: {
                    addMain: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },
                        label: 'ACTIVITIES_E_I_ADDMAIN'
                    },
                    addlink: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },
                        label: 'ACTIVITIES_E_I_ADDLINK'
                    },
                    addTip: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },
                        label: 'ACTIVITIES_E_I_ADDTIP'
                    }
                },

                modals: {
                    add: {
                        defaults: {
                            type: 'SIGHTSEEING',
                            pricingType: 'UI_PRICING_PERSON',
                            reservation: 'UI_RESERVATION_SELECTION_YES',
                            gettingThere: 'RIDE_SHARING'
                        },

                        icon: {
                            name: 'CAR_RENTAL',
                            size: '2xl'
                        },

                        buttons: {
                            action: {
                                label: 'UI_BTN_ADD',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },
                        description: 'ACTIVITIES_E_M_ADD_DESCRIPTION',
                        hint: 'ACTIVITIES_E_M_ADD_HINT'
                    },

                    delete: {
                        icon: {
                            name: 'TRASHALT',
                            size: '2xl'
                        },

                        buttons: {
                            action: {
                                label: 'UI_BTN_DELETE',
                                color: 'warning',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },

                        description: 'ACTIVITIES_E_M_DELETE_DESCRIPTION'
                    },
                    type: {
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },
                        hint: 'ACTIVITIES_E_M_TYPE_HINT',
                        description: 'ACTIVITIES_E_M_TYPE_DESCRIPTION',
                        placeholders: 'ACTIVITIES_E_M_TYPE_PLACEHOLDER'
                    },
                    description: {
                        icon: {
                            name: 'ADDTEXT',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },

                        hint: 'ACTIVITIES_E_M_DESCRIPTION_HINT',
                        placeholders: 'ACTIVITIES_E_M_DESCRIPTION_PLACEHOLDER'
                    },
                    map: {
                        icon: {
                            name: 'MAP_MARKERADDALT',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            },
                            address: {
                                label: 'ACTIVITIES_E_M_MAP_BTN_ADDRESS',
                                color: 'action3',
                                icon: {
                                    name: 'MAP',
                                    size: '2xl'
                                }
                            }
                        },

                        hint: 'ACTIVITIES_E_M_MAP_HINT',
                        placeholders: {
                            autocomplete: 'ACTIVITIES_E_M_MAP_PLACEHOLDER_AUTOCOMPLETE',
                            address: 'ACTIVITIES_E_M_MAP_PLACEHOLDER_ADDRESS'
                        },
                        description: 'ACTIVITIES_E_M_MAP_DESCRIPTION'
                    },
                    images: {
                        icon: {
                            name: 'PLUS',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },
                        placeholders: {
                            name: 'ACTIVITIES_E_M_VENDOR_PLACEHOLDER_NAME',
                            label: 'ACTIVITIES_E_M_VENDOR_PLACEHOLDER_LABEL',
                            url: 'ACTIVITIES_E_M_VENDOR_PLACEHOLDER_URL'
                        },

                        hint: 'ACTIVITIES_E_M_VENDOR_HINT',
                        description: 'ACTIVITIES_E_M_VENDOR_DESCRIPTION',
                        max_image_upload: 10
                    },
                    tip: {
                        icon: {
                            name: 'ADDTEXT',
                            size: '2xl'
                        },
                        buttons: {
                            action: {
                                label: 'UI_BTN_SAVE',
                                color: 'action2',
                                icon: {
                                    name: 'CHECKALT',
                                    size: '2xl'
                                }
                            },
                            cancel: {
                                label: 'UI_BTN_CANCEL',
                                color: 'default',
                                icon: {
                                    name: 'CLOSEALT',
                                    size: '2xl'
                                }
                            }
                        },
                        hint: 'ACTIVITIES_E_M_TIP_HINT',
                        placeholders: 'ACTIVITIES_E_M_TIP_PLACEHOLDER'
                    },
                    editor_settings: {}
                },
                reservation_list: ['UI_RESERVATION_SELECTION_NO', 'UI_RESERVATION_SELECTION_YES', 'UI_RESERVATION_SELECTION_RECOMMENDED'],
                activity_list: ['SIGHTSEEING', 'CULTURE', 'HISTORY', 'NATURE', 'ENTERTAINMENT', 'PHYSICAL', 'FOOD_RELATED', 'RELAXED'],
                getting_there_list: [
                    'PLANE_RIDES',
                    'BOAT_RIDES',
                    'FERRY',
                    'TRAIN',
                    'METRO',
                    'TRAMWAY',
                    'BUS',
                    'TAXI',
                    'CAR_RENTAL',
                    'RIDE_SHARING',
                    'RIDE_POOLING',
                    'SCOOTER',
                    'BIKE',
                    'E_BIKE',
                    'WALKING',
                    'HORSE',
                    'CAMEL',
                    'DONKEY',
                    'ELEPHANT'
                ],
                extra_list: ['BOOKING', 'WIKILINK', 'DIRECTION']
            }
        }
    }
};
export default uiStruct;
