import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import ModalContainer, {
    ModalBody,
    ModalFooter,
    ModalCTA,
    ModalButton
} from 'components/blocks/ModalTre';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { BlockHTML } from 'components/blocks/Blocks';
import { PopoverFooterDefault } from 'components/blocks/Popover';
import { handleRowReverse } from 'helpers/FEutils';

const targetElement = document.querySelector('#root');

function InfoModal({
    close,
    setModalIsOpen,
    handleActionBtn,
    globalState: { lang },
    icon = { name: 'MAIL' },
    messagesArr,
    hintObj = translations[lang][
        uiStruct.itinerary.moving_around.uiEdit.modals.vendor.hint
    ],
    actionBtnObj = uiStruct.ui.modals.delete.buttons.action,
    isArray = true
}) {
    const rtl = !!translations[lang].rtl;
    const rtlClass = handleRowReverse(rtl);

    // Copy Below to all modals
    //////////////////////////////////////////////////////////////////////

    // State for triggering animation of modal in and out
    const [transitionState, setTransitionState] = useState('off');
    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    ///////////////////////////////////////////////////////////////////

    const handleActionClick = () => {
        handleActionBtn();
        handleTransitionStateOff(); // important... don't forget to add it in all modals
    };

    return (
        <>
            <ModalContainer
                width='2xl'
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={icon}
                close={true}
                rtl={rtl}
            >
                <ModalBody height='24'>
                    <div className=''>
                        <div className={`${handleRowReverse(rtl).rtl}`}>
                            <div
                                className={`p-6`}
                                style={
                                    rtl
                                        ? {
                                              direction: 'rtl',
                                              textAlign: 'right'
                                          }
                                        : {}
                                }
                            >
                                {isArray ? (
                                    <div>{messagesArr}</div>
                                ) : (
                                    <BlockHTML
                                        className='break-words md:break-normal px-0 sm:px-8 pt-8 text-xs sm:text-base'
                                        html={messagesArr}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </ModalBody>
                {/* <PopoverFooterDefault rounded /> */}
            </ModalContainer>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(InfoModal);
