/* eslint-disable react/jsx-pascal-case */
/* eslint-disable arrow-body-style */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { useState } from 'react';
import { createItineraryDay, deleteItineraryDay } from 'store/actions/experienceDetails/overview';
import SectionItinerarySingle from 'components/sections/SectionItinerarySingle';
import ConfirmModal from 'components/modals/ConfirmModal';
import { Section__Title } from 'components/blocks/Sections';
import { ButtonsAddMain } from 'components/blocks/Buttons';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import OnboardingSection from 'components/specialty/OnboardingSection';
import { MenuRefsHolder } from 'helpers/MenuRefsHolder';
import { useOnlineStatus } from 'helpers/useOnlineStatus';

function SectionItinerary(props) {
    const {
        experienceDetails: { experience, id, type, days },
        globalState: { lang, edit, isFeedbackMode },
        createItineraryDay,
        deleteItineraryDay,
        title = uiStruct.overview.itinerary.uiPreview.headTitle,
        addBtn = uiStruct.overview.itinerary.uiEdit.buttons.addMain,
        publicView = false,
        disableAddBtn = false,
        disableDeletBtn = false,
        isPublishedView = false,
        purchased = false
    } = props;

    const isOnline = useOnlineStatus();

    const hintObj = uiStruct.overview.itinerary.uiPreview.hint;
    const hintObjEmpty = uiStruct.overview.itinerary.uiPreview.hintEmpty;

    const rtl = !!translations[lang].rtl;

    const {
        itinerary: { trip_days: ItineraryDaysList }
    } = experience;
    const [delConfirmModalState, setDelConfirmModalState] = useState(false);
    const [mrObjIndex, setMrObjIndex] = useState(0);
    const hasData = ItineraryDaysList.length;
    const show = edit ? true : !edit && !hasData ? false : true;

    const handleCreateDay = () => {
        createItineraryDay(type);
    };

    const removeModal = (index) => {
        setMrObjIndex(index);
        setDelConfirmModalState(true);
    };

    const confirmRemove = () => {
        deleteItineraryDay(mrObjIndex);
    };

    const buildPanel = (provided) => {
        return (
            <div>
                {ItineraryDaysList.map((mAObj, index, arr) => {
                    const key = `mr${index}`;

                    return (
                        <SectionItinerarySingle
                            purchased={purchased}
                            disableDeletBtn={disableDeletBtn}
                            key={key}
                            index={index}
                            edit={edit}
                            data={mAObj}
                            provided={provided}
                            removeModal={removeModal}
                            experienceId={id}
                            publicView={publicView}
                            isPublishedView={isPublishedView}
                            days={days}
                        />
                    );
                })}

                {edit && !isFeedbackMode && !disableAddBtn && (
                    <div className='flex items-center justify-center w-full'>
                        <ButtonsAddMain
                            disabled={!isOnline}
                            handleClick={handleCreateDay}
                            label={hasData ? translations[lang][addBtn.label_one] : translations[lang][addBtn.label_empty]}
                            rtl={rtl}
                        />
                    </div>
                )}
                {delConfirmModalState && <ConfirmModal setModalIsOpen={setDelConfirmModalState} handleActionBtn={confirmRemove} doubleConfirm={true} />}
            </div>
        );
    };

    return (
        <div className={`w-full ${edit ? 'touch:-mt-16' : 'touch:-mt-28'} touch:py-10`}>
            <div
                id='ovItinerary'
                ref={(ref) => {
                    MenuRefsHolder.setRef(ref);
                }}
            >
                <Section__Title title={translations[lang][title]} edit={edit} hasData={hasData} hintObj={hintObj} isVisible={true} className={'mb-4'} />
                <OnboardingSection visible={hasData} data={hintObjEmpty} />
            </div>
            {buildPanel()}
        </div>
    );
}

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createItineraryDay,
            deleteItineraryDay
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionItinerary);
