import { arrangeArray } from '../common';



export const updateDescriptionBudget = (draft, action) => {
    const { descHtml } = action;
        draft.experience.budget.description_html = descHtml;

    return draft;
};

export const arrangeTipsBudget = (draft, action) => {
    return arrangeArray(
        draft,
        action,
        draft.experience.budget.tips_html
    );
};

export const deleteTipBudget = (draft, action) => {
    const { tipIndex } = action;

    draft.experience.budget.tips_html.splice(tipIndex, 1);

    return draft;
};

export const addTipBudget = (draft, action) => {
    const { dataObj } = action;

    draft.experience.budget.tips_html.push(dataObj);

    return draft;
};

export const updateTipBudget = (draft, action) => {
    const { tipIndex, dataObj } = action;

    draft.experience.budget.tips_html[tipIndex] = {
        ...draft.experience.budget.tips_html[tipIndex],
        ...dataObj,
    };

    return draft;
};

// export const updateBudget = (draft, action) => {
//     const { dataObj } = action;

//     draft.experience.budget.time_range = dataObj;

//     return draft;
// };
