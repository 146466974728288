/* eslint-disable react/jsx-pascal-case */
import { handleRowReverse, urlArrLength } from 'helpers/FEutils';

import CardXlEditDelete from 'components/blocks/CardXlEditDelete';
import { CardXlDragHandleMain, CardXlDragHandleItem } from 'components/blocks/CardXlDrag';

import Icons from 'components/blocks/Icons';
import CollapseButton from 'components/blocks/CollapseButton';
import FeedbackBox from './FeedbackBox';

const CardXl = ({
    objId,
    edit,
    isPrintMode,
    isMarketingMode = false,
    isFeedbackMode,
    children,
    handleDelete,
    handleEdit,
    rtl,
    handleMinimize,
    hidden = false,
    transparent = false,
    nominimize = false,
    isVisible = true,
    handleVisible,
    day,
    hideEye = false,
    size = 'max-w-2xl md:max-w-3xl lg:max-w-4xl xl:max-w-6xl',
    padding = 'md:px-12 pt-8 pb-16 lg:py-16'
}) => {
    return (
        <div className={`relative ${hidden ? 'md:px-12 pt-8 pb-16 lg:py-16' : padding}  ${size} mx-auto`}>
            {/* card */}
            {objId && (
                <FeedbackBox objId={objId} zLevel='' label='Section' posY='bottom-full' labelPosX='left-24' root dot='right-4 top-1 md:top-8 md:right-8' />
            )}

            <div
                className={`relative p-4 md:border-2  ${
                    isPrintMode || isMarketingMode
                        ? 'border-transparent'
                        : hidden
                        ? '-mb-24 lg:-mb-40 border-transparent'
                        : transparent
                        ? 'md:border-transparent'
                        : 'md:border-glass-75 md:bg-glass-50 md:rounded-4xl  md:shadow-cards'
                }`}
            >
                <div
                    className={`relative ${day ? '' : 'p-4 pb-4 md:pb-12 md:px-12'} 
                    ${
                        isPrintMode || isMarketingMode
                            ? ''
                            : hidden
                            ? ''
                            : transparent
                            ? ''
                            : 'rounded-3xl bg-gradient-to-b bg-white shadow-2xl-green-400 md:border-glass-75 md:bg-glass-50'
                    }  `}
                >
                    {' '}
                    {edit && !isFeedbackMode && !isFeedbackMode && (
                        <CardXlEditDelete handleDelete={handleDelete} windowMode rtl={rtl} day={true} className={'z-200'} />
                    )}
                    {edit && !isFeedbackMode && handleVisible && !hideEye && (
                        <CardXlEditDelete handleVisible={handleVisible} isVisible={isVisible} windowMode rtl={rtl} day={true} className={'z-200'} />
                    )}
                    {handleEdit && (
                        <div
                            onClick={handleEdit}
                            className={`focus-outline:none outline-none absolute inset-0  z-100  ${
                                edit ? 'md:-inset-5 border-2 border-transparent hover:bg-green-200 hover:border-green-400 ' : 'hover:bg-green-300 md:-inset-4'
                            }  hover:bg-opacity-10 transition-all ease-in-out duration-300 rounded-3xl  md:rounded-4xl cursor-pointer`}
                        ></div>
                    )}
                    {!nominimize && !isFeedbackMode && (
                        <div className={`absolute transform rounded-full  flex z-100 ${handleRowReverse(rtl).right}-12 top-4`}>
                            {/* {!nominimize && (
                                <button
                                    onClick={handleMinimize}
                                    className={`border-2 p-1  border-transparent rounded-full text-gray-900 opacity-30 hover:opacity-100 focus:outline-none  flex items-center justify-center`}
                                >
                                    <Icons
                                        iName={`ARROW_${
                                            hidden
                                                ? handleRowReverse(rtl).RIGHT
                                                : 'DOWN'
                                        }_CHEVRON`}
                                        size='xl'
                                        iClasses=''
                                    />
                                </button>
                            )} */}
                        </div>
                    )}
                    {/* card container for responsive width*/}
                    <div className={`flex flex-col ${day ? '' : 'xl:max-w-4xl'} mx-auto `}>
                        {children}

                        {!isVisible && (
                            <div className='absolute inset-0 rounded-3xl bg-white bg-opacity-90 z-100'>
                                <div className='w-full h-full flex flex-col justify-center text-black'>
                                    <div className='mx-auto px-8 w-full md:max-w-lg lg:max-w-xl justify-center ma'>
                                        This section will be hidden from the published experience. Click the preview button from the right side menu to see your
                                        experience without this panel.
                                    </div>
                                    {!isFeedbackMode && (
                                        <div className='flex justify-center w-full pt-8'>
                                            <CollapseButton
                                                labelHover='Unhide section'
                                                handleClick={handleVisible}
                                                icon='ri-eye-line text-2xl -ml-0.5'
                                                size='10'
                                                textSize='text-xs'
                                                sizeHover='w-32'
                                                offsetCenter='2'
                                                alwaysOn
                                                padding='px-4'
                                                btnColor='bg-green-400 hover:bg-gray-900 text-green-900'
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const CardXlContent = ({
    tour,
    nohover = false,
    edit,
    isFeedbackMode,
    rtl,
    children,
    handleEdit,
    className,
    color = `bg-kn-primary-754  ${nohover && !edit ? '' : 'hover:bg-green-50'}`,
    padding = 'xl:px-8 py-4 '
}) => {
    return (
        <div
            className={`${tour} relative  transition-colors ease-in-out duration-500 rounded-3xl ${padding} flex flex-col md:flex-row md:${
                handleRowReverse(rtl).flex
            } ${handleRowReverse(rtl).rtl} ${handleRowReverse(rtl).justify} flex-wrap md:flex-nowrap mb-4  ${color} ${className || ''}`}
        >
            {children}
            {edit && !isFeedbackMode && <CardXlEditDelete handleEdit={handleEdit} rtl={rtl} />}
        </div>
    );
};

const CardXlIconTitleList = (props) => {
    const { objId, edit, isFeedbackMode, index, deleteIcon, name, url, rtl, icon, provided = {}, editIcon } = props;
    const urlArr = url.length ? [...url] : [url];
    const handleDelete = () => {
        deleteIcon(index);
    };

    const handleEdit = () => {
        editIcon(index);
    };

    return (
        <li
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={`list-none relative w-full justify-between bg-kn-primary-75 hover:bg-green-100 transition-colors ease-in-out duration-300 rounded-3xl px-4 md:px-8 py-4 flex flex-col md:${
                handleRowReverse(rtl).flex
            } flex-wrap md:flex-nowrap mb-4 `}
        >
            {edit && !isFeedbackMode && (
                <>
                    <CardXlEditDelete handleDelete={handleDelete} handleEdit={handleEdit} rtl={rtl} />
                </>
            )}
            <CardXlDragHandleItem dragOff={!edit || isFeedbackMode} dragProps={provided.dragHandleProps} rtl={rtl} />
            <div
                className={`flex flex-col md:${handleRowReverse(rtl).flex} flex-wrap lg:flex-nowrap  
                            ${urlArrLength(urlArr).toString().length > 35 ? 'md:items-start' : 'md:items-center'}`}
            >
                <div className={`flex items-center flex-shrink-0 ${handleRowReverse(rtl).flex}`}>
                    <div className=''>
                        <Icons iName={icon} size='4xl' iClasses='bg-clip-text text-transparent bg-gradient-to-tr from-green-600 via-green-400 to-blue-500' />
                    </div>
                    <div className='px-6 text-xl font-bold bg-clip-text text-transparent bg-gradient-to-tr from-gray-900 to-green-400 mix-blend-multiply'>
                        {name}
                    </div>
                </div>

                <div className={`flex flex-col text-sm ${handleRowReverse(rtl).flex}`}>
                    {urlArr.length > 0 &&
                        urlArr.map((urlItem, index) => {
                            return (
                                <div key={index} className={`flex flex-nowrap items-center ${handleRowReverse(rtl).flex}`}>
                                    <div className='flex-shrink-0 mx-1.5 text-kn-primary text-lg'>
                                        <Icons size='xl' iName={`${handleRowReverse(rtl).arrowLong}`} />
                                    </div>

                                    <a
                                        className={`break-all hover:text-white hover:bg-kn-primary rounded-full px-4 leading-tight py-2 ${
                                            handleRowReverse(rtl).text
                                        }`}
                                        href={urlItem.url}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        {urlItem.label || urlItem.url}
                                    </a>
                                </div>
                            );
                        })}
                </div>
            </div>
            <FeedbackBox objId={objId} label='Link' />
        </li>
    );
};

export { CardXlContent, CardXlIconTitleList, CardXlDragHandleMain, CardXlDragHandleItem };

export default CardXl;
