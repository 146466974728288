/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Editor from 'components/blocks/Editor';
import {
    ModalFooter,
    ModalCTA,
    ModalButton,
    ModalHelp
} from 'components/blocks/ModalTre';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import GenericSelectList from 'components/blocks/GenericSelectList';
import { BlockInputSingle } from 'components/blocks/Blocks';
import ModalFullscreen, { ModalBody } from 'components/blocks/ModalFullscreen';
import ModalTopBlock from 'components/blocks/ModalTopBlock';
import LayoutDay from 'components/layouts/LayoutDay';
import { MenuRefsHolder } from 'helpers/MenuRefsHolder';
// should be in all modals
const targetElement = document.querySelector('#root');

const dropList = {
    cancellation: { id: 'cancellation', label: 'Cancellation Policy' },
    refund: { id: 'refund', label: 'Refund Policy' }
};

function ItineraryModal({
    index,
    close,
    setModalIsOpen,
    handleActionBtn,
    globalState: { lang },

    icon = uiStruct.itinerary.moving_around.uiEdit.modals.add.icon
}) {
    const rtl = !!translations[lang].rtl;
    // Copy Below to all modals
    //////////////////////////////////////////////////////////////////////

    // State for triggering animation of modal in and out
    const [transitionState, setTransitionState] = useState('off');

    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    ///////////////////////////////////////////////////////////////////

    // Paul stuff Handle Action BTN function

    ///////////////////////////////

    return (
        <>
            <ModalFullscreen
                width='4xl'
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={icon}
                close={close}
                closeButton={true}
                rtl={rtl}
                fullscreen={true}
                // bgColor='bg-gray-100'
            >
                <ModalBody rtlOff>
                    <LayoutDay
                        dayIndex={index}
                        sections={MenuRefsHolder.sectionsMainNav}
                        inModal={true}
                    />
                    <div className='max-w-7xl mx-auto'>
                        {/* <div className='w-full  px-4 md:px-8 lg:px-2 mb-8 mt-4 '>
                            <ModalTopBlock
                                title={`Day ${index + 1}`}
                                subtitle={'Everything you need to know'}
                                text=''
                                rtl={rtl}
                                lang={lang}
                            />
                        </div> */}
                        <div className='flex flex-col-reverse portrait:flex-col-reverse lg:flex-row gap-16 lg:gap-4 xl:gap-8 px-8 md:px-10 lg:px-4'>
                            <div className='w-full  min-h-96'></div>
                        </div>
                    </div>
                </ModalBody>
            </ModalFullscreen>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(ItineraryModal);
