import * as types from 'store/actions/types';

export function createAccommodation(dataObj) {
    return (dispatch) => {
        dispatch({
            type: types.ADD_ACCOMMODATION_OVERVIEW,
            dataObj,
        });
    };
}

export function updateAccommodation(dataObj, accommIndex) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_ACCOMMODATION_OVERVIEW,
            dataObj,
            accommIndex
        });
    };
}

export function arrangeAccommodation(source, destination) {
    return (dispatch) => {
        dispatch({
            type: types.ARRANGE_ACCOMMODATION_OVERVIEW,
            source,
            destination
        });
    };
}

export function deleteAccommodation(accommIndex) {
    return (dispatch) => {
        dispatch({
            type: types.DELETE_ACCOMMODATION_OVERVIEW,
            accommIndex
        });
    };
}

export function deleteCircleAccom(accommIndex) {
    return (dispatch) => {
        dispatch({
            type: types.DELETE_MAP_CIRCLE_ACCOMMODATION_OVERVIEW,
            accommIndex
        });
    };
}

export function addCircleAccom(dataObj) {
    return (dispatch) => {
        dispatch({
            type: types.ADD_MAP_CIRCLE_ACCOMMODATION_OVERVIEW,
            dataObj
        });
    };
}

export function updateCaptionSlideActivityAccom(slideIndex, caption) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_ACCOMMODATION_GALLERY_OVERVIEW,
            slideIndex,
            caption
        });
    };
}

export function updateGalleryActivityAccom( galleryArray) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_GALLERY_ACTIVITY_ACCOM,
            galleryArray,
        });
    };
}
