/* This example requires Tailwind CSS v2.0+ */

import { useEffect, useRef, useState } from 'react';
import ReactDom from 'react-dom';

import { connect } from 'react-redux';
import translations from 'constants/translations';
import Icons from 'components/blocks/Icons';

import { handleRowReverse } from 'helpers/FEutils';
import uiStruct from 'constants/uiStruct';
import Popover, { PopoverBody, PopoverFooterDefault } from 'components/blocks/Popover';
import { BlockHTML } from 'components/blocks/Blocks';
import { ButtonsClose } from 'components/blocks/Buttons';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const modalRoot = document.getElementById('fullmodal-root');
const targetElement = document.getElementById('root');

const ModalFullscreen = ({
    zIndex = null,
    showModal = true,
    handleOpen,
    handleTransition,
    animationCss,
    children,
    close = true,
    closeButton = false,
    globalState: { lang },
    // help = null,
    bgColor = 'bg-white',
    dataLoading
}) => {
    const modalRef = useRef(null);

    const rtl = !!translations[lang].rtl;
    const style = zIndex ? { display: showModal ? '' : 'none', zIndex } : { display: showModal ? '' : 'none' };

    useEffect(() => {
        handleTransition();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

    useEffect(() => {
        // modalRef.current.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataLoading]);

    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // useEffect(() => {
    //     document.body.style.position = 'fixed';
    //     document.body.style.top = `-${window.scrollY}px`;
    //     return () => {
    //         const scrollY = document.body.style.top;
    //         document.body.style.position = '';
    //         document.body.style.top = '';
    //         window.scrollTo(0, parseInt(scrollY || '0') * -1);
    //     };
    // }, []);

    return ReactDom.createPortal(
        <>
            <div className='z-1000 fixed inset-0 flex justify-center ' style={style} id='xxx'>
                <div onMouseDown={handleOpen} className={`fixed inset-0 ${bgColor} transform transition duration-300 ease-in-out ${animationCss.glass}`}></div>
                {close && (
                    /* The close x button */
                    <ButtonsClose handleClose={handleOpen} closeButton={closeButton} rtl={rtl} />
                )}
                {/* The glass window */}

                <div id='helloWorld' ref={modalRef} className={`transform transition duration-300 ease-in-out  w-full ${animationCss.glass} overflow-y-scroll`}>
                    {children}
                </div>
            </div>
        </>,
        modalRoot
    );
};

const ModalButtonNS = ({ globalState: { lang }, label, color, icon, handleClick }) => {
    const rtl = !!translations[lang].rtl;
    const colorObj = uiStruct.ui.styles.buttons;
    const colorClass = color ? colorObj[color] ?? colorObj.green : colorObj.default;

    const gapClass = icon && label ? 'gap-2' : '';

    return (
        <button
            onClick={handleClick}
            className={`flex ${
                handleRowReverse(rtl).flex
            } items-center  justify-center ${gapClass} h-12 rounded-xl hover:bg-gray-900 hover:text-white w-full min-w-max focus:outline-none ${colorClass}`}
        >
            {icon && <Icons iName={icon.name} size={icon.size} iClasses='font-normal' />}
            <div className='font-medium'>{icon ? label || '' : label || 'undefined name'}</div>
        </button>
    );
};

const ModalHelpNS = ({ isHtml, helpText, globalState: { lang }, hintMain = null }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div className='flex flex-shrink-0 flex-col-reverse sm:flex-row-reverse gap-2'>
            <Popover icon='QUESTION' color='help' isOpen={isOpen} handleOpen={toggleOpen} hintMain={hintMain}>
                {helpText && (
                    <PopoverBody>
                        {isHtml ? <BlockHTML className='break-words md:break-normal px-0 sm:px-8 pt-8 text-xs sm:text-base' html={helpText} /> : helpText}
                    </PopoverBody>
                )}
                <PopoverFooterDefault />
            </Popover>
        </div>
    );
};

const ModalCommentNS = ({ isHtml, helpText, globalState: { lang }, hintMain = null }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div className='flex flex-shrink-0 flex-col-reverse sm:flex-row-reverse gap-2 bg-red-100'>
            <Popover icon='QUESTION' color='help' isOpen={isOpen} handleOpen={toggleOpen} hintMain={hintMain}>
                <PopoverBody>
                    {isHtml ? <BlockHTML className='break-words md:break-normal px-0 sm:px-8 pt-8 text-xs sm:text-base' html={helpText} /> : helpText}
                </PopoverBody>

                <PopoverFooterDefault />
            </Popover>
        </div>
    );
};

const ModalCTANS = ({ children, globalState: { lang } }) => {
    const rtl = !!translations[lang].rtl;
    return <div className={`w-full flex flex-col justify-end sm:${handleRowReverse(rtl).flex} gap-2 sm:items-center`}>{children}</div>;
};

const ModalFooterNS = ({ children, globalState: { lang } }) => {
    const rtl = !!translations[lang].rtl;
    return <div className={`flex flex-col gap-2 justify-between sm:${handleRowReverse(rtl).flex} sm:items-center`}>{children}</div>;
};

const ModalBodyNS = ({
    rtlOff,

    children,
    globalState: { lang }
}) => {
    const rtl = !!translations[lang].rtl;
    const editorClassRtl = rtlOff ? '' : handleRowReverse(rtl).rtl;
    return <div className={`${editorClassRtl} lg:px-12 py-16`}>{children}</div>;
};

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

const ModalButton = connect(mapStateToProps)(ModalButtonNS);
const ModalBody = connect(mapStateToProps)(ModalBodyNS);
const ModalFooter = connect(mapStateToProps)(ModalFooterNS);
const ModalCTA = connect(mapStateToProps)(ModalCTANS);
const ModalHelp = connect(mapStateToProps)(ModalHelpNS);
const ModalComment = connect(mapStateToProps)(ModalCommentNS);

export { ModalButton, ModalBody, ModalFooter, ModalCTA, ModalHelp, ModalComment };

export default connect(mapStateToProps, null)(ModalFullscreen);
