import { useState, useEffect } from 'react';
import EmptyModal from 'components/modals/EmptyModal';
import InfoModal from 'components/modals/InfoModal';
import translations from 'constants/translations';

function SubmitExperienceDialogs({
    handleCompleteSubmit,
    experience,
    handleClose,
    lang,
    completion:completionInput = null,
    status
}) {
    const [infoModalStatus, setInfoModalStatus] = useState(false);
    const [submitModalState, setSubmitModalState] = useState(false);
    const [infoDisplayData, setInfoDisplayData] = useState({
        isArray: true,
        message: ''
    });

    const closeAll = () => {
        handleClose({
            status: false
        });
    };
    const showSubmitMessage = () => {
        const msgs = [];
        switch (status) {
            case 'submit':
                msgs[0] = translations[lang].EXP_LIST_SUBMIT;
                msgs[1] = translations[lang].EXP_LIST_SUBMIT_PS;
                break;
            case 'approved':
                msgs[0] = translations[lang].EXP_LIST_SUBMIT_APPROVED;
                msgs[1] = translations[lang].EXP_LIST_SUBMIT_APPROVED_PS;
                break;
            default:
        }
        return msgs;
    };
    useEffect(() => {
        const {
            tags_list: tags,
            cats_list: cats,

            experience: { completion }
        } = experience;

        const completionMessagesArray = [];
        const totalComplete = Number(completionInput) || completion;

        if (totalComplete !== 100) {
            completionMessagesArray.push(
                <div className='mb-2' key='completionMessages-finstatus'>
                    Unfinished experience
                </div>
            ); // Amine text
            if (!cats || !cats.length) {
                completionMessagesArray.push(
                    <div className='mb-2' key='completionMessages-tags'>
                        Categories/Interests need completing
                    </div>
                ); // Amine text
            }
            if (!tags || !tags.length) {
                completionMessagesArray.push(
                    <div className='mb-2' key='completionMessages-cats'>
                        Tags need completing
                    </div>
                ); // Amine text
            }
        }

        if (completionMessagesArray.length) {
            setInfoDisplayData({
                isArray: true,
                message: completionMessagesArray
            });
            setInfoModalStatus(true);
        } else {
            setSubmitModalState(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [experience]);

    return (
        <>
            {submitModalState && (
                <EmptyModal
                    setModalIsOpen={closeAll}
                    handleActionBtn={handleCompleteSubmit}
                >
                    <div>{showSubmitMessage()[0]}</div>

                    <div className='mt-8'>
                        <div className='rounded-full px-4 py-1 inline-block bg-kn-yellow font-bold italic'>
                            Note
                        </div>
                        <div className='rounded-xl bg-gray-100 py-8 text-sm mt-2 px-8'>
                            {showSubmitMessage()[1]}
                        </div>
                    </div>
                </EmptyModal>
            )}
            {infoModalStatus && (
                <InfoModal
                    setModalIsOpen={closeAll}
                    messagesArr={infoDisplayData.message}
                    isArray={infoDisplayData.isArray}
                    icon={{ name: 'UPLOAD' }}
                />
            )}
        </>
    );
}

export default SubmitExperienceDialogs;
