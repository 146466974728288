export const minsToTime = (n, unformatted = true) => {
    const num = n;
    const hours = num / 60;
    let rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    const tmDay = n > 719 && n < 1440 ? 'PM' : 'AM';

    if (!unformatted) {
        return { h: rhours, m: rminutes };
    }

    rminutes = rminutes < 10 ? `0${rminutes}` : rminutes;
    rhours = rhours > 12 ? rhours - 12 : rhours;

    return `${rhours}:${rminutes}${tmDay}`;
};

export const minsToDuration = (n, showHoursTxt = true) => {
    const { h, m } = minsToTime(n, false);
    let str = '';

    if (m > 0) {
        str = `${h > 0 ? `${h} h${h > 1 ? `` : ``}` : ``}`;
        str = `${str} ${m > 0 ? `${m} ${m > 1 ? `` : ``}` : ``}`;
    } else {
        str = showHoursTxt
            ? `${h > 0 ? `${h} h${h > 1 ? `` : ``}` : ``}`
            : `${h}`;
    }
    if (h == 0) {
        str = `${m > 0 ? `${m} mins ${m > 1 ? `` : ``}` : ``}`;
    }

    return str;
};

export const addCurrency = (n, showSymbol = true, symbol = '$US') => {
    return `${n} ${symbol} `;
};
