import React, { useState } from 'react';
// import { ButtonsHelp } from 'components/blocks/Buttons';
import { connect } from 'react-redux';
import translations from 'constants/translations';
// import HelpModal from 'components/modals/HelpModal';
import { handleRowReverse } from 'helpers/FEutils';

const SectionTitle = ({
    hasData = null,
    title,
    // hintObj = null,
    globalState: { lang, edit, isFeedbackMode },
    handleVisible,
    isVisible,
    className = 'mb-4 lg:mb-0'
}) => {
    const rtl = !!translations[lang].rtl;
    // const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <div
                className={`${
                    !hasData ? 'opacity-30' : ''
                } z-50 relative max-w-2xl md:max-w-2xl xl:max-w-4xl  mx-auto flex ${
                    handleRowReverse(rtl).flex
                } items-center justify-center px-8 md:px-8 lg:px-4 ${className} `}
            >
                {title && (
                    <div
                        className={` text-center w-max  font-bold leading-tight bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500 py-8 -mb-8 ${
                            !isVisible
                                ? 'opacity-50 text-2xl'
                                : 'text-4xl md:text-5xl'
                        }`}
                    >
                        {title}
                    </div>
                )}
                {handleVisible && edit && !isFeedbackMode ? (
                    <div
                        className={`absolute transform rounded-full flex  z-50 left-1/2 -top-4 -translate-x-1/2 `}
                    >
                        <button
                            onClick={handleVisible}
                            className={`border-2 p-1 bg-gray-300 w-8 h-8 border-transparent rounded-full text-gray-900  hover:opacity-100 outline-none focus:outline-none  flex items-center justify-center hover:bg-gray-900 hover:text-white transition-colors duration-300`}
                        >
                            {' '}
                            <i
                                className={`${
                                    isVisible
                                        ? 'ri-eye-line'
                                        : 'ri-eye-close-line'
                                } text-xl`}
                            ></i>
                        </button>
                    </div>
                ) : null}
            </div>
        </>
    );
};

const Section__ListContainer = (props) => {
    return (
        <div>
            <ul>{props.children}</ul>
        </div>
    );
};

const Section__SingleContainer = (props) => {
    return <div>{props.children}</div>;
};

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

const Section__Title = connect(mapStateToProps)(SectionTitle);

export { Section__Title, Section__ListContainer, Section__SingleContainer };
