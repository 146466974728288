import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ModalContainer, { ModalFooter, ModalCTA, ModalButton, ModalHelp } from 'components/blocks/ModalTre';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import MapBoxSearch from 'components/map/MapBoxSearch';
import { parseDestinationData, makeDesinationLabel } from 'helpers/destinations';
import Icons from '../blocks/Icons';
import ModalTopBlock from 'components/blocks/ModalTopBlock';
import ModalFullscreen, { ModalBody } from 'components/blocks/ModalFullscreen';

// should be in all modals
const targetElement = document.querySelector('#root');

const MapModal = ({
    close,
    setModalIsOpen,
    handleActionBtn,
    globalState: { lang },
    icon = uiStruct.itinerary.activities.uiEdit.modals.map.icon,
    hintObj = uiStruct.itinerary.activities.uiEdit.modals.map.hint,
    actionBtnObj = uiStruct.ui.modals.editor.buttons.action,
    cancelBtnObj = uiStruct.ui.modals.editor.buttons.cancel,
    showModal,
    destinations
}) => {
    //

    const rtl = !!translations[lang].rtl;

    //
    const [destinationsObj, setDestinationsObj] = useState(JSON.parse(JSON.stringify(destinations)));
    const [tmpDestObj, setTmpDestObj] = useState(null);
    const [clearInput, setClearInput] = useState(false);
    const [errorObj, setErrorObj] = useState({
        error: false,
        msg: ''
    });
    const [warningMsg, setWarningMsg] = useState({
        warning: false,
        msg: ''
    });
    // Warning removed Amine
    // Copy Below to all modals
    //////////////////////////////////////////////////////////////////////

    // State for triggering animation of modal in and out
    const [transitionState, setTransitionState] = useState('off');

    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    const handleActionClick = () => {
        handleActionBtn(destinationsObj);
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    const handleAddDestination = (destObj) => {
        // console.log('destObj', destObj);
        const tmpDest = parseDestinationData(destObj[0]);

        if (!tmpDest.country) {
            setErrorObj({
                error: true,
                msg: 'No countries associated with this place'
            });
        } else {
            setErrorObj({
                error: false,
                msg: ''
            });

            const previousState = [...destinationsObj];

            // find if any entry has current added country

            const findCountryCode = (array) => array.some((d) => d.countryCode === tmpDest.countryCode);
            const countryIndex = previousState.findIndex(findCountryCode);
            if (countryIndex != -1) {
                previousState[countryIndex].push(tmpDest);
            } else {
                // const tmpHolder = { ...tmpDest };
                const tmpHolder = JSON.parse(JSON.stringify(tmpDest));
                tmpHolder.administrative_area_level_1 = null;
                tmpHolder.place = null;
                tmpHolder.address = null;
                // tmpHolder.geometry = null;
                if (tmpDest.place || tmpDest.address || tmpDest.administrative_area_level_1) {
                    previousState.push([tmpHolder, tmpDest]);
                } else previousState.push([tmpHolder]);
            }

            setDestinationsObj(() => {
                return previousState;
            });
            setClearInput((clearInput) => !clearInput);
        }
    };

    // const addDestination = (destObj) => {
    //     if (tmpDestObj) {
    //         setDestinationsObj(() => {
    //             return destinationsObj.concat(tmpDestObj);
    //         });
    //         setTmpDestObj(null);
    //         setClearInput((clearInput) => !clearInput);
    //     }
    // };
    const handleClearInput = () => {
        setTmpDestObj(null);
        setClearInput((clearInput) => !clearInput);
        setErrorObj({
            error: false,
            msg: ''
        });
    };

    const removeDestination = (groupIndex, index) => {
        const tmpArr = JSON.parse(JSON.stringify(destinationsObj));

        tmpArr[groupIndex].splice(index, 1);
        setDestinationsObj(tmpArr);
    };

    const removeGroup = (index) => {
        const tmpArr = JSON.parse(JSON.stringify(destinationsObj));

        setWarningMsg({
            ...warningMsg,
            warning: true
        });
        tmpArr.splice(index, 1);
        setDestinationsObj(tmpArr);
    };

    const buildDesinationDisplay = () => {
        return destinationsObj.map((groupDest, index) => {
            return (
                <div key={index} className='flex-none  rounded-xl bg-green-50 p-3 relative flex flex-col text-sm hover:bg-green-100 transition duration-200'>
                    <div className='flex items-center justify-between gap-2'>
                        <div className=''>{makeDesinationLabel(groupDest[0], lang).country}</div>
                        <button onClick={() => removeGroup(index)} className='focus:outline-none text-green-600 hover:text-gray-900 duration-200 transition'>
                            <Icons iName='CLOSEALT' iClasses='text-sm' size='lg' />
                        </button>
                    </div>
                    {groupDest.length > 1 && (
                        <div className='flex flex-wrap items-center gap-2 w-64 mt-2'>
                            {groupDest.map((dest, destIndex) => {
                                return (
                                    (dest.place || dest.address || dest.administrative_area_level_1) && (
                                        <button
                                            onClick={() => removeDestination(index, destIndex)}
                                            className='focus:outline-none flex-shrink-0 justify-between rounded-full bg-green-400 px-2 flex items-center text-sm group hover:bg-gray-900 transition duration-200'
                                        >
                                            <span className='px-2 group-hover:text-white duration-200 transition'>{makeDesinationLabel(dest, lang).place}</span>
                                            <span className='group-hover:text-white duration-200 transition'>
                                                <Icons iName='CLOSEALT' iClasses='text-sm' size='lg' />
                                            </span>
                                        </button>
                                    )
                                );
                            })}
                        </div>
                    )}
                </div>
            );
        });
    };

    return (
        <>
            <ModalFullscreen
                width='4xl'
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={icon}
                close={close}
                closeButton={true}
                rtl={rtl}
                fullscreen={true}
            >
                <ModalBody rtlOff>
                    <div className='xl:px-32 '>
                        <div className='w-full  px-4 md:px-8 lg:px-2 mb-8 mt-4'>
                            <ModalTopBlock
                                title='Destination'
                                subtitle='Add'
                                text='Let us help you find your destination(s). Search for a country, region or place and select it from the list. Add as many as you want. Remove by clicking on the x.'
                                rtl={rtl}
                                lang={lang}
                            />
                        </div>

                        <div className='flex items-center justify-center w-full px-8 md:px-8 lg:px-2 mb-8 mt-4'>
                            <MapBoxSearch
                                handleAddDestination={handleAddDestination}
                                // handleMarkerUpdate={handleMarkerUpdate}
                                // handleAddressUpdate={handleAddressUpdate}
                                clearInput={clearInput}
                            />
                        </div>

                        <div className='mx-auto flex justify-center mt-8 h-8 px-8 md:px-8 lg:px-2 mb-8' style={{ color: 'red' }}>
                            {errorObj.error ? errorObj.msg : warningMsg.warning ? warningMsg.msg : ''}
                        </div>

                        <div className='flex gap-4 justify-center mx-auto flex-wrap px-8 md:px-8 lg:px-2 mb-8 mt-4'>{buildDesinationDisplay()}</div>
                        <div className='mt-8 mb-8 px-4 md:px-10'>
                            <ModalFooter>
                                {hintObj && <ModalHelp helpText={translations[lang][hintObj]} isHtml />}
                                <ModalCTA>
                                    <ModalButton
                                        handleClick={handleTransitionStateOff}
                                        label={translations[lang][cancelBtnObj.label]}
                                        color={cancelBtnObj.color}
                                        icon={cancelBtnObj.icon}
                                    />
                                    <ModalButton
                                        handleClick={handleActionClick}
                                        label={translations[lang][actionBtnObj.label]}
                                        color={actionBtnObj.color}
                                        icon={actionBtnObj.icon}
                                    />
                                </ModalCTA>
                            </ModalFooter>
                        </div>
                    </div>
                </ModalBody>
            </ModalFullscreen>
        </>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(MapModal);
