import { arrangeArray } from '../common';

export const arrangeTipsOverview = (draft, action) => {
    return arrangeArray(draft, action, draft.experience.tips_html);
};

export const deleteTipOverview = (draft, action) => {
    const { tipIndex } = action;

    draft.experience.tips_html.splice(tipIndex, 1);

    return draft;
};

export const deleteTipsOverview = (draft, action) => {
    // const { tipIndex } = action;

    draft.experience.tips_html = [];

    return draft;
};

export const addTipOverview = (draft, action) => {
    const { dataObj } = action;

    draft.experience.tips_html.push(dataObj);

    return draft;
};

export const updateTipOverview = (draft, action) => {
    const { tipIndex, dataObj } = action;

    draft.experience.tips_html[tipIndex] = {
        ...draft.experience.tips_html[tipIndex],
        ...dataObj,
    };

    return draft;
};
