/* eslint-disable no-param-reassign */
/* eslint-disable max-len */

import { produce } from 'immer';
// import defaultExperience from '_dummy/dummyExperience';
import * as types from 'store/actions/types';

import {
    addRef,
    addComment,
    updateComment,
    deleteComment
} from './experienceDetails/feedback';

import {
    setExperienceSingle,
    setExperienceSinglePublished,
    setExperienceSinglePending,
    setExperinceRejected,
    setUpdateExperiencePending,
    setUpdateExperienceFufilled,
    setUpdateTags,
    setUpdateCats,
    setUpdateGuideIncludes,
    setUpdateBudget,
    setExperienceSinglePurchased
} from './experienceDetails/master/singleExperience';

import {
    updateTypeMovingAroundOverview,
    updateDescriptionMovingAroundOverview,
    updateTipMovingAroundOverview,
    addTipMovingAroundOverview,
    deleteTipMovingAroundOverview,
    arrangeTipsMovingAroundOverview,
    addVendorMovingAroundOverview,
    updateVendoeMovingAroundOverview,
    deletVendorMovingAroundOverview,
    arrangeVendorsMovingAroundOverview,
    arrangeMovingAroundOverview,
    deleteMovingAroundOverview,
    addMovingAroundOverview
} from './experienceDetails/overview/movingAround';
import {
    updateTipOverview,
    addTipOverview,
    deleteTipOverview,
    deleteTipsOverview,
    arrangeTipsOverview
} from './experienceDetails/overview/tips';
import {
    updateDescIntroOverview,
    updateTitleIntroOverview,
    updateImageIntroOverview
} from './experienceDetails/overview/intro';

import {
    updateTypeMovingAround,
    updateDescriptionMovingAround,
    updateTipMovingAround,
    addTipMovingAround,
    deleteTipMovingAround,
    arrangeTipsMovingAround,
    addVendorMovingAround,
    updateVendoeMovingAround,
    deletVendorMovingAround,
    arrangeVendorsMovingAround,
    arrangeMovingAround,
    deleteMovingAround,
    addMovingAround
} from './experienceDetails/day/movingAround';

import {
    deleteDayItinerary,
    addDayItinerary,
    arrangeDaysItinerary
} from './experienceDetails/overview/itinerary';

import {
    createWhatYouNeed,
    updateDescriptionCheckList,
    updateCheckList,
    deletWhatYouNeed
} from './experienceDetails/day/whatYouNeed';

import {
    updateTipActivities,
    addTipActivities,
    deleteTipActivities,
    arrangeTipsActivities,
    addActivity,
    arrangeActivities,
    deleteActivity,
    updateActivity,
    updateGalleryActivity,
    updateCaptionSlideActivity,
    updateDescActivities,
    updateMapActivity,
    arrangeVendorActivity,
    deleteVendorActivity,
    updateVendorActivity,
    addVendorActivity
} from './experienceDetails/day/activities';

import {
    updateImageIntro,
    updateDescIntro,
    updateTitleIntro
} from './experienceDetails/day/intro';

import {
    updateDescriptionGettingThere,
    updateTipGettingThere,
    addTipGettingThere,
    deleteTipGettingThere,
    arrangeTipsGettingThere,
    arrangeVendorsGettingThere,
    deletVendorGettingThere,
    updateVendoeGettingThere,
    addVendorGettingThere,
    updateEntryGettingThere
} from './experienceDetails/overview/gettingThere';

import {
    updateDescriptionDestinations,
    arrangeDestinations,
    deleteDestination,
    updateDestinations,
    updatePlacePlacesList,
    arrangeTipsDestinations,
    deleteTipsDestinations,
    addTipDestinations,
    updateTipDestinations
} from './experienceDetails/overview/destinations';

import {
    updateDescriptionAccommodation,
    arrangeTipsAccommodation,
    deleteTipsAccommodation,
    addTipAccommodation,
    updateTipAccommodation,
    createAccommodation,
    updateAccommodation,
    arrangeAccommodation,
    deleteAccommodation,
    addCircleAccommodation,
    deleteACircleAccommodation,
    updateAccomGalleryCaption,
    updateGalleryActivityAccom
} from './experienceDetails/overview/accommodation';

import {
    updateDescriptionCurrency,
    arrangeTipsCurrency,
    deleteTipCurrency,
    addTipCurrency,
    updateTipCurrency,
    addCurrencyDetails,
    deleteCurrencyDetails,
    updateCurrencyDetails,
    updateCurrencyVendors,
    arrangeCurrencyVendors
} from './experienceDetails/overview/currency';
import {
    updateDescriptionGoTime,
    arrangeTipsGoTime,
    deleteTipGoTime,
    addTipGoTime,
    updateTipGoTime,
    updateGoTime
} from './experienceDetails/overview/timeToGo';
import {
    updateDescriptionBudget,
    arrangeTipsBudget,
    deleteTipBudget,
    addTipBudget,
    updateTipBudget
} from './experienceDetails/overview/budget';

import { updateFeatureImage } from './experienceDetails/overview/sidebar';

import {
    clearExperience,
    setExperienceUpdated
} from './experienceDetails/general';

import {
    updateMarketingGalleryCaption,
    updateMarketingGallery,
    updateMarketingSectionTitle,
    updateMarketingIncludes,
    updateMarketingPolicies,
    updateTipMarketing,
    addTipMarketing,
    deletTipMarketing,
    arrangTipsMarketing
} from './experienceDetails/marketing/marketing';
import { changeVisibility } from 'store/reducers/experienceDetails/common';

const initialState = {
    invalid: false,
    loading: true,
    updated: false,
    saving: false,
    ioStatus: {
        feedback: {
            saving: false,
            updated: false,
            loading: false
        },
        experience: {
            saving: false,
            updated: false,
            loading: false,
            invalid: false
        }
    },
    experience: {},
    experience_refs: {}
};

function createReducer(initialState, handlers) {
    return produce((draft = initialState, action) => {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](draft, action);
        } else {
            return draft;
        }
    });
}
export default createReducer(initialState, {
    [types.UPDATE_TYPE_MOVING_AROUND]: updateTypeMovingAround,
    [types.UPDATE_DESCRIPTION_MOVING_AROUND]: updateDescriptionMovingAround,
    [types.UPDATE_TIP_MOVING_AROUND]: updateTipMovingAround,
    [types.ADD_TIP_MOVING_AROUND]: addTipMovingAround,
    [types.DELETE_TIP_MOVING_AROUND]: deleteTipMovingAround,
    [types.ARRANGE_TIPS_MOVING_AROUND]: arrangeTipsMovingAround,
    [types.ADD_VENDOR_MOVING_AROUND]: addVendorMovingAround,
    [types.UPDATE_VENDOR_MOVING_AROUND]: updateVendoeMovingAround,
    [types.DELETE_VENDOR_MOVING_AROUND]: deletVendorMovingAround,
    [types.ARRANGE_VENDORS_MOVING_AROUND]: arrangeVendorsMovingAround,
    [types.ARRANGE_MOVING_AROUND]: arrangeMovingAround,
    [types.ADD_MOVING_AROUND]: addMovingAround,
    [types.DELETE_MOVING_AROUND]: deleteMovingAround,
    [types.UPDATE_TITLE_INTRO_OVERVIEW]: updateTitleIntroOverview,
    [types.UPDATE_DESCRIPTION_INTRO_OVERVIEW]: updateDescIntroOverview,
    [types.UPDATE_IMAGE_INTRO_OVERVIEW]: updateImageIntroOverview,
    [types.UPDATE_TIP_OVERVIEW]: updateTipOverview,
    [types.ADD_TIP_OVERVIEW]: addTipOverview,
    [types.DELETE_TIP_OVERVIEW]: deleteTipOverview,
    [types.DELETE_TIPS_OVERVIEW]: deleteTipsOverview,
    [types.ARRANGE_TIPS_OVERVIEW]: arrangeTipsOverview,
    [types.UPDATE_TYPE_MOVING_AROUND_OVERVIEW]: updateTypeMovingAroundOverview,
    [types.UPDATE_DESCRIPTION_MOVING_AROUND_OVERVIEW]: updateDescriptionMovingAroundOverview,
    [types.UPDATE_TIP_MOVING_AROUND_OVERVIEW]: updateTipMovingAroundOverview,
    [types.ADD_TIP_MOVING_AROUND_OVERVIEW]: addTipMovingAroundOverview,
    [types.DELETE_TIP_MOVING_AROUND_OVERVIEW]: deleteTipMovingAroundOverview,
    [types.ARRANGE_TIPS_MOVING_AROUND_OVERVIEW]: arrangeTipsMovingAroundOverview,
    [types.ADD_VENDOR_MOVING_AROUND_OVERVIEW]: addVendorMovingAroundOverview,
    [types.UPDATE_VENDOR_MOVING_AROUND_OVERVIEW]: updateVendoeMovingAroundOverview,
    [types.DELETE_VENDOR_MOVING_AROUND_OVERVIEW]: deletVendorMovingAroundOverview,
    [types.ARRANGE_VENDORS_MOVING_AROUND_OVERVIEW]: arrangeVendorsMovingAroundOverview,
    [types.ARRANGE_MOVING_AROUND_OVERVIEW]: arrangeMovingAroundOverview,
    [types.ADD_MOVING_AROUND_OVERVIEW]: addMovingAroundOverview,
    [types.DELETE_MOVING_AROUND_OVERVIEW]: deleteMovingAroundOverview,
    [types.DELETE_DAY_ITINERARY]: deleteDayItinerary,
    [types.ADD_DAY_ITINERARY]: addDayItinerary,
    [types.ARRANGE_DAYS_ITINERARY]: arrangeDaysItinerary,
    [types.CREATE_WHAT_YOU_NEED]: createWhatYouNeed,
    [types.UPDATE_DESCRIPTION_CHECKLIST]: updateDescriptionCheckList,
    [types.UPDATE_CHECKLIST_LIST]: updateCheckList,
    [types.DELETE_WHAT_YOU_NEED]: deletWhatYouNeed,
    [types.UPDATE_TIP_ACTIVITIES]: updateTipActivities,
    [types.ADD_TIP_ACTIVITIES]: addTipActivities,
    [types.DELETE_TIP_ACTIVITIES]: deleteTipActivities,
    [types.ARRANGE_TIPS_ACTIVITIES]: arrangeTipsActivities,
    [types.ADD_ACTIVITY]: addActivity,
    [types.ARRANGE_ACTIVITY]: arrangeActivities,
    [types.DELETE_ACTIVITY]: deleteActivity,
    [types.UPDATE_ACTIVITY]: updateActivity,
    [types.UPDATE_GALLERY_ACTIVITY]: updateGalleryActivity,
    [types.UPDATE_GALLERY_CAPTION_ACTIVITY]: updateCaptionSlideActivity,
    [types.UPDATE_CAPTION_SLIDE_ACTIVITY]: updateCaptionSlideActivity,
    [types.UPDATE_DESCRIPTION_ACTIVITIES]: updateDescActivities,
    [types.UPDATE_MAP_DATA_ACTIVITY]: updateMapActivity,

    [types.ARRANGE_VENDORS_ACTIVITIES]: arrangeVendorActivity,
    [types.DELETE_VENDOR_ACTIVITIES]: deleteVendorActivity,
    [types.UPDATE_VENDOR_ACTIVITIES]: updateVendorActivity,
    [types.ADD_VENDOR_ACTIVITIES]: addVendorActivity,

    [types.UPDATE_IMAGE_INTRO]: updateImageIntro,
    [types.UPDATE_DESCRIPTION_INTRO]: updateDescIntro,
    [types.UPDATE_TITLE_INTRO]: updateTitleIntro,
    [types.UPDATE_DESCRIPTION_GETTING_THERE]: updateDescriptionGettingThere,

    [types.UPDATE_TIP_GETTING_THERE]: updateTipGettingThere,
    [types.ADD_TIP_GETTING_THERE]: addTipGettingThere,
    [types.DELETE_TIP_GETTING_THERE]: deleteTipGettingThere,
    [types.ARRANGE_TIPS_GETTING_THERE]: arrangeTipsGettingThere,

    [types.ARRANGE_VENDORS_GETTING_THERE]: arrangeVendorsGettingThere,
    [types.DELETE_VENDOR_GETTING_THERE]: deletVendorGettingThere,
    [types.UPDATE_VENDOR_GETTING_THERE]: updateVendoeGettingThere,
    [types.ADD_VENDOR_GETTING_THERE]: addVendorGettingThere,
    [types.UPDATE_ENTRY_GETTING_THERE]: updateEntryGettingThere,
    [types.UPDATE_DESCRIPTION_DESTINATIONS_OVERVIEW]: updateDescriptionDestinations,
    [types.ARRANGE_DESTINATIONS_OVERVIEW]: arrangeDestinations,
    [types.DELETE_DESTINATIONS_OVERVIEW]: deleteDestination,
    [types.UPDATE_DESTINATIONS_OVERVIEW]: updateDestinations,
    [types.UPDATE_DESTINATIONS_PLACES_LIST]: updatePlacePlacesList,
    [types.ARRANGE_TIPS_DESTINATIONS_OVERVIEW]: arrangeTipsDestinations,
    [types.DELETE_TIP_DESTINATIONS_OVERVIEW]: deleteTipsDestinations,
    [types.ADD_TIP_DESTINATIONS_OVERVIEW]: addTipDestinations,
    [types.UPDATE_TIP_DESTINATIONS_OVERVIEW]: updateTipDestinations,

    [types.UPDATE_DESCRIPTION_ACCOMMODATION_OVERVIEW]: updateDescriptionAccommodation,
    [types.ARRANGE_TIPS_ACCOMMODATION_OVERVIEW]: arrangeTipsAccommodation,
    [types.DELETE_TIP_ACCOMMODATION_OVERVIEW]: deleteTipsAccommodation,
    [types.ADD_TIP_ACCOMMODATION_OVERVIEW]: addTipAccommodation,
    [types.UPDATE_TIP_ACCOMMODATION_OVERVIEW]: updateTipAccommodation,
    [types.ADD_ACCOMMODATION_OVERVIEW]: createAccommodation,
    [types.UPDATE_ACCOMMODATION_OVERVIEW]: updateAccommodation,
    [types.ARRANGE_ACCOMMODATION_OVERVIEW]: arrangeAccommodation,
    [types.DELETE_ACCOMMODATION_OVERVIEW]: deleteAccommodation,
    [types.ADD_MAP_CIRCLE_ACCOMMODATION_OVERVIEW]: addCircleAccommodation,
    [types.DELETE_MAP_CIRCLE_ACCOMMODATION_OVERVIEW]: deleteACircleAccommodation,
    [types.UPDATE_ACCOMMODATION_GALLERY_OVERVIEW]: updateAccomGalleryCaption,
    [types.UPDATE_GALLERY_ACTIVITY_ACCOM]: updateGalleryActivityAccom,

    [types.UPDATE_DESCRIPTION_CURRENCY_OVERVIEW]: updateDescriptionCurrency,
    [types.ARRANGE_TIPS_CURRENCY_OVERVIEW]: arrangeTipsCurrency,
    [types.DELETE_TIP_CURRENCY_OVERVIEW]: deleteTipCurrency,
    [types.ADD_TIP_CURRENCY_OVERVIEW]: addTipCurrency,
    [types.UPDATE_TIP_CURRENCY_OVERVIEW]: updateTipCurrency,

    [types.ADD_CURRENCY_OVERVIEW]: addCurrencyDetails,
    [types.DELETE_CURRENCY_OVERVIEW]: deleteCurrencyDetails,
    [types.UPDATE_CURRENCY_OVERVIEW]: updateCurrencyDetails,
    [types.UPDATE_CURRENCY_VENDORS_OVERVIEW]: updateCurrencyVendors,
    [types.ARANGE_CURRENCY_OVERVIEW]: arrangeCurrencyVendors,

    [types.UPDATE_DESCRIPTION_GO_TIME_OVERVIEW]: updateDescriptionGoTime,
    [types.ARRANGE_TIPS_GO_TIME_OVERVIEW]: arrangeTipsGoTime,
    [types.DELETE_TIP_GO_TIME_OVERVIEW]: deleteTipGoTime,
    [types.ADD_TIP_GO_TIME_OVERVIEW]: addTipGoTime,
    [types.UPDATE_TIP_GO_TIME_OVERVIEW]: updateTipGoTime,
    [types.UPDATE_GO_TIME_OVERVIEW]: updateGoTime,
    [types.UPDATE_FEATURE_IMAGE]: updateFeatureImage,

    [types.UPDATE_SECTION_VISIBILITY]: changeVisibility,

    [types.UPDATE_DESCRIPTION_BUDGET_OVERVIEW]: updateDescriptionBudget,
    [types.ARRANGE_TIPS_BUDGET_OVERVIEW]: arrangeTipsBudget,
    [types.DELETE_TIP_BUDGET_OVERVIEW]: deleteTipBudget,
    [types.ADD_TIP_BUDGET_OVERVIEW]: addTipBudget,
    [types.UPDATE_TIP_BUDGET_OVERVIEW]: updateTipBudget,
    //[types.UPDATE_BUDGET_OVERVIEW]: updateBudget,

    [`${types.GET_EXPERIENCE_SINGLE}_PENDING`]: setExperienceSinglePending,
    [`${types.GET_EXPERIENCE_SINGLE}_FULFILLED`]: setExperienceSingle,
    [`${types.GET_EXPERIENCE_SINGLE}_REJECTED`]: setExperinceRejected,

    [`${types.GET_PUBLISHED_EXPERIENCE_PUBLIC}_PENDING`]: setExperienceSinglePending,
    [`${types.GET_PUBLISHED_EXPERIENCE_PUBLIC}_FULFILLED`]: setExperienceSinglePublished,
    [`${types.GET_PUBLISHED_EXPERIENCE_PUBLIC}_REJECTED`]: setExperinceRejected,

    [`${types.GET_EXPERIENCE_PUBLISHED_SINGLE}_PENDING`]: setExperienceSinglePending,
    [`${types.GET_EXPERIENCE_PUBLISHED_SINGLE}_FULFILLED`]: setExperienceSinglePublished,
    [`${types.GET_EXPERIENCE_PUBLISHED_SINGLE}_REJECTED`]: setExperinceRejected,

    [`${types.UPDATE_EXPERIENCE}_PENDING`]: setUpdateExperiencePending,
    [`${types.UPDATE_EXPERIENCE}_FULFILLED`]: setUpdateExperienceFufilled,
    [`${types.UPDATE_EXPERIENCE}_REJECTED`]: () => {
        console.log('_REJECTED');
    },
    [types.ADD_REF]: addRef,
    [types.ADD_COMMENT]: addComment,
    [types.UPDATE_COMMENT]: updateComment,
    [types.DELETE_COMMENT]: deleteComment,
    [types.CLEAR_EXPERIENCE]: clearExperience,
    [types.SET_EXPERIENCE_UPDATED]: setExperienceUpdated,
    [types.UPDATE_TAGS_SINGLE]: setUpdateTags,
    [types.UPDATE_CATS_SINGLE]: setUpdateCats,
    [types.UPDATE_GUIDE_INCLUDES_SINGLE]: setUpdateGuideIncludes,
    [types.UPDATE_BUDGET]: setUpdateBudget,

    [types.UPDATE_GALLERY_INTRO_MARKETING]: updateMarketingGallery,
    [types.UPDATE_GALLERY_CAPTION_INTRO_MARKETING]: updateMarketingGalleryCaption,

    [types.UPDATE_GALLERY_WHAT_TO_DO_MARKETING]: updateMarketingGallery,
    [types.UPDATE_GALLERY_CAPTION_WHAT_TO_DO_MARKETING]: updateMarketingGalleryCaption,

    [types.UPDATE_GALLERY_WHERE_TO_STAY_MARKETING]: updateMarketingGallery,
    [types.UPDATE_GALLERY_CAPTION_WHERE_TO_STAY_MARKETING]: updateMarketingGalleryCaption,

    [types.UPDATE_TITLE_WHAT_TO_DO_MARKETING]: updateMarketingSectionTitle,
    [types.UPDATE_TITLE_WHERE_TO_STAY_MARKETING]: updateMarketingSectionTitle,

    [types.UPDATE_SUBTITLE_WHERE_TO_STAY_MARKETING]: updateMarketingSectionTitle,
    [types.UPDATE_SUBTITLE_WHAT_TO_DO_MARKETING]: updateMarketingSectionTitle,

    [types.UPDATE_DESCRIPTION_WHAT_TO_DO_MARKETING]: updateMarketingSectionTitle,
    [types.UPDATE_DESCRIPTION_WHERE_TO_STAY_MARKETING]: updateMarketingSectionTitle,

    [types.UPDATE_DESCRIPTION_THANKYOU_MARKETING]: updateMarketingSectionTitle,

    [types.UPDATE_TITLE_INTRO_MARKETING]: updateMarketingSectionTitle,
    [types.UPDATE_SUBTITLE_INTRO_MARKETING]: updateMarketingSectionTitle,
    [types.UPDATE_DESCRIPTION_INTRO_MARKETING]: updateMarketingSectionTitle,

    [types.ADD_WHATS_INCLUDED_MARKETING]: updateMarketingIncludes,
    [types.ARRANGE_WHATS_INCLUDED_MARKETING]: updateMarketingIncludes,
    [types.DELETE_WHATS_INCLUDED_MARKETING]: updateMarketingIncludes,
    [types.UPDATE_WHATS_INCLUDED_MARKETING]: updateMarketingIncludes,

    [types.UPDATE_GALLERY_WHATS_INCLUDED_MARKETING]: updateMarketingGallery,
    [types.UPDATE_GALLERY_CAPTION_WHATS_INCLUDED_MARKETING]: updateMarketingGalleryCaption,
    [types.UPDATE_DESCRIPTION_WHATS_INCLUDED_MARKETING]: updateMarketingSectionTitle,

    [types.ADD_POLICIES_MARKETING]: updateMarketingPolicies,
    [types.DELETE_POLICIES_MARKETING]: updateMarketingPolicies,
    [types.UPDATE_POLICIES_MARKETING]: updateMarketingPolicies,

    [types.UPDATE_TIP_WHAT_TO_DO_MARKETING]: updateTipMarketing,
    [types.ADD_TIP_WHAT_TO_DO_MARKETING]: addTipMarketing,
    [types.DELETE_TIP_WHAT_TO_DO_MARKETING]: deletTipMarketing,
    [types.ARRANGE_TIPS_WHAT_TO_DO_MARKETING]: arrangTipsMarketing,

    [types.UPDATE_TIP_WHERE_TO_STAY_MARKETING]: updateTipMarketing,
    [types.ADD_TIP_WHERE_TO_STAY_MARKETING]: addTipMarketing,
    [types.DELETE_TIP_WHERE_TO_STAY_MARKETING]: deletTipMarketing,
    [types.ARRANGE_TIPS_WHERE_TO_STAY_MARKETING]: arrangTipsMarketing,

    [types.UPDATE_TIP_WHATS_INCLUDED_MARKETING]: updateTipMarketing,
    [types.ADD_TIP_WHATS_INCLUDED_MARKETING]: addTipMarketing,
    [types.DELETE_TIP_WHATS_INCLUDED_MARKETING]: deletTipMarketing,
    [types.ARRANGE_TIPS_WHATS_INCLUDED_MARKETING]: arrangTipsMarketing,

    [`${types.GET_PURCHASED_EXPERIENCE}_PENDING`]: setExperienceSinglePending,
    [`${types.GET_PURCHASED_EXPERIENCE}_FULFILLED`]: setExperienceSinglePublished,
    [`${types.GET_PURCHASED_EXPERIENCE}_REJECTED`]: setExperinceRejected,
});
