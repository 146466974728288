import Image from 'components/blocks/Image';
import { PillLogo } from 'components/blocks/Pills';
import Button from 'components/blocks/Title/Button';
import GradientTitle from 'components/blocks/Title/GradientTitle';
import LandingCapture from 'components/landing/LandingCapture';
import { marketPath } from 'constants/globalConsts';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const list1 = [
    {
        text: 'Check your completion progress',
        tag: ''
    },
    {
        text: 'Follow the status of your submission',
        tag: ''
    },
    {
        text: 'Edit the price and calendar',
        tag: ''
    },
    {
        text: 'Get detailed performance analytics',
        tag: 'soon'
    }
];

const LayoutLanding = () => {
    const [tab, setTab] = useState('kreate');
    const tabClasses = {
        on: 'border-green-400',
        off: 'text-gray-400 border-transparent hover:border-gray-200'
    };
    const containerClasses = {
        on: 'translate-x-0',
        off: '-translate-x-full'
    };

    return (
        <>
            <div
                className='w-full mt-24 md:mt-20 py-8a lg:py-16a landing-gradient '
                // style={{
                //     backgroundImage: 'url("/assets/media/wood.svg")'
                // }}
            >
                <div
                    className={`relative 
                             md:max-w-3xl lg:max-w-4xl xl:max-w-7xl mx-auto bg-contain bg-no-repeat bg-center py-8 xl:py-16 px-4 xl:px-16 2xl:px-0 `}
                >
                    <div className='flex flex-col-reverse lg:flex-row justify-between items-center mb-8 md:px-16 lg:px-0 lg:gap-16 '>
                        <div className='lg:w-1/2 flex flex-col px-8 md:px-0'>
                            {/* <div className="uppercase tracking-widest text-green-400 text-xl md:text-3xl lg:text-2xl xl:text-3xl font-normal leading-tight mb-4">
                                via Konnect
                            </div> */}
                            <GradientTitle label='An Ecosystem' textSize='text-4xl md:text-6xl' justify='' containerClass='' />
                            <div className='text-2xl md:text-3xl xl:text-5xl tracking-tight text-gray-900 font-semibold leading-tight mb-4 md:mb-8'>
                                For Travelers and Experience Creators
                            </div>
                            <div className='text-green-900 text-base md:text-lg xl:text-xl font-normal mb-8'>
                                Join a community of ever-growing travelers, content kreators, and travel professionals who are transforming what it means to
                                build truly unique travel experiences.
                            </div>
                            <Button
                                // animation={false}
                                as='link'
                                label='Become a Kreator'
                                link='/accounts/signup'
                                width='md:w-96'
                            />
                        </div>
                        <div className='px-4 md:px-0'>
                            <img src='/assets/media/landing/untangle@2x.png' />
                        </div>
                    </div>
                </div>
            </div>

            <div
                className='w-full mt-161 md:mt-201 py-0 landing-gradient2 bg-gray-100 '
                // style={{
                //     backgroundImage: 'url("/assets/media/landing/wood.svg")'
                // }}
            >
                <div
                    className={`relative 
                             md:max-w-3xl lg:max-w-4xl xl:max-w-7xl mx-auto bg-contain bg-no-repeat bg-center py-16 md:pt-24 lg:pt-16 xl:py-16 px-4 xl:px-16 2xl:px-0`}
                >
                    <div className='absolute top-0 left-1/2 transform -translate-y-1/2 -translate-x-1/2'>
                        <PillLogo />
                    </div>
                    <div className='flex flex-col-reverse lg:flex-row-reverse justify-between items-center mb-8 md:px-16 lg:px-0 lg:gap-16'>
                        <div className='px-4 md:px-0 mb-4 md:mb-8  overflow-hidden rounded-xl shadow-2xl-green'>
                            <video controls={true} autoPlay={true} muted={true} loop={true} preload={true}>
                                <source src='https://ucarecdn.com/725038b5-1ac3-4d9d-a825-a50450478c2f/' type='video/mp4'></source>
                            </video>
                        </div>
                    </div>
                    <div className='flex flex-col-reverse lg:flex-row-reverse justify-center items-center md:mb-8 md:px-16 lg:px-0 lg:gap-16'>
                        <div className='lg:w-1/2 flex flex-col items-center px-8 md:px-0'>
                            <GradientTitle label="" textSize='text-4xl md:text-6xl' justify='' containerClass='' />
                            <div className='text-2xl md:text-3xl xl:text-5xl tracking-tight text-center text-gray-900 font-semibold leading-tight mb-4 md:mb-8'>
                                Build Digital Guides or Guided Tours. It's up to you.
                            </div>
                            <div className='text-green-900 text-base md:text-lg xl:text-xl font-normal mb-8 md:leading-9 text-center'>
                                As we get ready to open our home to the world, we invite you, the travel creators to try our platform and{' '}
                                <a href={`${marketPath}/contact`} target='_self' className='underline text-green-500'>
                                    tell us what you think
                                </a>{' '}
                                and how we can do better. Build your own Digital Guides or Guided tours, and we'll we take care of the details.
                            </div>
                            <Button as='link' label='Become a Kreator' link='/accounts/signup' width='w-full md:w-96' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full bg-white pb-0'>
                <div
                    className={`relative 
                          md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto bg-contain bg-no-repeat bg-center pt-16 md:pt-16 px-0 lg:px-8 w-80 md:w-full`}
                >
                    <div className='absolute top-0 left-1/2 transform -translate-y-1/2 -translate-x-1/2'>
                        <PillLogo />
                    </div>
                    <div className='w-full flex flex-col px-0 md:px-8 lg:px-0'>
                        <GradientTitle label='How does it work?' textSize='text-3xl md:text-6xl' justify='justify-center' />

                        <div className='max-w-lg w-full mx-auto flex items-center border-b border-gray-200 mb-8 md:mb-0 mt-4 md:mt-12'>
                            <div
                                onClick={() => setTab('travel')}
                                className={`cursor-pointer w-1/2 h-12 flex items-center border-b-4 px-4 uppercase md:tracking-wider text-sm ${
                                    tab === 'travel' ? tabClasses.on : tabClasses.off
                                }`}
                            >
                                For Travelers
                            </div>
                            <div
                                onClick={() => setTab('kreate')}
                                className={`cursor-pointer w-1/2 h-12 flex items-center border-b-4 px-4 uppercase md:tracking-wider text-sm ${
                                    tab === 'kreate' ? tabClasses.on : tabClasses.off
                                }`}
                            >
                                For Kreators
                            </div>
                        </div>
                        <div className='w-full rounded-3xl bg-white overflow-hidden'>
                            <div
                                className={`p-4 xl:p-16 flex flex-nowrap gap-8 md:gap-8 lg:gap-8 xl:gap-32 transform-gpu transition-all duration-500 ease-in-out-expo-hard ${
                                    tab === 'travel' ? containerClasses.on : containerClasses.off
                                }`}
                            >
                                <div className='flex flex-nowrap flex-col lg:flex-row lg:justify-center gap-4 lg:gap-8 xl:gap-16  '>
                                    <OptionCard title='Explore' image='/assets/media/landing/choosing.png'>
                                        Browse the Konnect marketplace and explore the evergrowing collection of both experiences - both digital and guided.
                                    </OptionCard>
                                    <OptionCard title='Choose' image='/assets/media/landing/aiming.png'>
                                        Each experience sets clear expectations for travellers. Where, when and how and everything in between. We've got all the
                                        info you need so that when you go for it, you're sure about what you're getting
                                    </OptionCard>
                                    <OptionCard title='Enjoy' image='/assets/media/landing/running.png'>
                                        The rest is up to you. Share back your journey with the
                                        <a
                                            href='https://www.instagram.com/explore/tags/viakonnect/'
                                            target='_blank'
                                            className='text-sm bg-green-200 rounded px-2 mx-2'
                                        >
                                            #viakonnect
                                        </a>
                                        tag
                                    </OptionCard>
                                </div>

                                <div className='flex flex-col lg:flex-row lg:justify-center gap-4 lg:gap-8 xl:gap-16 '>
                                    <OptionCard title='Kreate' image='/assets/media/landing/create@2x.png'>
                                        Use our uniquely built tool to build a flexible experience, digital or guided.
                                    </OptionCard>
                                    <OptionCard title='Share' image='/assets/media/landing/share@2x.png'>
                                        Share content with your followers directly through your social media accounts.
                                    </OptionCard>
                                    <OptionCard title='Earn' image='/assets/media/landing/earn@2x.png'>
                                        Sell your Kreations to the world. We'll take care of all the rest.
                                    </OptionCard>
                                </div>
                            </div>
                        </div>
                        {/* <div className="w-12 border-b-4 border-green-400 mx-auto mt-16 mb-16"></div> */}
                    </div>
                </div>
            </div>
            <div
                className='w-full py-8a lg:py-16a landing-gradient '
                // style={{
                //     backgroundImage: 'url("/assets/media/wood.svg")'
                // }}
            >
                <div
                    className={`relative 
                             md:max-w-3xl lg:max-w-4xl xl:max-w-7xl mx-auto bg-contain bg-no-repeat bg-center py-8 pt-16 md:pt-24 lg:pt-16 xl:py-16 px-4 xl:px-16 2xl:px-0`}
                >
                    <div className='absolute top-0 left-1/2 transform -translate-y-1/2 -translate-x-1/2'>
                        <PillLogo />
                    </div>
                    <div className='flex flex-col-reverse lg:flex-row-reverse justify-between items-center mb-8 md:px-16 lg:px-0 lg:gap-16 '>
                        <div className='lg:w-1/2 flex flex-col px-8 md:px-0'>
                            {/* <div className="uppercase tracking-widest text-green-400 text-xl md:text-3xl lg:text-2xl xl:text-3xl font-normal leading-tight mb-4">
                                via Konnect
                            </div> */}
                            <GradientTitle
                                flex='flex'
                                leading='leading-tight'
                                label='Earning with Konnect'
                                textSize='text-4xl md:text-6xl'
                                justify=''
                                containerClass='mb-4 md:mb-0'
                            />
                            <div className='text-2xl md:text-3xl xl:text-5xl tracking-tight text-gray-900 font-semibold leading-tight mb-4 md:mb-8'>
                                Who said you can’t make a living traveling
                            </div>
                            <div className='text-green-900 text-base md:text-lg xl:text-xl font-normal mb-8'>
                                While our focus is on the Kreator, we still need to cover our costs and marketing. For Digital experiances we share revenue
                                80-20% in favor of the Kreator while for Guided tours our commision starts from %10.
                            </div>
                            <Button
                                // animation={false}
                                as='link'
                                label='Become a Kreator'
                                link='/accounts/signup'
                                width='md:w-96'
                            />
                        </div>
                        <div className='px-4 md:px-0 md:pr-20'>
                            <img src='/assets/media/landing/kreator_earn@2x.png' />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className='w-full mt-161 md:mt-201 py-16 lg:py-0 landing-gradient2 bg-gray-100 '
                // style={{
                //     backgroundImage: 'url("/assets/media/wood.svg")'
                // }}
            >
                <div
                    className={`relative 
                             md:max-w-3xl lg:max-w-4xl xl:max-w-7xl mx-auto bg-contain bg-no-repeat bg-center py-0 lg:py-16 xl:py-16 px-4 xl:px-16 2xl:px-0`}
                >
                    <div className='flex flex-col-reverse lg:flex-row justify-between items-center mb-8 md:px-16 lg:px-0 lg:gap-16 '>
                        <div className='lg:w-1/2 flex flex-col px-8 md:px-0'>
                            {/* <div className="uppercase tracking-widest text-green-400 text-xl md:text-3xl lg:text-2xl xl:text-3xl font-normal leading-tight mb-4">
                                via Konnect
                            </div> */}
                            <GradientTitle label='A Community' textSize='text-4xl md:text-6xl' justify='' containerClass='' />
                            <div className='text-2xl md:text-3xl xl:text-5xl tracking-tight text-gray-900 font-semibold leading-tight mb-4 md:mb-8'>
                                Join our community of growing kreators
                            </div>
                            <div className='text-green-900 text-base md:text-lg xl:text-xl font-normal mb-8'>
                                Join{' '}
                                <a className='text-green-400 font-bold' href='https://www.instagram.com/mariaronnn/'>
                                    Maria
                                </a>
                                ,{' '}
                                <a className='text-green-400 font-bold' href='https://www.instagram.com/arabiantrails'>
                                    Turki
                                </a>
                                ,{' '}
                                <a className='text-green-400 font-bold' href='http://instagram.com/jasonbillamtravel'>
                                    Jason
                                </a>{' '}
                                and a growing list of amazing Kreators and influencers from all around the world.
                            </div>
                            <Button
                                // animation={false}
                                as='link'
                                label='Become a Kreator'
                                link='/accounts/signup'
                                width='md:w-96'
                            />
                        </div>
                        <div className='px-4 md:px-0 mb-8'>
                            <img src='/assets/media/landing/community_2.png' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='relative w-full bg-white py-4 pb-12 md:py-16 lg:py-8 xl:py-16' id='join'>
                <div className='absolute top-0 left-1/2 transform -translate-y-1/2 -translate-x-1/2'>
                    <PillLogo />
                </div>
                <div
                    className={`relative 
                          md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto bg-contain bg-no-repeat bg-center  lg:px-8 px-12 md:w-full`}
                >
                    <LandingCapture />
                </div>
            </div>
        </>
    );
};

export default LayoutLanding;

const OptionCard = ({ title, children, image }) => {
    return (
        <div className='w-full lg:w-72 xl:w-80 mb-4 md:mb-0  flex flex-col md:flex-row lg:flex-col md:gap-8 lg:gap-0'>
            <div className='w-72 md:w-80 lg:w-72 md:h-64 flex items-center justify-center'>
                <img src={image} className='' />
            </div>
            <div className='w-72 md:w-80 lg:w-72 md:mt-16 lg:mt-0 px-4 xl:px-0'>
                <div className='text-2xl md:text-3xl lg:text-4xl lg:mb-4 text-green-400 font-semibold'>{title}</div>
                <div className='text-base text-gray-600 '>{children}</div>
            </div>
        </div>
    );
};
