/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useReducer, useRef } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Fuse from 'fuse.js';
import ModalContainer, {
    ModalBody,
    ModalFooter,
    ModalCTA,
    ModalButton,
    ModalHelp
} from 'components/blocks/ModalTre';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import ButtonTags from 'components/blocks/ButtonTags';
import { getCountryObjs } from 'helpers/countryHelpers.js';

const { countriesObj } = getCountryObjs();

// should be in all modals
const targetElement = document.querySelector('#root');

function TagsCatsListModal({
    isAdminStaff = false,
    isOwner = true,
    tagList = [],
    catList = [],
    selectedCatsList = [],
    selected = [],
    setModalIsOpen,
    handleActionBtn,
    globalState: { lang, isSuperAdmin },
    hintObj = null,
    icon = uiStruct.itinerary.moving_around.uiEdit.modals.add.icon,
    actionBtnObj = uiStruct.itinerary.moving_around.uiEdit.modals.add.buttons
        .action,
    cancelBtnObj = uiStruct.itinerary.moving_around.uiEdit.modals.add.buttons
        .cancel
}) {
    const rtl = !!translations[lang].rtl;
    const regex = new RegExp(/^Days \d*$/);

    // Copy Below to all modals
    //////////////////////////////////////////////////////////////////////

    // State for triggering animation of modal in and out
    const tagsObject = useRef(
        tagList.reduce((prev, next) => {
            return {
                ...prev,
                [next.id]: next.name
            };
        }, {})
    );
    const catsObject = useRef(
        catList.reduce((prev, next) => {
            return {
                ...prev,
                [next.id]: next.name
            };
        }, {})
    );

    const [transitionState, setTransitionState] = useState('off');
    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    ///////////////////////////////////////////////////////////////////

    // Paul stuff Handle Action BTN function
    const handleActionClick = () => {
        handleActionBtn(selectedTags, selectedCats);
        handleTransitionStateOff(); // important... don't forget to add it in all modals
    };

    ///////////////////////////////

    const filterOptions = (tmpSelctedTags = []) => {
        return tagList.reduce((curr, next) => {
            const option = { value: next.id, label: next.name, fixed: true };

            if (!tmpSelctedTags.includes(next.id)) {
                return curr.concat(option);
            } else return curr;
        }, []);
    };

    const filterCatOptions = (tmpSelctedTags = []) => {
        return catList.reduce((curr, next) => {
            const option = { value: next.id, label: next.name, fixed: true };

            if (!tmpSelctedTags.includes(next.id)) {
                return curr.concat(option);
            } else return curr;
        }, []);
    };

    const allRelated = Object.keys(
        tagList.reduce((acc, curr) => {
            const tmpObj = curr.related.reduce(
                (accum, next) => ((acc[next] = ''), accum),
                {}
            );
            tmpObj[curr.name] = '';
            return {
                ...acc,
                ...tmpObj
            };
        }, {})
    );

    const fuseOptions = {
        includeScore: true,
        threshold: 0.4
    };

    const fuse = new Fuse(allRelated, fuseOptions);
    const tagsObj = tagList.reduce((acc, curr) => {
        return {
            ...acc,
            [curr.name]: [...curr.related, curr.name]
        };
    }, {});

    const [catOptions, setCatOptions] = useState(
        filterCatOptions(selectedCatsList || [])
    );
    const [selectedCats, setSelectedCats] = useState(selectedCatsList || []);
    const [options, setOptions] = useState(filterOptions(selected));
    const [tagKey, tagRerender] = useReducer((v) => Math.random());
    const [catKey, catRerender] = useReducer((v) => !v);
    const [selectedTags, setSelectedTags] = useState(selected);
    const selectTagChange = (data) => {
        const tagVal = data.value;

        setSelectedTags([...selectedTags, tagVal]);

        setTimeout(() => {
            setOptions(options.filter((opt) => opt.value !== tagVal));
            tagRerender();
        }, 500);
    };

    const selectCatChange = (data) => {
        const catVal = data.value;

        setSelectedCats([...selectedCats, catVal]);

        setTimeout(() => {
            setCatOptions(catOptions.filter((opt) => opt.value !== catVal));
            catRerender();
        }, 500);
    };

    const removeTag = (tag) => {
        const tmpSelctedTags = selectedTags.filter((objTag) => objTag !== tag);

        setSelectedTags(tmpSelctedTags);
        setOptions(filterOptions(tmpSelctedTags));
    };

    const removeCat = (tag) => {
        const tmpSelctedTags = selectedCats.filter((objTag) => objTag !== tag);

        setSelectedCats(tmpSelctedTags || []);
        setCatOptions(filterCatOptions(tmpSelctedTags || []));
    };

    const filterOption = (candidate, input) => {
        const fuzzArray = fuse.search(input);
        let hasRelated = false;
        let startsInput = false;
        const regex = new RegExp(`^${input}`);

        if (input.length > 2) {
            fuzzArray.forEach((fuzzObj) => {
                const { item } = fuzzObj;

                if (tagsObj[candidate.label].includes(item)) {
                    hasRelated = true;
                }
            });
        }

        if (input.length < 3) {
            startsInput = regex.test(candidate.label);
        }

        return candidate.data.__isNew__ || hasRelated || startsInput;
    };

    const filterCatOption = (candidate, input) => {
        let startsInput = false;
        const regex = new RegExp(`^${input}`);

        if (input.length < 3) {
            startsInput = regex.test(candidate.label);
        }

        return candidate.data.__isNew__ || startsInput;
    };

    return (
        <>
            <ModalContainer
                width='xl'
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={{ name: 'HASHTAG', size: '2xl' }}
                rtl={rtl}
            >
                <ModalBody height='24'></ModalBody>
                <div className='mb-16'>
                    <div key={catKey} className='mb-4'>
                        <div className='mb-4'>
                            Select one or more categories for your experience
                        </div>
                        <Select
                            isDisabled={
                                selectedCats.length ===
                                Number(process.env.REACT_APP_MAX_CATEGORIES)
                            }
                            isClearable={false}
                            options={catOptions}
                            onChange={selectCatChange}
                            filterOption={filterCatOption}
                        />
                    </div>
                    <div className='flex flex-wrap items-center gap-2 w-full max-w-3xl mt-4'>
                        {selectedCats.map((cat, index) => {
                            return (
                                <div key={`${cat}_${index}`}>
                                    <ButtonTags
                                        handleActionBtn={removeCat}
                                        label={catsObject.current[cat]}
                                        params={[cat]}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className='mb-12'>
                    <div key={tagKey} className='mb-4'>
                        <div className='mb-4'>
                            Select up to 10 keywords that help describe what
                            your experience is about
                        </div>
                        <Select
                            isClearable={false}
                            options={options}
                            onChange={selectTagChange}
                            filterOption={filterOption}
                        />
                    </div>
                    <div className='flex flex-wrap items-center gap-2 w-full max-w-3xl mt-4'>
                        {selectedTags
                            .filter((tag) => tag !== countriesObj[tag])
                            .filter((tag) => !regex.test(tag))
                            .map((tag, index) => {
                                return (
                                    <div key={`${tag}_${index}`}>
                                        <ButtonTags
                                            handleActionBtn={removeTag}
                                            label={tagsObject.current[tag]}
                                            params={[tag]}
                                        />
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <ModalFooter>
                    {hintObj && (
                        <ModalHelp
                            helpText={translations[lang][hintObj]}
                            isHtml
                        />
                    )}

                    {(isOwner || (isAdminStaff && isSuperAdmin)) && (
                        <ModalCTA>
                            <ModalButton
                                handleClick={handleTransitionStateOff}
                                label={translations[lang][cancelBtnObj.label]}
                                color={cancelBtnObj.color}
                                icon={cancelBtnObj.icon}
                            />
                            <ModalButton
                                handleClick={handleActionClick}
                                label={translations[lang][actionBtnObj.label]}
                                color={actionBtnObj.color}
                                icon={actionBtnObj.icon}
                            />
                        </ModalCTA>
                    )}
                </ModalFooter>
            </ModalContainer>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(TagsCatsListModal);
