import { countriesTranslations } from 'constants/countriesTranslations.js';

export const setPriceStatusSaving = (draft, action) => {
    draft.status.priceSaving = draft.status.priceSaving.concat(true);

    return draft;
};

export const setPriceStatusSaved = (draft, action) => {
    draft.status.priceSaving.shift();

    return draft;
};

export const setPriceStatusRejected = (draft, action) => {
    draft.status.priceSaving.shift();

    return draft;
};

export const setTagsCatsStatusSaving = (draft, action) => {
    draft.status.tagsCatsSaving = draft.status.priceSaving.concat(true);

    return draft;
};

export const setTagsCatsStatusSaved = (draft, action) => {
    draft.status.tagsCatsSaving.shift();

    return draft;
};

export const setTagsCatsStatusRejected = (draft, action) => {
    draft.status.tagsCatsSaving.shift();

    return draft;
};

export const setIncludesStatusSaving = (draft, action) => {
    draft.status.includesSaving = draft.status.priceSaving.concat(true);

    return draft;
};

export const setIncludesStatusSaved = (draft, action) => {
    draft.status.includesSaving.shift();

    return draft;
};

export const setIncludesStatusRejected = (draft, action) => {
    draft.status.includesSaving.shift();

    return draft;
};

export const setStartUpData = (draft, action) => {
    const {
        payload: {
            data: {
                data: {
                    tags,
                    categories,
                    setting: { whatsIncluded, xchangeRates, app_version },
                    placesLists
                }
            }
        }
    } = action;

    draft.siteData.loading = false;
    draft.siteData.placesLists = placesLists;
    draft.siteData.countryList  = placesLists.map((place) => {
        const { id, code } = place;
        const { name } = countriesTranslations['en'][code];
        return { id, name };
    }).sort(( a, b ) => {
        if ( a.name < b.name ){
            return -1;
        }
        if ( a.name > b.name ){
            return 1;
        }
        return 0;
    })
    draft.siteData.categories = categories;
    draft.siteData.tags = tags.filter((tag) => tag.type === 'general');
    draft.siteData.settings.whatsIncluded = whatsIncluded;
    draft.siteData.settings.xchangeRates = xchangeRates;
    draft.siteData.settings.app_version = app_version;
    return draft;
};
// Marketplace', 'Kreo', 'Owned'
export const setExpType = (draft, action) => {
    const { dataObj } = action;
    switch(dataObj.toLowerCase()){
        case 'kreo':
            draft.inPublished = false;

            break;
        case 'owned':
            draft.inPublished = true;

            break;
        default:
            draft.inPublished = true;

            
    }
   // draft.inPublished = dataObj;
    draft.inMode = dataObj.toLowerCase()
    return draft;
};

export const setUsers = (draft, action) => {
    const {
        payload: {
            data: {
                data: {
                    users
                }
            }
        }
    } = action;
    draft.siteData.users = users;

    return draft;
};