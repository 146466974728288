export const feedbackStyling = {
    bg: {
        completed: 'bg-green-200',
        remaining: 'bg-yellow-200',
        all: 'bg-gray-200'
    },
    cardBg: {
        completed: 'bg-green-50',
        remaining: 'bg-yellow-50',
        all: 'bg-gray-50'
    },
    cardContainer: {
        completed: 'border-green-400 shadow-2xl-green-400',
        remaining: 'border-yellow-400 shadow-2xl-yellow-400',
        all: 'border-gray-400 shadow-2xl'
    },
    text: {
        completed: 'text-gray-600',
        remaining: 'text-gray-600',
        all: 'text-gray-600'
    },
    border: {
        completed: 'border-green-200 ',
        remaining: 'border-yellow-200',
        all: 'border-gray-200'
    }
};
