/* eslint-disable arrow-body-style */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as types from 'store/actions/types';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic';
import React, { useState } from 'react';

import { Section__Title } from 'components/blocks/Sections';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import OnboardingSection from 'components/specialty/OnboardingSection';
import GenericDescription from 'components/blocks/GenericDescription';
import CardXl from 'components/blocks/CardXl';
import TipList from 'components/blocks/TipList';
import VendorList from 'components/blocks/VendorList';
import { PillContainer, PillIcon, PillTitle } from 'components/blocks/Pills';
import GettingThereEntry from 'components/blocks/GettingThereEntry';
import classNames from '../../../node_modules/classnames/index';

function SectionGettingThere(props) {
    const {
        experienceDetailsAction,
        actionType,
        dayIndex = null,
        experienceDetails: { experience },

        globalState: { lang, edit, isPrintMode, isFeedbackMode }
    } = props;

    const rtl = !!translations[lang].rtl;
    const gettingThereObj = experience.getting_there;
    const isSectionVisible = gettingThereObj.isVisible ?? true;
    const hintObj = uiStruct.overview.getting_there.uiPreview.hint;
    const hintObjEmpty = uiStruct.overview.getting_there.uiPreview.hintEmpty;
    const hintObjModal = uiStruct.overview.getting_there.uiEdit.modals.description.hint;

    const [isMinimized, setIsMinimized] = useState(false);

    const handleVisible = () => {
        experienceDetailsAction({
            type: types.UPDATE_SECTION_VISIBILITY,
            val: !isSectionVisible,
            objName: 'getting_there'
        });
    };

    return (
        (edit || isSectionVisible) && (
            <div className={classNames('w-full touch:py-10', edit ? 'touch:-mt-8 ' : 'touch:-mt-28', 'mt-8')}>
                {/* <div className={`w-full ${edit ? 'touch:-mt-16' : 'touch:-mt-28'} touch:py-10`}> */}

                <>
                    <Section__Title
                        title={translations[lang][uiStruct.overview.getting_there.uiPreview.headTitle]}
                        edit={edit}
                        hasData
                        hintObj={hintObj}
                        handleVisible={handleVisible}
                        isVisible={isSectionVisible}
                        className={`${isSectionVisible ? '-mb-4 lg:-mb-8' : 'mb-4 lg:mb-0'}`}
                    />
                    {/* <OnboardingSection visible data={hintObjEmpty} /> */}
                </>
                {isSectionVisible && (
                    <CardXl
                        isFeedbackMode={isFeedbackMode}
                        objId={experience?.getting_there?.objId?.section}
                        edit={edit}
                        isPrintMode={isPrintMode}
                        rtl={rtl}
                        hidden={isMinimized}
                        isVisible={isSectionVisible}
                        // handleVisible={handleVisible}
                        handleMinimize={() => setIsMinimized(!isMinimized)}
                    >
                        {/* <div className={`z-200 w-max mx-auto ${isMinimized ? 'mt-2 xl:mt-8' : ''}`}>
                            <PillContainer rtl={rtl}>
                                <PillIcon icon='AIR_TRAVEL' />
                                <PillTitle title={translations[lang][uiStruct.overview.getting_there.uiPreview.subTitle]} />
                            </PillContainer>
                        </div> */}
                        <div className={`${isMinimized ? 'hidden' : ''}`}>
                            <GenericDescription
                                actionType={actionType}
                                descriptionHtml={gettingThereObj.description_html}
                                defaultHtml={translations[lang][uiStruct.overview.getting_there.uiPreview.descriptionEmpty]}
                                hintObj={hintObjModal}
                                objId={experience?.getting_there?.objId?.description}
                                title='Getting there'
                                subtitle='Description'
                            />

                            {/* <VendorList
                                objId={experience?.getting_there?.objId?.vendorlist}
                                actionType={actionType}
                                vendors={gettingThereObj.vendors}
                                dayIndex={dayIndex}
                                index={null}
                                btnLabel={translations[lang][uiStruct.overview.getting_there.uiEdit.buttons.addlink.label]}
                                icon='ri-flight-land-line'
                                modalTitle='Aiports & Visas'
                                modalSubTitle='Select Major entry points'
                                labelTitle='Add an aiport, seaport or train station depending on how you would get to your destination.'
                                labelVendors={'Add one or more entry points of the same type (several aiports for ex)'}
                                labelVendorsPlaceholderName={'Ex: Barcelona El Prat (BCN)'}
                                labelVendorsPlaceholderUrl={'https://'}
                                labelVendorsPlaceholderTitle={'hello'}
                                btnOffset='16'
                                isVisible={isSectionVisible}
                            /> */}
                            {/* <GettingThereEntry
                                actionType={actionType}
                                entryData={gettingThereObj.entry}
                                defaultTxt={translations[lang][uiStruct.overview.getting_there.uiPreview.entryEmpty]}
                                objId={experience?.getting_there?.objId?.entry}
                            /> */}

                            <TipList
                                // max
                                objId={experience?.getting_there?.objId?.tiplist}
                                actionType={actionType}
                                tips={gettingThereObj.tips_html}
                                dayIndex={null}
                                index={null}
                                offset={0}
                                isVisible={isSectionVisible}
                            />
                        </div>
                    </CardXl>
                )}
            </div>
        )
    );
}

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            experienceDetailsAction
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionGettingThere);
