/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModalFullscreen, { ModalBody } from 'components/blocks/ModalFullscreen';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import LayoutLoading from 'components/layouts/LayoutLoading';
import TitleGradient from 'components/blocks/TitleGradient';
import {
    fetchUsersListAdmin,
    fetchPublishedId,
    updateExpOwner
} from 'fetch/experiences';
import EditExpForm from 'components/forms/EditExpForm';
import Spinner from 'components/blocks/Spinner';

// should be in all modals
const targetElement = document.querySelector('#root');

function EditExpModal(props) {
    const {
        close,
        setModalIsOpen,
        globalState: { lang },
        icon = uiStruct.ui.modals.editor.icon,
        hintObj = null,
        actionBtnObj = uiStruct.ui.modals.editor.buttons.action,
        cancelBtnObj = uiStruct.ui.modals.editor.buttons.cancel,
        fetchUsersListAdmin,
        fetchPublishedId,
        updateExpOwner,
        fetchAll,
        dataObj: { id: expId, type, userId, inPublished, status }
    } = props;
    const rtl = !!translations[lang].rtl;
    const [transitionState, setTransitionState] = useState('off');
    const userData = useRef([]);
    const pubId = useRef(null);
    // Copy Below to all modals
    //////////////////////////////////////////////////////////////////////

    // State for triggering animation of modal in and out
    const [dataLoading, setDataLoading] = useState(true);
    useEffect(() => {
        setTransitionState('on');
        fetchUsersListAdmin().then((res) => {
            if (res?.action?.type) {
                if (res.action.type === 'FETCH_USER_LIST_FULFILLED') {
                    userData.current =
                        res?.action?.payload?.data?.data?.users || [];
                }
            }
            if (inPublished) {
                fetchPublishedId(expId).then((res) => {
                    if (res?.action?.type) {
                        if (res.action.type === 'GET_PUBLISHED_ID_FULFILLED') {
                            if (
                                res?.action?.payload?.data?.data?.publisheds
                                    ?.length
                            ) {
                                pubId.current =
                                    res.action.payload.data.data.publisheds[0].id;
                            }
                        }
                    }
                    setDataLoading(false);
                });
            } else {
                setDataLoading(false);
            }
        });
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    const handleEditExp = (selectedUser, selectedType, selectedStatus) => {
        const updateData = {
            owner: false,
            type: false,
            pubId: pubId.current,
            expId,
            status: false
        };

        if (type !== selectedType) {
            updateData.type = selectedType;
        }
        if (selectedUser !== userId) {
            updateData.owner = selectedUser;
        }

        if (selectedStatus !== status) {
            updateData.status = selectedStatus;
        }

        setDataLoading(true);
        updateExpOwner(updateData).then(() => {
            fetchAll();
            setModalIsOpen(false);
        });
        // console.log(updateData);
    };
    return (
        <>
            <ModalFullscreen
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={icon}
                close={close}
                closeButton={true}
                rtl={rtl}
                fullscreen={true}
                bgColor='bg-gray-100'
                dataLoading={dataLoading}
            >
                <ModalBody rtlOff>
                    <div
                        className={`relative 
                    px-4 md-px-0 max-w-2xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl mx-auto `}
                    >
                        {!dataLoading ? (
                            <>
                                <TitleGradient
                                    size='text-4xl'
                                    text='Experience settings'
                                />

                                <EditExpForm
                                    handleEditExp={handleEditExp}
                                    {...props}
                                    handleTransitionStateOff={
                                        handleTransitionStateOff
                                    }
                                    setDataLoading={setDataLoading}
                                    userData={userData.current}
                                />
                            </>
                        ) : (
                            <LayoutLoading
                                height='h-screen-4/5'
                                // message='Loading User data 2'
                                spinnerColor='#00cc97'
                            />
                        )}
                    </div>
                </ModalBody>
            </ModalFullscreen>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchUsersListAdmin,
            fetchPublishedId,
            updateExpOwner
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditExpModal);
