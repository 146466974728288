/* eslint-disable react/jsx-pascal-case */
/* eslint-disable arrow-body-style */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { useState, useEffect } from 'react';
import { createActivity, arrangeActivities, deleteActivity, updateActivity, updateMapDataActivity } from 'store/actions/experienceDetails/activities';
import SectionActivitySingle from 'components/sections/SectionActivitySingle';
import ActivitiesModal from 'components/modals/activities/ActivitiesModal';
import ConfirmModal from 'components/modals/ConfirmModal';
import { Section__Title } from 'components/blocks/Sections';
import { ButtonsAddMain } from 'components/blocks/Buttons';
import { ACTIVITY } from 'constants/experienceDetailConsts';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import MapModal from 'components/modals/MapModal';
import OnboardingSection from 'components/specialty/OnboardingSection';
import { MenuRefsHolder } from 'helpers/MenuRefsHolder';

function SectionActivities(props) {
    const {
        dayIndex,
        experienceDetails: { experience, type },
        globalState: { lang, edit, isFeedbackMode },
        deleteActivity,
        createActivity,
        updateActivity,
        updateMapDataActivity,
        isMarketingMode
    } = props;

    const rtl = !!translations[lang].rtl;

    const { activities: activitiesList } = experience.itinerary.trip_days[dayIndex];
    const [editModalState, setEditModalState] = useState(false);
    const [updateObj, setUpdateObj] = useState();
    const [updateStatus, setUpdateStatus] = useState(false);
    const [activityIndex, setActivityIndex] = useState(0);
    const [delConfirmModalState, setDelConfirmModalState] = useState(false);
    const [showMapModal, setShowMapModal] = useState(false);
    const hasData = activitiesList.length;
    const show = edit ? true : !edit && !hasData ? false : true;
    const [locationData, setLocationData] = useState(hasData ? activitiesList[0].location : null);
    const [editActivityType, setEditActivityType] = useState(true);
    const [mapDataReady, setMapDataReady] = useState(false);

    const openMapModal = (index) => {
        setMapDataReady(true);
        setActivityIndex(index);
        setLocationData(activitiesList[index].location);
        setShowMapModal(true);
    };

    const handleMapUpdateAction = (marker, address) => {
        setMapDataReady(false);
        updateMapDataActivity(dayIndex, activityIndex, {
            data: { ...marker },
            address: address
        });
    };

    const updateCreate = (dataObj) => {
        if (updateStatus) {
            updateActivity(dayIndex, dataObj, activityIndex);
        } else {
            createActivity(dayIndex, dataObj);
        }
    };

    const handleUpdate = (index, typeOnly) => {
        setEditActivityType(typeOnly);
        setActivityIndex(index);
        setUpdateObj({
            ...activitiesList[index]
        });
        setEditModalState(true);
        setUpdateStatus(true);
    };

    const removeModal = (index) => {
        setActivityIndex(index);
        setDelConfirmModalState(true);
    };

    const confirmRemove = () => {
        deleteActivity(dayIndex, activityIndex);
    };

    const handleUpdateMapCoords = () => {
        deleteActivity(dayIndex, activityIndex);
    };

    const buildSingleEdit = (provided) => {
        return activitiesList.map((mAObj, index, arr) => {
            const key = `mr${index}`;

            return (
                <div key={key}>
                    <SectionActivitySingle
                        totalActivities={activitiesList.length}
                        type={type}
                        openMapModal={openMapModal}
                        dayIndex={dayIndex}
                        index={index}
                        data={arr[index]}
                        provided={provided}
                        removeModal={removeModal}
                        handleUpdate={handleUpdate}
                        isMarketingMode={isMarketingMode}
                        // ref={refs[index]}
                    />
                </div>
            );
        });
    };

    const addNew = () => {
        setEditActivityType(true);
        setEditModalState(true);
        setUpdateStatus(false);
    };

    const buildPanel = (provided) => {
        return (
            <div className={!hasData ? 'mt-8' : ''}>
                <ul>{buildSingleEdit()}</ul>
                {edit && !isFeedbackMode && (
                    <div className='flex items-center justify-center w-full'>
                        <ButtonsAddMain handleClick={addNew} label={translations[lang][uiStruct.itinerary.activities.uiEdit.buttons.addMain.label]} rtl={rtl} />
                    </div>
                )}
                {editModalState && (
                    <ActivitiesModal
                        editActivityType={editActivityType}
                        defaultObj={ACTIVITY}
                        expType={type}
                        setModalIsOpen={setEditModalState}
                        handleActionBtn={updateCreate}
                        update={updateStatus}
                        updateObj={updateObj}
                    />
                )}
                {delConfirmModalState && <ConfirmModal setModalIsOpen={setDelConfirmModalState} handleActionBtn={confirmRemove} />}
            </div>
        );
    };

    useEffect(() => {
        if (!activitiesList.length) setMapDataReady(false);
    }, [activitiesList]);

    return (
        <div className={`w-full ${experience.itinerary.trip_days[dayIndex].what_you_need.isVisible ? 'touch:-mt-20' : edit ? '' : 'mt-16 md:mt-0'}  pb-10`}>
            {true && (
                <div
                    id='dayActivities'
                    ref={(ref) => {
                        MenuRefsHolder.setRef(ref);
                    }}
                >
                    <Section__Title
                        title={translations[lang][uiStruct.itinerary.activities.uiPreview.headTitle]}
                        hasData={true}
                        hintObj={uiStruct.itinerary.activities.uiPreview.hint}
                        isVisible={true}
                        className='-mb-4 lg:-mb-8'
                    />
                    {/* <OnboardingSection
                        visible={hasData}
                        data={uiStruct.itinerary.activities.uiPreview.hintEmpty}
                    /> */}
                </div>
            )}
            {buildPanel()}
            {edit && showMapModal && (
                <MapModal
                    locationData={locationData}
                    handleActionBtn={handleMapUpdateAction}
                    handleUpdateMapCoords={handleUpdateMapCoords}
                    setModalIsOpen={setShowMapModal}
                    //  showModal={showMapModal}
                />
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            arrangeActivities,
            createActivity,
            deleteActivity,
            updateActivity,
            updateMapDataActivity
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionActivities);
