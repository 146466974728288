export const updateFilter = (draft, action) => {
    const { filterType, value } = action;
    if(filterType === 'extra') {
        draft.filters.extra.extraSelected = value;
    } else if(filterType === 'order') {
        draft.filters.extra.orderType = value;
    } else {
        draft.filters[filterType] = value;
    }
    draft.pagination.currentPage = 0; // reset pagination

    return draft;
};

export const updateExpeListPagination = (draft, action) => {
    const { currentPage } = action;

    draft.pagination.currentPage = currentPage;

    return draft;
};