import { useState } from 'react';
import { connect } from 'react-redux';
import GalleryModal from 'components/modals/GalleryModal';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import Icons from 'components/blocks/Icons';
import { handleRowReverse } from 'helpers/FEutils';
import CollapseButton from 'components/blocks/CollapseButton';
import { useOnlineStatus } from 'helpers/useOnlineStatus';

function GalleryUpdater({
    imageList,
    handleUpdateGallery,
    globalState: { lang, isFeedbackMode },
    max = 12,
    raw = false,
    children,
    className = 'w-min italic whitespace-nowrap mb-3 sm:mb-0 font-bold tracking-tight flex flex-none justify-center items-center bg-green-200 rounded-full px-6 text-green-700 h-8',
    modalTitle
}) {
    const isOnline = useOnlineStatus();
    const [updaterModalState, setUpdaterModalState] = useState(false);
    const {
        itinerary: {
            activities: {
                uiEdit: {
                    modals: {
                        images: { max_image_upload: maxImages }
                    }
                }
            }
        }
    } = uiStruct;

    const rtl = !!translations[lang].rtl;

    const openGalleryModal = () => {
        setUpdaterModalState(true);
    };

    const labelText = () => {
        if (imageList?.length > 0) return translations[lang][uiStruct.ui.gallery.manage];
        else return translations[lang][uiStruct.ui.gallery.add];
    };

    return (
        <>
            {!children ? (
                <>
                    {!isFeedbackMode ? (
                        <div className='flex justify-center mb-8'>
                            <CollapseButton
                                alwaysOn
                                disabled={!isOnline}
                                labelHover='Manage Gallery'
                                handleClick={isOnline ? openGalleryModal : null}
                                icon='ri-image-add-line text-2xl -ml-0.5a'
                                size='10'
                                textSize='text-xs'
                                sizeHover='w-40'
                                offsetCenter='2'
                                padding='px-4'
                                btnColor='bg-green-400 hover:bg-gray-900 text-green-900'
                            />
                        </div>
                    ) : (
                        <div className='w-full h-20 '></div>
                    )}
                    {/* <div
                        className={`${imageList?.length > 0 ? '-mt-4' : ''} ${
                            handleRowReverse(rtl).flex
                        } bg-green-50 py-6 px-8 cursor-pointer rounded-3xl transition duration-300 hover:bg-green-100 hover:border-green-400 border-2 border-transparent flex items-center mb-8 gap-4`}
                        onClick={openGalleryModal}
                    >
                        <Icons iName='GALLERY' iClasses='text-green-400' />
                        <div
                            className={`${
                                imageList?.length > 0 ? '' : 'italic text-gray-400'
                            }`}
                        >
                            {labelText()}
                        </div>
                    </div> */}
                </>
            ) : (
                <div className={`${className} focus:outline-none outline-none cursor-pointer`} onClick={isOnline ? openGalleryModal : null}>
                    {children}
                </div>
            )}
            {updaterModalState && (
                <GalleryModal
                    maxImages={max}
                    handleUpdateGallery={handleUpdateGallery}
                    setModalIsOpen={setUpdaterModalState}
                    imageList={imageList}
                    hintObj={uiStruct.itinerary.moving_around.uiEdit.modals.tip.hint}
                    modalTitle={modalTitle}
                />
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps)(GalleryUpdater);
