/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ConfirmModal from 'components/modals/ConfirmModal';
import { ButtonsAdd } from 'components/blocks/Buttons';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import useDraggableInPortal from 'helpers/useDraggableInPortal';
import MapModal from 'components/modals/MapModal';
import {
    createAccommodation,
    updateAccommodation,
    arrangeAccommodation,
    deleteAccommodation
} from '../../store/actions/experienceDetails/accommodation';
import Accommodation from './Accommodation';
import GenericBtn from './GenericBtn';
import CircleMapModal from 'components/modals/CircleMapModal';
import CircleListItem from './CircleListItem';
import FeedbackBox from './FeedbackBox';

const defaultAccomodationObj = {
    data: {
        zoom: 2,
        location: {
            lat: 0,
            lng: 0
        }
    },
    address: '',
    info: {
        type: 'ACC_HOTEL',
        rating: 'ACC_STAR1'
    }
};
const defaultAreaObj = {
    circle: false,
    // circle: {
    //     location: {
    //         lat: 28.753407058258556,
    //         lng: 61.09373728525492
    //     },
    //     radius: 392663.6611772638,
    //     bounds: {
    //         south: 25.226049374719903,
    //         west: 57.069513128582514,
    //         north: 32.280764741797206,
    //         east: 65.11796144192732
    //     }
    // },
    label: ''
};

function AccommodationList({
    objId,
    updateAccommodation,
    createAccommodation,
    arrangeAccommodation,
    deleteAccommodation,
    globalState: { lang, edit, isFeedbackMode },
    locations,
    icon = uiStruct.itinerary.moving_around.uiEdit.modals.tip.icon,
    hintObj = uiStruct.itinerary.moving_around.uiEdit.modals.tip.hint,
    max,
    isVisible = true
}) {
    const rtl = !!translations[lang].rtl;
    const renderDraggable = useDraggableInPortal();
    const [delConfirmModalState, setDelConfirmModalState] = useState(false);
    const [accommIndex, setAccommIndex] = useState(0);
    const [updateObj, setUpdateObj] = useState({});
    const [mapModalStatus, setMapModalStatus] = useState(false);
    const [circleMapModalStatus, setCircleMapModalStatus] = useState(false);
    const [updateTip, setUpdateTip] = useState(false);
    const [dayListMode, setDayListMode] = useState('initial');

    const handleDelete = (accommIndex) => {
        setAccommIndex(accommIndex);
        setDelConfirmModalState(true);
    };

    const handleEdit = (accommIndex, type = 'location') => {
        setAccommIndex(accommIndex);
        setUpdateTip(true);
        setUpdateObj({
            ...locations[accommIndex]
        });
        if (type === 'location') {
            setMapModalStatus(true);
        } else {
            setCircleMapModalStatus(true);
        }
    };
    const addArea = () => {
        setUpdateObj({ ...defaultAreaObj });
        setUpdateTip(false);
        setCircleMapModalStatus(true);
    };
    const addAccommodation = () => {
        setUpdateObj({ ...defaultAccomodationObj });
        setUpdateTip(false);
        setMapModalStatus(true);
    };

    const updateAccomm = (marker, address, accommData, objId) => {
        const tmpObj = {
            data: {
                zoom: marker.zoom,
                location: marker.location
            },
            address: address,
            info: accommData,
            objId
        };
        if (updateTip) {
            updateAccommodation(tmpObj, accommIndex);
        } else {
            createAccommodation(tmpObj);
        }
    };

    const updateAccommCircle = (dataObj) => {
        if (updateTip) {
            updateAccommodation(dataObj, accommIndex);
        } else {
            createAccommodation(dataObj);
        }
    };

    const updateDayAccom = (days, index) => {
        updateAccommodation(
            {
                ...locations[index],
                days
            },
            index
        );
    };

    const confirmDelete = () => {
        deleteAccommodation(accommIndex);
    };

    const buildPreviewDay = (dest, provided, index = null, isEdit = false) => {
        const { type, objId } = dest;

        return type !== 'circle' ? (
            <Accommodation
                objId={objId}
                index={index}
                byDay={dayListMode}
                handleDeleteBtnAction={handleDelete}
                handleClickBtnAction={handleEdit}
                edit={isEdit}
                accommInfo={dest}
                label={'text'}
                rtl={rtl}
                icon='CHECK'
                bullet='TRAIN'
                provided={provided}
                handleDayUpdate={updateDayAccom}
            />
        ) : (
            <CircleListItem
                objId={objId}
                index={index}
                byDay={dayListMode}
                handleDeleteBtnAction={handleDelete}
                handleClickBtnAction={handleEdit}
                edit={isEdit}
                accommInfo={dest}
                label={'text'}
                rtl={rtl}
                icon='CHECK'
                bullet='TRAIN'
                provided={provided}
                handleDayUpdate={updateDayAccom}
            />
        );
    };

    const buildTipSingle = () => {
        if (dayListMode === 'byDay' && !edit) {
            const daysUsed = {};
            let noDays = [];
            let jsxView = [];
            locations.forEach((destination) => {
                if (destination.days && destination.days.length) {
                    destination.days.forEach((dayNum) => {
                        if (!daysUsed[dayNum]) {
                            daysUsed[dayNum] = [];
                        }
                        daysUsed[dayNum] = [...daysUsed[dayNum], destination];
                    });
                } else {
                    noDays = [...noDays, destination];
                }
            });

            Object.keys(daysUsed)
                .sort((a, b) => a - b)
                .forEach((daykey, i) => {
                    jsxView = [
                        ...jsxView,
                        <div
                            key={`day_name${daykey}_${i}`}
                            className='bg-white shadow-cards rounded-3xl py-8 px-4 md:px-8 mb-16 relative -mx-4 md:mx-0'
                        >
                            <div className='absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '>
                                <div className='text-green-400 text-xs flex items-center px-6 py-1 bg-gray-900  rounded-full gap-2'>
                                    <i className=' text-green-400 text-base ri-check-line'></i>
                                    <span className='uppercase'>
                                        Day {daykey}
                                    </span>
                                </div>
                            </div>
                            {daysUsed[daykey].map((dest, index) => {
                                return (
                                    <div key={`day_${daykey}_${i}_${index}`}>
                                        <Draggable
                                            key={daykey}
                                            draggableId={daykey}
                                            index={index}
                                        >
                                            {renderDraggable((provided) =>
                                                buildPreviewDay(dest, provided)
                                            )}
                                        </Draggable>
                                    </div>
                                );
                            })}
                        </div>
                    ];
                });

            if (noDays.length) {
                jsxView = [
                    ...jsxView,

                    <div
                        key={`no_day_chosen`}
                        className='bg-white shadow-cards rounded-3xl py-8 px-4 md:px-8 mb-16 relative -mx-4 md:mx-0'
                    >
                        <div className='absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                            <div className='text-red-400 text-xs flex items-center px-6 py-1 bg-gray-900  rounded-full gap-2'>
                                <i className=' text-red-400 text-lg ri-close-line'></i>
                                <span className='uppercase'> Unassigned</span>
                            </div>
                        </div>
                        {noDays.map((dest, index) => {
                            return (
                                <div key={`no_day_${index}`}>
                                    <Draggable
                                        key={`no_day_${index}`}
                                        draggableId={`no_day_${index}`}
                                        index={index}
                                    >
                                        {renderDraggable((provided) =>
                                            buildPreviewDay(dest, provided)
                                        )}
                                    </Draggable>
                                </div>
                            );
                        })}
                    </div>
                ];
            }

            return jsxView;
        }

        return locations.map((obj, index) => {
            const key = `dest${index}`;

            return (
                <Draggable key={key} draggableId={key} index={index}>
                    {renderDraggable((provided) =>
                        buildPreviewDay(obj, provided, index, edit)
                    )}
                </Draggable>
            );
        });
    };

    const buildLocactions = () => {
        return (
            <DragDropContext onDragEnd={accommDragEnd}>
                <Droppable droppableId='movingAround'>
                    {(provided) => (
                        <ul
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {buildTipSingle(provided)}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
        );
    };
    const accommDragEnd = (result) => {
        const { source, destination } = result;

        if (source && destination && source.index !== destination.index) {
            arrangeAccommodation(source.index, destination.index);
        }
    };

    const changeDayListPreviewMode = () => {
        if (dayListMode === 'initial') {
            setDayListMode('byDay');
        } else {
            setDayListMode('initial');
        }
    };
    return (
        <div className=''>
            {locations.length && locations.length > 1 ? (
                <div className='mb-12  flex justify-center'>
                    {!edit && (
                        <GenericBtn
                            bgColor='bg-green-400 hover:bg-gray-900'
                            className='font-semibold'
                            params={[]}
                            handleActionBtn={changeDayListPreviewMode}
                        >
                            {dayListMode === 'initial'
                                ? 'Order by day'
                                : 'Order by place'}
                        </GenericBtn>
                    )}
                </div>
            ) : null}
            <div className='relative'>
                <FeedbackBox
                    inset='-inset-y-4 -inset-x-8'
                    dot='right-2 -top-3 md:top-1/2 md:-translate-y-1/2 md:-right-16 lg:-right-20'
                    posY='bottom-full'
                    root
                    objId={objId}
                    label='Accommodation List'
                />
                {locations.length ? buildLocactions() : null}
            </div>
            {edit && isVisible && !isFeedbackMode && (
                <div className='flex items-center justify-center w-full'>
                    <ButtonsAdd
                        handleClick={addAccommodation}
                        label={
                            translations[lang][
                                uiStruct.overview.accommodation.uiEdit.buttons
                                    .addlink.label
                            ]
                        }
                        className={`${rtl ? '' : '-'}translate-x-8`}
                        rtl={rtl}
                        iconOnly='ri-map-pin-add-line'
                    />
                </div>
            )}
            {edit && isVisible && !isFeedbackMode && (
                <div className='flex items-center justify-center w-full'>
                    <ButtonsAdd
                        handleClick={addArea}
                        label={`Area`}
                        className={`${rtl ? '' : '-'}translate-x-28`}
                        rtl={rtl}
                        iconOnly='ri-record-circle-line'
                    />
                </div>
            )}
            {mapModalStatus && (
                <MapModal
                    locationData={updateObj}
                    handleActionBtn={updateAccomm}
                    setModalIsOpen={setMapModalStatus}
                    type='accommodation'
                    modalTitle='Accommodation'
                    modalSubTitle='Add Location Pin'
                    text='Let us help you pin this place on a map. Try to find it first by searching in the box. Ex:"Hilton Jeddah". Alternatively, search for a neatby place and click on the map to add a pin. If a postal address is not found, add one manually along with the accommodation type and rating if you have them.'
                />
            )}
            {circleMapModalStatus && (
                <CircleMapModal
                    circleData={updateObj}
                    handleActionBtn={updateAccommCircle}
                    setModalIsOpen={setCircleMapModalStatus}
                    type='accommodation'
                    modalTitle='Accommodation'
                    modalSubTitle='Add Location Pin'
                    text='Let us help you pin this place on a map. Try to find it first by searching in the box. Ex:"Hilton Jeddah". Alternatively, search for a neatby place and click on the map to add a pin. If a postal address is not found, add one manually along with the accommodation type and rating if you have them.'
                />
            )}
            {delConfirmModalState && (
                <ConfirmModal
                    setModalIsOpen={setDelConfirmModalState}
                    handleActionBtn={confirmDelete}
                />
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createAccommodation,
            updateAccommodation,
            arrangeAccommodation,
            deleteAccommodation
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationList);
