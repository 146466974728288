import * as types from 'store/actions/types';
import { defaultDay } from 'constants/experienceDetailConsts';

export function createItineraryDay(expType = 'DIGITAL') {
    return (dispatch) => {
        dispatch({
            type: types.ADD_DAY_ITINERARY,
            defaultDay: defaultDay('en', expType.toLowerCase())
        });
    };
}

export function arrangeItineraryDays(source, destination) {
    return (dispatch) => {
        dispatch({
            type: types.ARRANGE_DAYS_ITINERARY,
            source,
            destination
        });
    };
}

export function deleteItineraryDay(index) {
    return (dispatch) => {
        dispatch({ type: types.DELETE_DAY_ITINERARY, index });
    };
}
