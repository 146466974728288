import * as types from 'store/actions/types';
import { arrangeArray } from '../common';
export const updateMarketingSectionTitle = (draft, action) => {
    const { titleTxt, descHtml, type } = action;
    switch (type) {
        case types.UPDATE_TITLE_WHAT_TO_DO_MARKETING:
            draft.content_marketing.whatToDo.title = titleTxt;
            break;
        case types.UPDATE_TITLE_WHERE_TO_STAY_MARKETING:
            draft.content_marketing.whereToStay.title = titleTxt;
            break;
        case types.UPDATE_SUBTITLE_WHAT_TO_DO_MARKETING:
            draft.content_marketing.whatToDo.subtitle = titleTxt;
            break;
        case types.UPDATE_SUBTITLE_WHERE_TO_STAY_MARKETING:
            draft.content_marketing.whereToStay.subtitle = titleTxt;
            break;
        case types.UPDATE_DESCRIPTION_WHAT_TO_DO_MARKETING:
            draft.content_marketing.whatToDo.desc = descHtml;
            break;
        case types.UPDATE_DESCRIPTION_WHERE_TO_STAY_MARKETING:
            draft.content_marketing.whereToStay.desc = descHtml;
            break;
        case types.UPDATE_TITLE_INTRO_MARKETING:
            draft.content_marketing.intro.title = titleTxt;
            break;
        case types.UPDATE_SUBTITLE_INTRO_MARKETING:
            draft.content_marketing.intro.subtitle = titleTxt;
            break;
        case types.UPDATE_DESCRIPTION_INTRO_MARKETING:
            draft.content_marketing.intro.desc = descHtml;
            break;
        case types.UPDATE_DESCRIPTION_WHATS_INCLUDED_MARKETING:
            draft.content_marketing.whatsIncluded.desc = descHtml;
            break;
        case types.UPDATE_DESCRIPTION_THANKYOU_MARKETING:
            draft.content_marketing.thankYou.desc = descHtml;
            break;
        default:
            return draft;
    }

    return draft;
};
export const updateMarketingGallery = (draft, action) => {
    const { dataObj, type } = action;
    switch (type) {
        case types.UPDATE_GALLERY_INTRO_MARKETING:
            draft.content_marketing.gallery = dataObj;
            break;
        case types.UPDATE_GALLERY_WHAT_TO_DO_MARKETING:
            draft.content_marketing.whatToDo.gallery = dataObj;
            break;
        case types.UPDATE_GALLERY_WHERE_TO_STAY_MARKETING:
            draft.content_marketing.whereToStay.gallery = dataObj;
            break;
        case types.UPDATE_GALLERY_WHATS_INCLUDED_MARKETING:
            draft.content_marketing.whatsIncluded.gallery = dataObj;
            break;
        default:
            return draft;
    }

    return draft;
};

export const updateMarketingGalleryCaption = (draft, action) => {
    const { slideIndex, caption, type } = action;

    switch (type) {
        case types.UPDATE_GALLERY_CAPTION_INTRO_MARKETING:
            draft.content_marketing.gallery[slideIndex].caption = caption;
            break;
        case types.UPDATE_GALLERY_CAPTION_WHAT_TO_DO_MARKETING:
            draft.content_marketing.whatToDo.gallery[slideIndex].caption =
                caption;
            break;
        case types.UPDATE_GALLERY_CAPTION_WHERE_TO_STAY_MARKETING:
            draft.content_marketing.whereToStay.gallery[slideIndex].caption =
                caption;
            break;
        case types.UPDATE_GALLERY_CAPTION_WHATS_INCLUDED_MARKETING:
            draft.content_marketing.whatsIncluded.gallery[slideIndex].caption =
                caption;
            break;
        default:
            return draft;
    }

    return draft;
};

export const updateMarketingIncludes = (draft, action) => {
    const { index, type, includeData, includeType, prevType } = action;

    switch (type) {
        case types.ADD_WHATS_INCLUDED_MARKETING:
            if (includeType === 'includes') {
                draft.content_marketing.whatsIncluded.includes.push(
                    includeData
                );
            } else {
                draft.content_marketing.whatsIncluded.excluded.push(
                    includeData
                );
            }
            break;
        case types.ARRANGE_WHATS_INCLUDED_MARKETING:
            if (includeType === 'includes') {
                return arrangeArray(
                    draft,
                    action,
                    draft.content_marketing.whatsIncluded.includes
                );
            } else {
                return arrangeArray(
                    draft,
                    action,
                    draft.content_marketing.whatsIncluded.excluded
                );
            }
        case types.DELETE_WHATS_INCLUDED_MARKETING:
            if (includeType === 'includes') {
                draft.content_marketing.whatsIncluded.includes.splice(index, 1);
            } else {
                draft.content_marketing.whatsIncluded.excluded.splice(index, 1);
            }
            break;
        case types.UPDATE_WHATS_INCLUDED_MARKETING:
            if (includeType === 'includes') {
                if (prevType !== includeType) {
                    draft.content_marketing.whatsIncluded.includes.push(
                        includeData
                    );
                    draft.content_marketing.whatsIncluded.excluded.splice(
                        index,
                        1
                    );
                } else {
                    draft.content_marketing.whatsIncluded.includes[index] =
                        includeData;
                }
            } else {
                if (prevType !== includeType) {
                    draft.content_marketing.whatsIncluded.excluded.push(
                        includeData
                    );
                    draft.content_marketing.whatsIncluded.includes.splice(
                        index,
                        1
                    );
                } else {
                    draft.content_marketing.whatsIncluded.excluded[index] =
                        includeData;
                }
            }
            break;

        default:
            return draft;
    }

    return draft;
};

export const updateMarketingPolicies = (draft, action) => {
    const { index, type, policyData } = action;
    if (!draft.content_marketing.policies.data) {
        draft.content_marketing.policies.data = [];
    }
    switch (type) {
        case types.ADD_POLICIES_MARKETING:
            draft.content_marketing.policies.data.push(policyData);

            break;

        case types.DELETE_POLICIES_MARKETING:
            draft.content_marketing.policies.data.splice(index, 1);

            break;
        case types.UPDATE_POLICIES_MARKETING:
            draft.content_marketing.policies.data[index] = policyData;
            break;

        default:
            return draft;
    }

    return draft;
};

export const arrangTipsMarketing = (draft, action) => {
    const { type } = action;

    switch (type) {
        case types.ARRANGE_TIPS_WHAT_TO_DO_MARKETING:
            return arrangeArray(
                draft,
                action,
                draft.content_marketing.whatToDo.tips
            );
        case types.ARRANGE_TIPS_WHERE_TO_STAY_MARKETING:
            return arrangeArray(
                draft,
                action,
                draft.content_marketing.whereToStay.tips
            );
        case types.ARRANGE_TIPS_WHATS_INCLUDED_MARKETING:
            return arrangeArray(
                draft,
                action,
                draft.content_marketing.whatsIncluded.tips
            );

        default:
            return draft;
    }
};

export const deletTipMarketing = (draft, action) => {
    const { tipIndex, type } = action;

    switch (type) {
        case types.DELETE_TIP_WHAT_TO_DO_MARKETING:
            draft.content_marketing.whatToDo.tips.splice(tipIndex, 1);
            break;
        case types.DELETE_TIP_WHERE_TO_STAY_MARKETING:
            draft.content_marketing.whereToStay.tips.splice(tipIndex, 1);
            break;
        case types.DELETE_TIP_WHATS_INCLUDED_MARKETING:
            draft.content_marketing.whatsIncluded.tips.splice(tipIndex, 1);
            break;
        default:
            return draft;
    }

    return draft;
};

export const addTipMarketing = (draft, action) => {
    const { dataObj, type } = action;

    switch (type) {
        case types.ADD_TIP_WHAT_TO_DO_MARKETING:
            draft.content_marketing.whatToDo.tips.push(dataObj);
            break;
        case types.ADD_TIP_WHERE_TO_STAY_MARKETING:
            draft.content_marketing.whereToStay.tips.push(dataObj);
            break;
        case types.ADD_TIP_WHATS_INCLUDED_MARKETING:
            draft.content_marketing.whatsIncluded.tips.push(dataObj);
            break;
        default:
            return draft;
    }

    return draft;
};

export const updateTipMarketing = (draft, action) => {
    const { tipIndex, dataObj, type } = action;

    switch (type) {
        case types.UPDATE_TIP_WHAT_TO_DO_MARKETING:
            draft.content_marketing.whatToDo.tips[tipIndex] = {
                ...draft.content_marketing.whatToDo.tips[tipIndex],
                ...dataObj
            };
            break;
        case types.UPDATE_TIP_WHERE_TO_STAY_MARKETING:
            draft.content_marketing.whereToStay.tips[tipIndex] = {
                ...draft.content_marketing.whereToStay.tips[tipIndex],
                ...dataObj
            };
            break;
        case types.UPDATE_TIP_WHATS_INCLUDED_MARKETING:
            draft.content_marketing.whatsIncluded.tips[tipIndex] = {
                ...draft.content_marketing.whatsIncluded.tips[tipIndex],
                ...dataObj
            };
            break;
        default:
            return draft;
    }

    return draft;
};
