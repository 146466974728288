import React, { useState, useEffect, useCallback } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { ButtonsGroupNextPrev } from 'components/blocks/Button/Buttons';
import 'styles/embla.css';
import ImageModal from 'components/modals/ImageModal';
import { connect } from 'react-redux';
import { youtubeCode, galleryVidtType } from 'constants/experienceDetailConsts';
import { useOnlineStatus } from 'helpers/useOnlineStatus';

const CarouselContainer = ({
    objId,
    slides,
    globalState: { lang },
    edit,
    handleUpdateCaption,
    scroll = 1
}) => {
    const [viewportRef, embla] = useEmblaCarousel({
        dragFree: true,
        slidesToScroll: 1,
        align: 'start',
        skipSnaps: true,
        containScroll: 'trimSnaps'
    });

    const isOnline = useOnlineStatus();
    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

    const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
    const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
    const onSelect = useCallback(() => {
        if (!embla) return;
        setPrevBtnEnabled(embla.canScrollPrev());
        setNextBtnEnabled(embla.canScrollNext());
    }, [embla]);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedSlide, setSelectedSlide] = useState(null);
    useEffect(() => {
        if (!embla) return;
        embla.on('select', onSelect);
        onSelect();
    }, [embla, onSelect, slides]);

    useEffect(() => {
        if (!embla) return;
        embla.reInit();
        onSelect();
    }, [slides]);

    const handleActionBtn = (caption) => {
        handleUpdateCaption(selectedSlide, caption);
    };
    const openSesame = (slideIndex) => {
        setSelectedSlide(slideIndex);
        setIsOpen(true);
    };
    // console.log('slides', slides);
    const expImages = slides.map((singleImage) => {
        return {
            type: singleImage.type,
            src:
                singleImage.type === 'img'
                    ? singleImage.url + '-/preview/-/quality/lightest/'
                    : singleImage.url,
            thumbnail:
                singleImage.type === 'img'
                    ? singleImage.url + '-/preview/80x80/'
                    : singleImage.url,
            caption: singleImage.caption
        };
    });
    // console.log('expslides', expImages);

    return (
        <div className='relative max-w-6xl mx-auto pt-12 xl:pt-0 touch:pt-12 '>
            <div
                className={`mb-8 h-8 ${
                    nextBtnEnabled || prevBtnEnabled ? '' : 'hidden'
                }`}
            >
                <ButtonsGroupNextPrev
                    nextEnabled={nextBtnEnabled}
                    nextAction={scrollNext}
                    prevEnabled={prevBtnEnabled}
                    prevAction={scrollPrev}
                    // isPrev={!prevBtnState}
                    className={`z-30 touch:hidden`}
                    // rtl={rtl}
                />
            </div>
            <div className='embla__viewport' ref={viewportRef}>
                <div className='embla__container'>
                    {expImages.map((slide, index) => (
                        <div
                            className='embla__slide x4 md:x4 lg:x6'
                            key={slide.src}
                            onClick={isOnline ? () => openSesame(index) : null}
                        >
                            <div className='embla__slide__inner h-32 rounded-md relative'>
                                <img
                                    className={`object-cover  lg:rounded-lg overflow-hidden w-full h-full transform ${
                                        slide.type !== 'img' ? 'scale-120' : ''
                                    } `}
                                    src={`${
                                        slide.type === 'img'
                                            ? slide.src +
                                              '-/scale_crop/240x240/smart_objects_faces_points/'
                                            : slide.src
                                    }`}
                                    alt={slide.caption}
                                />
                                {slide.type === 'youtube' && (
                                    <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                                        <i className='ri-play-fill text-2xl text-white'></i>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {isOpen && (
                <ImageModal
                    edit={edit}
                    setModalIsOpen={setIsOpen}
                    handleActionBtn={handleActionBtn}
                    slide={slides[selectedSlide]}
                    galleryVidtType={galleryVidtType}
                    hintObj={''}
                />
            )}
            {/* <FeedbackBox objId={objId} label='Gallery' /> */}
        </div>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps)(CarouselContainer);
