/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import LayoutOverview from 'components/layouts/LayoutOverview';
import LayoutDay from 'components/layouts/LayoutDay';
import LayoutLoading from 'components/layouts/LayoutLoading';
import LayoutExperienceSidebar, {
    LayoutExperienceSidebarOverview,
    LayoutExperienceSidebarDay,
    LayoutExperienceSidebarMarketing
} from 'components/layouts/LayoutExperienceSidebar';
import { handleRowReverse } from 'helpers/FEutils';
import {
    toggleEdit,
    toggleToolTips,
    toggleTour,
    setIsOwnerMode,
    setIsAdminStaffMode,
    setIsPublishedViewMode
    //  setIsEduContentMode
} from 'store/actions/globalState/master';
import { fetchExperiencePublished } from 'fetch/experiences';
import { clearExperience } from 'store/actions/experienceDetails/general';
import translations from 'constants/translations';
import { animateScroll as scroll } from 'react-scroll';
import LayoutMenuEditPublic from 'components/layouts/LayoutMenuEditPublic';
import { useNavigate, Navigate, useParams } from 'react-router-dom';

import 'styles/shepherd.css';
import LayoutMarketing from 'components/layouts/LayoutMarketing';
import { MenuRefsHolder } from 'helpers/MenuRefsHolder';

import useStatuses from 'helpers/useStatuses';
import { isAdminStaffFunc, isOwnerFunc } from 'helpers/statusHelper';
import ErrorPage from 'components/pages/ErrorPage';
import { isPurchasedPublicPage } from '../../helpers/misc';

import LayoutNavbar from 'components/layouts/LayoutNavbar';


const getDimensions = (ele) => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return {
        height,
        offsetTop,
        offsetBottom
    };
};

const scrollTo = (ele, width) => {
    const { offsetTop } = getDimensions(ele);
    if (width > 640) {
        scroll.scrollTo(offsetTop, { smooth: true });
    } else scroll.scrollTo(offsetTop, { duration: 0, smooth: false });
    // window.scrollTo(0, offsetTop);
};

const PageExperienceSinglePublic = (props) => {
    const history = useNavigate();
    const {
        toggleEdit,
        toggleToolTips,
        setIsOwnerMode,
        setIsAdminStaffMode,
        setIsPublishedViewMode,
        // setIsEduContentMode,
        globalState: {
            lang,

            isOwner,

            siteData: { loading: siteDataLoading }
        },
        auth: { user, isAuthenticated },
        // match: {
        //     params: { day = 0, id }
        // },
        experienceDetails,
        experienceDetails: { loading, experience, invalid, user: author, type, publicAccess = true },
        fetchExperiencePublished
    } = props;

    const {day = 0, id} = useParams();

    const isValidDay = (day) => {
        let isValid = true;
        const tmpDay = Number(day);
        if (isNaN(tmpDay)) {
            isValid = false;
        } else {
            if (tmpDay >= 0 && tmpDay <= experience.itinerary.trip_days.length) isValid = true;
            else isValid = false;
        }
        return isValid;
    };

    const isSubscribed = () => {
        if (user?.accessible_contents?.length > 0) {
            const res = user?.accessible_contents.find((item) => item.experience_id === id);
            // console.log('found it?', res);
            return res;
        } else console.log('Not found');
        return false;
    };
    // const { isOwner, isEduContent } = useStatuses();
    // const isPublishedView = isPublishedViewFunc();
    // const isAdminStaff = isAdminStaffFunc(userRole);

    // useEffect(() => {
    //     setIsAdminStaffMode(isAdminStaff);
    //     setIsPublishedViewMode(isPublishedView);
    //     setIsOwnerMode(isOwner);
    //     setIsEduContentMode(isEduContent);
    // }, [isAdminStaff, isPublishedView, isOwner, isEduContent]);

    const [menuIsOpen, setMenuIsOpen] = useState(true);
    const [visibleSection, setVisibleSection] = useState();
    const dayIndex = Number(day) - 1; // Array starts at zero
    const [pageLoad, setPageLoading] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + 150;
            const selected = MenuRefsHolder.sectionRefs.find(({ section, ref }) => {
                const ele = ref;

                if (ele) {
                    const { offsetBottom, offsetTop } = getDimensions(ele);
                    return scrollPosition > offsetTop && scrollPosition < offsetBottom;
                }
            });

            if (selected && selected.section !== visibleSection) {
                setVisibleSection(selected.section);
            } else if (!selected && visibleSection) {
                setVisibleSection(undefined);
            }
        };

        handleScroll();
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [visibleSection]);

    const rtl = !!translations[lang].rtl;

    const { isEduContent } = useStatuses();
    const isAdminStaff = isAdminStaffFunc(user?.role);

    // useEffect(() => {
    //     setIsAdminStaffMode(isAdminStaff);
    //     setIsEduContentMode(isEduContent);
    // }, [isAdminStaff, isEduContent]);

    // useEffect(() => {
    //     const isOwnerState = isOwnerFunc(author?.id);
    //     setIsOwnerMode(isOwnerState);
    // }, [author]);

    // const fetchFunc = isPurchasedPublicPage(window.location.href) ? : useRef(fetchExperiencePublished);
    const fetchFunc = useRef(fetchExperiencePublished);
    useEffect(() => {
        if (pageLoad && !siteDataLoading) {
            setPageLoading(true);
            fetchFunc.current(id).then((res) => {
                setPageLoading(false);
                if (res.error || invalid) {
                    history(`/403`, { replace: true });
                } else {
                    setPageLoading(false);
                    toggleEdit(false);
                }
            });
        }
    }, [siteDataLoading, pageLoad, invalid]);

    return (
        <>
            {/* <ShepherdTour
                steps={userId == author?._id ? singleSteps : wanderSteps}
                tourOptions={tourOptions}
            > */}
            <LayoutNavbar />
            {publicAccess || isAdminStaff || isOwner || isSubscribed() ? (
                <div className={`${day === 'marketing' ? '' : 'overflow-x-hidden'} no-scrollbar pt-16 pb-32 min-h-screen ${handleRowReverse(rtl).flex} `}>
                    {!pageLoad && !loading && experienceDetails && !invalid ? (
                        isValidDay(day) ? (
                            <>
                                <LayoutExperienceSidebar
                                    publicView
                                    dayIndex={dayIndex}
                                    experienceId={id}
                                    menuIsOpen={menuIsOpen}
                                    handleMenuIsOpen={setMenuIsOpen}
                                >
                                    {dayIndex === -1 && (
                                        <LayoutExperienceSidebarOverview
                                            publicView
                                            handleScroll={scrollTo}
                                            sections={MenuRefsHolder.sectionsMainNav}
                                            visibleSection={visibleSection}
                                            menuIsOpen={menuIsOpen}
                                            handleMenuIsOpen={setMenuIsOpen}
                                        />
                                    )}
                                    {dayIndex >= 0 && (
                                        <LayoutExperienceSidebarDay
                                            publicView
                                            handleScroll={scrollTo}
                                            sections={MenuRefsHolder.sectionsMainNav}
                                            visibleSection={visibleSection}
                                            dayIndex={dayIndex}
                                            menuIsOpen={menuIsOpen}
                                            handleMenuIsOpen={setMenuIsOpen}
                                        />
                                    )}
                                </LayoutExperienceSidebar>
                                <LayoutMenuEditPublic author={author} handleEdit={toggleEdit} handleToolTips={toggleToolTips} rtl={rtl} />
                                <div
                                    className={`transition duration-500 ease-in-out transform ${
                                        menuIsOpen ? handleRowReverse(rtl).pageTranslate : 'translate-x-0'
                                    }`}
                                >
                                    {dayIndex === -1 && (
                                        <LayoutOverview
                                            type={type}
                                            publicView={true}
                                            sectionRefs={MenuRefsHolder.sectionRefs}
                                            sectionsMainNav={MenuRefsHolder.sectionsMainNav}
                                        />
                                    )}

                                    {dayIndex >= 0 && <LayoutDay dayIndex={dayIndex} sections={MenuRefsHolder.sectionsMainNav} />}
                                </div>
                            </>
                        ) : day === 'blablasomething' ? (
                            <>
                                <LayoutExperienceSidebar
                                    publicView
                                    dayIndex={experience.itinerary.trip_days.length}
                                    experienceId={id}
                                    menuIsOpen={menuIsOpen}
                                    handleMenuIsOpen={setMenuIsOpen}
                                >
                                    <LayoutExperienceSidebarMarketing
                                        handleScroll={scrollTo}
                                        sections={MenuRefsHolder.sectionsMainNav}
                                        visibleSection={visibleSection}
                                        menuIsOpen={menuIsOpen}
                                        handleMenuIsOpen={setMenuIsOpen}
                                    />
                                </LayoutExperienceSidebar>

                                <div
                                    className={`transition duration-500 ease-in-out transform ${
                                        menuIsOpen ? handleRowReverse(rtl).pageTranslate : 'translate-x-0'
                                    }`}
                                >
                                    {dayIndex === -1 && (
                                        <LayoutOverview
                                            sectionRefs={MenuRefsHolder.sectionRefs}
                                            sectionsMainNav={MenuRefsHolder.sectionsMainNav}
                                            type={type}
                                            sections={MenuRefsHolder.sectionsMainNav}
                                        />
                                    )}

                                    {dayIndex >= 0 && <LayoutDay dayIndex={dayIndex} sections={MenuRefsHolder.sectionsMainNav} />}
                                    {day === 'marketing' && <LayoutMarketing type={type} sections={MenuRefsHolder.sectionsMainNav} />}
                                </div>
                            </>
                        ) : (
                            <ErrorPage err={403} />
                        )
                    ) : (
                        <LayoutLoading />
                    )}
                </div>
            ) : (
                <ErrorPage err={403} />
            )}
            {/* </ShepherdTour> */}
        </>
    );
};

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,

    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleEdit,
            toggleToolTips,
            toggleTour,
            setIsOwnerMode,
            setIsAdminStaffMode,
            setIsPublishedViewMode,
            //  setIsEduContentMode,
            fetchExperiencePublished,
            clearExperience
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageExperienceSinglePublic);
