import React from 'react';
import moment from 'moment';
// import { destinationObj } from 'helpers/LocaleHelper';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import KreatorBadge from 'components/blocks/KreatorBadge';
import { toggleNav } from 'store/actions/globalState/master';
import { Clock, MapPin } from 'lucide-react';
// import { toggleLang, toggleNav } from 'store/actions/globalState/master';
// import { User, Clock, MapPin, Users } from 'lucide-react';
// import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import Icons from 'components/blocks/Icons';
import Image from 'components/blocks/Image';
import ExperienceCardBar from 'components/blocks/ExperienceCardBar';
import { country } from 'helpers/LocaleHelper';
import { getBtnLabel, getBtnLabelPublished } from 'helpers/misc.js';
import { isOwnerFunc } from 'helpers/statusHelper';
import PillType from './PillType';

const baseUrl = {
    kreatorPage: '/kreator',
    experiencePage: '/experience',
    avatar: '/assets/media/kreators',
    experienceImageFeatured: '/assets/media/results'
};

// const currencyPrefix = '$US';

const ExperienceCard = ({
    disabled,
    handlePublish,
    viewPublished = false,
    type,
    expId,
    pubId,
    data,
    data: {
        completion,
        status: experienceStatus,
        inPublished,
        expAuthor,
        isPublished,
        isLive,
        createdAt,
        updatedAt,
        featured_image,
        title,
        days,
        places_lists
    },
    globalState: { lang, isSuperAdmin },
    toggleNav,
    author,
    auth: {
        user: { role: userRole, _id: userId }
    },
    expReportBtn,
    guidedActionBtn,
    deleteActionBtn,
    submitActionBtn,
    handleFeedbackBtn,
    handleCloneBtn,
    index,
    isAdminStaff,
    myPublished=false,
    isEdu
}) => {
    const linkType = type.toLowerCase() === 'guided' ? 'marketing' : '0';
    const expPath = !viewPublished ? process.env.REACT_APP_BASENAME_EXPERIENCE : process.env.REACT_APP_BASENAME_EXPERIENCE_PUBLISHED;

    // const isAdminStaff =
    // userRole === process.env.REACT_APP_STRAPI_ADMIN_ROLE_ID ||
    // userRole === process.env.REACT_APP_STRAPI_ADMIN_ROLE_ID;
    const isOwner = isOwnerFunc(expAuthor);
    // const isOwner = expAuthor._id === userId;
    const handleExpReportAction = () => {
        expReportBtn(expId);
    };
    const handleGuidedAction = () => {
        guidedActionBtn(expId);
    };

    const handleDigitalAction = () => {
        guidedActionBtn(expId);
    };

    const handleDelete = () => {
        deleteActionBtn(expId);
    };
    const submitExperience = () => {
        submitActionBtn(expId);
    };

    const submitPublishStatus = () => {
        handlePublish(expId);
    };

    const handleFeedback = () => {
        handleFeedbackBtn(expId);
    };
    const handleClone = () => {
        handleCloneBtn(expId);
    };
    const getStatusColor = (liveStatus) => {
        let statusColor = '';

        if (viewPublished) {
            if (isPublished) statusColor = 'bg-green-300';
            else statusColor = 'bg-gray-300';
        } else {
            switch (experienceStatus) {
                case 'initial':
                    statusColor = 'bg-gray-300';
                    break;
                case 'submitted':
                    statusColor = 'bg-blue-300';
                    break;
                case 'pending':
                    statusColor = 'bg-yellow-300';
                    break;
                case 'approved':
                    statusColor = 'bg-green-300';
                    break;
                default:
            }
        }

        return statusColor;
    };

    return (
        <div className={`my-3 px-2 lg:my-4 lg:px-2 `}>
            <div className='group-scope relative bg-transparent transition-all duration-300 transform rounded-xl hover:shadow-xl hover:-translate-y2-1 hover:bg-white'>
                <div className=''>{!isOwner && <KreatorBadge author={author} baseUrl={baseUrl} nolink fixEdge={true} />}</div>
                {isOwner || isAdminStaff ? (
                    <ExperienceCardBar
                        viewPublished={viewPublished}
                        completion={completion}
                        type={type}
                        handleExpReport={handleExpReportAction}
                        handleGuided={handleGuidedAction}
                        handleDigital={handleDigitalAction}
                        handleDelete={handleDelete}
                        isOwner={isOwner}
                        isAdminStaff={isAdminStaff}
                        experienceStatus={experienceStatus}
                        inPublished={inPublished}
                        feedbackAction={handleFeedback}
                        cloneAction={handleClone}
                        isSuperAdmin={isSuperAdmin}
                        myPublished={myPublished}
                    />
                ) : null}

                <div className=''>
                    <Link
                        to={`${expPath}/${expId}/${linkType}`}
                        onClick={(e) => (disabled ? e.preventDefault() : toggleNav(false))}
                        className={disabled ? 'pointer-events-none cursor-default' : ''}
                    >
                        <div
                            // className={` w-full h-40 xs360:h-44 xs390:h-52 xs410:h-56 sm:h-64 md:h-64 lg:h-64 xl:h-72 2xl:h-96 relative rounded-xl overflow-hidden  bg-gray-200 `}
                            className={` w-full h-56 md:h-36 lg:h-44 xl:h-56 relative rounded-xl overflow-hidden  bg-gray-200 `}
                        >
                            {featured_image ? (
                                <Image
                                    disabled={disabled}
                                    // groupScope={true}
                                    size='600'
                                    src={featured_image}
                                    // className='object-cover w-full h-40 xs360:h-44 xs390:h-52 xs410:h-56 sm:h-64 md:h-64 lg:h-64 xl:h-72 2xl:h-96'
                                    className='object-cover w-full md:h-36 lg:h-44 xl:h-56'
                                />
                            ) : (
                                <div
                                    // className='rounded-xl bg-gray-200 w-full h-40 xs360:h-44 xs390:h-52 xs410:h-56 sm:h-64 md:h-64 lg:h-64 xl:h-72 2xl:h-96'
                                    className='rounded-xl bg-gray-200 w-full md:h-36 lg:h-44 xl:h-56'
                                >
                                    <div className='relative h-full'>
                                        <div className='text-xs whitespace-nowrap tracking-widest uppercase absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                                            <Icons iName='IMAGEALT2' />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Link>
                </div>
                <div className='flex justify-around w-full transform -mt-4 px-4'>
                    {/* <div className='uppercase rounded-full h-8 flex justify-center items-center bg-gray-900 text-xxs text-kn-primary tracking-widest px-6'>
                        {type || 'digital'}
                    </div> */}
                    <PillType type={type} />
                </div>
                <div className='p-2 sm:p-4'>
                    <div className='flex items-center justify-between leading-tight text-black font-sans text-xs md:text-sm'>
                        <span className='flex'>
                            {title ? (
                                title
                            ) : (
                                <span className='flex'>
                                    <span className='w-48 bg-gray-300 rounded-xl h-3' />
                                </span>
                            )}
                        </span>
                    </div>
                    <div className='mt-2 flex flex-wrap items-center font-sans text-xs text-gray-900'>
                        <div className='flex items-center mr-8 py-1'>
                            <span className='text-kn-primary mr-2'>
                                <MapPin size={18} />
                            </span>
                            {places_lists.length > 0 ? (
                                places_lists.map((item, index, itemArray) => {
                                    return (
                                        <span key={`${item.name}_${index}`}>
                                            <span className=''>{country(lang, item.code)}</span>
                                            {index < itemArray.length - 1 && <span className='px-1'>.</span>}
                                        </span>
                                    );
                                })
                            ) : (
                                <span key={index} className='w-20 bg-gray-300 rounded-full h-2' />
                            )}
                        </div>
                        <div className='flex items-center mr-8 py-1'>
                            <span className='text-kn-primary mr-2'>
                                <Clock size={18} />
                            </span>

                            {days ? `${days}  ${days > 1 ? 'Days' : 'Day'}` : <span className='w-8 bg-gray-300 rounded-full h-2' />}
                        </div>
                    </div>
                    {isEdu ? (
                        <>
                            <div className='flex items-center gap-3 pt-4 mt-3 border-t border-gray-300'>
                                <div
                                    className={`flex flex-1 bg-purple-200 font-medium text-purple-800 h-8 rounded-lg items-center justify-center uppercase text-xs tracking-wide`}
                                >
                                    INSPIRATIONAL SAMPLE
                                </div>
                            </div>
                            <div className='flex justify-center items-center mt-6 w-full rounded-lg h-10 text-black text-sm font-medium tracking-tight'>
                                Learn to build better content
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='flex items-center gap-3 pt-4 mt-3 border-t border-gray-300'>
                                <div className='text-xs lg:text-sm'>Status</div>
                                <div
                                    className={`flex flex-1 ${getStatusColor()} h-8 rounded-lg items-center justify-center uppercase text-xxs lg:text-xs tracking-wide`}
                                >
                                    {!viewPublished && experienceStatus ? translations[lang][experienceStatus] : ''}
                                    {viewPublished ? (isPublished ? 'published' : 'not published yet') : null}
                                </div>
                            </div>
                            {!viewPublished && experienceStatus === 'approved' ? (
                                <div className='flex justify-between items-center gap-3 pb-4 mt-3 border-b border-gray-300'>
                                    <div className='text-xs xl:text-sm'>Published in Marketplace</div>
                                    <div
                                        className={`flex flex-none ${
                                            isLive ? 'bg-green-300' : 'bg-yellow-300'
                                        } h-8 px-4 rounded-lg items-center justify-center uppercase text-xxs lg:text-xs tracking-wide`}
                                    >
                                        {isLive ? 'yes' : 'awaiting'}
                                    </div>
                                </div>
                            ) : null}
                            {/* <div>
                                { isLive ? "Its alive!!!!!" : "its dead!!"}
                            </div> */}
                            {false && experienceStatus && experienceStatus !== 'submitted' && (isOwner || (isAdminStaff && isSuperAdmin)) && !viewPublished && (
                                <button
                                    className='outline-none focus:outline-none transition-colors duration-300 hover:bg-kn-primary flex justify-center items-center mt-6 w-full rounded-lg border-2 border-kn-primary h-10 text-black text-sm font-bold tracking-tight hover:text-white'
                                    onClick={submitExperience}
                                >
                                    {getBtnLabel(experienceStatus)}
                                </button>
                            )}
                        </>
                    )}
                    {isAdminStaff && isSuperAdmin && viewPublished && (
                        <button
                            className='outline-none focus:outline-none transition-colors duration-300 hover:bg-kn-primary flex justify-center items-center mt-6 w-full rounded-lg border-2 border-kn-primary h-10 text-black text-sm font-bold tracking-tight hover:text-white'
                            onClick={submitPublishStatus}
                        >
                            {getBtnLabelPublished(isPublished)}
                        </button>
                    )}
                    {isAdminStaff ? (
                        <div className='mt-4'>
                            <div className='flex flex-wrap items-center gap-2'>
                                <span className='text-xxs bg-green-100 text-green-600 whitespace-nowrap rounded-full px-2 py-0.5'>
                                    {`Created ${moment(createdAt).format('MMM Do YYYY')}`}
                                </span>
                                <span className='text-xxs bg-blue-100 text-blue-600 whitespace-nowrap rounded-full px-2 py-0.5'>
                                    {`Updated ${moment(updatedAt).format('MMM Do YYYY')}`}
                                </span>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleNav
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ExperienceCard);
