import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import translations from 'constants/translations';
import GenericDescription from 'components/blocks/GenericDescription';

import ExpSubsection from 'components/blocks/ExpSubsection';
import { Section__Title } from 'components/blocks/Sections';

import CardXl from 'components/blocks/CardXl';
import FeedbackBox from 'components/blocks/FeedbackBox';
import MadeWithLove from 'components/blocks/MadeWithLove';
import { PillLogo } from 'components/blocks/Pills';

function SectionThankyou({
    actionType,

    marketingData: {
        thankYou: { title, subtitle, desc, objId }
    },
    globalState: { lang, edit }
}) {
    const rtl = !!translations[lang].rtl;

    return (
        <div className='w-full mt-8'>
            <div className='flex items-center justify-center -mt-8 lg:mt-12'>
                <PillLogo />
            </div>

            <Section__Title
                title={'Thank you'}
                edit={edit}
                hasData
                isVisible={true}
                className={`${
                    edit
                        ? '-mb-12 mt-8 md:mt-8 md:-mb-16 lg:-mb-20 lg:mt-16'
                        : '-mb-16 mt-8 md:mt-8 md:-mb-20 lg:-mb-24 lg:mt-16'
                }`}
            />

            <CardXl
                edit={edit}
                rtl={false}
                transparent
                nominimize={true}
                isFeedbackMode={false}
            >
                <GenericDescription
                    nohover
                    actionType={actionType}
                    descriptionHtml={desc}
                    objId={objId?.description}
                    title='Thank you note'
                    subtitle='Description'
                    textSize='text-xl'
                    textSizeEmpty='text-xl'
                    justifyClass='text-center'
                    defaultHtml={`Use this space to add a personal message to the traveler such as "I really hope you enjoyed the weekend experience in Paris. Please let me know if you have any comments or suggestions to make it better. My best John Doe"`}
                />

                <MadeWithLove className={`${edit ? '' : '-mt-4'}`} />
            </CardXl>
        </div>
    );
}

const mapStateToProps = (state) => ({
    marketingData: state.experienceDetails.content_marketing,
    globalState: state.globalState,
    experienceDetails: state.experienceDetails
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionThankyou);
