import Image from 'components/blocks/Image';
import { PillLogo } from 'components/blocks/Pills';
import Button from 'components/blocks/Title/Button';
import GradientTitle from 'components/blocks/Title/GradientTitle';
import LandingCapture from 'components/landing/LandingCapture';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Row, Section, Column, OptionCard } from 'components/layouts/LayoutElements';
import { useEffect } from 'react';

const LayoutBasicsGuided = () => {
    const path = window.location.hash;

    useEffect(() => {
        // const path = window.location.hash;
        if (path && path.includes('#')) {
            setTimeout(() => {
                const id = path.replace('#', '');
                const el = window.document.getElementById(id);
                const r = el.getBoundingClientRect();
                window.top.scroll({
                    top: r.top
                    // behavior: 'smooth'
                });
            }, 100);
        }
    }, [path]);

    return (
        <>
            <Section background='bg-white' padding='py-16 md:pt-24 md:pb-24 lg:pb-16' margins='mt-16'>
                <Row justify='justify-centera' margins='mb-2 mx-auto'>
                    <Link
                        to={{ pathname: '/basics', hash: '#guided' }}
                        className='flex bg-green-400 rounded-full pl-4 pr-4 py-1.5 gap-2 items-center hover:bg-gray-900 hover:text-white'
                    >
                        <i className='ri-arrow-left-line'></i>
                        <span>Back to Basics</span>
                    </Link>
                </Row>
                <Row justify='justify-centera'>
                    <GradientTitle label='Guided Experience Sample' textSize='text-4xl md:text-5xl 2xl:text-6xl' justify='' containerClass='mb-2 md:mb-4' />
                </Row>
                <Row justify='justify-centera'>
                    <Column textSize='text-2xl'>
                        <h3 className='text-2xl md:text-3xl xl:text-4xl font-bold md:mb-4 leading-tight tracking-tight mb-4'>
                            How is a guided experience structured?
                        </h3>
                        <div className='text-lg'>
                            We've seen that an experience is made of 3 main elements. Here we dive deeper into the various elements of each section of a guided
                            experience.
                        </div>
                    </Column>
                </Row>
                <Row justify='justify-center'>
                    <div className='grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-4 lg:gap-16 mt-16'>
                        <div className='w-full mb-4 md:mb-0  flex flex-col md:gap-4 lg:gap-0'>
                            <Link
                                to={{ pathname: '/basics/digital', hash: '#overview' }}
                                className='rounded-xl overflow-hidden border-2 border-transparent hover:border-green-400'
                            >
                                <img
                                    src='https://ucarecdn.com/1fd79fdb-06ac-4515-b6a5-5288ab819bba/-/preview/-/quality/smart/'
                                    className='lg:h-72 w-full object-contain '
                                />
                            </Link>
                            <div className='mx-auto mt-4 text-green-400 font-semibold text-xl'>Overview Section</div>
                        </div>
                        <div className='w-full mb-4 md:mb-0  flex flex-col md:gap-4 lg:gap-0'>
                            <Link
                                to={{ pathname: '/basics/digital', hash: '#itinerary' }}
                                className='rounded-xl overflow-hidden border-2 border-transparent hover:border-green-400'
                            >
                                <img
                                    src='https://ucarecdn.com/862f4916-6e04-4dde-93cc-a0d75bcaae89/-/preview/-/quality/smart/'
                                    className='lg:h-72 w-full object-contain '
                                />
                            </Link>
                            <div className='mx-auto mt-4 text-green-400 font-semibold text-xl'>Itinerary Section</div>
                        </div>
                        <div className='w-full mb-4 md:mb-0  flex flex-col md:gap-4 lg:gap-0'>
                            <Link
                                to={{ pathname: '/basics/digital', hash: '#marketing' }}
                                className='rounded-xl overflow-hidden border-2 border-transparent hover:border-green-400'
                            >
                                <img
                                    src='https://ucarecdn.com/a10e915d-a191-4e10-91ac-2ed99c56a15f/-/preview/-/quality/smart/'
                                    className='lg:h-72 w-full object-contain '
                                />
                            </Link>
                            <div className='mx-auto mt-4 text-green-400 font-semibold text-xl'>Marketing Section</div>
                        </div>
                    </div>
                </Row>
            </Section>
            <Section id='overview' background='bg-gray-100 bg-opacity-50a' padding='py-16' minHeight=''>
                {/* <Row justify='justify-center' flex='flex-row' align='items-center'> */}

                <Row justify='justify-center' flex='flex-row'>
                    <GradientTitle
                        label='The Overview Section'
                        textSize='text-4xl md:text-5xl 2xl:text-6xl text-center'
                        justify='justify-center'
                        containerClass=''
                    />
                </Row>
            </Section>
            <Section pill='top' background='bg-white' padding='py-24'>
                <Row justify='justify-center' width='w-full'>
                    <img src='https://ucarecdn.com/159b06e8-922d-456c-9d2e-a1576483afd2/' className='w-full object-contain' />
                </Row>
            </Section>
            <Section id='itinerary' background='bg-gray-100 bg-opacity-50a' padding='py-16' minHeight=''>
                {/* <Row justify='justify-center' flex='flex-row' align='items-center'> */}

                <Row justify='justify-center' flex='flex-row'>
                    <GradientTitle
                        label='The Itinerary Section'
                        textSize='text-4xl md:text-5xl 2xl:text-6xl text-center'
                        justify='justify-center'
                        containerClass=''
                    />
                </Row>
            </Section>

            <Section pill='top' background='bg-white' padding='py-24'>
                <Row justify='justify-center' width='w-full'>
                    <img src='https://ucarecdn.com/b4a56fcb-05ad-496e-ab7f-4783dd14dc77/' className='w-full object-contain' />
                </Row>
            </Section>
            <Section id='marketing' background='bg-gray-100 bg-opacity-50a' padding='py-16' minHeight=''>
                {/* <Row justify='justify-center' flex='flex-row' align='items-center'> */}

                <Row justify='justify-center' flex='flex-row'>
                    <GradientTitle
                        label='The Marketing Section'
                        textSize='text-4xl md:text-5xl 2xl:text-6xl text-center'
                        justify='justify-center'
                        containerClass=''
                    />
                </Row>
            </Section>

            <Section pill='top' background='bg-white' padding='py-24'>
                <Row justify='justify-center' width='w-full'>
                    <img src='https://ucarecdn.com/5a5531d2-611e-4a41-a3da-5fd34dfbd17b/' className='w-full object-contain' />
                </Row>
            </Section>
        </>
    );
};

export default LayoutBasicsGuided;
