import React, { useState, useRef, useEffect, useCallback } from 'react';
import KnIcons from 'constants/KnIcons';
import {
    ButtonsGroupNextPrev,
    ButtonsNextPrev
} from 'components/blocks/Buttons';
import Icons from 'components/blocks/Icons';
import ImageModal from 'components/modals/ImageModal';
import { connect } from 'react-redux';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { handleRowReverse } from 'helpers/FEutils';
import { PillLogo } from 'components/blocks/Pills';
import { youtubeCode, galleryVidtType } from 'constants/experienceDetailConsts';
import FeedbackBox from 'components/blocks/FeedbackBox';
import classNames from 'classnames';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useOnlineStatus } from 'helpers/useOnlineStatus';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        partialVisibilityGutter: 40 // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        partialVisibilityGutter: 2 // this is needed to tell the amount of px that should be visible.
    }
};

const SliderNew = ({
    objId,
    slides,
    globalState: { lang, isPrintMode, isFeedbackMode },
    edit,
    handleUpdateCaption
}) => {
    const isOnline = useOnlineStatus();
    const rtl = !!translations[lang].rtl;
    const [nextBtnState, setNextBtnState] = useState(true);
    const [prevBtnState, setPrevBtnState] = useState(false);

    const [selectedSlide, setSelectedSlide] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    // Modal for slide
    const openSlide = (nb) => {
        setSelectedSlide(nb);
        setIsOpen(true);
    };

    // Build slides JSX
    const buildSlides = () => {
        return slides.map((slide, i) => {
            const { url, caption, type, vidCode, objId } = slide;

            return (
                <div
                    key={`${url}_${i}`}
                    className={`relative ${
                        isPrintMode
                            ? ''
                            : 'inline-blocka px-1a md:px-1.5a lg:px-2a'
                    } `}
                >
                    {!isFeedbackMode && (
                        <FeedbackBox
                            inset={`${
                                isPrintMode
                                    ? '-inset-2'
                                    : 'inset-x-0 -inset-y-2'
                            }`}
                            dot='top-0 right-0'
                            objId={objId}
                            label='Image'
                            zLevel='z-0'
                            tmp='ring-8'
                            // type='image'
                        />
                    )}
                    <div
                        className={`${
                            isOnline ? 'cursor-pointer' : ''
                        } group relative ${
                            isPrintMode ? '' : ''
                        } overflow-hidden rounded-3xl ring-white ring-4 ring-opacity-0 hover:ring-opacity-100 transition-all duration-300 `}
                        onClick={
                            isOnline
                                ? () => {
                                      openSlide(i);
                                  }
                                : null
                        }
                    >
                        {/* {type === 'youtube' && galleryVidtType === 'popup' && (
                            <img
                                alt=''
                                src={`https://img.youtube.com/vi/${vidCode}/0.jpg`}
                                className='object-cover w-full h-72 md:h-96 lg:h-96 xl:h-128'
                            />
                        )} */}
                        {type === 'youtube' && (
                            <div className='object-cover w-full h-72 md:h-96 lg:h-96 xl:h-128'>
                                {youtubeCode(vidCode)}
                            </div>
                        )}
                        {type !== 'youtube' && (
                            <img
                                alt=''
                                data-blink-src={url}
                                // src={`${url}-/format/webp/-/quality/lighter/-/progressive/yes/-/max_icc_size/10/-/scale_crop/720x720/smart_faces_objects_points/`}
                                className='object-cover w-full h-72 md:h-96 lg:h-96 xl:h-128 '
                            />
                        )}

                        {caption && (
                            <div
                                className={`${
                                    isPrintMode
                                        ? 'opacity-100 translate-y-0'
                                        : 'opacity-0 translate-y-4'
                                } group-hover:opacity-100  transition-all transform group-hover:translate-y-0 duration-500 z-50 absolute bottom-4 left-4 right-4`}
                            >
                                <div
                                    className={`relative bg-kn-yellow ${
                                        isPrintMode
                                            ? 'text-xxs rounded-lg px-2 py-2 inline-block'
                                            : 'text-sm md:max-w-xs w-max xl:max-w-xs p-4 rounded-2xl'
                                    } text-gray-900 whitespace-pre-wrap `}
                                >
                                    {caption}
                                </div>
                            </div>
                        )}
                    </div>
                    {isFeedbackMode && (
                        <FeedbackBox
                            inset={`${
                                isPrintMode
                                    ? '-inset-2'
                                    : 'inset-x-0 -inset-y-2'
                            }`}
                            dot='top-0 right-0'
                            objId={objId}
                            label='Image'
                            // zLevel=''
                        />
                    )}
                </div>
            );
        });
    };

    const btnSide = handleRowReverse(rtl).right;

    const handleActionBtn = (caption) => {
        handleUpdateCaption(selectedSlide, caption);
    };
    return (
        <>
            {isOpen && (
                <ImageModal
                    edit={edit}
                    setModalIsOpen={setIsOpen}
                    handleActionBtn={handleActionBtn}
                    slide={slides[selectedSlide]}
                    galleryVidtType={galleryVidtType}
                    hintObj={''}
                />
            )}
            {!isPrintMode ? (
                <div className='mb-16 relative'>
                    <Carousel
                        responsive={responsive}
                        additionalTransfrom={0}
                        customDot={<CustomDot slides={slides} />}
                        arrows
                        autoPlay={false}
                        autoPlaySpeed={60000}
                        centerMode={false}
                        className=''
                        containerClass='mb-4'
                        dotListClass='static-forced'
                        draggable
                        focusOnSelect={false}
                        infinite
                        itemClass='px-1'
                        removeArrowOnDeviceType={['tablet', 'mobile']}
                        // keyBoardControl
                        minimumTouchDrag={80}
                        renderButtonGroupOutside={false}
                        renderDotsOutside
                        showDots
                        sliderClass=''
                        slidesToSlide={1}
                        swipeable
                    >
                        {buildSlides()}
                    </Carousel>
                </div>
            ) : (
                <div className='grid grid-cols-2 gap-4 mb-16 relative'>
                    {/* <FeedbackBox
                        inset='-inset-8'
                        dot='top-1/2 -translate-y-1/2 -right-20'
                        root
                        objId={objId}
                        label='Gallery'
                        zLevel=''
                    /> */}
                    {buildSlides()}
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps)(SliderNew);

const CustomDot = ({ index, onClick, active, slides }) => {
    const images = slides.map((slide) => {
        const { url, caption, type, vidCode, objId } = slide;

        return (
            <div
                key={url}
                className={`
           `}
            >
                {type === 'youtube' && (
                    <img
                        alt=''
                        src={`https://img.youtube.com/vi/${vidCode}/0.jpg`}
                        className='object-cover w-6 h-6 md:w-8 md:h-8 lg:w-12 lg:h-12 '
                    />
                )}
                {type !== 'youtube' && (
                    <img
                        alt=''
                        src={`${url}-/format/webp/-/quality/lighter/-/progressive/yes/-/max_icc_size/10/-/scale_crop/640x480/smart_faces_objects_points/`}
                        className='object-cover w-6 h-6 md:w-8 md:h-8 lg:w-12 lg:h-12 '
                    />
                )}
            </div>
        );
    });

    return (
        <button
            onClick={(e) => {
                onClick();
                e.preventDefault();
            }}
            className={`mx-0.25 lg:mx-1 overflow-hidden rounded-lg border-2 ring-2 hover:ring-green-200 ring-transparent focus:outline-none ${classNames(
                {
                    'ring-green-200': active,
                    'ring-transparent': !active,
                    'border-green-400': active,
                    'border-transparent': !active
                }
            )}`}
        >
            {React.Children.toArray(images)[index]}
        </button>
    );
};
