const Images = [
    {
        url: 'https://ucarecdn.com/9c260f04-742d-4d48-93a2-788f1d676f1a/login_samples_013.jpg',
        caption: ''
    },
    {
        url: 'https://ucarecdn.com/78bf93e6-f2fd-4d01-93b3-63fdbd90b32e/login_samples_012.jpg',
        caption: ''
    },
    {
        url: 'https://ucarecdn.com/3534103d-4218-4c99-808c-154d47f5f6f8/login_samples_011.jpg',
        caption: ''
    },
    {
        url: 'https://ucarecdn.com/e1a0ddfc-aa03-44eb-817e-94c08d21810a/login_samples_010.jpg',
        caption: ''
    },
    {
        url: 'https://ucarecdn.com/fd6fd060-ebc4-467b-bc65-26610a91ac23/login_samples_009.jpg',
        caption: ''
    },
    {
        url: 'https://ucarecdn.com/95d1bce9-b684-439b-b7bc-ef49e622f13f/login_samples_008.jpg',
        caption: ''
    },
    {
        url: 'https://ucarecdn.com/f28db7ee-b499-46f7-a555-35d8300b23df/login_samples_007.jpg',
        caption: ''
    },
    {
        url: 'https://ucarecdn.com/621b0af2-72e0-4c74-8945-df425597f54d/login_samples_006.jpg',
        caption: ''
    },
    {
        url: 'https://ucarecdn.com/5058d157-0502-41d6-b305-a2e694251e00/login_samples_005.jpg',
        caption: ''
    },
    {
        url: 'https://ucarecdn.com/bedb4e0c-7e54-49cb-9e8a-6c361c8df088/login_samples_004.jpg',
        caption: ''
    },
    {
        url: 'https://ucarecdn.com/4c144ad0-321f-43ce-b29c-70f8c006e4db/login_samples_003.jpg',
        caption: ''
    },
    {
        url: 'https://ucarecdn.com/c4098bf6-a283-4ea8-b80b-7598605c198c/login_samples_002.jpg',
        caption: ''
    },
    {
        url: 'https://ucarecdn.com/074c63e4-1a4c-4535-9bbb-0d55fc74b78c/login_samples_001.jpg',
        caption: ''
    }
];

export default Images;
