/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CardXl from 'components/blocks/CardXl';
import UploadGalleryImages from 'components/specialty/UploadGalleryImages';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic';
import translations from 'constants/translations';
import uiStruct from 'constants/uiStruct';
import GenericDescription from 'components/blocks/GenericDescription';
import GenericTitle from 'components/blocks/GenericTitle';
import { PillLogo } from 'components/blocks/Pills';
import { _INTRO_OVERVIEW } from 'store/actions/types';
import Icons from 'components/blocks/Icons';
import { ButtonsClose } from 'components/blocks/Buttons';
import { handleRowReverse } from 'helpers/FEutils';
import YoutubeModal from 'components/modals/YoutubeModal';
import { youtubeCode } from 'constants/experienceDetailConsts';
import Image from 'components/blocks/Image';
import ConfirmModal from 'components/modals/ConfirmModal';
import FeedbackBox from 'components/blocks/FeedbackBox';
import SectionMarketingTitles from './SectionMarketingTitles';
import MarketingInfo from 'components/blocks/MarketingInfo';
import KreatorBadgeStatic from 'components/blocks/KreatorBadgeStatic';
import { capitalize, getDays, kreatorName } from 'helpers/FEutils';
import RawCard from 'components/blocks/marketing/RawCard';
import classNames from '../../../node_modules/classnames/index';
import { useOnlineStatus } from 'helpers/useOnlineStatus';
import { updateFeatureImage } from 'store/actions/experienceDetails/sidebar';
import SocialMediaKreator from 'components/blocks/SocialMediaKreator';
import BioBlock from 'components/blocks/BioBlock';
import { marketPath } from 'constants/globalConsts';

function SectionIntroTop(props) {
    const {
        updateFeatureImage,
        objId,
        page,
        actionType,
        dayIndex = null,
        experienceDetails: {
            experience,
            days,
            type,
            user,
            user: { profile }
        },

        globalState: { lang, edit, isFeedbackMode },
        experienceDetailsAction,
        titleEmpty = uiStruct.overview.intro.uiPreview.titleEmpty,
        titleHint = uiStruct.overview.intro.uiEdit.modals.title.hint,
        titleModalPlaceholder = uiStruct.overview.intro.uiEdit.modals.title.placeholders,
        descriptionEmpty = uiStruct.overview.intro.uiPreview.descriptionEmpty,
        descriptionHint = uiStruct.overview.intro.uiEdit.modals.description.hint
    } = props;

    const isOnline = useOnlineStatus();
    const [youtubeModalState, setYoutubeModalState] = useState(false);
    const [delConfirmModalState, setDelConfirmModalState] = useState(false);
    let featuredImage = null;
    let descriptionHtml = null;
    let titleTxt = null;
    let featuredImageType = null;

    const dayTitle =
        actionType === _INTRO_OVERVIEW
            ? translations[lang][uiStruct.overview.intro.uiPreview.headTitle]
            : `${translations[lang][uiStruct.itinerary.intro.uiPreview.headTitle]} ${dayIndex + 1}`;

    switch (actionType) {
        case _INTRO_OVERVIEW:
            featuredImageType = experience.overview_intro.overview_featured_image.type;
            featuredImage = experience.overview_intro.overview_featured_image.data;
            descriptionHtml = experience.overview_intro.description_html;
            titleTxt = experience.overview_intro.title;

            break;
        default:
            // _INTRO
            featuredImageType = experience.itinerary.trip_days[dayIndex].day_intro.day_featured_image.type;
            featuredImage = experience.itinerary.trip_days[dayIndex].day_intro.day_featured_image.data;
            descriptionHtml = experience.itinerary.trip_days[dayIndex].day_intro.description_html;
            titleTxt = experience.itinerary.trip_days[dayIndex].day_intro.title;
            break;
    }

    const rtl = !!translations[lang].rtl;

    const introObj = {
        overview: {
            titleEmpty: uiStruct.overview.intro.uiPreview.titleEmpty,
            titleHint: uiStruct.overview.intro.uiEdit.modals.title.hint,
            titleModalPlaceholder: uiStruct.overview.intro.uiEdit.modals.title.placeholders,
            descriptionEmpty: uiStruct.overview.intro.uiPreview.descriptionEmpty,
            descriptionHint: uiStruct.overview.intro.uiEdit.modals.description.hint
        },
        day: {
            titleEmpty: uiStruct.itinerary.intro.uiPreview.titleEmpty,
            titleHint: uiStruct.itinerary.intro.uiEdit.modals.title.hint,
            titleModalPlaceholder: uiStruct.itinerary.intro.uiEdit.modals.title.placeholders,
            descriptionEmpty: uiStruct.itinerary.intro.uiPreview.descriptionEmpty,
            descriptionHint: uiStruct.itinerary.intro.uiEdit.modals.description.hint
        }
    };

    const selIntroObj = actionType === _INTRO_OVERVIEW ? introObj.overview : introObj.day;

    const modalTitle = actionType === _INTRO_OVERVIEW ? 'Overview Intro' : 'Day Intro';
    const handleImageUpdate = (imageArray) => {
        const { url } = Array.isArray(imageArray) && imageArray.length ? imageArray[0] : '';

        if (url) {
            experienceDetailsAction({
                type: `UPDATE_IMAGE${actionType}`,
                url,
                imgType: 'img',
                dayIndex
            });
            if (!experience?.featuredImage && dayIndex === null) updateFeatureImage(url);
        }
    };

    const handleVidUpdate = (vidcode) => {
        experienceDetailsAction({
            type: `UPDATE_IMAGE${actionType}`,
            url: vidcode,
            imgType: 'vid',
            dayIndex
        });
    };

    const handleDeleteImage = () => {
        setDelConfirmModalState(true);
    };

    const openYoutubeModal = () => {
        setYoutubeModalState(true);
    };

    const confirmDelete = () => {
        experienceDetailsAction({
            type: `UPDATE_IMAGE${actionType}`,
            url: null,
            dayIndex
        });
    };

    // console.log('obj ID for intro is', objId);

    // console.log('day index is', dayIndex);

    return (
        <div className={`w-full -mt-20 md:mt-0 -mb-36 ${handleRowReverse(rtl).rtl}`}>
            <CardXl edit={edit} rtl={rtl} transparent nominimize={true} isFeedbackMode={isFeedbackMode}>
                <div className={`relative`}>
                    {!isFeedbackMode && <FeedbackBox objId={objId?.image} labelPosX='left-24' posY='bottom-1/4' label='Intro image' />}
                    {featuredImage && (
                        <div className='absolute z-50 top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                            <PillLogo />
                        </div>
                    )}

                    <div
                        className={`tour-ov-intro-featured relative overflow-hidden rounded-3xl video-player-4/5 md:video-player-16/9-md lg:video-player-16/9 ${
                            featuredImage ? 'shadow-images' : 'border-4 border-green-400 border-dotted'
                        }`}
                    >
                        <div
                            className={`w-full text-xs whitespace-nowrap tracking-widest uppercase absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center ${
                                featuredImage ? 'hidden' : ''
                            }`}
                        >
                            <Icons iName='ADDIMAGE' />

                            {edit && (
                                <span className='text-base mt-6 w-full flex justify-center'>
                                    <UploadGalleryImages multiple={false} handleUpdate={handleImageUpdate} imageCount={featuredImage} />
                                </span>
                            )}
                            {edit && (
                                <span className='text-lg mt-6 w-full flex justify-center'>
                                    <button
                                        disabled={!isOnline}
                                        onClick={openYoutubeModal}
                                        className={classNames(
                                            isOnline ? 'text-red-800 bg-red-200 hover:bg-gray-900 hover:text-white' : 'bg-gray-200 text-gray-400',
                                            'focus:outline-none outline-none  font-bold text-base  rounded-md '
                                        )}
                                        style={{ width: 175, height: 37 }}
                                    >
                                        or link a Video
                                    </button>
                                </span>
                            )}
                        </div>
                        {featuredImage && (
                            <>
                                {featuredImage && featuredImageType === 'img' && (
                                    <Image size='800' src={featuredImage} className='h-full w-full object-cover' />
                                )}
                                {featuredImage && featuredImageType === 'vid' && youtubeCode(featuredImage)}

                                {edit && !isFeedbackMode && <ButtonsClose rtl={rtl} type='image' handleClose={handleDeleteImage} />}
                            </>
                        )}
                        {/* <Feedback action={{a:1}} data /> */}
                        {false && (
                            <div
                                className={`z-40 absolute inset-0 border-2 border-transparent transition-colors ease-in-out duration-300 hover:border-green-400 rounded-3xl`}
                            ></div>
                        )}
                    </div>
                    {isFeedbackMode && <FeedbackBox objId={objId?.image} labelPosX='left-24' posY='bottom-1/4' label='Intro image' />}
                </div>

                <div className={`z-10 xl:px-8 py-4 mt-8`}>
                    {days > 1 && <h2 className='text-2xl lg:text-3xl xl:text-4xl xl:px-4 text-green-400 font-bold'>{dayTitle}</h2>}
                </div>

                <GenericTitle
                    nohover
                    actionType={actionType}
                    dayIndex={dayIndex}
                    index={null}
                    titleTxt={titleTxt}
                    defaultTxt={translations[lang][selIntroObj.titleEmpty]}
                    hintObj={selIntroObj.titleHint}
                    placeholder={selIntroObj.titleModalPlaceholder}
                    icon={uiStruct.itinerary.intro.uiEdit.modals.title.icon}
                    tour='tour-ov-intro-title'
                    objId={objId?.title}
                    title={modalTitle}
                    subtitle='Title'
                />

                {!edit && actionType === _INTRO_OVERVIEW && (
                    <div className={`z-10 xl:px-12`}>
                        <MarketingInfo />
                    </div>
                )}

                {!edit && actionType === _INTRO_OVERVIEW && (
                    <RawCard padding='p-8 md:p-8' margins='mt-12'>
                        <div className='flex flex-col md:flex-row gap-8 w-full '>
                            <div className='mx-auto md:mx-0'>
                                <KreatorBadgeStatic author={user} card={false} avatarOnly={true} size='w-36 h-36' />
                            </div>
                            <div>
                                <div className='flex justify-between2 w-full items-center mb-4'>
                                    <div className='flex-1'>
                                        <div className='text-green-400 flex flex-col lg:flex-row gap-2 font-bold lg:items-center text-2xl tracking-tight leading-none flex-shrink-0 flex-initial mb-2  lg:mr-8 '>
                                            <div className='mr-3 whitespace-nowrap'>{`A ${capitalize(type)} Experience`}</div>
                                            <div className='flex items-center'>
                                                <span className='mr-3 text-sm text-green-700'>by</span>

                                                <a
                                                    href={`${marketPath}/experiences/user/${user.username}/all`}
                                                    target='_blank'
                                                    className='underline text-base lg:text-lg text-green-700'
                                                >{`${kreatorName(profile)}`}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div
                                    className={`block-html leading-6  text-xs md:text-sm`}
                                    dangerouslySetInnerHTML={{
                                        __html: profile.bio
                                    }}
                                /> */}
                                <BioBlock bio={profile.bio} />

                                <div className='flex items-center gap-2 text-xl'>
                                    <SocialMediaKreator social={profile?.social} />
                                </div>
                            </div>
                        </div>
                    </RawCard>
                )}

                <GenericDescription
                    tour='tour-ov-intro-description'
                    nohover
                    actionType={actionType}
                    dayIndex={dayIndex}
                    index={null}
                    descriptionHtml={descriptionHtml}
                    defaultHtml={translations[lang][selIntroObj.descriptionEmpty]}
                    hintObj={selIntroObj.descriptionHint}
                    placeholder={selIntroObj.titleModalPlaceholder}
                    icon={uiStruct.itinerary.intro.uiEdit.modals.description.icon}
                    textSize='text-xl'
                    textSizeEmpty='text-xl'
                    objId={objId?.description}
                    title={modalTitle}
                    subtitle='Description'
                />

                {/* <div className=' flex items-center justify-center lg:mt-12'>
                    <PillLogo />
                </div> */}
            </CardXl>
            {youtubeModalState && (
                <YoutubeModal
                    setModalIsOpen={setYoutubeModalState}
                    updateObj={featuredImageType === 'vid' ? featuredImage : ''}
                    update={true}
                    handleActionBtn={handleVidUpdate}
                />
            )}
            {delConfirmModalState && <ConfirmModal setModalIsOpen={setDelConfirmModalState} handleActionBtn={confirmDelete} />}
        </div>
    );
}

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            experienceDetailsAction,
            updateFeatureImage
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionIntroTop);
