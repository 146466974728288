export const defaultProfile = {
    first: '',
    last: '',
    displayname: null,
    country: 'AF',
    city: null,
    bio: null,
    avatar: null,
    settings: {},
    social: null
};
