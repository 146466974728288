export default [
    'Swapping time and space...',
    'Waiting for the elves',
    'Testing your patience',
    'Following the white rabbit',
    'Moving satellites into position...',
    'Keep calm and...',
    'Kounting to...10',
    'Kounting backwards...from Infinity',
    'Spinning the wheel of fortune...',
    'Adjusting flux capacitor...',
    'Waiting for the sloth to start moving.',
    'Kleaning off the Kobwebs...',
    "Making sure all the i's have dots...",
    'Spinning the hamster...',
    'Konvincing AI not to turn evil..',
    'Laughing at your pictures-i mean, loading...',
    'Optimizing the optimizer...',
    'Pushing pixels to the left',
    'Inserting elevator music',
    'Waiting for the minions to do their work',
    'Grabbing extra minions',
    'Waking up the minions'
];
