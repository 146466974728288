import PolicyItem from 'components/blocks/PolicyItem';

function PoliciesList({
    isFeedbackMode,
    handleDeleteAction,
    edit,
    policyData,
    handleUpdateAction
}) {
    const deleteClick = (index) => {
        handleDeleteAction(index);
    };
    const editClick = (index) => {
        handleUpdateAction(index);
    };
    const buildList = () => {
        return policyData.map((obj, index) => {
            const key = `${obj.title}_${index}`;

            return (
                <PolicyItem
                    isFeedbackMode={isFeedbackMode}
                    key={key}
                    handleUpdateAction={editClick}
                    deleteClick={deleteClick}
                    index={index}
                    edit={edit}
                    itemData={obj}
                />
            );
        });
    };
    return (
        <div className=''>
            <ul className='h-full'>{buildList()}</ul>
        </div>
    );
}

export default PoliciesList;
