import React from 'react';
import Avatar from 'components/specialty/Avatar';

const KreatorBadgeStatic = ({ author, avatarOnly = false, card = true, size = 'w-14 h-14' }) => {
    return (
        <>
            {author && (
                <div>
                    {avatarOnly ? (
                        <a className=''>
                            <Avatar profile={author?.profile} card={card} size={size} username={author?.username} />
                        </a>
                    ) : (
                        <a className={`flex rounded-full h-8 mb-8`}>
                            <div className='h-8 flex items-center no-underline text-white'>
                                <Avatar profile={author?.profile} card={card} size={'w-8 h-8'} username={author?.username} />

                                <div className='h-8 bg-green-400 z-0 text-xs text-gray-900 flex  whitespace-nowrap font-bold shadow-2xl-green-500 items-center pl-10 pr-4 rounded-full'>
                                    {author.profile?.displayname ? author.profile.displayname : author.username}
                                </div>
                            </div>
                        </a>
                    )}
                </div>
            )}
        </>
    );
};

export default KreatorBadgeStatic;
