/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    GoogleMap,
    Marker,
    DrawingManager,
    Circle
} from '@react-google-maps/api';
import ModalFullscreen, { ModalBody } from 'components/blocks/ModalFullscreen';
import ModalContainer, {
    ModalFooter,
    ModalCTA,
    ModalButton,
    ModalHelp
} from 'components/blocks/ModalTre';
import ModalTopBlock from 'components/blocks/ModalTopBlock';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import MapStatus from 'components/blocks/MapStatus';
import CollapseButton from 'components/blocks/CollapseButton';
import { BlockInputSingle } from 'components/blocks/Blocks';
import { borders } from 'constants/bordersJS';
import MapSearch from 'components/map/MapSearch';
import { nanoid } from 'nanoid';

const targetElement = document.querySelector('#root');
const mapContainerStyle = {
    height: '50vh',
    width: '100%'
};

const mapOptions = {
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    clickableIcons: false,
    gestureHandling: 'greedy',
    mapId: '8e0ece1c437d65ae'
};

const CircleMapModal = ({
    globalState: {
        lang,
        edit = true,
        googleMaps: {
            loading: { isLoading },
            error: { isError }
        }
    },
    close,
    setModalIsOpen,
    handleActionBtn,
    icon = uiStruct.itinerary.activities.uiEdit.modals.map.icon,
    hintObj = uiStruct.itinerary.activities.uiEdit.modals.map.hint,
    actionBtnObj = uiStruct.ui.modals.editor.buttons.action,
    cancelBtnObj = uiStruct.ui.modals.editor.buttons.cancel,
    experienceDetails: {
        experience: {
            accommodation: { locations },
            destination: { locations: destinationLocations }
        }
    },
    type = 'default',
    modalTitle = 'Activity',
    modalSubTitle = 'Add Location Pin',
    text = translations[lang][
        uiStruct.itinerary.activities.uiEdit.modals.map.description
    ],
    placeholder = null,
    circleData,
    inputIcon
}) => {
    const rtl = !!translations[lang].rtl;
    const mapRef = useRef();
    const [mapReady, setMapReady] = useState(null);
    const [panPlace, setPanPlace] = useState(null);
    const containerRef = useRef(null);
    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
        setMapReady(mapRef.current);
    }, []);
    const firstTime = useRef(true);
    const [drawMode, setDrawMode] = useState('circle');
    const circleOptions = {
        strokeColor: '#34D399',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#34D399',
        fillOpacity: 0.2
    };
    const validObj = {
        label: { error: false },
        circle: { error: false }
    };
    const [editorValidationError, setEditorValidationError] = useState({
        ...validObj
    });
    // Copy Below to all modals
    //////////////////////////////////////////////////////////////////////
    const [circlesDataArr, setCirclesDataArr] = useState(
        circleData.circle ? [circleData.circle] : []
    );
    const [circleLabel, setCircleLabel] = useState(circleData.label);
    // State for triggering animation of modal in and out
    const [transitionState, setTransitionState] = useState('off');
    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    ///////////////////////////////////////////////////////////////////
    const buildMarkers = () => {
        return locations
            .filter((loc) => loc.type !== 'circle')
            .map((obj, index) => {
                const {
                    location: { lat, lng }
                } = obj.data;
                const key = `lat${lat}${index}`;

                return (
                    <Marker
                        key={key}
                        position={{
                            lat: lat,
                            lng: lng
                        }}
                        icon={{
                            url: `/assets/media/map-pin-fill-green.svg`,
                            origin: new window.google.maps.Point(0, 0),
                            anchor: new window.google.maps.Point(15, 30),
                            scaledSize: new window.google.maps.Size(30, 30)
                        }}
                    />
                );
            });
    };

    const drawCircles = () => {
        if (!mapReady) return;
        const overlayArray = [];
        const circlesArr = [];
        circlesDataArr.forEach((obj, index) => {
            const { location, radius } = obj;
            const key = `${location.lat}${index}`;

            circlesArr.push(
                <Circle
                    options={{ ...circleOptions }}
                    key={`circle_${key}`}
                    center={location}
                    radius={radius}
                />
            );
        });

        return overlayArray.concat(circlesArr);
    };

    const onCircleComplete = (circle) => {
        const tmpCircle = {};

        tmpCircle.location = {
            lat: circle.center.lat(),
            lng: circle.center.lng()
        };
        tmpCircle.radius = circle.radius;
        tmpCircle.bounds = circle.getBounds();
        setCirclesDataArr([tmpCircle]);

        circle.setMap(null);

        setEditorValidationError({
            ...editorValidationError,
            circle: {
                error: false
            }
        });
    };

    const resetBounds = () => {
        if (mapReady) {
            const bounds = new window.google.maps.LatLngBounds();

            circlesDataArr.forEach((obj, index) => {
                const { bounds: circleBounds } = obj;
                bounds.union(circleBounds);
            });
            locations.filter((loc) => loc.type !== 'circle');
            locations
                .filter((loc) => loc.type !== 'circle')
                .forEach((obj) => {
                    const {
                        data: { location }
                    } = obj;
                    bounds.extend(location);
                });

            if (!locations) {
                if (destinationLocations.length) {
                    [destinationLocations[0]].forEach((obj, index) => {
                        const { countryCode } = obj[0];
                        const coordinates = borders[countryCode]
                            ? borders[countryCode].coordinates
                            : [];

                        coordinates.forEach((coord) => {
                            if (Array.isArray(coord)) {
                                coord.forEach((latLng) => {
                                    bounds.extend(latLng);
                                });
                            } else {
                                bounds.extend(coord);
                            }
                        });

                        try {
                            if (!coordinates.length) {
                                bounds.union(obj[0].geometry.bounds);
                            }
                        } catch (error) {
                            // console.log('country with no bounds data');
                        }
                    });
                }
            }

            if (
                !circlesDataArr.length &&
                !locations.length &&
                !destinationLocations.length
            ) {
                mapRef.current.setZoom(1);
                mapRef.current.setCenter({
                    lat: 0,
                    lng: 0
                });
            } else {
                mapRef.current.fitBounds(bounds);
            }
        }
    };

    const updateDescriptionHtml = (event) => {
        setCircleLabel(event.target.value);
        setEditorValidationError({
            ...editorValidationError,
            label: {
                error: false
            }
        });
    };

    const validate = () => {
        let validateObj = {
            ...validObj
        };
        let isValid = true;
        if (!circlesDataArr.length) {
            isValid = false;
            validateObj = {
                ...validateObj,
                circle: { error: true }
            };
        }

        if (!circleLabel.length) {
            isValid = false;
            validateObj = {
                ...validateObj,
                label: { error: true }
            };
        }

        setEditorValidationError(validateObj);

        return isValid;
    };

    const handleActionClick = () => {
        if (validate()) {
            handleActionBtn({
                type: 'circle',
                circle: circlesDataArr[0],
                label: circleLabel,
                days: circleData.days,
                objId: circleData?.objId ?? `overview_accommodation_${nanoid()}`
            });
            handleTransitionStateOff();
        }
    };

    useEffect(() => {
        if (mapReady) {
            setDrawMode(null);
            if (firstTime.current) {
                setTimeout(() => {
                    resetBounds();
                    window.google.maps.event.addDomListener(
                        containerRef.current,
                        'resize',
                        function () {
                            const center = mapRef.current.getCenter();
                            window.google.maps.event.trigger(
                                mapRef.current,
                                'resize'
                            );
                            mapRef.current.setCenter(center);
                        }
                    );
                }, 1);
                setTimeout(() => {
                    containerRef.current.style.width = '99%';
                }, 100);
                setTimeout(() => {
                    containerRef.current.style.width = '100%';
                }, 200);
                firstTime.current = false;
            }
        }
    }, [locations, mapReady]);

    useEffect(() => {
        if (mapReady && !edit) {
            setDrawMode(null);
        }
    }, [edit]);

    const setDrawModeAction = (mode) => {
        setDrawMode(mode);
    };
    // Pan to location

    const panTo = useCallback(({ lat, lng }, placeBounds) => {
        if (mapRef.current) {
            mapRef.current.panTo({ lat: Number(lat), lng: Number(lng) });

            setPanPlace({
                bounds: placeBounds,
                location: {
                    lat,
                    lng
                }
            });
        }
    }, []);
    const toggleMode = () => {
        setDrawModeAction(drawMode === 'circle' ? null : 'circle');
    };
    return (
        <>
            <ModalFullscreen
                width='4xl'
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={icon}
                close={close}
                closeButton={true}
                rtl={rtl}
                fullscreen={true}
            >
                <ModalBody rtlOff>
                    <div className='xl:px-32 '>
                        <div className='w-full  px-4 md:px-8 lg:px-2 mb-8 mt-4'>
                            <ModalTopBlock
                                title={'Accommodation'}
                                subtitle={'Draw Region'}
                                text={
                                    "Besides adding pins, you can also draw circles on a map to suggest wider regions of interest. Don't forget to give these regions names so that they are easy to identify by the users."
                                }
                                rtl={rtl}
                                lang={lang}
                            />
                        </div>
                        {isLoading || isError ? (
                            <MapStatus />
                        ) : (
                            <div className='flex flex-col-reverse lg:flex-row gap-16 px-0 md:px-8 lg:px-0'>
                                <div className='lg:w-3/5'>
                                    <div
                                        className='relative'
                                        ref={containerRef}
                                    >
                                        {edit && (
                                            <>
                                                <div className='absolute top-8 left-8 z-10'>
                                                    <div className='text-xs font-medium hover:text-green-900 text-green-900 rounded-full bg-green-100 bg-opacity-70 px-4 py-2'>
                                                        {drawMode
                                                            ? 'You are in Drawing mode. Click and drag to draw a circle'
                                                            : 'You are in Edit Mode.'}
                                                    </div>
                                                </div>
                                                <div className='absolute top-20 left-8 z-10'>
                                                    <CollapseButton
                                                        labelHover={
                                                            drawMode ===
                                                            'circle'
                                                                ? 'Draw area mode'
                                                                : 'Edit area mode'
                                                        }
                                                        handleClick={toggleMode}
                                                        icon='ri-brush-line text-2xl -ml-0.5'
                                                        size='10'
                                                        textSize='text-xs'
                                                        sizeHover='w-44'
                                                        offsetCenter='2'
                                                        btnColor={`${
                                                            drawMode
                                                                ? 'bg-green-400'
                                                                : 'bg-green-200'
                                                        } font-medium hover:text-green-900 text-green-900`}
                                                    />
                                                    {/* <CollapseButton
                                                        labelHover='Draw area mode'
                                                        handleClick={() =>
                                                            setDrawModeAction(
                                                                'circle'
                                                            )
                                                        }
                                                        icon='ri-brush-line text-2xl -ml-0.5'
                                                        size='10'
                                                        textSize='text-xs'
                                                        sizeHover='w-44'
                                                        offsetCenter='2'
                                                        btnColor={`${
                                                            drawMode
                                                                ? 'bg-green-400'
                                                                : 'bg-green-200'
                                                        } font-medium hover:text-green-900 text-green-900`}
                                                    /> */}
                                                </div>
                                                {/* <div className='absolute top-32 left-8 z-10'>
                                                    <CollapseButton
                                                        labelHover='Edit area mode'
                                                        handleClick={() =>
                                                            setDrawModeAction(
                                                                null
                                                            )
                                                        }
                                                        icon='ri-pencil-line text-2xl -ml-0.5'
                                                        size='10'
                                                        textSize='text-xs'
                                                        sizeHover='w-44'
                                                        offsetCenter='2'
                                                        btnColor={`${
                                                            !drawMode
                                                                ? 'bg-green-400'
                                                                : 'bg-green-200'
                                                        } font-medium hover:text-green-900 text-green-900`}
                                                    />
                                                </div> */}
                                            </>
                                        )}

                                        <div className='absolute top-8 right-8 z-10'>
                                            <CollapseButton
                                                labelHover='Zoom to fit'
                                                handleClick={resetBounds}
                                                icon='ri-artboard-2-line text-2xl -ml-0.5'
                                                size='10'
                                                textSize='text-xs'
                                                sizeHover='w-36'
                                                offsetCenter='2'
                                                btnColor='bg-green-400 hover:bg-gray-900 text-green-900'
                                            />
                                        </div>
                                        <div
                                            style={{
                                                border: `${
                                                    editorValidationError.circle
                                                        .error
                                                        ? '1px solid red'
                                                        : 'none'
                                                }`
                                            }}
                                        >
                                            <GoogleMap
                                                id='map'
                                                mapContainerStyle={
                                                    mapContainerStyle
                                                }
                                                //   zoom={6}
                                                options={mapOptions}
                                                onClick={null}
                                                onLoad={onMapLoad}
                                            >
                                                {drawCircles()}
                                                {buildMarkers()}
                                                {edit && (
                                                    <DrawingManager
                                                        options={{
                                                            drawingMode:
                                                                drawMode,
                                                            drawingControl: false,
                                                            drawingControlOptions:
                                                                {
                                                                    position:
                                                                        window
                                                                            .google
                                                                            .maps
                                                                            .ControlPosition
                                                                            .TOP_LEFT,
                                                                    drawingModes:
                                                                        [
                                                                            window
                                                                                .google
                                                                                .maps
                                                                                .drawing
                                                                                .OverlayType
                                                                                .CIRCLE
                                                                        ]
                                                                },
                                                            circleOptions
                                                        }}
                                                        onCircleComplete={
                                                            onCircleComplete
                                                        }
                                                    />
                                                )}
                                            </GoogleMap>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className={`flex flex-col lg:w-2/5 mt-4 py-4 px-8 md:px-4`}
                                >
                                    <div className='mt-4 mb-4'>
                                        1. Search for a any place or navigate
                                        using the pan and zoom
                                    </div>
                                    <div className='flex items-center w-full mb-6 gap-4'>
                                        <MapSearch
                                            panTo={panTo}
                                            placeholder={
                                                translations[lang][
                                                    uiStruct.itinerary
                                                        .activities.uiEdit
                                                        .modals.map.placeholders
                                                        .autocomplete
                                                ]
                                            }
                                        />
                                    </div>

                                    <div className='mt-8 mb-4'>
                                        2. Give it a name
                                    </div>
                                    <div
                                        className={`flex flex-col md:flex-row md:items-center gap-2 md:gap-4 mb-8 md:mb-4`}
                                    >
                                        <span className='text-xs whitespace-nowrap w-36 ml-2 md:ml-0'>
                                            Name
                                        </span>
                                        <BlockInputSingle
                                            responsive={true}
                                            normal
                                            error={
                                                editorValidationError.label
                                                    .error
                                            }
                                            handleChange={updateDescriptionHtml}
                                            id='name'
                                            value={circleLabel}
                                            placeholder={
                                                'Give this region a name'
                                            }
                                            rtl={rtl}
                                            className='text-sm'
                                            margins=''
                                            height='h-12'
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className='mt-8 mb-8 px-4 md:px-10'>
                            <ModalFooter>
                                {hintObj && (
                                    <ModalHelp
                                        helpText={translations[lang][hintObj]}
                                        isHtml
                                    />
                                )}
                                <ModalCTA>
                                    <ModalButton
                                        handleClick={handleTransitionStateOff}
                                        label={
                                            translations[lang][
                                                cancelBtnObj.label
                                            ]
                                        }
                                        color={cancelBtnObj.color}
                                        icon={cancelBtnObj.icon}
                                    />
                                    <ModalButton
                                        handleClick={handleActionClick}
                                        label={
                                            translations[lang][
                                                actionBtnObj.label
                                            ]
                                        }
                                        color={actionBtnObj.color}
                                        icon={actionBtnObj.icon}
                                    />
                                </ModalCTA>
                            </ModalFooter>
                        </div>
                    </div>
                </ModalBody>
            </ModalFullscreen>
        </>
    );
};

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(CircleMapModal);
