import axios from 'axios';
import * as types from 'store/actions/types';
import { defaultExperience, defaultMarketing } from 'constants/experiencesConsts';
import store from 'store';
import { AxiosAbortService } from 'helpers/AxiosAbortService';
import { serverErrors } from 'helpers/serverErrors';
import { getOverallCompletion } from 'helpers/menuHelpers';
import {CLONE_EXPERIENCE} from '../store/actions/types';

export const fetchMyPublishedExps = () => {

    let params = '';
    const {
        globalState: {
            filters,
            pagination: { currentPage, maxPerPage }
        },
        auth: {
            user: { id: userId, role: userRole }
        }
    } = store.getState();

    const inPublished = true;
    const isAdminStaff = false;
    const sort = `sort: "${filters.extra.orderType === 'desc' ? '-' : ''}${filters.extra.extraSelected}",`;
    const collection = !inPublished ? `experiencesByLoggedUser` : `publishedsByLoggedUser`;
    const status = !inPublished
        ? `
        isLive,
        status,
        inPublished,
        completion,
        `
        : `isPublished, experience_id,`;

    params = ``;

    let pageCount = '';


        if (!isAdminStaff) {
            pageCount = `publishedCount ${params.length ? `(where: { user: "${userId}"})` : `(where: { user: "${userId}"})`}`;
        } else {
            pageCount = `publishedCount ${params.length ? `(where: { ${params} })` : ''}`;
        }

    let query = `
    query fetchAll {
        ${!inPublished ? 'not_edu:' : ''} ${collection} (where: {myPublished:true, ${params} ${!inPublished ? `isEdu: false,` : ''} ${sort} start: ${
        currentPage * maxPerPage
    }, limit: ${maxPerPage}}) {
            id,
            _id,
            content_marketing,
            type,
            budget_currency,
            days,
            updatedAt,
            createdAt,
            title,
            featured_image,
            ${!inPublished ? `isEdu,` : ``}
            places_lists {
                name,
                code
            },
            user {
                _id,
                id,
                username,
                role {
                    id,
                }
                profile {
                    avatar,
                    country,
                    currency,
                    displayname,
                    first,
                    id,
                    last,
                    social
                }
            }
            ${status}
        },
        ${pageCount}
    `;
    query = `${query}     }`;

    return (dispatch) =>
        dispatch({
            type: !inPublished ? `${types.GET_EXPERIENCES}${isAdminStaff && !params.length ? '_ISADMIN' : ''}` : types.GET_PUBLISHED_EXPERIENCES,
            payload: axios.post(`${process.env.REACT_APP_API_BASE}/graphql`, {
                query
            })
        })
            .then((res) => {
                return res;
            })
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const updateDigitalPrice = (pubId, price) => {
    return (dispatch) =>
        dispatch({
            type: types.UPDATE_DIGITAL_PRICE,
            payload: axios.put(`${process.env.REACT_APP_API_BASE}/price`, { id: pubId, price, type: 'digital' })
        })
            .then((res) => {
                return res;
            })
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const createGuidedSku = (pubId, dataToPost) => {
    const { remove, update, add, lowestPrice } = dataToPost;

    return (dispatch) =>
        dispatch({
            type: types.UPDATE_GUIDED_PRICE,
            payload: axios.put(`${process.env.REACT_APP_API_BASE}/price`, { id: pubId, remove, update, add, lowestPrice, type: 'guided' })
        })
            .then((res) => {
                return res;
            })
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const fetchPublishedPriceData = (pubId, type) => {
    return (dispatch) =>
        dispatch({
            type: types.GET_GUIDED_PRICE_DATA,
            payload: axios.get(`${process.env.REACT_APP_API_BASE}/price/${pubId}/${type}`)
        })
            .then((res) => {
                if (type.toLowerCase() === 'guided') {
                    if (!res?.value?.data?.stock) {
                        serverErrors(new Error(JSON.stringify(res)));
                    }
                } else if (!res.value.data.count) {
                    serverErrors(new Error(JSON.stringify(res)));
                }

                return res;
            })
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const fetchExpReport = (fetchData, all=false) => {
    const { pubId, start=0, limit=1000, sort="-createdAt" } = fetchData;

    return (dispatch) =>
        dispatch({
            type: types.GET_PURCHASES_FOR_PUBLISHED,
            payload: axios.post(`${process.env.REACT_APP_API_BASE}/graphql`, {
                query: `
                    query{
                        purchasesByPubId(where: {publish_id: "${pubId}", sort: "${sort}", start: ${start}, limit: ${limit}, all: ${all} }) { 
                            id,
                            user { id, username, email },
                            createdAt,
                            experience_id,
                            publish_id,
                            people,
                            travel_date,
                            title,
                            featured_image,
                            canceled,
                            order,
                            payout
                        },
                    purchaseCount (where: { publish_id: "${pubId}"})
                }`
            })
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
    
};
export const fetchExperiencePurchased = (id) => {
    const gql = String.raw;
    return (dispatch) =>
        dispatch({
            type: types.GET_PURCHASED_EXPERIENCE,
            payload: axios.post(`${process.env.REACT_APP_API_BASE}/graphql`, {
                query: gql`
                    query{
                        purchasedByUser(where: {experience_id: "${id}" }) {
                            id,
                            experience_id
                            experience {
                                id,
                                experience_id,
                                user {
                                    _id,
                                    id,
                                    username,
                                role {
                                    id,
                                }
                                profile {
                                    avatar,
                                    country,
                                    currency,
                                    displayname,
                                    first,
                                    id,
                                    last,
                                    bio,
                                    social
                                }
                            },
                            updatedAt,
                            content,
                            type,
                            isPublished,
                            publicAccess,
                            budget_min,
                            budget_max,
                            budget_currency,
                            content_marketing,
                            cats_list {
                                id,
                                name
                            },
                            tags_list{
                                id,
                                name
                            },
                            places_lists{
                                id,
                                name,
                                code
                            },
                            guided_extra
                            days
                        }
                    }
                }`
            })
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const fetchExperiencePublished = (id) => {
    const gql = String.raw;
    return (dispatch) =>
        dispatch({
            type: types.GET_PUBLISHED_EXPERIENCE_PUBLIC,
            payload: axios.post(`${process.env.REACT_APP_API_BASE}/graphql`, {
                query: gql`
                        query getExperience {
                            categories {
                                id
                                name
                            },
                            publisheds (where: {experience_id: "${id}", isPublished: true}) 
                            {   
                                experience_id,
                                content,
                                tags,
                                # short_content,
                                type,
                                cats,
                                guided_extra,
                                days,
                                budget_max,
                                budget_min,
                                budget_currency,
                                content_marketing,
                                publicAccess,
                                cats_list {id},
                                tags_list {id},
                                places_lists {id},
                                user {
                                    _id,
                                    id,
                                    username,
                                    profile {
                                        published_at,
                                        id,
                                        first,
                                        last,
                                        displayname,
                                        bio,
                                        city,
                                        avatar,
                                        country,
                                        social
                                    }
                                }
                            }
                        }`
            })
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};
export const fetchExperienceSingle = (id) => {

    return (dispatch, getState) =>
        dispatch({
            type: types.GET_EXPERIENCE_SINGLE,
            payload: axios.get(`${process.env.REACT_APP_API_BASE}/experiences/${id}`, {
                cancelToken: AxiosAbortService.addRequest('singleExperience')
            })
        })
            .then((res) => {
                return res;
            })
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const fetchExperiencePublishedSingle = (id) => {
    const gql = String.raw;

    return (dispatch) =>
        dispatch({

            type: types.GET_EXPERIENCE_PUBLISHED_SINGLE,
            payload: axios.post(`${process.env.REACT_APP_API_BASE}/graphql`, {
                query: gql`
                        query getExperience {
                                categories {
                                id
                                name
                            },
                            publisheds (where: {experience_id: "${id}"})
                            {
                                id,
                                experience_id,
                                isPublished,
                                content,
                                # short_content,
                                type,
                                guided_extra,
                                experience_status {
                                    id,
                                    _id,
                                    inPublished,
                                    status,
                                    feedback_object_published
                                },
                                publicAccess,
                                budget_max,
                                budget_min,
                                budget_currency,
                                content_marketing,
                                days
                                cats_list {id},
                                tags_list {id},
                                places_lists {id},
                                user {
                                    _id,
                                    id,
                                    username,
                                    profile {
                                        published_at,
                                        id,
                                        first,
                                        last,
                                        displayname,
                                        bio,
                                        city,
                                        avatar,
                                        country,
                                        social
                                    }
                                }
                            }
                        }`
            }),
        })
        .then((res) => res)
        .catch((error) => {
            serverErrors(error);

            return { error };
        });
};

export const fetchPurchasedAll = () => {
    const gql = String.raw;

    return (dispatch) =>
        dispatch({
            type: types.GET_PURCHASED_EXPERIENCES,
            payload: axios.post(`${process.env.REACT_APP_API_BASE}/graphql`, {
                query: gql`
                    query {
                        purchasesByUser {
                            id
                            experience_id
                            people
                            travel_date
                            swellbypass
                            experience {
                                title
                                experience_id
                                featured_image
                                type
                                createdAt
                                days
                                user {
                                    _id
                                    id
                                    username
                                    role {
                                        id
                                    }
                                    profile {
                                        avatar
                                        country
                                        currency
                                        displayname
                                        first
                                        id
                                        last
                                        social
                                    }
                                }
                                places_lists {
                                    name
                                    code
                                }
                                # content
                            }
                        }
                    }
                `
            })
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};
export const fetchExperiencesAll = () => {
    let params = '';
    const {
        globalState: {
            inPublished,
            filters,
            pagination: { currentPage, maxPerPage }
        },
        auth: {
            user: { id: userId, role: userRole }
        }
    } = store.getState();
    // console.log('while fetching exp, user id is', userId);
    const isAdminStaff = userRole === process.env.REACT_APP_STRAPI_ADMIN_ROLE_ID || userRole === process.env.REACT_APP_STRAPI_ADMIN_ROLE_ID;
    const sort = `sort: "${filters.extra.orderType === 'desc' ? '-' : ''}${filters.extra.extraSelected}",`;
    const collection = !inPublished ? `experiencesByLoggedUser` : `publishedsByLoggedUser`;
    const status = !inPublished
        ? `
        isLive,
        status,
        inPublished,
        completion,
        `
        : `isPublished, experience_id,`;

    params = `${params}${
        Object.keys(filters).reduce((acc, next) => {
            switch (next) {
                case 'type':
                case 'user':
                    if (filters[next] !== 'all') {
                        return `${acc} ${next}: "${filters[next]}",`;
                    }
                    break;
                case 'status':
                    if (filters[next] !== 'all' && !inPublished) {
                        return `${acc} ${next}: "${filters[next]}",`;
                    }
                    break;
                case 'published':
                    if (filters[next] !== 'all' && inPublished) {
                        return `${acc} isPublished: ${filters[next] === 'published'},`;
                    }
                    break;
                case 'country':
                    if (filters[next] !== 'all') {
                        return `${acc} places_lists: ["${filters[next]}"],`;
                    }
                    break;
                default:
                    break;
            }

            return acc;
        }, ``) || ``
    }`;

    let pageCount = '';

    if (!inPublished) {
        pageCount = `experiencesCount ${!isAdminStaff ? `(where: { user: "${userId}" })` : `(where: { ${params.length ? params : ''}})`}`;
    } else {
        if (!isAdminStaff) {
            pageCount = `publishedCount ${params.length ? `(where: { user: "${userId}", ${params} })` : `(where: { user: "${userId}"})`}`;
        } else {
            pageCount = `publishedCount ${params.length ? `(where: { ${params} })` : ''}`;
        }
    }

    let query = `
    query fetchAll {
        ${!inPublished ? 'not_edu:' : ''} ${collection} (where: {${params} ${!inPublished ? `isEdu: false,` : ''} ${sort} start: ${
        currentPage * maxPerPage
    }, limit: ${maxPerPage}}) {
            id,
            _id,
            content_marketing,
            type,
            budget_currency,
            days,
            updatedAt,
            createdAt,
            title,
            featured_image,
            ${!inPublished ? `isEdu,` : ``}
            places_lists {
                name,
                code
            },
            user {
                _id,
                id,
                username,
                role {
                    id,
                }
                profile {
                    avatar,
                    country,
                    currency,
                    displayname,
                    first,
                    id,
                    last,
                    social
                }
            }
            ${status}
        },
        ${pageCount}
    `;

    if (!inPublished) {
        query = `${query} 
        is_edu: ${collection} (where: {${!inPublished ? 'isEdu: true,' : ''} ${sort} start: 0, limit: 1000}) {
            id,
            _id,
            content_marketing,
            type,
            budget_currency,
            days,
            updatedAt,
            createdAt,
            isEdu,
            status,
            completion,
            title,
            featured_image
            places_lists {
                name,
                code
            },
            user {
                _id,
                id,
                username,
                role {
                    id,
                }
                profile {
                    avatar,
                    country,
                    currency,
                    displayname,
                    first,
                    id,
                    last,
                    social
                }
            },
            ${status}
        }
        `;
    }
    query = `${query}     }`;

    return (dispatch) =>
        dispatch({
            type: !inPublished ? `${types.GET_EXPERIENCES}${isAdminStaff && !params.length ? '_ISADMIN' : ''}` : types.GET_PUBLISHED_EXPERIENCES,
            payload: axios.post(`${process.env.REACT_APP_API_BASE}/graphql`, {
                query
            })
        })
            .then((res) => {
                return res;
            })
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};
export const fetchStartupData = (params) => {
    const gql = String.raw;
    return (dispatch) =>
        dispatch({
            type: types.GET_STARTUP_DATA,
            payload: axios.post(`${process.env.REACT_APP_API_BASE}/graphql`, {
                query: gql`
                    query start_up_data {
                        setting {
                            whatsIncluded
                            xchangeRates
                            app_version
                        }
                        placesLists {
                            id
                            code
                        }
                        categories {
                            id
                            name
                        }
                        tags {
                            id
                            name
                            related
                            type
                        }
                    }
                `
            })
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const fetchIpData = (params) => {
    return (dispatch) =>
        dispatch({
            type: types.GET_IP_DATA,
            payload: axios.get(`https://pro.ip-api.com/json/?key=${process.env.REACT_APP_IP_API_KEY}`)
        })
            .then((res) => res)
            .catch((error) => {
                // console.log(error);
                // serverErrors(error);
                // return { error };
            });
};

export const createExperience = (type = 'DIGITAL') => {
    const {
        auth: { user }
    } = store.getState();
    const dataObj = {
        tags: ['Days 1'],
        content: defaultExperience(type.toLowerCase()),
        // short_content: defaultShortExperience(),
        user: user._id,
        type: type.toUpperCase(),
        content_marketing: defaultMarketing(),
        days: 1
    };
    return (dispatch, getState) =>
        dispatch({
            // headers: {
            //     'Content-Type': 'application/json'
            // },
            type: types.CREATE_EXPERIENCE,
            payload: axios.post(`${process.env.REACT_APP_API_BASE}/experiences`, dataObj, {}).then((res) => {
                // There was an error from the api response, handle it.

                // if (res.data.error) {
                //     throw new Error(res.data.error.message);
                // }
                return res;
            })
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const updateExperience = (extra = {}) => {
    const {
        globalState: { inPublished },
        experienceDetails,
        experienceDetails: { budget_max, budget_min, budget_currency, experience, id, pubId, content_marketing, cats_list, tags_list, places_lists, days }
    } = store.getState();
    const expId = !inPublished ? id : pubId;
    const pathStr = !inPublished ? 'experiences' : 'publisheds';
    const completion = getOverallCompletion(experience, experienceDetails).total;

    let dataObj = {
        budget_max,
        budget_min,
        budget_currency,
        content: experience,
        //  short_content: buildShortContent(),
        content_marketing,
        cats_list,
        tags_list,
        places_lists,
        days,
        title: experience?.overview_intro?.title || '',
        featured_image: experience?.featured_image || '',
        completion
    };

    dataObj = {
        ...dataObj,
        ...extra
    };

    return (dispatch, getState) =>
        dispatch({
            type: types.UPDATE_EXPERIENCE,
            payload: axios.put(`${process.env.REACT_APP_API_BASE}/${pathStr}/${expId}`, dataObj, {}).then((res) => {
                // There was an error from the api response, handle it.

                // if (res.data.error) {
                //     throw new Error(res.data.error.message);
                // }
                return res;
            })
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const updateFeedback = () => {
    const {
        experienceDetails: {
            experience_status: { feedback_object, id }
        }
    } = store.getState();

    return (dispatch, getState) =>
        dispatch({
            type: types.UPDATE_FEEDBACK,
            payload: axios.put(`${process.env.REACT_APP_API_BASE}/experience-statuses/${id}`, { feedback_object }).then((res) => {
                return res;
            })
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};
export const createExperienceStatus = (id) => {
    return (dispatch, getState) =>
        dispatch({
            // headers: {
            //     'Content-Type': 'application/json'
            // },
            type: types.CREATE_EXPERIENCE_STATUS,
            payload: axios
                .post(
                    `${process.env.REACT_APP_API_BASE}/experience-statuses`,
                    {
                        experience: id
                    },
                    {}
                )
                .then((res) => {
                    // There was an error from the api response, handle it.

                    // if (res.data.error) {
                    //     throw new Error(res.data.error.message);
                    // }
                    return res;
                })
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};
export const updateExperienceStatus = (id, data) => {
    return (dispatch) =>
        dispatch({
            type: types.UPDATE_EXP_STATUS,
            payload: axios.put(`${process.env.REACT_APP_API_BASE}/experiences/${id}`, data)
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const updatePublished = (id, data, type = types.UPDATE_PUBLISHED_STATUS) => {
    return (dispatch, getState) =>
        dispatch({
            type,
            payload: axios.put(`${process.env.REACT_APP_API_BASE}/publisheds/${id}`, data, {}).then((res) => {
                return res;
            })
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const updateTagsAxios = (id, tags, cats) => {
    const {
        globalState: { inPublished }
    } = store.getState();
    const pathStr = !inPublished ? 'experiences' : 'publisheds';

    return (dispatch, getState) =>
        dispatch({
            type: types.UPDATE_TAGS_CATS_STATUS,
            payload: axios.put(`${process.env.REACT_APP_API_BASE}/${pathStr}/${id}`, { tags, cats }, {}).then((res) => {
                // There was an error from the api response, handle it.

                // if (res.data.error) {
                //     throw new Error(res.data.error.message);
                // }
                return res;
            })
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const updateIncludedGuide = (id, guidedExtra) => {
    const {
        globalState: { inPublished }
    } = store.getState();
    const pathStr = !inPublished ? 'experiences' : 'publisheds';

    return (dispatch, getState) =>
        dispatch({
            type: types.UPDATE_INCLUDES_DATA,
            payload: axios.put(`${process.env.REACT_APP_API_BASE}/${pathStr}/${id}`, { guided_extra: guidedExtra }, {}).then((res) => {
                // There was an error from the api response, handle it.

                // if (res.data.error) {
                //     throw new Error(res.data.error.message);
                // }
                return res;
            })
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const deleteExperience = (id) => {
    return (dispatch) =>
        dispatch({
            type: types.GET_PUBLISHED_ID_LIST,
            payload: axios.delete(`${process.env.REACT_APP_API_BASE}/experiences/${id}`)
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const fetchPublishedIdList = () => {
    const gql = String.raw;
    return (dispatch) =>
        dispatch({
            type: types.GET_PUBLISHED_ID_LIST,
            payload: axios.post(`${process.env.REACT_APP_API_BASE}/graphql`, {
                query: gql`
                    query getPublishedIds {
                        publisheds {
                            id
                            experience_id
                        }
                    }
                `
            })
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const fetchPublishedExpData = (expId) => {
    return (dispatch) =>
        dispatch({
            type: types.GET_GUIDED_PRICE_DATA,
            payload: axios.post(`${process.env.REACT_APP_API_BASE}/graphql`, {
                query: `
                query getExperience {
                    publisheds (where: {experience_id: "${expId}"}) 
                    {   
                        skus  {
                            id
                        }
                    },
                    experienceStatuses (where: {experience: "${expId}"}) 
                    {   
                        id
                    },
                }`
            })
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const updateDataAdmin = (type, end, data, id) => {
    // const {
    //     auth: { user },
    // } = store.getState();
    return (dispatch) =>
        dispatch({
            type,
            payload: axios.put(`${process.env.REACT_APP_API_BASE}/${end}/${id}`, data)
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const createDataAdmin = (type, end, data) => {
    return (dispatch) =>
        dispatch({
            type,
            payload: axios.post(`${process.env.REACT_APP_API_BASE}/${end}`, data)
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const fetchUsersListAdmin = () => {
    return (dispatch) =>
        dispatch({ // { $ne: true }
            type: types.FETCH_USER_LIST,
            payload: axios.post(`${process.env.REACT_APP_API_BASE}/graphql`, {
                query: `
                    query fetchUsersListAdmin  {
                        users (sort: "username:asc", where: { isKreator: true }) {
                            id
                            username,
                        }
                    }
                `
            })
        })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            serverErrors(error);

            return { error };
        });
};

export const fetchPublishedId = (expId) => {
    const gql = String.raw;
    return (dispatch) =>
        dispatch({
            type: types.GET_PUBLISHED_ID,
            payload: axios.post(`${process.env.REACT_APP_API_BASE}/graphql`, {
                query: gql`query getExperience {
                    publisheds (where: {experience_id: "${expId}"}) 
                    {   
                        id,
                    }
                }`
            })
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const updateExpOwner = (updateData) => {
    const { owner, type, pubId, expId, status } = updateData;
    let mutation = `mutation {`;
    let expStr = type ? `type: ${type} ` : ``;

    expStr = status
        ? `${expStr}
        status: ${status}`
        : expStr;
    expStr = `${expStr}${
        owner
            ? `
        user: "${owner}"`
            : ``
    }`;

    if (owner || type || status) {
        mutation = `${mutation}
        update_0: updateExperience(input: { 
            where: {
                id: "${expId}"
            },
            data: {
            ${expStr}
            } }) {
                experience {
                    id
                }
            }`;
        if (pubId) {
            mutation = `${mutation}
                update_1: updatePublished(input: { 
                    where: {
                        id: "${pubId}"
                    },
                    data: {
                        user: "${owner}"
                    } }) {
                        published {
                            id
                        }
                    }`;
        }
    }

    mutation = `${mutation} }`;

    const gql = String.raw;
    return (dispatch) =>
        dispatch({
            type: types.UPDATE_EXP_OWNER,
            payload: axios.post(`${process.env.REACT_APP_API_BASE}/graphql`, {
                query: gql`
                    ${mutation}
                `
            })
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const getDate = async () => {
    try {
        return await axios.get(`${process.env.REACT_APP_API_BASE}/settings/date`);
    } catch (error) {
        // Handle Error Here
        serverErrors(error);
        return error;
    }
};

export const genericMutation = (mutation, type) => {
    return (dispatch) =>
        dispatch({
            type,
            payload: axios.post(`${process.env.REACT_APP_API_BASE}/graphql`, {
                query: `${mutation}`
            })
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const cloneExperience = (expId, userId) => {
    return (dispatch, getState) =>
        dispatch({
            type: types.CLONE_EXPERIENCE,
            payload: axios.post(`${process.env.REACT_APP_API_BASE}/experiences/clone/${expId}`, { userId }).then((res) => {
                // There was an error from the api response, handle it.

                // if (res.data.error) {
                //     throw new Error(res.data.error.message);
                // }
                return res;
            })
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};