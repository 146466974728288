import { arrangeArray } from '../common';

export const updateDescriptionCurrency = (draft, action) => {
    const { descHtml, index } = action;

    draft.experience.local_currency[index].description_html = descHtml;

    return draft;
};

export const arrangeTipsCurrency = (draft, action) => {
    const { index } = action;

    return arrangeArray(
        draft,
        action,
        draft.experience.local_currency[index].tips_html
    );
};

export const deleteTipCurrency = (draft, action) => {
    const { tipIndex, index } = action;

    draft.experience.local_currency[index].tips_html.splice(tipIndex, 1);

    return draft;
};

export const addTipCurrency = (draft, action) => {
    const { dataObj, index } = action;

    draft.experience.local_currency[index].tips_html.push(dataObj);

    return draft;
};

export const updateTipCurrency = (draft, action) => {
    const { index, tipIndex, dataObj } = action;

    draft.experience.local_currency[index].tips_html[tipIndex] = {
        ...draft.experience.local_currency[index].tips_html[tipIndex],
        ...dataObj,
    };

    return draft;
};

export const addCurrencyDetails = (draft, action) => {
    const { dataObj } = action;

    draft.experience.local_currency.push(dataObj);

    return draft;
};
export const deleteCurrencyDetails = (draft, action) => {
    const { index } = action;

    draft.experience.local_currency.splice(index, 1);

    return draft;
};
export const updateCurrencyDetails = (draft, action) => {
    const { dataObj, index } = action;

    draft.experience.local_currency[index] = {
        ...draft.experience.local_currency[index],
        ...dataObj,
    };

    return draft;
};

export const updateCurrencyVendors = (draft, action) => {
    const { dataObj, index } = action;

    draft.experience.local_currency[index].vendors = [...dataObj];

    return draft;
};

export const arrangeCurrencyVendors = (draft, action) => {
    return arrangeArray(draft, action, draft.experience.local_currency);
}
