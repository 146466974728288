import React, { useContext } from 'react';
import { ShepherdTourContext } from 'react-shepherd';
import CollapseButton from 'components/blocks/CollapseButton';
import { useOnlineStatus } from 'helpers/useOnlineStatus';
import { useNavigate } from 'react-router-dom';

const LayoutMenuListEdit = ({ rtl, isAdminStaff = false, isOwner, isSuperAdmin = true, handleSuperAdmin, showHeader }) => {
    const history = useNavigate();
    const isOnline = useOnlineStatus();
    const mainTour = useContext(ShepherdTourContext);
    const logTour = useContext(ShepherdTourContext);
    const handleMainTour = () => {
        mainTour.start();
    };

    const handleHowTo = () => {
        history(`/basics/how-to`, { replace: true });
    };

    const handleLogTour = () => {
        logTour.show(1);
    };

    // const showHeader = useScroll();

    return (
        <>
            <div className='block md:hidden'>
                <div
                    className={`z-50 bg-white fixed tour-right-bar top-0 pt-16 origin-top-right shadow-cards inset-x-0 overflow-x-scroll no-scrollbar
                    transform-gpu duration-300 
                    
                    ${showHeader ? 'translate-y-0' : '-translate-y-16'}

                    `}
                >
                    <div className='flex flex-row flex-nowrap items-center text-2xl px-8 gap-2 py-4'>
                        {!isOnline && (
                            <div className='flex justify-end'>
                                <CollapseButton
                                    labelHover='You are offline'
                                    noHover={true}
                                    handleClick={null}
                                    icon={'ri-wifi-off-line text-2xl'}
                                    size='12'
                                    textSize='text-xs'
                                    sizeHover={'w-44'}
                                    offsetCenter='2'
                                    padding='px-3'
                                    btnColor='bg-red-100 text-red-600'
                                    hoverText=''
                                />
                            </div>
                        )}
                        {isAdminStaff && (
                            <div className='flex justify-end'>
                                <CollapseButton
                                    noHover={true}
                                    labelHover={isSuperAdmin ? 'Superadmin is On' : 'Superadmin is Off'}
                                    handleClick={() => handleSuperAdmin(!isSuperAdmin)}
                                    icon={`${isSuperAdmin ? 'ri-vip-crown-line' : 'ri-vip-crown-line'} text-2xl`}
                                    size='12'
                                    textSize='text-xs font-bold'
                                    sizeHover='w-48'
                                    offsetCenter='2'
                                    padding='px-3'
                                    btnColor={
                                        isSuperAdmin
                                            ? 'bg-yellow-300 text-yellow-900 hover:ring-yellow-600'
                                            : 'bg-yellow-100 text-yellow-800 hover:ring-yellow-400'
                                    }
                                    hoverText=''
                                />
                            </div>
                        )}

                        <div className='flex justify-end'>
                            <CollapseButton
                                noHover={true}
                                labelHover='PHow-to Videos'
                                handleClick={handleHowTo}
                                icon='ri-slideshow-2-line text-2xl'
                                size='12'
                                textSize='text-xs'
                                sizeHover='w-40'
                                offsetCenter='2'
                                padding='px-3'
                            />
                        </div>
                        {/* <div className='flex justify-end'>
                            <CollapseButton
                                noHover={true}
                                labelHover="What's new?"
                                handleClick={handleLogTour}
                                icon='ri-file-list-3-line text-2xl'
                                size='12'
                                textSize='text-xs'
                                sizeHover='w-40'
                                offsetCenter='2'
                                padding='px-3'
                            />
                        </div> */}
                    </div>
                </div>
            </div>
            <div className='hidden md:block'>
                <div className={`z-50 tour-right-bar fixed top-32  ${rtl ? 'left-0' : 'right-0'}`}>
                    <div className='flex-1 flex flex-col p-4 justify-end text-2xl gap-2'>
                        {!isOnline && (
                            <div className='flex justify-end'>
                                <CollapseButton
                                    labelHover='You are offline'
                                    handleClick={null}
                                    icon={'ri-wifi-off-line text-2xl'}
                                    size='12'
                                    textSize='text-xs'
                                    sizeHover={'w-44'}
                                    offsetCenter='2'
                                    padding='px-3'
                                    btnColor='bg-red-100 text-red-600'
                                    hoverText=''
                                />
                            </div>
                        )}
                        {isAdminStaff && (
                            <div className='flex justify-end'>
                                <CollapseButton
                                    labelHover={isSuperAdmin ? 'Superadmin is On' : 'Superadmin is Off'}
                                    handleClick={() => handleSuperAdmin(!isSuperAdmin)}
                                    icon={`${isSuperAdmin ? 'ri-vip-crown-line' : 'ri-vip-crown-line'} text-2xl`}
                                    size='12'
                                    textSize='text-xs font-bold'
                                    sizeHover='w-48'
                                    offsetCenter='2'
                                    padding='px-3'
                                    btnColor={
                                        isSuperAdmin
                                            ? 'bg-yellow-300 text-yellow-900 hover:ring-yellow-600'
                                            : 'bg-yellow-100 text-yellow-800 hover:ring-yellow-400'
                                    }
                                    hoverText=''
                                />
                            </div>
                        )}

                        <div className='flex justify-end'>
                            <CollapseButton
                                labelHover='How-to Videos'
                                handleClick={handleHowTo}
                                icon='ri-slideshow-2-line text-2xl'
                                size='12'
                                textSize='text-xs'
                                sizeHover='w-40'
                                offsetCenter='2'
                                padding='px-3'
                            />
                        </div>

                        {/* <div className='flex justify-end'>
                            <CollapseButton
                                labelHover="What's new?"
                                handleClick={handleLogTour}
                                icon='ri-file-list-3-line text-2xl'
                                size='12'
                                textSize='text-xs'
                                sizeHover='w-40'
                                offsetCenter='2'
                                padding='px-3'
                            />
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default LayoutMenuListEdit;
