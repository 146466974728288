import React, { useState, useEffect, useCallback } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { ButtonsGroupNextPrev } from 'components/blocks/Button/Buttons';
import 'styles/embla.css';

const CarouselCards = ({ children, scrollSlides = 5, loop = false }) => {
    const [viewportRef, embla] = useEmblaCarousel({
        dragFree: true,
        containScroll: 'trimSnaps',
        loop: loop
    });
    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

    const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
    const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
    const onSelect = useCallback(() => {
        if (!embla) return;
        setPrevBtnEnabled(embla.canScrollPrev());
        setNextBtnEnabled(embla.canScrollNext());
    }, [embla]);

    useEffect(() => {
        if (!embla) return;
        embla.on('select', onSelect);
        onSelect();
    }, [embla, onSelect]);

    return (
        <div className=''>
            <div className=''>
                <ButtonsGroupNextPrev
                    nextEnabled={nextBtnEnabled}
                    nextAction={scrollNext}
                    prevEnabled={prevBtnEnabled}
                    prevAction={scrollPrev}
                    // isPrev={!prevBtnState}
                    className={`z-30 touch:hidden`}
                    // rtl={rtl}
                />
            </div>

            <div className='embla'>
                <div className='embla__viewport' ref={viewportRef}>
                    <div className='embla__container'>{children}</div>
                </div>
            </div>
        </div>
    );
};

export default CarouselCards;
