import * as types from 'store/actions/types';

export function clearExperience() {
    return (dispatch) => {
        dispatch({
            type: types.CLEAR_EXPERIENCE
        });
    };
}

export function clearAllExperiences() {
    return (dispatch) => {
        dispatch({
            type: types.CLEAR_ALL_EXPERIENCES
        });
    };
}

export function experienceHasUpdated() {
    return (dispatch) => {
        dispatch({
            type: types.SET_EXPERIENCE_UPDATED
        });
    };
}