import KnIcons from 'constants/KnIcons';
import uiStruct from 'constants/uiStruct';
import Icons from 'components/blocks/Icons';
import { PillLogo } from 'components/blocks/Pills';

const errorObj = {
    401: {
        color: 'kn-red',
        icon: '',
        title: 'Error 401',
        body: 'Some error msg'
    },
    404: {
        color: 'green-400',
        icon: '',
        title: 'Error 404',
        body: 'Nothing to see here'
    },
    403: {
        color: 'green-400',
        icon: '',
        title: 'Error 403',
        body: "Sorry, you don't have access to this page"
    },
    500: {
        color: 'kn-red',
        icon: 'ri-server-line',
        title: 'Error 500',
        body: 'Some server error'
    }
};

const ErrorPage = ({ err = 404 }) => {
    return (
        <div className='w-full flex flex-col justify-center items-center h-screen-90'>
            {/* Pill shape Bottom */}
            <div className='pb-4'>
                <PillLogo />
            </div>

            <div className={`text-6xl font-bold text-${errorObj[err].color}`}>
                {errorObj[err].title}
            </div>
            <div className={`text-lg text-gray-700`}>{errorObj[err].body}</div>
        </div>
    );
};

export default ErrorPage;
