/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Fuse from 'fuse.js';
import ModalContainer, {
    ModalBody,
    ModalFooter,
    ModalCTA,
    ModalButton,
    ModalHelp
} from 'components/blocks/ModalTre';
import GenericBtn from 'components/blocks/GenericBtn';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import ButtonTags from 'components/blocks/ButtonTags';

// should be in all modals
const targetElement = document.querySelector('#root');

function GuidedIncludeListModal({
    isAdminStaff = false,
    isOwner = true,
    list = [],
    selectedIncludeList = [],
    selectedExcludeList = [],
    setModalIsOpen,
    handleActionBtn,
    globalState: { lang, isSuperAdmin },
    hintObj = null,
    icon = uiStruct.itinerary.moving_around.uiEdit.modals.add.icon,
    actionBtnObj = uiStruct.itinerary.moving_around.uiEdit.modals.add.buttons
        .action,
    cancelBtnObj = uiStruct.itinerary.moving_around.uiEdit.modals.add.buttons
        .cancel
}) {
    const rtl = !!translations[lang].rtl;

    // Copy Below to all modals
    //////////////////////////////////////////////////////////////////////

    // State for triggering animation of modal in and out
    const [transitionState, setTransitionState] = useState('off');
    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    ///////////////////////////////////////////////////////////////////
    const [includeList, setIncludeList] = useState([...selectedIncludeList]);
    const [excludeList, setIExcludeList] = useState([...selectedExcludeList]);
    const [tagKey, tagRerender] = useReducer((v) => Math.random());
    const [catKey, catRerender] = useReducer((v) => !v);

    // Paul stuff Handle Action BTN function
    const handleActionClick = () => {
        handleActionBtn(includeList, excludeList);
        handleTransitionStateOff(); // important... don't forget to add it in all modals
    };

    ///////////////////////////////

    const filterIncludeOptions = () => {
        const tmpList = list.filter((lItem) => {
            return !includeList.includes(lItem) && !excludeList.includes(lItem);
        });
        return tmpList.map((item) => {
            return {
                value: item,
                label: translations[lang][item],
                fixed: true
            };
        });
    };
    const [selectOptions, setSelectOptions] = useState(filterIncludeOptions());

    const selectIncludeChange = (data) => {
        const val = data.value;

        setIncludeList([...includeList, val]);
    };

    const removeIncluded = (name) => {
        setIncludeList(includeList.filter((val) => val !== name));
    };

    const selectExcludeChange = (data) => {
        const val = data.value;

        setIExcludeList([...excludeList, val]);
    };

    const removeExcluded = (name) => {
        setIExcludeList(excludeList.filter((val) => val !== name));
    };

    useEffect(() => {
        setTimeout(() => {
            setSelectOptions(filterIncludeOptions());
            catRerender();
            tagRerender();
        }, 500);
    }, [includeList, excludeList]);

    const filterCatOption = (candidate, input) => {
        let startsInput = false;
        const regex = new RegExp(`^${input.toLowerCase()}`);

        if (input.length < 3) {
            startsInput = regex.test(candidate.label.toLowerCase());
        }

        return candidate.data.__isNew__ || startsInput;
    };

    return (
        <>
            <ModalContainer
                width='xl'
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={{
                    name: 'ARCHIVE',
                    size: '2xl'
                }}
                // icon={{ name: selectedType.id, size: '4xl' }}

                rtl={rtl}
            >
                <ModalBody height='24'></ModalBody>
                <div className='mb-16'>
                    <div key={catKey}>
                        <div className='mb-4'>Included</div>
                        <Select
                            isClearable={false}
                            options={selectOptions}
                            onChange={selectIncludeChange}
                            filterOption={filterCatOption}
                        />
                    </div>
                    <div className='flex flex-wrap items-center gap-2 w-full max-w-3xl mt-4'>
                        {includeList.map((name, index) => {
                            return (
                                <div key={`${name}_${index}`}>
                                    <ButtonTags
                                        handleActionBtn={removeIncluded}
                                        label={translations[lang][name]}
                                        params={[name]}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className='mb-16'>
                    <div key={tagKey}>
                        <div className='mb-4'>Excluded</div>
                        <Select
                            isClearable={false}
                            options={selectOptions}
                            onChange={selectExcludeChange}
                            filterOption={filterCatOption}
                        />
                    </div>
                    <div className='flex flex-wrap items-center gap-2 w-full max-w-3xl mt-4'>
                        {excludeList.map((name, index) => {
                            return (
                                <div key={`${name}_${index}`}>
                                    <ButtonTags
                                        handleActionBtn={removeExcluded}
                                        label={translations[lang][name]}
                                        params={[name]}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>

                <ModalFooter>
                    {hintObj && (
                        <ModalHelp
                            helpText={translations[lang][hintObj]}
                            isHtml
                        />
                    )}
                    {(isOwner || (isAdminStaff && isSuperAdmin)) && (
                        <ModalCTA>
                            <ModalButton
                                handleClick={handleTransitionStateOff}
                                label={translations[lang][cancelBtnObj.label]}
                                color={cancelBtnObj.color}
                                icon={cancelBtnObj.icon}
                            />
                            <ModalButton
                                handleClick={handleActionClick}
                                label={translations[lang][actionBtnObj.label]}
                                color={actionBtnObj.color}
                                icon={actionBtnObj.icon}
                            />
                        </ModalCTA>
                    )}
                </ModalFooter>
            </ModalContainer>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(GuidedIncludeListModal);
