import Spinner from 'components/blocks/Spinner';
import loadingMessages from 'constants/loadingMessages';
import { randomItem } from 'helpers/FEutils';

function LayoutLoading({
    message,
    showMessage = true,
    spinnerColor,
    height = 'h-screen-100'
}) {
    return (
        <>
            <div
                className={`w-full ${height} flex items-center justify-center`}
            >
                <div className='flex flex-col justify-center items-center '>
                    <span className='flex justify-center'>
                        <Spinner
                            size='64'
                            strokeWidth='20'
                            strokeColor={spinnerColor}
                        />
                    </span>
                    {showMessage && (
                        <span className='mt-6 text-sm uppercase tracking-wide text-gray-600'>
                            {message ? message : randomItem(loadingMessages)}
                        </span>
                    )}
                </div>
            </div>
        </>
    );
}

export default LayoutLoading;
