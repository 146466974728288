/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import translations from 'constants/translations';

import { handleRowReverse } from 'helpers/FEutils';

import { createItineraryDay } from 'store/actions/experienceDetails/overview';

import 'react-circular-progressbar/dist/styles.css';
import 'styles/progressCircle.css';

import { updateFeatureImage } from 'store/actions/experienceDetails/sidebar';

const LayoutCommentsSidebar = (props) => {
    const {
        publicView,
        globalState: { lang },
        menuIsOpen,
        handleMenuIsOpen,
        children
    } = props;

    // let userRole = null;
    // let isAdminStaff = false;

    // if (!publicView) {
    //     userRole = props.auth.user.role;
    //     isAdminStaff =
    //         userRole === process.env.REACT_APP_STRAPI_ADMIN_ROLE_ID ||
    //         userRole === process.env.REACT_APP_STRAPI_ADMIN_ROLE_ID;
    // }
    // const isPublishedView = window.location.pathname.includes(
    //     process.env.REACT_APP_BASENAME_EXPERIENCE_PUBLISHED
    // );
    const rtl = !!translations[lang].rtl;

    const [move] = useState(false);

    return (
        <div className={`print:hidden fixed top-0 z-100 `}>
            <div
                onClick={() => handleMenuIsOpen(false)}
                className={`fixed xl:hidden inset-0 bg-black duration-300 transition-opacity ${
                    menuIsOpen ? 'opacity-50' : 'opacity-0 pointer-events-none'
                }`}
            ></div>

            <div className={`w-full md:w-96 ${handleRowReverse(rtl).rtl}`}>
                <div
                    style={{ zIndex: 51 }}
                    className={`${
                        handleRowReverse(rtl).rtl
                    } fixed bottom-0 inset-x-0 h-16 md:h-20 bg-white transform-gpu duration-300 translate-y-0 shadow-cards-top md:hidden
                    `}
                >
                    <div className='flex flex-1 flex-row justify-start md:justify-between items-center h-full gap-4'>
                        <div className='flex justify-start items-center h-full'>
                            <button
                                onClick={() => handleMenuIsOpen(!menuIsOpen)}
                                className={`focus:outline-none w-20 
                                    flex flex-shrink-0 h-12 items-center justify-center text-2xl bg-yellow-300 hover:bg-gray-900 hover:text-white rounded-r-lg`}
                            >
                                <i
                                    className={`${
                                        menuIsOpen
                                            ? rtl
                                                ? 'ri-menu-unfold-line'
                                                : 'ri-menu-fold-line'
                                            : rtl
                                            ? 'ri-menu-fold-line'
                                            : 'ri-menu-unfold-line'
                                    }`}
                                ></i>
                            </button>
                        </div>
                        <div className='ml-0 mr-6 w-full flex flex-row items-center gap-4 justify-between'></div>
                    </div>
                </div>
                {true && (
                    <div
                        className={`z-50 w-96 ${
                            handleRowReverse(rtl).left
                        }-0 fixed   transition duration-500 ease-in-out hidden md:block transform ${
                            menuIsOpen
                                ? 'translate-x-0'
                                : handleRowReverse(rtl).menuTranslate
                        }`}
                    >
                        <button
                            onClick={() => handleMenuIsOpen(!menuIsOpen)}
                            className={`flex absolute top-4 tour-drawer outline-none focus:outline-none w-12 h-12
                             items-center justify-center text-xl bg-yellow-300 hover:bg-gray-900 hover:text-white ${
                                 rtl
                                     ? 'rounded-l-lg -left-12'
                                     : 'rounded-r-lg -right-12'
                             }`}
                        >
                            <i
                                className={`${
                                    menuIsOpen
                                        ? rtl
                                            ? 'ri-menu-unfold-line'
                                            : 'ri-menu-fold-line'
                                        : rtl
                                        ? 'ri-menu-fold-line'
                                        : 'ri-menu-unfold-line'
                                }`}
                            ></i>
                        </button>
                    </div>
                )}

                <div
                    className={`z-50 ${
                        handleRowReverse(rtl).left
                    }-0 fixed overflow-y-auto no-scrollbar ${
                        publicView ? '' : ''
                    } md:pt-0 lg:overflow-visible2 top-0 h-full w-full md:w-96  tour-exp-step1 bg-yellow-100 shadow-images transition duration-500 ease-in-out transform ${
                        menuIsOpen
                            ? 'translate-x-0'
                            : handleRowReverse(rtl).menuTranslate
                    }`}
                >
                    <div className='flex flex-col tour-left-nav '>
                        <nav
                            className={`relative flex-1 h-full transform transition duration-500 ${
                                move ? '-translate-y-72' : 'translate-y-0'
                            }`}
                        >
                            <div

                            // className='h-max lg:h-screen-2/5-5 xl:h-screen-1/2-5 overflow-y-scroll pb-20 md:pb-36'
                            >
                                {children}
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { updateFeatureImage, createItineraryDay },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LayoutCommentsSidebar);
