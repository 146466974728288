import { connect } from 'react-redux';
import Geocode from 'react-geocode';
// import { useLoadScript } from '@react-google-maps/api';
import MapSearch from 'components/map/MapSearch';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { handleRowReverse } from 'helpers/FEutils';

const libraries = ['places'];

const MapBoxSearch = ({
    globalState: { lang },
    handleAddDestination,
    clearInput
}) => {
    const rtl = !!translations[lang].rtl;
    // const { isLoaded, loadError } = useLoadScript({
    //     googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    //     libraries,
    // });

    // if (loadError) return 'Error';
    // if (!isLoaded) return 'Loading...';

    // Edit address box
    const handleSearch = (destObj) => {
        handleAddDestination(destObj);
    };

    // JSX
    return (
        <MapSearch
            clearInput={clearInput}
            searchOnly
            handleSearch={handleSearch}
            placeholder={
                translations[lang][
                    uiStruct.overview.destination.uiEdit.modals.places
                        .placeholders.autocomplete
                ]
            }
        />
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(MapBoxSearch);
