import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ListBoxGeneric from 'components/blocks/ListBoxGeneric';
import CardXlEditDelete from 'components/blocks/CardXlEditDelete';
import { handleRowReverse } from 'helpers/FEutils';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { BlockInputSingle } from 'components/blocks/Blocks';
import { ModalButton } from 'components/blocks/ModalTre';
import { _GETTING_THERE, _ACTIVITIES } from 'store/actions/types';
// import ModalTopBlock from 'components/blocks/ModalTopBlock';

// overview.getting_there.uiEdit.transport_list_vendors
function LinkTitle({
    noDelete = false,
    single = false,
    actionType = {},
    data,
    updateVendorUrls,
    deleteVendorUrls,
    addVendorUrls,
    validationObj,
    saveAttempted,
    globalState: { lang },
    labels
    // modalTitle,
    // modalSubTitle
}) {
    const rtl = !!translations[lang].rtl;
    const isGettingThereType =
        actionType === _GETTING_THERE || actionType === _ACTIVITIES
            ? true
            : false;
    const noUrl = actionType === _GETTING_THERE;
    const handleUpdateList = (selectedObj) => {
        updateVendorUrls('name', null, null, selectedObj.id);
        setSelectedType(selectedObj);
    };

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        if (name === 'name') {
            updateVendorUrls('name', index, name, value);
        } else {
            updateVendorUrls('url', index, name, value);
        }
    };
    const vendorList =
        actionType === _GETTING_THERE
            ? uiStruct.overview.getting_there.uiEdit.transport_list_vendors
            : actionType === _ACTIVITIES
            ? uiStruct.itinerary.activities.uiEdit.extra_list
            : [];
    const gettingThereTypes = isGettingThereType
        ? vendorList.map((key) => {
              return {
                  id: key,
                  name: translations[lang][key],
                  unavailable: false
              };
          })
        : null;

    const [selectedType, setSelectedType] = useState(() => {
        if (isGettingThereType) {
            const name = !data.name ? gettingThereTypes[0].id : data.name;

            return isGettingThereType
                ? gettingThereTypes[
                      gettingThereTypes.findIndex((obj) => {
                          return obj.id === name;
                      })
                  ]
                : null;
        }
    });

    useEffect(() => {
        if (isGettingThereType && !data.name) {
            updateVendorUrls('name', null, null, gettingThereTypes[0].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const buildUrls = () => {
        return data.url.map((obj, index) => {
            const key = `vendor_${index}`;
            return (
                <li
                    key={key}
                    className={`relative w-full items-center justify-between ${
                        handleRowReverse(rtl).pr
                    }-4  flex flex-col md:flex-row md:${
                        handleRowReverse(rtl).flex
                    } flex-wrap md:flex-nowrap  mb-4 
                    `}
                >
                    {!noDelete && (
                        <CardXlEditDelete
                            handleDelete={() => {
                                deleteVendorUrls(index);
                            }}
                            rtl={rtl}
                        />
                    )}
                    <div
                        className={`flex w-full flex-col gap-4 md:flex-row md:${
                            handleRowReverse(rtl).flex
                        } ${
                            handleRowReverse(rtl).pr
                        }-10 flex-wrap md:flex-nowrap  md:items-center
                             `}
                    >
                        <BlockInputSingle
                            error={
                                validationObj.url[index].label.error &&
                                saveAttempted
                            }
                            className='text-xs'
                            height='h-10'
                            width={`w-full ${noUrl ? '' : 'md:w-full'}`}
                            rounded='rounded-lg'
                            handleChange={(e) => handleChange(e, index)}
                            id={`label${index}`}
                            name='label'
                            value={obj.label}
                            placeholder={labels.labelVendorsPlaceholderName}
                        />

                        {!noUrl && (
                            <BlockInputSingle
                                error={
                                    validationObj.url[index].url.error &&
                                    saveAttempted
                                }
                                className={`text-xs ${noUrl ? 'hidden' : ''}`}
                                height='h-10'
                                width='w-full'
                                rounded='rounded-lg'
                                handleChange={(e) => handleChange(e, index)}
                                id={`url${index}`}
                                name='url'
                                value={obj.url}
                                placeholder={labels.labelVendorsPlaceholderUrl}
                            />
                        )}
                    </div>
                </li>
            );
        });
    };

    return (
        <>
            <div className='block px-4'>
                <div className='px-2 mb-8'>
                    <div className='flex flex-col'>
                        {isGettingThereType ? (
                            <ListBoxGeneric
                                listData={gettingThereTypes}
                                val={selectedType}
                                handleChange={handleUpdateList}
                            />
                        ) : (
                            <BlockInputSingle
                                error={
                                    validationObj.name.error && saveAttempted
                                }
                                className='flex-grow'
                                height='h-12'
                                width='w-full'
                                rounded='rounded-lg'
                                handleChange={handleChange}
                                id={`name`}
                                name='name'
                                value={data.name}
                                placeholder={
                                    labels.labelVendorsPlaceholderTitle
                                }
                            />
                        )}
                    </div>
                </div>
                <div className='w-full px-6 mb-4 text-sm'>
                    <div>{labels.labelVendors}</div>
                </div>
                <div className='w-full px-2'>
                    <ul>{buildUrls()}</ul>
                </div>
                {!single && (
                    <div className='flex justify-center pt-4'>
                        <ModalButton
                            handleClick={addVendorUrls}
                            icon={{ name: 'PLUS' }}
                            color='plusdark'
                        />
                    </div>
                )}
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(LinkTitle);
