import store from 'store';
import * as types from 'store/actions/types';
import { menuRefsSetting, menuRefsSet } from 'store/actions/globalState/master';

// Singleton
class MenuRefsHolder {
    constructor() {
        this.sectionRefs = [];
        this.sectionsMainNav = {};
        this.count = 0;
        this.totalRefCount = 0;
        this.update = false;
    }

    getMenuCount(refType, dayIndex) {
        const { experienceDetails } = store.getState();
        const overviewDefaultMenuCount =
            experienceDetails.type.toLowerCase() === 'digital' ? 10 : 9; // number of static menu items / mkGuided mkPolicies mkItinerary
        const marketingDefaultMenuCount = 6;
        const dayDefaultMenuCount = 3;
        const {
            experience: {
                itinerary: { trip_days },
                moving_around,
                moving_around_visible: { isVisible: moveAroundIsVisible }
            },
            type: expType
        } = experienceDetails;
        const daysCount = trip_days.length;
        const movingAroundCount = moveAroundIsVisible
            ? moving_around.length
            : 0;

        switch (refType) {
            case types.REFS_OVERVIEW:
                return (
                    overviewDefaultMenuCount +
                    (expType.toLowerCase() === 'digital' ? 1 : 0) +
                    daysCount +
                    movingAroundCount
                );
            case types.REFS_MARKETING:
                return (
                    marketingDefaultMenuCount +
                    (expType.toLowerCase() === 'guided' ? 1 : 0)
                );
            default:
                const { activities } =
                    experienceDetails.experience.itinerary.trip_days[dayIndex];

                return dayDefaultMenuCount + activities.length;
        }
    }

    reset(type, dayIndex) {
        store.dispatch(menuRefsSetting());
        this.sectionRefs = [];
        this.sectionsMainNav = {};
        this.count = 0;
        this.totalRefCount = this.getMenuCount(type, dayIndex);
        this.update = true;
    }

    setRef(ref) {
        if (ref && this.update) {
            const { id: name } = ref;

            this.sectionRefs = [...this.sectionRefs, { section: name, ref }];
            this.sectionsMainNav = {
                ...this.sectionsMainNav,
                [name]: {
                    id: name,
                    ref
                }
            };
            this.count++;

            if (this.count >= this.totalRefCount) {
                this.update = false;
                store.dispatch(menuRefsSet());
            }
        }
    }

    static instance = null;
    static createInstance() {
        const object = new MenuRefsHolder();
        return object;
    }

    static getInstance() {
        if (!MenuRefsHolder.instance) {
            MenuRefsHolder.instance = MenuRefsHolder.createInstance();
        }
        return MenuRefsHolder.instance;
    }
}

const instance = MenuRefsHolder.getInstance();
export { instance as MenuRefsHolder };
