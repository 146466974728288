import { User, Clock, MapPin, Users, Layers } from 'lucide-react';
import { capitalize, getDays, kreatorName } from 'helpers/FEutils';
import { country, findLowestPrice } from 'helpers/LocaleHelper';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MarketingInfo from 'components/blocks/MarketingInfo';

const SectionMarketingTitles = (props) => {
    const {
        experienceDetails: {
            experience,
            id,
            days,
            budget_min,
            budget_max,
            type,
            cats_list: tmpCats,
            places_lists: tmpPlaces,
            user,
            user: { profile }
        },
        globalState: { lang, edit, siteData }
    } = props;

    const {
        overview_intro: { title }
    } = experience;

    const places_lists = tmpPlaces.map((place) => {
        const found = siteData.placesLists.find((elm) => elm.id == place);
        return found;
    });
    const cats_list = tmpCats.map((cat) => {
        const found = siteData.categories.find((elm) => elm.id == cat);
        return found?.name;
    });

    const EmptyData = <span className='w-20 bg-gray-300 rounded-full h-2' />;
    const ContentDays = days ? <span> {getDays(days)}</span> : EmptyData;

    return (
        <div className={`z-100 mb-6 mt-16 mx-auto px-5 md:px-9 lg:px-12 xl:px-241 2xl:px-401 xl:max-w-7xl`}>
            <div className={`px-4`}>
                <div className='inline-flex text-transparent bg-clip-text bg-gradient-to-l from-blue-600 via-green-400 to-green-400 font-bold text-3xl tracking-tight leading-none pb-1 flex-shrink-0 flex-initial'>
                    {title}
                </div>
                <MarketingInfo />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionMarketingTitles);
