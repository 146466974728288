import { combineReducers } from 'redux';
import auth from './auth';
import experiences from './experiences';
import experienceDetails from './experienceDetails';
import editor from './editor';
import globalState from './globalState';
import session from './session';

export default combineReducers({
    auth,
    experiences,
    experienceDetails,
    globalState,
    session
});
