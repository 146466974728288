export const getBtnLabel = (experienceStatus) => {
    const { status } = experienceStatus;

    switch (status) {
        case 'initial':
            return 'Submit Experience';
        case 'submitted':
            return 'Submit Changes';
        case 'pending':
            return 'Submit Changes';
        case 'approved':
            return 'Re-Submit Experience';
        default:
    }
};

export const getBtnLabelPublished = (isPublished) => {
    switch (isPublished) {
        case true:
            return 'Unpublish';
        default:
            return 'Publish';
    }
};

export const isPurchasedPublicPage = (path) => {
    const pathRegEx = /purchased\/.{1,}\//gi;

    return pathRegEx.test(path);
}