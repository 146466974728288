import Footer2 from 'components/layouts/Footer2';
import LayoutHeader from 'components/layouts/LayoutHeader';
import LayoutLanding from 'components/layouts/LayoutLanding';
import LayoutNavbar from 'components/layouts/LayoutNavbar';

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

const PageLanding = (props) => {
    return (
        <>
            <LayoutNavbar />
            {/* <LayoutHeader /> */}
            {/* <LayoutNavbar /> */}
            <LayoutLanding />
            <Footer2 isBody={true} isBottomBar={true} />
        </>
    );
};

export default PageLanding;
