import { Pagination } from 'react-headless-pagination';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import classNames from 'classnames';
import { useOnlineStatus } from 'helpers/useOnlineStatus';
function Paginate({ paginateTotal, maxPerPage, setPagination, currentPage }) {
    const isOnline = useOnlineStatus();
    return (
        <>
            {paginateTotal > maxPerPage && (
                <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setPagination}
                    className='flex items-center w-full h-10 text-sm select-none  px-6'
                    truncableText='...'
                    truncableClassName='w-10 px-0.5 text-center'
                    totalPages={Math.ceil(paginateTotal / maxPerPage)}
                    edgePageCount={2}
                    middlePagesSiblingCount={2}
                >
                    <Pagination.PrevButton
                        className={classNames(
                            !isOnline && 'pointer-events-none',
                            'flex items-center text-gray-500 gap-2 dark:hover:text-gray-200a focus:outline-none rounded-full hover:bg-gray-900 hover:text-white px-4 py-1',
                            {
                                'cursor-pointer': currentPage !== 0,
                                'opacity-50': currentPage === 0
                            }
                        )}
                    >
                        <FiArrowLeft size={20} className={classNames('')} />
                        <span className='hidden md:block'>Previous</span>
                    </Pagination.PrevButton>

                    <div className='flex items-center justify-center flex-grow'>
                        <Pagination.PageButton
                            activeClassName='bg-green-400 text-green-900 rounded-full font-bold'
                            inactiveClassName='text-gray-500'
                            className={classNames(
                                !isOnline && 'pointer-events-none',
                                'flex items-center justify-center h-10 w-10 rounded-full cursor-pointer hover:bg-gray-900 hover:text-white'
                            )}
                        />
                    </div>

                    <Pagination.NextButton
                        className={classNames(
                            !isOnline && 'pointer-events-none',
                            'flex items-center gap-2 text-gray-500 dark:hover:text-gray-200a focus:outline-none rounded-full hover:bg-gray-900 hover:text-white px-4 py-1',
                            {
                                'cursor-pointer': currentPage !== paginateTotal - 1,
                                'opacity-50': currentPage === paginateTotal - 1
                            }
                        )}
                    >
                        <span className='hidden md:block'>Next</span>
                        <FiArrowRight size={20} className={classNames('')} />
                    </Pagination.NextButton>
                </Pagination>
            )}
        </>
    );
}

export default Paginate;
