import { animateScroll as scroll } from 'react-scroll';
import { createLog, versionLog } from 'constants/versionLog';

const youtubeVid = (code) => {
    return `<iframe width='100%' height='100%' src='https://www.youtube-nocookie.com/embed/playlist?list=${code}&modestbranding=1&rel=0&iv_load_policy=3&theme=light&color=white' frameorder='0' allowFullScreen></iframe>`;
};

const targetElement = document.querySelector('#root');

const scrollThis = (selector) => {
    const targetEl = document.querySelector(selector);
    scrollTo(targetEl);
};

const scrollTo = (ele) => {
    const { offsetTop, offsetBottom } = getDimensions(ele);
    scroll.scrollTo(offsetTop - 150, { smooth: true });
};

const getDimensions = (ele) => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return {
        height,
        offsetTop,
        offsetBottom
    };
};

const buttons = [
    {
        classes: 'shepherd-button-secondary',
        text: 'Skip',
        type: 'cancel'
    },
    {
        classes: 'shepherd-button-third',
        text: 'Back',
        type: 'back'
    },
    {
        classes: 'shepherd-button',
        text: 'Next',
        type: 'next'
    }
];

const buttons2 = [
    {
        classes: 'shepherd-button-secondary',
        text: 'Exit',
        type: 'cancel'
    },
    {
        classes: 'shepherd-button',
        text: 'Next',
        type: 'next'
    }
];

const waitFunction = function () {
    return new Promise(function (resolve) {
        setTimeout(function () {
            // window.scrollTo(0, 0);
            resolve();
        }, 500);
    });
};

export const tourOptions = {
    defaultStepOptions: {
        cancelIcon: {
            enabled: true
        },
        scrollTo: { behavior: 'smooth', block: 'center' }
    },
    useModalOverlay: true
};

export const singleSteps = [
    {
        id: 'intro',
        // attachTo: { element: '.root', on: 'auto' },

        buttons,
        scrollTo: false,
        cancelIcon: {
            enabled: true
        },
        title: "<span class='text-2xl mr-4'>👋</span> Hello fellow Kreator",
        text: [
            "<p class='mb-4'><strong>Welcome to the builder tour!</strong> We're excited to have you on board and know you're eager to get started, so we will make this brief. We've prepared a couple of slides to get you familiar with the builder tool.</p><p class='mb-4'> You can exit the tour at any moment.</p><p class='mb-4'><strong>Kreators...ahoy!!</strong></p>"
        ]
    },
    {
        id: 'intro',
        attachTo: { element: '.tour-left-nav', on: 'auto' },

        buttons,
        scrollTo: false,
        cancelIcon: {
            enabled: true
        },
        title: "<span class='text-2xl mr-4'>🧐</span> This is the Experience Nav bar",
        text: [
            "It gives you a global view of the page we're editing; in this case, it's <strong>the Overview</strong> page."
        ]
    },
    {
        id: 'intro',
        attachTo: { element: '.tour-drawer', on: 'auto' },

        buttons,
        scrollTo: false,
        cancelIcon: {
            enabled: true
        },
        title: "<span class='text-2xl mr-4'>🧐</span> Opening and Closing",
        text: [
            'For more screen real estate use the drawer button to open and close the nav bar.'
        ]
    },
    {
        id: 'intro',
        attachTo: { element: '.tour-featured', on: 'auto' },

        buttons,
        scrollTo: false,
        cancelIcon: {
            enabled: true
        },
        title: "<span class='text-2xl mr-4'>🔍</span> Your Thumbnail and Experience type",
        text: [
            "<p class='mb-4'>This box will hold the image that shows in search results. Make it pop 😎 and make it unique. The black bar lists your experience type.</p>"
        ]
    },

    {
        id: 'intro',
        attachTo: { element: '.tour-arrow-nav', on: 'auto' },

        buttons,
        scrollTo: false,
        cancelIcon: {
            enabled: true
        },
        title: "<span class='text-2xl mr-4'>🧭</span> Navigating through pages",
        text: [
            "<p class='mb-4'>Once you have several pages in your experience, you can start moving between them using the left/right arrow buttons or by selecting the page from the dropdown box.</p>"
        ]
    },
    {
        id: 'intro',
        attachTo: { element: '.tour-add-nav', on: 'auto' },

        buttons,
        scrollTo: false,
        cancelIcon: {
            enabled: true
        },
        title: "<span class='text-2xl mr-4'>📅</span> Adding Days",
        text: [
            "<p class='mb-4'>To add a new page/day, click the ➕ button located above the drop down box.</p>"
        ]
    },
    {
        id: 'intro',
        attachTo: { element: '.tour-nav-section', on: 'auto' },

        buttons,
        scrollTo: false,
        cancelIcon: {
            enabled: true
        },
        title: "<span class='text-2xl mr-4'>🧐</span> Nav sections 1/3",
        text: [
            "<p class='mb-4'>Each page is made of several sections. We're looking here at the summary section.</p><p>Click on section to scroll automatically to it.</p>"
        ]
    },
    {
        id: 'intro',
        attachTo: { element: '.tour-nav-section', on: 'auto' },

        buttons,

        cancelIcon: {
            enabled: true
        },
        title: "<span class='text-2xl mr-4'>🧐</span> Nav sections 2/3",
        text: [
            "<p class='mb-4'>Each section has a small circle chart showing you how much you still have to complete it.</p><p>If we look at our content in the center we can clearly see what's missing...</p>"
        ]
    },
    {
        id: 'intro',
        attachTo: { element: '.tour-ov-intro-featured', on: 'left' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                scrollThis('.tour-ov-intro-featured');
                setTimeout(function () {
                    resolve();
                }, 1000);
            });
        },
        scrollTo: false,
        buttons,

        cancelIcon: {
            enabled: true
        },
        title: "<span class='text-2xl mr-4'>🧐</span> Missing 1/3",
        text: ["<p class='mb-4'>The Overview featured image.</p>"]
    },
    {
        id: 'intro',
        attachTo: { element: '.tour-ov-intro-title', on: 'left' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                scrollThis('.tour-ov-intro-title');
                setTimeout(function () {
                    resolve();
                }, 1000);
            });
        },
        scrollTo: false,
        buttons,

        cancelIcon: {
            enabled: true
        },
        title: "<span class='text-2xl mr-4'>🧐</span> Missing 2/3",
        text: [
            "<p class='mb-4'>Our experience title is clearly not filled yet. Unfilled fields will always be italicized and slighlty gray.</p>"
        ]
    },
    {
        id: 'intro',
        attachTo: { element: '.tour-ov-intro-description', on: 'left' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                scrollThis('.tour-ov-intro-description');
                setTimeout(function () {
                    resolve();
                }, 1000);
            });
        },

        scrollTo: false,
        buttons,

        cancelIcon: {
            enabled: true
        },
        title: "<span class='text-2xl mr-4'>🧐</span> Missing 3/3",
        text: [
            "<p class='mb-4'>And our exeprience summary is also missing.</p><p>Once you fill all 3, you should have a complete summary section</p>"
        ]
    },

    {
        id: 'intro',
        attachTo: { element: '.tour-right-bar', on: 'left' },
        // beforeShowPromise: waitFunction,
        buttons,

        cancelIcon: {
            enabled: true
        },
        title: 'This is the Experience Toolbar',
        text: [
            '<p class="mb-8">The right tool bar gives you access to:</p><p class="mb-4"><strong>1. Save and Autosave button.</strong> If you make any changes to your content, an autosave will be triggered within 30 seconds. You can always manually force save by pressing the button.</p><p class="mb-4"><strong>2. An edit/preview mode.</strong> The preview mode shows you what your experience would look like for buyers. It strips away all the xtra buttons, handles and overlays that are useful for you as a kreator but not so useful for people to consume the content.</p><p class="mb-4"><strong>3. Experience settings.</strong> a set of buttons to choose the category or theme to which your experience belongs, the tags or keywords, as well as setting a price / choosing dates of your availability</p>'
        ]
    }
];

export const wanderSteps = [
    {
        id: 'intro',
        // attachTo: { element: '.root', on: 'auto' },

        buttons,
        scrollTo: false,
        cancelIcon: {
            enabled: true
        },
        title: "<span class='text-2xl mr-4'>👋</span> Hello fellow Wanderer",
        text: [
            "<p class='mb-4'><strong>Thanks for being part of the Konnect community!</strong> We're excited to have you on board. We've prepared a couple of slides to help you navigate your purchased experience and get the most out of the content.</p><p class='mb-4'> You can exit the tour at any moment.</p><p class='mb-4'><strong>Let's get started!!</strong></p>"
        ]
    },
    {
        id: 'intro',
        attachTo: { element: '.tour-left-nav', on: 'auto' },

        buttons,
        scrollTo: false,
        cancelIcon: {
            enabled: true
        },
        title: "<span class='text-2xl mr-4'>🧐</span> This is the Experience Nav bar",
        text: [
            "It gives you quick links to sections of the page you're viewing, in this case, <strong>the Overview</strong> page."
        ]
    },
    {
        id: 'intro',
        attachTo: { element: '.tour-drawer', on: 'auto' },

        buttons,
        scrollTo: false,
        cancelIcon: {
            enabled: true
        },
        title: "<span class='text-2xl mr-4'>🧐</span> Opening and Closing",
        text: [
            'For more screen real estate use the drawer button to open and close the nav bar.'
        ]
    },

    {
        id: 'intro',
        attachTo: { element: '.tour-arrow-nav', on: 'auto' },

        buttons,
        scrollTo: false,
        cancelIcon: {
            enabled: true
        },
        title: "<span class='text-2xl mr-4'>🧭</span> Navigating through pages",
        text: [
            "<p class='mb-4'>You can move between pages by using the left/right arrow buttons or by selecting the page from the dropdown box.</p>"
        ]
    },

    {
        id: 'intro',
        attachTo: { element: '.tour-nav-section', on: 'auto' },

        buttons,
        scrollTo: false,
        cancelIcon: {
            enabled: true
        },
        title: "<span class='text-2xl mr-4'>🧐</span> Nav sections 1/3",
        text: [
            "<p class='mb-4'>To go a section click on the section in the nav menu.</p><p>The pagewill scroll automatically to it.</p>"
        ]
    },

    {
        id: 'intro',
        attachTo: { element: '.tour-wander-bar', on: 'left' },
        // beforeShowPromise: waitFunction,
        buttons,

        cancelIcon: {
            enabled: true
        },
        title: 'This is the Experience Toolbar',
        text: ['<p class="mb-8">It gives you access to helpful tools:</p>']
    }
];

export const firstSteps = [
    {
        id: 'video',
        // attachTo: { element: '.root', on: 'auto' },

        classes: 'video-player',
        scrollTo: false,
        // buttons: buttons2,

        cancelIcon: {
            enabled: true
        },
        title: "<span class='text-2xl px-8'><span class='text-3xl mr-4'>⚡</span> QuickStart </span>",
        text: [
            '<p class="mb-8">We have prepared a video playlist to get you up to speed with the experience kreation tool. Click on the playlist icon on the top right of the video to see the full list.</p> <div class="mx-auto w-3/4 h-96">' +
                youtubeVid(process.env.REACT_APP_TUTORIAL_VID_CODE) +
                '</div>'
        ]
    },
    {
        id: 'logging',
        // attachTo: { element: '.root', on: 'auto' },

        classes: 'video-player',
        scrollTo: false,

        cancelIcon: {
            enabled: true
        },
        title: "<span class='text-2xl px-8'><span class='text-3xl mr-4'>📅</span> What's new?</span>",
        text: createLog(versionLog)
    }
];
