/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import 'react-quill/dist/quill.snow.css';
import { checkMultiLine, handleRowReverse } from 'helpers/FEutils';
import { CardXlDragHandleItem } from 'components/blocks/CardXl';
import CardXlEditDelete from 'components/blocks/CardXlEditDelete';
import uiStruct from 'constants/uiStruct';
import FeedbackBox from './FeedbackBox';

const Tip = (props) => {
    const {
        provided = {},
        data = {},
        edit,
        isFeedbackMode,
        handleDelete,
        handleEdit,
        index,
        // dragOff,
        rtl,
        label,
        colors = uiStruct.ui.tip_list[data.type]
    } = props;

    return (
        <div
            className='list-none'
            ref={provided.innerRef}
            {...provided.draggableProps}
            className='mb-4 relative'
        >
            <div
                className={` ${
                    checkMultiLine(data.tip, 70)
                        ? 'sm:items-start'
                        : 'sm:items-center'
                } relative flex group flex-col  ${
                    rtl ? 'kn-rtl sm:kn-ltr' : ''
                } sm:flex-row py-5 px-4 w-full  bg-${
                    colors.bg_color
                } hover:bg-${
                    colors.hover_color
                } transition-colors ease-in-out duration-300 rounded-3xl sm:${
                    handleRowReverse(rtl).flex
                }`}
            >
                {edit && !isFeedbackMode && (
                    <>
                        <CardXlEditDelete
                            handleDelete={() => handleDelete(index)}
                            handleEdit={() => handleEdit(index)}
                            color='yellow'
                            rtl={rtl}
                            colors={colors}
                        />
                    </>
                )}
                <CardXlDragHandleItem
                    dragOff={!edit || isFeedbackMode}
                    dragProps={provided.dragHandleProps}
                    rtl={rtl}
                />
                <div
                    className={`w-min italic whitespace-nowrap mb-3 sm:mb-0 font-bold tracking-tight flex flex-none justify-center items-center bg-${colors.pill_color} rounded-full px-6 text-${colors.text_color} h-8`}
                >
                    {label}
                </div>
                <div
                    className={`w-full block-html text-sm ${
                        rtl ? 'kn-rtl sm:pr-4 sm:pl-8' : 'sm:pl-4 sm:pr-8'
                    }`}
                    dangerouslySetInnerHTML={{
                        __html: data.tip
                    }}
                ></div>
            </div>
            <FeedbackBox objId={data?.objId} label='Tip' />
        </div>
    );
};

export default Tip;
