import { connect } from 'react-redux';

import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { handleRowReverse } from 'helpers/FEutils';
import { makeDesinationLabel } from 'helpers/destinations';
import CardXlEditDelete from 'components/blocks/CardXlEditDelete';
import { CardXlDragHandleItem } from 'components/blocks/CardXl';
import Icons from 'components/blocks/Icons';
// import FeedbackBox from './FeedbackBox';

function Destination({
    globalState: { lang, edit, isFeedbackMode },
    destInf,
    defaultHtml = 'Amine tanslate',
    hintObj = uiStruct.itinerary.activities.uiEdit.modals.description.hint,
    icon = uiStruct.itinerary.activities.uiEdit.modals.description.icon,
    nohover = null,
    provided = {},
    handleDeleteBtnAction,
    index,
    handleClickBtnAction,
    handleActionBtn
}) {
    const rtl = !!translations[lang].rtl;
    const handleEdit = () => {
        handleClickBtnAction(true);
    };

    const handleDelete = () => {
        handleDeleteBtnAction(index);
    };

    // const destObj = makeDesinationLabel(destInf, lang);

    return (
        <div ref={provided.innerRef} {...provided.draggableProps}>
            <div
                className={`relative w-full justify-between bg-kn-primary-75 hover:bg-green-100 transition-colors ease-in-out duration-300 rounded-3xl px-4 md:px-8 py-4 flex flex-col md:${
                    handleRowReverse(rtl).flex
                } flex-wrap md:flex-nowrap  mb-4`}
            >
                {edit && !isFeedbackMode && (
                    <>
                        <CardXlEditDelete
                            handleDelete={handleDelete}
                            handleEdit={handleEdit}
                            color='yellow'
                            rtl={rtl}
                        />
                    </>
                )}
                <CardXlDragHandleItem
                    dragOff={!edit || isFeedbackMode}
                    dragProps={provided.dragHandleProps}
                    rtl={rtl}
                />
                <div
                    className={`z-200 pointer-events-none flex flex-col md:${
                        handleRowReverse(rtl).flex
                    } flex-wrap lg:flex-nowrap md:items-center`}
                >
                    <div
                        className={`flex items-center flex-shrink-0 ${
                            handleRowReverse(rtl).flex
                        }`}
                    >
                        <div className=''>
                            <Icons
                                iName='MAP_MARKERALT'
                                size='4xl'
                                iClasses='bg-clip-text text-transparent bg-gradient-to-tr from-green-600 via-green-400 to-blue-500'
                            />
                        </div>
                        <div className='px-6 text-xl font-bold bg-clip-text text-transparent bg-gradient-to-tr from-gray-900 to-green-400 mix-blend-multiply'>
                            {makeDesinationLabel(destInf[0], lang).country}
                        </div>
                    </div>
                    {destInf.length > 1 && (
                        <div
                            className={`flex items-center ${
                                handleRowReverse(rtl).flex
                            }`}
                        >
                            <div
                                className={`flex-shrink-0 ${
                                    handleRowReverse(rtl).mr
                                }-4 text-kn-primary text-lg`}
                            >
                                <Icons
                                    size='xl'
                                    iName={`${handleRowReverse(rtl).arrowLong}`}
                                />
                            </div>
                            <div
                                className={`flex flex-wrap text-sm gap-2 ${
                                    handleRowReverse(rtl).flex
                                }`}
                            >
                                {/* Buttons start here */}

                                {destInf.map((singleDest, destIndex) => {
                                    return (
                                        (singleDest.place ||
                                            singleDest.administrative_area_level_1 ||
                                            singleDest.address) &&
                                        (edit && !isFeedbackMode ? (
                                            <button
                                                onClick={() =>
                                                    handleActionBtn(
                                                        index,
                                                        destIndex
                                                    )
                                                }
                                                key={destIndex}
                                                className={`pointer-events-auto roup focus:outline-none text-gray-900 group flex flex-shrink-0 flex-nowrap items-center hover:bg-gray-900 bg-green-200 rounded-full px-4 gap-2 justify-between ${
                                                    handleRowReverse(rtl)
                                                        .flexreverse
                                                }`}
                                            >
                                                <i className='-mx-1 text-gray-900 opacity-30 group-hover:opacity-80 group-hover:text-white text-xl ri-close-line'></i>

                                                <span
                                                    className={`break-all group-hover:text-white ${
                                                        handleRowReverse(rtl)
                                                            .text
                                                    }`}
                                                >
                                                    {
                                                        makeDesinationLabel(
                                                            singleDest,
                                                            lang
                                                        ).place
                                                    }
                                                </span>
                                            </button>
                                        ) : (
                                            <button
                                                key={destIndex}
                                                className={`focus:outline-none text-gray-900 group flex flex-shrink-0 gap-2 flex-nowrap items-center hover:bg-green-400 rounded-full px-4 py-0.5 ${
                                                    handleRowReverse(rtl).flex
                                                }`}
                                            >
                                                <i className='group-hover:text-white text-green-400 text-lg ri-check-line'></i>

                                                <span
                                                    className={`break-all ${
                                                        handleRowReverse(rtl)
                                                            .text
                                                    }`}
                                                >
                                                    {
                                                        makeDesinationLabel(
                                                            singleDest,
                                                            lang
                                                        ).place
                                                    }
                                                </span>
                                            </button>
                                        ))
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
                {/* <FeedbackBox /> */}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(Destination);
