import React, { useRef } from 'react';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

const setRangeData = {
    marks: {
        0: '0h',
        60: '1h',
        120: '2h',
        180: '3h',
        240: '4h',
        300: '5h',
        360: '6h',
        420: '7h',
        480: '8h',
        540: '9h',
        600: '10h',
        660: '11h',
        720: '12h',
        780: '13h',
        840: '14h',
        900: '15h',
        960: '16h'
    },
    rangeMin: 0,
    rangeMax: 960
};

const DurationRange = ({ defaultValue, min, max, onChange, isReverse }) => {
    const rangeData = useRef(setRangeData);

    return (
        <div className='pb-8'>
            <Range
                trackStyle={[{ background: isReverse ? '#A7F3D0' : '#34D399' }]}
                railStyle={{
                    background: !isReverse ? '#A7F3D0' : '#34D399'
                }}
                activeDotStyle={{
                    borderColor: isReverse ? '#A7F3D0' : '#34D399'
                }}
                dotStyle={{
                    borderColor: !isReverse ? '#A7F3D0' : '#34D399'
                }}
                handleStyle={[{}]}
                step={15}
                min={0}
                max={960}
                marks={rangeData.current.marks}
                defaultValue={defaultValue}
                onAfterChange={onChange}
            />
        </div>
    );
};

export default DurationRange;
