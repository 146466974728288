import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GalleryWithUpdater from 'components/blocks/GalleryWithUpdater';
import { experienceDetailsAction } from 'store/actions/experienceDetails/generic.js';

function GenericGallery({
    objId,
    experienceDetailsAction,
    actionType,
    images,
    lang,
    edit,
    slider = true,
    max = 12,
    children,
    className,
    modalTitle,
    scroll,
    showThumnails
}) {
    const handleUpdateCaption = (slideIndex, caption) => {
        experienceDetailsAction({
            type: `UPDATE_GALLERY_CAPTION${actionType}`,
            slideIndex,
            caption
        });
    };

    const handleUpdateGallery = (data) => {
        experienceDetailsAction({
            type: `UPDATE_GALLERY${actionType}`,
            dataObj: data
        });
    };

    return (
        <GalleryWithUpdater
            objId={objId}
            lang={lang}
            edit={edit}
            slider={slider}
            images={images}
            handleUpdateCaption={handleUpdateCaption}
            handleUpdateGallery={handleUpdateGallery}
            max={max}
            className={className}
            modalTitle={modalTitle}
            scroll={scroll}
            showThumnails={showThumnails}
        >
            {children}
        </GalleryWithUpdater>
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            experienceDetailsAction
        },
        dispatch
    );
}

export default connect(null, mapDispatchToProps)(GenericGallery);
