import { arrangeArray } from '../common';

export const deleteMovingAroundOverview = (draft, action) => {
    const { index } = action;

    draft.experience.moving_around.splice(index, 1);

    return draft;
};

export const addMovingAroundOverview = (draft, action) => {
    const { dataObj } = action;

    draft.experience.moving_around.push(dataObj);

    return draft;
};

export const arrangeMovingAroundOverview = (draft, action) => {
    return arrangeArray(draft, action, draft.experience.moving_around);
};

export const arrangeVendorsMovingAroundOverview = (draft, action) => {
    const { index } = action;

    return arrangeArray(
        draft,
        action,
        draft.experience.moving_around[index].vendors
    );
};

export const deletVendorMovingAroundOverview = (draft, action) => {
    const { index, vendorIndex } = action;

    draft.experience.moving_around[index].vendors.splice(vendorIndex, 1);

    return draft;
};

export const updateVendoeMovingAroundOverview = (draft, action) => {
    const { index, vendorIndex, dataObj } = action;

    draft.experience.moving_around[index].vendors[vendorIndex] = {
        ...draft.experience.moving_around[index].vendors[vendorIndex],
        ...dataObj,
    };

    return draft;
};

export const addVendorMovingAroundOverview = (draft, action) => {
    const { dataObj, index } = action;

    draft.experience.moving_around[index].vendors.push(dataObj);

    return draft;
};

export const arrangeTipsMovingAroundOverview = (draft, action) => {
    const { index } = action;

    return arrangeArray(
        draft,
        action,
        draft.experience.moving_around[index].tips_html
    );
};

export const deleteTipMovingAroundOverview = (draft, action) => {
    const { index, tipIndex } = action;

    draft.experience.moving_around[index].tips_html.splice(tipIndex, 1);

    return draft;
};

export const addTipMovingAroundOverview = (draft, action) => {
    const { dataObj, index } = action;

    draft.experience.moving_around[index].tips_html.push(dataObj);

    return draft;
};

export const updateTipMovingAroundOverview = (draft, action) => {
    const { index, tipIndex, dataObj } = action;

    draft.experience.moving_around[index].tips_html[tipIndex] = {
        ...draft.experience.moving_around[index].tips_html[tipIndex],
        ...dataObj,
    };

    return draft;
};

export const updateDescriptionMovingAroundOverview = (draft, action) => {
    const { index, descHtml } = action;

    draft.experience.moving_around[index].description_html = descHtml;

    return draft;
};

export const updateTypeMovingAroundOverview = (draft, action) => {
    const { index, movingAroundType } = action;

    draft.experience.moving_around[index].type = movingAroundType;

    return draft;
};
