export const updateImageIntroOverview = (draft, action) => {
    const { url, imgType: type } = action;

    draft.experience.overview_intro.overview_featured_image = {
        data: url,
        type
    }

    return draft;
};

export const updateDescIntroOverview = (draft, action) => {
    const { descHtml } = action;

    draft.experience.overview_intro.description_html = descHtml;

    return draft;
};

export const updateTitleIntroOverview = (draft, action) => {
    const { titleTxt } = action;

    draft.experience.overview_intro.title = titleTxt;

    return draft;
};
