import axios from 'axios';
import { serverErrors } from 'helpers/serverErrors';
import * as types from 'store/actions/types';

export const createDataAdmin = (type, end, data) => {
    return (dispatch) =>
        dispatch({
            type,
            payload: axios.post(`${process.env.REACT_APP_API_BASE}/${end}`, data)
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const updateDataAdmin = (type, end, data, id) => {
    return (dispatch) =>
        dispatch({
            type,
            payload: axios.put(`${process.env.REACT_APP_API_BASE}/${end}/${id}`, data)
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const deleteDataAdmin = (type, end, id) => {
    return (dispatch) =>
        dispatch({
            type,
            payload: axios.delete(`${process.env.REACT_APP_API_BASE}/${end}/${id}`)
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const updateUserAdmin = (id, profileId, data) => {
    const {
        email,
        password,
        stripeId,
        profile: { first, last, displayname, country, currency, city, bio, avatar, social, cover_image }
    } = data;
    let socialStr = '';
    Object.keys(social).forEach((key) => {
        socialStr = `${socialStr},${key}: "${social[key]}"`;
    });
    const gql = String.raw;
    return (dispatch) =>
        dispatch({
            type: types.UPDATE_USER_BY_ID,
            payload: axios.post(`${process.env.REACT_APP_API_BASE}/graphql`, {
                query: gql`
                mutation {
                    updateProfile(input: {
                        where: {
                            id: "${profileId}"
                        },
                        data: {
                            first: "${first}",
                            last: "${last}",
                            country: "${country}",
                            bio: "${bio}",
                            avatar: "${avatar}",
                            displayname: "${displayname}",
                            city:"${city}",
                            currency:"${currency}",
                            social: {${socialStr}},
                            cover_image: "${cover_image}"
                        }
                        }
                    ) {
                        profile {
                            id
                        }
                    },
                    
                    updateUser(input: {
                        where: { 
                            id: "${id}"
                        },
                        data: {
                            email: "${email}",
                            ${password.length ? `, password: "${password}"` : ''}
                            ${stripeId?.length ? `, stripeId: "${stripeId}"` : ''}
                        }
                        }
                    ) {
                        user {
                            id,
                            username,
                            email,
                            confirmed,
                            blocked,
                            role {
                                id,
                                name
                            },
                            isKreator,
                            stripeId,
                            accessible_contents {
                                id,
                                experience_id
                            },
                            profile {
                                id,
                                _id,
                                first,
                                last,
                                displayname,
                                country,
                                city,
                                currency,
                                bio,
                                avatar,
                                settings,
                                social
                                cover_image
                            }
                        }
                    },
                }`
            })
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};

export const updateUsernameAdmin = (id, data) => {
    const {
        username
    } = data;

    return (dispatch) =>
        dispatch({
            type: types.UPDATE_USER_BY_ID,
            payload: axios.post(`${process.env.REACT_APP_API_BASE}/graphql`, {
                query: `
                mutation {
                    
                    updateUser(input: {
                        where: { 
                            id: "${id}"
                        },
                        data: {
                            username: "${username}"
                        }
                        }
                    ) {
                        user {
                            id,
                            _id,
                            username,
                            email,
                            confirmed,
                            blocked,
                            profile {
                                avatar,
                                bio,
                                city,
                                country,
                                createdAt,
                                currency,
                                displayname,
                                first,
                                id,
                                last,
                                published_at,
                                settings,
                                social,
                                updatedAt,
                                _id
                            }
                        }
                    },
                }`
            })
        })
            .then((res) => res)
            .catch((error) => {
                serverErrors(error);

                return { error };
            });
};