/* eslint-disable react/jsx-pascal-case */
import { CardXlDragHandleMenuItem } from 'components/blocks/CardXlDrag';
import Icons from 'components/blocks/Icons';
import translations from 'constants/translations';
import ProgressMenu from 'components/blocks/ProgressMenu';
import { MenuRefsHolder } from 'helpers/MenuRefsHolder';

const NavigationMoveAround = ({
    lang,
    edit,
    provided = {},
    rtl,
    type,
    dragOff,
    completion = 0,
    index,
    handleBtnClick,
    visibleSection,
    isFeedbackMode
}) => {
    const { ref: scrollRef } = MenuRefsHolder.sectionsMainNav[
        `ovMovingAround${index}`
    ] || { ref: null };

    return (
        <li
            className={`group relative flex py-2 px-10`}
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            <div style={{ display: !edit ? 'none' : '' }}>
                <CardXlDragHandleMenuItem
                    dragOff={!edit || isFeedbackMode}
                    dragProps={provided.dragHandleProps}
                    rtl={rtl}
                />
            </div>

            <button
                onClick={() => handleBtnClick(scrollRef)}
                style={{ direction: rtl ? 'rtl' : 'ltr' }}
                // onClick={() => handleScroll(refs.current)}
                className={`shadow-2xl-green-400 border border-glass-75 group-hover:border-green-400 ring-4 ring-transparent group-hover:ring-green-200  group-hover:bg-green-50 transition-all duration-200 ease-in-out flex flex-1 gap-4 items-center justify-between py-2 rounded-xl px-4 outline-none focus:outline-none  ${
                    visibleSection === scrollRef?.id
                        ? 'bg-green-100 shadow-none'
                        : 'bg-white shadow-2xl-green-400'
                }`}
            >
                <span className='flex flex-1 gap-4 items-center'>
                    <span
                        className={`w-12 h-8 flex-shrink-0 flex items-center justify-center rounded-full bg-gradient-to-tr from-green-300 via-green-400 to-green-500 shadow-2xl-green-500`}
                    >
                        <Icons
                            iName={type}
                            iClasses={`text-gray-500 mix-blend-multiply`}
                            size='2xl'
                        />
                    </span>
                    <span className={`bg-green-1002 text-xs text-green-900`}>
                        {translations[lang][type]}
                    </span>
                </span>
                <ProgressMenu completion={completion} />
            </button>
        </li>
    );
};

export default NavigationMoveAround;
