/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ListBoxGeneric from 'components/blocks/ListBoxGeneric';
import ModalContainer, {
    ModalFooter,
    ModalCTA,
    ModalButton,
    ModalHelp
} from 'components/blocks/ModalTre';
import { minsToTime, minsToDuration, addCurrency } from 'helpers/time';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import TimeToGoRange from 'components/blocks/TimeToGoRange';
import DurationRange from 'components/blocks/DurationRange';
import PricingRange from 'components/blocks/PricingRange';
import Pricing from 'components/blocks/Pricing';
import { BlockInputSingle } from 'components/blocks/Blocks';
import useDidMountEffect from 'helpers/useDidMountEffect';
import ModalTopBlock from 'components/blocks/ModalTopBlock';
import Icons from 'components/blocks/Icons';
import KnIcons from 'constants/KnIcons';
import { handleRowReverse } from 'helpers/FEutils';
import ModalFullscreen, { ModalBody } from 'components/blocks/ModalFullscreen';
import { ButtonsClose } from 'components/blocks/Buttons';
// should be in all modals
const targetElement = document.querySelector('#root');

function ActivitiesModal({
    editActivityType,
    close,
    setModalIsOpen,
    handleActionBtn,
    updateObj = null,
    update = false,
    defaultObj,
    expType,
    globalState: { lang },
    hintObj = uiStruct.itinerary.activities.uiEdit.modals.type.hint,
    icon = uiStruct.itinerary.activities.uiEdit.modals.add.icon,
    actionBtnObj = uiStruct.itinerary.activities.uiEdit.modals.add.buttons
        .action,
    cancelBtnObj = uiStruct.itinerary.activities.uiEdit.modals.add.buttons
        .cancel
}) {
    const rtl = !!translations[lang].rtl;

    const [dataObj, updateDataObj] = useState(() =>
        update ? updateObj : defaultObj(lang, expType.toLowerCase())
    ); // set to a default or editing

    const gettingThereTypes =
        uiStruct.itinerary.activities.uiEdit.getting_there_list.map((key) => {
            return {
                id: key,
                name: translations[lang][key],
                unavailable: false
            };
        });

    const [selectedGettingThereType, setSelectedGettingThereType] = useState(
        () => {
            return gettingThereTypes[
                gettingThereTypes.findIndex((obj) => {
                    return obj.id === dataObj.getting_there.type;
                })
            ];
        }
    );

    const reservationTypes =
        uiStruct.itinerary.activities.uiEdit.reservation_list.map((key) => {
            return {
                id: key,
                name: translations[lang][key],
                unavailable: false
            };
        });

    const [selectedReservationType, setSelectedReservationType] = useState(
        () => {
            return (
                reservationTypes[
                    reservationTypes.findIndex((obj) => {
                        return obj.id === dataObj.reservation.type;
                    })
                ] || reservationTypes[0]
            );
        }
    );

    const activityTypes =
        uiStruct.itinerary.activities.uiEdit.activity_list.map((key) => {
            return {
                id: key,
                name: translations[lang][key],
                unavailable: false
            };
        });

    const activityIndex = activityTypes.findIndex((obj) => {
        return obj.id === dataObj.type;
    });

    const [selectedType, setSelectedType] = useState(() => {
        return activityTypes[activityIndex === -1 ? 0 : activityIndex];
    });

    const priceRangeTypes =
        uiStruct.ui.componentTypes.pricing_range.selections.map((key) => {
            return {
                id: key,
                name: translations[lang][key],
                unavailable: false
            };
        });

    const [pricingType, setPricingType] = useState(() => {
        return priceRangeTypes[
            priceRangeTypes.findIndex((obj) => {
                return obj.id === dataObj.pricing.type;
            })
        ];
    });

    const [pricingCurrency, setPricingCurrency] = useState('CAD');

    // Amine translations
    const defaultErrorObj = {
        title: {
            error: false,
            message: 'Must have a title'
        },
        pricing: {
            max: { error: false, message: '' },
            min: { error: false, message: '' },
            message:
                'Numbers must be alpha nummeric, max must be larger than min....etc'
        },
        gettingThereDuration: {
            error: false,
            message: 'Must have a title'
        }
    };
    const [errorObj, setErrorObj] = useState({
        ...defaultErrorObj
    });
    const [saveAttempted, setSaveAttempted] = useState(false);
    // Copy Below to all modals
    //////////////////////////////////////////////////////////////////////

    // State for triggering animation of modal in and out
    const [transitionState, setTransitionState] = useState('off');
    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    ///////////////////////////////////////////////////////////////////

    // Paul stuff Handle Action BTN function

    const resetErrorObject = () => {
        setErrorObj({
            ...defaultErrorObj
        });
    };

    const validatePrices = (dataObj) => {
        const {
            title,
            pricing: { max, min },
            getting_there: { duration }
        } = dataObj;
        let error = false;

        const tmpErrorObj = {
            ...defaultErrorObj
        };
        if (!title.length) {
            error = true;
            tmpErrorObj.title.error = true;
        }
        if (
            isNaN(Number(duration)) ||
            !duration.toString().length ||
            Number(duration) === 0
        ) {
            error = true;
            tmpErrorObj.gettingThereDuration.error = true;
        }

        if (
            isNaN(Number(max)) ||
            !max.toString().length ||
            // max > 1000 ||
            Number(max) < 0 ||
            Number(max) < Number(min)
        ) {
            error = true;
            tmpErrorObj.pricing.max.error = true;
        }
        if (
            isNaN(Number(min)) ||
            !min.toString().length ||
            // min > 1000 ||
            Number(min) < 0 ||
            Number(max) < Number(min)
        ) {
            error = true;
            tmpErrorObj.pricing.min.error = true;
        }

        setErrorObj({
            ...tmpErrorObj
        });

        return error;
    };

    const checkIfObject = (obj) => {
        return typeof obj === 'object' && obj !== null;
    };

    const handleActionClick = () => {
        const saveObject = {
            ...dataObj,
            type: selectedType.id,
            reservation: checkIfObject(dataObj.reservation)
                ? {
                      ...dataObj.reservation,
                      type: selectedReservationType.id
                  }
                : { type: selectedReservationType.id, isVisible: true },
            getting_there: {
                ...dataObj.getting_there,
                type: selectedGettingThereType.id
            }
        };
        setSaveAttempted(true);

        if (dataObj.pricing.free) {
            handleActionBtn({
                ...saveObject,
                pricing: {
                    ...dataObj.pricing,
                    min: 0,
                    max: 0,
                    type: pricingType.id
                }
            });
            handleTransitionStateOff(); // important... don't forget to add it in all modals
        } else if (!validatePrices(dataObj)) {
            handleActionBtn({
                ...saveObject,
                pricing: {
                    ...dataObj.pricing,
                    type: pricingType.id
                }
            });
            handleTransitionStateOff(); // important... don't forget to add it in all modals
        }
    };

    const {
        best_time_to_go: {
            min: minGo,
            max: maxGo,
            isVisible: bestTimeToGoVisible = true
        },
        duration: {
            min: minDur,
            max: maxDur,
            isVisible: durationVisible = true
        }
    } = dataObj;
    const timeRangeChange = (data) => {
        updateDataObj({
            ...dataObj,
            best_time_to_go: {
                min: data[0],
                max: data[1],
                isVisible: true
            }
        });
    };

    const handleVisible = (obj) => {
        const currentVis = dataObj[obj].isVisible;
        updateDataObj({
            ...dataObj,
            [obj]: { ...dataObj[obj], isVisible: !currentVis }
        });
    };

    const durationRangeChange = (data) => {
        updateDataObj({
            ...dataObj,
            duration: {
                min: data[0],
                max: data[1],
                isVisible: true
            }
        });
    };

    const handleFreeChange = () => {
        const {
            pricing: { free }
        } = dataObj;
        // validate if save attempted
        updateDataObj({
            ...dataObj,
            pricing: {
                ...dataObj.pricing,
                free: !free
            }
        });
        resetErrorObject();
    };

    const handleCurrencyChange = (val) => {
        updateDataObj({
            ...dataObj,
            pricing: {
                ...dataObj.pricing,
                currency: val
            }
        });

        resetErrorObject();
    };

    const handleSetPrices = (e) => {
        const { name, value } = e.currentTarget;
        const obj = {
            ...dataObj,
            pricing: {
                ...dataObj.pricing,
                [name]: value || ''
            }
        };

        updateDataObj(obj);
        if (saveAttempted) {
            validatePrices(obj);
        }
    };
    const updateTitle = (e) => {
        updateDataObj({
            ...dataObj,
            title: e.target.value
        });
    };

    useDidMountEffect(() => {
        const tmpErrorObj = {
            ...errorObj
        };
        if (!dataObj.title.length) {
            tmpErrorObj.title.error = true;
        } else tmpErrorObj.title.error = false;
        setErrorObj(tmpErrorObj);
    }, [dataObj.title]);

    useEffect(() => {
        resetErrorObject();
    }, []);

    const updateGettingDuration = (e) => {
        const obj = {
            ...dataObj,
            getting_there: {
                ...dataObj.getting_there,
                duration: e.target.value
            }
        };

        updateDataObj(obj);
        if (saveAttempted) {
            validatePrices(obj);
        }
    };

    const {
        title,
        pricing: {
            free,
            min: minPrice,
            max: maxPrice,
            currency,
            isVisible: pricingVisible = true
        },
        getting_there: { duration },
        reservation: { isVisible: reservationVisible = true }
    } = dataObj;

    return (
        <>
            <ModalFullscreen
                width='4xl'
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={{ name: selectedType.id, size: '4xl' }}
                close={close}
                closeButton={true}
                rtl={rtl}
                fullscreen={true}
            >
                <ModalBody rtlOff>
                    <div className='xl:px-32 '>
                        {editActivityType && (
                            <>
                                <div className='w-full  px-4 md:px-8 lg:px-2 mb-8 mt-4'>
                                    <ModalTopBlock
                                        title='Activity'
                                        subtitle='Name and Type'
                                        text='Give this activity a name and choose its type.'
                                        rtl={rtl}
                                        lang={lang}
                                    />
                                    <div className='flex flex-col gap-4 md:flex-row w-full md:max-w-6xl'>
                                        <div className='md:w-72'>
                                            <ListBoxGeneric
                                                listData={activityTypes}
                                                val={selectedType}
                                                handleChange={setSelectedType}
                                                className=''
                                                listHeight='max-h-32 lg:max-h-32 xl:max-h-48 '
                                            />
                                        </div>
                                        <div className='flex-1'>
                                            <BlockInputSingle
                                                normal
                                                error={errorObj.title.error}
                                                handleChange={updateTitle}
                                                id='name'
                                                margins='mb-4'
                                                value={title || ''}
                                                placeholder={
                                                    translations[lang][
                                                        uiStruct.itinerary
                                                            .activities.uiEdit
                                                            .modals.type
                                                            .placeholders
                                                    ]
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {!editActivityType && (
                            <>
                                <div className='w-full  px-4 md:px-8 lg:px-2 mb-8 mt-4'>
                                    <ModalTopBlock
                                        title='Activity'
                                        subtitle='Details'
                                        text='You can add specific details about the activity here. Delete all panels you do not want. '
                                        rtl={rtl}
                                        lang={lang}
                                    />
                                </div>
                                <div className='flex justify-center items-center gap-4'>
                                    {!bestTimeToGoVisible && (
                                        <SectionActivityPill
                                            label={'Best time to go'}
                                            icon='KnBestTime'
                                            handleClick={handleVisible}
                                            objLabel='best_time_to_go'
                                        />
                                    )}
                                    {!durationVisible && (
                                        <SectionActivityPill
                                            label={'Duration'}
                                            icon='KnDuration'
                                            handleClick={handleVisible}
                                            objLabel='duration'
                                        />
                                    )}
                                    {!pricingVisible && (
                                        <SectionActivityPill
                                            label={'Pricing'}
                                            icon='KnWallet'
                                            handleClick={handleVisible}
                                            objLabel='pricing'
                                        />
                                    )}
                                    {!reservationVisible && (
                                        <SectionActivityPill
                                            label={'Reservation'}
                                            icon='KnReservation'
                                            handleClick={handleVisible}
                                            objLabel='reservation'
                                        />
                                    )}
                                </div>
                                <div className='w-full  px-4 md:px-8 lg:px-2 mb-8 mt-4'>
                                    <div className='flex flex-col lg:flex-row gap-4 flex-wrap justify-center'>
                                        <SectionActivitySingle__DetailItem2
                                            isVisible={bestTimeToGoVisible}
                                            handleClose={handleVisible}
                                            objLabel='best_time_to_go'
                                            width='w-full xl:w-1/2'
                                            icon='KnBestTime'
                                            label={'Best time to go'}
                                            min={minsToTime(minGo)}
                                            max={minsToTime(maxGo)}
                                            rtl={rtl}
                                        >
                                            <TimeToGoRange
                                                onChange={timeRangeChange}
                                                defaultValue={[minGo, maxGo]}
                                                min={minGo}
                                                max={maxGo}
                                            />
                                        </SectionActivitySingle__DetailItem2>

                                        <SectionActivitySingle__DetailItem2
                                            isVisible={durationVisible}
                                            handleClose={handleVisible}
                                            objLabel='duration'
                                            width='w-full xl:w-1/2'
                                            icon='KnDuration'
                                            label={'Duration'}
                                            min={minsToDuration(minDur)}
                                            max={minsToDuration(maxDur)}
                                            rtl={rtl}
                                        >
                                            <DurationRange
                                                onChange={durationRangeChange}
                                                defaultValue={[minDur, maxDur]}
                                                min={minDur}
                                                max={maxDur}
                                            />
                                        </SectionActivitySingle__DetailItem2>

                                        <SectionActivitySingle__DetailItem2
                                            isVisible={pricingVisible}
                                            handleClose={handleVisible}
                                            objLabel='pricing'
                                            width='w-full xl:w-1/2'
                                            icon='KnWallet'
                                            label={'Pricing'}
                                            value2={
                                                free
                                                    ? 'This activity is free'
                                                    : null
                                            }
                                            min2={free ? null : minPrice}
                                            max2={free ? null : maxPrice}
                                            suffix2={
                                                free
                                                    ? null
                                                    : translations[lang][
                                                          pricingType.id
                                                      ]
                                            }
                                            rtl={rtl}
                                            childSide={false}
                                        >
                                            <Pricing
                                                errorObj={errorObj.pricing}
                                                saveAttempted={saveAttempted}
                                                handleSetPrices={
                                                    handleSetPrices
                                                }
                                                selected={pricingCurrency}
                                                onChange={handleFreeChange}
                                                free={free}
                                                currency={currency}
                                                handleCurrencyChange={
                                                    handleCurrencyChange
                                                }
                                                setSelectedType={
                                                    setPricingCurrency
                                                }
                                                minPrice={minPrice}
                                                maxPrice={maxPrice}
                                            />
                                        </SectionActivitySingle__DetailItem2>

                                        <SectionActivitySingle__DetailItem2
                                            isVisible={reservationVisible}
                                            handleClose={handleVisible}
                                            width='w-full xl:w-1/2'
                                            objLabel='reservation'
                                            icon='KnReservation'
                                            label={'Reservation'}
                                            rtl={rtl}
                                            childSide={false}
                                        >
                                            <div className='flex flex-col md:flex-row md:items-center flex-nowrap gap-4 '>
                                                <div className='text-sm'>
                                                    Do you need to make
                                                    reservations?
                                                </div>
                                                <ListBoxGeneric
                                                    listData={reservationTypes}
                                                    val={
                                                        selectedReservationType
                                                    }
                                                    handleChange={
                                                        setSelectedReservationType
                                                    }
                                                    color='bg-white'
                                                    textBtn='text-sm'
                                                    textClass='text-sm'
                                                    height='h-10'
                                                    direction='top'
                                                />
                                            </div>
                                        </SectionActivitySingle__DetailItem2>
                                    </div>
                                </div>
                                {/* <div className='w-full'>
                                    <SectionActivitySingle__DetailItem2
                                        icon='KnGettingThere'
                                        label={'Getting there'}
                                        rtl={rtl}
                                        childSide={true}
                                    >
                                        <div className='flex items-center flex-nowrap gap-x-4 w-128'>
                                            <div>We recommend getting there by</div>
                                            <ListBoxGeneric
                                                listData={gettingThereTypes}
                                                val={selectedGettingThereType}
                                                handleChange={
                                                    setSelectedGettingThereType
                                                }
                                                color='bg-white'
                                                textBtn='text-sm'
                                                textClass='text-sm'
                                                height='h-10'
                                                direction='top'
                                                className='flex-1'
                                            />
    
                                        </div>
                                    </SectionActivitySingle__DetailItem2>
                                </div> */}
                            </>
                        )}
                        <div className='mt-8 mb-8 px-4 md:px-10'>
                            <ModalFooter>
                                {hintObj && (
                                    <ModalHelp
                                        helpText={translations[lang][hintObj]}
                                        isHtml
                                    />
                                )}
                                <ModalCTA>
                                    <ModalButton
                                        handleClick={handleTransitionStateOff}
                                        label={
                                            translations[lang][
                                                cancelBtnObj.label
                                            ]
                                        }
                                        color={cancelBtnObj.color}
                                        icon={cancelBtnObj.icon}
                                    />
                                    <ModalButton
                                        handleClick={handleActionClick}
                                        label='Save'
                                        color={actionBtnObj.color}
                                        icon={actionBtnObj.icon}
                                    />
                                </ModalCTA>
                            </ModalFooter>
                        </div>
                    </div>
                </ModalBody>
            </ModalFullscreen>
        </>
    );
}

const SectionActivityPill = ({
    label,
    icon,
    rtl = false,
    handleClick,
    objLabel
}) => {
    return (
        <button
            onClick={() => handleClick(objLabel)}
            className={`h-10 focus:outline-none outline-none flex bg-green-200 hover:bg-gray-900 text-green-900 hover:text-white items-center  rounded-xl md:rounded-full px-4 gap-4`}
        >
            <div className={` `}>
                <KnIcons icon={icon} className='' />
            </div>
            <div
                className={`whitespace-nowrap flex-grow text-sm lg:text-xs font-semibold`}
            >
                {label}
            </div>
            <div className={``}>
                <i className='text-xl ri-add-line'></i>
            </div>
        </button>
    );
};

const SectionActivitySingle__DetailItem2 = ({
    width = 'w-full',
    icon,
    label,
    value,
    min,
    max,
    suffix,
    children,
    rtl,
    ref,
    childSide = false,
    isVisible = true,
    handleClose,
    objLabel
}) => {
    return (
        <div
            className={`mb-8 bg-green-50 rounded-3xl py-6 relative px-4 ${width} ${
                isVisible ? '' : 'hidden'
            }`}
        >
            <ButtonsClose handleClose={() => handleClose(objLabel)} />

            <div className='flex items-center gap-4 '>
                <div
                    className={`flex bg-green-200 items-center py-2 rounded-xl md:rounded-full px-4 ${
                        handleRowReverse(rtl).pr
                    }-6`}
                >
                    <div
                        className={`${
                            handleRowReverse(rtl).mr
                        }-4 text-kn-primary`}
                    >
                        <KnIcons
                            icon={icon}
                            className='text-green-600 mix-blend-multiply'
                        />
                    </div>

                    <div
                        className={`whitespace-nowrap flex-grow ${
                            rtl ? 'text-sm' : 'text-sm lg:text-xs'
                        }`}
                    >
                        {label}
                    </div>
                </div>
                <div className='text-sm  hidden md:flex flex-row flex-1 flex-nowrap justify-center md:justify-start py-2 sm:py-0 items-center'>
                    {childSide && (
                        <div className='flex-1 w-96 px-4'>{children}</div>
                    )}
                    {max && !childSide && (
                        <>
                            <span>{min || 0}</span>
                            {max !== min && (
                                <>
                                    <span className='px-2 text-kn-primary'>
                                        <Icons
                                            iName={
                                                handleRowReverse(rtl).arrowLong
                                            }
                                            size='xl'
                                        />
                                    </span>
                                    <span>{max}</span>
                                </>
                            )}
                        </>
                    )}
                    {value && <span>{value}</span>}
                    {suffix && (
                        <span className={`${handleRowReverse(rtl).ml}-2`}>
                            {suffix}
                        </span>
                    )}
                </div>
            </div>
            {min && max && !childSide && (
                <div className='md:hidden text-sm flex flex-row flex-1 flex-nowrap px-4 mt-6 py-2 sm:py-0 items-center'>
                    <span>{min}</span>
                    <span className='px-2 text-kn-primary'>
                        <Icons
                            iName={handleRowReverse(rtl).arrowLong}
                            size='xl'
                        />
                    </span>
                    <span>{max}</span>
                </div>
            )}
            {!childSide && <div className='px-4 mt-6'>{children}</div>}
        </div>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(ActivitiesModal);
