import Image from 'components/blocks/Image';
import { PillLogo } from 'components/blocks/Pills';
import Button from 'components/blocks/Title/Button';
import GradientTitle from 'components/blocks/Title/GradientTitle';
import LandingCapture from 'components/landing/LandingCapture';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Row, Section, Column, OptionCard } from 'components/layouts/LayoutElements';
import { marketPath } from 'constants/globalConsts';

const list1 = [
    {
        text: 'Check your completion progress',
        tag: ''
    },
    {
        text: 'Follow the status of your submission',
        tag: ''
    },
    {
        text: 'Edit the price and calendar',
        tag: ''
    },
    {
        text: 'Get detailed performance analytics',
        tag: 'soon'
    }
];

const LayoutBasics = () => {
    const [tab, setTab] = useState('kreate');
    const tabClasses = {
        on: 'border-green-400',
        off: 'text-gray-400 border-transparent hover:border-gray-200'
    };
    const containerClasses = {
        on: 'translate-x-0',
        off: '-translate-x-full'
    };

    const path = window.location.hash;
    useEffect(() => {
        // const path = window.location.hash;
        if (path && path.includes('#')) {
            setTimeout(() => {
                const id = path.replace('#', '');
                const el = window.document.getElementById(id);
                const r = el?.getBoundingClientRect();
                window.top.scroll({
                    top: r?.top ?? 0
                    // behavior: 'smooth'
                });
            }, 300);
        }
    }, [path]);

    return (
        <>
            <Section id='top' background='landing-gradient' padding='py-16 md:pt-24 md:pb-24 lg:pb-0' margins='mt-16'>
                <Row justify='justify-end'>
                    <Column textSize='text-2xl'>
                        <GradientTitle label='Experience Basics' textSize='text-4xl md:text-5xl 2xl:text-6xl' justify='' containerClass='md:mb-4' />
                        <h3 className='text-2xl md:text-3xl xl:text-4xl font-bold md:mb-4 leading-tight tracking-tight mb-4'>
                            I'm new and don't know where to start
                        </h3>
                        <div className='text-lg'>
                            This page will help you jumpstart your first experience. You will learn The difference between Digital and Guided experiences, check
                            samples of both types, and watch how-to videos to learn about using the platform.
                        </div>
                    </Column>
                    <Column textSize='text-2xl' align='items-center'>
                        <img src='/assets/media/basics/exp_hlp@2x.png' className='h-80 md:h-128 w-auto object-contain -mt-12' />
                    </Column>
                </Row>
            </Section>

            <Section pill='top' background='bg-gray-100' padding='py-16 md:pb-24 lg:py-16 lg:pb-24'>
                <Row justify='justify-center'>
                    <Column textSize='text-2xl' justify='justify-center' align='items-center'>
                        <GradientTitle label='Digital vs. Guided' textSize='text-4xl md:text-5xl 2xl:text-6xl' justify='justify-center' />
                        <div className='text-center text-lg'>
                            We know that everyone isn’t at the same writing level so we will try to help out with some creative editing and proofing.
                        </div>
                    </Column>
                </Row>

                <Row justify='justify-center' margins='mt-8 mx-auto'>
                    <div className='flex flex-col lg:flex-row lg:justify-center gap-4 lg:gap-8 xl:gap-16 w-full md:gap-12'>
                        <div className='w-full lg:w-1/2 mb-4  md:mb-0  flex flex-col md:gap-8 lg:gap-0'>
                            <img src='/assets/media/basics/d_v_g_01@2x.png' className='lg:h-96 lg:w-auto object-contain' />
                            <div className='mt-4 md:mt-0 lg:mt-0'>
                                <div className='text-2xl md:text-3xl lg:text-4xl lg:mb-4 text-green-400 font-semibold'>Digital</div>
                                <div className='text-base text-gray-600 '>
                                    These are written guides that you can follow on your own without a guide. All the activities and details are included and
                                    can be done at your own pace. They take a bit more effort to produce as they include some personal writing and photos.
                                </div>
                            </div>
                        </div>
                        <div className='w-full  lg:w-1/2  md:mb-0  flex flex-col  md:gap-8 lg:gap-0'>
                            <img src='/assets/media/basics/d_v_g_02@2x.png' className='lg:h-96 lg:w-auto object-contain' />
                            <div className='mt-4 md:mt-0 lg:mt-0'>
                                <div className='text-2xl md:text-3xl lg:text-4xl lg:mb-4 text-green-400 font-semibold'>Guided</div>
                                <div className='text-base text-gray-600 '>
                                    These are packages that include a Kreator/Guide during the experience. You can include activities, transportation,
                                    accommodation. They also don't need a full write-up
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </Section>

            <Section pill='top' background='bg-white' padding='py-16 md:pb-24'>
                <Row justify='justify-center'>
                    <GradientTitle label='Anatomy of an experience' textSize='text-4xl md:text-5xl 2xl:text-6xl text-center' justify='justify-center' />
                </Row>
                <Row justify='justify-center'>
                    <Column textSize='text-2xl' justify='justify-center' align='items-center'>
                        <h3 className='text-2xl md:text-3xl xl:text-4xl font-bold md:mb-4 leading-tight tracking-tight mb-4 text-center'>
                            How is everything structured?
                        </h3>
                        <div className='text-center text-base'>Every experience - digital or guided - is made of 3 main page types.</div>
                    </Column>
                </Row>
                <Row justify='justify-center' flex='flex-row'>
                    {/* <div className='flex flex-col lg:flex-row lg:justify-center gap-4 lg:gap-8 xl:gap-16 '> */}
                    <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-12 xl:gap-16 mt-8 md:mt-12'>
                        <Card number='1' title='Overview' image='/assets/media/landing/choosing.png'>
                            This is where you add key information about your experience to summarize everything. Quick description, destination, accommodation,
                            currency, general tips. You want to get this information quickly out of the way so that people can read and focus on the itinerary
                            of the experience itself
                        </Card>
                        <Card number='2' title='Itinerary' image='/assets/media/landing/create@2x.png'>
                            This is your experience. You add as many days as you want, and in each day, as many activities as you want. For each activity you
                            can describe it, add photos, and extra information pertaining to it.
                        </Card>
                        <Card number='3' title='Marketing' image='/assets/media/landing/aiming.png'>
                            The information you have provided up to this point is for people who have already bought you experience. But as you know well,
                            people aren't sold easily. This is where you spice up and filter the info you need to make your experience shine in the best light
                            and push people to buy it.
                        </Card>
                        {/* <div className='w-full lg:w-72 xl:w-1/3 mb-16 md:mb-0  flex flex-col md:flex-row lg:flex-col md:gap-8 lg:gap-0'>
                            <img src='/assets/media/basics/d_v_g_01@2x.png' className='h-96 w-auto object-contain' />
                            <div className='md:mt-16 lg:mt-0 px-4 xl:px-0'>
                                <div className='text-4xl mb-4 text-green-400 font-semibold'>Overview</div>
                                <div className='text-lg text-gray-600 '>
                                    This is where you add key info about your experience to summarize everything. Quick description, destination, accommodation,
                                    currency, general tips. You want to get this information quickly out of the way so that people can read and focus on the
                                    itinerary of the experience itself
                                </div>
                            </div>
                        </div>
                        <div className='w-full lg:w-72 xl:w-1/3 mb-16 md:mb-0  flex flex-col md:flex-row lg:flex-col md:gap-8 lg:gap-0'>
                            <img src='/assets/media/basics/d_v_g_02@2x.png' className='h-96 w-auto object-contain' />
                            <div className='md:mt-16 lg:mt-0 px-4 xl:px-0'>
                                <div className='text-4xl mb-4 text-green-400 font-semibold'>The Itinerary</div>
                                <div className='text-lg text-gray-600 '>
                                    This is your experience. You add as many days as you want, and in each day, as many activities as you want. For each
                                    activity you can describe it, add photos, and extra information pertaining to it.
                                </div>
                            </div>
                        </div>
                        <div className='w-full lg:w-72 xl:w-1/3 mb-16 md:mb-0  flex flex-col md:flex-row lg:flex-col md:gap-8 lg:gap-0'>
                            <img src='/assets/media/basics/d_v_g_02@2x.png' className='h-96 w-auto object-contain' />
                            <div className='md:mt-16 lg:mt-0 px-4 xl:px-0'>
                                <div className='text-4xl mb-4 text-green-400 font-semibold'>The Marketing page</div>
                                <div className='text-lg text-gray-600 '>
                                    The information you have provided up to this point is for people who have already bought you experience. But as you know
                                    well, people aren't sold easily. This is where you spice up and filter the info you need to make your experience shine in
                                    the best light and push people to buy it.
                                </div>
                            </div>
                        </div> */}
                    </div>
                </Row>
            </Section>

            <Section id='digital' pill='top' background='landing-gradient' padding='pt-8 pb-16 md:pb-24 lg:pt-24 lg:pb-12'>
                <Row justify='justify-end'>
                    <Column textSize='text-2xl'>
                        <GradientTitle label='Digital Experience' textSize='text-4xl md:text-5xl 2xl:text-6xl' justify='' containerClass='md:mb-4' />
                        <h3 className='text-2xl md:text-3xl xl:text-4xl font-bold md:mb-4 leading-tight tracking-tight mb-4'>
                            Anatomy of a digital experience
                        </h3>
                        <div className='text-lg'>
                            To see everything in action click on the button below to see a chart exlaining all sections and content of a sample digital
                            experience
                        </div>
                        <div className='mt-8 w-full md:w-auto'>
                            <Button
                                // animation={false}
                                margins='-mt-0.5'
                                as='link'
                                label='Digital Sample'
                                link='/basics/digital'
                                width='w-full md:w-64'
                            />
                        </div>
                    </Column>
                    <Column textSize='text-2xl' align='items-center'>
                        <img src='/assets/media/basics/d_v_g_01@2x.png' className='h-64 md:h-128 lg:h-96 w-auto object-contain lg:-mt-12' />
                    </Column>
                </Row>
            </Section>

            <Section id='guided' pill='top' background='bg-white' padding='pt-8 pb-16 md:pb-24 lg:pt-24 lg:pb-20'>
                <Row justify='justify-end' flex='flex-col lg:flex-row'>
                    <Column textSize='text-2xl' align='items-center'>
                        <img src='/assets/media/basics/d_v_g_02@2x.png' className='lg:pr-12 h-64 md:h-128 lg:h-96 w-auto object-contain lg:-mt-12' />
                    </Column>
                    <Column textSize='text-2xl'>
                        <GradientTitle label='Guided Experience' textSize='text-4xl md:text-5xl 2xl:text-6xl' justify='' containerClass='md:mb-4' />
                        <h3 className='text-2xl md:text-3xl xl:text-4xl font-bold md:mb-4 leading-tight tracking-tight mb-4'>Less is definitely more!</h3>
                        <div className='text-lg'>
                            As this is a guided experience, you don't need to go into lots of detail. We still need 3 sections (Overview, Itinerary, and
                            Marketing) but we don't go into as much detail as a digital experience. Check the sample below
                        </div>
                        <div className='mt-8 w-full md:w-auto'>
                            <Button
                                // animation={false}
                                margins='-mt-0.5'
                                as='link'
                                label='Guided Sample'
                                link='/basics/guided'
                                width='w-full md:w-64'
                            />
                        </div>
                    </Column>
                </Row>
            </Section>
            <Section id='how-to' pill='top' background='bg-gray-100' padding='py-20'>
                {/* <Row justify='justify-centera'>
                    <GradientTitle label='How-to Videos' textSize='text-3xl md:text-5xl lg:text-6xl'  justify='justify-center' />
                </Row> */}

                <Row justify='justify-center lg:justify-start' align='items-center' gap='gap-8 xl:gap-8' flex='flex-col-reverse lg:flex-row'>
                    <Column textSize='text-2xl' justify='justify-center lg:justify-start' align='items-center lg:items-start'>
                        <GradientTitle
                            label='How-to Videos'
                            textSize='text-4xl md:text-5xl 2xl:text-6xl text-center lg:text-left'
                            justify='justify-center'
                            containerClass='md:mb-4'
                        />
                        <h3 className='text-2xl md:text-3xl font-bold mb-1 text-center lg:text-left'>How to use the platform</h3>
                        <div className='text-base xl:text-lg text-center lg:text-left'>
                            Check out our How-to video page to learn how to ins and outs of using the Kreator Platform.{' '}
                        </div>
                        <div className='mt-8 w-full md:w-auto'>
                            <Button
                                // animation={false}
                                margins='-mt-0.5'
                                as='link'
                                label='How-to Videos'
                                link='/basics/how-to'
                                width='w-full md:w-64'
                            />
                        </div>
                    </Column>
                    <Column>
                        <div className='overflow-hidden rounded-xl shadow-2xl-green'>
                            <video controls={true} autoPlay={true} muted={true} loop={true} preload={true}>
                                <source src='https://ucarecdn.com/725038b5-1ac3-4d9d-a825-a50450478c2f/' type='video/mp4'></source>
                            </video>
                        </div>
                    </Column>
                </Row>
                {/* <Row justify='justify-center'>
                    <Column width='w-full'>
                        <div class='mx-auto w-full h-160 mt-16 mb-8'>
                            
                            <iframe
                                width='100%'
                                height='100%'
                                src={`https://www.youtube-nocookie.com/embed/playlist?list=${process.env.REACT_APP_TUTORIAL_VID_CODE}&modestbranding=1&rel=0&iv_load_policy=3&theme=light&color=white`}
                                frameorder='0'
                                allowFullScreen
                            ></iframe>
                        </div>
                    </Column>
                </Row> */}
            </Section>
            <Section id='faq' pill='top' background='bg-white' padding='pt-8 pb-16 md:pb-24 lg:pt-24 lg:pb-20'>
                <Row justify='justify-end' flex='flex-col lg:flex-row'>
                    <Column textSize='text-2xl' align='items-center'>
                        <img src='/assets/media/landing/joinus@2x.png' className='lg:pr-12 h-64 md:h-128 lg:h-96 w-auto object-contain lg:-mt-12' />
                    </Column>
                    <Column textSize='text-2xl'>
                        <GradientTitle label='Kreator FAQ' textSize='text-4xl md:text-5xl 2xl:text-6xl' justify='' containerClass='mb-2 md:mb-4' />

                        <div className='text-lg'>
                            You probably still have a ton of questions. We'll answer some of the most common here. For general or full FAQ, click on the button
                            below
                        </div>
                        <div className='mt-8 w-full md:w-auto'>
                            <Button
                                // animation={false}
                                margins='-mt-0.5'
                                as='url'
                                label='General FAQ'
                                url={`${marketPath}/help/article/2001`}
                                width='md:w-64'
                            />
                        </div>
                    </Column>
                </Row>
                <Row gap='gap-12 md:gap-8 lg:gap-20' flex='flex-col md:flex-row' margins='mt-12 mx-auto'>
                    <Column gap='gap-12'>
                        <Question number='1' title='How do I start an experience?'>
                            Just sign up and you will have access the Kreator page.
                        </Question>
                        <Question number='2' title='Can I make more than one experience?'>
                            You can make as many experiences as you like.
                        </Question>
                        <Question number='3' title='What is the experience approval process?'>
                            Once you finish and submit your experience, it will be reviewed by Konnect and either approved and made public or you will be sent
                            comments to make it better.
                        </Question>
                        <Question number='4' title='How do I know what to write?'>
                            There is information/advice text on every section of the experience that will help you understand what you need to include.
                        </Question>
                        <Question number='5' title='Who sets the price for my experience?'>
                            You do but we provide guidelines on pricing to help increase your sales.
                        </Question>
                    </Column>
                    <Column gap='gap-12'>
                        <Question number='6' title='Is Konnect free to use?'>
                            There is no fee of subscription to be a part of Konnect. We get our revenues from commissions.
                        </Question>
                        <Question number='7' title='Who owns the content?'>
                            You own the intellectual property (IP) of your experience including photos.
                        </Question>
                        <Question number='8' title='How much commission do I make?'>
                            For Digital we share the revenue 80-20% in favor of the Kreator. For Guided tours we take 10% commission of the purchase price.
                        </Question>
                        <Question number='9' title='How am I paid?'>
                            You are paid directly to a digital wallet such as Stripe, Paypal, or STC Pay in 30 days.
                        </Question>
                        <Question number='10' title='What happens if I want to leave?'>
                            You can leave any time and your experiences will be removed from the site.
                        </Question>
                    </Column>
                </Row>
            </Section>
        </>
    );
};

export default LayoutBasics;

const Card = ({ number, title, children, image }) => {
    return (
        <div className='w-full mb-4 md:mb-0  flex flex-col md:flex-row lg:flex-col md:gap-8 lg:gap-0'>
            <img src={image} className='md:w-1/2 lg:h-80 w-auto lg:w-auto object-contain' />
            <div className='md:mt-0 lg:mt-0 '>
                <div className='flex items-center gap-4 mb-4'>
                    <NbPill value={number} />

                    <div className='text-3xl xl:text-4xl text-green-400 font-semibold'>{title}</div>
                </div>
                <div className='text-base xl:text-lg text-gray-600 '>{children}</div>
            </div>
        </div>
    );
};

const Question = ({ title, children, number }) => {
    return (
        <div>
            {/* <h2 className='text-green-400 text-4xl font-bold mb-2'>{number}</h2> */}

            <h3 className='text-gray-800 text-xl lg:text-xl font-bold  leading-tight flex items-center gap-4 mb-2 lg:mb-0'>
                <div className='lg:-ml-12'>
                    <NbPill value={number} />
                </div>
                <div>{title}</div>
            </h3>
            <div className='text-lg'>{children}</div>
        </div>
    );
};

const NbPill = ({ value }) => {
    return (
        <div className='rounded-full h-12 w-8 flex justify-center items-center text-xl bg-green-400 text-gray-600 font-bold shadow-2xl-green-500 '>
            <span className='mix-blend-multiply'>{value}</span>
        </div>
    );
};
