import store from 'store/index';

export const isPublishedViewFunc = () => {
    return window?.location.pathname.includes(
        process.env.REACT_APP_BASENAME_EXPERIENCE_PUBLISHED
    );
};

export const isAdminStaffFunc = (userRole) => {
    return (
        userRole === process.env.REACT_APP_STRAPI_ADMIN_ROLE_ID ||
        userRole === process.env.REACT_APP_STRAPI_ADMIN_ROLE_ID
    );
};

export const isOwnerFunc = (expAuthorTmp) => {
    const {
        auth: {
            user: { id: userId }
        },
        experienceDetails: { user: expAuthor }
    } = store.getState();

    return expAuthorTmp?._id === userId || expAuthor?._id === userId;
};
