import { ProgressPage } from 'components/blocks/ProgressMenu';
import CollapseButton from 'components/blocks/CollapseButton';
import { useOnlineStatus } from 'helpers/useOnlineStatus';

const ExperiencePurchasedCardBar = ({
    swellbypass,
    guided,
    isOwner,
    isAdminStaff,
    isSuperAdmin = false,
    expStatus,
    feedbackAction,
    handleGuided,
    handleDigital,
    handleDelete,
    type,
    rtl,
    completion = 100,
    inPublished = false,
    experienceStatus,
    viewPublished
}) => {
    const isOnline = useOnlineStatus();

    return (
        <div className={`z-50 absolute top-0 ${rtl ? 'left-0' : 'right-0'}`}>
            <div className='flex-1 flex flex-col p-4 justify-end text-2xl gap-2'>
                {guided && (
                    <div className='flex justify-end'>
                        <CollapseButton
                            //  disabled={!isOnline}
                            labelHover={'Guided Info'}
                            handleClick={null}
                            icon={`ri-equalizer-line text-xl`}
                            size='10'
                            textSize='text-xs'
                            sizeHover='w-44'
                            offsetCenter='2'
                            padding='px-2.5'
                        />
                    </div>
                )}
                {swellbypass && (
                    <div className='flex justify-end'>
                        <CollapseButton
                            labelHover={`Trial`}
                            size='10'
                            sizeHover='w-24'
                            textSize='text-xs'
                            icon='ri-time-line text-2xl -ml-0.5'
                            offsetCenter='2'
                            nolink
                            btnColor='bg-gray-900 text-white'
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ExperiencePurchasedCardBar;
