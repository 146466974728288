import { Link } from 'react-router-dom';
const FooterMenuLink = ({ isLink, url, label, hash, sameWindow }) => {
    return (
        <li className='list-none'>
            {isLink ? (
                <Link to={{ pathname: url, hash }} className='hover:underline'>
                    {label}
                </Link>
            ) : (
                <a className='hover:underline' href={url} target={sameWindow ? '_self' : '_blank'}>
                    {label}
                </a>
            )}
        </li>
    );
};

export default FooterMenuLink;
