export const updateImageIntro = (draft, action) => {
    const { url, dayIndex, imgType: type } = action;

    draft.experience.itinerary.trip_days[
        dayIndex
    ].day_intro.day_featured_image = {
        data: url,
        type
    };

    return draft;
};

export const updateDescIntro = (draft, action) => {
    const { descHtml, dayIndex } = action;

    draft.experience.itinerary.trip_days[dayIndex].day_intro.description_html =
        descHtml;

    return draft;
};

export const updateTitleIntro = (draft, action) => {
    const { titleTxt, dayIndex } = action;

    draft.experience.itinerary.trip_days[dayIndex].day_intro.title = titleTxt;

    return draft;
};
