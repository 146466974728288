/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';
import { connect } from 'react-redux';
import ModalFullscreen, { ModalBody } from 'components/blocks/ModalFullscreen';
import ModalContainer, {
    ModalFooter,
    ModalCTA,
    ModalButton,
    ModalHelp
} from 'components/blocks/ModalTre';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import GalleryArrange from 'components/specialty/GalleryArrange';
import UploadGalleryImages from 'components/specialty/UploadGalleryImages';
import YoutubeModal from './YoutubeModal';
import ModalTopBlock from 'components/blocks/ModalTopBlock';
import { nanoid } from 'nanoid';
import { useOnlineStatus } from 'helpers/useOnlineStatus';
import classNames from '../../../node_modules/classnames/index';

// should be in all modals
const targetElement = document.querySelector('#root');

function GalleryModal({
    maxImages,
    close,
    setModalIsOpen,
    handleArrangeGallery,
    globalState: { lang },
    icon = { name: 'GALLERY' },
    hintObj = null,
    actionBtnObj = uiStruct.ui.modals.editor.buttons.action,
    cancelBtnObj = uiStruct.ui.modals.editor.buttons.cancel,
    imageList,
    handleUpdateGallery,
    modalTitle = 'Activity'
}) {
    const rtl = !!translations[lang].rtl;

    const isOnline = useOnlineStatus();
    // Copy Below to all modals
    //////////////////////////////////////////////////////////////////////

    // State for triggering animation of modal in and out
    const [transitionState, setTransitionState] = useState('off');
    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    const convertKonnectObjToGalleryObj = (arr, startKey = 0) => {
        return arr.map((obj, i) => {
            const { url, caption, vidCode, type } = obj;

            return {
                src: url,
                key: `${url}${i + startKey}`,
                caption,
                width: 0,
                height: 0,
                type: type || 'img',
                vidCode: vidCode || null
            };
        });
    };
    const [imageOrder, setImageOrder] = useState(
        convertKonnectObjToGalleryObj(imageList)
    );
    const handleReorder = (listArray) => {
        setImageOrder(listArray);
    };

    const handleNewImages = (newArray) => {
        if (Array.isArray(newArray)) {
            setImageOrder([
                ...imageOrder,
                ...convertKonnectObjToGalleryObj(newArray, imageOrder.length)
            ]);
        } else {
            // setImageOrder([...imageOrder, { src: url, key: `${url}${i + startKey}`, caption, width: 0, height: 0 }])
        }
    };

    const handleActionClick = () => {
        handleUpdateGallery(
            imageOrder.map((obj) => {
                const { src: url, caption, type, vidCode } = obj;

                return {
                    url,
                    caption,
                    type: type || 'img',
                    vidCode: vidCode || null,
                    objId: obj.objId ?? `image_${nanoid()}`
                };
            })
        );
        handleTransitionStateOff(); // important... don't forget to add it in all modals
    };

    const [youtubeModalState, setYoutubeModalState] = useState(false);
    const openYoutubeModal = () => {
        setYoutubeModalState(true);
    };
    const handleVidUpdate = (vidCode) => {
        handleNewImages([
            {
                caption: '',
                url: `https://img.youtube.com/vi/${vidCode}/0.jpg`,
                type: 'youtube',
                vidCode
            }
        ]);
    };
    return (
        <>
            {!youtubeModalState && (
            <ModalFullscreen
                width='4xl'
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={icon}
                close={close}
                closeButton={true}
                rtl={rtl}
                fullscreen={true}
            >
                <ModalBody rtlOff>
                    <div className='xl:px-32 '>
                        <div className='w-full px-2 mb-8 mt-4'>
                            <ModalTopBlock
                                title={modalTitle}
                                subtitle='Manage Gallery'
                                text={`Click to add images and/or videos. Drag and drop to
                                    arrange them. Max Limit: ${maxImages}`}
                                rtl={rtl}
                                lang={lang}
                            />
                        </div>

                        {
                            maxImages - imageOrder.length ? (
                                <div className='flex flex-col md:flex-row justify-center mt-6 gap-2 md:gap-6 px-4'>
                                    <div className='mx-auto md:mx-0'>
                                        <UploadGalleryImages
                                            multiple={
                                                maxImages -
                                                    imageOrder.length !==
                                                1
                                            }
                                            maxImages={
                                                maxImages - imageOrder.length
                                            }
                                            handleUpdate={handleNewImages}
                                        />
                                    </div>
                                    {imageOrder.length < maxImages && (
                                        <button
                                            disabled={!isOnline}
                                            onClick={openYoutubeModal}
                                            className={classNames(
                                                'focus:outline-none mx-auto md:mx-0 outline-none  font-medium text-base  rounded-md ',
                                                isOnline
                                                    ? 'text-red-800 bg-red-200 hover:bg-gray-900 hover:text-white'
                                                    : 'text-gray-400 bg-gray-200'
                                            )}
                                            style={{ width: 175, height: 37 }}
                                        >
                                            Add a Video
                                        </button>
                                    )}
                                </div>
                            ) : (
                                <p>Max Images reached</p>
                            ) // Amine Translate?
                        }

                        <div className=''>
                            <GalleryArrange
                                maxImages={maxImages}
                                imageList={imageOrder}
                                handleReorder={handleReorder}
                                handleArrangeGallery={handleArrangeGallery}
                            />
                        </div>
                        <div className='mt-16 mb-8 px-4 md:px-10'>
                            <ModalFooter>
                                {hintObj && (
                                    <ModalHelp
                                        helpText={translations[lang][hintObj]}
                                        isHtml
                                    />
                                )}
                                <ModalCTA>
                                    <ModalButton
                                        handleClick={handleTransitionStateOff}
                                        label={
                                            translations[lang][
                                                cancelBtnObj.label
                                            ]
                                        }
                                        color={cancelBtnObj.color}
                                        icon={cancelBtnObj.icon}
                                    />
                                    <ModalButton
                                        handleClick={handleActionClick}
                                        label={
                                            translations[lang][
                                                actionBtnObj.label
                                            ]
                                        }
                                        color={actionBtnObj.color}
                                        icon={actionBtnObj.icon}
                                    />
                                </ModalCTA>
                            </ModalFooter>
                        </div>
                    </div>
                </ModalBody>
            </ModalFullscreen>)}
            {youtubeModalState && (
                <YoutubeModal
                    setModalIsOpen={setYoutubeModalState}
                    updateObj={''}
                    update={true}
                    handleActionBtn={handleVidUpdate}
                />
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(GalleryModal);
