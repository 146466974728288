/* eslint-disable react/jsx-pascal-case */
/* eslint-disable arrow-body-style */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { useState } from 'react';
import { createItineraryDay, deleteItineraryDay } from 'store/actions/experienceDetails/overview';

import { MenuRefsHolder } from 'helpers/MenuRefsHolder';
import ExpSubsection from 'components/blocks/ExpSubsection';
import ItineraryCard from 'components/blocks/marketing/ItineraryCard';
import ItineraryModal from 'components/modals/ItineraryModal';

function SectionMarketingItinerary(props) {
    const {
        experienceDetails: { experience, id, days, type },
        globalState: { lang, edit },
        setSelectedDay,
        setItineraryModalStatus
    } = props;

    const {
        itinerary: { trip_days: ItineraryDaysList }
    } = experience;

    return (
        <>
            <ExpSubsection>
                <div className='marketing-title'>Experience Itinerary</div>

                <div className='w-full -mt-6'>
                    {ItineraryDaysList.map((dayContent, index) => {
                        return (
                            <ItineraryCard
                                key={`itin_${index}`}
                                data={dayContent.day_intro}
                                index={index}
                                experienceId={id}
                                setSelectedDay={setSelectedDay}
                                setModalIsOpen={setItineraryModalStatus}
                                days={days}
                                type={type}
                            />
                        );
                    })}
                </div>
            </ExpSubsection>
            {/* {itineraryModalStatus ? (
                <ItineraryModal
                    index={selectedDay}
                    setModalIsOpen={setItineraryModalStatus}
                />
            ) : null} */}
        </>
    );
}

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createItineraryDay,
            deleteItineraryDay
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionMarketingItinerary);
