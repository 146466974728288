import React, { useState } from 'react';
import { connect } from 'react-redux';
import CardXl from 'components/blocks/CardXl';
import TipList from 'components/blocks/TipList';
import GenericDescription from 'components/blocks/GenericDescription';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { countriesTranslations } from 'constants/countriesTranslations';
import { PillContainer, PillIcon, PillTitle } from 'components/blocks/Pills';
import CurrencyVendorsList from 'components/blocks/CurrencyVendorsList';
import CurrencyBox from 'components/blocks/CurrencyBox';

function SectionCurrencySingle({
    actionType,
    index,
    data,
    updateCurrency,
    removeModal = null,
    globalState: { lang, edit, isPrintMode, isFeedbackMode },
    countriesNotUsedYet,
    handleVisible,
    isVisible
}) {
    const typeText = countriesTranslations[lang][data.countryCode].name;
    const rtl = !!translations[lang].rtl;
    const [isMinimized, setIsMinimized] = useState(false);
    const handleEdit = () => {
        updateCurrency(index);
    };

    return (
        <>
            <li className='list-none'>
                <CardXl
                    isFeedbackMode={isFeedbackMode}
                    objId={data?.objId?.section}
                    edit={edit}
                    isPrintMode={isPrintMode}
                    handleDelete={() => {
                        removeModal(index);
                    }}
                    rtl={rtl}
                    hidden={isMinimized}
                    handleMinimize={() => setIsMinimized(!isMinimized)}
                    isVisible={isVisible}
                    handleVisible={handleVisible}
                    handleMinimize={() => setIsMinimized(!isMinimized)}
                    hideEye={true}
                >
                    <div
                        className={`z-200 w-max mx-auto  ${
                            isMinimized ? 'mt-2 xl:mt-8' : ''
                        }`}
                    >
                        <div
                            onClick={
                                edit && countriesNotUsedYet && !isFeedbackMode
                                    ? handleEdit
                                    : null
                            }
                        >
                            <PillContainer
                                rtl={rtl}
                                edit={edit && countriesNotUsedYet}
                                isFeedbackMode={isFeedbackMode}
                            >
                                <PillIcon
                                    icon={
                                        uiStruct.overview.getting_there
                                            .uiPreview.icon.name
                                    }
                                />
                                <PillTitle title={typeText} />
                            </PillContainer>
                        </div>
                    </div>
                    <div className={`${isMinimized ? 'hidden' : ''}`}>
                        <CurrencyBox country={data.countryCode} />

                        {false && (
                            <CurrencyVendorsList
                                index={index}
                                vendors={data.vendors}
                            />
                        )}

                        <GenericDescription
                            actionType={actionType}
                            dayIndex={null}
                            index={index}
                            descriptionHtml={data.description_html}
                            defaultHtml={
                                translations[lang][
                                    uiStruct.overview.currency_paying.uiPreview
                                        .descriptionEmpty
                                ]
                            }
                            objId={data?.objId?.description}
                            title='Currency'
                            subtitle='Description'
                        />

                        <TipList
                            // max
                            objId={data?.objId?.tiplist}
                            actionType={actionType}
                            tips={data.tips_html}
                            dayIndex={null}
                            index={index}
                            offset={0}
                            isVisible={isVisible}
                        />
                    </div>
                </CardXl>
            </li>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(SectionCurrencySingle);
