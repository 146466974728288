import { connect } from 'react-redux';
import translations from 'constants/translations';

const MapStatus = ({
    globalState: {
        lang,
        googleMaps: {
            loading: { isLoading, msg: loadingMessage },
            error: { isError, msg: errorMessage }
        }
    }
}) => {
    const buildMessage = () => {
        if (isLoading) {
            return (
                <div className='flex flex-col items-center text-sm text-gray-500'>
                    <span>Loading Map Component...</span>
                    <span> If you can't see any map try refreshing the page.</span>
                </div>
            );
        } else if (isError) {
            return (
                <div className='flex flex-col items-center text-sm text-gray-500'>
                    <span>Error Loading Map Component</span>
                    <span> {translations[lang][errorMessage]}</span>
                </div>
            );
        } else {
            return null;
        }
    };

    return <div>{buildMessage()}</div>;
};

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(MapStatus);
