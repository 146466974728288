/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ModalContainer, { ModalBody, ModalFooter, ModalCTA, ModalButton, ModalHelp } from 'components/blocks/ModalTre';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { BlockInputSingle } from 'components/blocks/Blocks';

const targetElement = document.querySelector('#root');

function ConfirmModal({
    close,
    setModalIsOpen,
    arg,
    handleActionBtn,
    globalState: { lang },
    icon = uiStruct.ui.modals.delete.icon,

    confirmText = translations[lang][uiStruct.ui.modals.delete.description],
    actionBtnObj = uiStruct.ui.modals.delete.buttons.action,
    cancelBtnObj = uiStruct.ui.modals.delete.buttons.cancel,
    doubleConfirm = false
}) {
    const rtl = !!translations[lang].rtl;

    const [deleteInput, setDeleteInput] = useState('');
    // Copy Below to all modals
    //////////////////////////////////////////////////////////////////////

    // State for triggering animation of modal in and out
    const [transitionState, setTransitionState] = useState('off');
    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    ///////////////////////////////////////////////////////////////////

    const handleActionClick = () => {
        handleActionBtn(arg);
        handleTransitionStateOff(); // important... don't forget to add it in all modals
    };

    const handleDeleteInput = (e) => {
        const { name, value } = e.target;

        setDeleteInput(value);
    };

    return (
        <>
            <ModalContainer
                width='xl'
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                icon={icon}
                close={close}
                rtl={rtl}
            >
                <ModalBody height='24'>
                    {confirmText}

                    {doubleConfirm && (
                        <div className='mt-4 p-8 rounded-xl border-gray-100 border-2'>
                            <div className='text-sm mb-4'>Type DELETE in the input box then click on the Delete button</div>
                            <BlockInputSingle
                                error={deleteInput !== 'DELETE'}
                                className='text-xs'
                                height='h-10'
                                width='w-full'
                                rounded='rounded-lg'
                                handleChange={(e) => handleDeleteInput(e)}
                                id={`confirm_001`}
                                name='confirm'
                                value={deleteInput}
                            />
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <ModalCTA>
                        <ModalButton
                            handleClick={handleTransitionStateOff}
                            label={translations[lang][cancelBtnObj.label]}
                            color={cancelBtnObj.color}
                            icon={cancelBtnObj.icon}
                        />
                        <ModalButton
                            handleClick={handleActionClick}
                            label={translations[lang][actionBtnObj.label]}
                            color={actionBtnObj.color}
                            icon={actionBtnObj.icon}
                            isDisabled={doubleConfirm ? deleteInput !== 'DELETE' : false}
                        />
                    </ModalCTA>
                </ModalFooter>
            </ModalContainer>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(ConfirmModal);
