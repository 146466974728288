/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ModalContainer, {
    ModalBody,
    ModalFooter,
    ModalCTA,
    ModalButton,
    ModalHelp
} from 'components/blocks/ModalTre';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import CheckListLinkTitle from 'components/modals/whatYouNeed/CheckListLinkTitle';
import uiStruct from 'constants/uiStruct';
import translations from 'constants/translations';
import { testValidUrl } from 'helpers/FEutils';
import ModalTopBlock from 'components/blocks/ModalTopBlock';

// should be in all modals
const targetElement = document.querySelector('#root');

function CheckListModal({
    close,
    setModalIsOpen,
    handleActionBtn,
    updateObj = null,
    update = false,
    globalState: { lang },
    hintObj = null,
    icon = uiStruct.itinerary.what_you_need.uiEdit.modals.checklist.icon,

    actionBtnObj = uiStruct.itinerary.what_you_need.uiEdit.modals.checklist
        .buttons.action,
    cancelBtnObj = uiStruct.itinerary.what_you_need.uiEdit.modals.checklist
        .buttons.cancel
}) {
    //

    const rtl = !!translations[lang].rtl;

    const defaultDataObj = {
        url: [
            {
                label: '',
                url: ''
            }
        ]
    };

    const defaultUrl = {
        title: '',
        url: '' //title and url in pauls
    };

    //

    const [dataObj, updateDataObj] = useState(() =>
        update ? updateObj : defaultDataObj
    ); // set to a default or editing

    // path to ui local object - moving_around.uiEdit - in uiStruct.js (in reducers)
    // const [dataObj, updateDataObj] = useState(updateObj); // set to a default or editing

    const createValidationObj = (testObj) => {
        const valObj = JSON.parse(JSON.stringify(testObj));

        testObj.forEach((obj, index) => {
            if (obj.url.length !== 0 && !testValidUrl(obj.url)) {
                valObj[index].url = { error: true, message: 'Invalid url' };
            } else {
                valObj[index].url = { error: false, message: '' };
            }
            if (obj.title.length === 0) {
                valObj[index].title = { error: true, message: 'Required' };
            } else {
                valObj[index].title = { error: false, message: '' };
            }
        });

        return valObj;
    };
    const [validationObj, setValidationObj] = useState(
        createValidationObj(dataObj)
    );
    const [saveAttempted, setSaveAttempted] = useState(false);

    useEffect(() => {
        setValidationObj(createValidationObj(dataObj));
    }, [dataObj]);

    const validateForm = () => {
        for (let i = 0; i < validationObj.length; i++) {
            if (validationObj[i].url.error || validationObj[i].title.error) {
                return false;
            }
        }

        return true;
    };

    // State for triggering animation of modal in and out
    const [transitionState, setTransitionState] = useState('off');
    useEffect(() => {
        setTransitionState('on');
    }, []);

    // Prevent BG from scrolling
    useEffect(() => {
        disableBodyScroll(targetElement, { reserveScrollBarGap: true });

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    // Handle When Modal is loaded
    const handleTransitionStateOn = () => {
        setTransitionState('on');
    };

    // Handle When Modal is about to unload (trigger animation then change modal state)
    const handleTransitionStateOff = () => {
        setTransitionState('off');
        setTimeout(() => setModalIsOpen(false), 300);
    };

    ///////////////////////////////////////////////////////////////////

    // Paul stuff Handle Action BTN function
    const handleActionClick = () => {
        setSaveAttempted(true);
        if (validateForm()) {
            handleActionBtn(dataObj);
            handleTransitionStateOff(); // important... don't forget to add it in all modals
        }
    };

    // add / edit / delete on the modal

    const updateCheckListUrls = (type, index, name, val) => {
        updateDataObj([
            ...dataObj.map((obj, i) => {
                if (i === index) {
                    return {
                        ...obj,
                        [name]: name === 'url' ? val.replace(/ /g, '') : val
                    };
                }
                return obj;
            })
        ]);
    };

    const addCheckList = () => {
        setSaveAttempted(false);
        updateDataObj([...dataObj, defaultUrl]);
    };

    const deleteCheckList = (index) => {
        setSaveAttempted(false);
        updateDataObj([...dataObj.filter((item, i) => i !== index)]);
    };

    ///////////////////////

    return (
        <>
            <ModalContainer
                width='3xl'
                handleOpen={handleTransitionStateOff}
                handleTransition={handleTransitionStateOn}
                animationCss={uiStruct.ui.modals.animation[transitionState]}
                close
                icon={icon}
                close={close}
                rtl={rtl}
            >
                <ModalBody rtlOff maxheight='screen-2/3'>
                    <div className='w-full px-2 mt-4'>
                        <ModalTopBlock
                            title='Before you go'
                            subtitle='Checklist'
                            text='Add as many items as you need. Optionally add a link to your items.'
                            rtl={rtl}
                            lang={lang}
                        />
                    </div>

                    <CheckListLinkTitle
                        saveAttempted={saveAttempted}
                        validationObj={validationObj}
                        data={dataObj}
                        updateCheckListUrls={updateCheckListUrls}
                        deleteCheckList={deleteCheckList}
                        addCheckList={addCheckList}
                    />
                </ModalBody>

                <ModalFooter>
                    <ModalHelp isHtml helpText={translations[lang][hintObj]} />
                    <ModalCTA>
                        <ModalButton
                            handleClick={handleTransitionStateOff}
                            label={translations[lang][cancelBtnObj.label]}
                            color={cancelBtnObj.color}
                            icon={cancelBtnObj.icon}
                        />
                        <ModalButton
                            handleClick={handleActionClick}
                            label={translations[lang][actionBtnObj.label]}
                            color={actionBtnObj.color}
                            icon={actionBtnObj.icon}
                        />
                    </ModalCTA>
                </ModalFooter>
            </ModalContainer>
        </>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(CheckListModal);
