export const toggleLang = (draft, action) => {
    const { lang } = action;

    return {
        ...draft,
        lang
    };
};

export const toggleNav = (draft, action) => {
    const { navIsOpen } = action;

    return {
        ...draft,
        navIsOpen
    };
};

export const toggleEdit = (draft, action) => {
    const { edit } = action;

    return {
        ...draft,
        edit
    };
};

export const togglePrintMode = (draft, action) => {
    const { isPrintMode } = action;

    return {
        ...draft,
        isPrintMode
    };
};

export const toggleFeedbackMode = (draft, action) => {
    const { isFeedbackMode } = action;

    return {
        ...draft,
        isFeedbackMode
        // edit: isFeedbackMode
    };
};

export const toggleSuperAdmin = (draft, action) => {
    const { isSuperAdmin } = action;

    return {
        ...draft,
        isSuperAdmin
    };
};

export const setIsAdminStaffMode = (draft, action) => {
    const { isAdminStaff } = action;

    return {
        ...draft,
        isAdminStaff
    };
};

export const setIsOwnerMode = (draft, action) => {
    const { isOwner } = action;

    return {
        ...draft,
        isOwner
    };
};

export const setIsPublishedViewMode = (draft, action) => {
    const { isPublishedView } = action;

    return {
        ...draft,
        isPublishedView
    };
};

export const setIsEduContentMode = (draft, action) => {
    const { isEduContent } = action;

    return {
        ...draft,
        isEduContent
    };
};

export const toggleToolTips = (draft, action) => {
    const { toolTips } = action;

    return {
        ...draft,
        toolTips
    };
};

export const toggleTour = (draft, action) => {
    const { tour } = action;

    return {
        ...draft,
        tour
    };
};

export const googleMapReady = (draft, action) => {
    draft.googleMaps.loading.isLoading = false;

    return draft;
};

export const googleMapLoadFail = (draft, action) => {
    draft.googleMaps.error.isError = true;
    draft.googleMaps.loading.isLoading = false;

    return draft;
};

export const setUpdateFeedbackPending = (draft, action) => {
    draft.status.feedbackSaving = true;

    return draft;
};

export const setUpdateFeedbackFufilled = (draft, action) => {
    draft.status.feedbackSaving = false;

    return draft;
};
