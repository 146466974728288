/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import SectionWhatYouNeed from 'components/sections/SectionWhatYouNeed';
import SectionActivities from 'components/sections/SectionActivities';
import SectionIntroTop from 'components/sections/SectionIntroTop';
import { _INTRO } from 'store/actions/types';
import { MenuRefsHolder } from 'helpers/MenuRefsHolder';
import * as types from 'store/actions/types';

function LayoutDay(props) {
    const { experienceDetails, dayIndex, inModal = false } = props;
    const [key, rerender] = useReducer((v) => !v);
    const { activities } =
        experienceDetails.experience.itinerary.trip_days[dayIndex];

    useEffect(() => {
        MenuRefsHolder.reset(types.REFS_DAY, dayIndex);
        rerender();
    }, [activities, dayIndex]);

    // console.log(
    //     'Section Intro Top ObjID',
    //     experienceDetails.experience.itinerary.trip_days[dayIndex].day_intro
    //         .objId
    // );

    return (
        <>
            <div className={`flex flex-1 flex-col justify-center `} key={key}>
                <div
                    id='dayIntro'
                    ref={
                        !inModal
                            ? (ref) => {
                                MenuRefsHolder.setRef(ref);
                            }
                            : null
                    }
                >
                    <SectionIntroTop
                        dayIndex={dayIndex}
                        actionType={_INTRO}
                        objId={
                            experienceDetails.experience.itinerary.trip_days[
                                dayIndex
                            ].day_intro.objId
                        }
                    />
                </div>
                <div
                    id='dayWhatYouNeed'
                    ref={
                        !inModal
                            ? (ref) => {
                                MenuRefsHolder.setRef(ref);
                            }
                            : null
                    }
                >
                    <SectionWhatYouNeed
                        max
                        defaultSet={false}
                        dayIndex={dayIndex}
                        isMarketingMode={inModal}
                    />
                </div>
                <SectionActivities
                    dayIndex={dayIndex}
                    isMarketingMode={inModal}
                />
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    experienceDetails: state.experienceDetails
});

export default connect(mapStateToProps)(LayoutDay);
