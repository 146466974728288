export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const USER_PROFILE_MISSING = 'USER_PROFILE_MISSING';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAIL = 'USER_PROFILE_FAIL';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_SWITCHING = 'AUTH_SWITCHING';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SWITCH_ERROR = 'SWITCH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SWITCH_SUCCESS = 'SWITCH_SUCCESS';
export const SWITCH_FAIL = 'SWITCH_FAIL';

export const LOGOUT = 'LOGOUT';
export const AUTH_CLEAR_ERRORS = 'AUTH_CLEAR_ERRORS';

/*----------*/
// site specific
/*----------*/
export const TOGGLE_NAV = 'TOGGLE_NAV';

export const SUBMIT_SUCCESS = 'SUBMIT_SUCCESS';
export const SUBMIT_FAIL = 'SUBMIT_FAIL';
export const GENERAL_LOADING = 'GENERAL_LOADING';
/*----------*/
// editor specific
/*----------*/
export const TOGGLE_EDITMODE = '[editor specific] TOGGLE_EDITMODE';
export const TOGGLE_PRINTMODE = 'TOGGLE_PRINTMODE';
export const TOGGLE_FEEDBACKMODE = 'TOGGLE_FEEDBACKMODE';
export const TOGGLE_SUPERADMIN = 'TOGGLE_SUPERADMIN';

export const SET_ISADMINSTAFFMODE = 'SET_ISADMINSTAFFMODE';
export const SET_ISOWNERMODE = 'SET_ISOWNERMODE';
export const SET_ISPUBLISHEDVIEWMODE = 'SET_ISPUBLISHEDVIEWMODE';
export const SET_ISEDUCONTENTMODE = 'SET_ISEDUCONTENTMODE';

export const TOGGLE_LANG = 'TOGGLE_LANG';
export const TOGGLE_TOOLTIPS = 'TOGGLE_TOOLTIPS';
export const TOGGLE_TOUR = 'TOGGLE_TOUR';

export const GOOGLE_MAP_INITIALISED = 'GOOGLE_MAP_INITIALISED';
export const GOOGLE_MAP_INITIALISED_FAILED = 'GOOGLE_MAP_INITIALISED_FAILED';

/*----------*/
// editor comments
/*----------*/
export const ADD_REF = 'ADD_REF';
export const ADD_COMMENT = 'ADD_COMMENT';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const UPDATE_FEEDBACK = 'UPDATE_FEEDBACK';

/*----------*/
// moving around
/*----------*/
export const ADD_MOVING_AROUND = 'ADD_MOVING_AROUND';
export const ADD_MOVING_AROUND_OVERVIEW = 'ADD_MOVING_AROUND_OVERVIEW';
export const UPDATE_MOVING_AROUND = 'UPDATE_MOVING_AROUND';
export const ARRANGE_MOVING_AROUND = 'ARRANGE_MOVING_AROUND';
export const ARRANGE_MOVING_AROUND_OVERVIEW = 'ARRANGE_MOVING_AROUND_OVERVIEW';
export const DELETE_MOVING_AROUND = 'DELETE_MOVING_AROUND';
export const DELETE_MOVING_AROUND_OVERVIEW = 'DELETE_MOVING_AROUND_OVERVIEW';

export const _MOVING_AROUND = '_MOVING_AROUND';
export const _MOVING_AROUND_OVERVIEW = '_MOVING_AROUND_OVERVIEW';
/*----------*/
// type
/*----------*/
export const UPDATE_TYPE_MOVING_AROUND = 'UPDATE_TYPE_MOVING_AROUND';
export const UPDATE_TYPE_MOVING_AROUND_OVERVIEW =
    'UPDATE_TYPE_MOVING_AROUND_OVERVIEW';

/*----------*/
// description
/*----------*/
export const UPDATE_DESCRIPTION = 'UPDATE_DESCRIPTION';
export const UPDATE_DESCRIPTION_MOVING_AROUND =
    'UPDATE_DESCRIPTION_MOVING_AROUND';
export const UPDATE_DESCRIPTION_MOVING_AROUND_OVERVIEW =
    'UPDATE_DESCRIPTION_MOVING_AROUND_OVERVIEW';

/*----------*/
// vendors
/*----------*/
export const ARRANGE_VENDORS = 'ARRANGE_VENDORS';
export const DELETE_VENDOR = 'DELETE_VENDOR';
export const UPDATE_VENDOR = 'UPDATE_VENDOR';
export const ADD_VENDOR = 'ADD_VENDOR';

export const ARRANGE_VENDORS_MOVING_AROUND = 'ARRANGE_VENDORS_MOVING_AROUND';
export const DELETE_VENDOR_MOVING_AROUND = 'DELETE_VENDOR_MOVING_AROUND';
export const UPDATE_VENDOR_MOVING_AROUND = 'UPDATE_VENDOR_MOVING_AROUND';
export const ADD_VENDOR_MOVING_AROUND = 'ADD_VENDOR_MOVING_AROUND';

export const ARRANGE_VENDORS_MOVING_AROUND_OVERVIEW =
    'ARRANGE_VENDORS_MOVING_AROUND_OVERVIEW';
export const DELETE_VENDOR_MOVING_AROUND_OVERVIEW =
    'DELETE_VENDOR_MOVING_AROUND_OVERVIEW';
export const UPDATE_VENDOR_MOVING_AROUND_OVERVIEW =
    'UPDATE_VENDOR_MOVING_AROUND_OVERVIEW';
export const ADD_VENDOR_MOVING_AROUND_OVERVIEW =
    'ADD_VENDOR_MOVING_AROUND_OVERVIEW';

/*----------*/
// tips
/*----------*/

export const ARRANGE_TIPS = 'ARRANGE_TIPS';
export const ADD_TIP = 'ADD_TIP';
export const DELETE_TIP = 'DELETE_TIP';
export const UPDATE_TIP = 'UPDATE_TIP';

export const ARRANGE_TIPS_MOVING_AROUND = 'ARRANGE_TIPS_MOVING_AROUND';
export const DELETE_TIP_MOVING_AROUND = 'DELETE_TIP_MOVING_AROUND';
export const ADD_TIP_MOVING_AROUND = 'ADD_TIP_MOVING_AROUND';
export const UPDATE_TIP_MOVING_AROUND = 'UPDATE_TIP_MOVING_AROUND';

export const ARRANGE_TIPS_MOVING_AROUND_OVERVIEW =
    'ARRANGE_TIPS_MOVING_AROUND_OVERVIEW';
export const DELETE_TIP_MOVING_AROUND_OVERVIEW =
    'DELETE_TIP_MOVING_AROUND_OVERVIEW';
export const ADD_TIP_MOVING_AROUND_OVERVIEW = 'ADD_TIP_MOVING_AROUND_OVERVIEW';
export const UPDATE_TIP_MOVING_AROUND_OVERVIEW =
    'UPDATE_TIP_MOVING_AROUND_OVERVIEW';

export const _OVERVIEW = '_OVERVIEW';
export const ARRANGE_TIPS_OVERVIEW = 'ARRANGE_TIPS_OVERVIEW';
export const DELETE_TIP_OVERVIEW = 'DELETE_TIP_OVERVIEW';
export const DELETE_TIPS_OVERVIEW = 'DELETE_TIPS_OVERVIEW';

export const ADD_TIP_OVERVIEW = 'ADD_TIP_OVERVIEW';
export const UPDATE_TIP_OVERVIEW = 'UPDATE_TIP_OVERVIEW';

export const ARRANGE_TIPS_ACTIVITIES = 'ARRANGE_TIPS_ACTIVITIES';
export const DELETE_TIP_ACTIVITIES = 'DELETE_TIP_ACTIVITIES';
export const ADD_TIP_ACTIVITIES = 'ADD_TIP_ACTIVITIES';
export const UPDATE_TIP_ACTIVITIES = 'UPDATE_TIP_ACTIVITIES';

/*----------*/
// what you need for day
/*----------*/

export const CREATE_WHAT_YOU_NEED = 'CREATE_WHAT_YOU_NEED';
export const DELETE_WHAT_YOU_NEED = 'DELETE_WHAT_YOU_NEED';
export const UPDATE_DESCRIPTION_CHECKLIST = 'UPDATE_DESCRIPTION_CHECKLIST';
export const UPDATE_CHECKLIST_LIST = 'UPDATE_CHECKLIST_LIST';
export const _CHECKLIST = '_CHECKLIST';
/*----------*/
// what you will be doing
/*----------*/

export const _ACTIVITIES = '_ACTIVITIES';

/*----------*/
// activities
/*----------*/

export const _ACTIVITY = '_ACTIVITY';
export const ADD_ACTIVITY = 'ADD_ACTIVITY';
export const ARRANGE_ACTIVITY = 'ARRANGE_ACTIVITY';
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
export const UPDATE_DESCRIPTION_ACTIVITY = 'UPDATE_DESCRIPTION_ACTIVITY';
export const UPDATE_GALLERY_ACTIVITY = 'UPDATE_DESCRIPTION_ACTIVITY';
export const UPDATE_GALLERY_CAPTION_ACTIVITY =
    'UPDATE_GALLERY_CAPTION_ACTIVITY';
export const UPDATE_CAPTION_SLIDE_ACTIVITY = 'UPDATE_CAPTION_SLIDE_ACTIVITY';
export const UPDATE_DESCRIPTION_ACTIVITIES = 'UPDATE_DESCRIPTION_ACTIVITIES';
export const UPDATE_MAP_DATA_ACTIVITY = 'UPDATE_MAP_DATA_ACTIVITY';
export const ARRANGE_VENDORS_ACTIVITIES = 'ARRANGE_VENDORS_ACTIVITIES';
export const DELETE_VENDOR_ACTIVITIES = 'DELETE_VENDOR_ACTIVITIES';
export const UPDATE_VENDOR_ACTIVITIES = 'UPDATE_VENDOR_ACTIVITIES';
export const ADD_VENDOR_ACTIVITIES = 'ADD_VENDOR_ACTIVITIES';

/*----------*/
// Day Intro
/*----------*/
export const UPDATE_TITLE = 'UPDATE_TITLE';
export const _INTRO = '_INTRO';
export const _INTRO_OVERVIEW = '_INTRO_OVERVIEW';

export const UPDATE_IMAGE_INTRO = 'UPDATE_IMAGE_INTRO';
export const UPDATE_DESCRIPTION_INTRO = 'UPDATE_DESCRIPTION_INTRO';
export const UPDATE_TITLE_INTRO = 'UPDATE_TITLE_INTRO';

export const UPDATE_IMAGE_INTRO_OVERVIEW = 'UPDATE_IMAGE_INTRO_OVERVIEW';
export const UPDATE_DESCRIPTION_INTRO_OVERVIEW =
    'UPDATE_DESCRIPTION_INTRO_OVERVIEW';
export const UPDATE_TITLE_INTRO_OVERVIEW = 'UPDATE_TITLE_INTRO_OVERVIEW';

/*----------*/
// Day Intro
/*----------*/
export const ADD_DAY_ITINERARY = 'ADD_DAY_ITINERARY';
export const ARRANGE_DAYS_ITINERARY = 'ARRANGE_DAYS_ITINERARY';
export const DELETE_DAY_ITINERARY = 'DELETE_DAY_ITINERARY';

/*----------*/
// Getting There
/*----------*/
export const _GETTING_THERE = '_GETTING_THERE';
export const UPDATE_DESCRIPTION_GETTING_THERE =
    'UPDATE_DESCRIPTION_GETTING_THERE';
export const UPDATE_TIP_GETTING_THERE = 'UPDATE_TIP_GETTING_THERE';
export const ADD_TIP_GETTING_THERE = 'ADD_TIP_GETTING_THERE';
export const DELETE_TIP_GETTING_THERE = 'DELETE_TIP_GETTING_THERE';
export const ARRANGE_TIPS_GETTING_THERE = 'ARRANGE_TIPS_GETTING_THERE';
export const ARRANGE_VENDORS_GETTING_THERE = 'ARRANGE_VENDORS_GETTING_THERE';
export const DELETE_VENDOR_GETTING_THERE = 'DELETE_VENDOR_GETTING_THERE';
export const UPDATE_VENDOR_GETTING_THERE = 'UPDATE_VENDOR_GETTING_THERE';
export const ADD_VENDOR_GETTING_THERE = 'ADD_VENDOR_GETTING_THERE';
export const UPDATE_ENTRY_GETTING_THERE = 'UPDATE_ENTRY_GETTING_THERE';

/*----------*/
// Destinations
/*----------*/

export const _DESTINATIONS_OVERVIEW = '_DESTINATIONS_OVERVIEW';
export const UPDATE_DESCRIPTION_DESTINATIONS_OVERVIEW =
    'UPDATE_DESCRIPTION_DESTINATIONS_OVERVIEW';
export const ARRANGE_DESTINATIONS_OVERVIEW = 'ARRANGE_DESTINATIONS_OVERVIEW';
export const DELETE_DESTINATIONS_OVERVIEW = 'DELETE_DESTINATIONS_OVERVIEW';
export const UPDATE_DESTINATIONS_OVERVIEW = 'UPDATE_DESTINATIONS_OVERVIEW';
export const UPDATE_DESTINATIONS_PLACES_LIST =
    'UPDATE_DESTINATIONS_PLACES_LIST';
export const ARRANGE_TIPS_DESTINATIONS_OVERVIEW =
    'ARRANGE_TIPS_DESTINATIONS_OVERVIEW';
export const DELETE_TIP_DESTINATIONS_OVERVIEW =
    'DELETE_TIP_DESTINATIONS_OVERVIEW';
export const ADD_TIP_DESTINATIONS_OVERVIEW = 'ADD_TIP_DESTINATIONS_OVERVIEW';
export const UPDATE_TIP_DESTINATIONS_OVERVIEW =
    'UPDATE_TIP_DESTINATIONS_OVERVIEW';

// Setting / getting
/*----------*/
export const SET_EXPERIENCE_SINGLE_DIGITAL = 'SET_EXPERIENCE_SINGLE_DIGITAL';
export const SET_EXPERIENCES_ALL = 'SET_EXPERIENCES_ALL';

/*----------*/
// Experiences
/*----------*/

export const ADD_DIGITAL_EXPERIENCE = 'ADD_DIGITAL_EXPERIENCE';
export const DELETE_DIGITAL_EXPERIENCE = 'DELETE_DIGITAL_EXPERIENCE';
export const UPDATE_CALENDAR = 'UPDATE_CALENDAR';
export const UPDATE_PRICE = 'UPDATE_PRICE';

export const UPDATE_TAGS = 'UPDATE_TAGS';
export const UPDATE_CATS = 'UPDATE_CATS';
export const UPDATE_GUIDE_INCLUDES = 'UPDATE_GUIDE_INCLUDES';

/*----------*/
// Experience
/*----------*/
export const UPDATE_CALENDAR_SINGLE = 'UPDATE_CALENDAR_SINGLE';
export const UPDATE_PRICE_SINGLE = 'UPDATE_PRICE_SINGLE';
export const UPDATE_TAGS_SINGLE = 'UPDATE_TAGS_SINGLE';
export const UPDATE_CATS_SINGLE = 'UPDATE_CATS_SINGLE';
export const UPDATE_GUIDE_INCLUDES_SINGLE = 'UPDATE_GUIDE_INCLUDES_SINGLE';
export const UPDATE_BUDGET = 'UPDATE_BUDGET';

/*----------*/
// Both
/*----------*/
export const UPDATE_PRICE_STATUS = 'UPDATE_PRICE_STATUS';
export const UPDATE_TAGS_CATS_STATUS = 'UPDATE_TAGS_CATS_STATUS';
export const UPDATE_INCLUDES_STATUS = 'UPDATE_INCLUDES_STATUS';
export const UPDATE_EXP_STATUS = 'UPDATE_EXP_STATUS';
export const UPDATE_INCLUDES_DATA = 'UPDATE_INCLUDES_DATA';

/*----------*/
// Accommodation
/*----------*/

export const _ACCOMMODATION_OVERVIEW = '_ACCOMMODATION_OVERVIEW';
export const UPDATE_DESCRIPTION_ACCOMMODATION_OVERVIEW =
    'UPDATE_DESCRIPTION_ACCOMMODATION_OVERVIEW';
export const ARRANGE_TIPS_ACCOMMODATION_OVERVIEW =
    'ARRANGE_TIPS_ACCOMMODATION_OVERVIEW';
export const DELETE_TIP_ACCOMMODATION_OVERVIEW =
    'DELETE_TIP_ACCOMMODATION_OVERVIEW';
export const ADD_TIP_ACCOMMODATION_OVERVIEW = 'ADD_TIP_ACCOMMODATION_OVERVIEW';
export const UPDATE_TIP_ACCOMMODATION_OVERVIEW =
    'UPDATE_TIP_ACCOMMODATION_OVERVIEW';
export const ADD_ACCOMMODATION_OVERVIEW = 'ADD_ACCOMMODATION_OVERVIEW';
export const UPDATE_ACCOMMODATION_OVERVIEW = 'UPDATE_ACCOMMODATION_OVERVIEW';
export const ARRANGE_ACCOMMODATION_OVERVIEW = 'ARRANGE_ACCOMMODATION_OVERVIEW';
export const DELETE_ACCOMMODATION_OVERVIEW = 'DELETE_ACCOMMODATION_OVERVIEW';
export const ADD_MAP_CIRCLE_ACCOMMODATION_OVERVIEW =
    'ADD_MAP_CIRCLE_ACCOMMODATION_OVERVIEW';
export const DELETE_MAP_CIRCLE_ACCOMMODATION_OVERVIEW =
    'DELETE_MAP_CIRCLE_ACCOMMODATION_OVERVIEW';
export const UPDATE_ACCOMMODATION_GALLERY_OVERVIEW =
    'UPDATE_ACCOMMODATION_GALLERY_OVERVIEW';
export const UPDATE_GALLERY_ACTIVITY_ACCOM = 'UPDATE_GALLERY_ACTIVITY_ACCOM';
/*----------*/
// Currency
/*----------*/
export const _CURRENCY_OVERVIEW = '_CURRENCY_OVERVIEW';
export const UPDATE_DESCRIPTION_CURRENCY_OVERVIEW =
    'UPDATE_DESCRIPTION_CURRENCY_OVERVIEW';
export const ARRANGE_TIPS_CURRENCY_OVERVIEW = 'ARRANGE_TIPS_CURRENCY_OVERVIEW';
export const DELETE_TIP_CURRENCY_OVERVIEW = 'DELETE_TIP_CURRENCY_OVERVIEW';
export const ADD_TIP_CURRENCY_OVERVIEW = 'ADD_TIP_CURRENCY_OVERVIEW';
export const UPDATE_TIP_CURRENCY_OVERVIEW = 'UPDATE_TIP_CURRENCY_OVERVIEW';
export const ADD_CURRENCY_OVERVIEW = 'ADD_CURRENCY_OVERVIEW';
export const DELETE_CURRENCY_OVERVIEW = 'DELETE_CURRENCY_OVERVIEW';
export const UPDATE_CURRENCY_OVERVIEW = 'UPDATE_CURRENCY_OVERVIEW';
export const UPDATE_CURRENCY_VENDORS_OVERVIEW =
    'UPDATE_CURRENCY_VENDORS_OVERVIEW';
export const ARANGE_CURRENCY_OVERVIEW = 'ARANGE_CURRENCY_OVERVIEW';

/*----------*/
// best time to go
/*----------*/
export const _GO_TIME_OVERVIEW = '_GO_TIME_OVERVIEW';
export const UPDATE_DESCRIPTION_GO_TIME_OVERVIEW =
    'UPDATE_DESCRIPTION_GO_TIME_OVERVIEW';
export const ARRANGE_TIPS_GO_TIME_OVERVIEW = 'ARRANGE_TIPS_GO_TIME_OVERVIEW';
export const DELETE_TIP_GO_TIME_OVERVIEW = 'DELETE_TIP_GO_TIME_OVERVIEW';
export const ADD_TIP_GO_TIME_OVERVIEW = 'ADD_TIP_GO_TIME_OVERVIEW';
export const UPDATE_TIP_GO_TIME_OVERVIEW = 'UPDATE_TIP_GO_TIME_OVERVIEW';
export const UPDATE_GO_TIME_OVERVIEW = 'UPDATE_GO_TIME_OVERVIEW';

// _BUDGET_OVERVIEW
/*----------*/
// budget
/*----------*/
export const _BUDGET_OVERVIEW = '_BUDGET_OVERVIEW';
export const UPDATE_DESCRIPTION_BUDGET_OVERVIEW =
    'UPDATE_DESCRIPTION_BUDGET_OVERVIEW';
export const ARRANGE_TIPS_BUDGET_OVERVIEW = 'ARRANGE_TIPS_BUDGET_OVERVIEW';
export const DELETE_TIP_BUDGET_OVERVIEW = 'DELETE_TIP_BUDGET_OVERVIEW';
export const ADD_TIP_BUDGET_OVERVIEW = 'ADD_TIP_BUDGET_OVERVIEW';
export const UPDATE_TIP_BUDGET_OVERVIEW = 'UPDATE_TIP_BUDGET_OVERVIEW';
export const UPDATE_BUDGET_OVERVIEW = 'UPDATE_BUDGET_OVERVIEW';
/*----------*/
// sidebar
/*----------*/
export const UPDATE_FEATURE_IMAGE = 'UPDATE_FEATURE_IMAGE';
export const UPDATE_SECTION_VISIBILITY = 'UPDATE_SECTION_VISIBILITY';

export const GET_EXPERIENCES = 'GET_EXPERIENCES';
export const GET_EXPERIENCES_ISADMIN = 'GET_EXPERIENCES_ISADMIN';
export const GET_PUBLISHED_EXPERIENCES = 'GET_PUBLISHED_EXPERIENCES';
export const CREATE_EXPERIENCE = 'CREATE_EXPERIENCE';
export const CREATE_EXPERIENCE_STATUS = 'CREATE_EXPERIENCE_STATUS';
export const GET_EXPERIENCE_SINGLE = 'GET_EXPERIENCE_SINGLE';
export const UPDATE_EXPERIENCE = 'UPDATE_EXPERIENCE';
export const CLONE_EXPERIENCE = 'CLONE_EXPERIENCE';

/*----------*/
// general
/*----------*/
export const CLEAR_EXPERIENCE = 'CLEAR_EXPERIENCE';
export const CLEAR_ALL_EXPERIENCES = 'CLEAR_ALL_EXPERIENCES';
export const SET_EXPERIENCE_UPDATED = 'SET_EXPERIENCE_UPDATED';

/*----------*/
// startup
/*----------*/
export const GET_STARTUP_DATA = 'GET_STARTUP_DATA';
export const GET_IP_DATA = 'GET_IP_DATA';

/*----------*/
// published
/*----------*/
export const GET_PUBLISHED_EXPERIENCE_PUBLIC =
    'GET_PUBLISHED_EXPERIENCE_PUBLIC';
export const GET_EXPERIENCE_PUBLISHED_SINGLE =
    'GET_EXPERIENCE_PUBLISHED_SINGLE';
export const SET_TO_PUBLISHED = 'SET_TO_PUBLISHED';
export const UPDATE_PUBLISHED_STATUS = 'UPDATE_PUBLISHED_STATUS';
export const GET_PUBLISHED_ID_LIST = 'GET_PUBLISHED_ID_LIST';
export const UPDATE_EXP_PUBLISHED = 'UPDATE_EXP_PUBLISHED';
export const CREATE_PUBLISHED_EXP = 'CREATE_PUBLISHED_EXP';
export const GET_GUIDED_PRICE_DATA = 'GET_GUIDED_PRICE_DATA';
export const GET_EXP_REPORT_DATA = 'GET_EXP_REPORT_DATA';
export const GET_PURCHASES_FOR_PUBLISHED = 'GET_PURCHASES_FOR_PUBLISHED';

/*----------*/
// filters/Pagination
/*----------*/

export const SET_FILTER = 'SET_FILTER';
export const SET_EXP_LIST_PAGINATION = 'SET_EXP_LIST_PAGINATION';
/*----------*/
// filters
/*----------*/
export const CREATE_PROFILE = 'CREATE_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const UPDATE_USER_BY_ID = 'UPDATE_USER_BY_ID';

/*----------*/
// marketing
/*----------*/

export const _INTRO_MARKETING = '_INTRO_MARKETING';
export const UPDATE_GALLERY_INTRO_MARKETING = 'UPDATE_GALLERY_INTRO_MARKETING';
export const UPDATE_GALLERY_CAPTION_INTRO_MARKETING =
    'UPDATE_GALLERY_CAPTION_INTRO_MARKETING';
export const UPDATE_TITLE_INTRO_MARKETING = 'UPDATE_TITLE_INTRO_MARKETING';
export const UPDATE_SUBTITLE_INTRO_MARKETING =
    'UPDATE_SUBTITLE_INTRO_MARKETING';
export const UPDATE_DESCRIPTION_INTRO_MARKETING =
    'UPDATE_DESCRIPTION_INTRO_MARKETING';

export const _WHAT_TO_DO_MARKETING = '_WHAT_TO_DO_MARKETING';
export const UPDATE_GALLERY_WHAT_TO_DO_MARKETING =
    'UPDATE_GALLERY_WHAT_TO_DO_MARKETING';
export const UPDATE_GALLERY_CAPTION_WHAT_TO_DO_MARKETING =
    'UPDATE_GALLERY_CAPTION_WHAT_TO_DO_MARKETING';
export const UPDATE_TITLE_WHAT_TO_DO_MARKETING =
    'UPDATE_TITLE_WHAT_TO_DO_MARKETING';
export const UPDATE_SUBTITLE_WHAT_TO_DO_MARKETING =
    'UPDATE_SUBTITLE_WHAT_TO_DO_MARKETING';
export const UPDATE_DESCRIPTION_WHAT_TO_DO_MARKETING =
    'UPDATE_DESCRIPTION_WHAT_TO_DO_MARKETING';

export const _WHERE_TO_STAY_MARKETING = '_WHERE_TO_STAY_MARKETING';

export const UPDATE_GALLERY_WHERE_TO_STAY_MARKETING =
    'UPDATE_GALLERY_WHERE_TO_STAY_MARKETING';
export const UPDATE_GALLERY_CAPTION_WHERE_TO_STAY_MARKETING =
    'UPDATE_GALLERY_CAPTION_WHERE_TO_STAY_MARKETING';
export const UPDATE_TITLE_WHERE_TO_STAY_MARKETING =
    'UPDATE_TITLE_WHERE_TO_STAY_MARKETING';
export const UPDATE_SUBTITLE_WHERE_TO_STAY_MARKETING =
    'UPDATE_SUBTITLE_WHERE_TO_STAY_MARKETING';
export const UPDATE_DESCRIPTION_WHERE_TO_STAY_MARKETING =
    'UPDATE_DESCRIPTION_WHERE_TO_STAY_MARKETING';

export const _WHATS_INCLUDED_MARKETING = '_WHATS_INCLUDED_MARKETING';
export const ADD_WHATS_INCLUDED_MARKETING = 'ADD_WHATS_INCLUDED_MARKETING';
export const DELETE_WHATS_INCLUDED_MARKETING =
    'DELETE_WHATS_INCLUDED_MARKETING';
export const ARRANGE_WHATS_INCLUDED_MARKETING =
    'ARRANGE_WHATS_INCLUDED_MARKETING';
export const UPDATE_WHATS_INCLUDED_MARKETING =
    'UPDATE_WHATS_INCLUDED_MARKETING';
export const UPDATE_DESCRIPTION_WHATS_INCLUDED_MARKETING =
    'UPDATE_DESCRIPTION_WHATS_INCLUDED_MARKETING';

export const _THANKYOU_MARKETING = '_THANKYOU_MARKETING';
export const UPDATE_DESCRIPTION_THANKYOU_MARKETING =
    'UPDATE_DESCRIPTION_THANKYOU_MARKETING';

export const UPDATE_GALLERY_WHATS_INCLUDED_MARKETING =
    'UPDATE_GALLERY_WHATS_INCLUDED_MARKETING';
export const UPDATE_GALLERY_CAPTION_WHATS_INCLUDED_MARKETING =
    'UPDATE_GALLERY_CAPTION_WHATS_INCLUDED_MARKETING';

export const _POLICIES_MARKETING = '_POLICIES_MARKETING';
export const ADD_POLICIES_MARKETING = 'ADD_POLICIES_MARKETING';
export const DELETE_POLICIES_MARKETING = 'DELETE_POLICIES_MARKETING';
export const UPDATE_POLICIES_MARKETING = 'UPDATE_POLICIES_MARKETING';

export const UPDATE_TIP_WHAT_TO_DO_MARKETING =
    'UPDATE_TIP_WHAT_TO_DO_MARKETING';
export const ADD_TIP_WHAT_TO_DO_MARKETING = 'ADD_TIP_WHAT_TO_DO_MARKETING';
export const DELETE_TIP_WHAT_TO_DO_MARKETING =
    'DELETE_TIP_WHAT_TO_DO_MARKETING';
export const ARRANGE_TIPS_WHAT_TO_DO_MARKETING =
    'ARRANGE_TIPS_WHAT_TO_DO_MARKETING';

export const UPDATE_TIP_WHERE_TO_STAY_MARKETING =
    'UPDATE_TIP_WHERE_TO_STAY_MARKETING';
export const ADD_TIP_WHERE_TO_STAY_MARKETING =
    'ADD_TIP_WHERE_TO_STAY_MARKETING';
export const DELETE_TIP_WHERE_TO_STAY_MARKETING =
    'DELETE_TIP_WHERE_TO_STAY_MARKETING';
export const ARRANGE_TIPS_WHERE_TO_STAY_MARKETING =
    'ARRANGE_TIPS_WHERE_TO_STAY_MARKETING';

export const UPDATE_TIP_WHATS_INCLUDED_MARKETING =
    'UPDATE_TIP_WHATS_INCLUDED_MARKETING';
export const ADD_TIP_WHATS_INCLUDED_MARKETING =
    'ADD_TIP_WHATS_INCLUDED_MARKETING';
export const DELETE_TIP_WHATS_INCLUDED_MARKETING =
    'DELETE_TIP_WHATS_INCLUDED_MARKETING';
export const ARRANGE_TIPS_WHATS_INCLUDED_MARKETING =
    'ARRANGE_TIPS_WHATS_INCLUDED_MARKETING';

/*----------*/
// Menu Refs
/*----------*/
export const REFS_OVERVIEW = 'REFS_OVERVIEW';
export const REFS_DAY = 'REFS_DAY';
export const REFS_MARKETING = 'REFS_MARKETING';
export const MENU_REFS_SETTING = 'MENU_REFS_SETTING';
export const MENU_REFS_SET = 'MENU_REFS_SET';

/*----------*/
// Sku
/*----------*/
export const CREATE_GUIDED_SKU = 'CREATE_GUIDED_SKU';
export const CREATE_DIGITAL_SKU = 'CREATE_DIGITAL_SKU';

/*----------*/
// admin
/*----------*/
export const FETCH_USER_LIST = 'FETCH_USER_LIST';
export const UPDATE_EXP_OWNER = 'UPDATE_EXP_OWNER';
export const GET_PUBLISHED_ID = 'GET_PUBLISHED_ID';
export const DELETE_PUBLISHED = 'DELETE_PUBLISHED';
export const DELETE_EXPERIENCE = 'DELETE_EXPERIENCE';
export const FETCH_USERS = 'FETCH_USERS';

/*----------*/
// purchased
/*----------*/

export const GET_PURCHASED_EXPERIENCES = 'GET_PURCHASED_EXPERIENCES';
export const GET_PURCHASED_EXPERIENCE = 'GET_PURCHASED_EXPERIENCE';

/*----------*/
// Price
/*----------*/
export const UPDATE_DIGITAL_PRICE = 'UPDATE_DIGITAL_PRICE';
export const UPDATE_GUIDED_PRICE = 'UPDATE_GUIDED_PRICE';