import { connect } from 'react-redux';
import { handleRowReverse } from 'helpers/FEutils';
import CardXlEditDelete from 'components/blocks/CardXlEditDelete';
import translations from 'constants/translations';
import uiStruct from 'constants/uiStruct';
import Icons from 'components/blocks/Icons';
// import FeedbackBox from './FeedbackBox';

function CheckListTitlesLinks(props) {
    const {
        edit,
        isFeedbackMode,
        // deleteIcon,
        rtl,
        // icon,
        // bullet,
        editIcon,
        dontForgetList,
        globalState: { lang }
    } = props;

    const urlArr = [...dontForgetList];

    // const handleDelete = () => {
    //     //deleteIcon();
    // };

    const handleEdit = () => {
        editIcon();
    };

    return (
        <li
            className={`relative w-full justify-between border-2 border-yellow-100 bg-yellow-503 hover:bg-kn-yellow-25s transition-colors ease-in-out duration-300 rounded-3xl px-8 md:px-12 py-4 md:py-12 flex flex-col md:flex-row md:${
                handleRowReverse(rtl).flex
            } flex-wrap md:flex-nowrap mb-4  
                            `}
        >
            {edit && !isFeedbackMode && (
                <>
                    {/* <div
                        className={`z-40 bg-red-200 cursor-pointer absolute inset-0 opacity-30 rounded-xl ring-transparent ring-4 hover:ring-green-400 transition-all ease-in-out duration-300 ${
                            edit ? 'hover:bg-green-100' : ''
                        }`}
                        onClick={handleEdit}
                    /> */}

                    <CardXlEditDelete
                        // handleDelete={handleDelete}
                        handleEdit={() => handleEdit()}
                        rtl={rtl}
                        color='yellow'
                        colors={uiStruct.ui.tip_list.UI_LBL_DONTFORGET}
                        test
                    />
                </>
            )}

            <div
                className={`py-4 md:py-0 w-full flex flex-col md:flex-row md:flex-wrap text-sm gap-y-4 ${
                    handleRowReverse(rtl).flex
                } ${handleRowReverse(rtl).rtl} ${
                    urlArr.length > 0 ? '' : 'italic text-lg text-gray-400'
                }`}
            >
                {urlArr.length > 0 ? (
                    urlArr.map((urlItem, index) => {
                        return (
                            <div key={index} className={`md:w-1/2 `}>
                                <div className='inline-flex gap-2 flex-nowrap items-center relative pointer-events-auto'>
                                    {urlItem.url.length ? (
                                        <>
                                            <Icons
                                                iName='CHECK'
                                                size='2xl'
                                                iClasses='text-yellow-400'
                                            />
                                            <div className='relative h-6 flex items-center hover:bg-yellow-100'>
                                                <div className='z-10 absolute bottom-0 h-1 w-full bg-kn-yellow'></div>
                                                <a
                                                    className={`z-50  leading-tight ${
                                                        handleRowReverse(rtl)
                                                            .text
                                                    }`}
                                                    href={urlItem.url}
                                                    target='_blank'
                                                    rel='noreferrer'
                                                >
                                                    {urlItem.title}
                                                </a>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <Icons
                                                iName='CHECK'
                                                size='2xl'
                                                iClasses='text-green-400'
                                            />
                                            <div className='relative h-6 flex items-center'>
                                                <div className='z-0 absolute bottom-0 h-0 bg-kn-yellow'></div>
                                                <div
                                                    className={`z-10 pointer-events-none inline leading-tight ${
                                                        handleRowReverse(rtl)
                                                            .text
                                                    }`}
                                                >
                                                    {urlItem.title}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {/* <FeedbackBox dot='top-1/2 -translate-y-1/2 -right-8' /> */}
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div>
                        {
                            translations[lang][
                                uiStruct.itinerary.what_you_need.uiPreview
                                    .checklistEmpty
                            ]
                        }
                    </div>
                )}
            </div>
        </li>
    );
}

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps)(CheckListTitlesLinks);
