// Example
// https://maps.google.com/?q=51.7554246,-0.3324318&ll=51.7554246,-0.3324318&z=21

import { handleRowReverse } from 'helpers/FEutils';
import CardXlEditDelete from 'components/blocks/CardXlEditDelete';
import Icons from 'components/blocks/Icons';
import uiStruct from 'constants/uiStruct';
import FeedbackBox from 'components/blocks/FeedbackBox';

function GoogleMapLink({
    objId,
    edit,
    isFeedbackMode,
    openMapModal,
    mapData,
    placeholder = '',
    rtl = false
}) {
    const {
        address,
        data: {
            zoom,
            location: { lat, lng }
        }
    } = mapData;
    const googleMapLink = `https://maps.google.com/?q=${lat},${lng}&ll=${lat},${lng}&z=${zoom}`;
    const editMapLink = () => {
        openMapModal();
    };

    const colors = uiStruct.ui.tip_list.map;
    return (
        <div className='mb-6 relative'>
            <div
                className={`sm:items-center relative flex group py-5 px-4 w-full bg-${
                    colors.bg_color
                } hover:bg-${
                    colors.hover_color
                } transition-colors ease-in-out duration-300 rounded-3xl  ${
                    handleRowReverse(rtl).flex
                }`}
            >
                {edit && !isFeedbackMode && (
                    <CardXlEditDelete
                        handleEdit={editMapLink}
                        rtl={rtl}
                        color='cyan'
                        colors={colors}
                    />
                )}

                <div
                    className={`px-4 lg:px-12 flex flex-nowrap items-center 
                            ${handleRowReverse(rtl).rtl}`}
                >
                    <Icons
                        iName='MAP_MARKERALT'
                        iClasses={`text-cyan-400 ${rtl ? 'ml-4' : 'mr-4'}`}
                    />
                    {edit ? (
                        <div
                            className={`focus:outline-none ${
                                address ? 'text-sm' : 'italic text-gray-400'
                            }`}
                        >
                            {address || placeholder}
                        </div>
                    ) : (
                        <a
                            href={googleMapLink}
                            rel='noreferrer'
                            target='_blank'
                            className={`${
                                address
                                    ? 'text-sm'
                                    : 'text-sm italic text-gray-400'
                            }`}
                        >
                            {address || placeholder}
                        </a>
                    )}
                </div>
            </div>
            <FeedbackBox objId={objId} label='Location element' />
        </div>
    );
}

export default GoogleMapLink;
